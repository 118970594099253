import React from 'react';
import { TaxiPathMap } from './map/TaxiPathMap';
import { Provider as MapProvider } from 'src/components/Map/context/Provider';
import { TaxiPathContainer } from './TaxiPath.styles';
import { TaxiPathProvider } from './context/TaxiPathContext';

export const TaxiPath = () => (
  <TaxiPathProvider>
    <TaxiPathContainer>
      <MapProvider>
        <TaxiPathMap />
      </MapProvider>
    </TaxiPathContainer>
  </TaxiPathProvider>
);
