import { useEffect } from 'react';
import { useMapContext } from '../context/useMapContext';
import { MAP_ACTIONS } from '../context/Reducer';
import { MapActionType } from '../map.types';

export enum InteractiveLayerId {
  taxiPathNode = 'taxi-path-node',
  editableNode = 'editable-node',
  taxiPathConnector = 'taxi-path-connector',
  addedConnector = 'added-connector',
  anomsMapMarker = 'anoms-map-marker',
  drawableLinePoint = 'drawable-line-point',
  drawableLineSegment = 'drawable-line-segment',
  drawableLineSegmentMidpoint = 'drawable-line-segment-midpoint',
  drawablePoint = 'drawable-point',
}

/**
 * Adds layer IDs to the list of interactive layer IDs
 * in context on mount and removes on unmount.
 * Can't use static ID values due to react-map-gl not
 * binding addLayer/removeLayer to appropriate event
 * handlers when changing map styles.
 *
 * @param ids ID of the interactive layer
 */
export const useInteractiveLayerIds = (ids: InteractiveLayerId[]) => {
  const { dispatch } = useMapContext();
  useEffect(() => {
    dispatch({ type: MAP_ACTIONS.ADD_INTERACTIVE_LAYERS as MapActionType, payload: ids });
    return () =>
      dispatch({ type: MAP_ACTIONS.REMOVE_INTERACTIVE_LAYERS as MapActionType, payload: ids });
  }, [dispatch, ids]);
};
