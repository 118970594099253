// Types
import { ITableFilterObject } from 'src/components';

// Functions
import { getQueryFilterFromTableFilters } from 'src/@scenarioGeneration/containers/ViewScenario/components/Table/helpers';
import { IAircraftMappingActions } from '../types';

export const onFilterUpdate = (
  { filters }: ITableFilterObject,
  dispatch: (value: IAircraftMappingActions) => void
): void => {
  dispatch({ type: 'TABLE_FILTER', data: getQueryFilterFromTableFilters(filters) });
};
