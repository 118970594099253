import React from 'react';
import styled from 'styled-components/macro';
import {
  FormErrorDisplay,
  FormTextField,
  getConvertedDistance,
  getConvertedVerticalDistance,
  Icons,
} from '@ems/client-design-system';
import { TDistanceUnit, TVerticalDistanceUnit } from 'src/utils/interfaces';
import { IMPERIAL_BASE_UNIT, INFRINGEMENT_RULE_TYPES, UNIT_FOOT, UNIT_METER } from 'src/constants';
import { formatMeasurementAsNumericString, getImperialOrMetricBaseUnit } from 'src/utils';
import styles from 'src/styles/vars.module.scss';
import { CollapseComponent } from 'src/components/CollapseComponent/CollapseComponent';
import { CollapseControl } from 'src/components/CollapseComponent/CollapseComponent.styles';
import { useLanguageSelectors } from 'src/app/reducers';

const Container = styled.div`
  ${CollapseControl} {
    margin: 0;
  }
`;
const Primary = styled.div<{ imageName: string }>`
  background-image: url(${process.env.PUBLIC_URL}/${props => props.imageName});
  background-size: contain;
  background-repeat: no-repeat;
  display: table;
  height: 220px;
  width: 100%;
  position: relative;
`;
const PrimaryLeft = styled.div`
  display: table-cell;
  width: 330px;
`;
const PrimaryRight = styled.div`
  display: table-cell;
  position: relative;
  min-width: 4rem;
  margin-left: 1rem;
`;
const CeilingField = styled.div<{ margin: string }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${props => props.margin};

  .form-group__error {
    position: relative;
    max-width: 75px;
    margin: 20px 0 0 5px;

    .mosaic-icon {
      display: none;
    }

    .form-group__error-text {
      font-size: 12px;
    }
  }
`;
const MinSegmentField = styled.div`
  position: absolute;
  top: 75px;
`;
const FloorField = styled.div<{ margin: string }>`
  position: absolute;
  top: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${props => props.margin};

  .form-group__error {
    position: relative;
    max-width: 75px;
    margin: 20px 0 0 5px;

    .mosaic-icon {
      display: none;
    }

    .form-group__error-text {
      font-size: 12px;
    }
  }
`;
const Secondary = styled.div`
  padding-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;
const SecondaryLeft = styled.div`
  padding-right: 60px;
`;
const SecondaryRight = styled.div`
  display: table-cell;
`;

const Line = styled.div<{ position?: string; margin?: string }>`
  position: ${props => (props.position ? props.position : 'absolute')};
  max-width: 300px;
  width: 80%;
  left: 0;
  margin: ${props => (props.margin ? props.margin : '0')}};
  background: #5aaafa;
  height: 1px;
`;

const SideLineContainer = styled.div<{ top: string }>`
  display: flex;
  position: absolute;
  flex-direction: row;
  height: 1px;
  top: ${props => props.top};
  width: 100%;
  left: 0px;
`;

const SideArrowContent = styled.div<{ margin: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1.7rem;
  margin: ${props => props.margin};
`;

export const CcoCdoPanel = infDetailsData => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { form: formStrings },
      },
    },
  } = languageSelectors.getLanguage();
  const { infRule, setInfRule, units, isEditing, errors } = infDetailsData;
  const handleChange = (value, fieldId) => {
    setInfRule(Object.assign({}, infRule, { [fieldId]: value }, { hasChanged: true }));
  };

  const formatVerticalDistance = (value: string, unit: TVerticalDistanceUnit): string => {
    if (isEditing) {
      return value;
    }
    // returns value following 4 digit rule
    return getConvertedVerticalDistance(Number(value), unit, 0, unit);
  };

  const formatDistance = (value: string, unit: TDistanceUnit): string => {
    if (isEditing) {
      // for distance sometimes the unit is miles (ICAO Alternative) - users can only enter in base units ft so we have to override this conversion
      return formatMeasurementAsNumericString(
        getConvertedDistance(
          Number(value),
          getImperialOrMetricBaseUnit(unit),
          0,
          getImperialOrMetricBaseUnit(unit) === IMPERIAL_BASE_UNIT ? UNIT_FOOT : UNIT_METER
        )
      );
    }

    // returns value following 4 digit rule
    return getConvertedDistance(
      Number(value),
      unit,
      -1,
      getImperialOrMetricBaseUnit(unit) === IMPERIAL_BASE_UNIT ? UNIT_FOOT : UNIT_METER
    );
  };

  const getGraphImageName = (): string =>
    `${infRule.infringementType === INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT ? 'CCO' : 'CDO'}.png`;

  const sideLine = (top: string) => (
    <SideLineContainer top={top}>
      <Line />
    </SideLineContainer>
  );

  const readOnlyArrowMargin = '0';
  const isEditingArrowMargin = '0.8rem 0 0 0';
  const arrow = () => (
    <SideArrowContent margin={isEditing ? isEditingArrowMargin : readOnlyArrowMargin}>
      <Icons fill={'#5aaafa'} iconName={'ic-triangle-left'} />
      <Line position={'relative'} margin={'0.2rem 0 0 -0.4rem'} />
    </SideArrowContent>
  );

  const editingFieldMargin = '0rem 0 0 -1.7rem';
  const readOnlyFieldMargin = '1rem 0 0 -1.7rem';

  return (
    <Container>
      <Primary imageName={getGraphImageName()}>
        <PrimaryLeft />
        {sideLine('2.4rem')}
        {sideLine('12.4rem')}
        <PrimaryRight>
          <CeilingField margin={isEditing ? editingFieldMargin : readOnlyFieldMargin}>
            {arrow()}
            <FormTextField
              label={formStrings.ceilingAMSL}
              id="ceilingAltitude"
              value={formatVerticalDistance(infRule.ceilingAltitude, units.distanceVertical)}
              isNumberOnly
              onChange={handleChange}
              isReadOnly={!isEditing}
              displayEmdashOnEmpty={false}
              inputStyleProps={{ width: '80px' }}
              sideLabelEditMode={units.distanceVertical}
            />
            {errors && (
              <FormErrorDisplay
                displayError={isEditing}
                error={errors && errors.ceilingAltitude ? errors.ceilingAltitude : undefined}
              />
            )}
          </CeilingField>
          <MinSegmentField>
            <FormTextField
              label={formStrings.minSegment}
              id="minLevelSegmentLength"
              value={formatDistance(infRule.minLevelSegmentLength, units.distance)}
              isNumberOnly
              onChange={handleChange}
              isReadOnly={!isEditing}
              displayEmdashOnEmpty={false}
              inputStyleProps={{ width: '80px' }}
              sideLabelEditMode={
                getImperialOrMetricBaseUnit(units.distance) === IMPERIAL_BASE_UNIT
                  ? UNIT_FOOT
                  : UNIT_METER
              }
            />
          </MinSegmentField>
          <FloorField margin={isEditing ? editingFieldMargin : readOnlyFieldMargin}>
            {arrow()}
            <FormTextField
              label={formStrings.floorAMSL}
              id="floorAltitude"
              value={formatVerticalDistance(infRule.floorAltitude, units.distanceVertical)}
              isNumberOnly
              onChange={handleChange}
              isReadOnly={!isEditing}
              displayEmdashOnEmpty={false}
              inputStyleProps={{ width: '80px' }}
              sideLabelEditMode={units.distanceVertical}
            />
            {errors && (
              <FormErrorDisplay
                displayError={isEditing}
                error={errors && errors.floorAltitude ? errors.floorAltitude : undefined}
              />
            )}
          </FloorField>
        </PrimaryRight>
      </Primary>
      <CollapseComponent headerTitle={'Advanced'} fill={styles.brand01}>
        <Secondary>
          <SecondaryLeft>
            <FormTextField
              label={formStrings.noOfSegments}
              id="maxLevelSegments"
              value={infRule.maxLevelSegments}
              isNumberOnly
              onChange={handleChange}
              isReadOnly={!isEditing}
              displayEmdashOnEmpty={false}
              inputStyleProps={{ width: '80px' }}
            />
          </SecondaryLeft>
          <SecondaryRight>
            <FormTextField
              label={formStrings.anySegmentLength}
              id="autoInfringementSegmentLength"
              value={formatDistance(infRule.autoInfringementSegmentLength, units.distance)}
              isNumberOnly
              onChange={handleChange}
              isReadOnly={!isEditing}
              displayEmdashOnEmpty={false}
              inputStyleProps={{ width: '80px' }}
              sideLabelEditMode={
                getImperialOrMetricBaseUnit(units.distance) === IMPERIAL_BASE_UNIT
                  ? UNIT_FOOT
                  : UNIT_METER
              }
            />
          </SecondaryRight>
        </Secondary>
      </CollapseComponent>
    </Container>
  );
};
