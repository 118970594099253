import React from 'react';
// utils
import { InlineEditDropDown, InlineEditText } from 'src/utils';
// constants
import {
  PERMISSIONS,
  FUELTYPES_MODULE,
  LOADFACTORS_MODULE,
  MANAGE_CONFIGURATIONS_MODULE,
  AIRCRAFT_MAPPING_MODULE,
  TIME_IN_MODES_MODULE,
  FLEETMIX_MODULE,
  AIRCRAFT_MODEL_SEPARATOR,
} from 'src/constants';
// libraries
import uuid from 'uuid';
// components
import { Filter } from '@ems/client-design-system';
import { DatePickerComponent } from 'src/components';

// interfaces
import { IFilterItem } from 'src/@operations/props';
import { IEmissionData, TMode, TSettingsPermissionObject } from 'src/@settings/interfaces';
// functions
import {
  getFormattedScenarioMutationRecord,
  getFormattedAircraftMappingRecord,
  getFormattedPhaseDefaultRecord,
  getFormattedFuelTypesMutationRecord,
  getFormattedLoadFactorRecord,
  getFormattedFleetMixMutationRecord,
} from 'src/@settings/functions';

export const getLtoEmissionMappings = (ltoEmissions: IEmissionData[]) =>
  ltoEmissions.map(item => item.aircraftType + AIRCRAFT_MODEL_SEPARATOR + item.engine);

export const getEnrouteEmissionMappings = (enrouteEmissions: IEmissionData[]) =>
  enrouteEmissions.map(item => item.aircraftType + AIRCRAFT_MODEL_SEPARATOR + item.engine);

export const addNewTableIds = data =>
  data.map(item => ({
    ...item,
    tableId: uuid.v4(),
  }));

export const assignDropdown = (
  index,
  aircraftMapping,
  options,
  translationData,
  fieldType,
  labelName,
  updateAircraftMapping
) =>
  InlineEditDropDown({
    id: index,
    operation: aircraftMapping,
    fieldType,
    position: 'center',
    labelName,
    translationData,
    options,
    onUpdateSelection: updateAircraftMapping,
    canUpdate: true,
  });

export const assignDatePicker = (
  index,
  value,
  updateLoadFactor,
  fieldNameVal,
  maxDate,
  minDate
) => (
  <DatePickerComponent
    value={value}
    onChange={(fieldName, value) => {
      const dateChanged = {};
      dateChanged[fieldName] = value;

      updateLoadFactor(index, dateChanged);
    }}
    setFieldValue={null}
    fieldName={fieldNameVal}
    maxDate={maxDate}
    minDate={minDate}
  />
);

export const assignTextInput = (
  index,
  translationData,
  fieldType,
  labelName,
  updateAction,
  validationRegex
) => {
  const {
    components: {
      labels: { entervalue },
      hints: { invalidEntry },
    },
  } = translationData;
  return InlineEditText({
    id: index,
    fieldType,
    position: 'center',
    value: labelName,
    translationData: {
      invalidText: invalidEntry,
      placeholder: entervalue,
    },
    canUpdate: true,
    onUpdateSelection: updateAction,
    validation: { regexStr: validationRegex },
    onCheckValidity: () => {},
  });
};

const getDayWithIndex = day => {
  for (let i = 0; i < day.length; i++) {
    const dayValue = day.charAt(i);
    if (dayValue !== '_') {
      return { index: i, value: dayValue };
    }
  }
  return { index: 0, value: '_' };
};

const formatDaysItem = days => {
  let daysString = '_______';
  days.forEach((day: IFilterItem) => {
    const { index, value } = getDayWithIndex(day.key);
    daysString = daysString.substr(0, index) + value + daysString.substr(index + 1);
  });
  return daysString;
};

export const assignDaysDropDrown = (
  index,
  selectedItems,
  originalItems,
  fieldName,
  updateLoadFactor
) => (
  <Filter
    key={''}
    categoryName={''}
    filterItems={originalItems}
    selectedItems={selectedItems}
    updateItems={(items: IFilterItem[]) => {
      const itemsChanged = {};
      itemsChanged[fieldName] = formatDaysItem(items);
      updateLoadFactor(index, itemsChanged);
    }}
    languageData={{ clearValue: '', filterValue: '', noMatchesFound: '' }}
    iconCategories={[]}
    isCategoryNameRequired={false}
    showClear={false}
  />
);

export const formatFilterItems = filterItems =>
  filterItems && filterItems.length
    ? filterItems.map(item => {
        const filterItem: IFilterItem = {
          key: item.shortcut,
          label: item.name,
          icon: '',
          className: '',
        };
        return filterItem;
      })
    : [];

export const getFormattedMutationItem = (mode: TMode, updatingItem, mutationData, module) => {
  const existingItem = mutationData.get(updatingItem.id);
  let record;
  if (existingItem && existingItem.mode === 'Insert' && mode === 'Update') {
    mode = 'Insert';
  }
  if (existingItem && existingItem.mode === 'Duplicate' && mode === 'Update') {
    mode = 'Duplicate';
  }
  if (existingItem && existingItem.mode === 'Insert' && mode === 'Delete') {
    mutationData.delete(updatingItem.id);
  } else {
    switch (module) {
      case FLEETMIX_MODULE:
        record = getFormattedFleetMixMutationRecord(updatingItem);
        break;
      case FUELTYPES_MODULE:
        record = getFormattedFuelTypesMutationRecord(updatingItem);
        break;
      case LOADFACTORS_MODULE:
        record = getFormattedLoadFactorRecord(updatingItem);
        break;
      case AIRCRAFT_MAPPING_MODULE:
        record = getFormattedAircraftMappingRecord(updatingItem);
        break;
      case TIME_IN_MODES_MODULE:
        record = getFormattedPhaseDefaultRecord(updatingItem);
        break;
      case MANAGE_CONFIGURATIONS_MODULE:
        record = getFormattedScenarioMutationRecord(updatingItem);
    }
    mutationData.set(updatingItem.id, { mode, record });
  }
  return mutationData;
};

export const getColumnTypes = columnValues => {
  const columnTypeValues = {};
  columnValues.forEach(columnValue => {
    columnTypeValues[columnValue] = {
      title: columnValue,
      abbreviation: '',
    };
  });
  return columnTypeValues;
};

export const canReadSomeOf = (permissionsByCategory: TSettingsPermissionObject): boolean =>
  Object.keys(permissionsByCategory).some(
    category => permissionsByCategory[category][PERMISSIONS.READ]
  );

export const canInsertSomeOf = (permissionsByCategory: TSettingsPermissionObject): boolean =>
  Object.keys(permissionsByCategory).some(
    category => permissionsByCategory[category][PERMISSIONS.INSERT]
  );

export const canUpdateSomeOf = (permissionsByCategory: TSettingsPermissionObject): boolean =>
  Object.keys(permissionsByCategory).some(
    category => permissionsByCategory[category][PERMISSIONS.UPDATE]
  );

export const canDeleteSomeOf = (permissionsByCategory: TSettingsPermissionObject): boolean =>
  Object.keys(permissionsByCategory).some(
    category => permissionsByCategory[category][PERMISSIONS.DELETE]
  );
