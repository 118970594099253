import { IAircraftToNoiseModelingTypeSummaryFilter, IAircraftAssignmentsData } from './interfaces';
import { ISortObject } from 'src/components';

export const DEFAULT_QUERY_FILTER: IAircraftToNoiseModelingTypeSummaryFilter = {
  noiseModelTypes: ['INM'],
};

export const DEFAULT_SORT_ORDER: ISortObject = {
  field: 'numberOfFlights',
  direction: 'DESC',
};

export const INITIAL_PAGE_SIZE = 300;
export const LOAD_MORE_PAGE_SIZE = 1000;

export const DEFAULT_QUERY_RESULT: IAircraftAssignmentsData = {
  aircraftToNoiseModelingTypeSummariesByTimeRange: {
    edges: [],
    totalCount: -1,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  },
};
