import {
  IAuditLogEntrySummariesByTimeRangeVariables,
  IAuditLogEntrySummariesByTimeRangeResponse,
} from './interfaces';

export const FIRST_LOAD_SIZE = 300;
export const LOAD_MORE_COUNT = 1000;

export const defaultQueryVariables: IAuditLogEntrySummariesByTimeRangeVariables = {
  filter: {},
  sort: [{ field: 'time', direction: 'DESC' }],
  first: FIRST_LOAD_SIZE,
};

export const defaultData: IAuditLogEntrySummariesByTimeRangeResponse = {
  auditLogEntrySummariesByTimeRange: {
    totalCount: -1,
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
};
