import {
  Button,
  Filter,
  FilterRow,
  Icons,
  IDFilter,
  isCompletedTimeFormat,
  isValidTime,
  onCloseTimeDialogValidation,
  RangeSelector,
  validateTimeFormat,
} from '@ems/client-design-system';
import React from 'react';
import {
  FilterComponentTypes,
  IdFilterProps,
  SelectionFilterFilterProps,
  TimeFilterProps,
} from 'src/@operations/containers/FilterContainer/filters.types';
import { useOperationsFilter } from './useOperationsFilter';

export const OperationsFilterContainer = () => {
  const {
    filters,
    clearAllFilters,
    filterRowLanguageData,
    isClearFiltersDisabled,
    pcaData: { pcaLocation, pcaFieldLabel, onClearPcaFilter },
  } = useOperationsFilter();

  const filterComponents = filters.flatMap(({ filterType, filterCategory, filterProps }) => {
    switch (filterType) {
      case FilterComponentTypes.Selection: {
        const {
          filterItems,
          selectedItems,
          onUpdateFilter,
          iconCategories,
          theme,
          categoryLabel,
          languageData,
        } = filterProps as SelectionFilterFilterProps;
        return (
          <Filter
            key={filterCategory}
            categoryName={categoryLabel}
            filterItems={filterItems}
            selectedItems={selectedItems}
            updateItems={onUpdateFilter}
            languageData={languageData}
            iconCategories={iconCategories}
            theme={theme}
          />
        );
      }
      case FilterComponentTypes.ID: {
        const {
          onFilterChange,
          onFilterSubmit,
          onFilterClear,
          selectedItems,
          searchValue,
          languageData,
          categoryLabel,
        } = filterProps as IdFilterProps;
        return (
          <IDFilter
            categoryName={categoryLabel}
            key={`filter-id-${filterCategory}`}
            value={searchValue}
            onChange={onFilterChange}
            onSubmit={onFilterSubmit}
            selectedID={selectedItems}
            onClearSelect={onFilterClear}
            languageData={languageData}
          />
        );
      }
      case FilterComponentTypes.Time: {
        const { filterValues, setInputValue, label, languageData } = filterProps as TimeFilterProps;
        return (
          <RangeSelector
            key="filter-time-selector"
            type="time"
            min={null}
            max={null}
            fromInputValue={filterValues.from}
            toInputValue={filterValues.to}
            setInputValue={setInputValue}
            label={label}
            validateInputFormat={validateTimeFormat}
            isValidValue={isValidTime}
            onCloseValidation={onCloseTimeDialogValidation}
            isCompletedValueFormat={isCompletedTimeFormat}
            languageData={languageData}
          />
        );
      }
      default:
        console.warn(`${filterType} component has not been configured for operations filter`);
        return [];
    }
  });

  if (pcaLocation) {
    filterComponents.push(
      <span className="filter-pca-location" key="filter-pca">
        <span className="filter-pca-location--category">{pcaFieldLabel}: </span>
        <span className="filter-pca-location--item">{pcaLocation}</span>
        <Button
          style="subtle"
          leftIcon={<Icons iconName="ic-ui-cancel-sm" />}
          onClick={onClearPcaFilter}
        />
      </span>
    );
  }

  return (
    <FilterRow
      filters={filterComponents}
      clearFilters={clearAllFilters}
      clearDisabled={isClearFiltersDisabled}
      languageData={filterRowLanguageData}
    />
  );
};
