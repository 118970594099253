import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  singleSummarySchema,
  noiseEventsByIdQuery,
  noiseMonitorLocationIdsQuery,
  noiseMonitorSchema,
  operationDetailsSchema,
  fetchOperationDetailsQuery,
  profilesByIdsQuery,
  operationProfileSchema,
} from 'src/@noiseEvents/resolvers/schema';

export const fetchNoiseEventsById = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_NOISE_EVENTS = gql`
      query {
        ${noiseEventsByIdQuery}(ids: [${id}]) ${singleSummarySchema}
      }
    `;

    client
      .query({ query: GET_NOISE_EVENTS })
      .then(response => {
        if (response && response.data && response.data[noiseEventsByIdQuery]) {
          resolve({
            data: response.data[noiseEventsByIdQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseMonitorLocations = (client: ApolloClient<object>, locationIds: string[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `
      ids: ${JSON.stringify(locationIds)}
    `;
    const GET_MONITORS = gql`
      query {
        ${noiseMonitorLocationIdsQuery}(${queryParams}) ${noiseMonitorSchema}
      }
    `;
    client
      .query({ query: GET_MONITORS })
      .then(response => {
        if (response && response.data && response.data[noiseMonitorLocationIdsQuery]) {
          resolve({
            data: response.data[noiseMonitorLocationIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchOperationDetails = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS = gql`
      query {
        ${fetchOperationDetailsQuery}(id: ${id}) ${operationDetailsSchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (response && response.data && response.data.operationSummary) {
          resolve({
            data: response.data.operationSummary,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchProfilesById = (client: ApolloClient<object>, ids: number[]) => {
  return new Promise((resolve, reject) => {
    const GET_PROFILES_BY_ID = gql`
      query {
        ${profilesByIdsQuery}(ids: ${JSON.stringify(ids)}) ${operationProfileSchema}
      }
    `;

    client
      .query({ query: GET_PROFILES_BY_ID })
      .then(response => {
        if (response && response.data && response.data[profilesByIdsQuery]) {
          resolve({
            data: response.data[profilesByIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
