import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const MAP_WIDTH = 0.435;
export const SplitMapContainer = styled.div`
  flex-grow: 1;
  // 40.5% of screen size minus - settings sidebar
  min-width: calc((100% - 14rem) * ${MAP_WIDTH});
  position: fixed;
  right: 0;
`;

export const SettingsHeading = styled.div`
  margin-bottom: 0;
  padding: 2.5rem 2.5rem 0 2.5rem;
`;

export const SplitContent = styled.div`
  max-width: 1200px;
  width: 57%;
  display: block;
`;

export const StyledTableLink = styled(Link)`
  color: #07469e;
  text-decoration: none;
  cursor: pointer;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
`;
