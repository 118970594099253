import React, { useContext, useState, useEffect } from 'react';

// Page contents
import { SpatialFeaturesMap } from '../SpatialFeaturesMap';

// Components
import { PageHeader } from 'src/components';

import { Button, Card, displaySuccess } from '@ems/client-design-system';

import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import { ISpatialFeature, ISpatialFeatureForm } from 'src/@settings/interfaces';
import { FormWrapper } from 'src/components/FormField';
import {
  getNewSpatialFeatureForm,
  setZonePointsToClockwise,
  useSpatialFeatureMutations,
  validateFeature,
  mutateSpatialFeature,
} from 'src/@settings/functions';
import {
  TEXT_INPUT,
  FEATURE_TYPES,
  FLOOR_DEFAULT_FEET,
  CEILING_DEFAULT_FEET,
  IMPERIAL_BASE_UNIT,
  UNIT_FOOT,
  INPUT_RETURN_TYPES,
  GQL_MUTATION_TYPES,
} from 'src/constants';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

import { newFeatureAdded } from 'src/@settings/actions';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import {
  getImperialOrMetricBaseUnit,
  getVerticalDistance,
  setUnitAsMetersOrFeet,
  toCamelCase,
} from 'src/utils';
import { OperationsFilter } from 'src/@settings/components';
import {
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
  TSelectionZoneGeometry,
  TSpatialFeature,
  TSpatialFeatureGeometry,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { TVerticalDistanceUnit } from 'src/utils/interfaces';

interface IAddFeatureProps {
  cancelAddFeature: () => void;
  onFeatureAdded: (newFeature: ISpatialFeature, featureType: string) => void;
}

export const AddSpatialFeature: React.FC<IAddFeatureProps> = ({
  cancelAddFeature,
  onFeatureAdded,
}) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const settingsDispatcher = useContext<any>(SettingsDispatchContext);
  // Form data
  const [formData, updateFormData] = useState<ISpatialFeatureForm>(getNewSpatialFeatureForm());
  const [isSaveDisabled, updateIsSaveDisabled] = useState<boolean>(true);

  const [isGeometryValid, setIsGeometryValid] = useState<boolean>(false);
  const languageSelector = useLanguageSelectors();

  const {
    components: { spatialFeatureMutationMessages },
  } = languageSelector.getLanguage();
  // Spatial Feature Data
  const [featureType, updateFeatureType] = useState<string>(FEATURE_TYPES.GATE);
  const [featureGeometry, updateFeatureGeometry] = useState<undefined | TSpatialFeatureGeometry>();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { save: saveString, cancel: cancelString },
        spatialFeatures: {
          newFeature: newFeatureString,
          details: detailsString,
          form: formStrings,
        },
      },
    },
  } = languageSelectors.getLanguage();

  const cancel = () => {
    updateFeatureGeometry(undefined);
    updateFeatureType(null);
    cancelAddFeature();
  };

  const spatialFeaturesSelector = useSpatialFeaturesSelector();

  const verticalDistance: TVerticalDistanceUnit = setUnitAsMetersOrFeet(units.distanceVertical);
  // params for value conversion/setting
  const numericFeet = {
    convertTo: IMPERIAL_BASE_UNIT,
    convertFrom: verticalDistance,
    returnValueType: INPUT_RETURN_TYPES.NUMBER,
  };

  const numericDistanceUnits = {
    convertTo: getImperialOrMetricBaseUnit(verticalDistance),
    convertFrom: UNIT_FOOT as 'ft',
    returnValueType: INPUT_RETURN_TYPES.NUMBER,
  };

  const formatNewCorridor = () => ({
    name: formData.name.value,
    isActive: false,
    groupName: 'Default',
    filter: {
      operationTypes: [],
      runways: [],
    },
    geometry: featureGeometry,
  });

  const formatNewGate = () => ({
    name: formData.name.value,
    groupName: 'Default',
    isActive: false,
    filter: {
      operationTypes: [],
      runways: [],
    },
    geometry: {
      ...featureGeometry,
      floorAltitude: getVerticalDistance(formData.floorAltitude.value, numericFeet),
      ceilingAltitude: getVerticalDistance(formData.ceilingAltitude.value, numericFeet),
    },
  });

  const formatNewZone = () => ({
    name: formData.name.value,
    isActive: false,
    groupName: 'Default',
    floorAltitude: getVerticalDistance(formData.floorAltitude.value, numericFeet),
    ceilingAltitude: getVerticalDistance(formData.ceilingAltitude.value, numericFeet),
    points: setZonePointsToClockwise(featureGeometry as TSelectionZoneGeometry),
  });

  const onAddFeatureSuccess = (addedFeature, mutationAction) => {
    const successMessage = spatialFeatureMutationMessages[mutationAction][toCamelCase(featureType)];
    displaySuccess({ message: successMessage });
    const newSpatialFeature: ISpatialFeature = {
      ...addedFeature,
      featureId: addedFeature.id,
      featureType: featureType,
      createTime: new Date().toISOString(),
    };
    newFeatureAdded(newSpatialFeature, settingsDispatcher);
    onFeatureAdded(newSpatialFeature, featureType);
  };

  const onError = error => {
    console.error(error);
  };

  const formatFeatureData = (): TSpatialFeature => {
    switch (featureType) {
      case FEATURE_TYPES.GATE:
        return formatNewGate() as IGate;
      case FEATURE_TYPES.CORRIDOR:
        return formatNewCorridor() as INoiseAbatementCorridor;
      case FEATURE_TYPES.SELECTION_ZONE:
        return formatNewZone() as ISelectionZone;
    }
  };

  const spatialFeatureMutations = useSpatialFeatureMutations(onAddFeatureSuccess, onError);
  const addFeature = () => {
    const featureData = formatFeatureData();
    mutateSpatialFeature(featureType, featureData, spatialFeatureMutations, GQL_MUTATION_TYPES.ADD);
  };

  // update form object
  const onFormUpdate = (formData: ISpatialFeatureForm) => {
    // Update feature type if changed - changes fields in form
    updateFeatureType(formData.type.value);
    updateFormData(formData);
  };

  const setFormValidity = (isValid: boolean): void => {
    updateIsSaveDisabled(!isValid);
  };

  useEffect(() => {
    if (!!featureType) {
      // create feature object when type is selected
      switch (featureType) {
        case FEATURE_TYPES.GATE:
        case FEATURE_TYPES.SELECTION_ZONE:
          updateFormData({
            ...formData,
            floorAltitude: {
              label: formStrings.floor,
              value: getVerticalDistance(FLOOR_DEFAULT_FEET, numericDistanceUnits) as string,
              inputType: TEXT_INPUT,
              required: true,
              sideLabelEditMode: verticalDistance,
              validations: {
                isNumber: true,
                decimalPlaces: 0,
              },
              readOnly: false,
              className: 'spatial-feature__form-field',
            },
            ceilingAltitude: {
              label: formStrings.ceiling,
              value: getVerticalDistance(CEILING_DEFAULT_FEET, numericDistanceUnits) as string,
              inputType: TEXT_INPUT,
              readOnly: false,
              required: true,
              sideLabelEditMode: verticalDistance,
              validations: {
                isNumber: true,
                decimalPlaces: 0,
              },
              className: 'spatial-feature__form-field',
            },
          });
          break;
        case FEATURE_TYPES.CORRIDOR:
          const updatedFormData: ISpatialFeatureForm = Object.assign({}, formData);
          delete updatedFormData.ceilingAltitude;
          delete updatedFormData.floorAltitude;
          updateFormData(updatedFormData);
          break;
      }
    }
  }, [featureType]);

  const updateGeometry = (geometry: TSpatialFeatureGeometry, isGeometryValid: boolean) => {
    setIsGeometryValid(isGeometryValid);
    updateFeatureGeometry(geometry);
  };

  return (
    <>
      <div className="settings__split-content">
        <div className="spatial-features__heading">
          <PageHeader title={newFeatureString} />
        </div>
        <div className={'spatial-feature__detail'}>
          <span className={'spatial-feature__title'}>{detailsString}</span>
          <Card className="spatial-feature__card">
            <FormWrapper
              formData={formData}
              setFormValidity={setFormValidity}
              onUpdate={onFormUpdate}
              formLogicValidation={validateFeature}
              validationParams={{ units: verticalDistance, selector: spatialFeaturesSelector }}
            />
            <OperationsFilter />
          </Card>
          <div className="spatial-feature__footer">
            <Button onClick={cancel}>{cancelString}</Button>
            <div className="footer__action-buttons">
              <Button
                onClick={addFeature}
                className={'footer__button'}
                disabled={isSaveDisabled || !isGeometryValid}
                style={'primary'}>
                {saveString}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="settings__split-map">
        <SpatialFeaturesMap
          key={featureType}
          featureId={-1}
          editable
          featureType={featureType}
          onGeometryUpdate={updateGeometry}
        />
      </div>
    </>
  );
};
