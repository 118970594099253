import { createContext } from 'react';
import { MapLayerMouseEvent, MapProps } from 'react-map-gl';
import { MAP_ACCESS_TOKEN, MAP_STYLE_URL } from '../Map.consts';
import { Cursor, MapAction } from '../map.types';

export const DEFAULT_MIN_ZOOM = 7;
export const DEFAULT_MAX_ZOOM = 19;
export const DEFAULT_ZOOM = Math.floor(
  DEFAULT_MIN_ZOOM + (DEFAULT_MAX_ZOOM - DEFAULT_MIN_ZOOM) / 2
);

export const DEFAULT_MAP_STATE = {
  mapProps: {
    zoom: DEFAULT_ZOOM,
    longitude: 0,
    latitude: 0,
    bearing: 0,
    pitch: 0,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    minZoom: DEFAULT_MIN_ZOOM,
    maxZoom: DEFAULT_MAX_ZOOM,
    mapboxAccessToken: MAP_ACCESS_TOKEN,
    dragRotate: false,
    id: 'map',
    interactiveLayerIds: [],
    onClick: (_event: MapLayerMouseEvent) => {},
  } as MapProps,
  onClickFunctions: [],
  mapInstance: '',
  cursorOverride: null as Cursor | null,
  mapStyle: MAP_STYLE_URL,
};

const defaultDispatch: React.Dispatch<MapAction> = () => {};

export const Context = createContext({
  ...DEFAULT_MAP_STATE,
  dispatch: defaultDispatch,
});
Context.displayName = 'MapContext';
