/**
 * Constructs an enumeration with values equal to their keys + a section prefix.
 *
 * obj is in the form: {BLUE: null, RED: null}
 * section is the prefix that will be prepended to the key to form the value.
 *
 * For example:
 *
 *   var COLORS = keyToActionTypes({BLUE: null, RED: null}, 'COLOR');
 *   would create {BLUE: 'COLOR_BLUE', RED: 'COLOR_RED'}.
 *
 */

export const keyToActionTypes = (obj: object, section: string): { [key: string]: string } => {
  const ret: { [key: string]: string } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      ret[key] = `${section}_${key}`;
    }
  }
  return ret;
};
