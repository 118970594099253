import React, { FC } from 'react';
// components
import { TextInput } from '@ems/client-design-system';
import { ITextField } from '../interfaces';

export const TextField: FC<ITextField> = ({ label, value, setData, name, validationString, maxValue, postfix }) => {
  const onChange = event => {
    const inputKey = name;
    const inputValue = event.target.value;
    const validation = new RegExp(validationString);
    if (inputValue === '' || validation.test(inputValue)) {
      const obj = {};
      obj[inputKey] = inputValue === '' ? '' : inputValue;
      setData(obj);
    }
  };

  return (
    <div className='config-element'>
      <p className='config-element__label'>{label}</p>
      <div className={'config-element__field'}>
        <TextInput className='config-element__input' value={value} onChange={onChange} state={parseFloat(value) <= maxValue ? 'default' : 'error'} />
        {postfix && <p className={'config-element__postfix'}>{postfix}</p>}
      </div>
    </div>
  );
};
