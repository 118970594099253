import React from 'react';

// Types
import { TUpdateUrl } from 'src/app/props';

// Components
import { AMPageHeader, AMStatsHeader, AMTableFilters, AMTable } from './components';

// Hooks
import { useAircraftMappings } from './providers/aircraftMappings';
import { useBuildTableData } from './hooks';

export const AircraftMappingsContainer = ({
  updateUrl,
}: {
  updateUrl: TUpdateUrl;
}): JSX.Element => {
  // State
  const {
    state: {
      data: { edges },
      invalidRows,
    },
  } = useAircraftMappings();
  const tableData = useBuildTableData(edges, invalidRows);

  return (
    <>
      <div className="settings__full">
        <div className="aircraft-assignments-settings__container">
          <AMPageHeader updateUrl={updateUrl} />
          <AMStatsHeader />
          <AMTableFilters tableData={tableData} />
          <AMTable tableData={tableData} updateUrl={updateUrl} />
        </div>
      </div>
    </>
  );
};
