import React, { SyntheticEvent, useState } from 'react';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { Popover, Button, DateRangePicker, Icons } from '@ems/client-design-system';
// functions
import { useDateRangeFilter, predefinedDateRangeList } from 'src/app/functions/dateRange';
import { format, getDatePickerRange } from 'src/utils/dateTimeConverters';
import { LocaleCalendar } from 'src/utils/localeCalendar';
import { AIRTRAK, DATE_TIME_FORMAT } from 'src/constants';
import { useWindowDimensions } from 'src/utils';

export type TFilterLoadRange = 'day' | 'week' | 'month' | 'year';

export const DateFilter = ({
  updateUrl,
  moduleName,
  scenario,
  defaultRange = 'day',
  displayShortDate = false,
}: {
  updateUrl: any;
  moduleName: string;
  scenario: any;
  defaultRange: TFilterLoadRange;
  displayShortDate: boolean;
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { languageTag, firstDayOfWeek },
    operations: {
      filter: { dateFormat },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { dateRangeList, monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();
  // By supplying LocaleUtils to Blueprint DatePicker as a prop, you can customise how dates are rendered
  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );
  // calendar's from and to dates
  const updateQueryString = true;
  const { from, to } = useDateRangeFilter(updateQueryString, false, defaultRange);
  // dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState(false);
  // on interaction with datepicker's popover
  const popOverInteraction = (
    nextOpenState: boolean,
    event?: SyntheticEvent<HTMLElement>
  ): void => {
    if (typeof event !== 'undefined' && event.type === 'mousedown') {
      // mouse clicked outside the Popover
      toggleDialog(false);
    }
  };
  // datepicker's button's title
  const buttonTitle = useButtonTitle(from, to, dateFormat, languageTag, displayShortDate);
  // datepicker's button clicked
  const buttonOnClick = () => {
    toggleDialog(!isDialogOpen);
  };
  // when datepicker's date-values changed
  const dateChanged = (selectedDates: any) => {
    // Upon selection of the TO date, the calendar control will hide and the corresponding data in the table will be loaded
    if (selectedDates.length === 2 && selectedDates[0] && selectedDates[1]) {
      toggleDialog(false);
      setTimeout(() => {
        updateUrl({
          from: selectedDates[0], // from Date
          to: selectedDates[1], // to Date
        });
      }, 0);
    }
  };

  const clamp = (value: Date, min: Date, max: Date) => Math.min(Math.max(+value, +min), +max);
  const maxDateRange = getDatePickerRange('max');
  const minDateRange = getDatePickerRange('min');

  const isAirTrakScenario = moduleName === AIRTRAK && scenario !== null;
  const maxDate = isAirTrakScenario
    ? new Date(clamp(new Date(scenario.endTime), minDateRange, maxDateRange))
    : maxDateRange;
  const minDate = isAirTrakScenario
    ? new Date(clamp(new Date(scenario.startTime), minDateRange, maxDateRange))
    : minDateRange;

  return (
    <Popover
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus
      isOpen={isDialogOpen}
      onClose={() => toggleDialog(false)}
      onInteraction={popOverInteraction}>
      <Button
        style="subtle"
        leftIcon={<Icons iconName={`ic-ui-calendar`} size="24" />}
        aria-label={`Calendar ${buttonTitle}`}
        onClick={buttonOnClick}>
        {buttonTitle}
      </Button>
      <DateRangePicker
        locale={languageTag}
        localeUtils={localeCalendar.localeUtils()}
        shortcuts={moduleName === AIRTRAK ? false : predefinedDateRangeList(dateRangeList)}
        defaultValue={[from, to]}
        onChange={dateChanged}
        contiguousCalendarMonths={moduleName === AIRTRAK ? false : true}
        maxDate={maxDate}
        minDate={minDate}
        allowSingleDayRange={moduleName === AIRTRAK ? true : false}
      />
    </Popover>
  );
};

const MIN_BREAKPOINT = 1280;
const MAX_BREAKPOINT = 1350;
// Hook for a responsive button title
const useButtonTitle = (
  from: Date,
  to: Date,
  dateFormat: string,
  languageTag,
  displayShortDate = false
) => {
  const { width } = useWindowDimensions();
  const configSelectors = useConfigSelectors();
  const isPortraitMode = configSelectors.getIfPortraitLayout();
  const fromDateString = format(from, dateFormat);
  const toDateString = format(to, dateFormat);
  // displays a shorter date format for the date picker in landscape between breakpoints
  const displayedFormat =
    !isPortraitMode && width > MIN_BREAKPOINT && width < MAX_BREAKPOINT && displayShortDate
      ? DATE_TIME_FORMAT.SHORT_DATE_DISPLAY
      : dateFormat;
  return fromDateString === toDateString
    ? `${format(from, dateFormat, languageTag)}`
    : `${format(from, displayedFormat, languageTag)} - ${format(to, displayedFormat, languageTag)}`;
};
