import { ITrackDensityData } from './interfaces';

const TRACK_DENSITY_CACHE_ID = 'trackDensityCache';
const TRACK_DENSITY_CACHE_TTL_MS = 24 * 7 * 86400 * 1000;

const createTrackDensityCacheItem = (trackDensityData: ITrackDensityData) => {
  return {
    trackDensityData,
    creationTime: new Date().getTime(),
  };
};

const getTrackDensityCacheData = () => {
  const cacheJSON = localStorage.getItem(TRACK_DENSITY_CACHE_ID);
  if (cacheJSON) {
    try {
      const parsedCacheData = JSON.parse(cacheJSON);
      const currentEpochTime = new Date().getTime();
      const cleanedCacheData = {};
      const keys = Object.keys(parsedCacheData);
      keys.forEach(key => {
        if (currentEpochTime - parsedCacheData[key].creationTime < TRACK_DENSITY_CACHE_TTL_MS) {
          cleanedCacheData[key] = parsedCacheData[key];
        }
      });
      return cleanedCacheData;
    } catch (err) {
      console.error(err);
    }
  }
  return {};
};

export const setTrackDensityCacheItem = (
  startCursor: string,
  trackDensityData: ITrackDensityData
) => {
  if (!startCursor.length) {
    console.error('startCursor is', startCursor);
  }
  const cacheData = getTrackDensityCacheData();
  const cacheKey = getTrackDensityCacheKey(startCursor, trackDensityData.size);
  cacheData[cacheKey] = createTrackDensityCacheItem(trackDensityData);
  localStorage.setItem(TRACK_DENSITY_CACHE_ID, JSON.stringify(cacheData));
};

export const getTrackDensityCacheItem = (startCursor: string) => {
  if (!startCursor.length) {
    console.error('startCursor is', startCursor);
  }
  const cacheData = getTrackDensityCacheData();
  return cacheData[startCursor];
};

export const getTrackDensityCacheKey = (startCursor, trackDensitySize) => {
  const sizeSuffix = parseInt(trackDensitySize.replace(/[^\d]+/g, ''), 10);
  if (sizeSuffix !== 60 && sizeSuffix !== 150) {
    console.error('Invalid track density size', trackDensitySize);
  }
  return startCursor + sizeSuffix;
};
