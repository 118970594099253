import { ICarbonEmissionSettingsPermissions } from 'src/@settings/interfaces';
import { IRolesSelectors } from 'src/app/props';
import { PERMISSIONS, CARBON_EMISSION_SETTINGS_RULE_TYPES } from 'src/constants';

// Checks if user has read/update/insert/delete permissions to view carbon emission settings
export const getCarbonEmissionsSettingsPermissions = (
  roleSelector: IRolesSelectors
): ICarbonEmissionSettingsPermissions => {
  return Object.values(CARBON_EMISSION_SETTINGS_RULE_TYPES).reduce(
    (acc, ruleType) => ({
      ...acc,
      [ruleType]: {
        [PERMISSIONS.READ]: roleSelector.hasPermission(`${ruleType}.${PERMISSIONS.READ}`),
        [PERMISSIONS.INSERT]: roleSelector.hasPermission(`${ruleType}.${PERMISSIONS.INSERT}`),
        [PERMISSIONS.UPDATE]: roleSelector.hasPermission(`${ruleType}.${PERMISSIONS.UPDATE}`),
        [PERMISSIONS.DELETE]: roleSelector.hasPermission(`${ruleType}.${PERMISSIONS.DELETE}`),
      },
    }),
    {}
  ) as ICarbonEmissionSettingsPermissions;
};
