import { useIsColorByAltitudeEnabled } from 'src/utils/mapHelpers/hooks/useIsColorByAltitudeEnabled';
import { useIsDynamicTileServer } from 'src/utils/mapHelpers/hooks/useIsDynamicTileServer';
import { useDataSelectors } from '../reducers';
import { useEffect, useState } from 'react';
import { useConfigSelectors } from 'src/app/reducers';
import { useHaveTilesLoaded, removeMapSourcesByPrefix } from 'src/utils';
import { useTracksOnTiles } from 'src/utils/mapHelpers';
import { fetchTrackTiles } from 'src/app/functions/map';
import { mapboxStyleDynamicSource, mapboxStyleAltitudeBands } from 'src/utils/mapStyles';
import { useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';

export const useTileLayers = (
  mapApis,
  mapStyle: string,
  lockedMapTrackStyle: string,
  isUsingTracks: boolean
) => {
  const isDynamicTileServer = useIsDynamicTileServer();
  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 1000);
  const isColorByAltitudeEnabled = useIsColorByAltitudeEnabled();

  const isColorByAltitudeSelected = lockedMapTrackStyle === 'altitude' && isColorByAltitudeEnabled;

  const dataSelectors = useDataSelectors();
  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);

  const { pageInfo, totalCount: operationCount, isLoading } = dataSelectors.getDataInformation();

  const configSelectors = useConfigSelectors();
  const altitudeColorBands = configSelectors.getAltitudeColorBands('operations');
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const paintStyle = isColorByAltitudeSelected
    ? mapboxStyleAltitudeBands(altitudeColorBands.colors, operationCount)
    : mapboxStyleDynamicSource(selectedTrackTheme, operationCount);

  const { areTilesLoaded, setAreTilesLoaded } = useHaveTilesLoaded({
    mapApis,
    isLoading,
    totalCount: operationCount,
    layerPrefixesToCheck: ['trackLayer_', 'track_'],
  });

  const areTracksPainted = useTracksOnTiles({
    mapApis,
    trackPaths: tileLayers,
    trackVisibility: isUsingTracks,
    paintStyle,
    rerunHook,
  });

  useEffect(() => {
    if (pageInfo && isDynamicTileServer) {
      if (pageInfo.startCursor) {
        setMapApiStartCursor(pageInfo.startCursor);
      }
    }
  }, [pageInfo]);

  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles({
        startCursor: mapApiStartCursor,
        altitudeBands: isColorByAltitudeSelected ? altitudeColorBands.bands : null,
      });
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor, lockedMapTrackStyle]);

  useEffect(() => {
    if (isLoading === true) {
      setAreTilesLoaded(false);
      // When we start a data fetch, clear the map data to give users a better state experience
      removeMapSourcesByPrefix(mapApis, 'trackLayer_');
    }
  }, [isLoading]);

  return { mapApiStartCursor, areTracksPainted, areTilesLoaded };
};
