import React from 'react';
import cx from 'classnames';
import { InlineText, InlineDropDown, Icons } from '@ems/client-design-system';
// shared components
import { TooltipDropdown } from 'src/components';
import { NULL_VALUE } from 'src/constants';
import { getOperationTypeColor } from 'src/utils';

export const InlineEditText = ({
  id,
  value,
  validation,
  translationData,
  fieldType,
  position = 'left',
  onUpdateSelection,
  onCheckValidity,
  canUpdate,
}: {
  id: number;
  fieldType: string;
  value: string;
  validation?: {
    regexStr: string;
  };
  translationData?: {
    invalidText: string;
    placeholder: string;
  };
  position?: 'left' | 'center';
  onUpdateSelection: (id: number, value: object) => void;
  onCheckValidity: (object: { fieldType: string; isInvalid: boolean }) => void;
  canUpdate: boolean;
}) => {
  const label = value ? value : '—';
  if (!canUpdate) {
    return (
      <div
        className={cx('inline-value', {
          'u-flex-center': position === 'center',
        })}>
        {label}
      </div>
    );
  }

  const updateSelection = (updatedValue: string) => {
    if (updatedValue !== value) {
      onUpdateSelection(id, { [fieldType]: updatedValue });
    }
  };

  const updateValidation = (isInvalid: boolean) => {
    onCheckValidity({ fieldType, isInvalid });
  };

  const TextField = (
    <InlineText
      value={value}
      updateSelection={updateSelection}
      validation={validation}
      checkValidity={updateValidation}
      translationData={translationData}
    />
  );

  return (
    <div
      className={cx('', {
        'u-flex-center': position === 'center',
      })}>
      {TextField}
    </div>
  );
};

export const InlineEditDropDown = ({
  id,
  operation,
  fieldType,
  position,
  labelName,
  translationData,
  options,
  onUpdateSelection,
  canUpdate,
}: {
  id: number;
  operation: any;
  fieldType: string;
  position: 'left' | 'center';
  labelName: string | null;
  translationData: any;
  options: any;
  onUpdateSelection: any;
  canUpdate: boolean;
}) => {
  if (!options) {
    return;
  }
  const label = labelName ? labelName : '—';
  if (!canUpdate) {
    return position === 'center' ? <div className="u-flex-center">{label}</div> : label;
  }

  const updateSelection = selection => {
    const key = selection.key === NULL_VALUE ? null : selection.key;
    onUpdateSelection(id, { [fieldType]: key });
  };

  const fieldOptions: any[] = [];
  options.map((key: string) => {
    const NullValue = { key: NULL_VALUE, label: translationData.NullValue };

    const option = {
      key,
      label: translationData && translationData[key] ? translationData[key] : key,
    };

    if (key === NULL_VALUE) {
      fieldOptions.push(NullValue);
    } else {
      fieldOptions.push(option);
    }
  });

  const selectedItem = {
    key: `${operation.tableId}-${fieldType}`,
    label: translationData && translationData[label] ? translationData[label] : label,
  };

  const Dropdown = (
    <InlineDropDown
      searchItems={fieldOptions}
      updateSelection={updateSelection}
      selectedItem={selectedItem}
      isIconOnly={false}
      hasIcon={false}
      isNullable={false}
    />
  );

  return position === 'center' ? <div className="u-flex-center">{Dropdown}</div> : Dropdown;
};

export const InlineEditDropDownWithIcon = ({
  id,
  operation,
  fieldType,
  fieldIcon,
  fieldTooltip,
  iconPrefix,
  reduceChevronPadding,
  translationData,
  options,
  onUpdateSelection,
  canUpdate,
  selectedTrackTheme,
}: {
  id: number;
  operation: any;
  fieldType: string;
  fieldIcon: JSX.Element;
  fieldTooltip: string;
  iconPrefix: string;
  reduceChevronPadding: boolean;
  translationData: any;
  options: any;
  onUpdateSelection: any;
  canUpdate: boolean;
  selectedTrackTheme: string;
}) => {
  if (!options) {
    return;
  }
  const updateSelection = selection => {
    onUpdateSelection(id, { [fieldType]: selection.key });
  };

  const selectedItem = {
    key: `${operation.tableId}-${fieldType}`,
    label: '',
    icon: fieldIcon,
  };

  if (!canUpdate) {
    return <div className="u-flex-center no-edit">{fieldIcon}</div>;
  }

  const fieldOptions: any[] = [];
  options.map((key: string) => {
    const iconTypeName = key.toLocaleLowerCase();
    const iconColour = getOperationTypeColor(selectedTrackTheme, key);
    const option = {
      key,
      label: translationData[key],
      icon: (
        <Icons
          iconName={`ic-${iconPrefix}-${iconTypeName}`}
          size={20}
          style={
            iconPrefix === 'ad'
              ? {
                  fill: iconColour,
                  color: iconColour,
                }
              : null
          }
        />
      ),
    };
    fieldOptions.push(option);
  });

  return (
    <div className="u-flex-center">
      <TooltipDropdown
        text={translationData[fieldTooltip]}
        searchItems={fieldOptions}
        updateSelection={updateSelection}
        selectedItem={selectedItem}
        isIconOnly={reduceChevronPadding}
      />
    </div>
  );
};
