import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { ComplaintsStateProvider } from 'src/@complaints/providers/ComplaintsStateProvider';
import { ContentContainer } from 'src/@complaints/containers/addFlight';
const AddFlightScreen = ({ match, location }) => {
  const { id } = match.params;
  const { state } = location;
  // fromInquiryDetails: redirect back to complaint details after selecting/correlating flight
  const fromInquiryDetails = state !== undefined && state.hasOwnProperty('fromInquiryDetails') ? true: false;

  return (
    <SplitLayout>
      <ComplaintsStateProvider>
        <ContentContainer id={id} fromInquiryDetails={fromInquiryDetails} />
      </ComplaintsStateProvider>
    </SplitLayout>
  );
};

export const AddFlight = withRouteProps(AddFlightScreen);
