import React, { ReactNode, useMemo, useReducer } from 'react';
import { Context, DEFAULT_MAP_STATE as fallbackState } from './Context';
import { mapReducer } from './Reducer';
import { useRequiredMapProps } from './useRequiredMapProps/useRequiredMapProps';
import { MapState } from '../map.types';

interface IProps {
  children: ReactNode;
  defaultState?: MapState;
}

export const Provider = ({ children, defaultState = fallbackState }: IProps) => {
  const mapState = useMapState(defaultState);
  return <Context.Provider value={mapState}>{children}</Context.Provider>;
};

function useMapState(defaultState: MapState) {
  const [state, dispatch] = useReducer(mapReducer, defaultState);
  const requiredMapProps = useRequiredMapProps(dispatch);
  const combinedMapProps = useMemo(
    () => ({
      ...requiredMapProps,
      ...state.mapProps,
    }),
    [requiredMapProps, state.mapProps]
  );
  return useMemo(
    () => ({
      ...state,
      mapProps: combinedMapProps,
      dispatch,
    }),
    [combinedMapProps, state]
  );
}
