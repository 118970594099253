import React, { FC, useState, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
// context
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { resetAndFetchData } from 'src/@infringements/actions';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSortSelectors } from 'src/@infringements/reducers';
// components
import { SkeletonText, Overlay, Icons, Button, Dropdown } from '@ems/client-design-system';
import { PageHeader, DateFilter } from 'src/components';
// containers
import { ExportContainer } from 'src/containers/ExportContainer';
// fns
import { IHeaderContainer } from 'src/@infringements/interfaces';
import { useDataSelectors } from 'src/@infringements/reducers/dataReducer';
import { formatNumber, setTabTitle } from 'src/utils';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
import { ADD_OPERATION_TAGS, REMOVE_OPERATION_TAGS } from 'src/@infringements/mutations';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
// constants
import { INFRINGEMENTS, NULL_VALUE, VECTORED } from 'src/constants';

import { IDropdownItem } from '@ems/client-design-system';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  const client = useApolloClient();
  const dispatcher = useContext<any>(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const atcView = true;

  const [overlayState, setoverlayState] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDropdownItem>(null);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [addInfringementVectored] = useMutation(ADD_OPERATION_TAGS, {
    onCompleted() {
      resetAndFetchData(client, dispatcher, {
        resultSize,
        sortString,
        atcView,
      });
    },
  });
  const [removeInfringementVectored] = useMutation(REMOVE_OPERATION_TAGS, {
    onCompleted() {
      resetAndFetchData(client, dispatcher, {
        resultSize,
        sortString,
        atcView,
      });
    },
  });

  const configSelectors = useConfigSelectors();
  const {
    grid: { resultSize },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { update, cancel, bulkEdit },
      labels: {
        table: { totalItems },
      },
      overlay: {
        bulkEdit: {
          infringements: {
            update: overlayTitle1,
            infringement: overlayTitle2,
            infringements: overlayTitle2Plural,
          },
        },
      },
      dropdowns: {
        empty: emptyLabel,
        vectored: { label: vectoredLabel, placeholder: vectoredPlaceholder },
      },
    },
    screens: {
      infringements: { title },
    },
  } = languageSelectors.getLanguage();

  setTabTitle(title);

  const vectoredItems: any[] = [
    {
      key: NULL_VALUE,
      label: emptyLabel,
    },
    {
      key: VECTORED,
      label: vectoredLabel,
    },
  ];

  const dataSelectors = useDataSelectors();
  const { totalCount, data, selectedData: selectedIndexes } = dataSelectors.getDataInformation();
  const selectedIds = dataSelectors.getSelectedIDs(selectedIndexes);

  const DateFilterHoc = withQueryStringUpdater({ Container: DateFilter, updateUrl });

  const updateInfringements = selectedData => {
    const operationsToUpdate: number[] = [];

    selectedData.map(item => {
      operationsToUpdate.push(data[item].operationId);
    });

    if (selectedItem.key === VECTORED) {
      addInfringementVectored({
        variables: { ids: operationsToUpdate },
      });
    } else if (selectedItem.key === NULL_VALUE) {
      removeInfringementVectored({
        variables: { ids: operationsToUpdate },
      });
    }

    handleOverlayClose();
    dispatcher({ type: dataActionTypes.RESET_DATA });
  };

  const handleOverlayState = (bool: boolean) => {
    const infringementsToUpdate: any = [];

    selectedIndexes.map(item => {
      infringementsToUpdate.push(data[item].operationTags ? VECTORED : NULL_VALUE);
    });

    const statusIsTheSame = infringementsToUpdate.every((val, i, arr) => val === arr[0]);

    if (statusIsTheSame) {
      const selected = vectoredItems.filter(obj => {
        return obj.key === infringementsToUpdate[0];
      });
      setSelectedItem(selected[0]);
    }

    setoverlayState(bool);
  };

  const handleOverlayClose = () => {
    setSelectedItem(null);
    setoverlayState(false);
    setbuttonDisabled(true);
  };

  const handleUpdateState = (item: any) => {
    if (selectedItem !== undefined && selectedItem !== null && item.label !== selectedItem.label) {
      setbuttonDisabled(false);
    } else if (selectedItem === undefined || selectedItem === null) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }

    setSelectedItem(item);
  };

  // when no item selected, then action button should be disabled
  const disableBulkEditBtn = selectedIndexes.length < 1 ? true : false;

  return (
    <PageHeader title={title}>
      <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
        <span className="page-count">
          {totalCount && formatNumber(totalCount)} {totalItems}
        </span>
      </SkeletonText>
      <div className="page-tools">
        <ExportContainer
          source={INFRINGEMENTS}
          selectedIds={selectedIds}
          tableData={data.filter(item => selectedIds.includes(item.id))}
        />
        <Button
          style="primary"
          size="s"
          disabled={disableBulkEditBtn}
          onClick={() => handleOverlayState(!overlayState)}>
          {bulkEdit}
        </Button>
        <DateFilterHoc />
      </div>
      <Overlay
        openState={overlayState}
        onClose={() => handleOverlayClose()}
        classes={['overlay--bulk-edit']}>
        <div className="overlay_header">
          <h3
            style={{
              fontWeight: 'normal',
            }}>{`${overlayTitle1} ${selectedIndexes.length} ${
            selectedIndexes.length > 1 ? overlayTitle2Plural : overlayTitle2
          }`}</h3>
          <Button
            size="s"
            style="subtle"
            iconOnly={true}
            onClick={() => handleOverlayClose()}
            aria-label="Close modal"
            className="overlay_close"
            leftIcon={
              <Icons
                iconName="ic-ui-cancel"
                size={16}
                style={{ cursor: 'pointer', fill: '#5a6872' }}
              />
            }
          />
        </div>
        <div className="overlay_content">
          <Dropdown
            label={vectoredLabel}
            placeholderValue={vectoredPlaceholder}
            searchItems={vectoredItems}
            isNullable={false}
            updateSelection={item => handleUpdateState(item)}
            selectedItem={selectedItem}
          />
        </div>
        <div className="overlay_footer">
          <Button onClick={() => handleOverlayClose()} style="subtle" className="bulk-edit_cancel">
            {cancel}
          </Button>
          <Button
            style="primary"
            onClick={() => updateInfringements(selectedIndexes)}
            disabled={buttonDisabled}>
            {update}
          </Button>
        </div>
      </Overlay>
    </PageHeader>
  );
};
