import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getConvertedDistance, getConvertedVerticalDistance } from '@ems/client-design-system';
// shared components
import { TableIcon, ColumnHeader, Pill } from 'src/components';
// action
import { sortTable } from 'src/@operations/actions';
// utils
import {
  getDeployedProductId,
  tableDateTimeFormat,
  tableDateTimeFormatShort,
  InlineEditDropDown,
  InlineEditDropDownWithIcon,
  getOperationTypeColor,
  convertMapToArray,
} from 'src/utils';
// const
import { OPERATIONS, FORMATION_COUNT_EDIT_VALUES } from 'src/constants';
import { IConfigurationUnits } from 'src/app/props';

import { Icons } from '@ems/client-design-system';
import { CorrelatedPill } from '../containers/Table/CorrelatedPill';
import { IFetchedData } from '../props';
import { useAvailableOperationsTableWidth } from '../hooks/useAvailableOperationsTableWidth';

export const renderOperationTagPills = (operationTags: string[]): JSX.Element[] =>
  operationTags.map((tag: string, index: number) => (
    <Pill title={tag} icon="" type="operation-tag" key={`pill_${index}`} />
  ));

/*
 * Rule Name link
 */
const linkToOpDetails = (rule: string | null, id: any | null, ruleInfo) => (
  <Link
    className="rule_link"
    to={{
      pathname: `/${getDeployedProductId()}/${OPERATIONS}/${id}`,
      search: undefined,
      state: ruleInfo,
    }}>
    {rule}
  </Link>
);

const formatADIcon = (adFlag = '', selectedTrackTheme: string) => {
  const operationTheme = getOperationTypeColor(selectedTrackTheme, adFlag);
  return (
    <Icons
      iconName={`ic-ad-${adFlag.toLowerCase()}`}
      size={24}
      style={{
        fill: operationTheme,
        color: operationTheme,
      }}
    />
  );
};

const formatFlightIcon = (acType: string | null) => {
  if (!acType) {
    return <Icons iconName={`ic-ac-unknown`} size={24} />;
  }
  return <Icons iconName={`ic-ac-${acType.toLowerCase()}`} size={24} />;
};

export const formatRunwayField = (runway: string | null) => (runway ? runway : '—');

// All operations columns combined excluding time
const OPERATIONS_TABLE_WIDTH = 920;
const TIME_BREAKPOINT = 180;
// Displays a smaller time field when the table size is smaller
const useOperationsTimeField = () => {
  const tableWidth = useAvailableOperationsTableWidth();
  return useCallback(
    (time: string | null, twelveHourFormat = false) => {
      if (tableWidth - OPERATIONS_TABLE_WIDTH < TIME_BREAKPOINT) {
        return tableDateTimeFormatShort(time, twelveHourFormat);
      } else {
        return tableDateTimeFormat(time, twelveHourFormat);
      }
    },
    [tableWidth]
  );
};

export const addDisplayField = (
  operation,
  units: IConfigurationUnits,
  twelveHourFormat: boolean,
  selectedTrackTheme: string
) => ({
  'time-short': tableDateTimeFormatShort(operation.time, twelveHourFormat),
  adIcon: formatADIcon(operation.operationType, selectedTrackTheme),
  adType: operation.operationType,
  flightCategory: operation.aircraftCategory,
  operatorCategory: operation.operatorCategory,
  flightIcon: formatFlightIcon(operation.aircraftCategory),
  runway: formatRunwayField(operation.runwayName),
  model: operation.aircraftType,
  horizontal:
    operation.pca && operation.pca.horizontalDistance
      ? getConvertedDistance(operation.pca.horizontalDistance, units.distance, 1)
      : 0,
  diagonal:
    operation.pca && operation.pca.slantDistance
      ? getConvertedDistance(operation.pca.slantDistance, units.distance, 1)
      : 0,
  altitude:
    operation.pca && operation.pca.verticalDistance
      ? getConvertedVerticalDistance(operation.pca.verticalDistance, units.distanceVertical, 0)
      : 0,
  'pca-time':
    operation.pca && operation.pca.time
      ? tableDateTimeFormat(operation.pca.time, twelveHourFormat)
      : null,
  'pca-time-short':
    operation.pca && operation.pca.time
      ? tableDateTimeFormatShort(operation.pca.time, twelveHourFormat)
      : null,
});

export const canUpdateField = (canUpdate: boolean, readOnlyFields: string[], field: string) =>
  canUpdate && readOnlyFields.findIndex(e => e === field) === -1;

/*
 * Prepare operation for render on table
 */
export const useOperationsTableData = (
  data: any,
  translationData: any,
  filterData: any,
  onUpdateSelection: any,
  ruleInfo: any,
  canUpdate: boolean,
  units: IConfigurationUnits,
  readOnlyFields: string[],
  twelveHourFormat: boolean,
  featureFlags: {
    operatorCategory: boolean;
    operationTags: boolean;
    formationCount: boolean;
  },
  selectedTrackTheme: string
) => {
  const [dataIds, setDataIds] = useState<number[]>([]);
  const [displayData, setDisplayData] = useState<IFetchedData[]>([]);
  const getOperationTimeField = useOperationsTimeField();
  useEffect(() => {
    const mapObject = new Map();
    for (const [id, operationData] of data.entries()) {
      const operation = Object.assign({}, operationData);
      const { correlated, tableId } = operationData;
      const displayFields = addDisplayField(operation, units, twelveHourFormat, selectedTrackTheme);
      const operationsRowItem: any = {
        acid: linkToOpDetails(operation.acid, operation.id, ruleInfo),
        displayFlag: InlineEditDropDownWithIcon({
          id,
          operation,
          fieldType: 'operationType',
          fieldIcon: displayFields.adIcon,
          fieldTooltip: displayFields.adType,
          iconPrefix: 'ad',
          reduceChevronPadding: true,
          translationData,
          options: filterData.operationTypes,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'operationType'),
          selectedTrackTheme,
        }),
        displayCategory: InlineEditDropDownWithIcon({
          id,
          operation,
          fieldType: 'aircraftCategory',
          fieldIcon: displayFields.flightIcon,
          fieldTooltip: displayFields.flightCategory,
          iconPrefix: 'ac',
          reduceChevronPadding: false,
          translationData,
          options: filterData.aircraftCategories,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftCategory'),
          selectedTrackTheme,
        }),
        displayRunwayName: InlineEditDropDown({
          id,
          operation,
          fieldType: 'runwayName',
          position: 'center',
          labelName: displayFields.runway,
          translationData,
          options: filterData.runwayNames,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'runwayName'),
        }),
        correlated: <CorrelatedPill correlatedOperationData={correlated} tableId={tableId} />,
        aircraftType: InlineEditDropDown({
          id,
          operation,
          fieldType: 'aircraftType',
          position: 'left',
          labelName: displayFields.model,
          translationData,
          options: filterData.aircraftTypes,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftType'),
        }),
        airportId: InlineEditDropDown({
          id,
          operation,
          fieldType: 'airportId',
          position: 'center',
          labelName: operation.airportId,
          translationData,
          options: filterData.airportIds,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'airportId'),
        }),
      };

      // If we display formationCount, do not display the remoteAirportId column
      if (featureFlags.formationCount) {
        operationsRowItem.aircraftCount = InlineEditDropDown({
          id,
          operation,
          fieldType: 'aircraftCount',
          position: 'left',
          labelName: operationData.aircraftCount,
          translationData,
          options: FORMATION_COUNT_EDIT_VALUES,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'aircraftCount'),
        });
      } else {
        operationsRowItem.remoteAirportId = InlineEditDropDown({
          id,
          operation,
          fieldType: 'remoteAirportId',
          position: 'left',
          labelName: operation.remoteAirportId,
          translationData,
          options: filterData.remoteAirportIds,
          onUpdateSelection,
          canUpdate: canUpdateField(canUpdate, readOnlyFields, 'remoteAirportId'),
        });
      }
      if (featureFlags.operatorCategory) {
        operationsRowItem.metaData = { operatorCategory: operationData.operatorCategory };
      }
      if (featureFlags.operationTags && operationData.tags) {
        const operationTags: string[] = operationData.tags.map(tag => tag.name);
        operationsRowItem.operationTags = renderOperationTagPills(operationTags);
      }
      operationsRowItem.displayTime = getOperationTimeField(operation.time, twelveHourFormat);
      operationsRowItem[`displayTime-short`] = displayFields[`time-short`];
      operationsRowItem.displayAlt = displayFields.altitude;
      operationsRowItem.displayHoriz = displayFields.horizontal;
      operationsRowItem.displayDiag = displayFields.diagonal;
      operationsRowItem.pcaTime = displayFields['pca-time'];
      operationsRowItem[`pcaTime-short`] = displayFields[`pca-time-short`];
      mapObject.set(id, operationsRowItem);
    }
    const { keys, items } = convertMapToArray(mapObject);
    setDataIds(keys);
    setDisplayData(items);
  }, [data]);

  return { dataIds, displayData };
};

/*
 * Prepare operation header
 */
export const formatHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config
  const {
    fields: { operations: rowHeaders },
    abbreviations: {
      aircraftCategory: aircraftCategoryAbrr,
      runwayName: runwayNameAbbr,
      diagonal: diagonalAbbr,
      horizontal: horizontalAbbr,
      altitude: altitudeAbbr,
      aircraftCount: aircraftCountAbbr,
    },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const acid = ColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayFlag = ColumnHeader({
    titleIcon: TableIcon({
      name: 'arrival',
      prefix: 'ad',
      size: 24,
      className: isLoading ? 'disabled' : '',
    }),
    sortName: 'operationType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const airportId = ColumnHeader({
    sortName: 'airportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.airportId,
      abbreviation: null,
      sortBy,
    },
  });
  const remoteAirportId = ColumnHeader({
    sortName: 'remoteAirportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.remoteAirportId,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRunwayName = ColumnHeader({
    sortName: 'runwayName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRunwayName,
      abbreviation: runwayNameAbbr,
      sortBy,
    },
  });
  const displayCategory = ColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = ColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayHoriz = ColumnHeader({
    sortName: 'pcaHoriz',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayHoriz,
      abbreviation: horizontalAbbr,
      sortBy,
    },
  });
  const displayDiag = ColumnHeader({
    sortName: 'pcaDiag',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayDiag,
      abbreviation: diagonalAbbr,
      sortBy,
    },
  });
  const displayAlt = ColumnHeader({
    sortName: 'pcaHoriz',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.displayAlt,
      abbreviation: altitudeAbbr,
      sortBy,
    },
  });
  const pcaTime = ColumnHeader({
    sortName: 'pcaTime',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.pcaTime,
      abbreviation: null,
      sortBy,
    },
  });
  const correlated = ColumnHeader({
    sortName: 'correlated',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.correlated,
      abbreviation: null,
      sortBy,
    },
  });
  const operatorCategory = ColumnHeader({
    sortName: 'operatorCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.operatorCategory,
      abbreviation: null,
      sortBy,
    },
  });
  const aircraftCount = ColumnHeader({
    sortName: 'aircraftCount',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftCount,
      abbreviation: aircraftCountAbbr,
      sortBy,
    },
  });
  const operationTags = ColumnHeader({
    sortName: 'operationTags',
    sortable: false,
    sortSelectors,
    sortTable,
    resultSize,
    isLoading,
    dispatcher,
    languageData: {
      title: rowHeaders.operationTags,
      abbreviation: null,
      sortBy: null,
    },
  });

  return Object.assign({}, rowHeaders, {
    acid,
    displayFlag,
    displayTime,
    airportId,
    remoteAirportId,
    displayRunwayName,
    displayCategory,
    operatorCategory,
    aircraftCount,
    aircraftType,
    displayDiag,
    displayHoriz,
    displayAlt,
    pcaTime,
    correlated,
    operationTags,
  });
};
