import React, { useEffect } from 'react';
import { AnomsMap } from 'src/components/Map/AnomsMap/AnomsMap';
import { MapControl } from 'src/components/MapControl/MapControl';
import { useMapStateFromConfig } from 'src/components/Map/useMapStateFromConfig';
import { TaxiPathLayer } from 'src/@settings/containers/TaxiPath/map/taxi-path-layer/TaxiPathLayer';
import { TaxiPathEditControls } from './taxi-path-controls/TaxipathEditControls';
import { loadSavedGeoJSON } from '../utils';

const DEFAULT_VIEWPORT_TORONTO = {
  zoom: 14,
  latitude: 43.6797,
  longitude: -79.6227,
  mapStyle: 'mapbox://styles/mapbox/satellite-v9',
};

export const TaxiPathMap = () => {
  // If we find existing work, center on that
  useEffect(() => {
    const existingNodes = loadSavedGeoJSON<GeoJSON.Point>('taxipath-nodes');
    if (
      existingNodes &&
      existingNodes.features[0] &&
      existingNodes.features[0].geometry &&
      existingNodes.features[0].geometry.coordinates
    ) {
      const { coordinates } = existingNodes.features[0].geometry;
      DEFAULT_VIEWPORT_TORONTO.latitude = coordinates[1];
      DEFAULT_VIEWPORT_TORONTO.longitude = coordinates[0];
    }
  }, []);

  useMapStateFromConfig(DEFAULT_VIEWPORT_TORONTO);

  return (
    <AnomsMap>
      <MapControl resetView mapSettings showCompass customMapProps={DEFAULT_VIEWPORT_TORONTO} />
      <TaxiPathEditControls />
      <TaxiPathLayer />
    </AnomsMap>
  );
};
