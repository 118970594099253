class RequestCanceller {
  requestCanceller: AbortController;

  constructor() {
    this.requestCanceller = new AbortController();
  }

  init() {
    this.requestCanceller.abort();
    this.requestCanceller = new AbortController();
  }

  getSignal() {
    return this.requestCanceller.signal;
  }
}

export const requestCanceller = new RequestCanceller();
