import React, { ReactElement, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Card, BlockHeader, Icons } from '@ems/client-design-system';
import { useLanguageSelectors } from 'src/app/reducers';
import { PageHeader } from 'src/components';
import { fetchUserHelp } from 'src/@userHelp/resolvers/dataResolver';
import { ProductVersion } from 'src/components/ProductVersion';
import { setTabTitle } from 'src/utils';

export const ContentContainer = () => {
  const client = useApolloClient();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      userHelp: { title },
    },
  } = languageSelectors.getLanguage();

  setTabTitle(title);

  const [helpData, setHelpData] = useState<any>();

  useEffect(() => {
    fetchUserHelp(client).then(data => {
      setHelpData(data);
    });
  }, []);

  if (!helpData) {
    return (
      <>
        <PageHeader title={title} />
      </>
    );
  }

  // Filter to prevent push notification file from appearing in user help list.
  const links = helpData.data.userHelpLinks.filter(link => link.path !== 'Notifications');

  const uniquePaths = links
    .filter((val, idx, self) => self.findIndex(e => e.path === val.path) === idx)
    .map(e => e.path)
    .sort((a, b) => {
      if (a === '') {
        return 1;
      }

      if (b === '') {
        return -1;
      }

      return a < b ? -1 : b < a ? 1 : 0;
    });

  const defaultIcon = <Icons iconName="ic-ac-unknown" size="24" className="link-icon" />;
  const videoIcon = <Icons iconName="ic-ui-play" size="24" className="link-icon" />;
  const pdfIcon = <Icons iconName="ic-ui-report" fill="#de3617" size="24" className="link-icon" />;

  const helpCards: ReactElement[] = [];
  uniquePaths.map(path => {
    const helpLinks = links.filter(e => e.path === path);
    const linkElements: ReactElement[] = [];

    helpLinks.map(link => {
      const lcFileType = link.fileType.toLowerCase();
      const icon =
        {
          pdf: pdfIcon,
          mp4: videoIcon,
        }[lcFileType] || defaultIcon;

      linkElements.push(
        <div key={`icon-link-${link.name}`} className="help-link">
          {icon}
          <a
            className="subtle-link"
            href={link.downloadResource.uri}
            target="_blank"
            key={link.name}
            rel="noreferrer">
            {link.name}
          </a>
        </div>
      );
    });

    helpCards.push(
      <Card key={path} className="link-card">
        <div className="card__padder">
          <BlockHeader title={path ? path : 'Other'} />
          <div className="link-container">{linkElements}</div>
        </div>
      </Card>
    );
  });

  return (
    <>
      <PageHeader title={title} />

      <div className="help-layout">
        <div className="help-cards">{helpCards}</div>
        <ProductVersion />
      </div>
    </>
  );
};
