import React from 'react';
import { Icons } from '@ems/client-design-system';
// constants
import { NULL_VALUE } from 'src/constants';

export const createSelectItems = (
  object: any,
  type: any,
  isNullable: boolean,
  translatedList: any
) => {
  const newArray: any = [];

  object.map(item => {
    let newLabel = item;

    newLabel = translatedList[item] ? translatedList[item] : item;

    const newItem = { label: newLabel, key: item };
    if (type === 'aircraftCategory') {
      Object.assign(newItem, {
        icon: <Icons iconName={`ic-ac-${item.toLowerCase()}`} size={24} />,
      });
    } else if (type === 'operationType') {
      Object.assign(newItem, {
        icon: (
          <Icons
            iconName={`ic-ad-${item.toLowerCase()}`}
            className={`ad-${item.toLowerCase()}`}
            size={24}
          />
        ),
      });
    }

    newArray.push(newItem);
  });

  if (isNullable) {
    const NullValueLabel = NULL_VALUE;
    const NullValue = { key: '', label: translatedList[NullValueLabel], icon: undefined };

    return [NullValue, ...newArray];
  }

  return newArray;
};
