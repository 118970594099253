import styled from 'styled-components/macro';

export const ViewScenarioTotalsWrapper = styled.div<{ tableWidth: number; columnWidths: number[] }>`
  position: fixed;
  bottom: 0;
  border-top: 1px solid black;
  background: white;
  display: grid;
  border-collapse: collapse;
  width: ${props => props.tableWidth}px;
  grid-template-columns:
    [selector] ${props => props.columnWidths[0] || 100}px
    [aircraftType] ${props => props.columnWidths[1] || 100}px
    [stage] ${props => props.columnWidths[2] || 100}px
    [route] ${props => props.columnWidths[3] || 100}px
    [runway] ${props => props.columnWidths[4] || 100}px
    [operation] ${props => props.columnWidths[5] || 100}px
    [day] ${props => props.columnWidths[6] || 100}px
    [evening] ${props => props.columnWidths[7] || 100}px
    [night] ${props => props.columnWidths[8] || 100}px
    [spacer] ${props => props.columnWidths[9] || 100}px;
`;

export const ViewScenarioTotalsCell = styled.span<{ isNumber?: boolean }>`
  height: 3rem;
  display: flex;
  justify-content: ${({ isNumber = false }) => (isNumber ? 'flex-end' : 'flex-start')};
  align-items: center;
  line-height: 1.2;
  font-weight: 600;
  font-size: 14px;
  padding: 0 16pt;
`;
