import { InteractiveLayerId } from '../hooks/useInteractiveLayerIds';

export const getQueriedFeaturesById = (
  target,
  point: mapboxgl.Point,
  layerId: InteractiveLayerId
) => {
  const features = target.queryRenderedFeatures(point);
  if (features) {
    return features.filter(({ layer }) => layer.id === layerId);
  }
  return [];
};
