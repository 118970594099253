import React from 'react';

// Components
import { Card } from '@ems/client-design-system';

// Styles
import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

export const OperationDetailsCard = styled(Card)`
  padding: ${styles.spacingMd} ${styles.spacingLg};

  .block-header {
    padding: 0;
  }

  .description-list {
    padding: 0;
  }

  .card-header {
    padding: 0;
  }
`;

export const OperationCard = ({ children }: { children: JSX.Element }) => (
    <div className="block operation-details">
      <OperationDetailsCard>{children}</OperationDetailsCard>
    </div>
  );
