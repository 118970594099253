import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

export const ProfileGraphButton = styled.div`
  display: block;
  position: absolute;
  right: 5px;
  bottom: 48px;
  z-index: 3;

  button.bp3-button.bp3-intent-primary.track-profiles-btn.mosaic-button.compact {
    border-radius: 100px;
    font-size: 11px;
    background: ${styles.brandDarkened};
  }
`;
