import React, { FC } from 'react';
import { FieldDetails, Card } from '@ems/client-design-system';
// functions
import { convertAddressToString, getComplainerDisplayName } from 'src/@complaints/functions';
// constants
import {
  EMAIL,
  PRIMARY_CONTACT,
  NOT_PREFERRED_CONTACT,
  SECONDARY_CONTACT,
  MAIL,
} from 'src/constants';
// selectors
import { useLanguageSelectors } from 'src/app/reducers';
// interfaces
import { IViewComplainer } from 'src/@complaints/interfaces';

export const ComplainerDetails: FC<IViewComplainer> = ({ columns, data }) => {
  const {
    title,
    lastName,
    firstName,
    email,
    preferredResponseMethod,
    phoneNumbers = [],
    address = {},
  } = data;
  // Translation
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      lists: { preferredResponseMethodList },
      labels: {
        anonymous: anonymousText
      },
    },
  } = languageSelector.getLanguage();

  const getPreferredContactLabel = (key: string) => {
    switch (key) {
      case NOT_PREFERRED_CONTACT:
        return preferredResponseMethodList.notSet === '-' ? '' : preferredResponseMethodList.notSet;
      case PRIMARY_CONTACT:
        return preferredResponseMethodList.primaryContact;
      case EMAIL:
        return preferredResponseMethodList.email;
      case MAIL:
        return preferredResponseMethodList.mail;
      case SECONDARY_CONTACT:
        return preferredResponseMethodList.secondaryContact;
      default:
        return '';
    }
  };
  return (
    <Card className="inquirer-view-card">
      <div className="row">
        <FieldDetails
          fieldType="inputField"
          className="col-md-2"
          label={columns.title}
          text={title}
        />
        <FieldDetails
          fieldType="inputField"
          className="col-md-3"
          label={columns.firstName}
          text={getComplainerDisplayName(firstName, anonymousText)}
        />
        <FieldDetails
          fieldType="inputField"
          className="col-md-3"
          label={columns.lastName}
          text={getComplainerDisplayName(lastName, anonymousText)}
        />
      </div>
      <FieldDetails
        fieldType="inputField"
        label={columns.address}
        text={convertAddressToString(address)}
      />
      {phoneNumbers.map((phoneNumber, index) => (
        <FieldDetails
          key={index}
          fieldType="inputField"
          label={columns[`phone${index + 1}`]}
          text={phoneNumber.number}
        />
      ))}
      <FieldDetails fieldType="inputField" label={columns.email} text={email} />
      <FieldDetails
        fieldType="inputField"
        label={columns.preferredResponseMethod}
        text={getPreferredContactLabel(preferredResponseMethod)}
      />
    </Card>
  );
};
