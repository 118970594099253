import { dispatcher } from 'src/utils/dispatcher';
// ts
import { IFilterItem } from 'src/@operations/props';
import { TSource } from 'src/app/props';
// actions
import { updateTimeFilterValueAction, updateTimeFilterInputAction } from 'src/@operations/actions';
import { actionTypes } from 'src/@operations/newActionTypes';

export const updateSelectedItems = (
  category: string,
  selectedItems: IFilterItem[],
  newDispatcher: any
): void => {
  newDispatcher({
    type: actionTypes.UPDATE_SELECTED_ITEMS,
    data: { category, selectedItems },
  });
};

export const clearSelectedItems = (newDispatcher: any): void => {
  newDispatcher({ type: actionTypes.CLEAR_SELECTED_ITEMS });
};

export const updateACIDFilter = (
  acid: string | null,
  dispatcher: any
) => {
  dispatcher({ type: actionTypes.UPDATE_ACID_FILTER, data: { acid } });
};

export const updateRangeFilter = (
  type: string,
  value: string,
  field: TSource,
  newDispatcher: any
) => {
  newDispatcher({ type: actionTypes.UPDATE_RANGE_FILTER, data: { type, value, field } });
};

export const updateTimeFilterInput = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterInputAction({
      time,
      fromOrTo,
    })
  );
};

export const updateTimeFilterValue = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterValueAction({
      time,
      fromOrTo,
    })
  );
};

export const initialiseFilterStore = (newDispatcher: any, initialFilters: object) => {
  newDispatcher({ type: actionTypes.INITIALISE_STORE, data: { initialFilters } });
};

export const setToPcaFilter = (
  dispatcher: any,
  latitude: number,
  longitude: number,
  elevation: number | null,
  location: string
) => {
  dispatcher({
    type: actionTypes.SET_TO_PCA_FILTER,
    data: { latitude, longitude, elevation, location },
  });
};

export const clearPcaFilter = (dispatcher: any) => {
  dispatcher({ type: actionTypes.CLEAR_PCA_FILTER });
};
