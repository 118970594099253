import React, { useContext, useState, MutableRefObject, useLayoutEffect } from 'react';

// Components
import { Button } from '@ems/client-design-system';
import { ViewScenarioTotalsWrapper, ViewScenarioTotalsCell } from './RecordTotals.styles';

// Contexts
import {
  dialogsStatusContext,
  noiseScenarioRecordsContext,
} from 'src/@scenarioGeneration/containers/ViewScenario/contexts';
import { viewScenarioTranslations } from '../../helpers';

export const RecordTotals = ({
  tableReference,
  isLoading,
}: {
  tableReference: MutableRefObject<HTMLDivElement>;
  isLoading: boolean;
}) => {
  const { setIsAddRecordOpen } = useContext(dialogsStatusContext);
  const { noiseScenarioTotals, noiseScenarioRecords } = useContext(noiseScenarioRecordsContext);
  const translations = viewScenarioTranslations();
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(2000);

  useLayoutEffect(() => {
    if (tableReference.current && !isLoading) {
      // Slight timeout to ensure we get values after table render
      setTimeout(() => {
        setTableWidth(tableReference.current.querySelector('table').getBoundingClientRect().width);
        const nodeList = [];
        tableReference.current.querySelectorAll('th').forEach(node => {
          nodeList.push(node.getBoundingClientRect().width);
        });
        setColumnWidths(nodeList);
      }, 100);
    }
  }, [isLoading, noiseScenarioRecords]);

  return (
    <ViewScenarioTotalsWrapper tableWidth={tableWidth} columnWidths={columnWidths}>
      <span>&nbsp;</span>
      <ViewScenarioTotalsCell>
        <span className="table_cell--item">
          <Button
            style="primary"
            onClick={() => {
              setIsAddRecordOpen(true);
            }}>
            {translations.buttons.addRecordButton}
          </Button>
        </span>
      </ViewScenarioTotalsCell>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <ViewScenarioTotalsCell>{translations.labels.totals}</ViewScenarioTotalsCell>
      <ViewScenarioTotalsCell isNumber>
        {`${noiseScenarioTotals.day.toLocaleString()}`}
      </ViewScenarioTotalsCell>
      <ViewScenarioTotalsCell
        isNumber>{`${noiseScenarioTotals.evening.toLocaleString()}`}</ViewScenarioTotalsCell>
      <ViewScenarioTotalsCell
        isNumber>{`${noiseScenarioTotals.night.toLocaleString()}`}</ViewScenarioTotalsCell>
      <span>&nbsp;</span>
    </ViewScenarioTotalsWrapper>
  );
};
