import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { ComplaintsPopup, MapSelectionArea, OperationMapTags } from 'src/components';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useDataSelectors } from 'src/@complaints/reducers';
// functions
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useGeocoderPinAlternative } from 'src/utils/mapHelpers/mapHelpers';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  fitPointsInMap,
  useHoverOnMapElement,
  useHoveredPointData,
  useClickOnMapElement,
  drawMapPcaPoints,
  getTrackPcas,
  useMapRuler,
} from 'src/utils';
import { useGeocodePosition } from 'src/utils/geocoding';
import { useStaticTrackDisplay } from 'src/utils/playback';
// interfaces
import { ISelectMonitorCoords, ISelectAddress } from 'src/@complaints/interfaces';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { Position } from 'common-logic';
import { Projection } from 'common-logic';
import { MAP_TYPES } from 'src/constants';
import { ITrackInteractionPoint } from 'src/utils/interfaces';
import { ITag, addTagToList, removeTagFromList, vectorLayerToPoint } from 'src/utils/mapTagHelpers';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

export const MapContainer: FC<any> = ({ trackData, selectedId, complaintTime }) => {
  const client = useApolloClient();
  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  const dataSelectors = useDataSelectors();
  const {
    globals: { distanceUnits, altitudeUnits },
    map: { mapProjectionString },
  } = configSelectors.getConfig();
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [selectionBounds]: any = useState(null);
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  const selectedAddress: ISelectAddress = dataSelectors.getSelectedAddress();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        ruler: ruler,
      },
    },
    abbreviations,
  } = languageSelectors.getLanguage();

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 2000);

  const labels = Object.assign(opsFields, abbreviations);

  const { addRemoveCircles } = useCircleRanges(mapApis, distanceUnits);

  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const { latitude, longitude } = geocoding;
  const { elevation } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude]],
  });

  const requiredMouseLayers = useMemo(() => ['select-flight-tracks'], []);
  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    radius: 2,
    disabled: false,
    radiusGradient: 0.1,
    mapType: MAP_TYPES.COMPLAINTVIEWCOMPLAINT,
  });

  const [tagList, setTagList] = useState<ITag[]>([]);

  const { handleClick, clickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    mouseFilters,
    false,
    '',
    0.5,
    0.1,
    setTagList
  );

  useEffect(() => {
    addTagToList(clickedElement, tagList, setTagList);
  }, [clickedElement]);

  const [hoveredPointData, setHoveredPointData] = useState<ITrackInteractionPoint>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
    distanceHorizontal: null,
    distanceVertical: null,
    distanceSlant: null,
  });

  const [trackPcas, setTrackPcas] = useState<any>();
  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return trackData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  let userHomeLocation: Position = { latitude: 0, longitude: 0, altitude: 0 };
  if (selectedAddress && selectedAddress.position) {
    userHomeLocation = {
      latitude: selectedAddress.position.latitude,
      longitude: selectedAddress.position.longitude,
      altitude: elevation ? elevation : 0,
    };
  }
  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode: false,
    isPlaybackRunning: false,
    userHomeLocation,
    mapProjectionString,
  });

  const highlightIds = useMemo(() => (selectedId ? [selectedId] : []), [selectedId]);
  useStaticTrackDisplay({ mapApis, dataSources: trackData, highlightIds, rerunHook });

  useEffect(() => {
    const { place_name, position } = selectedAddress;
    updateGeocoding({
      longitude: position ? position.longitude : 0,
      latitude: position ? position.latitude : 0,
    });
    updateLocationAddress(place_name || null);
    if (mapApis) {
      const selectedMonitorCoords: ISelectMonitorCoords[] = [];
      if (position && position.longitude && position.longitude) {
        selectedMonitorCoords.push({
          longitude: position.longitude,
          latitude: position.latitude,
        });
      }
      fitPointsInMap(mapApis, viewport, setViewport, selectedMonitorCoords);
    }
  }, [selectedAddress, mapApis]);

  useEffect(() => {
    if (mapApis && trackData.length) {
      Projection.current = new Projection(mapProjectionString);
      const pcaValues = getTrackPcas([userHomeLocation], trackData[0]);
      pcaValues.map(point => {
        const { trackPoint, distanceSlant } = point;
        trackData[0].points.splice(Math.floor(point.trackPointIndex), 0, {
          ...trackPoint,
          __typename: 'Trackpoint',
        });
        trackData[0].profile.splice(Math.floor(point.trackPointIndex), 0, {
          alt: trackPoint.alt,
          dist: distanceSlant,
          time: trackPoint.t,
          __typename: 'Trackpoint',
        });
      });
      setTrackPcas(pcaValues);
    }
  }, [mapApis, trackData]);

  // Draw out PCA points on the map
  useEffect(() => {
    if (trackPcas) {
      drawMapPcaPoints(mapApis, trackPcas);
    }
  }, [trackPcas]);

  // Ruler Tool

  const units = configSelectors.getUnits();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx('map_wrapper', {
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapStyle={mapStyle}
          onHover={debounce(handleHover, 5)}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          onClick={handleClick}
          height={mapHeight}>
          {isLocationTagOpen && (
            <ComplaintsPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress}
              elevation={elevation}
              altitudeUnits={altitudeUnits}
              languageData={{ latLabel, lngLabel, amslLabel }}
              mapApis={mapApis}
              onClose={() => updateLocationTagOpen(!isLocationTagOpen)}
            />
          )}
          <MapSelectionArea selectionBounds={selectionBounds} />

          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable={false}
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]) => {
              if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
                updateGeocoding({ longitude, latitude });
              }
              addRemoveCircles({ longitude, latitude });
              setTimeout(() => {
                updateLocationTagOpen(true);
              }, 1);
            }}
          />

          {enableMapControls && (
            <MapControl
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              resetView={resetView}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapRef={{ current: mapNode }}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(new Date(complaintTime), 'start'),
              dateFilterTo: dateTimeInQueryUTC(new Date(complaintTime), 'end'),
            }}
          />
          {hoveredElement && (
            <OperationMapTags
              element={hoveredElement}
              labels={labels}
              pointData={hoveredPointData}
              draggable={false}
              trackPcas={trackPcas}
            />
          )}

          {tagList.map(tag => {
            const matchingOperation = trackData.find(
              operation => operation.id === tag.data.properties.id || operation.id === tag.data.id
            );
            return (
              <OperationMapTags
                element={tag.data}
                labels={labels}
                pointData={vectorLayerToPoint({
                  operation: matchingOperation,
                  clickedElement: tag.data,
                  userHomeLocation,
                  mapProjectionString,
                })}
                draggable
                trackPcas={trackPcas}
                onClose={() => removeTagFromList(tag.uuid, tagList, setTagList)}
                key={tag.uuid}
              />
            );
          })}
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
