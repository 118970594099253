import { useContext } from 'react';
import uuid from 'uuid';
import { actionTypes } from 'src/@reports/actionTypes';
import { ReportsStateContext } from 'src/@reports/providers/ReportsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { IDataState, IDataSelectors, IFetchedData, IModifiedData } from 'src/@reports/interfaces';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(ReportsStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getReportsData: () => state.reports,
    getIfLoading: () => state.isLoading,
    getIfLoadingMore: () => state.isLoadingMore,
    getPageInfo: () => state.pageInfo,
    getReportCount: () => state.totalCount,
    getSelectedInTable: () => state.selectedInTable,
    getDownloadList: () => {
      const list: any[] = [];
      state.selectedInTable.map(id => {
        list.push(...state.reports.get(id).downloadList);
      });

      return list;
    },
  }));
};

const initialStateObj: IDataState = {
  reports: new Map(),
  isLoading: false,
  totalCount: undefined,
  pageInfo: undefined,
  selectedInTable: [],
  isLoadingMore: false,
};

export const dataInitialState: IDataState = Object.assign({}, initialStateObj);

const convertDataToMap = (currentMap, newData: IFetchedData[]) => {
  const data = new Map(currentMap);
  for (const report of newData) {
    const modifiedReport: IModifiedData = Object.assign({}, report);
    const identifier = [
      modifiedReport.templateName.replace(/\s/g, '-'),
      modifiedReport.startTime,
      modifiedReport.reportInterval,
      modifiedReport.dataType,
    ].join('_');
    const existingData: any = data.get(identifier);
    if (existingData !== undefined) {
      const copyData = Object.assign({}, existingData);
      // Add to download list
      if (copyData.downloadList) {
        copyData.downloadList.push({
          id: modifiedReport.id,
          resource: modifiedReport.reportFileResource,
          format: modifiedReport.reportFormat,
        });

        const formatOrder = ['Excel', 'CSV', 'XML', 'Crystal'];

        copyData.downloadList.sort((a, b) => {
          if (formatOrder.indexOf(a.format) > formatOrder.indexOf(b.format)) {
            return -1;
          }
          if (formatOrder.indexOf(a.format) < formatOrder.indexOf(b.format)) {
            return 1;
          }
          return 0;
        });
      } else {
        modifiedReport.downloadList = [
          {
            resource: modifiedReport.reportFileResource,
            format: modifiedReport.reportFormat,
            id: modifiedReport.id,
          },
        ];
      }

      data.set(identifier, copyData);
    } else {
      modifiedReport.tableId = uuid.v4();
      modifiedReport.downloadList = [
        {
          id: modifiedReport.id,
          resource: modifiedReport.reportFileResource,
          format: modifiedReport.reportFormat,
        },
      ];
      data.set(identifier, modifiedReport);
    }
  }

  return data;
};

export const dataReducer = (state: IDataState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_FIRST_FETCH:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case actionTypes.DATA_FETCHED:
      const { data, pageInfo, totalCount } = action.data;

      return Object.assign({}, state, {
        reports: convertDataToMap(state.reports, data),
        isLoading: false,
        isLoadingMore: false,
        pageInfo,
        totalCount,
      });
    case actionTypes.LOAD_MORE:
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    case actionTypes.RESET_DATA:
      return Object.assign({}, initialStateObj);
    case actionTypes.SELECT_ROW:
      return Object.assign({}, state, {
        selectedInTable: action.data,
      });
    default:
      return state;
  }
};
