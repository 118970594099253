import { useMemo } from 'react';
import { useAircraftMappings } from '../providers';

export const useBuildModelFilterOptions = () => {
  const {
    state: { enrouteMappings, ltoMappings },
  } = useAircraftMappings();

  const enrouteFilterOptions = useMemo(
    () =>
      enrouteMappings.map(({ engine, aircraftType }) => ({
        key: `${aircraftType} / ${engine}`,
        label: `${aircraftType} / ${engine}`,
      })),
    [enrouteMappings]
  );

  const ltoFilterOptions = useMemo(
    () =>
      ltoMappings.map(({ engine, aircraftType }) => ({
        key: `${aircraftType} / ${engine}`,
        label: `${aircraftType} / ${engine}`,
      })),
    [enrouteMappings]
  );

  return { enrouteFilterOptions, ltoFilterOptions };
};
