import { useEffect, useState } from 'react';

export const useIsLoading = ({
  isLoadingAllMonitorLocations,
  isLoadingDeviceData,
  isLoadingDeviceMonitorLocation,
  isLoadingMutation,
}) => {
  const [areQueriesLoading, setAreQueriesLoading] = useState(true);
  const [areMutationsLoading, setAreMutationsLoading] = useState(true);
  useEffect(() => {
    setAreQueriesLoading(
      isLoadingAllMonitorLocations || isLoadingDeviceMonitorLocation || isLoadingDeviceData
    );
    setAreMutationsLoading(isLoadingMutation);
  }, [
    isLoadingAllMonitorLocations,
    isLoadingDeviceMonitorLocation,
    isLoadingDeviceData,
    isLoadingMutation,
  ]);

  return { areQueriesLoading, areMutationsLoading };
};
