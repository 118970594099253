export interface IResponseValidator {
  networkRequests: any;
}

export class ResponseValidator implements IResponseValidator {
  networkRequests;

  constructor() {
    this.networkRequests = {};
  }

  get(instance: string): [string, number] {
    return [instance, new Date().getTime()];
  }

  set(instance: string, t: number) {
    this.networkRequests[instance] = t;
  }

  // cancel responses that are expired
  isValid(instance: string, t: number): boolean {
    return t >= this.networkRequests[instance];
  }
}
