import { useMemo, useState } from 'react';
import { useDataSelectors } from '../reducers';

export const useGetDeselectedInTable = () => {
  const [selectedInTableIds, setSelectedInTableIds] = useState<number[]>([]);
  const dataSelectors = useDataSelectors();
  const { selectedInTable } = dataSelectors.getDataInformation();

  const deselected = useMemo(() => selectedInTableIds.filter(id => !selectedInTable.includes(id)), [
    selectedInTable,
  ]);
  if (selectedInTable.length !== selectedInTableIds.length) {
    setSelectedInTableIds(selectedInTable);
  }

  return deselected;
};
