// Apollo
import gql from 'graphql-tag';

export const CHECK_ACTIVE_SCENARIOS = gql`
  query getNoiseModelingScenariosForUser {
    aircraftNoiseModelingScenariosForCurrentUser {
      status
      name
      id
      createTime
      lastUpdateTime
    }
  }
`;

export const CREATE_NOISE_SCENARIO = gql`
  query generatedScenarios(
    $startTime: DateTimeOffset!
    $endTime: DateTimeOffset!
    $name: String
    $filter: InputAircraftNoiseModelingScenarioFilter
    $noiseModelType: AircraftNoiseModelType!
    $measurementDayPeriod: MeasurementDayPeriodInput
  ) {
    createAircraftNoiseModelingScenario(
      startTime: $startTime
      endTime: $endTime
      name: $name
      filter: $filter
      noiseModelType: $noiseModelType
      measurementDayPeriod: $measurementDayPeriod
    )
  }
`;

export const CHECK_NOISE_SCENARIO_STATUS = gql`
  query getNoiseModelingScenarios($id: ID) {
    aircraftNoiseModelingScenario(id: $id) {
      status
    }
  }
`;

export const UPDATE_NOISE_SCENARIO_LICENSE = gql`
  mutation updateSettings($settings: [SettingInput]) {
    updateSettingsForDeployedProduct(settings: $settings)
  }
`;
