import { IFilterItem } from 'src/@airtrak/interfaces';
import { actionTypes } from 'src/@airtrak/actionTypes';

export const updateSelectedFilterItems = (
  category: string,
  selectedItems: IFilterItem[],
  convertedDataList: string[],
  airtrakFilterData: {},
  newDispatcher: any
): void => {
  newDispatcher({ type: actionTypes.DATA_LOADING });
  newDispatcher({
    type: actionTypes.UPDATE_SELECTED_FILTER_ITEMS,
    data: { category, selectedItems, convertedDataList, airtrakFilterData },
  });
};

export const clearSelectedFilterItems = (newDispatcher: any): void => {
  newDispatcher({ type: actionTypes.DATA_LOADING });
  newDispatcher({ type: actionTypes.CLEAR_SELECTED_FILTER_ITEMS });
};
