import React from 'react';

// Reducers
import { useLanguageSelectors } from 'src/app/reducers';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';
import { SettingsContentWrapper } from 'src/@settings/components';

// Container
import { ModelingSettingsContainer } from 'src/@settings/containers';

const Screen = () => {
  const languageSelectors = useLanguageSelectors();

  // Translations
  const {
    screens: {
      settings: {
        tabs: { modelingSettings: modelingSettingsString },
      },
    },
  } = languageSelectors.getLanguage();

  return (
    <SettingsLayout>
      <SettingsContentWrapper title={modelingSettingsString as string}>
        <ModelingSettingsContainer />
      </SettingsContentWrapper>
    </SettingsLayout>
  );
};

export const ModelingSettingsScreen = withRouteProps(Screen);
