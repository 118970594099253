import React, { useEffect, useContext, useState, FC } from 'react';
// reducers
import { useLanguageSelectors, useScenariosSelectors, useConfigSelectors } from 'src/app/reducers';
import { useTimeInModesSelector, useTableSelectors } from 'src/@settings/reducers';
// components
import { TextField } from 'src/@settings/components';
import { Button, Spinner, Table } from '@ems/client-design-system';
// provider
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// functions
import {
  getColumnTypes,
  updateTableIds,
  formatRunwaysData,
  getFormattedMutationItem,
} from 'src/@settings/functions';

// interfaces
import {
  ITimeInModesContainer,
  ITimeInModeItem,
  ITimeInModesData,
  IMutationItem,
  TMode,
} from 'src/@settings/interfaces';
// utils
import { convertMapToArray, sortTableData, formatHeaders } from 'src/utils';
// constants
import {
  MANAGE_CONFIGURATIONS_MODULE,
  MAX_TIME_IN_MODE_SECONDS,
  NULL_VALUE,
  TIME_IN_MODES_MODULE,
} from 'src/constants';
import { Prompt } from 'react-router-dom';
import { sortTimeinModesTable } from '../actions';

export const TimeInModesContainer: FC<ITimeInModesContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateTimeInModeSettings,
  updateScenarios,
  discardMutationData,
}) => {
  // Config
  const configSelectors = useConfigSelectors();
  const {
    formValidations: {
      airtrak: { timeInMode: timeInModeValidation },
    },
  } = configSelectors.getConfig();

  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);
  // selectors
  const tableSelectors = useTableSelectors();
  const sortObject = tableSelectors.getSortObject(TIME_IN_MODES_MODULE);
  const sortString = tableSelectors.getSortString(TIME_IN_MODES_MODULE);
  const timeInModesSelector = useTimeInModesSelector();
  const languageSelectors = useLanguageSelectors();
  const scenariosSelector = useScenariosSelectors();
  const activeScenario = scenariosSelector.getActiveScenario();
  const airport = timeInModesSelector.getAirport(activeScenario.airportId);
  const translationData = languageSelectors.getLanguage();
  const [rowData, setRowData] = useState<ITimeInModesData[]>([]); // formatted data for table
  const [highlightRow, setHighlightRow] = useState<number>();
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [disableDelete, setDisableDelete] = useState<boolean>(true);
  const [disableAddNew, setDisableAddNew] = useState<boolean>(false);
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  const {
    fields: {
      timeInModes: {
        airport: airportColumnHeader,
        runway: runwayColumnHeader,
        durationTaxiOut: durationTaxiOutColumnHeader,
        durationTakeoff: durationTakeoffColumnHeader,
        durationClimb: durationClimbColumnHeader,
        durationApproach: durationApproachClumnHeader,
        durationTaxiIn: durationTaxiInColumnHeader,
      },
    },
    components: {
      labels: { taxiOut, taxiIn, takeOff, climb, approach, timInfo, timDefaultInfo, timEachRunway },
      buttons: { addNew, deleteSelected },
      hints: { areYouSureYouWantToLeave, noDataTitle, tryChangingFiltersOrDate },
    },
  } = translationData;

  // Table header set up
  const columnData = [
    {
      columnName: 'airport',
      key: 'airport',
      title: airportColumnHeader,
    },
    { columnName: 'runway', key: 'runway', title: runwayColumnHeader },
    {
      columnName: 'durationTaxiOut',
      key: 'durationTaxiOut',
      title: durationTaxiOutColumnHeader,
    },

    {
      columnName: 'durationTakeoff',
      key: 'durationTakeoff',
      title: durationTakeoffColumnHeader,
    },
    { columnName: 'durationClimb', key: 'durationClimb', title: durationClimbColumnHeader },
    {
      columnName: 'durationApproach',
      key: 'durationApproach',
      title: durationApproachClumnHeader,
    },
    {
      columnName: 'durationTaxiIn',
      key: 'durationTaxiIn',
      title: durationTaxiInColumnHeader,
    },
  ];

  const rowHeaders = formatHeaders(
    TIME_IN_MODES_MODULE,
    columnData,
    rowData.length,
    dispatcher,
    sortTimeinModesTable,
    tableSelectors,
    languageSelectors.getLanguage(),
    'loadFactors',
    false,
    true
  );

  const timeInModesColumns: string[] = columnData.map(header => header.columnName);

  // variables
  const timeInModes: ITimeInModeItem[] = timeInModesSelector.getTimeInModes();
  const loading: boolean = timeInModesSelector.getIfLoading();
  const [data, setData] = useState<ITimeInModeItem[]>(timeInModes); // raw runways for updates
  const timeInModesCopy = timeInModes.map(item => ({ ...item }));
  const defaultTimeInModeConfigurationData = {
    defaultDurationTaxiOut: activeScenario.defaultDurationTaxiOut,
    defaultDurationTakeOff: activeScenario.defaultDurationTakeOff,
    defaultDurationClimb: activeScenario.defaultDurationClimb,
    defaultDurationApproach: activeScenario.defaultDurationApproach,
    defaultDurationTaxiIn: activeScenario.defaultDurationTaxiIn,
  };
  const [timeInModeConfigurationData, setTimeInModeConfigurationData] = useState(
    defaultTimeInModeConfigurationData
  );
  const [configfurationMutationData, setConfigfurationMutationData] = useState<IMutationItem[]>([]);
  const setdefaultTimeInModeConfigurationData = obj => {
    const timeInModeConfigurations = Object.assign({}, timeInModeConfigurationData, obj);
    setTimeInModeConfigurationData(timeInModeConfigurations);
    const { items } = convertMapToArray(
      getFormattedMutationItem(
        'Update',
        Object.assign({}, activeScenario, obj),
        new Map<number, IMutationItem>(),
        MANAGE_CONFIGURATIONS_MODULE
      )
    );
    setConfigfurationMutationData(items);
    updateScenarios(items);
    setChangesAvailable(true);
  };
  const [apiData, setApiData] = useState<ITimeInModeItem[]>(timeInModesCopy); // for discard

  useEffect(() => {
    formatAndSetRowData(data);
  }, [data]);

  useEffect(() => {
    setData(apiData);
    formatAndSetRowData(apiData);
    setTimeInModeConfigurationData(defaultTimeInModeConfigurationData);
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [areChangesDiscarded]);

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [discardMutationData]);

  const updateRunway = (runwayId, selectedItem) => {
    setData(prev => {
      const prevData = prev.slice(0);
      prevData[runwayId] = { ...prevData[runwayId], ...selectedItem };
      handleMutation('Update', prevData[runwayId]);
      return prevData;
    });
    setChangesAvailable(true);
  };

  const formatAndSetRowData = data => {
    const mappedRunways: string[] = data.map(each => each.runway);
    const availableRunways: string[] = airport ? airport.runways.map(each => each.name) : [];
    const unMappedRunways: string[] =
      airport &&
      airport.runways.map(each => (!mappedRunways.includes(each.name) ? each.name : NULL_VALUE));
    const formattedData = formatRunwaysData(
      updateTableIds([], data),
      unMappedRunways,
      airport && [airport.icaoCode],
      translationData,
      updateRunway,
      timeInModeValidation.match
    );
    setRowData(formattedData);
    mappedRunways.length === availableRunways.length
      ? setDisableAddNew(true)
      : setDisableAddNew(false);
  };

  useEffect(() => {
    const sortData = sortTableData(data, sortObject);
    setData(sortData);
  }, [sortString]);

  useEffect(() => {
    setData(sortTableData(timeInModes, sortObject));
    setApiData(timeInModes);
  }, [timeInModes]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(
      getFormattedMutationItem(mode, updatingItem, mutationData, TIME_IN_MODES_MODULE)
    );
    const { items } = convertMapToArray(mutationData);
    updateTimeInModeSettings(items);
  };

  const onSelectRow = selectedIndexes => {
    setSelectedInTable(selectedIndexes);
    selectedIndexes.length > 0 ? setDisableDelete(false) : setDisableDelete(true);
  };

  const addNewRow = () => {
    const addNewDefaultRowData = {
      id: new Date().getTime(),
      airport: airport && airport.icaoCode,
      runway: '',
      durationTaxiOut: activeScenario.defaultDurationTaxiOut,
      durationTakeoff: activeScenario.defaultDurationTakeOff,
      durationClimb: activeScenario.defaultDurationClimb,
      durationApproach: activeScenario.defaultDurationApproach,
      durationTaxiIn: activeScenario.defaultDurationTaxiIn,
      scenarioId: activeScenario.id,
    };
    setHighlightRow(0);
    handleMutation('Insert', addNewDefaultRowData);
    setData(updateTableIds([addNewDefaultRowData], data));
  };

  const onDeleteClick = () => {
    const deletingItems = getDeletingItems();
    deletingItems.map(item => {
      handleMutation('Delete', item);
    });
    const updatedData = data.filter((item, index) => !selectedInTable.includes(index));
    setData(updateTableIds([], updatedData));
    setSelectedInTable([]);
    mutationData.size > 0 ? setChangesAvailable(true) : setChangesAvailable(false);
  };

  const getDeletingItems = () => data.filter((item, index) => selectedInTable.includes(index));

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      {' '}
      <Spinner loading size="l" centered />{' '}
    </div>
  );

  const getTimeInModeComponent = () => (
    <>
      <p className="tim-info">{timInfo}</p>
      <p className="tim-default-info">{timDefaultInfo}</p>
      <div className="tim-input-bar">
        <TextField
          label={taxiOut}
          value={timeInModeConfigurationData.defaultDurationTaxiOut}
          setData={setdefaultTimeInModeConfigurationData}
          name={'defaultDurationTaxiOut'}
          validationString={timeInModeValidation.match}
          maxValue={MAX_TIME_IN_MODE_SECONDS}
        />
        <TextField
          label={takeOff}
          value={timeInModeConfigurationData.defaultDurationTakeOff}
          setData={setdefaultTimeInModeConfigurationData}
          name={'defaultDurationTakeOff'}
          validationString={timeInModeValidation.match}
          maxValue={MAX_TIME_IN_MODE_SECONDS}
        />
        <TextField
          label={climb}
          value={timeInModeConfigurationData.defaultDurationClimb}
          setData={setdefaultTimeInModeConfigurationData}
          name={'defaultDurationClimb'}
          validationString={timeInModeValidation.match}
          maxValue={MAX_TIME_IN_MODE_SECONDS}
        />
        <TextField
          label={approach}
          value={timeInModeConfigurationData.defaultDurationApproach}
          setData={setdefaultTimeInModeConfigurationData}
          name={'defaultDurationApproach'}
          validationString={timeInModeValidation.match}
          maxValue={MAX_TIME_IN_MODE_SECONDS}
        />
        <TextField
          label={taxiIn}
          value={timeInModeConfigurationData.defaultDurationTaxiIn}
          setData={setdefaultTimeInModeConfigurationData}
          name={'defaultDurationTaxiIn'}
          validationString={timeInModeValidation.match}
          maxValue={MAX_TIME_IN_MODE_SECONDS}
        />
      </div>
      <div className="tim-action-element">
        <p className="tim-each-runway">{timEachRunway}</p>
        <div>
          <Button className="airtrak-delete-btn" disabled={disableDelete} onClick={onDeleteClick}>
            {deleteSelected}
          </Button>
          <Button className="airtrak-addnew-btn" disabled={disableAddNew} onClick={addNewRow}>
            {addNew}
          </Button>
        </div>
      </div>
      <div className="multiple-emission-table">
        <Table
          className="time-in-modes-table"
          data={rowData}
          columns={timeInModesColumns}
          rowHeaders={rowHeaders}
          gridID={'fleet-mix'}
          columnTypes={getColumnTypes(timeInModesColumns)}
          clickedRow={highlightRow}
          selectedData={selectedInTable}
          onSelectRow={onSelectRow}
          languageData={{
            noDataTitle: `${noDataTitle}`,
            noDataText: `${tryChangingFiltersOrDate}`,
          }}
        />
        <Prompt
          message={areYouSureYouWantToLeave}
          when={mutationData.size > 0 || configfurationMutationData.length > 0}
        />
      </div>
    </>
  );

  return loading ? getSpinnerComponent() : getTimeInModeComponent();
};
