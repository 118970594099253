import { useMemo } from 'react';
import { LinePoints, LineSegments } from './InteractiveLineLayer';
import { FeatureCollection, LineString } from 'geojson';
import * as turf from '@turf/turf';
import { useTaxiPathContext } from 'src/@settings/containers/TaxiPath/context/TaxiPathContext';

export const useLineLayerDataPoints = (
  geometries: FeatureCollection<LineString>
): {
  segments: LineSegments;
  points: LinePoints;
  creationPoints: LinePoints;
  midPoints: LinePoints;
} => {
  const {
    state: { pathGeoJSON },
  } = useTaxiPathContext();
  return useMemo(() => {
    const allCoordinates = geometries.features.flatMap(({ geometry, properties }) =>
      geometry.coordinates.map(coord => ({
        coordinates: coord,
        id: properties.id,
        PathwayId: properties.PathwayId,
      }))
    );

    const pointList = allCoordinates.map(({ coordinates, id, PathwayId }, index) =>
      turf.point([...coordinates], {
        id: `${PathwayId}-${index}`,
        PathwayId,
      })
    );

    let midPoints = turf.featureCollection<GeoJSON.Point>([]);
    pointList.forEach((point, index) => {
      if (index + 1 <= pointList.length - 1) {
        const inBetween = turf.midpoint(point, pointList[index + 1]);
        inBetween.properties = {
          ...inBetween.properties,
          beforeIndex: index + 1,
          afterIndex: index,
          PathwayId: point.properties.PathwayId,
        };
        midPoints = turf.featureCollection<GeoJSON.Point>([...midPoints.features, inBetween]);
      }
    });

    // Dont first and last as they are the nodes
    const points = turf.featureCollection([...pointList.slice(1, -1)]);
    const creationPoints = turf.featureCollection([...pointList]);
    const segments = { ...geometries };
    return { points, segments, creationPoints, midPoints };
  }, [geometries, pathGeoJSON]);
};
