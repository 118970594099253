import React from 'react';
import { DateTime } from 'luxon';
import { DATE_TIME_FORMAT } from 'src/constants';

interface IDateTimeTableFieldProps {
  dateTimeValue: string;
  className?: string;
  dateFormat?: string;
}

export const DateTimeTableField: React.FC<IDateTimeTableFieldProps> = ({
  dateTimeValue,
  className,
  dateFormat = DATE_TIME_FORMAT.TABLE_DATE_ONLY,
}) => {
  const displayTime = dateTimeValue
    ? DateTime.fromISO(dateTimeValue, { setZone: true }).toFormat(dateFormat)
    : '';

  return <div className={className}>{displayTime}</div>;
};
