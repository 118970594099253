import { useContext } from 'react';
import { tagDataActionTypes } from 'src/app/newActionTypes';
import { useSelectors } from 'src/utils/storeHelpers';

export const tagDataReducer = (state, action) => {
  switch (action.type) {
    case tagDataActionTypes.TAG_DATA_UPDATE:
      const { key, body } = action.payload;
      return {
        ...state,
        [key]: body,
      };
    default:
      return state;
  }
};

export const useTagDataContext = context => {
  const state: any = useContext(context);
  const tagDataState = state.tagData;

  return useSelectors(tagDataState, state => ({
    getTagData: () => state,
  }));
};
