import { createContext } from 'react';

// Types
import { IScenarioFilterObject } from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

interface IScenarioFilterDataContext {
  scenarioFilterData: IScenarioFilterObject;
  setScenarioFilterData: (object: IScenarioFilterObject) => void;
}

export const scenarioFilterDataContext = createContext<IScenarioFilterDataContext>({
  scenarioFilterData: {},
  setScenarioFilterData: object => {},
});
