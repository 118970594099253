import { useContext } from 'react';
import uuid from 'uuid';
import {
  IActions,
  IAircraftMappingsState,
  IAircraftMappingsSelector,
  IAircraftFlights,
} from '../interfaces';
import { useSelectors } from 'src/utils/storeHelpers';
import { actionTypes } from 'src/@settings/actionTypes';
import { SettingsStateContext } from '../provider/SettingsStateProvider';
import { AIRCRAFT_MODEL_SEPARATOR } from 'src/constants';
import { groupBy } from 'src/utils';

export const useAircraftMappingsSelector: () => IAircraftMappingsSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const aircraftMappingsState: IAircraftMappingsState = state.aircraftMappings;
  return useSelectors(aircraftMappingsState, (state: IAircraftMappingsState) => ({
    getMappings: () => state.mappings,
    getLtoEmissions: () => state.ltoAircraftEngineMappings,
    getEnrouteEmissions: () => state.enrouteAircraftEngineMappings,
    getSelectedMapping: () => state.selectedMapping,
    getLtoMappedProportion: () => state.ltoMappedProportion,
    getEnrouteMappedProportion: () => state.enrouteMappedProportion,
    getIfLoading: () => state.isLoading,
    getIfFlightsLoading: () => state.isFlightsLoading,
  }));
};

export const aircraftMappingsInitialState: IAircraftMappingsState = {
  mappings: [],
  aircraftMappings: [],
  ltoAircraftEngineMappings: [],
  enrouteAircraftEngineMappings: [],
  selectedMapping: {},
  ltoMappedProportion: '0%',
  enrouteMappedProportion: '0%',
  isLoading: false,
  isFlightsLoading: false,
  groupByAircraftData: new Map(),
};

const formatAircraftsData = (newData: any, groupByAircraftData: any) => {
  const data = [];
  if (newData.length > 0) {
    for (const mapping of newData) {
      const ltoAircraft = mapping.mappedLTOAircraft;
      const enrouteAircraft = mapping.mappedEnrouteAircraft;
      const ltoEngine = mapping.mappedLTOEngine;
      const enroutEngine = mapping.mappedEnrouteEngine;
      const aircraftMapping = {
        id: mapping.id,
        aircraftType: mapping.aircraftType,
        scenarioId: mapping.scenarioId,
        engine: mapping.engine,
        numberOfFlights: getNumberOfFlights(
          mapping.aircraftType,
          mapping.engine,
          groupByAircraftData
        ),
        ltoAircraft,
        enrouteAircraft,
        ltoModel: ltoAircraft + AIRCRAFT_MODEL_SEPARATOR + ltoEngine,
        enrouteModel: enrouteAircraft + AIRCRAFT_MODEL_SEPARATOR + enroutEngine,
        tableId: uuid.v4(),
        mapped: ltoAircraft !== '?' && enrouteAircraft !== '?',
      };
      data.push(aircraftMapping);
    }
  }
  return data;
};

const getNumberOfFlights = (
  aircraft: string,
  engine: string,
  groupByAircraftData: IAircraftFlights[]
) => {
  if (!!groupByAircraftData.length) {
    const aircraftInfo = groupByAircraftData.find(
      each => each.aircraftType === aircraft && each.engine === engine
    );
    return aircraftInfo ? aircraftInfo.numberOfFlights : 0;
  }
  return 0;
};

const getMappedProportions = mappings => {
  let ltoCount = 0;
  let enrouteCount = 0;
  let totalCount = 0;
  let ltoFlightsMappedPercentage;
  let enrouteFlightsMappedPercentage;

  mappings.forEach(mapping => {
    if (mapping.ltoAircraft !== '?') {
      ltoCount += mapping.numberOfFlights;
    }
    if (mapping.enrouteAircraft !== '?') {
      enrouteCount += mapping.numberOfFlights;
    }
    totalCount += mapping.numberOfFlights;
  });

  if (totalCount > 0) {
    const ltoPercent = Math.floor((100 * ltoCount) / totalCount);
    ltoFlightsMappedPercentage = ltoPercent + '%';

    const enroutePercent = Math.floor((100 * enrouteCount) / totalCount);
    enrouteFlightsMappedPercentage = enroutePercent + '%';
  } else {
    ltoFlightsMappedPercentage = '0%';
    enrouteFlightsMappedPercentage = '0%';
  }
  return { ltoFlightsMappedPercentage, enrouteFlightsMappedPercentage };
};

export const aircraftsMappingsReducer = (state: IAircraftMappingsState, action: IActions) => {
  switch (action.type) {
    case actionTypes.SHOW_MAPPED:
      return Object.assign({}, state, {
        mappings: action.showMapped
          ? state.aircraftMappings
          : state.aircraftMappings.filter(each => !each.mapped),
        isLoading: false,
      });
    case actionTypes.AIRCRAFT_MAPPINGS_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case actionTypes.GET_LTO_MAPPINGS:
      return Object.assign({}, state, {
        ltoAircraftEngineMappings: action.ltoAircraftEngineMappings,
      });
    case actionTypes.GET_ENROUTE_MAPPINGS:
      return Object.assign({}, state, {
        enrouteAircraftEngineMappings: action.enrouteAircraftEngineMappings,
      });
    case actionTypes.AIRCRAFT_FLIGHTS_LOADING:
      return Object.assign({}, state, { isFlightsLoading: action.isFlightsLoading });
    case actionTypes.GET_AIRCRAFT_FLIGHTS:
      return Object.assign({}, state, {
        isFlightsLoading: false,
        groupByAircraftData: groupBy(
          action.aircraftFlights,
          ['aircraftType', 'engine'],
          'numberOfFlights'
        ),
      });
    case actionTypes.GET_AIRCRAFT_MAPPINGS:
      if (action.aircraftMappingsData) {
        const aircraftMappings = action.aircraftMappingsData;
        const itemsMap = formatAircraftsData(aircraftMappings, state.groupByAircraftData);
        const { ltoFlightsMappedPercentage, enrouteFlightsMappedPercentage } = getMappedProportions(
          itemsMap
        );
        return Object.assign({}, state, {
          mappings: itemsMap.filter(each => !each.mapped),
          aircraftMappings: itemsMap,
          selectedMapping: aircraftMappings[0],
          ltoMappedProportion: ltoFlightsMappedPercentage,
          enrouteMappedProportion: enrouteFlightsMappedPercentage,
          isLoading: false,
        });
      }
    case actionTypes.UPDATE_AIRCRAFT_MAPPINGS: {
      const aircraftMappings = action.aircraftMappingsData;
      const itemsMap = formatAircraftsData(aircraftMappings, state.groupByAircraftData);
      const { ltoFlightsMappedPercentage, enrouteFlightsMappedPercentage } = getMappedProportions(
        itemsMap
      );
      return Object.assign({}, state, {
        mappings: itemsMap.filter(each => !each.mapped),
        aircraftMappings: itemsMap,
        ltoMappedProportion: ltoFlightsMappedPercentage,
        enrouteMappedProportion: enrouteFlightsMappedPercentage,
        isLoading: false,
        selectedMapping: aircraftMappings[0],
      });
    }
    default:
      return state;
  }
};
