import React, { FC } from 'react';
import cx from 'classnames';

import { IPageHeaderDetails } from './interfaces';

export const PageHeaderDetails: FC<IPageHeaderDetails> = ({
  children,
  title,
  subtitle = '',
  backButton,
}) => (
  <>
    <section className={cx('page-header page-header_navigation')}>
      {backButton ? <div className="page-header_details-return">{backButton}</div> : null}
    </section>
    <section className={cx('page-header page-header_details')}>
      <div>
        <div className="page-header_title">{title}</div>
        <div className="page-header_subtitle">{subtitle}</div>
      </div>
      {children}
    </section>
  </>
);
