import { useEffect } from 'react';
import { MAP_ACTIONS } from '../context/Reducer';
import { useMapContext } from '../context/useMapContext';
import { ClickHandlerConfig, MapActionType } from '../map.types';

export const useAddOnMapClickFunction = (onClickFunction: ClickHandlerConfig) => {
  const { dispatch } = useMapContext();
  useEffect(() => {
    dispatch({ type: MAP_ACTIONS.ADD_CLICK_HANDLER as MapActionType, payload: onClickFunction });

    return () => {
      dispatch({
        type: MAP_ACTIONS.REMOVE_CLICK_HANDLER as MapActionType,
        payload: onClickFunction.id,
      });
    };
  }, [onClickFunction, dispatch]);
};
