import React from 'react';
import { ISpatialFeature } from 'src/@settings/interfaces';
import { formatDisplayFeatureType } from 'src/@settings/functions';
import { DateTimeTableField } from 'src/@settings/components/DateTimeTableField';
import uuid from 'uuid';
import { Link } from 'react-router-dom';
import { SETTINGS } from 'src/constants';
import { getDeployedProductId } from 'src/utils';
/*
 * Feature Name link
 */
const linkToFeatureDetails = (
  featureName: string,
  id: number,
  featureType: string,
  onSelect: (featureId: number, featureType: string) => void
) => (
  <Link
    onClick={() => onSelect(id, featureType)}
    className="feature_link"
    to={{
      pathname: `/${getDeployedProductId()}/${SETTINGS}/spatial-features/${featureType}-${id}`,
    }}>
    {featureName}
  </Link>
);

export const getSelectedIndexesFromKeys = (selectedKeys: string[], allTableKeys: string[]) => {
  const indexes: number[] = [];
  if (selectedKeys && selectedKeys.length) {
    selectedKeys.forEach((key: string) => {
      const index: number = allTableKeys.indexOf(key);
      if (index !== -1) {
        indexes.push(index);
      }
    });
  }

  return indexes.sort();
};

export const getIdFromTableRowKey = (tableRowKey: string): number => {
  const featureId = tableRowKey.substring(tableRowKey.indexOf(':') + 1, tableRowKey.indexOf('/'));
  return Number(featureId);
};

export const getTableData = (
  features: ISpatialFeature[],
  onSelectSpatialFeature: (featureId: number, featureType: string) => void
) => {
  const tableData = features.map((spatialFeature: ISpatialFeature) => {
    const rowItem = {
      feature: linkToFeatureDetails(
        spatialFeature.name,
        spatialFeature.featureId,
        spatialFeature.featureType,
        onSelectSpatialFeature
      ),
      name: spatialFeature.name,
      featureType: spatialFeature.featureType,
      type: <div>{formatDisplayFeatureType(spatialFeature.featureType)}</div>,
      lastUpdateTime: (
        <DateTimeTableField
          className={'feature-time-column'}
          dateTimeValue={spatialFeature.lastUpdateTime}
        />
      ),
      tableId: uuid.v4(),
      tableRowKey: `id:${spatialFeature.featureId}/${spatialFeature.featureType}`,
    };

    return rowItem;
  });

  return tableData;
};
