import { useMemo } from 'react';
import { IAircraftMappingEdge } from '../types';

// Get percent of mapped flights vs non mapped
export const useMappedPercent = (edges: IAircraftMappingEdge[]) =>
  useMemo(() => {
    let totalFlights = 0;
    let mappedLtoFlights = 0;
    let mappedEnrouteFlights = 0;

    edges.forEach(({ node }) => {
      totalFlights += node.numberOfFlights;

      if (node.mappedLTOAircraftEngine.split(' / ')[0] !== '?') {
        mappedLtoFlights += node.numberOfFlights;
      }

      if (node.mappedEnrouteAircraftEngine.split(' / ')[0] !== '?') {
        mappedEnrouteFlights += node.numberOfFlights;
      }
    });

    return {
      totalFlights,
      mappedLtoFlights,
      mappedLtoPercent: Math.floor(
        totalFlights === 0 ? 0 : Math.round((mappedLtoFlights / totalFlights) * 100)
      ),
      mappedEnrouteFlights,
      mappedEnroutePercent:
        totalFlights === 0 ? 0 : Math.round((mappedEnrouteFlights / totalFlights) * 100),
    };
  }, [edges]);
