import { useState } from 'react';
import { LayerProps, SourceProps } from 'react-map-gl';

export type MapLayerProps = LayerProps & {
  source: string;
};
// Safely retrieve map layers
export const getLayers = (mapApis): MapLayerProps[] => {
  const renderedLayers = [];
  if (!mapApis) {
    return [];
  }
  try {
    const layers = mapApis.getStyle().layers as MapLayerProps[];
    if (layers) {
      layers.forEach(layer => {
        renderedLayers.push(layer);
      });
    }
  } catch {
    console.warn('error in retrieving layers');
  }
  return renderedLayers;
};

export const getSources = (
  mapApis
): {
  [key: string]: SourceProps;
} => {
  if (!mapApis) {
    return {};
  }
  try {
    return mapApis.getStyle().sources as {
      [key: string]: SourceProps;
    };
  } catch {
    console.warn('error retrieving sources');
    return {};
  }
};

export const getAreSourcesLoading = mapApis => {
  const sources = Object.keys(getSources(mapApis));
  return sources.every(layer => mapApis.isSourceLoaded(layer));
};

export const useAreSourcesLoading = mapApis => {
  const [areSourcesLoading, setIsSourceLoaded] = useState<boolean>(false);
  const isEverySourceLoaded = getAreSourcesLoading(mapApis);
  if (isEverySourceLoaded !== areSourcesLoading) {
    setIsSourceLoaded(isEverySourceLoaded);
  }
  return areSourcesLoading;
};

export const hasStyleLoaded = mapApis => {
  if (!mapApis) {
    return false;
  }
  try {
    const style = mapApis.getStyle();
    return !!style;
  } catch {
    return false;
  }
};

// Useful for when the returned layers are a dependency of a useEffect or useMemo
export const useGetMapLayers = mapApis => {
  const [layers, setLayers] = useState<LayerProps[]>([]);
  const mapLayers = getLayers(mapApis);
  if (layers.length !== mapLayers.length) {
    setLayers(mapLayers);
  }
  return layers;
};

export const removeSourceFromMap = (mapApis, sourceId: string) => {
  if (mapApis) {
    // Remove layers using source first
    const layersWithSource = getLayers(mapApis).filter(({ source }) => source === sourceId);
    if (layersWithSource.length) {
      layersWithSource.forEach(layer => {
        removeLayerFromMap(mapApis, layer.id);
      });
    }

    try {
      mapApis.removeSource(sourceId);
    } catch {
      console.warn(`error removing sourceID: ${sourceId}`);
    }
  }
};

export const removeLayerFromMap = (mapApis, layerId) => {
  if (mapApis) {
    try {
      mapApis.removeLayer(layerId);
    } catch {
      console.warn(`error removing layerID: ${layerId}`);
    }
  }
};

export const addLayerToMap = (mapApis, layerData) => {
  const mapLayers = getLayers(mapApis);

  if (!mapLayers.some(({ id }) => id === layerData.id)) {
    try {
      mapApis.addLayer({ ...layerData });
    } catch (error) {
      console.warn(`error adding layerID: ${layerData.id}`);
    }
  }
};
