import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, Source, useMap } from 'react-map-gl';
import { InteractiveLayerId, useInteractiveLayerIds } from '../../hooks/useInteractiveLayerIds';
import { useDraggableLayer } from '../../hooks/useDraggableLayer';
import { getQueriedFeaturesById } from '../../helpers/getQueriedFeaturesById';
import { FeatureCollection, Point } from 'geojson';
import { MapboxStyleEditNode } from 'src/@settings/containers/TaxiPath/TaxiPath.styles';

interface DrawablePointLayerProps {
  id: string;
  geometry: Point;
  pointStyles;
  onPointSet;
  onRedraw?: (points: PointGeoJson) => void;
}

export type PointGeoJson = FeatureCollection<Point>;
const INTERACTIVE_LAYER_IDS = [InteractiveLayerId.drawablePoint];

export const DrawablePointLayer = ({
  id,
  geometry,
  pointStyles,
  onPointSet,
  onRedraw,
}: DrawablePointLayerProps) => {
  const { points, onPointUpdate } = usePointData(id, geometry, onRedraw);

  const draggable = true;
  return (
    <>
      {draggable && (
        <DraggablePointControl
          points={points}
          pointStyles={pointStyles}
          onPointUpdate={onPointUpdate}
          onPointSet={onPointSet}
        />
      )}
      <PointLayerAndSource points={points} pointStyles={pointStyles} />
    </>
  );
};

const PointLayerAndSource = ({ points, pointStyles }) => {
  useInteractiveLayerIds(INTERACTIVE_LAYER_IDS);

  return (
    <>
      <Source
        type="geojson"
        data={points}
        id={`${InteractiveLayerId.drawablePoint}-source`}
        generateId>
        <Layer {...MapboxStyleEditNode} id={InteractiveLayerId.drawablePoint} />
      </Source>
    </>
  );
};

const DraggablePointControl = ({ points, pointStyles, onPointUpdate, onPointSet }) => {
  useDraggablePoint(points, pointStyles, onPointUpdate, onPointSet);
  return null;
};

const usePointGeoJson = (id: string, geometry: Point): PointGeoJson =>
  useMemo(
    () =>
      ({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: geometry?.coordinates ?? [],
            },
            properties: { NodeId: id },
          },
        ],
      } as PointGeoJson),
    [geometry, id]
  );

const usePointData = (id: string, geometry: Point, onRedraw) => {
  const pointData = usePointGeoJson(id, geometry);
  const [points, setPoints] = useState(pointData);

  useEffect(() => {
    setPoints(pointData);
  }, [pointData]);

  const onPointUpdate = useCallback(
    point => {
      setPoints(point);
      onRedraw(points);
    },
    [setPoints]
  );

  return {
    points,
    onPointUpdate,
  };
};

export const useDraggablePoint = (points, pointStyles, onPointUpdate, onPointSet) => {
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null);
  const { current: map } = useMap();

  const onLayerMouseDown = useCallback(
    ({ target, point }: MapLayerMouseEvent) => {
      const features = getQueriedFeaturesById(target, point, InteractiveLayerId.drawablePoint);

      const pointId = features.length ? features[0].properties.NodeId : null;
      setSelectedPointId(pointId);
    },
    [setSelectedPointId]
  );

  const [movedPoint, setMovedPoint] = useState(null);

  const setLinesAndPoints = useCallback(
    movedPoint => {
      if (movedPoint) {
        const updatedPointGeometry = points.features.map(point =>
          point.properties.NodeId === movedPoint.id
            ? { ...point, geometry: { ...point.geometry, coordinates: movedPoint.coords } }
            : point
        );
        const updatedPoints = { ...points, features: updatedPointGeometry };
        onPointUpdate(updatedPoints);
      }
    },
    [points]
  );

  useEffect(() => {
    if (movedPoint) {
      setLinesAndPoints(movedPoint);
    }
  }, [movedPoint]);

  const onDragMove = useCallback(
    ({ lngLat: { lng, lat } }: MapLayerMouseEvent) => {
      if (selectedPointId) {
        setMovedPoint({ id: selectedPointId, coords: [lng, lat] });
      } else {
        setMovedPoint(null);
      }
      return;
    },
    [setMovedPoint, selectedPointId]
  );

  const onLayerOver = useCallback(
    (featureId, currentMap) => {
      map.setFeatureState(
        { source: `${InteractiveLayerId.drawablePoint}-source`, id: featureId },
        { hover: true }
      );
    },
    [pointStyles]
  );

  const onDragEnd = useCallback(
    ({ lngLat: { lng, lat } }) => {
      if (selectedPointId) {
        onPointSet([lng, lat]);
        setSelectedPointId(null);
      }
    },
    [setSelectedPointId, selectedPointId, onPointSet]
  );

  const onLayerMouseOut = useCallback(
    currentMap => {
      const layerFeatures = map.querySourceFeatures(`${InteractiveLayerId.drawablePoint}-source`);

      layerFeatures.forEach(featureId => {
        map.removeFeatureState(
          { source: `${InteractiveLayerId.drawablePoint}-source`, id: featureId.id },
          'hover'
        );
      });
    },
    [pointStyles]
  );

  useDraggableLayer(
    InteractiveLayerId.drawablePoint,
    onDragMove,
    onDragEnd,
    onLayerOver,
    onLayerMouseDown,
    onLayerMouseOut
  );
};
