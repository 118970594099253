import { EventEmitter } from 'events';
import { dispatcher } from 'src/utils/dispatcher';
import uuid from 'uuid';
// ts
import { IDateRange } from 'src/app/props';
import {
  IInfringementsData,
  IMapData,
  IPageInfo,
  IAction,
} from 'src/@infringementsCandidates/interfaces';
// actions
import { appActionTypes } from 'src/app/actionTypes';
import { infringementsActionTypes } from 'src/@infringementsCandidates/actionTypes';
// constants
import { CHANGE_EVENT } from 'src/constants';
// functions
import { setSelectionDelta, getMapDataFromSelection } from 'src/utils';

class DataStore extends EventEmitter {
  isLoading: boolean;
  fetchedData: IInfringementsData[];
  selectedData: number[];
  selectedDateRange: null | IDateRange;
  addedToSelection: any[];
  removedFromSelection: any[];
  totalCount?: number;
  pageInfo?: IPageInfo;
  isLoadingMore?: boolean;

  constructor() {
    super();
    this.isLoading = true;
    this.selectedData = [];
    this.selectedDateRange = null;
    this.addedToSelection = [];
    this.removedFromSelection = [];
    this.fetchedData = [];
    this.isLoadingMore = false;
    this.pageInfo = undefined;
    this.totalCount = undefined;
  }

  getDataInformation() {
    return {
      data: this.fetchedData,
      pageInfo: this.pageInfo,
      totalCount: this.totalCount,
      selectedData: this.selectedData,
      isLoading: this.isLoading,
      areAllRowsSelected: this.areAllRowsSelected(),
      isLoadingMore: this.isLoadingMore,
    };
  }

  getDataIds() {
    return this.fetchedData.map(item => item.id);
  }

  getPagingInfo() {
    return this.pageInfo;
  }

  getSelectedDateRange() {
    return this.selectedDateRange;
  }

  /**
   * infringments data required for map
   * TODO: add delta for map tracks later
   */
  getRequiredDataForMap(): {
    requiredData: IMapData[];
    selectionLength: number;
    addedToSelection: any[];
    removedFromSelection: any[];
  } {
    const selectedData = this.selectedData;
    const requiredData: any[] = [];
    const selectedDataLength = selectedData && selectedData.length;
    if (selectedData && selectedDataLength) {
      for (let i = selectedDataLength; i--; ) {
        const {
          id,
          operationId,
          infringementType,
          time,
          position: { altitude, latitude, longitude },
        } = this.fetchedData[selectedData[i]];
        requiredData.push({
          id,
          operationId,
          infringementType,
          time,
          position: { altitude, latitude, longitude },
        });
      }
    }
    return {
      requiredData,
      selectionLength: this.selectedData.length,
      addedToSelection: this.addedToSelection,
      removedFromSelection: this.removedFromSelection,
    };
  }

  setLocalSelectionData(data) {
    const { removedFromSelection, addedToSelection } = setSelectionDelta(data, [
      ...this.selectedData,
    ]);
    const addedToSelectionData =
      this.fetchedData.length &&
      addedToSelection.map(rowId => {
        const addedMapData = getMapDataFromSelection(this.fetchedData)(rowId);
        const addedInfringement = this.fetchedData[rowId];
        return {
          infringementId: addedInfringement.id,
          infringementType: addedInfringement.infringementType,
          operation: addedMapData,
        };
      });
    const removedFromSelectionData =
      this.fetchedData.length &&
      removedFromSelection.map(rowId => {
        const removedMapData = getMapDataFromSelection(this.fetchedData)(rowId);
        const removedInfringement = this.fetchedData[rowId];
        return {
          infringementId: removedInfringement.id,
          infringementType: removedInfringement.infringementType,
          operation: removedMapData,
        };
      });
    this.removedFromSelection = removedFromSelectionData ? removedFromSelectionData : [];
    this.addedToSelection = addedToSelectionData ? addedToSelectionData : [];
  }

  resetStoreValues() {
    this.isLoading = true;
    this.selectedData = [];
    this.fetchedData = [];
    this.isLoadingMore = false;
    this.pageInfo = undefined;
    this.totalCount = undefined;
  }

  areAllRowsSelected() {
    if (this.selectedData && this.selectedData.length && this.fetchedData) {
      if (this.fetchedData.length === this.selectedData.length) {
        return true;
      } else {
        return 'indeterminate';
      }
    }
    return false;
  }

  addDisplayData = data => {
    const formattedData: any = [];
    const dataLength = data.length;

    if (dataLength) {
      for (let i = dataLength; i--; ) {
        const infringement = Object.assign({}, data[i]);
        infringement.tableId = uuid.v4();
        formattedData.unshift(infringement);
      }
    }
    return formattedData;
  };

  handleActions(action: IAction) {
    if (action) {
      switch (action.type) {
        case infringementsActionTypes.INLINE_EDIT:
          const { id, status } = action.data;
          const foundId = this.fetchedData.findIndex(item => item.id === id);
          if (foundId !== -1) {
            const currentItem = { ...this.fetchedData[foundId] };
            currentItem.status = status;
            currentItem.tableId = uuid.v4();

            this.fetchedData = Object.assign([...this.fetchedData], { [foundId]: currentItem });
          }
          this.emit(CHANGE_EVENT);
          break;
        case infringementsActionTypes.TABLE_HEADERS:
          this.isLoading = true;
          this.emit(CHANGE_EVENT);
          break;
        case infringementsActionTypes.INFRINGEMENTS_FETCHED:
          this.isLoading = false;
          this.isLoadingMore = false;
          const { data, selectedDateRange, pageInfo, totalCount } = action.data;
          this.selectedDateRange = selectedDateRange;
          const formattedData = this.addDisplayData(data);
          const newData = [...this.fetchedData, ...formattedData];
          this.fetchedData = newData;
          this.pageInfo = pageInfo;
          this.totalCount = totalCount;
          this.emit(CHANGE_EVENT);
          break;
        case infringementsActionTypes.GET_TOTAL_COUNT:
          if (this.totalCount !== action.data.totalCount) {
            this.totalCount = action.data.totalCount;
            this.emit(CHANGE_EVENT);
          }
          break;
        case infringementsActionTypes.LOAD_MORE:
          this.isLoadingMore = true;
          this.emit(CHANGE_EVENT);
          break;
        case infringementsActionTypes.SELECT_ROW:
          const list = action.data;
          this.setLocalSelectionData(list);
          this.selectedData = list;
          this.emit(CHANGE_EVENT);
          break;
        case appActionTypes.ROUTE_CHANGE:
        case infringementsActionTypes.RESET_DATA:
          this.setLocalSelectionData([]);
          this.resetStoreValues();
          this.emit(CHANGE_EVENT);

        default:
      }
    }
  }
}

export const dataStore = new DataStore();
dispatcher.register(dataStore.handleActions.bind(dataStore));
