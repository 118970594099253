import styled from 'styled-components/macro';

export const TaxiPathControlsContainer = styled.div`
  left: 0;
  right: initial;
  width: auto;
  .mapboxgl-ctrl-icon.selected {
    background: deepskyblue;
  }
`;

export const NodeSearchControlContainer = styled.div`
  display: flex;
  .mapboxgl-ctrl-search {
    position: absolute;
    left: 50px;
  }
`;
