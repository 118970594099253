import React, { createContext, useReducer } from 'react';
// reducers
import {
  tableReducer,
  tableInitialState,
  fleetMixReducer,
  fleetMixInitialState,
  aircraftMappingsInitialState,
  aircraftsMappingsReducer,
  timeInModesReducer,
  timeInModesInitialState,
  loadFactorsInitialState,
  loadFactorsReducer,
  aircraftFuelMappingsReducer,
  aircraftFuelMappingsInitialState,
  spatialFeaturesReducer,
  spatialFeaturesInitialState,
  infRulesSettingsInitialState,
  infRulesSettingsReducer,
} from 'src/@settings/reducers';

// interfaces
import { IStoreState } from 'src/@settings/interfaces';

export const SettingsStateContext = createContext({
  aircraftMappings: aircraftMappingsInitialState,
  table: tableInitialState,
  fleetMix: fleetMixInitialState,
  timeInModes: timeInModesInitialState,
  loadFactors: loadFactorsInitialState,
  fuelTypes: aircraftFuelMappingsInitialState,
  spatialFeatures: spatialFeaturesInitialState,
});

export const SettingsDispatchContext = createContext<React.Dispatch<any> | null>(null);

export const SettingsStateProvider = ({ children }) => {
  const [tableState, tableDispatch] = useReducer(tableReducer, tableInitialState);
  const [fleetMixState, fleetMixDispatch] = useReducer(fleetMixReducer, fleetMixInitialState);

  const [aircraftMappingsState, aircraftMappingsDispatch] = useReducer(
    aircraftsMappingsReducer,
    aircraftMappingsInitialState
  );

  const [timeInModesState, timeInModesDispatch] = useReducer(
    timeInModesReducer,
    timeInModesInitialState
  );

  const [loafFactorsState, loadFactorsDispatch] = useReducer(
    loadFactorsReducer,
    loadFactorsInitialState
  );

  const [fuelTypesState, fuelTypesDispatch] = useReducer(
    aircraftFuelMappingsReducer,
    aircraftFuelMappingsInitialState
  );

  const [spatialFeaturesState, spatialFeaturesDispatch] = useReducer(
    spatialFeaturesReducer,
    spatialFeaturesInitialState
  );

  const [infRulesSettingsState, infRulesSettingsDispatch] = useReducer(
    infRulesSettingsReducer,
    infRulesSettingsInitialState
  );

  const state: IStoreState = {
    aircraftMappings: aircraftMappingsState,
    table: tableState,
    fleetMix: fleetMixState,
    timeInModes: timeInModesState,
    loadFactors: loafFactorsState,
    fuelTypes: fuelTypesState,
    spatialFeatures: spatialFeaturesState,
    infRulesSettings: infRulesSettingsState,
  };

  const dispatchers = [
    aircraftMappingsDispatch,
    tableDispatch,
    fleetMixDispatch,
    timeInModesDispatch,
    loadFactorsDispatch,
    fuelTypesDispatch,
    spatialFeaturesDispatch,
    infRulesSettingsDispatch,
  ];

  const dispatch: (action: string) => void = (action: string) =>
    dispatchers.map((dispatcher: React.Dispatch<any>) => {
      dispatcher(action);
    });

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
};
