import { useEffect } from 'react';
import { MapEventType, EventData } from 'mapbox-gl';
import { useMapboxMapContext } from '../context/useMapboxMapContext';

export type MapEventHandler<T extends keyof MapEventType> = (
  event: MapEventType[T] & EventData
) => void;

export const useMapEvent = <T extends keyof MapEventType>(
  eventType: T,
  eventHandler: MapEventHandler<T>
) => {
  const { map } = useMapboxMapContext();
  useEffect(() => {
    map?.on(eventType, eventHandler);
    return () => {
      map?.off(eventType, eventHandler);
    };
  }, [eventHandler, eventType, map]);
};
