// Types
import { IColourByAltitude, IConfigurationUnits } from 'src/app/props';
// Constants
import { UNIT_FOOT, IMPERIAL_BASE_UNIT, METRIC_BASE_UNIT, UNIT_METER } from 'src/constants';

// Utils
import { getVerticalDistance } from 'src/utils';

// theme
import themeToken from 'src/styles/themes.json';

const convertVerticalDistance = (value: number, verticalDistanceUnit) =>
  getVerticalDistance(value, {
    convertTo: verticalDistanceUnit,
    convertFrom: 'ft',
    returnValueType: 'number',
  }) as number;

export const buildAltitudeLegendValues = ({
  altitudeColorBands,
  units,
}: {
  altitudeColorBands: IColourByAltitude;
  units: IConfigurationUnits;
}) => {
  const verticalDistanceUnit =
    units.distanceVertical === UNIT_FOOT ? IMPERIAL_BASE_UNIT : METRIC_BASE_UNIT;
  const unitDisplayString = verticalDistanceUnit === IMPERIAL_BASE_UNIT ? UNIT_FOOT : UNIT_METER;

  return altitudeColorBands.colors.map((color, i) => {
    if (i === 0) {
      return {
        label: 'Ground',
        key: color,
      };
    } else {
      return {
        label: `${(
          convertVerticalDistance(altitudeColorBands.bands[i], verticalDistanceUnit) + 1
        ).toLocaleString()} ${unitDisplayString} ${
          i === altitudeColorBands.colors.length - 1 ? '+' : ''
        }`,
        key: color,
      };
    }
  });
};

export const buildTrackDensityLegendValues = (trackDensityCellCount: number) => {
  if (trackDensityCellCount === null) {
    return [];
  }
  const colorScheme = themeToken.trackDensity.colorScheme1;
  const colorKeys = Object.keys(colorScheme);

  const increment = trackDensityCellCount / (Object.keys(colorKeys).length - 1);

  return colorKeys.map((color, i) => ({
    label: (i === 0 ? '1' : `${Math.round(increment * i)}`).toLocaleString(),
    key: colorScheme[color],
  }));
};
