import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { fetchReportsQuery, fetchReportsSchema } from 'src/@reports/resolvers/schema';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';

const FETCH_SIZE = 1000;
export const fetchReportsData = (
  client: ApolloClient<object>,
  count = FETCH_SIZE,
  sortString: any,
  filterString: any,
  cursor?: string | null
) => {
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;
    const queryParams = `
      startTime: "${dateTimeInQuery(from, 'start')}"
      endTime: "${dateTimeInQuery(to, 'end')}"
      first:${count}
      ${cursorParam}
    `;
    const GET_REPORTS = gql`
      query {
        ${fetchReportsQuery}(${queryParams} ${sortString} ${filterString}) ${fetchReportsSchema}
      }
    `;

    client
      .query({
        query: GET_REPORTS,
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[fetchReportsQuery] &&
          response.data[fetchReportsQuery].items
        ) {
          const { items: data, pageInfo, totalCount } = response.data[fetchReportsQuery];
          resolve({
            data,
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
