import React from 'react';
import { CustomMapControlButton } from './controls/CustomMapControls';
import { useLanguageSelectors } from 'src/app/reducers';
import { NavigationControl } from 'react-map-gl';
import { useResetView } from '../Map/hooks/useResetView';
import { CustomMapProps } from '../Map/map.types';
import { MapSettingsControl } from './controls/MapSettingsControl/MapSettingsControl';

interface MapControl {
  showCompass?: boolean;
  resetView?: boolean;
  mapSettings?: boolean;
  setMouseOver?: (isSet: boolean) => void;
  customMapProps?: CustomMapProps;
}

export const MapControl = ({
  resetView = false,
  showCompass = false,
  mapSettings = false,
  setMouseOver,
  customMapProps,
}: MapControl) => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: { backToCenter },
    },
  } = languageSelectors.getLanguage();

  // const configSelectors = useConfigSelectors();
  //  const showSettings = configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL);
  const resetViewPort = useResetView(customMapProps);
  return (
    <div
      className="navigation-control"
      onMouseEnter={() => setMouseOver && setMouseOver(true)}
      onMouseLeave={() => setMouseOver && setMouseOver(false)}>
      <NavigationControl style={{ position: 'static' }} showCompass={showCompass} />
      {resetView && (
        <CustomMapControlButton
          title={backToCenter}
          onClick={resetViewPort}
          className="navigation-control-home"
          iconName="ic-ui-home"
        />
      )}
      {mapSettings && <MapSettingsControl />}
      {}
    </div>
  );
};
