import React, { FC, useContext } from 'react';
import { Filter, FilterRow } from '@ems/client-design-system';
import { useFilterDataSelectors, useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
import { capitalizeObjectKeys } from 'src/utils';
import {
  translateLabels,
  convertDataToFilterForm,
  getIfFiltersEmpty,
} from 'src/@reports/functions/reports';
import { ReportsDispatchContext } from 'src/@reports/providers/ReportsStateProvider';
import { useFilterSelectors } from 'src/@reports/reducers';
import { updateSelectedItems, clearSelectedItems } from 'src/@reports/actions';

export const FilterContainer: FC = () => {
  const dispatcher = useContext(ReportsDispatchContext);
  const filterSelectors = useFilterSelectors();
  const filteredItems = filterSelectors.getFilters();
  const configSelectors = useConfigSelectors();
  const {
    reports: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const reportFilterData = filtersSelectors.getReportsFilterData();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound },
      lists: { reportFilterCategories, downloadFormats, reportDataTypes, reportingPeriods },
    },
  } = languageSelectors.getLanguage();
  const convertedDataList = capitalizeObjectKeys({
    ...reportDataTypes,
    ...reportingPeriods,
    ...downloadFormats,
  });

  const convertedFilterData = {};
  Object.entries(reportFilterData).map(([key, value]) => {
    convertedFilterData[key] = convertDataToFilterForm(value);
  });
  const filterComponents: JSX.Element[] = [];
  availableCategoryList.map((category: string) => {
    const originalItems = convertedFilterData[category];
    if (originalItems !== undefined) {
      filterComponents.push(
        <Filter
          key={category}
          categoryName={reportFilterCategories[category]}
          filterItems={translateLabels(originalItems, convertedDataList)}
          selectedItems={filteredItems[category]}
          updateItems={(items: any[]) => {
            updateSelectedItems(category, items, dispatcher);
          }}
          languageData={{ clearValue, filterValue, noMatchesFound }}
        />
      );
    }
  });

  return (
    <>
      <FilterRow
        filters={filterComponents}
        clearFilters={() => clearSelectedItems(dispatcher)}
        clearDisabled={getIfFiltersEmpty(filteredItems)}
        languageData={{ clearFilters }}
      />
    </>
  );
};
