import { IInfRuleFormErrors } from 'src/@settings/interfaces';

export interface IGateRuleItem {
  id: string;
  name: string;
  numDirections?: number;
  intersectionMode?: string;
}

export interface IGateRuleArray {
  gates: IGateRuleItem[];
  onGateChange: (gateRule: IGateRuleItem) => void;
  isReadOnly: boolean;
  selectedGateItem: IGateRuleItem;
  formErrors: IInfRuleFormErrors;
}

export interface IGateOption {
  label: string;
  key: string;
  numDirections: number;
  intersectionMode?: string;
}

export enum numDirectionsRadioOptions {
  oneDirection = 1,
  bothDirections = 2,
}
