import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { RegularLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@userHelp/containers';

const UserHelpScreen = ({ location, updateUrl }) => (
  <RegularLayout>
    <div className="container-single">
      <ContentContainer />
    </div>
  </RegularLayout>
);

export const UserHelp = withRouteProps(UserHelpScreen);
