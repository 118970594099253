import React from 'react';

// Types
import { TUpdateUrl } from 'src/app/props';

// Components
import { DateFilter, PageHeader } from 'src/components';

// Functions
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';

// Hooks
import { useAircraftMappings } from '../providers';
import { useLanguageSelectors } from 'src/app/reducers';
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';

export const AMPageHeader = ({ updateUrl }: { updateUrl: TUpdateUrl }): JSX.Element => {
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  // Language Strings
  const {
    screens: {
      settings: {
        tabs: { aircraftMappings: pageTitle },
      },
    },
  } = translationData;

  // State
  const {
    state: {
      data: { totalCount },
      isLoading,
    },
  } = useAircraftMappings();

  // Date picker
  const DateFilterHOC = withQueryStringUpdater({
    Container: DateFilter,
    updateUrl,
    defaultRange: 'month',
  });

  return (
    <div className="settings__heading">
      <PageHeader title={pageTitle}>
        <span className="settings__heading-count page-count">{`${
          totalCount < 0 || isLoading ? BLANK_STRING_PLACEHOLDER : totalCount
        } Total`}</span>
      </PageHeader>
      <div>
        <DateFilterHOC />
      </div>
    </div>
  );
};
