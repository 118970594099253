/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { DateTime } from 'luxon';
import { TableIcon, ColumnHeader, SafeLink } from 'src/components';
import { sortTable } from 'src/@reports/actions';
import {
  IFilterTypes,
  IDownloadData,
  IFetchedData,
  IModifiedData,
  ISortSelectors,
} from 'src/@reports/interfaces';
import { tableDateTimeFormatLong, tableDateTimeFormatShort } from 'src/utils';

export const convertDataToFilterForm = (
  data: any,
  iconType = '',
  dataForm: 'array' | 'object' = 'array'
): IFetchedData[] => {
  // Find and add an extra className if required based on icon type
  const className =
    {
      ac: 'filter-icon-ac',
      co: 'filter-icon-co',
    }[iconType] || '';

  if (dataForm === 'object') {
    return data && data.length
      ? data.map(({ id, name }) => ({
          key: id,
          label: name,
          icon: iconType
            ? TableIcon({ name: name.toLowerCase(), prefix: iconType, size: 24, className })
            : undefined,
        }))
      : [];
  } else {
    return data && data.length
      ? data.map(item => ({
          key: item,
          label: item,
          icon: iconType
            ? TableIcon({ name: item.toLowerCase(), prefix: iconType, size: 24, className })
            : undefined,
        }))
      : [];
  }
};

const createDownloadLink = (downloadList, translationData) => {
  const links: JSX.Element[] = [];
  if (downloadList.length > 0) {
    downloadList.map((item: IDownloadData) => {
      const { id, format, resource } = item;
      if (resource) {
        const linkText = translationData[format];
        links.push(
          <SafeLink
            key={`${format}_${id}`}
            className="reports_downloadlink"
            href={resource.uri}
            download>
            {linkText}
          </SafeLink>
        );
      }
    });

    if (links.length > 0) {
      return links;
    }

    return null;
  } else {
    return null;
  }
};

const getDisplayPeriod = (period: string, startTime: string): string => {
  const convertedTime = DateTime.fromISO(startTime, { setZone: true });
  switch (period) {
    case 'Daily':
      return `${convertedTime.monthLong} ${convertedTime.day} ${convertedTime.year}`;
    case 'Monthly':
      return `${convertedTime.monthLong} ${convertedTime.year}`;
    case 'Quarterly':
      return `Q${convertedTime.quarter} ${convertedTime.year}`;
    default:
      return `${convertedTime.year}`;
  }
};

const createDisplayName = ({ templateName, reportInterval, startTime }): string => {
  const displayPeriod = getDisplayPeriod(reportInterval, startTime);
  return `${templateName} ${displayPeriod}`;
};

type TObject = Record<string, unknown>;
export const formatReportsData = (
  data: any,
  translationData: any,
  twelveHourFormat: boolean
): Map<string, IModifiedData> => {
  const reportData = new Map<string, IModifiedData>();
  for (const [id, report] of data) {
    report.displayName = createDisplayName(report);
    report.displayTime = tableDateTimeFormatLong(report.lastUpdateTime, twelveHourFormat);
    report[`displayTime-short`] = tableDateTimeFormatShort(report.lastUpdateTime, twelveHourFormat);
    report.displayDownload = createDownloadLink(report.downloadList, translationData);
    reportData.set(id, report);
  }
  return reportData;
};

export const formatReportsHeaders = (
  resultSize: number,
  isLoading: boolean,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dispatcher: any,
  sortSelectors: ISortSelectors,
  translationData: any
): any => {
  const {
    fields: { reports: rowHeaders },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const displayName = ColumnHeader({
    sortName: 'templateName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.name,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'lastUpdateTime',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.updated,
      abbreviation: null,
      sortBy,
    },
  });
  const reportInterval = ColumnHeader({
    sortName: 'reportInterval',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.period,
      abbreviation: null,
      sortBy,
    },
  });
  const displayDownload = ColumnHeader({
    sortName: 'download',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: rowHeaders.download,
      abbreviation: null,
      sortBy,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.assign({}, rowHeaders, {
    displayName,
    displayTime,
    reportInterval,
    displayDownload,
  });
};

export const translateLabels = (items: any[], translationList: TObject): any[] => {
  if (items === undefined) {
    return items;
  }

  items.map(item => {
    const translatedLabel = translationList[item.label];
    if (translatedLabel !== undefined) {
      item.label = translatedLabel;
    }
  });

  return items;
};

export const getIfFiltersEmpty = (filters: IFilterTypes): boolean => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => +accumulator + +currentValue.length,
    0
  );

  return totalFilterLength === 0;
};
