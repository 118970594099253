import React, { FC, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { IClimbProfileGraphContainer } from 'src/@infringements/interfaces';
import { BlockHeader, LineGraph, Card } from '@ems/client-design-system';
import { convertXYTPointstoArray } from 'src/utils/dataConverter';
// Reducers
import { useConfigSelectors } from 'src/app/reducers';
// Constants
import { THEME_DEFAULT_COLOR, THEME_DEFAULT_OPERATION_TYPE } from 'src/constants';
// Utils
import { getOperationTheme } from 'src/utils';

export const ClimbProfileGraphContainer: FC<IClimbProfileGraphContainer> = ({
  operationData,
  ruleData,
  type,
  infringementTime,
  selectedTrackTheme,
}) => {
  const [lineValue, setLineValue] = useState<number>(-1);
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      climbProfile: { title },
    },
  } = languageSelectors.getLanguage();

  const hasProfile: boolean =
    operationData && operationData.profile && !!Object.keys(operationData.profile).length;

  const operationType: string = hasProfile
    ? operationData.operationType.toLowerCase()
    : THEME_DEFAULT_OPERATION_TYPE;
  const currentTheme = getOperationTheme(selectedTrackTheme);
  const lineColor = currentTheme[operationType.toLowerCase()] || THEME_DEFAULT_COLOR;

  const startTimeInSeconds =
    operationData && operationData.startTime
      ? DateTime.fromISO(operationData.startTime).toSeconds()
      : 0;

  useEffect(() => {
    if (hasProfile && startTimeInSeconds > 0) {
      const convertedInfTime = DateTime.fromISO(infringementTime).toSeconds();
      const timeDifference = convertedInfTime - startTimeInSeconds;

      const convertedArray = convertXYTPointstoArray(operationData.profile);
      if (timeDifference < convertedArray.length) {
        setLineValue(convertedArray[timeDifference][0]);
      }
    }
  }, [startTimeInSeconds]);

  return (
    <div className="block">
      <BlockHeader title={`${title}`} loading={!hasProfile} />
      <Card>
        <div className="card__padder">
          {hasProfile ? (
            <LineGraph
              scatterData={operationData.profile}
              maxYValue={15000}
              type={type}
              stepSizeY={5000}
              stepSizeX={50000}
              lineValue={lineValue}
              flightDistance={ruleData.flightDistance}
              lineColor={lineColor}
              units={units}
            />
          ) : (
            <div className="graph--loading" />
          )}
        </div>
      </Card>
    </div>
  );
};
