import React, { useState, useRef } from 'react';

// Providers
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { Dialog, Button, Icons, displayToast } from '@ems/client-design-system';
import {
  DialogBody,
  GenerateNoiseScenarioDialog,
} from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/Dialog.styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

// Queries
import {
  COMPLETE_CONTOUR_BUCKET_UPLOAD,
  GET_CONTOUR_BUCKET_UPLOAD,
  GET_CONTOUR_UPLOAD_STATUS_AEDT,
  GET_CONTOUR_UPLOAD_STATUS_INM,
} from 'src/@settings/containers/Modeling/Contours/queries';

// Types
import {
  IGetContourUploadBucketResponse,
  ICompleteContourBucketUploadResponse,
  ICompleteContourUploadVariables,
  IGetContourUploadStatusResponse,
  IContourUploadBucketFile,
  IGetContourUploadStatusTypes,
} from 'src/@settings/containers/Modeling/Contours/interfaces';
import { IAircraftNoiseModelingConfig } from 'src/app/props';
import { TScenarioModelTypes } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';
import { TRecord } from 'src/@settings/containers/Modeling/AircraftAssignments/interfaces';

// Styles
import styled from 'styled-components/macro';

const ContourDialogRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ContourFileNameWrapper = styled.div`
  margin-left: 10px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UploadDialog = ({
  isOpen,
  onRefetch,
  onClose,
}: {
  isOpen: boolean;
  onRefetch: () => void;
  onClose: (refetchRquired?: boolean) => void;
}) => {
  // Selectors
  const configSelectors = useConfigSelectors();
  const appConfig = configSelectors.getConfig();
  const licenseConfig: IAircraftNoiseModelingConfig = appConfig.aircraftNoiseModeling;
  const modelList = licenseConfig.software;
  const activeModels = Object.keys(modelList).filter(
    key => modelList[key].enabled && modelList[key].licenseConfirmed
  );
  const selectedNoiseModel = licenseConfig.selectedModel;
  const languageSelector = useLanguageSelectors();

  const translationData = languageSelector.getLanguage() as TRecord;
  const {
    components: {
      dialogs: {
        uploadingContourCompleted: uploadingContourCompletedString,
        uploadingContourError: uploadingContourErrorString,
        uploadingContourProgress: uploadingContourProgressString,
      },
    },
  } = translationData;

  // Ref
  const inputFile = useRef<HTMLInputElement>(null);

  // States
  const [contourFile, setContourFile] = useState<File | undefined>();
  const [noiseModel, setNoiseModel] = useState<TScenarioModelTypes>(selectedNoiseModel);

  // Queries
  const [getUploadStatusINM, { stopPolling: stopPollingINM }] = useLazyQuery<
    IGetContourUploadStatusResponse,
    ICompleteContourUploadVariables
  >(GET_CONTOUR_UPLOAD_STATUS_INM, {
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ aircraftNoiseContourUploadResult: { status } }) => {
      handleOnComplete(status, stopPollingINM);
    },
  });

  const [getUploadStatusAEDT, { stopPolling: stopPollingAEDT }] = useLazyQuery<
    IGetContourUploadStatusResponse,
    ICompleteContourUploadVariables
  >(GET_CONTOUR_UPLOAD_STATUS_AEDT, {
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ aircraftNoiseContourUploadResult: { status } }) => {
      handleOnComplete(status, stopPollingAEDT);
    },
  });

  const [completeBucketUpload] = useMutation<
    ICompleteContourBucketUploadResponse,
    ICompleteContourUploadVariables
  >(COMPLETE_CONTOUR_BUCKET_UPLOAD(noiseModel));

  const [getContourBucket] = useMutation<IGetContourUploadBucketResponse>(
    GET_CONTOUR_BUCKET_UPLOAD(noiseModel),
    {
      onCompleted: ({
        beginAircraftNoiseContourAEDTUpload: AEDTResponse,
        beginAircraftNoiseContourINMUpload: INMResponse,
      }) => {
        let id: string;
        let files: IContourUploadBucketFile[];

        if (noiseModel === 'AEDT') {
          id = AEDTResponse.id;
          files = AEDTResponse.files;
        } else {
          id = INMResponse.id;
          files = INMResponse.files;
        }

        if (files[0]) {
          const { contentType, uploadUri } = files[0];
          const uri = uploadUri.uri;
          uploadToBucket(uri, contourFile, contentType)
            // Successful s3 upload, close job
            .then(() => {
              completeBucketUpload({
                variables: {
                  jobId: id,
                },
              })
                // Successful close job mutation, check status
                .then(() => {
                  if (noiseModel === 'AEDT') {
                    getUploadStatusAEDT({
                      variables: {
                        jobId: id,
                      },
                    });
                  } else {
                    getUploadStatusINM({
                      variables: {
                        jobId: id,
                      },
                    });
                  }
                })
                // Failed close job mutation, error
                .catch(error => {
                  console.error(error);
                  useToast('danger');
                });
            })
            // Failed s3 upload, error
            .catch(error => {
              console.error(error);
              useToast('danger');
              void completeBucketUpload({
                variables: {
                  jobId: id,
                },
              });
            });
        } else {
          useToast('danger');
        }
      },
    }
  );

  const uploadToBucket = async (uri: string, file: File, contentType: string) => {
    await fetch(uri, {
      method: 'PUT',
      headers: {
        'content-type': contentType,
      },
      body: file,
    })
      .then(response => response)
      .catch(err => Promise.reject(err));
  };

  const useToast = (type: 'danger' | 'success' | 'primary') => {
    let message: string;

    switch (type) {
      case 'danger':
        message = uploadingContourErrorString;
        break;
      case 'success':
        message = uploadingContourCompletedString;
        break;
      case 'primary':
      default:
        message = uploadingContourProgressString;
        break;
    }

    if (type !== 'primary') {
      handleFileReset();
    }

    displayToast({
      key: 'uploadStatusToast',
      message,
      intent: type,
    });
  };

  const handleUpload = () => {
    onClose();
    useToast('primary');

    void getContourBucket().catch(() => {
      useToast('danger');
    });
  };

  const handleFileReset = () => {
    if (inputFile.current) {
      inputFile.current.value = null;
      inputFile.current.files = null;
    }
    setContourFile(undefined);
    onClose();
  };

  const handleFileAttached = () => {
    if (inputFile.current.files[0]) {
      setContourFile(inputFile.current.files[0]);
    }
  };

  const handleOnComplete = (status: IGetContourUploadStatusTypes, stopPolling: () => void) => {
    if (status !== 'Processing') {
      stopPolling();
    }
    if (status === 'Error') {
      useToast('danger');
    }
    if (status === 'Completed') {
      useToast('success');
      onRefetch();
    }
  };

  return (
    <>
      <GenerateNoiseScenarioDialog
        isOpen={isOpen}
        onClose={() => {
          handleFileReset();
        }}>
        <Dialog.Body>
          <DialogBody>
            <ContourDialogRow>
              <Button
                className="aircraft-noise-contours-settings__upload-button"
                style="primary"
                onClick={() => inputFile.current.click()}
                leftIcon={<Icons iconName={`ic-ui-add`} title="Upload" size={18} />}>
                {`Select file`}
                {
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    accept=".zip"
                    onChange={handleFileAttached}
                    style={{ display: 'none' }}
                  />
                }
              </Button>
              <ContourFileNameWrapper>
                <span>{contourFile ? contourFile.name : 'No file selected'}</span>
              </ContourFileNameWrapper>
            </ContourDialogRow>
            <ContourDialogRow>
              <label htmlFor={`noiseModels`} style={{ marginRight: `10px` }}>
                Contour Type:
              </label>
              <select
                name="noiseModels"
                id="noiseModels"
                value={noiseModel}
                onChange={e => {
                  setNoiseModel(e.target.value as TScenarioModelTypes);
                }}>
                {activeModels.map((model, key) => (
                  <option key={key} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </ContourDialogRow>
          </DialogBody>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.FooterActions>
            <Button
              onClick={() => {
                handleFileReset();
              }}>
              Close
            </Button>

            <Button
              style="primary"
              onClick={() => {
                handleUpload();
              }}
              disabled={!contourFile}>
              Upload
            </Button>
          </Dialog.FooterActions>
        </Dialog.Footer>
      </GenerateNoiseScenarioDialog>
    </>
  );
};
