import { createContext } from 'react';
import {
  configInitialState,
  infringementRulesInitialState,
  filtersInitialState,
  rolesInitialState,
  scenariosInitialState,
  languageInitialState,
  userHelpInitialState,
} from 'src/app/reducers/initialStates';

export const GlobalStateContext = createContext({
  config: configInitialState,
  filters: filtersInitialState,
  language: languageInitialState,
  roles: rolesInitialState,
  infringementRules: infringementRulesInitialState,
  scenarios: scenariosInitialState,
});

export const PushNoticesStateContext = createContext(userHelpInitialState);
