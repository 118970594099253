import { IGetMonitorLocationQueryResponse, IMonitorLocation } from '../../interfaces';
import { GET_MONITOR_LOCATIONS } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect } from 'react';
import { useLocationManagementContext } from '../../Context/LocationManagementContext';
import { LocationManagementActions } from '../../Context/LoctionManagementReducer';

export const useFetchLocationsList = (deviceMonitorLocation?: IMonitorLocation | undefined) => {
  const {
    dispatch,
    state: { locationsList },
  } = useLocationManagementContext();
  const {
    loading: isLoadingAllMonitorLocations,
    data: monitorLocationsData,
    refetch: refetchMonitorLocations,
  } = useQuery<IGetMonitorLocationQueryResponse>(GET_MONITOR_LOCATIONS, {
    skip: !!locationsList.length || !deviceMonitorLocation,
  });

  // Initialize the list
  useEffect(() => {
    if (!locationsList.length && !!monitorLocationsData && !!deviceMonitorLocation) {
      addNewMonitorLocation(deviceMonitorLocation);
    }
  }, [monitorLocationsData, deviceMonitorLocation, locationsList]);

  const addNewMonitorLocation = useCallback(
    (newDeviceLocation: IMonitorLocation) => {
      const list = locationsList.length
        ? locationsList
        : monitorLocationsData?.monitorLocations ?? [];
      list.push(newDeviceLocation);
      list.sort((a, b) => a.id - b.id);
      dispatch({
        type: LocationManagementActions.SET_LOCATIONS_LIST,
        payload: { locationsList: [...list] },
      });
    },
    [locationsList, monitorLocationsData, dispatch]
  );

  const updateMonitorLocationList = useCallback(
    updatedDeviceLocation => {
      const list = locationsList.map(location =>
        location.id === updatedDeviceLocation.id ? updatedDeviceLocation : location
      );
      dispatch({
        type: LocationManagementActions.SET_LOCATIONS_LIST,
        payload: { locationsList: list },
      });
    },
    [locationsList, dispatch]
  );

  return {
    isLoadingAllMonitorLocations,
    locationsList,
    refetchMonitorLocations,
    addNewMonitorLocation,
    updateMonitorLocationList,
  };
};
