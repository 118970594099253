import themeToken from 'src/styles/themes.json';
import { hexToWgl } from './TrackDensityUtils';
const { colorScheme1, colorScheme2, colorScheme3, colorScheme4 } = themeToken.trackDensity;

export const vertexShaderSource = `
    uniform mat4 u_matrix;
    attribute vec2 a_position;
    attribute vec2 a_texture;

    varying vec2 v_texcoord;

    void main() {
        gl_Position = u_matrix * vec4(a_position, 0.0, 1.0);
        v_texcoord = a_texture;
    }
    `;

export const fragmentShaderSource = `
    precision mediump float;
    varying vec2 v_texcoord;
    uniform sampler2D u_texture;

    vec3 getColorStop(int index) {
        vec3 colors[6];

        int COLOR_SCHEME = 1;

        if (COLOR_SCHEME == 1) // Blue-green-yellow
        {
                colors[0] = ${hexToWgl(colorScheme1.color0)};
                colors[1] = ${hexToWgl(colorScheme1.color1)};
                colors[2] = ${hexToWgl(colorScheme1.color2)};
                colors[3] = ${hexToWgl(colorScheme1.color3)};
                colors[4] = ${hexToWgl(colorScheme1.color4)};
                colors[5] = ${hexToWgl(colorScheme1.color5)};
        }
        else if (COLOR_SCHEME == 2)
        {
                colors[0] = ${hexToWgl(colorScheme2.color0)};
                colors[1] = ${hexToWgl(colorScheme2.color1)};
                colors[2] = ${hexToWgl(colorScheme2.color2)};
                colors[3] = ${hexToWgl(colorScheme2.color3)};
                colors[4] = ${hexToWgl(colorScheme2.color4)};
                colors[5] = ${hexToWgl(colorScheme2.color5)};
        }
        else if (COLOR_SCHEME == 3)
        {
                colors[0] = ${hexToWgl(colorScheme3.color0)};
                colors[1] = ${hexToWgl(colorScheme3.color1)};
                colors[2] = ${hexToWgl(colorScheme3.color2)};
                colors[3] = ${hexToWgl(colorScheme3.color3)};
                colors[4] = ${hexToWgl(colorScheme3.color4)};
                colors[5] = ${hexToWgl(colorScheme3.color5)};
        }
        else if (COLOR_SCHEME == 4)
        {
                colors[0] = ${hexToWgl(colorScheme4.color0)};
                colors[1] = ${hexToWgl(colorScheme4.color1)};
                colors[2] = ${hexToWgl(colorScheme4.color2)};
                colors[3] = ${hexToWgl(colorScheme4.color3)};
                colors[4] = ${hexToWgl(colorScheme4.color4)};
                colors[5] = ${hexToWgl(colorScheme4.color5)};
        }

        for(int i = 0; i < 7; i++){
            if(i == index) {
                return colors[i];
            }
        }
    }

    vec3 colorRamp(float value) {
        float blockSize = 1.0 / 5.0; // 1 / colors array length - 1
        int colorStopIndex = int(value / blockSize);
        float valueResidual = value - (float(colorStopIndex) * blockSize);
        float colorValue = valueResidual / blockSize;
        vec3 colorStop1 = getColorStop(colorStopIndex);
        vec3 colorStop2 = getColorStop(colorStopIndex + 1);
        float deltaR = colorStop2.r - colorStop1.r;
        float deltaG = colorStop2.g - colorStop1.g;
        float deltaB = colorStop2.b - colorStop1.b;
        float r = colorStop1.r + (deltaR * colorValue);
        float g = colorStop1.g + (deltaG * colorValue);
        float b = colorStop1.b + (deltaB * colorValue);
        return vec3(r, g, b);
    }

    float adjustValue(float x) {
        // These functions transform the input values in a non-linear fashion,
        // to boost the smaller values disproportionately and make them easier to see.
        // The best way to visualize the effect of these equations is to use a graphing tool like graphtoy.com.
        // x = clamp(log(x * 100.0) / 5.0, 0.0, 1.0);
        // x = pow(x, 0.25);
        return clamp(x, 0.0, 1.0);
    }

    float adjustAlpha(float x) {
        x *= 10.0;
        // x = clamp(log(x * 100.0) / 5.0, 0.0, 1.0);
        return clamp(x, 0.0, 1.0);
    }

    void main() {
        vec4 samp = texture2D(u_texture, v_texcoord);
        float value = (samp.r * 16581375.0 + samp.g * 65535.0 + samp.b * 255.0) / {{trackDensityMaxCellCount}}.0;
        float valueAdjusted = adjustValue(value);
        vec3 rgb = colorRamp(valueAdjusted);
        float a = adjustAlpha(value);
        gl_FragColor = vec4(rgb.r, rgb.g, rgb.b, a);
    }
`;
