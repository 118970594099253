import React, { FC, useContext, useEffect } from 'react';
// interfaces
import { IDataContainer } from 'src/@airtrak/interfaces';
// selectors
import { useScenariosSelectors } from 'src/app/reducers';
// actions
import { loadAirtrakScenarios, loadScenarioReprocessStatus } from 'src/app/actions';
import { updateDateRangeAction } from 'src/app/actions/dateRangeActions';
// client
import { useApolloClient } from '@apollo/react-hooks';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
// helpers
import { dispatcher } from 'src/utils';

export const DataContainer: FC<IDataContainer> = ({ children, route }) => {
  const globalDispatcher = useContext(GlobalDispatchContext);
  const client = useApolloClient();
  const scenariosSelector = useScenariosSelectors();
  const scenario = scenariosSelector.getActiveScenario();
  const scenarioList = scenariosSelector.getScenarios();
  const extractDate = (dateTime: string) => dateTime.split('T')[0];

  useEffect(() => {
    loadAirtrakScenarios(client, globalDispatcher);
  }, []);

  useEffect(() => {
    loadScenarioReprocessStatus(client, globalDispatcher, scenario.id);
  }, [scenarioList]);

  useEffect(() => {
    if (scenario !== null) {
      // startTime format example: 1995-12-31T19:00:00-05:00
      const dateRange = {
        from: extractDate(scenario.startTime),
        to: extractDate(scenario.endTime),
      };
      dispatcher.handleDispatch(updateDateRangeAction(dateRange, route));
    }
  }, [scenario]);
  return <>{children}</>;
};
