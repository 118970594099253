import { useContext } from 'react';
// provider
import { SettingsStateContext } from 'src/@settings/provider/SettingsStateProvider';
// interfaces
import { ILoadFactorssSelector, ILoadFactorsState, IActions } from 'src/@settings/interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// utils
import uuid from 'uuid';

export const useLoadFactorsSelector: () => ILoadFactorssSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const loadFactorsState: ILoadFactorsState = state.loadFactors;
  return useSelectors(loadFactorsState, (state: ILoadFactorsState) => ({
    getLoadFactors: () => state.airlineLoadFactors,
    getIfLoading: () => state.isLoading,
  }));
};

export const loadFactorsInitialState: ILoadFactorsState = {
  airlineLoadFactors: [],
  isLoading: false,
};

const addTableId = (newData: any) => {
  const data = new Array();
  if (newData && newData.length > 0) {
    for (const mapping of newData) {
      data.push({
        ...mapping,
        tableId: uuid.v4(),
      });
    }
  }
  return data;
};

export const loadFactorsReducer = (state: ILoadFactorsState, action: IActions) => {
  switch (action.type) {
    case actionTypes.LOAD_FACTORS_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case actionTypes.GET_LOAD_FACTORS:
      if (action.loadFactorsData) {
        const loadFactorsData = action.loadFactorsData;
        const itemsMap = addTableId(loadFactorsData);
        return Object.assign({}, state, {
          airlineLoadFactors: itemsMap,
          isLoading: false,
        });
      }
    default:
      return state;
  }
};
