import React, { FC, useState, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
// global state
import {
  useConfigSelectors,
  useFilterDataSelectors,
  useLanguageSelectors,
  useRolesSelectors,
} from 'src/app/reducers';
import { resetAndFetchData } from 'src/@infringements/actions';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSortSelectors } from 'src/@infringements/reducers';
// hocs
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
// containers
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import { SkeletonText, Overlay, Icons, Button, Dropdown } from '@ems/client-design-system';
import { PageHeader, DateFilter } from 'src/components';
// fns
import { IHeaderContainer } from 'src/@infringements/interfaces';
import { useDataSelectors } from 'src/@infringements/reducers/dataReducer';
// mutations
import { UPDATE_INFRINGEMENTS } from 'src/@infringements/mutations';
// utils
import { capitalizeObjectKeys, formatNumber, setTabTitle } from 'src/utils';
// constants
import { INFRINGEMENTS } from 'src/constants';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  const client = useApolloClient();
  const dispatcher = useContext<any>(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const rolesSelectors = useRolesSelectors();
  const canUpdate = rolesSelectors.hasPermission('Infringement.Update');

  const dataSelectors = useDataSelectors();
  const { data, selectedData: selectedIndexes } = dataSelectors.getDataInformation();
  const selectedIds = dataSelectors.getSelectedIDs(selectedIndexes);
  const totalCount = dataSelectors.getTotalCount();
  const candidatesSelected = dataSelectors.getIfCandidatesSelected();
  const showCandidates = dataSelectors.getCandidatesEnabled();

  const [overlayState, setoverlayState] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [updateInfringementsCall] = useMutation(UPDATE_INFRINGEMENTS, {
    onCompleted() {
      resetAndFetchData(client, dispatcher, {
        resultSize,
        sortString,
        showCandidates,
      });
    },
  });

  const configSelectors = useConfigSelectors();
  const {
    infringements: {
      grid: { statusColors },
    },
    grid: { resultSize },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { update, cancel, bulkEdit },
      labels: {
        table: { totalItems },
      },
      lists: { infringementStatuses },
      overlay: {
        bulkEdit: {
          infringements: {
            update: overlayTitle1,
            infringement: overlayTitle2,
            infringements: overlayTitle2Plural,
          },
        },
      },
      dropdowns: {
        empty: statusEmpty,
        status: { label: statusLabel, placeholder: statusMultiple },
      },
    },
    screens: {
      infringements: { title },
    },
  } = languageSelectors.getLanguage();

  setTabTitle(title);

  const [placeHolder, setPlaceHolder] = useState(statusMultiple);

  const DateFilterHoc = withQueryStringUpdater({ Container: DateFilter, updateUrl });

  const translationDataList = capitalizeObjectKeys({
    ...infringementStatuses,
  });

  const statusItems: any[] = [];
  const filtersSelectors = useFilterDataSelectors();
  const statusOptions = filtersSelectors.getInfringementFilterData().statuses;
  statusOptions.map((key: string) => {
    const option = {
      key,
      label: translationDataList[key],
      icon: `ui-circle`,
      fill: statusColors[key],
    };
    statusItems.push(option);
  });

  const updateInfringements = selectedData => {
    const infringementsToUpdate: number[] = [];

    selectedData.map(item => {
      const infringementId = showCandidates ? data[item].infringementId : data[item].id;
      infringementsToUpdate.push(infringementId);
    });

    if (selectedItem) {
      updateInfringementsCall({
        variables: { ids: infringementsToUpdate, status: selectedItem.key },
      });

      handleOverlayClose();
      dispatcher({ type: dataActionTypes.RESET_DATA });
    }
  };

  const handleOverlayState = (bool: boolean) => {
    setPlaceHolder(statusMultiple);
    const infringementsToUpdate: any = [];

    selectedIndexes.map(item => {
      infringementsToUpdate.push(data[item].status);
    });

    const statusIsTheSame = infringementsToUpdate.every((val, i, arr) => val === arr[0]);

    if (statusIsTheSame) {
      if (infringementsToUpdate[0] === null) {
        setPlaceHolder(statusEmpty);
      } else {
        const selected = statusItems.filter(obj => obj.key === infringementsToUpdate[0]);

        setSelectedItem(selected[0]);
      }
    }

    setoverlayState(bool);
  };

  const handleOverlayClose = () => {
    setSelectedItem(null);
    setoverlayState(false);
    setbuttonDisabled(true);
  };

  const handleUpdateState = (item: any) => {
    if (selectedItem !== undefined && selectedItem !== null && item.label !== selectedItem.label) {
      setbuttonDisabled(false);
    } else if (selectedItem === undefined || selectedItem === null) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }

    setSelectedItem(item);
  };

  // when no item selected, then action button should be disabled
  const disableBulkEditBtn = selectedIndexes.length < 1 || candidatesSelected ? true : false;

  return (
    <>
      <PageHeader title={title}>
        <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
          <span className="page-count">
            {totalCount && formatNumber(totalCount)} {totalItems}
          </span>
        </SkeletonText>
        <div className="page-tools">
          <ExportContainer
            source={INFRINGEMENTS}
            selectedIds={selectedIds}
            tableData={data.filter(item => selectedIds.includes(item.id))}
          />
          {canUpdate && (
            <Button
              style="primary"
              size="s"
              leftIcon={<Icons iconName={`ic-ui-edit`} size="20" />}
              disabled={disableBulkEditBtn}
              onClick={() => handleOverlayState(!overlayState)}>
              {bulkEdit}
            </Button>
          )}
          <DateFilterHoc />
        </div>
        <Overlay
          openState={overlayState}
          onClose={() => handleOverlayClose()}
          classes={['overlay--bulk-edit']}>
          <div className="overlay_header">
            <h3
              style={{
                fontWeight: 'normal',
              }}>{`${overlayTitle1} ${selectedIndexes.length} ${
              selectedIndexes.length > 1 ? overlayTitle2Plural : overlayTitle2
            }`}</h3>
            <Button
              size="s"
              style="subtle"
              iconOnly
              onClick={() => handleOverlayClose()}
              aria-label="Close modal"
              className="overlay_close"
              leftIcon={
                <Icons
                  iconName="ic-ui-cancel"
                  size={16}
                  style={{ cursor: 'pointer', fill: '#5a6872' }}
                />
              }
            />
          </div>
          <div className="overlay_content">
            <Dropdown
              label={statusLabel}
              placeholderValue={placeHolder}
              searchItems={statusItems}
              isNullable={false}
              updateSelection={item => handleUpdateState(item)}
              selectedItem={selectedItem}
            />
          </div>
          <div className="overlay_footer">
            <Button
              onClick={() => handleOverlayClose()}
              style="subtle"
              className="bulk-edit_cancel">
              {cancel}
            </Button>
            <Button
              style="primary"
              onClick={() => updateInfringements(selectedIndexes)}
              disabled={buttonDisabled}>
              {update}
            </Button>
          </div>
        </Overlay>
      </PageHeader>
    </>
  );
};
