import { useContext } from 'react';
import { ILanguageSelectors, ILanguageState, IStoreState } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateContext';
import { useSelectors } from 'src/utils/storeHelpers';
import { deepMergeAll } from 'src/utils';

export const useLanguageSelectors: () => ILanguageSelectors = () => {
  const globalState: IStoreState = useContext(GlobalStateContext);

  return useSelectors(globalState, (state: IStoreState) => {
    const languageState: ILanguageState = state.language;

    return {
      hasCompletedInitialLoad: () => languageState.initialLoadCompleted,
      getLanguage: () => languageState.language,
      hasAccessFailed: () => languageState.failedToAccessLanguage,
      getDefaultLanguageTag: () => globalState.config.config.globals.languageTag || 'en',
    };
  });
};

export const languageReducer = (state: ILanguageState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_LANGUAGE:
      return {
        language: deepMergeAll([state.language, action.payload], {}),
        failedToAccessLanguage: false,
        initialLoadCompleted: true,
      };
    case appActionTypes.LANGUAGE_ERROR:
      return Object.assign({}, state, {
        language: null,
        failedToAccessLanguage: true,
      });
    default:
      return state;
  }
};
