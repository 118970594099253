import React, { FC, useState, useEffect } from 'react';
// common components
import {
  EMapStyle,
  EMapType,
  StyledMap,
  DeckGLMap,
  MapControl,
  footToMeter,
} from '@ems/client-design-system';
// functions
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useInfringementMap } from 'src/app/functions/infringementsOnMap';
import { hexToRgb, useGeometryData, getOperationTheme } from 'src/utils';
// component
import { AMSLPopup } from 'src/components';
// Reducers
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// constants
import { INFRINGEMENT_RULE_TYPES } from 'src/constants';

// colors
import sassVars from 'src/styles/vars.module.scss';

// Map tags
import { vectorLayerToPoint } from 'src/utils/mapTagHelpers';
import { useMapTags } from 'src/utils/mapHelpers/tags/useMapTags';

const TRANSITION_DELAY = 1000;
const INITIAL_VIEW_STATE = {
  latitude: 0,
  longitude: 0,
  zoom: 10,
  minZoom: 7,
  maxZoom: 13,
  bearing: 0,
  pitch: 60,
};

export const MapContainer3D: FC<{
  operationId: string;
  operation: any;
  infTypeId: number;
  extraIds: number[];
  infringementType: string;
  infringementId: string;
  position: any;
  time: string;
}> = ({
  operationId,
  operation,
  infTypeId,
  extraIds,
  infringementType,
  infringementId,
  position,
  time,
}) => {
    // map ref
    const [mapNode, mapRef] = useMapRef();
    // get map apis
    const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
    // get map props from config
    const { viewportFromProps, mapStyle: mapStyleLight, mapStyleDark, ...mapProps } = useMapProps();
    // viewport in state
    const [viewport, setViewport] = useState(viewportFromProps);
    const [newZoomLevel, setNewZoomLevel] = useState(undefined);
    const [mapStyle, setMapStyle] = useState<EMapStyle>(EMapStyle.DARK);
    const lightMode = mapStyleLight;
    const darkMode = mapStyleDark;

    // get mapbox config values required to add source and styles
    const mapBoxConfig = useMapConfig();

    // Theme
    const configSelectors = useConfigSelectors();
    const selectedTrackTheme = configSelectors.getTheme('operations');
    const currentTheme = getOperationTheme(selectedTrackTheme);
    const trackColours = {
      Departure: hexToRgb(currentTheme.departure),
      Arrival: hexToRgb(currentTheme.arrival),
      Infringement: hexToRgb(mapStyle === EMapStyle.LIGHT ? sassVars.brandDarkened : sassVars.white),
      Selected: hexToRgb(sassVars.support03),
      // will be updated once colors finalised
      [INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT]: hexToRgb(sassVars.airGeometry),
      [INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT]: hexToRgb(sassVars.airGeometry),
      [INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT]: hexToRgb(sassVars.airGeometry),
    };

    // restrict map pan
    const onViewportChange = viewport => {
      if (
        Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
        Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
      ) {
        setViewport(viewport);
      }
    };

    // getting map style layers
    const { tracks } = useInfringementMap({
      instance: '3d-inf-summary',
      mapApis,
      mapBoxConfig,
      dateString: '',
      operationId,
      operation,
      infTypeId,
      infringementId,
      infringementType,
      showTracks: false,
      time,
    });

    const [spinner, showSpinner] = useState(true);
    const [tracks3D, updateTracks3D] = useState<[] | any[]>([]);
    const [geometries3D, updateGeometries3D] = useState<any[]>([]);
    const { geometryData } = useGeometryData({
      infringementType,
      as3d: true,
    });

    useEffect(() => {
      if (geometryData && geometryData.hasOwnProperty('features')) {
        updateGeometries3D(geometryData.features);
      }
    }, [geometryData]);

    useEffect(() => {
      if (mapLoaded) {
        showSpinner(false);
      }
    }, [mapLoaded]);

    // TODO: handle data loading/spinner state properly when getting all data from API later
    useEffect(() => {
      if (tracks.length) {
        updateTracks();
      } else {
        updateTracks3D([]);
        showSpinner(true);
        setTimeout(() => {
          showSpinner(false);
        }, TRANSITION_DELAY);
      }
    }, [JSON.stringify(tracks)]);

    const [initialViewState, updateViewState] = useState(INITIAL_VIEW_STATE);
    const { zoom, minZoom, maxZoom, bearing, pitch } = INITIAL_VIEW_STATE;
    useEffect(() => {
      if (position) {
        const { latitude, longitude } = position;
        updateViewState({
          latitude,
          longitude,
          zoom,
          minZoom,
          maxZoom,
          bearing,
          pitch,
        });
      }
    }, [position]);

    const updateTracks = () => {
      const tracksArray: any[] = tracks.map(track => {
        const {
          id,
          acid,
          aircraftCategory,
          aircraftType,
          airline,
          airportId,
          remoteAirportId,
          runwayName,
          operationType,
          operatorCategory,
          time: operationFullTime,
        } = track;
        return {
          type: 'Feature',
          id,
          geometry: {
            type: 'LineString',
            coordinates: track.points.map(point => [point.lon, point.lat, footToMeter(point.alt)]),
          },
          properties: {
            airportId,
            isInfringement: operationId === id,
            acid,
            operationType,
            operationFullTime,
            remoteAirportId,
            runwayName,
            aircraftCategory,
            aircraftType,
            airline,
            operatorCategory,
          },
        };
      });

      const newTrackData = Object.assign({}, { type: 'FeatureCollection', features: tracksArray });
      updateTracks3D([newTrackData]);
    };

    const toggleStyle = (mapStyle: EMapStyle) => {
      showSpinner(true);
      updateTracks3D([]);
      setTimeout(() => {
        updateTracks();
        showSpinner(false);
      }, TRANSITION_DELAY);
      setMapStyle(mapStyle === EMapStyle.LIGHT ? EMapStyle.DARK : EMapStyle.LIGHT);
    };

    const [resetToHome, setResetToHome] = useState(false);

    // get field labels from language selectors
    const languageSelectors = useLanguageSelectors();
    const {
      fields: { operations: opsFields },
      abbreviations,
    } = languageSelectors.getLanguage();
    const labels = Object.assign(opsFields, abbreviations);

    const [hoveredTrack, setHoveredTrack]: any = useState(null);
    const [clickedTrack, setClickedTrack]: any = useState(null);

    const { displayedMapTags, addTagToMap, removeDisplayedTag } = useMapTags();

    useEffect(() => {
      addTagToMap(clickedTrack);
    }, [clickedTrack]);

    const geometryIdsToDisplay = (geometryId: number): number[] => {
      if (geometryId) {
        if (infringementType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT) {
          return [geometryId, ...extraIds];
        } else {
          return [geometryId];
        }
      }
      return [];
    };

    const HoverTag = ({ longitude, latitude }: { longitude: number; latitude: number }) => {
      const matchingOperation = tracks.find(track => track.id === hoveredTrack.id);

      if (!matchingOperation) {
        return null;
      }
      return (
        <AMSLPopup
          labels={labels}
          pointData={vectorLayerToPoint({
            operation: matchingOperation,
            clickedElement: hoveredTrack,
          })}
          draggable={false}
          longitude={longitude}
          latitude={latitude}
          anchorMarker={false}
        />
      );
    };

    return (
      <DeckGLMap
        initialViewState={initialViewState}
        initialMapMode={EMapType.M3D}
        newZoomLevel={newZoomLevel}
        colors={trackColours}
        tracks2D={[]}
        tracks3D={tracks3D}
        geometries2D={[]}
        geometries3D={geometries3D}
        selectedGeometries={geometryIdsToDisplay(infTypeId)}
        infringementType={infringementType}
        showSpinner={spinner}
        hideToggleStyle
        hideToggleMode
        onStyleChange={toggleStyle}
        resetToHome={resetToHome}
        setHoveredTrack={setHoveredTrack}
        setClickedTrack={setClickedTrack}>
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          onViewportChange={viewport => onViewportChange(viewport)}
          {...mapProps}
          mapStyle={mapStyle === EMapStyle.LIGHT ? lightMode : darkMode}
          disableSpinner
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }>
          {hoveredTrack && hoveredTrack.latitude && hoveredTrack.longitude && (
            <HoverTag longitude={hoveredTrack.longitude} latitude={hoveredTrack.latitude} />
          )}
          {displayedMapTags.map(({ data, uuid }) => {
            const matchingOperation = tracks.find(track => track.id === data.id);
            const { latitude, longitude } = data;
            return (
              <AMSLPopup
                labels={labels}
                pointData={vectorLayerToPoint({
                  operation: matchingOperation,
                  clickedElement: data,
                })}
                draggable
                anchorMarker={false}
                onClose={() => removeDisplayedTag(uuid)}
                longitude={longitude}
                latitude={latitude}
                key={uuid}
              />
            );
          })}
        </StyledMap>
        <MapControl
          changeViewPort={({ zoom }) => setNewZoomLevel(zoom)}
          resetView={() => {
            setResetToHome(!resetToHome);
          }}
        />
      </DeckGLMap>
    );
  };
