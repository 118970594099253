import React from 'react';
import { IInfDetailsData, IInfRuleFormErrors } from 'src/@settings/interfaces';
import { IGateCriteria, IGateInfringementRule } from 'src/app/interfaces';
import { GateRuleConfigurator } from 'src/components';
import { IGateRuleItem } from 'src/components/GateRuleConfigurator/interfaces';
import { IGate } from 'src/utils/spatialFeatureHelpers/interfaces';

export const GatePanel = (infDetailsData: IInfDetailsData) => {
  const infRule = infDetailsData.infRule as IGateInfringementRule;
  const {
    setInfRule,
    allGates,
    isEditing,
    errors,
  }: {
    setInfRule: (infRule: IGateInfringementRule) => void;
    allGates: IGate[];
    isEditing: boolean;
    errors?: IInfRuleFormErrors;
  } = infDetailsData;

  const gateDetailsById = {};
  if (infRule.gateCriteria && infRule.gateCriteria.length) {
    const gateIds = infRule.gateCriteria.map(criterion => criterion.gateId);
    gateIds.forEach(gateId => {
      const criterion = infRule.gateCriteria.find(criterion => criterion.gateId === gateId);
      const gateDetails = allGates.find(gate => gate.id === gateId);
      if (criterion && gateDetails) {
        gateDetailsById[gateId.toString()] = {
          gateDirections: criterion.gateDirections,
          name: gateDetails.name,
          intersectionMode: criterion.intersectionMode,
        };
      }
    });
  }

  // Sets up options for dropdown
  const gatesRuleData: IGateRuleItem[] = allGates.map(gate => {
    const gateDetails = gateDetailsById[gate.id];
    if (gateDetails) {
      const gateDirections = gateDetails.gateDirections;
      const numDirections = gateDirections ? gateDirections.length : 0;
      const intersectionMode = gateDetails.intersectionMode || null;
      return {
        id: String(gate.id),
        name: gate.name,
        numDirections,
        intersectionMode,
      };
    } else {
      return {
        id: String(gate.id),
        name: gate.name,
      };
    }
  });

  const selectedGate: IGateRuleItem = infRule.gateCriteria
    ? gatesRuleData.find(gateRule => gateRule.id === String(infRule.gateCriteria[0].gateId))
    : null;

  const handleGateChange = (selectedGateRule: IGateRuleItem) => {
    const gateData = allGates.find(gate => gate.id === Number(selectedGateRule.id));
    if (selectedGateRule && gateData) {
      const gateCriterion: IGateCriteria = {
        gateId: Number(selectedGateRule.id),
        altitudeRange: {
          start: gateData.geometry.floorAltitude,
          end: gateData.geometry.ceilingAltitude,
        },
        intersectionMode: selectedGateRule.intersectionMode,
        gateDirections: selectedGateRule.numDirections
          ? selectedGateRule.numDirections === 1
            ? ['In']
            : ['In', 'Out']
          : null,
      };
      setInfRule(
        Object.assign({}, infRule, { gateCriteria: [gateCriterion] }, { hasChanged: true })
      );
    } else {
      setInfRule(Object.assign({}, infRule, { gateCriteria: [] }, { hasChanged: true }));
    }
  };

  return (
    <GateRuleConfigurator
      selectedGateItem={selectedGate}
      gates={gatesRuleData}
      onGateChange={handleGateChange}
      isReadOnly={!isEditing}
      formErrors={errors}
    />
  );
};
