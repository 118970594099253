import React from 'react';
import { useFormikContext } from 'formik';

// Reducers
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { DropdownField, TimeField } from './FormControlElements';
import { CollapseComponent } from 'src/components/CollapseComponent/CollapseComponent';
import {
  AdvancedSettingsConfigFieldWrapper,
  AdvancedSettingsContainer,
  FieldError,
} from './DeviceManagementDetails.styles';

// Variables
import { NMT_METRICS } from 'src/constants';
import styles from 'src/styles/vars.module.scss';

// Types
import { IDeviceManagementForm } from '../../interfaces';

export const DeviceAdvancedSettings = ({ isEditing }: { isEditing: boolean }) => {
  const { values, errors } = useFormikContext<IDeviceManagementForm>();
  const languageSelector = useLanguageSelectors();
  const {
    fields: {
      locationManagement: { calibration, metric },
    },
    components: {
      labels: { advancedSettings },
    },
  } = languageSelector.getLanguage();

  const triggerTypeDropdownOptions = Object.entries(NMT_METRICS).map(([key, value]) => ({
    key,
    label: value,
  }));

  return (
    <AdvancedSettingsContainer className="advanced-settings__container">
      <CollapseComponent
        headerTitle={advancedSettings}
        contentPosition="above"
        fill={styles.text01}>
        <DropdownField
          fieldData={{
            label: metric,
            name: 'trigger',
            selectedKey: String(values.trigger),
            options: triggerTypeDropdownOptions,
          }}
          isEditing={isEditing}
          isShortField
          disabled
        />

        <AdvancedSettingsConfigFieldWrapper>
          <TimeField
            fieldData={{
              label: `${calibration} 1`,
              name: `calibrationTimes.0`,
              value: String(values.calibrationTimes[0]),
            }}
            isEditing={isEditing}
          />
          <TimeField
            fieldData={{
              label: `${calibration} 2`,
              name: `calibrationTimes.1`,
              value: String(values.calibrationTimes[1]),
            }}
            isEditing={isEditing}
          />
          <TimeField
            fieldData={{
              label: `${calibration} 3`,
              name: `calibrationTimes.2`,
              value: String(values.calibrationTimes[2]),
            }}
            isEditing={isEditing}
          />
          <TimeField
            fieldData={{
              label: `${calibration} 4`,
              name: `calibrationTimes.3`,
              value: String(values.calibrationTimes[3]),
            }}
            isEditing={isEditing}
          />
        </AdvancedSettingsConfigFieldWrapper>
        <FieldError className="location-settings__field-error">
          {errors.duplicateCalibrations || <>&nbsp;</>}
        </FieldError>
      </CollapseComponent>
    </AdvancedSettingsContainer>
  );
};
