import React, { useState, useEffect, useContext, SetStateAction, Dispatch, useMemo } from 'react';
import { GeometryDispatchContext } from 'src/app/providers/GeometryProvider';
import { geometryActionTypes } from 'src/app/newActionTypes';
import { useApolloClient } from '@apollo/react-hooks';
import { useConfigSelectors } from 'src/app/reducers';
import { useGeometryRequiredByMap, isSourceAvailable } from 'src/utils';
import { useMapConfig } from 'src/app/functions/map';
import {
  useGis,
  useCustomLayers,
  useKmlLayersOnMap,
  useRerunHookOnMapBackgroundChange,
} from 'src/app/functions/mapSettings';
import { IViewState } from 'src/utils/interfaces';
import { INFRINGEMENT_RULE_TYPES, MONITOR_LOCATIONS, RUNWAYS, TRACK_DENSITY } from 'src/constants';
import {
  initialTrackDensityData,
  ITrackDensityData,
  trackDensitySourceData,
  removeAndResetTrackDensity,
  fetchAndDisplayTrackDensity,
  isTrackDensityOutOfDate,
  generateTrackDensity,
} from 'src/components/TrackDensityLayer';
import { getLayers } from 'src/utils/mapHelpers/mapApis';

interface IMapLayers {
  mapApis: any;
  mapRef?: any;
  mapStyle: string;
  layers: string[];
  viewport?: IViewState;
  mapApiStartCursor?: string;
  isGeneratingTrackDensity?: boolean;
  setIsGeneratingTrackDensity?: Dispatch<SetStateAction<boolean>>;
  setTrackDensityId?: Dispatch<SetStateAction<string>>;
  trackDensitySize?: string;
  isTrackDensityCheckRequired?: boolean;
  setIsTrackDensityCheckRequired?: Dispatch<SetStateAction<boolean>>;
  setTrackDensityMaxCellCount?: Dispatch<SetStateAction<number>>;
  dateRange?: {
    dateFilterFrom: string;
    dateFilterTo: string;
  };
  afterRender?: () => void;
}

export const MapReferenceLayers = React.memo(
  ({
    mapApis,
    mapStyle,
    layers,
    viewport,
    mapApiStartCursor,
    isGeneratingTrackDensity,
    setIsGeneratingTrackDensity,
    setTrackDensityId,
    trackDensitySize,
    isTrackDensityCheckRequired,
    setIsTrackDensityCheckRequired,
    setTrackDensityMaxCellCount,
    dateRange,
    afterRender,
  }: IMapLayers) => {
    const rerun = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 2000);

    const apolloClient = useApolloClient();
    const configSelectors = useConfigSelectors();
    const {
      map: { mapStyleSatelliteURL },
    } = configSelectors.getConfig();
    const mapBoxConfig = useMapConfig();
    const sourceIdExt = '_reference';
    const {
      corridorIdentifier,
      selectionZoneIdentifier,
      gateIdentifier,
      monitorLocationsIdentifier,
      runwaysIdentifier,
      trackDensityIdentifier,
    } = mapBoxConfig;
    const sourceIdentifiers = {
      [INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT]: `${corridorIdentifier}${sourceIdExt}`,
      [INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT]: `${selectionZoneIdentifier}${sourceIdExt}`,
      [INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT]: `${gateIdentifier}${sourceIdExt}`,
      [MONITOR_LOCATIONS]: `${monitorLocationsIdentifier}${sourceIdExt}`,
      [RUNWAYS]: `${runwaysIdentifier}${sourceIdExt}`,
      [TRACK_DENSITY]: `${trackDensityIdentifier}${sourceIdExt}`,
    };

    const [referenceLayers, updateRefLayers] = useState<string[]>([]);
    const [customLayers, updateCustomLayers] = useState<any>([]);
    const { gisLayers: customLayersAvailable } = useGis();
    const { kmlData } = useCustomLayers(customLayers);

    useKmlLayersOnMap({
      mapApis,
      customLayers,
      kmlData,
      rerunHook: rerun,
    });

    useEffect(() => {
      if (layers) {
        const refLayersToAdd: string[] = [];
        const customLayersToAdd = customLayersAvailable.filter(
          ({ name }) => layers.indexOf(name) !== -1
        );
        const customLayerNames = customLayersToAdd.map(({ name }) => name);
        const customLayerIds = customLayersToAdd.map(({ id }) => id);
        layers.forEach(layerName => {
          if (!customLayerNames.includes(layerName)) {
            refLayersToAdd.push(layerName);
          }
        });
        updateRefLayers(refLayersToAdd);
        updateCustomLayers(customLayerIds);
      }
    }, [layers, mapStyle, rerun]);

    // GEOMERTY LAYERS - GATES/CORRIDORS/ZONES ect
    const {
      corridorsData,
      exclusionsData,
      gateData,
      monitorLocationsData,
      runwaysData,
    } = useGeometryRequiredByMap({
      mapApis,
      infringementTypes: referenceLayers,
      mapBoxConfig: Object.assign(mapBoxConfig, {
        isSatelliteBackground: mapStyle === mapStyleSatelliteURL,
      }),
      sourceIdExt,
      dateRange: dateRange,
      mapStyle,
    });

    const getFilters = (layer: string) => {
      switch (layer) {
        case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
          return { selected: false, visible: true };
        case MONITOR_LOCATIONS:
          return { selected: false, visible: true };
        default:
          return { selected: true };
      }
    };

    const getLayerFeatures = (layer: string) => {
      switch (layer) {
        case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
          return corridorsData && typeof corridorsData.features !== 'undefined'
            ? corridorsData.features
            : null;
        case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
          return exclusionsData && typeof exclusionsData.features !== 'undefined'
            ? exclusionsData.features
            : null;
        case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
          return gateData && typeof gateData.features !== 'undefined' ? gateData.features : null;
        case MONITOR_LOCATIONS:
          return monitorLocationsData && typeof monitorLocationsData.features !== 'undefined'
            ? monitorLocationsData.features
            : null;
        case RUNWAYS:
          return runwaysData && typeof runwaysData.features !== 'undefined'
            ? runwaysData.features
            : null;
        case TRACK_DENSITY:
          return trackDensitySourceData && typeof trackDensitySourceData.features !== 'undefined'
            ? trackDensitySourceData.features
            : null;
        default:
          return null;
      }
    };

    // layer being added is set to state so it can trigger mapApis.on(render)
    const [layerBeingAdded, updateLayerBeingAdded] = useState<null | string>(null);

    // make sure layout visibility is set once layer is available
    const [layerWaitingToDisplay, setLayerWaitingToDisplay] = useState<null | string>(null);
    const setLayerVisible = () => {
      if (layerWaitingToDisplay === null) {
        updateLayerBeingAdded(null);
        mapApis.off('render', handleAfterRender);
        return;
      }

      if (mapApis && layerWaitingToDisplay && mapApis.getLayer(layerWaitingToDisplay)) {
        mapApis.setLayoutProperty(layerWaitingToDisplay, 'visibility', 'visible');
        setLayerWaitingToDisplay(null);
        mapApis.off('render', handleAfterRender);
      }
    };

    const handleAfterRender = () => {
      setLayerVisible();
      const rulerLayer = mapApis.getLayer('RULER_LAYER_LINE');
      // Check if ruler is top most layer
      const allLayers = getLayers(mapApis);
      if (allLayers.length && allLayers[allLayers.length - 1].id !== 'RULER_LAYER_LINE') {
        mapApis.isRulerEnabled && rulerLayer && mapApis.moveLayer('RULER_LAYER_LINE');
      }

      if (afterRender) {
        afterRender();
      }
    };

    useEffect(() => {
      if (mapApis && (layerWaitingToDisplay || layerBeingAdded)) {
        mapApis.on('render', handleAfterRender);
      }
    }, [mapApis, layerWaitingToDisplay, layerBeingAdded, rerun]);

    const geometryDispatcher = useContext(GeometryDispatchContext);

    const [displayedLayers, setDisplayedLayers] = useState<string[]>([]);
    const geometryLayersToShow: string[] = useMemo(
      () =>
        layers.flatMap(layerName => {
          if (getLayerFeatures(layerName)) {
            return layerName;
          }
          return [];
        }),
      [corridorsData, exclusionsData, gateData, monitorLocationsData, runwaysData, layers]
    );

    const geometryLayersToRemove = useMemo(() => {
      const remove = displayedLayers.flatMap(layerName =>
        !geometryLayersToShow.includes(layerName) ? layerName : []
      );
      setDisplayedLayers(geometryLayersToShow);
      return remove;
    }, [geometryLayersToShow]);

    useEffect(() => {
      if (mapApis && geometryLayersToRemove.length) {
        geometryLayersToRemove.forEach(layer => {
          const layerFeatures = getLayerFeatures(layer);
          if (layerFeatures) {
            if (layer === MONITOR_LOCATIONS) {
              if (isSourceAvailable(mapApis, sourceIdentifiers[layer])) {
                mapApis.removeLayer(sourceIdentifiers[layer]);
                mapApis.removeSource(sourceIdentifiers[layer]);
                geometryDispatcher({
                  type: geometryActionTypes[MONITOR_LOCATIONS],
                  data: null,
                  as3d: false,
                });
              }
            } else {
              layerFeatures.forEach(({ id }) => {
                mapApis.removeFeatureState({
                  id,
                  source: sourceIdentifiers[layer],
                });
              });
            }
          }
        });
      }
    }, [geometryLayersToRemove]);

    useEffect(() => {
      if (mapApis && geometryLayersToShow.length) {
        geometryLayersToShow.forEach(layer => {
          const layerFeatures = getLayerFeatures(layer);
          const filter = getFilters(layer);
          if (layerFeatures) {
            if (layer === MONITOR_LOCATIONS) {
              if (mapApis.getLayer(sourceIdentifiers[layer])) {
                updateLayerBeingAdded(layer);
              } else {
                // map layer not available yet
                setLayerWaitingToDisplay(sourceIdentifiers[layer]);
              }
            } else {
              updateLayerBeingAdded(layer);
              if (layer !== TRACK_DENSITY) {
                layerFeatures.forEach(({ id }) => {
                  const layerSource = sourceIdentifiers[layer];
                  if (layerSource) {
                    try {
                      mapApis.setFeatureState(
                        {
                          id,
                          source: layerSource,
                        },
                        filter
                      );
                    } catch {
                      console.warn('error setting feature state');
                    }
                  }
                });
              }
            }
          }
        });
      }
    }, [geometryLayersToShow, rerun]);

    // TRACK DENSITY
    const isTrackDensityEnabled = useMemo(
      () => geometryLayersToShow.some(layerName => layerName === TRACK_DENSITY),
      [geometryLayersToShow]
    );
    const [isTrackDensityCompleted, setIsTrackDensityCompleted] = useState<boolean>(false);
    const [isTrackDensityVisible, setIsTrackDensityVisible] = useState<boolean>(false);
    const [trackDensityData, setTrackDensityData] = useState<ITrackDensityData>(
      initialTrackDensityData
    );
    const trackDensityStateFunctions = {
      setIsGeneratingTrackDensity,
      setTrackDensityId,
      setTrackDensityData,
      setIsTrackDensityCompleted,
      setIsTrackDensityCheckRequired,
      setIsTrackDensityVisible,
    };

    const trackDensityInputs = {
      trackDensitySize,
      trackDensityData,
      trackDensityStateFunctions,
      mapApis,
      mapApiStartCursor,
      viewport,
      apolloClient,
      configSelectors,
    };

    useEffect(() => {
      if (isTrackDensityCompleted) {
        setTrackDensityMaxCellCount(trackDensityData.maxOperationCellCount);
      }
    }, [trackDensityData, isTrackDensityCompleted]);

    // Track density
    useEffect(() => {
      if (isTrackDensityEnabled) {
        if (!isTrackDensityCompleted && !isGeneratingTrackDensity) {
          generateTrackDensity(trackDensityInputs);
        } else {
          if (isTrackDensityCheckRequired && isTrackDensityVisible) {
            if (isTrackDensityOutOfDate(trackDensityInputs)) {
              removeAndResetTrackDensity(trackDensityInputs);
              generateTrackDensity(trackDensityInputs);
            }
          } else {
            if (!isTrackDensityVisible && isTrackDensityCompleted) {
              fetchAndDisplayTrackDensity(trackDensityInputs, trackDensityData);
            }
          }
        }
      } else {
        removeAndResetTrackDensity(trackDensityInputs);
      }
    }, [isTrackDensityEnabled, isTrackDensityCheckRequired, mapApiStartCursor]);

    return null;
  }
);
