// Types
import { useLanguageSelectors } from 'src/app/reducers';
import { ITableColumnData } from 'src/components';

// Variables
import { TABLE_CONSTANTS } from 'src/constants';

import { useSelectedNoiseModel } from './useSelectedNoiseModel';

export const useColumnData = (): ITableColumnData[] => {
  const selectedNoiseModel = useSelectedNoiseModel();
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const {
    fields: { aircraftAssignments },
  } = translationData;
  return [
    {
      columnName: 'aircraftType',
      key: 'aircraftType',
      title: aircraftAssignments.assignmentModel,
      filterName: 'aircraftTypes',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'engineModel',
      key: 'engineModel',
      title: aircraftAssignments.assignmentEngine,
    },
    {
      columnName: 'airline',
      key: 'airline',
      title: aircraftAssignments.airline,
      filterName: 'airlines',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },

    {
      columnName: 'modelingAircraftName',
      key: 'modelingAircraftName',
      title: `${selectedNoiseModel} ${aircraftAssignments.assignmentModel}`,
    },
    {
      columnName: 'numberOfFlights',
      key: 'numberOfFlights',
      title: aircraftAssignments.count,
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.INT,
      filterType: 'listFilter',
    },
    {
      columnName: 'source',
      title: '',
    },
  ];
};
