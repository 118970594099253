// client
import ApolloClient from 'apollo-client';
// actions
import { actionTypes } from 'src/@settings/actionTypes';
// resolvers
import { fetchTimeModes } from 'src/@settings/resolvers';

export const getTimeInModes = (
  client: ApolloClient<object>,
  dispatcher: any,
  scenarioIds: number[]
) => {
  dispatcher({ type: actionTypes.TIME_IN_MODES_LOADING });
  fetchTimeModes(client, scenarioIds)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_TIME_IN_MODES, timeInModesData: data });
    })
    .catch(error => {
      console.error(error);
      dispatcher({ type: actionTypes.TIME_IN_MODES_LOADING });
    });
};
