import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';

// Container
import { ChangelogContainer } from 'src/@settings/containers';

// Reducers
import { useConfigSelectors } from 'src/app/reducers';

// Flags
import { CHANGELOGS as CHANGELOGS_FLAG } from 'src/app/featureToggles';

const Screen = ({ updateUrl }) => {
  const configSelectors = useConfigSelectors();
  // Flags
  const FEATURE_FLAG_CHANGELOGS = configSelectors.isFeatureAvailable(CHANGELOGS_FLAG);
  return (
    <SettingsLayout>
      {FEATURE_FLAG_CHANGELOGS ? (
        <ChangelogContainer updateUrl={updateUrl} />
      ) : (
        <p>Feature not enabled</p>
      )}
    </SettingsLayout>
  );
};

export const ChangeLogSettingsScreen = withRouteProps(Screen);
