import { useContext } from 'react';
// interfaces
import { ISortSelectors, ISortState, ISortField } from 'src/@airtrak/interfaces';
// actions
import { actionTypes } from 'src/@airtrak/actionTypes';
// providers
import { AirtrakStateContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';

export const useSortSelectors: () => ISortSelectors = () => {
  const state: any = useContext(AirtrakStateContext);
  const dataState: ISortState = state.sort;

  return useSelectors(dataState, (state: ISortState) => ({
    getIconProps: sortName => {
      if (sortName === 'date' && state.sortObject) {
        // do not show icon for time when it is implicit
        return false;
      }
      return findSortObject(state, sortName);
    },
    getSortString: () => {
      const sortObject = state.sortObject;
      const sortString = `{field:"${sortObject.field}", direction:${sortObject.direction}}`;
      return `sort: [${sortString}]`;
    },
    getSortObject: () => {
      return state.sortObject;
    },
  }));
};

const initSortField: ISortField = {
  field: 'date',
  direction: 'DESC',
};

export const sortInitialState: ISortState = {
  sortObject: initSortField,
};

const findSortObject = (state, sortName) => {
  return state.sortObject.field === sortName ? state.sortObject : false;
};

export const sortReducer = (state: ISortState, action: any) => {
  switch (action.type) {
    case actionTypes.SORT_TABLE:
      const sortName = action.data;
      const sortObject = findSortObject(state, sortName);
      if (sortObject) {
        const newSortObject: ISortField = state.sortObject;
        newSortObject.direction = state.sortObject.direction === 'ASC' ? 'DESC' : 'ASC';
        return Object.assign({}, state, {
          sortObject: newSortObject,
        });
      } else {
        const newSortObject = { field: action.data, direction: 'ASC' };
        return Object.assign({}, state, {
          sortObject: newSortObject,
        });
      }

    default:
      return state;
  }
};
