import React, { FC, useContext, useEffect, useState, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useSortSelectors, useDataSelectors, useFilterSelectors } from 'src/@operations/reducers';
// actions
import { selectRow, loadMore } from 'src/@operations/actions';
// components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
// function
import {
  capitalizeObjectKeys,
  getSelectedIndexes,
  useEffectAfterMount,
  insertStyle,
  getFeatureFlagClasses,
} from 'src/utils';
import { formatHeaders, useOperationsTableData } from 'src/@operations/functions';
// context
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
import { UPDATE_OPERATION, updateOperationBuilder } from 'src/@operations/mutations';
import { actionTypes } from 'src/@operations/newActionTypes';

// constants
import { NULL_VALUE } from 'src/constants';
import { usePermissions } from 'src/app/functions/permissions';
import {
  getOperationsColumnsAndStyle,
  operationsColumnDefinitions,
} from '../operationsTableColumns';
import { OPERATOR_CATEGORY, OPERATION_TAGS, FORMATION_COUNT } from 'src/app/featureToggles';
import { useScreenLoadStateContext } from 'src/app/providers/ScreenLoadStateContext';

export const TableContainer: FC = () => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const dispatcher = useContext<any>(OperationDispatchContext);
  const dataSelectors = useDataSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaPosition = filterSelectors.getPcaPosition();
  const filterDataSelectors = useFilterDataSelectors();
  const operationFilterData = filterDataSelectors.getOperationsFilterData();

  const { canUpdate } = usePermissions('Operation');
  const { canRead: infringementsRead } = usePermissions('Infringement');
  const { canRead: noiseEventsRead } = usePermissions('NoiseEvent');
  const { canRead: complaintsRead } = usePermissions('Complaint');
  const FEATURE_FLAG_OPERATOR_CATEGORY = configSelectors.isFeatureAvailable(OPERATOR_CATEGORY);
  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const FEATURE_FLAG_FORMATION_COUNT = configSelectors.isFeatureAvailable(FORMATION_COUNT);
  const featureFlags = {
    operatorCategory: FEATURE_FLAG_OPERATOR_CATEGORY,
    operationTags: FEATURE_FLAG_OPERATION_TAGS,
    formationCount: FEATURE_FLAG_FORMATION_COUNT,
  };
  const featureFlagClasses = getFeatureFlagClasses(featureFlags);

  const {
    data,
    selectedInTable,
    pageInfo,
    isLoading,
    areAllRowsSelected,
    isLoadingMore,
  } = dataSelectors.getDataInformation();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const [mutationData, setMutationData] = useState<any>({
    mutation: UPDATE_OPERATION,
    item: {},
  });
  const [updateOperationCall] = useMutation(mutationData.mutation, {
    update(cache, { data: { updateOperation } }) {
      dispatcher({
        type: actionTypes.INLINE_EDIT,
        data: {
          updatedItem: updateOperation,
        },
      });
    },
  });

  // Only do an operation call once the mutation call changes.
  useEffectAfterMount(() => {
    const { id, updatedSection } = mutationData.item;
    const oldData = dataSelectors.getDataFromID(id);
    updateOperationCall({
      variables: { id, ...updatedSection },
      optimisticResponse: {
        __typename: 'Operation',
        updateOperation: {
          id,
          __typename: 'Type',
          ...oldData,
          ...updatedSection,
        },
      },
    });
  }, [mutationData]);
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize, loadMoreSize },
    operations: {
      grid: { columns },
      filter: { nullable },
      pca: { maxHorizontalRange, maxAltitudeRange },
      availableOperationTags,
    },
  } = configSelectors.getConfig();
  const availableFilters = {
    operationTags: availableOperationTags,
  };

  const units = configSelectors.getUnits();

  // TODO: This currentLayout variable will be used to position the switcher, commented out for now
  // const currentLayout = configSelectors.getLayout();
  const readOnlyFields = configSelectors.getOperationReadOnlyFields();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    abbreviations: headersAbbreviations,
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFiltersOrDate },
      lists: {
        aircraftCategories,
        operatorCategories,
        operationTypes,
        correlatedCategories,
        extraFilterValues,
        shortenedCorrelated,
      },
    },
    screens: {
      operations: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      capitalizeObjectKeys({
        ...correlatedCategories,
        ...shortenedCorrelated,
        ...aircraftCategories,
        ...operatorCategories,
        ...operationTypes,
        ...extraFilterValues,
      })
    );
  }, [correlatedCategories, aircraftCategories]);

  const loading = isLoading || isLoadingMore;
  // column types are used to add class-names to each column (used only for styling)
  // TODO: this logic needs to be handled in the design system, not here. We'll refactor when the table needs to be modified.
  const {
    runwayName: runwayNameAbbr,
    aircraftCategory: aircraftCategoryAbrr,
    aircraftCount: aircraftCountAbbr,
    operatorCategory: operatorCategoryAbbr,
    duration: durationAbrr,
    diagonal: diagonalAbbr,
    horizontal: horizontalAbbr,
    altitude: altitudeAbbr,
  } = headersAbbreviations;
  const columnTypes = {
    displayFlag: {
      title: 'ad-flag',
      abbreviation: '',
    },
    acid: {
      title: 'record-title',
      abbreviation: '',
    },
    displayRunwayName: {
      title: 'runway',
      abbreviation: runwayNameAbbr,
    },
    displayCategory: {
      title: 'aircraft',
      abbreviation: aircraftCategoryAbrr,
    },
    aircraftCount: {
      title: 'count',
      abbreviation: aircraftCountAbbr,
    },
    operatorCategory: {
      title: 'operatorCategory',
      abbreviation: operatorCategoryAbbr,
    },
    duration: {
      title: 'duration',
      abbreviation: durationAbrr,
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    displayDiag: {
      title: 'diagonal',
      abbreviation: diagonalAbbr,
    },
    displayHoriz: {
      title: 'horizontal',
      abbreviation: horizontalAbbr,
    },
    displayAlt: {
      title: 'altitude',
      abbreviation: altitudeAbbr,
    },
    pcaTime: {
      title: 'pcaTime',
      abbreviation: '',
    },
    correlated: {
      title: 'correlated',
      abbreviation: '',
    },
  };

  const onSelectRow = (indexes: number[]) => {
    const ids: number[] = [];
    for (const index of indexes) {
      ids.push(dataIds[index]);
    }
    selectRow(ids, dispatcher);
  };

  const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
  const ruleInfo = { itemsIds, hasNextPage, endCursor, dateRange };

  const updatedFilterData = {};
  Object.keys(operationFilterData).map(category => {
    const originalData = [...operationFilterData[category]];
    if (nullable && nullable.includes(category)) {
      updatedFilterData[category] = [NULL_VALUE, ...originalData];
    } else {
      updatedFilterData[category] = [...originalData];
    }
  });

  const onUpdateSelection = (id: number, updatedSection: object) => {
    const clonedDataObject = { ...updatedSection };
    const ref = 'aircraftCount';
    if (clonedDataObject[ref]) {
      clonedDataObject[ref] = parseInt(clonedDataObject[ref], 10);
    }
    setMutationData({
      mutation: updateOperationBuilder(Object.keys(updatedSection)[0]),
      item: { id, updatedSection: clonedDataObject },
    });
  };
  const { dataIds, displayData } = useOperationsTableData(
    data,
    translationDataList,
    updatedFilterData,
    onUpdateSelection,
    ruleInfo,
    canUpdate,
    units,
    readOnlyFields,
    twelveHourFormat,
    featureFlags,
    selectedTrackTheme
  );

  const pcaData = useMemo(
    () =>
      usingPca
        ? {
            position: pcaPosition,
            altRange: maxAltitudeRange,
            horizRange: maxHorizontalRange,
          }
        : undefined,
    [usingPca, pcaPosition]
  );

  const rowHeaders = formatHeaders(
    resultSize,
    loading,
    dispatcher,
    sortSelectors,
    languageSelectors.getLanguage()
  );

  const hiddenColumns = {
    pca: !usingPca,
    correlated: !(!usingPca && (infringementsRead || noiseEventsRead || complaintsRead)),
    operatorCategory: !FEATURE_FLAG_OPERATOR_CATEGORY,
    operationTags: !FEATURE_FLAG_OPERATION_TAGS,
    aircraftCount: !FEATURE_FLAG_FORMATION_COUNT,
    remoteAirportId: FEATURE_FLAG_FORMATION_COUNT,
  };
  const { operationsColumns, styleOperationsGridTemplate } = getOperationsColumnsAndStyle(
    columns,
    operationsColumnDefinitions,
    hiddenColumns
  );
  insertStyle(styleOperationsGridTemplate, 'styleOperationsGridTemplate');

  for (const column of operationsColumns) {
    if (!rowHeaders[column]) {
      throw new Error(
        `rowHeaders[${column}] is ${rowHeaders[column]}. It needs to be defined in src/app/strings.json`
      );
    }
  }

  // Connect map load state to table load state
  const { screenLoadStates, setScreenLoadStates } = useScreenLoadStateContext();
  const { mapLoadState, tableLoadState } = screenLoadStates;

  useEffect(() => {
    setScreenLoadStates({ type: 'SET_TABLE_LOAD_STATE', payload: loading });
  }, [isLoading, isLoadingMore]);
  return (
    <>
      <Table
        className={`operations-table ${featureFlagClasses}`}
        loading={mapLoadState || tableLoadState}
        rowHeaders={rowHeaders}
        data={displayData}
        columns={operationsColumns}
        columnTypes={columnTypes}
        selectedData={getSelectedIndexes(selectedInTable, dataIds)}
        areAllRowsSelected={areAllRowsSelected}
        gridID="operations"
        onSelectRow={onSelectRow}
        hasEnded={displayData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFiltersOrDate, endTable }}
        showDashIfEmpty={false}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        filterString={filterString}
        pcaData={pcaData}
        permissions={{
          infringements: infringementsRead,
          noiseEvents: noiseEventsRead,
          complaints: complaintsRead,
        }}
        resultSize={loadMoreSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
        featureFlags={featureFlags}
        availableFilters={availableFilters}
      />
    </>
  );
};
