import { useContext } from 'react';
import { IScenariosState, IStoreState, IScenariosSelectors, IScenario } from 'src/app/props';
import { appActionTypes } from 'src/app/actionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateContext';
import { useSelectors } from 'src/utils/storeHelpers';

export const useScenariosSelectors: () => IScenariosSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const scenariosState: IScenariosState = state.scenarios;

  return useSelectors(scenariosState, (state: IScenariosState) => ({
    hasScenariosFetched: (): boolean => state.isFetched,
    getScenarios: (): IScenario[] => state.scenarios,
    getActiveScenario: (): IScenario => state.activeScenario,
    getSelectedScenario: (): IScenario => state.currentScenario,
    getSelectedRowIndex: (): number => state.selectedRowIndex,
    getDefaultScenario: (): IScenario => state.defaultScenario,
    getIfLoading: (): boolean => state.isLoading,
    getRecalculationStatus: (): boolean => getScenarioStatus(state),
    getOutStandingMonthsToProcess: (): number => state.outstandingMonthsToProcess,
  })) as IScenariosSelectors;
};

const getScenarioStatus = (state: IScenariosState) => {
  return state.outstandingMonthsToProcess !== undefined && state.outstandingMonthsToProcess !== 0;
};

export const scenariosReducer = (state: IScenariosState, action: any) => {
  switch (action.type) {
    case appActionTypes.SCENARIOS_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case appActionTypes.UPDATE_SCENARIOS:
      const defaultScenario = getDefaultScenario(action.payload);
      let activeScenario = getActiveScenario(action.payload);
      activeScenario = activeScenario ? activeScenario : defaultScenario;
      return Object.assign({}, state, {
        scenarios: action.payload,
        activeScenario,
        defaultScenario,
        isLoading: false,
        isFetched: true,
      });
    case appActionTypes.UPDATE_CURRENT_SCENARIO:
      return Object.assign({}, state, {
        currentScenario: action.currentScenario.scenario,
        selectedRowIndex: action.currentScenario.selectedRowIndex,
      });
    case appActionTypes.UPDATE_SCENARIO_REPROCESS_STATUS:
      if (
        state.outstandingMonthsToProcess !== 0 &&
        action.payload.outstandingMonthsToProcess === 0
      ) {
        return Object.assign({}, state, {
          outstandingMonthsToProcess: action.payload.outstandingMonthsToProcess,
          activeScenario: { ...state.activeScenario, ...action.payload },
        });
      }
      return Object.assign({}, state, {
        outstandingMonthsToProcess: action.payload.outstandingMonthsToProcess,
      });
    default:
      return state;
  }
};

const getDefaultScenario = scenarios => {
  let defaultScenario;
  scenarios.forEach(scenario => {
    if (scenario.isDefault) {
      defaultScenario = scenario;
    }
  });
  return defaultScenario;
};

const getActiveScenario = scenarios => {
  let activeScenario;
  scenarios.forEach(scenario => {
    if (scenario.isActive) {
      activeScenario = scenario;
    }
  });
  return activeScenario;
};
