import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  searchAddressQuery,
  searchAddressSchema,
  geocodeCandidateDetailQuery,
  geocodeCandidateDetailSchema,
  geocodePositionQuery,
  geocodePositionSchema,
} from 'src/app/resolvers/schema';
import { IAddress, IGeocodePosition, IGeocodeCandidateDetail } from 'src/app/props';

export const addressSearchResults = ({ client, address, bounds }: {
  client: ApolloClient<object>;
  address: string;
  bounds?: {
    location: {
      latitude: number,
      longitude: number,
    },
    radius: number
  };
}) => {
  return new Promise<IAddress[]>((resolve, reject) => {
    const GET_ADDRESS = gql`${searchAddressSchema}`;
    client.query({
      query: GET_ADDRESS,
      variables: {
        address,
        bounds,
      }
    })
      .then(response => {
        if (response && response.data && response.data[searchAddressQuery]) {
          resolve(response.data[searchAddressQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const geocodeCandidateDetail = ({ client, id }: {
  client: ApolloClient<object>;
  id: string;
}) => {
  return new Promise<IGeocodeCandidateDetail>((resolve, reject) => {
    const GET_DETAIL = gql`${geocodeCandidateDetailSchema}`;
    client.query({
      query: GET_DETAIL,
      variables: {
        id
      }
    })
      .then(response => {
        if (response && response.data && response.data[geocodeCandidateDetailQuery]) {
          resolve(response.data[geocodeCandidateDetailQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const geocodePosition = ({ client, position }: {
  client: ApolloClient<object>;
  position: {
    latitude: number,
    longitude: number,
  };
}) => {
  return new Promise<IGeocodePosition[]>((resolve, reject) => {
    const GET_GEOCODE_POSITION = gql`${geocodePositionSchema}`;
    client.query({
      query: GET_GEOCODE_POSITION,
      variables: {
        position
      }
    })
      .then(response => {
        if (response && response.data && response.data[geocodePositionQuery]) {
          resolve(response.data[geocodePositionQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};