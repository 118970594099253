import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const ScenarioTableWrapper = styled.div`
  padding: 0 3rem;
`;

export const ScenarioClearLink = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  line-height: 1;
  margin-left: 1rem;
`;

export const ScenarioButtonWrapper = styled.span`
  margin-right: 6px;
  &:last-of-type {
    margin-right: 0;
  }
`;
