import React from 'react';
import cx from 'classnames';
// components
import { BlockHeader } from '@ems/client-design-system';
import { InfringementMapContainer } from './';
// utils
import { formatFromISO } from 'src/utils/dateTimeConverters';
// constants
import { INFRINGEMENT_RULE_TYPES } from 'src/constants';

export const InfringementRuleContainer = ({
  title,
  time,
  operationId,
  operation,
  selectionOnMap,
  infringementType,
  infringementId,
  position,
  extraIds,
}) => {
  let infTypeId;
  const { corridorId, selectionZoneId, gateId } = selectionOnMap;
  switch (infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      infTypeId = corridorId;
      break;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      infTypeId = selectionZoneId;
      break;
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      infTypeId = gateId;
      break;
    default:
  }
  return (
    <div
      className={`card__padder ${cx({
        'no-pad-bottom': infringementType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT,
      })}`}>
      <BlockHeader
        title={title}
        cta={
          time && <span className="infringements_time">{formatFromISO(time, 'HH:mm, DDD')}</span>
        }
      />
      <InfringementMapContainer
        operationId={operationId}
        operation={operation}
        infTypeId={infTypeId}
        time={time}
        infringementType={infringementType}
        infringementId={infringementId}
        position={position}
        extraIds={extraIds}
      />
    </div>
  );
};
