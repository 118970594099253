import { useApolloClient, useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import { Button, FlightSelector, Icons, NoData, Spinner } from '@ems/client-design-system';
import React, { useEffect, useState, FC, useCallback, useMemo } from 'react';
import {
  IContentContainer,
  ISelectFlightComplaint,
  IPosition,
  INearestFlight,
} from 'src/@complaints/interfaces';
import { fetchSingleComplaint } from 'src/@complaints/resolvers';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { getDeployedProductId, getOperationTheme, history, rememberSavedFilters } from 'src/utils';
import { HeaderContainer, AddFlightMap } from 'src/@complaints/containers/addFlight';
import { goBack } from 'src/app/functions/itemsNavigation';
import { COMPLAINTS } from 'src/constants';
import {
  UPDATE_COMPLAINER_POSITION,
  UPDATE_COMPLAINT_OPERATION_CORRELATION,
} from 'src/@complaints/mutations';
import { Prompt } from 'react-router-dom';
import { getCorrelatedFlightProfiles, getCorrelatedFlights } from 'src/@complaints/resolvers';
import { timePlusMinusDuration } from 'src/utils';
import { DateTime, Duration } from 'luxon';
import ApolloClient from 'apollo-client';

export const ContentContainer: FC<IContentContainer> = ({ id, fromInquiryDetails = false }) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const languageSelectors = useLanguageSelectors();
  const {
    fields: {
      operations: { aircraftType },
    },
    components: {
      headings: { addressNotFound, addressChange },
      hints: {
        tryChangingTimeLoc,
        addFlightPlacePin,
        addFlightChangePin,
        areYouSureYouWantToLeave,
      },
      buttons: { done, removeFlight, cancel, save },
      labels: { pca: pcaLabel, expandSearchArea },
    },
    screens: {
      complaints: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();
  const languageData = {
    model: aircraftType,
    pca: pcaLabel,
    removeFlight,
  };

  const [selectedId, setSelectedId] = useState<number | null>(null);

  // Used to check which copy to show in the save position panel

  const [correlationSaved, setCorrelationSaved] = useState<boolean>(false);
  const [positionSaved, setPositionSaved] = useState<boolean>(false);

  useEffect(() => {
    if (correlationSaved && positionSaved) {
      if (fromInquiryDetails) {
        // go back to complaint details after selecting/correlating flight
        goBack(`${COMPLAINTS}/${id}`, null, []);
      } else {
        goBack(`${COMPLAINTS}`, null, []);
      }
    }
  }, [correlationSaved, positionSaved]);

  useEffect(() => {
    rememberSavedFilters(COMPLAINTS);
  }, []);

  const [updateCorrelation] = useMutation(UPDATE_COMPLAINT_OPERATION_CORRELATION, {
    onCompleted() {
      setCorrelationSaved(true);
    },
  });

  const [updateComplainerPosition] = useMutation(UPDATE_COMPLAINER_POSITION, {
    onCompleted() {
      setPositionSaved(true);
    },
  });

  const complaintData = useComplaintData(id);
  const {
    pinIsSet,
    initialPinSet,
    pinPosition,
    setPin,
    onAltitudeChange,
    onPinMove,
    initializePinPosition,
  } = usePin();

  const { nearestFlights, hasFinishedLoading, expandNearestFlightSearch } = useNearestFlights(
    complaintData,
    pinPosition
  );

  const onSelectCB = (val: number) => {
    setSelectedId(val);
  };

  const onDoneSelect = () => {
    if (selectedId) {
      updateCorrelation({
        variables: {
          id,
          correlation: {
            operationId: selectedId !== -1 ? selectedId : null,
          },
        },
      });
    } else {
      setCorrelationSaved(true);
    }

    if (canEditAddress && complaintData) {
      updateComplainerPosition({
        variables: {
          complainer: {
            id: complaintData.complainerId,
            address: {
              position: pinPosition,
            },
          },
        },
      });
    } else {
      setPositionSaved(true);
    }
  };

  // Conditions for save being disabled:
  // 1. If you can edit the address, and the pin is not set
  // 2. If no operation has been selected
  const isEditing = complaintData ? !!complaintData.operationId : false;
  const canEditAddress = !isPositionValid(complaintData ? complaintData.complainerPosition : null);
  const saveDisabled: boolean = (canEditAddress && !pinIsSet) || selectedId === null;

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const displayExpandSearch =
    complaintData &&
    (isPositionValid(complaintData.complainerPosition) || isPositionValid(pinPosition));
  return (
    <>
      {!isGridFullscreen && (
        <div
          className={cx('map_wrapper', {
            'map_wrapper--fullscreen': isMapFullscreen,
            'map_wrapper--collapsed': isGridFullscreen,
          })}>
          <AddFlightMap
            trackData={nearestFlights}
            selectedId={selectedId}
            onSelect={onSelectCB}
            complainerPosition={
              complaintData && isPositionValid(complaintData.complainerPosition)
                ? complaintData.complainerPosition
                : null
            }
            canMovePin={canEditAddress}
            onPinMove={onPinMove}
            onAltitudeChange={onAltitudeChange}
            initializePinPosition={initializePinPosition}
            complaintTime={complaintData ? complaintData.time : new Date().toISOString()}
          />
          {!pinIsSet && canEditAddress ? (
            <div className="map-overlay-panel">
              <div className="flight-confirm-panel">
                <div>
                  <div className="flight-confirm-panel_title">
                    {initialPinSet ? addressNotFound : addressChange}
                  </div>
                  <div className="flight-confirm-panel_description">
                    {initialPinSet ? addFlightPlacePin : addFlightChangePin}
                  </div>
                </div>
                <Button className="flight-confirm-panel_confirm" style="primary" onClick={setPin}>
                  {done}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className={cx('container-fluid', 'container-fluid--details')}>
        <div className="container-fluid--inner">
          <HeaderContainer
            isEditing={isEditing}
            time={complaintData ? complaintData.time : null}
            id={id}
          />
          <div className="layout_single">
            {hasFinishedLoading ? (
              <>
                {nearestFlights.length ? (
                  <>
                    <FlightSelector
                      data={nearestFlights}
                      selectedId={selectedId}
                      onSelect={onSelectCB}
                      languageData={languageData}
                      unitProfile={{
                        distanceUnit: units.distance,
                        distanceVerticalUnit: units.distanceVertical,
                      }}
                      allowRemoval={isEditing}
                    />

                    <div className="cancel-save-panel">
                      <Button
                        style="subtle"
                        onClick={() => goBack(`${COMPLAINTS}/${id}`, null, [])}>
                        {cancel}
                      </Button>

                      <Button style="primary" disabled={saveDisabled} onClick={onDoneSelect}>
                        {save}
                      </Button>
                    </div>
                  </>
                ) : (
                  <NoData text={noDataFound} title={tryChangingTimeLoc} bordered={false} />
                )}
                {displayExpandSearch ? (
                  <div className="expand-search">
                    <span onClick={expandNearestFlightSearch}>{expandSearchArea}</span>
                  </div>
                ) : null}
              </>
            ) : (
              <Spinner loading={!hasFinishedLoading} size="l" centered />
            )}
          </div>
        </div>
        <Prompt
          message={areYouSureYouWantToLeave}
          when={selectedId !== null && !correlationSaved}
        />
      </div>
    </>
  );
};

const useComplaintData = (id: number) => {
  const client = useApolloClient();
  const [complaintData, setComplaintData] = useState<ISelectFlightComplaint | null>(null);
  // First get the complaints data if it exists, otherwise redirect to 404
  useEffect(() => {
    if (id) {
      fetchSingleComplaint({ client, id })
        .then((data: ISelectFlightComplaint) => {
          setComplaintData(data);
        })
        .catch(error => {
          history.replace(`/${getDeployedProductId()}/404`);
          console.error(error);
        });
    } else {
      history.replace(`/${getDeployedProductId()}/404`);
    }
  }, [id]);

  return complaintData;
};

const usePin = () => {
  const [pinIsSet, setPinIsSet] = useState<boolean>(false);
  const [initialPinSet, setInitialPinSet] = useState<boolean>(true);
  const [pinPosition, setPinPosition] = useState<IPosition>({ latitude: 0, longitude: 0 });

  const initializePinPosition = (position: IPosition) => {
    setPinPosition(position);
  };

  const onPinMove = useCallback((position: IPosition) => {
    setPinPosition(position);
    setPinIsSet(false);
  }, []);

  const onAltitudeChange = useCallback(
    (altitude: number) => {
      setPinPosition({ ...pinPosition, altitude });
    },
    [pinPosition]
  );

  const setPin = useCallback(() => {
    setPinIsSet(true);
    setInitialPinSet(false);
  }, []);

  return {
    pinIsSet,
    initialPinSet,
    pinPosition,
    setPin,
    onAltitudeChange,
    onPinMove,
    initializePinPosition,
  };
};

const FIFTY_PERCENT_MULTIPLIER = 1.5;

const useNearestFlights = (
  complaintData: ISelectFlightComplaint | null,
  pinPosition: IPosition
): {
  nearestFlights: INearestFlight[];
  hasFinishedLoading: boolean;
  expandNearestFlightSearch: () => void;
} => {
  const [nearestFlights, setNearestFlights] = useState<INearestFlight[]>([]);
  const [hasFinishedLoading, setHasFinishedLoading] = useState<boolean>(false);

  const client = useApolloClient();
  const configSelectors = useConfigSelectors();

  // Nearest Flight Search Parameters
  const {
    complaints: {
      correlation: { timeBufferAroundTime, maxAltitudeRange, maxHorizontalRange },
    },
  } = configSelectors.getConfig();

  const [timeBuffer, setTimeBuffer] = useState<number>(
    timeBufferAroundTime !== undefined ? timeBufferAroundTime : 1800
  );
  const [horizontalRange, setHorizontalRange] = useState<number>(maxHorizontalRange);
  const [altitudeRange, setAltitudeRange] = useState<number>(maxAltitudeRange);

  const minTime = useMemo(
    () => (complaintData ? timePlusMinusDuration(complaintData.time, { seconds: -timeBuffer }) : 0),
    [timeBuffer, complaintData]
  );
  const maxTime = useMemo(
    () => (complaintData ? timePlusMinusDuration(complaintData.time, { seconds: timeBuffer }) : 0),
    [timeBuffer, complaintData]
  );

  const expandNearestFlightSearch = useCallback(() => {
    setTimeBuffer(timeBuffer * FIFTY_PERCENT_MULTIPLIER);
    setHorizontalRange(horizontalRange * FIFTY_PERCENT_MULTIPLIER);
    setAltitudeRange(altitudeRange * FIFTY_PERCENT_MULTIPLIER);
  }, [timeBuffer]);

  useEffect(() => {
    async function fetchAndFormatNearestFlightData() {
      if (!complaintData) {
        return;
      }
      setHasFinishedLoading(false);
      const selectedTrackTheme = configSelectors.getTheme('operations');
      const complaintTime = DateTime.fromISO(complaintData.time, { setZone: true });
      const position: IPosition = isPositionValid(complaintData.complainerPosition)
        ? complaintData.complainerPosition
        : pinPosition;
      position.altitude = !position.altitude ? pinPosition.altitude : position.altitude;

      const { formattedData, correlatedIds } = await getCorrelatedFlightDataAndIds(
        client,
        position,
        { minTime, maxTime },
        { maxHorizontalRange: horizontalRange, maxAltitudeRange: altitudeRange },
        selectedTrackTheme,
        complaintTime
      );

      const correlatedFlightProfiles = await getFlightProfiles(client, correlatedIds);
      if (complaintData) {
        correlatedFlightProfiles.forEach(({ id, profile }) => {
          formattedData.find(operation => {
            if (operation.id === id) {
              operation.profile = profile;
            }
          });
        });
      }

      // Manually sort by PCA time
      const sortedData = formattedData.sort((a, b) => {
        const timeA = DateTime.fromISO(a.pca.time, { setZone: true });
        const timeB = DateTime.fromISO(b.pca.time, { setZone: true });
        const aDiff = Math.abs(timeA.diff(complaintTime).milliseconds);
        const bDiff = Math.abs(timeB.diff(complaintTime).milliseconds);
        return bDiff > aDiff ? -1 : bDiff < aDiff ? 1 : 0;
      });
      setHasFinishedLoading(true);
      setNearestFlights(sortedData);
    }
    fetchAndFormatNearestFlightData();
  }, [complaintData, pinPosition, minTime, maxTime, timeBuffer]);

  return { nearestFlights, hasFinishedLoading, expandNearestFlightSearch };
};

const getCorrelatedFlightDataAndIds = async (
  client: ApolloClient<object>,
  position: IPosition,
  time: { minTime; maxTime },
  range: { maxHorizontalRange; maxAltitudeRange },
  selectedTrackTheme: string,
  complaintTime
) => {
  try {
    const flightData = await getCorrelatedFlights({
      client,
      position,
      time,
      range,
    });
    const correlatedIds: number[] = [];
    const formattedData: INearestFlight[] = flightData.map(
      ({
        operation,
        time,
        horizontalDistance,
        slantDistance,
        elevationAngle,
        verticalDistance,
      }) => {
        const {
          id,
          acid,
          operationType,
          aircraftCategory,
          aircraftType,
          runwayName,
          startTime,
          endTime,
          points,
        } = operation;
        const pcaTime = DateTime.fromISO(time, { setZone: true });
        const timeDiff = pcaTime.diff(complaintTime).milliseconds;
        const displayTime = Duration.fromMillis(Math.abs(timeDiff)).toFormat("mm':'ss");
        const operationTypeLower = operation.operationType.toLowerCase();
        const trackTheme = getOperationTheme(selectedTrackTheme);
        correlatedIds.push(id);

        return {
          operationIcon: (
            <Icons
              iconName={`ic-ad-${operationTypeLower}`}
              size={20}
              style={{
                fill: trackTheme[operationTypeLower],
                color: trackTheme[operationTypeLower],
              }}
            />
          ),
          id,
          acid,
          operationType,
          aircraftCategory,
          aircraftType,
          runway: runwayName,
          startTime,
          endTime,
          points,
          pca: {
            horizontalDistance,
            time,
            slantDistance,
            elevationAngle,
            verticalDistance,
            displayTime: timeDiff > 0 ? displayTime : `-${displayTime}`,
          },
        };
      }
    );
    return { formattedData, correlatedIds };
  } catch {
    console.error('error occurred in get correlated flights and data');
    return { formattedData: [], correlatedIds: [] };
  }
};

const getFlightProfiles = async (client: ApolloClient<object>, correlatedIds: number[]) => {
  try {
    return getCorrelatedFlightProfiles({ client, correlatedIds });
  } catch {
    console.error('error getting flight profiles');
    return [];
  }
};

// Returns true if there is a location object it's not at (0, 0).
export const isPositionValid = (location: IPosition | null) => {
  const isLocationUnset = location && location.longitude === 0 && location.longitude === 0;
  return location && !isLocationUnset;
};
