import React, { useState, useEffect, useRef, useMemo } from 'react';

// Configs
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';

// Design system
import {
  CorridorGeometry,
  GateGeometry,
  GeocoderPin,
  MapControl,
  RulerTool,
  StyledMap,
  ZoneGeometry,
} from '@ems/client-design-system';
import { useInfRulesSettingsSelector, useSpatialFeaturesSelector } from 'src/@settings/reducers';
import {
  FEATURE_TYPES,
  INFRINGEMENT_DISPLAY_STRINGS,
  INFRINGEMENT_RULES,
  INFRINGEMENT_RULE_TYPES,
} from 'src/constants';
import { flyTo, useGeocoderPinAlternative, useMapRuler, useSpatialFeatures } from 'src/utils';

import {
  IGateGeometry,
  ISetFeatureObject,
  TCorridorGeometry,
  TSelectionZoneGeometry,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MapSettingsContainer } from 'src/containers';
import { LocationPopup } from 'src/components';
import { MapReferenceLayers } from 'src/app/components';
import {
  useConfigSelectors,
  useInfringementRulesSelectors,
  useLanguageSelectors,
} from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { addPinToCentre, goToSelectedAddress, useGeocodePosition } from 'src/utils/geocoding';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useApolloClient } from '@apollo/react-hooks';
import { removeLayerFromMap, removeSourceFromMap } from 'src/utils/mapHelpers/mapApis';

interface InfringementRulesMapProps {
  infRuleIds?: number[];
  infRuleGateIds?: number[];
  infRuleCorridorIds?: number[];
  infRuleZoneIds?: number[];
  infRuleNoiseMonitorIds?: number[];
}

export const InfringementRulesMap: React.FC<InfringementRulesMapProps> = ({
  infRuleIds,
  infRuleGateIds,
  infRuleCorridorIds,
  infRuleZoneIds,
  infRuleNoiseMonitorIds,
}) => {
  const client = useApolloClient();
  // Map set up
  const [mapNode, mapRef] = useMapRef();
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  const mapBoxConfig = useMapConfig();
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  const [viewport, setViewport] = useState(viewportFromProps);

  const [zoomSet, updateZoomSet] = useState<boolean>(false);
  const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);

  const handleOnMapLoad = () => {
    mapLoaded();
    setIsMapInitialized(true);
  };
  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        ruler: ruler,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();

  // capture map image
  // used for taking screenshot of map
  const captureRef = useRef(null);
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // set up infringement rule geometry
  const infringementRulesSelector = useInfringementRulesSelectors();
  const infRulesSettingsSelectors = useInfRulesSettingsSelector();
  const spatialFeaturesSelector = useSpatialFeaturesSelector();
  const noiseMonitors = infRulesSettingsSelectors.getAllNoiseMonitors();
  const [selectedGates, setSelectedGates] = useState<IGateGeometry[]>([]);
  const [selectedCorridors, setSelectedCorridors] = useState<TCorridorGeometry[]>([]);
  const [selectedZones, setSelectedZones] = useState<TSelectionZoneGeometry[]>([]);

  const [renderedFeatureStrings, setRenderedFeatureStrings] = useState<string[]>([]);

  // Noise Monitor data
  const [selectedNoiseMonitors, setSelectedNoiseMonitors] = useState<any[]>([]);
  // Checks to see if features are already rendered - to avoid recalling the hooks and rerendering the features
  const isAlreadyRendered = (featureStringArray: string[]): boolean =>
    featureStringArray.every((featureString: string) =>
      renderedFeatureStrings.includes(featureString)
    );

  // Checks to see if there is a feature that should not be rendered
  const shouldNotBeRendered = (featureStringArray: string[]): boolean =>
    !renderedFeatureStrings.every((renderedFeatureString: string) => {
      featureStringArray.includes(renderedFeatureString);
    });

  useEffect(() => {
    if (isMapInitialized) {
      // get rules from passed ids
      const rulesToRender = infRuleIds ? infringementRulesSelector.getRulesById(infRuleIds) : [];

      // get gate feature data
      const gateIds = rulesToRender
        .filter(rule => rule.gateCriteria)
        .map(rule => rule.gateCriteria.map(gateCriteria => gateCriteria.gateId))
        .flat()
        .concat(infRuleGateIds ? infRuleGateIds : []);

      const gateFeatureData = gateIds
        .map((gateId: number) => spatialFeaturesSelector.getFeatureData(gateId, FEATURE_TYPES.GATE))
        .filter(Boolean);

      // corridor feature data
      const corridorIds = rulesToRender
        .filter(rule => rule.corridorId)
        .map(rule => rule.corridorId)
        .concat(infRuleCorridorIds ? infRuleCorridorIds : []);
      const corridorFeatureData = corridorIds
        .map((corridorId: number) =>
          spatialFeaturesSelector.getFeatureData(corridorId, FEATURE_TYPES.CORRIDOR)
        )
        .filter(Boolean);

      // Exclusion zones
      const zoneIds = rulesToRender
        .filter(rule => rule.selectionZoneId)
        .map(rule => rule.selectionZoneId)
        .concat(infRuleZoneIds ? infRuleZoneIds : []);

      const zoneFeatureData = zoneIds
        .map((zoneId: number) =>
          spatialFeaturesSelector.getFeatureData(zoneId, FEATURE_TYPES.SELECTION_ZONE)
        )
        .filter(Boolean);

      // Noise Monitors

      const noiseMonitorIds = rulesToRender
        .filter(
          rule =>
            rule.infringementType ===
            INFRINGEMENT_DISPLAY_STRINGS[INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT]
        )
        .map(rule => rule.thresholds[0].locationId)
        .concat(infRuleNoiseMonitorIds ? infRuleNoiseMonitorIds : []);

      const noiseMonitorData = noiseMonitors.filter(noiseMonitor =>
        noiseMonitorIds.includes(noiseMonitor.id)
      );

      setSelectedNoiseMonitors(noiseMonitorData);

      const featureData = {
        gates: gateFeatureData.filter(gate => gate),
        corridors: corridorFeatureData,
        zones: zoneFeatureData,
      };

      const featuresToRender: string[] = zoneIds
        .map(zoneId => `${zoneId}-${FEATURE_TYPES.SELECTION_ZONE}`)
        .concat(corridorIds.map(corridorId => `${corridorId}-${FEATURE_TYPES.CORRIDOR}`))
        .concat(gateIds.map(gateId => `${gateId}-${FEATURE_TYPES.GATE}`));
      if (
        !isAlreadyRendered(featuresToRender) ||
        shouldNotBeRendered(featuresToRender) ||
        !featuresToRender.length
      ) {
        setRenderedFeatureStrings(featuresToRender);

        const setFeatures: ISetFeatureObject = {
          setGates: setSelectedGates,
          setCorridors: setSelectedCorridors,
          setZones: setSelectedZones,
        };
        useSpatialFeatures({
          featureData,
          mapApis,
          viewport,
          setViewport,
          updateZoomSet,
          setFeatures,
        });
      }
    }
  }, [infRuleIds, infRuleGateIds, infRuleCorridorIds, infRuleZoneIds, isMapInitialized]);

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 1000);
  const [noiseMonitorIds, updateNoiseMonitorIds] = useState<string[]>([]);
  const displayNoiseMonitors = (mapApis, noiseMonitors) => {
    useEffect(() => {
      noiseMonitorIds.forEach(monitorSourceId => {
        removeLayerFromMap(mapApis, `${monitorSourceId}-layer`);
        removeSourceFromMap(mapApis, monitorSourceId);
      });
      const sourceIds: string[] = [];
      if (!!noiseMonitors.length) {
        noiseMonitors.map(monitorData => {
          const { position, id } = monitorData;
          const sourceName = `inf-rules-noise-monitor-${id}`;
          sourceIds.push(sourceName);
          mapApis.addSource(sourceName, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {
                id,
                valid: true,
              },
              geometry: {
                type: 'Point',
                coordinates: [position.longitude, position.latitude],
              },
            },
          });
          mapApis.addLayer({
            id: `${sourceName}-layer`,
            source: sourceName,
            type: 'circle',
            paint: {
              'circle-color': '#0b6bf2',
            },
          });
        });
      }
      updateNoiseMonitorIds(sourceIds);
    }, [noiseMonitors, mapApis, rerunHook]);
  };

  displayNoiseMonitors(mapApis, selectedNoiseMonitors);

  // Geocoder pin and address search
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);

  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  // Removes the distance marker circles from the geocoding pin
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[geocoding.longitude, geocoding.latitude]],
  });

  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude: geocoding.longitude,
      latitude: geocoding.latitude,
    },
  });

  // Address search component

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  const resetView = (): void => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Ruler Tool
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  return (
    <div className="map__wrapper">
      <StyledMap
        height={'100vh'}
        onLoad={handleOnMapLoad}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          onViewportChange(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onMouseMove={undefined}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        {zoomSet &&
          selectedGates.map((gate, index) => (
            <GateGeometry
              key={`map-gate-${index}`}
              mapZoom={viewport.zoom}
              gateName={`gate ${index}`}
              isEditable={false}
              coordinates={gate}
            />
          ))}
        {zoomSet &&
          selectedCorridors.map((corridor, index) => (
            <CorridorGeometry
              key={`map-corridor-${index}`}
              corridorName={`corridor ${index}`}
              isEditable={false}
              coordinates={corridor}
              mapApis={mapApis}
              mapZoom={viewport.zoom}
            />
          ))}
        {zoomSet &&
          selectedZones.map((zone, index) => (
            <ZoneGeometry
              key={`map-zone-${index}`}
              zoneName={`zone ${index}`}
              isEditable={false}
              points={zone}
              mapApis={mapApis}
            />
          ))}

        {enableMapControls && (
          <MapControl
            isPinAdded={geocoding.latitude && geocoding.longitude ? true : false}
            addPinToCentre={() =>
              addPinToCentre({
                updateLocationAddress,
                geocoding,
                viewport,
                updateGeocoding,
                addRemoveCircles,
                updateLocationTagOpen,
              })
            }
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            navigationControl={{
              showCompass: false,
              showHome: true,
              showSearch: true,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            translationData={{
              home: backToCenterLabel,
              search: searchLabel,
              addPin: addPinLabel,
              removePin: removePinLabel,
              mapSettings: mapSettingsTitle,
              ruler,
            }}
            resetView={resetView}
            addressSearch={AddressSearch}
            closeSearch={closeSearch}
            mapSettingsConfig={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  instance={INFRINGEMENT_RULES}
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  onUpdate={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}
        <GeocoderPin
          latitude={enableMapControls ? geocoding.latitude : 0}
          longitude={enableMapControls ? geocoding.longitude : 0}
          draggable
          onClick={() => {
            updateLocationTagOpen(!isLocationTagOpen);
          }}
          onDragStart={() => {
            addRemoveCircles(null);
            updateLocationTagOpen(false);
          }}
          onDragEnd={([longitude, latitude]: number[]) => {
            if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
              updateGeocoding({ longitude, latitude });
            }

            updateLocationAddress(null);

            addRemoveCircles({ longitude, latitude });
            setTimeout(() => {
              updateLocationTagOpen(true);
            }, 1);
          }}
        />
        {isLocationTagOpen && (
          <LocationPopup
            latitude={geocoding.latitude}
            longitude={geocoding.longitude}
            address={locationAddress || place}
            elevation={elevation}
            showFilterButton={false}
            languageData={{ latLabel, lngLabel, amslLabel, filterLabel }}
            isUsingPca={false}
            mapApis={mapApis}
            onClose={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
          />
        )}
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
        <RulerTool
          distanceUnits={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          addressCoordinates={geocoding}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
      </StyledMap>
    </div>
  );
};

/*

FOR 2.0.6 Release - viewing operations is not going ahead - to add operations to the map add the below code to the map component

  // Viewing Operations

  const selectedTrackTheme = configSelectors.getTheme('operations');
  // get layers for styling

  const opsTypeFilter = [
    'all',
    [
      'any',
      ['==', ['get', 'operationType'], 'TouchAndGo'],
      ['==', ['get', 'operationType'], 'Departure'],
      ['==', ['get', 'operationType'], 'Arrival'],
    ],
  ];
  const layers = [
    {
      prefix: mapBoxConfig.backgroundLayerPrefix,
      style: Object.assign({}, mapboxStyleBackgroundNormalPaint(selectedTrackTheme), {
        'line-opacity': getInterpolatedOpacity({ filter: opsTypeFilter, totalCount: 0 }),
      }),
    },
  ];

  const [areOperationsSet, updateAreOperationsSet] = useState<boolean>(false);

  const dataSelectors = useDataSelectors();
  const requiredDataForMap = dataSelectors.getRequiredDataForMap();

  const spatialFeaturesSelectors = useSpatialFeaturesSelector();
  const { pageInfo, datesSelected } = spatialFeaturesSelectors.getOperations();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

  // get values when dates change
  const datesArray = !!datesSelected ? getDateArray(datesSelected) : [];
  const dateRangeMapping = !!datesSelected ? getDateMapping(datesSelected) : [];
  if (!!datesSelected) {
    addSourcesStylesToMap(mapApis, datesArray, mapBoxConfig, layers);
  }

  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);
  const [areTracksDisabled, setAreTracksDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (datesSelected === null) {
      setAreTracksDisabled(true);
      removeMapSourcesByPrefix(mapApis, 'track_');
    } else {
      setAreTracksDisabled(false);
    }
  }, [datesSelected]);

  useEffect(() => {
    if (pageInfo && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      if (pageInfo.startCursor) {
        setMapApiStartCursor(pageInfo.startCursor);
      }
    }
  }, [pageInfo]);

  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles({
        startCursor: mapApiStartCursor,
        altitudeBands: null,
      });
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor]);

  useEffect(() => {
    if (tileLayers.length) {
      addCustomTileSource({
        mapApis,
        trackPaths: tileLayers,
        trackVisibility: !areTracksDisabled,
        paintStyle: mapboxStyleDynamicSource(selectedTrackTheme, OPERATION_COUNT),
      });
    }
  }, [tileLayers, mapApis]);

  if (FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
    // Toggle visibility of tile layer for dynamic server
    useEffect(() => {
      toggleMapSourcesVisibility({ mapApis, prefix: 'trackLayer_', hide: areTracksDisabled });
    }, [areTracksDisabled]);
  }

  const [layerCount, setLayerCount] = useState<number>(0);
  if (mapApis && isMapInitialized && !areOperationsSet) {
    try {
      const layers = mapApis.getStyle().layers;
      if (layers.length !== layerCount) {
        setLayerCount(layers.length);
      }
      updateAreOperationsSet(true);
    } catch {
      console.error(typeof mapApis.getStyle);
    }
  }

  // Pass in null until map is initialized
  useOperationsDataInMap({
    mapApis: zoomSet ? null : mapApis,
    dateRangeMapping,
    datesArray,
    mapBoxConfig,
    requiredDataForMap,
    selectedOperations: [],
    opstypeFilter: opsTypeFilter,
    viewport,
    layerCount,
    disableTracks: areTracksDisabled,
    areOperationsSet,
  });


*/
