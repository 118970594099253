/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext } from 'react';
import { getDeployedProductId } from 'src/utils/generics';
import { IConfigState, IStoreState, IConfigSelectors, IConfigurationUnits } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateContext';
import { useSelectors } from 'src/utils/storeHelpers';
import { parseQueryString } from 'src/utils/queryParams';
import {
  USER_SETTINGS,
  CONVERSION_TABLE,
  UNIT_PROFILES,
  UNIT_PROFILE_DEFAULT,
  UNIT_PROFILE_LOCAL,
} from 'src/constants';

export const useConfigSelectors: () => IConfigSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const configState: IConfigState = state.config;

  return useSelectors(configState, (state: IConfigState) => ({
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    getConfig: () => state.config,
    getUserConfig: () => state.userConfig,
    getTheme: (type: string): string => {
      if (!type || !state.config) {
        return 'default';
      }
      return state.config.globals.colors[type].colorScheme.toLowerCase();
    },
    getAltitudeColorBands: (type: string): string => {
      if (!type || !state.config) {
        return 'default';
      }
      return state.config.globals.colors[type].colorByAltitude;
    },
    getDistanceUnitsProfile: () => {
      if (!state.config) {
        return UNIT_PROFILE_DEFAULT;
      }
      return state.config.globals.unitProfileName;
    },
    isFeatureAvailable: (feature: string): boolean => {
      // check if 'featureToggle' defined and is set to true
      if (state.config && typeof state.config.featureToggle[feature] !== 'undefined') {
        return !!state.config.featureToggle[feature];
      }
      return false;
    },
    hasAccessFailed: () => state.failedToAccessConfig,
    getProductId: () => getDeployedProductId(),
    getTimeZone: (): string => {
      if (state.config && typeof state.config.globals !== 'undefined') {
        return state.config.globals.timeZone;
      }
      return 'UTC'; // Default is UTC/GMT
    },
    getAvailableRoutes: () => {
      if (state.config && typeof state.config.globals !== 'undefined') {
        return state.config.globals.availableRoutes;
      }

      return [];
    },
    getOperationFilterSet: () => {
      const savedSetName = parseQueryString(window.location.search).filter;
      const filterSet = savedSetName || 'defaults';
      if (state.config) {
        const configSet = state.config.operations.filter[filterSet];
        return configSet || state.config.operations.filter.defaults;
      }
      return {};
    },
    getIfPortraitLayout: (): boolean => state.userConfig.isPortrait,
    getIsFullscreen: (): boolean =>
      state.userConfig.layout && state.userConfig.layout.includes('FULL_SCREEN'),
    getLayout: (): string =>
      state.userConfig.layout ||
      (state.userConfig.isPortrait ? 'SPLIT_PORTRAIT_LAYOUT' : 'SPLIT_LANDSCAPE_LAYOUT'),
    getMapBackground: () => state.userConfig.mapStyle,
    getMapTrackStyle: () => state.userConfig.mapTrackStyle,
    getTrackDensitySize: () => state.userConfig.trackDensitySize,
    getOperationReadOnlyFields: () => {
      if (state.config && state.config.operations && state.config.operations.readOnlyFields) {
        return state.config.operations.readOnlyFields;
      }

      return [];
    },
    getUnits: () => {
      // Load the currently selected profile name, then grab its unit types
      const unitProfileName = state.config!.globals.unitProfileName.replace(' ', '_').toUpperCase();
      const configUnits: IConfigurationUnits =
        UNIT_PROFILES[unitProfileName] || UNIT_PROFILES[UNIT_PROFILE_DEFAULT];
      const localSystemUnitsExist = state.config!.globals.units;
      if (localSystemUnitsExist && unitProfileName === UNIT_PROFILE_LOCAL) {
        Object.keys(UNIT_PROFILES[UNIT_PROFILE_DEFAULT]).map((key: string) => {
          if (localSystemUnitsExist[key]) {
            configUnits[key] = localSystemUnitsExist[key];
          }
        });
      }

      const convertedUnits = {};
      Object.entries(configUnits).map(([key, value]) => {
        convertedUnits[key] = CONVERSION_TABLE[value];
      });

      return convertedUnits;
    },
    getNoiseModelConfig: () => state.config.aircraftNoiseModeling,
  }));
};

export const configReducer = (state: IConfigState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_USER_CONFIG:
      const newSettings = Object.assign({}, state.userConfig, action.payload);
      localStorage.setItem(USER_SETTINGS, JSON.stringify(newSettings));
      return Object.assign({}, state, { userConfig: newSettings });
    case appActionTypes.UPDATE_CONFIG:
    case appActionTypes.APP_UPDATE_FILTERS:
      let userSettings = {};
      const retrievedSettings = localStorage.getItem(USER_SETTINGS);
      if (retrievedSettings) {
        userSettings = JSON.parse(retrievedSettings);
      }
      return Object.assign({}, state, {
        config: action.payload,
        failedToAccessConfig: false,
        initialLoadCompleted: true,
        userConfig: Object.assign({}, state.userConfig, userSettings),
      });
    case appActionTypes.CONFIG_ERROR:
      return Object.assign({}, state, {
        config: null,
        failedToAccessConfig: true,
      });
    case appActionTypes.UPDATE_OPERATION_THEME:
      const newOperationConfig = state.config;
      newOperationConfig!.globals.colors.operations.colorScheme = action.payload;
      return Object.assign({}, state, { config: newOperationConfig });
    case appActionTypes.UPDATE_DISTANCE_UNITS:
      const newDistanceConfig = state.config;
      newDistanceConfig!.globals.unitProfileName = action.payload;
      return Object.assign({}, state, { config: newDistanceConfig });
    case appActionTypes.UPDATE_COLOR_BY_ALTITUDE_COLORS:
      const newColorByAltitudeColors = state.config;
      newColorByAltitudeColors.globals.colors.operations.colorByAltitude.colors = action.payload;
      return Object.assign({}, state, { config: newColorByAltitudeColors });
    case appActionTypes.UPDATE_COLOR_BY_ALTITUDE_BANDS:
      const newColorByAltitudeBands = state.config;
      newColorByAltitudeBands.globals.colors.operations.colorByAltitude.bands = action.payload;
      return Object.assign({}, state, { config: newColorByAltitudeBands });
    case appActionTypes.UPDATE_NOISE_SCENARIO_LICENSE_ACCEPTED:
      const newNoiseScenarioLicense = state.config;
      const software = newNoiseScenarioLicense.aircraftNoiseModeling.software;

      Object.keys(software).forEach(key => {
        if (action.payload[key]) {
          return (software[key] = {
            ...software[key],
            ...action.payload[key],
          });
        }
        return software[key];
      });
      return Object.assign({}, state, { config: newNoiseScenarioLicense });
    case appActionTypes.UPDATE_NOISE_SCENARIO_SELECTED_MODEL:
      const newNoiseScenarioSelectedModel = state.config;
      newNoiseScenarioSelectedModel.aircraftNoiseModeling.selectedModel = action.payload;
      return Object.assign({}, state, { config: newNoiseScenarioSelectedModel });
    default:
      return state;
  }
};
