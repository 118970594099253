import gql from 'graphql-tag';

export const UPDATE_INFRINGEMENTS = gql`
  mutation UpdateInfringements($ids: [Int!]!, $status: String!) {
    updateInfringements(ids: $ids, status: $status) {
      id
      status
    }
  }
`;

export const UPDATE_INFRINGEMENT_STATUS = gql`
  mutation UpdateInfringement($id: Int!, $status: String!) {
    updateInfringement(infringement: { id: $id, status: $status }) {
      id
      status
    }
  }
`;

export const updateInfringementQuery = 'updateInfringement';

const updateInfringementSchema = `
{
  id
  comments
}
`;

export const UPDATE_INFRINGEMENT = gql`
mutation ${updateInfringementQuery}($id: Int!, $comments: String!) {
  ${updateInfringementQuery}(infringement: {id: $id, comments: $comments}) ${updateInfringementSchema}
}
`;

export const ADD_OPERATION_TAGS = gql`
  mutation AddOperationsTags($ids: [Int]!) {
    addOperationTags(ids: $ids, tags: [{ name: "Vector" }]) {
      id
      tags {
        tagId
        name
      }
    }
  }
`;

export const REMOVE_OPERATION_TAGS = gql`
  mutation RemoveOperationsTags($ids: [Int]!) {
    removeOperationTags(ids: $ids, tags: [{ name: "Vector" }]) {
      id
      tags {
        tagId
        name
      }
    }
  }
`;
