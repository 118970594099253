import { IInfringementRulesPermissions } from 'src/@settings/interfaces';
import { IRolesSelectors } from 'src/app/props';
import { INFRINGEMENT_RULE_TYPES, PERMISSIONS } from 'src/constants';

export const formatAirportAndRunwayData = airportsData => {
  const airportIdsByRunwayName: any = {};
  const runwayNamesSortedByAirport: string[] = [];
  if (!!airportsData) {
    airportsData.forEach(airport => {
      const airportId = airport.id;
      if (!!airport.runways) {
        airport.runways.forEach(runway => {
          const runwayName: string = runway.name;
          airportIdsByRunwayName[runwayName] = airportId;
          runwayNamesSortedByAirport.push(runway.name);
        });
      }
    });
  }

  return {
    airportIdsByRunwayName,
    runwayNamesSortedByAirport,
  };
};

// Function to return permissions for each infringement rule and all actions update, read, insert and delete
export const getInfringementRulePermissions = (
  roleSelector: IRolesSelectors
): IInfringementRulesPermissions => {
  return Object.values(INFRINGEMENT_RULE_TYPES).reduce(
    (acc, ruleType) => ({
      ...acc,
      [ruleType]: {
        [PERMISSIONS.UPDATE]: roleSelector.hasPermission(
          `${ruleType}InfringementRule.${PERMISSIONS.UPDATE}`
        ),
        [PERMISSIONS.READ]: roleSelector.hasPermission(
          `${ruleType}InfringementRule.${PERMISSIONS.READ}`
        ),
        [PERMISSIONS.INSERT]: roleSelector.hasPermission(
          `${ruleType}InfringementRule.${PERMISSIONS.INSERT}`
        ),
        [PERMISSIONS.DELETE]: roleSelector.hasPermission(
          `${ruleType}InfringementRule.${PERMISSIONS.DELETE}`
        ),
      },
    }),
    {}
  ) as IInfringementRulesPermissions;
  // need to typecast because at the start of reduce it is an empty object and gives a typescript error
};
