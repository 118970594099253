import { IFilterItem } from '@ems/client-design-system';
import { ChangeEvent } from 'react';

// TODO - Move to 'app' level folder when global state/filter context has been built.
// https://envirosuitelimited.atlassian.net/browse/AX-2837
export interface FilterSetupSchema {
  filterCategory: string;
  filterType: FilterComponentTypes;
  filterProps: FilterPropType;
}

export enum FilterComponentTypes {
  Selection = 'selectionFilter',
  ID = 'idFilter',
  Time = 'timeFilter',
}

type FilterPropType = SelectionFilterFilterProps | IdFilterProps | TimeFilterProps;

export interface SelectionFilterFilterProps {
  filterItems: IFilterItem[];
  selectedItems: IFilterItem[];
  theme: Record<string, string> | null;
  iconCategories: string[];
  onUpdateFilter: (items: IFilterItem[]) => void;
  categoryLabel: string;
  languageData: { clearValue: string; filterValue: string; noMatchesFound: string };
}

export interface IdFilterProps {
  onFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFilterSubmit: () => void;
  onFilterClear: () => void;
  selectedItems: string;
  searchValue: string;
  languageData: { title: string; noMatchesFound: string; loading: string };
  categoryLabel: string;
}

export interface TimeFilterProps {
  filterValues: IRangeFilters;
  label: string;
  setInputValue: (value: string, field: 'from' | 'to') => void;
  languageData: {
    buttonTitle: string;
    from: string;
    placeholder: { from: string; to: string };
    to: string;
    validationError: string;
  };
}

export interface IRangeFilters {
  from: string;
  to: string;
}
