import React from 'react';
import {
  Filter,
  TextInput,
  Dropdown,
  FieldDetails,
  DaysOfWeekSelector,
  Icons,
  getConvertedMass,
  RangeSelector,
  validateTimeFormat,
  isValidTime,
  onCloseTimeDialogValidation,
  isCompletedTimeFormat,
  getConvertedDistance,
} from '@ems/client-design-system';
import { capitalizeObjectKeys } from 'src/utils/objectModifiers';
import {
  AIRCRAFT_TYPE_ICON_CODES,
  CHARACTER_UNICODES,
  FIFTY_NINE_SECONDS,
  INFRINGEMENT_DISPLAY_STRINGS,
  INFRINGEMENT_RULE_TYPES,
  MAX_SEGMENT_LENGTH_METERS,
  METRIC_BASE_UNIT,
  MIN_SEGMENT_LENGTH_METERS,
  NOISE_RULE_THRESHOLD_MAX,
  NOISE_RULE_THRESHOLD_MIN,
  ONE_DAY_IN_SECONDS,
  PERMISSIONS,
  UNIT_DECIBEL,
} from 'src/constants';

// theme
import themeToken from 'src/styles/themes.json';
import { IInfDetailsData, IInfRuleFormErrors } from 'src/@settings/interfaces';
import { getImperialOrMetricBaseUnit, setMeasurementAsNumber } from 'src/utils';
import {
  ICCOInfringementRule,
  ICDOInfringementRule,
  ICorridorInfringementRule,
  IExclusionInfringementRule,
  IGateInfringementRule,
  INoiseInfringementRule,
  TInfringementRule,
} from 'src/app/interfaces';

export const createNewInfringementRule = (filterData, dropdownData, airportIdsByRunwayName) => {
  const defaultInfRuleOperationTypes: string[] = filterData.operationTypes.map(
    opType => opType.key
  );

  const defaultInfRuleAircraftCategories = filterData.aircraftCategories.map(
    aircraftCat => aircraftCat.key
  );
  const defaultInfRuleOperatorCategories = filterData.operatorCategories.map(
    operatorCat => operatorCat.key
  );

  const infRule = {
    id: null,
    name: null,
    description: null,
    isActive: true,
    isNew: true,
    candidateFilter: {
      aircraftCategories: defaultInfRuleAircraftCategories,
      aircraftTypes: null,
      airportRunways: runwaysToAirportRunwaysArray(
        dropdownData.runwayNames,
        airportIdsByRunwayName
      ),
      daysOfWeek: null,
      startTime: null,
      endTime: null,
      lowestMaxTakeOffWeight: null,
      lowestMaxLandingWeight: null,
      operationTypes: defaultInfRuleOperationTypes,
      operatorCategories: defaultInfRuleOperatorCategories,
    },
  };
  return infRule;
};

export const secondsToHHMM = (seconds: number) =>
  seconds === 0 ? '00:00' : new Date(seconds * 1000).toISOString().substr(11, 5);

export const hhmmToSeconds = hhmm => {
  const splat = hhmm.split(':');
  if (splat && splat.length === 2) {
    const hours = parseInt(splat[0], 10);
    const minutes = parseInt(splat[1], 10);
    return hours * 3600 + minutes * 60;
  }
  return undefined;
};

const getCurrentlyConfiguredRunways = infRule => {
  const configuredRunways =
    infRule.candidateFilter && infRule.candidateFilter.airportRunways
      ? infRule.candidateFilter.airportRunways
      : null;
  if (configuredRunways) {
    const configuredRunwayNames: string[] = [];
    configuredRunways.forEach(runway => {
      runway.runwayNames.forEach(runwayName => {
        configuredRunwayNames.push(runwayName);
      });
    });
    return configuredRunwayNames;
  } else {
    return null;
  }
};

const getCurrentlyConfiguredKeys = (infRule, propName) => {
  const configuredValueKeys =
    infRule.candidateFilter && infRule.candidateFilter[propName]
      ? infRule.candidateFilter[propName]
      : null;
  if (configuredValueKeys) {
    const configuredKeys: string[] = [];
    configuredValueKeys.forEach(key => {
      configuredKeys.push(key);
    });
    return configuredKeys;
  } else {
    return null;
  }
};

const runwaysToAirportRunwaysArray = (runwayNames: string[], airportIdsByRunwayName) => {
  const newAirportRunwaysObj: any = {};
  for (let i = 0, ii = runwayNames.length; i < ii; i++) {
    const selectedRunwayName = runwayNames[i];
    const airportId = airportIdsByRunwayName[selectedRunwayName];
    if (newAirportRunwaysObj[airportId]) {
      newAirportRunwaysObj[airportId].push(selectedRunwayName);
    } else {
      newAirportRunwaysObj[airportId] = [selectedRunwayName];
    }
  }
  const newAirportRunwaysArray = [];
  Object.keys(newAirportRunwaysObj).forEach(airportId => {
    newAirportRunwaysArray.push({
      airportId,
      runwayNames: newAirportRunwaysObj[airportId],
    });
  });
  return newAirportRunwaysArray;
};

const handleRunwaysChange = (items, infDetailsData) => {
  items;
  const { infRule, setInfRule, airportIdsByRunwayName } = infDetailsData;
  const selectedRunwayNames = filterItemsToKeys(items);

  const candidateFilter = Object.assign({}, infRule.candidateFilter);
  candidateFilter.airportRunways = runwaysToAirportRunwaysArray(
    selectedRunwayNames,
    airportIdsByRunwayName
  );
  setInfRule(Object.assign({}, infRule, { candidateFilter }, { hasChanged: true }));
};

const handleFilterChange = (items, infDetailsData, propName) => {
  const { infRule, setInfRule } = infDetailsData;
  const selectedKeys = filterItemsToKeys(items);
  if (infRule.candidateFilter) {
    const candidateFilter = Object.assign({}, infRule.candidateFilter);
    candidateFilter[propName] = selectedKeys;
    setInfRule(Object.assign({}, infRule, { candidateFilter }, { hasChanged: true }));
  }
};

const arrayToFilterItems = items => {
  if (Array.isArray(items) && items.length) {
    return items.map((name, index) => ({ key: name.replace(/[^A-Za-z0-9]+/g, ''), label: name }));
  } else {
    return [];
  }
};

const filterItemsToKeys = items => items.map(item => item.key);

const filterItemsToLabels = items => items.map(item => item.label);

const handleTextInputChange = (evt, infDetailsData, propName) => {
  const nameValue = evt.target.value;
  const { infRule, setInfRule } = infDetailsData;
  setInfRule(Object.assign({}, infRule, { name: nameValue }, { hasChanged: true }));
};

const handleTimeFieldChange = (timeString, infDetailsData, propName) => {
  const { infRule, setInfRule } = infDetailsData;
  const timeInSeconds =
    hhmmToSeconds(timeString) + (propName === 'startTime' ? 0 : FIFTY_NINE_SECONDS);
  const candidateFilter = Object.assign({}, infRule.candidateFilter, {
    [propName]: timeInSeconds,
  });
  setInfRule(Object.assign({}, infRule, { candidateFilter }, { hasChanged: true }));
};

const handleWeightFieldChange = (evt, infDetailsData, propName) => {
  const { infRule, setInfRule } = infDetailsData;
  const value = evt.target.value.length ? parseInt(evt.target.value, 10) : null;
  if (isNaN(value)) {
    return;
  }
  const candidateFilter = Object.assign({}, infRule.candidateFilter, {
    [propName]: value,
  });
  setInfRule(Object.assign({}, infRule, { candidateFilter }, { hasChanged: true }));
};

export const daysMapFromList = (daysList: string[]) => {
  const initialValue: boolean = daysList ? false : true;
  const daysMap = {
    Sunday: initialValue,
    Monday: initialValue,
    Tuesday: initialValue,
    Wednesday: initialValue,
    Thursday: initialValue,
    Friday: initialValue,
    Saturday: initialValue,
  };
  if (daysList) {
    for (const day of daysList) {
      daysMap[day] = true;
    }
  }
  return daysMap;
};

export const daysListFromMap = daysMap => {
  const daysList = [];
  for (const day in daysMap) {
    if (daysMap[day]) {
      daysList.push(day);
    }
  }
  return daysList;
};

export const getTranslations = useLanguageSelectors => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      infringements: { title: goBackTitle },
    },
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, all, empty },
      },
      hints: { noMatchesFound },
      filters: filtersTranslation,
      lists: { aircraftCategories, operatorCategories, operationTypes },
    },
  } = languageSelectors.getLanguage();
  const translations = {
    goBackTitle,
    filterLanguageData: {
      noMatchesFound,
      filterValue,
      clearValue,
      all,
      empty,
    },
    timeInputLanguageData: filtersTranslation.time,
    aircraftCategories: capitalizeObjectKeys(aircraftCategories),
    operatorCategories: capitalizeObjectKeys(operatorCategories),
    operationTypes: capitalizeObjectKeys(operationTypes),
  };
  return translations;
};

export const filterKeysToFilterItems = (filterKeys, filterTranslations, iconType = '') => {
  const iconClassMap = {
    ac: 'filter-icon-ac',
    co: 'filter-icon-co',
  };
  const className = iconType ? iconClassMap[iconType] : '';
  return filterKeys && filterKeys.length
    ? filterKeys.map(filterKey => ({
        key: filterKey,
        label: filterTranslations[filterKey],
        icon: iconType ? `${iconType}-${filterKey.toLowerCase()}` : undefined,
        className,
      }))
    : [];
};

export const formatFilterData = (filterCategory, filterValues, filterTranslations) => {
  const iconType: string = AIRCRAFT_TYPE_ICON_CODES[filterCategory] || '';
  const filterItems = filterKeysToFilterItems(filterValues, filterTranslations, iconType);
  return filterItems;
};

export const sanitizeAirportRunways = airportRunways => {
  if (!(airportRunways && airportRunways.length)) {
    return null;
  }
  return airportRunways.map(runwayData => ({
    airportId: runwayData.airportId,
    runwayNames: runwayData.runwayNames,
  }));
};

export const returnReadOnlyField = (value, label) => (
  <FieldDetails
    key={`${value} ${label} `}
    fieldType="inputField"
    className="infdetailspanel-item col-md-2 feature__field"
    label={label}
    text={value}
  />
);

// If no value return emdash
const formatReadOnlyMeasurement = (value, unit) => {
  if (Number(value)) {
    // get converted mass but allow for non base value units
    return `${getConvertedMass(Number(value), unit, 2, unit)}`;
  } else {
    return `${CHARACTER_UNICODES.EMDASH}`;
  }
};

const formatReadOnlyFilter = (currentlyConfiguredItems, allValues, translationData) => {
  const list = filterItemsToLabels(currentlyConfiguredItems);

  if (list.length === allValues.length) {
    return <span className="infrule-filter-value">{translationData.all}</span>;
  } else if (!list.length) {
    return <span className="infrule-filter-value">{translationData.empty}</span>;
  } else {
    return <span className="infrule-filter-value">{list.join(', ')}</span>;
  }
};
// Candidate Panel Fields

export const nameInput = infDetailsData => {
  const { infRule, isEditing } = infDetailsData;
  const nameValue = infRule.name || '';
  if (isEditing) {
    return (
      <>
        <div className="infdetailspanel-label">Name</div>
        <TextInput
          className="config-element__input"
          value={nameValue}
          disabled={!isEditing}
          onChange={evt => handleTextInputChange(evt, infDetailsData, 'name')}
        />
      </>
    );
  } else {
    return returnReadOnlyField(nameValue, 'Name');
  }
};

export const infringementTypeDropdown = (infDetailsData: IInfDetailsData) => {
  const { infRule, setInfRule, isEditing, permissions } = infDetailsData;
  const infType = infRule.infringementType;

  const handleChange = item => {
    setInfRule(Object.assign({}, infRule, { infringementType: item.key }, { hasChanged: true }));
  };

  // Only add rules where the user has insert permissions for that rule type
  const ruleTypes = Object.keys(INFRINGEMENT_DISPLAY_STRINGS)
    .map(key => ({
      key,
      label: INFRINGEMENT_DISPLAY_STRINGS[key],
    }))
    .filter(dropdownItem => permissions[dropdownItem.key][PERMISSIONS.INSERT]);

  const selectedRuleType = infType
    ? {
        key: infType,
        label: INFRINGEMENT_DISPLAY_STRINGS[infType],
      }
    : null;

  if (isEditing) {
    return (
      <>
        <div className="infdetailspanel-label">{'Type'}</div>
        <Dropdown
          disabled={!!infRule.id}
          placeholderValue=""
          searchItems={ruleTypes}
          isNullable={false}
          updateSelection={item => handleChange(item)}
          selectedItem={selectedRuleType}
        />
      </>
    );
  } else {
    return returnReadOnlyField(selectedRuleType.label, 'Type');
  }
};

export const getStartEndTimeInput = infDetailsData => {
  const { infRule, translations, isEditing } = infDetailsData;

  const getInfTimeAsSeconds = (timeType: 'startTime' | 'endTime') =>
    infRule.candidateFilter &&
    (infRule.candidateFilter[timeType] || infRule.candidateFilter[timeType] === 0)
      ? secondsToHHMM(infRule.candidateFilter[timeType])
      : '';

  const times = { from: getInfTimeAsSeconds('startTime'), to: getInfTimeAsSeconds('endTime') };

  const handleTimeChange = (time: string, toFrom: string) => {
    times[toFrom] = time;
    handleTimeFieldChange(time, infDetailsData, toFrom === 'to' ? 'endTime' : 'startTime');
  };

  if (isEditing) {
    return (
      <RangeSelector
        key="filter-time-selector"
        type="time"
        min={null}
        max={null}
        isInline
        customLabelFrom={'Start'}
        customLabelTo={'End'}
        fromInputValue={times.from}
        toInputValue={times.to}
        setInputValue={handleTimeChange}
        label={''}
        validateInputFormat={validateTimeFormat}
        isValidValue={isValidTime}
        languageData={translations.timeInputLanguageData}
        onCloseValidation={onCloseTimeDialogValidation}
        isCompletedValueFormat={isCompletedTimeFormat}
      />
    );
  } else {
    return (
      <div className="inftimerange">
        <div className="inftimerange-input">
          {returnReadOnlyField(getInfTimeAsSeconds('startTime'), 'Start')}
        </div>
        <div className="inftimerange-input">
          {returnReadOnlyField(getInfTimeAsSeconds('endTime'), 'End')}
        </div>
      </div>
    );
  }
};

export const getInfDetailsPanelDaysOfWeek = infDetailsData => {
  const { infRule, setInfRule, isEditing } = infDetailsData;
  if (!infRule.candidateFilter || typeof infRule.candidateFilter.daysOfWeek === 'undefined') {
    return;
  }
  const daysMap = daysMapFromList(infRule.candidateFilter.daysOfWeek);
  const handleDaysChange = daysMapNew => {
    for (const day of Object.keys(daysMap)) {
      daysMap[day] = daysMapNew[day];
    }
    const daysOfWeek = daysListFromMap(daysMap);
    const candidateFilter = Object.assign({}, infRule.candidateFilter, { daysOfWeek });
    setInfRule(Object.assign({}, infRule, { candidateFilter }, { hasChanged: true }));
  };

  return (
    <div className="infdetailspanel-item daysofweek">
      <DaysOfWeekSelector
        daysMap={daysMap}
        onDaysChange={handleDaysChange}
        isReadOnly={!isEditing}
      />
    </div>
  );
};

export const operationTypesFilter = infDetailsData => {
  const { infRule, filterData, translations, isEditing, selectedTrackTheme } = infDetailsData;
  const availableOperationTypes = filterData.operationTypes;
  const currentlyConfiguredOperationTypes = formatFilterData(
    'operationTypes',
    getCurrentlyConfiguredKeys(infRule, 'operationTypes'),
    translations.operationTypes
  );

  const iconTheme = themeToken.operations[selectedTrackTheme];
  if (isEditing) {
    return (
      <Filter
        key="operationTypes"
        categoryName="operationTypes"
        isCategoryNameRequired={false}
        iconCategories={['operationTypes']}
        filterItems={availableOperationTypes}
        selectedItems={currentlyConfiguredOperationTypes}
        type="multiple"
        theme={iconTheme}
        updateItems={items => handleFilterChange(items, infDetailsData, 'operationTypes')}
        languageData={translations.filterLanguageData}
      />
    );
  } else {
    if (currentlyConfiguredOperationTypes.length) {
      return (
        <>
          {currentlyConfiguredOperationTypes.map((operation, index) => (
            <Icons
              key={`operation-icon-${index}`}
              iconName={`ic-${operation.icon}`}
              title={`ic-${operation.icon}`}
              size={24}
              style={{
                fill: iconTheme[operation.key.toLowerCase()],
                color: iconTheme[operation.key.toLowerCase()],
              }}
            />
          ))}
        </>
      );
    }
    return <h5>{translations.filterLanguageData.all}</h5>;
  }
};

export const runwayFilter = infDetailsData => {
  const { infRule, dropdownData, translations, isEditing } = infDetailsData;
  const availableRunways = arrayToFilterItems(dropdownData.runwayNames);
  const currentlyConfiguredRunways = arrayToFilterItems(getCurrentlyConfiguredRunways(infRule));

  if (!isEditing) {
    return formatReadOnlyFilter(
      currentlyConfiguredRunways,
      availableRunways,
      translations.filterLanguageData
    );
  } else {
    return (
      <Filter
        key="runwayNames"
        categoryName=""
        isCategoryNameRequired={false}
        filterItems={availableRunways}
        selectedItems={currentlyConfiguredRunways}
        type="multiple"
        hasTruncationOptions
        updateItems={items => handleRunwaysChange(items, infDetailsData)}
        languageData={translations.filterLanguageData}
      />
    );
  }
};

export const aircraftCategoriesFilter = infDetailsData => {
  const { infRule, filterData, translations, isEditing } = infDetailsData;
  const availableAircraftCategories = filterData.aircraftCategories;
  const currentlyConfiguredAircraftCategories = formatFilterData(
    'aircraftCategories',
    getCurrentlyConfiguredKeys(infRule, 'aircraftCategories'),
    translations.aircraftCategories
  );
  if (!isEditing) {
    return formatReadOnlyFilter(
      currentlyConfiguredAircraftCategories,
      availableAircraftCategories,
      translations.filterLanguageData
    );
  }
  return (
    <Filter
      key="aircraftCategories"
      categoryName=""
      isCategoryNameRequired={false}
      filterItems={availableAircraftCategories}
      selectedItems={currentlyConfiguredAircraftCategories}
      type="multiple"
      hasTruncationOptions
      updateItems={items => handleFilterChange(items, infDetailsData, 'aircraftCategories')}
      languageData={translations.filterLanguageData}
    />
  );
};

export const operatorCategoriesFilter = infDetailsData => {
  const { infRule, filterData, translations, isEditing } = infDetailsData;
  const availableOperatorCategories = filterData.operatorCategories;
  const currentlyConfiguredOperatorCategories = formatFilterData(
    'operatorCategories',
    getCurrentlyConfiguredKeys(infRule, 'operatorCategories'),
    translations.operatorCategories
  );

  if (!isEditing) {
    return formatReadOnlyFilter(
      currentlyConfiguredOperatorCategories,
      availableOperatorCategories,
      translations.filterLanguageData
    );
  }
  return (
    <Filter
      key="operatorCategories"
      categoryName=""
      isCategoryNameRequired={false}
      filterItems={availableOperatorCategories}
      selectedItems={currentlyConfiguredOperatorCategories}
      type="multiple"
      hasTruncationOptions
      updateItems={items => handleFilterChange(items, infDetailsData, 'operatorCategories')}
      languageData={translations.filterLanguageData}
    />
  );
};

export const MTOWinput = infDetailsData => {
  const { infRule, units, isEditing } = infDetailsData;
  const lowestMTOW =
    infRule.candidateFilter && infRule.candidateFilter.lowestMaxTakeOffWeight
      ? infRule.candidateFilter.lowestMaxTakeOffWeight
      : '';

  if (isEditing) {
    return (
      <>
        <div className="infdetailspanel-label">MTOW</div>
        <div className={'infdetailspanel-weight-input'}>
          <TextInput
            className="config-element__input"
            value={lowestMTOW}
            label={'MTOW'}
            onChange={evt => handleWeightFieldChange(evt, infDetailsData, 'lowestMaxTakeOffWeight')}
            disabled={!isEditing}
          />
          <span className="infdetailspanel__unit">{units.mass}</span>
        </div>
      </>
    );
  } else {
    return returnReadOnlyField(`${formatReadOnlyMeasurement(lowestMTOW, units.mass)}`, 'MTOW');
  }
};

export const MLWinput = infDetailsData => {
  const { infRule, units, isEditing } = infDetailsData;
  const lowestMLW =
    infRule.candidateFilter && infRule.candidateFilter.lowestMaxLandingWeight
      ? infRule.candidateFilter.lowestMaxLandingWeight
      : '';
  if (isEditing) {
    return (
      <>
        <div className="infdetailspanel-label">MLW</div>
        <div className={'infdetailspanel-weight-input'}>
          <TextInput
            className="config-element__input"
            value={lowestMLW}
            label={'MLW'}
            onChange={evt => handleWeightFieldChange(evt, infDetailsData, 'lowestMaxLandingWeight')}
            disabled={!isEditing}
          />
          <span className="infdetailspanel__unit">{units.mass}</span>
        </div>
      </>
    );
  } else {
    return returnReadOnlyField(`${formatReadOnlyMeasurement(lowestMLW, units.mass)}`, 'MLW');
  }
};

// Inf Rule form validation

export const validateInfRule = (
  infDetailsData: IInfDetailsData,
  infRules: TInfringementRule[],

  // some fields validate on save and some on input change
  isValidatingOnSave?: boolean
): { isValid: boolean; errors: IInfRuleFormErrors } => {
  const infRule = infDetailsData.infRule;
  const errors: IInfRuleFormErrors = {};

  if (infRule) {
    // CANDIDATE PANEL VALIDATION

    // - Checks name is unique for that infringement rule type
    if (!!infRule.infringementType) {
      if (
        infRules.findIndex(
          rule =>
            rule.infringementType === infRule.infringementType &&
            rule.name === infRule.name &&
            rule.id !== infRule.id
        ) !== -1
      ) {
        errors.name = `Rule name must be unique`;
      }
    }
    if (!infRule.infringementType) {
      errors.infringementType = `An infringement type must be selected`;
    }

    // & greater than min length & less than max length
    // Checking for empty string but not null
    if (!infRule.name && isValidatingOnSave) {
      errors.name = 'Field cannot be empty';
    }
    if (infRule.name && infRule.name.length > 50) {
      errors.name = 'Name cannot be longer than 50 characters';
    }

    // Start and end time
    const isValueSet = value => value !== null && value !== undefined;
    if (
      (!isValueSet(infRule.candidateFilter.endTime) && infRule.candidateFilter.startTime) ||
      (infRule.candidateFilter.endTime &&
        !isValueSet(infRule.candidateFilter.startTime) &&
        isValidatingOnSave)
    ) {
      errors.startEndTime = 'Both a start and end time must be entered';
    }

    // if time is undefined then it's empty which is ok
    const isValidTime = time => (!isNaN(time) && time <= ONE_DAY_IN_SECONDS) || !time;

    if (
      !isValidTime(infRule.candidateFilter.startTime) ||
      !isValidTime(infRule.candidateFilter.endTime)
    ) {
      errors.startEndTime = 'Invalid time';
    }
  }

  // RULE PANEL VALIDATION

  const validateGateRule = (infRule: IGateInfringementRule, errors: IInfRuleFormErrors): void => {
    const gateArray = infRule.gateCriteria;
    const gate = Array.isArray(gateArray) ? gateArray[0] : undefined;
    if (!gate && isValidatingOnSave) {
      errors.gate = 'A gate must be selected';
    }
    if (gate && !gate.gateDirections && isValidatingOnSave) {
      errors.gateDirection = 'A direction must be selected';
    }
  };

  const validateNoiseRule = (infRule: INoiseInfringementRule, errors: IInfRuleFormErrors): void => {
    if (infRule.thresholds && isValidatingOnSave) {
      const noiseThreshold = Number(infRule.thresholds[0].threshold);
      if (noiseThreshold < NOISE_RULE_THRESHOLD_MIN) {
        errors.threshold = `Threshold must be greater than ${NOISE_RULE_THRESHOLD_MIN} ${UNIT_DECIBEL}`;
      } else if (noiseThreshold > NOISE_RULE_THRESHOLD_MAX) {
        errors.threshold = `Threshold must be under ${NOISE_RULE_THRESHOLD_MAX} ${UNIT_DECIBEL}`;
      }
    } else if (!infRule.thresholds && isValidatingOnSave) {
      errors.noiseMonitor = 'A noise monitor must be selected';
    }
  };

  const validateCorridorRule = (
    infRule: ICorridorInfringementRule,
    errors: IInfRuleFormErrors
  ): void => {
    if (!infRule.corridorId && isValidatingOnSave) {
      errors.corridor = 'A corridor must be selected';
    }
  };

  const validateExclusionZoneRule = (
    infRule: IExclusionInfringementRule,
    errors: IInfRuleFormErrors
  ): void => {
    if (!infRule.selectionZoneId && isValidatingOnSave) {
      errors.exclusionZone = 'An exclusion zone must be selected';
    }
  };

  const validateFloorLessThanCeiling = (
    floor: number,
    ceiling: number,
    errors: IInfRuleFormErrors
  ) => {
    if (floor > ceiling) {
      errors.floorAltitude = 'Floor must be less than ceiling';
    }
  };

  const validateSegmentLengthMin = (
    segmentLength: number,
    segmentKey: string,
    errors: IInfRuleFormErrors
  ) => {
    if (
      setMeasurementAsNumber(
        getConvertedDistance(segmentLength, METRIC_BASE_UNIT, 0, infDetailsData.units.distance)
      ) < MIN_SEGMENT_LENGTH_METERS
    ) {
      errors[segmentKey] = `${segmentKey} must be greater than ${getConvertedDistance(
        MIN_SEGMENT_LENGTH_METERS,
        getImperialOrMetricBaseUnit(infDetailsData.units.distance),
        0
      )}`;
    }
  };

  const validateSegmentLengthMax = (
    segmentLength: number,
    segmentKey: string,
    errors: IInfRuleFormErrors
  ) => {
    if (
      setMeasurementAsNumber(
        getConvertedDistance(segmentLength, METRIC_BASE_UNIT, 0, infDetailsData.units.distance)
      ) > MAX_SEGMENT_LENGTH_METERS
    ) {
      errors[segmentKey] = `${segmentKey} must be less than ${getConvertedDistance(
        MAX_SEGMENT_LENGTH_METERS,
        getImperialOrMetricBaseUnit(infDetailsData.units.distance),
        0
      )}`;
    }
  };

  const validateCcoCdoRule = (
    infRule: ICCOInfringementRule | ICDOInfringementRule,
    errors: IInfRuleFormErrors
  ): void => {
    if (infRule.floorAltitude && infRule.ceilingAltitude) {
      validateFloorLessThanCeiling(
        Number(infRule.floorAltitude),
        Number(infRule.ceilingAltitude),
        errors
      );
    }
    if (infRule.minLevelSegmentLength) {
      validateSegmentLengthMin(infRule.minLevelSegmentLength, 'minLevelSegmentLength', errors);
      validateSegmentLengthMax(infRule.minLevelSegmentLength, 'minLevelSegmentLength', errors);
    }

    if (infRule.autoInfringementSegmentLength) {
      validateSegmentLengthMin(
        infRule.autoInfringementSegmentLength,
        'minLevelSegmentLength',
        errors
      );
    }

    if (!infRule.floorAltitude && infRule.floorAltitude !== 0 && isValidatingOnSave) {
      errors.floorAltitude = 'Floor value must be set';
    }
    if (!infRule.ceilingAltitude && isValidatingOnSave) {
      errors.ceilingAltitude = 'Ceiling Altitude must be set';
    }
  };

  switch (infRule.infringementType) {
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      validateGateRule(infRule as IGateInfringementRule, errors);
      break;
    case INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT:
      validateNoiseRule(infRule as INoiseInfringementRule, errors);
      break;
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      validateCorridorRule(infRule as ICorridorInfringementRule, errors);
      break;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      validateExclusionZoneRule(infRule as IExclusionInfringementRule, errors);
      break;
    case INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT:
    case INFRINGEMENT_RULE_TYPES.CDO_INFRINGEMENT:
      validateCcoCdoRule(infRule as ICCOInfringementRule, errors);
      break;
  }

  // if error object is empty - form is valid :)
  return { isValid: !Object.keys(errors).length, errors };
};
