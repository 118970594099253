import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { RouteComponentProps } from 'react-router-dom';

// Layout
import { RegularLayout } from 'src/components/Layout';

// Container
import { ViewScenarioContainer } from 'src/@scenarioGeneration/containers';

interface IMatchParams {
  id?: string;
}

const ViewNoiseScenario = ({ match }: RouteComponentProps<IMatchParams>) => {
  const { id } = match.params;
  return (
    <RegularLayout>
      <ViewScenarioContainer scenarioId={id} />
    </RegularLayout>
  );
};

export const ViewNoiseScenarioScreen = withRouteProps(ViewNoiseScenario);
