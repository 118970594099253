import ApolloClient from 'apollo-client';
import { useMemo } from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import { ITableColumnData, ITableFilterObject } from '../interfaces';
import { ISortActionCallback, TableHeaderTranslationModuleKey } from './interfaces';
import { TableColumnHeader } from './TableColumnHeader';

export const useTableColumnHeaders = ({
  columnData,
  sortAction,
  sortObjectSelector,
  translationModuleName,
  isLoading,
  client,
  filterObject,
  beforeSort,
  resultSize,
  dispatcher,
}: {
  columnData: ITableColumnData[]; // The number of items returned when sort is selected
  sortAction: (data: ISortActionCallback, dispatcher?) => void; // The action called sort
  sortObjectSelector: object;
  translationModuleName: TableHeaderTranslationModuleKey; //  Name of module for header title translation
  isLoading?: boolean; //  Boolean value if the table data is being load
  client?: ApolloClient<object>;
  filterObject?: ITableFilterObject;
  beforeSort?: () => void;
  resultSize?: number; // The number of items returned when sort is selected
  dispatcher?; // Dispatcher to be called on sort
}): Record<string, JSX.Element | string> => {
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();

  const {
    fields: { [translationModuleName]: rowHeaders },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  return useMemo(() => {
    const headersObject = columnData.reduce(
      (acc, headerItem) => ({
        ...acc,
        [headerItem.columnName]: TableColumnHeader({
          sortName: headerItem.key,
          sortable: headerItem.key && !headerItem.unSortable ? true : false,
          showSortIcon: headerItem.key ? true : false,
          sortAction,
          sortObjectSelector,
          resultSize,
          isLoading,
          dispatcher,
          languageData: {
            title: headerItem.title,
            abbreviation: headerItem.abbreviation ? headerItem.abbreviation : null,
            sortBy,
          },
          client,
          filterObject,
          beforeSort,
          titleIcon: headerItem.titleIcon,
        }),
      }),
      {}
    );
    return Object.assign({}, rowHeaders, headersObject);
  }, [
    columnData,
    resultSize,
    dispatcher,
    sortAction,
    sortObjectSelector,
    translationModuleName,
    isLoading,
    client,
    filterObject,
    beforeSort,
  ]);
};
