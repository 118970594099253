import { ApolloClient } from 'apollo-client';
import { fetchInfringementRulesData } from 'src/app/resolvers/infringementRulesResolver';
import { appActionTypes } from 'src/app/newActionTypes';
import { ISortActionData, TFilterAction } from 'src/components/TableComponents';

import {
  ILoadingInfringementRules,
  ISortInfringementRules,
  IUpdateInfringementRules,
} from './interfaces';
import { TInfringementRule } from '../interfaces';

export const loadInfringementRules = (client: ApolloClient<object>, dispatcher): void => {
  fetchInfringementRulesData(client)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_INFRINGEMENT_RULES, payload: response });
    })
    .catch(error => {
      dispatcher({ type: appActionTypes.INFRINGEMENT_RULES_ERROR, payload: error });
    });
};

export const filterInfringementRulesSettings = ({
  client,
  dispatcher,
  filterString,
  abortControllerSignal,
}: TFilterAction): void => {
  dispatcher({ type: appActionTypes.LOADING_INFRINGEMENT_RULES });
  fetchInfringementRulesData(client, filterString, abortControllerSignal)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_INFRINGEMENT_RULES, payload: response });
      dispatcher({ type: appActionTypes.INFRINGEMENT_RULES_LOADED });
    })
    .catch(error => {
      dispatcher({ type: appActionTypes.INFRINGEMENT_RULES_ERROR, payload: error });
    });
};

export const ignoreInfringementRules = (client: ApolloClient<object>, dispatcher): void => {
  dispatcher({ type: appActionTypes.IGNORE_INFRINGEMENT_RULES });
};

export const updateInfringementRules = (
  rules: TInfringementRule[],
  dispatcher: React.Dispatch<IUpdateInfringementRules | ILoadingInfringementRules>
) => {
  dispatcher({ type: appActionTypes.UPDATE_INFRINGEMENT_RULES, payload: rules });
};

// INF RULES
export const sortInfringementRulesTable = async (
  data: ISortActionData,
  dispatcher: React.Dispatch<ISortInfringementRules>
) => {
  await dispatcher({
    type: appActionTypes.SORT_INFRINGEMENT_RULES,
    payload: { sortName: data.sortName },
  });
};
