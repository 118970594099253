import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// selectors
import { useLanguageSelectors } from 'src/app/reducers';
// common components
import { Filter, FilterRow, IFilterItem, IDFilter } from '@ems/client-design-system';

// interfaces
import { IFilterObject, ITableFilterObject, TFilterAction, TTableFilterTypes } from '../interfaces';
import { useEffectOnUnmountWithDependency } from 'src/utils';
import { formatFilterString, getActiveFilters } from 'src/utils/tableHelpers/tableFilterHelpers';

interface ITableFilterProps {
  tableFilterObject: ITableFilterObject | null;
  setTableFilterObject: any;
  onClearFilters: any;
  areFilterOptionsInitialized: boolean;
  defaultFilterString?: string;
  sortString?: string;
  isLoading?: boolean;
  beforeFilter?: () => void;
  onFilterAction?: (TFilterAction) => void;
  dispatcher?: any;
  onFilterUpdate?: (tableFilterObject: ITableFilterObject) => void; // pass back the selected filter items to filter table from
}

const TableInputFilter = ({
  filter,
  languageData,
  submitAction,
}: {
  filter: IFilterObject;
  languageData: { noMatchesFound: string; loading: string };
  submitAction: (title: string, selectedOptions: IFilterItem[]) => void;
}) => {
  const { noMatchesFound, loading } = languageData;
  const [liveEditValue, setLiveEditValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  // Check for all filter clear action
  useEffect(() => {
    if (filter.selectedItems[0] && filter.selectedItems[0].label) {
      setSelectedValue(filter.selectedItems[0].label);
      setLiveEditValue(filter.selectedItems[0].label);
    } else {
      setSelectedValue('');
      setLiveEditValue('');
    }
  }, [filter]);

  const onTextFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    filterType: TTableFilterTypes
  ) => {
    const value = e.currentTarget.value;
    const numberOnlyRegex = /^\d+$/;
    // If filter is type int, only allow numbers
    if (filterType === 'inputNumberFilter') {
      if (numberOnlyRegex.test(value)) {
        setLiveEditValue(value);
      }
    } else {
      setLiveEditValue(value);
    }
  };

  return (
    <IDFilter
      categoryName={filter.title}
      key="filter-id-selector"
      value={liveEditValue}
      onChange={e => onTextFilterChange(e, filter.filterType)}
      onSubmit={() => {
        submitAction(filter.title, [{ key: liveEditValue, label: liveEditValue }]);
      }}
      selectedID={selectedValue}
      onClearSelect={() => {
        setLiveEditValue('');
        submitAction(filter.title, []);
      }}
      languageData={{
        title: filter.title,
        noMatchesFound,
        loading,
      }}
    />
  );
};

export const TableFilter: React.FC<ITableFilterProps> = ({
  onFilterAction = () => {},
  dispatcher,
  defaultFilterString = '',
  sortString = '',
  isLoading,
  tableFilterObject,
  setTableFilterObject,
  onClearFilters,
  areFilterOptionsInitialized,
  beforeFilter,
  onFilterUpdate,
}) => {
  // State and behavior
  const [filterComponents, updateFilterComponents] = useState<JSX.Element[]>([]);
  const [isClearFiltersDisabled, updateClearFiltersDisabled] = useState<boolean>(true);
  const [filterString, updateFilterString] = useState<string>(defaultFilterString);
  const [isFilterActivated, updateIsFilterActivated] = useState<boolean>(false);
  // client
  const client = useApolloClient();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { noMatchesFound, loading },
    },
  } = languageSelectors.getLanguage();

  const areItemsSelected = (tableObject: ITableFilterObject): boolean =>
    tableObject.filters.some(filter => !!filter.selectedItems.length);
  const [abortControl, setAbortController] = useState(new AbortController());
  // GQL request takes place here - so abort controller can stop race conditions happening if user rapidly changes filters
  useEffect(() => {
    if (isFilterActivated && dispatcher) {
      if (isLoading) {
        abortControl.abort();
      }
      const controller = new AbortController();
      setAbortController(controller);
      onFilterAction({
        client,
        dispatcher,
        filterString,
        activeFilterObject: getActiveFilters(tableFilterObject),
        abortControllerSignal: controller.signal,
        sortString,
      });
    }
  }, [filterString]);

  const onFilterOptionSelected = (title: string, selectedOptions: IFilterItem[]) => {
    if (beforeFilter) {
      beforeFilter();
    }
    updateIsFilterActivated(true);

    // creates a new updated filter with the selected items
    const updatedTableFilterObject = {
      ...tableFilterObject,
      filters: tableFilterObject.filters.map((filterObject: IFilterObject) =>
        filterObject.title === title
          ? { ...filterObject, selectedItems: selectedOptions }
          : filterObject
      ),
    };

    if (onFilterUpdate) {
      onFilterUpdate(updatedTableFilterObject);
    }

    // if items are selected - clear filter button is not disabled
    updateClearFiltersDisabled(!areItemsSelected(updatedTableFilterObject));
    setTableFilterObject(updatedTableFilterObject);

    const filterString: string = formatFilterString(updatedTableFilterObject, defaultFilterString);
    updateFilterString(filterString);
  };

  const clearSelectedItems = () => {
    updateFilterString(
      formatFilterString({ areOptionsInitialized: true, filters: [] }, defaultFilterString)
    );

    onClearFilters();
    updateClearFiltersDisabled(true);
  };

  // FILTER SET UP
  useEffect(() => {
    if (!!tableFilterObject) {
      updateFilterComponents(
        tableFilterObject.filters.map((filter: IFilterObject, index: number) => {
          if (
            filter.filterType === 'inputTextFilter' ||
            filter.filterType === 'inputNumberFilter'
          ) {
            return (
              <TableInputFilter
                key={index}
                filter={filter}
                languageData={{ noMatchesFound, loading }}
                submitAction={onFilterOptionSelected}
              />
            );
          }
          return (
            // Todo - create function for returning different filter types - e.g date time
            <Filter
              key={index}
              categoryName={filter.title}
              filterItems={filter.filterItems}
              selectedItems={filter.selectedItems}
              updateItems={(items: IFilterItem[]) => onFilterOptionSelected(filter.title, items)}
              languageData={{ clearValue, filterValue, noMatchesFound }}
              theme={filter.theme ? filter.theme : null}
            />
          );
        })
      );
    }
  }, [tableFilterObject, areFilterOptionsInitialized]);

  // FILTER CLEAN UP/UNMOUNT

  // Resets filter and data - only called if filter activated/modified
  // to avoid expensive reloads if full data set is already loaded
  const cleanUpOnUnmount = isFilterActivated => {
    if (isFilterActivated) {
      const emptyFilterString = formatFilterString(
        { areOptionsInitialized: true, filters: [] },
        defaultFilterString
      );
      onFilterAction({
        client,
        dispatcher,
        filterString: emptyFilterString,
        abortControllerSignal: abortControl.signal,
      });
      onClearFilters();
      updateClearFiltersDisabled(true);
    }
  };

  useEffectOnUnmountWithDependency(cleanUpOnUnmount, isFilterActivated);

  return (
    <FilterRow
      key={`${areFilterOptionsInitialized} Filter Row`}
      filters={filterComponents}
      clearFilters={clearSelectedItems}
      clearDisabled={isClearFiltersDisabled}
      languageData={{ clearFilters }}
    />
  );
};
