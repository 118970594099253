import gql from 'graphql-tag';

export const GET_AUDIT_LOG_ENTRY_SUMMARIES_BY_TIMERANGE = gql`
  query getChangelogsByTimeRange(
    $filter: AuditLogEntrySummaryFilter
    $sort: [OrderByType]
    $startTime: DateTimeOffset
    $endTime: DateTimeOffset
    $first: Int
    $after: String
  ) {
    auditLogEntrySummariesByTimeRange(
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      first: $first
      after: $after
    ) {
      totalCount
      ...auditPageInfo
      ...auditItems
      __typename
    }
  }

  fragment auditItems on AuditLogEntrySummaryConnection {
    edges {
      cursor
      node {
        changeType
        dataId
        dataType
        fieldName
        id
        newValue
        oldValue
        revertable
        time
        userName
      }
    }
  }

  fragment auditPageInfo on AuditLogEntrySummaryConnection {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;
