import { useState } from 'react';
import { ITag } from 'src/utils/mapTagHelpers';
import { v4 as uuidv4 } from 'uuid';

export const useMapTags = () => {
  const [displayedMapTags, setDisplayedMapTags] = useState<ITag[]>([]);
  const addTagToMap = newTag => {
    if (
      newTag &&
      // Prevent tag being created from a point that is already in the list

      !displayedMapTags.some(
        listItem =>
          listItem.data.latitude === newTag.latitude && listItem.data.longitude === newTag.longitude
      )
    ) {
      setDisplayedMapTags([
        ...displayedMapTags,
        {
          uuid: uuidv4(),
          data: newTag,
          longitude: newTag.longitude,
          latitude: newTag.latitude,
        },
      ]);
    }
  };
  const clearDisplayedTags = () => {
    setDisplayedMapTags([]);
  };
  const removeDisplayedTag = (id: string) => {
    setDisplayedMapTags(displayedMapTags.filter(({ uuid }) => uuid !== id));
  };

  // Used for the onMapClick hook which returns an array of operations
  const addTagOnTrackClick = clickedOperationPoint => {
    const selectedOperation = clickedOperationPoint[0];
    if (selectedOperation && selectedOperation.latitude && selectedOperation.longitude) {
      addTagToMap(selectedOperation);
    }
  };

  return {
    displayedMapTags,
    addTagToMap,
    clearDisplayedTags,
    removeDisplayedTag,
    addTagOnTrackClick,
  };
};
