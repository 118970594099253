import React from 'react';
import cx from 'classnames';
import { Icons } from '@ems/client-design-system';
import { ITableIcon } from './interfaces';

export const TableIcon = ({ name, prefix, size, className }: ITableIcon) => (
  <Icons
    iconName={`ic-${prefix}-${name}`}
    title={name}
    className={cx(`${prefix}-${name}`, className)}
    size={size}
  />
);
