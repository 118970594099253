import {
  IFieldValidation,
  IFormField,
  TTypedForms,
  TValidationFunction,
  TValidationParams,
} from './interfaces';

// Checks to see if all required fields are filled
export const areFieldsValid = (formData: IFormField[]): boolean =>
  // every field that is required is not undefined/null or empty
  // And if there is no input or validation error
  formData.every(
    (field: IFormField) =>
      ((field.required === true && String(field.value) !== '') || field.required !== true) &&
      !field.error
  );

// returns key value pair form form object
// should pass in Object.values(formObject) and Object.keys(formObject) for this function
export const getFormFieldValues = (formData: IFormField[], formKeys: string[]) => {
  const formKeyValues: any = {};
  formData.forEach((field: IFormField, index: number) => {
    if (formKeys[index]) {
      formKeyValues[formKeys[index]] = field.value;
    }
  });

  return formKeyValues;
};

export const validateForm = (
  formData: TTypedForms,
  formLogicValidation?: TValidationFunction,
  validationParams?: TValidationParams
) => {
  // validate the form on form mount
  const updatedForm = formLogicValidation
    ? formLogicValidation(formData, validationParams)
    : formData;

  // Validate the inputs
  const formKeys = Object.keys(updatedForm);
  formKeys.forEach((key: string) => {
    const inputErrors = validateInput(updatedForm[key].value, updatedForm[key].validations);
    // display first input error is there are any
    if (inputErrors.length > 0) {
      updatedForm[key].error = inputErrors[0];
    }
  });
  return updatedForm;
};
export const validateInput = (value: string, validations?: IFieldValidation) => {
  if (!validations) {
    return [];
  } else {
    return Object.keys(validations)
      .map((validationType: string) => {
        switch (validationType) {
          case 'isNumber':
            if (isNaN(Number(value))) {
              return 'Input must be a number';
            }
            break;
          case 'measurement':
            // remove the mesurement text - if not a number then return invalid
            if (isNaN(Number(value.replace(`${validations.measurement}`, '').replace(',', '')))) {
              return `Input must be a numeric value in ${validations.measurement}`;
            }
          case 'decimalPlaces':
            const countDecimalPlaces = (val: number) =>
              val % 1 ? value.toString().split('.')[1].length : 0;
            if (countDecimalPlaces(Number(value)) > validations.decimalPlaces) {
              return `Input can not have more than ${validations.decimalPlaces} decimals`;
            }
            break;
          case 'inputMaxLength':
            if (value.length > validations.inputMaxLength) {
              return `Input can't be longer than ${validations.inputMaxLength} characters`;
            }
            break;
          case 'inputMinLength':
            if (value.length > validations.inputMinLength) {
              return `Input be less than ${validations.inputMinLength}`;
            }
            break;
          default:
            break;
        }
      })
      .filter(errorMessage => !!errorMessage);
  }
};
