import React, { FC } from 'react';
import ApolloClient from 'apollo-client';
import classNames from 'classnames';
import { Button, Icons } from '@ems/client-design-system';
import { overflowTextModifier } from 'src/utils';
import { ISortActionCallback, ITitleIcon } from './interfaces';
import { ISortObject, ITableFilterObject, TableIcon } from 'src/components';

export interface ITableColumnHeader {
  resultSize?: number;
  isLoading: boolean;
  dispatcher;
  sortAction: (data: ISortActionCallback, dispatcher?) => void;
  languageData: {
    title: string;
    sortBy: string;
    abbreviation: null | string;
  };
  sortName?: string;
  sortObjectSelector;
  content?: JSX.Element;
  sortable?: boolean;
  showSortIcon?: boolean;
  titleIcon?: ITitleIcon;
  // eslint-disable-next-line @typescript-eslint/ban-types
  client?: ApolloClient<object>;
  filterObject?: ITableFilterObject;
  beforeSort?: () => void;
}

export const TableColumnHeader: FC<ITableColumnHeader> = ({
  resultSize,
  isLoading,
  dispatcher,
  sortAction,
  languageData,
  content,
  sortObjectSelector,
  sortName,
  sortable = true,
  showSortIcon = true,
  titleIcon,
  client,
  filterObject,
  beforeSort,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const sortObject: ISortObject = sortObjectSelector.getSortObject();
  let iconClass = '';
  let iconName = '';

  const { title: titleText, sortBy, abbreviation } = languageData;
  const title = titleIcon ? TableIcon({ ...titleIcon }) : titleText;

  const isSortable = !sortable ? 'sort-header--static' : '';
  const hasAbbreviation = abbreviation ? 'has-abbreviation' : '';
  const loading = isLoading ? 'sort-header--loading' : '';
  const className = classNames('sort-header', loading, isSortable, hasAbbreviation);

  const columnContent =
    abbreviation && typeof title === 'string' ? (
      overflowTextModifier(title, abbreviation)
    ) : (
      <span className="grid-cell--title">{title}</span>
    );

  if (content && !sortable) {
    return content;
  }

  if (showSortIcon && sortObject.field === sortName) {
    iconClass = 'sort-icon';
    iconName = sortObject.direction === 'DESC' ? 'ic-ui-caret-up' : 'ic-ui-caret-down';
  } else if (showSortIcon) {
    iconClass = 'sort-icon hover-only';
    iconName = 'ic-ui-caret-down';
  }
  if (!sortable) {
    return <span className={className}>{content ? content : columnContent}</span>;
  }

  return (
    <>
      <Button
        style="subtle"
        rightIcon={<Icons iconName={iconName} size="20" className={iconClass} />}
        className={className}
        disabled={isLoading}
        aria-label={`${sortBy} ${titleText}`}
        onClick={
          !isLoading &&
          sortable &&
          (() => {
            if (beforeSort) {
              beforeSort();
            }
            sortAction({ sortName, resultSize, sortObject, client, filterObject }, dispatcher);
          })
        }>
        {columnContent}
      </Button>
    </>
  );
};
