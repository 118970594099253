import React, { useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import { useAddOnMapClickFunction } from '../../../../../../components/Map/hooks/useAddOnMapClickFunction';
import { EditableConnector } from './EditableConnector';
import {
  InteractiveLayerId,
  useInteractiveLayerIds,
} from '../../../../../../components/Map/hooks/useInteractiveLayerIds';
import { useBeforeId } from '../../../../../../components/Map/hooks/useBeforeId';
import { DEFAULT_COLOR } from '../taxiPathLayer.consts';
import { TaxiPathEditMode, useTaxiPathContext } from '../../../context/TaxiPathContext';
import { getQueriedFeaturesById } from 'src/components/Map/helpers/getQueriedFeaturesById';
import { AddedConnector } from './AddedConnector';
import { useTaxiRefresher } from '../../../utils';

interface ConnectorProps {
  //geojson: ConnectorsGeojson;
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>;
}

const INTERACTIVE_LAYER_IDS = [InteractiveLayerId.taxiPathConnector];

export const Connectors = ({ geojson }: ConnectorProps) => {
  const { selectedConnector, selectedConnectorId, currentEditMode } = useConnectorData(geojson);
  useInteractiveLayerIds(INTERACTIVE_LAYER_IDS);
  const beforeId = useBeforeId([InteractiveLayerId.taxiPathNode]);

  const { refresher } = useTaxiRefresher(geojson);

  return (
    <>
      {refresher && (
        <Source type="geojson" data={geojson} id="connector-source" generateId>
          <Layer
            type="line"
            paint={{
              'line-color': DEFAULT_COLOR,
              'line-width': ['case', ['==', ['get', 'PathwayId'], selectedConnectorId], 0, 4],
            }}
            id={InteractiveLayerId.taxiPathConnector}
            beforeId={beforeId as string}
          />
        </Source>
      )}

      {selectedConnectorId && <EditableConnector connectorFeature={selectedConnector} />}
      {currentEditMode === TaxiPathEditMode.LINES && <AddedConnector />}
    </>
  );
};

const useConnectorData = (geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>) => {
  const {
    state: { currentEditMode },
  } = useTaxiPathContext();
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(null);
  const onClick = useMemo(
    () => ({
      id: 'connector-click',
      function: ({ target, point }: MapLayerMouseEvent) => {
        if (currentEditMode === TaxiPathEditMode.NULL) {
          const connectorFeatures = getQueriedFeaturesById(
            target,
            point,
            InteractiveLayerId.taxiPathConnector
          );
          const lineFeaturesPoint = getQueriedFeaturesById(
            target,
            point,
            InteractiveLayerId.drawableLinePoint
          );
          const lineFeaturesSegment = getQueriedFeaturesById(
            target,
            point,
            InteractiveLayerId.drawableLineSegment
          );
          const nodeFeatures = getQueriedFeaturesById(
            target,
            point,
            InteractiveLayerId.taxiPathNode
          );
          const connectorsClicked =
            connectorFeatures.length || lineFeaturesPoint.length || lineFeaturesSegment.length;

          // Prioritise node over path selection, and prevent case where both get selected
          if (!connectorsClicked || nodeFeatures.length) {
            setSelectedConnectorId(null);
          } else if (connectorFeatures.length) {
            const connectorId = connectorFeatures[0].properties.PathwayId;
            setSelectedConnectorId(
              connectorId && connectorId !== selectedConnectorId ? connectorId : null
            );
          }
        }
      },
    }),
    [selectedConnectorId, setSelectedConnectorId, currentEditMode]
  );
  useAddOnMapClickFunction(onClick);

  const selectedConnector = useMemo(
    () => geojson.features.find(feature => feature.properties.PathwayId === selectedConnectorId),
    [selectedConnectorId, geojson]
  );
  return { selectedConnectorId, selectedConnector, currentEditMode };
};
