import { getConvertedVerticalDistance } from '@ems/client-design-system';
import {
  IMPERIAL_BASE_UNIT,
  IMPERIAL_UNITS,
  METRIC_BASE_UNIT,
  METRIC_UNITS,
  UNIT_KILOMETER,
  UNIT_METER,
} from 'src/constants';
import { TImperialBaseUnit, TMetricBaseUnit, TVerticalDistanceUnit } from './interfaces';

export const setUnitAsMetersOrFeet = (unit: 'm' | 'ft' | 'km'): TVerticalDistanceUnit => {
  if (unit === UNIT_METER || unit === UNIT_KILOMETER) {
    return 'm';
  } else {
    return 'ft';
  }
};

export const formatMeasurementAsNumericString = (value: string) =>
  String(setMeasurementAsNumber(value));

// returns a measurement as a numeric value - if invalid number will return 0
export const setMeasurementAsNumber = (value: string): number => {
  const numericValue = Number(value.replace(/[^\d.+-]|\.(?=.*\.)/g, ''));
  return isNaN(numericValue) ? 0 : numericValue;
};

// returns a converted value in the output units as a number or string.
export const getVerticalDistance = (
  value: string | number,
  params: {
    // Units that the value will be returned in
    convertTo: TVerticalDistanceUnit | 'metricBaseUnit' | 'imperialBaseUnit';
    // Units that the value is provided in
    convertFrom: TVerticalDistanceUnit;
    returnValueType: 'number' | 'string';
  }
): string | number => {
  // gets the converted vertical distance as a string
  const convertedValue: string = getConvertedVerticalDistance(
    setMeasurementAsNumber(String(value)),
    params.convertTo,
    params.returnValueType === 'number' ? 0 : 2,
    params.convertFrom
  );

  if (params.returnValueType === 'number') {
    return setMeasurementAsNumber(convertedValue);
  } else {
    return convertedValue;
  }
};

export const getImperialOrMetricBaseUnit = (
  measurementUnit: string
): TImperialBaseUnit | TMetricBaseUnit => {
  if (METRIC_UNITS.includes(measurementUnit)) {
    return METRIC_BASE_UNIT;
  } else if (IMPERIAL_UNITS.includes(measurementUnit)) {
    return IMPERIAL_BASE_UNIT;
  } else {
    console.error(
      `Supplied measurement unit: ${measurementUnit} is not recognized as metric or imperial`
    );
  }
};
