import { STATE, ROUTE, CODE_VERIFIER } from 'src/constants';
import { USER_SESSION } from 'src/constants';

// we used to use appTimestamp as store key to invalidate auth data with every new version 
const authKey = window.btoa(`${USER_SESSION}`);

export const cleanupOutDatedAuthData = () => {
  if (localStorage.getItem(authKey) === null) {
    localStorage.clear();
  }
};

export const storeInfoBeforeRedirect = ({ state, route = '', codeVerifier = '' }) => {
  localStorage.setItem(STATE, state);
  localStorage.setItem(ROUTE, route);
  if (codeVerifier) {
    localStorage.setItem(CODE_VERIFIER, codeVerifier);
  }
};

export const cleanupAfterComingBack = () => {
  localStorage.removeItem(STATE);
  localStorage.removeItem(CODE_VERIFIER);
};

export const getCodeVerifier = () => {
  return localStorage.getItem(CODE_VERIFIER);
};

export const getRoute = () => {
  return localStorage.getItem(ROUTE);
};

export const setRoute = (route: string) => {
  return localStorage.setItem(ROUTE, route);
};

export const removeRoute = () => {
  return localStorage.removeItem(ROUTE);
};

export const setAuth = data => {
  try {
    const str = JSON.stringify(data);
    localStorage.setItem(authKey, window.btoa(str));
    return true;
  } catch {
    return false;
  }
};

export const getAuth = () => {
  try {
    const str = localStorage.getItem(authKey) || '';
    return JSON.parse(window.atob(str));
  } catch {
    return null;
  }
};

export const removeAuth = () => {
  localStorage.setItem(authKey, window.atob(''));
};
