import { Collapse, CollapseRotatedIcon } from '@ems/client-design-system';
import React, { useState } from 'react';
import {
  CollapseContainer,
  CollapseControl,
  CollapseHeader,
  HeaderText,
} from './CollapseComponent.styles';

interface CollapseComponentProps {
  headerTitle: string;
  children: React.ReactNode;
  isOpen?: boolean;
  fill?: string;
  contentPosition?: 'above' | 'below';
}

export const CollapseComponent = ({
  headerTitle,
  children,
  isOpen = false,
  fill = 'black',
  contentPosition = 'below',
}: CollapseComponentProps) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(isOpen);
  return (
    <CollapseContainer className="collapse-component__container">
      {contentPosition === 'above' && <Collapse isOpen={isCollapseOpen}>{children}</Collapse>}
      <CollapseHeader className="collapse-component__header">
        <CollapseControl>
          <HeaderText
            color={fill}
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            className="collapse-component__header-text">
            {headerTitle}
          </HeaderText>
          <span
            data-testid="collapse-icon-wrapper"
            onClick={() => {
              setIsCollapseOpen(!isCollapseOpen);
            }}>
            <CollapseRotatedIcon
              isOpen={isCollapseOpen}
              iconProps={{ iconName: 'ic-triangle', fill }}
            />
          </span>
        </CollapseControl>
      </CollapseHeader>
      {contentPosition === 'below' && <Collapse isOpen={isCollapseOpen}>{children}</Collapse>}
    </CollapseContainer>
  );
};
