import { ApolloClient } from 'apollo-client';
import { dataActionTypes } from 'src/@infringementsCandidates/newActionTypes';
import {
  fetchInfringementData,
  fetchInfringementCandidatesData,
} from 'src/@infringementsCandidates/resolvers/infringementsResolver';
// const
import { GET_COUNT_DELAY } from 'src/constants';
// utils
import { ResponseValidator } from 'src/utils/responseValidator';
const request = new ResponseValidator();

// fetch 1 record and check for count
export const getDataCount = (
  client: ApolloClient<object>,
  sortString: any,
  cursor: string,
  newDispatcher: any
) => {
  const [instance, t] = request.get('inf-candidates-count');
  request.set(instance, t);
  fetchInfringementData(client, 1, sortString, cursor)
    .then(data => {
      if (request.isValid(instance, t)) {
        newDispatcher({ type: dataActionTypes.GET_TOTAL_COUNT, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

// checks value of the data recieved for count
export const checkDataCount = (
  client: ApolloClient<object>,
  data: any,
  sortString: any,
  cursor: string,
  newDispatcher: any
) => {
  if (
    (data &&
      data.pageInfo &&
      data.pageInfo.hasNextPage &&
      typeof data.totalCount === 'undefined') ||
    data.totalCount === -1
  ) {
    // Fetch more to get count when not available after delay
    setTimeout(() => {
      getDataCount(client, sortString, cursor, newDispatcher);
    }, GET_COUNT_DELAY);
  } else {
    newDispatcher({ type: dataActionTypes.GET_TOTAL_COUNT, data });
  }
};

// Fetch data
export const fetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher?: any,
  sortString?: any,
  cursor?: string,
  candidates?: boolean
) => {
  const reset = !cursor ? true : false;
  const [instance, t] = request.get('fetch-inf-candidates');
  request.set(instance, t);
  if (candidates) {
    fetchInfringementCandidatesData(client, resultSize, sortString, cursor)
      .then((data: any) => {
        if (request.isValid(instance, t)) {
          checkDataCount(client, data, sortString, data.pageInfo.startCursor, newDispatcher);
          newDispatcher({
            type: dataActionTypes.INFRINGEMENTS_FETCHED,
            data,
            candidates: true,
            reset,
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    fetchInfringementData(client, resultSize, sortString, cursor)
      .then((data: any) => {
        if (request.isValid(instance, t)) {
          checkDataCount(client, data, sortString, data.pageInfo.startCursor, newDispatcher);
          newDispatcher({
            type: dataActionTypes.INFRINGEMENTS_FETCHED,
            data,
            candidates: false,
            reset,
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
};

export const resetAndFetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher: any,
  sortString: any,
  candidates?: boolean
) => {
  newDispatcher({ type: dataActionTypes.RESET_DATA });
  fetchData(client, resultSize, newDispatcher, sortString, '', candidates);
};

export const selectRow = (ids: number[], newDispatcher: any) => {
  newDispatcher({ type: dataActionTypes.SELECT_ROW, data: ids });
};

export const loadMore = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    showCandidates?: boolean;
  }
) => {
  const { showCandidates, resultSize, sortString, endCursor } = options;
  newDispatcher({ type: dataActionTypes.LOAD_MORE });
  fetchData(client, resultSize, newDispatcher, sortString, endCursor, showCandidates);
};

export const sortTable = async (data: any, newDispatcher: any) => {
  const { sortName } = data;
  await newDispatcher({ type: dataActionTypes.SORT_TABLE, data: sortName });
};
