import React, { useReducer, useContext, createContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

// Queries
import { GET_SCENARIOS } from 'src/@settings/queries';

// Types
import {
  IScenariosState,
  TScenariosActions,
  ScenariosContextProps,
  IScenariosResponse,
} from './types';

const scenariosProviderInitialState: IScenariosState = {
  isLoading: false,
  error: null,
  scenarios: [],
  activeScenario: null,
};

const scenariosReducer = (state: IScenariosState, action: TScenariosActions): IScenariosState => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true };
    case 'ERROR':
      return { ...state, isLoading: false, error: action.error };
    case 'SUCCESS':
      const { scenarios } = action.data;

      return {
        ...state,
        isLoading: false,
        scenarios,
        error: null,
      };
    case 'SET_DEFAULT_SCENARIO': {
      const { scenarios } = action.data;
      const activeScenario = scenarios.find(s => s.isActive);
      if (activeScenario) {
        return { ...state, activeScenario };
      }

      const defaultScenario = scenarios.find(s => s.isDefault);
      if (defaultScenario) {
        return { ...state, activeScenario: defaultScenario };
      }

      return state; // If no active or default scenario is found, state remains unchanged
    }
    default:
      return state;
  }
};

const ScenariosContext = createContext<ScenariosContextProps | undefined>(undefined);

export const ScenariosProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(scenariosReducer, scenariosProviderInitialState);

  return (
    <ScenariosContext.Provider value={{ state, dispatch }}>{children}</ScenariosContext.Provider>
  );
};

export const useScenarios = (): ScenariosContextProps => {
  const { dispatch, state } = useContext(ScenariosContext);

  if (!state) {
    throw new Error('useScenarios must be used within a ScenariosProvider');
  }

  // If no scenarios are present in the context, fetch them.
  const { data, loading: isLoading, error } = useQuery<IScenariosResponse>(GET_SCENARIOS, {
    skip: state.scenarios.length > 0, // Skip the query if scenarios are already present
  });

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: 'LOADING' });
    } else if (error) {
      dispatch({ type: 'ERROR', error });
    } else if (data && data.scenarios && data.scenarios.length > 0) {
      dispatch({ type: 'SUCCESS', data });
      dispatch({ type: 'SET_DEFAULT_SCENARIO', data });
    }
  }, [data, error, isLoading, dispatch]);

  return { dispatch, state };
};
