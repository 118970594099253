import React, { useMemo } from 'react';
import uuid from 'uuid';
import {
  CHARACTER_UNICODES,
  ONE_DAY_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_MINUTE_IN_SECONDS,
  SETTINGS,
} from 'src/constants';
import { getDeployedProductId } from 'src/utils/generics';
import { LocationItem, NMTTableDataItem } from '../interfaces';
import { DateTime } from 'luxon';
import { StyledTableLink } from 'src/@settings/components/Settings.styles';
/*
 * Feature Name link
 */
const linkToDetails = (name: string | null, id: number, lastData: string) => (
  <StyledTableLink
    to={{
      pathname: `/${getDeployedProductId()}/${SETTINGS}/nmt-management/${id}`,
      state: { lastData },
    }}>
    {name ?? CHARACTER_UNICODES.EMDASH}
  </StyledTableLink>
);

// LAST DATA FIELD

const secondsToMinutes = (seconds: number) => (seconds / ONE_MINUTE_IN_SECONDS).toFixed(0);
const secondsToHours = (seconds: number) => (seconds / ONE_HOUR_IN_SECONDS).toFixed(0);
const secondsToDays = (seconds: number) => (seconds / ONE_DAY_IN_SECONDS).toFixed(0);

export const calculateLastDataDisplayValue = (lastData: string) => {
  const lastDataTime = DateTime.fromISO(lastData, { setZone: true }).toSeconds();
  const currentTime = DateTime.now({ setZone: true }).toSeconds();
  const diff = currentTime - lastDataTime;
  if (diff < ONE_MINUTE_IN_SECONDS) {
    return 'Now';
  } else if (diff < ONE_HOUR_IN_SECONDS) {
    return `${secondsToMinutes(diff)} min`;
  } else if (diff < ONE_DAY_IN_SECONDS) {
    return `${secondsToHours(diff)} hr`;
  } else if (diff < ONE_DAY_IN_SECONDS * 7) {
    const days = secondsToDays(diff);
    return `${days} ${Number(days) < 2 ? 'day' : 'days'}`;
  } else {
    return `7+ days`;
  }
};

export const formatLocation = (location: string, id: number) => {
  if (!location || location === '') {
    return CHARACTER_UNICODES.EMDASH;
  } else {
    return `${id} - ${location}`;
  }
};
export const useNmtTableRowData = (
  nmtData: LocationItem[]
): { tableRowData: NMTTableDataItem[]; tableRowKeys: string[] } =>
  useMemo(() => {
    const tableRowData = nmtData.map((nmtItem: LocationItem) => {
      const { locationId, lastData, isActive, locationDescription, deviceName, deviceId } = nmtItem;
      const rowItem = {
        ...nmtItem,
        displayedLocationDescription: formatLocation(locationDescription, locationId),
        displayedName: linkToDetails(deviceName, deviceId, lastData),
        isActiveCheckMark: isActive ? CHARACTER_UNICODES.CHECK_MARK : '',
        tableId: uuid.v4(),
        lastDataValue: calculateLastDataDisplayValue(lastData),
      };
      return rowItem;
    });
    const tableRowKeys = tableRowData.map(row => row.tableId);

    return { tableRowData, tableRowKeys };
  }, [nmtData]);
