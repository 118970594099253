import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { corridorQuery, corridorSchema } from './schema';
import { INoiseAbatementCorridor } from 'src/utils/spatialFeatureHelpers/interfaces';

export const fetchAllCorridors = (client: ApolloClient<object>) =>
  new Promise<INoiseAbatementCorridor[]>((resolve, reject) => {
    const GET_CORRIDOR = gql`
      query {
        ${corridorQuery} ${corridorSchema}
      }
    `;
    client
      .query({
        query: GET_CORRIDOR,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchCorridorById = (client: ApolloClient<object>, corridorId: number) =>
  new Promise<INoiseAbatementCorridor>((resolve, reject) => {
    const queryParams = ` id : ${corridorId}`;
    const GET_CORRIDOR = gql`
    query {
      corridor(${queryParams}) ${corridorSchema}
      
    }
  `;
    client
      .query({
        query: GET_CORRIDOR,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data.corridor);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchCorridorsByIds = (client: ApolloClient<object>, corridorIds: number[]) =>
  new Promise<INoiseAbatementCorridor[]>((resolve, reject) => {
    const queryParams = `ids :[${corridorIds}]`;
    const GET_CORRIDORS = gql`
  query {
    corridorsByIds(${queryParams}) ${corridorSchema}
    
  }
`;
    client
      .query({
        query: GET_CORRIDORS,
      })
      .then(response => {
        if (response && response.data && response.data.corridorsByIds) {
          resolve(response.data.corridorsByIds);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
