import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';

interface InputDropdownProps {
  value: number;
  appearActive?: boolean;
  onClickOutside?: (value?: number) => void;
  onChangeAction?: (value?: number) => void;
}

const InlineEditNumberSpan = styled.span`
  display: inline-block;
  padding: 2px;
  border: 1px solid transparent;
  border-radius: 3px;

  &.isActive,
  :hover {
    border-color: #8897a2;
  }
`;

const InlineEditNumberInput = styled.input`
  width: 100%;
  text-align: right;
  padding: 3px;
  border: 1px solid #8897a2;
  border-radius: 3px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InlineEditNumber = ({
  value,
  onClickOutside,
  onChangeAction,
  appearActive,
}: InputDropdownProps) => {
  const [isFieldActive, setIsFieldActive] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleSubmission = (value: number) => {
    const fieldValue = value || 0;
    if (onClickOutside) {
      onClickOutside(fieldValue);
    }
  };

  useEffect(() => {
    if (isFieldActive) {
      const inputField: HTMLInputElement = wrapperRef.current.querySelector(
        `.inlineEdit__number-input`
      );
      if (inputField) {
        inputField.focus();
      }
    }
  }, [isFieldActive]);

  return (
    <div className="inlineEdit__number" ref={wrapperRef}>
      {!isFieldActive ? (
        <InlineEditNumberSpan
          className={cx({ isActive: appearActive })}
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsFieldActive(true);
            }
          }}
          onClick={() => {
            setIsFieldActive(true);
          }}>
          {value.toLocaleString()}
        </InlineEditNumberSpan>
      ) : (
        <InlineEditNumberInput
          className={`inlineEdit__number-input`}
          type="number"
          value={inputValue.toString()}
          onChange={e => {
            // maxLength enforce due to number input
            if (e.target.value.length > 8) {
              return false;
            }

            setInputValue(parseInt(e.target.value, 10));
            if (onChangeAction) {
              onChangeAction(parseInt(e.target.value, 10));
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && isFieldActive) {
              setIsFieldActive(false);
              handleSubmission(inputValue);
            }
          }}
          onBlur={e => {
            setInputValue(parseInt(e.target.value, 10));
            handleSubmission(inputValue);
            setIsFieldActive(false);
          }}
        />
      )}
    </div>
  );
};
