import { MapProps } from 'react-map-gl';
import { useMapContext } from '../context/useMapContext';
import { useConfigSelectors } from 'src/app/reducers';
import { MAP_ACTIONS } from '../context/Reducer';
import { useCallback } from 'react';
import { MapActionType } from '../map.types';

export const useResetView = (customMapProps?: Partial<MapProps>) => {
  const { dispatch } = useMapContext();
  const configSelectors = useConfigSelectors();
  const {
    map: {
      centre: { latitude, longitude },
      zoom,
      pitch,
      bearing,
    },
  } = configSelectors.getConfig();

  return useCallback(() => {
    dispatch({
      type: MAP_ACTIONS.SET_VIEW_STATE as MapActionType,
      payload: {
        latitude,
        longitude,
        zoom,
        pitch,
        bearing,
        ...customMapProps,
      },
    });
  }, [latitude, longitude, zoom, pitch, bearing, customMapProps]);
};
