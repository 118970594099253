import React from 'react';
import { useHistory } from 'react-router-dom';
// Components
import { PageHeader } from 'src/components';
import { SkeletonText } from '@ems/client-design-system';
import {
  DevicePositionSettings,
  DeviceSettingsButtons,
  DeviceTemplateSettings,
  DeviceManagementDetailsForm,
  MapWrapper,
  DeviceActiveToggle,
} from './Components';

// Actions
import { redirectListViewAction } from './helpers/actions';

// Variables
import {
  DeviceManagementPageHeader,
  DeviceManagementPageWrapper,
} from './Components/DeviceManagementDetails.styles';
import { useBuildFormData } from '../Hooks';
import { LocationManagementProvider } from '../Context/LocationManagementContext';
import { useFetchDeviceData } from './hooks/useFetchDeviceData';
import { useDeviceMonitorLocation } from './hooks/useDeviceMonitorLocation';
import { useFetchLocationsList } from './hooks/useFetchLocationsList';
import { useIsLoading } from './hooks/useIsLoading';
import { useUpdateDevice } from './hooks/useUpdateDevice';
import { useIsEditing } from './hooks/useIsEditing';
import { setTabTitle } from 'src/utils';
import { useLanguageSelectors } from 'src/app/reducers';

export const DeviceManagementDetailsContainer = () => (
  <LocationManagementProvider>
    <LocationManagementDetails />
  </LocationManagementProvider>
);

const LocationManagementDetails = () => {
  const routerHistory = useHistory();
  const { isEditing, toggleIsEditing } = useIsEditing();

  const { isLoadingDeviceData, deviceData, deviceId } = useFetchDeviceData();
  const { isLoadingDeviceMonitorLocation, deviceMonitorLocation } = useDeviceMonitorLocation(
    deviceData?.noiseDevice?.locationId ?? null
  );
  const { isLoadingAllMonitorLocations, locationsList } = useFetchLocationsList(
    deviceMonitorLocation
  );

  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        title: settingsTitle,
        tabs: { nmtManagement: nmtManagementString },
      },
    },
  } = languageSelectors.getLanguage();

  const formData = useBuildFormData(deviceMonitorLocation, deviceData);
  const { isLoadingMutation, updateDeviceData } = useUpdateDevice({
    deviceId,
    initialFormData: formData,
  });

  const { areQueriesLoading, areMutationsLoading } = useIsLoading({
    isLoadingAllMonitorLocations,
    isLoadingDeviceData,
    isLoadingDeviceMonitorLocation,
    isLoadingMutation,
  });

  setTabTitle(
    `${settingsTitle} - ${nmtManagementString} - ${formData ? formData.name : `${deviceId}`}`
  );

  return (
    <DeviceManagementDetailsForm initialFormData={formData} onSubmit={updateDeviceData}>
      <DeviceManagementPageWrapper className="settings__split  device-management__page-wrapper">
        <div className="settings__split-content">
          <DeviceManagementPageHeader className="settings__heading device-management__page-header">
            <SkeletonText loading={areQueriesLoading}>
              <PageHeader title={formData ? formData.name : `${deviceId}`}>
                <span className="settings__heading-count page-count">{`#${deviceId}`}</span>
              </PageHeader>
            </SkeletonText>
            <DeviceActiveToggle isEditing={isEditing} />
          </DeviceManagementPageHeader>

          <div style={{ margin: '0rem 2.5rem' }} className="locationsettings__content">
            <DevicePositionSettings
              isEditing={isEditing}
              isLoading={areQueriesLoading}
              monitorLocations={locationsList}
              deviceId={deviceId}
            />

            <div style={{ padding: '1rem 0' }} />

            <DeviceTemplateSettings isEditing={isEditing} isLoading={areQueriesLoading} />

            <DeviceSettingsButtons
              isEditing={isEditing}
              isSaveLoading={areMutationsLoading}
              onCancelAction={() => {
                if (isEditing) {
                  toggleIsEditing();
                } else {
                  redirectListViewAction(routerHistory);
                }
              }}
              toggleEditModeAction={toggleIsEditing}
            />
          </div>
        </div>
        <MapWrapper />
      </DeviceManagementPageWrapper>
    </DeviceManagementDetailsForm>
  );
};
