import { IConfigurationUnits } from 'src/app/props';
import {
  TImperialBaseUnit,
  TMetricBaseUnit,
  TMutationActions,
  TOperationDetailsSchema,
} from './utils/interfaces';
// shared/global app constants

export const CHANGE_EVENT = 'change';
export const DEPLOYED_PRODUCT_ID = 'deployedProductId';
export const GET_COUNT_DELAY = 1500;
export const INITIALISE_EVENT = 'initialise';
export const SITENAME_KEY = 'sitename';

export const PERMISSIONS = {
  READ: 'Read',
  UPDATE: 'Update',
  DELETE: 'Delete',
  INSERT: 'Insert',
};

const ADD: TMutationActions = 'add';
const UPDATE: TMutationActions = 'update';
const DELETE: TMutationActions = 'delete';
export const GQL_MUTATION_TYPES = {
  ADD,
  UPDATE,
  DELETE,
};
export const STATUS = {
  IN_PROGRESS: 'InProgress',
  COMPLETE: 'Complete',
  ERROR: 'Error',
};

export const TOTAL_METRIC = 'GreenhouseGasTotal';
export const PER_MOVEMENT_METRIC = 'GreenhouseGasPerMovement';
export const PER_PASSENGER_METRIC = 'GreenhouseGasPerPassenger';
export const PER_PASSENGER_KILOMETER_METRIC = 'GreenhouseGasPerPassengerKilometer';
export const TMA_EFFICIENCY = 'TerminalManagementAreaEfficiencyTotal';
export const TOTAL_MOVEMENTS = 'MovementTotal';
export const TOTAL_PASSENGERS = 'PassengerTotal';
export const OPERATION = 'operation';
export const OPERATIONS = 'operations';
export const INFRINGEMENT = 'infringement';
export const INFRINGEMENTS = 'infringements';
export const NOISEEVENT = 'noiseevent';
export const NOISEEVENTS = 'noiseevents';
export const COMPLAINT = 'complaint';
export const COMPLAINTS = 'complaints';
export const NOISE_MODELING = 'noisemodeling';
export const SETTING = 'setting';
export const SETTINGS = 'settings';
export const IMPLAUSIBLEEVENTS = 'implausibleEvents';
export const FILTER = 'filter';
export const FILTER_ROW_SELECTION = 'filter.row';
export const FILTER_RANGE_SELECTION = 'filter.range';
export const FILTER_TIME_SELECTION = 'filter.time';
export const FILTER_CORRELATED_IDS = 'filter.correlatedids';
export const FILTER_COMPLAINER = 'filter.complainer';
export const FILTER_COMPLAINT_ID = 'filter.complaintID';
export const FILTER_ACID = 'filter.acid';

// Operation schema types
const OPERATION_DETAILS_SCHEMA_COMPLETE: TOperationDetailsSchema = 'schemaComplete';
const OPERATION_DETAILS_SCHEMA_TAG: TOperationDetailsSchema = 'schemaTag';
const OPERATION_DETAILS_SCHEMA_NO_COMPLAINTS: TOperationDetailsSchema =
  'schemaCompleteNoComplaints';

export const OPERATION_DETAILS_SCHEMA_TYPES = {
  COMPLETE: OPERATION_DETAILS_SCHEMA_COMPLETE,
  TAG: OPERATION_DETAILS_SCHEMA_TAG,
  COMPLETE_NO_COMPLAINTS: OPERATION_DETAILS_SCHEMA_NO_COMPLAINTS,
};
export const POWERBI = {
  LAYOUT_DESKTOP: 0,
  LAYOUT_CUSTOM: 1,
  LAYOUT_MOBILE: 2,
};

// Complaints constants
export const HAS_OPERATION = 'hasOperation';
export const FLIGHT = 'Flight';
export const NO_FLIGHT = 'No Flight';
export const COMPLAINER = 'Complainer';
export const ANONYMOUS = 'Anonymous';
export const DATE_TIME_FORMAT = {
  INPUT_12H: 'hh:mm a LLL d, yyyy',
  INPUT_24H: 'HH:mm  LLL d, yyyy',
  FIELD_12H: 'hh:mm a, DDD',
  FIELD_24H: 'HH:mm, DDD',
  TABLE_12H: 'hh:mm a MMM dd',
  TABLE_24H: 'HH:mm MMM dd',
  TABLE_12H_SHORT: 'hh:mm a',
  TABLE_24H_SHORT: 'HH:mm',
  TABLE_12H_SECONDS: 'hh:mm:ss a MMM dd',
  TABLE_24H_SECONDS: 'HH:mm:ss MMM dd',
  TABLE_12H_SECONDS_SHORT: 'hh:mm:ss a',
  TABLE_24H_SECONDS_SHORT: 'HH:mm:ss',
  TABLE_12H_YEARS: 'hh:mm a MMM dd yyyy',
  TABLE_24H_YEARS: 'HH:mm MMM dd yyyy',
  TABLE_DATE_ONLY: 'MMM dd yyyy',
  SHORT_DATE_DISPLAY: "LLL d',' yy",
  LARGE_DATE_DISPLAY: 'cccc dd LLLL yyyy',
  DEVICE_MGT_24H: 'HH:mm, d MMM yyyy',
};

export const SAVE = 'SAVE';
export const SAVE_AND_DUPLICATE = 'SAVE_AND_DUPLICATE';

// Airtrak constants
export const AIRTRAK = 'airtrak';
export const IN_BOUND = 'Inbound';
export const OUT_BOUND = 'Outbound';
export const LTO = 'LTO';
export const SHORTCUTS = 'Shortcuts';
export const ARRIVAL_ENROUTE = 'ArrivalEnroute';
export const DEPARTURE_ENROUTE = 'DepartureEnroute';
export const REGION_BREAKDOWN = 'DomesticOrInternational';
export const GHG_BREAKDOWN = 'Pollutant';
export const AIRLINE_BREAKDOWN = 'Airline';
export const AIRCRAFT_BREAKDOWN = 'Aircraft';
export const NONE_BREAKDOWN = 'None';
export const PHASE_BREAKDOWN = 'Phase';
export const PHASES = 'phases';
export const REGIONS = 'regions';
export const LINE = 'line';
export const BAR = 'bar';
export const PIE = 'pie';
export const TABLE = 'table';
export const ASC = 'ASC';
export const DESC = 'DESC';
export const EMMISIONS = 'emissions';
export const COMBINED_TMA = 'TMACombined';
export const COMBINED = 'Combined';
export const MIN_CARBON_EMISSION_TABLE_COLUMNS = 3;
export const AIRCRAFT_MODEL_SEPARATOR = ' / ';
export const MAX_TIME_IN_MODE_SECONDS = 9999;
export const MAX_TMA_DISTANCE = 99.9;
export const MAX_CARBON_EMISSION_FACTOR = 9.9999;
export const MAX_LOAD_FACTOR_PERCENTAGE = 100;
export const LOADFACTORS_FUTURE_YEARS = 1;
export const SCENARIO_FUTURE_YEARS = 10;
export const DEFAULT_SCENARIO_START_YEAR = 1996;
export const CARBON_EMISSION_ROUNDING_PRECISION = 2;
// Below are values in pixels
export const MIN_CARBON_EMISSION_TABLE_WIDTH = 800;
export const CARBON_EMISSION_TABLE_PER_COLUMN_WIDTH = 100;

// layers
export const TRACKS = 'Tracks';
export const TRACK_DENSITY = 'TrackDensity';
export const MONITOR_LOCATIONS = 'MonitorLocations';
export const MONITOR_LOCATIONS_LAYER_POSTFIX = 'monitor_locations';
export const RUNWAYS = 'Runways';
export const LAYER_PREFIX_DYNAMIC_TRACK = 'trackLayer';
export const EVENT_NOISE_DB_LAYER = 'static-monitors';
export const EVENT_NOISE_DB = 'EventNoiseDB';
export const DEFAULT_LAYER_PREFIX = 'tracks_background_';

// exports
export const EXCEL_EXPORT = 'excel';
export const WORD_EXPORT = 'word';
export const KML_EXPORT = 'kml';
export const INM_EXPORT = 'inm';
export const CSV_EXPORT = 'csv';

export const DATA_FILTER_DIR = 'data/filters/';

// pkce localstorage keys
export const STATE = 'pkceState';
export const CODE_VERIFIER = 'pkceCodeVerifier';
export const ROUTE = 'pageRoute';

// local storage keys
export const USER_SESSION = 'user.session';
export const USER_SETTINGS = 'user.settings';
export const USER_LAST_ACTIVE = 'user.lastActive';
export const LOCALSTORAGE_PUSH_NOTICE = 'pushNoticeAlert';

// Possible product IDs
// Only used for local development
export const PRODUCT_PANY = 'x_pany';
export const PRODUCT_SZG = 'szg';
export const PRODUCT_SZG_ANOMSX = 'szg.anomsx';
export const PRODUCT_LHR = 'lhr';
export const PRODUCT_LHR_ANOMSX = 'lhr.anomsx';
export const PRODUCT_BNE = 'bne';
export const PRODUCT_BNE_ANOMSX = 'bne.anomsx';
export const PRODUCT_LHR_PT = 'lhr.pt';
export const PRODUCT_KEF = 'kef';

export const CORRELATED_TYPES = {
  INFRINGEMENT: 'infringement',
  NOISEEVENT: 'noiseEvents',
  COMPLAINT: 'complaint',
};

// Changelogs

export const CHANGELOG_DATA_TYPES = {
  INFRINGEMENT: 'Infringement',
  NOISEEVENT: 'NoiseEvent',
  COMPLAINT: 'Complaint',
  OPERATION: 'Operation',
};

export const CHANGELOG_DISPLAY_STRINGS = {
  [CHANGELOG_DATA_TYPES.INFRINGEMENT]: 'Infringements',
  [CHANGELOG_DATA_TYPES.NOISEEVENT]: 'Noise Events',
  [CHANGELOG_DATA_TYPES.COMPLAINT]: 'Complaints',
  [CHANGELOG_DATA_TYPES.OPERATION]: 'Operations',
};

// Carbon Emission Settings
export const CARBON_EMISSION_SETTINGS_RULE_TYPES = {
  AIRCRAFT_ENGINE_MAPPING: 'AircraftEngineMapping',
  AIRCRAFT_FLIGHTS: 'AircraftFlights',
  AIRCRAFT_FUEL_MAPPING: 'AircraftFuelMapping',
  AIRCRAFT_MAPPING: 'AircraftMapping',
  AIRLINE_LOAD_FACTOR: 'AirlineLoadFactor',
  AIRLINE_MAPPING: 'AirlineMapping',
  AIRPORT: 'Airport',
  EMISSION_COLUMNS: 'EmissionColumns',
  PHASE_DEFAULT: 'PhaseDefault',
  RUNWAY: 'Runway',
  SCENARIO: 'Scenario',
  SETTING: 'Setting',
  USER_HELP_ITEM: 'UserHelpItem',
};

// Infringment Rules
export const INFRINGEMENT_RULES = 'infringementRules';

export const INFRINGEMENT_RULE_TYPES = {
  CORRIDOR_INFRINGEMENT: 'Corridor',
  EXCLUSION_INFRINGEMENT: 'Exclusion',
  GATE_INFRINGEMENT: 'Gate',
  CURFEW_INFRINGEMENT: 'Curfew',
  CCO_INFRINGEMENT: 'Cco',
  CDO_INFRINGEMENT: 'Cdo',
  MINHEIGHT_INFRINGEMENT: 'MinHeight',
  NOISE_INFRINGEMENT: 'Noise',
};

export const INFRINGEMENT_DISPLAY_STRINGS = {
  [INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT]: 'Corridor',
  [INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT]: 'Exclusion',
  [INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT]: 'Gate',
  [INFRINGEMENT_RULE_TYPES.CURFEW_INFRINGEMENT]: 'Curfew',
  [INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT]: 'CCO',
  [INFRINGEMENT_RULE_TYPES.CDO_INFRINGEMENT]: 'CDO',
  [INFRINGEMENT_RULE_TYPES.MINHEIGHT_INFRINGEMENT]: 'Min Height',
  [INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT]: 'Noise',
};
export const INFRINGEMENT_RULES_MODULE = 'infRules';
export const INFRINGEMENT_RULES_TRANSLATION_MODULE = 'infRules';
export const MAX_SEGMENT_LENGTH_METERS = 80000;
export const MIN_SEGMENT_LENGTH_METERS = 0;

// cause status
export const CAUSE_AIRCRAFT = 'Aircraft';
export const CAUSE_COMMUNITY = 'Community';
export const CAUSE_WEATHER = 'Weather';
export const CAUSE_EQUIPMENT = 'Equipment';
export const CAUSE_UNKNOWN = 'Unknown';
export const CAUSE_NOT_SET = 'NotSet';
// TODO: will be removed soon
export const CAUSE_SINGLE_LOCAL_AIRCRAFT = 'SingleLocalAircraft';
export const CAUSE_MULTIPLE_LOCAL_AIRCRAFT = 'MultipleLocalAircraft';
export const CAUSE_NON_LOCAL_AIRCRAFT = 'NonLocalAircraft';
export const CAUSE_MIXED_AIRCRAFT = 'MixedLocalAndNonLocalAircraft';

// comment status
export const COMMENT_STATUS_LOADED = 'loaded';
export const COMMENT_STATUS_LOADING = 'loading';
export const COMMENT_STATUS_SAVED = 'saved';

export const DEVICE_TYPES = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

// status options
export const NULL_VALUE = 'NullValue';
export const VECTORED = 'vectored';

// Unit Profiles
export const PROFILE_US_CUSTOMARY = 'US Customary';
export const PROFILE_ICAO_METRIC = 'ICAO Metric';
export const PROFILE_ICAO_ALTERNATIVE = 'ICAO Alternative';

// UNITS OF MEASUREMENT
// Sound
export const UNIT_DECIBEL = 'dB';
// Time
export const UNIT_SECOND = 'sec';
// Distances and Lengths
export const UNIT_METER = 'm';
export const UNIT_KILOMETER = 'km';
export const UNIT_FOOT = 'ft';
export const UNIT_MILE = 'mi';
export const UNIT_NAUTICAL_MILE = 'NM';
export const UNIT_INCH = 'in';
export const UNIT_MILLIMETER = 'mm';
export const UNIT_CENTIMETER = 'cm';
// Speed
export const UNIT_METERS_PER_SECOND = 'm/s';
export const UNIT_KNOT = 'kt';
export const UNIT_MILES_PER_HOUR = 'mph';
export const UNIT_KILOMETERS_PER_HOUR = 'km/h';
export const UNIT_FEET_PER_MIN = 'ft/min';
// Temperature
export const UNIT_CELSIUS = 'C';
export const UNIT_FAHRENHEIT = 'F';
export const UNIT_KELVIN = 'K';
// Pressure
export const UNIT_HECTOPASCAL = 'hPa';
export const UNIT_INCHES_OF_MERCURY = 'inHg';
export const UNIT_MILLIBAR = 'mb';
// Humidity
export const UNIT_PERCENTAGE = '%';
// Location and Angles (needs to be converted into required symbols upon use)
export const UNIT_DEGREE = 'degrees';
export const UNIT_DMS = 'dms';
// Mass
export const UNIT_KILOGRAM = 'kg';
export const UNIT_POUND = 'lb';
export const UNIT_TON = 't';
export const UNIT_GRAM = 'g';
export const UNIT_MILLIGRAM = 'mg';

// Option for unit conversions - overrides any conversions within the same measurement system and returns the base unit if supplied
export const METRIC_BASE_UNIT: TMetricBaseUnit = 'metricBaseUnit';
export const IMPERIAL_BASE_UNIT: TImperialBaseUnit = 'imperialBaseUnit';

export const METRIC_UNITS = [
  UNIT_MILLIGRAM,
  UNIT_GRAM,
  UNIT_TON,
  UNIT_KILOGRAM,
  UNIT_CELSIUS,
  UNIT_MILLIBAR,
  UNIT_HECTOPASCAL,
  UNIT_KELVIN,
  UNIT_CELSIUS,
  UNIT_METER,
  UNIT_KILOMETER,
  UNIT_MILLIMETER,
  UNIT_CENTIMETER,
  UNIT_METERS_PER_SECOND,
  UNIT_KNOT,
  UNIT_KILOMETERS_PER_HOUR,
  METRIC_BASE_UNIT,
];

export const IMPERIAL_UNITS = [
  UNIT_FOOT,
  UNIT_MILE,
  UNIT_NAUTICAL_MILE,
  UNIT_INCH,
  UNIT_KNOT,
  UNIT_MILES_PER_HOUR,
  UNIT_FEET_PER_MIN,
  UNIT_FAHRENHEIT,
  UNIT_INCHES_OF_MERCURY,
  UNIT_POUND,
  IMPERIAL_BASE_UNIT,
];
// Unit libraries for conversions and presentation
export const UNIT_PROFILE_DEFAULT = 'ICAO_ALTERNATIVE';
export const UNIT_PROFILE_LOCAL = 'LOCAL_SYSTEM';
export const UNIT_PROFILES: { [key: string]: IConfigurationUnits } = {
  US_CUSTOMARY: {
    distance: 'mi',
    distanceVertical: 'ft',
    speed: 'mph',
    speedVertical: 'ftmin',
    temperature: 'f',
    pressure: 'inhg',
    humidity: 'percent',
    visibility: 'mi',
    runwayVisualRange: 'ft',
    precipitation: 'in',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'ft',
    mass: 'lb',
    carbonEmissions: 'kg',
  },
  ICAO_METRIC: {
    distance: 'km',
    distanceVertical: 'm',
    speed: 'kmh',
    speedVertical: 'ms',
    temperature: 'c',
    pressure: 'hpa',
    humidity: 'percent',
    visibility: 'km',
    runwayVisualRange: 'm',
    precipitation: 'mm',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'm',
    mass: 'kg',
    carbonEmissions: 'kg',
  },
  ICAO_ALTERNATIVE: {
    distance: 'nm',
    distanceVertical: 'ft',
    speed: 'kt',
    speedVertical: 'ftmin',
    temperature: 'c',
    pressure: 'hpa',
    humidity: 'percent',
    visibility: 'km',
    runwayVisualRange: 'm',
    precipitation: 'mm',
    location: 'dms',
    direction: 'deg',
    runwayLength: 'm',
    mass: 'kg',
    carbonEmissions: 'kg',
  },
};

export const CONVERSION_TABLE: { [key: string]: string } = {
  km: UNIT_KILOMETER,
  m: UNIT_METER,
  mi: UNIT_MILE,
  nm: UNIT_NAUTICAL_MILE,
  ft: UNIT_FOOT,
  kt: UNIT_KNOT,
  mph: UNIT_MILES_PER_HOUR,
  kmh: UNIT_KILOMETERS_PER_HOUR,
  ms: UNIT_METERS_PER_SECOND,
  ftmin: UNIT_FEET_PER_MIN,
  f: UNIT_FAHRENHEIT,
  c: UNIT_CELSIUS,
  k: UNIT_KELVIN,
  inhg: UNIT_INCHES_OF_MERCURY,
  hpa: UNIT_HECTOPASCAL,
  mb: UNIT_MILLIBAR,
  percent: UNIT_PERCENTAGE,
  in: UNIT_INCH,
  mm: UNIT_MILLIMETER,
  cm: UNIT_CENTIMETER,
  deg: UNIT_DEGREE,
  dms: UNIT_DMS,
  kg: UNIT_KILOGRAM,
  lb: UNIT_POUND,
};

export const DECIBEL_FILTER_MIN = 0;
export const DECIBEL_FILTER_MAX = 140;
export const SECONDS_FILTER_MIN = 0;
export const SECONDS_FILTER_MAX = 240;
export const ERROR_MAX_GREATER_THAN_MIN = 'maxGreaterThanMin';

export const MOBILE_SCREEN_WIDTH = 992;
export const DEFAULT_BBOX_DISTANCE = 320;
export const FLY_TO_DURATION = 800;

export const ONE_DAY_IN_SECONDS = 86400;
export const ONE_MINUTE_IN_SECONDS = 60;
export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_WEEK__IN_SECONDS = 604800;
export const ONE_HOUR = 1000 * 60 * 60;
export const FIFTY_NINE_SECONDS = 59;
export const MAX_COMPLAINER_ADDRESS_LENGTH = 30;
export const ALIASES = '...';
export const PRIMARY_CONTACT = 'Phone1';
export const SECONDARY_CONTACT = 'Phone2';

export const NOT_PREFERRED_CONTACT = 'NotSet';
export const EMAIL = 'Email';
export const MAIL = 'Letter';

export const TOO_LOUD = 'Too Loud';
export const TOO_LOW = 'Too Low';
export const FREQUENT = 'Frequent';
export const GENERAL_INQUIRY = 'General Inquiry';
export const PRIMARY_PHONE_CONTACT = 'Phone1';
export const SECONDARY_PHONE_CONTACT = 'Phone2';
export const EMAIL_CONTACT = 'Email';
export const NOT_SET_CONTACT = 'NotSet';

export const BLANK_STRING_PLACEHOLDER = '—';
export const UNMAPPED_STRING_PLACEHOLDER = '? / ?';
export const SETTINGS_MODULE_TO_REFRESH_ON_CLICK = ['Infringement Rules', 'Spatial Features'];
// Settings
export const SINGLE = 'single';
export const MULTIPLE = 'multiple';
export const LOADFACTORS_MODULE = 'loadFactors';
export const FUELTYPES_MODULE = 'fuelTypes';
export const MANAGE_CONFIGURATIONS_MODULE = 'manageConfigurations';
export const TIME_IN_MODES_MODULE = 'timeInModes';
export const AIRCRAFT_MAPPING_MODULE = 'aircraftMapping';
export const FLEETMIX_MODULE = 'fleetMix';
export const AIRLINE_MAPPING_MODULE = 'airlineMapping';
export const SPATIAL_FEATURES_MODULE = 'spatialFeatures';
export const CHANGELOGS_MODULE = 'changelogs';
export const AIRCRAFT_ASSIGNMENTS_MODULE = 'aircraftAssignments';
export const CONTOURS_MODULE = 'contours';
export const FORMATION_COUNT_EDIT_VALUES = Array.from(Array(20), (e, i) => (i + 1).toString());
export const LOCATION_MANAGEMENT_MODULE = 'locationManagement';
export const MONITOR_LOCATION = 'MonitorLocation';
export const NOISE_DEVICE = 'NoiseDevice';

// ZOOM
export const ZOOM_DEFAULT_LEVEL = 8;
export const ZOOM_SELECTION_TOLERANCE_LOW = 5; // When zoomed in, set tolerance for track selection click lower
export const ZOOM_SELECTION_TOLERANCE_HIGH = 5; // When zoomed out, set tolerance for track selection click higher

// Theme
export const THEME_DEFAULT_COLOR = '#8C54FF';
export const THEME_DEFAULT_OPERATIONS_THEME = 'default';
export const THEME_DEFAULT_OPERATION_TYPE = 'arrival';
export const THEME_DEFAULT_OPERATIONS = {
  arrival: '#EA702E',
  departure: '#9B68DE',
  touchandgo: '#5AAAFA',
  overflight: '#5AA700',
};
export const THEME_ALTITUDE_BANDS_FALLBACKS = [
  '#591944',
  '#900D41',
  '#C90036',
  '#E2611B',
  '#F2920D',
  '#FEC300',
  '#FED24080',
];

export const PROFILE_GRAPH_OPACITY = 0.25;

export const AIRCRAFT_TYPE_ICON_CODES = {
  aircraftCategories: 'ac',
  operationTypes: 'ad',
  correlated: 'co',
};

// Maptypes
export const MAP_TYPES = {
  COMPLAINTDETAILS: 'complaintDetails',
  COMPLAINTADDFLIGHT: 'complaintAddFlight',
  COMPLAINTVIEWCOMPLAINT: 'complaintViewComplaint',
  INFRINGEMENTDETAILS: 'infringementDetails',
  INFRINGEMENTSUMMARY: 'infringementSummary',
  INFRINGEMENTCANDIDATES: 'infringementCandidates',
  NOISEEVENTDETAILS: 'noiseEventDetails',
  NOISEEVENTSUMMARY: 'noiseEventSummary',
  OPERATIONDETAILS: 'operationDetails',
  OPERATIONSUMMARY: 'operationSummary',
  SETTINGS: 'settings',
};
export const MAPTYPE_DEFAULT = MAP_TYPES.OPERATIONSUMMARY;

// Ruler Constants
export const MARKER_OFFSET = 100;

export const KEYCODES = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  pausebreak: 19,
  capslock: 20,
  esc: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
  insert: 45,
  delete: 46,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  leftwindowkey: 91,
  rightwindowkey: 92,
  selectkey: 93,
  numpad0: 96,
  numpad1: 97,
  numpad2: 98,
  numpad3: 99,
  numpad4: 100,
  numpad5: 101,
  numpad6: 102,
  numpad7: 103,
  numpad8: 104,
  numpad9: 105,
  multiply: 106,
  add: 107,
  subtract: 109,
  decimalpoint: 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  numlock: 144,
  scrolllock: 145,
  semicolon: 186,
  equalsign: 187,
  comma: 188,
  dash: 189,
  period: 190,
  forwardslash: 191,
  graveaccent: 192,
  openbracket: 219,
  backslash: 220,
  closebracket: 221,
  singlequote: 222,
};

// Unicodes for special characters
export const CHARACTER_UNICODES = {
  CHECK_MARK: '\u2713',
  EMDASH: '\u2014',
};

// Spatial features form constants
export const FEATURE_TYPE_FILTER = 'featureTypeFilter';
export const FEATURE_TYPES = {
  GATE: 'Gate',
  SELECTION_ZONE: 'SelectionZone',
  CORRIDOR: 'Corridor',
};

export const FEATURE_NAME_BY_TYPE = {
  Gate: 'gates',
  SelectionZone: 'selectionZones',
  Corridor: 'corridors',
};

export const SPATIAL_FEATURES_TRANSLATION_MODULE = 'spatial feature translation';
export const TEXT_INPUT = 'text';
export const DROPDOWN_INPUT = 'dropdown';
export const SPATIAL_FEATURES = 'Spatial Features';
export const CEILING_MAX_FEET = 70000;
export const FLOOR_MIN_FEET = -1226;
export const LEGEND = 'Legend';
export const INITIAL_GATE_OFFSET = 10;
export const CORRIDOR_GATE_OFFSET = -10;
export const RENDER_FEATURE_DELAY = 1000;
export const VIEWPORT_OFFSET = 100;
export const FLOOR_DEFAULT_FEET = 0;
export const CEILING_DEFAULT_FEET = 65616.798;
export const GEOMETRY_INVALID_TIMEOUT = 2500;
export const INITIAL_PAGE_INFO_OBJECT = { endCursor: '', startCursor: '', hasNextPage: false };

export const invalidGeometryMessage = {
  Gate: 'Invalid Geometry: Left and right points must be different',
  SelectionZone: "Invalid Geometry: Polygon can't self intersect",
  Zone: "Invalid Geometry: Polygon can't self intersect",
  Corridor: "Invalid Geometry: Polygon can't self intersect",
};
export const MAX_RENDERABLE_FEATURES = 30;
export const DEFAULT_ZONE_SIZE = { height: 10, width: 10 };
export const ZONE_EDGE_LENGTH = 10;
export const TABLE_FILTER_DEBOUNCE_INTERVAL = 300;
export const OPERATION_FILTER_STRING = 'filter: {operationTypes: [Arrival,Departure,TouchAndGo]}';
export const OPERATION_SORT_STRING = 'sort: [ {field:"time", direction:ASC} ]';
// Tag related values
export const MAP_POPUP_OFFSET = 8;

// Operation correlation counts
export const OPERATION_CORRELATION_COUNT_LIMIT = 5;

// TABLE Constants
export const DEFAULT_FILTER_OBJECT = { isInitialized: false, filters: [] };
export const TABLE_CONSTANTS = {
  SORT: 'sort',
  FILTER: 'filter',
  FILTER_TYPES: {
    INT: 'int',
    STRING: 'string',
  },
};

export const TABLE_FIELD_TYPES = {
  STRING: [
    // Spatial
    'ltoModel',
    'type',
    'aircraft',
    'feature',
    'mappedEnrouteAircraft',
    'mappedLTOAircraft',
    'comment',
    'enrouteModel',
    'engine',
    'airline',
    'origin',
    'destination',
    'aircraftEngine',
    'airport',
    'name',
    'infringementType',
    'featureType',
    'runway',
    'aircraftType',
    'infringementName',
    // Changelog fields
    'dataType',
    'changeType',
    'fieldName',
    'userName',
    'oldValue',
    'newValue',
  ],
  DATE_TIME: [
    'toDatetime',
    'fromDatetime',
    'lastUpdateTime',
    'endTime',
    'startTime',
    // Changelogs
    'time',
  ],
  BOOLEAN: ['isActive'],
  NUMBER: ['numberOfAircraft', 'percentage', 'factorCO2', 'numberOfFlights', 'dataId'],
};

export const OPERATION_COUNT = 100;
export const INFRINGEMENT_TYPE = 'infringementType';
export const RENDERED_RULES = ['Corridor', 'Gate', 'Exclusion', 'Noise'];
export const INPUT_RETURN_TYPES = {
  NUMBER: 'number' as const,
  STRING: 'string' as const,
};
export const NOISE_RULE_THRESHOLD_MIN = 20;
export const NOISE_RULE_THRESHOLD_MAX = 141;
export const NOISE_MONITOR_METRICS = {
  LMAX: 'LMAX',
  LEQ: 'LEQ',
  SEL: 'SEL',
  EPNL: 'EPNL',
};

// Comment and inquiry text area rules
export const INQUIRY_MAX_LENGTH = 4000;
export const COMMENT_MAX_LENGTH = 2000;

// Development templates
export const PUSH_NOTICE_SAMPLE = {
  name: 'pushNotice',
  path: 'Notifications',
  fileType: 'HTML',
  lastModified: '2022-03-20T04:53:39+01:00',
  downloadResource: {
    uri: 'http://localhost:3000/pushNotice.html',
  },
};
export const NMT_METRICS = {
  LAeq: 'LAeq',
  LAI: 'LAI',
  LAF: 'LAF',
  LAS: 'LAS',
  LApk: 'LApk',
  LCeq: 'LCeq',
  LCI: 'LCI',
  LCF: 'LCF',
  LCS: 'LCS',
  LCpk: 'LCpk',
  LZeq: 'LZeq',
  LZI: 'LZI',
  LZF: 'LZF',
  LZS: 'LZS',
  LZpk: 'LZpk',
};

export const DEFAULT_COMPLAINT_FORM_VALIDATION = {
  title: {
    match: '^.{0,10}$',
  },
  firstName: {
    match: '^.{1,100}$',
  },
  lastName: {
    match: '^.{1,100}$',
  },
  phone: {
    match: '^(\\+?)([\\d]){0,15}$',
  },
  email: {
    match:
      '^((?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|\\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\\")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])){0,254}$',
  },
  complaint: {
    match: '^(.|[\\n]){1,4000}$',
    required: true,
  },
  address: {
    placeName: {
      match: '^.{0,200}$',
    },
    streetAddress: {
      match: '^.{0,200}$',
    },
    city: {
      match: '^.{0,50}$',
    },
    state: {
      match: '^.{0,30}$',
    },
    postcode: {
      match: '^[0-9A-Z -]{0,15}$',
    },
    country: {
      match: '^.*$',
    },
    streetAddress2: {
      match: '^.{0,200}$',
    },
  },
};

export const SCENARIO_PERIOD_DEFAULTS = { day: '07:00', evening: '19:00', night: '22:00' };
