import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { operationQuery, operationSchema } from 'src/app/resolvers/schema';

export const fetchOperationsByTime = (
  client: ApolloClient<object>,
  time: string,
  airportId?: string
) => {
  return new Promise((resolve, reject) => {
    const queryParams = `
      startTime: "${time}"
      endTime: "${time}"
      timeRangeMode:InFlight
      filter: {
        operationTypes: [Arrival, Departure, TouchAndGo]
        airportIds: ["${airportId}"]
      }
    `;
    const GET_OPERATIONS = gql`
      query {
        ${operationQuery}(${queryParams}) ${operationSchema}
      }
    `;
    client
      .query({ query: GET_OPERATIONS })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[operationQuery] &&
          response.data[operationQuery].items
        ) {
          const { items: data } = response.data[operationQuery];
          resolve({
            data,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
