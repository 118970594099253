import gql from 'graphql-tag';

export const UPDATE_AIRCRAFT_MAPPINGS = gql`
  mutation updateAircraftMappings($aircraftMappings: [BulkAircraftMappingChangeInput], $scenarioId: Int , $startTime: DateTimeOffset, $endTime: DateTimeOffset) {
    updateAircraftMappings(aircraftMappings: $aircraftMappings) {
      aircraftType
      id
      mappedLTOEngine
      mappedLTOAircraft
      mappedEnrouteEngine
      mappedEnrouteAircraft
      engine
      scenarioId
    }
    carbonEmissionsRequestReprocessing(scenarioId: $scenarioId, startTime: $startTime, endTime: $endTime)
  }
`;
