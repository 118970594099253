import { ColumnHeader } from 'src/components';
import { sortTable } from 'src/@complaints/actions';
// formik
import { getIn } from 'formik';

/*
 * Prepare operation header
 */
export const formatHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config

  const {
    fields: { complaints: columns },
  } = translationData;

  const displayName = ColumnHeader({
    sortName: 'complainerName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: columns.complainerName,
      abbreviation: null,
      sortBy: 'complainerName',
    },
  });

  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: columns.time,
      abbreviation: null,
      sortBy: 'time',
    },
  });

  const reason = ColumnHeader({
    sortName: 'disturbanceTypes',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: columns.disturbanceTypes,
      abbreviation: null,
      sortBy: 'disturbanceTypes',
    },
  });

  const complainerPostcode = ColumnHeader({
    sortName: 'complainerPostcode',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: columns.complainerPostcode,
      abbreviation: null,
      sortBy: 'complainerPostcode',
    },
  });

  const displayStatus = ColumnHeader({
    sortName: 'status',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: columns.status,
      abbreviation: null,
      sortBy: 'status',
    },
  });

  const correlated = ColumnHeader({
    sortName: 'correlated',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortable: false,
    sortTable,
    languageData: {
      title: columns.correlated,
      abbreviation: null,
      sortBy: 'correlated',
    },
  });

  return Object.assign({}, columns, {
    displayName,
    displayTime,
    reason,
    complainerPostcode,
    correlated,
    displayStatus,
  });
};

export const getComplaintsFormikProps = columns => {
  const firstNameProps = {
    label: columns.firstName,
    id: 'firstName',
    name: 'firstName',
  };
  const titleProps = {
    label: columns.title,
    id: 'title',
    name: 'title',
  };
  const lastNameProps = {
    label: columns.lastName,
    id: 'lastName',
    name: 'lastName',
  };
  const emailProps = {
    label: columns.email,
    id: 'email',
    name: 'email',
  };
  const placeNameProps = {
    label: columns.address,
    id: 'place_name',
    name: 'place_name',
  };
  const complaintProps = {
    label: columns.complaint,
    id: 'complaint',
    name: 'complaint',
  };
  const responseRequiredProps = {
    label: columns.responseRequired,
    id: 'responseRequired',
    name: 'responseRequired',
  };
  const privacyEnabledProps = {
    id: 'privacyEnabled',
    name: 'privacyEnabled',
  };
  const cityProps = {
    label: columns.addressFields.city,
    id: 'address.city',
    name: 'address.city',
  };
  const stateProps = {
    label: columns.addressFields.state,
    name: 'address.state',
  };
  const streetAddress2Props = {
    label: columns.addressFields.streetAddress2,
    name: 'address.streetAddress2',
  };
  const streetAddressProps = {
    label: columns.addressFields.streetAddress,
    name: 'address.streetAddress',
  };
  const postcodeProps = {
    label: columns.addressFields.postcode,
    name: 'address.postcode',
  };
  return {
    firstNameProps,
    titleProps,
    lastNameProps,
    emailProps,
    placeNameProps,
    complaintProps,
    privacyEnabledProps,
    responseRequiredProps,
    cityProps,
    stateProps,
    streetAddressProps,
    streetAddress2Props,
    postcodeProps,
  };
};

export const fieldFormikProps: any = (
  fieldName,
  { handleChange, handleBlur, values, touched, errors },
  isAnonymous
) => {
  return {
    onChange: handleChange,
    onBlur: handleBlur,
    value: isAnonymous ? '' : getIn(values, fieldName),
    disabled: isAnonymous ? true : false,
    error: getIn(touched, fieldName) ? getIn(errors, fieldName) : '',
  };
};

export const phoneNumberFormikProps = (index, columns) => ({
  label: index === 0 ? columns.phone1 : columns.phone2,
  id: 'phoneNumbers[' + index + '].number',
  name: 'phoneNumbers[' + index + '].number',
});
