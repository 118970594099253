import { InteractiveLayerId } from './useInteractiveLayerIds';
import { useMapboxLayerIds } from './useMapboxLayerIds';

export enum NonInteractiveLayerId {}

type LayerId = NonInteractiveLayerId | InteractiveLayerId;

/**
 * Accepts an array of layer IDs, returns the first layer ID that exists.
 * Layers may or may not exist depending on the map, so find the ID of the first
 * layer that's in use.
 *
 * @param layerIds LayerId[]
 * @returns First layer ID that exists
 */
export const useBeforeId = (layerIds: LayerId[]) => {
  const allLayerIds = useMapboxLayerIds();
  return layerIds.find(id => allLayerIds.includes(id as string));
};
