import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import cx from 'classnames';

// Providers
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { PageHeader } from 'src/components';
// import { ContoursMap } from './Components';

import { SkeletonText, Table, Button, Icons } from '@ems/client-design-system';

// Variables
import { AIRCRAFT_ASSIGNMENTS_MODULE, BLANK_STRING_PLACEHOLDER } from 'src/constants';

import { formatTableData } from './helpers';

// Functions
import { useTableFilter, formatTableHeaders } from 'src/utils';

// Queries
import { GET_AIRCRAFT_NOISE_CONTOURS } from './queries';

// Types
import { ITableFilterObject, IFilterItem } from 'src/components/TableComponents';

import {
  TRecord,
  IAircraftNoiseContourVars,
  IAircraftNoiseContourData,
  ITableData,
} from './interfaces';

// Styles
import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

// DEFAULTS
import { defaultQueryFilter, defaultSortOrder, defaultQueryResult } from './defaults';

// TABLE HEADER SET UP
import { columnData } from './tableSettings';

// Components
import { DeleteContourDialog, UploadDialog, ContoursMap } from './Components';
import { ISortActionCallback } from 'src/components/TableComponents/TableColumnHeader/interfaces';
import { getActiveFilters } from 'src/utils/tableHelpers/tableFilterHelpers';

const ContourTable = styled(Table)`
  .table_cell.aircraft-noise-contours-table-name .table_cell--item {
    width: 100%;
  }

  &.selection-disabled .table_select {
    pointer-events: none;
    cursor: not-allowed;

    .bp3-control-indicator {
      border-width: 1px;
      background-color: ${styles.ui02};
      cursor: not-allowed;
    }
  }
`;

export const ContoursContainer = ({}) => {
  // Selectors
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage() as TRecord;
  const loadedColorConfig = {
    chroma: {
      bands: [0, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 1000],
      colors: [
        '#82A6AD',
        '#A0BABF',
        '#B8D6D1',
        '#CEE4CC',
        '#E2F2BF',
        '#F3C683',
        '#E87E4D',
        '#CD463E',
        '#A11A4D',
        '#75085C',
        '#430A4A',
      ],
    },
  };

  const defaultQueryVariables: IAircraftNoiseContourVars = {
    queryFilter: defaultQueryFilter,
    sortOrder: [defaultSortOrder],
  };

  // States
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [queryVariables, setQueryVariables] = useState<IAircraftNoiseContourVars>(
    defaultQueryVariables
  );
  const [tableFilterObject, setTableFilterObject] = useState<ITableFilterObject>(null);
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [contourIdToDelete, setContourIdToDelete] = useState<number>();
  const [isDeleteContourDialogOpen, setIsDeleteContourDialogOpen] = useState(false);
  const [isMapAnimating, setIsMapAnimating] = useState(false);
  const [selectedContours, setSelectedContours] = useState([]);

  // Language Strings
  const {
    components: {
      hints: { tryChangingFilters },
      labels: {
        table: { endTable },
      },
    },
    screens: {
      settings: {
        notFound: {
          table: { noAircraftNoiseContourFound },
        },
      },
    },
  }: TRecord = translationData;

  // Queries
  const { loading: isLoading, data, refetch } = useQuery<
    IAircraftNoiseContourData,
    IAircraftNoiseContourVars
  >(GET_AIRCRAFT_NOISE_CONTOURS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  // Utils
  const updateFilterItems = (
    tableFilters: ITableFilterObject,
    filterKey: string,
    filterItems: IFilterItem[]
  ) => {
    const filter = (tableFilters.filters || []).find(({ filterName }) => filterName === filterKey);

    if (filter) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      filter.filterItems = filterItems;
    }
  };

  // On first load, select first contour result
  useEffect(() => {
    if (isLoading) {
      setSelectedInTable([]);
    }
    if (isFirstLoad && !selectedInTable.length && data) {
      setSelectedInTable([0]);
      setIsFirstLoad(false);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      if (!!tableData.length) {
        setSelectedContours(
          selectedInTable.map(index => ({
            ...tableData[index].contourData,
            style: tableData[index].style,
          }))
        );
      } else {
        setSelectedInTable([0]);
      }
    }
  }, [selectedInTable, data]);

  // Actions
  const sortAction = ({ sortName, sortObject: { field, direction } }: ISortActionCallback) => {
    const sortDirection = field === sortName ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    handleSelectRow([]);
    setQueryVariables({
      ...queryVariables,
      sortOrder: [
        {
          field: sortName,
          direction: sortDirection,
        },
      ],
    });
  };

  const setTableFilters = (tableFilters: ITableFilterObject) => {
    if ((!isLoading && !tableFilterObject) || !!tableFilterObject) {
      const filterData: {
        [filterName: string]: IFilterItem[];
      } = {
        noiseModelTypes: ['INM', 'AEDT'].map(type => ({ label: type, key: type })),
      };

      updateFilterItems(tableFilters, 'noiseModelTypes', filterData.noiseModelTypes);
      setTableFilterObject(tableFilters);
    }
  };

  useEffect(() => {
    if (tableFilterObject) {
      const activeFilterObject = getActiveFilters(tableFilterObject);
      const newQueryFilter = {
        ...defaultQueryFilter,
        ...activeFilterObject,
      };
      handleSelectRow([]);
      setQueryVariables({
        ...queryVariables,
        queryFilter: newQueryFilter,
      });
    }
  }, [tableFilterObject]);

  const handleSelectRow = (indices: number[]) => {
    const selected = indices.filter(i => !selectedInTable.includes(i));
    if (selected.length) {
      setSelectedInTable(selected);
    }
  };

  const confirmDelete = (contourId: number) => {
    setContourIdToDelete(contourId);
    setIsDeleteContourDialogOpen(true);
  };

  const handleDelete = (id: number) => {
    handleSelectRow([]);
    const updated = tableData.filter(contour => contour.id !== id);
    setTableData(updated);
  };

  // Table components
  useEffect(() => {
    if (isLoading) {
      setTableData([]);
    } else {
      const { aircraftNoiseContours } = data || defaultQueryResult;
      setTableData(formatTableData({ aircraftNoiseContours, handleDelete: confirmDelete }));
    }
  }, [data, isLoading]);

  const rowHeaders: Record<string, JSX.Element> = formatTableHeaders({
    headerItems: columnData(translationData),
    sortAction,
    sortObjectSelector: {
      getSortObject: () => queryVariables.sortOrder[0],
    },
    translationData,
    translationModuleName: AIRCRAFT_ASSIGNMENTS_MODULE,
    isLoading,
  });

  const { tableFilter } = useTableFilter({
    tableColumnData: columnData(translationData),
    tableData,
    isTableLoading: isLoading,
    tableFilterObject,
    setTableFilterObject: setTableFilters,
  });

  const totalCount = tableData.length;

  return (
    <>
      <UploadDialog
        isOpen={isUploadDialogOpen}
        onRefetch={() => {
          void refetch();
        }}
        onClose={() => {
          setIsUploadDialogOpen(false);
        }}
      />

      {isDeleteContourDialogOpen && (
        <DeleteContourDialog
          isOpen={isDeleteContourDialogOpen}
          contourId={contourIdToDelete}
          onCompleted={id => {
            setIsDeleteContourDialogOpen(false);
            setContourIdToDelete(undefined);
            if (id) {
              handleDelete(id);
            }
          }}
        />
      )}

      <div className="settings__split">
        <div className="settings__split-content">
          <div className="settings__heading">
            <PageHeader title="Noise Contours">
              <SkeletonText loading={isLoading} width="4rem">
                <span className="settings__heading-count page-count">{`${
                  totalCount < 0 || isLoading ? BLANK_STRING_PLACEHOLDER : totalCount
                } Total`}</span>
              </SkeletonText>
            </PageHeader>
            <div>
              <Button
                className="aircraft-noise-contours-settings__upload-button"
                style="primary"
                leftIcon={<Icons iconName={`ic-ui-add`} title="Upload" size={18} />}
                onClick={() => setIsUploadDialogOpen(true)}>
                {'Upload'}
              </Button>
            </div>
          </div>
          <div className="aircraft-noise-contours-settings__container">
            {tableFilter}
            <ContourTable
              className={cx('aircraft-noise-contours-table', {
                'selection-disabled': isMapAnimating,
              })}
              loading={isLoading}
              rowHeaders={rowHeaders}
              data={tableData}
              wrapperClassName={'feature-wrapper'}
              columns={columnData(translationData).map(({ columnName }) => columnName)}
              selectedData={selectedInTable}
              gridID="aircraft-noise-contours-table"
              onSelectRow={handleSelectRow}
              languageData={{
                noDataTitle: noAircraftNoiseContourFound as string,
                noDataText: tryChangingFilters as string,
                endTable: endTable as string,
              }}
              showDashIfEmpty
              hideSelectAll
            />
          </div>
        </div>
        <div className="settings__split-map">
          <div className="map__wrapper">
            <ContoursMap
              colorProfiles={loadedColorConfig}
              selectedContours={selectedContours}
              dataAvailable={!!tableData.length}
              setIsMapAnimating={setIsMapAnimating}
            />
          </div>
        </div>
      </div>
    </>
  );
};
