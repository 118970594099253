// components
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
// schema
import {
  airQualitySummaryTableByTimeRangeQuery,
  airQualitySummaryTableByTimeRangeSchema,
} from './schema';
// helpers
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';
// store
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// constants
import {
  TOTAL_MOVEMENTS,
  TMA_EFFICIENCY,
  TOTAL_PASSENGERS,
  UNIT_POUND,
  CARBON_EMISSION_ROUNDING_PRECISION,
  UNIT_KILOMETER,
} from 'src/constants';
// interfaces
import {
  IAirQualitySummaryRow,
  IAirQualitySummaryTableData,
  IEmissionSummary,
  IGraphColumn,
} from '../interfaces';
import {
  getMassConversionFactorAndUnit,
  IMassConversionFactor,
  kilogramToPound,
} from '@ems/client-design-system';

export const fetchData = (
  client: ApolloClient<object>,
  filterString: string,
  metric: string,
  unit: string
) => {
  const { from, to } = dateRangeStore.getDateFilters();
  const selectedDateRange = formatdate(from, to);
  return new Promise((resolve, reject) => {
    const GET_AIRQUALITY_SUMMARY_TABLE = gql`
    query {
      ${airQualitySummaryTableByTimeRangeQuery} (${getQueryParams(
      selectedDateRange,
      filterString
    )}) ${airQualitySummaryTableByTimeRangeSchema}
    }`;
    client
      .query({
        query: GET_AIRQUALITY_SUMMARY_TABLE,
      })
      .then(response => {
        if (response && response.data) {
          resolve(
            getFormattedResponse(
              response.data[airQualitySummaryTableByTimeRangeQuery],
              metric,
              unit
            )
          );
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

const getEmissionSummary = (
  data: IAirQualitySummaryTableData,
  conversionFactor: number
): IEmissionSummary[] => {
  const emissionSummaries: IEmissionSummary[] = [];
  const totalsColumns = data.columns.filter(column => column.name === 'Totals').pop();
  const UnfilteredTotalsColumns = data.columns
    .filter(column => column.name === 'UnfilteredTotals')
    .pop();

  if (totalsColumns && UnfilteredTotalsColumns) {
    totalsColumns.rows.map(row => {
      if (row.measure === TOTAL_MOVEMENTS || row.measure === TOTAL_PASSENGERS) {
        emissionSummaries.push({
          measure: row.measure,
          value: row.total,
          total: UnfilteredTotalsColumns.rows.find(({ measure }) => measure === row.measure).total,
        });
      } else {
        emissionSummaries.push({
          measure: row.measure,
          value: row.total * conversionFactor,
          total: UnfilteredTotalsColumns.rows.find(({ measure }) => measure === row.measure).total,
        });
      }
    });
  }
  return emissionSummaries;
};

const getMovements = (data: IAirQualitySummaryTableData) => {
  const totalsColumns = data.columns.filter(column => column.name === 'Totals').pop();
  let totalColumnMovementRow: IAirQualitySummaryRow | undefined;
  if (totalsColumns) {
    totalColumnMovementRow = totalsColumns.rows
      .filter(row => row.measure === TOTAL_MOVEMENTS)
      .pop();
  }
  return totalColumnMovementRow ? totalColumnMovementRow.values : [];
};

const getHighestCarbonEmissionWeight = (data: IAirQualitySummaryTableData, metric: string) => {
  let highestCarbonEmissionWeight = 0;
  data.columns.forEach(column => {
    let columnHeighest = 0;
    const emissionRow = column.rows.filter(row => row.measure === metric).pop();
    if (emissionRow) {
      columnHeighest = Math.max(...emissionRow.values);
      if (emissionRow.total > columnHeighest) {
        columnHeighest = emissionRow.total;
      }
    }
    if (columnHeighest > highestCarbonEmissionWeight) {
      highestCarbonEmissionWeight = columnHeighest;
    }
  });

  return highestCarbonEmissionWeight;
};

const getConvertedCarbonEmissions = (values: number[], conversionFactor: number) => {
  return values.map(value =>
    parseFloat((value * conversionFactor).toFixed(CARBON_EMISSION_ROUNDING_PRECISION))
  );
};

const getFormattedResponse = (data: IAirQualitySummaryTableData, metric: string, unit: string) => {
  if (data.columns.length === 0) {
    return {
      rowData: [],
      footerData: [],
      emissionSummary: [],
      graphColumns: [],
      rowGrouping: [],
      movements: [],
    };
  }
  let highestCarbonEmissionWeight = getHighestCarbonEmissionWeight(data, metric);
  if (unit === UNIT_POUND) {
    highestCarbonEmissionWeight = kilogramToPound(highestCarbonEmissionWeight);
  }
  const unitConverter: IMassConversionFactor = getMassConversionFactorAndUnit(
    highestCarbonEmissionWeight,
    unit
  );
  const conversionFactor: number = metric !== TMA_EFFICIENCY ? unitConverter.conversionFactor : 1;
  const unitLabel: string = metric !== TMA_EFFICIENCY ? unitConverter.to : UNIT_KILOMETER;

  const { totalsRow, graphColumns } = getTotalsRow(data, metric, conversionFactor);
  const formattedData = formatData(data, metric, conversionFactor);
  const emissionSummary: IEmissionSummary[] =
    metric !== TMA_EFFICIENCY ? getEmissionSummary(data, conversionFactor) : [];
  const movements = metric !== TMA_EFFICIENCY ? getMovements(data) : [];

  return {
    rowData: formattedData,
    footerData: totalsRow,
    emissionSummary,
    graphColumns,
    rowGrouping: data.rowGrouping,
    movements,
    carbonEmissionDisplayUnit: unitLabel,
  };
};

const getQueryParams = (selectedDateRange: { [key: string]: string }, filterString: string) => {
  let queryParams = `
  startTime: "${selectedDateRange.startDate}", 
  endTime: "${selectedDateRange.endDate}",
`;
  queryParams += filterString;

  return queryParams;
};

const getTotalsRow = (
  data: IAirQualitySummaryTableData,
  metric: string,
  conversionFactor: number
) => {
  const totalsRow = {
    total: data.measures.find(measure => measure === metric),
  };
  const graphColumns: IGraphColumn[] = [];
  data.columns
    .filter(column => column.name !== 'Totals')
    .map(column => {
      const emissionRowValues = column.rows.filter(row => row.measure === metric).pop();
      if (emissionRowValues) {
        totalsRow[column.name] = emissionRowValues.total * conversionFactor;
        graphColumns.push({
          name: column.name,
          percentage: emissionRowValues.percentage,
          rows: getConvertedCarbonEmissions(emissionRowValues.values, conversionFactor),
          columnTotal: (emissionRowValues.total * conversionFactor).toFixed(
            CARBON_EMISSION_ROUNDING_PRECISION
          ),
        });
      }
    });
  return { totalsRow, graphColumns };
};

const formatData = (
  data: IAirQualitySummaryTableData,
  metric: string,
  conversionFactor: number
) => {
  const formattedData: object[] = [];
  data.rowGrouping.forEach((currentDate, index) => {
    const dayEmissionData = {};
    const date = 'date';
    dayEmissionData[date] = currentDate;
    data.columns
      .filter(column => column.name !== 'Totals')
      .forEach(column => {
        const emissionRowValues = column.rows.filter(row => row.measure === metric).pop();
        if (emissionRowValues) {
          const rowData = getRowData(emissionRowValues.values, index) * conversionFactor;
          dayEmissionData[column.name] = rowData;
        }
      });
    formattedData.push(dayEmissionData);
  });
  return formattedData;
};

const getRowData = (rows, index) => {
  if (rows.length > index) {
    return rows[index];
  } else {
    return 0.0;
  }
};

const formatdate = (from: Date, to: Date) => {
  const startDate = new Date(`${from.getFullYear()}-${from.getMonth() + 1}-01`);
  const endDate = new Date(`${to.getFullYear()}-${to.getMonth() + 1}-01`);
  return {
    startDate: dateTimeInQuery(startDate, 'start'),
    endDate: dateTimeInQuery(endDate, 'end'),
  };
};
