import React, { useEffect, FC, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useSortSelectors, useFilterSelectors } from 'src/@reports/reducers';
import { ReportsDispatchContext } from 'src/@reports/providers/ReportsStateProvider';
// actions
import { resetAndFetchData, fetchData } from 'src/@reports/actions';
// stores
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { IDataContainer } from 'src/@reports/interfaces';
import { screenScrollToTop } from 'src/utils';

export const DataContainer: FC<IDataContainer> = ({ children }) => {
  const client = useApolloClient();
  const dispatcher = useContext(ReportsDispatchContext);
  // Configuration
  const configSelectors = useConfigSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();

  const {
    reports: {
      grid: { resultSize },
    },
  } = configSelectors.getConfig();
  const { from, to } = dateRangeStore.getDatesStrings();

  useEffect(() => {
    resetAndFetchData(client, resultSize, dispatcher, sortString, filterString);
  }, [filterString, sortString, from, to]);

  useEffect(() => {
    screenScrollToTop();
    fetchData(client, resultSize, dispatcher, sortString, filterString);
  }, []);

  return <>{children}</>;
};
