import { ApolloClient } from 'apollo-client';
import {
  fetchNoiseEventsData,
  fetchNoiseMonitorLocations,
  fetchInAirTracksForPlayback,
} from 'src/@noiseEvents/resolvers/getDataResolver';
// const
import { actionTypes } from 'src/@noiseEvents/newActionTypes';
import { GET_COUNT_DELAY } from 'src/constants';
// utils
import { ResponseValidator } from 'src/utils/responseValidator';
import { fetchProfilesById } from '../resolvers/summaryResolver';
const request = new ResponseValidator();

// fetch 1 record and check for count
export const getDataCount = (
  client: ApolloClient<object>,
  sortString: any,
  filterString: any,
  cursor: string,
  newDispatcher: any
) => {
  const [instance, t] = request.get('noise-events-count');
  request.set(instance, t);
  fetchNoiseEventsData(client, 1, sortString, filterString, cursor)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        newDispatcher({ type: actionTypes.GET_TOTAL_COUNT, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

// checks value of the data recieved for count
export const checkDataCount = (
  client: ApolloClient<object>,
  data: any,
  sortString: any,
  filterString: any,
  cursor: string,
  newDispatcher: any
) => {
  if (
    (data &&
      data.pageInfo &&
      data.pageInfo.hasNextPage &&
      typeof data.totalCount === 'undefined') ||
    data.totalCount === -1
  ) {
    // Fetch more to get count when not available after delay
    setTimeout(() => {
      getDataCount(client, sortString, filterString, cursor, newDispatcher);
    }, GET_COUNT_DELAY);
  }
};

// Fetch data
export const fetchData = ({
  client,
  resultSize,
  newDispatcher,
  sortString,
  filterString,
  cursor = '',
}: {
  client: ApolloClient<object>;
  resultSize: number;
  newDispatcher: any;
  sortString: any;
  filterString: any;
  cursor?: string;
}) => {
  if (!cursor) {
    // Only happens on the first fetch
    newDispatcher({ type: actionTypes.GET_FIRST_FETCH });
  }
  const [instance, t] = request.get('fetch-noise-events');
  request.set(instance, t);
  fetchNoiseEventsData(client, resultSize, sortString, filterString, cursor)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkDataCount(
          client,
          data,
          sortString,
          filterString,
          data.pageInfo.startCursor,
          newDispatcher
        );
        newDispatcher({ type: actionTypes.DATA_FETCHED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchNoiseMonitorData = (
  client: ApolloClient<object>,
  dispatcher: any,
  airportIds: string[]
) => {
  const [instance, t] = request.get('fetch-noise-monitors');
  request.set(instance, t);
  fetchNoiseMonitorLocations(client, airportIds)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        dispatcher({ type: actionTypes.NOISE_MONITORS_RECEIVED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchInAirData = (
  client: ApolloClient<object>,
  dispatcher: any,
  startTime: string,
  endTime: string,
  airportIds: string[]
) => {
  const [instance, t] = request.get('fetch-in-air-tracks');
  request.set(instance, t);
  fetchInAirTracksForPlayback(client, startTime, endTime)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        const initialInAirData = data.data;
        const ids = initialInAirData.map(e => e.id);
        fetchProfilesById(client, ids).then((profileData: any) => {
          const mergedData = initialInAirData.map(e => {
            const foundData = profileData.data.find(d => d.id === e.id);
            return foundData
              ? {
                ...e,
                profile: foundData.profile,
              }
              : e;
          });

          dispatcher({ type: actionTypes.IN_AIR_DATA_RECEIVED, data: mergedData });
        });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const resetAndFetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher?: any,
  sortString?: any,
  filterString?: any
) => {
  newDispatcher({ type: actionTypes.RESET_DATA });
  fetchData({ client, resultSize, newDispatcher, sortString, filterString });
};

export const selectRow = (ids: number[], newDispatcher?: any) => {
  newDispatcher({ type: actionTypes.SELECT_ROW, data: ids });
};

export const selectTracks = (ids: number[], newDispatcher?: any) => {
  newDispatcher({ type: actionTypes.SELECT_TRACK, data: ids });
};

export const resetData = (newDispatcher: any) => {
  newDispatcher({ type: actionTypes.RESET_DATA });
};

export const loadMore = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    filterString: any;
  }
) => {
  const { resultSize, sortString, endCursor, filterString } = options;
  newDispatcher({ type: actionTypes.LOAD_MORE });
  fetchData({
    client,
    resultSize,
    newDispatcher,
    sortString,
    filterString,
    cursor: endCursor,
  });
};

export const sortTable = async (data: any, newDispatcher: any) => {
  const { sortName } = data;
  await newDispatcher({ type: actionTypes.SORT_TABLE, data: sortName });
};
