// Get the sitename or product-id that is part of the URL e.g. https://{domian-name}/{site-name}/operation.
export const getDeployedProductId = (): string | null => {
  const [, siteName] = window.location.pathname.split('/');
  return siteName && siteName !== 'null' ? siteName : null;
};

export const isAtcView = (options: string | string[]): boolean => {
  return options.includes('infringements-atc');
};

export const isCandidateView = (options: string | string[]): boolean => {
  return options.includes('infringements-candidates');
};

// just a temporary solution to get the correct GQL enpoint
// until we sort out our release pipleline and inject the correct config file
export const getGraphQlEndpoint = (uris: { dev: string; demo1: string; demo2: string }) => {
  const { dev, demo1, demo2 } = uris;
  const demo = window.location.host.search('anomsx-demo1') === 0;
  const client = window.location.host.search('anomsx-client') === 0;
  let uri = dev;
  if (demo) {
    uri = demo1;
  } else if (client) {
    uri = demo2;
  }
  return uri;
};

/*
 * prepare a url for external source
 */
export const getUrl = (source: { dev: string; prod: string } | string): string => {
  return typeof source === 'string'
    ? `${getProtocol()}//${source}`
    : `${getProtocol()}//${process.env.NODE_ENV === 'development' ? source.dev : source.prod}`;
};

/*
 * http or https
 */
export const getProtocol = (): string => {
  return window.location.protocol;
};

/*
 * Format number for display
 */
export const formatNumber = (num: number) => {
  if (num === null || num === undefined) {
    return '';
  }
  // TODO use Locale
  return new Intl.NumberFormat().format(num);
};

/*
 * Replaces templated string value
 */
export const translatableTemplate = (
  template: string,
  replaceString: { id: string; value: string }
) => {
  const matchString = `{${replaceString.id}}`;
  if (template.includes(matchString)) {
    return template.replace(matchString, replaceString.value);
  }
  return template;
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

/*
 * returns a load all text required for the LoadMoreBar component
 */
export const loadAllDisplayedText = (
  loadAllText: string,
  totalCount: number | undefined
): string => {
  return totalCount && totalCount > 0
    ? translatableTemplate(loadAllText, {
        id: 'recordCount',
        value: formatNumber(totalCount ? totalCount : 0),
      })
    : '';
};

export const screenScrollToTop = (): void => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
};

/*
 * detect IE or Edge browser
 * returns >= 12 for Edge
 * returns < 12 for IE
 * return false for other browsers
 */
export const detectIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};

export const redirectToValidSite = sitename => {
  const {
    location: { protocol, host },
  } = window;
  console.log('redirect to valid site...');
  window.location.href = `${protocol}//${host}/${sitename}`;
};

export const createCookie = (name, value, expiry) => {
  const date = new Date();
  date.setTime(date.getTime() + expiry);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const readCookie = name => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (const element of cookies) {
    let c = element;
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const eraseCookie = name => {
  createCookie(name, '', -1);
};

// Example, if we wish to interpolate the value of 8 between
// {5: 100, 10: 200}, we have valueA = 100, valueB = 200, distance = (10-5) = 5, target = (8-5) = 3
// which will return (100 / 5) * 3 + 100 = 160.
export const linearInterpolateValue = (
  valueA: number,
  valueB: number,
  distance: number,
  target: number
) => {
  return ((valueB - valueA) / distance) * target + valueA;
};

// Force Download with JavaScript
export const downloadFileFromUrl = (uri: string) => {
  window.location.href = uri;
};

export const stripTrailingComma = (text: string) => {
  return text.trim().replace(/,$/, "");
};

export const getFeatureFlagClasses = (featureFlagObj) => {
  const keys = Object.keys(featureFlagObj);
  if(!keys.length) return '';
  let classNames = '';
  for(const key of keys) {
    if(featureFlagObj[key]) {
      classNames += `feature--${key.toLowerCase()} `;
    }
  }
  return classNames;
};
