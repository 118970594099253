import React, { ReactNode, useContext, useReducer, createContext } from 'react';
import { taxiPathReducer } from './TaxiPathReducer';
import { loadSavedGeoJSON } from '../utils';

// Constants
import { TaxiPathActions } from './TaxiPathReducer';

// Types
import { Dispatch } from 'react';
import { EditedConnectors, EditedNode } from '../map/taxi-path-layer/types';

export enum TaxiPathEditMode {
  NULL = 'null',
  NODES = 'nodes',
  LINES = 'lines',
  DELETE = 'delete',
}

export interface IContextProps {
  children: ReactNode;
}
export interface TaxiPathContextState {
  currentEditMode: TaxiPathEditMode;
  importedKmlData: null | string;
  editedNodes: EditedNode[];
  removedNodeIds: string[];
  addedConnectors: GeoJSON.Feature[];
  editedConnectors: EditedConnectors[];
  removedConnectorIds: string[];
  pathGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString>;
  nodeGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point>;
  searchedNodeId: number | null;
}

export const defaultTaxiPathState: TaxiPathContextState = {
  currentEditMode: TaxiPathEditMode.NULL,
  editedNodes: [] as EditedNode[],
  removedNodeIds: [] as string[],
  importedKmlData: null,
  addedConnectors: [] as GeoJSON.Feature[],
  editedConnectors: [] as EditedConnectors[],
  removedConnectorIds: [] as string[],
  pathGeoJSON: loadSavedGeoJSON<GeoJSON.LineString>('taxipath-linestring'),
  nodeGeoJSON: loadSavedGeoJSON<GeoJSON.Point>('taxipath-nodes'),
  searchedNodeId: null,
};

export interface ITaxiPathContext {
  state: TaxiPathContextState;
  dispatch: Dispatch<{
    payload: unknown;
    type: TaxiPathActions;
  }>;
}

const TaxiPathContext = createContext<ITaxiPathContext>(null);

export const useTaxiPathContext = () => {
  const context = useContext(TaxiPathContext);
  if (!context) {
    throw new Error('No taxi path context found. Is there a wrapping Provider component?');
  }
  return context;
};

export const TaxiPathProvider = ({ children }: IContextProps) => {
  const [state, dispatch] = useReducer(taxiPathReducer, defaultTaxiPathState);
  return (
    <TaxiPathContext.Provider value={{ state, dispatch }}>{children}</TaxiPathContext.Provider>
  );
};
