import React, { useEffect, useMemo, useRef, useState } from 'react';

// Configs
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useMapSettings } from 'src/app/functions/mapSettings';

// Design system
import {
  StyledMap,
  GateGeometry,
  GeocoderPin,
  RulerTool,
  MapControl,
  ZoneGeometry,
  CorridorGeometry,
} from '@ems/client-design-system';

import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { flyTo, useGeocoderPinAlternative, useMapRuler, useSpatialFeatures } from 'src/utils';
import { SPATIAL_FEATURES } from 'src/constants';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MapSettingsContainer } from 'src/containers';
import { MapReferenceLayers } from 'src/app/components';
import { LocationPopup } from 'src/components';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
import { addPinToCentre, goToSelectedAddress, useGeocodePosition } from 'src/utils/geocoding';
import {
  IFeatureDataObject,
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { MapLegend } from 'src/components/MapLegend';
import { useApolloClient } from '@apollo/react-hooks';

// This map is read only - displays items when features are selected in the spatial features list
interface ReviewFeaturesMapProps {
  gates: IGate[];
  corridors: INoiseAbatementCorridor[];
  selectionZones: ISelectionZone[];
}

export const ReviewFeaturesMap: React.FC<ReviewFeaturesMapProps> = ({
  gates,
  corridors,
  selectionZones,
}) => {
  const client = useApolloClient();

  // Map set up
  const [mapNode, mapRef] = useMapRef();
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  const mapBoxConfig = useMapConfig();
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  const [viewport, setViewport] = useState(viewportFromProps);
  const [zoomSet, updateZoomSet] = useState<boolean>(false);
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        ruler: ruler,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();

  const handleMapLoaded = () => {
    mapLoaded();
  };

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // Configuration
  const configSelectors = useConfigSelectors();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  // capture map image
  // used for taking screenshot of map
  const captureRef = useRef(null);
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // Misc map controls
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Geocoder pin and address search
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);

  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  // Removes the distance marker circles from the geocoding pin
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[geocoding.longitude, geocoding.latitude]],
  });

  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude: geocoding.longitude,
      latitude: geocoding.latitude,
    },
  });

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // Ruler Tool

  const units = configSelectors.getUnits();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  // Sets up the appropriate feature base on feature type props
  useEffect(() => {
    const featureData = {
      gates,
      corridors,
      zones: selectionZones,
    } as IFeatureDataObject;

    useSpatialFeatures({
      featureData,
      mapApis,
      viewport,
      setViewport,
      updateZoomSet,
    });
  }, [gates.length, corridors.length, selectionZones.length]);

  return (
    <div className="map_wrapper">
      <StyledMap
        height={'100vh'}
        onLoad={handleMapLoaded}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          onViewportChange(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onMouseMove={undefined}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        {zoomSet &&
          selectionZones.map((zone: ISelectionZone, index: number) => (
            <ZoneGeometry
              key={` ${index} ${zone.name}`}
              zoneName={`zone ${index} ${zone.name}`}
              isEditable={false}
              points={zone.points}
              mapApis={mapApis}
            />
          ))}
        {zoomSet &&
          corridors.map((corridor: INoiseAbatementCorridor, index: number) => (
            <CorridorGeometry
              key={`${index} ${corridor.name}`}
              corridorName={`${index} ${corridor.name}`}
              isEditable={false}
              coordinates={corridor.geometry}
              mapApis={mapApis}
              mapZoom={viewport.zoom}
            />
          ))}
        {zoomSet &&
          gates.map((gate: IGate, index: number) => (
            <GateGeometry
              key={`${index} ${gate.name}`}
              mapZoom={viewport.zoom}
              gateName={`${index} ${gate.name}`}
              isEditable={false}
              coordinates={{
                leftPosition: gate.geometry.leftPosition,
                rightPosition: gate.geometry.rightPosition,
                anchorPosition: gate.geometry.anchorPosition,
              }}
            />
          ))}

        <GeocoderPin
          latitude={enableMapControls ? geocoding.latitude : 0}
          longitude={enableMapControls ? geocoding.longitude : 0}
          draggable
          onClick={() => {
            updateLocationTagOpen(!isLocationTagOpen);
          }}
          onDragStart={() => {
            addRemoveCircles(null);
            updateLocationTagOpen(false);
          }}
          onDragEnd={([longitude, latitude]: number[]) => {
            if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
              updateGeocoding({ longitude, latitude });
            }

            updateLocationAddress(null);

            addRemoveCircles({ longitude, latitude });
            setTimeout(() => {
              updateLocationTagOpen(true);
            }, 1);
          }}
        />

        {enableMapControls && (
          <MapControl
            isPinAdded={geocoding.latitude && geocoding.longitude ? true : false}
            addPinToCentre={() =>
              addPinToCentre({
                updateLocationAddress,
                geocoding,
                viewport,
                updateGeocoding,
                addRemoveCircles,
                updateLocationTagOpen,
              })
            }
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            navigationControl={{
              showCompass: false,
              showHome: true,
              showSearch: true,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            translationData={{
              home: backToCenterLabel,
              search: searchLabel,
              addPin: addPinLabel,
              removePin: removePinLabel,
              mapSettings: mapSettingsTitle,
              ruler,
            }}
            resetView={resetView}
            addressSearch={AddressSearch}
            closeSearch={closeSearch}
            mapSettingsConfig={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  instance={SPATIAL_FEATURES}
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  onUpdate={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}
        {isLocationTagOpen && (
          <LocationPopup
            latitude={geocoding.latitude}
            longitude={geocoding.longitude}
            address={locationAddress || place}
            elevation={elevation}
            languageData={{ latLabel, lngLabel, amslLabel, filterLabel }}
            showFilterButton={false}
            isUsingPca={false}
            mapApis={mapApis}
            onClose={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
          />
        )}
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
        <RulerTool
          distanceUnits={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          addressCoordinates={geocoding}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
        <MapLegend layersDisplayed={layersDisplayed} />
      </StyledMap>
    </div>
  );
};
