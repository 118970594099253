import React, { useState } from 'react';
import { DateTime } from 'luxon';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { DatePicker, TimePrecision, TimeUnit } from '@blueprintjs/datetime';
import { Popover2 } from '@blueprintjs/popover2';
import { Button, Icons } from '@ems/client-design-system';
// utils
import { LocaleCalendar } from 'src/utils/localeCalendar';
// constants
import { DATE_TIME_FORMAT } from 'src/constants';

/**
 * Renders a DateTimePicker component inside of a popover button
 *
 * @param className - The CSS class name for the component.
 * @param selectedDate - The currently selected date.
 * @param maxDate - The maximum selectable date.
 * @param onChange - The callback function triggered when the date is changed.
 * @param twelveHourFormat - Determines whether to use a 12-hour format or a 24-hour format.
 * @param fieldName - The name of the field associated with the component.
 * @returns The rendered DateTimePicker component.
 */
export const DateTimePicker = ({
  className,
  selectedDate,
  maxDate,
  onChange,
  twelveHourFormat,
  fieldName,
}: {
  className: string;
  selectedDate: Date;
  maxDate: Date;
  onChange: (newDate: Date, fieldName: string) => void;
  twelveHourFormat: boolean;
  fieldName: string;
}): JSX.Element => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const dateFormat = twelveHourFormat ? DATE_TIME_FORMAT.INPUT_12H : DATE_TIME_FORMAT.INPUT_24H;
  const {
    globals: { languageTag, firstDayOfWeek },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();

  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );

  // Dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState<boolean>(false);
  // Title for picker button and display
  const buttonTitle = (from: Date, dateFormat: string): string => {
    const fromDateString: string = DateTime.fromJSDate(from, { setZone: true }).toFormat(
      dateFormat
    );
    return fromDateString;
  };

  const handleDateChange = (updatedDate: Date) => {
    onChange(updatedDate, fieldName);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>, unit: TimeUnit) => {
    const value = event.target.value;

    if (unit === TimeUnit.HOUR_24) {
      const newDate = new Date(selectedDate);
      newDate.setHours(parseInt(value, 10));
      onChange(newDate, fieldName);
    } else if (unit === TimeUnit.MINUTE) {
      const newDate = new Date(selectedDate);
      newDate.setMinutes(parseInt(value, 10));
      onChange(newDate, fieldName);
    } else {
      return;
    }
  };

  return (
    <Popover2
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus
      isOpen={isDialogOpen}
      onClose={() => toggleDialog(false)}
      content={
        <DatePicker
          className={className}
          value={selectedDate}
          maxDate={maxDate}
          timePrecision={TimePrecision.MINUTE}
          dayPickerProps={{
            locale: languageTag,
            // Cast to any due to type diff between blueprint and react-day-picker LocaleUtils API
            localeUtils: localeCalendar.localeUtils() as any,
          }}
          timePickerProps={{
            useAmPm: !!twelveHourFormat,
            onBlur: handleBlur,
          }}
          onChange={value => {
            // Second click in double click returns null (since its not a change in value)
            // So skip it and close popover
            if (value) {
              handleDateChange(value);
            } else {
              toggleDialog(false);
            }
          }}
        />
      }>
      <>
        <Button
          style="subtle"
          leftIcon={<Icons iconName={`ic-ui-calendar`} size="24" />}
          aria-label={`Calendar ${buttonTitle(selectedDate, dateFormat)}`}
          onClick={() => toggleDialog(true)}>
          {buttonTitle(selectedDate, dateFormat)}
        </Button>
      </>
    </Popover2>
  );
};
