import { IRoutePermission, IRolesSelectors } from 'src/app/props';

export const canAccessRoute = (
  permissions: IRoutePermission[],
  rolesSelectors: IRolesSelectors
): boolean => {
  let canAccessRoute: boolean = true;
  for (const permission of permissions) {
    if (!rolesSelectors.canAccess(permission.name, permission.type)) {
      canAccessRoute = false;
      break;
    }
  }
  return canAccessRoute;
};
