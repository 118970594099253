import React from 'react';

interface ITrackStyle {
  label: string;
  name: string;
  thumbSrc: string;
}

export interface IMapSettingTrackStyle {
  onSelect: (style: string) => void;
  selectedTrackStyle: string;
  trackStyles: ITrackStyle[];
}

export const MapSettingTrackStyle: React.FC<IMapSettingTrackStyle> = ({
  onSelect,
  selectedTrackStyle,
  trackStyles,
}) => {
  const handleThemeChange = (value: string) => {
    if (selectedTrackStyle !== value) {
      onSelect(value);
    }
  };

  return (
    <>
      {trackStyles.map(mapStyle => (
        <div
          className={`backgrounds-panel_option ${selectedTrackStyle === mapStyle.name &&
            'backgrounds-panel_option--selected'}`}
          key={mapStyle.name}>
          <div className="backgrounds-panel_image" onClick={() => handleThemeChange(mapStyle.name)}>
            <img src={`${process.env.PUBLIC_URL}/${mapStyle.thumbSrc}`} />
          </div>
          <div className="backgrounds-panel_label">
            <span>{mapStyle.label}</span>
          </div>
        </div>
      ))}
    </>
  );
};
