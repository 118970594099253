import { assignTextInput, assignDropdown } from './settings';

export const formatRunwaysData = (timeInModes, runways: string[], airports: string[], translationData, updateRunway, timeInModeValidation) => {
  const formattedTimeInMode = new Array();
  let index = 0;
  const validationRegexp = timeInModeValidation;
  for (const timeInMode of timeInModes) {
    const timeInModeObj = { ...timeInMode };
    timeInModeObj.airport = assignDropdown(
      index,
      timeInModeObj,
      airports,
      translationData,
      'airport',
      timeInModeObj.airport,
      updateRunway
    );
    timeInModeObj.runway = assignDropdown(
      index,
      timeInModeObj,
      runways,
      translationData,
      'runway',
      timeInModeObj.runway,
      updateRunway
    );
    timeInModeObj.durationTaxiOut = assignTextInput(
      index,
      translationData,
      'durationTaxiOut',
      timeInModeObj.durationTaxiOut,
      updateRunway,
      validationRegexp
    );
    timeInModeObj.durationTakeoff = assignTextInput(
      index,
      translationData,
      'durationTakeoff',
      timeInModeObj.durationTakeoff,
      updateRunway,
      validationRegexp
    );
    timeInModeObj.durationClimb = assignTextInput(
      index,
      translationData,
      'durationClimb',
      timeInModeObj.durationClimb,
      updateRunway,
      validationRegexp
    );
    timeInModeObj.durationApproach = assignTextInput(
      index,
      translationData,
      'durationApproach',
      timeInModeObj.durationApproach,
      updateRunway,
      validationRegexp
    );
    timeInModeObj.durationTaxiIn = assignTextInput(
      index,
      translationData,
      'durationTaxiIn',
      timeInModeObj.durationTaxiIn,
      updateRunway,
      validationRegexp
    );
    formattedTimeInMode.push(timeInModeObj);
    index = index + 1;
  }
  return formattedTimeInMode;
};

export const getFormattedPhaseDefaultRecord = record => {
  return {
    id: record.id,
    scenarioId: record.scenarioId,
    airport: record.airport,
    runway: record.runway,
    durationTaxiOut: parseInt(record.durationTaxiOut, 10),
    durationTakeoff: parseInt(record.durationTakeoff, 10),
    durationClimb: parseInt(record.durationClimb, 10),
    durationApproach: parseInt(record.durationApproach, 10),
    durationTaxiIn: parseInt(record.durationTaxiIn, 10),
  };
};
