import { useContext } from 'react';
import { IUserHelpState, IUserHelpItem } from 'src/app/props';
import { appActionTypes } from 'src/app/actionTypes';
import { PushNoticesStateContext } from 'src/app/providers/GlobalStateContext';
import { IPushNoticeSelectors } from 'src/app/props';
import { useSelectors } from 'src/utils/storeHelpers';

export const usePushNoticeSelectors: () => IPushNoticeSelectors = () => {
  const pushNoticeState = useContext(PushNoticesStateContext);

  return useSelectors(pushNoticeState, pushNoticeState => ({
    hasCompletedInitialLoad: () => pushNoticeState.initialLoadCompleted,
  }));
};

export const pushNoticesReducer = (
  state: IUserHelpState,
  action: { type: string; payload: { data: { userHelpLinks: IUserHelpItem[] } } }
) => {
  switch (action.type) {
    case appActionTypes.CHECK_PUSH_NOTICES:
      const { userHelpLinks } = action.payload.data;
      return Object.assign({}, state, {
        userHelpLinks,
        initialLoadCompleted: true,
      });
    default:
      return state;
  }
};
