import { useEffect, useState } from 'react';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

/**
 * Returns the full name of a locale.
 * @param locale The locale string.
 * @returns The full name of the locale.
 */
export const getLocaleFullName = (locale: string): string => {
  const [languageCode, regionCode] = locale.split('-');
  const languageDisplayName = new Intl.DisplayNames([locale], { type: 'language' });
  const regionDisplayName = new Intl.DisplayNames([locale], { type: 'region' });
  const languageName = languageDisplayName.of(languageCode);
  const regionName = regionCode ? regionDisplayName.of(regionCode) : '';

  return regionCode ? `${languageName} (${regionName})` : languageName;
};

/**
 * Retrieves the user's language information.
 * @returns An object containing the selected language, available languages, and default site language.
 */
export const useGetUserLanguage = (): {
  selectedLanguage: {
    label: string;
    key: string;
  } | null;
  availableLanguages: Array<{
    label: string;
    key: string;
  }>;
  defaultSiteLanguage: string;
} => {
  const configSelectors = useConfigSelectors();
  const languageSelectors = useLanguageSelectors();
  const { languageTag: userLanguageTag } = configSelectors.getUserConfig();
  const {
    globals: { availableLanguages: availableLanguageLocales },
  } = configSelectors.getConfig();
  const defaultSiteLanguage = languageSelectors.getDefaultLanguageTag();

  // Combined list just incase default is missing from avaliable languages
  const completeLanguageList = [defaultSiteLanguage, ...availableLanguageLocales];

  const [state, setState] = useState({
    selectedLanguage: completeLanguageList.includes(userLanguageTag)
      ? { label: getLocaleFullName(userLanguageTag), key: userLanguageTag }
      : { label: getLocaleFullName(defaultSiteLanguage), key: defaultSiteLanguage },
    availableLanguages: completeLanguageList.map(locale => ({
      label: getLocaleFullName(locale),
      key: locale,
    })),
    defaultSiteLanguage,
  });

  useEffect(() => {
    setState({
      ...state,
      selectedLanguage: completeLanguageList.includes(userLanguageTag)
        ? { label: getLocaleFullName(userLanguageTag), key: userLanguageTag }
        : { label: getLocaleFullName(defaultSiteLanguage), key: defaultSiteLanguage },
    });
  }, [userLanguageTag]);

  return state;
};
