/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// selectors
import { useDataSelectors } from 'src/@operations/reducers/dataReducer';
import { useTagDataContext } from 'src/app/reducers/tagDataReducer';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import { MapControl, GeocoderPin, RulerTool, Button, StyledMap } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { LocationPopup, AMSLPopup, NMTPopup, OperationMapTags } from 'src/components';
// functions
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useDatesDataForMap,
  useMapConfig,
} from 'src/app/functions/map';
import { defineBbox } from 'src/app/functions/bbox';
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useOperationsDataInMap, useFilterMap } from 'src/@operations/functions/map';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useFilterSelectors } from 'src/@operations/reducers';
// actions
import { setToPcaFilter } from 'src/@operations/actions';
// context
import { OperationDispatchContext } from 'src/@operations/providers/OperationsStateProvider';
// functions
import {
  flyTo,
  useHoverOnMapElement,
  useMapClick,
  useMapHover,
  useGeocoderPinAlternative,
  toggleMapSourcesVisibility,
  getMarqueeSelectFeatures,
} from 'src/utils/mapHelpers/mapHelpers';

import {
  vectorLayerToPoint,
  fetchTagOperationData,
  profileTimeToMapVectorLayer,
} from 'src/utils/mapTagHelpers';

import {
  getStoredMarkers,
  useGeocodePosition,
  goToSelectedAddress,
  addPinToCentre,
  onGeocodingDragEnd,
} from 'src/utils/geocoding';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
// constants
import {
  DEFAULT_BBOX_DISTANCE,
  OPERATIONS,
  TRACKS,
  MAP_TYPES,
  ZOOM_SELECTION_TOLERANCE_LOW,
  MONITOR_LOCATIONS,
} from 'src/constants';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { TrackDensityLoadingScreen } from 'src/components/TrackDensityLoadingScreen';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';

// ts
import { IBounds, ITrackInteractionPoint } from 'src/utils/interfaces';
import { useMapRuler, useHoveredPointData } from 'src/utils';
// context
import { OperationStateContext } from 'src/@operations/providers/OperationsStateProvider';
import { MapLegend } from 'src/components/MapLegend';
import { ProfileGraph } from 'src/components/ProfileGraph';
import { TrackProfileDialog } from '../components';
import { ProfileGraphButton } from './MapContainer.styles';

import { useSelectedOperationsInTable } from '../hooks/useSelectedOperationsInTable';
import { useTrackSelection } from 'src/utils/mapHelpers/trackHelpers/useTrackSelection';
import { useIsDynamicTileServer } from 'src/utils/mapHelpers/hooks/useIsDynamicTileServer';
import { useMapTags } from 'src/utils/mapHelpers/tags/useMapTags';
import { useTileLayers } from '../hooks/useTrackTileLayers';
import { useIsColorByAltitudeEnabled } from 'src/utils/mapHelpers/hooks/useIsColorByAltitudeEnabled';
import { useGetDeselectedInTable } from '../hooks/useGetDeselectedInTable';
import { useScreenLoadStateContext } from 'src/app/providers/ScreenLoadStateContext';

export const MapContainer: FC = () => {
  const client = useApolloClient();
  const dispatcher: any = useContext(OperationDispatchContext);

  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    mapTrackStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    isUsingTracks,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
    storeSelectedMapTrackStyle,
    storeSelectedTrackDensitySize,
    applyMapTrackStyle,
    updateTrackDensityInUserConfig,
    lockedMapTrackStyle,
    mapHasInteractiveLayer,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [TRACKS],
    mapTrackStyle,
  });

  const [isGeneratingTrackDensity, setIsGeneratingTrackDensity] = useState<boolean>(false);
  const [trackDensityId, setTrackDensityId] = useState<string>(null);
  const [trackDensitySizeTemp, setTrackDensitySizeTemp] = useState<string>(null); // This one is updated as soon as the user clicks a thumbnail
  const [trackDensitySizeFinal, setTrackDensitySizeFinal] = useState<string>(null); // This one is updated only when the user closes the Map Settings modal
  const [isTrackDensityCheckRequired, setIsTrackDensityCheckRequired] = useState<boolean>(false);
  const [trackDensityMaxCellCount, setTrackDensityMaxCellCount] = useState<number>(null);

  const setTrackDensitySizeAndTriggerValidation = () => {
    if (trackDensitySizeTemp) {
      setTrackDensitySizeFinal(trackDensitySizeTemp);
    }
    setIsTrackDensityCheckRequired(true);
  };

  // used for taking screenshot of map
  const captureRef = useRef(null);
  const [mapNode, mapRef] = useMapRef();
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  const [viewport, setViewport] = useState(viewportFromProps);
  const mapBoxConfig = useMapConfig();
  const configSelectors = useConfigSelectors();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();
  const trackDensitySize = configSelectors.getTrackDensitySize();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    abbreviations,
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        ruler: ruler,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();
  const filterSelectors = useFilterSelectors();
  const selectedFilters = filterSelectors.getRegularFilters();
  const isFilterApplied = filterSelectors.getIfFilterApplied();
  const isPca = filterSelectors.getIfUsingPcaFilter();
  const timeFilter = filterSelectors.getTimeValues();
  const dataSelectors = useDataSelectors();
  const { isLoading, selectedInTable } = dataSelectors.getDataInformation();
  const requiredDataForMap = dataSelectors.getRequiredDataForMap();

  // Filter date range
  const { from: filterDateFrom, to: filterDateTo } = dateRangeStore.getDateFilters();

  // get layers for styling
  const { layers, opstypeFilter } = useFilterMap({
    mapApis,
    mapBoxConfig,
    selectedFilters,
    timeFilter,
    isFilterApplied,
  });

  // get values when dates change
  const { datesArray, dateRangeMapping } = useDatesDataForMap(
    mapApis,
    mapBoxConfig,
    layers,
    !isUsingTracks
  );
  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 4000);
  const { mapApiStartCursor, areTilesLoaded } = useTileLayers(
    mapApis,
    mapStyle,
    lockedMapTrackStyle,
    isUsingTracks
  );

  const { selectedOperationsInTable, setSelectedInTableCallback } = useSelectedOperationsInTable(
    mapApis,
    datesArray,
    rerunHook
  );
  const deselectedTracksInTable = useGetDeselectedInTable();
  const [deselectedInMap, setDeselectedInMap] = useState<number[]>([]);
  const externallyDeselectedTracks = useMemo(
    () =>
      selectedInTable.length > 1
        ? deselectedTracksInTable
        : [...deselectedTracksInTable, ...deselectedInMap],
    [deselectedTracksInTable, deselectedInMap, selectedInTable]
  );

  const { setSelectedOperations, selectedOperations } = useTrackSelection({
    mapApis,
    externallySelectedTracks: selectedOperationsInTable,
    rerunHook,
    externallyDeselectedTrackIds: externallyDeselectedTracks,
    externallySelectTrack: setSelectedInTableCallback,
  });

  const isDynamicTileServer = useIsDynamicTileServer();

  const [areMapControlsActive, setAreMapControlsActive] = useState(false);
  const selectedTrackTheme = configSelectors.getTheme('operations');

  const {
    displayedMapTags,
    addTagToMap,
    clearDisplayedTags,
    removeDisplayedTag,
    addTagOnTrackClick,
  } = useMapTags();
  useEffect(() => {
    clearDisplayedTags();
  }, [layersDisplayed, mapStyle, isLoading]);

  if (isDynamicTileServer) {
    // Toggle visibility of tile layer for dynamic server
    useEffect(() => {
      toggleMapSourcesVisibility({ mapApis, prefix: 'trackLayer_', hide: !isUsingTracks });
    }, [isUsingTracks]);
  }

  const [layerCount, setLayerCount] = useState<number>(0);
  if (mapApis) {
    try {
      const layers = mapApis.getStyle().layers;
      if (layers.length !== layerCount) {
        setLayerCount(layers.length);
      }
    } catch {
      console.error(typeof mapApis.getStyle);
    }
  }

  useOperationsDataInMap({
    mapApis,
    dateRangeMapping,
    datesArray,
    mapBoxConfig,
    requiredDataForMap,
    selectedOperations,
    opstypeFilter,
    viewport,
    layerCount,
    disableTracks: !isUsingTracks,
  });

  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const labels = Object.assign(opsFields, abbreviations);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: opstypeFilter,
    restrictZoomLevels: true,
    layerPrefix: 'tracks_background_',
    radius: ZOOM_SELECTION_TOLERANCE_LOW,
    disabled: !isUsingTracks,
    mapType: MAP_TYPES.OPERATIONDETAILS,
  });

  const [, setShowSelected] = useState(false);
  const storedMarkers = getStoredMarkers();
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>(
    storedMarkers ? storedMarkers.main : { longitude: 0, latitude: 0 }
  );
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [drag, updateDragStatus] = useState<boolean>(false);

  // geocoder bbox
  const [bbox, updateBbox] = useState<number[]>([]);
  useEffect(() => {
    // define the geocoder bbox at the start
    updateBbox(defineBbox(viewport, mapBoxConfig.geocoderBbox, DEFAULT_BBOX_DISTANCE));
  }, []);

  // Set max bounds for pan
  // react-map-gl doesn't support setMaxBound yet so we handle it manually
  const onViewportChange = viewport => {
    if (viewport.longitude < bbox[0]) {
      viewport.longitude = bbox[0];
    } else if (viewport.longitude > bbox[2]) {
      viewport.longitude = bbox[2];
    }
    if (viewport.latitude < bbox[1]) {
      viewport.latitude = bbox[1];
    } else if (viewport.latitude > bbox[3]) {
      viewport.latitude = bbox[3];
    }
    if (viewport) {
      setViewport(viewport);
    }
  };

  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);
  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  const onOperationClick = selectedOperation => {
    addTagOnTrackClick(selectedOperation);
    if (selectedOperation.length) {
      const operation = selectedOperation[0];
      const operationId = isDynamicTileServer ? operation.properties.id : operation.id;
      setSelectedInTableCallback([operationId]);
      setDeselectedInMap([operationId]);
    } else {
      setDeselectedInMap([]);
      setSelectedOperations([]);
    }
  };

  const { handleClick } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: opstypeFilter,
    selectedOperations,
    setSelectedOperations: onOperationClick,
    clearDisplayedTags,
    layerPrefix: 'tracks_background_',
    disabled: !mapHasInteractiveLayer,
    mapType: MAP_TYPES.OPERATIONDETAILS,
  });

  const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude]],
  });

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // Ruler Tool
  const units = configSelectors.getUnits();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const [selectedBounds, setSelectedBounds] = useState<IBounds | undefined>();

  useEffect(() => {
    if (selectedBounds) {
      let trackLayers = [];
      if (isDynamicTileServer) {
        mapApis.getStyle().layers.forEach(({ id }) => {
          if (id.includes('trackLayer_')) {
            trackLayers.push(id);
          }
        });
      } else {
        trackLayers = datesArray.map(date => `${mapBoxConfig.backgroundLayerPrefix}${date}`);
      }
      getMarqueeSelectFeatures({
        mapApis,
        bounds: selectedBounds,
        setSelectedOperations,
        setShowSelected,
        trackLayers,
        isDynamic: isDynamicTileServer,
        tracksFilter: opstypeFilter,
      });
    }
  }, [selectedBounds, mapStyle]);
  const tagDataState = useTagDataContext(OperationStateContext).getTagData();

  useEffect(() => {
    displayedMapTags.forEach(({ data }) => {
      const { id } = data;
      if (!(id in tagDataState)) {
        fetchTagOperationData(id, dispatcher, client);
      }
    });
  }, [displayedMapTags]);

  // Grabs first selected operation for inline graph
  useEffect(() => {
    if (selectedInTable[0] && !(selectedInTable[0] in tagDataState)) {
      fetchTagOperationData(selectedInTable[0], dispatcher, client);
    }
  }, [selectedInTable]);

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : '28rem';

  const HoveredTag = ({ element }) => {
    const hoveredOperationId = hoveredElement.id || hoveredElement.properties.id;
    if (!tagDataState[hoveredOperationId]) {
      fetchTagOperationData(hoveredOperationId, dispatcher, client);
    }
    const { metadata } = element.layer;

    if (metadata.tagType === OPERATIONS) {
      return (
        <AMSLPopup
          labels={labels}
          pointData={
            tagDataState[hoveredOperationId] && tagDataState[hoveredOperationId].data
              ? vectorLayerToPoint({
                  operation: tagDataState[hoveredOperationId].data,
                  clickedElement: element,
                })
              : element
          }
          draggable={false}
          isLoading={
            tagDataState[hoveredOperationId] ? tagDataState[hoveredOperationId].isLoading : true
          }
        />
      );
    } else if (metadata.tagType === MONITOR_LOCATIONS) {
      const { properties, latitude, longitude } = element;
      return (
        <NMTPopup lat={latitude} lon={longitude} draggable={false}>
          <>
            <p className="amsl-popup_title">{properties.name}</p>
            <p className="amsl-popup_value">{properties.description}</p>
          </>
        </NMTPopup>
      );
    }
  };

  const [isTrackProfileOpen, setIsTrackProfileOpen] = useState(false);
  const [selectedTrack, setSelectedTrack]: any = useState();
  const [markedTime, setMarkedTime] = useState<number | null>(null);
  const [profileHoverTime, setProfileHoverTime] = useState<number | null>(null);
  const [profileClickTime, setProfileClickTime] = useState<number | null>(null);
  const [selectedPointData, setSelectedPointData] = useState<ITrackInteractionPoint>({
    amsl: null,
    dist: null,
    time: null,
    longitude: null,
    latitude: null,
    type: null,
    showPointData: false,
    flightId: null,
  });

  useHoveredPointData({
    mapApis,
    operation: selectedTrack,
    nearbyFlightsData: null,
    hoveredElement,
    profileHoverTime,
    setSelectedTime: setMarkedTime,
    setSelectedPointData,
    isPlaybackMode: false,
    isPlaybackRunning: false,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  useEffect(() => {
    if (selectedInTable.length) {
      const selectedTagData = tagDataState[selectedInTable[0]];
      if (selectedTagData && !selectedTagData.isLoading) {
        setSelectedTrack(selectedTagData.data);
      }
    } else {
      setSelectedTrack(null);
    }
  }, [selectedInTable, tagDataState]);

  useEffect(() => {
    if (selectedTrack && profileClickTime) {
      const selectedLayers = profileTimeToMapVectorLayer(mapApis, selectedTrack, profileClickTime);
      const selectedLayer = selectedLayers.find(layer => layer.id === selectedOperations[0].id);
      addTagToMap(selectedLayer);
    }
  }, [profileClickTime]);
  const enableColorByAltitudeSelector = useIsColorByAltitudeEnabled();

  // Connect map load state to table load state
  const { screenLoadStates, setScreenLoadStates } = useScreenLoadStateContext();
  const { mapLoadState, tableLoadState } = screenLoadStates;

  useEffect(() => {
    if (areTilesLoaded) {
      // Hack till we can properly onReady map sources etc.
      setTimeout(() => {
        setScreenLoadStates({ type: 'SET_MAP_LOAD_STATE', payload: !areTilesLoaded });
      }, 2000);
    }
  }, [areTilesLoaded]);

  return (
    <div
      className={cx({
        map_wrapper: !isFullScreen,
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        {isTrackProfileOpen && (
          <TrackProfileDialog
            setIsOpen={setIsTrackProfileOpen}
            isOpen={isTrackProfileOpen}
            selectedOperationIds={selectedInTable.map(id => id)}
          />
        )}
        <StyledMap
          onLoad={() => mapLoaded()}
          isGeneratingTrackDensity={isGeneratingTrackDensity}
          trackDensityLoadingScreen={
            <TrackDensityLoadingScreen
              id={trackDensityId}
              setIsGeneratingTrackDensity={setIsGeneratingTrackDensity}
            />
          }
          isLoading={mapLoadState && tableLoadState}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          onClick={isSearchOpen || areMapControlsActive ? undefined : handleClick}
          onHover={isSearchOpen ? undefined : debounce(handleHover, 500)}
          marqueeSelect
          getSelectionBounds={bounds => {
            setSelectedBounds(bounds);
          }}
          height={mapHeight}>
          {displayedMapTags.map(({ data, uuid }) => {
            const { metadata } = data.layer;

            if (metadata.tagType === OPERATIONS) {
              const matchingOperation = tagDataState[data.id];
              if (!matchingOperation) {
                return null;
              }
              return (
                <AMSLPopup
                  labels={labels}
                  pointData={
                    matchingOperation.data
                      ? vectorLayerToPoint({
                          operation: matchingOperation.data,
                          clickedElement: data,
                        })
                      : data
                  }
                  onClose={() => removeDisplayedTag(uuid)}
                  draggable
                  isLoading={matchingOperation.isLoading}
                  key={uuid}
                />
              );
            } else if (metadata.tagType === MONITOR_LOCATIONS) {
              const { properties } = data;
              return (
                <NMTPopup
                  lat={data.latitude}
                  lon={data.longitude}
                  draggable
                  onClose={() => removeDisplayedTag(uuid)}
                  key={uuid}>
                  <>
                    <p className="amsl-popup_title">{properties.name}</p>
                    <p className="amsl-popup_value">{properties.description}</p>
                  </>
                </NMTPopup>
              );
            }
          })}
          {hoveredElement && hoveredElement.latitude && hoveredElement.longitude && (
            <HoveredTag element={hoveredElement} />
          )}

          {isLocationTagOpen && (
            <LocationPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              languageData={{ latLabel, lngLabel, amslLabel, filterLabel }}
              onFilterButtonClick={() =>
                setToPcaFilter(
                  dispatcher,
                  latitude,
                  longitude,
                  elevation,
                  place ||
                    locationAddress ||
                    `${latLabel}: ${latitude.toFixed(6)}, ${lngLabel}: ${longitude.toFixed(6)}`
                )
              }
              isUsingPca={isPca}
              mapApis={mapApis}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
              }}
            />
          )}

          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            mapApis={mapApis}
            addRemoveCircles={addRemoveCircles}
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              removeHovered();
              updateDragStatus(true);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]: number[]) =>
              onGeocodingDragEnd({
                longitude,
                latitude,
                updateDragStatus,
                updateGeocoding,
                updateLocationAddress,
                updateLocationTagOpen,
                addRemoveCircles,
              })
            }
            onMouseEnter={() => {
              removeHovered();
              updateDragStatus(true);
            }}
            onMouseLeave={() => {
              updateDragStatus(false);
            }}
          />

          {enableMapControls && (
            <MapControl
              setMouseOver={setAreMapControlsActive}
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={() =>
                addPinToCentre({
                  updateLocationAddress,
                  geocoding,
                  viewport,
                  updateGeocoding,
                  addRemoveCircles,
                  updateLocationTagOpen,
                })
              }
              onDialogStateChange={isOpen => {
                setIsSearchOpen(isOpen);
                if (isOpen) {
                  removeHovered();
                }
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              translationData={{
                home: backToCenterLabel,
                search: searchLabel,
                addPin: addPinLabel,
                removePin: removePinLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              resetView={resetView}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                  applyMapTrackStyle();
                  updateTrackDensityInUserConfig();
                  setTrackDensitySizeAndTriggerValidation();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    instance={OPERATIONS}
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                      mapTrackStyle: lockedMapTrackStyle,
                      trackDensitySize,
                    }}
                    onUpdate={({
                      selectedBackground,
                      selectedLayers,
                      selectedTrackTheme,
                      selectedTrackDensitySize,
                    }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                      if (typeof selectedTrackTheme !== 'undefined') {
                        storeSelectedMapTrackStyle(selectedTrackTheme);
                      }
                      if (typeof selectedTrackDensitySize !== 'undefined') {
                        storeSelectedTrackDensitySize(selectedTrackDensitySize);
                        setTrackDensitySizeTemp(selectedTrackDensitySize);
                      }
                      clearDisplayedTags();
                    }}
                    enableColorByAltitudeSelector={enableColorByAltitudeSelector}
                  />
                ),
              }}
              modalSize={'large'}
            />
          )}
          {hoveredElement && !profileHoverTime && (
            <OperationMapTags
              element={hoveredElement}
              pointData={selectedPointData}
              labels={labels}
              draggable={false}
            />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            viewport={viewport}
            mapApiStartCursor={mapApiStartCursor}
            trackDensitySize={trackDensitySizeFinal}
            setTrackDensityId={setTrackDensityId}
            isGeneratingTrackDensity={isGeneratingTrackDensity}
            setIsGeneratingTrackDensity={setIsGeneratingTrackDensity}
            isTrackDensityCheckRequired={isTrackDensityCheckRequired}
            setIsTrackDensityCheckRequired={setIsTrackDensityCheckRequired}
            setTrackDensityMaxCellCount={setTrackDensityMaxCellCount}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(filterDateFrom, 'start'),
              dateFilterTo: dateTimeInQueryUTC(filterDateTo, 'end'),
            }}
          />
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
          <MapLegend
            layersDisplayed={layersDisplayed}
            trackStyle={lockedMapTrackStyle}
            trackDensityCellCount={trackDensityMaxCellCount}
            selectedFilters={selectedFilters}
          />
        </StyledMap>
      </div>
      <div className="map-overlay-panel">
        {!!selectedTrack && (
          <ProfileGraph
            data={selectedTrack.profile}
            operationType={selectedTrack.operationType}
            currentTime={0}
            startTime={selectedTrack.startTime}
            endTime={selectedTrack.endTime}
            shouldDisplay={selectedTrack && selectedTrack.profile}
            markedTime={markedTime}
            onHoverCB={setProfileHoverTime}
            selectedTrackTheme={selectedTrackTheme}
            onClick={setProfileClickTime}
          />
        )}
        {!!selectedInTable.length && (
          <ProfileGraphButton>
            <Button
              style="primary"
              size="s"
              className="track-profiles-btn"
              disabled={!selectedInTable.length}
              onClick={() => setIsTrackProfileOpen(true)}>
              Plot Profiles
            </Button>
          </ProfileGraphButton>
        )}
      </div>
    </div>
  );
};
