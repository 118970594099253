import { useContext } from 'react';
import { ISortSelectors, ISortState } from 'src/@infringementsCandidates/props';
import { dataActionTypes } from 'src/@infringementsCandidates/newActionTypes';
import { InfringementStateContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';

export const useSortSelectors: () => ISortSelectors = () => {
  const state: any = useContext(InfringementStateContext);
  const dataState: ISortState = state.sort;

  return useSelectors(dataState, (state: ISortState) => ({
    getIconProps: sortName => {
      if (sortName === 'time' && state.sortArray.length > 1) {
        // do not show icon for time when it is implicit
        return false;
      }
      return findSortObject(state, sortName);
    },
    getSortString: () => {
      const sortArray = state.sortArray;
      const sortArrayLength = sortArray.length;
      let sortString = '';
      if (sortArrayLength) {
        for (let i = sortArrayLength; i--; ) {
          sortString = `${sortString} {field:"${sortArray[i].field}", direction:${
            sortArray[i].direction
          }} ${i ? ',' : ''}  `;
        }
      }
      // Example output
      // [{field: "time", direction: ASC}]
      // OR
      // [{field: "airportId", direction: ASC}, {field: "time", direction: ASC}]
      return `sort: [${sortString}]`;
    },
  }));
};

const initSortArray: any = {
  field: 'time',
  direction: 'ASC',
};

export const sortInitialState: ISortState = {
  sortArray: [Object.assign({}, initSortArray)],
};

const findSortObject = (state, sortName) => {
  return state.sortArray.find(sortString => sortString.field && sortString.field === sortName);
};

export const sortReducer = (state: ISortState, action: any) => {
  switch (action.type) {
    case dataActionTypes.SORT_TABLE:
      const sortName = action.data;

      const sortObject = findSortObject(state, sortName);

      if (sortObject) {
        if (sortName === 'time' && state.sortArray.length === 2) {
          // sort only by time when explicit
          return Object.assign({}, state, {
            sortArray: [initSortArray],
          });
        }

        const newSortArray: any = state.sortArray.map(item => {
          if (item.field === sortName) {
            return Object.assign({}, item, {
              direction: item.direction === 'ASC' ? 'DESC' : 'ASC',
            });
          }
          return item;
        });

        return Object.assign({}, state, {
          sortArray: newSortArray,
        });
      } else {
        const newSortArray: any = [Object.assign({}, initSortArray)];

        newSortArray.push({ field: action.data, direction: 'ASC' });

        return Object.assign({}, state, {
          sortArray: newSortArray,
        });
      }

    default:
      return state;
  }
};
