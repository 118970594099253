import { useConfigSelectors } from 'src/app/reducers';
import {
  INPUT_RETURN_TYPES,
  UNIT_FOOT,
  UNIT_KILOMETER,
  UNIT_METER,
  UNIT_MILE,
} from 'src/constants';
import { getVerticalDistance } from 'src/utils';

export const useUnitConversion = () => {
  const configSelectors = useConfigSelectors();
  // Despite typings, sometimes local settings can send Kilometers or Miles as the unit, so we need to account for this.
  const units = configSelectors.getUnits();
  let verticalDistanceUnit = units.distanceVertical;

  switch (units.distanceVertical as string) {
    case UNIT_KILOMETER:
      verticalDistanceUnit = UNIT_METER;
      break;
    case UNIT_MILE:
      verticalDistanceUnit = UNIT_FOOT;
      break;
  }

  const verticalDistanceBackendUnits = {
    convertTo: UNIT_FOOT as 'ft',
    convertFrom: units.distanceVertical,
  };
  const verticalDistanceDisplayUnits = {
    convertTo: units.distanceVertical,
    convertFrom: UNIT_FOOT as 'ft',
  };

  const convertAltitudeToDisplay = (altitude: number) =>
    getVerticalDistance(altitude, {
      returnValueType: INPUT_RETURN_TYPES.NUMBER,
      ...verticalDistanceDisplayUnits,
    });

  const convertAltitudeToBackEnd = (altitude: number) =>
    getVerticalDistance(altitude, {
      returnValueType: INPUT_RETURN_TYPES.NUMBER,
      ...verticalDistanceBackendUnits,
    });

  return { convertAltitudeToDisplay, convertAltitudeToBackEnd, verticalDistanceUnit };
};
