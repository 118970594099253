import React from 'react';
import { withRouter } from 'react-router';
import { SplitLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@operations/containers/Summary';
// constants
import { OPERATIONS } from 'src/constants';

const OperationsSummaryScreen = ({ match, location }) => {
  const { id } = match.params;

  return (
    <SplitLayout>
      <ContentContainer id={id} path={OPERATIONS} paginationInfo={location.state} />
    </SplitLayout>
  );
};

export const OperationsSummary = withRouter(OperationsSummaryScreen);
