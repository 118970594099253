import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';

// Components
import { Dialog, Button } from '@ems/client-design-system';
import { NoiseScenarioDialog } from './Dialog.styles';

// Contexts
import {
  dialogsStatusContext,
  scenarioFilterDataContext,
  noiseScenarioRecordsContext,
  translationsContext,
} from 'src/@scenarioGeneration/containers/ViewScenario/contexts';

// Functions
import {
  ModifyRecordsDialogForm,
  modifyRecordsFormikOptions,
} from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/helpers';
import { validateScenarioRecord } from 'src/@scenarioGeneration/containers/ViewScenario/helpers';

// Types
import { IAddNoiseScenarioRecordMutationVariables } from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';
import { IAddRecordFormErrors } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/interfaces';

export interface AddRecordDialogProps {
  scenarioId: string;
  addRecordMutation: (options?: { variables: IAddNoiseScenarioRecordMutationVariables }) => void;
  isRecordMutationLoading: boolean;
}

export const AddRecordDialog = ({
  scenarioId,
  addRecordMutation,
  isRecordMutationLoading,
}: AddRecordDialogProps) => {
  // Contexts
  const { isAddRecordOpen, setIsAddRecordOpen } = useContext(dialogsStatusContext);
  const { scenarioFilterData } = useContext(scenarioFilterDataContext);
  const { updateSelectedInTable, updateSelectedTableKeys } = useContext(
    noiseScenarioRecordsContext
  );
  const translations = useContext(translationsContext);

  const addRecordFormikOptions = modifyRecordsFormikOptions({
    validation: values => {
      const { errors: errorStrings } = translations;
      const scenarioErrors: IAddRecordFormErrors = {};
      const stringKeys = ['modelingAircraftId', 'stageLength', 'modelingRouteId'];

      stringKeys.forEach(key => {
        if (!values[key].length) {
          scenarioErrors[key] = errorStrings.noiseScenarioErrorEmptyValue;
        }
      });

      validateScenarioRecord({
        record: values,
        filterData: scenarioFilterData,
        translations,
      }).catch(errors => {
        Object.keys(errors).map(key => {
          scenarioErrors[key] = errors[key];
        });
      });

      return scenarioErrors;
    },
    onSubmitAction: values => {
      updateSelectedInTable([]);
      updateSelectedTableKeys([]);
      addRecordMutation({
        variables: {
          record: {
            scenarioId,
            modelingAircraftId: values.modelingAircraftId,
            modelingRouteId: Number(values.modelingRouteId),
            stageLength: values.stageLength,
            dayCount: Number(values.dayCount),
            eveningCount: Number(values.eveningCount),
            nightCount: Number(values.nightCount),
          },
        },
      });
    },
  });

  const addRecordFormik = useFormik({ ...addRecordFormikOptions });

  useEffect(() => {
    if (!isAddRecordOpen) {
      addRecordFormik.resetForm();
    }
  }, [isAddRecordOpen]);

  return (
    <NoiseScenarioDialog
      isOpen={isAddRecordOpen}
      title={translations.headings.addScenarioRecordHeading}
      isCloseButtonShown
      onClose={() => {
        addRecordFormik.resetForm();
        setIsAddRecordOpen(false);
      }}>
      <Dialog.Body>
        <ModifyRecordsDialogForm
          formik={addRecordFormik}
          scenarioFilterData={scenarioFilterData}
          translations={translations}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.FooterActions>
          <Button
            onClick={() => {
              addRecordFormik.resetForm();
              setIsAddRecordOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            style="primary"
            loading={isRecordMutationLoading}
            disabled={!addRecordFormik.isValid || isRecordMutationLoading}
            onClick={() => {
              addRecordFormik.submitForm();
            }}>
            Update
          </Button>
        </Dialog.FooterActions>
      </Dialog.Footer>
    </NoiseScenarioDialog>
  );
};
