import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes = keyToActionTypes(
  {
    INITIALISE_STORE: null,
    GET_DATA: null,
    SORT_TABLE: null,
    SELECT_ROW: null,
    GET_FIRST_FETCH: null,
    GET_TOTAL_COUNT: null,
    LOAD_MORE: null,
    RESET_DATA: null,
    SELECT_ADDRESS: null,
    UPDATE_SELECTED_FILTER_ITEMS: null,
    CLEAR_SELECTED_FILTER_ITEMS: null,
    UPDATE_RANGE_FILTER: null,
    UPDATE_COMPLAINER_FILTER: null,
    UPDATE_COMPLAINT_ID_FILTER: null,
    INLINE_EDIT_STATUS: null,
    UPDATE_COMPLAINT: null,
  },
  'COMPLAINTS'
);
