// Styling
import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

export const ChangelogModalRowCell = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangelogModalRowCellIcon = styled.button`
  margin-left: 10px;
  color: ${props => (props.disabled ? `${styles.$ui05}` : `${styles.brandDarkened}`)};
  border: none;
  background: none;
  display: flex;
  cursor: ${props => !props.disabled && `pointer`};
`;

export const ChangelogModalRowCellText = styled.div`
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ChangelogModalContentWrapper = styled.div`
  display: flex;
`;

export const ChangelogModalContentIcon = styled.div`
  margin-right: 32px;
  margin-left: 32px;
  display: flex;
  align-items: center;
`;

export const ChangelogModalContentChangeView = styled.div`
  flex-grow: 1;

  & .form-group .form-group__form_field__textarea {
    height: 324px;
    width: 100%;
  }
`;
