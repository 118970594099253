import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
// components
import { Button } from '@ems/client-design-system';
// containers
import { OperationDetailsContainer } from 'src/containers';
// resolvers
import { fetchSingleComplaint } from 'src/@complaints/resolvers';
import { fetchOperationDetails } from 'src/@infringements/resolvers/summaryResolver';
// selectors
import { useLanguageSelectors } from 'src/app/reducers';
// utils
import { getDeployedProductId } from 'src/utils/generics';
// constants
import { COMPLAINTS } from 'src/constants';

export const ComplaintFlightCorrelation = ({ complaintDetails }) => {
  const client = useApolloClient();
  const [noFlightCorrelated, setNoFlightCorrelated] = useState<boolean>(false);
  const [operation, updateOperation] = useState(null);

  // Translation
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      buttons: { addFlight: addFlightBtnText, editFlight: editFlightBtnText },
    },
  } = languageSelector.getLanguage();

  useEffect(() => {
    if (complaintDetails && typeof complaintDetails.id !== 'undefined') {
      setNoFlightCorrelated(false);
      fetchSingleComplaint({ client, id: complaintDetails.id })
        .then(({ operationId }) => {
          if (operationId !== undefined && operationId) {
            fetchOperationDetails(client, operationId)
              .then(({ data }: any) => {
                updateOperation(data);
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            setNoFlightCorrelated(true);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [complaintDetails]);

  if (noFlightCorrelated) {
    return (
      <div className="flight-correlation">
        <div className="edit-flight">
          <Link
            className="link-to-page"
            to={{
              pathname: `/${getDeployedProductId()}/${COMPLAINTS}/${complaintDetails.id}/flight`,
              state: { fromInquiryDetails: true },
            }}>
            <Button style="primary" type="submit">
              {addFlightBtnText}
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return operation ? (
    <div className="flight-correlation">
      <OperationDetailsContainer operation={operation} />
      <div className="edit-flight">
        <Link
          className="link-to-page"
          to={{
            pathname: `/${getDeployedProductId()}/${COMPLAINTS}/${complaintDetails.id}/flight`,
            state: { fromInquiryDetails: true },
          }}>
          <Button style="primary" type="submit">
            {editFlightBtnText}
          </Button>
        </Link>
      </div>
    </div>
  ) : null;
};
