import React from 'react';
import { DateTimeTableField } from 'src/@settings/components/DateTimeTableField';

import { Icons, Tooltip } from '@ems/client-design-system';

import { IAircraftNoiseContour, ITableData } from './interfaces';

// Styles
import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

const DeleteButton = styled.button`
  background: none;
  border: none;
  fill: ${styles.brand01};
  color: ${styles.brand01};
  cursor: pointer;

  &:hover {
    fill: ${styles.brandDarkened};
    color: ${styles.brandDarkened};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${styles.brand01}, 0 0 0 0 transparent;
    border-radius: 3px;
  }
`;

const ContourName = styled.div`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

const ContourNameTooltip = styled(Tooltip)`
  &.bp4-popover2-target {
    display: block;
    width: 100%;
  }
`;

const deleteIcon = (handleDelete: (contourId: number) => void, contourId: number) => {
  const icon = <Icons iconName={'ic-bin'} title={'Remove contour'} size={20} />;
  return <DeleteButton onClick={() => handleDelete(contourId)}>{icon}</DeleteButton>;
};

export const formatTableData = ({
  aircraftNoiseContours,
  handleDelete,
}: {
  aircraftNoiseContours: IAircraftNoiseContour[];
  handleDelete?: (contourId: number) => void;
}): ITableData[] =>
  (aircraftNoiseContours || []).map((contour: IAircraftNoiseContour) => {
    const { name, noiseModelType, createTime, id } = contour;
    return {
      id,
      name: (
        <ContourNameTooltip content={name} hoverOpenDelay={1000} position={`top`}>
          <ContourName>{name}</ContourName>
        </ContourNameTooltip>
      ),
      noiseModelType: noiseModelType || 'Unknown',
      style: 'Chroma',
      createTime: <DateTimeTableField dateTimeValue={createTime} />,
      delete: deleteIcon(handleDelete, id),
      contourData: contour,
    };
  });
