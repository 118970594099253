import React, { FC, useState, useEffect, useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
// providers
import { NoiseEventsDispatchContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
// actions
import {
  updateGroomingFlagFilter,
  clearSelectedItems,
  resetAndFetchData,
} from 'src/@noiseEvents/actions';
import { dataActionTypes } from 'src/@noiseEvents/actionTypes';
// selectors
import { useConfigSelectors, useLanguageSelectors, useFilterDataSelectors } from 'src/app/reducers';
import { useDataSelectors, useSortSelectors, useFilterSelectors } from 'src/@noiseEvents/reducers';
// hoc
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
// containers
import { ExportContainer } from 'src/containers/ExportContainer';
// components
import { SkeletonText, Overlay, Icons, Button, Dropdown } from '@ems/client-design-system';
import { DateFilter, PageHeaderDropdown } from 'src/components';
// props
import { IDropdownItem } from '@ems/client-design-system';
// mutations
import {
  UPDATE_NOISE_EVENTS_CAUSE,
  BREAK_EXISTING_CORRELATION,
  MODIFY_NOISE_EVENTS_TAGS,
} from 'src/@noiseEvents/mutations';
// functions
import { usePermissions } from 'src/app/functions/permissions';
import { translatableTemplate, formatNumber, capitalizeObjectKeys, setTabTitle } from 'src/utils';
import { createSelectItems } from 'src/@noiseEvents/functions';
// props
import { IHeaderContainer } from 'src/@noiseEvents/props';
// constants
import {
  CAUSE_AIRCRAFT,
  CAUSE_COMMUNITY,
  CAUSE_WEATHER,
  CAUSE_EQUIPMENT,
  CAUSE_UNKNOWN,
  NOISEEVENTS,
  IMPLAUSIBLEEVENTS,
} from 'src/constants';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl, isPlayingBack }) => {
  const [operationsMapping, setoperationsMapping]: any = useState({
    operationType: [],
    airportId: [],
    remoteAirportId: [],
    runwayName: [],
    aircraftCategory: [],
    aircraftType: [],
  });

  const pluralizeOperations = {
    operationType: 'operationTypes',
    airportId: 'airportIds',
    remoteAirportId: 'remoteAirportIds',
    runwayName: 'runwayNames',
    aircraftCategory: 'aircraftCategories',
    aircraftType: 'aircraftTypes',
  };

  const client = useApolloClient();
  const dispatcher = useContext<any>(NoiseEventsDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  // permissions
  const { canUpdate } = usePermissions('NoiseEvent');

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    operations: {
      filter: { nullable },
    },
    grid: { resultSize },
  } = configSelectors.getConfig();

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { update, cancel, bulkEdit },
      labels: {
        table: { totalItems },
      },
      lists: { aircraftCategories, operationTypes, classificationTypes, extraFilterValues },
      dropdowns: {
        empty: statusEmpty,
        cause: { label: causeLabel, placeholder: causeMultiple },
      },
      headings: {
        updateNoiseEvent: updateNoiseEventTitle,
        updateNoiseEvents: updateNoiseEventTitlePlural,
        noiseEvents: noiseEventsTitle,
        implausibleEvents: implausibleEventsTitle,
      },
    },
  } = languageSelectors.getLanguage();

  setTabTitle(noiseEventsTitle);

  const primaryHeading = {
    key: NOISEEVENTS,
    label: noiseEventsTitle,
  };
  const secondaryHeading = {
    key: IMPLAUSIBLEEVENTS,
    label: implausibleEventsTitle,
  };
  const pageHeaders = canUpdate ? [primaryHeading, secondaryHeading] : [primaryHeading];
  const switchContext = ({ key }) => {
    if (canUpdate) {
      clearSelectedItems(dispatcher);
    }
    switch (key) {
      case NOISEEVENTS:
        updateGroomingFlagFilter(dispatcher, false);
        break;
      case IMPLAUSIBLEEVENTS:
        updateGroomingFlagFilter(dispatcher, true);
        break;
    }
  };

  const dataSelectors = useDataSelectors();
  const { totalCount, data, selectedInTable: selectedData } = dataSelectors.getDataInformation();
  const selectedCauses = dataSelectors.getCause(selectedData);
  // bulkedit overlay
  const [overlayState, setoverlayState] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IDropdownItem>({
    key: '',
    label: '',
  });
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [placeHolder, setPlaceHolder] = useState(causeMultiple);
  const [updateNoiseEventsCause] = useMutation(UPDATE_NOISE_EVENTS_CAUSE, {
    onCompleted() {
      resetAndFetchData(client, resultSize, dispatcher, sortString, filterString);
    },
  });
  const [updateNoiseEventsCauseToUnknown] = useMutation(BREAK_EXISTING_CORRELATION, {
    onCompleted() {
      resetAndFetchData(client, resultSize, dispatcher, sortString, filterString);
    },
  });
  const [modifyExistingTags] = useMutation(MODIFY_NOISE_EVENTS_TAGS, {
    update() {
      // getNoiseGroomingCount({ client, dispatcher, sortString });
    },
  });

  useEffect(() => {
    const dataItems = {};

    Object.keys(operationsMapping).map(item => {
      Object.assign(dataItems, {
        [item]: createSelectItems(
          operationFilterData[pluralizeOperations[item]],
          item,
          nullable && nullable.includes(pluralizeOperations[item]),
          translationDataList
        ),
      });
    });

    setoperationsMapping(dataItems);
  }, []);

  const DateFilterHoc = withQueryStringUpdater({ Container: DateFilter, updateUrl });

  const translationDataList = capitalizeObjectKeys({
    ...aircraftCategories,
    ...operationTypes,
    ...classificationTypes,
    ...extraFilterValues,
  });

  const statusItems: any[] = [];
  [CAUSE_COMMUNITY, CAUSE_WEATHER, CAUSE_EQUIPMENT, CAUSE_UNKNOWN].map((key: string) => {
    const option = {
      key,
      label: translationDataList[key],
    };
    statusItems.push(option);
  });

  const updateRecords = selectedData => {
    if (selectedData && selectedData.length) {
      modifyExistingTags({
        variables: {
          ids: selectedData,
          tags: [{ name: 'Manual Event Match Review', isCompleted: true }],
        },
      });
      const { key: cause } = selectedItem;
      if (cause === CAUSE_UNKNOWN) {
        updateNoiseEventsCauseToUnknown({
          variables: { ids: selectedData, cause: CAUSE_AIRCRAFT },
        });
      } else {
        updateNoiseEventsCause({
          variables: { ids: selectedData, cause },
        });
      }

      handleOverlayClose();
      dispatcher({ type: dataActionTypes.RESET_DATA });
    }
  };

  const handleOverlayState = (bool: boolean) => {
    setPlaceHolder(causeMultiple);
    const itemsToUpdate: any = [];

    selectedData.map(item => {
      itemsToUpdate.push(data[item]);
    });

    if (selectedCauses.length === 1) {
      // same cause
      if (selectedCauses[0] === null) {
        setPlaceHolder(statusEmpty);
      } else {
        const key = selectedCauses[0];
        setSelectedItem({
          key,
          label: translationDataList[key],
        });
      }
    }

    setoverlayState(bool);
  };

  const handleOverlayClose = () => {
    setSelectedItem({
      key: '',
      label: '',
    });
    setoverlayState(false);
    setbuttonDisabled(true);
  };

  const handleUpdateState = (item: any) => {
    if (selectedItem !== undefined && selectedItem !== null && item.label !== selectedItem.label) {
      setbuttonDisabled(false);
    } else if (selectedItem === undefined || selectedItem === null) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }

    setSelectedItem(item);
  };

  // when no item selected, then action button should be disabled
  const disableBulkEditBtn = selectedData.length >= 1 ? false : true; // selectedData.length < 1 ? true : false
  return (
    <PageHeaderDropdown items={pageHeaders} headerUpdated={switchContext}>
      <SkeletonText loading={typeof totalCount === 'undefined' || totalCount === -1} width="4rem">
        <span className="page-count">
          {totalCount && formatNumber(totalCount)} {totalItems}
        </span>
      </SkeletonText>
      <div className="page-tools">
        <ExportContainer
          source={NOISEEVENTS}
          selectedIds={!isPlayingBack ? selectedData : []}
          tableData={selectedData.map(id => data.get(id) || {})}
        />
        {canUpdate && (
          <Button
            style="primary"
            size="s"
            leftIcon={<Icons iconName={`ic-ui-edit`} size="20" />}
            disabled={disableBulkEditBtn}
            onClick={() => handleOverlayState(!overlayState)}>
            {bulkEdit}
          </Button>
        )}
        <DateFilterHoc />
      </div>
      <Overlay
        openState={overlayState}
        onClose={() => handleOverlayClose()}
        classes={['overlay--bulk-edit']}>
        <div className="overlay_header">
          <h3>
            {selectedData.length > 1
              ? translatableTemplate(updateNoiseEventTitlePlural, {
                  id: 'count',
                  value: selectedData.length,
                })
              : translatableTemplate(updateNoiseEventTitle, {
                  id: 'count',
                  value: selectedData.length,
                })}
          </h3>
          <Button
            size="s"
            style="subtle"
            iconOnly
            onClick={() => handleOverlayClose()}
            aria-label="Close modal"
            className="overlay_close"
            leftIcon={
              <Icons
                iconName="ic-ui-cancel"
                size={16}
                style={{ cursor: 'pointer', fill: '#5a6872' }}
              />
            }
          />
        </div>
        <div className="overlay_content">
          <Dropdown
            label={causeLabel}
            placeholderValue={placeHolder}
            searchItems={statusItems}
            isNullable={false}
            updateSelection={item => handleUpdateState(item)}
            selectedItem={selectedItem}
          />
        </div>
        <div className="overlay_footer">
          <Button onClick={() => handleOverlayClose()} style="subtle" className="bulk-edit_cancel">
            {cancel}
          </Button>
          <Button
            style="primary"
            onClick={() => updateRecords(selectedData)}
            disabled={buttonDisabled}>
            {update}
          </Button>
        </div>
      </Overlay>
    </PageHeaderDropdown>
  );
};
