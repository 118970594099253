import React, { FC } from 'react';
import { IMapLegend } from './interfaces';
import styled from 'styled-components/macro';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// theme
import themeToken from 'src/styles/themes.json';
import { LEGEND, OPERATION, TRACKS, TRACK_DENSITY } from 'src/constants';

// Utils
import { buildAltitudeLegendValues, buildTrackDensityLegendValues } from './mapLegendUtils';

// Colours
import styles from 'src/styles/vars.module.scss';

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 50px;
  background-color: rgba(255, 255, 255, 0.6);
  max-width: 95%;
  overflow: hidden;
`;

const LegendItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  z-index: 2;
  min-width: 150%;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const LegendItemLabel = styled.span`
  margin-right: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const LegendItemKey = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  height: 18px;
  width: 18px;
  margin: 0 4px 0 0;
  border-radius: 3px;
  border: ${props => props.color === '#ffffff' && `1px solid ${styles.ui03}`};
`;

export const MapLegend: FC<IMapLegend> = ({
  layersDisplayed,
  trackStyle = OPERATION,
  trackDensityCellCount,
  selectedFilters,
}) => {
  const languageSelectors = useLanguageSelectors();
  const configSelectors = useConfigSelectors();

  const {
    components: {
      mapLegend: { operationTypes },
    },
  } = languageSelectors.getLanguage();
  const displayLegend = layersDisplayed.indexOf(LEGEND) !== -1;

  const selectedTrackTheme = configSelectors.getTheme('operations');
  const altitudeColorBands = configSelectors.getAltitudeColorBands('operations');
  const units = configSelectors.getUnits();

  const getLegendItems = () => {
    switch (trackStyle) {
      case 'operation':
        if (layersDisplayed.includes(TRACK_DENSITY)) {
          return buildTrackDensityLegendValues(trackDensityCellCount);
        } else if (layersDisplayed.includes(TRACKS)) {
          const legendList = [];
          const iconTheme: { [key: string]: string } = themeToken.operations[selectedTrackTheme];
          const { operationTypes: selectedOperationTypesFromFilter } = selectedFilters;

          Object.keys(iconTheme).forEach(iconName => {
            let isSelected = true;

            if (selectedOperationTypesFromFilter.length) {
              isSelected = selectedOperationTypesFromFilter.some(
                ({ key }) =>
                  key.replace(/\s/g, '').toLowerCase() ===
                  operationTypes[iconName].replace(/\s/g, '').toLowerCase()
              );
            }

            if (isSelected) {
              legendList.push({
                label: operationTypes[iconName],
                key: iconTheme[iconName],
              });
            }
          });
          return legendList;
        }
        return [];

      case 'altitude':
        if (layersDisplayed.includes(TRACK_DENSITY)) {
          return buildTrackDensityLegendValues(trackDensityCellCount);
        } else if (layersDisplayed.includes(TRACKS)) {
          return buildAltitudeLegendValues({ altitudeColorBands, units });
        }
        return [];

      default:
        console.error(`Map legend hasn't been configured for: ${trackStyle}`);
        return [];
    }
  };
  if (displayLegend) {
    const legendItems = getLegendItems();
    return legendItems.length ? (
      <LegendContainer>
        <LegendItemsContainer>
          {legendItems.map((item, index) => (
            <LegendItem key={index}>
              <LegendItemKey color={item.key} />
              <LegendItemLabel>{item.label}</LegendItemLabel>
            </LegendItem>
          ))}
        </LegendItemsContainer>
      </LegendContainer>
    ) : null;
  } else {
    return null;
  }
};
