import gql from 'graphql-tag';
import { operationSummariesQuery } from 'src/@operations/resolvers/operationQuerySchema';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';
import { IOperationFetch } from 'src/@operations/props';

const FETCH_SIZE = 1000;
export const fetchOperationData = ({
  client,
  count = FETCH_SIZE,
  sortString,
  filterString,
  pcaData,
  permissions = {
    infringements: false,
    noiseEvents: false,
    complaints: false,
  },
  cursor,
  featureFlags,
  availableFilters,
}: IOperationFetch) => {
  let subQueryCorrelatedData = '';
  const subQueryInfringements = `
    infringements {
      infringementType
      id
      time
    }
  `;
  const subQueryNoiseEvents = `
    noiseEvents {
      id
      time
    }
  `;
  const subQueryComplaints = `
    complaints {
      id
      time
    }
  `;
  if(permissions.infringements || permissions.noiseEvents || permissions.complaints) {
    subQueryCorrelatedData = `
      correlated {
        ${permissions.infringements ? subQueryInfringements : '' }
        ${permissions.noiseEvents ? subQueryNoiseEvents : '' }
        ${permissions.complaints ? subQueryComplaints : '' }
      }
    `;
  }

  //  When the Operation Tags feature is enabled, fetch the tags.
  //  Note that operation tags are stored as part of all tags, so
  //  we need to specify the returnOnly condition to ensure we
  //  only fetch the operation tags.
  let subQueryOperationTags = '';
  if (featureFlags && featureFlags.operationTags) {
    if (availableFilters) {
      const availableOperationTags: string[] = availableFilters.operationTags;
      if (!availableOperationTags) {
        console.log('ERROR: Operation Tags feature is enabled, but available filters are', availableFilters);
      }
      const returnOnlyClause = availableOperationTags.map(tag => `"${tag}"`).join(',');
      subQueryOperationTags = `tags(returnOnly:[${returnOnlyClause}]) { name }`;
    }
  }

  const operationSummariesSchemaGenerated = operationSummariesQuery.schema
    .replace("{{subQueryCorrelated}}", subQueryCorrelatedData)
    .replace("{{subQueryOperationTags}}", subQueryOperationTags);

  const pcaPosition = pcaData && pcaData.position ? pcaData.position : null;
  const pcaHorizRange = pcaData && pcaData.horizRange ? pcaData.horizRange : null;
  const pcaAltRange = pcaData && pcaData.altRange ? pcaData.altRange : null;
  const defaultPcaPosition = { latitude: 0, longitude: 0 };
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;
    const pcaParam =
      pcaPosition !== null
        ? `pcaFilter: { position: $position, horizontalRange: {start: 0, end:${pcaHorizRange}}, verticalRange: {start: 0, end: ${pcaAltRange}} }`
        : ``;
    const queryParams = `
        startTime: "${dateTimeInQuery(from, 'start')}"
        endTime: "${dateTimeInQuery(to, 'end')}"
        first:${count}
        ${cursorParam}
        ${pcaParam}
      `;
    const GET_OPERATIONS = gql`
    query getOperations ($position: InputPositionType!, $includePca: Boolean!){
      ${operationSummariesQuery.name}(${queryParams} ${sortString} ${filterString}) ${
        operationSummariesSchemaGenerated
      }
    }
    `;
    client
      .query({
        query: GET_OPERATIONS,
        variables: {
          includePca: pcaPosition !== null,
          position: pcaPosition ? pcaPosition : defaultPcaPosition,
        },
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[operationSummariesQuery.name] &&
          response.data[operationSummariesQuery.name].items
        ) {
          const { items: operationsData, pageInfo, totalCount } = response.data[operationSummariesQuery.name];
          resolve({
            data: operationsData,
            selectedDateRange: { from, to },
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
