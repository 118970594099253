import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import strings from 'src/app/strings.json';
// schema
import { translationQuery, unitTestNodeInTranslation } from 'src/app/resolvers/schema';
// functions
import { deepMerge, deepMergeAll } from 'src/utils/objectModifiers';
import { requestNewLanguages } from 'src/app/functions/fetchNewLanguage';
// types
import { ILanguageData, IData, ITranslationsByLanguageTag } from './types/languageTypes';

export const fetchLanguageData = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  languageTag: string
): Promise<string | IData> =>
  new Promise((resolve, reject) => {
    const GET_TRANSLATIONS = gql`
      query {
          ${translationQuery}(languageTag:"${languageTag}")
      }
    `;
    // fetch query
    client
      .query({ query: GET_TRANSLATIONS })
      .then((response: ILanguageData) => {
        if (response && response.data && response.data[translationQuery]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const responseData: IData = response.data || {};
          const data: ITranslationsByLanguageTag = responseData[translationQuery];

          // remove unit testing values inserted to the db
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (data[unitTestNodeInTranslation]) {
            delete data[unitTestNodeInTranslation];
          }
          // start of temporary language testing solution
          // merge local string to the result so for now we have the translation
          // TODO: remove local translation file when we don't need it anymore. All the translation values should come from graphql
          // TODO: load from CDN - this is temporary solution to be able to load a new language from CDN and overwrite the current strings
          requestNewLanguages()
            .then(response => {
              resolve(deepMergeAll([strings, data, response], {}));
            })
            .catch(() => {
              resolve(deepMerge(strings, data, {}));
            });
          // end of temporary language testing solution
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
