import React, { FC, useMemo, useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
// router
import { withRouter } from 'react-router-dom';
// hocs
import { withAvailabilityChecks, withPermissionsCheck } from 'src/app/hocs/withPermissionsCheck';
// formik
import { Formik } from 'formik';
// containers
import { ComplaintFlightCorrelation } from 'src/@complaints/containers';
// components
import {
  CardDecision,
  CommentBox,
  BlockHeader,
  Button,
  displaySuccess,
  Icons,
  ItemsNavigator,
  MultiOptionButton,
  IDropdownItem,
} from '@ems/client-design-system';
// functions
import {
  getComplainerFormData,
  getComplaintFormData,
  getAnonymousComplainerFormData,
  validateFields,
  getMatchString,
  getComplainerFormValues,
  convertDatesToLocale,
  isAddressEqual,
  convertAddressToString,
  isAnonymousComplainer,
  getComplainerDisplayName,
  getComplaintWithDefaultValues,
  isComplainerUpdated,
} from 'src/@complaints/functions';
// actions
import {
  selectAddress,
  updateComplaint as updateComplaintData,
  complainersByPartialMatch,
} from 'src/@complaints/actions';
// context
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
// resolvers
import { getComplainersByPartialMatch, fetchFrequentInquirers } from 'src/@complaints/resolvers';
import { fetchPrivacyAgreement } from 'src/@complaints/resolvers';
// utils
import {
  getDeployedProductId,
  convertToFullName,
  rememberSavedFilters,
  setTabTitle,
} from 'src/utils';
// selectors
import { useLanguageSelectors, useConfigSelectors, useFilterDataSelectors } from 'src/app/reducers';
// constants
import {
  COMPLAINTS,
  PRIMARY_CONTACT,
  EMAIL,
  NOT_PREFERRED_CONTACT,
  SECONDARY_CONTACT,
  MAIL,
  COMPLAINER,
  COMPLAINT,
  COMMENT_STATUS_LOADED,
  COMMENT_STATUS_LOADING,
  COMMENT_STATUS_SAVED,
  ANONYMOUS,
  SAVE,
  SAVE_AND_DUPLICATE,
  COMMENT_MAX_LENGTH,
} from 'src/constants';
// Apollo client
import { useApolloClient } from '@apollo/react-hooks';
// interfaces
import {
  IComplainer,
  IComplaintForm,
  IAddress,
  TLoaded,
  TLoading,
  TSaved,
  ISelectedComplainer,
} from 'src/@complaints/interfaces';
// Containers
import {
  ComplainerForm,
  ComplaintForm,
  ComplainerDetails,
  ComplaintDetails,
} from 'src/@complaints/containers';
import {
  ADD_COMPLAINER,
  ADD_COMPLAINT,
  UPDATE_COMPLAINER,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_OPERATION_CORRELATION,
  ADD_COMMENT,
  UPDATE_COMMENT,
  REMOVE_COMMENT,
} from 'src/@complaints/mutations';
import { SummaryHeader } from 'src/components';
import { ExportContainer } from 'src/containers/ExportContainer';
import { DATA_EXPORT } from 'src/app/featureToggles';
import { goBack } from 'src/app/functions/itemsNavigation';
// utils
import { alert } from 'src/utils';

const ComplaintsForm: FC<IComplaintForm> = ({ history, isViewMode, complaintDetails, paging }) => {
  // Config
  const configSelectors = useConfigSelectors();
  const {
    globals: { privacyEnabled, '12HourFormat': twelveHourFormat, timeZone },
    complaints: {
      formValidations: { createComplaint: createComplaintValidations },
    },
  } = configSelectors.getConfig();

  // Translation
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      labels: columns,
      headings: { updateOrcreateNew: questionToUpdateOrCreate },
      buttons: { updateInquirer: updateInquirerBtnText, createInquirer: createInquirerBtnText },
      lists: { preferredResponseMethodList },
      headings: { comments: commentsBoxTitle },
      buttons: { save, saved, saveAndDuplicate, cancel, saveAndAddFlight },
    },
    screens: {
      complaints: {
        title: goBackTitle,
        create: { titleNew: title },
        errors,
      },
    },
  } = languageSelector.getLanguage();

  setTabTitle(
    isViewMode
      ? `${goBackTitle} - ${getComplainerDisplayName(
          convertToFullName(complaintDetails),
          columns.anonymous
        )}`
      : title
  );

  const { localeTime, localeReportedTime } = convertDatesToLocale(null, null, timeZone);
  const addressObj = {
    streetAddress: '',
    streetAddress2: '',
    city: '',
    state: '',
    postcode: '',
    position: {
      latitude: 0,
      longitude: 0,
      altitude: 0,
    },
  };
  const initValues = {
    title: '',
    firstName: '',
    lastName: '',
    address: {
      ...addressObj,
    },
    email: '',
    preferredContact: '',
    phoneNumbers: [
      {
        number: '',
      },
    ],
    contactTime: localeTime,
    complaint: '',
    incidentTime: localeReportedTime,
    responseRequired: false,
    privacyEnabled: false,
    privacyAgreementId: null,
  };

  const saveComplaintButtonOptions = [
    { label: save, key: SAVE },
    { label: saveAndDuplicate, key: SAVE_AND_DUPLICATE },
  ];

  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [initialValues, updateInitialValues] = useState<object>({
    ...initValues,
  });
  const [complainers, setComplainers] = useState<IComplainer[]>([]);
  const [manualAddress, setManualAddress] = useState<boolean>(false);
  const [addFlightNavigation, setAddFlightNavigation] = useState<boolean>(false);
  const [formType, updateFromType] = useState<null | 'complaint' | 'Complainer'>(null);
  const [isFormEditing, updateIsFormEditing] = useState<boolean>(false);
  const [complaintComments, updateComplaintComment] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [selectedAddressCopy, updateSelectedAddressCopy] = useState<IAddress>(addressObj);
  const [selectedPreferredContact, updateSelectedPreferredContact] = useState<string>('');
  const [commentStatus, updateCommentStatus] = useState<TLoading | TLoaded | TSaved>(
    COMMENT_STATUS_LOADED
  );
  const [selectedSaveButtonOption, updateSelectedSaveButtonOption] = useState<{
    label: string;
    key: string;
  }>(saveComplaintButtonOptions[0]);

  const filterDataSelectors = useFilterDataSelectors();
  const filters = filterDataSelectors.getComplaintsFilterData();
  const disturbanceTypes = filters.disturbanceTypes;

  // Configuration
  const dispatcher = useContext(ComplaintsDispatchContext);
  const client = useApolloClient();

  const [addComplainer] = useMutation(ADD_COMPLAINER, {
    update() {
      displaySuccess({
        message: columns.createComplainerSuccessMessage,
      });
    },
  });

  const [updateComplainerDetails] = useMutation(UPDATE_COMPLAINER, {
    update() {
      displaySuccess({
        message: columns.updateComplainerSuccessMessage,
      });
      if (recordAlreadyExist) {
        resetForm();
      }
    },
  });

  const [addComplaint] = useMutation(ADD_COMPLAINT, {
    update() {
      displaySuccess({
        message: columns.createComplaintSuccessMessage,
      });
    },
  });

  const [updateComplaintDetails] = useMutation(UPDATE_COMPLAINT, {
    update() {
      displaySuccess({
        message: columns.updateComplaintSuccessMessage,
      });
      resetForm();
    },
  });

  const [updateCorrelation] = useMutation(UPDATE_COMPLAINT_OPERATION_CORRELATION, {});
  const [addComment] = useMutation(ADD_COMMENT, {
    update(
      cache,
      {
        data: {
          addComplaintComment: { id, body },
        },
      }
    ) {
      updateComplaintData(
        {
          ...complaintDetails,
          commentId: id,
          comment: body,
        },
        dispatcher
      );
    },
  });
  const [updateComment] = useMutation(UPDATE_COMMENT, {
    update(
      cache,
      {
        data: {
          updateComplaintComment: { id, body },
        },
      }
    ) {
      updateComplaintData(
        {
          ...complaintDetails,
          commentId: id,
          comment: body,
        },
        dispatcher
      );
    },
  });
  const [removeComment] = useMutation(REMOVE_COMMENT, {
    update(cache, { data: { removeComplaintComment } }) {
      if (removeComplaintComment) {
        updateComplaintData(
          {
            ...complaintDetails,
            commentId: null,
            comment: '',
          },
          dispatcher
        );
      }
    },
  });

  useEffect(() => {
    rememberSavedFilters(COMPLAINTS);
  }, []);

  const [activePrivacyAgreement, setPrivacyAgreement] = useState<null | {
    id: number;
    agreement: string;
  }>(null);
  useEffect(() => {
    if (privacyEnabled) {
      fetchPrivacyAgreement({ client })
        .then(response => {
          setPrivacyAgreement(response);
          if (!response) {
            // backend issue. privacy is enabled but there is no active privacy agreement
            alert('failedToGetPrivacyAgreement');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [privacyEnabled]);

  const initPreferredContactItems = [
    {
      key: NOT_PREFERRED_CONTACT,
      label: preferredResponseMethodList.notSet,
    },
    {
      key: PRIMARY_CONTACT,
      label: preferredResponseMethodList.primaryContact,
    },
    {
      key: EMAIL,
      label: preferredResponseMethodList.email,
    },
    {
      key: MAIL,
      label: preferredResponseMethodList.mail,
    },
  ];

  const reasonItems = disturbanceTypes.map(item => ({
    key: item,
    label: item,
  }));

  const [preferredContactItems, setPreferredContactItems] = useState(initPreferredContactItems);
  const [preferredContact, setPreferredContact] = useState<IDropdownItem>(preferredContactItems[0]);
  const [reason, setReason] = useState<{ key: string; label: string }>(reasonItems[3]);

  // TODO: Update this to use withAvailabilityChecks hoc - withPermissionsCheck is redundant
  const CommentBoxHoc = useMemo(
    () => withPermissionsCheck(CommentBox, 'Complainer.Read', 'Complainer.Update'),
    []
  );

  // data export feature
  const DataExportFeature = useMemo(
    () =>
      withAvailabilityChecks(ExportContainer, {
        feature: DATA_EXPORT,
        permissions: 'Export',
      }),
    []
  );

  const createComplainerHandler = (values, inquirerData) => {
    addComplainer({
      variables: {
        complainer: { ...inquirerData },
      },
    }).then(({ data }) => {
      updateSelectedComplainer({ id: data.addComplainer.id, ...inquirerData });
      setIsUpdate(null);
      createComplaint({
        ...values,
        complainerId: data.addComplainer.id,
      });
    });
  };

  const createComplainer = values => {
    if (isAnonymous) {
      getComplainersByPartialMatch({
        client,
        count: 1,
        matchString: `firstName: "${ANONYMOUS}", lastName: "${ANONYMOUS}"`,
      }).then(res => {
        if (res && res.length > 0) {
          const { id } = res[0];
          createComplaint({ ...values, complainerId: id });
        } else {
          const inquirerData = getAnonymousComplainerFormData();
          createComplainerHandler(values, inquirerData);
        }
      });
    } else {
      const inquirerData = getComplainerFormData({
        ...values,
        preferredResponseMethod: preferredContact.key,
      });
      createComplainerHandler(values, inquirerData);
    }
  };

  const createComplaint = values => {
    const inquiryData = getComplaintFormData({
      ...values,
      reason: [reason.key],
    });
    addComplaint({
      variables: {
        complaint:
          privacyEnabled && activePrivacyAgreement
            ? {
                ...inquiryData,
                privacyAgreementId: activePrivacyAgreement.id,
              }
            : {
                ...inquiryData,
              },
      },
    }).then(res => {
      const redirect = () => {
        if (addFlightNavigation) {
          return history.push(
            `/${getDeployedProductId()}/${COMPLAINTS}/${res.data.addAircraftComplaint.id}/flight`
          );
        } else if (selectedSaveButtonOption.key === SAVE) {
          return goBack(`${COMPLAINTS}`, paging, []);
        } else if (selectedSaveButtonOption.key === SAVE_AND_DUPLICATE) {
          window.scrollTo(0, 0);
        }
      };
      // add comment for complaint here
      if (complaintComments) {
        addComment({
          variables: {
            id: res.data.addAircraftComplaint.id,
            comment: {
              body: complaintComments,
            },
          },
        }).then(() => {
          redirect();
        });
      } else {
        redirect();
      }
    });
  };

  const updateComplainer = values => {
    const { complainerId, id } = complaintDetails;
    const inquirerData = getComplainerFormData({
      ...values,
      preferredResponseMethod: preferredContact.key,
    });
    let newComplainerId: number | null = null;
    if (selectedComplainer) {
      newComplainerId = selectedComplainer.id;
    } else {
      newComplainerId = complainerId;
    }
    updateComplainerDetails({
      variables: {
        complainer: { ...inquirerData, id: newComplainerId },
      },
    }).then(() => {
      if (selectedComplainer && selectedComplainer.id) {
        updateComplaintDetails({
          variables: {
            complaint: { id, complainerId: selectedComplainer.id },
          },
        }).then(() => {
          updateSelectedComplainer({ id, ...inquirerData });
          updateSelectedComplainer(null);
        });
      }
      updateComplaintData(
        {
          ...getComplaintWithDefaultValues({ ...inquirerData }),
          complainerId: newComplainerId,
          address: {
            ...inquirerData.address,
            place_name: convertAddressToString(inquirerData.address),
          },
        },
        dispatcher
      );
      const addressEqual = isAddressEqual(complaintDetails.address, inquirerData.address);
      if (!addressEqual || (selectedComplainer && selectedComplainer.id)) {
        updateCorrelation({
          variables: {
            id,
            correlation: {
              operationId: null,
            },
          },
        }).then(() => {
          updateComplaintData({ operationId: null }, dispatcher);
        });
      }
    });
  };

  const updateComplainerAndCreateComplaint = values => {
    if (selectedComplainer) {
      const { id } = selectedComplainer;
      const inquirerData = getComplainerFormData({
        ...values,
        preferredResponseMethod: preferredContact.key,
      });
      const complainerUpdated = isComplainerUpdated(selectedComplainer, { id, ...inquirerData });
      if (complainerUpdated) {
        setIsUpdate(null);
        createComplaint({ ...values, complainerId: id });
      } else {
        updateComplainerDetails({
          variables: {
            complainer: {
              id,
              ...inquirerData,
            },
          },
        }).then(({ data }) => {
          setIsUpdate(null);
          updateSelectedComplainer({ id, ...inquirerData });
          createComplaint({ ...values, complainerId: id });
        });
      }
    }
  };

  const updateComplaint = values => {
    const { id } = complaintDetails;
    const inquiryData = getComplaintFormData({
      ...values,
      reason: reason ? [reason.key] : complaintDetails.disturbanceTypes,
    });
    updateComplaintDetails({
      variables: {
        complaint: {
          id,
          ...inquiryData,
        },
      },
    }).then(() =>
      updateComplaintData(
        {
          ...values,
          disturbanceTypes: reason ? [reason.key] : complaintDetails.disturbanceTypes,
        },
        dispatcher
      )
    );
  };

  const handleSubmit = values => {
    if (isViewMode) {
      if (formType === COMPLAINER) {
        updateComplainer(values);
      } else if (formType === COMPLAINT) {
        updateComplaint(values);
      }
    } else {
      if (selectedComplainer) {
        if (createOrUpdateFlag) {
          // waiting for decision to be made by user
          scrollIntoDecisionCard();
        } else {
          if (isUpdate === false) {
            createComplainer(values);
          } else {
            updateComplainerAndCreateComplaint(values);
          }
        }
      } else {
        createComplainer(values);
      }
    }
  };

  const mapAddress = address => {
    const {
      // autoCompleteAddress, // is the original address string in the list of suggestions
      city,
      country,
      county,
      formattedAddress,
      neighborhood,
      postcode,
      state,
      streetAddress,
      streetName,
      streetNumber,
      position: { latitude, longitude, altitude },
    } = address;

    return {
      city,
      country,
      county,
      place_name: formattedAddress,
      streetName,
      streetNumber,
      position: { longitude, latitude, altitude },
      neighborhood,
      postcode,
      state,
      streetAddress,
      streetAddress2: '',
    };
  };

  const onAddressSelect = (address, { setFieldValue }) => {
    const formatedAddress = mapAddress(address);
    updateSelectedAddressCopy(formatedAddress);
    setFieldValue('address', formatedAddress);
    selectAddress(formatedAddress, dispatcher);
  };

  const toggleManualAddress = ({ setFieldValue }, value) => {
    setManualAddress(value);
    selectAddress({}, dispatcher);
    if (!value) {
      selectAddress(selectedAddressCopy, dispatcher);
      return setFieldValue('address', { ...selectedAddressCopy });
    }
    setFieldValue('address', { ...addressObj });
  };

  const onInquirerAnonymous = ({ setFieldValue }) => {
    setFieldValue('title', '');
    setFieldValue('firstName', '');
    setFieldValue('lastName', '');
    setFieldValue('address', addressObj);
    setFieldValue('phoneNumbers', [{ number: '' }]);
    setFieldValue('email', '');
    setFieldValue('responseRequired', false);
    // reset the decision on create a new record or update existing complainer
    updateSelectedComplainer(null);
    setIsUpdate(null);
    setCreateOrUpdateFlag(false);
    // reset preferred contact method
    setPreferredContact(preferredContactItems[0]);
    // remove map tag
    selectAddress({}, dispatcher);
  };

  const updatePreferredContactItems = () => {
    const preferredContactList = [...preferredContactItems];
    const listItemExist = preferredContactItems.some(reason => reason.key === SECONDARY_CONTACT);
    if (!listItemExist) {
      const newItem = {
        key: SECONDARY_CONTACT,
        label: preferredResponseMethodList.secondaryContact,
      };
      preferredContactList.splice(2, 0, newItem);
      setPreferredContactItems(preferredContactList);
    }
  };

  const removeSecondaryPhonePreferredContact = () => {
    const preferredContactList = preferredContactItems.filter(
      ({ key }) => key !== SECONDARY_CONTACT
    );
    setPreferredContactItems(preferredContactList);
  };

  const onSearchResultsReturned = (res: IComplainer[]) => {
    setComplainers(res);
    setIsLoadingData(false);
  };

  const onComplainerSearchChange = (
    value: string,
    formikFieldName,
    searchType,
    { values, setFieldValue }
  ) => {
    setFieldValue(formikFieldName, value);
    setIsLoadingData(true);
    setComplainers([]);

    const matchString = getMatchString(searchType, {
      phoneNumber: value,
      firstName: formikFieldName === 'firstName' ? value : values.firstName,
      lastName: formikFieldName === 'lastName' ? value : values.lastName,
    });
    if (matchString) {
      complainersByPartialMatch(client, matchString, onSearchResultsReturned);
    }
  };

  // logic for fetching frequent inquirers
  const onFocusComplainerInput = () => {
    setIsLoadingData(true);
    fetchFrequentInquirers({ client }).then(res => {
      setComplainers(res.data);
      setIsLoadingData(false);
    });
  };

  // logic to decide if we need to create a new record or update existing complainer
  const [isUpdate, setIsUpdate] = useState<null | boolean>(null);
  const [selectedComplainer, updateSelectedComplainer] = useState<null | ISelectedComplainer>(null);
  const [createOrUpdateFlag, setCreateOrUpdateFlag] = useState<boolean>(false);
  const decisionCardRef = useRef<HTMLHeadingElement>(null);
  const scrollIntoDecisionCard = () => {
    if (decisionCardRef && decisionCardRef.current) {
      decisionCardRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  };
  const checkToCreateOrUpdateComplainer = () => {
    if (!recordAlreadyExist && selectedComplainer && isUpdate === null) {
      setCreateOrUpdateFlag(true);
    }
  };
  const choices = [
    {
      label: updateInquirerBtnText,
      onSelect: () => {
        setIsUpdate(true);
        setCreateOrUpdateFlag(false);
      },
    },
    {
      label: createInquirerBtnText,
      onSelect: () => {
        setIsUpdate(false);
        setCreateOrUpdateFlag(false);
      },
    },
  ];

  const onComplainerSelect = (item, { setFieldValue }) => {
    const {
      title,
      firstName,
      lastName,
      address,
      email,
      phoneNumbers,
      preferredResponseMethod,
      // comments,
    } = getComplainerFormValues(item);
    if (isAnonymousComplainer({ firstName, lastName })) {
      return setIsAnonymous(true);
    }
    setFieldValue('title', title);
    setFieldValue('firstName', firstName);
    setFieldValue('lastName', lastName);
    setFieldValue('phoneNumbers', phoneNumbers);
    setFieldValue('email', email);
    setFieldValue('address', address);
    // dropdown values
    if (phoneNumbers.length >= 2) {
      updatePreferredContactItems();
    }
    updatePreferredResponseType(preferredResponseMethod);
    updateSelectedPreferredContact(preferredResponseMethod);
    // Switch to automatic address
    updateSelectedAddressCopy(address);
    setManualAddress(false);
    // reset the decision on create a new record or update existing complainer
    const inquirerData = getComplainerFormValues(item);
    delete inquirerData.address.place_name;
    updateSelectedComplainer({ ...inquirerData });
    setIsUpdate(null);
    setCreateOrUpdateFlag(false);
    selectAddress(address, dispatcher);
  };

  const updatePreferredResponseType = (value: string) => {
    if (value === SECONDARY_CONTACT) {
      updatePreferredContactItems();
    }
    const selectedResponseType = preferredContactItems.find(item => item.key === value);
    if (selectedResponseType) {
      setPreferredContact(selectedResponseType);
    }
  };
  const updateDisturbanceType = (value: string) => {
    const selectedReason = reasonItems.find(reason => reason.key === value);
    if (selectedReason) {
      setReason(selectedReason);
    }
  };

  const resetForm = () => {
    updateIsFormEditing(false);
    setManualAddress(false);
    updateFromType(null);
    updateInitialValues({
      ...initValues,
    });
    updateSelectedComplainer(null);
  };

  const toggleForm = (formType: 'complaint' | 'Complainer') => {
    updateInitialValues({
      ...complaintDetails,
    });
    updateFromType(formType);
  };

  // Chaining setting the form to edit mode - so no null values are passed into time picker
  useEffect(() => {
    if (formType !== null) {
      updateIsFormEditing(true);
    }
  }, [formType]);

  const submitElement = ({ handleSubmit }) => (
    <div className="row row-space-between edit-form-buttons">
      <Button style="standard" type="submit" onClick={resetForm}>
        {cancel}
      </Button>
      <Button
        className="primary"
        style="primary"
        type="button"
        onClick={values => {
          handleSubmit(values);
        }}>
        {save}
      </Button>
    </div>
  );

  const submitComplaintComments = useCallback((id, commentId, comment) => {
    const body = comment.trim();

    if (commentId) {
      if (body) {
        // change saved status
        updateCommentStatus(COMMENT_STATUS_LOADING);
        updateComment({
          variables: {
            id,
            comment: {
              id: commentId,
              body,
            },
          },
        }).then(() => {
          updateCommentStatus(COMMENT_STATUS_SAVED);
        });
      } else {
        updateCommentStatus(COMMENT_STATUS_LOADING);
        removeComment({
          variables: {
            id,
            commentId,
          },
        }).then(() => {
          updateCommentStatus(COMMENT_STATUS_SAVED);
        });
      }
    } else {
      if (body) {
        updateCommentStatus(COMMENT_STATUS_LOADING);
        addComment({
          variables: {
            id,
            comment: {
              body,
            },
          },
        }).then(() => {
          updateCommentStatus(COMMENT_STATUS_SAVED);
        });
      }
    }
  }, []);

  const [recordAlreadyExist, setRecordAlreadyExist] = useState<boolean>(false);
  useEffect(() => {
    if (
      complaintDetails !== undefined &&
      typeof complaintDetails.id !== 'undefined' &&
      typeof complaintDetails.complainerId !== 'undefined'
    ) {
      const { firstName, lastName } = complaintDetails;
      if (isAnonymousComplainer({ firstName, lastName })) {
        setIsAnonymous(true);
      }
      setRecordAlreadyExist(true);
    } else {
      setRecordAlreadyExist(false);
    }
    if (complaintDetails && complaintDetails.disturbanceTypes) {
      updateDisturbanceType(complaintDetails.disturbanceTypes[0]);
    }
    if (complaintDetails && complaintDetails.phoneNumbers) {
      const listItemExist = preferredContactItems.some(reason => reason.key === SECONDARY_CONTACT);
      if (complaintDetails.phoneNumbers.length === 2) {
        updatePreferredContactItems();
      } else if (listItemExist && complaintDetails.phoneNumbers.length <= 1) {
        removeSecondaryPhonePreferredContact();
      }
    }
    if (complaintDetails && complaintDetails.address) {
      updateSelectedAddressCopy(complaintDetails.address);
    }
  }, [complaintDetails]);

  useEffect(() => {
    if (complaintDetails && complaintDetails.preferredResponseMethod) {
      updatePreferredResponseType(complaintDetails.preferredResponseMethod);
    }
    if (selectedPreferredContact) {
      updatePreferredResponseType(selectedPreferredContact);
      updateSelectedPreferredContact('');
    }
  }, [complaintDetails, preferredContactItems]);

  return (
    <div className="container-fluid container-fluid--details">
      <div className="container-fluid--inner">
        <SummaryHeader type="navigation">
          <ItemsNavigator languageData={{ goBackTitle }} />
        </SummaryHeader>
        <SummaryHeader type="summary">
          <div className="page-header_title">
            {isViewMode
              ? getComplainerDisplayName(convertToFullName(complaintDetails), columns.anonymous)
              : title}
          </div>
          {isViewMode ? (
            <div className="page-tools-summary noise">
              <DataExportFeature
                source={COMPLAINT}
                selectedIds={[Number(complaintDetails.id)]}
                dropdownWidth={103}
              />
            </div>
          ) : null}
        </SummaryHeader>
        <section className="complaints_layout layout_split">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={values =>
              validateFields({
                values,
                createComplaintValidations,
                translationData: {
                  ...errors,
                },
                recordAlreadyExist,
                privacyEnabled,
                activePrivacyAgreement,
                formType,
                isAnonymous,
              })
            }
            enableReinitialize
            render={(formikProps: any) => (
              <div className="layout_split">
                <div className="layout_split--half">
                  <div className="layout_content">
                    <div>
                      <BlockHeader
                        title={columns.complainer}
                        cta={
                          isViewMode && !isFormEditing && !isAnonymous ? (
                            <div
                              className="btn-subtle-link row align-center edit-container"
                              onClick={() => toggleForm(COMPLAINER)}>
                              <span className="edit-container__text">Edit</span>
                              <Icons iconName="ic-ui-edit" fill="#0b6bf2" size="22" />
                            </div>
                          ) : (
                            ''
                          )
                        }
                      />
                      {isViewMode && formType !== COMPLAINER && (
                        <ComplainerDetails columns={columns} data={complaintDetails} />
                      )}
                      {(!isViewMode || (isFormEditing && formType === COMPLAINER)) && (
                        <>
                          <ComplainerForm
                            formikProps={formikProps}
                            columns={columns}
                            complainers={complainers}
                            onComplainerSearchChange={onComplainerSearchChange}
                            onComplainerSelect={onComplainerSelect}
                            preferredContactItems={preferredContactItems}
                            updatePreferredContactItems={updatePreferredContactItems}
                            preferredContact={preferredContact}
                            setPreferredContact={setPreferredContact}
                            manualAddress={manualAddress}
                            toggleManualAddress={toggleManualAddress}
                            onAddressSelect={onAddressSelect}
                            isAnonymous={isAnonymous}
                            setIsAnonymous={setIsAnonymous}
                            onInquirerAnonymous={onInquirerAnonymous}
                            checkToCreateOrUpdateComplainer={checkToCreateOrUpdateComplainer}
                            recordAlreadyExist={recordAlreadyExist}
                            isLoadingData={isLoadingData}
                            onFocusComplainerInput={onFocusComplainerInput}
                          />
                          {isViewMode && submitElement(formikProps)}
                        </>
                      )}
                    </div>
                    <div className="card_spacer" ref={decisionCardRef}>
                      {createOrUpdateFlag && (
                        <CardDecision question={questionToUpdateOrCreate} choices={choices} />
                      )}
                    </div>
                    <div>
                      <BlockHeader
                        title={columns.complaintHeading}
                        cta={
                          isViewMode && !isFormEditing ? (
                            <div
                              className="btn-subtle-link row align-center edit-container"
                              onClick={() => toggleForm(COMPLAINT)}>
                              <span className="edit-container__text">Edit</span>
                              <Icons iconName="ic-ui-edit" fill="#0b6bf2" size="22" />
                            </div>
                          ) : (
                            ''
                          )
                        }
                      />
                      {isViewMode && formType !== COMPLAINT && (
                        <ComplaintDetails
                          columns={columns}
                          data={complaintDetails}
                          twelveHourFormat={twelveHourFormat}
                          privacyEnabled={privacyEnabled}
                        />
                      )}
                      {(!isViewMode || (isFormEditing && formType === COMPLAINT)) && (
                        <>
                          <ComplaintForm
                            formikProps={formikProps}
                            columns={columns}
                            recordAlreadyExist={recordAlreadyExist}
                            twelveHourFormat={twelveHourFormat}
                            privacyEnabled={privacyEnabled}
                            reasonItems={reasonItems}
                            reason={reason}
                            setReason={setReason}
                            isAnonymous={isAnonymous}
                            disturbanceTypes={complaintDetails && complaintDetails.disturbanceTypes}
                            activePrivacyAgreement={activePrivacyAgreement}
                          />
                          {isViewMode && submitElement(formikProps)}
                        </>
                      )}
                    </div>
                    {!isViewMode && (
                      <div className="row row-space-between">
                        <Button
                          style="standard"
                          type="submit"
                          onClick={() => goBack(`${COMPLAINTS}`, paging, [])}>
                          {cancel}
                        </Button>
                        <div>
                          <MultiOptionButton
                            options={saveComplaintButtonOptions}
                            onSelect={item => {
                              updateSelectedSaveButtonOption(item);
                              formikProps.handleSubmit();
                            }}
                            onClick={() => {
                              if (!formikProps.isSubmitting) {
                                setAddFlightNavigation(false);
                                formikProps.handleSubmit();
                              }
                            }}
                            selectedOption={selectedSaveButtonOption}
                          />

                          {!isAnonymous && (
                            <Button
                              style="primary"
                              type="submit"
                              onClick={() => {
                                setAddFlightNavigation(true);
                                formikProps.handleSubmit();
                              }}>
                              {saveAndAddFlight}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    {isViewMode && !isAnonymous && (
                      <ComplaintFlightCorrelation complaintDetails={complaintDetails} />
                    )}
                  </div>
                </div>
                <div className="layout_split--half">
                  <div className="layout_content comment-container">
                    <CommentBoxHoc
                      readOnly={false}
                      title={commentsBoxTitle}
                      inputText={
                        typeof complaintDetails !== 'undefined' ? complaintDetails.comment : ''
                      }
                      buttonText={save}
                      successButtonText={saved}
                      textLimit={COMMENT_MAX_LENGTH}
                      onChange={text => (!isViewMode ? updateComplaintComment(text) : () => {})}
                      status={commentStatus}
                      hideSubmitButton={isViewMode ? false : true}
                      onSubmit={text =>
                        submitComplaintComments(
                          complaintDetails.id,
                          complaintDetails.commentId,
                          text
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </section>
      </div>
    </div>
  );
};

export const ComplaintsFormContainer = withRouter(ComplaintsForm);
