import React, { FC, useState, useMemo, useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  useHoverOnMapElement,
  useClickOnMapElement,
  useHoveredPointData,
  useMapRuler,
} from 'src/utils';
import { IFlightMapProps, IPosition } from 'src/@complaints/interfaces';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MAP_TYPES } from 'src/constants';
import { useStaticTrackDisplay } from 'src/utils/playback';
import { AMSLPopup, LocationPopup } from 'src/components';
import { useGeocodePosition } from 'src/utils/geocoding';
import { ITrackInteractionPoint } from 'src/utils/interfaces';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

export const AddFlightMap: FC<IFlightMapProps> = ({
  trackData,
  selectedId,
  onSelect,
  complainerPosition,
  canMovePin = false,
  onPinMove,
  onAltitudeChange,
  complaintTime,
  initializePinPosition,
}) => {
  const client = useApolloClient();
  // get map props from config
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // Translation
  const languageSelectors = useLanguageSelectors();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        ruler: ruler,
      },
    },
    abbreviations,
  } = languageSelectors.getLanguage();

  const labels = Object.assign(opsFields, abbreviations);

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Location Pin funcs
  const [locationPopupOpen, setLocationPopupOpen] = useState(false);
  useEffect(() => {
    setLocationPopupOpen(canMovePin);
  }, [canMovePin]);
  const [geocoding, setGeocoding] = useState<IPosition>({ latitude: 0, longitude: 0 });
  useEffect(() => {
    if (complainerPosition) {
      setGeocoding(complainerPosition);
    } else {
      const { longitude, latitude } = viewport;
      initializePinPosition({ longitude, latitude });
      setGeocoding({ longitude, latitude });
    }
  }, [complainerPosition]);

  const onPinDragStart = () => {
    setLocationPopupOpen(false);
  };

  const onPinDragEnd = ([longitude, latitude]: number[]) => {
    onPinMove({ latitude, longitude, altitude: elevation });
    setGeocoding({ longitude, latitude });
    setTimeout(() => {
      setLocationPopupOpen(true);
    }, 1);
  };

  const onGeocoderPinClick = () => {
    setLocationPopupOpen(!locationPopupOpen);
  };

  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  useEffect(() => {
    if (elevation) {
      onAltitudeChange(elevation);
    }
  }, [elevation]);

  // Hover / Click functionality
  const requiredMouseLayers = useMemo(() => ['select-flight-tracks'], []);
  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    radius: 2,
    disabled: false,
    radiusGradient: 0.1,
    mapType: MAP_TYPES.COMPLAINTADDFLIGHT,
  });

  const { handleClick, clickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    mouseFilters,
    false,
    '',
    2,
    0.1
  );

  useEffect(() => {
    if (clickedElement) {
      onSelect(clickedElement.properties.id);
    }
  }, [clickedElement]);

  const [hoveredPointData, setHoveredPointData] = useState<ITrackInteractionPoint>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return trackData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode: false,
    isPlaybackRunning: false,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  const highlightIds = useMemo(() => (selectedId ? [selectedId] : []), [selectedId]);

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 1000);

  useStaticTrackDisplay({ mapApis, dataSources: trackData, highlightIds, rerunHook });

  // Ruler Tool

  const units = configSelectors.getUnits();

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx('map_wrapper', {
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          {enableMapControls && (
            <MapControl
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSearch: false,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              resetView={resetView}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <>
            <GeocoderPin
              latitude={latitude}
              longitude={longitude}
              draggable={canMovePin}
              onClick={onGeocoderPinClick}
              onDragStart={onPinDragStart}
              onDragEnd={onPinDragEnd}
            />
            {locationPopupOpen ? (
              <LocationPopup
                latitude={latitude}
                longitude={longitude}
                address={place}
                elevation={elevation}
                languageData={{ latLabel, lngLabel, amslLabel }}
                isUsingPca={false}
                showFilterButton={false}
                mapApis={mapApis}
                onClose={onGeocoderPinClick}
              />
            ) : null}
          </>
          {hoveredElement && (
            <AMSLPopup labels={labels} pointData={hoveredPointData} draggable={false} />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(new Date(complaintTime), 'start'),
              dateFilterTo: dateTimeInQueryUTC(new Date(complaintTime), 'end'),
            }}
          />
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
