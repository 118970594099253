import { useMemo } from 'react';
import { useTrackLayers } from '../mapLayerHelpers';
import { getLayers } from '../mapApis';

export const useOperationTrackData = (
  mapApis,
  datesArray: string[],
  operationIds: number[],
  rerunHook: boolean = false
) => {
  const trackLayers = useTrackLayers(mapApis, datesArray);
  const renderedLayers = getLayers(mapApis).map(({ id }) => id);
  const filteredTrackLayers = renderedLayers.filter(id => trackLayers.includes(id));

  return useMemo(() => {
    if (mapApis && trackLayers) {
      const allTracks = mapApis.queryRenderedFeatures({
        layers: filteredTrackLayers,
      });
      return allTracks.filter(track => operationIds.includes(track.id));
    }
    return [];
  }, [trackLayers, rerunHook, operationIds]);
};
