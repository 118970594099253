import { useApolloClient } from '@apollo/react-hooks';
import React, { FC, useContext, useEffect } from 'react';
// interfaces
import { IDataContainer } from 'src/@complaints/interfaces';
import { useConfigSelectors } from 'src/app/reducers';
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
import { useSortSelectors, useFilterSelectors } from 'src/@complaints/reducers';
import { getData, initialiseFilterStore } from 'src/@complaints/actions';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { screenScrollToTop } from 'src/utils';

export const DataContainer: FC<IDataContainer> = ({ children, correlatedIds = [] }) => {
  const client = useApolloClient();
  const dispatcher = useContext(ComplaintsDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const isInitialised = filterSelectors.getIfInitialised();

  const configSelectors = useConfigSelectors();

  const {
    grid: { resultSize },
  } = configSelectors.getConfig();

  const { from, to } = dateRangeStore.getDatesStrings();

  useEffect(() => {
    if (isInitialised) {
      getData({ client, sortString, filterString, dispatcher, resultSize, resetData: true });
    }
  }, [sortString, filterString, from, to]);

  useEffect(() => {
    screenScrollToTop();
  }, []);

  useEffect(() => {
    if (isInitialised) {
      getData({ client, sortString, filterString, dispatcher, resultSize, resetData: false });
    } else {
      initialiseFilterStore({ dispatcher, correlatedIds });
    }
  }, [isInitialised]);

  return <>{children}</>;
};
