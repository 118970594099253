import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

// Queries
import {
  GET_NOISE_SCENARIO_RECORDS,
  GET_SCENARIO_FILTER_OPTIONS,
  ADD_NOISE_SCENARIO_RECORD,
  UPDATE_NOISE_SCENARIO_RECORDS,
  GET_SCENARIO_MODEL_TYPE,
  GET_SCENARIO_COUNTS,
} from './queries';

// Reducers and Context
import { useFilterDataSelectors } from 'src/app/reducers';
import {
  scenarioFilterDataContext,
  dialogsStatusContext,
  noiseScenarioRecordsContext,
  translationsContext,
} from './contexts';

// Layout
import { ViewScenarioHeader, ViewScenarioTable } from './components';

// Types
import {
  IGetNoiseScenarioRecordsData,
  IGetScenarioFilterOptionsResponse,
  IGetScenarioFilterOptionsVariables,
  IScenarioFilterObject,
  IGetNoiseScenarioRecordsVariables,
  IAddNoiseScenarioRecordMutationData,
  IAddNoiseScenarioRecordMutationVariables,
  IUpdateNoiseScenarioRecordsMutationData,
  IUpdateNoiseScenarioRecordsMutationVariables,
  INoiseScenario,
  IGetNoiseModelingScenarioModelTypeResponse,
  IGetNoiseModelingScenarioModelTypeVariables,
  IGetNoiseModelingScenarioCountsResponse,
  IGetNoiseModelingScenarioCountsVariables,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';
import { TScenarioModelTypes } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

// Components
import { AddRecordDialog, BulkEditDialog, GenerateScenarioDialog } from './components';
import { displayToast } from '@ems/client-design-system';

// Functions
import { getDeployedProductId } from 'src/utils';
import { viewScenarioTranslations } from './helpers';

// Variables
import { NOISE_MODELING } from 'src/constants';
import {
  scenarioFilterStagesDefault,
  getNoiseScenarioRecordsDefaultVariables,
  getNoiseScenarioRecordsDefultData,
  defaultTableName,
  noiseScenarioTotalDefaults,
} from 'src/@scenarioGeneration/containers/ViewScenario/defaults';

interface ViewScenarioProps {
  scenarioId: string;
}

export const ViewScenarioContainer = ({ scenarioId }: ViewScenarioProps) => {
  const routerHistory = useHistory();

  /* Due to how default routing works '/:id' will attempt to be loaded first
   * if the only allowed routes are noise scenario pages.
   * Catch this and return user to generate page
   */
  if (scenarioId === ':id') {
    console.warn(`Invalid scenario id: '${scenarioId}'`);
    routerHistory.push(`/${getDeployedProductId()}/${NOISE_MODELING}`, {
      invalidScenarioId: false,
    });
    return null;
  }

  // Selectors
  const filterDataSelectors = useFilterDataSelectors();
  const operationFilterData = filterDataSelectors.getOperationsFilterData();

  // States
  const [isBulkEditOpen, setIsBulkEditOpen] = useState(false);
  const [isAddRecordOpen, setIsAddRecordOpen] = useState(false);
  const [isGenerateScenarioOpen, setIsGenerateScenarioOpen] = useState(false);
  const [getNoiseScenarioRecordsVariables, setGetNoiseScenarioRecordsVariables] = useState({
    ...getNoiseScenarioRecordsDefaultVariables,
    scenarioId,
  });
  const [areQueriesLoading, setAreQueriesLoading] = useState(true);
  const [scenarioFilterData, setScenarioFilterData] = useState<IScenarioFilterObject>({
    runwayNames: operationFilterData.runwayNames,
    stages: scenarioFilterStagesDefault,
    operations: operationFilterData.operationTypes,
  });
  const [selectedInTable, updateSelectedInTable] = useState<string[]>([]);
  const [selectedTableKeys, updateSelectedTableKeys] = useState<string[]>([]);
  const [noiseScenarioRecords, setNoiseScenarioRecords] = useState<INoiseScenario[]>();
  const [scenarioModelType, setScenarioModelType] = useState<TScenarioModelTypes>();
  const [scenarioName, setScenarioName] = useState<string>(defaultTableName);
  const [noiseScenarioTotals, setNoiseScenarioTotals] = useState(noiseScenarioTotalDefaults);

  const translationStrings = viewScenarioTranslations();

  const {
    loading: getNoiseScenarioRecordsLoading,
    data: getNoiseScenarioRecordsData,
    fetchMore: getNoiseScenarioRecordsFetchMore,
    refetch: getNoiseScenarioRecordsRefetch,
  } = useQuery<IGetNoiseScenarioRecordsData, IGetNoiseScenarioRecordsVariables>(
    GET_NOISE_SCENARIO_RECORDS,
    {
      variables: { ...getNoiseScenarioRecordsVariables },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onError: () => {
        routerHistory.push(`/${getDeployedProductId()}/${NOISE_MODELING}`, {
          invalidScenarioId: true,
        });
      },
    }
  );

  useEffect(() => {
    if (
      getNoiseScenarioRecordsData &&
      getNoiseScenarioRecordsData.aircraftNoiseModelingScenarioRecordSummaries
    ) {
      setNoiseScenarioRecords(
        getNoiseScenarioRecordsData.aircraftNoiseModelingScenarioRecordSummaries.edges
      );
    }
  }, [getNoiseScenarioRecordsData]);

  const { loading: scenarioFilterDataLoading } = useQuery<
    IGetScenarioFilterOptionsResponse,
    IGetScenarioFilterOptionsVariables
  >(GET_SCENARIO_FILTER_OPTIONS, {
    variables: {
      modelType: scenarioModelType,
    },
    onCompleted: ({ noiseModelingAircraftTypes, aircraftNoiseModelingRoutes }) => {
      setScenarioFilterData({
        ...scenarioFilterData,
        runwayNames: scenarioFilterData.runwayNames.sort((a, b) => a.localeCompare(b)),
        noiseModelingAircraftTypes: noiseModelingAircraftTypes.sort((a, b) =>
          a.aircraftName.localeCompare(b.aircraftName)
        ),
        aircraftNoiseModelingRoutes: aircraftNoiseModelingRoutes.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      });
    },
  });

  const { refetch: getScenarioCountsRefetch } = useQuery<
    IGetNoiseModelingScenarioCountsResponse,
    IGetNoiseModelingScenarioCountsVariables
  >(GET_SCENARIO_COUNTS, {
    variables: {
      id: scenarioId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ aircraftNoiseModelingScenario }) => {
      const { dayCount, nightCount, eveningCount } = aircraftNoiseModelingScenario;

      setNoiseScenarioTotals({
        day: Number(dayCount) || 0,
        evening: Number(eveningCount) || 0,
        night: Number(nightCount) || 0,
      });
    },
  });

  const {} = useQuery<
    IGetNoiseModelingScenarioModelTypeResponse,
    IGetNoiseModelingScenarioModelTypeVariables
  >(GET_SCENARIO_MODEL_TYPE, {
    variables: {
      id: scenarioId,
    },
    onCompleted: ({ aircraftNoiseModelingScenario }) => {
      // Sometimes the id works, but is still an invalid call
      if (!aircraftNoiseModelingScenario) {
        routerHistory.push(`/${getDeployedProductId()}/${NOISE_MODELING}`, {
          invalidScenarioId: true,
        });
      } else {
        const { name, noiseModelType } = aircraftNoiseModelingScenario;
        if (noiseModelType) {
          setScenarioModelType(noiseModelType);
        }
        if (name !== scenarioName) {
          setScenarioName(name);
        }
      }
    },
  });

  const [
    updateNoiseScenarioRecords,
    { loading: updateNoiseScenarioRecordsIsLoading },
  ] = useMutation<
    IUpdateNoiseScenarioRecordsMutationData,
    IUpdateNoiseScenarioRecordsMutationVariables
  >(UPDATE_NOISE_SCENARIO_RECORDS, {
    onCompleted: response => {
      setIsBulkEditOpen(false);
      getScenarioCountsRefetch();
      getNoiseScenarioRecordsRefetch().then(({ data }) => {
        setNoiseScenarioRecords(data.aircraftNoiseModelingScenarioRecordSummaries.edges);
      });
      displayToast({
        message: translationStrings.success.noiseScenarioUpdateSuccess.replace(
          '{count}',
          `${response.updateAircraftNoiseModelingScenarioRecords.length}`
        ),
        intent: 'success',
      });
    },
    onError: error => {
      setIsAddRecordOpen(false);
      console.warn(error);
      displayToast({
        message: translationStrings.errors.noiseScenarioEndpointError,
        intent: 'danger',
      });
    },
  });

  const [addNoiseScenarioRecord, { loading: addRecordIsLoading }] = useMutation<
    IAddNoiseScenarioRecordMutationData,
    IAddNoiseScenarioRecordMutationVariables
  >(ADD_NOISE_SCENARIO_RECORD, {
    onCompleted: () => {
      setIsAddRecordOpen(false);
      getScenarioCountsRefetch();
      getNoiseScenarioRecordsRefetch().then(({ data }) => {
        setNoiseScenarioRecords(data.aircraftNoiseModelingScenarioRecordSummaries.edges);
      });

      displayToast({
        message: translationStrings.success.noiseScenarioAddRecordSuccess,
        intent: 'success',
      });
    },
    onError: error => {
      setIsAddRecordOpen(false);
      console.warn(error);
      displayToast({
        message: translationStrings.errors.noiseScenarioEndpointError,
        intent: 'danger',
      });
    },
  });

  const {
    aircraftNoiseModelingScenarioRecordSummaries: {
      edges: noiseScenarioEdges,
      pageInfo,
      totalCount,
    },
  } =
    (!getNoiseScenarioRecordsLoading && getNoiseScenarioRecordsData) ||
    getNoiseScenarioRecordsDefultData;

  useEffect(() => {
    if (scenarioFilterDataLoading || getNoiseScenarioRecordsLoading) {
      setAreQueriesLoading(true);
    } else if (!scenarioFilterDataLoading || !getNoiseScenarioRecordsLoading) {
      setAreQueriesLoading(false);
    }
  }, [getNoiseScenarioRecordsLoading, scenarioFilterDataLoading]);

  return (
    <scenarioFilterDataContext.Provider value={{ scenarioFilterData, setScenarioFilterData }}>
      <noiseScenarioRecordsContext.Provider
        value={{
          noiseScenarioRecords,
          setNoiseScenarioRecords,
          noiseScenarioTotals,
          setNoiseScenarioTotals,
          getNoiseScenarioRecordsVariables,
          setGetNoiseScenarioRecordsVariables,
          selectedInTable,
          updateSelectedInTable,
          selectedTableKeys,
          updateSelectedTableKeys,
        }}>
        <dialogsStatusContext.Provider
          value={{
            isAddRecordOpen,
            setIsAddRecordOpen,
            isBulkEditOpen,
            setIsBulkEditOpen,
            isGenerateScenarioOpen,
            setIsGenerateScenarioOpen,
          }}>
          <translationsContext.Provider value={translationStrings}>
            {isBulkEditOpen && (
              <BulkEditDialog
                updateRecordsMutation={updateNoiseScenarioRecords}
                isRecordMutationLoading={updateNoiseScenarioRecordsIsLoading}
              />
            )}
            {isAddRecordOpen && (
              <AddRecordDialog
                addRecordMutation={addNoiseScenarioRecord}
                isRecordMutationLoading={addRecordIsLoading}
                scenarioId={scenarioId}
              />
            )}
            {isGenerateScenarioOpen && (
              <GenerateScenarioDialog scenarioName={scenarioName} scenarioId={scenarioId} />
            )}

            <div className="container-fluid scenarioGeneration-container">
              <div className="header-container">
                <ViewScenarioHeader
                  scenarioName={scenarioName}
                  isLoading={areQueriesLoading}
                  totalCount={totalCount}
                  scenarioModelType={scenarioModelType}
                />
              </div>
              <ViewScenarioTable
                isLoading={areQueriesLoading}
                tableData={noiseScenarioEdges}
                pageInfo={pageInfo}
                fetchMore={getNoiseScenarioRecordsFetchMore}
                updateRecordsMutation={updateNoiseScenarioRecords}
              />
            </div>
          </translationsContext.Provider>
        </dialogsStatusContext.Provider>
      </noiseScenarioRecordsContext.Provider>
    </scenarioFilterDataContext.Provider>
  );
};
