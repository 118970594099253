import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SettingsLayout } from 'src/components/Layout';
import { TaxiPath } from '../containers/TaxiPath/TaxiPath';
import { getDeployedProductId, history } from 'src/utils';

// Reducers
import { useConfigSelectors } from 'src/app/reducers';

// Flags
import { TAXIPATHTOOL as TAXIPATHTOOL_FLAG } from 'src/app/featureToggles';

export const Screen = () => {
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_TAXIPATHTOOL = configSelectors.isFeatureAvailable(TAXIPATHTOOL_FLAG);

  if (!FEATURE_FLAG_TAXIPATHTOOL) {
    history.replace(`/${getDeployedProductId()}/404`);
  }

  return (
    <SettingsLayout>
      <TaxiPath />
    </SettingsLayout>
  );
};

export const TaxiPathScreen = withRouteProps(Screen);
