import gql from 'graphql-tag';

export const ADD_CURFEW_INFRINGEMENT_RULE = gql`
  mutation addCurfewInfringementRule($rule: InputCurfewInfringementRule!) {
    addCurfewInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
    }
  }
`;

export const UPDATE_CURFEW_INFRINGEMENT_RULE = gql`
  mutation updateCurfewInfringementRule($rule: InputCurfewInfringementRule!) {
    updateCurfewInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
    }
  }
`;

export const ADD_GATE_INFRINGEMENT_RULE = gql`
  mutation addGateInfringementRule($rule: InputGateInfringementRule!) {
    addGateInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on GateInfringementRule {
        gateCriteria {
          gateId
          gateDirections
          intersectionMode
          altitudeRange {
            start
            end
          }
          speedRange {
            start
            end
          }
        }
      }
    }
  }
`;

export const UPDATE_GATE_INFRINGEMENT_RULE = gql`
  mutation updateGateInfringementRule($rule: InputGateInfringementRule!) {
    updateGateInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on GateInfringementRule {
        gateCriteria {
          gateId
          gateDirections
          intersectionMode
          altitudeRange {
            start
            end
          }
          speedRange {
            start
            end
          }
        }
      }
    }
  }
`;

export const ADD_CORRIDOR_INFRINGEMENT_RULE = gql`
  mutation addCorridorInfringementRule($rule: InputCorridorInfringementRule!) {
    addCorridorInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CorridorInfringementRule {
        corridorId
        pathName
      }
    }
  }
`;

export const UPDATE_CORRIDOR_INFRINGEMENT_RULE = gql`
  mutation updateCorridorInfringementRule($rule: InputCorridorInfringementRule!) {
    updateCorridorInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CorridorInfringementRule {
        corridorId
      }
    }
  }
`;

export const ADD_NOISE_INFRINGEMENT_RULE = gql`
  mutation addNoiseInfringementRule($rule: InputNoiseInfringementRule!) {
    addNoiseInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on NoiseInfringementRule {
        metric
        thresholds {
          locationId
          threshold
        }
      }
    }
  }
`;

export const UPDATE_NOISE_INFRINGEMENT_RULE = gql`
  mutation updateNoiseInfringementRule($rule: InputNoiseInfringementRule!) {
    updateNoiseInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on NoiseInfringementRule {
        metric
        thresholds {
          locationId
          threshold
        }
      }
    }
  }
`;

export const ADD_EXCLUSION_ZONE_INFRINGEMENT_RULE = gql`
  mutation addExclusionInfringementRule($rule: InputExclusionInfringementRule!) {
    addExclusionInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on ExclusionInfringementRule {
        selectionZoneId
      }
    }
  }
`;

export const UPDATE_EXCLUSION_ZONE_INFRINGEMENT_RULE = gql`
  mutation updateExclusionInfringementRule($rule: InputExclusionInfringementRule!) {
    updateExclusionInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on ExclusionInfringementRule {
        selectionZoneId
      }
    }
  }
`;

export const ADD_CCO_INFRINGEMENT_RULE = gql`
  mutation addCcoInfringementRule($rule: InputCcoInfringementRule!) {
    addCcoInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CcoInfringementRule {
        autoInfringementSegmentLength
        ceilingAltitude
        floorAltitude
        maxAltitudeVariation
        maxLevelSegments
        minLevelSegmentLength
        noDescentDuringSegment
      }
    }
  }
`;

export const UPDATE_CCO_INFRINGEMENT_RULE = gql`
  mutation updateCcoInfringementRule($rule: InputCcoInfringementRule!) {
    updateCcoInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CcoInfringementRule {
        autoInfringementSegmentLength
        ceilingAltitude
        floorAltitude
        maxAltitudeVariation
        maxLevelSegments
        minLevelSegmentLength
        noDescentDuringSegment
      }
    }
  }
`;

export const ADD_CDO_INFRINGEMENT_RULE = gql`
  mutation addCdoInfringementRule($rule: InputCdoInfringementRule!) {
    addCdoInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CdoInfringementRule {
        autoInfringementSegmentLength
        ceilingAltitude
        floorAltitude
        maxAltitudeVariation
        maxLevelSegments
        minLevelSegmentLength
        noAscentDuringSegment
      }
    }
  }
`;

export const UPDATE_CDO_INFRINGEMENT_RULE = gql`
  mutation updateCdoInfringementRule($rule: InputCdoInfringementRule!) {
    updateCdoInfringementRule(rule: $rule) {
      id
      name
      description
      isActive
      severity
      infringementType
      candidateFilter {
        aircraftCategories
        aircraftTypes
        airportRunways {
          airportId
          runwayNames
        }
        daysOfWeek
        startTime
        endTime
        lowestMaxTakeOffWeight
        lowestMaxLandingWeight
        operationTypes
        operatorCategories
      }
      ... on CdoInfringementRule {
        autoInfringementSegmentLength
        ceilingAltitude
        floorAltitude
        maxAltitudeVariation
        maxLevelSegments
        minLevelSegmentLength
        noAscentDuringSegment
      }
    }
  }
`;
