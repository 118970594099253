import { useEffect } from 'react';
import { FLY_TO_DURATION, VIEWPORT_OFFSET } from 'src/constants';
import { calculateMapZoom, getCenter, offsetCoordinatesByPixels } from 'src/utils';
import { IPosition } from 'src/utils/interfaces';
import WebMercatorViewport from 'viewport-mercator-project';
import { isValidLatitude, isValidLongitude } from '../Details/Components';
import { LocationItem } from '../interfaces';

const NMT_MAP_OFFSET = VIEWPORT_OFFSET;

export const isValidLatLong = (lat: number, long: number) =>
  !(lat === 0 && long === 0) && isValidLatitude(lat) && isValidLongitude(long);
export const useFlyTo = (
  deviceData: LocationItem[],
  selectedDeviceIds: number[],
  mapApis,
  viewport,
  setViewport
) => {
  useEffect(() => {
    if (!mapApis || !selectedDeviceIds.length || !deviceData.length) {
      return;
    }

    const selectedNmtPositions = deviceData.flatMap(
      ({ deviceId, locationId, position: { longitude, latitude } }) =>
        (selectedDeviceIds.includes(deviceId) || selectedDeviceIds.includes(locationId)) &&
        isValidLatLong(latitude, longitude)
          ? { longitude, latitude }
          : []
    ) as IPosition[];

    const hasInvalidPos = selectedNmtPositions.some(
      pos => !isValidLatitude(pos.latitude) || !isValidLongitude(pos.longitude)
    );

    if (!selectedNmtPositions.length || hasInvalidPos) {
      return;
    }

    const center = getCenter([...selectedNmtPositions]);
    const bounds = {
      longMin: Math.min(...selectedNmtPositions.map(({ longitude }) => longitude)),
      latMin: Math.min(...selectedNmtPositions.map(({ latitude }) => latitude)),
      latMax: Math.max(...selectedNmtPositions.map(({ latitude }) => latitude)),
      longMax: Math.max(...selectedNmtPositions.map(({ longitude }) => longitude)),
    };
    const adjustedMinLongLat = offsetCoordinatesByPixels(
      [bounds.longMin, bounds.latMin],
      NMT_MAP_OFFSET,
      NMT_MAP_OFFSET,
      mapApis
    );

    const adjustedMaxLongLat = offsetCoordinatesByPixels(
      [bounds.longMax, bounds.latMax],
      NMT_MAP_OFFSET,
      NMT_MAP_OFFSET,
      mapApis
    );

    const zoomLevels = {
      max: 12.5,
      min: 8,
    };

    const zoom = calculateMapZoom(adjustedMinLongLat, adjustedMaxLongLat, zoomLevels);
    mapApis.flyTo({
      center,
      zoom,
      duration: FLY_TO_DURATION,
    });

    const oldViewport = new WebMercatorViewport(viewport);
    const newViewport = oldViewport.fitBounds([
      [adjustedMinLongLat.lng, adjustedMinLongLat.lat],
      [adjustedMaxLongLat.lng, adjustedMaxLongLat.lat],
    ]);
    const newViewPort = Object.assign({}, newViewport, {
      zoom,
      longitude: center[0],
      latitude: center[1],
    });
    setTimeout(() => {
      setViewport(newViewPort);
    }, FLY_TO_DURATION);
  }, [selectedDeviceIds, deviceData, mapApis]);
};
