import { IAircraftNoiseModelingConfig } from 'src/app/props';
import { useConfigSelectors } from 'src/app/reducers';
import { DEFAULT_QUERY_FILTER } from '../constants';

export const useSelectedNoiseModel = () => {
  const configSelectors = useConfigSelectors();
  const appConfig = configSelectors.getConfig();
  const licenseConfig: IAircraftNoiseModelingConfig = appConfig.aircraftNoiseModeling;
  return licenseConfig.selectedModel
    ? licenseConfig.selectedModel
    : DEFAULT_QUERY_FILTER.noiseModelTypes[0];
};
