import React, { FC, useEffect, useMemo, useState } from 'react';
import { Radio, Dropdown, FormTextField, FormErrorDisplay } from '@ems/client-design-system';
import { INMTOption, INoiseRuleConfiguratorProps } from './interfaces';
import { UNIT_DECIBEL } from 'src/constants';
import { useLanguageSelectors } from 'src/app/reducers';

export const NoiseRuleConfigurator: FC<INoiseRuleConfiguratorProps> = ({
  nmts,
  onNoiseRuleChange,
  metric,
  isReadOnly,
  selectedNoiseMonitor,
  formErrors,
}) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { form: formStrings },
      },
    },
  } = languageSelectors.getLanguage();
  const [selectedNmt, setSelectedNmt] = useState<INMTOption | null>(null);

  const nmtList = useMemo(
    () =>
      nmts.map(
        nmt =>
          nmt.id === Number(selectedNmt?.key) ? { ...nmt, threshold: selectedNmt.threshold } : nmt,
        [nmts, selectedNmt]
      ),
    [nmts, selectedNmt]
  );

  const nmtOptions: INMTOption[] = useMemo(
    () =>
      nmtList.map(nmt => ({
        key: String(nmt.id),
        label: nmt.name,
        threshold: nmt.threshold,
      })),
    [nmtList]
  );

  const handleChangeNmt = item => {
    if (!!item) {
      onNoiseRuleChange(item, metric);
    }
    setSelectedNmt(item);
  };

  const onThresholdChange = (value: string) => {
    const updatedNmt: INMTOption = Object.assign({}, selectedNmt, {
      threshold: value,
    });

    const indexToUpdate = nmtList.findIndex(nmt => String(nmt.id) === updatedNmt.key);
    if (indexToUpdate !== -1) {
      setSelectedNmt(updatedNmt);
      onNoiseRuleChange(updatedNmt, metric);
    }
  };

  const onMetricChange = evt => {
    if (evt && evt.target && evt.target.value) {
      onNoiseRuleChange(selectedNmt, evt.target.value);
    }
  };

  useEffect(() => {
    if (!selectedNmt) {
      setSelectedNmt(
        selectedNoiseMonitor
          ? nmtOptions.find(option => option.key === String(selectedNoiseMonitor.id))
          : null
      );
    }
  }, [nmtOptions]);

  const threshold = useMemo(() => selectedNmt?.threshold ?? '', [selectedNmt, isReadOnly]);

  const metricOptions = [
    { key: 'LMAX', label: 'Lmax' },
    { key: 'LEQ', label: 'Leq' },
    { key: 'SEL', label: 'SEL' },
    { key: 'EPNL', label: 'EPNL' },
  ];

  return (
    <div className={`infdetailspanel noiseruleconfigurator`}>
      <div className="noiseruleconfigurator-row">
        {isReadOnly ? (
          <FormTextField
            label={formStrings.monitorLocation}
            id={'Monitor Location'}
            value={selectedNmt ? selectedNmt.label : ''}
            isReadOnly={isReadOnly}
          />
        ) : (
          <Dropdown
            label={formStrings.monitorLocation}
            placeholderValue=""
            searchItems={nmtOptions}
            isNullable={false}
            updateSelection={handleChangeNmt}
            selectedItem={selectedNmt}
            disabled={isReadOnly}
          />
        )}
        <FormErrorDisplay
          displayError={!isReadOnly}
          error={formErrors ? formErrors.noiseMonitor : undefined}
        />
      </div>
      <div className="noiseruleconfigurator-row">
        <FormTextField
          label={formStrings.noiseThreshold}
          sideLabelEditMode={UNIT_DECIBEL}
          id={'Noise threshold'}
          inputStyleProps={{ width: '70px' }}
          value={
            !!String(threshold).length && isReadOnly
              ? `${threshold} ${UNIT_DECIBEL}`
              : String(threshold)
          }
          isNumberOnly
          onChange={onThresholdChange}
          isReadOnly={isReadOnly}
        />
        <FormErrorDisplay
          displayError={!isReadOnly}
          error={formErrors ? formErrors.threshold : undefined}
        />
      </div>
      <div className="noiseruleconfigurator-row">
        {isReadOnly ? (
          <FormTextField
            label={''}
            id={'Metric'}
            value={metric ? metric : ''}
            isReadOnly={isReadOnly}
          />
        ) : (
          <>
            {metricOptions.map(option => (
              <Radio
                key={option.key}
                checked={metric === option.key}
                onChange={onMetricChange}
                value={option.key}
                label={option.label}
                disabled={isReadOnly}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
