import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';

// Providers
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { PageHeader, LoadMoreBar, DateFilter } from 'src/components';
import { Icons, SkeletonText, Button, Table, displayToast } from '@ems/client-design-system';

// Variables
import { CHANGELOGS_MODULE, BLANK_STRING_PLACEHOLDER } from 'src/constants';
import { getIdFromTableRowKey } from './functions';

// Functions
import {
  useTableFilter,
  getSelectedIndexesFromKeys,
  formatTableHeaders,
  dateTimeInQuery,
} from 'src/utils';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';

// Types
import { TUpdateUrl } from 'src/app/props';
import { ITableFilterObject } from 'src/components/TableComponents';

import { dateRangeStore } from 'src/app/stores/dateRangeStore';

// Mutations
import { REVERT_AUDITLOG_ENTRIES } from 'src/@settings/mutations';
import { GET_AUDIT_LOG_ENTRY_SUMMARIES_BY_TIMERANGE } from './queries';
import {
  IAuditLogEntrySummariesByTimeRangeResponse,
  IAuditLogEntrySummariesByTimeRangeVariables,
  IUpdateQueryOptions,
  IFetchMoreArgs,
} from './interfaces';
import { ILoadMoreOptions } from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

import { defaultData, defaultQueryVariables, LOAD_MORE_COUNT } from './defaults';
import { useChangelogColumnData } from './hooks/useChangelogColumnData';
import { useChangelogTableData } from './hooks/useChangelogTableData';
import { ISortActionCallback } from 'src/components/TableComponents/TableColumnHeader/interfaces';

export const ChangelogContainer = ({ updateUrl }: { updateUrl: TUpdateUrl }) => {
  // Selectors
  const languageSelector = useLanguageSelectors();
  const dateStore = dateRangeStore.getDateFilters();

  // States
  const [totalCount, setTotalCount] = useState(-1);

  const [tableRowKeys, setTableRowKeys] = useState<string[]>([]);
  const [selectedTableKeys, updateSelectedTableKeys] = useState<string[]>([]);
  const [tableFilterObject, setTableFilterObject] = useState<ITableFilterObject>(null);
  const [selectedInTable, updateSelectedInTable] = useState<number[]>([]);
  const [currentDateRange, setCurrentDateRange] = useState(dateStore);

  const [idsBeingReverted, setIdsBeingReverted] = useState<number[]>([]);

  const [queryVariables, setQueryVariables] = useState<IAuditLogEntrySummariesByTimeRangeVariables>(
    {
      ...defaultQueryVariables,
      startTime: dateTimeInQuery(dateStore.from, 'start'),
      endTime: dateTimeInQuery(dateStore.to, 'end'),
    }
  );

  const { data, loading, fetchMore } = useQuery<
    IAuditLogEntrySummariesByTimeRangeResponse,
    IAuditLogEntrySummariesByTimeRangeVariables
  >(GET_AUDIT_LOG_ENTRY_SUMMARIES_BY_TIMERANGE, {
    variables: {
      ...queryVariables,
    },
  });

  const {
    auditLogEntrySummariesByTimeRange: { pageInfo, edges: auditEntries },
  } = data || defaultData;

  const [confirmTotalCount] = useLazyQuery<
    IAuditLogEntrySummariesByTimeRangeResponse,
    IAuditLogEntrySummariesByTimeRangeVariables
  >(GET_AUDIT_LOG_ENTRY_SUMMARIES_BY_TIMERANGE, {
    onCompleted(response) {
      const { totalCount, pageInfo } = response.auditLogEntrySummariesByTimeRange;
      if (totalCount && pageInfo.startCursor) {
        setTotalCount(totalCount);
      }
    },
  });

  const handleLoadMore = (_client, _dispatcher, options: ILoadMoreOptions) => {
    const fetchMoreArguments: IFetchMoreArgs = {
      variables: {
        ...queryVariables,
        first: LOAD_MORE_COUNT,
        after: options.endCursor as string,
      },
      updateQuery: (
        {
          auditLogEntrySummariesByTimeRange: { edges: previousEdges },
        }: IAuditLogEntrySummariesByTimeRangeResponse,
        {
          fetchMoreResult: {
            auditLogEntrySummariesByTimeRange: {
              pageInfo: newPageInfo,
              edges: newEdges,
              totalCount: newTotalCount,
              __typename,
            },
          },
        }: IUpdateQueryOptions
      ) => ({
        auditLogEntrySummariesByTimeRange: {
          pageInfo: newPageInfo,
          edges: [...previousEdges, ...newEdges],
          totalCount: newTotalCount,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          __typename,
        },
      }),
    };
    void fetchMore(fetchMoreArguments);
  };

  const handleClearSelected = () => {
    updateSelectedInTable([]);
    updateSelectedTableKeys([]);
  };

  // Mutations
  const [revertAuditLogEntry] = useMutation(REVERT_AUDITLOG_ENTRIES, {
    onCompleted: () => {
      displayToast({
        message: `${idsBeingReverted.length} records reverted`,
        intent: 'success',
        key: 'auditRevert-toast',
        timeout: 5000,
      });
      setIdsBeingReverted([]);
    },
    onError: error => {
      displayToast({
        message: `${idsBeingReverted.length} records failed to revert`,
        intent: 'danger',
        key: 'auditRevert-toast',
        timeout: 5000,
      });
      console.warn(error);
      setIdsBeingReverted([]);
    },
  });

  // Datepicker
  const DateFilterHOC = withQueryStringUpdater({ Container: DateFilter, updateUrl });

  // Language Strings
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      hints: { tryChangingFilters },
      labels: {
        table: { endTable },
      },
    },
    screens: {
      settings: {
        notFound: {
          table: { noChangelogsFound },
        },
      },
    },
  } = languageSelector.getLanguage();

  const handleRevert = (ids: number[]) => {
    setIdsBeingReverted(ids);
    revertAuditLogEntry({ variables: { ids } });
  };

  const handleMultipleRevert = () => {
    if (selectedInTable.length) {
      handleRevert(selectedInTable);
    }
  };

  const onSelectRow = (indices: number[]) => {
    const ids: number[] = [];
    const selectedKeys: string[] = [];
    indices.forEach((index: number) => {
      const id = getIdFromTableRowKey(tableRowKeys[index]);
      const matchingRow = auditEntries.find(({ node }) => node.id === id);
      // Prevent select all from selecting disabled rows
      if (matchingRow && matchingRow.node.revertable === 'CanRevert') {
        ids.push(id);
        selectedKeys.push(tableRowKeys[index]);
      }
    });

    updateSelectedInTable(ids);
    updateSelectedTableKeys(selectedKeys);
  };

  // Takes API data and formats it for the table
  const changelogTableData = useChangelogTableData(auditEntries, handleRevert);
  useEffect(() => {
    setTableRowKeys(changelogTableData.map(item => item.tableRowKey));
  }, [changelogTableData]);

  const handleRowSortAction = ({
    sortName,
    sortObject: { field, direction },
  }: ISortActionCallback) => {
    const sortDirection = field === sortName ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    handleClearSelected();
    setQueryVariables({
      ...queryVariables,
      sort: [
        {
          field: sortName,
          direction: sortDirection,
        },
      ],
    });
  };

  const columnData = useChangelogColumnData(changelogTableData);

  const rowHeaders = formatTableHeaders({
    headerItems: columnData,
    sortAction: handleRowSortAction,
    sortObjectSelector: {
      getSortObject: () => queryVariables.sort[0],
    },
    translationData: languageSelector.getLanguage(),
    translationModuleName: CHANGELOGS_MODULE,
    isLoading: loading,
    beforeSort: () => {
      handleClearSelected();
    },
  });

  const setTableFilter = (tableFilters: ITableFilterObject) => {
    const selectedList = {};
    tableFilters.filters.forEach(({ key, selectedItems }) => {
      if (selectedItems.length) {
        selectedList[`${key}s`] = selectedItems.map(field => field.key);
      }
    });

    handleClearSelected();
    setTableFilterObject(tableFilters);
    setQueryVariables({
      ...queryVariables,
      filter: {
        ...selectedList,
      },
    });
  };

  const { tableFilter } = useTableFilter({
    tableColumnData: columnData,
    tableData: changelogTableData,
    isTableLoading: loading,
    tableFilterObject,
    setTableFilterObject: setTableFilter,
    dateFilter: currentDateRange,
    beforeFilter: () => {
      handleClearSelected();
    },
  });

  // Update page data when date range changes
  // Update filters when date range changes
  useEffect(() => {
    if (JSON.stringify(dateStore) !== JSON.stringify(currentDateRange)) {
      setCurrentDateRange(dateStore);
      handleClearSelected();
      setQueryVariables({
        ...defaultQueryVariables,
        startTime: dateTimeInQuery(dateStore.from, 'start'),
        endTime: dateTimeInQuery(dateStore.to, 'end'),
      });
    }
  }, [dateStore]);

  // If data query comes back with an invalid totalCount, request it again from cache
  useEffect(() => {
    if (data && data.auditLogEntrySummariesByTimeRange) {
      const { totalCount } = data.auditLogEntrySummariesByTimeRange;

      if (!loading && totalCount === -1) {
        const { pageInfo } = data.auditLogEntrySummariesByTimeRange;
        confirmTotalCount({
          variables: {
            ...queryVariables,
            first: 1,
            after: pageInfo.startCursor,
          },
        });
      } else {
        setTotalCount(totalCount);
      }
    }
  }, [loading, data]);

  return (
    <div className="settings__full">
      <div className="changelog-settings__container">
        <div className="settings__heading">
          <PageHeader title="Changelog">
            <SkeletonText loading={false} width="4rem">
              <span className="settings__heading-count page-count">{`${
                totalCount < 0 || loading ? BLANK_STRING_PLACEHOLDER : totalCount
              } Total`}</span>
            </SkeletonText>
          </PageHeader>
          <div>
            <Button
              disabled={selectedInTable.length ? false : true}
              className="changelog-settings__revert-button"
              style="primary"
              leftIcon={<Icons iconName={`ic-arrow-return`} title={'revert'} size={18} />}
              onClick={handleMultipleRevert}>
              {'Revert'}
            </Button>
            <DateFilterHOC />
          </div>
        </div>

        <div>
          {tableFilter}
          <Table
            className={`changelog-table`}
            loading={loading}
            rowHeaders={rowHeaders}
            data={changelogTableData}
            wrapperClassName={'feature-wrapper'}
            columns={columnData.map(({ columnName }) => columnName)}
            selectedData={getSelectedIndexesFromKeys(selectedTableKeys, tableRowKeys)}
            gridID="changelog-table"
            onSelectRow={onSelectRow}
            languageData={{
              noDataTitle: noChangelogsFound,
              noDataText: tryChangingFilters,
              endTable,
            }}
            hasEnded={changelogTableData.length && pageInfo && !pageInfo.hasNextPage}
            showDashIfEmpty
          />
          <LoadMoreBar
            isVisible={pageInfo.hasNextPage}
            isLoadingMore={auditEntries.length && loading}
            loadMore={handleLoadMore}
            dispatcher={null}
            sortString={null}
            resultSize={auditEntries.length}
            endCursor={pageInfo && pageInfo.endCursor}
            loadMoreText={loadMoreText}
          />
        </div>
      </div>
    </div>
  );
};
