import React, { useReducer, useContext } from 'react';

// Hooks
import { useRolesSelectors } from 'src/app/reducers';
import { AircraftMappingsContext, aircraftMappingsReducer } from './context';
import { DEFAULT_SORT_ORDER, INITIAL_PAGE_SIZE } from '../constants';

// Types
import { IAircraftMappingState } from '../types';

export const initialState = (canEdit: boolean): IAircraftMappingState => ({
  isLoading: false,
  canEdit,
  error: null,
  data: {
    totalCount: 0,
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: true,
      hasPreviousPage: false,
    },
    edges: [],
  },
  queryVariables: {
    scenarioId: null,
    startTime: '',
    endTime: '',
    sort: [DEFAULT_SORT_ORDER],
    first: INITIAL_PAGE_SIZE,
  },
  ltoMappings: [],
  enrouteMappings: [],
  invalidRows: [],
});

export const AircraftMappingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const rolesSelectors = useRolesSelectors();
  const canEdit = rolesSelectors.hasPermission('AircraftMapping.Update');
  const [state, dispatch] = useReducer(aircraftMappingsReducer, initialState(canEdit));

  return (
    <AircraftMappingsContext.Provider value={{ state, dispatch }}>
      {children}
    </AircraftMappingsContext.Provider>
  );
};

export const useAircraftMappings = () => {
  const context = useContext(AircraftMappingsContext);
  if (!context) {
    throw new Error('useAircraftMappings must be used within a AircraftMappingsProvider');
  }
  return context;
};
