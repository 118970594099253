import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Page contents
import { SpatialFeaturesList } from './SpatialFeaturesList/SpatialFeaturesList';
import { SpatialFeatureSummary } from './SpatialFeatureSummary';
import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import { ISpatialFeature } from 'src/@settings/interfaces';
import { AddSpatialFeature } from './AddSpatialFeature/AddSpatialFeature';
import { useApolloClient } from '@apollo/react-hooks';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import {
  getAllCorridors,
  getAllGates,
  getAllZones,
  getSpatialFeatures,
} from 'src/@settings/actions';

// Variables
import { getDeployedProductId, setTabTitle } from 'src/utils';
import { SETTINGS } from 'src/constants';
import { useLanguageSelectors } from 'src/app/reducers';

export const SpatialFeaturesContainer = (): JSX.Element => {
  const routerHistory = useHistory();
  const spatialFeaturesSelector = useSpatialFeaturesSelector();
  const spatialFeatures = spatialFeaturesSelector.getSpatialFeatures();
  const params: { id: string } = useParams();

  const featureInfo = params.id ? params.id.split('-') : [];

  const [featureType, setFeatureType] = useState<string>(featureInfo[0] || null);
  const [featureId, setFeatureId] = useState<number | null>(Number(featureInfo[1]) || null);
  const [, /* selectedFeature, */ setSelectedFeature] = useState<ISpatialFeature | null>(null);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        title: settingsTitle,
        tabs: { spatialFeatures: spatialFeaturesString },
        infringementRules: { addRule: addRuleString },
      },
    },
  } = languageSelectors.getLanguage();

  // Add feature sets - feature id to negative 1 and selected feature as empty feature object
  const addFeature = () => {
    setFeatureId(-1);
  };

  const cancel = () => {
    setFeatureId(null);
    setFeatureType(null);
  };

  // When feature ID is changed - update container
  useEffect(() => {
    if (featureId) {
      const newSelectedFeature = spatialFeatures.find(
        (feature: ISpatialFeature) =>
          feature.featureId === featureId && feature.featureType === featureType
      );
      if (newSelectedFeature) {
        setSelectedFeature(newSelectedFeature);
      }
    } else {
      setSelectedFeature(null);
    }
  }, [featureId, featureType]);

  useEffect(() => {
    if (featureId === -1) {
      setTabTitle(`${settingsTitle} - ${spatialFeaturesString} - ${addRuleString}`);
    } else if (spatialFeatures.length && featureId) {
      const foundFeature = spatialFeatures.find(
        feature => feature.featureId === featureId && feature.featureType === featureType
      );
      setTabTitle(`${settingsTitle} - ${spatialFeaturesString} - ${foundFeature?.name}`);
    } else {
      setTabTitle(`${settingsTitle} - ${spatialFeaturesString}`);
    }
  }, [featureId, spatialFeatures]);

  // When a feature is added - set the selected feature to the newly added feature
  const onFeatureAdded = (newFeature: ISpatialFeature, featureType: string): void => {
    setFeatureId(newFeature.featureId);
    setFeatureType(featureType);
    routerHistory.push(
      `/${getDeployedProductId()}/${SETTINGS}/spatial-features/${featureType}-${
        newFeature.featureId
      }`
    );
  };

  const onSelectFeature = (featureId: number, featureType: string): void => {
    setFeatureId(featureId);
    setFeatureType(featureType);
  };

  // get all feature data - for name validation
  const client = useApolloClient();
  const settingsDispatcher = useContext<any>(SettingsDispatchContext);

  useEffect(() => {
    getAllGates(client, settingsDispatcher);
    getAllZones(client, settingsDispatcher);
    getAllCorridors(client, settingsDispatcher);
    getSpatialFeatures({ client, dispatcher: settingsDispatcher });
  }, []);

  return (
    <div className="settings__split">
      {featureId === -1 ? (
        <AddSpatialFeature cancelAddFeature={cancel} onFeatureAdded={onFeatureAdded} />
      ) : spatialFeatures.length && featureId ? (
        <SpatialFeatureSummary
          featureId={featureId}
          setFeatureId={setFeatureId}
          selectedFeature={spatialFeatures.find(
            (f: ISpatialFeature) => f.featureId === featureId && f.featureType === featureType
          )}
        />
      ) : (
        <SpatialFeaturesList
          onSelectFeature={onSelectFeature}
          features={spatialFeatures}
          toggleAddFeature={addFeature}
        />
      )}
    </div>
  );
};
