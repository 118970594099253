import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useDataSelectors, useSortSelectors, useFilterSelectors } from 'src/@noiseEvents/reducers';
import { actionTypes } from 'src/@noiseEvents/newActionTypes';
// actions
import { selectRow, loadMore } from 'src/@noiseEvents/actions';
// components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
// mutations
import { UPDATE_NOISE_EVENT_CAUSE, MODIFY_NOISE_EVENTS_TAGS } from 'src/@noiseEvents/mutations';
// function
import { usePermissions } from 'src/app/functions/permissions';
import { capitalizeObjectKeys, insertStyle } from 'src/utils';
import { formatHeaders, formatDisplayData } from 'src/@noiseEvents/functions';
import { convertMapToArray, getSelectedIndexes } from 'src/utils/arrays';
import {
  getNoiseEventsColumnsAndStyle,
  noiseEventsColumnDefinitions,
} from './noiseEventsTableColumns';

// context
import { NoiseEventsDispatchContext } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import { IFetchedData } from 'src/@noiseEvents/props';
// constants
import {
  NULL_VALUE,
  CAUSE_AIRCRAFT,
  CAUSE_COMMUNITY,
  CAUSE_WEATHER,
  CAUSE_EQUIPMENT,
} from 'src/constants';

export const TableContainer: FC = () => {
  const dispatcher = useContext<any>(NoiseEventsDispatchContext);
  const configSelectors = useConfigSelectors();
  const dataSelectors = useDataSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const filterSelectors = useFilterSelectors();
  const filterString = filterSelectors.getFilterString();
  const isGroomingRequired = filterSelectors.getIsGroomingRequiredFilter();
  const [dataIds, setDataIds] = useState<number[]>([]);
  const { canUpdate } = usePermissions('NoiseEvent');
  // Configuration
  const {
    data,
    selectedInTable,
    pageInfo,
    isLoading,
    areAllRowsSelected,
    isLoadingMore,
  } = dataSelectors.getDataInformation();
  const {
    globals: { '12HourFormat': twelveHourFormat },
    grid: { resultSize, loadMoreSize },
    noiseEvents: {
      // grid: { columns },
      filter: { nullable },
    },
  } = configSelectors.getConfig();

  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    abbreviations: headersAbbreviations,
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFiltersOrDate },
      lists: {
        aircraftCategories,
        operationTypes,
        correlatedCategories,
        classificationTypes,
        extraFilterValues,
      },
    },
    screens: {
      noiseEvents: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const selectedTrackTheme = configSelectors.getTheme('operations');

  const [translationDataList, setTranslationDataList] = useState<object[]>([]);
  useEffect(() => {
    setTranslationDataList(
      capitalizeObjectKeys({
        ...correlatedCategories,
        ...aircraftCategories,
        ...classificationTypes,
        ...operationTypes,
        ...extraFilterValues,
      })
    );
  }, [correlatedCategories, aircraftCategories]);

  const loading = isLoading || isLoadingMore;

  // column types are used to add class-names to each column (used only for styling)
  // TODO: this logic needs to be handled in the design system, not here. We'll refactor when the table needs to be modified.
  const {
    runwayName: runwayNameAbbr,
    aircraftCategory: aircraftCategoryAbrr,
    duration: durationAbrr,
  } = headersAbbreviations;
  const columnTypes = {
    displayLocationName: {
      title: 'location-name',
      abbreviation: '',
    },
    duration: {
      title: 'duration',
      abbreviation: durationAbrr,
    },
    displayFlag: {
      title: 'ad-flag',
      abbreviation: '',
    },
    acid: {
      title: 'record-title',
      abbreviation: '',
    },
    displayRunwayName: {
      title: 'runway',
      abbreviation: runwayNameAbbr,
    },
    displayCategory: {
      title: 'aircraft',
      abbreviation: aircraftCategoryAbrr,
    },
    displayTime: {
      title: 'time',
      abbreviation: '',
    },
    isGroomed: {
      title: '',
      abbreviation: '',
    },
  };

  const onSelectRow = (indexes: number[]) => {
    const ids: number[] = [];
    for (const index of indexes) {
      ids.push(dataIds[index]);
    }
    selectRow(ids, dispatcher);
  };
  const [updateNoiseEventCause] = useMutation(UPDATE_NOISE_EVENT_CAUSE, {
    update(cache, { data: { setNoiseEventCause } }) {
      const id = setNoiseEventCause.id;
      const cause = setNoiseEventCause.primaryCause;
      dispatcher({
        type: actionTypes.INLINE_EDIT_CAUSE,
        data: {
          id,
          cause,
        },
      });
    },
  });
  const onUpdateSelection = (id: number, updatedSection: { classification: string }) => {
    const { classification } = updatedSection;
    if ([CAUSE_COMMUNITY, CAUSE_WEATHER, CAUSE_EQUIPMENT].indexOf(classification || '') !== -1) {
      updateNoiseEventCause({
        variables: { id, cause: classification },
      });
    } else {
      updateNoiseEventCause({
        variables: { id, cause: CAUSE_AIRCRAFT },
      });
    }
    modifyExistingTags({
      variables: {
        ids: [id],
        tags: [{ name: 'Manual Event Match Review', isCompleted: true }],
      },
    });
  };

  const [modifyExistingTags] = useMutation(MODIFY_NOISE_EVENTS_TAGS, {
    update(cache, { data: { addNoiseEventTags } }) {
      const [item] = addNoiseEventTags;
      const { id, tags } = item;
      dispatcher({
        type: actionTypes.INLINE_EDIT_GROOMING_TAG,
        data: {
          id,
          isCompleted: tags[0].isCompleted,
        },
      });
    },
  });
  const handleGroomingChange = (isCompleted: boolean, id: number) => {
    if (canUpdate) {
      modifyExistingTags({
        variables: {
          ids: [id],
          tags: [{ name: 'Manual Event Match Review', isCompleted }],
        },
      });
    }
  };

  const [displayData, setDisplayData] = useState<IFetchedData[]>([]);
  useEffect(() => {
    const updatedFilterData = {};
    Object.keys(operationFilterData).map(category => {
      const originalData = [...operationFilterData[category]];
      if (nullable && nullable.includes(category)) {
        updatedFilterData[category] = [NULL_VALUE, ...originalData];
      } else {
        updatedFilterData[category] = [...originalData];
      }
    });

    const { itemsIds, hasNextPage, endCursor, dateRange } = dataSelectors.getNavigationData();
    const requiredInfo = { itemsIds, hasNextPage, endCursor, dateRange };
    const { keys, items } = convertMapToArray(
      formatDisplayData(
        data,
        translationDataList,
        requiredInfo,
        onUpdateSelection,
        canUpdate,
        isGroomingRequired,
        handleGroomingChange,
        twelveHourFormat,
        selectedTrackTheme
      )
    );
    setDataIds(keys);
    setDisplayData(items);
  }, [data, translationDataList, operationFilterData]);

  const rowHeaders = formatHeaders(
    resultSize,
    loading,
    dispatcher,
    sortSelectors,
    languageSelectors.getLanguage()
  );
  const hiddenColumns = {
    isGroomed: !isGroomingRequired,
  };
  const { noiseEventsColumns, styleNoiseEventsGridTemplate } = getNoiseEventsColumnsAndStyle(
    noiseEventsColumnDefinitions,
    hiddenColumns
  );
  insertStyle(styleNoiseEventsGridTemplate, 'styleNoiseEventsGridTemplate');

  return (
    <>
      <Table
        className={!isGroomingRequired ? 'noise-events-table' : 'implausible-events-table'}
        loading={isLoading || isLoadingMore}
        rowHeaders={rowHeaders}
        data={displayData}
        columns={noiseEventsColumns}
        columnTypes={columnTypes}
        selectedData={getSelectedIndexes(selectedInTable, dataIds)}
        areAllRowsSelected={areAllRowsSelected}
        gridID="noiseevents"
        onSelectRow={onSelectRow}
        hasEnded={displayData.length && pageInfo && !pageInfo.hasNextPage}
        languageData={{
          noDataTitle: noDataFound,
          noDataText: tryChangingFiltersOrDate,
          endTable,
        }}
        showDashIfEmpty={false}
      />
      <LoadMoreBar
        isVisible={pageInfo && pageInfo.hasNextPage ? true : false}
        isLoadingMore={isLoadingMore ? true : false}
        loadMore={loadMore}
        dispatcher={dispatcher}
        sortString={sortString}
        filterString={filterString}
        resultSize={loadMoreSize}
        endCursor={pageInfo && pageInfo.endCursor}
        loadMoreText={loadMoreText}
      />
    </>
  );
};
