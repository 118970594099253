// components
import { ColumnHeader } from 'src/components';
// actions
import { sortTable } from 'src/@airtrak/actions';
// interfaces
import { IFilterType, IFilterItem } from 'src/@airtrak/interfaces';
import { IAirtrakFilters } from 'src/app/props';
// constants
import {
  IN_BOUND,
  SHORTCUTS,
  ASC,
  DESC,
  TMA_EFFICIENCY,
  AIRLINE_BREAKDOWN,
  AIRCRAFT_BREAKDOWN,
  COMBINED,
  COMBINED_TMA,
  MIN_CARBON_EMISSION_TABLE_COLUMNS,
  CARBON_EMISSION_TABLE_PER_COLUMN_WIDTH,
  MIN_CARBON_EMISSION_TABLE_WIDTH,
  TABLE,
  ARRIVAL_ENROUTE,
  DEPARTURE_ENROUTE,
} from 'src/constants';
import { emissionMappings } from './mappings';
// helpers
import { letterCaseFormat, stripTrailingComma } from 'src/utils';
import uuid from 'uuid';

export const formatHeaders = (dispatcher: any, sortSelectors: any, columns: any) => {
  const columnHeaders = {};
  const columnHeads: string[] = Object.keys(columns);
  columnHeads.forEach(column => {
    columnHeaders[column] = ColumnHeader({
      sortName: column,
      resultSize: 10,
      isLoading: false,
      dispatcher,
      sortSelectors,
      sortTable,
      languageData: {
        title: columns[column],
        abbreviation: null,
        sortBy: column,
      },
      titleElement: emissionMappings[columns[column]],
    });
  });

  return Object.assign({}, columns, columnHeaders);
};

export const getIfFiltersEmpty = (filters: IFilterType) => {
  const totalFilterLength = Object.values(filters).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );
  return totalFilterLength === 0;
};

export const formatFilterItems = (
  filterItems: string[],
  filterCategory?: keyof IAirtrakFilters
) => {
  if (!(filterItems && filterItems.length)) {
    return [];
  }
  const formattedFilters = [];
  for (const item of filterItems) {
    let labelElement: JSX.Element = null;

    if (filterCategory === 'emissions') {
      labelElement = emissionMappings[item];
    }

    const filterItem: IFilterItem = {
      key: item,
      label: item,
      labelElement,
      icon: '',
      className: '',
      showDivider: item === IN_BOUND ? true : false,
      isDisable: item === SHORTCUTS ? true : false,
    };
    switch (item) {
      case ARRIVAL_ENROUTE:
        filterItem.key = 'Arrival';
        break;
      case DEPARTURE_ENROUTE:
        filterItem.key = 'Departure';
        break;
    }
    formattedFilters.push(filterItem);
  }
  return formattedFilters;
};

// Calculates the number of columns to be fetched and rendered on carbon emission table based on the screen width.
export const getMaxColumns = screenwidth => {
  return Math.floor(
    MIN_CARBON_EMISSION_TABLE_COLUMNS +
    (screenwidth - MIN_CARBON_EMISSION_TABLE_WIDTH) / CARBON_EMISSION_TABLE_PER_COLUMN_WIDTH
  );
};

export const getFilterString = (
  filters: IFilterType,
  breakdown: string,
  metric: string,
  scenarioId: number,
  screenWidth: number
) => {
  const maxColumns = getMaxColumns(screenWidth);
  let filterString = `breakdown: ${breakdown},
                  metric: ${metric},
                  scenarioId: ${scenarioId},
                  maxColumns: ${maxColumns},`;
  for (const [filterCategory, selectedFilters] of Object.entries(filters)) {
    if (!selectedFilters.length) {
      continue;
    }

    const selectedFilterKeys = [];
    for (const filter of selectedFilters) {
      let key = letterCaseFormat(filterCategory, filter.key);
      if (key === 'Arrival' || key === 'Departure') {
        key = 'Enroute';
      }
      selectedFilterKeys.push(key);
    }

    const selectedOperationTypes = [];
    const operationTypesFilterKey = 'operationTypes';
    for (const filter of selectedFilters) {
      const key = letterCaseFormat(filterCategory, filter.key);
      if (key === 'Arrival' || key === 'Departure') {
        selectedOperationTypes.push(key);
      }
    }

    const ENUM_VALUES = ['emissions', 'phases', 'regions'];
    if (selectedFilterKeys.length) {
      const formattedValues = ENUM_VALUES.includes(filterCategory)
        ? JSON.stringify(selectedFilterKeys).replace(/\"/g, '')
        : JSON.stringify(selectedFilterKeys);
      if (
        metric === TMA_EFFICIENCY &&
        (filterCategory === 'phases' || filterCategory === 'emissions')
      ) {
        continue;
      }
      filterString += `${filterCategory}: ${formattedValues},`;
      if (selectedOperationTypes.length) {
        const formattedOperationTypes = JSON.stringify(selectedOperationTypes).replace(/\"/g, '');
        filterString += `${operationTypesFilterKey}: ${formattedOperationTypes},`;
      }
    }
  }
  filterString = stripTrailingComma(filterString);
  return `filter: {${filterString}}`;
};

export const translateLabels = (items: IFilterItem[], translationList) => {
  if (items === undefined) {
    return items;
  }
  items.map(item => {
    const translatedLabel = translationList[item.label];
    if (translatedLabel !== undefined) {
      item.label = translatedLabel;
    }
  });
  return items;
};

export const getSortData = (data, sortObject) => {
  if (sortObject) {
    const { field, direction } = sortObject;
    const sortedData = data.sort((item1, item2) => {
      if (field === 'date') {
        const date1 = new Date(item1[field]);
        const date2 = new Date(item2[field]);
        if (direction === ASC) {
          return date1.getTime() - date2.getTime();
        } else if (direction === DESC) {
          return date2.getTime() - date1.getTime();
        }
      } else {
        if (direction === ASC) {
          return parseFloat(item1[field]) - parseFloat(item2[field]);
        } else if (direction === DESC) {
          return parseFloat(item2[field]) - parseFloat(item1[field]);
        }
      }
    });
    return addNewTableIds(sortedData);
  } else {
    return data;
  }
};

export const addNewTableIds = sortedData => {
  return sortedData.map(item => {
    return {
      ...item,
      tableId: uuid.v4(),
    };
  });
};

export const formatDataForGraphs = (columnsDataArray, airtrak, breakdown, combinedColumnName) => {
  if (breakdown === AIRLINE_BREAKDOWN || breakdown === AIRCRAFT_BREAKDOWN) {
    return columnsDataArray.filter(({ name }) => name !== 'UnfilteredTotals');
  }

  return columnsDataArray
    ? columnsDataArray
      .filter(({ name }) => name !== 'UnfilteredTotals')
      .map(item => {
        const columnName = item.name === 'Combined' ? combinedColumnName : airtrak[item.name];
        return { ...item, name: columnName };
      })
    : false;
};

export const getCombinedColumnName = (metric, airtrak, filters, unitLabel: string) => {
  return metric === TMA_EFFICIENCY
    ? `${airtrak[COMBINED_TMA]} (${unitLabel})`
    : `${airtrak[COMBINED]} (${unitLabel})`;
};

export const getExportData = (items, footerData) => {
  const formattedItems = items.map(item => {
    const formattedItem = { ...item };
    delete formattedItem.tableId;
    delete formattedItem.UnfilteredTotals;
    return formattedItem;
  });
  const footer = { date: footerData.total, ...footerData };
  delete footer.total;
  delete footer.UnfilteredTotals;
  formattedItems.push(footer);
  return formattedItems;
};

export const getExportFileName = (dateRange: string[], view: string) => {
  const fileName = dateRange.length ? dateRange[0] + '_' + dateRange[dateRange.length - 1] : '';
  return view === TABLE ? fileName + '.csv' : fileName;
};

export const getCompactedMetric = (metric, translationData) => {
  const {
    components: {
      lists: { metrics },
      labels: { total, average },
    },
  } = translationData;
  switch (metric) {
    case metrics.GreenhouseGasTotal:
      return total;
    default:
      return average;
  }
};
