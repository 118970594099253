import { useContext } from 'react';
import uuid from 'uuid';
// interfaces
import { IDataSelectors, IDataState, IActions, IFetchedData } from 'src/@airtrak/interfaces';
// providers
import { AirtrakStateContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actions
import { actionTypes } from 'src/@airtrak/actionTypes';
import { TOTAL_METRIC, LINE, PIE, BAR } from 'src/constants';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(AirtrakStateContext);
  const dataState: IDataState = state.data;
  return useSelectors(dataState, (state: IDataState) => ({
    getTableData: () => state.rowData,
    getFooterData: () => state.footerData,
    getEmissionSummary: () => state.emissionSummary,
    getData: () => state.graphColumns,
    getIfLoading: () => state.isLoading,
    getMetric: () => state.metric,
    getRowGrouping: () => state.rowGrouping,
    getMovements: () => state.movements,
    getIsLinechartExport: () => state.isLineChartExport,
    getIsPiechartExport: () => state.isPieChartExport,
    getIsBarchartExport: () => state.isBarChartExport,
    getIsTablechartExport: () => state.isTableExport,
    getCarbonEmissionDisplayUnit: () => state.carbonEmissionDisplayUnit,
  }));
};

export const dataInitialState: IDataState = {
  rowGrouping: [],
  graphColumns: [{ name: 'dummy', percentage: 0.0, rows: [0, 0, 0], columnTotal: '0' }],
  rowData: new Map(),
  footerData: {},
  emissionSummary: [
    {
      measure: 'GreenhouseGasTotal',
      value: 0,
      total: 0,
    },
    {
      measure: 'PassengerTotal',
      value: 0,
      total: 0,
    },
    {
      measure: 'MovementTotal',
      value: 0,
      total: 0,
    },
  ],
  isLoading: false,
  metric: TOTAL_METRIC,
  movements: [],
  isLineChartExport: false,
  isTableExport: false,
  isPieChartExport: false,
  isBarChartExport: false,
  carbonEmissionDisplayUnit: 'kg',
};

const addTableId = (newData: IFetchedData[]) => {
  const data = new Map();
  if (newData.length > 0) {
    for (const emissionData of newData) {
      data.set(emissionData.date, {
        ...emissionData,
        tableId: uuid.v4(),
      });
    }
  }
  return data;
};

export const dataReducer = (state: IDataState, action: IActions) => {
  switch (action.type) {
    case actionTypes.GET_DATA:
      if (action.data) {
        const {
          graphColumns,
          rowData,
          footerData,
          emissionSummary,
          rowGrouping,
          movements,
          carbonEmissionDisplayUnit,
        } = action.data;
        const itemsMap = addTableId(rowData);
        return Object.assign({}, state, {
          graphColumns,
          rowData: itemsMap,
          footerData,
          emissionSummary,
          rowGrouping,
          movements,
          isLoading: false,
          carbonEmissionDisplayUnit,
        });
      }
      return Object.assign({}, state, {
        data: {},
        rowData: {},
        footerData: {},
        emissionSummary: {},
        isLoading: false,
        carbonEmissionDisplayUnit: 'kg',
      });
    case actionTypes.DATA_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case actionTypes.SET_METRIC:
      return Object.assign({}, state, { metric: action.data });
    case actionTypes.SET_IS_EXPORT:
      const graphType = action.data.graphType;
      const isExport = action.data.isExport;
      if (graphType === LINE) {
        return Object.assign({}, state, { isLineChartExport: isExport });
      } else if (graphType === PIE) {
        return Object.assign({}, state, { isPieChartExport: isExport });
      } else if (graphType === BAR) {
        return Object.assign({}, state, { isBarChartExport: isExport });
      } else {
        return Object.assign({}, state, { isTableExport: isExport });
      }

    default:
      return state;
  }
};
