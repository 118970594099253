import React, { useEffect, useState, useContext } from 'react';
import { Icons } from '@ems/client-design-system';
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import theme from '../../styles/themes.json';
import { useMutation } from '@apollo/react-hooks';

// mutations
import { UPDATE_SETTING } from 'src/@settings/mutations';

// actions
import {
  updateOperationTheme,
  updateDistanceUnits,
  updateColorByAltitudeBands,
  updateUserConfig,
  /* updateColorByAltitudeColors */
} from 'src/app/actions';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';

// Components
import { displayToast } from '@ems/client-design-system';
import { SettingsSelectorList } from 'src/app/components';
import { ColorBandConfigurator } from 'src/app/components/ColorBandConfigurator';

// Feature flags
import { DYNAMIC_TILE_SERVER, TRACK_COLORS_ALTITUDE } from 'src/app/featureToggles';

// Types
import { IColorByAltitudeConfig } from 'src/@settings/interfaces';
import { useLanguageSelectors } from 'src/app/reducers';
import { SettingsDropdownList } from 'src/app/components/SettingsSelector/SettingsDropdownList';
import { useGetUserLanguage } from 'src/app/applicationState/language/hooks';

const TrackIcons = ({ value }) => {
  const themePath = theme.operations[value];

  return (
    <>
      {Object.entries(themePath).map(([key, value], i) => (
        <Icons
          iconName={`ic-ad-${key}`}
          size={24}
          style={{
            fill: value,
            color: value,
          }}
          key={i}
          className="track-theme__icon"
        />
      ))}
    </>
  );
};

export const GeneralSettings: React.FC<{}> = ({}) => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const selectedDistanceUnits = configSelectors.getDistanceUnitsProfile();
  const loadedColorByAltitudeConfig = configSelectors.getAltitudeColorBands('operations');
  const currentConfig = configSelectors.getConfig();
  const hasLocalUnitSystem = currentConfig.globals.units !== undefined;
  const [selectedTrackThemeRadio, setSelectedTrackThemeRadio] = useState<string[]>([
    selectedTrackTheme,
  ]);
  const [selectedDistanceUnitRadio, setSelectedDistanceUnitRadio] = useState<string[]>([
    selectedDistanceUnits,
  ]);
  const [colorByAltitudeConfig, setColorByAltitudeConfig] = useState<IColorByAltitudeConfig>({
    ...loadedColorByAltitudeConfig,
  });

  const dispatcher = useContext(GlobalDispatchContext);
  const [updateSettingsForDeployedProduct] = useMutation(UPDATE_SETTING, {
    update() {},
  });

  const { selectedLanguage, availableLanguages } = useGetUserLanguage();

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        generalSettings: {
          units: unitsString,
          USCustomary: USCustomaryString,
          ICAOMetric: ICAOMetricString,
          ICAOAlternative: ICAOAlternativeString,
          localSystem: localSystemString,
          trackTheme: trackThemeString,
          altitudeColourBands: altitudeColourBandsString,
        },
      },
    },
  } = languageSelectors.getLanguage();

  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  // Color by altitude requires dynamic tile server to work
  const IS_COLOR_BY_ALTITUDE_ENABLED =
    FEATURE_FLAG_DYNAMIC_TILE_SERVER && configSelectors.isFeatureAvailable(TRACK_COLORS_ALTITUDE);

  const trackColourOptions = [
    {
      label: '',
      value: 'default',
      body: <TrackIcons value="default" />,
    },
    {
      label: '',
      value: 'defense',
      body: <TrackIcons value="defense" />,
    },
    {
      label: '',
      value: 'classic',
      body: <TrackIcons value="classic" />,
    },
  ];

  const distanceUnitOptions = [
    {
      label: USCustomaryString,
      value: 'US Customary',
    },
    {
      label: ICAOMetricString,
      value: 'ICAO Metric',
    },
    {
      label: ICAOAlternativeString,
      value: 'ICAO Alternative',
    },
  ];
  if (hasLocalUnitSystem) {
    distanceUnitOptions.push({
      label: localSystemString,
      value: 'Local System',
    });
  }

  const handleThemeChange = (value: string) => {
    const capitalizeValue = value.charAt(0).toUpperCase() + value.slice(1);
    const settingsPath = [
      { parameter: 'globals/colors/operations/colorScheme', stringValue: capitalizeValue },
    ];
    updateSettingsForDeployedProduct({
      variables: {
        settings: settingsPath,
      },
    }).then(() => {
      updateOperationTheme(dispatcher, value);
      setSelectedTrackThemeRadio([value]);
    });
  };

  const handleDistanceChange = (value: string) => {
    const settingsPath = [{ parameter: 'globals/unitProfileName', stringValue: value }];
    updateSettingsForDeployedProduct({
      variables: {
        settings: settingsPath,
      },
    }).then(() => {
      updateDistanceUnits(dispatcher, value);
      setSelectedDistanceUnitRadio([value]);
    });
  };

  const handleAltitudeBandValueChange = (value: IColorByAltitudeConfig) => {
    setIsFormSubmitting(true);
    updateSettingsForDeployedProduct({
      variables: {
        settings: [
          {
            parameter: 'globals/colors/operations/colorByAltitude/bands',
            doubleArrayValue: value.bands,
          },
        ],
      },
    })
      .then(() => {
        updateColorByAltitudeBands(dispatcher, value.bands);
        setIsFormSubmitting(false);
        setColorByAltitudeConfig(value);
      })
      .catch(e => {
        console.error(e);
        displayToast({
          timeout: 5000,
          intent: 'danger',
          message: 'Unable to complete your request, please try again later.',
        });
        setIsFormSubmitting(false);
      });

    // Option to update colours not yet implemented
    /* updateSettingsForDeployedProduct({
      variables: {
        settings: [
          {
            parameter: 'globals/colors/operations/colorByAltitude/colors',
            stringArrayValue: value.colors,
          },
        ],
      },
    }).then(() => {
      updateColorByAltitudeColors(dispatcher, value.colors);
    }); */
  };

  useEffect(() => {
    if (selectedTrackThemeRadio === null) {
      setSelectedTrackThemeRadio([trackColourOptions[0].value]);
    }
  });

  const handleLanguageUpdate = (value: string) => {
    updateUserConfig(dispatcher, { languageTag: value });
  };

  return (
    <>
      {Object.keys(availableLanguages).length > 1 && (
        <SettingsDropdownList
          title="User Language"
          className="user-language"
          options={availableLanguages}
          selected={selectedLanguage}
          onChangeHandler={handleLanguageUpdate}
        />
      )}
      <SettingsSelectorList
        title={unitsString}
        className="distance-units"
        options={distanceUnitOptions}
        selected={selectedDistanceUnitRadio}
        onChangeHandler={handleDistanceChange}
      />
      <SettingsSelectorList
        title={trackThemeString}
        className="track-theme"
        options={trackColourOptions}
        selected={selectedTrackThemeRadio}
        onChangeHandler={handleThemeChange}
      />
      {IS_COLOR_BY_ALTITUDE_ENABLED && (
        <ColorBandConfigurator
          title={altitudeColourBandsString}
          className="altitude-bands"
          colorByAltitudeConfig={colorByAltitudeConfig}
          onSubmitHandler={handleAltitudeBandValueChange}
          isFormSubmitting={isFormSubmitting}
        />
      )}
    </>
  );
};
