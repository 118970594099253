import React, { FC, useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useDatesDataForMap,
  fetchTrackTiles,
} from 'src/app/functions/map';
import {
  useDataForMap,
  useMapLayer,
  useMapTracksFilter,
} from 'src/app/functions/infringementsOnMap';
import {
  useMapSettings,
  useRerunHookOnMapBackgroundChange,
  useRerunFunctionOnMapBackgroundChange,
} from 'src/app/functions/mapSettings';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useSortSelectors } from 'src/@infringements/reducers';
import {
  flyTo,
  useHoverOnMapElement,
  useMapHover,
  useMapClick,
  useMapSelection,
  useGeometryRequiredByMap,
  offsetCoordinatesByPixels,
  dateTimeInQuery,
  addCustomTileSource,
  mapboxStyleInfringementPaint,
} from 'src/utils';
import { useStaticDbDisplay } from 'src/utils/playback';
import { OperationPopup } from 'src/components';
import { useDataSelectors } from '../reducers';
import { IMapProps } from 'src/@infringementsCandidates/interfaces';
import { NoisePopup } from 'src/components/NoisePopup';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL, DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
import { MAP_TYPES, MARKER_OFFSET } from 'src/constants';
import { IRulerCoordinateObject } from 'src/utils/interfaces';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { filterStore } from 'src/@infringements/stores/filterStore';
import { fetchTileCursor } from 'src/@infringements/resolvers/summaryResolver';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

export const MapContainer: FC<IMapProps> = ({
  noiseMonitors = [],
  noiseData = [],
  candidateFlag,
}) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: { backToCenter: backToCenterLabel, ruler: ruler },
    },
    abbreviations,
  } = languageSelectors.getLanguage();
  const labels = Object.assign(opsFields, abbreviations);

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();
  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // getting map style layers
  const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'infringements' });
  // changing map tiles when dates change
  const { dateRangeMapping, datesArray } = useDatesDataForMap(
    mapApis,
    mapBoxConfig,
    layers,
    false,
    MAP_TYPES.INFRINGEMENTCANDIDATES
  );

  const dataSelectors = useDataSelectors();
  const {
    requiredData,
    infringementTypes,
    addedToSelection,
    removedFromSelection,
  } = dataSelectors.getRequiredDataForMap();

  // Tile server related set up
  const { pageInfo } = dataSelectors.getDataInformation();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);
  const client = useApolloClient();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const { from, to } = dateRangeStore.getDateFilters();
  const filterParams = candidateFlag
    ? filterStore.getFilterString(false)
    : filterStore.getFilterString(false, { hasInfringements: true });

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 3000);

  useGeometryRequiredByMap({
    mapApis,
    mapBoxConfig,
    infringementTypes,
    dateRange: {
      dateFilterFrom: dateTimeInQueryUTC(from, 'start'),
      dateFilterTo: dateTimeInQueryUTC(to, 'end'),
    },
    mapStyle,
  });

  const updateLayerFunction = useCallback(() => {
    if (tileLayers.length) {
      addCustomTileSource({
        mapApis,
        trackPaths: tileLayers,
        trackVisibility: true,
        paintStyle: mapboxStyleInfringementPaint(),
      });
    }
  }, [mapApis, tileLayers]);

  useRerunFunctionOnMapBackgroundChange({ mapApis, mapStyle, reloadFunction: updateLayerFunction });

  // getting coordinates for infringements
  const { infringementTracks, selectedInfringements } = useDataForMap(
    'inf-candidate',
    mapApis,
    mapBoxConfig,
    viewport,
    setViewport,
    dateRangeMapping,
    requiredData,
    addedToSelection,
    removedFromSelection,
    [],
    rerunHook
  );

  // extrack map filters for the features
  const { infringementFilter, clickFilter } = useMapTracksFilter({
    features: infringementTracks.selectedTracks,
    selectedInfringements,
  });

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // handle hover and get hovered operation
  const { hoveredElement, handleHover, setHoveredElement } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: datesArray,
    tracksFilter: infringementFilter,
    mapType: MAP_TYPES.INFRINGEMENTCANDIDATES,
  });

  // set map selection for the hover object
  useMapHover(hoveredElement, mapApis);

  // Map selection
  const [showSelected, setShowSelected] = useState(false);
  const [selectedOperations, setSelectedOperations]: any = useState(null);

  // click handlers that provide selected operations
  const { handleClick, selectedOperation } = useMapClick({
    hoveredOperation: hoveredElement,
    setShowSelected,
    mapApis,
    datesArray,
    tracksFilter: clickFilter,
    selectedOperations,
    setSelectedOperations,
    mapType: MAP_TYPES.INFRINGEMENTCANDIDATES,
  });

  // set feature state for the hovered operation
  useMapSelection(selectedOperations, mapApis, 'infringement-select', selectedInfringements);

  // reset hover and select when selection changes
  useEffect(() => {
    setShowSelected(false);
    setHoveredElement(null);
    setSelectedOperations([]);
  }, [selectedInfringements.length]);

  const selectedMonitor: any[] = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, false);

  const noisePopups: JSX.Element[] = [];
  if (selectedMonitor.length > 0) {
    selectedMonitor.map((monitor: any) => {
      noisePopups.push(
        <NoisePopup
          key={`popup_${monitor.properties.id}`}
          zoomLevel={mapApis.getZoom()}
          monitorData={monitor}
        />
      );
    });
  }
  // Ruler Tool

  const units = configSelectors.getUnits();
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
      mapApis.isRulerEnabled = false;
    } else {
      setIsRulerEnabled(true);
      mapApis.isRulerEnabled = true;

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };
  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  // Loading of tiles from self hosted server when enabled
  // Generate a tile cursor from the 'operations' endpoint
  useEffect(() => {
    const handleFetchingTileCursor = async () => {
      const queryParams = `
      startTime: "${dateTimeInQuery(from, 'start')}"
      endTime: "${dateTimeInQuery(to, 'end')}"
      first:${100}
    `;

      const tileCursor = await fetchTileCursor(
        client,
        `${queryParams} ${sortString} ${filterParams}`
      );
      setMapApiStartCursor(tileCursor);
    };

    if (pageInfo && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      if (pageInfo.startCursor) {
        handleFetchingTileCursor();
      }
    }
  }, [pageInfo]);

  // Using cursor to fetch tiles from self hosted server
  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles({ startCursor: mapApiStartCursor });
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor]);

  // Draw tiles to map
  const selectedTrackTheme = configSelectors.getTheme('operations');
  useEffect(() => {
    if (tileLayers.length) {
      addCustomTileSource({
        mapApis,
        trackPaths: tileLayers,
        trackVisibility: true,
        paintStyle: mapboxStyleInfringementPaint(selectedTrackTheme),
      });
    }
  }, [tileLayers, mapApis]);

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx('map_wrapper', {
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          {...mapProps}
          ref={mapRef}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          <MapControl
            navigationControl={{
              showCompass: false,
              showHome: true,
              showSearch: false,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            translationData={{
              home: backToCenterLabel,
              mapSettings: mapSettingsTitle,
              ruler,
            }}
            resetView={resetView}
            mapSettingsConfig={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  onUpdate={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />

          {noisePopups}
          {showSelected &&
            selectedOperation &&
            selectedOperation.latitude &&
            selectedOperation.longitude && (
              <OperationPopup
                selectedOperation={selectedOperation}
                onClose={() => setShowSelected(false)}
                labels={labels}
                mapApis={mapApis}
              />
            )}
          {!showSelected &&
            hoveredElement &&
            hoveredElement.latitude &&
            hoveredElement.longitude && (
              <OperationPopup
                selectedOperation={hoveredElement}
                labels={labels}
                mapApis={mapApis}
              />
            )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(from, 'start'),
              dateFilterTo: dateTimeInQueryUTC(to, 'end'),
            }}
          />
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
