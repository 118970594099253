// change infringementTypes acronyms to
export const letterCaseFormat = (filterCategory: string, filterValue: string) => {
  if (filterCategory === 'infringementTypes') {
    switch (filterValue) {
      case 'CCO':
        return 'Cco';
      case 'CDO':
        return 'Cdo';
      default:
        return filterValue;
    }
  }
  return filterValue;
};

export const convertToFullName = details => {
  const { firstName, lastName } = details;
  const nameArray: string[] = [];

  [firstName, lastName].forEach((field: string | null) => {
    if (field) {
      nameArray.push(field.trimRight());
    }
  });

  return nameArray.join(' ');
};

export const convertToAddress = address => {
  const { streetAddress, streetAddress2, city, state, postcode } = address;
  const fieldArray = [streetAddress, streetAddress2, city, state, postcode];
  const addressArray: string[] = [];
  fieldArray.forEach((field: string | null) => {
    if (field) {
      addressArray.push(field.trimRight());
    }
  });
  return addressArray.join(', ');
};
