import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

// Providers
import { useLanguageSelectors } from 'src/app/reducers';
// Components
import { PageHeader, LoadMoreBar, DateFilter } from 'src/components';
import {
  SkeletonText,
  Table,
  Button,
  Icons,
  Overlay,
  Dropdown,
  displaySuccess,
} from '@ems/client-design-system';

// Variables
import { AIRCRAFT_ASSIGNMENTS_MODULE, BLANK_STRING_PLACEHOLDER } from 'src/constants';

// Functions
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';

// Queries
import { GET_AIRCRAFT_ASSIGNMENTS, SET_AIRCRAFT_ASSIGNMENT } from './queries';

// Types
import { TUpdateUrl } from 'src/app/props';
import { ITableFilterObject, TableFilter } from 'src/components/TableComponents';

import { dateRangeStore } from 'src/app/stores/dateRangeStore';

import {
  IAircraftAssignmentsData,
  IAircraftAssignmentsVars,
  IDateRange,
  ISelectedModelItem,
  IAircraftAssignmentsMutationVariables,
  IInlineDropdownItem,
  IDropdownItem,
  ISetAircraftToNoiseModelingTypeMap,
  IAircraftToNoiseModelingTypeSummaryFilter,
} from './interfaces';

// DEFAULTS
import { DEFAULT_SORT_ORDER, INITIAL_PAGE_SIZE } from './constants';

// TABLE HEADER SET UP

import { getQueryFilterFromTableFilters } from 'src/@scenarioGeneration/containers/ViewScenario/components/Table/helpers';
import { useAircraftAssignmentData } from './hooks/useAircraftAssignmentData';
import { useColumnData } from './hooks/useColumnData';
import { useSelectedNoiseModel } from './hooks/useSelectedNoiseModel';
import { useAircraftAssignmentTableFilters } from './hooks/useAircraftAssignmentsTableFilters';
import { useTableData } from './hooks/useTableData';
import { useTableColumnHeaders } from 'src/components/TableComponents/TableColumnHeader/useTableColumnHeaders';
import { ISortActionCallback } from 'src/components/TableComponents/TableColumnHeader/interfaces';

let mutatedItemIndices: number[] = [];
export const AircraftAssignmentsContainer = ({
  updateUrl,
}: {
  updateUrl: TUpdateUrl;
}): JSX.Element => {
  // Selectors
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const dateStore = dateRangeStore.getDateFilters();

  const selectedNoiseModel = useSelectedNoiseModel();
  // States
  const [queryFilter] = useState<IAircraftToNoiseModelingTypeSummaryFilter>({
    noiseModelTypes: [selectedNoiseModel],
  });
  const [currentDateRange, setCurrentDateRange] = useState<IDateRange>(dateStore);
  const [tableRowKeys, setTableRowKeys] = useState<string[]>([]);

  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [isBulkEditModalVisible, setIsBulkEditModalVisible] = useState<boolean>(false);
  const [bulkEditSelection, setBulkEditSelection] = useState<IDropdownItem>(null);

  const selectedNoiseModelType = useSelectedNoiseModel();
  const initialQueryFilter = {
    noiseModelTypes: [selectedNoiseModelType],
  };
  const defaultQueryVariables: IAircraftAssignmentsVars = {
    queryFilter: { ...initialQueryFilter },
    sortOrder: [DEFAULT_SORT_ORDER],
    start: dateTimeInQuery(currentDateRange.from, 'start'),
    end: dateTimeInQuery(currentDateRange.to, 'end'),
    count: INITIAL_PAGE_SIZE,
  };
  const [queryVariables, setQueryVariables] = useState<IAircraftAssignmentsVars>({
    ...defaultQueryVariables,
  });

  // Language Strings
  const {
    components: {
      buttons: { update, cancel, bulkEdit, loadMore: loadMoreText },
      hints: { tryChangingFilters },
      labels: {
        table: { endTable },
      },
      dropdowns: { multiple: multipleDropdownLabel, pleaseSelect: pleaseSelectDropdownLabel },
    },
    screens: {
      settings: {
        notFound: {
          table: { noAircraftAssignmentsFound },
        },
      },
    },
  } = translationData;

  // Update page data when date range changes
  // Update filters when date range changes
  useEffect(() => {
    if (JSON.stringify(dateStore) !== JSON.stringify(currentDateRange)) {
      // Set selected row stores to empty
      handleSelectRow([]);
      setCurrentDateRange(dateStore);
      /*  setQueryVariables({
        ...queryVariables,
        start: dateStore.from,
        end: dateStore.to,
      }); */
      setQueryVariables({
        ...queryVariables,
        start: dateTimeInQuery(dateStore.from, 'start'),
        end: dateTimeInQuery(dateStore.to, 'end'),
      });
    }
  }, [dateStore]);

  // Date picker
  const DateFilterHOC = withQueryStringUpdater({
    Container: DateFilter,
    updateUrl,
    defaultRange: 'month',
  });

  const updateQueryFilter = ({ filters }: ITableFilterObject) => {
    setQueryVariables({
      ...queryVariables,
      queryFilter: {
        ...queryFilter,
        ...getQueryFilterFromTableFilters(filters),
      },
    });
  };

  const {
    variables,
    areQueriesLoading,
    totalCount,
    hasNextPage,
    endCursor,
    edges,
    loadMore,
    noiseModelingAircraftTypes,
  } = useAircraftAssignmentData(queryVariables);

  // Result data handling

  const [setAircraftAssignment, { loading: isSetAircraftAssignmentLoading }] = useMutation<
    IAircraftAssignmentsMutationVariables
  >(SET_AIRCRAFT_ASSIGNMENT, {
    update: (cache, { data }) => {
      // Read the data from our cache for this query.
      const store: IAircraftAssignmentsData = cache.readQuery({
        query: GET_AIRCRAFT_ASSIGNMENTS,
        variables,
      });
      const aircraftToNoiseModelingTypeMap = (data.setAircraftToNoiseModelingTypeMap as unknown) as ISetAircraftToNoiseModelingTypeMap;

      if (
        aircraftToNoiseModelingTypeMap &&
        aircraftToNoiseModelingTypeMap.noiseModelingAircraftType
      ) {
        const newData = data.setAircraftToNoiseModelingTypeMap;
        const updatedItems = store.aircraftToNoiseModelingTypeSummariesByTimeRange.edges;
        const { aircraftName, aircraftId } = newData.noiseModelingAircraftType;

        const getUpdatedNode = index => {
          const systemModelingAircraftName = updatedItems[index].node.modelingAircraftName;
          return {
            ...updatedItems[index],
            node: {
              ...updatedItems[index].node,
              modelingAircraftName: aircraftName,
              modelingAircraftId: aircraftId,
              source: newData.source,
              systemModelingAircraftName,
            },
          };
        };

        const filterUpdatedItems = (index, updatedNode) => {
          if (
            queryVariables.queryFilter.modelingAircraftNames &&
            !queryVariables.queryFilter.modelingAircraftNames.includes(aircraftName)
          ) {
            updatedItems.splice(index, 1);
          } else {
            updatedItems.splice(index, 1, updatedNode);
          }
        };

        if (mutatedItemIndices.length) {
          mutatedItemIndices.forEach(index => {
            const updatedNode = getUpdatedNode(index);
            filterUpdatedItems(index, updatedNode);
          });
          mutatedItemIndices = [];
          setSelectedInTable([]);
        }

        const queryData = store;
        queryData.aircraftToNoiseModelingTypeSummariesByTimeRange.edges = [...updatedItems];
        // Update the cache with the manipulated data
        cache.writeQuery({
          query: GET_AIRCRAFT_ASSIGNMENTS,
          data: queryData,
        });
      }
    },
  });

  // Actions
  const sortAction = ({ sortName, sortObject: { field, direction } }: ISortActionCallback) => {
    const sortDirection = field === sortName ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    handleSelectRow([]);
    setQueryVariables({
      ...queryVariables,
      sortOrder: [
        {
          field: sortName,
          direction: sortDirection,
        },
      ],
    });
  };

  const handleModelItemMutation = ({
    updatedVariables,
  }: {
    updatedVariables: IAircraftAssignmentsMutationVariables;
  }) => {
    void setAircraftAssignment({
      variables: updatedVariables,
      optimisticResponse: updatedVariables,
    });
  };

  const handleSelectRow = (indices: number[]) => {
    const selectedRows = indices.map(index => tableData[index]);
    const selectedRowKeys = selectedRows.map(({ tableRowKey }) => tableRowKey);
    setSelectedInTable(indices);
    setTableRowKeys(selectedRowKeys);
  };

  const handleInlineSelect = (
    selectedItem: IInlineDropdownItem,
    selectedNode: ISelectedModelItem,
    selectedNodeIndex: number
  ) => {
    if (selectedItem.key === selectedNode.modelingAircraftId) {
      // Nothing to update
      return;
    }
    const { key: newModelingAircraftId, label: newModelingAircraftName } = selectedItem;
    const updatedVariables: IAircraftAssignmentsMutationVariables = {
      newNoiseModelType: selectedNode.noiseModelType,
      newAircraftType: selectedNode.aircraftType,
      newEngineModel: selectedNode.engineModel,
      newAirline: selectedNode.airline,
      newModelingAircraftId,
      newModelingAircraftName,
    };

    mutatedItemIndices = [selectedNodeIndex];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    displaySuccess({ message: `Updating record` });
    void handleModelItemMutation({
      updatedVariables,
    });
  };

  const handleBulkEditModal = () => {
    setIsBulkEditModalVisible(true);
  };

  const handleBulkEdit = (selectedItem: IInlineDropdownItem) => {
    const { key: newModelingAircraftId } = selectedItem;
    const mutationVariables = (tableRowKeys || [])
      .map(rowKeys => JSON.parse(rowKeys) as IAircraftAssignmentsMutationVariables)
      .filter(
        ({ newModelingAircraftId: presentModelingAircraftId }) =>
          newModelingAircraftId !== presentModelingAircraftId
      );

    mutatedItemIndices = selectedInTable;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    displaySuccess({ message: `Updating ${mutationVariables.length} record(s)` });
    mutationVariables.forEach((mutation: IAircraftAssignmentsMutationVariables) => {
      void handleModelItemMutation({
        updatedVariables: { ...mutation, newModelingAircraftId },
      });
    });
  };

  const isLoading = areQueriesLoading || isSetAircraftAssignmentLoading;
  const columnData = useColumnData();
  // Table components
  const tableData = useTableData(edges, noiseModelingAircraftTypes, handleInlineSelect);

  const getSort = () => queryVariables.sortOrder[0];

  const rowHeaders = useTableColumnHeaders({
    columnData,
    sortAction,
    sortObjectSelector: {
      getSortObject: () => getSort(),
    },
    translationModuleName: AIRCRAFT_ASSIGNMENTS_MODULE,
    isLoading,
  });

  const aircraftTypes = (noiseModelingAircraftTypes || []).map(({ aircraftName, aircraftId }) => ({
    label: aircraftName,
    key: aircraftId,
  }));

  const handleModalClose = () => {
    setBulkEditSelection(null);
    setIsBulkEditModalVisible(false);
  };

  const handleModalSave = () => {
    handleBulkEdit(bulkEditSelection);
    handleModalClose();
  };

  const handleBulkSelectedItem = (value: IDropdownItem) => {
    setBulkEditSelection(value);
    return value;
  };

  // TABLE FILTERS
  const beforeFilter = () => {
    handleSelectRow([]);
  };
  const {
    tableFilterObject,
    setTableFilterObject,
    onClearFilters,
    isTableFilterInitialized,
  } = useAircraftAssignmentTableFilters(
    isLoading,
    tableData,
    noiseModelingAircraftTypes,
    beforeFilter,
    currentDateRange
  );

  useEffect(() => {
    if (tableFilterObject) {
      onFilterUpdate(tableFilterObject);
    }
  }, [tableFilterObject]);

  const onFilterUpdate = (updatedFilters: ITableFilterObject) => {
    setSelectedInTable([]);
    const queryObject = { ...updatedFilters };
    updateQueryFilter(queryObject);
  };

  return (
    <>
      <div className="settings__full">
        <div className="aircraft-assignments-settings__container">
          <div className="settings__heading">
            <PageHeader title="Aircraft Assignments">
              <SkeletonText loading={isLoading} width="4rem">
                <span className="settings__heading-count page-count">{`${
                  totalCount < 0 || isLoading ? BLANK_STRING_PLACEHOLDER : totalCount
                } Total`}</span>
              </SkeletonText>
            </PageHeader>
            <div>
              {
                <Button
                  disabled={!selectedInTable.length}
                  className="aircraft-assignments-settings__bulk-edit-button"
                  style="primary"
                  leftIcon={<Icons iconName={`ic-ui-edit`} title="Bulk Edit" size={18} />}
                  onClick={handleBulkEditModal}>
                  {'Bulk Edit'}
                </Button>
              }
              <DateFilterHOC />
            </div>
          </div>

          <div>
            <TableFilter
              tableFilterObject={tableFilterObject}
              isLoading={isLoading}
              areFilterOptionsInitialized={isTableFilterInitialized}
              setTableFilterObject={setTableFilterObject}
              onClearFilters={onClearFilters}
              onFilterUpdate={onFilterUpdate}
            />
            <Table
              className={`aircraft-assignments-table`}
              loading={isLoading}
              rowHeaders={rowHeaders}
              data={tableData}
              wrapperClassName={'feature-wrapper'}
              columns={columnData.map(({ columnName }) => columnName)}
              selectedData={selectedInTable}
              gridID="aircraft-assignments-table"
              onSelectRow={handleSelectRow}
              languageData={{
                noDataTitle: noAircraftAssignmentsFound,
                noDataText: tryChangingFilters,
                endTable,
              }}
              hasEnded={tableData.length && !hasNextPage}
              showDashIfEmpty
            />
            <LoadMoreBar
              isVisible={hasNextPage}
              isLoadingMore={tableData.length && isLoading}
              loadMore={loadMore}
              loadMoreText={loadMoreText}
              resultSize={tableData.length}
              endCursor={hasNextPage && endCursor}
              dispatcher={null}
              sortString={null}
            />
          </div>
        </div>
      </div>

      <Overlay
        openState={isBulkEditModalVisible}
        onClose={handleModalClose}
        classes={['overlay--bulk-edit']}>
        <div className="overlay_header">
          <h3
            style={{
              fontWeight: 'normal',
            }}>
            {bulkEdit}
          </h3>
          <Button
            size="s"
            style="subtle"
            iconOnly
            onClick={handleModalClose}
            aria-label="Close modal"
            className="overlay_close"
            leftIcon={
              <Icons
                iconName="ic-ui-cancel"
                size={16}
                style={{ cursor: 'pointer', fill: '#5a6872' }}
              />
            }
          />
        </div>
        <div className="overlay_content">
          <Dropdown
            label={`Set ${queryFilter.noiseModelTypes[0]} Model to:`}
            isNullable
            searchItems={aircraftTypes}
            updateSelection={handleBulkSelectedItem}
            selectedItem={bulkEditSelection}
            placeholderValue={
              selectedInTable.length > 1 ? multipleDropdownLabel : pleaseSelectDropdownLabel
            }
          />
        </div>
        <div className="overlay_footer">
          <Button onClick={handleModalClose} style="subtle" className="bulk-edit_cancel">
            {cancel}
          </Button>
          <Button style="primary" onClick={handleModalSave} disabled={bulkEditSelection === null}>
            {update}
          </Button>
        </div>
      </Overlay>
    </>
  );
};
