import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { useLocation } from 'react-router-dom';

// Layout
import { RegularLayout } from 'src/components/Layout';
import { ScenarioFormLayoutContainer } from '../containers/CreateScenario/CreateScenario.styles';

// Containers
import { CreateScenarioContainer } from 'src/@scenarioGeneration/containers';

interface CreateNoiseScenarioLocation {
  skipCacheCheck?: boolean;
  invalidScenarioId?: boolean;
}

const CreateNoiseScenario = ({}) => {
  const location = useLocation<CreateNoiseScenarioLocation>();

  const skipCacheCheck =
    location.state && location.state.skipCacheCheck ? location.state.skipCacheCheck : false;
  const invalidScenarioId =
    location.state && location.state.invalidScenarioId ? location.state.invalidScenarioId : false;
  return (
    <RegularLayout>
      <ScenarioFormLayoutContainer>
        <CreateScenarioContainer
          skipCacheCheck={skipCacheCheck}
          invalidScenarioId={invalidScenarioId}
        />
      </ScenarioFormLayoutContainer>
    </RegularLayout>
  );
};

export const CreateNoiseScenarioScreen = withRouteProps(CreateNoiseScenario);
