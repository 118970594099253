import { assignDropdown, assignDatePicker, assignTextInput, assignDaysDropDrown } from './settings';
import { formatFilterItems } from 'src/@settings/functions';
import { capitalizeObjectKeys } from 'src/utils/objectModifiers';
import { translateLabels } from 'src/@airtrak/functions';
import { IDayItem } from 'src/@settings/interfaces';

export const formatLoadFactorsData = (
  data,
  translationData,
  settingsFilterData,
  daysItems,
  updateLoadFactor,
  minDate: Date,
  maxDate: Date,
  percentageOfFlightsValidation: string
) => {
  const formattedLoadFactors = new Array();
  let index = 0;
  for (const loadFactor of data) {
    const loadFactorObj = { ...loadFactor };

    loadFactorObj.airline = assignDropdown(
      index,
      loadFactorObj,
      settingsFilterData.airlines,
      translationData,
      'airline',
      loadFactorObj.airline,
      updateLoadFactor
    );

    loadFactorObj.aircraftType = assignDropdown(
      index,
      loadFactorObj,
      settingsFilterData.aircraftTypes,
      translationData,
      'aircraftType',
      loadFactorObj.aircraftType,
      updateLoadFactor
    );
    loadFactorObj.origin = assignDropdown(
      index,
      loadFactorObj,
      settingsFilterData.origin,
      translationData,
      'origin',
      loadFactorObj.origin,
      updateLoadFactor
    );
    loadFactorObj.destination = assignDropdown(
      index,
      loadFactorObj,
      settingsFilterData.destination,
      translationData,
      'destination',
      loadFactorObj.destination,
      updateLoadFactor
    );
    loadFactorObj.loadFactor = assignTextInput(
      index,
      translationData,
      'loadFactor',
      parseInt(loadFactorObj.loadFactor, 10),
      updateLoadFactor,
      percentageOfFlightsValidation
    );
    loadFactorObj.startTime = assignDatePicker(
      index,
      new Date(loadFactorObj.startTime),
      updateLoadFactor,
      'startTime',
      maxDate,
      minDate,
    );
    loadFactorObj.endTime = assignDatePicker(
      index,
      new Date(loadFactorObj.endTime),
      updateLoadFactor,
      'endTime',
      maxDate,
      minDate,
    );
    loadFactorObj.dayOfWeek = assignDaysDropDrown(
      index,
      formatDays(loadFactorObj.dayOfWeek, settingsFilterData.daysOfWeek, translationData),
      daysItems,
      'dayOfWeek',
      updateLoadFactor
    );

    formattedLoadFactors.push(loadFactorObj);
    index = index + 1;
  }
  return formattedLoadFactors;
};

const formatDays = (days, abbrevations: string[], translationData) => {
  const daysMap: Map<number, string> = getDaysMap(abbrevations);
  const dayFilterItems: IDayItem[] = [];
  for (let i = 0; i < days.length; i++) {
    let day = days.charAt(i);
    const mappedDay = daysMap.get(i) || '';
    if (day !== '_') {
      day = getDayWithPosition(i);
      dayFilterItems.push({ shortcut: day, name: mappedDay });
    }
  }

  const formattedFilterItems = formatFilterItems(dayFilterItems);
  const {
    components: {
      lists: { daysOfWeek },
    },
  } = translationData;

  const convertedDataList = capitalizeObjectKeys({
    ...daysOfWeek,
  });
  return translateLabels(formattedFilterItems, convertedDataList);
};

const getDaysMap = (abbrevations: string[]) => {
  const daysMap = new Map();
  let index = 0;
  abbrevations.forEach((item: string) => {
    daysMap.set(index, item);
    index++;
  });
  return daysMap;
};

export const getDayWithPosition = dayIndex => {
  switch (dayIndex) {
    case 0: {
      return 'S_____';
    }
    case 1: {
      return '_M_____';
    }
    case 2: {
      return '__T____';
    }
    case 3: {
      return '___W___';
    }
    case 4: {
      return '____T__';
    }
    case 5: {
      return '_____F_';
    }
    case 6: {
      return '______S';
    }
    default: {
      return '';
    }
  }
};

export const getFormattedLoadFactorRecord = record => {
  return {
    id: record.id,
    scenarioId: record.scenarioId,
    aircraftType: record.aircraftType,
    airline: record.airline,
    origin: record.origin,
    destination: record.destination,
    startTime: record.startTime,
    endTime: record.endTime,
    dayOfWeek: record.dayOfWeek,
    loadFactor: parseFloat(record.loadFactor),
  };
};
