import React from 'react';
import { DateTime } from 'luxon';
// components
import { Link } from 'react-router-dom';
import {
  MapPopupMoveable,
  getConvertedDistance,
  getConvertedVerticalDistance,
  Icons,
  SkeletonText,
} from '@ems/client-design-system';
import { useConfigSelectors } from 'src/app/reducers';
// functions
import { getDeployedProductId, tooltipTimeFormat } from 'src/utils';
// Interfaces
import { ITrackPCA, ITrackInteractionPoint } from 'src/utils/interfaces';

// constants
import { MAP_POPUP_OFFSET, OPERATIONS } from 'src/constants';

const BRAND01 = '#0b6bf2';
const UI01 = '#8897A2';

export const AMSLPopup = ({
  onClose,
  labels,
  pointData,
  anchor = 'top-left',
  trackPcas = [],
  draggable,
  isLoading = false,
  latitude,
  longitude,
  anchorMarker = true,
}: {
  onClose?: () => void;
  labels: {
    [key: string]: string;
  };
  pointData: ITrackInteractionPoint;
  anchor?: string;
  trackPcas?: ITrackPCA[];
  draggable?: boolean;
  isLoading?: boolean;
  latitude?: number;
  longitude?: number;
  anchorMarker?: boolean;
}) => {
  const showPointData = pointData && pointData.showPointData;

  if ((!showPointData && !isLoading) || pointData === null) {
    return null;
  }

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const lat = latitude || pointData.latitude;
  const lon = longitude || pointData.longitude;
  const timeDay = tooltipTimeFormat(pointData.time);
  const isPca = trackPcas.some(pca => {
    const trackTimeConverted = DateTime.fromJSDate(pca.time).toISO();
    const pointTimeConverted = DateTime.fromISO(pointData.time).toISO();
    return trackTimeConverted === pointTimeConverted;
  });
  const flightId = pointData.flightId || pointData.properties.acid;
  const mapOffset = anchor == 'top-left' ? MAP_POPUP_OFFSET : MAP_POPUP_OFFSET * -1;

  const operationLink = (
    <Link
      className="correlatedCollapse__header-link"
      to={{
        pathname: `/${getDeployedProductId()}/${OPERATIONS}/${pointData.id}`,
        search: undefined,
      }}>
      {flightId}
    </Link>
  );

  return (
    <MapPopupMoveable
      latitude={lat}
      longitude={lon}
      anchorLongitude={lon}
      anchorLatitude={lat}
      anchorLine={draggable}
      anchorMarker={anchorMarker}
      className={'amsl-popup'}
      offsetLeft={mapOffset}
      offsetTop={mapOffset}
      onClose={onClose}
      closeButton={draggable}
      draggable={draggable}>
      <div className="amsl-popup_title">{operationLink}</div>
      <br />
      <div className="amsl-popup_label">{labels.amsl}:</div>{' '}
      <SkeletonText loading={isLoading} width="50px">
        <span className="amsl-popup_value">
          {getConvertedVerticalDistance(pointData.amsl, units.distanceVertical, 0)}
        </span>
      </SkeletonText>
      <br />
      <div className="amsl-popup_label">{labels.distance}:</div>{' '}
      <SkeletonText loading={isLoading} width="50px">
        <span className="amsl-popup_value">
          {getConvertedDistance(pointData.dist, units.distance, -1)}
        </span>
      </SkeletonText>
      <br />
      <div className="amsl-popup_label">{labels.time}:</div>{' '}
      <SkeletonText loading={isLoading} width="50px">
        <span className="amsl-popup_value">{timeDay}</span>
      </SkeletonText>
      <br />
      {pointData.distanceHorizontal && (
        <>
          <div className="distance-table_separator" />
          <div className="amsl-popup_title">Distance to Inquirer</div>
          <table className="distance-table">
            <tbody>
              <tr>
                <td>
                  <Icons
                    iconName="ic-distance-hypotenuse"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
                <td>
                  <Icons
                    iconName="ic-distance-horizontal"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
                <td>
                  <Icons
                    iconName="ic-distance-vertical"
                    fill={isPca ? BRAND01 : UI01}
                    size="24"
                    inline={true}
                  />
                </td>
              </tr>
              <tr>
                <td>{getConvertedDistance(pointData.distanceSlant, units.distance, -1)}</td>
                <td>{getConvertedDistance(pointData.distanceHorizontal, units.distance, -1)}</td>
                <td>
                  {getConvertedVerticalDistance(
                    pointData.distanceVertical,
                    units.distanceVertical,
                    0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </MapPopupMoveable>
  );
};
