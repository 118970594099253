// ts
import { IDateRange, IDateRangeQueryParams, IValidateDateQueryParams } from 'src/app/props';
import { DateTime } from 'luxon';
import { History } from 'history';
// functions
import {
  format,
  dateFormat,
  getDatePickerRange,
  dateToStringYMd,
} from 'src/utils/dateTimeConverters';
import { isValidDate } from 'src/utils/dateTimes';
import { rangeLimitInMonths } from 'src/app/functions/dateRange';
import { HeapAPI } from 'src/components/Heap';
import { configStore } from 'src/app/stores/configStore';

/**
 * read query string and based on the store's default dates and query params, return the page date range
 */
export const getDateRangeBasedOnQueryString = (
  defaultDateRange: IDateRange,
  appConfig?: any
): IValidateDateQueryParams => {
  // parse the query string into an object which we can then grab the values off of
  const params = getDateRangeParams();

  const {
    updateQueryString,
    dates: { from, to },
  } = validateDateRange(defaultDateRange, params, appConfig);
  return {
    isUpdateRequired: updateQueryString,
    from,
    to,
  };
};

// Calculate the duration of hours, months, years and more.
export const getDuration = (zone, unit, startISOString, endISOString) => {
  const startTime = DateTime.fromISO(startISOString, { zone });
  const endTime = DateTime.fromISO(endISOString, { zone });
  const diff = endTime.diff(startTime, unit);

  return diff.values.days;
}

// could be expanded to do more in the future but we keep it very simple for now and only validate the date range
export const validateDateRange = (
  defaultDateRange: IDateRange,
  { from, to }: IDateRangeQueryParams,
  appConfig
) => {
  // get current from and to dates from date-filter store
  const { from: storeFromDate, to: storeToDate } = defaultDateRange;
  // updateQueryString means => correct the query strings format in the URL
  let updateQueryString: boolean = false;
  let invalidQueryString: boolean = false;
  const defaultStoreDates: IDateRangeQueryParams = {
    from: format(storeFromDate, dateFormat),
    to: format(storeToDate, dateFormat),
  };
  let dates: IDateRangeQueryParams = { from, to };
  // from and to dates are both missing
  if (!from && !to) {
    updateQueryString = true;
    dates = defaultStoreDates;
  }
  // missing "from" or "to" values will pick the same day for "from" and "to" date range
  // Example: ?from=2019-05-16 => ?from=2019-05-16&to=2019-05-16 (date-picker updates accordingly)
  // Example: ?to=2019-05-16 => ?from=2019-05-16&to=2019-05-16 (date-picker updates accordingly)
  else if (!from || !to) {
    const dateString = from || to || '';
    // make sure the available date is valid
    if (isValidDate(dateString)) {
      updateQueryString = true;
      dates = getDatesInOrder({ from: dateString, to: dateString }, appConfig);
    } else {
      invalidQueryString = true;
      updateQueryString = false;
      dates = defaultStoreDates;
    }
  }
  // both from and to are available
  // example: ?from=2019-05-16&to=2019-06-07
  else {
    if (isValidDate(from) && isValidDate(to)) {
      dates = getDatesInOrder({ from, to }, appConfig);
      if (dates.from === defaultStoreDates.from && dates.to === defaultStoreDates.to) {
        updateQueryString = false;
      } else {
        updateQueryString = true;
      }
    } else {
      invalidQueryString = true;
      updateQueryString = false;
      dates = defaultStoreDates;
    }
  }

  // don't update if the values are the same the defaults date range values in store
  if (defaultStoreDates.from === from && defaultStoreDates.to === to) {
    updateQueryString = false;
  }

  return {
    updateQueryString,
    invalidQueryString,
    dates,
  };
};

export const getDateRangeParams = (search?: string): IDateRangeQueryParams => {
  const dateObj = parseQueryString(search || window.location.search);
  const zone = configStore.getTimeZone();
  const currentDate = DateTime.fromObject({ zone }).toFormat('yyyy-MM-dd');
  // If there's no available date range, take the current date
  const { from, to } = dateObj.from ? dateObj : { from: currentDate, to: currentDate };

  // Number of days between start day and end day
  // To make sure that the duration counts from the begining of the start date
  // to the end of the end date, we need to add T24:00 to the end date.
  const dayDuration = getDuration(zone, 'days', from, `${to}T24:00`);

  // Number of days between start day and today
  const dayOffset = getDuration(zone, 'days', from, currentDate);

  HeapAPI('addEventProperties', { 'Query days': dayDuration });
  HeapAPI('addEventProperties', { 'Query offset': dayOffset });

  return dateObj;
};

export const isQueryStringAvailable = (): boolean => {
  return !!window.location.search;
};

// turn a query string to object literal
// example: ?key1=value1&key2=value2 => { key1: value1, key2: value2 }
export const parseQueryString = (search: string): any => {
  const queryData = {};
  if (search && typeof search === 'string') {
    search
      .replace(/(^\?)/, '')
      .split('&')
      .forEach((item: string) => {
        const [key, value] = item.split('=');
        if (key && value) {
          queryData[decodeURIComponent(key)] = decodeURIComponent(value);
        }
      });
  }
  return queryData;
};

export const isValidDateRangeQueryString = (search: string): boolean => {
  const { from, to } = parseQueryString(search);
  if (typeof from === 'string') {
    return !isValidDate(from) ? false : true;
  }
  if (typeof to === 'string') {
    return !isValidDate(to) ? false : true;
  }
  return true;
};

// Date picker min-date is 1 Jan 1970 and max-date is today
// Any date in the past or in the future (outside min-max range defined above) would not be applied to the date-range picker (default min-date and max-date will be used)
export const getDatesInOrder = (
  { from, to }: IDateRangeQueryParams,
  appConfig
): IDateRangeQueryParams => {
  // set the min date (if defined in the config)
  let rangeLimits = null;
  if (appConfig) {
    const {
      dateRange: { rangeLimits: rangeLimitsConfig },
    } = appConfig;
    rangeLimits = rangeLimitsConfig;
  }
  const minDate = getDatePickerRange('min');
  const minDateString: string = dateToStringYMd(minDate);
  const maxDateString: string = dateToStringYMd(getDatePickerRange('max'));
  const minDateInMilSec: number = new Date(minDateString).getTime();
  const maxDateInMilSec: number = new Date(maxDateString).getTime();
  const validOutput = { from, to };
  if (from && to) {
    // if "from" date is after "to" date or vice versa, just swap the values to make it a correct date range and update the date-picker accordingly
    // example: ?from=2018-07-30&to=2018-05-16 => ?from=2018-05-16&to=2018-07-30
    const checkFromDate: number = new Date(from).getTime();
    const checkToDate: number = new Date(to).getTime();
    let fromDateString: string = checkFromDate <= checkToDate ? from : to;
    let fromDateInMilSec: number = new Date(fromDateString).getTime();
    let toDateString: string = checkToDate >= checkFromDate ? to : from;
    // Check 1: If "From" < "Min Date", set it to min date.
    if (fromDateInMilSec < minDateInMilSec) {
      fromDateString = minDateString;
    }
    // Check 2: If from and to are at least a month apart, make sure they are only maximum 1 month apart.
    const luxTo = DateTime.fromISO(toDateString);
    const luxFrom = DateTime.fromISO(fromDateString);
    const monthDiff = luxTo.diff(luxFrom, 'months').toObject().months;
    // range limits config
    const rangeLimit = rangeLimitInMonths(rangeLimits);
    // apply the range limit
    if (rangeLimit !== 0 && monthDiff >= rangeLimit) {
      toDateString = luxFrom.plus({ months: rangeLimit }).toISODate();
    }
    fromDateInMilSec = new Date(fromDateString).getTime();
    const toDateInMilSec: number = new Date(toDateString).getTime();
    // Check 3: If "To" > "Max Date", set it to max date.
    if (from !== to) {
      validOutput.from = fromDateInMilSec >= minDateInMilSec ? fromDateString : minDateString;
      validOutput.to = toDateInMilSec <= maxDateInMilSec ? toDateString : maxDateString;
    } else {
      // from and to are equal
      // example: ?from/to=[date-before-1970] => ?from=1970-01-01&to=1970-01-01
      // sxample: ?from/to=[date-after-today] => ?from=[today-date]&to=[today-date]
      let singleDate = from;
      if (fromDateInMilSec < minDateInMilSec) {
        singleDate = minDateString;
      } else if (toDateInMilSec > maxDateInMilSec) {
        singleDate = maxDateString;
      }
      validOutput.from = singleDate;
      validOutput.to = singleDate;
    }
  }
  return validOutput;
};

// could be expanded to do more in the future but we keep it very simple for now
export const constructDateRangeQueryString = ({ from, to }: IDateRangeQueryParams): string => {
  const urlParams = new URLSearchParams(window.location.search);
  if (from) {
    urlParams.set('from', from);
  }
  if (to) {
    urlParams.set('to', to);
  }
  return `?${urlParams.toString()}`;
};

export const UpdateQueryString = (history: History, pathname: string, search: string) => {
  history.push({
    pathname,
    search,
  });
};

// Updates the most recent entry on the history stack to have the specified data
// used for the first time user visit the page
export const updateQueryWithoutRerouting = (pathname: string, queryString: string): void => {
  const {
    location: { protocol, host },
  } = window;
  const url = `${protocol}//${host}${pathname}${queryString}`;
  window.history.replaceState({ path: url }, '', url);
};
