import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  fetchUserDashboardsQuery,
  fetchUserDashboardsSchema,
  fetchDashboardAccessQuery,
  fetchDashboardAccessSchema,
} from 'src/@dashboard/resolvers/schema';

interface IUserDashboard {
  id: string;
  name: string;
  mode: string;
}

interface IDashboardAccess {
  embedReportId: string;
  embedToken: string;
  embedUrl: string;
  expiration: string;
}

export const fetchUserDashboards = (client: ApolloClient<object>) => {
  return new Promise<IUserDashboard[]>((resolve, reject) => {
    const GET_USER_DASHBOARDS = gql`
      query {
        ${fetchUserDashboardsQuery} ${fetchUserDashboardsSchema}
      }
    `;

    client
      .query({
        query: GET_USER_DASHBOARDS,
      })
      .then(response => {
        if (response && response.data && response.data[fetchUserDashboardsQuery]) {
          resolve(response.data[fetchUserDashboardsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchDashboardAccess = (client: ApolloClient<object>, id: string) => {
  return new Promise<IDashboardAccess>((resolve, reject) => {
    const GET_DASHBOARD_ACCESS = gql`
      {
        ${fetchDashboardAccessQuery} (id: "${id}") ${fetchDashboardAccessSchema}
      }
    `;

    client
      .query({
        query: GET_DASHBOARD_ACCESS,
      })
      .then(response => {
        if (response && response.data && response.data[fetchDashboardAccessQuery]) {
          resolve(response.data[fetchDashboardAccessQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
