import React, { useContext, useState, useEffect, FC } from 'react';
// components
import { Spinner, Table } from '@ems/client-design-system';
import { AirlineMappingContainer } from 'src/@settings/containers';
// functions
import {
  getColumnTypes,
  getFilteredAirlineMappings,
  updateTableIds,
} from 'src/@settings/functions';
// providers
import { SettingsDispatchContext } from '../provider/SettingsStateProvider';
// reducers
import {
  useTableSelectors,
  useFleetMixSelector,
  useAircraftMappingsSelector,
} from 'src/@settings/reducers';
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
// actions
import {
  sortFleetmixTable,
  updateCurrentAirline,
  updateCurrentAirlineMappings,
} from 'src/@settings/actions';
// libraries
import uuid from 'uuid';
// interfaces
import { IAirlineMapping, IFleetMixContainer } from 'src/@settings/interfaces';
// constants
import { FLEETMIX_MODULE } from 'src/constants';
import { sortTableData, formatHeaders } from 'src/utils';

export const FleetMixContainer: FC<IFleetMixContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateFleetMixSettings,
  discardMutationData,
}) => {
  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);
  // selectors
  const tableSelectors = useTableSelectors();
  const fleetMixSelector = useFleetMixSelector();
  const languageSelectors = useLanguageSelectors();
  const mappingSelectors = useAircraftMappingsSelector();
  const filterSelectors = useFilterDataSelectors();
  const currentAirline = fleetMixSelector.getCurrentAirline();
  const sortObject = tableSelectors.getSortObject(FLEETMIX_MODULE);
  const sortString = tableSelectors.getSortString(FLEETMIX_MODULE);
  const {
    components: {
      info: { fleetMixInfo },
    },
    fields: {
      fleetMix: { airline: airlineColumnHeader, numberOfFlights: numberOfFlightsColumnHeader },
    },
  } = languageSelectors.getLanguage();

  // variables
  const airlines = fleetMixSelector.getAirlines();
  const [selectedAirline, setSelectedAirline] = useState<string>(currentAirline);

  const airlineMappings: IAirlineMapping[] = fleetMixSelector.getAirlineMappings();
  const loading: boolean = fleetMixSelector.getIfLoading();
  const areMapsLoading = mappingSelectors.getIfLoading();
  const areFlightsLoading = mappingSelectors.getIfFlightsLoading();
  const { aircraftTypes = [] } = filterSelectors.getAirtrakFilterData();

  // state
  const [rowData, setRowData] = useState<IAirlineMapping[]>(airlines);
  const [clickedRow, setClickedRow] = useState<number>(0);

  const updateSelectedAirline = (airline: string) => {
    setSelectedAirline(airline);
    if (airline) {
      updateCurrentAirlineMappings(
        getFilteredAirlineMappings(airline, airlineMappings),
        dispatcher
      );

      updateCurrentAirline(airline, dispatcher);
    }
  };

  useEffect(() => {
    const sortData = sortTableData(airlines, sortObject);
    setRowData(updateTableIds([], sortData));

    if (sortData[0]) {
      const { airline } = sortData[0];
      setSelectedAirline(`${airline}`);
      updateSelectedAirline(`${airline}`);
    }
  }, [airlines]);

  useEffect(() => {
    const sortData = sortTableData(airlines, sortObject);
    setRowData(updateTableIds([], sortData));
  }, [sortString]);

  const onRowClick = event => {
    const rowIndex = event.currentTarget.rowIndex;
    const rowText = event.currentTarget.innerText;

    updateMappings(rowText);
    if (rowIndex) {
      updateHighlightRow(rowIndex);
    }
  };

  const updateMappings = rowData => {
    const selectedAirline = rowData ? rowData.split('\n')[0] : null;
    setSelectedAirline(selectedAirline);
    updateSelectedAirline(selectedAirline);
  };

  // Table header set up
  const columnData = [
    {
      columnName: 'airline',
      key: 'airline',
      title: airlineColumnHeader,
    },
    {
      columnName: 'numberOfFlights',
      key: 'numberOfFlights',
      title: numberOfFlightsColumnHeader,
    },
  ];

  const rowHeaders = formatHeaders(
    FLEETMIX_MODULE,
    columnData,
    rowData.length,
    dispatcher,
    sortFleetmixTable,
    tableSelectors,
    languageSelectors.getLanguage(),
    'fleetmix',
    false,
    true
  );
  const fleetMixColumns: string[] = columnData.map(header => header.columnName);

  const updateHighlightRow = rowIndex => {
    const updatedData: any = [];
    airlines.forEach(item => {
      const i = Object.assign({}, item, { tableId: uuid.v4() });
      updatedData.push(i);
    });
    setRowData(updatedData);
    setClickedRow(rowIndex - 1);
  };

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      {' '}
      <Spinner loading size="l" centered />{' '}
    </div>
  );

  const getFleetMixComponent = () => (
    <div className={'fleet-mix-wrapper'}>
      <div className="airlines">
        <Table
          className="fleet-mix-table"
          data={rowData}
          columns={fleetMixColumns}
          rowHeaders={rowHeaders}
          gridID={'fleet-mix'}
          columnTypes={getColumnTypes(fleetMixColumns)}
          onRowClick={onRowClick}
          clickedRow={clickedRow}
          loading={areMapsLoading || areFlightsLoading}
        />
      </div>
      <AirlineMappingContainer
        airline={selectedAirline}
        aircraftList={aircraftTypes}
        setChangesAvailable={setChangesAvailable}
        areChangesDiscarded={areChangesDiscarded}
        updateFleetMixSettings={updateFleetMixSettings}
        discardMutationData={discardMutationData}
      />
    </div>
  );

  return (
    <>
      <p className={'fleetmix-info'}>{fleetMixInfo}</p>
      {loading ? getSpinnerComponent() : getFleetMixComponent()}
    </>
  );
};
