import React, { useCallback } from 'react';
// components
import { Icons, InlineDropDown } from '@ems/client-design-system';
import {
  useFilterDataSelectors,
  useLanguageSelectors,
  useConfigSelectors,
  useRolesSelectors,
} from 'src/app/reducers';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_INFRINGEMENT_STATUS } from 'src/@infringements/mutations';

export const StatusContainer = ({ infringement, updateStatus }) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    infringements: {
      grid: { statusColors },
    },
  } = configSelectors.getConfig();

  // filter data
  const filtersSelectors = useFilterDataSelectors();
  const infringementFilterData = filtersSelectors.getInfringementFilterData();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { infringementStatuses },
    },
  } = languageSelectors.getLanguage();

  const rolesSelectors = useRolesSelectors();
  const canUpdate = rolesSelectors.hasPermission('Infringement.Update');

  const statusItems: any[] = [];
  const statusOptions = infringementFilterData.statuses;
  statusOptions.map((key: string) => {
    const option = {
      key,
      label: infringementStatuses[key],
      icon: <Icons iconName="ic-ui-circle" fill={statusColors[key]} />,
    };
    statusItems.push(option);
  });

  let selectedItem = statusItems.find(item => item.key === infringement.status);
  // if inf status = null
  if (selectedItem === undefined) {
    selectedItem = {
      key: '—',
      label: '—',
      icon: <span className="status_icon" style={{ backgroundColor: 'transparent', width: 0 }} />,
    };
  }

  const [updateInfringementStatus] = useMutation(UPDATE_INFRINGEMENT_STATUS, {
    update(cache, { data: { updateInfringement } }) {
      const status = updateInfringement.status;
      updateStatus(status);
    },
  });

  const handleInlineDropdown = useCallback((infringementId, newStatus, infringementType) => {
    updateInfringementStatus({
      variables: { id: infringementId, status: newStatus },
      optimisticResponse: {
        __typename: infringementType,
        updateInfringement: {
          id: infringementId,
          status: newStatus,
          __typename: infringementType,
        },
      },
    });
  }, []);

  const updateSelection = newStatus => {
    handleInlineDropdown(
      infringement.id,
      newStatus.key,
      `${infringement.infringementType}Infringement`
    );
  };

  if (!canUpdate) {
    return (
      <div className="u-flex-center status_viewOnly">
        {selectedItem.icon}
        {selectedItem.label}
      </div>
    );
  }

  return (
    <div className="u-flex-center">
      <InlineDropDown
        searchItems={statusItems}
        hasIcon={false}
        isIconOnly={false}
        isNullable={false}
        updateSelection={updateSelection}
        selectedItem={selectedItem}
        openPosition={'bottom'}
        width={150}
      />
    </div>
  );
};
