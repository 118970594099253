import React, { useState } from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import {
  FilterContainer,
  MapInfoContainer,
  HeaderContainer,
  TableContainer,
  DataContainer,
} from 'src/@infringementsCandidates/containers';
import { useConfigSelectors } from 'src/app/reducers';
import { InfringementsStateProvider } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';

const InfringementsCandidatesScreen = ({ location, updateUrl }) => {
  // Configuration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const configSelectors = useConfigSelectors();

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  // State
  const [candidateFlag, setCandidateFlag] = useState<boolean>(false);

  return (
    <SplitLayout>
      <InfringementsStateProvider>
        <DataContainer candidateFlag={candidateFlag}>
          {!isGridFullscreen && <MapInfoContainer candidateFlag={candidateFlag} />}
          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer updateUrl={updateUrl} />
            </div>
            <FilterContainer candidateFlag={candidateFlag} setCandidateFlag={setCandidateFlag} />
            <TableContainer candidateFlag={candidateFlag} setCandidateFlag={setCandidateFlag} />
          </div>
        </DataContainer>
      </InfringementsStateProvider>
    </SplitLayout>
  );
};

export const InfringementsCandidates = withRouteProps(InfringementsCandidatesScreen);
