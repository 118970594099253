import { useContext } from 'react';
import { SettingsStateContext } from 'src/@settings/provider/SettingsStateProvider';
import { IInfRulesSettingsState } from 'src/@settings/interfaces';
import { useSelectors } from 'src/utils/storeHelpers';
import { actionTypes } from 'src/@settings/actionTypes';

export const useInfRulesSettingsSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const infRulesSettingsState: IInfRulesSettingsState = state.infRulesSettings;
  return useSelectors(infRulesSettingsState, state => ({
    getAllNoiseMonitors: () => state.noiseMonitors,
  }));
};

export const infRulesSettingsInitialState: IInfRulesSettingsState = {
  noiseMonitors: [],
};

export const infRulesSettingsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_NOISE_MONITORS:
      return Object.assign({}, state, {
        noiseMonitors: action.payload,
      });
    default:
      return state;
  }
};
