import { useMemo } from 'react';
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { ITableColumnData } from 'src/components';
import { TABLE_CONSTANTS } from 'src/constants';
import { ChangeLogTableData } from '../interfaces';
import { useChangelogFieldNames } from './useChangelogFieldNames';

export const useChangelogColumnData = (
  tableData: ChangeLogTableData[] = []
): ITableColumnData[] => {
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { changelog },
  } = languageSelectors.getLanguage();
  const filterSelectors = useFilterDataSelectors();
  const { userEmails } = filterSelectors.getOperationsFilterData();
  const fieldNameDisplayStrings = useChangelogFieldNames();

  const users = {};
  tableData.forEach(({ field, fieldName, userName }) => {
    fieldNameDisplayStrings[fieldName] = field;
    users[userName] = userName;
  });
  if (userEmails) {
    userEmails.forEach(email => {
      users[email] = email;
    });
  }

  return useMemo(
    () => [
      {
        columnName: 'browser',
        key: 'dataType',
        title: changelog.browser,
        filterName: 'dataTypes',
        filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
        filterOptions: { Operation: 'Operations', NoiseEvent: 'Noise Events' },
        unSortable: true,
        filterType: 'listFilter',
      },
      {
        columnName: 'key',
        key: 'dataId',
        title: changelog.key,
        filterName: 'dataIds',
        filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
        unSortable: true,
        filterType: 'inputNumberFilter',
      },
      {
        columnName: 'field',
        key: 'fieldName',
        title: changelog.field,
        filterName: 'fieldNames',
        filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
        unSortable: true,
        filterOptions: fieldNameDisplayStrings,
        filterType: 'listFilter',
      },
      {
        columnName: 'oldValue',
        key: 'oldValue',
        title: changelog.oldValue,
        unSortable: true,
      },
      {
        columnName: 'newValue',
        key: 'newValue',
        title: changelog.newValue,
        unSortable: true,
      },
      {
        columnName: 'user',
        key: 'userName',
        title: changelog.user,
        filterName: 'userName',
        filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
        filterOptions: users,
        unSortable: true,
        filterType: 'listFilter',
      },
      {
        columnName: 'changeTime',
        key: 'time',
        title: changelog.changeTime,
      },
      {
        columnName: 'revertable',
        key: 'revertable',
        title: '',
        unSortable: true,
      },
    ],
    [fieldNameDisplayStrings, users]
  );
};
