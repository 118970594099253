import { IUserHelpItem } from 'src/app/props';
import { getDeployedProductId } from 'src/utils';

export const fetchPushNotice = (uri: string): Promise<string> =>
  fetch(uri).then(response => response.text());

export const getNoticeTimestamp = (userHelpLinks: IUserHelpItem[]) => {
  const pushItem: IUserHelpItem = userHelpLinks.find(
    document => document.name === 'pushNotice' && document.fileType === 'HTML'
  );
  return pushItem ? pushItem.lastModified : null;
};

// Replaces the template strings with the values from the push notice
export const applyTemplateStrings = (contents: string) => {
  let contentsCopy = contents;
  contentsCopy = contentsCopy.replace(/{{siteLink}}/g, `/${getDeployedProductId()}`);

  return contentsCopy;
};
