import React, { ReactElement } from 'react';
// common components
import { Icons } from '@ems/client-design-system';
import { CSVLink } from 'react-csv';
// props
import { IExport } from 'src/app/props';

interface IReport {
  key: string;
  label: string;
  icon: ReactElement;
  export: IExport;
}

// constants
import {
  EXCEL_EXPORT,
  WORD_EXPORT,
  KML_EXPORT,
  INM_EXPORT,
  CSV_EXPORT,
  COMPLAINTS,
  COMPLAINT,
  OPERATIONS,
  OPERATION,
  INFRINGEMENTS,
  INFRINGEMENT,
  NOISEEVENTS,
  NOISEEVENT,
} from 'src/constants';

export const exportOptions = ({
  source,
  translationData,
  csvExportData,
  hasExportPermission,
}: {
  source: string;
  translationData: any;
  csvExportData?: { [key: string]: string | number };
  hasExportPermission: boolean;
}): IReport[] => {
  const { excel, word, kml, inm, csv } = translationData;
  const excelReportIcon = <Icons iconName="ic-ui-report" title={excel} fill="#36b37e" />;
  const wordReportIcon = <Icons iconName="ic-ui-report" title={word} fill="#2684ff" />;
  const kmlReportIcon = <Icons iconName="ic-ui-report" title={kml} fill="#00b8d9" />;
  const inmReportIcon = <Icons iconName="ic-ui-report" title={inm} fill="#e88354" />;
  const csvReportIcon = <Icons iconName="ic-ui-report" title={inm} fill="#36b37e" />;

  const exportOperationToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Operations Selection Report',
      outputFilename: 'Operations Report',
    },
  };

  const exportOperationToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Operations Selection Report',
      outputFilename: 'Operations Report',
    },
  };

  const exportOperationToKml = {
    key: KML_EXPORT,
    label: kml,
    icon: kmlReportIcon,
    export: {
      type: KML_EXPORT,
      format: 'KML',
      templateName: '',
      outputFilename: 'Operations Report - Kml',
    },
  };

  const exportOperationToInm = {
    key: INM_EXPORT,
    label: inm,
    icon: inmReportIcon,
    export: {
      type: INM_EXPORT,
      format: 'Inm',
      templateName: '',
      outputFilename: 'Operations Report - Inm',
    },
  };

  const exportToCsv = {
    key: CSV_EXPORT,
    label: csv,
    icon: csvReportIcon,
    export: {
      type: CSV_EXPORT,
      format: 'csv',
      templateName: '',
      outputFilename: 'selected',
    },
    useCustomBody: true,
    body: (
      <CSVLink
        data={csvExportData || [{ error: 'Please ensure rows are selected' }]}
        filename={`${source} - ${csvExportData ? csvExportData.length : 0} selected`}
        target="_blank"
        className="bp3-menu-item bp3-popover-dismiss dropdown_item">
        {csvReportIcon}
        <div className="bp3-fill bp3-text-overflow-ellipsis">{csv}</div>
      </CSVLink>
    ),
  };

  const exportInfringementsToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Infringements Selection Report',
      outputFilename: 'Infringements Report',
    },
  };

  const exportInfringementsToWordOnDetailsPage = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Letters/Infringement Letter',
      outputFilename: 'Infringement Letter',
    },
  };

  const exportInfringementsToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Infringements Selection Report',
      outputFilename: 'Infringements Report',
    },
  };

  const exportNoiseEventToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Events Selection Report',
      outputFilename: 'Events Report',
    },
  };

  const exportNoiseEventToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Events Selection Report',
      outputFilename: 'Events Report',
    },
  };

  const exportComplaintToWordOnDetailsPage = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Letters/Inquiry Letter',
      outputFilename: 'Inquiry Letter',
    },
  };

  const exportComplaintToWord = {
    key: WORD_EXPORT,
    label: word,
    icon: wordReportIcon,
    export: {
      type: WORD_EXPORT,
      format: 'Word',
      templateName: 'Selection/Inquiries Selection Report',
      outputFilename: 'Inquiries Report',
    },
  };

  const exportComplaintToExcel = {
    key: EXCEL_EXPORT,
    label: excel,
    icon: excelReportIcon,
    export: {
      type: EXCEL_EXPORT,
      format: 'Excel',
      templateName: 'Selection/Inquiries Selection Report',
      outputFilename: 'Inquiries Report',
    },
  };

  if (!hasExportPermission) {
    return [exportToCsv];
  } else {
    switch (source) {
      case OPERATIONS:
        return [
          exportOperationToWord,
          exportOperationToExcel,
          exportToCsv,
          exportOperationToKml,
          exportOperationToInm,
        ];
      case OPERATION:
        return [exportOperationToWord, exportOperationToExcel, exportOperationToKml];
      case INFRINGEMENTS:
        return [exportInfringementsToWord, exportInfringementsToExcel, exportToCsv];
      case INFRINGEMENT:
        return [exportInfringementsToWordOnDetailsPage];
      case NOISEEVENTS:
        return [exportNoiseEventToWord, exportNoiseEventToExcel, exportToCsv];
      case NOISEEVENT:
        return [exportNoiseEventToWord];
      case COMPLAINTS:
        return [exportComplaintToWord, exportComplaintToExcel, exportToCsv];
      case COMPLAINT:
        return [exportComplaintToWordOnDetailsPage];
      default:
        return [];
    }
  }
};
