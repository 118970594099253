import gql from 'graphql-tag';
import {
  ICorrelatedFlight,
  ICorrelatedFlightFetch,
  ICorrelatedFlightProfilesFetch,
  ICorrelatedFlightProfiles,
} from '../interfaces';
import { operationPcaByTimeRangeQueryName, operationSummariesByIdsQueryName } from './schema';

export const getCorrelatedFlights = ({ client, position, time, range }: ICorrelatedFlightFetch) => {
  return new Promise<ICorrelatedFlight[]>((resolve, reject) => {
    const QUERY_BODY = gql`
      query GetNearestFlightsByPca(
        $minTime: DateTimeOffset!
        $maxTime: DateTimeOffset!
        $position: InputPositionType!
        $maxHorizontalRange: Float!
        $maxAltitudeRange: Float!
      ) {
        ${operationPcaByTimeRangeQueryName}(
          startTime: $minTime
          endTime: $maxTime
          pcaFilter: {
            position: $position
            horizontalRange: { start: 0, end: $maxHorizontalRange }
            verticalRange: { start: 0, end: $maxAltitudeRange }
          }
          sort: [{ field: "time", direction: ASC }]
        ) {
          elevationAngle
          slantDistance
          horizontalDistance
          verticalDistance
          time
          operation {
            id
            acid
            operationType
            aircraftCategory
            aircraftType
            runwayName
            startTime
            endTime
            points {
              alt
              lat
              lon
              t
            }
          }
        }
      }
    `;

    const { minTime, maxTime } = time;
    // The passed position includes a "__typename" property which breaks the query, below is nicer than deleting
    const { latitude, longitude, altitude } = position;
    const { maxHorizontalRange, maxAltitudeRange } = range;
    client
      .query({
        query: QUERY_BODY,
        variables: {
          minTime,
          maxTime,
          maxHorizontalRange,
          maxAltitudeRange,
          position: {
            latitude,
            longitude,
            altitude,
          },
        },
      })
      .then(response => {
        if (response && response.data && response.data[operationPcaByTimeRangeQueryName]) {
          resolve(response.data[operationPcaByTimeRangeQueryName]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getCorrelatedFlightProfiles = ({
  client,
  correlatedIds,
}: ICorrelatedFlightProfilesFetch) => {
  return new Promise<ICorrelatedFlightProfiles[]>((resolve, reject) => {
    const QUERY_BODY = gql`
      query get($ids: [Int!]!) {
        ${operationSummariesByIdsQueryName}(ids: $ids) {
          id
          profile {
            alt: altitude
            dist: distance
            time: timeOffset
          }
        }
      }
    `;

    client
      .query({
        query: QUERY_BODY,
        variables: { ids: correlatedIds },
      })
      .then(response => {
        if (response && response.data && response.data[operationSummariesByIdsQueryName]) {
          resolve(response.data[operationSummariesByIdsQueryName]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
