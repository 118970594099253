import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

// Components
import { IDeviceManagementForm } from '../../interfaces';
import {
  TextInput,
  FieldDetails,
  Dropdown,
  IDropdownItem,
  TimeInput,
  Button,
  Icons,
} from '@ems/client-design-system';
import {
  LocationSettingsCardButtons,
  DeviceManagementField,
  FieldLabel,
  FieldAppendedWrapper,
  FieldError,
  BlankButton,
} from './DeviceManagementDetails.styles';

// Reducers
import { useLanguageSelectors } from 'src/app/reducers';
import {
  hhmmToSeconds,
  secondsToHHMM,
} from 'src/@settings/containers/Rules/InfringementRuleDetails/InfringementRuleDetailsHelpers';
import { IsActiveCheckbox } from 'src/@settings/components/ActiveCheckbox/ActiveCheckbox';

interface SettingsFieldProps {
  isEditing: boolean;
  fieldData: {
    label: string;
    name: string;
    value?: string;
    options?: IDropdownItem[];
    selectedKey?: string;
  };
  appendField?: string;
  isShortField?: boolean;
  disabled?: boolean;
  fieldLength?: number;
  numberOnly?: boolean;
}

interface DeviceSettingsButtonsProps {
  isEditing: boolean;
  isSaveLoading: boolean;
  onCancelAction: () => void;
  toggleEditModeAction: () => void;
}

export const DropdownField = ({
  fieldData,
  isEditing,
  isShortField = false,
}: SettingsFieldProps) => {
  const { setFieldValue, getFieldProps } = useFormikContext<IDeviceManagementForm>();
  const { name, label, options, selectedKey } = fieldData;
  const { value: fieldValue } = getFieldProps<string>(name);
  const isNumberField = typeof fieldValue === 'number';
  const selectedValue = selectedKey
    ? options.find(({ key }) => selectedKey.toLowerCase() === key.toLowerCase()) || {
        key: selectedKey,
        label: selectedKey,
      }
    : { key: null, label: null };

  return (
    <DeviceManagementField className="location-settings__field" isShortField={isShortField}>
      {isEditing ? (
        <>
          <FieldLabel className="location-settings__field-label">{label}</FieldLabel>
          <Dropdown
            placeholderValue=""
            searchItems={options}
            isNullable
            updateSelection={({ key }) => {
              setFieldValue(name, isNumberField ? Number(key) : key);
            }}
            selectedItem={selectedValue}
          />
        </>
      ) : (
        <ReadOnlyField value={selectedValue.label} label={label} />
      )}
    </DeviceManagementField>
  );
};

export const TextInputField = ({
  fieldData,
  isEditing,
  appendField,
  isShortField = false,
  disabled = false,
  fieldLength,
  numberOnly = false,
}: SettingsFieldProps) => {
  const { setFieldValue, getFieldProps, errors } = useFormikContext<IDeviceManagementForm>();
  const { name, value, label } = fieldData;
  const { value: fieldValue } = getFieldProps<string>(name);
  const numberOnlyRegex = new RegExp(/^[-]?([0-9]{1,})?(\.)?([0-9]{1,})?$/);

  return (
    <DeviceManagementField className="location-settings__field" isShortField={isShortField}>
      {isEditing ? (
        <>
          <FieldLabel className="location-settings__field-label">{label}</FieldLabel>
          <FieldAppendedWrapper>
            <TextInput
              state={!errors[name] ? 'default' : 'error'}
              name={name}
              className="location-settings__field-input"
              value={fieldValue}
              disabled={!isEditing || disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;
                // Length check without including "-" or "."
                const valueLength = newValue.match(/\d/g) ? newValue.match(/\d/g).length : 0;

                if (fieldLength && valueLength > fieldLength) {
                  return false;
                }
                if (numberOnly && !numberOnlyRegex.test(newValue)) {
                  return false;
                }
                setFieldValue(name, newValue);
              }}
            />
            {appendField && <span>{appendField}</span>}
          </FieldAppendedWrapper>
          <FieldError className="location-settings__field-error">
            {errors[name] || <>&nbsp;</>}
          </FieldError>
        </>
      ) : (
        <>
          <ReadOnlyField value={`${value} ${appendField || ''}`} label={label} />
        </>
      )}
    </DeviceManagementField>
  );
};

export const TimeField = ({ fieldData, isEditing }: SettingsFieldProps) => {
  const { setFieldValue, getFieldProps, setFieldError } = useFormikContext<IDeviceManagementForm>();

  const languageSelector = useLanguageSelectors();
  const {
    components: {
      filters: {
        time: { validationError, defaultPlaceholder },
      },
    },
  } = languageSelector.getLanguage();
  const { label, name, value } = fieldData;
  const { value: fieldValue } = getFieldProps<string>(name);

  const onTimeInput = (timeString: string) => {
    setFieldError(name, '');
    setFieldValue(name, hhmmToSeconds(timeString));
  };

  const handleError = () => {
    setFieldError(name, 'invalid time format');
  };

  return (
    <DeviceManagementField
      className="location-settings__field"
      isShortField
      style={{ marginBottom: '0px' }}>
      {isEditing ? (
        <>
          <FieldLabel className="location-settings__field-label">{label}</FieldLabel>
          <FieldAppendedWrapper>
            {fieldValue === undefined ? (
              <span className="location-settings__field-label__empty">
                <TimeInput
                  languageData={{ validationError, placeholder: defaultPlaceholder }}
                  onTimeInput={onTimeInput}
                  onError={handleError}
                  allowEmpty
                />
              </span>
            ) : (
              <TimeInput
                defaultValue={secondsToHHMM(Number(fieldValue))}
                languageData={{ validationError, placeholder: defaultPlaceholder }}
                onTimeInput={onTimeInput}
                onError={handleError}
                allowEmpty
              />
            )}
          </FieldAppendedWrapper>
        </>
      ) : (
        <>
          <ReadOnlyField value={`${secondsToHHMM(Number(value) || 0)}`} label={label} />
        </>
      )}
    </DeviceManagementField>
  );
};

export const ReadOnlyField = ({ value, label }: { value: string; label: string }) => (
  <FieldDetails key={`${value} ${label} `} fieldType="inputField" label={label} text={value} />
);

export const DeviceSettingsButtons = ({
  isEditing,
  isSaveLoading,
  onCancelAction,
  toggleEditModeAction,
}: DeviceSettingsButtonsProps) => {
  const { resetForm, values, submitForm, errors } = useFormikContext<IDeviceManagementForm>();

  return (
    <>
      <LocationSettingsCardButtons className="locationsettings__buttons">
        <div className="locationsettings__buttons_left">
          <Button
            size="m"
            onClick={() => {
              if (isEditing) {
                resetForm();
              }
              onCancelAction();
            }}
            style="standard">
            Cancel
          </Button>
        </div>
        <div className="locationsettings__buttons_right">
          <>
            {!isEditing && (
              <Button size="m" onClick={toggleEditModeAction} disabled={false} style="primary">
                Edit
              </Button>
            )}
            {isEditing && (
              <Button size="m" onClick={submitForm} style="primary" disabled={isSaveLoading}>
                Save
              </Button>
            )}
          </>
        </div>
      </LocationSettingsCardButtons>
      {!process.env.NODE_ENV ||
        (process.env.NODE_ENV === 'development' && (
          <>
            <strong>Values</strong>
            <pre>{JSON.stringify(values, null, '\t')}</pre>
            <strong>Errors</strong>
            <pre>{JSON.stringify(errors, null, '\t')}</pre>
          </>
        ))}
    </>
  );
};

export const DeleteTemplateButton = ({
  removeTemplateAction,
  index,
}: {
  removeTemplateAction: (indexToRemove: number) => void;
  index: number;
}) => (
  <BlankButton
    onClick={() => {
      removeTemplateAction(index);
    }}>
    <Icons iconName="ic-ui-add-inverted" size={'20px'} />
  </BlankButton>
);

export const DeviceActiveToggle = ({ isEditing }: { isEditing: boolean }) => {
  const { setFieldValue, values } = useFormikContext<IDeviceManagementForm>();
  const { isActive } = values;

  const updateIsActive = useCallback(
    (isActiveUpdated: boolean) => {
      setFieldValue('isActive', isActiveUpdated);
    },
    [setFieldValue]
  );

  return (
    <IsActiveCheckbox isEditing={isEditing} isActive={isActive} updateIsActive={updateIsActive} />
  );
};
