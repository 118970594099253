import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { featureQuery, featureSchema } from './schema';
import { IPageOptions } from '../interfaces';

interface IOptionalParams {
  abortControllerSignal?: AbortSignal;
  pageOptions?: IPageOptions;
}

const FETCH_SIZE = 10000;
export const fetchSpatialFeatures = (
  client: ApolloClient<object>,
  filterString: string,
  params: IOptionalParams
) =>
  new Promise((resolve, reject) => {
    const { pageOptions, abortControllerSignal } = params;
    const cursorParam =
      pageOptions && pageOptions.endCursor ? `after:"${pageOptions.endCursor}"` : ``;
    const sortString =
      pageOptions && pageOptions.sortString
        ? pageOptions.sortString
        : `sort:[{field : "name", direction : ASC}]`;
    const queryParams = `${filterString},${sortString},
    first: ${FETCH_SIZE} ${cursorParam}`;
    const GET_FEATURE = gql`
    query {
      ${featureQuery}(${queryParams})
      ${featureSchema}
    }
    `;

    client
      .query({
        query: GET_FEATURE,
        context: { fetchOptions: { signal: abortControllerSignal } },
      })
      .then(response => {
        if (response && response.data) {
          const { items, pageInfo, totalCount } = response.data.spatialFeatureSummaries;
          resolve({ items, pageInfo, totalCount });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
