import { appActionTypes } from 'src/app/actionTypes';
import { IDateRangeSelection } from 'src/app/props';
import { dispatcher } from 'src/utils';

export const updateDateRangeAction = (dateRange: IDateRangeSelection, route: string) => ({
  type: appActionTypes.UPDATE_DATE_RANGE,
  dateRange,
  route,
});

export const updateDateRange = (dateRange: IDateRangeSelection, route: string) => {
  dispatcher.handleDispatch(updateDateRangeAction(dateRange, route));
};
