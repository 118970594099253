import { useCallback } from 'react';

// Map
import * as turf from '@turf/turf';

// Types

import { MapLayerMouseEvent } from 'react-map-gl';
import { TaxiPathEditMode, useTaxiPathContext } from '../../context/TaxiPathContext';
import { TaxiPathActions } from '../../context/TaxiPathReducer';

// const MIN_CURSOR_DISTANCE = 0.014;

export const useMapClickWithControl = () => {
  const {
    dispatch,
    state: { currentEditMode, nodeGeoJSON },
  } = useTaxiPathContext();

  return useCallback(
    ({ target, point, lngLat: { lng, lat } }: MapLayerMouseEvent) => {
      const features = target.queryRenderedFeatures(point);
      const clickedPoint = turf.point([lng, lat]);
      switch (currentEditMode) {
        case TaxiPathEditMode.NODES:
          const lastNodeById = nodeGeoJSON.features.length
            ? nodeGeoJSON.features?.reduce((prev, current) =>
                Number(prev.properties.NodeId) > Number(current.properties.NodeId) ? prev : current
              )
            : { properties: { NodeId: 0 } };
          const addedPoint = {
            ...clickedPoint,
            properties: { NodeId: `${Number(lastNodeById.properties.NodeId) + 1}` },
          };
          dispatch({ type: TaxiPathActions.ADD_NODE, payload: addedPoint });
          break;
        case TaxiPathEditMode.DELETE:
          if (features) {
            const clickedFeature = features[0];

            if (clickedFeature) {
              if (clickedFeature.source === 'node-source' && clickedFeature.properties.NodeId) {
                const nodeId = clickedFeature.properties.NodeId;
                dispatch({ type: TaxiPathActions.REMOVE_NODE, payload: nodeId });
              }
              if (
                clickedFeature.source === 'connector-source' &&
                clickedFeature.properties.PathwayId
              ) {
                const pathId = clickedFeature.properties.PathwayId;
                dispatch({ type: TaxiPathActions.REMOVE_CONNECTOR, payload: pathId });
              }
            }
          }
          break;
        default:
          break;
      }
    },
    [currentEditMode, dispatch, nodeGeoJSON]
  );
};
