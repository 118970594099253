import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { gateQuery, gateSchema } from './schema';
import { IGate } from 'src/utils/spatialFeatureHelpers/interfaces';

export const fetchAllGates = (client: ApolloClient<object>) =>
  new Promise<IGate[]>((resolve, reject) => {
    const GET_GATES = gql`
      query {
        ${gateQuery} ${gateSchema}
      }
    `;
    client
      .query({
        query: GET_GATES,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchGateById = (client: ApolloClient<object>, gateId: number) =>
  new Promise<IGate>((resolve, reject) => {
    const queryParams = ` id : ${gateId}`;
    const GET_GATE = gql`
    query {
      gate(${queryParams}) ${gateSchema}
      
    }
  `;
    client
      .query({
        query: GET_GATE,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data.gate);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchGatesByIds = (client: ApolloClient<object>, gateIds: number[]) =>
  new Promise<IGate[]>((resolve, reject) => {
    const queryParams = `ids :[${gateIds}]`;
    const GET_GATES = gql`
  query {
    gatesByIds(${queryParams}) ${gateSchema}
    
  }
`;
    client
      .query({
        query: GET_GATES,
      })
      .then(response => {
        if (response && response.data && response.data.gatesByIds) {
          resolve(response.data.gatesByIds);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
