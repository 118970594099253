import React from 'react';
import { Route } from 'react-router-dom';
// selectors
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
import { SplitLayout } from 'src/components/Layout';
// comnponents
import { Button } from '@ems/client-design-system';
// utils
import { getDeployedProductId } from 'src/utils';

const GoBack = ({ title }: { title: string }) => (
  <Route
    render={({ history }) => (
      <Button
        onClick={() => {
          history.push(`/${getDeployedProductId()}`);
        }}
        style="primary"
        size="s"
        className="bulk-edit_cancel">
        {title}
      </Button>
    )}
  />
);

export const NotFound = () => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: { errors },
    screens: {
      settings: {
        notFound: {
          title,
          buttons: { goBackHome },
        },
      },
    },
  } = languageSelectors.getLanguage();
  return (
    <SplitLayout>
      <div className="container-fluid">
        <div className="header-container">
          <section className="page-header">
            <h1 className="page-header-text">{title}</h1>
          </section>
          <div className="page-header">
            <p className="page-header-description">
              {errors['404']}
              <br />
              {errors.contactSupport}
            </p>
          </div>
          <div className="row-element button">
            <GoBack title={`${goBackHome}`} />
          </div>
        </div>
      </div>
    </SplitLayout>
  );
};
