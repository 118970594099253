import { CEILING_DEFAULT_FEET, CORRIDOR_GATE_OFFSET, FLOOR_DEFAULT_FEET } from 'src/constants';
import { removeAllTypenameProps } from '..';
import { offsetCoordinatesByPixels } from '../mapHelpers/mapHelpers';
import { getDefaultGateCoordinates } from './gateHelpers';
import { IEditCorridorParams, INoiseAbatementCorridor, TCorridorGeometry } from './interfaces';

export const setUpExistingCorridor = (
  corridor: INoiseAbatementCorridor,
  editGeometryParams?: IEditCorridorParams
): TCorridorGeometry => {
  if (editGeometryParams) {
    editGeometryParams.updateState(corridor.geometry);
    editGeometryParams.updateGeometry(corridor.geometry, true);
  }
  return corridor.geometry;
};

export const setUpNewCorridor = (
  viewport,
  mapApis,
  editGeometryParams?: IEditCorridorParams
): TCorridorGeometry => {
  const { longitude, latitude } = viewport;
  const firstGate = getDefaultGateCoordinates(longitude, latitude, mapApis);

  // Second gate
  const secondLongLat = offsetCoordinatesByPixels(
    [longitude, latitude],
    0,
    CORRIDOR_GATE_OFFSET,
    mapApis
  );
  const secondGate = getDefaultGateCoordinates(secondLongLat.lng, secondLongLat.lat, mapApis);

  // Third gate
  const thirdLongLat = offsetCoordinatesByPixels(
    [secondLongLat.lng, secondLongLat.lat],
    0,
    CORRIDOR_GATE_OFFSET,
    mapApis
  );
  const thirdGate = getDefaultGateCoordinates(thirdLongLat.lng, thirdLongLat.lat, mapApis);

  const corridorGeometry = [firstGate, secondGate, thirdGate];
  if (editGeometryParams && editGeometryParams.updateState && editGeometryParams.updateGeometry) {
    const updatedCorridorGeometry = corridorGeometry.map((item: any) =>
      removeAllTypenameProps({
        ...item,
        floorAltitude: FLOOR_DEFAULT_FEET,
        ceilingAltitude: Number(CEILING_DEFAULT_FEET.toFixed(0)),
      })
    );
    editGeometryParams.updateState(corridorGeometry);
    editGeometryParams.updateGeometry(updatedCorridorGeometry, true);
  }

  return corridorGeometry;
};
