import { useState } from 'react';
import { IRulerCoordinateObject, IViewState } from './interfaces';
import { Map as IMapApis } from 'mapbox-gl';
import { offsetCoordinatesByPixels } from '.';
import { MARKER_OFFSET } from 'src/constants';

export const useMapRuler = ({ mapApis, viewport }: { mapApis: IMapApis; viewport: IViewState }) => {
  const [isRulerEnabled, setIsRulerEnabled] = useState<boolean>(false);
  const [rulerCoordinates, updateRulerCoordinates] = useState<IRulerCoordinateObject>({
    start: { longitude: 0, latitude: 0 },
    end: { longitude: 0, latitude: 0 },
  });

  const toggleRuler = () => {
    if (isRulerEnabled) {
      setIsRulerEnabled(false);
    } else {
      setIsRulerEnabled(true);

      const { longitude, latitude } = viewport;
      const startMarkerCoordinates = offsetCoordinatesByPixels(
        [longitude, latitude],
        MARKER_OFFSET,
        MARKER_OFFSET,
        mapApis
      );

      updateRulerCoordinates({
        start: { longitude: startMarkerCoordinates.lng, latitude: startMarkerCoordinates.lat },
        end: { longitude, latitude },
      });
    }
  };

  const rulerCoordinatesChanged = (rulerPoint: string, [longitude, latitude]: number[]) => {
    if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
      updateRulerCoordinates({
        ...rulerCoordinates,
        [rulerPoint]: { longitude, latitude },
      });
    }
  };

  return {
    rulerCoordinatesChanged,
    toggleRuler,
    isRulerEnabled,
    setIsRulerEnabled,
    rulerCoordinates,
    updateRulerCoordinates,
  };
};
