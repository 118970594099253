import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';

// Container
import { AircraftAssignmentsContainer } from 'src/@settings/containers';

// Types
import { TUpdateUrl } from 'src/app/props';

const Screen = ({ updateUrl }: { updateUrl: TUpdateUrl }) => (
  <SettingsLayout>
    <AircraftAssignmentsContainer updateUrl={updateUrl} />
  </SettingsLayout>
);

export const ModelingAircraftAssignmentsSettingsScreen = withRouteProps(Screen);
