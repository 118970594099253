import React from 'react';
import { useMutation } from '@apollo/react-hooks';

// Providers
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { Dialog, Button, displayToast } from '@ems/client-design-system';
import { GenerateNoiseScenarioDialog } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/Dialog.styles';

// Styles
import styles from 'src/styles/vars.module.scss';
import styled from 'styled-components/macro';

// Types
import {
  IDeleteNoiseContourResponse,
  IDeleteNoiseContourVariables,
  TRecord,
} from '../../interfaces';

// Queries
import { DELETE_NOISE_CONTOUR } from '../../queries';

const DeleteButton = styled(Button)`
  &.bp3-button:not(.bp3-minimal):not(.button-boilerplate).mosaic-button {
    background: ${styles.error01};
    color: white;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${styles.brandDarkened}, 0 0 0 0 transparent;
    }
  }
`;

const DeleteContourDialogBody = styled(GenerateNoiseScenarioDialog)`
  &.bp3-dialog .bp3-dialog-body {
    min-height: initial;
  }
`;

export const DeleteContourDialog = ({
  isOpen,
  contourId,
  onCompleted,
}: {
  isOpen: boolean;
  contourId: number;
  onCompleted: (contourId: number) => void;
}) => {
  // Selectors
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage() as TRecord;

  // Translations
  const {
    components: {
      dialogs: {
        deleteContourHeader: deleteContourHeaderString,
        deleteContourBody: deleteContourBodyString,
      },
      errors: { noiseScenarioEndpointError: noiseScenarioEndpointErrorString },
      success: { deleteNoiseContourSuccess: deleteNoiseContourSuccessString },
      buttons: { cancel: cancelString, deleteScenario: deleteString },
    },
  } = translationData;

  // Queries
  const [deleteContour, { loading }] = useMutation<
    IDeleteNoiseContourResponse,
    IDeleteNoiseContourVariables
  >(DELETE_NOISE_CONTOUR);

  const handleClose = (contourId?: number) => {
    onCompleted(contourId);
  };

  const handleDelete = (contourId: number) => {
    deleteContour({
      variables: {
        contourId,
      },
    })
      .then(() => {
        handleClose(contourId);
        displayToast({
          timeout: 4000,
          key: 'deleteContourDialog',
          message: deleteNoiseContourSuccessString.replace('{count}', `1`),
          intent: 'success',
        });
      })
      .catch(error => {
        console.error(error);
        handleClose(contourId);
        displayToast({
          timeout: 4000,
          key: 'deleteContourDialog',
          message: noiseScenarioEndpointErrorString,
          intent: 'warning',
        });
      });
  };

  return (
    <DeleteContourDialogBody
      isOpen={isOpen}
      onClose={() => handleClose()}
      title={deleteContourHeaderString}>
      <Dialog.Body>
        <p>{deleteContourBodyString}</p>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.FooterActions>
          <Button onClick={() => handleClose()}>{cancelString}</Button>
          <DeleteButton
            loading={loading}
            disabled={loading}
            onClick={() => handleDelete(contourId)}>
            {deleteString}
          </DeleteButton>
        </Dialog.FooterActions>
      </Dialog.Footer>
    </DeleteContourDialogBody>
  );
};
