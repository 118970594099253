// Types
import { TOrderByDirectionEnum, ISortObject, IPageInfo } from 'src/components';

// Query types
export interface ISetAircraftMappingVars {
  scenarioId: number;
  aircraftType: string;
  engineModel: string;
  source: AirQualityMappingSource;
  ltoEngineModel: string;
  ltoAircraftType: string;
  enrouteEngineModel: string;
  enrouteAircraftType: string;
}

export enum AirQualityMappingSource {
  SYSTEM = 'System',
  USER = 'User',
}

export interface ISetAircraftMappingResponse {
  setAirQualityAircraftTypeMap: {
    aircraftType?: string;
    engineModel?: string;
    id: number;
    mappedEnrouteAircraft?: string;
    mappedEnrouteEngine?: string;
    mappedLTOAircraft?: string;
    mappedLTOEngine?: string;
    scenarioId?: number;
    source: AirQualityMappingSource;
  };
}

export interface IEngineMappingFilter {
  aircraftType: string;
  engine: string;
}

export interface IEngineMappingResponse {
  ltoAircraftEngineMappings: IEngineMappingFilter[];
  enrouteAircraftEngineMappings: IEngineMappingFilter[];
}

export interface IAircraftMappingVars {
  scenarioId: number | null;
  startTime: string;
  endTime: string;
  sort?: ISortObject[];
  filter?: IAircraftMappingFilter;
  first?: number;
  after?: string;
}

export interface IAircraftMappingResponse {
  airQualityAircraftMappingSummariesByTimeRange: IAircraftMapping;
}

export interface IAircraftMapping {
  totalCount: number;
  pageInfo: IPageInfo;
  edges: IAircraftMappingEdge[];
  __typename?: string;
}

export interface IAircraftMappingEdge {
  cursor: string;
  node: IAircraftMappingNode;
}

export interface IAircraftMappingNode {
  aircraftType: string;
  engineModel: string;
  numberOfFlights: number;
  mappedLTOAircraftEngine: string;
  mappedEnrouteAircraftEngine: string;
}

export interface IAircraftMappingFilter {
  aircraftTypes?: string[];
  engineModels?: string[];
  operationTypes?: string[];
  lTOAircraftEngines?: string[];
  enrouteAircraftEngines?: string[];
}
export interface IUpdateQueryOptions {
  fetchMoreResult?: IAircraftMappingResponse;
  variables?: IAircraftMappingVars;
}

export interface IFetchMoreArgs {
  variables: IAircraftMappingVars;
  updateQuery: (
    previousQueryResult: IAircraftMappingResponse,
    options: IUpdateQueryOptions
  ) => IAircraftMappingResponse;
}

export interface ILoadMoreOptions {
  resultSize: number;
  endCursor: string;
  sortString: string;
  filterString: string;
}

export interface IAircraftMappingTableData
  extends Omit<
    IAircraftMappingNode,
    'numberOfFlights' | 'mappedLTOAircraftEngine' | 'mappedEnrouteAircraftEngine' | 'engineModel'
  > {
  engineModel: JSX.Element;
  numberOfFlights: JSX.Element;
  mappedLTOAircraftEngine: JSX.Element;
  mappedEnrouteAircraftEngine: JSX.Element;
  className: string;
}

// Provider Types
export interface IAircraftMappingState {
  data: IAircraftMapping;
  canEdit: boolean;
  isLoading: boolean;
  error: Error | null;
  queryVariables: IAircraftMappingVars;
  ltoMappings: IEngineMappingFilter[];
  enrouteMappings: IEngineMappingFilter[];
  invalidRows: string[];
}

export type IAircraftMappingActions =
  | { type: 'LOADING' }
  | { type: 'SUCCESS'; data: IAircraftMappingResponse }
  | { type: 'ERROR'; error: Error }
  | { type: 'UPDATE_MAPPING_NODE'; edge: IAircraftMappingEdge }
  | { type: 'UPDATE_FILTER_MAPPINGS'; data: IEngineMappingResponse }
  | { type: 'UPDATE_SCENARIO_ID'; data: number }
  | {
      type: 'UPDATE_DATE_RANGE';
      data: {
        start: string;
        end: string;
      };
    }
  | {
      type: 'TABLE_SORT';
      data: {
        field: string;
        direction: TOrderByDirectionEnum;
      };
    }
  | {
      type: 'TABLE_FILTER';
      data: IAircraftMappingFilter;
    }
  | { type: 'TABLE_SET_INVALID_ROW'; data: string[] };
