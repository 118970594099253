import React, { createContext, useReducer } from 'react';
import { exportInitialState, exportReducer } from 'src/app/reducers';
export const ExportStateContext = createContext({
  data: exportInitialState,
});
export const ExportDispatchContext = createContext(null);
export const ExportStateProvider = ({ children }) => {
  const [exportState, exportDispatch] = useReducer<any>(exportReducer, exportInitialState);
  const state: any = {
    data: exportState,
  };
  const dispatchers = [exportDispatch];
  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });
  return (
    <ExportStateContext.Provider value={state}>
      <ExportDispatchContext.Provider value={dispatch}>{children}</ExportDispatchContext.Provider>
    </ExportStateContext.Provider>
  );
};
