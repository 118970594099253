// client
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { phaseDefaultsQuery, phaseDefaultsSchema, airportsQuery, airportsSchema } from './schema';

export const fetchTimeModes = (client: ApolloClient<object>, scenarioIds: number[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `scenarioIds: ${scenarioIds} `;
    const GET_PHASE_DEFAULTS = gql`
    query {
      ${phaseDefaultsQuery} (${queryParams}) ${phaseDefaultsSchema}
    }`;
    client
      .query({
        query: GET_PHASE_DEFAULTS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[phaseDefaultsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchAirports = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_AIRPORTS = gql`
    query {
      ${airportsQuery} ${airportsSchema}
    }`;
    client
      .query({
        query: GET_AIRPORTS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[airportsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};