import { EventEmitter } from 'events';
import { DateTime } from 'luxon';
// ts
import { IDateRange, IDateRangeSelection } from 'src/app/props';
// actions
import { appActionTypes } from 'src/app/actionTypes';
// functions
import { dispatcher } from 'src/utils/dispatcher';
import { getDay, dateToStringYMd, getDayFromDateString } from 'src/utils/dateTimeConverters';
import {
  getDateRangeBasedOnQueryString,
  isQueryStringAvailable,
} from 'src/app/functions/queryString';
// store
import { configStore } from 'src/app/stores/configStore';
// const
import { CHANGE_EVENT } from 'src/constants';
import { getDeployedProductId } from 'src/utils';

class DateRangeStore extends EventEmitter {
  dateRange: IDateRange;
  routeChangedDateRange: string; // not used for any logic atm (only for debugging), just to keep track of what route has changed the last state of the date range
  previousDateRange: IDateRange | null;
  isDateRangeDefaultSet: boolean;

  constructor() {
    super();
    this.isDateRangeDefaultSet = false;
    this.dateRange = {
      from: new Date(),
      to: new Date(),
    };
    this.routeChangedDateRange = '';
    this.previousDateRange = null;
  }

  getPath(): string {
    const {
      location: { pathname },
    } = window;
    return pathname.replace(`/${getDeployedProductId()}/`, '');
  }

  getSessionKey(isGlobal: boolean) {
    return `${(!isGlobal && `${this.getPath()}.`) || ''}default.date.filter`;
  }

  getSessionDateFilter(isGlobal): IDateRange | false {
    const sessionData: string = sessionStorage.getItem(this.getSessionKey(isGlobal));
    if (sessionData) {
      try {
        const { to, from } = JSON.parse(sessionData) as IDateRange;
        return {
          to: new Date(to),
          from: new Date(from),
        };
      } catch {
        return false;
      }
    }
  }

  updateSessionDateFilter(isGlobal) {
    sessionStorage.setItem(this.getSessionKey(isGlobal), JSON.stringify(this.dateRange));
  }

  updateRangeFromSession(isGlobal) {
    const sessionDateFilter = this.getSessionDateFilter(isGlobal);
    if (sessionDateFilter) {
      this.dateRange = sessionDateFilter;
    }
  }

  updateRangeFromQueryString(appConfig, isGlobal) {
    const { from, to } = getDateRangeBasedOnQueryString(this.dateRange, appConfig);
    if (from && to) {
      this.dateRange = {
        from: getDayFromDateString(from, 'start'),
        to: getDayFromDateString(to, 'end'),
      };
      this.updateSessionDateFilter(isGlobal);
    }
  }

  setDefaultDateRange(isGlobal) {
    if (this.previousDateRange) {
      this.previousDateRange = Object.assign({}, this.dateRange);
    }

    const path = this.getPath();
    const appConfig = configStore.getConfig();
    let dateRanges: any = {};
    if (appConfig) {
      const {
        dateRange: { defaultDateRanges },
      } = appConfig;
      dateRanges = defaultDateRanges;
    }

    const range = dateRanges[path] ? dateRanges[path] : dateRanges.default;
    let rangeTo: Date = getDay('day', 'start', 0);
    const specialDates = ['today', 'yesterday'];
    if (specialDates.includes(range.to)) {
      const dateSubtraction =
        {
          today: 0,
          yesterday: 1,
        }[range.to] || 0;

      rangeTo = new Date();
      rangeTo.setDate(rangeTo.getDate() - dateSubtraction);
      rangeTo.setHours(0, 0, 0, 0);
    } else {
      rangeTo = getDayFromDateString(range.to, 'start');
    }

    const rangeFrom = DateTime.fromJSDate(rangeTo)
      .minus(range.range)
      .toJSDate();

    this.dateRange = {
      to: rangeTo,
      from: rangeFrom as Date,
    };

    // Persist session value if present
    this.updateRangeFromSession(isGlobal);

    // Lastly, override with query string if set
    this.updateRangeFromQueryString(appConfig, isGlobal);
  }

  getDateFilters(isGlobal = true) {
    // Make sure app's config is ready and available before we set default values
    if (!this.isDateRangeDefaultSet || !isQueryStringAvailable()) {
      this.isDateRangeDefaultSet = true;
      this.setDefaultDateRange(isGlobal);
    }
    return {
      from: this.dateRange.from,
      to: this.dateRange.to,
    };
  }

  getPreviousDateRange() {
    if (this.previousDateRange) {
      return {
        from: this.previousDateRange.from,
        to: this.previousDateRange.to,
      };
    }
    return null;
  }

  setDateFilters(dateRange: IDateRangeSelection, isGlobal = true) {
    this.previousDateRange = Object.assign({}, this.dateRange);
    this.dateRange = {
      from: getDayFromDateString(dateRange.from, 'start'),
      to: getDayFromDateString(dateRange.to, 'end'),
    };
    this.updateSessionDateFilter(isGlobal);
  }

  getDatesStrings() {
    const { from, to } = this.dateRange;
    return {
      from: dateToStringYMd(from),
      to: dateToStringYMd(to),
    };
  }

  handleActions(
    action?: { type: string; dateRange: IDateRangeSelection; route: string },
    isGlobal = true
  ) {
    if (action) {
      switch (action.type) {
        case appActionTypes.UPDATE_DATE_RANGE:
          this.setDateFilters(action.dateRange, isGlobal);
          this.routeChangedDateRange = action.route;
          this.emit(CHANGE_EVENT);
          break;
        default:
      }
    }
  }
}

export const dateRangeStore = new DateRangeStore();
dispatcher.register(dateRangeStore.handleActions.bind(dateRangeStore));
