/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext } from 'react';
// import { actionTypes } from 'src/@reports/actionTypes';
import { ReportsStateContext } from 'src/@reports/providers/ReportsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { IFilterState, IFilterSelectors, ITableFilterItem } from 'src/@reports/interfaces';
import { actionTypes } from 'src/@reports/actionTypes';
// constants
import { NULL_VALUE } from 'src/constants';

export const useFilterSelectors: () => IFilterSelectors = () => {
  const state: any = useContext(ReportsStateContext);
  const filterState: IFilterState = state.filter;

  return useSelectors(filterState, (state: IFilterState) => ({
    getIfInitialised: () => state.isInitialised,
    getFilters: () => state.tableFilters,
    getFilterString: () => {
      // Manually formats the selected filters to fit what is required
      // Unlike JSON.stringify, the key side needs to be unquoted
      // For operation types and aircraft categories, each value in the array must be unquoted as well,
      // as they are enum types in the back-end.
      // Example outputs: {operationTypes: [Arrival], runwayNames: ["04L"], aircraftCategories: [BusinessJet]}
      // OR {} (initially)

      // isAvailable is always set to true for reports
      let filterString = 'isAvailable: true';

      Object.entries(state.tableFilters).map(([key, value]: any) => {
        const valuesToFormat = ['reportInterval'];
        const keysToPluralize = ['templateName'];

        const selectedKeys = value.reduce(
          (acc: Array<string | null>, current: ITableFilterItem) => {
            const currentKey = current.key === NULL_VALUE ? null : current.key;
            return acc.concat(currentKey);
          },
          []
        );
        if (selectedKeys.length > 0) {
          // Add comma if it's not the first filter to be added
          if (filterString !== '') {
            filterString += ', ';
          }

          const values = JSON.stringify(selectedKeys);
          const formattedValues = valuesToFormat.includes(key) ? values.replace(/\"/g, '') : values;
          const pluralizedKeys = keysToPluralize.includes(key) ? `${key}s` : key;

          filterString += `${pluralizedKeys}: ${formattedValues}`;
        }
      });

      return `filter: {${filterString}}`;
    },
  }));
};

const initialStateObj: IFilterState = {
  isInitialised: false,
  tableFilters: {
    reportInterval: [],
    reportFormat: [],
    dataType: [],
    templateName: [],
  },
};

export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);

export const filterReducer = (state: IFilterState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_ITEMS:
      return Object.assign({}, state, {
        tableFilters: {
          ...state.tableFilters,
          [action.data.category]: action.data.selectedItems,
        },
      });
    case actionTypes.CLEAR_SELECTED_ITEMS:
      return Object.assign({}, initialStateObj, {
        isInitialised: true,
      });
    default:
      return state;
  }
};
