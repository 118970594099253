import { useEffect, useState } from 'react';
// selectors
import { useFilterDataSelectors } from 'src/app/reducers';
// functions
import {
  IMap,
  getDateString,
  setSelectionFeatureState,
  setLayerStyles,
  mapboxStyleBackgroundNormalPaint,
  mapboxStyleForegroundPaint,
  mapboxStyleHoverPaint,
  getInterpolatedOpacity,
  getTotalCountForLayer,
  useDebouncedEffect,
} from 'src/utils';
// reducers
import { useConfigSelectors } from 'src/app/reducers';
// constants
import { INFRINGEMENT_RULE_TYPES, NULL_VALUE } from 'src/constants';
import { isCompletedTimeFormat } from '@ems/client-design-system';

import { DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';

/**
 * Singular values for filters that map to the data keys
 */

const filterKeyMapping = {
  acid: 'acid',
  aircraftCategories: 'aircraftCategory',
  operatorCategories: 'operatorCategory',
  operationTags: 'operationTag',
  aircraftTypes: 'aircraftType',
  airportIds: 'airportId',
  operationTypes: 'operationType',
  runwayNames: 'runwayName',
  remoteAirportIds: 'remoteAirportId',
  airlines: 'airline',
  correlated: 'correlated',
  pathname: 'pathname',
  tailNumber: 'tailNumber',
};

export const useFilterMap = ({
  mapApis,
  mapBoxConfig,
  selectedFilters,
  timeFilter,
  isFilterApplied,
}: {
  mapApis: IMap | null;
  mapBoxConfig;
  selectedFilters;
  timeFilter;
  isFilterApplied: boolean;
}) => {
  // filters data
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();

  const [layers, updateLayers]: any = useState([]);
  const { opsTypeFilter } = getMapFilter(
    operationFilterData,
    isFilterApplied,
    selectedFilters,
    timeFilter
  );

  const [opstypeFilter, setOpsTypeFilter] = useState(opsTypeFilter);
  useEffect(() => {
    const { opsTypeFilter } = getMapFilter(
      operationFilterData,
      isFilterApplied,
      selectedFilters,
      timeFilter
    );
    setOpsTypeFilter(opsTypeFilter);
  }, [selectedFilters, timeFilter]);

  const [count, setCount] = useState<number>(0);
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');

  useEffect(() => {
    if (mapApis) {
      const totalCount = getTotalCountForLayer(mapApis, mapBoxConfig, opstypeFilter);
      setCount(totalCount);
    }
  }, [mapApis, opstypeFilter]);

  useEffect(() => {
    // update layers for operations maps
    updateLayers([
      {
        prefix: mapBoxConfig.backgroundLayerPrefix,
        style: Object.assign({}, mapboxStyleBackgroundNormalPaint(selectedTrackTheme), {
          'line-opacity': getInterpolatedOpacity({ filter: opsTypeFilter, totalCount: count }),
        }),
      },
      { prefix: mapBoxConfig.foregroundLayerPrefix, style: mapboxStyleForegroundPaint },
      { prefix: 'hovered_', style: mapboxStyleHoverPaint },
      { prefix: 'selection_', style: mapboxStyleForegroundPaint },
    ]);
  }, [selectedFilters]);

  return { layers, opstypeFilter };
};

/**
 * Provides style filter for map
 */

export const getMapFilter = (operationFilterData, isFilterApplied, selectedItems, timeFilter) => {
  const opsTypeFilter: any[] = ['all'];

  if (isFilterApplied) {
    // Checking filters
    const filters = selectedItems;
    const filterKeys = Object.keys(filters);

    if (filterKeys.length) {
      filterKeys.forEach(filterKey => {
        const opsFilter = filters[filterKey];
        const opsFilterLength = opsFilter.length;

        if (opsFilterLength) {
          const opsFilterArray: any[] = ['any'];
          const mapKey: string = filterKeyMapping[filterKey];
          // Because our type checking is broken
          if (!mapKey) {
            throw new Error(`\n\n mapKey is ${typeof mapKey}; check your filterKeyMapping\n`);
          }

          for (let i = opsFilterLength; i--; ) {
            if (filterKey === 'correlated') {
              const correlationSelected = opsFilter[i].key;
              if (correlationSelected === NULL_VALUE) {
                opsFilterArray.push(['has', 'hasNoCorrelations']);
              } else if (correlationSelected === 'Infringements') {
                const checkAny: any[] = ['any'];
                operationFilterData.correlated.forEach(type => {
                  if (type === INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT) {
                    type = 'CCO';
                  } else if (type === INFRINGEMENT_RULE_TYPES.CDO_INFRINGEMENT) {
                    type = 'CDO';
                  }
                  checkAny.push(['has', 'has' + type]);
                });
                opsFilterArray.push(checkAny);
              } else {
                // for correlated, we add properties to map data for each infringement
                let type = opsFilter[i].key.split(' ').join('');

                if (type === INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT) {
                  type = 'CCO';
                } else if (type === INFRINGEMENT_RULE_TYPES.CDO_INFRINGEMENT) {
                  type = 'CDO';
                }
                opsFilterArray.push(['has', 'has' + type]);
              }
            } else {
              // empty for null value
              const checkValue = opsFilter[i].key === NULL_VALUE ? '' : opsFilter[i].key;
              opsFilterArray.push(['==', ['get', mapKey], checkValue]);
            }
          }
          opsTypeFilter.push(opsFilterArray);
        }
      });
    }

    // Time filters
    const { from, to } = timeFilter;
    if (isCompletedTimeFormat(from) && isCompletedTimeFormat(to)) {
      const timefilterTo = ['<', ['get', 'operationTime'], to + ':00'];
      const timefilterFrom = ['>', ['get', 'operationTime'], from + ':00'];

      // If from <= to, such as [01:00, 12:00], we expect both conditions to be met as it will sit both after from and before to
      // If from > to, such as [12:00, 01:00], we can only satisfy 1 condition or the other but cannot satisfy both.

      const modifier = from <= to ? 'all' : 'any';
      const timefilter = [modifier, timefilterTo, timefilterFrom];
      opsTypeFilter.push(timefilter);
    }
  }

  return { opsTypeFilter };
};

/**
 * Custom hook for operations in Map
 *
 * @param mapApis - Mapbox API
 * @param dateRangeMapping - date mapping object for maps
 * @param datesArray - array of date strings
 */

export const useOperationsDataInMap = ({
  mapApis,
  dateRangeMapping,
  datesArray,
  mapBoxConfig,
  requiredDataForMap,
  selectedOperations,
  opstypeFilter,
  viewport,
  layerCount,
  disableTracks = false,
  areOperationsSet,
}: {
  mapApis: IMap | null;
  dateRangeMapping;
  datesArray: string[];
  mapBoxConfig;
  requiredDataForMap;
  selectedOperations;
  opstypeFilter;
  viewport;
  layerCount;
  disableTracks: boolean;
  areOperationsSet?: boolean;
}) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

  useDebouncedEffect(
    () => {
      if (mapApis) {
        const count = getTotalCountForLayer(mapApis, mapBoxConfig, opstypeFilter);
        setTotalCount(count);
      }
    },
    100,
    [mapApis, datesArray, selectedOperations, opstypeFilter, viewport, layerCount]
  );

  useEffect(() => {
    if (!areOperationsSet) {
      if (mapApis) {
        const { selectionLength } = requiredDataForMap;
        let lineOpacity: any = getInterpolatedOpacity({ filter: opstypeFilter, totalCount });
        if (selectionLength) {
          lineOpacity = getInterpolatedOpacity({
            filter: opstypeFilter,
            totalCount,
            multiplier: 0.33,
          });
        }
        setLayerStyles({
          mapApis,
          mapBoxConfig,
          backgroundStyle: Object.assign({}, mapboxStyleBackgroundNormalPaint(selectedTrackTheme), {
            'line-opacity': disableTracks ? 0 : lineOpacity,
          }),
        });
      }
    }
  }, [totalCount, requiredDataForMap, disableTracks]);

  useEffect(() => {
    if (!areOperationsSet) {
      // when map is ready
      if (mapApis && !disableTracks) {
        // remove tracks when filter changes
        selectedOperations.forEach(operation => {
          const dateString = getDateString(operation.time, dateRangeMapping);
          setSelectionFeatureState({
            mapApis,
            mapBoxConfig,
            operation,
            dateString,
            removeFeature: true,
            dynamicTileServer: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
          });
        });

        const { addedToSelection, removedFromSelection } = requiredDataForMap;

        // process selection removal
        if (removedFromSelection.length) {
          removedFromSelection.forEach(operation => {
            const dateString = getDateString(operation.time, dateRangeMapping);
            setSelectionFeatureState({
              mapApis,
              mapBoxConfig,
              operation,
              dateString,
              removeFeature: true,
              dynamicTileServer: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
            });
          });
        }

        // process new selection
        if (addedToSelection.length) {
          addedToSelection.forEach(operation => {
            const dateString = getDateString(operation.time, dateRangeMapping);
            setSelectionFeatureState({
              mapApis,
              mapBoxConfig,
              operation,
              dateString,
              removeFeature: false,
              dynamicTileServer: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
            });
          });
        }
      }
    }
  }, [mapApis, disableTracks, datesArray, selectedOperations, opstypeFilter]);
};
