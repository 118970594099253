import { useCallback } from 'react';
import { useSetCursor } from '../useSetCursor';
import { Cursor } from 'src/components/Map/map.types';

export const useOnMouseEnter = () => {
  const setCursor = useSetCursor();
  return useCallback(() => {
    setCursor(Cursor.pointer);
  }, [setCursor]);
};
