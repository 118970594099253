import React from 'react';
import classNames from 'classnames';
import uuid from 'uuid';

// Components
import { Radio, Checkbox } from '@ems/client-design-system';
import { SettingsSelectorWrapper } from '.';

// Types
import { ISelectorOption } from '.';

export const RadioSelectors = ({
  options,
  className,
  onChange,
  selected,
}: {
  options: ISelectorOption[];
  className: string;
  onChange: (value: string) => void;
  selected: string[];
}) => (
  <>
    {options.map((option, key) => {
      const { label, value, body } = option;
      return (
        <div
          key={key}
          className={classNames(`${className}__group settings-selector__group`, value)}>
          <Radio
            label={label}
            value={value}
            checked={selected.includes(value)}
            onChange={() => onChange(value)}
            className={`${className}__radio settings-selector__radio`}
          />
          {body}
        </div>
      );
    })}
  </>
);

export const CheckboxSelectors = ({
  options,
  className,
  onChange,
  selected,
}: {
  options: ISelectorOption[];
  className: string;
  onChange: (value: string) => void;
  selected: string[];
}) => {
  const groupName = uuid.v4();
  return (
    <>
      {options.map((option, key) => {
        const { label, value, body } = option;
        return (
          <div
            key={key}
            className={classNames(`${className}__group settings-selector__group`, value)}>
            <Checkbox
              name={`settings_${groupName}`}
              label={label}
              value={value}
              checked={selected.includes(value)}
              onChange={() => {
                onChange(value);
              }}
              className={`${className}__checkbox settings-selector__checkbox`}
            />
            {body}
          </div>
        );
      })}
    </>
  );
};

export const SettingsSelectorList = ({
  title,
  className,
  options,
  selected,
  onChangeHandler,
  selectMultiple = false,
}: {
  title: string;
  className: string;
  options: ISelectorOption[];
  selected: string[];
  onChangeHandler: (value: string) => void;
  selectMultiple?: boolean;
}) => {
  const onChange = (value: string) => {
    onChangeHandler(value);
  };

  return (
    <SettingsSelectorWrapper className={className} title={title}>
      {selectMultiple ? (
        <CheckboxSelectors
          options={options}
          className={className}
          onChange={onChange}
          selected={selected}
        />
      ) : (
        <RadioSelectors
          options={options}
          className={className}
          onChange={onChange}
          selected={selected}
        />
      )}
    </SettingsSelectorWrapper>
  );
};
