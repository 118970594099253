import React, { useState } from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import classNames from 'classnames';
import { Dialog, Icons } from '@ems/client-design-system';
import { UploadKML, ExportKML } from './UploadKMLDialog';
import { CustomMapControls } from 'src/components/MapControl/controls/CustomMapControls';

export const MapKMLControl = () => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        uploadKml: uploadKmlString,
        downloadKml,
        exportMapFeatures: exportMapFeaturesString,
      },
    },
  } = languageSelectors.getLanguage();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  const toggleUploadModal = () => {
    setIsUploadModalOpen(!isUploadModalOpen);
  };

  const toggleDownloadModal = () => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
  };

  return (
    <>
      <CustomMapControls>
        <button
          title={uploadKmlString}
          className={classNames(`mapboxgl-ctrl-icon`, `mapboxgl-ctrl-pin`)}
          onClick={toggleUploadModal}>
          <Icons size={20} iconName="ic-ui-upload" className={classNames(`pin-icon`)} />
        </button>
        <button
          title={downloadKml}
          className={classNames(`mapboxgl-ctrl-icon`, `mapboxgl-ctrl-pin`)}
          onClick={toggleDownloadModal}>
          <Icons size={20} iconName="ic-ui-download" className={classNames(`pin-icon`)} />
        </button>
      </CustomMapControls>
      {isUploadModalOpen && (
        <Dialog
          title={uploadKmlString}
          isCloseButtonShown
          onClose={toggleUploadModal}
          canOutsideClickClose={false}
          isOpen={isUploadModalOpen}>
          <UploadKML />
        </Dialog>
      )}
      {isDownloadModalOpen && (
        <Dialog
          title={exportMapFeaturesString}
          isCloseButtonShown
          onClose={toggleDownloadModal}
          canOutsideClickClose={false}
          isOpen={isDownloadModalOpen}>
          <ExportKML />
        </Dialog>
      )}
    </>
  );
};
