import styled from 'styled-components/macro';

// Colors
import styles from 'src/styles/vars.module.scss';

// Components
import { Dialog, Icons } from '@ems/client-design-system';

export const NoiseScenarioDialog = styled(Dialog)`
  min-width: 450px;
  width: 450px;

  .bp3-dialog-footer {
    justify-content: flex-end;
  }
`;

export const GenerateNoiseScenarioDialog = styled(NoiseScenarioDialog)`
  width: 515px;
`;

export const DialogInputWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.2rem;

  input[type='text'] {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 0px 10px 0 14px;
    line-height: 2.29;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='text'] {
      -moz-appearance: textfield;
    }
  }

  .dropdown_button {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    .filter-category-button {
      padding: 0px 10px 0 14px;
    }
    .filter-category-button,
    .bp3-popover-target {
      width: 100%;
    }
  }
`;

export const DialogForm = styled.form`
  max-width: 385px;
  margin: 0 auto;
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const DialogInput = styled.input`
  &::placeholder {
    font-style: italic;
    color: ${styles.ui05};
    font-weight: 500;
  }
`;

export const DialogInputError = styled.p`
  color: #ff0000;
  font-size: 14px;
`;

export const DialogFormLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const DialogFormTable = styled.table`
  font-size: 14px;
  text-align: right;

  th,
  td {
    padding: 9px;
  }
`;

export const DialogFormTableHeading = styled.tr`
  font-weight: 500;
  border-bottom: 1px solid ${styles.ui05};
`;

export const DialogFormTableTd = styled.td`
  &.title {
    font-weight: 600;
  }

  .inlineEdit__number-input,
  .inlineEdit__number .isActive {
    width: 82px;
  }
`;

export const DialogHeadings = styled.h3`
  font-size: 28px;
  margin-bottom: 40px;
  text-align: center;
`;

export const DialogResponseIcon = styled(Icons)`
  width: 110px;
  height: 110px;
  fill: ${styles.success};
  margin-bottom: 40px;
`;

export const DialogResponseIconError = styled(Icons)`
  width: 110px;
  height: 110px;
  fill: ${styles.error01};
  margin-bottom: 40px;
`;

export const DialogDownloadLink = styled.a`
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;
