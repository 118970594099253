export const fetchInfringementsQuery = 'infringementSummariesByTimeRange';
export const fetchInfringementsCandidatesQuery = 'infringementCandidateSummariesByTimeRange';
export const fetchInfringementSummary = 'infringement';
export const fetchOperationDetailsQuery = 'operationSummary';
export const fetchRuleDetailsQuery = 'infringementRule';
export const fetchNoiseEventQuery = 'noiseEventSummariesByIds';
export const fetchNoiseMonitorQuery = 'monitorLocationsByIds';
export const fetchContinuousNoiseQuery = 'continuousNoiseSamplesByTimeRange';
export const fetchNoiseMonitorByOpsQuery = 'monitorLocationsByAirportIds';

export const infringementsSchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    id
    operationId
    infringementType
    ruleName
    status
    aircraftType
    aircraftCategory
    acid
    time
    airportId
    position {
      altitude
      latitude
      longitude
    }
    severity
    operationType
    detail {
      ... on CorridorInfringement {
        corridorId
      }
      ... on ExclusionInfringement {
        selectionZoneId
      }
      ... on GateInfringement {
        gateId
        gateDirection
      }
      ... on NoiseInfringement {
        noiseEventIds
      }
    }
  }
}
`;

export const infringementsCandidatesSchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    operationId
    infringementType
    ruleId
    ruleName
    status
    aircraftType
    aircraftCategory
    acid
    isInfringement
    time
    airportId
    position {
      altitude
      latitude
      longitude
    }
    severity
    operationType
    infringementId
    rule {
      ... on CorridorInfringementRule {
        corridorId
      }
    }
  }
}
`;

export const summarySchema = `
{
  infringementType
  id
  ruleId
  ruleName
  operationId
  time
  comments
  position {
    altitude
    latitude
    longitude
  }
  ... on CcoInfringement {
    segments {
      startDistance
      endDistance
    }
  }
  ... on CdoInfringement {
    segments {
      startDistance
      endDistance
    }
  }
  ... on CorridorInfringement {
    corridorId
  }
  ... on NoiseInfringement {
    noiseEventIds
  }
}
`;

export const operationDetailsSchema = `
  {
    id
    acid
    airline
    aircraftCategory
    aircraftType
    airportId
    operationType
    remoteAirportId
    runwayName
  }
`;

export const operationTrackProfile = `
  {
    id
    profile {
      y: altitude
      x: distance
    }
  }
`;

export const ruleDetailsSchema = `
  {
    infringementType
    description
    name
    ... on CcoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CdoInfringementRule {
      id
      maxAltitudeVariation
      autoInfringementSegmentLength
      ceilingAltitude
      floorAltitude
      maxLevelSegments
      minLevelSegmentLength
    }
    ... on CorridorInfringementRule {
      id
      name
      allowedPassage
      ceilingAltitude
      corridorId
    }
    ... on MinHeightInfringementRule {
      id
      requiredAltitude
      flightDistance
    }
    ... on NoiseInfringementRule {
      metric
      thresholds {
        locationId
        threshold
      }
    }
  }
`;

export const infringementMapQuery = 'infringement';

export const infringementMapSchema = `
{
  id
  ... on CorridorInfringement {
    corridorId
  }
}
`;

export const noiseEventSchema = `
{
  audio {
    id
    startTime
    endTime
    resourceUri {
      uri
    }
  }
  endTime
  epnl
  id
  leq
  locationId
  locationName
  maxLevel
  samples {
    sampleType
    samples
  }
  sel
  startTime
  time
}`;

export const continuousNoiseSchema = `
{
  locationId
  startTime
  endTime
  sampleType
  sampleResolution
  samples
}
`;

export const noiseMonitorSchema = `
{
  id
  position {
    latitude
    longitude
  }
}
`;
