import { ChangeEvent } from 'react';
import { TPersistentStorageHook, useSessionStorage } from 'src/utils';

export const useSearchFilter = ({
  key,
  defaultValue = '',
  regexValidator,
}: {
  key: string;
  defaultValue?: string;
  regexValidator?: RegExp;
}) => {
  const [filterValue, setFilterValue]: TPersistentStorageHook = useSessionStorage(
    defaultValue,
    key
  );

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (regexValidator && (value === '' || regexValidator.test(value))) {
      setFilterValue(value);
    } else if (!regexValidator) {
      setFilterValue(value);
    }
  };

  const onClearFilter = () => {
    setFilterValue('');
  };

  return { onFilterChange, onClearFilter, filterValue };
};
