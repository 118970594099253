import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/@operations/props';
import { dataReducer } from 'src/@operations/reducers';
import { sortReducer } from 'src/@operations/reducers/sortReducer';
import { filterReducer } from 'src/@operations/reducers/filterReducer';
import { tagDataReducer } from 'src/app/reducers/tagDataReducer';
// Hooks
import { usePersistentReducer } from 'src/utils';
// import { useReducerWithLogger } from 'src/utils/storeHelpers';
import {
  dataInitialState,
  sortInitialState,
  filterInitialState,
} from 'src/@operations/reducers/initialStates';
import { tagDataInitialState } from 'src/app/reducers/initialStates';

export const OperationStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
});

export const OperationDispatchContext = createContext(null);

export const OperationsStateProvider = ({ children }) => {
  const [dataState, dataDispatch]: any = useReducer<any>(dataReducer, dataInitialState);
  const [sortState, sortDispatch]: any = useReducer<any>(sortReducer, sortInitialState);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [filterState, filterDispatch] = usePersistentReducer(
    filterReducer,
    filterInitialState,
    'operations.filter.state',
    'session'
  );
  const [tagDataState, tagDataDispatch]: any = useReducer<any>(tagDataReducer, tagDataInitialState);

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    filter: filterState,
    tagData: tagDataState,
  };

  const dispatchers = [dataDispatch, sortDispatch, filterDispatch, tagDataDispatch];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <OperationStateContext.Provider value={state}>
      <OperationDispatchContext.Provider value={dispatch}>
        {children}
      </OperationDispatchContext.Provider>
    </OperationStateContext.Provider>
  );
};
