import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';
import {
  complaintInfringementsQuery,
  complaintSummarySchema,
  privacyAgreementQuery,
  privacyAgreementSchema,
  nearestFlightQuery,
  nearestFlightSchema,
  singleComplaintQuery,
  singleComplaintSchema,
  operationIdsQuery,
  operationSchema,
  profilesByIdsQuery,
  operationProfileSchema,
  complainerMatchSchema,
  complainersByPartialMatch,
  complaintQuery,
  complaintSchema,
  complainerQuery,
  complainerSchema,
  frequentComplainersQuery,
  frequentComplainersSchema,
} from './schema';
import {
  IPrivacyAgreement,
  INearestFlight,
  INearestFlightFetch,
  ISelectFlightComplaint,
  ISingleComplaintFetch,
  IFetchData,
  IComplainer,
  IFetchFrequentComplainers
} from '../interfaces';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';

const FETCH_SIZE = 1000;
export const fetchData = ({
  client,
  sortString,
  filterString,
  count = FETCH_SIZE,
  cursor,
}: IFetchData) => {
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;

    const queryParams = `
    startTime: "${dateTimeInQuery(from, 'start')}"
    endTime: "${dateTimeInQuery(to, 'end')}"
    first: ${count}
    ${cursorParam}
  `;
    const GET_INFRINGEMENTS = gql`
    query {
      ${complaintInfringementsQuery}(${queryParams} ${sortString} ${filterString}) ${complaintSummarySchema}
    }
  `;
    client
      .query({
        query: GET_INFRINGEMENTS,
      })
      .then(response => {
        if (response && response.data) {
          const { items: complaintsData, pageInfo, totalCount } = response.data[complaintInfringementsQuery];
          resolve({
            data: complaintsData,
            selectedDateRange: { from, to },
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getNearestFlights = ({
  client,
  minTime,
  maxTime,
  position,
  maxHorizontalRange,
  maxAltitudeRange,
}: INearestFlightFetch) => {
  return new Promise<INearestFlight[]>((resolve, reject) => {
    const queryParams = `
    startTime: "${minTime}",
    endTime: "${maxTime}",
    first: 50,
    pcaFilter: { position: $position, horizontalRange: {start: 0, end: ${maxHorizontalRange}}, verticalRange: {start: 0, end: ${maxAltitudeRange}} }
    sort: [{field: "time", direction: ASC}]
    `;

    const GET_NEAREST_FLIGHTS = gql`
    query GetNearestFlights ($position: InputPositionType!){
      ${nearestFlightQuery}(${queryParams}) ${nearestFlightSchema}
    }
    `;

    client
      .query({
        query: GET_NEAREST_FLIGHTS,
        variables: {
          position: {
            latitude: position.latitude,
            longitude: position.longitude,
            altitude: position.altitude ? position.altitude : 0,
          },
        },
      })
      .then(response => {
        if (response && response.data) {
          const data = response.data[nearestFlightQuery];
          resolve(data.items);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchPrivacyAgreement = ({ client }) => {
  return new Promise<IPrivacyAgreement>((resolve, reject) => {

    const PRIVACY_AGREEMENT_QUERY = gql`
    query GetPrivacyAgreement {
      ${privacyAgreementQuery} ${privacyAgreementSchema}
    }
    `;

    client
      .query({
        query: PRIVACY_AGREEMENT_QUERY,
      })
      .then(response => {
        if (response && response.data) {
          const data = response.data[privacyAgreementQuery];
          resolve(data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchSingleComplaint = ({ client, id }: ISingleComplaintFetch) => {
  return new Promise<ISelectFlightComplaint>((resolve, reject) => {
    const queryParams = `id: ${id}`;

    const QUERY_STRING = gql`
    query GetSingleComplaint {
      ${singleComplaintQuery}(${queryParams}) ${singleComplaintSchema}
    }
    `;

    client
      .query({
        query: QUERY_STRING,
      })
      .then(response => {
        if (response && response.data) {
          const data = response.data[singleComplaintQuery];
          resolve(data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchOperationsByIds = (client: ApolloClient<object>, operationIds: number[] = []) => {
  return new Promise((resolve, reject) => {
    const GET_OPERATIONS_BY_ID = gql`
      query {
        ${operationIdsQuery}(
          ids: ${JSON.stringify(operationIds)}
        ) ${operationSchema}
      }
    `;

    client
      .query({ query: GET_OPERATIONS_BY_ID })
      .then(response => {
        if (response && response.data && response.data[operationIdsQuery]) {
          resolve({
            data: response.data[operationIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchProfilesById = (client: ApolloClient<object>, ids: number[]) => {
  return new Promise((resolve, reject) => {
    const GET_PROFILES_BY_ID = gql`
      query {
        ${profilesByIdsQuery}(ids: ${JSON.stringify(ids)}) ${operationProfileSchema}
      }
    `;

    client
      .query({ query: GET_PROFILES_BY_ID })
      .then(response => {
        if (response && response.data && response.data[profilesByIdsQuery]) {
          resolve({
            data: response.data[profilesByIdsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getComplainersByPartialMatch = ({ matchString, client, count = FETCH_SIZE }) => {
  return new Promise<IComplainer[]>((resolve, reject) => {
    const GET_INFRINGEMENTS = gql`
    query {
      ${complainersByPartialMatch}(first: ${count}, ${matchString} ) ${complainerMatchSchema}
    }
  `;
    client
      .query({
        query: GET_INFRINGEMENTS,
      })
      .then(response => {
        if (response && response.data) {
          const data = response.data[complainersByPartialMatch];
          resolve(data.items);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchComplaintById = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {

    const GET_COMPLAINT_SUMMARY_BY_ID = gql`
      query {
        ${complaintQuery}(id: ${id}) ${complaintSchema}
      }
    `;

    client
      .query({ query: GET_COMPLAINT_SUMMARY_BY_ID })
      .then(response => {
        if (response && response.data && response.data[complaintQuery]) {
          resolve({
            data: response.data[complaintQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchComplainerById = (client: ApolloClient<object>, id: number) => {
  return new Promise((resolve, reject) => {
    const queryParams = `
      id: ${id}
    `;
    const GET_COMPLAINT_SUMMARY_BY_ID = gql`
      query {
        ${complainerQuery}(${queryParams}) ${complainerSchema}
      }
    `;

    client
      .query({ query: GET_COMPLAINT_SUMMARY_BY_ID })
      .then(response => {
        if (response && response.data && response.data[complainerQuery]) {
          resolve({
            data: response.data[complainerQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchFrequentInquirers = ({ client, count = 10 }: IFetchFrequentComplainers) => {
  return new Promise<any>((resolve, reject) => {
    const queryParams = `
      first: ${count}
    `;
    const GET_FREQUENT_INQUIRERS = gql`
      query {
        ${frequentComplainersQuery}(${queryParams}) ${frequentComplainersSchema}
      }
    `;
    client
      .query({ query: GET_FREQUENT_INQUIRERS })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[frequentComplainersQuery] &&
          response.data[frequentComplainersQuery].items) {
          const { items: complainersData } = response.data[frequentComplainersQuery];
          resolve({
            data: complainersData,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
