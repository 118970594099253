import React, { FC } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Button, Spinner } from '@ems/client-design-system';
import { ILoadMoreBar } from './interfaces';

export const LoadMoreBar: FC<ILoadMoreBar> = ({
  isVisible = false,
  isLoadingMore,
  dispatcher,
  loadMore,
  resultSize,
  endCursor,
  sortString,
  filterString,
  showCandidates,
  pcaData = {},
  permissions,
  atcView = false,
  loadMoreText,
  featureFlags,
  availableFilters,
}) => {
  const client = useApolloClient();
  if (!isVisible || !endCursor) {
    return null;
  }

  return (
    <div className="load-more-bar">
      <Spinner loading={isLoadingMore}>
        <Button
          style="primary"
          onClick={() => {
            loadMore(client, dispatcher, {
              resultSize,
              endCursor,
              sortString,
              filterString,
              showCandidates,
              pcaData,
              permissions,
              atcView,
              featureFlags,
              availableFilters,
            });
          }}>
          {loadMoreText}
        </Button>
      </Spinner>
    </div>
  );
};
