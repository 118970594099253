import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { ComplaintsStateProvider } from 'src/@complaints/providers/ComplaintsStateProvider';
import {
  DataContainer,
  MapDataContainer,
  TableContainer,
  HeaderContainer,
  FilterContainer,
} from 'src/@complaints/containers';
import { useConfigSelectors } from 'src/app/reducers';

const ComplaintsScreen = ({ location, updateUrl }) => {
  const locationState = location.state;
  const fromCorrelated =
    locationState && locationState.hasOwnProperty('fromCorrelated')
      ? locationState.fromCorrelated
      : false;
  const correlatedIds = fromCorrelated ? location.state.ids : [];

  // Configuration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const configSelectors = useConfigSelectors();

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();

  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  return (
    <SplitLayout>
      <ComplaintsStateProvider>
        <DataContainer correlatedIds={correlatedIds}>
          {!isGridFullscreen && <MapDataContainer />}

          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer fromCorrelated={fromCorrelated} updateUrl={updateUrl} />
            </div>
            <FilterContainer />
            <TableContainer />
          </div>
        </DataContainer>
      </ComplaintsStateProvider>
    </SplitLayout>
  );
};

export const Complaints = withRouteProps(ComplaintsScreen);
