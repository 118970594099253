export const complaintInfringementsQuery = 'complaintSummariesByTimeRange';
export const operationIdsQuery = 'operationsByIds';
export const profilesByIdsQuery = 'operationSummariesByIds';
export const complainersByPartialMatch = 'complainersByPartialMatch';
export const complaintQuery = 'complaint';
export const complainerQuery = 'complainer';
export const privacyAgreementQuery = 'privacyAgreementByIsActive';
export const frequentComplainersQuery = 'complainersByMostLikely';
export const operationPcaByTimeRangeQueryName = 'operationPcaByTimeRange';
export const operationSummariesByIdsQueryName = 'operationSummariesByIds';

export const complaintSummarySchema = `{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    id
    time
    status
    acid
    runwayName
    aircraftCategory
    aircraftType
    airline
    complainerName
    complainerId
    operatorCategory
    contactMethod
    complainerPosition {
      altitude
      latitude
      longitude
    }
    complainerPostcode
    disturbanceTypes
    operationId
    complainer
    {
      email
      preferredResponseMethod
      phoneNumbers
      {
        number
      }
      address
      {
        streetAddress
        streetAddress2
        city
        country
        state
        postcode
      }
    }
  }
}`;

export const nearestFlightQuery = 'operationSummariesByTimeRange';
export const nearestFlightSchema = `
{
  totalCount
  items {
    id
    acid
    operationType
    aircraftCategory
    aircraftType
    runwayName
    startTime
    endTime
    points {
      alt
      lat
      lon
      t
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    pca(position: $position) {
      elevationAngle
      slantDistance
      horizontalDistance
      verticalDistance
      time
    }
  }
}
`;

export const singleComplaintQuery = 'complaintSummary';
export const singleComplaintSchema = `
{
  id
  complainerId
  complainerPosition {
    latitude
    longitude
    altitude
  }
  operationId
  time
}
`;

export const operationSchema = `
{
  id
  startTime
  endTime
  acid
  airline
  runwayName
  airportId
  operationType
  aircraftCategory
  points
  {
    t
    lat
    lon
    alt
  }
}`;

export const operationProfileSchema = `
  {
    id
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
  }
`;

export const complainerMatchSchema = `
  {
    items {
      id
      title
      firstName
      email
      middleName
      lastName
      comments
      preferredResponseMethod
      phoneNumbers {
        number
      }
      address
      {
        country
        city
        postcode
        state
        streetAddress
        streetAddress2
        position {
          latitude
          longitude
          altitude
        }
      }
    }
  }
`;

export const complaintSchema = `
{
  id
  complainerId
  comments
  time
  disturbanceTypes
  responseRequired
  privacyAgreementId
  reportedTime
  notes
  {
    id
    createdBy
    body
  }
}`;

export const privacyAgreementSchema = `
{
  id
  acceptanceTerm
  agreement
}`;

export const complainerSchema = `
{
  address {
    city
    country
    position {
      latitude
      longitude
      altitude
    }
    postcode
    streetAddress2
    streetAddress
    state
  }
  firstName
  lastName
  title
  preferredResponseMethod
  email
  phoneNumbers {
    number
    type
  }
  comments
}`;

export const frequentComplainersSchema = `
{
  items {  
    id  
    address { 
      city
      country
      position {
        latitude
        longitude
      }
      postcode
      streetAddress2
      streetAddress
      state
    }
    firstName
    lastName
    title
    preferredResponseMethod
    email
    phoneNumbers {
      number
      type
    }
    comments
  }
}`;

export const operationPcaByTimeRangeSchema = `
  {
    slantDistance
    horizontalDistance
    verticalDistance
    elevationAngle
    time
    operation
    {
      id
      acid
      operationType
      runwayName
      aircraftCategory
      aircraftType
      airportId
    }
  }
`;
