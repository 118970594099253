import React, { useEffect, useRef, useState } from 'react';
import { FileInput, FileInputLabel, ValidateIcon } from './MapKMLControls.styles';
import { Button, TextInput } from '@ems/client-design-system';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import { useDownloadMapFeatures } from './useDownLoadMapFeatures';
import { removeSavedGeoJson } from '../../../utils';
import { kml as convertKmlToGeoJson } from '@tmcw/togeojson';

// interface KmlData {
//name: string;
//content: string;
//}

export const kmlToGeoJSON = (kmlData: string): GeoJSON.FeatureCollection => {
  if (kmlData) {
    return convertKmlToGeoJson(
      new DOMParser().parseFromString(kmlData, 'text/xml')
    ) as GeoJSON.FeatureCollection;
  }
  return null;
};

export const validateGeoJSONType = (
  geoJSON: GeoJSON.FeatureCollection,
  type: 'Point' | 'LineString'
): boolean => !geoJSON.features.some(feature => feature.geometry.type !== type);

export const UploadKML = () => {
  const { dispatch } = useTaxiPathContext();

  const [kmlPathFile, setKmlPathFile] = useState<File | null>(null);
  const [kmlPathData, setKmlPathData] = useState<GeoJSON.FeatureCollection>(null);
  const [isPathJsonValid, setPathJsonValid] = useState<boolean>(false);
  const [kmlNodeFile, setKmlNodeFile] = useState<File | null>(null);
  const [kmlNodeData, setKmlNodeData] = useState<GeoJSON.FeatureCollection>(null);
  const [isNodeJsonValid, setNodeJsonValid] = useState<boolean>(false);

  const pathFileInputRef = useRef<HTMLInputElement>(null);
  const nodeFileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (kmlNodeData) {
      setNodeJsonValid(validateGeoJSONType(kmlNodeData, 'Point'));
    }
    if (kmlPathData) {
      setPathJsonValid(validateGeoJSONType(kmlPathData, 'LineString'));
    }
  }, [kmlNodeData, kmlPathData]);

  const drawMapFeatures = () => {
    // Clear existing map data
    removeSavedGeoJson(['taxipath-linestring', 'taxipath-nodes']);
    dispatch({ type: TaxiPathActions.CLEAR_NODES, payload: '' });

    // Update states
    dispatch({ type: TaxiPathActions.SET_PATH_GEOJSON, payload: kmlPathData });
    dispatch({ type: TaxiPathActions.SET_NODE_GEOJSON, payload: kmlNodeData });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'node' | 'path') => {
    const file = e.target.files?.[0] || null;
    if (file) {
      if (type === 'path') {
        setKmlPathFile(file);
      }
      if (type === 'node') {
        setKmlNodeFile(file);
      }
      const reader = new FileReader();
      reader.onload = ev => handleFileRead(ev, type);
      reader.readAsText(file);
    }
  };

  const handleFileRead = (e: ProgressEvent<FileReader>, type: 'node' | 'path') => {
    const content = e.target?.result as string;

    if (type === 'node') {
      setKmlNodeData(kmlToGeoJSON(content));
    }

    if (type === 'path') {
      setKmlPathData(kmlToGeoJSON(content));
    }
  };

  const handleOpenFileInput = (type: 'node' | 'path') => {
    if (type === 'node' && !!nodeFileInputRef.current) {
      nodeFileInputRef.current.click();
    }
    if (type === 'path' && !!pathFileInputRef.current) {
      pathFileInputRef.current.click();
    }
  };

  return (
    <div className="bp3-dialog-body">
      <p>Select KML files to upload. A path file and a node file are required</p>
      <br />
      <p style={{ marginBottom: '15px' }}>Uploading new files will clear existing map data.</p>

      <FileInput ref={pathFileInputRef} onChange={e => handleFileChange(e, 'path')} />
      <FileInput ref={nodeFileInputRef} onChange={e => handleFileChange(e, 'node')} />

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '20px' }}>
          <Button size="s" onClick={() => handleOpenFileInput('path')}>
            Paths KML File
          </Button>
          {kmlPathData && (
            <FileInputLabel>
              {kmlPathFile.name} <ValidateIcon isValid={isPathJsonValid} />
            </FileInputLabel>
          )}
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <Button size="s" onClick={() => handleOpenFileInput('node')}>
            Nodes KML File
          </Button>
          {kmlNodeData && (
            <FileInputLabel>
              {kmlNodeFile.name}
              <ValidateIcon isValid={isNodeJsonValid} />
            </FileInputLabel>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!isNodeJsonValid || !isPathJsonValid}
            style="primary"
            onClick={drawMapFeatures}>
            Draw Map
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ExportKML = () => {
  const [fileName, setFileName] = useState<string>('');
  const { exportToGeoJson, exportToKml } = useDownloadMapFeatures();

  return (
    <div className="bp3-dialog-body">
      <p>Select format to export</p>
      <br />

      <fieldset>
        <label style={{ marginBottom: '5px', display: 'block' }} htmlFor="fileName">
          Airport Name
        </label>
        <TextInput
          name="fileName"
          value={fileName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFileName(e.target.value);
          }}
        />
      </fieldset>
      <div style={{ display: 'flex', marginTop: '15px', gap: '10px' }}>
        <Button style="primary" onClick={() => exportToKml(fileName, 'paths')}>
          Paths KML
        </Button>
        <Button style="primary" onClick={() => exportToGeoJson(fileName, 'paths')}>
          Paths GeoJSON
        </Button>
      </div>
      <div style={{ display: 'flex', marginTop: '15px', gap: '10px' }}>
        <Button style="primary" onClick={() => exportToKml(fileName, 'nodes')}>
          Nodes KML
        </Button>
        <Button style="primary" onClick={() => exportToGeoJson(fileName, 'nodes')}>
          Nodes GeoJSON
        </Button>
      </div>
    </div>
  );
};
