import React from 'react';

// Functions
import { formatFilterData } from 'src/@settings/containers/Rules/InfringementRuleDetails/InfringementRuleDetailsHelpers';

// Types
import {
  ICreateScenarioTranslations,
  IFilterKeyData,
  IScenarioFormValues,
} from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';
import { FormikProps } from 'formik';

// Components
import {
  ScenarioFormLabel,
  ScenarioFormInput,
  ScenarioFormError,
} from './CreateScenarioForm.styles';
import { Filter } from '@ems/client-design-system';

const arrayToFilterItems = (selectedItems, listValues) => {
  if (Array.isArray(selectedItems) && selectedItems.length) {
    return listValues.filter(item => selectedItems.includes(item.key));
  } else {
    return [];
  }
};

export const createScenarioFilter = ({
  formik,
  translations,
  listValues,
  name,
  key,
  isMultiple = false,
  iconTheme,
  hasIcons = false,
}: {
  formik: FormikProps<IScenarioFormValues>;
  translations: ICreateScenarioTranslations;
  listValues: Array<{
    key: string;
    label: string;
    icon?: string;
  }>;
  name: string;
  key: string;
  isMultiple?: boolean;
  iconTheme?: Record<string, string>;
  hasIcons?: boolean;
}) => {
  const selectedFilterItems: IFilterKeyData[] = hasIcons
    ? formatFilterData(key, formik.values[key], translations[key])
    : arrayToFilterItems(formik.values[key], listValues);

  return (
    <ScenarioFormInput className={`scenarioGeneration__${key}`}>
      <ScenarioFormLabel htmlFor={key}>{name}</ScenarioFormLabel>
      <Filter
        key={key}
        categoryName={name}
        isCategoryNameRequired={false}
        filterItems={listValues}
        selectedItems={selectedFilterItems}
        type={isMultiple ? 'multiple' : 'single'}
        hasTruncationOptions={isMultiple}
        iconCategories={hasIcons && [key]}
        theme={iconTheme}
        updateItems={(items: IFilterKeyData[]) => {
          formik.setFieldValue(
            key,
            items.map(item => item.key)
          );
        }}
        languageData={translations.filterLanguageData}
        showSelectAll={isMultiple}
      />
      <ScenarioFormError>{formik.errors[key] || <>&nbsp;</>}</ScenarioFormError>
    </ScenarioFormInput>
  );
};
