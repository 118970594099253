import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
// props
import { IExport, IExportFilter, IRequiredImage, TExportImage } from 'src/app/props';
// data
import { noContentImage } from 'src/app/data';

export const exportData = ({
  client,
  requestExport,
  filter,
  requiredImages,
  noContentImages = [],
}: {
  client: ApolloClient<object>;
  requestExport: IExport;
  filter: IExportFilter;
  source?: string;
  requiredImages?: IRequiredImage;
  noContentImages?: TExportImage[];
}) => {
  return new Promise((resolve, reject) => {
    const queryName = 'exportData';
    const { format, templateName, outputFilename } = requestExport;
    const count = Object.keys(filter)
      .map(key => filter[key].length)
      .reduce((a, b) => a + b, 0);
    let filterString: any = JSON.stringify(filter);
    filterString = filterString.replaceAll('"', '');
    // if requiredImages is set and images need to be included in the query
    let trackmapImageValue = '';
    let altitudeImageValue = '';
    let noiseImageValue = '';
    let imageDataString = '';
    if (typeof requiredImages !== 'undefined') {
      const { trackmapImage, altitudeImage, noiseImage } = requiredImages;
      if (typeof trackmapImage !== 'undefined') {
        trackmapImageValue = trackmapImage;
        imageDataString += '$trackmapImage, ';
      } else if (noContentImages.indexOf('trackmapImage') !== -1) {
        trackmapImageValue = noContentImage;
        imageDataString += '$trackmapImage, ';
      }
      if (typeof altitudeImage !== 'undefined') {
        altitudeImageValue = altitudeImage;
        imageDataString += '$altitudeImage, ';
      } else if (noContentImages.indexOf('altitudeImage') !== -1) {
        altitudeImageValue = noContentImage;
        imageDataString += '$altitudeImage, ';
      }
      if (typeof noiseImage !== 'undefined') {
        noiseImageValue = noiseImage;
        imageDataString += '$noiseImage, ';
      } else if (noContentImages.indexOf('noiseImage') !== -1) {
        noiseImageValue = noContentImage;
        imageDataString += '$noiseImage, ';
      }
      imageDataString = `[${imageDataString.substring(0, imageDataString.length - 2)}]`;
    }
    const EXPORT_DATA = gql`
      query ExportToFile (
        $filter: ExportFilterType!
        $format: ExportFormat!
        $templateName: String
        $outputFilename: String
        ${trackmapImageValue && '$trackmapImage: ImageDataType'}
        ${altitudeImageValue && '$altitudeImage: ImageDataType'}
        ${noiseImageValue && '$noiseImage: ImageDataType'}
      ) {
        ${queryName}(
          filter: $filter
          format: $format
          templateName: $templateName
          outputFilename: $outputFilename
          ${imageDataString && `imageData: ${imageDataString}`}
        )
      }
    `;
    // fetch query
    client
      .query({
        query: EXPORT_DATA,
        variables: {
          filter,
          format,
          templateName,
          outputFilename: count > 1 ? `${outputFilename} - ${count} selected` : outputFilename,
          trackmapImage: trackmapImageValue
            ? { key: 'trackmapImage', value: trackmapImageValue }
            : null,
          altitudeImage: altitudeImageValue
            ? { key: 'altitudeImage', value: altitudeImageValue }
            : null,
          noiseImage: noiseImageValue ? { key: 'noiseImage', value: noiseImageValue } : null,
        },
      })
      .then(response => {
        if (response && response.data && response.data[queryName]) {
          const requestId = response.data[queryName];
          resolve(requestId);
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const exportResult = ({
  client,
  requestKey,
}: {
  client: ApolloClient<object>;
  requestKey: string;
}): any => {
  return new Promise((resolve, reject) => {
    const queryName = 'exportResult';
    const EXPORT_RESULT = gql`
    query GetExportStatus {
      exportResult(key: "${requestKey}") {
        status
        key
        downloadResource {
          authenticationScheme
          uri
        }
      }
    }
    `;
    // fetch query
    client
      .query({
        query: EXPORT_RESULT,
      })
      .then(response => {
        if (response && response.data && response.data[queryName]) {
          const downloadStatus = response.data[queryName];
          resolve(downloadStatus);
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
