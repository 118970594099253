import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Page contents
import { SpatialFeaturesMap } from './SpatialFeaturesMap';

// Components
import { PageHeader } from 'src/components';
import { ViewSpatialFeature } from './ViewSpatialFeature/ViewSpatialFeature';

import { Card, displaySuccess, SkeletonText } from '@ems/client-design-system';
import { EditSpatialFeature } from './EditSpatialFeature/EditSpatialFeature';

// Fetch/Mutate data
import { useApolloClient } from '@apollo/react-hooks';
import { fetchGateById } from 'src/@settings/resolvers/gateResolver';
import { fetchCorridorById } from 'src/@settings/resolvers/corridorResolver';
import { fetchSelectionZoneById } from 'src/@settings/resolvers/selectionZoneResolver';

// Actions/reducers/selectors
import { addNewlyLoadedFeature, spatialFeatureUpdated } from 'src/@settings/actions';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import { useConfigSelectors, useLanguageSelectors, useRolesSelectors } from 'src/app/reducers';

// Interfaces
import { ISpatialFeature, ISpatialFeatureForm } from 'src/@settings/interfaces';
import { IPosition } from '@ems/client-design-system/dist/components/Geometry/interfaces';

import {
  GQL_MUTATION_TYPES,
  IMPERIAL_BASE_UNIT,
  INPUT_RETURN_TYPES,
  PERMISSIONS,
  SETTINGS,
} from 'src/constants';

// Functions
import {
  getVerticalDistance,
  removeAllTypenameProps,
  setUnitAsMetersOrFeet,
  toCamelCase,
  getDeployedProductId,
} from 'src/utils';
import {
  getSpatialFeaturesPermissions,
  mutateSpatialFeature,
  setZonePointsToClockwise,
  useSpatialFeatureMutations,
} from 'src/@settings/functions';
import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import {
  TSpatialFeature,
  IGate,
  IGateGeometry,
  INoiseAbatementCorridor,
  ISelectionZone,
  TSpatialFeatureGeometry,
  TSelectionZoneGeometry,
  TCorridorGeometry,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { TMutationActions } from 'src/utils/interfaces';

interface SpatialFeaturesSummaryProps {
  featureId: number;
  setFeatureId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedFeature: ISpatialFeature;
}

export const SpatialFeatureSummary: React.FC<SpatialFeaturesSummaryProps> = ({
  featureId,
  setFeatureId,
  selectedFeature,
}) => {
  if (!selectedFeature) {
    return null;
  }
  const routerHistory = useHistory();
  // Selectors
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  // summary state
  const [isLoadingFeature, updateIsLoadingFeature] = useState<boolean>(false);
  const [isEditing, updateIsEditing] = useState<boolean>(false);
  const [isGeometryValid, setIsGeometryValid] = useState<boolean>(true);
  const [featureDetails, updateFeatureDetails] = useState<TSpatialFeature | null>(null);
  const [isMapInitialized, setIsMapInitialized] = useState<boolean>(false);
  // permissions

  const rolesSelectors = useRolesSelectors();

  const spatialFeaturesPermissions = getSpatialFeaturesPermissions(rolesSelectors);

  const canEditFeature =
    spatialFeaturesPermissions[selectedFeature.featureType][PERMISSIONS.UPDATE];
  const spatialFeatureSelector = useSpatialFeaturesSelector();

  const settingsDispatcher = useContext<any>(SettingsDispatchContext);

  const verticalDistance = setUnitAsMetersOrFeet(units.distanceVertical);
  // params for value conversion/setting
  const numericFeet = {
    convertTo: IMPERIAL_BASE_UNIT,
    convertFrom: verticalDistance,
    returnValueType: INPUT_RETURN_TYPES.NUMBER,
  };
  const cancel = () => {
    setFeatureId(null);
    routerHistory.push(`/${getDeployedProductId()}/${SETTINGS}/spatial-features`);
  };

  const languageSelector = useLanguageSelectors();

  const {
    components: { spatialFeatureMutationMessages },
  } = languageSelector.getLanguage();

  // TODO - delete feature https://envirosuitelimited.atlassian.net/browse/AX-2235
  const deleteFeature = () => {};

  const client = useApolloClient();
  const onLoadDetails = (data: TSpatialFeature) => {
    updateFeatureDetails(Object.assign({}, data));
    updateIsLoadingFeature(false);
  };

  // Gets the detailed data of the spatial feature
  useEffect(() => {
    if (featureId !== -1 && featureId !== null) {
      // Try select feature data from redux
      const originalFeature = spatialFeatureSelector.getFeatureData(
        featureId,
        selectedFeature.featureType
      );
      if (originalFeature) {
        onLoadDetails(originalFeature);
      } else {
        // If it's not there - retrieve from gql
        switch (selectedFeature.featureType) {
          case 'Gate':
            fetchGateById(client, selectedFeature.featureId)
              .then((response: IGate) => {
                addNewlyLoadedFeature(response, selectedFeature.featureType, settingsDispatcher);
                onLoadDetails(response);
              })
              .catch(error => {
                throw error;
              });
            break;
          case 'Corridor':
            fetchCorridorById(client, selectedFeature.featureId)
              .then((response: INoiseAbatementCorridor) => {
                response.geometry = response.geometry.map(item => ({
                  ...item,
                  leftPosition: removeAllTypenameProps(item.leftPosition),
                  rightPosition: removeAllTypenameProps(item.rightPosition),
                  anchorPosition: removeAllTypenameProps(item.anchorPosition),
                }));
                addNewlyLoadedFeature(response, selectedFeature.featureType, settingsDispatcher);
                onLoadDetails(response);
              })
              .catch(error => {
                throw error;
              });
            break;
          case 'SelectionZone':
            fetchSelectionZoneById(client, selectedFeature.featureId)
              .then((response: ISelectionZone) => {
                response.points = response.points.map((pointObj: IPosition) =>
                  removeAllTypenameProps(pointObj)
                );
                addNewlyLoadedFeature(response, selectedFeature.featureType, settingsDispatcher);
                onLoadDetails(response);
              })
              .catch(error => {
                throw error;
              });
            break;
          default:
            break;
        }
      }
    }
  }, []);

  // Updates the feature in the spatial feature list in redux
  const updateFeatureInReducerState = (
    updatedData: IGate | INoiseAbatementCorridor | ISelectionZone
  ) => {
    switch (selectedFeature.featureType) {
      case 'Gate':
        updateFeatureDetails(updatedData as IGate);
        break;
      case 'Corridor':
        updateFeatureDetails(updatedData as INoiseAbatementCorridor);
        break;
      case 'Zone':
      case 'SelectionZone':
        updateFeatureDetails(updatedData as ISelectionZone);
        break;
    }

    // last update time doesn't get returned from server on update - so have to populate it here
    // Will be returned when user makes call to get spatial features
    selectedFeature.lastUpdateTime = new Date().toISOString();
    selectedFeature = { ...selectedFeature, ...updatedData };
    selectedFeature.featureId = updatedData.id;
    updateIsEditing(false);
    spatialFeatureUpdated(selectedFeature, updatedData, settingsDispatcher);
  };

  const onSuccess = (updatedFeature, mutationAction: TMutationActions) => {
    const successMessage =
      spatialFeatureMutationMessages[mutationAction][toCamelCase(selectedFeature.featureType)];
    displaySuccess({ message: successMessage });
    updateFeatureInReducerState(updatedFeature);
  };

  const onMutateError = error => {
    console.error(error);
  };
  const spatialFeatureMutations = useSpatialFeatureMutations(onSuccess, onMutateError);

  const formatGateObject = (formData: ISpatialFeatureForm, gateDetails: IGate) => ({
    id: gateDetails.id,
    name: formData.name.value,
    isActive: gateDetails.isActive,
    groupName: gateDetails.groupName,
    geometry: {
      leftPosition: {
        longitude: gateDetails.geometry.leftPosition.longitude,
        latitude: gateDetails.geometry.leftPosition.latitude,
      },
      rightPosition: {
        longitude: gateDetails.geometry.rightPosition.longitude,
        latitude: gateDetails.geometry.rightPosition.latitude,
      },
      anchorPosition: {
        longitude: gateDetails.geometry.anchorPosition.longitude,
        latitude: gateDetails.geometry.anchorPosition.latitude,
      },
      ceilingAltitude: getVerticalDistance(formData.ceilingAltitude.value, numericFeet),
      floorAltitude: getVerticalDistance(formData.floorAltitude.value, numericFeet),
    },
  });
  const formatZoneObject = (formData: ISpatialFeatureForm, zoneDetails: ISelectionZone) => ({
    name: formData.name.value,
    id: zoneDetails.id,
    isActive: zoneDetails.isActive,
    groupName: zoneDetails.groupName,
    ceilingAltitude: getVerticalDistance(formData.ceilingAltitude.value, numericFeet),
    floorAltitude: getVerticalDistance(formData.floorAltitude.value, numericFeet),
    points: setZonePointsToClockwise(
      zoneDetails.points.map(point => removeAllTypenameProps(point))
    ),
  });

  const formatCorridorObject = (
    formData: ISpatialFeatureForm,
    corridorDetails: INoiseAbatementCorridor
  ) => ({
    id: corridorDetails.id,
    name: formData.name.value,
    isActive: corridorDetails.isActive,
    groupName: corridorDetails.groupName,
    geometry: corridorDetails.geometry.map(geometry => removeAllTypenameProps(geometry)),
  });

  const getCompletedFeatureData = (formData: ISpatialFeatureForm): TSpatialFeature => {
    switch (selectedFeature.featureType) {
      case 'Gate':
        return formatGateObject(formData, featureDetails as IGate) as IGate;
      case 'Corridor':
        return formatCorridorObject(
          formData,
          featureDetails as INoiseAbatementCorridor
        ) as INoiseAbatementCorridor;
      case 'SelectionZone':
        return formatZoneObject(formData, featureDetails as ISelectionZone) as ISelectionZone;
    }
  };

  const saveFeature = (formData: ISpatialFeatureForm) => {
    const completedFeatureData = getCompletedFeatureData(formData);
    mutateSpatialFeature(
      selectedFeature.featureType,
      completedFeatureData,
      spatialFeatureMutations,
      GQL_MUTATION_TYPES.UPDATE
    );
  };

  // Geometry Handling
  const updateGeometry = (updatedGeometry: TSpatialFeatureGeometry, isGeometryValid: boolean) => {
    const setFeatureGeometryZone = (zoneGeometry: TSelectionZoneGeometry) => {
      updateFeatureDetails({
        ...featureDetails,
        points: zoneGeometry,
      });
    };

    const setFeatureGeometryCorridor = (corridorGeometry: TCorridorGeometry) => {
      updateFeatureDetails({
        ...featureDetails,
        geometry: corridorGeometry,
      });
    };

    const setFeatureGeometryGate = (feature: IGate, gateGeometry: IGateGeometry) => {
      updateFeatureDetails({
        ...featureDetails,
        geometry: { ...feature.geometry, ...gateGeometry },
      });
    };

    setIsGeometryValid(isGeometryValid);
    switch (selectedFeature.featureType) {
      case 'Zone':
      case 'SelectionZone':
        setFeatureGeometryZone(updatedGeometry as TSelectionZoneGeometry);
        break;
      case 'Corridor':
        setFeatureGeometryCorridor(updatedGeometry as TCorridorGeometry);
        break;
      case 'Gate':
        setFeatureGeometryGate(featureDetails as IGate, updatedGeometry as IGateGeometry);
    }
  };

  // Resets feature details to original
  const cancelEditFeature = () => {
    const originalFeature = spatialFeatureSelector.getFeatureData(
      featureId,
      selectedFeature.featureType
    );
    if (originalFeature) {
      updateFeatureDetails(originalFeature);
      updateIsEditing(false);
    }
  };

  // Controls to load form data when map is initialized
  const onMapInitialized = () => {
    setIsMapInitialized(true);
  };

  useEffect(() => {
    setIsMapInitialized(false);
  }, [featureId]);

  const handleOnEditClick = () => {
    updateIsEditing(true);
  };
  return (
    <>
      <div className="settings__split-content">
        <div className="spatial-features__heading">
          <PageHeader title={selectedFeature.name}> </PageHeader>
        </div>
        {featureDetails && !isLoadingFeature && isMapInitialized ? (
          <>
            {isEditing && canEditFeature ? (
              <EditSpatialFeature
                featureDetails={featureDetails}
                featureType={selectedFeature.featureType}
                cancelEditFeature={cancelEditFeature}
                saveFeature={saveFeature}
                isGeometryValid={isGeometryValid}
              />
            ) : (
              <ViewSpatialFeature
                featureDetails={featureDetails}
                featureType={selectedFeature.featureType}
                spatialFeatureData={selectedFeature}
                cancelViewFeature={cancel}
                canUpdate={canEditFeature}
                editFeature={handleOnEditClick}
                deleteFeature={deleteFeature}
              />
            )}
          </>
        ) : (
          <div className={'spatial-feature__detail'}>
            <Card className="spatial-feature__card">
              <div className={'card__padder'}>
                <SkeletonText loading width="300px" />
              </div>
              <div className={'card__padder'}>
                <SkeletonText loading width="300px" />
              </div>
              <div className={'card__padder'}>
                <SkeletonText loading width="300px" />
              </div>
              <div className={'card__padder'}>
                <SkeletonText loading width="300px" />
              </div>
            </Card>
          </div>
        )}
      </div>
      <div className="settings__split-map">
        <SpatialFeaturesMap
          key={`featureMap`}
          featureId={featureId}
          feature={featureDetails}
          featureType={selectedFeature.featureType}
          editable={isEditing}
          onMapInitialized={onMapInitialized}
          onGeometryUpdate={updateGeometry}
        />
      </div>
    </>
  );
};
