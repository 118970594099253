import React from 'react';
import { Card } from '@ems/client-design-system';
import { GatePanel } from './GatePanel';
import { CorridorPanel } from './CorridorPanel';
import { NoisePanel } from './NoisePanel';
import { CcoCdoPanel } from './CcoCdoPanel';
import { IInfDetailsData } from 'src/@settings/interfaces';
import { ExclusionZonePanel } from './ExclusionZonePanel';
import { IMinHeightInfringementRule, TInfringementRule } from 'src/app/interfaces';
import { LanguageSchema } from 'src/app/props';

const RuleDetails = (
  infDetailsData: IInfDetailsData,
  formStrings: {
    [key: string]: string;
  }
) => {
  const infRuleType = infDetailsData.infRule.infringementType;

  switch (infRuleType) {
    case 'Cco':
    case 'Cdo':
      return CcoCdoPanel(infDetailsData);
    case 'Gate':
      return GatePanel(infDetailsData);
    case 'Corridor':
      return CorridorPanel(infDetailsData);
    case 'Noise':
      return NoisePanel(infDetailsData);
    case 'Exclusion':
      return ExclusionZonePanel(infDetailsData);
    case 'MinHeight':
      const infRule = infDetailsData.infRule as IMinHeightInfringementRule;
      return (
        <>
          <h4>{`${formStrings.flightDistance}: ${infRule.flightDistance}`}</h4>
          <h4>{`${formStrings.requiredAltitude}: ${infRule.requiredAltitude}`}</h4>
        </>
      );

    default:
      return <h4>Unknown infringement type</h4>;
  }
};

interface IRulePanelProps {
  infDetailsData: IInfDetailsData;
  translationStrings: Partial<LanguageSchema>;
}

export const RulePanel: React.FC<IRulePanelProps> = ({ infDetailsData, translationStrings }) => {
  // Translation
  const {
    screens: {
      settings: {
        infringementRules: {
          table: { rule: ruleString },
          form: formStrings,
        },
      },
    },
  } = translationStrings;
  const { infRule }: { infRule: TInfringementRule } = infDetailsData;
  if (!infRule.infringementType || infRule.infringementType === 'Curfew') {
    return <div />;
  }

  // ***** For 2.0.6 Release - the preview flights will not be going ahead - to add the preview flights uncomment the below code ****
  // const languageSelectors = useLanguageSelectors();
  // const {
  //   components: {
  //     lists: { dateRangeList },
  //   },
  // } = languageSelectors.getLanguage();
  // // Get yesterdays dates for operations filter date range default.
  // const getDefaultFilterDates = () => {
  //   const dates = predefinedDateRangeList(dateRangeList);
  //   const yesterdayDate = dates.find(date => date.label === 'Yesterday');

  //   const defaultFilterDateFrom = yesterdayDate.dateRange[0];
  //   const defaultFilterDateTo = yesterdayDate.dateRange[1];
  //   return { defaultFilterDateFrom, defaultFilterDateTo };
  // };

  // const { defaultFilterDateFrom, defaultFilterDateTo } = getDefaultFilterDates();

  // Import Operations filter and add this below RuleDetails
  /*
  <div className="infringementrules_content_pad">
          <OperationsFilter
            defaultFilterDateFrom={defaultFilterDateFrom}
            defaultFilterDateTo={defaultFilterDateTo}
          />
        </div>
*/
  return (
    <>
      <div className="infringementrules_card_spacer" />
      <div className="infringementrules_card_title">
        <h4>{ruleString}</h4>
      </div>
      <Card>
        <div className="infringementrules_content_pad">
          {RuleDetails(infDetailsData, formStrings)}
        </div>
      </Card>
    </>
  );
};
