import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'src/app/routes';
// screen
import { NotFound } from 'src/screens/notFound';
import { NoAccess } from 'src/screens/noAccess';
// props
import { IRolesSelectors } from 'src/app/props';
// utils
import { getDeployedProductId } from 'src/utils/generics';
import { getRoute, removeRoute } from 'src/app/functions/storage';
// constants
import { DEPLOYED_PRODUCT_ID } from 'src/constants';
import { getCurrentRoute } from './core';

export const getRoutes = (
  defaultRouteName: string,
  availableRoutes: string[],
  rolesSelectors: IRolesSelectors
) => {
  // constructing app routes
  const appRoutes: any = [];
  let defaultRoute: any;
  let canSetDefaultRoute = true;
  const routesLength = routes && routes.length;
  // Only set the default route to settings if the url is settings
  const isSettings = route => {
    const currentRoute = getCurrentRoute();
    return (
      (route.indexOf('settings') !== -1 && currentRoute.indexOf('settings') !== -1) ||
      (route.indexOf('settings') === -1 && currentRoute.indexOf('settings') === -1)
    );
  };
  if (routesLength) {
    for (let i = routesLength; i--; ) {
      const { route, permissions, component, redirect } = routes[i];
      if (availableRoutes.includes(route)) {
        let canAccessRoute = true;
        for (const permission of permissions) {
          if (!rolesSelectors.canAccess(permission.name, permission.type)) {
            canAccessRoute = false;
            break;
          }
        }

        // Check default route
        // Since the for loop iterates from footer links upwards, we keep setting it until we hit the default route
        // or until we hit the highest link in the header that matches.
        if (canAccessRoute && canSetDefaultRoute && isSettings(route)) {
          defaultRoute = routes[i];
        }

        // Once we have hit our default route name, don't try to set it to any other route
        if (route === defaultRouteName) {
          canSetDefaultRoute = false;
        }

        appRoutes.unshift({
          path: `/:${DEPLOYED_PRODUCT_ID}/${route}`,
          component: canAccessRoute
            ? redirect
              ? ({ match: { params } }) => (
                  <Redirect to={`/${params[DEPLOYED_PRODUCT_ID]}/${redirect}`} />
                )
              : component
            : NoAccess,
        });
        // error on child that doesn't exist
        appRoutes.unshift({
          path: `/:${DEPLOYED_PRODUCT_ID}/${route}/:unknownRoute`,
          component: NotFound,
        });
      }
    }
  }

  // route did not match
  appRoutes.push({
    path: `/:${DEPLOYED_PRODUCT_ID}/:unknownRoute`,
    component: NotFound,
  });

  // add redirect to default if default is available
  if (defaultRoute !== undefined && defaultRoute) {
    appRoutes.push({
      path: `/:${DEPLOYED_PRODUCT_ID}`,
      component: ({ match: { params } }) => (
        <Redirect to={`/${params[DEPLOYED_PRODUCT_ID]}/${defaultRoute.route}`} />
      ),
    });
  }

  // none of the paths matched
  appRoutes.push({
    component: ({ match: { params } }) => {
      // handle default route after a successful authentication
      if (!Object.keys(params).length && getDeployedProductId()) {
        const storedRoute = getRoute();
        if (storedRoute) {
          // enable shareable urls for the app after successful authentication
          removeRoute();
          return <Redirect to={storedRoute} />;
        } else {
          return <Redirect to={`/${getDeployedProductId()}/${defaultRouteName}`} />;
        }
      } else {
        return <NotFound />;
      }
    },
  });

  return appRoutes;
};
