import { useContext } from 'react';
// providers
import { AirtrakStateContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// interfaces
import { IViewState, IActions, IViewSelector } from '../interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actions
import { actionTypes } from 'src/@airtrak/actionTypes';

export const useViewSelector: () => IViewSelector = () => {
  const state: any = useContext(AirtrakStateContext);
  const viewState: IViewState = state.view;
  return useSelectors(viewState, (state: IViewState) => ({
    getView: () => state.view,
  }));
};

export const viewIntialState: IViewState = {
  view: 'line',
};

export const viewReducer = (state: IViewState, action: IActions) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEW:
      if (action.data) {
        return Object.assign({}, state, { view: action.data });
      }
    default:
      return state;
  }
};
