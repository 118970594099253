import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/@noiseEvents/props';
import { dataInitialState, dataReducer } from 'src/@noiseEvents/reducers/dataReducer';
import { sortInitialState, sortReducer } from 'src/@noiseEvents/reducers/sortReducer';
import { filterInitialState, filterReducer } from 'src/@noiseEvents/reducers/filterReducer';
// Hooks
import { usePersistentReducer } from 'src/utils';

export const NoiseEventsStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
  filter: filterInitialState,
});

export const NoiseEventsDispatchContext = createContext(null);

export const NoiseEventsStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [filterState, filterDispatch] = usePersistentReducer(
    filterReducer,
    filterInitialState,
    'noise-events.filter.state',
    'session'
  );

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    filter: filterState,
  };

  const dispatchers = [dataDispatch, sortDispatch, filterDispatch];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <NoiseEventsStateContext.Provider value={state}>
      <NoiseEventsDispatchContext.Provider value={dispatch}>
        {children}
      </NoiseEventsDispatchContext.Provider>
    </NoiseEventsStateContext.Provider>
  );
};
