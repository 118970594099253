import { useCallback, useMemo } from 'react';
import { doesLayerExist } from 'src/utils/mapHelpers/mapLayerHelpers';
import { NMT_SOURCE_ID_PREFIX } from '../constants';
import { useLocationManagementContext } from '../Context/LocationManagementContext';
import { LocationItem } from '../interfaces';
import { isValidLatLong } from './useFlyTo';
export const useDisplayDevicesOnMap = (
  selectedNmtIds: number[],
  staticDevicePositions: LocationItem[],
  mapApis
) => {
  const getUniqueId = source => (staticDevicePositions ? source.locationId : source.deviceId);
  const deviceData = useDeviceData(staticDevicePositions);
  const displayedData = useMemo(
    () =>
      deviceData.flatMap(
        device => {
          const id = getUniqueId(device);
          const { position, deviceId } = device;
          const isSelected = selectedNmtIds.includes(id);
          const sourceId = `${NMT_SOURCE_ID_PREFIX}_${id}`;
          const layerId = `${sourceId}_layer${isSelected ? '_selected' : ''}`;
          const layerHoverIdentifier = `_${id}_layer${isSelected ? '_selected' : ''}`;
          const isValidPosition = isValidLatLong(position.latitude, position.longitude);
          if (!isValidPosition) {
            return [];
          }
          const sourceData = {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {
                deviceId,
                valid: true,
              },
              geometry: {
                type: 'Point',
                coordinates: [position.longitude, position.latitude],
              },
            },
          };
          const layerData = {
            id: layerId,
            source: sourceId,
            type: 'symbol',
            paint: {
              'icon-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 12, 1],
            },

            layout: {
              visibility: 'visible',
              'icon-image': isSelected ? 'noise_monitor_selected' : 'noise_monitor',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'icon-size': ['interpolate', ['linear'], ['zoom'], 8, 0.25, 12, 0.5],
            },
          };

          return { sourceData, layerData, isSelected, layerHoverIdentifier, sourceId };
        },
        [deviceData, selectedNmtIds]
      ),
    [selectedNmtIds, deviceData]
  );
  const layerArray = displayedData.map(({ layerHoverIdentifier }) => layerHoverIdentifier);

  const { displayLocations, displayNMTS } = useDisplayDataCallback(displayedData, mapApis);
  return {
    displayDevicesCallback: staticDevicePositions ? displayLocations : displayNMTS,
    deviceData,
    layerArray,
  };
};

const useDeviceData = (staticDevicePositions: LocationItem[]) => {
  const {
    state: { nmtListData },
  } = useLocationManagementContext();

  return useMemo(() => staticDevicePositions ?? nmtListData, [nmtListData, staticDevicePositions]);
};

const useDisplayDataCallback = (displayedData, mapApis) => {
  const displayNMTS = useCallback(() => {
    if (mapApis) {
      const devicesToRerender = displayedData.flatMap(
        ({ sourceData, layerData, isSelected, sourceId }) => {
          if (!doesLayerExist(mapApis, layerData.id)) {
            const rootLayerId = `${layerData.source}_layer`;
            const originalLayerId = `${rootLayerId}${isSelected ? '' : '_selected'}`;
            if (doesLayerExist(mapApis, originalLayerId)) {
              mapApis.removeLayer(originalLayerId);
              mapApis.removeSource(sourceId);
            }
            return { sourceData, layerData, isSelected, sourceId };
          }
          return [];
        }
      );

      const sortedDevices = devicesToRerender.sort(
        data => Number(data.isSelected) - Number(data.isSelected)
      );
      sortedDevices.forEach(({ sourceId, sourceData, layerData }) => {
        mapApis.addSource(sourceId, sourceData);
        mapApis.addLayer(layerData);
      });
    }
  }, [mapApis, displayedData]);

  const displayLocations = useCallback(() => {
    if (!mapApis) {
      return;
    }
    const layers = mapApis.getStyle().layers.map(({ id }) => id);
    const isLayerSelected = layerId =>
      layers.includes(`${layerId}_selected`) || layerId.indexOf('selected') !== -1;
    const sourceIds = Object.keys(mapApis.getStyle().sources).flatMap(id =>
      id.indexOf(NMT_SOURCE_ID_PREFIX) !== -1 ? id : []
    );
    sourceIds.forEach(sourceId => {
      const layerId = `${sourceId}_layer`;
      const isSelected = `${isLayerSelected(layerId) ? '_selected' : ''}`;
      mapApis.removeLayer(`${layerId}${isSelected}`);
      mapApis.removeSource(sourceId);
    });
    displayedData.forEach(({ sourceData, layerData, sourceId }) => {
      mapApis.addSource(sourceId, sourceData);
      mapApis.addLayer(layerData);
    });
  }, [mapApis, displayedData]);

  return { displayLocations, displayNMTS };
};
