import React from 'react';

export interface ISelectorOption {
  label: string;
  value?: string;
  body?: React.ReactNode;
  color?: string;
  rangeValue?: number;
}

export const SettingsSelectorWrapper: React.FC<{
  className: string;
  title: string;
  children: React.ReactNode;
}> = ({ className, title, children }) => (
    <>
      <h2 className="settings-selector__title">{title}</h2>
      <fieldset className={`${className}__fieldset settings-selector__fieldset`}>
        {children}
      </fieldset>
    </>
  );
