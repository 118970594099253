import { useContext } from 'react';
// providers
import { AirtrakStateContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// interfaces
import { IBreakdownState, IActions, IBreakdownSelector } from '../interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// actions
import { actionTypes } from 'src/@airtrak/actionTypes';

export const useBreakdownSelector: () => IBreakdownSelector = () => {
  const state: any = useContext(AirtrakStateContext);
  const dataState: IBreakdownState = state.breakdown;
  return useSelectors(dataState, (state: IBreakdownState) => ({
    getBreakdown: () => state.breakdown,
  }));
};

export const breakdownIntialState: IBreakdownState = {
  breakdown: 'None',
};

export const breakdownReducer = (state: IBreakdownState, action: IActions) => {
  switch (action.type) {
    case actionTypes.UPDATE_BREAKDOWN:
      if (action.data) {
        return Object.assign({}, state, { breakdown: action.data });
      }
    default:
      return state;
  }
};
