import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

// Types
import {
  IAircraftMappingVars,
  IAircraftMappingResponse,
  IEngineMappingResponse,
  ILoadMoreOptions,
  IFetchMoreArgs,
  IUpdateQueryOptions,
} from '../types';

// Constants
import { GET_AIRCRAFT_MAPPINGS, GET_ENGINE_MAPPING_VALUES } from '../queries/aircraftMappings';
import { LOAD_MORE_PAGE_SIZE } from '../constants';

// Hooks
import { useAircraftMappings } from '../providers/aircraftMappings';

/* Fetches the aircraft assignments data and sets up the load more callback */
export const useFetchTableData = () => {
  const {
    dispatch,
    state: { queryVariables },
  } = useAircraftMappings();
  const { loading, variables, fetchMore, data, error } = useQuery<
    IAircraftMappingResponse,
    IAircraftMappingVars
  >(GET_AIRCRAFT_MAPPINGS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !queryVariables.endTime || !queryVariables.startTime || !queryVariables.scenarioId,
  });
  const loadMore = useLoadMore(variables, fetchMore);

  const { loading: loadingFilterData } = useQuery<IEngineMappingResponse>(
    GET_ENGINE_MAPPING_VALUES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: data => {
        dispatch({ type: 'UPDATE_FILTER_MAPPINGS', data });
      },
    }
  );

  const areQueriesLoading = useMemo(() => loadingFilterData || loading, [
    loading,
    loadingFilterData,
  ]);

  useEffect(() => {
    if (areQueriesLoading) {
      dispatch({ type: 'LOADING' });
    } else if (error) {
      dispatch({ type: 'ERROR', error });
    } else if (data) {
      dispatch({ type: 'SUCCESS', data });
    }
  }, [areQueriesLoading, error, data, dispatch]);

  return { loadMore };
};

// Callback for loading more aircraft assignment data
const useLoadMore = (variables: IAircraftMappingVars, fetchMore) =>
  useCallback(
    (_client, _dispatcher, options: ILoadMoreOptions) => {
      const fetchMoreArguments: IFetchMoreArgs = {
        variables: {
          ...variables,
          first: LOAD_MORE_PAGE_SIZE,
          after: options.endCursor,
        },
        /* TODO: Update this when we update to AC3.
         * We manually merge the results here instead of using the relay methods,
         * due to the weak support for it in AC2. When we update our Apollo Client
         * we should leverage the automatic pagination in these situations
         */
        updateQuery: (
          {
            airQualityAircraftMappingSummariesByTimeRange: { edges: previousEdges },
          }: IAircraftMappingResponse,
          {
            fetchMoreResult: {
              airQualityAircraftMappingSummariesByTimeRange: {
                pageInfo: newPageInfo,
                edges: newEdges,
                totalCount: newTotalCount,
                __typename,
              },
            },
          }: IUpdateQueryOptions
        ) => ({
          airQualityAircraftMappingSummariesByTimeRange: {
            pageInfo: newPageInfo,
            edges: [...previousEdges, ...newEdges],
            totalCount: newTotalCount,
            __typename,
          },
        }),
      };
      void fetchMore(fetchMoreArguments);
    },
    [variables, fetchMore]
  );
