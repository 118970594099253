// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';

// TODO - convert all these sort actions to one generic sort action and pass the module in
export const sortAircraftMappingTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_AIRCRAFT_MAPPING_TABLE, data: sortName });
};

export const sortFleetmixTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_FLEET_MIX_TABLE, data: sortName });
};

export const sortLoadFactorsTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_LOAD_FACTORS_TABLE, data: sortName });
};

export const sortTimeinModesTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_TIME_IN_MODES_TABLE, data: sortName });
};

export const sortFuelTypesTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_FUEL_TYPES_TABLE, data: sortName });
};

export const sortAirlineMappingTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_AIRLINE_MAPPING_TABLE, data: sortName });
};
