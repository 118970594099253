import gql from 'graphql-tag';

export const GET_SCENARIOS = gql`
  query getScenarios {
    scenarios {
      id
      name
      isActive
      isDefault
      isLocked
      startTime
      endTime
      airportId
      defaultOptimumTMAApproachDistance
      applyWeatherAdjustment
      defaultLoadFactor
      defaultDurationClimb
      defaultDurationTaxiIn
      defaultDurationTakeOff
      defaultDurationTaxiOut
      defaultDurationApproach
      emissionRateMode
      emissionRateAvGas
      emissionRateSingle
      emissionRateJetFuel
      emissionRateUnknown
      __typename
      outstandingMonthsToProcess
    }
  }
`;
