import { Button, Icons, Modal, TextArea, Tooltip } from '@ems/client-design-system';
import React, { useMemo, useState } from 'react';
import { DateTimeTableField } from 'src/@settings/components/DateTimeTableField';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { BLANK_STRING_PLACEHOLDER, DATE_TIME_FORMAT } from 'src/constants';
import { getDeployedProductId, toCamelCase } from 'src/utils';
import {
  ChangelogModalContentChangeView,
  ChangelogModalContentIcon,
  ChangelogModalContentWrapper,
  ChangelogModalRowCell,
  ChangelogModalRowCellIcon,
  ChangelogModalRowCellText,
} from '../ChangelogContainer.styles';
import { translateFieldValues } from '../functions';
import { ChangeLogTableData, IAuditLogEntry, IAuditLogEntryEdge } from '../interfaces';
import uuid from 'uuid';
import { Link } from 'react-router-dom';

export const useChangelogTableData = (
  changelogs: IAuditLogEntryEdge[],
  revertAction: (ids: number[]) => void
): ChangeLogTableData[] => {
  const configSelectors = useConfigSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();
  const languageSelector = useLanguageSelectors();
  const {
    fields: fieldStrings,
    components: {
      lists: { reportDataTypes },
      lists: listStrings,
      hints: hintStrings,
    },
  } = languageSelector.getLanguage();

  return useMemo(() => changelogs.map(({ node }) => {
      const { dataType, dataId, fieldName, oldValue, newValue, userName, time } = node;
      const { translatedNewValue, translatedOldValue } = translateFieldValues({
        fieldName,
        oldValue,
        newValue,
        listStrings,
      });

      return {
        // formatted display of dataType
        browser: reportDataTypes[toCamelCase(dataType)] || dataType,
        dataType,
        key: keyFieldLink(node, availableRoutes),
        dataId,
        // formated display of fieldName
        field: fieldNameTranslation({ fieldStrings, dataType, fieldName }),
        fieldName,
        oldValue: (
          <ChangelogModalRowCellText>
            {translatedOldValue || BLANK_STRING_PLACEHOLDER}
          </ChangelogModalRowCellText>
        ),
        newValue: (
          <ViewLargeChangelogModal
            changelog={node}
            fieldStrings={fieldStrings}
            revertAction={revertAction}
            translatedNewValue={translatedNewValue}
          />
        ),
        // formatted display of userName
        user: (
          <span className="username-column" title={userName}>
            {userName}
          </span>
        ),
        userName,
        revertable: revertStatusFieldIcon(node, revertAction, hintStrings),
        changeTime: (
          <DateTimeTableField
            className={'feature-time-column'}
            dateTimeValue={time}
            dateFormat={DATE_TIME_FORMAT.FIELD_24H}
          />
        ),
        tableId: uuid.v4(),
        tableRowKey: `id:${node.id}/${node.dataType}`,
        className:
          node.revertable === 'CanRevert' ? 'table-row__revertable' : 'table-row__irrevertible',
      };
    }), [changelogs]);
};

const fieldNameTranslation = ({
  fieldStrings,
  dataType,
  fieldName,
}: {
  fieldStrings: Record<string, { [key: string]: string }>;
  dataType: string;
  fieldName: string;
}) => {
  const formattedDataType = `${toCamelCase(dataType)}s`;
  const formattedFieldName = `${toCamelCase(fieldName)}`;
  if (fieldStrings[formattedDataType] && fieldStrings[formattedDataType][formattedFieldName]) {
    return fieldStrings[formattedDataType][formattedFieldName];
  }

  return fieldName;
};

const ViewLargeChangelogModal = ({
  changelog,
  fieldStrings,
  revertAction,
  translatedNewValue,
}: {
  changelog: IAuditLogEntry;
  fieldStrings;
  revertAction;
  translatedNewValue: string;
}) => {
  const { dataType, fieldName, newValue, oldValue, revertable } = changelog;
  const LARGE_CHANGELOG_CHARACTER_LIMIT = 100;
  const modalTitle = fieldNameTranslation({ fieldStrings, dataType, fieldName });
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (!newValue) {
    return <>{BLANK_STRING_PLACEHOLDER}</>;
  }

  if (
    newValue.length >= LARGE_CHANGELOG_CHARACTER_LIMIT ||
    (oldValue && oldValue.length >= LARGE_CHANGELOG_CHARACTER_LIMIT)
  ) {
    return (
      <ChangelogModalRowCell>
        <ChangelogModalRowCellText>{translatedNewValue}</ChangelogModalRowCellText>

        <ChangelogModalRowCellIcon
          disabled={revertable !== 'CanRevert'}
          onClick={() => {
            if (revertable === 'CanRevert') {
              setModalIsOpen(true);
            }
          }}>
          <Icons iconName="ic-eye" title={'View More'} size={20} />
        </ChangelogModalRowCellIcon>
        <Modal
          className="changelog-modal"
          title={modalTitle}
          isOpen={modalIsOpen}
          handleModalClose={() => setModalIsOpen(false)}
          handleUpdate={() => {
            setModalIsOpen(false);
            revertAction([changelog.id]);
          }}
          translationData={{
            cancel: 'Cancel',
            update: 'Revert',
            closeModal: 'Close Modal',
            resetToDefault: '',
          }}>
          <ChangelogModalContentWrapper>
            <ChangelogModalContentChangeView>
              <TextArea disabled label="Old Value" id="oldValue" defaultValue={oldValue} />
            </ChangelogModalContentChangeView>
            <ChangelogModalContentIcon>
              <Icons iconName="ic-ui-arrow-right" />
            </ChangelogModalContentIcon>
            <ChangelogModalContentChangeView>
              <TextArea disabled label="New Value" id="newValue" defaultValue={newValue} />
            </ChangelogModalContentChangeView>
          </ChangelogModalContentWrapper>
        </Modal>
      </ChangelogModalRowCell>
    );
  }

  return <ChangelogModalRowCellText>{translatedNewValue}</ChangelogModalRowCellText>;
};

export const revertStatusFieldIcon = (
  changelog: IAuditLogEntry,
  revertAction: (ids: number[]) => void,
  hintStrings: { [key: string]: string }
) => {
  const { revertable } = changelog;
  const { changelogRevertRecordMissing, changelogRevertCannotRevert } = hintStrings;
  const revertableIcon = 'ic-arrow-return';
  const irrevertableIcon = 'ic-ac-unknown';

  switch (revertable) {
    case 'CanRevert':
      return (
        <span className="table-icon__revertable">
          <Button
            style="subtle"
            leftIcon={<Icons iconName={revertableIcon} title={'revert'} size={20} />}
            iconOnly
            onClick={() => {
              revertAction([changelog.id]);
            }}
          />
        </span>
      );
    case 'RecordDeleted':
      return (
        <span className="table-icon__irrevertible">
          <Tooltip content={changelogRevertRecordMissing}>
            <Icons iconName={irrevertableIcon} title={'revert'} size={20} />
          </Tooltip>
        </span>
      );
    case 'UnsupportedAction':
    default:
      return (
        <span className="table-icon__irrevertible">
          <Tooltip content={changelogRevertCannotRevert}>
            <Icons iconName={irrevertableIcon} title={'revert'} size={20} />
          </Tooltip>
        </span>
      );
  }
};

export const keyFieldLink = (changelog: IAuditLogEntry, avaliableRoutes) => {
  const { dataId, dataType } = changelog;
  const routeString = `${dataType.toLowerCase()}s`;
  const route = avaliableRoutes.some(route => route === routeString);
  return route ? (
    <Link
      target="_blank"
      className="table-cell__link"
      to={{
        pathname: `/${getDeployedProductId()}/${dataType.toLowerCase()}s/${dataId}`,
      }}>
      {dataId}
    </Link>
  ) : (
    dataId
  );
};
