import { setUpExistingCorridor, setUpNewCorridor } from './corridorHelpers';
import { setUpExistingGate, setUpNewGate } from './gateHelpers';
import {
  IEditCorridorParams,
  IEditGateParams,
  IFeatureDataObject,
  IFeatureGeometryObject,
  IGateCoordinateObject,
  TCorridorGeometry,
  TEditFeatureParams,
  TSelectionZoneGeometry,
  IGateGeometry,
  TSpatialFeatureGeometry,
  IEditZoneParams,
  ISetFeatureObject,
} from './interfaces';
import { Map as IMapApis } from 'mapbox-gl';
import { IViewState } from '../interfaces';
import { Dispatch } from 'react';
import {
  calculateMapZoom,
  getCenter,
  getMinMaxCoordinates,
  offsetCoordinatesByPixels,
} from '../mapHelpers/mapHelpers';
import {
  FEATURE_TYPES,
  FLY_TO_DURATION,
  RENDER_FEATURE_DELAY,
  VIEWPORT_OFFSET,
} from 'src/constants';
import WebMercatorViewport from 'viewport-mercator-project';
import { setUpExistingZone, setUpNewZone } from './zoneHelpers';

// function that returns spatial feature geometries for map and fly to coordinates
export const useSpatialFeatures = ({
  featureData,
  mapApis,
  viewport,
  setViewport,
  updateZoomSet,
  setFeatures = null,
  editFeatureParams,
}: {
  featureData: IFeatureDataObject;
  mapApis: IMapApis;
  viewport: IViewState;
  setViewport: Dispatch<IViewState>;
  updateZoomSet: Dispatch<boolean>;
  setFeatures?: ISetFeatureObject | null;
  editFeatureParams?: TEditFeatureParams;
}) => {
  updateZoomSet(false);
  // Gate geometries
  const gateGeometries = featureData.gates.map(gate =>
    gate.id === -1
      ? setUpNewGate(viewport, mapApis, editFeatureParams as IEditGateParams)
      : setUpExistingGate(
          gate,
          editFeatureParams ? (editFeatureParams as IEditGateParams) : undefined
        )
  );

  const corridorGeometries = featureData.corridors.map(corridor =>
    corridor.id === -1
      ? setUpNewCorridor(viewport, mapApis, editFeatureParams as IEditCorridorParams)
      : setUpExistingCorridor(
          corridor,
          editFeatureParams ? (editFeatureParams as IEditCorridorParams) : undefined
        )
  );

  const zoneGeometries = featureData.zones.map(zone =>
    zone.id === -1
      ? setUpNewZone(viewport, mapApis, editFeatureParams as IEditZoneParams)
      : setUpExistingZone(
          zone,
          editFeatureParams ? (editFeatureParams as IEditZoneParams) : undefined
        )
  );
  const featureGeometries: IFeatureGeometryObject = {
    gates: gateGeometries,
    zones: zoneGeometries,
    corridors: corridorGeometries,
  };

  if (!!setFeatures) {
    if (setFeatures.setGates) {
      setFeatures.setGates(!!gateGeometries.length ? gateGeometries : []);
    }
    if (setFeatures.setZones) {
      setFeatures.setZones(!!zoneGeometries.length ? zoneGeometries : []);
    }
    if (setFeatures.setCorridors) {
      setFeatures.setCorridors(!!corridorGeometries.length ? corridorGeometries : []);
    }
  }

  // Sets up the appropriate feature base on feature type props
  if (!!gateGeometries.length || !!corridorGeometries.length || !!zoneGeometries.length) {
    fitFeaturesInMap(mapApis, viewport, setViewport, updateZoomSet, featureGeometries);
  }
};

export const fitFeaturesInMap = (
  mapApis: IMapApis,
  viewport: IViewState,
  setViewport: Dispatch<IViewState>,
  updateZoomSet: Dispatch<boolean>,
  featureGeometries: IFeatureGeometryObject
): void => {
  if (mapApis) {
    // gets the center of all the feature points
    const center = getCenter([
      ...featureGeometries.gates.map((gate: IGateGeometry) => gate.anchorPosition),
      ...featureGeometries.zones.map((zone: TSelectionZoneGeometry) => zone).flat(1),
      ...featureGeometries.corridors.map((corridor: TCorridorGeometry) =>
        getCorridorCenter(corridor)
      ),
    ]);

    // gets minMaxLongLats for all spatial features
    const gateMinMax = getMinMaxForGeometries(featureGeometries.gates, FEATURE_TYPES.GATE);
    const zoneMinMax = getMinMaxForGeometries(
      featureGeometries.zones,
      FEATURE_TYPES.SELECTION_ZONE
    );
    const corridorMinMax = getMinMaxForGeometries(
      featureGeometries.corridors,
      FEATURE_TYPES.CORRIDOR
    );

    const oldViewport = new WebMercatorViewport(viewport);

    // Get viewport for new points
    const longitudeMin: number = Math.min(zoneMinMax[0], corridorMinMax[0], gateMinMax[0]);
    const longitudeMax: number = Math.max(zoneMinMax[1], corridorMinMax[1], gateMinMax[1]);

    const latitudeMin = Math.min(zoneMinMax[2], corridorMinMax[2], gateMinMax[2]);
    const latitudeMax = Math.max(zoneMinMax[3], corridorMinMax[3], gateMinMax[3]);

    const adjustedMinLongLat = offsetCoordinatesByPixels(
      [longitudeMin, latitudeMin],
      VIEWPORT_OFFSET,
      VIEWPORT_OFFSET,
      mapApis
    );

    const adjustedMaxLongLat = offsetCoordinatesByPixels(
      [longitudeMax, latitudeMax],
      VIEWPORT_OFFSET,
      VIEWPORT_OFFSET,
      mapApis
    );

    const zoom = calculateMapZoom(adjustedMinLongLat, adjustedMaxLongLat);
    const newViewport = oldViewport.fitBounds([
      [adjustedMinLongLat.lng, adjustedMinLongLat.lat],
      [adjustedMaxLongLat.lng, adjustedMaxLongLat.lat],
    ]);

    const newViewPort = Object.assign({}, newViewport, {
      zoom,
      longitude: center[0],
      latitude: center[1],
    });

    mapApis.flyTo({
      center,
      zoom,
      duration: FLY_TO_DURATION,
    });

    setTimeout(() => {
      setViewport(newViewPort);
      updateZoomSet(true);
    }, RENDER_FEATURE_DELAY);
  }
};

// Gets Min and Max LongLat for spatial geometries
export const getMinMaxForGeometries = (
  geometries: TSpatialFeatureGeometry[],
  featureType: string
): number[] => {
  // longitude can take values from -180 to 180
  let featureLongitudeMin = 180;
  let featureLongitudeMax = -180;
  // latitude can take values from -90 to 90
  let featureLatitudeMin = 90;
  let featureLatitudeMax = -90;

  const getCoordsForFeatures = (geometry: TSpatialFeatureGeometry, featureType: string) => {
    switch (featureType) {
      case 'SelectionZone':
      case 'Zone':
        const zone = geometry as TSelectionZoneGeometry;
        return getMinMaxCoordinates(zone);
      case 'Corridor':
        const corridor = geometry as TCorridorGeometry;
        return getMinMaxCoordinates(corridor.map(corridorGate => corridorGate.anchorPosition));
      case 'Gate':
        const gate = geometry as IGateGeometry;
        return getMinMaxCoordinates([gate.anchorPosition]);
    }
  };
  geometries.forEach((geometry: TSpatialFeatureGeometry) => {
    const { longitudeMin, longitudeMax, latitudeMin, latitudeMax } = getCoordsForFeatures(
      geometry,
      featureType
    );

    featureLongitudeMin = Math.min(featureLongitudeMin, longitudeMin);
    featureLongitudeMax = Math.max(featureLongitudeMax, longitudeMax);

    featureLatitudeMin = Math.min(featureLatitudeMin, latitudeMin);
    featureLatitudeMax = Math.max(featureLatitudeMax, latitudeMax);
  });

  return [featureLongitudeMin, featureLongitudeMax, featureLatitudeMin, featureLatitudeMax];
};

export const getCorridorCenter = (geometry: IGateCoordinateObject[]) => {
  const centerCoordinates = getCenter(
    geometry.map((gate: IGateCoordinateObject) => gate.anchorPosition)
  );
  return { longitude: centerCoordinates[0], latitude: centerCoordinates[1] };
};
