import { useCallback, useState } from 'react';
import { useMapEvent } from './useMapEvent';

export const useMapIsLoaded = () => {
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const handleLoaded = useCallback(() => setMapIsLoaded(true), []);
  const handleLoading = useCallback(() => setMapIsLoaded(false), []);

  useMapEvent('load', handleLoaded);
  useMapEvent('styledataloading', handleLoading);
  useMapEvent('styledata', handleLoaded);

  return mapIsLoaded;
};
