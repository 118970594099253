// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
// resolvers
import { fetchLoadFactors } from 'src/@settings/resolvers';
// client
import ApolloClient from 'apollo-client';

export const getLoadFactors = (
  client: ApolloClient<object>,
  dispatcher: any,
  scenarioIds: number[]
) => {
  dispatcher({ type: actionTypes.LOAD_FACTORS_LOADING });
  fetchLoadFactors(client, scenarioIds)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_LOAD_FACTORS, loadFactorsData: data });
    })
    .catch(error => {
      console.error(error);
      dispatcher({ type: actionTypes.LOAD_FACTORS_LOADING });
    });
};
