// Types
import { LanguageSchema } from 'src/app/props';
import { ISortObject } from 'src/components';
import { IAircraftMappingFilter, IAircraftMappingResponse } from './types';

export const tableColumns: string[] = [
  'aircraftType',
  'engineModel',
  'numberOfFlights',
  'mappedLTOAircraftEngine',
  'mappedEnrouteAircraftEngine',
];

export const columnData = (translationData: Partial<LanguageSchema>) => {
  const {
    fields: {
      aircraftMappings: {
        aircraft: aircraftColumnHeader,
        engine: engineColumnHeader,
        numberOfFlights: numberOfFlightsColumnHeader,
        ltoModel: ltoModelColumnHeader,
        enrouteModel: enrouteModelColumnHeader,
      },
    },
  } = translationData;

  return [
    { columnName: 'aircraftType', key: 'aircraftType', title: aircraftColumnHeader },
    { columnName: 'engine', key: 'engineModel', title: engineColumnHeader },
    {
      columnName: 'numberOfFlights',
      key: 'numberOfFlights',
      title: numberOfFlightsColumnHeader,
    },
    {
      columnName: 'mappedLTOAircraftEngine',
      key: 'mappedLTOAircraftEngine',
      title: ltoModelColumnHeader,
    },
    {
      columnName: 'mappedEnrouteAircraftEngine',
      key: 'mappedEnrouteAircraftEngine',
      title: enrouteModelColumnHeader,
    },
  ];
};

export const DEFAULT_QUERY_FILTER: IAircraftMappingFilter = {
  operationTypes: null,
  aircraftTypes: null,
};

export const DEFAULT_SORT_ORDER: ISortObject = {
  field: 'numberOfFlights',
  direction: 'DESC',
};

// Force table to load all results in order to properly calculate mapped % values
// % mapped values may become a webcall in future, so swap back to pagniation defaults
// export const INITIAL_PAGE_SIZE = 300;
export const INITIAL_PAGE_SIZE = 300000;
export const LOAD_MORE_PAGE_SIZE = 1000;

export const DEFAULT_QUERY_RESULT: IAircraftMappingResponse = {
  airQualityAircraftMappingSummariesByTimeRange: {
    edges: [],
    totalCount: -1,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
  },
};
