import { ApolloClient } from 'apollo-client';
import { getScenarioReprocessStatus, getScenarios } from 'src/app/resolvers/scenariosResolver';
import { appActionTypes } from 'src/app/actionTypes';

export const loadAirtrakScenarios = (client: ApolloClient<object>, dispatcher: any) => {
  dispatcher({ type: appActionTypes.SCENARIOS_LOADING });
  getScenarios(client)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_SCENARIOS, payload: response });
    })
    .catch(error => {
      console.log(error);
      dispatcher({ type: appActionTypes.SCENARIOS_LOADING });
    });
};

export const loadScenarioReprocessStatus = (client: ApolloClient<object>, dispatcher: any, scenarioId: number) => {
  getScenarioReprocessStatus(client, scenarioId)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_SCENARIO_REPROCESS_STATUS, payload: response });
    })
    .catch(error => {
      console.log(error);
    });
};

export const updateCurrentScenario = async (currentScenario, dispatcher: any) => {
  await dispatcher({ type: appActionTypes.UPDATE_CURRENT_SCENARIO, currentScenario });
};
