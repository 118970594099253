import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { fetchUserHelpQuery, fetchUserHelpSchema } from 'src/@userHelp/resolvers/schema';

export const fetchUserHelp = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_USER_HELP = gql`
      query {
        ${fetchUserHelpQuery} ${fetchUserHelpSchema}
      }
    `;

    client
      .query({
        query: GET_USER_HELP,
      })
      .then(response => {
        if (response && response.data && response.data[fetchUserHelpQuery]) {
          resolve(response);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
