import gql from 'graphql-tag';

export const UPDATE_PHASE_DEFAULTS = gql`
  mutation updatePhaseDefaults($phaseDefaults: [BulkPhaseDefaultChangeInput], $scenarioId: Int , $startTime: DateTimeOffset, $endTime: DateTimeOffset) {
    updatePhaseDefaults(phaseDefaults: $phaseDefaults) {
      id
      scenarioId
      airport
      runway
      durationTaxiOut
      durationTakeoff
      durationClimb
      durationApproach
      durationTaxiIn
    }
    carbonEmissionsRequestReprocessing(scenarioId: $scenarioId, startTime: $startTime, endTime: $endTime)
  }
`;
