import React from 'react';

// Components
import { InlineDropDown } from '@ems/client-design-system';

// Styled
import {
  InlineEditErrorWrapper,
  InlineEditError,
  InlineEditErrorContent,
} from './InlineEditDropdown.styles';

// Types
import { InlineEditDropdownProps } from './interfaces';

export const InlineEditDropdown = ({
  selected,
  list,
  error = '',
  onUpdate,
}: InlineEditDropdownProps) => {
  return (
    <InlineEditErrorWrapper isError={!!error}>
      <InlineDropDown
        hasIcon={false}
        isIconOnly={false}
        isNullable
        searchItems={list}
        selectedItem={selected}
        updateSelection={item => {
          onUpdate(item);
        }}
      />
      {!!error && (
        <InlineEditError>
          <InlineEditErrorContent>{error}</InlineEditErrorContent>
        </InlineEditError>
      )}
    </InlineEditErrorWrapper>
  );
};
