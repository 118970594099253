import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';

// Container
import { ContoursContainer } from 'src/@settings/containers';

const Screen = () => (
  <SettingsLayout>
    <ContoursContainer />
  </SettingsLayout>
);

export const ContoursSettingsScreen = withRouteProps(Screen);
