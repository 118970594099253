import React from 'react';
// components
import { MapPopupMoveable, Icons } from '@ems/client-design-system';
// functions
import { mapDateTimeFormat, tableDateTimeFormat, getOperationTypeColor } from 'src/utils';
// style
import './styles.scss';
import { useConfigSelectors } from 'src/app/reducers/configReducer';

export const OperationPopup = ({
  latitude,
  longitude,
  selectedOperation,
  onClose,
  labels,
  mapApis,
  hover = false,
}: any) => {
  const configSelectors = useConfigSelectors();
  if (!selectedOperation) {
    return null;
  }
  const selectedTrackTheme = configSelectors.getTheme('operations');
  // operation time can be provided as one parameter operationFullTime or be split as operationDate and operationTime
  if (!selectedOperation.properties) {
    return null;
  }
  const {
    operationDate,
    operationTime,
    operationFullTime,
    operationType,
    aircraftCategory,
  } = selectedOperation.properties;
  const operationTypeColor = getOperationTypeColor(selectedTrackTheme, operationType);
  const arrow =
    selectedOperation.properties.operationType === 'Arrival' ? (
      <Icons
        iconName="ic-ui-arrow-left-long"
        size={24}
        style={{
          fill: operationTypeColor,
          color: operationTypeColor,
        }}
      />
    ) : (
      <Icons
        iconName="ic-ui-arrow-right-long"
        size={24}
        style={{
          fill: operationTypeColor,
          color: operationTypeColor,
        }}
      />
    );

  const timeDay = operationFullTime
    ? tableDateTimeFormat(operationFullTime)
    : mapDateTimeFormat(operationDate ? `${operationDate} ${operationTime}` : operationTime);

  // the airportId/remoteAirportId returns the string 'null' if the value is null
  const airportId =
    selectedOperation.properties.airportId !== 'null'
      ? selectedOperation.properties.airportId
      : '—';
  const remoteAirportId =
    selectedOperation.properties.remoteAirportId !== 'null'
      ? selectedOperation.properties.remoteAirportId
      : '—';

  const runwayName =
    selectedOperation.properties.runwayName !== 'null'
      ? selectedOperation.properties.runwayName
      : '—';

  const aircraftType =
    selectedOperation.properties.aircraftType !== 'null'
      ? selectedOperation.properties.aircraftType
      : '—';

  return (
    <MapPopupMoveable
      latitude={latitude ? latitude : selectedOperation.latitude}
      longitude={longitude ? longitude : selectedOperation.longitude}
      anchorLatitude={latitude ? latitude : selectedOperation.latitude}
      anchorLongitude={longitude ? longitude : selectedOperation.longitude}
      closeButton={true}
      onClose={onClose}
      draggable={!hover}
      anchorLine={!hover}
      offsetTop={5}
      offsetLeft={5}>
      <div className="ops-popup">
        <h3 className="ops-popup_title">
          <strong>{selectedOperation.properties.acid} </strong>
          <span className="ops-popup_icons">
            {aircraftCategory && (
              <Icons iconName={`ic-ac-${aircraftCategory.toLowerCase()}`} size={20} />
            )}
            {operationType && (
              <Icons
                iconName={`ic-ad-${operationType.toLowerCase()}`}
                size={32}
                style={{
                  fill: operationTypeColor,
                  color: operationTypeColor,
                }}
              />
            )}
          </span>
        </h3>
        <p>{timeDay}</p>
        <p className="ops-popup_airports">
          <span className="ops-popup_value">{airportId}</span> {arrow}
          <span className="ops-popup_value"> {remoteAirportId}</span>
        </p>
        <p>
          <span className="ops-popup_label">{labels.runwayName}:</span>
          <span className="ops-popup_value">{runwayName}</span>
        </p>
        <p>
          <span className="ops-popup_label">{labels.aircraftType}:</span>
          <span className="ops-popup_value">{aircraftType}</span>
        </p>
      </div>
    </MapPopupMoveable>
  );
};
