import gql from 'graphql-tag';

// Aircraft types for the filters
export const GET_MODELING_AIRCRAFT_TYPES = gql`
  query GetAircraftAssignments($type: AircraftNoiseModelType) {
    noiseModelingAircraftTypes(modelType: $type) {
      aircraftId
      aircraftName
      id
      maxStageLength
      noiseModelType
      supportsMaxMtowStageLength
    }
  }
`;

// Aircraft Assignments
export const AIRCRAFT_ASSIGNMENT_NODE_FRAGMENT = gql`
  fragment aircraftAssignmentItem on AircraftToNoiseModelingTypeSummary {
    aircraftType
    airline
    engineModel
    modelingAircraftId
    modelingAircraftName
    noiseModelType
    numberOfFlights
    source
    systemModelingAircraftName
  }
`;

export const GET_AIRCRAFT_ASSIGNMENTS = gql`
  query GetAircraftAssignments(
    $queryFilter: AircraftToNoiseModelingTypeSummaryFilter!
    $sortOrder: [OrderByType!]!
    $start: DateTimeOffset!
    $end: DateTimeOffset!
    $cursor: String
    $count: Int!
  ) {
    aircraftToNoiseModelingTypeSummariesByTimeRange(
      after: $cursor
      filter: $queryFilter
      sort: $sortOrder
      startTime: $start
      endTime: $end
      first: $count
    ) {
      totalCount
      ...aircraftAssignmentsPageInfo
      ...aircraftAssignmentsItems
    }
  }

  ${AIRCRAFT_ASSIGNMENT_NODE_FRAGMENT}

  fragment aircraftAssignmentsItems on AircraftToNoiseModelingTypeSummaryConnection {
    edges {
      node {
        ...aircraftAssignmentItem
      }
    }
  }

  fragment aircraftAssignmentsPageInfo on AircraftToNoiseModelingTypeSummaryConnection {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
`;

export const SET_AIRCRAFT_ASSIGNMENT = gql`
  mutation SetAircraftAssignment(
    $newNoiseModelType: AircraftNoiseModelType!
    $newAircraftType: String
    $newEngineModel: String
    $newAirline: String
    $newModelingAircraftId: String
  ) {
    setAircraftToNoiseModelingTypeMap(
      noiseModelType: $newNoiseModelType
      aircraftType: $newAircraftType
      engineModel: $newEngineModel
      airline: $newAirline
      modelingAircraftId: $newModelingAircraftId
      source: User
    ) {
      id
      aircraftType
      airline
      engineModel
      noiseModelType
      noiseModelingAircraftTypeId # This is the GUID id of the noise modeling aircraft type
      noiseModelingAircraftType {
        # This is the actual noise modeling aircraft type, which might be more
        #   useable because it contains the user-readable details like "A320-211" and "A320-211/CFM56-5A1"
        aircraftId # You don't need to include these details on the mutation return when using it in code
        aircraftName #   but it helps in debugging the GQL call
      }
      source
    }
  }
`;
