import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

// Selectors & utils
import { useLanguageSelectors } from 'src/app/reducers';
import { getDeployedProductId, tableDateTimeFormat } from 'src/utils';

// Components
import { CorrelatedEventCollapse, CorrelatedBlockTitle } from 'src/components';
import { Link } from 'react-router-dom';

// Constants
import {
  OPERATION_CORRELATION_COUNT_LIMIT,
  INFRINGEMENTS,
  INFRINGEMENT_DISPLAY_STRINGS,
  BLANK_STRING_PLACEHOLDER,
} from 'src/constants';
import { useHasRoutePermissions } from 'src/@operations/hooks/useHasRoutePermissions';

interface IInfringement {
  id: number;
  infringementType: string;
  rule: {
    name: string;
  };
  time: string;
}

interface IOperationInfringementsContainer {
  infringementData: IInfringement[];
}

export const OperationInfringementsContainer = ({
  infringementData,
}: IOperationInfringementsContainer) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: {
        correlatedCategories: { infringements: title },
      },
    },
  } = languageSelectors.getLanguage();

  const timeList: DateTime[] = infringementData.map(infringement =>
    DateTime.fromISO(infringement.time, { setZone: true })
  );
  const idList = infringementData.map(infringement => infringement.id);

  return (
    <div className="block">
      <CorrelatedBlockTitle
        idList={idList}
        timeList={timeList}
        blockTitle={title}
        screenPath={INFRINGEMENTS}
      />

      {infringementData.map(({ id, infringementType, rule, time }, key) => {
        if (key > OPERATION_CORRELATION_COUNT_LIMIT - 1) {
          return null;
        }

        const cardHeader = (
          <>
            {useHasRoutePermissions(INFRINGEMENTS) ? (
              <Link
                className="correlatedCollapse__header-name"
                to={{
                  pathname: `/${getDeployedProductId()}/${INFRINGEMENTS}/${id}`,
                }}>
                <span>{rule.name.trim() ? rule.name : BLANK_STRING_PLACEHOLDER}</span>
              </Link>
            ) : (
              <span className="correlatedCollapse__header-name">
                {rule.name.trim() ? rule.name : BLANK_STRING_PLACEHOLDER}
              </span>
            )}

            <span className="correlatedCollapse__header-data">
              {INFRINGEMENT_DISPLAY_STRINGS[infringementType]}
            </span>
            <span className="correlatedCollapse__header-data">
              {tableDateTimeFormat(time, false)}
            </span>
          </>
        );

        return <CorrelatedEventCollapse key={uuidv4()} headerChildren={cardHeader} />;
      })}
    </div>
  );
};
