import { useQuery } from '@apollo/react-hooks';
import { useCallback, useMemo } from 'react';
import { DEFAULT_QUERY_RESULT, LOAD_MORE_PAGE_SIZE } from '../constants';
import {
  IAircraftAssignmentsData,
  IAircraftAssignmentsVars,
  IFetchMoreArgs,
  ILoadMoreOptions,
  IModelingAircraftTypesData,
  IUpdateQueryOptions,
} from '../interfaces';
import { GET_AIRCRAFT_ASSIGNMENTS, GET_MODELING_AIRCRAFT_TYPES } from '../queries';
import { useSelectedNoiseModel } from './useSelectedNoiseModel';

export const useAircraftAssignmentData = (queryVariables: IAircraftAssignmentsVars) => {
  const { loading, data, variables, loadMore } = useAircraftAssignments(queryVariables);

  const {
    loading: loadingFilterData,
    noiseModelingAircraftTypes,
  } = useNoiseModelingAircraftTypes();

  const areQueriesLoading = useMemo(() => loadingFilterData || loading, [
    loading,
    loadingFilterData,
  ]);

  // Results
  const {
    aircraftToNoiseModelingTypeSummariesByTimeRange: {
      edges,
      pageInfo: { endCursor, hasNextPage },
      totalCount,
    },
  } = (!loading && data) || DEFAULT_QUERY_RESULT;

  /* 
    Because aircraft assignments data does not filter on operationType or airport id
    the totalCount will always be higher than what is returned when an operation type or airportId is selected in the filter
    hence the below check
  */
  const totalAircraftAssignments = !hasNextPage ? edges.length : totalCount;

  return {
    variables,
    areQueriesLoading,
    totalCount: totalAircraftAssignments,
    hasNextPage,
    endCursor,
    edges,

    loadMore,
    noiseModelingAircraftTypes,
  };
};

/* Fetches the aircraft assignments data and sets up the load more callback */
const useAircraftAssignments = (queryVariables: IAircraftAssignmentsVars) => {
  const { loading, variables, fetchMore, data } = useQuery<
    IAircraftAssignmentsData,
    IAircraftAssignmentsVars
  >(GET_AIRCRAFT_ASSIGNMENTS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const loadMore = useLoadMore(variables, fetchMore);
  return { loading, variables, data, loadMore };
};

// Callback for loading more aircraft assignment data
const useLoadMore = (variables, fetchMore) =>
  useCallback(
    (_client, _dispatcher, options: ILoadMoreOptions) => {
      const fetchMoreArguments: IFetchMoreArgs = {
        variables: {
          ...variables,
          count: LOAD_MORE_PAGE_SIZE,
          cursor: options.endCursor as string,
        },
        /* TODO: Update this when we update to AC3.
         * We manually merge the results here instead of using the relay methods,
         * due to the weak support for it in AC2. When we update our Apollo Client
         * we should leverage the automatic pagination in these situations
         */
        updateQuery: (
          {
            aircraftToNoiseModelingTypeSummariesByTimeRange: { edges: previousEdges },
          }: IAircraftAssignmentsData,
          {
            fetchMoreResult: {
              aircraftToNoiseModelingTypeSummariesByTimeRange: {
                pageInfo: newPageInfo,
                edges: newEdges,
                totalCount: newTotalCount,
                __typename,
              },
            },
          }: IUpdateQueryOptions
        ) => ({
          aircraftToNoiseModelingTypeSummariesByTimeRange: {
            pageInfo: newPageInfo,
            edges: [...previousEdges, ...newEdges],
            totalCount: newTotalCount,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            __typename,
          },
        }),
      };
      void fetchMore(fetchMoreArguments);
    },
    [variables, fetchMore]
  );

const useNoiseModelingAircraftTypes = () => {
  const selectedNoiseModel = useSelectedNoiseModel();
  const { loading, data } = useQuery<IModelingAircraftTypesData>(GET_MODELING_AIRCRAFT_TYPES, {
    variables: { type: selectedNoiseModel },
  });

  return {
    loading,
    noiseModelingAircraftTypes:
      data && data.noiseModelingAircraftTypes ? data.noiseModelingAircraftTypes : [],
  };
};
