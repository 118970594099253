import { MAP_BACKGROUND_TYPES } from './Map.consts';
import { MapLayerMouseEvent, Map as MapboxMap } from 'react-map-gl';
import { DEFAULT_MAP_STATE } from './context/Context';
import { MAP_ACTIONS } from './context/Reducer';

export type MapBackgroundType = keyof typeof MAP_BACKGROUND_TYPES;
export interface MapBackground {
  key: MapBackgroundType;
  value: string;
  label: string;
  imageName: string;
}

export type MapState = typeof DEFAULT_MAP_STATE;
export type MapProps = Parameters<typeof MapboxMap>[0];
export type MapActionType = keyof typeof MAP_ACTIONS;

export interface MapAction {
  type: MapActionType;
  payload;
}

export type CustomMapProps = Partial<MapProps> & {
  mapBackgroundStyle?: MapBackgroundType;
};
export interface ClickHandlerConfig {
  id: string;
  function: OnMapClickFunctions;
}
export type OnMapClickFunctions = ({ features, lngLat: { lng, lat } }: MapLayerMouseEvent) => void;

export enum Cursor {
  default = 'default',
  grab = 'grab',
  grabbing = 'grabbing',
  pointer = 'pointer',
  crosshair = 'crosshair',
  progress = 'progress',
  wait = 'wait',
  text = 'text',
  move = 'move',
  notAllowed = 'not-allowed',
}
