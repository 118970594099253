import { ApolloClient } from 'apollo-client';
import { dataActionTypes } from 'src/@infringements/newActionTypes';
import {
  fetchInfringementData,
  fetchNoiseMonitorLocations,
  fetchNoiseEvents,
  fetchInfringementCandidatesData,
} from 'src/@infringements/resolvers/infringementsResolver';
// const
import { GET_COUNT_DELAY } from 'src/constants';
// utils
import { ResponseValidator } from 'src/utils/responseValidator';
const request = new ResponseValidator();

// fetch 1 record and check for count
export const getDataCount = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    sortString: string;
    cursor: string;
    atcView: boolean;
    showCandidates: boolean;
  }
) => {
  const { showCandidates } = options;
  const checkTotalCount = data => {
    if (typeof data.totalCount !== undefined && data.totalCount !== -1) {
      newDispatcher({ type: dataActionTypes.GET_TOTAL_COUNT, data });
    } else {
      getDataCount(client, newDispatcher, options);
    }
  };
  const [instance, t] = request.get('infringements-count');
  request.set(instance, t);
  if (showCandidates) {
    fetchInfringementCandidatesData(client, options)
      .then(data => {
        if (request.isValid(instance, t)) {
          checkTotalCount(data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  } else {
    fetchInfringementData(client, options)
      .then(data => {
        if (request.isValid(instance, t)) {
          checkTotalCount(data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
};

// checks value of the data recieved for count
export const checkDataCount = (
  client: ApolloClient<object>,
  newDispatcher: any,
  data: any,
  options: {
    resultSize: number;
    sortString: string;
    cursor: string;
    atcView: boolean;
    showCandidates: boolean;
  }
) => {
  if (
    (data &&
      data.pageInfo &&
      data.pageInfo.hasNextPage &&
      typeof data.totalCount === 'undefined') ||
    data.totalCount === -1
  ) {
    // Fetch more to get count when not available after delay
    setTimeout(() => {
      getDataCount(client, newDispatcher, options);
    }, GET_COUNT_DELAY);
  } else {
    newDispatcher({ type: dataActionTypes.GET_TOTAL_COUNT, data });
  }
};

// Fetch data
export const fetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher?: any,
  sortString?: any,
  cursor: string = '',
  showCandidates: boolean = false,
  atcView: boolean = false
) => {
  if (!cursor) {
    // Only happens on the first fetch
    newDispatcher({ type: dataActionTypes.GET_FIRST_FETCH });
  }
  if (showCandidates) {
    fetchInfCandidatesData(client, newDispatcher, {
      resultSize,
      sortString,
      cursor,
      atcView,
    });
  } else {
    fetchInfData(client, newDispatcher, {
      resultSize,
      sortString,
      cursor,
      atcView,
    });
  }
};

const fetchInfCandidatesData = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    sortString?: string;
    cursor?: string;
    atcView: boolean;
  }
) => {
  const [instance, t] = request.get('fetch-infringements');
  const { sortString = '', atcView } = options;
  request.set(instance, t);
  fetchInfringementCandidatesData(client, options)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkDataCount(client, newDispatcher, data, {
          resultSize: 1,
          sortString,
          cursor: data.pageInfo.startCursor,
          atcView,
          showCandidates: true,
        });
        newDispatcher({
          type: dataActionTypes.INFRINGEMENTS_FETCHED,
          data,
          candidates: true,
        });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

const fetchInfData = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    sortString: string;
    cursor: string;
    atcView: boolean;
  }
) => {
  const [instance, t] = request.get('fetch-infringements');
  const { sortString, atcView } = options;
  request.set(instance, t);
  fetchInfringementData(client, options)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkDataCount(client, newDispatcher, data, {
          resultSize: 1,
          sortString,
          cursor: data.pageInfo.startCursor,
          atcView,
          showCandidates: false,
        });
        newDispatcher({ type: dataActionTypes.INFRINGEMENTS_FETCHED, data, candidates: false });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const setCandidatesEnabled = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher: any,
  sortString: any,
  showCandidates: boolean,
  atcView?: boolean
) => {
  newDispatcher({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: showCandidates });
  resetAndFetchData(client, newDispatcher, {
    resultSize,
    sortString,
    showCandidates,
    atcView,
  }); // fetch the next page
};

export const resetAndFetchData = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    sortString: string;
    showCandidates?: boolean;
    atcView?: boolean;
  }
) => {
  const { resultSize, sortString, showCandidates = false, atcView = false } = options;
  newDispatcher({ type: dataActionTypes.RESET_DATA });
  fetchData(client, resultSize, newDispatcher, sortString, '', showCandidates, atcView);
};

export const selectRow = (ids: number[], newDispatcher: any) => {
  newDispatcher({ type: dataActionTypes.SELECT_ROW, data: ids });
};

export const loadMore = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    showCandidates?: boolean;
    atcView?: boolean;
  }
) => {
  const { resultSize, sortString, endCursor, showCandidates, atcView } = options;
  newDispatcher({ type: dataActionTypes.LOAD_MORE });
  fetchData(client, resultSize, newDispatcher, sortString, endCursor, showCandidates, atcView);
};

export const sortTable = async (data: any, newDispatcher: any) => {
  const { sortName } = data;
  await newDispatcher({ type: dataActionTypes.SORT_TABLE, data: sortName });
};

export const fetchNoiseMonitors = (
  client: ApolloClient<object>,
  dispatcher: any,
  airportIds: string[]
) => {
  const [instance, t] = request.get('fetch-noise-monitors');
  request.set(instance, t);
  fetchNoiseMonitorLocations(client, airportIds)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        dispatcher({ type: dataActionTypes.NOISE_MONITORS_RECEIVED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchNoiseEventData = (
  client: ApolloClient<object>,
  dispatcher: any,
  noiseEventIds: number[]
) => {
  const [instance, t] = request.get('fetch-noise-events');
  request.set(instance, t);
  dispatcher({ type: dataActionTypes.BEGIN_NOISE_EVENT_FETCH });
  fetchNoiseEvents(client, noiseEventIds)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        dispatcher({ type: dataActionTypes.NOISE_EVENTS_RECEIVED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const resetNoiseEventData = (dispatcher: any) => {
  dispatcher({ type: dataActionTypes.RESET_NOISE_EVENTS });
};
