import React, { useState, useContext, useEffect, FC } from 'react';
// components
import { Spinner, Table, Checkbox } from '@ems/client-design-system';
// providers
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// selectors
import { useAircraftMappingsSelector, useTableSelectors } from 'src/@settings/reducers';
import { useLanguageSelectors } from 'src/app/reducers';
// interfaces
import {
  IAircraftMapping,
  IAircraftMappingsContainer,
  IAircraftMappingsData,
  IMutationItem,
  TMode,
} from 'src/@settings/interfaces';
// functions
import {
  formatAircraftMappingsData,
  getLtoEmissionMappings,
  getEnrouteEmissionMappings,
  getFormattedMutationItem,
  getColumnTypes,
  updateTableIds,
} from 'src/@settings/functions';
// utils
import { convertMapToArray, sortTableData, formatHeaders } from 'src/utils';
// constants
import { AIRCRAFT_MAPPING_MODULE } from 'src/constants';
import { Prompt } from 'react-router-dom';
import { sortAircraftMappingTable, updateShowMapped } from 'src/@settings/actions';

export const AircraftMappingsContainer: FC<IAircraftMappingsContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateAicraftMappingsData,
  discardMutationData,
}) => {
  // dispatcher
  const dispatcher = useContext(SettingsDispatchContext);

  const [rowData, setRowData] = useState<IAircraftMappingsData[]>([]);

  const tableSelectors = useTableSelectors();
  const sortObject = tableSelectors.getSortObject(AIRCRAFT_MAPPING_MODULE);
  const sortString = tableSelectors.getSortString(AIRCRAFT_MAPPING_MODULE);
  const languageSelectors = useLanguageSelectors();
  const translationData = languageSelectors.getLanguage();

  const aircraftMappingsSelector = useAircraftMappingsSelector();
  const loading: boolean = aircraftMappingsSelector.getIfLoading();
  const items = aircraftMappingsSelector.getMappings();
  const ltoEmissions = aircraftMappingsSelector.getLtoEmissions();
  const enrouteEmissions = aircraftMappingsSelector.getEnrouteEmissions();
  const mappedLtoProportion = aircraftMappingsSelector.getLtoMappedProportion();
  const mappedEnrouteProportion = aircraftMappingsSelector.getEnrouteMappedProportion();
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());
  const [showMapped, setShowMapped] = useState<boolean>(false);

  const {
    fields: {
      aircraftMappings: {
        status: statusColumnHeader,
        aircraft: aircraftColumnHeader,
        engine: engineColumnHeader,
        numberOfFlights: numberOfFlightsColumnHeader,
        ltoModel: ltoModelColumnHeader,
        enrouteModel: enrouteModelColumnHeader,
      },
    },
    components: {
      hints: {
        areYouSureYouWantToLeave,
        mappedLtoProportion: mappedLtoProportionText,
        mappedEnrouteProportion: mappedEnrouteProportionText,
        noDataTitle,
        tryChangingFiltersOrDate,
      },
      info: { aircraftMappingInfo },
      labels: { aircraftMappingShowAllMappings },
    },
  } = translationData;

  // Table header set up
  const columnData = [
    { columnName: 'status', title: statusColumnHeader },
    { columnName: 'aircraftType', key: 'aircraftType', title: aircraftColumnHeader },
    { columnName: 'engine', key: 'engine', title: engineColumnHeader },
    {
      columnName: 'numberOfFlights',
      key: 'numberOfFlights',
      title: numberOfFlightsColumnHeader,
    },
    { columnName: 'ltoModel', key: 'ltoModel', title: ltoModelColumnHeader },
    { columnName: 'enrouteModel', key: 'enrouteModel', title: enrouteModelColumnHeader },
  ];

  const rowHeaders = formatHeaders(
    AIRCRAFT_MAPPING_MODULE,
    columnData,
    rowData.length,
    dispatcher,
    sortAircraftMappingTable,
    tableSelectors,
    languageSelectors.getLanguage(),
    '',
    loading,
    true
  );

  const aircraftMappingColumns: string[] = [
    'status',
    'aircraftType',
    'engine',
    'numberOfFlights',
    'ltoModel',
    'enrouteModel',
  ];

  const [data, setData] = useState<IAircraftMapping[]>(items);
  const copyOfAircraftMappings = items.map(item => ({ ...item }));
  const [apiData, setApiData] = useState(copyOfAircraftMappings);

  useEffect(() => {
    formatAndSetRowData(data);
  }, [data]);

  useEffect(() => {
    setData(apiData);
    formatAndSetRowData(apiData);
    mutationData.clear();
    setMutationData(mutationData);
  }, [areChangesDiscarded]);

  useEffect(() => {
    setData(sortTableData(items, sortObject));
    setApiData(items);
  }, [items]);

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
  }, [discardMutationData]);

  const updateAircraftMappings = (aircraftMappingId, selectedItem) => {
    setData(prev => {
      const prevData = prev.slice(0);
      prevData[aircraftMappingId] = { ...prevData[aircraftMappingId], ...selectedItem };
      handleMutation('Update', prevData[aircraftMappingId]);
      return prevData;
    });
    setChangesAvailable(true);
  };

  const formatAndSetRowData = data => {
    if (data.length > 0) {
      const formattedData = formatAircraftMappingsData(
        updateTableIds([], data),
        getLtoEmissionMappings(ltoEmissions),
        getEnrouteEmissionMappings(enrouteEmissions),
        translationData,
        updateAircraftMappings
      );
      setRowData(formattedData);
    }
  };

  useEffect(() => {
    const sortData = sortTableData(rowData, sortObject);
    setRowData(sortData);
  }, [sortString]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(
      getFormattedMutationItem(mode, updatingItem, mutationData, AIRCRAFT_MAPPING_MODULE)
    );
    const { items } = convertMapToArray(mutationData);
    updateAicraftMappingsData(items);
  };

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      {' '}
      <Spinner loading size="l" centered />{' '}
    </div>
  );

  const onCheckBoxSelect = () => {
    updateShowMapped(dispatcher, !showMapped);
    setShowMapped(!showMapped);
  };

  const getAircraftMappingComponent = () => (
    <div>
      <div className="aircraft-mappings-header-text">{aircraftMappingInfo}</div>
      <div className="aircraft-mappings-proportions">
        <div className="lto-mappings-proportions">
          <span>{mappedLtoProportionText}:</span> {mappedLtoProportion}
        </div>
        <div className="enroute-mappings-proportions">
          <span>{mappedEnrouteProportionText}: </span> {mappedEnrouteProportion}
        </div>
        <Checkbox
          label={aircraftMappingShowAllMappings}
          focused={showMapped}
          checked={showMapped}
          onClick={onCheckBoxSelect}
        />
      </div>
      <div className="multiple-emission-table">
        <Table
          className="aircraft-mappings-table"
          data={rowData}
          columns={aircraftMappingColumns}
          rowHeaders={rowHeaders}
          gridID={'aircraftMappings'}
          columnTypes={getColumnTypes(aircraftMappingColumns)}
          languageData={{
            noDataTitle: `${noDataTitle}`,
            noDataText: `${tryChangingFiltersOrDate}`,
          }}
        />
      </div>
      <Prompt message={areYouSureYouWantToLeave} when={mutationData.size > 0} />
    </div>
  );

  return loading ? getSpinnerComponent() : getAircraftMappingComponent();
};
