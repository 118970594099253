import React from 'react';

// Hooks
import { useLanguageSelectors } from 'src/app/reducers';
import { useAircraftMappings } from '../providers';

// Components
import {
  MappingProportion,
  MappingProportionDescription,
  MappingProportionWrapper,
} from './AircraftMappings.styled';

// Constants
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';
import { useMappedPercent } from '../hooks';

export const AMStatsHeader = (): JSX.Element => {
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const {
    state: {
      isLoading,
      data: { edges },
    },
  } = useAircraftMappings();

  const {
    mappedLtoPercent,
    mappedEnroutePercent,
    totalFlights,
    mappedEnrouteFlights,
    mappedLtoFlights,
  } = useMappedPercent(edges);

  // Language Strings
  const {
    components: {
      info: { aircraftMappingInfo: pageDescription },
      hints: {
        mappedLtoProportion: mappedLtoProportionString,
        mappedEnrouteProportion: mappedEnrouteProportionString,
      },
    },
  } = translationData;
  return (
    <MappingProportionWrapper>
      <MappingProportionDescription>{pageDescription}</MappingProportionDescription>

      <MappingProportion>
        <span>
          {`${mappedLtoProportionString}: `}
          {isLoading ? (
            BLANK_STRING_PLACEHOLDER
          ) : (
            <>
              <strong>{`${mappedLtoPercent}% `}</strong>
              {`( ${mappedLtoFlights.toLocaleString()} of ${totalFlights.toLocaleString()} )`}
            </>
          )}
        </span>
        <span>
          {`${mappedEnrouteProportionString}: `}
          {isLoading ? (
            BLANK_STRING_PLACEHOLDER
          ) : (
            <>
              <strong>{`${mappedEnroutePercent}% `}</strong>
              {`( ${mappedEnrouteFlights.toLocaleString()} of ${totalFlights.toLocaleString()} )`}
            </>
          )}
        </span>
      </MappingProportion>
    </MappingProportionWrapper>
  );
};
