import React, { useEffect, useMemo } from 'react';
import { Connectors } from './connectors/Connectors';
import { Nodes } from './nodes/Nodes';
import { useTaxiPathData } from './useTaxiPathData';
import { useTaxiPathContext } from '../../context/TaxiPathContext';
import { useFlyTo } from 'src/components/Map/hooks/useFlyTo';

export const TaxiPathLayer = () => {
  const { nodes, connectors } = useTaxiPathData();
  useFlyToSearchedNode();
  return (
    <>
      <Nodes geojson={nodes} />
      <Connectors geojson={connectors} />
    </>
  );
};

const useFlyToSearchedNode = () => {
  const {
    state: { searchedNodeId },
  } = useTaxiPathContext();
  const { nodes } = useTaxiPathData();
  const searchedNode = useMemo(
    () => nodes.features.find(node => String(node.properties.NodeId) === String(searchedNodeId)),
    [nodes, searchedNodeId]
  );
  const flyTo = useFlyTo();

  useEffect(() => {
    if (searchedNode) {
      const coordinates = searchedNode.geometry.coordinates;
      flyTo(coordinates[0], coordinates[1]);
    }
  }, [searchedNode, flyTo]);
};
