import { useConfigSelectors } from 'src/app/reducers';
import { useWindowDimensions } from 'src/utils';

const LANDSCAPE_MIN_WIDTH_PX = 1280;
const NAV_BAR_WIDTH = 72;
const MAX_FLUID_CONTAINER_WIDTH = 1150;

export const useAvailableOperationsTableWidth = () => {
  const { width: windowWidthPx } = useWindowDimensions();
  const configSelectors = useConfigSelectors();
  const isPortraitMode = configSelectors.getIfPortraitLayout();

  // In Landscape view which is >= 1280px. Correlated column width starts at 46px and then increases linearly.
  if (!isPortraitMode && windowWidthPx >= LANDSCAPE_MIN_WIDTH_PX) {
    // referenced from the css rules for fluid container in landscape - minus all the columns for operations table (excluding correlated)
    return windowWidthPx * 0.6 < MAX_FLUID_CONTAINER_WIDTH
      ? windowWidthPx * 0.6 - NAV_BAR_WIDTH
      : MAX_FLUID_CONTAINER_WIDTH;
  } else {
    return windowWidthPx - NAV_BAR_WIDTH;
  }
};
