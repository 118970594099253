import React from 'react';

export const emissionMappings = {
  CO2: (
    <span>
      CO<sub className="subscript">2</sub>
    </span>
  ),
  H2O: (
    <span>
      H<sub className="subscript">2</sub>0
    </span>
  ),
  NOX: (
    <span>
      NO<sub className="subscript">X</sub>
    </span>
  ),
  N2O: (
    <span>
      N<sub className="subscript">2</sub>0
    </span>
  ),
  CH4: (
    <span>
      CH<sub className="subscript">4</sub>
    </span>
  ),
  GHGt: (
    <span>
      GHG<sub className="subscript">t</sub>
    </span>
  ),
  CH4e: (
    <span>
      CH<sub className="subscript">4</sub>e
    </span>
  ),
  N2Oe: (
    <span>
      N<sub className="subscript">2</sub>Oe
    </span>
  ),
};
