import React, { Dispatch, useReducer, useContext } from 'react';

type ActionType = 'SET_TABLE_LOAD_STATE' | 'SET_MAP_LOAD_STATE';

interface ScreenLoadContextState {
  tableLoadState: boolean;
  mapLoadState: boolean;
}

interface ScreenLoadContextValue {
  screenLoadStates: ScreenLoadContextState;
  setScreenLoadStates: Dispatch<{ type: ActionType; payload: boolean }>;
}

const screenLoadReducer = (
  state: ScreenLoadContextState,
  action: { type: ActionType; payload: boolean }
): ScreenLoadContextState => {
  switch (action.type) {
    case 'SET_TABLE_LOAD_STATE':
      return { ...state, tableLoadState: action.payload };
    case 'SET_MAP_LOAD_STATE':
      return { ...state, mapLoadState: action.payload };
    default:
      return state;
  }
};

export const ScreenLoadContext = React.createContext<ScreenLoadContextValue | undefined>(undefined);

export const ScreenLoadStateProvider = ({ children }: { children?: React.ReactNode }) => {
  const [screenLoadStates, dispatch] = useReducer(screenLoadReducer, {
    tableLoadState: true,
    mapLoadState: true,
  });

  return (
    <ScreenLoadContext.Provider value={{ screenLoadStates, setScreenLoadStates: dispatch }}>
      {children}
    </ScreenLoadContext.Provider>
  );
};

export const useScreenLoadStateContext = () => {
  const ctxValue = useContext(ScreenLoadContext);
  if (ctxValue === undefined) {
    throw new Error('Expected an AppProvider somewhere in the react tree to set context value');
  }
  return ctxValue;
};
