import React, { useState, useEffect } from 'react';

// Components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';

// Hooks
import { useColumnData, useFetchTableData } from '../hooks';
import { useAircraftMappings } from '../providers/aircraftMappings';
import { useLanguageSelectors } from 'src/app/reducers';
import { useScenarios } from 'src/@settings/provider/Scenarios/ScenariosProvider';

// Constants
import { tableColumns } from '../constants';
import { AIRCRAFT_MAPPING_MODULE } from 'src/constants';

// Functions
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { IDateRange, TUpdateUrl } from 'src/app/props';
import { useTableColumnHeaders } from 'src/components/TableComponents/TableColumnHeader/useTableColumnHeaders';
import { ISortActionCallback } from 'src/components/TableComponents/TableColumnHeader/interfaces';
import {
  dateFormat,
  dateTimeInQuery,
  format as formatDateObject,
} from 'src/utils/dateTimeConverters';
import { IAircraftMappingTableData } from '../types';
import { MappingTableWrapper } from './AircraftMappings.styled';

export const AMTable = ({
  tableData,
  updateUrl,
}: {
  tableData: IAircraftMappingTableData[];
  updateUrl: TUpdateUrl;
}): JSX.Element => {
  // Providers
  const dateStore = dateRangeStore.getDateFilters();
  const {
    dispatch,
    state: {
      isLoading,
      queryVariables,
      data: {
        pageInfo: { endCursor, hasNextPage },
      },
    },
  } = useAircraftMappings();

  // Hooks
  const { loadMore } = useFetchTableData();
  const columnData = useColumnData();

  // States
  const [currentDateRange, setCurrentDateRange] = useState<IDateRange>(dateStore);

  // Actions
  const sortAction = ({ sortName, sortObject: { field, direction } }: ISortActionCallback) => {
    const sortDirection = field === sortName ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
    dispatch({
      type: 'TABLE_SORT',
      data: {
        field: sortName,
        direction: sortDirection,
      },
    });
  };

  const {
    state: { activeScenario },
  } = useScenarios();

  useEffect(() => {
    if (activeScenario && activeScenario.id) {
      dispatch({ type: 'UPDATE_SCENARIO_ID', data: activeScenario.id });
      // Update date range on the date picker
      dateRangeStore.setDateFilters({
        from: formatDateObject(new Date(activeScenario.startTime), dateFormat),
        to: formatDateObject(new Date(activeScenario.endTime), dateFormat),
      });
      // Update the url params
      updateUrl({
        from: new Date(activeScenario.startTime),
        to: new Date(activeScenario.endTime),
      });
    }
  }, [activeScenario]);

  // Strings
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
      },
      hints: { tryChangingFiltersOrDate },
    },
    screens: {
      aircraftMappings: {
        errors: { noDataFound },
      },
    },
  } = languageSelectors.getLanguage();

  const getSort = () => queryVariables.sort[0];

  const rowHeaders = useTableColumnHeaders({
    columnData,
    sortAction,
    sortObjectSelector: {
      getSortObject: () => getSort(),
    },
    translationModuleName: AIRCRAFT_MAPPING_MODULE,
    isLoading,
  });

  // Run once to ensure date values are set
  useEffect(() => {
    setCurrentDateRange(dateStore);
    dispatch({
      type: 'UPDATE_DATE_RANGE',
      data: {
        start: dateTimeInQuery(dateStore.from, 'start'),
        end: dateTimeInQuery(dateStore.to, 'end'),
      },
    });
  }, []);

  // Update date range in query
  useEffect(() => {
    if (JSON.stringify(dateStore) !== JSON.stringify(currentDateRange)) {
      setCurrentDateRange(dateStore);
      dispatch({
        type: 'UPDATE_DATE_RANGE',
        data: {
          start: dateTimeInQuery(dateStore.from, 'start'),
          end: dateTimeInQuery(dateStore.to, 'end'),
        },
      });
    }
  }, [dateStore]);

  return (
    <MappingTableWrapper>
      <Table
        className={`aircraft-mappings-table`}
        wrapperClassName={'feature-wrapper'}
        loading={isLoading}
        rowHeaders={rowHeaders}
        data={!isLoading ? tableData : []}
        columns={tableColumns}
        gridID="aircraft-mappings-table"
        languageData={{ noDataTitle: noDataFound, noDataText: tryChangingFiltersOrDate, endTable }}
        hasEnded={tableData.length && !hasNextPage}
        showDashIfEmpty
      />
      <LoadMoreBar
        isVisible={hasNextPage}
        isLoadingMore={tableData.length && isLoading}
        loadMore={loadMore}
        loadMoreText={loadMoreText}
        resultSize={tableData.length}
        endCursor={hasNextPage && endCursor}
        dispatcher={null}
        sortString={null}
      />
    </MappingTableWrapper>
  );
};
