import { keyToActionTypes } from 'src/utils/keyToActionTypes';
// constants
import { INFRINGEMENT_RULE_TYPES, MONITOR_LOCATIONS, RUNWAYS } from 'src/constants';

export const appActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    UPDATE_CONFIG: null,
    UPDATE_USER_CONFIG: null,
    UPDATE_OPERATION_THEME: null,
    UPDATE_DISTANCE_UNITS: null,
    UPDATE_COLOR_BY_ALTITUDE_COLORS: null,
    UPDATE_COLOR_BY_ALTITUDE_BANDS: null,
    CONFIG_ERROR: null,
    UPDATE_LANGUAGE: null,
    LANGUAGE_ERROR: null,
    UPDATE_ROLES: null,
    ROLES_ERROR: null,
    UPDATE_FILTERS: null,
    FILTERS_ERROR: null,
    // Infringement Rules
    UPDATE_INFRINGEMENT_RULES: null,
    INFRINGEMENT_RULES_ERROR: null,
    IGNORE_INFRINGEMENT_RULES: null,
    LOADING_INFRINGEMENT_RULES: null,
    ADD_INFRINGEMENT_RULE: null,
    SORT_INFRINGEMENT_RULES: null,
    INFRINGEMENT_RULES_LOADED: null,
    CHECK_PUSH_NOTICES: null,
    // Noise Scenarios
    UPDATE_NOISE_SCENARIO_LICENSE_ACCEPTED: null,
    UPDATE_NOISE_SCENARIO_SELECTED_MODEL: null,
  },
  'APP'
);

// for geometry global store
export const geometryActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    [INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT]: null,
    [INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT]: null,
    [INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT]: null,
    [MONITOR_LOCATIONS]: null,
    [RUNWAYS]: null,
    GIS_LAYERS: null,
    UPDATE_KML: null,
    GEOMETRY_LOADING: null,
    GEOMETRY_ERROR: null,
  },
  'APP'
);

// for geometry global store
export const exportActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    CAPTURE_IMAGE: null,
    IMAGE_CAPTURED: null,
    UPDATE_EXPORT_IN_PROGRESS: null,
    UPDATE_EXPORT_COMPLETE: null,
    UPDATE_EXPORT_CLEAR: null,
    EXPORT_ERROR: null,
  },
  'APP'
);

export const tagDataActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    TAG_DATA_UPDATE: null,
  },
  'OPERATIONS'
);
