import { Dispatch, useCallback, useMemo } from 'react';
import { ViewStateChangeEvent } from 'react-map-gl';
import { MAP_ACTIONS } from '../Reducer';
import { MapProps } from '../../map.types';
import { useMapStyle } from '../../hooks/useMapStyles';

export const useRequiredMapProps = (dispatch: Dispatch<any>): MapProps => {
  const onDrag = useCallback(
    (nextViewport: ViewStateChangeEvent) => {
      dispatch({ type: MAP_ACTIONS.SET_VIEW_STATE, payload: nextViewport.viewState });
    },
    [dispatch]
  );
  const onZoom = useCallback(
    (nextViewport: ViewStateChangeEvent) => {
      dispatch({ type: MAP_ACTIONS.SET_VIEW_STATE, payload: nextViewport.viewState });
    },
    [dispatch]
  );
  // This sucks - need to trigger a resize event when the map loads
  // to get it to resize to its containing element's dimensions
  const onLoad = useCallback(event => event.target.resize(), []);
  const mapStyle = useMapStyle();

  return useMemo(
    () => ({
      onDrag,
      onLoad,
      onZoom,
      mapStyle,
    }),
    [onLoad, onDrag, mapStyle]
  );
};
