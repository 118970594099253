import { useContext } from 'react';
import { IInfringementRulesSelectors, IStoreState, IInfringementRulesState } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateContext';
import { useSelectors } from 'src/utils/storeHelpers';
import { setTableSortFieldAndDirection, sortTableData } from 'src/utils';

export const useInfringementRulesSelectors: () => IInfringementRulesSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const infringementRulesState: IInfringementRulesState = state.infringementRules;

  return useSelectors(infringementRulesState, (state: IInfringementRulesState) => ({
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    getIsLoading: () => state.isLoading,
    getRules: () => state.rules,
    getRule: (id: number) => {
      if (state.rules.length && id) {
        const rule = state.rules.find(rule => rule.id === id);
        return rule;
      } else {
        return {};
      }
    },
    getRulesById: (ids: number[]) => {
      return state.rules.filter(rule => ids.includes(rule.id));
    },
    hasAccessFailed: () => state.failedToAccessInfringementRules,
    getGateIdsByRuleId: (id: number) => {
      const rule = state.rules.find(rule => rule.id === id);
      if (rule && rule.gateCriteria) {
        return rule.gateCriteria.map(gates => gates.gateId);
      } else {
        return [];
      }
    },
    getSortObject: () => state.sortObject,
  }));
};

export const infringementRulesReducer = (state: IInfringementRulesState, action: any) => {
  switch (action.type) {
    case appActionTypes.LOADING_INFRINGEMENT_RULES:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case appActionTypes.UPDATE_INFRINGEMENT_RULES:
      return Object.assign({}, state, {
        rules: sortTableData(action.payload, state.sortObject),
        initialLoadCompleted: true,
      });
    case appActionTypes.INFRINGEMENT_RULES_LOADED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case appActionTypes.IGNORE_INFRINGEMENT_RULES:
      return Object.assign({}, state, {
        initialLoadCompleted: true,
      });
    case appActionTypes.INFRINGEMENT_RULES_ERROR:
      return Object.assign({}, state, {
        rules: null,
        failedToAccessInfringementRules: true,
      });
    case appActionTypes.SORT_INFRINGEMENT_RULES:
      const newSortObject = setTableSortFieldAndDirection(
        action.payload.sortName,
        state.sortObject
      );
      return Object.assign({}, state, {
        sortObject: newSortObject,
        rules: sortTableData(state.rules, newSortObject),
      });
    default:
      return state;
  }
};
