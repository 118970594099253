import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IGetDeviceData, IGetDeviceVariables } from '../../interfaces';
import { GET_DEVICE } from '../../queries';

export const useFetchDeviceData = () => {
  const pageParams: { id: string } = useParams();
  const deviceId = useMemo(() => Number(pageParams.id) ?? null, [pageParams.id]);
  const { loading: isLoadingDeviceData, data: deviceData, refetch: refetchDeviceData } = useQuery<
    IGetDeviceData,
    IGetDeviceVariables
  >(GET_DEVICE, {
    variables: {
      id: deviceId,
    },
  });

  return { isLoadingDeviceData, deviceData, refetchDeviceData, deviceId };
};
