import React from 'react';

export const overflowTextModifier = (longText: string, shortText?: string) => {
  const determinedShortText = shortText ? shortText : longText;
  return (
    <span className="overflower">
      <span className="overflower-short" aria-hidden="true" title={determinedShortText}>
        {determinedShortText}
      </span>
      <span className="overflower-long">{longText}</span>
    </span>
  );
};
