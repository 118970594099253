import React from 'react';
import { IInfDetailsData, IInfRuleFormErrors } from 'src/@settings/interfaces';
import { INoiseInfringementRule, INoiseMonitor, IThreshold } from 'src/app/interfaces';
import { NoiseRuleConfigurator } from 'src/components';
import { INMTOption, INoiseRuleItem } from 'src/components/NoiseRuleConfigurator/interfaces';
import { NOISE_MONITOR_METRICS } from 'src/constants';

export const NoisePanel = (infDetailsData: IInfDetailsData) => {
  const infRule = infDetailsData.infRule as INoiseInfringementRule;
  const {
    setInfRule,
    allNoiseMonitors,
    isEditing,
    errors,
  }: {
    setInfRule: (infRule: INoiseInfringementRule) => void;
    allNoiseMonitors: INoiseMonitor[];
    isEditing: boolean;
    errors?: IInfRuleFormErrors;
  } = infDetailsData;

  const nmtDetailsById: any = {};
  if (infRule.thresholds && infRule.thresholds.length) {
    const nmtIds = infRule.thresholds.map(threshold => threshold.locationId);
    nmtIds.forEach(nmtId => {
      const nmt = infRule.thresholds.find(threshold => threshold.locationId === nmtId);
      const nmtDetails = allNoiseMonitors.find(nmt => nmt.id === nmtId);
      if (nmt && nmtDetails) {
        nmtDetailsById[nmtId.toString()] = {
          name: nmtDetails.name,
          threshold: nmt.threshold,
        };
      }
    });
  }

  const noiseRuleData: INoiseRuleItem[] = allNoiseMonitors.map(nmt => {
    const nmtDetails = nmtDetailsById[nmt.id];
    if (nmtDetails) {
      return {
        id: nmt.id,
        name: nmt.name,
        threshold: nmtDetails.threshold,
      };
    } else {
      return {
        id: nmt.id,
        name: nmt.name,
        threshold: null,
      };
    }
  });

  const handleNoiseRuleChange = (selectedNmt: INMTOption, metric: string) => {
    if (selectedNmt) {
      const threshold: IThreshold = {
        locationId: Number(selectedNmt.key),
        threshold: selectedNmt.threshold,
      };
      setInfRule(
        Object.assign({}, infRule, { metric, thresholds: [threshold] }, { hasChanged: true })
      );
    }
  };

  const selectedNoiseMonitor = infRule.thresholds
    ? allNoiseMonitors.find(noiseMonitor => infRule.thresholds[0].locationId === noiseMonitor.id)
    : null;

  return (
    <NoiseRuleConfigurator
      nmts={noiseRuleData}
      onNoiseRuleChange={handleNoiseRuleChange}
      metric={infRule.metric || NOISE_MONITOR_METRICS.LMAX}
      isReadOnly={!isEditing}
      formErrors={errors}
      selectedNoiseMonitor={selectedNoiseMonitor}
    />
  );
};
