import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button, Icons, Spinner, TextInput } from '@ems/client-design-system';
import { SearchResult } from './autoSearch.types';

const DELAY = 250;

interface AutoSearchProps {
  searchResults: SearchResult[];
  resultsNotFound?: boolean;
  onSearchFocus?: () => void;
  onSearchChange: (value: string) => void;
  onSelectedResult: (result: SearchResult) => void;
  onClearResult: () => void;
  overwriteInputValue?: string;
  autoFocusOnOpen?: boolean;
  hideClearBtnOnSelect?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  translationData?: {
    placeholder: string;
    notFound: string;
  };
}
export const AutoSearch: FC<AutoSearchProps> = ({
  searchResults = [],
  resultsNotFound = false,
  onSearchFocus,
  onSearchChange,
  onSelectedResult,
  onClearResult,
  overwriteInputValue,
  autoFocusOnOpen = false,
  hideClearBtnOnSelect = false,
  isLoading = false,
  disabled = false,
  translationData = {
    placeholder: 'Search',
    notFound: 'Not Found',
  },
}) => {
  const { placeholder, notFound: notFoundText } = translationData;
  const [init, setInit] = useState<boolean>(false);
  const [isClearBtnHidden, updateIsClearBtnHidden] = useState<boolean>(hideClearBtnOnSelect);
  const [active, setActive] = useState<boolean>(false);
  const [searchText, updateAddress] = useState<string>('');
  const [highlight, updateHighlight] = useState<number>(0);
  const [inputRef, updateInputRef] = useState<HTMLInputElement>();

  const handleFocus = () => {
    if (onSearchFocus) {
      onSearchFocus();
      showDropdown();
    }
  };

  const handleChange = (value: string) => {
    updateAddress(value);
    if (value && value.trim() !== searchText.trim()) {
      if (onSearchChange) {
        onSearchChange(value);
      }
    }
  };
  const selectResult = (searchText: SearchResult) => {
    if (searchText && typeof searchText.value !== 'undefined') {
      updateAddress(searchText.value);
      if (onSelectedResult) {
        onSelectedResult(searchText);
      }
    }
  };
  const clearResults = () => {
    updateAddress('');
    if (inputRef) {
      inputRef.focus({ preventScroll: true });
    }
    if (onClearResult) {
      onClearResult();
    }
  };
  const hideDropdown = () => setActive(false);
  const showDropdown = () => setActive(true);

  useEffect(() => {
    if (inputRef && autoFocusOnOpen && !init) {
      if (inputRef) {
        inputRef.focus({ preventScroll: true });
      }
      setInit(true);
    }
  }, [inputRef]);

  useEffect(() => {
    if (overwriteInputValue) {
      updateAddress(overwriteInputValue);
    }
  }, [overwriteInputValue]);

  useEffect(() => {
    if (hideClearBtnOnSelect) {
      updateIsClearBtnHidden(!searchResults.length);
    }
  }, [searchResults]);

  return (
    <>
      <TextInput
        className="auto-search"
        placeholder={placeholder}
        value={searchText}
        autoComplete="none"
        disabled={disabled}
        accessInputRef={(ref: HTMLInputElement) => {
          updateInputRef(ref);
        }}
        onChange={({ target }) => {
          handleChange(target.value || '');
        }}
        onPressEnter={() => {
          selectResult(searchResults[highlight]);
        }}
        onEscape={() => {
          clearResults();
        }}
        onArrowUp={() => {
          if (highlight > 0) {
            updateHighlight(highlight - 1);
          }
        }}
        onArrowDown={() => {
          if (highlight < searchResults.length - 1) {
            updateHighlight(highlight + 1);
          }
        }}
        onFocus={handleFocus}
        onBlur={() => setTimeout(() => hideDropdown(), DELAY)}>
        <>
          {!isLoading && searchText.length && !isClearBtnHidden ? (
            <Button
              className="auto-search--clear"
              style="subtle"
              leftIcon={<Icons iconName="ic-ui-cancel-sm" />}
              iconOnly
              onClick={clearResults}
            />
          ) : null}
          <Spinner className="auto-search--loading" size="s" loading={isLoading} />
          {active && searchResults.length ? (
            <ul className={`auto-search_results`}>
              {!resultsNotFound &&
                searchResults.map((item, index) => (
                  <li
                    key={item.id}
                    className={classNames('auto-search_results_item', {
                      'auto-search_results_item--highlight': index === highlight,
                    })}
                    onClick={() => {
                      selectResult(item);
                    }}
                    onMouseOver={() => {
                      updateHighlight(index);
                    }}>
                    {item.value}
                  </li>
                ))}
            </ul>
          ) : null}
          {resultsNotFound && (
            <ul className={`auto-search_results`}>
              <li
                className={classNames('auto-search_results_item', {
                  'auto-search_results_item--error': true,
                })}>
                {notFoundText}
              </li>
            </ul>
          )}
        </>
      </TextInput>
    </>
  );
};
