import { useEffect } from 'react';
import { useOnDragEnd } from './useOnDragEnd';
import { useOnDragStart } from './useOnDragStart';
import { useOnMouseEnter } from './useOnMouseEnter';
import { useOnMouseLeave } from './useOnMouseLeave';
import { useMapContext } from 'src/components/Map/context/useMapContext';
import { MAP_ACTIONS } from 'src/components/Map/context/Reducer';
import { MapActionType } from 'src/components/Map/map.types';

export const useMouseEventHandlers = () => {
  const { dispatch } = useMapContext();
  const onMouseEnter = useOnMouseEnter();
  const onMouseLeave = useOnMouseLeave();
  const onDragStart = useOnDragStart();
  const onDragEnd = useOnDragEnd();
  useEffect(() => {
    dispatch({
      type: MAP_ACTIONS.SET_MAP_PROPS as MapActionType,
      payload: { onMouseEnter, onMouseLeave, onDragStart, onDragEnd },
    });
  }, [dispatch, onDragEnd, onDragStart, onMouseEnter, onMouseLeave]);
};
