import React, { useContext } from 'react';

// Components
import { PageHeader } from 'src/components';
import { SkeletonText, Button, Icons } from '@ems/client-design-system';

// Styled
import { ScenarioClearLink, ScenarioButtonWrapper } from '../../ViewScenario.styles';

// Variables
import { BLANK_STRING_PLACEHOLDER, NOISE_MODELING } from 'src/constants';
import { getDeployedProductId, setTabTitle } from 'src/utils';
import { defaultTableName } from '../../defaults';

// Contexts
import { noiseScenarioRecordsContext, dialogsStatusContext } from '../../contexts';
import { viewScenarioTranslations } from '../../helpers';
import { TScenarioModelTypes } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

// Types
interface ViewScenarioHeaderProps {
  isLoading: boolean;
  totalCount: number;
  scenarioName: string;
  scenarioModelType: TScenarioModelTypes;
}

export const ViewScenarioHeader = ({
  isLoading,
  totalCount,
  scenarioName = defaultTableName,
  scenarioModelType,
}: ViewScenarioHeaderProps) => {
  const { selectedInTable, getNoiseScenarioRecordsVariables } = useContext(
    noiseScenarioRecordsContext
  );
  const { setIsBulkEditOpen, setIsGenerateScenarioOpen } = useContext(dialogsStatusContext);
  const { filter } = getNoiseScenarioRecordsVariables;
  const translations = viewScenarioTranslations();

  setTabTitle(`${translations.headings.title} - ${scenarioName}`);

  return (
    <PageHeader title={scenarioName}>
      <SkeletonText loading={isLoading} width="4rem">
        <span className="settings__heading-count page-count">{`${
          totalCount < 0 || isLoading ? BLANK_STRING_PLACEHOLDER : totalCount
        } ${translations.labels.total}`}</span>
      </SkeletonText>
      <ScenarioClearLink
        to={{
          pathname: `/${getDeployedProductId()}/${NOISE_MODELING}`,
          state: { skipCacheCheck: true },
        }}>
        {translations.labels.new}
      </ScenarioClearLink>

      <div className="page-tools">
        <ScenarioButtonWrapper style={{ display: 'flex', alignItems: 'center' }}>
          {`${translations.labels.noiseScenarioModel}: ${scenarioModelType}`}
        </ScenarioButtonWrapper>
        <ScenarioButtonWrapper>
          <Button
            disabled={!totalCount || !!Object.keys(filter).length}
            style="primary"
            leftIcon={<Icons iconName={`ic-export`} title={'generate'} size={18} />}
            onClick={() => {
              setIsGenerateScenarioOpen(true);
            }}>
            {translations.buttons.generateButton}
          </Button>
        </ScenarioButtonWrapper>
        <ScenarioButtonWrapper>
          <Button
            disabled={selectedInTable.length ? false : true}
            style="primary"
            leftIcon={<Icons iconName={`ic-ui-edit`} title={'edit'} size={18} />}
            onClick={() => {
              setIsBulkEditOpen(true);
            }}>
            {translations.buttons.bulkEditButton}
          </Button>
        </ScenarioButtonWrapper>
      </div>
    </PageHeader>
  );
};
