import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';

export const fetchPushNotices = (client: ApolloClient<object>) =>
  new Promise((resolve, reject) => {
    const GET_USER_HELP = gql`
      query {
        userHelpLinks {
          name
          path
          fileType
          lastModified
          downloadResource {
            uri
          }
        }
      }
    `;

    client
      .query({
        query: GET_USER_HELP,
      })
      .then(response => {
        if (response && response.data && response.data.userHelpLinks) {
          resolve(response);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
