import { tagDataActionTypes } from 'src/app/newActionTypes';
import { IOperationTagData } from 'src/utils/interfaces';

export const updateOperationTagData = ({
  id,
  body,
  dispatcher,
}: {
  id: number;
  body: IOperationTagData;
  dispatcher: any;
}) => {
  dispatcher({ type: tagDataActionTypes.TAG_DATA_UPDATE, payload: { key: id, body } });
};
