import React, { useMemo, useRef, useState } from 'react';
import { debounce } from 'debounce';
// Configs
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import {
  useMapSettings,
  useRerunFunctionOnMapBackgroundChange,
} from 'src/app/functions/mapSettings';

// Design system
import { StyledMap, GeocoderPin, RulerTool, MapControl } from '@ems/client-design-system';

import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { flyTo, useGeocoderPinAlternative, useHoverOnMapElement, useMapRuler } from 'src/utils';
import { MAP_TYPES, SPATIAL_FEATURES, ZOOM_SELECTION_TOLERANCE_HIGH } from 'src/constants';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MapSettingsContainer } from 'src/containers';
import { MapReferenceLayers } from 'src/app/components';
import { LocationPopup, NMTPopup } from 'src/components';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
import { addPinToCentre, goToSelectedAddress, useGeocodePosition } from 'src/utils/geocoding';
import { MapLegend } from 'src/components/MapLegend';
import { useApolloClient } from '@apollo/react-hooks';
import { LocationItem } from '../interfaces';
import { useFlyTo } from './useFlyTo';
import { useDisplayDevicesOnMap } from './useDisplayDevicesOnMap';
import { NMT_SOURCE_ID_PREFIX } from '../constants';

interface LocationManagementMapProps {
  selectedNmtIds: number[];
  staticDevicePositions?: LocationItem[];
}

export const LocationManagementMap = ({
  selectedNmtIds,
  staticDevicePositions,
}: LocationManagementMapProps) => {
  const client = useApolloClient();
  // Map set up
  const [mapNode, mapRef] = useMapRef();
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  const mapBoxConfig = useMapConfig();
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  const [viewport, setViewport] = useState(viewportFromProps);

  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        ruler: ruler,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();

  const handleMapLoaded = () => {
    mapLoaded();
  };

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // Configuration
  const configSelectors = useConfigSelectors();

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  // capture map image
  // used for taking screenshot of map
  const captureRef = useRef(null);
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // Misc map controls
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Geocoder pin and address search
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);

  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  // Removes the distance marker circles from the geocoding pin
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[geocoding.longitude, geocoding.latitude]],
  });

  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude: geocoding.longitude,
      latitude: geocoding.latitude,
    },
  });

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // Ruler Tool
  const units = configSelectors.getUnits();
  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const { displayDevicesCallback, deviceData, layerArray } = useDisplayDevicesOnMap(
    selectedNmtIds,
    staticDevicePositions,
    mapApis
  );

  // Need to pass into useReloadHook otherwise the nmts don't display on map background change
  useRerunFunctionOnMapBackgroundChange({
    mapApis,
    mapStyle,
    reloadFunction: displayDevicesCallback,
    timeout: 500,
  });
  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: layerArray,
    tracksFilter: '',
    restrictZoomLevels: false,
    layerPrefix: NMT_SOURCE_ID_PREFIX,
    radius: ZOOM_SELECTION_TOLERANCE_HIGH,
    disabled: false,
    mapType: MAP_TYPES.SETTINGS,
  });

  const HoveredTag = ({ element }) => {
    if (!hoveredElement) {
      return <div />;
    }
    const { latitude, longitude } = element;
    const hoveredNmt = deviceData.find(nmt => nmt.deviceId === hoveredElement.properties.deviceId);
    if (hoveredNmt) {
      return (
        <NMTPopup lat={latitude} lon={longitude} draggable={false}>
          <>
            <p className="amsl-popup_title">{hoveredNmt.deviceName}</p>
            <p className="amsl-popup_value">{hoveredNmt.locationDescription}</p>
          </>
        </NMTPopup>
      );
    }
    return null;
  };

  useFlyTo(deviceData, selectedNmtIds, mapApis, viewport, setViewport);

  return (
    <div className="map_wrapper">
      <StyledMap
        height={'100vh'}
        onLoad={handleMapLoaded}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          onViewportChange(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onMouseMove={undefined}
        onHover={debounce(handleHover, 5)}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        <GeocoderPin
          latitude={enableMapControls ? geocoding.latitude : 0}
          longitude={enableMapControls ? geocoding.longitude : 0}
          draggable
          onClick={() => {
            updateLocationTagOpen(!isLocationTagOpen);
          }}
          onDragStart={() => {
            addRemoveCircles(null);
            updateLocationTagOpen(false);
          }}
          onDragEnd={([longitude, latitude]: number[]) => {
            if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
              updateGeocoding({ longitude, latitude });
            }
            updateLocationAddress(null);
            addRemoveCircles({ longitude, latitude });
            setTimeout(() => {
              updateLocationTagOpen(true);
            }, 1);
          }}
        />

        {enableMapControls && (
          <MapControl
            isPinAdded={geocoding.latitude && geocoding.longitude ? true : false}
            addPinToCentre={() =>
              addPinToCentre({
                updateLocationAddress,
                geocoding,
                viewport,
                updateGeocoding,
                addRemoveCircles,
                updateLocationTagOpen,
              })
            }
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            navigationControl={{
              showCompass: false,
              showHome: true,
              showSearch: true,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            translationData={{
              home: backToCenterLabel,
              search: searchLabel,
              addPin: addPinLabel,
              removePin: removePinLabel,
              mapSettings: mapSettingsTitle,
              ruler,
            }}
            resetView={resetView}
            addressSearch={AddressSearch}
            closeSearch={closeSearch}
            mapSettingsConfig={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  instance={SPATIAL_FEATURES}
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  onUpdate={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}
        <HoveredTag element={hoveredElement} />
        {isLocationTagOpen && (
          <LocationPopup
            latitude={geocoding.latitude}
            longitude={geocoding.longitude}
            address={locationAddress || place}
            elevation={elevation}
            languageData={{ latLabel, lngLabel, amslLabel, filterLabel }}
            showFilterButton={false}
            isUsingPca={false}
            mapApis={mapApis}
            onClose={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
          />
        )}
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
        <RulerTool
          distanceUnits={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          addressCoordinates={geocoding}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
        <MapLegend layersDisplayed={layersDisplayed} />
      </StyledMap>
    </div>
  );
};
