// Variables
import { TABLE_CONSTANTS } from 'src/constants';

// Types
import { ITableColumnData, ITableFilterObject, IFilterObject } from 'src/components';

// TABLE HEADER SET UP
export const columnDataConfig = (translations): ITableColumnData[] => {
  const { labels } = translations;
  return [
    {
      columnName: 'modelingAircraftName',
      key: 'modelingAircraftName',
      title: labels.aircraftType,
      filterName: 'modelingAircraftNames',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'stageLength',
      key: 'stageLength',
      title: labels.stage,
      filterName: 'stageLengths',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.INT,
      filterType: 'listFilter',
    },
    {
      columnName: 'modelingRouteName',
      key: 'modelingRouteName',
      title: labels.modelingRoute,
      filterName: 'modelingRouteNames',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'runwayName',
      key: 'runwayName',
      title: labels.runway,
      filterName: 'runwayNames',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'operationType',
      key: 'operationType',
      title: labels.operation,
      filterName: 'operationTypes',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'dayCount',
      key: 'dayCount',
      title: labels.day,
    },
    {
      columnName: 'eveningCount',
      key: 'eveningCount',
      title: labels.evening,
    },
    {
      columnName: 'nightCount',
      key: 'nightCount',
      title: labels.night,
    },
    {
      columnName: 'endSpacer',
      key: 'endSpacer',
      title: '',
    },
  ];
};

export const updateFilterItems = (
  tableFilters: ITableFilterObject,
  filterKey: string,
  filterItems
) => {
  const filter = (tableFilters.filters || []).find(({ filterName }) => filterName === filterKey);

  if (filter) {
    filter.filterItems = filterItems;
  }
};

export const getQueryFilterFromTableFilters = (filters: IFilterObject[]) => {
  const newFilter = {};
  (filters || [])
    .filter(({ selectedItems }) => selectedItems.length)
    .forEach(({ filterName, selectedItems }) => {
      newFilter[filterName] = selectedItems.map(({ key }) => key);
    });

  return newFilter;
};
