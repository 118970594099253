import { useCallback, useEffect, useMemo } from 'react';
import { useMapboxMapContext } from '../context/useMapboxMapContext';
import { useSetCursor } from '../AnomsMap/use-map-cursor/useSetCursor';
import { Cursor } from '../map.types';
import { MapboxGeoJSONFeature } from 'react-map-gl';
import { InteractiveLayerId } from './useInteractiveLayerIds';

export const useDraggableLayer = (
  layerId: InteractiveLayerId,
  onDragMove,
  onDragEnd,
  onLayerOver,
  onLayerMouseDown,
  onLayerMouseOut
) => {
  const setCursor = useSetCursor();
  const { map } = useMapboxMapContext();
  const currentMap = useMemo(() => map?.getMap(), [map]);

  const onLayerHover = useCallback(
    ({ features }: { features?: MapboxGeoJSONFeature[] }) => {
      if (features) {
        const hoveredFeature = features[0];
        const featureId = hoveredFeature.id;
        onLayerOver(featureId, currentMap);
      }
    },
    [currentMap, onLayerOver]
  );

  const onMouseOut = useCallback(() => {
    setCursor(Cursor.default);
    onLayerMouseOut(currentMap);
  }, [setCursor, onLayerMouseOut]);

  const onMouseUp = useCallback(
    e => {
      e.preventDefault();
      setCursor(Cursor.default);
      onDragEnd(e);
    },
    [setCursor, onDragMove, onDragEnd]
  );

  const onMouseDown = useCallback(
    e => {
      e.preventDefault();
      setCursor(Cursor.grabbing);
      onLayerMouseDown(e);
    },
    [setCursor, onDragMove, onLayerMouseDown]
  );

  const onMouseMove = useCallback(
    e => {
      e.preventDefault();
      onDragMove(e);
    },
    [onDragMove]
  );

  useEffect(() => {
    map.on('mouseenter', layerId, onLayerHover);
    map.on('mouseleave', layerId, onMouseOut);
    map.on('mousedown', layerId, onMouseDown);
    map.on('mouseup', onMouseUp);
    map.on('mousemove', onMouseMove);
    return () => {
      map?.off('mouseenter', onLayerHover);
      map?.off('mousemove', onMouseMove);
      map?.off('mousedown', onMouseDown);
      map?.off('mouseleave', onMouseOut);
      map?.off('mouseup', onMouseUp);
    };
  }, [map, onLayerHover, onMouseOut, onMouseMove, onMouseDown, onMouseUp]);
};
