import React, { useEffect, useMemo, useState } from 'react';
import {
  Radio,
  Dropdown,
  FormTextField,
  FormErrorDisplay,
  IDropdownItem,
} from '@ems/client-design-system';
import { IGateOption, IGateRuleArray, numDirectionsRadioOptions } from './interfaces';
import { useLanguageSelectors } from 'src/app/reducers';

/**
 *
 * @param gates list of gate rules
 * @param onGateChange action to trigger on change of gate value
 * @param isReadOnly if edit mode is toggled
 * @param selectedGateItem selected gate rule
 * @param formErrors formik based error list
 * @returns JSX.Element
 */
export const GateRuleConfigurator = ({
  gates,
  onGateChange,
  isReadOnly,
  selectedGateItem,
  formErrors,
}: IGateRuleArray) => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      ruleDetails: {
        fields: { intersectionMode: intersectionModeString, gateId: gateIdString },
        values: { intersectionMode: intersectionModeStrings },
      },
    },
    screens: {
      settings: {
        infringementRules: { form: formStrings },
      },
    },
  } = languageSelectors.getLanguage();

  const [selectedGate, setSelectedGate] = useState<IGateOption | null>(null);

  const intersectOptions: IDropdownItem[] = Object.keys(intersectionModeStrings).map(
    (key: string) => ({
      key,
      label: intersectionModeStrings[key] as string,
    })
  );

  const gatesList = useMemo(
    () =>
      gates.map(gate =>
        Number(gate.id) === Number(selectedGate?.key)
          ? {
              ...gate,
              numDirections: selectedGate.numDirections,
              intersectionMode: selectedGate.intersectionMode,
            }
          : gate
      ),
    [gates, selectedGate]
  );
  const gatesOptions: IGateOption[] = useMemo(
    () =>
      gatesList.map(gate => ({
        key: gate.id,
        label: gate.name,
        numDirections: gate.numDirections,
        intersectionMode: gate.intersectionMode,
      })),
    [gatesList]
  );

  const onIntersectChange = (evt: IDropdownItem) => {
    const { key } = evt;
    const updatedGate = Object.assign({}, selectedGate, {
      intersectionMode: key ? key : null,
    });
    const indexToUpdate = gatesList.findIndex(gate => gate.id === updatedGate.key);
    const newGatesList = gatesList.slice();
    if (indexToUpdate !== -1) {
      newGatesList[indexToUpdate] = {
        id: updatedGate.key,
        name: updatedGate.label,
        numDirections: updatedGate.numDirections,
        intersectionMode: updatedGate.intersectionMode,
      };
      setSelectedGate(updatedGate);
      onGateChange({
        id: updatedGate.key,
        name: updatedGate.label,
        numDirections: updatedGate.numDirections,
        intersectionMode: updatedGate.intersectionMode,
      });
    }
  };

  const onDirectionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    const directionMapping = {
      oneDirection: 1,
      bothDirections: 2,
    };
    const updatedGate: IGateOption = Object.assign({}, selectedGate, {
      numDirections: directionMapping[value] ? (directionMapping[value] as number) : 0,
    });

    const indexToUpdate = gatesList.findIndex(gate => gate.id === updatedGate.key);
    const newGatesList = gatesList.slice();
    if (indexToUpdate !== -1) {
      newGatesList[indexToUpdate] = {
        id: updatedGate.key,
        name: updatedGate.label,
        intersectionMode: updatedGate.intersectionMode,
        numDirections: updatedGate.numDirections,
      };
      setSelectedGate(updatedGate);
      onGateChange({
        id: updatedGate.key,
        name: updatedGate.label,
        intersectionMode: updatedGate.intersectionMode,
        numDirections: updatedGate.numDirections,
      });
    }
  };

  const handleGateChange = (item: IDropdownItem) => {
    const { numDirections, intersectionMode } = selectedGate || {
      numDirections: numDirectionsRadioOptions.bothDirections,
      intersectionMode: 'MustNotIntersect',
    };
    const updatedGate: IGateOption = {
      key: item.key,
      label: item.label,
      numDirections,
      intersectionMode,
    };
    setSelectedGate({ ...updatedGate });
    onGateChange({
      id: item.key,
      name: item.label,
      numDirections,
      intersectionMode,
    });
  };

  useEffect(() => {
    if (!selectedGate) {
      setSelectedGate(
        selectedGateItem ? gatesOptions.find(option => option.key === selectedGateItem.id) : null
      );
    }
  }, [gatesOptions, selectedGateItem]);

  return (
    <div className={`infdetailspanel gateruleconfigurator`}>
      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left">
          {isReadOnly ? (
            <FormTextField
              label={gateIdString}
              id={'Gate'}
              value={selectedGate ? selectedGate.label : ''}
              isReadOnly={isReadOnly}
            />
          ) : (
            <Dropdown
              label={gateIdString}
              placeholderValue=""
              searchItems={gatesOptions}
              isNullable={false}
              updateSelection={handleGateChange}
              selectedItem={selectedGate}
              disabled={isReadOnly}
            />
          )}
          <FormErrorDisplay
            displayError={!isReadOnly}
            error={formErrors ? formErrors.gate : undefined}
          />
        </div>
        <div className="infdetailspanel-row-right">
          {selectedGate && (
            <>
              {isReadOnly ? (
                <FormTextField
                  label={intersectionModeString}
                  id={'intersectionMode'}
                  value={intersectionModeStrings[selectedGate.intersectionMode] as string}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <Dropdown
                  label={intersectionModeString}
                  placeholderValue=""
                  searchItems={intersectOptions}
                  isNullable={false}
                  updateSelection={onIntersectChange}
                  selectedItem={{
                    key: selectedGate.intersectionMode,
                    label: intersectionModeStrings[selectedGate.intersectionMode] as string,
                  }}
                  disabled={isReadOnly}
                />
              )}
            </>
          )}
        </div>
        <div className="infdetailspanel-row-right nolabel">
          {selectedGate && (
            <>
              {isReadOnly ? (
                <FormTextField
                  label={formStrings.direction}
                  id={'Direction'}
                  value={selectedGate.numDirections === 2 ? 'Both Directions' : 'One Direction'}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <div className="gatedirectionspanel">
                  <div className="direction-radio">
                    <Radio
                      checked={selectedGate.numDirections === 1}
                      onChange={onDirectionChange}
                      value="oneDirection"
                      label={formStrings.oneDirection}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div className="direction-radio">
                    <Radio
                      checked={selectedGate.numDirections === 2}
                      onChange={onDirectionChange}
                      value="bothDirections"
                      label={formStrings.bothDirections}
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <FormErrorDisplay
            displayError={!isReadOnly}
            error={formErrors ? formErrors.gateDirection : undefined}
          />
        </div>
      </div>
    </div>
  );
};
