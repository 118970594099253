import { useEffect } from 'react';
import { useConfigSelectors } from 'src/app/reducers';
import { useMapContext } from './context/useMapContext';
import { MAP_ACTIONS } from './context/Reducer';
import { CustomMapProps, MapActionType } from './map.types';
import { useMapStyleProps } from './hooks/useMapStyles';

export const useMapStateFromConfig = (customMapProps?: CustomMapProps) => {
  const {
    dispatch,
    mapProps: { latitude: currentLatitude, longitude: currentLongitude },
  } = useMapContext();
  const configSelectors = useConfigSelectors();
  const {
    map: {
      centre: { latitude, longitude },
      zoom,
      mapAccessToken,
      mapStyleURL,
      mapStyleURLDarkMode,
      dragRotate,
      minZoom,
      maxZoom,
      pitch,
      bearing,
      mapTrackStyle,
    },
  } = configSelectors.getConfig();

  // if (altitude) {
  // altitude
  //   viewportFromConfig.altitude = altitude;
  // }
  // if (mode === '2D') {
  //     viewportFromConfig.maxPitch = 0;
  // }

  useEffect(() => {
    const isAlreadyCentered = currentLatitude !== 0 && currentLongitude !== 0;
    if (isAlreadyCentered) {
      return;
    }

    dispatch({
      type: MAP_ACTIONS.SET_VIEW_STATE as MapActionType,
      payload: {
        latitude,
        longitude,
        zoom,
        pitch,
        bearing,
        ...customMapProps,
      },
    });
  }, []);

  useMapStyleProps(customMapProps.mapBackgroundStyle);
  return {
    mapboxApiAccessToken: mapAccessToken,
    mapStyle: mapStyleURL,
    mapStyleDark: mapStyleURLDarkMode,
    dragRotate,
    minZoom,
    maxZoom,
    mapTrackStyle,
  };
};
