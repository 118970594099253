import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { MapContainer } from 'src/@complaints/containers';
import { useDataSelectors } from 'src/@complaints/reducers';
import { fetchOperationsByIds, fetchProfilesById } from 'src/@complaints/resolvers';
import { IMarkedTime } from 'src/@complaints/interfaces';
import { useConfigSelectors } from 'src/app/reducers';
import { Track, Projection, getTrackPca } from 'common-logic';

export const MapDataContainer = () => {
  const client = useApolloClient();
  const [complainerData, setcomplainerData] = useState<any>([]);
  const [pointData, setPointData] = useState<any>([]);
  const [markedTimes, setMarkedTimes] = useState<any>([]);
  const [markedPcaTimes, setMarkedPcaTimes] = useState<any>({});

  const dataSelectors = useDataSelectors();
  const data = dataSelectors.getData();
  const selectedRows = dataSelectors.getSelectedRows();

  const configSelectors = useConfigSelectors();
  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();
  Projection.current = new Projection(mapProjectionString);

 
  const updateTimesWithPcaTimes = (times) => {
    for(let i = 0, ii = times.length; i < ii; i++) {
      const time = times[i];
      const operationId = time.id;
      if(markedPcaTimes[operationId] && markedPcaTimes[operationId].complaintId === time.complaintId) {
        time.time = markedPcaTimes[operationId].time;
      }
    }
  };

  useEffect(() => {
    const filteredData: any = [];
    selectedRows.forEach((id: number) => {
      filteredData.push(data.get(id));
    });

    const complainerPositions: any = [];

    if (filteredData.length) {
      const times: IMarkedTime[] = [];
      const operationIds: number[] = [];
      const pcaTimes: any = {};
      filteredData.forEach(({ operationId, time, complainerPosition, id }) => {
        if (!operationId) {
          return;
        }
        times.push({
          id: operationId,
          time,
          complaintId: id,
        });
        complainerPositions.push({
          operationId,
          complainerPosition,
          complaintId: id,
        });
        // if (pointData.findIndex(item => item.id === operationId) === -1) {
        //   if (operationIds.findIndex(item => item === operationId) === -1) {
        //     operationIds.push(operationId);
        //   }
        // }
        operationIds.push(operationId);
      });

      if (operationIds.length) {
        fetchOperationsByIds(client, operationIds)
          .then((compData: any) => {
            const track: Track = {
              id: compData.data[0].id,
              startTime: new Date(compData.data[0].startTime),
              points: compData.data[0].points,
            };
            const pcas: any = {};
            for(let i = 0, ii = complainerPositions.length; i < ii; i++) {
              const complainerPosition = complainerPositions[i];
              if(complainerPosition.operationId === track.id) {
                pcas[complainerPosition.complaintId] = getTrackPca(complainerPosition.complainerPosition, track);
              }
            }
            for(const complaintId of Object.keys(pcas)) {
              const pca = pcas[complaintId];
              const timeIndex = times.findIndex(time => time.id === track.id && time.complaintId === parseInt(complaintId, 10));
              if(timeIndex !== -1) {
                times[timeIndex].time = pca.time.toISOString();
                pcaTimes[times[timeIndex].id] = {
                  complaintId: times[timeIndex].complaintId,
                  time: pca.time.toISOString()
                };
              }
            }
            setMarkedPcaTimes(Object.assign({}, markedPcaTimes, pcaTimes));

            const initPointData = compData;
            fetchProfilesById(client, operationIds).then((profileData: any) => {
              const mergedData = initPointData.data.map(e => {
                const foundData = profileData.data.find(d => d.id === e.id);

                return foundData
                  ? {
                    ...e,
                    profile: foundData.profile,
                  }
                  : e;
              });

              setcomplainerData(filteredData);
              updateTimesWithPcaTimes(times);
              setMarkedTimes(times);
              setPointData([...pointData, ...mergedData]);
            });
          })
          .catch((error: string) => {
            console.error(error);
          });
      } else {
        setcomplainerData(filteredData);
        updateTimesWithPcaTimes(times);
        setMarkedTimes(times);
      }
    } else {
      setcomplainerData(filteredData);
      setMarkedTimes([]);
    }
  }, [data, selectedRows]);

  return (
    <MapContainer complainerData={complainerData} pointData={pointData} markedTimes={markedTimes} />
  );
};
