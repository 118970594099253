import React, { useCallback, useMemo } from 'react';
import { SELECTED_COLOR } from '../taxiPathLayer.consts';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';
import { featureCollection } from '@turf/turf';
import { ConnectorFeature } from '../types';
import {
  InteractiveLineLayer,
  LineSegments,
} from 'src/components/Map/layers/InteractiveLine/InteractiveLineLayer';

const EDITABLE_CONNECTOR_POINT_STYLES = {
  default: {
    'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], SELECTED_COLOR, 'red'],
    'circle-radius': 10,
  },
};

const EDITABLE_CONNECTOR_LINE_STYLES = {
  default: {
    'line-color': SELECTED_COLOR,
    'line-width': 2,
    'line-dasharray': [2, 1],
  },
};

const DRAWABLE_LINE_CONFIG = { draggable: true, addPoints: true };

interface EditableConnectorProps {
  connectorFeature: ConnectorFeature;
}

export const EditableConnector = ({ connectorFeature }: EditableConnectorProps) => {
  const { dispatch } = useTaxiPathContext();

  const onLineUpdate = useCallback(
    (segments: LineSegments) => {
      const updatedLineSegments = segments.features.map(({ geometry, properties }) => ({
        id: properties.PathwayId,
        coordinates: geometry.coordinates,
      }));
      updatedLineSegments.forEach(updatedSegment => {
        dispatch({
          type: TaxiPathActions.UPDATE_CONNECTOR,
          payload: updatedSegment,
        });
      });
    },
    [dispatch]
  );

  const lineGeometry = useMemo(() => featureCollection([connectorFeature]), [connectorFeature]);

  return (
    <InteractiveLineLayer
      lineFeatures={lineGeometry}
      pointStyles={EDITABLE_CONNECTOR_POINT_STYLES}
      lineStyles={EDITABLE_CONNECTOR_LINE_STYLES}
      onLineUpdate={onLineUpdate}
      lineConfig={DRAWABLE_LINE_CONFIG}
    />
  );
};
