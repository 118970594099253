import React, { useState } from 'react';
// Components
import { Button, Card, TextInput, WarningDialog } from '@ems/client-design-system';
import {
  formatDisplayFeatureType,
  formatFormData,
  validateFeature,
} from 'src/@settings/functions/spatialFeatures';
import { FormWrapper } from 'src/components/FormField';
import { ISpatialFeatureForm } from 'src/@settings/interfaces';
import { setUnitAsMetersOrFeet } from 'src/utils';
import { TSpatialFeature } from 'src/utils/spatialFeatureHelpers/interfaces';

// Selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import { OperationsFilter } from 'src/@settings/components';

interface IEditFeatureProps {
  featureDetails: TSpatialFeature;
  featureType: string;
  cancelEditFeature: () => void;
  saveFeature: (formData: ISpatialFeatureForm) => void;
  isGeometryValid: boolean;
}

export const EditSpatialFeature: React.FC<IEditFeatureProps> = ({
  cancelEditFeature,
  featureType,
  featureDetails,
  saveFeature,

  isGeometryValid,
}) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { save: saveString, cancel: cancelString },
        spatialFeatures: { details: detailsString, messages: messagesString },
      },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const verticalDistance = setUnitAsMetersOrFeet(units.distanceVertical);
  const [confirmName, updateConfirmName] = useState<string>('');
  const [showOnSaveDialog, updateShowOnSaveDialog] = useState<boolean>(false);
  const [isSaveDisabled, updateIsSaveDisabled] = useState<boolean>(true);
  const [formData, updateFormData] = useState<ISpatialFeatureForm>(
    formatFormData(featureType, featureDetails, true, verticalDistance)
  );

  const originalFeatureName: string = featureDetails.name;

  const spatialFeaturesSelector = useSpatialFeaturesSelector();

  const cancel = () => {
    cancelEditFeature();
  };

  const handleClose = () => {
    updateShowOnSaveDialog(false);
  };

  const onSaveClicked = () => {
    updateShowOnSaveDialog(true);
  };

  const onConfirmSave = () => {
    updateShowOnSaveDialog(false);
    saveFeature(formData);
  };

  // update form object - when valid input is entered
  const onFormUpdate = (formData: ISpatialFeatureForm) => {
    updateFormData(formData);
  };

  const setFormValidity = (isValid: boolean): void => {
    updateIsSaveDisabled(!isValid);
  };

  const onConfirmNameUpdate = (event: React.KeyboardEvent) => {
    const enteredValue: HTMLInputElement = event.target as HTMLInputElement;
    updateConfirmName(enteredValue.value);
  };

  return (
    <div className={'spatial-feature__detail'}>
      <span className={'spatial-feature__title'}>{detailsString}</span>
      <Card className="spatial-feature__card">
        <FormWrapper
          formData={formData}
          setFormValidity={setFormValidity}
          onUpdate={onFormUpdate}
          formLogicValidation={validateFeature}
          validationParams={{
            units: units.distanceVertical,
            selector: spatialFeaturesSelector,
            originalFeatureName,
          }}
        />
        <OperationsFilter />
      </Card>
      <div className="spatial-feature__footer">
        <Button size="m" onClick={cancel}>
          {cancelString}
        </Button>
        <div className="footer__action-buttons">
          <Button
            size="m"
            className={'footer__button'}
            onClick={onSaveClicked}
            disabled={isSaveDisabled || !isGeometryValid}
            style={'primary'}>
            {saveString}
          </Button>
        </div>
      </div>
      <WarningDialog
        title={'Warning'}
        isOpen={showOnSaveDialog}
        confirmDisabled={confirmName !== formData.name.value}
        handleConfirm={onConfirmSave}
        handleModalClose={handleClose}>
        <div>
          <p>
            {messagesString.editWarning.replace(
              /{featureType}/g,
              formatDisplayFeatureType(featureType).toLowerCase()
            )}
          </p>
          <br />
          <p>
            {messagesString.confirmName.replace(
              /{featureType}/g,
              formatDisplayFeatureType(featureType).toLowerCase()
            )}
          </p>
          <div>
            <TextInput
              className={'confirm-text-box'}
              value={confirmName}
              onChange={onConfirmNameUpdate}
            />
          </div>
        </div>
      </WarningDialog>
    </div>
  );
};
