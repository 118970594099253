export const fetchUserDashboardsQuery = 'currentUserDashboards';
export const fetchDashboardAccessQuery = 'dashboardAccessToken';

export const fetchUserDashboardsSchema = `
{ 
  id 
  name 
  mode
}
`;

export const fetchDashboardAccessSchema = `
{
  embedReportId
  embedUrl 
  embedToken
  expiration
}
`;
