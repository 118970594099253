import gql from 'graphql-tag';

export const ADD_GATE = gql`
  mutation addGate($gate: InputGate!) {
    addGate(gate: $gate) {
      id
      name
      groupName
      isActive
      filter {
        operationTypes
        runways {
          airportId
          runwayName
        }
      }
      geometry {
        leftPosition {
          latitude
          longitude
        }
        rightPosition {
          latitude
          longitude
        }
        anchorPosition {
          latitude
          longitude
        }
        floorAltitude
        ceilingAltitude
      }
    }
  }
`;

export const UPDATE_GATE = gql`
  mutation UpdateGate($gate: InputGate!) {
    updateGate(gate: $gate) {
      id
      name
      groupName
      isActive
      filter {
        operationTypes
        runways {
          airportId
          runwayName
        }
      }
      geometry {
        leftPosition {
          latitude
          longitude
        }
        rightPosition {
          latitude
          longitude
        }
        anchorPosition {
          latitude
          longitude
        }
        floorAltitude
        ceilingAltitude
      }
    }
  }
`;

export const DELETE_GATE = gql`
  mutation deleteGate($id: Int!) {
    deleteGate(id: $Int)
  }
`;
