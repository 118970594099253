// Interfaces
import { NMT_METRICS } from 'src/constants';
import { removeAllTypenameProps } from 'src/utils';

import { IDeviceManagementForm, IGetDeviceData, IMonitorLocation, NMTMetric } from '../interfaces';
import { useUnitConversion } from './useUnitConversion';

export const useBuildFormData = (
  monitorLocationData: IMonitorLocation,
  deviceData: IGetDeviceData
): IDeviceManagementForm => {
  const { convertAltitudeToDisplay } = useUnitConversion();
  if (!monitorLocationData || !deviceData) {
    return undefined;
  }
  // Deep copy both data sets
  const copiedDeviceData = JSON.parse(JSON.stringify(deviceData)) as IGetDeviceData;
  const copiedMonitorLocationData = removeAllTypenameProps(monitorLocationData);
  const {
    id,
    locationId,
    // Todo - UPDATE DEVICE NAME AND DESCRIPTION?
    // name,
    // description,
    isActive,
    configuration: deviceConfig,
  } = copiedDeviceData.noiseDevice;
  const { position, configuration: monitorConfig } = copiedMonitorLocationData;

  // Cleanup object __typename
  monitorConfig.noiseEventTemplates.forEach(template => {
    // Convert to string for formik inputs
    template.threshold = String(template.threshold);
    template.minEventDuration = String(template.minEventDuration);
    template.maxEventDuration = String(template.maxEventDuration);
    template.endEventDuration = String(template.endEventDuration);
  });

  const trigger = monitorConfig.noiseEventTemplates.length
    ? monitorConfig.noiseEventTemplates[0].trigger
    : NMT_METRICS.LAS;

  position.altitude = Number(convertAltitudeToDisplay(position.altitude ?? 0));
  return {
    id,
    locationId,
    name: copiedMonitorLocationData.name,
    description: copiedMonitorLocationData.description,
    isActive,
    calibrationTimes: deviceConfig?.calibrationTimes ?? [],
    position: {
      latitude: String(position.latitude),
      longitude: String(position.longitude),
      altitude: String(position.altitude),
    },
    templates: monitorConfig.noiseEventTemplates,
    trigger: trigger as NMTMetric,
  };
};
