import React, { FC, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import uuid from 'uuid';
// providers
import { AirtrakDispatchContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// actions
import { updateBreakdown, cleanUpData, updateStatus } from 'src/@airtrak/actions';
// selectors
import { useBreakdownSelector, useDataSelectors, useViewSelector } from 'src/@airtrak/reducers';
import { useLanguageSelectors } from 'src/app/reducers';
// components
import { Button } from '@ems/client-design-system';
// constants
import { TABLE, TMA_EFFICIENCY } from 'src/constants';

export const BreakDownContainer: FC = ({}) => {
  const dispatcher = useContext(AirtrakDispatchContext);
  const breakdownSelector = useBreakdownSelector();
  const breakdown = breakdownSelector.getBreakdown();
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const viewSelector = useViewSelector();
  const view = viewSelector.getView();
  const [currentBreakdown, setCurrentBreakdown] = useState(breakdown);
  const dataSelectors = useDataSelectors();
  const metric = dataSelectors.getMetric();

  const {
    components: { labels, buttons },
  } = translationData;

  const breakdowns = [
    {
      key: 'None',
      name: buttons.none,
    },
    {
      key: 'Airline',
      name: buttons.airline,
    },
    {
      key: 'Aircraft',
      name: buttons.aircraft,
    },
    {
      key: 'DomesticOrInternational',
      name: buttons.domesticOrInternational,
    },
    {
      key: 'Phase',
      name: buttons.phase,
    },
    {
      key: 'Pollutant',
      name: buttons.pollutant,
    },
  ];

  const tmaBreakDowns = [
    {
      key: 'None',
      name: buttons.none,
    },
    {
      key: 'Airline',
      name: buttons.airline,
    },
    {
      key: 'Aircraft',
      name: buttons.aircraft,
    },
    {
      key: 'DomesticOrInternational',
      name: buttons.domesticOrInternational,
    },
  ];

  const getButton = (data: any) => (
    <Button
      size="s"
      style="subtle"
      className={cx({ 'breakdown-button': true, 'active-button': breakdown === data.key })}
      onClick={setBreakdownOnClick}
      aria-label={`${data.key}-breakdown`}
      name={data.key}
      key={uuid.v4()}
    >
      {data.name}
    </Button>
  );

  const setBreakdownOnClick = async e => {
    setCurrentBreakdown(e.currentTarget.name);
  };

  const getBreakdownButtons = () => metric === TMA_EFFICIENCY ? tmaBreakDowns.map(getButton) : breakdowns.map(getButton);

  useEffect(() => {
    setCurrentBreakdown(breakdown);
  }, [breakdown]);

  useEffect(() => {
    if (view === TABLE) {
      cleanUpData(dispatcher);
    }
    updateStatus(dispatcher);
    updateBreakdown(currentBreakdown, dispatcher);
  }, [currentBreakdown]);

  return (
    <div className='breakdown-container'>
      <span className='airtrak-breakdown-title'>{labels.breakdown}</span>
      <div>{getBreakdownButtons()}</div>
    </div>
  );
};
