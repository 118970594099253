import React, { useState, useEffect, FC, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
// components
import {
  Checkbox,
  Dropdown,
  Button,
  Overlay,
  IDropdownItem,
} from '@ems/client-design-system';
import { TextField } from 'src/@settings/components';
import { ManageConfigurationsContainer } from 'src/@settings/containers';
// reducers
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useScenariosSelectors } from 'src/app/reducers/scenariosReducer';
// interfaces
import { IAirtrakSettingsContainer, IMutationItem, TMode } from 'src/@settings/interfaces';
// utils
import { getFormattedMutationItem } from 'src/@settings/functions';
import { convertMapToArray } from 'src/utils';
// constants
import { MANAGE_CONFIGURATIONS_MODULE, MAX_TMA_DISTANCE, UNIT_KILOMETER } from 'src/constants';
import { Prompt } from 'react-router-dom';
// provider
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
// actionTypes
import { appActionTypes } from 'src/app/actionTypes';
// mutations
import { UPDATE_SCENARIOS } from 'src/@settings/mutations';

export const AirtrakSettingsContainer: FC<IAirtrakSettingsContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateScenarios,
  discardMutationData,
}) => {
  // dispatcher
  const appDispatcher: any = useContext(GlobalDispatchContext);

  // Config
  const configSelectors = useConfigSelectors();
  const {
    formValidations: {
      airtrak: { tmaDistance: tmaDistanceValidation },
    },
  } = configSelectors.getConfig();

  // selectors
  const scenariosSelector = useScenariosSelectors();
  const configurations = scenariosSelector.getScenarios();
  const activeScenario = scenariosSelector.getActiveScenario();
  const languageSelectors = useLanguageSelectors();

  const {
    components: {
      info: { temperatureInfo, tmaInfoDescription },
      labels: {
        applyWeatherAdjustments,
        optimumTmaDistance,
        activeConfig: activeConfiguration,
        manageConfig,
        selectConfig,
      },
      hints: { areYouSureYouWantToLeave },
    },
  } = languageSelectors.getLanguage();

  const setDefaultGeneralSettingsConfigurationData = obj => {
    setSelectedConfig(Object.assign({}, selectedConfig, obj));
    handleMutation('Update', Object.assign({}, activeScenario, obj));
    setChangesAvailable(true);
  };
  // state
  const [selectedConfig, setSelectedConfig] = useState(activeScenario);
  const [showConfigurationsPopup, setShowConfigurationsPopup] = useState<boolean>(false);
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
  }, [discardMutationData]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(
      getFormattedMutationItem(mode, updatingItem, mutationData, MANAGE_CONFIGURATIONS_MODULE)
    );
    const { items } = convertMapToArray(mutationData);
    updateScenarios(items);
  };

  const onCheckBoxSelect = () => {
    setDefaultGeneralSettingsConfigurationData({applyWeatherAdjustment: !selectedConfig.applyWeatherAdjustment});
  };

  const getScenarioById = id => {
    return configurations.filter(configuration => configuration.id === id);
  };

  const [makeScenarioActive] = useMutation(UPDATE_SCENARIOS, {
    update() {},
  });

  const handleActiveMutation = () => {
    const { items } = convertMapToArray(mutationData);
    makeScenarioActive({
      variables: {
        scenarios: items,
      },
    })
      .then(({ data }) => {
        appDispatcher({
          type: appActionTypes.UPDATE_SCENARIOS,
          payload: data.updateScenarios,
        });
        mutationData.clear();
        setMutationData(mutationData);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDropdownSelection = (item: IDropdownItem) => {
    mutationData.clear();
    const presentActiveScenario = getScenarioById(activeScenario.id);
    const newSelectedScenario = getScenarioById(item.key);
    if (activeScenario.id && item.key !== activeScenario.id.toString()) {
      setMutationData(
        getFormattedMutationItem('Update', Object.assign({}, ...presentActiveScenario, { isActive: false }), mutationData, MANAGE_CONFIGURATIONS_MODULE)
      );
      setMutationData(
        getFormattedMutationItem('Update', Object.assign({}, ...newSelectedScenario, { isActive: true }), mutationData, MANAGE_CONFIGURATIONS_MODULE)
      );
      setSelectedConfig(Object.assign({}, ...newSelectedScenario, { isActive: true }));
      handleActiveMutation();
    }
  };

  useEffect(() => {
    setSelectedConfig(activeScenario);
    mutationData.clear();
    setMutationData(mutationData);
  }, [areChangesDiscarded]);

  const getFormattedConfigurations = configurations => {
    const configs = configurations.map(config => ({
      ...config,
      key: config.id,
      label: config.name,
    }));
    return configs;
  };

  useEffect(() => {
    setSelectedConfig(activeScenario);
  }, [activeScenario]);

  const onPopupClose = () => {
    setShowConfigurationsPopup(false);
  };

  return (
    <>
      <p className={'active-config'}>{activeConfiguration}</p>
      <div className={'scenario-container'}>
        <Dropdown
          searchItems={getFormattedConfigurations(configurations)}
          selectedItem={{
            ...selectedConfig,
            key: selectedConfig.id,
            label: selectedConfig.name,
          }}
          isNullable={true}
          placeholderValue={selectConfig}
          updateSelection={item => onDropdownSelection(item)}
        />
        <Button
          style="standard"
          className={'manage-config'}
          onClick={() => {
            setShowConfigurationsPopup(true);
          }}>
          {manageConfig}
        </Button>
      </div>
      <hr className={'break-line'} />
      <p className={'temperature-info'}>{temperatureInfo}</p>
      <Checkbox
        label={applyWeatherAdjustments}
        checked={selectedConfig.applyWeatherAdjustment}
        onClick={onCheckBoxSelect}
      />
      <hr className={'break-line'} />
      <p className={'tma'}>{tmaInfoDescription}</p>
      <TextField
        label={optimumTmaDistance}
        value={selectedConfig.defaultOptimumTMAApproachDistance}
        setData={setDefaultGeneralSettingsConfigurationData}
        name={'defaultOptimumTMAApproachDistance'}
        validationString={tmaDistanceValidation.match}
        maxValue={MAX_TMA_DISTANCE}
        postfix={UNIT_KILOMETER}
      />
      {showConfigurationsPopup && (
        <Overlay
          openState={true}
          onClose={() => { }}
          classes={['manageconfig-popup']}
          className='manageconfig-overlay'>
          <ManageConfigurationsContainer onPopupClose={onPopupClose} />
        </Overlay>
      )}
      <Prompt
        message={areYouSureYouWantToLeave}
        when={mutationData.size > 0}
      />
    </>
  );
};
