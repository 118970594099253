import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const infringementsActionTypes: any = keyToActionTypes(
  {
    INFRINGEMENTS_FETCHED: null,
    GET_TOTAL_COUNT: null,
    SELECT_ROW: null,
    LOAD_MORE: null,
    SORT_TABLE: null,
    RESET_DATA: null,
    INLINE_EDIT: null,
  },
  'INFRINGEMENTS_CANDIDATES'
);

export const filterActionTypes: any = keyToActionTypes(
  {
    UPDATE_DATE_RANGE: null,
    UPDATE_SELECTED_ITEMS: null,
    CLEAR_SELECTED_ITEMS: null,
    UPDATE_TIME_FILTER_VALUE: null,
    UPDATE_TIME_FILTER_INPUT: null,
    INITIALISE_STORE: null,
  },
  'INFRINGEMENTS_CANDIDATES'
);

export const summaryActionTypes: any = keyToActionTypes(
  {
    SUMMARY_FETCHED: null,
  },
  'INFRINGEMENTS_CANDIDATES'
);
