import React, { useMemo } from 'react';

// Components
import { MappingDropdown, MappingTableCell } from '../components';
import { NumberCell } from '../../Modeling/AircraftAssignments/styles';

// Types
import { IAircraftMappingEdge, IAircraftMappingTableData } from '../types';

// Constants
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';

// Functions
import { formatNumber } from 'src/utils';
import { useBuildModelFilterOptions } from './useBuildModelFilterOptions';

export const useBuildTableData = (
  edges: IAircraftMappingEdge[],
  invalidRows: string[]
): IAircraftMappingTableData[] => {
  const { enrouteFilterOptions, ltoFilterOptions } = useBuildModelFilterOptions();

  return useMemo(
    () =>
      edges.flatMap((edge: IAircraftMappingEdge, _index): IAircraftMappingTableData[] => {
        if (!edge.node) {
          return [];
        }

        const {
          cursor,
          node: { aircraftType, engineModel, numberOfFlights },
        } = edge;

        const tableData: IAircraftMappingTableData = {
          aircraftType: aircraftType || BLANK_STRING_PLACEHOLDER,
          engineModel: (
            <MappingTableCell title={engineModel || BLANK_STRING_PLACEHOLDER}>
              {engineModel || BLANK_STRING_PLACEHOLDER}
            </MappingTableCell>
          ),
          numberOfFlights: <NumberCell>{formatNumber(numberOfFlights)}</NumberCell>,
          mappedLTOAircraftEngine: (
            <MappingDropdown
              selectedEdge={edge}
              type={'mappedLTOAircraftEngine'}
              filterOptions={ltoFilterOptions}
            />
          ),
          mappedEnrouteAircraftEngine: (
            <MappingDropdown
              selectedEdge={edge}
              type={'mappedEnrouteAircraftEngine'}
              filterOptions={enrouteFilterOptions}
            />
          ),
          className: invalidRows.includes(cursor) ? 'invalid_row' : '',
        };

        return [tableData];
      }),
    [edges, invalidRows]
  );
};
