import React, { useEffect, useState, FC, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useFilterDataSelectors, useConfigSelectors } from 'src/app/reducers';
import { useSortSelectors, useDataSelectors } from 'src/@infringements/reducers';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
// actions
import {
  initialiseFilterStore,
  resetAndFetchData,
  fetchNoiseMonitors,
} from 'src/@infringements/actions';
// stores
import { filterStore } from 'src/@infringements/stores/filterStore';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// // function
import { isAtcView, screenScrollToTop } from 'src/utils';
import { IDataContainer } from 'src/@infringements/interfaces';
// constants
import { INITIALISE_EVENT } from 'src/constants';

export const DataContainer: FC<IDataContainer> = ({
  children,
  source = '',
  correlatedIds = [],
}) => {
  const client = useApolloClient();
  const dispatcher = useContext(InfringementDispatchContext);
  // Configuration
  const configSelectors = useConfigSelectors();
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const dataSelectors = useDataSelectors();
  const showCandidates = dataSelectors.getCandidatesEnabled();
  const atcView = isAtcView(source);

  const {
    grid: { resultSize },
  } = configSelectors.getConfig();
  const { from, to } = dateRangeStore.getDatesStrings();

  useEffect(() => {
    const isFilterInitialised = filterStore.getIfStoreInitialised();
    if (isFilterInitialised) {
      resetAndFetchData(client, dispatcher, {
        resultSize,
        sortString,
        showCandidates,
        atcView,
      });
    }
  }, [sortString, from, to]);

  // defaults are initialised to false to ensure data is fetched initially
  const [filterInitialised, setFilterInitialised] = useState(false);
  // TODO: we need to get rid of the old filter stores - this is just a work around to pass the filter data to the legacy store
  const filtersSelectors = useFilterDataSelectors();
  const infringementFilterData = filtersSelectors.getInfringementFilterData();
  const operationFilterData = filtersSelectors.getOperationsFilterData();

  useEffect(() => {
    const handleInitialisation = () => {
      const isFilterInitialised = filterStore.getIfStoreInitialised();
      const defaultsNotInitialised = !filterInitialised;
      // Check if everything is initialised, and then check if it hasn't already been done previously
      // This is done to handle future updates to config, preventing a new fetch data should a store fire an INITIALISE_EVENT call
      // Theoretically, this should only be called once as config updates do not call INITIALISE_EVENT at the moment.
      if (isFilterInitialised && defaultsNotInitialised) {
        setFilterInitialised(isFilterInitialised);
        resetAndFetchData(client, dispatcher, {
          resultSize,
          sortString,
          showCandidates,
          atcView,
        });
        fetchNoiseMonitors(client, dispatcher, operationFilterData.airportIds);
      }
    };

    filterStore.on(INITIALISE_EVENT, handleInitialisation);
    // Handle any initialisation logic here. Each relevant store should be 'reset' to their initial values unless otherwise told.
    initialiseFilterStore({
      infringementFilterData,
      correlatedIds,
    });
    screenScrollToTop();

    return () => {
      filterStore.removeListener(INITIALISE_EVENT, handleInitialisation);
    };
  }, []);

  return <>{children}</>;
};
