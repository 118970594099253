import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
// ts
import { IDateRangeSelection } from 'src/app/props';
// store
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
// functions
import { getDeployedProductId } from 'src/utils';
import { getDay } from 'src/utils/dateTimeConverters';
import {
  getDateRangeBasedOnQueryString,
  isQueryStringAvailable,
  updateQueryWithoutRerouting,
  constructDateRangeQueryString,
} from 'src/app/functions/queryString';

import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { updateDateRange } from 'src/app/actions';
import { TFilterLoadRange } from 'src/components';

/**
 * Custom Hook function:
 * A custom Hook is a JavaScript function whose name starts with ”use” and that may call other Hooks.
 * The purpose of useDateRangeFilter Hook is to subscribe us to a filter status.
 *
 * @param updateQuery: add date range to query string
 */
export const useDateRangeFilter = (
  updateQueryString = false,
  useGlobal = true,
  range: TFilterLoadRange = 'day'
) => {
  const [dateRange, updateDateRange] = useState(dateRangeStore.getDateFilters(useGlobal));
  const configSelectors = useConfigSelectors();
  const appConfig = configSelectors.getConfig();

  useEffect(() => {
    const handleDateRangeUpdates = () => {
      updateDateRange(dateRangeStore.getDateFilters(useGlobal));
    };

    // add date range to query string if
    // 1. requested  by the screen and 2. it's not available already in the current URL
    if (updateQueryString && !isQueryStringAvailable()) {
      const { isUpdateRequired, from, to } = getDateRangeBasedOnQueryString(dateRange, appConfig);
      if (isUpdateRequired) {
        let adjustedRangeFrom: Date;
        const adjustedRangeTo: Date = DateTime.fromISO(to).toISODate();

        // Set range to week/month and update global store
        if (range !== 'day') {
          adjustedRangeFrom = DateTime.fromISO(from)
            .plus({ [`${range}s`]: -1 })
            .toISODate();

          dateRangeStore.setDateFilters({
            from: adjustedRangeFrom.toString(),
            to: adjustedRangeTo.toString(),
          });
          updateDateRange({ from: adjustedRangeFrom, to: adjustedRangeTo });
        }
        updateQueryWithoutRerouting(
          location.pathname,
          constructDateRangeQueryString({
            from: adjustedRangeFrom ? adjustedRangeFrom.toString() : from,
            to,
          })
        );
      }
    }
    return () => {
      // remove event listener after unmount
      dateRangeStore.removeListener('change', handleDateRangeUpdates);
    };
  }, []);

  return dateRange;
};

/**
 * updateDateRangeFilter updates date range, then reset and fetch data
 * this will be improved and designed a bit better later when we have more information about how the date filter changes work with other screens
 */
export const updateDateRangeFilter = (dateRange: IDateRangeSelection, route: string): void => {
  updateDateRange(dateRange, route);
};

/**
 * Returns a predefined daterange list for the date picker
 * Date range calculation is done by getDay helper
 * Ideally, this list will be populated dynamically from config
 * but until all workflows are figured out this is the best implementation
 */
export const predefinedDateRangeList = (translation: any) => [
  {
    dateRange: [getDay('day', 'start'), getDay('day', 'end')],
    key: 'today',
    label: translation.today,
  },
  {
    dateRange: [getDay('day', 'start', -1), getDay('day', 'end', -1)],
    key: 'yesterday',
    label: translation.yesterday,
  },
  {
    dateRange: [getDay('day', 'start', -7), getDay('day', 'end', -1)],
    key: 'past7Days',
    label: translation.past7Days,
  },
  {
    dateRange: [getDay('day', 'start', -14), getDay('day', 'end', -1)],
    key: 'past14Days',
    label: translation.past14Days,
  },
  {
    dateRange: [getDay('month', 'start', -1), getDay('month', 'end', -1)],
    key: 'lastMonth',
    label: translation.lastMonth,
  },
];

// Read rangeLimits from app config and return any restrictions applied
export const rangeLimitInMonths = (rangeLimits: any) => {
  let rangeLimitInMonths = 0; // default is unlimited months
  const {
    location: { pathname },
  } = window;
  if (typeof rangeLimits !== 'undefined' && typeof pathname !== 'undefined' && rangeLimits) {
    // is range limits available in config?
    const path = pathname.replace(`/${getDeployedProductId()}/`, '');
    if (typeof rangeLimits === 'object' && typeof rangeLimits[path] !== 'undefined') {
      const { months } = rangeLimits[path];
      if (months !== 'undefined' && months >= 0) {
        // value = 0 means ignore the limit
        rangeLimitInMonths = months;
      }
    }
  }
  return rangeLimitInMonths;
};
