import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
import { LocationPopup } from 'src/components';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import {
  flyTo,
  fitPointsInMap,
  useMapHover,
  useHoverOnMapElement,
  useHoveredPointData,
  useClickOnMapElement,
  useMapRuler,
} from 'src/utils';
import { IMapProps } from 'src/@noiseEvents/interfaces';
import {
  useInAirPlayback,
  useNoiseMonitorPlayback,
  useStaticFlightDisplay,
  useStaticDbDisplay,
} from 'src/utils/playback';
import { NoisePopup } from 'src/components/NoisePopup';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { OperationMapTags } from 'src/components';
import {
  getStoredMarkers,
  useGeocodePosition,
  goToSelectedAddress,
  addPinToCentre,
  onGeocodingDragEnd,
} from 'src/utils/geocoding';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { ITrackInteractionPoint } from 'src/utils/interfaces';
import { ITag, addTagToList, removeTagFromList, vectorLayerToPoint } from 'src/utils/mapTagHelpers';
import { MAP_TYPES } from 'src/constants';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';
import { MapLegend } from 'src/components/MapLegend';

export const MapContainer: FC<IMapProps> = ({
  inAirData,
  noiseMonitors,
  isPlaybackMode = false,
  currentTime = 0,
  noiseData,
  processedNoiseData,
  markedTime,
  pointData,
  isPlaybackRunning,
}) => {
  // get map props from config
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        backToCenter: backToCenterLabel,
        ruler: ruler,
      },
      noiseEvents: {
        fields: { lmax: lmaxString },
      },
    },
    abbreviations,
  } = languageSelectors.getLanguage();

  // Unsure why this object gets passed in as a wrapped array... hence the [0]
  const { startTime, endTime } = noiseData[0] || {
    startTime: null,
    endTime: null,
  };

  const labels = Object.assign(opsFields, abbreviations);

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // Hover / Click functionality
  const requiredMouseLayers = useMemo(
    () => ['static-points', 'static-lines', 'points', 'lines'],
    []
  );

  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    mapType: MAP_TYPES.NOISEEVENTSUMMARY,
  });

  const [tagList, setTagList] = useState<ITag[]>([]);

  const { handleClick, clickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    mouseFilters,
    false,
    '',
    5,
    1,
    setTagList
  );

  useEffect(() => {
    if (clickedElement) {
      addTagToList(clickedElement, tagList, setTagList);
    }
  }, [clickedElement]);

  const [hoveredPointData, setHoveredPointData] = useState<ITrackInteractionPoint>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return inAirData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode,
    isPlaybackRunning,
    userHomeLocation: null,
    mapProjectionString: null,
  });

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const highlightId = noiseData.length ? noiseData[0].operationId : -1;
  const highlightIds: number[] = useMemo(() => {
    const ids: number[] = [];
    noiseData.map(data => {
      ids.push(data.operationId);
    });
    return ids;
  }, [noiseData]);
  const selectedMonitor = useNoiseMonitorPlayback({
    mapApis,
    noiseMonitors,
    noiseData,
    processedNoiseData,
    currentTime,
    isPlaybackMode,
  });
  useStaticFlightDisplay(mapApis, pointData, isPlaybackMode, true, highlightIds, markedTime);
  const staticMonitors = useStaticDbDisplay(mapApis, noiseMonitors, noiseData, isPlaybackMode);
  useInAirPlayback(mapApis, inAirData, currentTime, isPlaybackMode, highlightId);

  // make map zoom to fit the points selected
  useEffect(() => {
    if (mapApis) {
      const selectedMonitorCoords: any = [];
      staticMonitors.map((monitor: any) => {
        selectedMonitorCoords.push({
          longitude: monitor.geometry.coordinates[0],
          latitude: monitor.geometry.coordinates[1],
        });
      });

      if (!isPlaybackMode) {
        fitPointsInMap(mapApis, viewport, setViewport, selectedMonitorCoords);
      }
    }
  }, [mapApis, staticMonitors]);

  const reorderNoiseLayers = () => {
    if (mapApis) {
      // Reorder layers if needed
      if (mapApis.getLayer('noiseMonitors')) {
        mapApis.moveLayer('noiseMonitors');
      }
      if (mapApis.getLayer('static-monitors')) {
        mapApis.moveLayer('static-monitors');
      }
      if (mapApis.getLayer('selectedMonitors')) {
        mapApis.moveLayer('selectedMonitors');
      }
      if (mapApis.getLayer('static-points')) {
        mapApis.moveLayer('static-points');
      }
      if (mapApis.getLayer('static-lines')) {
        mapApis.moveLayer('static-lines');
      }
      if (mapApis.getLayer('lines')) {
        mapApis.moveLayer('lines');
      }
      if (mapApis.getLayer('points')) {
        mapApis.moveLayer('points');
      }
    }
  };

  useEffect(() => {
    reorderNoiseLayers();
  }, [inAirData, mapApis, noiseData]);

  const noisePopups: JSX.Element[] = [];
  if (mapApis && (selectedMonitor.length > 0 || staticMonitors.length > 0)) {
    const mapFn = (monitor: any) => {
      noisePopups.push(
        <NoisePopup
          key={`popup_${monitor.properties.id}`}
          zoomLevel={mapApis.getZoom()}
          monitorData={monitor}
          metricString={lmaxString}
          isPlaybackMode={isPlaybackMode}
        />
      );
    };
    if (isPlaybackMode) {
      selectedMonitor.map(mapFn);
    } else {
      staticMonitors.map(mapFn);
    }
  }

  // Ruler Tool

  const units = configSelectors.getUnits();
  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const client = useApolloClient();
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);
  const storedMarkers = getStoredMarkers();
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>(
    storedMarkers ? storedMarkers.main : { longitude: 0, latitude: 0 }
  );
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [drag, updateDragStatus] = useState<boolean>(false);
  const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx('map_wrapper', {
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          {...mapProps}
          ref={mapRef}
          onMouseMove={undefined}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          {noisePopups}
          {isLocationTagOpen && (
            <LocationPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              languageData={{ latLabel, lngLabel, amslLabel }}
              mapApis={mapApis}
              showFilterButton={false}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
              }}
            />
          )}
          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            mapApis={mapApis}
            addRemoveCircles={addRemoveCircles}
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              removeHovered();
              updateDragStatus(true);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]: number[]) =>
              onGeocodingDragEnd({
                longitude,
                latitude,
                updateDragStatus,
                updateGeocoding,
                updateLocationAddress,
                updateLocationTagOpen,
                addRemoveCircles,
              })
            }
            onMouseEnter={() => {
              removeHovered();
              updateDragStatus(true);
            }}
            onMouseLeave={() => {
              updateDragStatus(false);
            }}
          />
          {enableMapControls && (
            <MapControl
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={() =>
                addPinToCentre({
                  updateLocationAddress,
                  geocoding,
                  viewport,
                  updateGeocoding,
                  addRemoveCircles,
                  updateLocationTagOpen,
                })
              }
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              resetView={resetView}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          {tagList.map(tag => {
            const matchingOperation = inAirData.find(
              operation => operation.id === tag.data.properties.id || operation.id === tag.data.id
            );
            return (
              <OperationMapTags
                element={tag.data}
                labels={labels}
                pointData={vectorLayerToPoint({
                  operation: matchingOperation,
                  clickedElement: tag.data,
                })}
                draggable
                onClose={() => removeTagFromList(tag.uuid, tagList, setTagList)}
                key={tag.uuid}
              />
            );
          })}
          {hoveredElement && (
            <OperationMapTags
              element={hoveredElement}
              labels={labels}
              pointData={hoveredPointData}
              draggable={false}
            />
          )}
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
          <MapReferenceLayers
            mapApis={mapApis}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(new Date(startTime), 'start'),
              dateFilterTo: dateTimeInQueryUTC(new Date(endTime), 'end'),
            }}
            afterRender={reorderNoiseLayers}
          />
          <MapLegend layersDisplayed={layersDisplayed} />
        </StyledMap>
      </div>
    </div>
  );
};
