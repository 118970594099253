import { useEffect, useRef, useState, useCallback, useReducer } from 'react';

export const useEffectAfterMount = (effect: () => void, input: any[]) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, input);
};

export const useDebouncedEffect = (callback: any, delay: number, deps: any[] = []) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};

export const useEffectOnUnmountWithDependency = (onUnmountFunction, dependency) => {
  const dependencyValue = useRef(dependency);

  useEffect(() => {
    dependencyValue.current = dependency;
  }, [dependency]);

  useEffect(() => () => onUnmountFunction(dependencyValue.current), []);
};

// Session persistency hooks
type JSONValue = string | number | boolean | { [x: string]: any } | any[];
export type TPersistentStorageValue = Record<string, JSONValue> | JSONValue;
export type TPersistentStorageHook = [
  TPersistentStorageValue,
  (value: TPersistentStorageValue) => void
];

const getSessionStorageOrDefault = (
  key: string,
  defaultValue: TPersistentStorageValue
): TPersistentStorageValue => {
  const stored: string | null = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return JSON.parse(stored);
};

/*
 * Session persistent value storage hook.
 * Keeps a key value persistent in a window session
 * but not between tabs or new windows.
 *
 * Usage:
 * `const [counter, setCounter] = useSessionStorage(0, 'localCounter');`
 */

export const useSessionStorage = (
  defaultValue: TPersistentStorageValue,
  key: string
): TPersistentStorageHook => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const [value, setValue] = useState<TPersistentStorageValue>(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    if (value === '') {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [value, setValue];
};

/*
 * Proposed implementation of global persistent store hook.
 * Uses the 'use-persisted-state' lib and would provide reflected
 * persistance over tabs and windows.
 *
 * Usage:
 * `const [counter, setCounter] = useGlobalStorage(0, 'globalCounter');`
 */

/*
import createPersistedState from 'use-persisted-state';

// Global persistency hooks
export const useGlobalStorage = (
  initialItems: TPersistentStorageValue,
  key: string
): TPersistentStorageHook =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-unsafe-call,  @typescript-eslint/no-unsafe-return
  createPersistedState(key)<TPersistentStorageValue>(initialItems);
*/

// Persistent reducer hook

interface IAction {
  [key: string]: any;
  type: string;
}

const init = (
  STORAGE_KEY: string,
  INITIAL_STATE: TPersistentStorageValue,
  storageMechanism: 'local' | 'session' = 'local'
) => {
  // initialize state w/ existing value saved in `...Storage`

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const storage = window[`${storageMechanism}Storage`];
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-call
    const storageValue = storage.getItem(STORAGE_KEY);

    if (storageValue !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(storageValue);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-call
      storage.setItem(STORAGE_KEY, JSON.stringify(INITIAL_STATE));
    }
  } catch {
    console.error('Malformed or inaccessible storage key. Initial default value used.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return INITIAL_STATE;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePersistentReducer = (
  reducer: (prevState: any, action: IAction) => any,
  INITIAL_STATE: TPersistentStorageValue,
  STORAGE_KEY: string,
  /*
   * `localStorage` will act as a global store, while
   * `sessionStorage` will act as a contained storage
   * for a specific window
   */
  storageMechanism: 'local' | 'session' = 'local'
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const storage = window[`${storageMechanism}Storage`];
  const reducerStorage = useCallback(
    (state: unknown, action: IAction) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newState = reducer(state, action);

      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        storage.setItem(STORAGE_KEY, JSON.stringify(newState));
      } catch {
        // if user is in incognito mode, `...Storage` access
        // will throw an error.
        // The state could fail to stringify too. So do nothing
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return newState;
    },
    [STORAGE_KEY]
  );

  // use `init` function to initialize state from `...Storage`
  return useReducer(reducerStorage, undefined, () =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    init(STORAGE_KEY, INITIAL_STATE, storageMechanism)
  );
};
