import { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { point as turfPoint, featureCollection as turfFC } from '@turf/helpers';
import nearestPoint from '@turf/nearest-point';

// Interfaces
import { IVectorLayer, IPosition } from 'src/utils/interfaces';
import { Map as IMapApis } from 'mapbox-gl';
import { TrackPca } from 'common-logic';
import { IOperationData } from 'src/@noiseEvents/interfaces';

// Utils
import { calculateCurrentDistances } from 'src/utils';

// Actions
import { updateOperationTagData } from 'src/app/actions/tagDataActions';

// Queries
import { fetchOperationDetails } from 'src/@operations/resolvers/operationSummaryResolver';

export interface ITag {
  uuid: string;
  data: IVectorLayer;
  isLoading?: boolean;
  fetchedData?: IOperationData;
  longitude: number;
  latitude: number;
}

export const addTagToList = (
  item: IVectorLayer,
  list: ITag[],
  setList: Dispatch<SetStateAction<ITag[]>>
) => {
  if (
    item &&
    // Prevent tag being created from a point that is already in the list
    !list.some(
      listItem =>
        listItem.data.latitude === item.latitude && listItem.data.longitude === item.longitude
    )
  ) {
    setList([
      ...list,
      {
        uuid: uuidv4(),
        data: item,
        longitude: item.longitude,
        latitude: item.latitude,
      },
    ]);
  }
};

export const removeTagFromList = (
  uuid: string,
  list: ITag[],
  setList: Dispatch<SetStateAction<ITag[]>>
) => {
  setList(list.filter(item => item.uuid !== uuid));
};

export const removeAllTags = (
  setList: Dispatch<SetStateAction<ITag[]>>
) => {
  setList([]);
}

export const batchRemoveTagFromList = (
  uuids: string[],
  list: ITag[],
  setList: Dispatch<SetStateAction<ITag[]>>
) => {
  setList(list.filter(item => !uuids.includes(item.uuid)));
};

export const vectorLayerToPoint = ({
  operation,
  clickedElement,
  userHomeLocation,
  mapProjectionString,
}: {
  operation: IOperationData;
  clickedElement: IVectorLayer;
  userHomeLocation?: IPosition;
  mapProjectionString?: string;
}) => {
  if (operation && operation.points) {
    if (clickedElement) {
      const closest = nearestPoint(
        turfPoint([clickedElement.latitude, clickedElement.longitude]),
        turfFC(operation.points.map(pt => turfPoint([pt.lat, pt.lon])))
      );
      if (closest) {
        let currentDistanceInfo: TrackPca = null;
        const point = operation.points[closest.properties.featureIndex];
        const profile = operation.profile.find(pt => pt.time === point.t);
        if (mapProjectionString && userHomeLocation) {
          currentDistanceInfo = calculateCurrentDistances(
            userHomeLocation,
            operation,
            point,
            mapProjectionString
          );
        }

        return {
          id: operation.id,
          showPointData: true,
          amsl: point.alt,
          dist: profile.dist,
          longitude: point.lon,
          latitude: point.lat,
          type: operation.operationType,
          flightId: operation.acid,
          time: DateTime.fromISO(operation.startTime, { setZone: true })
            .plus({ seconds: point.t })
            .toISO() as string,
          distanceHorizontal: currentDistanceInfo ? currentDistanceInfo.horizontalDistance : null,
          distanceVertical: currentDistanceInfo ? currentDistanceInfo.verticalDistance : null,
          distanceSlant: currentDistanceInfo ? currentDistanceInfo.slantDistance : null,
        };
      }
    }
  }
};

export const profileTimeToMapVectorLayer = (
  mapApis: IMapApis,
  operation: IOperationData,
  time: number
) => {
  const point = operation.points.find(pt => pt.t === time);
  const trackLayers: string[] = [];
  mapApis.getStyle().layers.map(({ id }) => {
    if (id.includes('foreground_')) {
      trackLayers.push(id);
    }
  });

  // Existing map types + mapbox types are broken here
  // @ts-ignore
  const queriedLayers: IVectorLayer[] = mapApis.queryRenderedFeatures(mapApis.project(point), {
    layers: trackLayers,
  });
  // Add lat/long values to the layer
  queriedLayers.forEach(layer => {
    layer.latitude = point.lat;
    layer.longitude = point.lon;
  });
  return queriedLayers;
};

export const fetchTagOperationData = (id: number, dispatcher, client) => {
  updateOperationTagData({
    id,
    body: {
      isLoading: true,
      data: null,
    },
    dispatcher,
  });

  fetchOperationDetails({
    client,
    id,
    correlatedPermissionsList: {
      hasInfringementsRead: false,
      hasNoiseEventsRead: false,
      hasComplaintsRead: false,
      hasWeatherRead: false,
    },
  })
    .then(response => {
      updateOperationTagData({
        id,
        body: {
          isLoading: false,
          data: response.data,
        },
        dispatcher,
      });
    })
    .catch(err => {
      console.error(`Unable to fetch summary data for: ${id}. Error: ${err}`);
      updateOperationTagData({
        id,
        body: {
          isLoading: false,
          data: null,
        },
        dispatcher,
      });
    });
};
