import React from 'react';
import { Dropdown, FormErrorDisplay, FormTextField } from '@ems/client-design-system';
import { useLanguageSelectors } from 'src/app/reducers';

export const ExclusionZonePanel = infDetailsData => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { form: formStrings },
      },
    },
  } = languageSelectors.getLanguage();
  const { infRule, setInfRule, allZones, isEditing, errors } = infDetailsData;

  const handleChange = item => {
    setInfRule(Object.assign({}, { ...infRule, selectionZoneId: item.key }, { hasChanged: true }));
  };

  const zoneOptions = allZones.map(zone => ({
    key: zone.id,
    label: zone.name,
  }));

  const selectedZoneDetails = allZones.find(zone => zone.id === infRule.selectionZoneId);
  const selectedZone = selectedZoneDetails
    ? {
        key: selectedZoneDetails.id,
        label: selectedZoneDetails.name,
      }
    : null;

  return (
    <>
      {isEditing ? (
        <Dropdown
          label={formStrings.zone}
          placeholderValue=""
          searchItems={zoneOptions}
          isNullable={false}
          updateSelection={handleChange}
          selectedItem={selectedZone}
          disabled={!isEditing}
        />
      ) : (
        <FormTextField
          label={formStrings.zone}
          id={'Zone'}
          value={selectedZone ? selectedZone.label : ''}
          isReadOnly
        />
      )}
      <FormErrorDisplay error={errors ? errors.exclusionZone : undefined} />
    </>
  );
};
