import React, { FC, useState, useContext, useEffect } from 'react';
// components
import { Button, Radio, Spinner, Table } from '@ems/client-design-system';
// interfaces
import {
  IAircraftFuelMappingsContainer,
  IFuelType,
  IMutationItem,
  TMode,
} from 'src/@settings/interfaces';
// selectors
import {
  useConfigSelectors,
  useFilterDataSelectors,
  useLanguageSelectors,
  useScenariosSelectors,
} from 'src/app/reducers';
import {
  useAircraftMappingsSelector,
  useTableSelectors,
  useAircraftFuelMappingsSelector,
} from 'src/@settings/reducers';
// provider
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// functions
import {
  getFormattedMutationItem,
  getLtoEmissionMappings,
  updateTableIds,
  formatFuelTypesData,
  getColumnTypes,
} from 'src/@settings/functions';
// constants
import {
  FUELTYPES_MODULE,
  MANAGE_CONFIGURATIONS_MODULE,
  MAX_CARBON_EMISSION_FACTOR,
  MULTIPLE,
  SINGLE,
} from 'src/constants';
// utils
import uuid from 'uuid';
import { convertMapToArray, sortTableData, formatHeaders } from 'src/utils';
import { Prompt } from 'react-router-dom';
import { TextField } from '../components';
import { sortFuelTypesTable } from '../actions';

export const FuelTypesContainer: FC<IAircraftFuelMappingsContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateFuelTypesData,
  updateScenarios,
  discardMutationData,
}) => {
  // Config
  const configSelectors = useConfigSelectors();
  const {
    formValidations: {
      airtrak: {
        co2Factor: co2FactorValidation,
        percentageOfFlights: percentageOfFlightsValidation,
      },
    },
  } = configSelectors.getConfig();

  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);

  const languageSelectors = useLanguageSelectors();
  const translationData = languageSelectors.getLanguage();
  const tableSelectors = useTableSelectors();
  const sortObject = tableSelectors.getSortObject(FUELTYPES_MODULE);
  const sortString = tableSelectors.getSortString(FUELTYPES_MODULE);
  const scenariosSelector = useScenariosSelectors();
  const activeScenario = scenariosSelector.getActiveScenario();
  const fuelTypesSelector = useAircraftFuelMappingsSelector();
  const items = fuelTypesSelector.getFuelTypes();
  const loading: boolean = fuelTypesSelector.getIfLoading();
  const aircraftMappingsSelector = useAircraftMappingsSelector();
  const ltoEmissions = aircraftMappingsSelector.getLtoEmissions();
  const filterSelector = useFilterDataSelectors();
  const filterData = filterSelector.getSettingsFiltersData();

  const [disableDelete, setDisableDelete] = useState<boolean>(true);
  const [rowData, setRowData] = useState<IFuelType[]>([]);
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [highlightRow, setHighlightRow] = useState<number>();
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  const {
    fields: {
      fuelTypes: {
        airline: airlineColumnHeader,
        aircraftEngine: aircraftEngineColumnHeader,
        factorCO2: factorCO2ColumnHeader,
        percentage: percentageColumnHeader,
        comment: commentColumnHeader,
      },
    },
    components: {
      buttons: { deleteSelected, addNew },
      hints: { noDataTitle, tryChangingFiltersOrDate, areYouSureYouWantToLeave },
      info: { fuelTypesInfo, fuelTypesSecondaryInfo },
      labels: {
        singleEmissionFactor,
        multipleEmissionFactor,
        CO2EmissionFactor,
        jetAviationFuelLabel,
        unknownEnginelabel,
        pistonEngineLabel,
      },
    },
  } = translationData;

  // Table header set up
  const columnData = [
    {
      columnName: 'airline',
      key: 'airline',
      title: airlineColumnHeader,
    },
    { columnName: 'aircraftEngine', key: 'aircraftEngine', title: aircraftEngineColumnHeader },
    {
      columnName: 'factorCO2',
      key: 'factorCO2',
      title: factorCO2ColumnHeader,
    },
    {
      columnName: 'percentage',
      key: 'percentage',
      title: percentageColumnHeader,
    },
    {
      columnName: 'comment',
      key: 'comment',
      title: commentColumnHeader,
    },
  ];

  const rowHeaders = formatHeaders(
    FUELTYPES_MODULE,
    columnData,
    rowData.length,
    dispatcher,
    sortFuelTypesTable,
    tableSelectors,
    languageSelectors.getLanguage(),
    '',
    false,
    true
  );

  const fuelTypesColumns: string[] = columnData.map(header => header.columnName);

  const options = [
    { key: SINGLE, label: singleEmissionFactor },
    { key: MULTIPLE, label: multipleEmissionFactor },
  ];
  const [selectedEmissionFactor, setSelectedEmissionFactor] = useState<string>(
    activeScenario.emissionRateMode
  );

  const [data, setData] = useState(items);
  const copyOfFuelTypes = items.map(item => ({ ...item }));
  const [apiData, setApiData] = useState(copyOfFuelTypes);

  const defaultFuelTypesConfigurationData = {
    emissionRateSingle: activeScenario.emissionRateSingle,
    emissionRateAvGas: activeScenario.emissionRateAvGas,
    emissionRateUnknown: activeScenario.emissionRateUnknown,
    emissionRateJetFuel: activeScenario.emissionRateJetFuel,
  };
  const [fuelTypesConfigurationData, setFuelTypesConfigurationData] = useState(
    defaultFuelTypesConfigurationData
  );
  const [configfurationMutationData, setConfigfurationMutationData] = useState<IMutationItem[]>([]);
  const setdefaultFuelTypesConfigurationData = obj => {
    const fuelTypesConfigurations = Object.assign({}, fuelTypesConfigurationData, obj);
    setFuelTypesConfigurationData(fuelTypesConfigurations);
    const { items } = convertMapToArray(
      getFormattedMutationItem(
        'Update',
        Object.assign({}, activeScenario, obj),
        new Map<number, IMutationItem>(),
        MANAGE_CONFIGURATIONS_MODULE
      )
    );
    setConfigfurationMutationData(items);
    updateScenarios(items);
    setChangesAvailable(true);
  };

  const onChange = e => {
    setSelectedEmissionFactor(e.target.value);
  };

  useEffect(() => {
    formatAndSetRowData(data);
  }, [data, ltoEmissions]);

  useEffect(() => {
    setData(apiData);
    formatAndSetRowData(apiData);
    setFuelTypesConfigurationData(defaultFuelTypesConfigurationData);
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [areChangesDiscarded]);

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [discardMutationData]);

  const updateFuelType = (fuelTypeId, selectedItem) => {
    setData(prev => {
      const prevData = prev.slice(0);
      prevData[fuelTypeId] = { ...prevData[fuelTypeId], ...selectedItem };
      handleMutation('Update', prevData[fuelTypeId]);
      return prevData;
    });
    setChangesAvailable(true);
  };

  const formatAndSetRowData = data => {
    const formattedData = formatFuelTypesData(
      updateTableIds([], data),
      translationData,
      getLtoEmissionMappings(ltoEmissions),
      filterData,
      updateFuelType,
      percentageOfFlightsValidation.match,
      co2FactorValidation.match
    );
    setRowData(formattedData);
  };

  useEffect(() => {
    const sortData = sortTableData(data, sortObject);
    setData(sortData);
  }, [sortString]);

  useEffect(() => {
    setData(sortTableData(items, sortObject));
    setApiData(items);
  }, [items]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(getFormattedMutationItem(mode, updatingItem, mutationData, FUELTYPES_MODULE));
    const { items } = convertMapToArray(mutationData);
    updateFuelTypesData(items);
  };

  const onSelectRow = selectedIndexes => {
    setSelectedInTable(selectedIndexes);
    selectedIndexes.length > 0 ? setDisableDelete(false) : setDisableDelete(true);
  };

  const addNewRow = () => {
    const addNewDefaultRowData = {
      airline: '',
      aircraftEngine: '? / ?',
      factorCO2: activeScenario.emissionRateSingle,
      percentage: 0.0,
      comment: '',
      scenarioId: activeScenario.id,
      tableId: uuid.v4(),
      id: new Date().getTime(),
    };
    setHighlightRow(0);
    handleMutation('Insert', addNewDefaultRowData);
    setData(updateTableIds([addNewDefaultRowData], data));
  };

  const onDeleteClick = () => {
    const deletingItems = getDeletingItems();
    deletingItems.map(item => {
      handleMutation('Delete', item);
    });
    const updatedData = data.filter((item, index) => !selectedInTable.includes(index));
    setData(updateTableIds([], updatedData));
    setSelectedInTable([]);
    mutationData.size > 0 ? setChangesAvailable(true) : setChangesAvailable(false);
  };

  const getDeletingItems = () => data.filter((item, index) => selectedInTable.includes(index));

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      {' '}
      <Spinner loading size="l" centered />{' '}
    </div>
  );

  const getFuelTypesComponent = () => (
    <>
      <div className="emission-factor-header-container">
        {options.map(option => (
          <div key={option.key} className="emission-factor-mode">
            <Radio
              checked={selectedEmissionFactor === option.key}
              onChange={onChange}
              value={option.key}
              label={option.label}
            />
          </div>
        ))}
        <hr className={'break-line'} />
      </div>
      {selectedEmissionFactor === SINGLE && (
        <div className="emission-factor-content-container">
          <div className="single-emission-factor-text-box">
            <TextField
              label={CO2EmissionFactor}
              value={fuelTypesConfigurationData.emissionRateSingle}
              setData={setdefaultFuelTypesConfigurationData}
              name={'emissionRateSingle'}
              validationString={co2FactorValidation.match}
              maxValue={MAX_CARBON_EMISSION_FACTOR}
            />
          </div>
        </div>
      )}
      {selectedEmissionFactor === MULTIPLE && (
        <div className="emission-factor-content-container">
          <div className="multiple-emission-factors-header">{fuelTypesInfo}</div>
          <div className="multiple-emission-factors-text-box-holder">
            <TextField
              label={jetAviationFuelLabel}
              value={fuelTypesConfigurationData.emissionRateJetFuel}
              setData={setdefaultFuelTypesConfigurationData}
              name={'emissionRateJetFuel'}
              validationString={co2FactorValidation.match}
              maxValue={MAX_CARBON_EMISSION_FACTOR}
            />
            <TextField
              label={unknownEnginelabel}
              value={fuelTypesConfigurationData.emissionRateUnknown}
              setData={setdefaultFuelTypesConfigurationData}
              name={'emissionRateUnknown'}
              validationString={co2FactorValidation.match}
              maxValue={MAX_CARBON_EMISSION_FACTOR}
            />
            <TextField
              label={pistonEngineLabel}
              value={fuelTypesConfigurationData.emissionRateAvGas}
              setData={setdefaultFuelTypesConfigurationData}
              name={'emissionRateAvGas'}
              validationString={co2FactorValidation.match}
              maxValue={MAX_CARBON_EMISSION_FACTOR}
            />
          </div>
          <div className="multiple-emission-factors-sub-header">
            <p className="multiple-emission-factors-header">{fuelTypesSecondaryInfo}</p>
            <div>
              <Button
                style="standard"
                className="airtrak-delete-btn"
                disabled={disableDelete}
                onClick={onDeleteClick}>
                {deleteSelected}
              </Button>
              <Button style="standard" className="airtrak-addnew-btn" onClick={addNewRow}>
                {addNew}
              </Button>
            </div>
          </div>
          <div className="multiple-emission-table">
            <Table
              className="fuel-types-table"
              data={rowData}
              columns={fuelTypesColumns}
              rowHeaders={rowHeaders}
              gridID={'selectable'}
              areAllRowsSelected={false}
              columnTypes={getColumnTypes(fuelTypesColumns)}
              languageData={{
                noDataTitle: `${noDataTitle}`,
                noDataText: `${tryChangingFiltersOrDate}`,
              }}
              selectedData={selectedInTable}
              onSelectRow={onSelectRow}
              clickedRow={highlightRow}
            />
            <Prompt
              message={areYouSureYouWantToLeave}
              when={mutationData.size > 0 || configfurationMutationData.length > 0}
            />
          </div>
        </div>
      )}
    </>
  );

  return loading ? getSpinnerComponent() : getFuelTypesComponent();
};
