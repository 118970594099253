import { TInfringementRules } from 'src/app/interfaces';
import { FEATURE_TYPES, INFRINGEMENT_RULE_TYPES } from 'src/constants';
import { ISpatialFeature } from '../../interfaces';
export const getFeaturesToBeSetInActive = () => {};

export const getSpatialFeatureIdsFromRule = (rule: TInfringementRules): number[] => {
  switch (rule.infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      return [rule.corridorId];
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      return rule.gateCriteria.map(gateCriterion => gateCriterion.gateId);
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      return [rule.selectionZoneId];
    default:
      return [];
  }
};

export const getSpatialFeatureDetailsFromRule = (rule: TInfringementRules) => {
  switch (rule.infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      return { featureId: rule.corridorId, ruleActive: rule.isActive };
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      return {
        featureId: rule.gateCriteria.map(gateCriterion => gateCriterion.gateId),
        ruleActive: rule.isActive,
      };
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      return { featureId: rule.selectionZoneId, ruleActive: rule.isActive };
    default:
      return {};
  }
};

export const getFeatureType = (rule: TInfringementRules) => {
  switch (rule.infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      return FEATURE_TYPES.CORRIDOR;
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      return FEATURE_TYPES.GATE;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      return FEATURE_TYPES.SELECTION_ZONE;
    default:
      return undefined;
  }
};

// Returns the spatial features that need to be updated with the active status correctly set
export const getUpdatedSpatialFeatureStates = (
  infRules: TInfringementRules[],
  features: ISpatialFeature[]
) => {
  const getFeatureByType = (featureType: string) =>
    features.filter(feature => (feature.featureType === featureType ? { feature } : null));
  // Sort features by type
  const featuresByType = {
    [FEATURE_TYPES.GATE]: getFeatureByType(FEATURE_TYPES.GATE),
    [FEATURE_TYPES.SELECTION_ZONE]: getFeatureByType(FEATURE_TYPES.SELECTION_ZONE),
    [FEATURE_TYPES.CORRIDOR]: getFeatureByType(FEATURE_TYPES.CORRIDOR),
  };

  // gets featuresIds of all rules
  const selectedFeaturesArrays = {
    [FEATURE_TYPES.GATE]: [],
    [FEATURE_TYPES.SELECTION_ZONE]: [],
    [FEATURE_TYPES.CORRIDOR]: [],
  };

  // Split spartial rules into category based on type
  infRules.forEach(rule => {
    const featureType = getFeatureType(rule);
    if (featureType) {
      selectedFeaturesArrays[featureType].push(getSpatialFeatureDetailsFromRule(rule));
    }
  });

  // Check if other rules use the spatial feature, and that the rules are active
  const featuresToBeUpdated = [];
  Object.keys(featuresByType).forEach(featureType => {
    const selectedFeatureIds = selectedFeaturesArrays[featureType].flat();
    const featureState = featuresByType[featureType].map(feature => {
      return selectedFeatureIds.some(({ featureId, ruleActive }) => {
        // An infringement can potentially have multiple gates, so need to check all
        if (Array.isArray(featureId)) {
          return featureId.includes(feature.featureId) && ruleActive;
        }
        return ruleActive && featureId === feature.featureId;
      })
        ? { ...feature, isActive: true }
        : { ...feature, isActive: false };
    });

    featuresToBeUpdated.push(
      featureState.filter(
        (feature, index) => featuresByType[featureType][index].isActive !== feature.isActive
      )
    );
  });

  return featuresToBeUpdated.flat();
};
