import { DEFAULT_LAYER_PREFIX, MONITOR_LOCATIONS_LAYER_POSTFIX } from 'src/constants';
import { useGetMapLayers } from './mapApis';
import { useMemo } from 'react';
import { useIsDynamicTileServer } from './hooks/useIsDynamicTileServer';

export const doesLayerExist = (mapApis, layerId: string) => {
  if (!mapApis) {
    return false;
  }
  const layers = mapApis.getStyle().layers.map(({ id }) => id);
  return layers.includes(layerId);
};

export const useTrackLayers = (
  mapApis,
  datesArray: string[] = [],
  isTrackLayerDisabled = false,
  layerPrefix: string = DEFAULT_LAYER_PREFIX
) => {
  const isDynamic = useIsDynamicTileServer();
  const mapLayers = useGetMapLayers(mapApis);
  const dynamicMapLayers = useMemo(
    () =>
      isDynamic
        ? mapLayers.flatMap(({ id }) =>
            id.includes('trackLayer_') || id.includes(`_${MONITOR_LOCATIONS_LAYER_POSTFIX}`)
              ? id
              : []
          )
        : [],
    [mapLayers, isDynamic]
  );
  const dateArrayLayers = useMemo(
    () => (isDynamic ? [] : datesArray.map(date => `${layerPrefix}${date}`)),
    [mapLayers, datesArray, isDynamic]
  );
  return useMemo(() => {
    return isTrackLayerDisabled ? [] : [...dynamicMapLayers, ...dateArrayLayers];
  }, [dynamicMapLayers, dateArrayLayers, isTrackLayerDisabled]);
};
