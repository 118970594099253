import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { ComplaintsStateProvider } from 'src/@complaints/providers/ComplaintsStateProvider';
import { ComplaintDetailsContainer } from 'src/@complaints/containers';
const ComplaintDetailsScreen = ({ match, location }) => {
  const { id } = match.params;

  return (
    <SplitLayout>
      <ComplaintsStateProvider>
        <ComplaintDetailsContainer id={id} paginationInfo={location.state} />
      </ComplaintsStateProvider>
    </SplitLayout>
  );
};

export const ComplaintDetails = withRouteProps(ComplaintDetailsScreen);
