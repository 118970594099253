import { IViewState } from 'src/@infringements/interfaces';
import { IEditZoneParams, ISelectionZone, TSelectionZoneGeometry } from './interfaces';
import { Map as IMapApis } from 'mapbox-gl';
import { offsetCoordinatesByPixels } from '../mapHelpers/mapHelpers';
import { DEFAULT_ZONE_SIZE } from 'src/constants';

export const setUpExistingZone = (
  zone: ISelectionZone,
  editGeometryParams?: IEditZoneParams
): TSelectionZoneGeometry => {
  const zoneGeometry = zone.points;

  if (editGeometryParams && editGeometryParams.updateState && editGeometryParams.updateGeometry) {
    editGeometryParams.updateState(zoneGeometry);
    editGeometryParams.updateGeometry(zoneGeometry, true);
  }
  return zoneGeometry;
};

export const setUpNewZone = (
  viewport: IViewState,
  mapApis: IMapApis,
  editGeometryParams?: IEditZoneParams
): TSelectionZoneGeometry => {
  const { width, height } = DEFAULT_ZONE_SIZE;
  // bottom right coordinate
  const { longitude, latitude } = viewport;

  const bottomLeft = offsetCoordinatesByPixels([longitude, latitude], width, 0, mapApis);
  const topLeft = offsetCoordinatesByPixels([longitude, latitude], width, height, mapApis);
  const topRight = offsetCoordinatesByPixels([longitude, latitude], 0, height, mapApis);

  // Coordinates initialized in a clockwise direction
  const zoneGeometry = [
    { longitude: bottomLeft.lng, latitude: bottomLeft.lat, altitude: 0 },
    { longitude: topLeft.lng, latitude: topLeft.lat, altitude: 0 },
    { longitude: topRight.lng, latitude: topRight.lat, altitude: 0 },
    { longitude, latitude, altitude: 0 },
  ];

  if (editGeometryParams && editGeometryParams.updateState && editGeometryParams.updateGeometry) {
    editGeometryParams.updateState(zoneGeometry);
    editGeometryParams.updateGeometry(zoneGeometry, true);
  }
  return zoneGeometry;
};
