import React, { useContext } from 'react';
import { createContext, ReactNode, useState } from 'react';

interface IProps {
  children: ReactNode;
}

export interface MapState {
  mapStyleURL: string;
}

export const defaultMapState: MapState = {
  mapStyleURL: '',
};

const MapStateContext = createContext(null);

export const useGlobalMapContext = () => {
  const context = useContext(MapStateContext);
  if (!context)
    {throw new Error('No map state context found. Is there a wrapping Provider component?');}
  return context;
};

export const MapStateProvider = ({ children }: IProps) => {
  const [mapState, setMapState] = useState(defaultMapState);

  const updateMapStyleURL = (newStyleUrl: string) => {
    setMapState({ ...mapState, mapStyleURL: newStyleUrl });
  };

  return (
    <MapStateContext.Provider value={{ ...mapState, updateMapStyleURL }}>
      {children}
    </MapStateContext.Provider>
  );
};
