import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SELECTED_COLOR } from '../taxiPathLayer.consts';
import { InteractiveLayerId } from 'src/components/Map/hooks/useInteractiveLayerIds';
import { getQueriedFeaturesById } from 'src/components/Map/helpers/getQueriedFeaturesById';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';
import { Position, featureCollection, lineString } from '@turf/turf';
import * as turf from '@turf/turf';
import {
  InteractiveLineLayer,
  LineSegments,
} from 'src/components/Map/layers/InteractiveLine/InteractiveLineLayer';

const ADD_CONNECTOR_POINT_STYLES = {
  default: {
    'circle-color': SELECTED_COLOR,
    'circle-radius': 2,
  },
  hovered: {
    'circle-color': 'black',
    'circle-radius': 2,
  },
  selected: {},
};

const ADD_CONNECTOR_LINE_STYLES = {
  default: {
    'line-color': SELECTED_COLOR,
    'line-width': 2,
    'line-dasharray': [2, 1],
  },
};

type ConnectorNodeIds = {
  source: string | null;
  dest: string | null;
};

const EMPTY_CONNECTOR_NODE_ID = {
  source: null,
  dest: null,
};

const ADD_LINE_CONFIG = { draggable: false, followCursor: true };

export const AddedConnector = () => {
  const { connectorCoords, onClick, onLineUpdate, connectorNodeIds } = useAddConnector();

  const displayedLineString = useMemo(() => {
    const lineStringCoords =
      connectorCoords.length === 1 && connectorNodeIds !== null
        ? Array(2).fill(connectorCoords[0])
        : connectorCoords;
    const lineStringData = lineStringCoords.length === 2 ? [lineString(lineStringCoords)] : [];
    return featureCollection(lineStringData);
  }, [connectorCoords, connectorNodeIds]);

  return (
    <InteractiveLineLayer
      lineFeatures={displayedLineString}
      pointStyles={ADD_CONNECTOR_POINT_STYLES}
      lineStyles={ADD_CONNECTOR_LINE_STYLES}
      onLineUpdate={onLineUpdate}
      lineConfig={ADD_LINE_CONFIG}
      handleClick={onClick}
    />
  );
};

const useAddConnector = () => {
  const {
    dispatch,
    state: { nodeGeoJSON, pathGeoJSON },
  } = useTaxiPathContext();

  const [connectorCoords, setConnectorCoords] = useState<Position[]>([]);
  const [connectorNodeIds, setConnectorNodeIds] = useState<ConnectorNodeIds | null>(null);

  const onLineUpdate = useCallback(
    (updateLine: LineSegments) => {
      const newCoordinates = updateLine.features[0].geometry.coordinates;

      if (connectorNodeIds?.source) {
        setConnectorCoords(newCoordinates);
      }
    },
    [connectorCoords, setConnectorCoords, connectorNodeIds]
  );

  useEffect(() => {
    if (connectorNodeIds) {
      const { source, dest } = connectorNodeIds;
      if (connectorCoords.length === 2 && source && dest) {
        const lastPathById = pathGeoJSON.features.length
          ? pathGeoJSON.features?.reduce((prev, current) =>
              Number(prev.properties.PathwayId) > Number(current.properties.PathwayId)
                ? prev
                : current
            )
          : { properties: { PathwayId: 0 } };

        const sourceNode = nodeGeoJSON.features.find(
          feature => feature.properties.NodeId === String(source)
        );
        const destNode = nodeGeoJSON.features.find(
          feature => feature.properties.NodeId === String(dest)
        );

        const newConnector = turf.lineString(
          [sourceNode.geometry.coordinates, destNode.geometry.coordinates],
          {
            PathwayId: `${Number(lastPathById.properties.PathwayId) + 1}`,
            SourceNodeId: source,
            DestNodeId: dest,
          }
        );
        dispatch({ type: TaxiPathActions.ADD_CONNECTOR, payload: newConnector });
        setConnectorCoords([]);
        setConnectorNodeIds(null);
      }
    }
  }, [connectorCoords, connectorNodeIds, dispatch, setConnectorCoords]);

  const onClick = useCallback(
    ({ target, point }) => {
      const nodeFeatures = getQueriedFeaturesById(target, point, InteractiveLayerId.taxiPathNode);
      if (nodeFeatures?.length) {
        const clickedNodeId = String(nodeFeatures[0].properties.NodeId);
        const matchedNode = nodeGeoJSON.features.find(
          node => node.properties.NodeId === clickedNodeId
        );

        if (!connectorNodeIds) {
          setConnectorNodeIds({ ...EMPTY_CONNECTOR_NODE_ID, source: clickedNodeId });
          setConnectorCoords([[...matchedNode.geometry.coordinates]]);
        } else if (clickedNodeId !== connectorNodeIds.source) {
          setConnectorNodeIds({ ...connectorNodeIds, dest: clickedNodeId });
        }
      }
    },
    [connectorNodeIds, setConnectorCoords]
  );

  return { connectorCoords, onClick, onLineUpdate, connectorNodeIds };
};
