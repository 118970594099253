import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { MAP_BACKGROUND_TYPES, MAP_STYLE_URL } from '../Map.consts';
import { useEffect, useMemo } from 'react';
import { MapBackground, MapBackgroundType } from '../map.types';
import { useGlobalMapContext } from 'src/app/applicationState/map/Provider';

export const useMapStyleProps = (selectedStyle?: MapBackgroundType) => {
  const availableBackgrounds = useAvailableMapStyles();
  const { updateMapStyleURL } = useGlobalMapContext();

  useEffect(() => {
    if (selectedStyle) {
      const background = availableBackgrounds.find(background => background.key === selectedStyle);
      if (background) {
        updateMapStyleURL(background.value);
      }
    }
  }, [updateMapStyleURL, selectedStyle]);
};

export const useAvailableMapStyles = (): MapBackground[] => {
  const configSelectors = useConfigSelectors();
  const config = configSelectors.getConfig();
  const {
    map: { mapStyleURL, mapStyleSatelliteURL, mapStyleStreetURL },
  } = config;

  const languageSelectors = useLanguageSelectors();
  const language = languageSelectors.getLanguage();
  const {
    components: {
      labels: { mapStyleBasicLabel, mapStyleStreetLabel, mapStyleSatelliteLabel },
    },
  } = language;

  return useMemo(
    () => [
      {
        key: MAP_BACKGROUND_TYPES.GREY as MapBackgroundType,
        value: mapStyleURL,
        label: mapStyleBasicLabel,
        imageName: 'mapbox_style.png',
      },
      {
        key: MAP_BACKGROUND_TYPES.STREET as MapBackgroundType,
        value: mapStyleStreetURL,
        label: mapStyleStreetLabel,
        imageName: 'mapstyle_street.png',
      },
      {
        key: MAP_BACKGROUND_TYPES.SATELLITE as MapBackgroundType,
        value: mapStyleSatelliteURL,
        label: mapStyleSatelliteLabel,
        imageName: 'satellite.png',
      },
    ],
    [
      mapStyleBasicLabel,
      mapStyleStreetLabel,
      mapStyleSatelliteLabel,
      mapStyleURL,
      mapStyleSatelliteURL,
      mapStyleStreetURL,
    ]
  );
};

// This is for compatibility with the current settings implementation can't refactor all of that atm
// Ideally you select things by a key
export const useSetMapBackgroundByValue = () => {
  const availableBackgrounds = useAvailableMapStyles();
  const { updateMapStyleURL } = useGlobalMapContext();
  return (mapStyleUrl: string) => {
    const background = availableBackgrounds.find(background => background.value === mapStyleUrl);
    if (background) {
      updateMapStyleURL(background.value);
    }
  };
};

export const useMapStyle = (): string => {
  const { mapStyleURL } = useGlobalMapContext();
  return useMemo(() => !!mapStyleURL ? mapStyleURL : MAP_STYLE_URL, [mapStyleURL]);
};
