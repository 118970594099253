import React from 'react';
// components
import { MapPopupMoveable } from '@ems/client-design-system';

// constants
import { MAP_POPUP_OFFSET } from 'src/constants';

export const NMTPopup = ({
  lat,
  lon,
  onClose,
  anchor = 'top-left',
  draggable,
  children,
}: {
  lat: number;
  lon: number;
  onClose?: () => void;
  anchor?: string;
  draggable?: boolean;
  children: React.ReactNode;
}) => {
  const mapOffset = anchor == 'top-left' ? MAP_POPUP_OFFSET : MAP_POPUP_OFFSET * -1;

  return (
    <MapPopupMoveable
      latitude={lat}
      longitude={lon}
      anchorLongitude={lon}
      anchorLatitude={lat}
      anchorLine={draggable}
      className={'amsl-popup'}
      offsetLeft={mapOffset}
      offsetTop={mapOffset}
      onClose={onClose}
      closeButton={draggable}
      draggable={draggable}
      anchorMarker={false}>
      {children}
    </MapPopupMoveable>
  );
};
