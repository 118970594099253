import React, { useContext, useEffect, useState } from 'react';

// Components
import { Icons, SkeletonText } from '@ems/client-design-system';
import { ITableColumnData, LoadMoreBar, PageHeader } from 'src/components';
import { Button, Table } from '@ems/client-design-system';
import { ReviewFeaturesMap } from './ReviewFeaturesMap';

// Functions
import {
  useTableFilter,
  getSelectedIndexesFromKeys,
  getSortString,
  formatTableHeaders,
} from 'src/utils';
import { canInsertSomeOf, getSpatialFeaturesPermissions } from 'src/@settings/functions';
import { getIdFromTableRowKey, getTableData } from './SpatialFeaturesTableHelpers';

// Selectors/Config
import { useLanguageSelectors, useRolesSelectors } from 'src/app/reducers';

import { useSpatialFeaturesSelector } from 'src/@settings/reducers';

// Actions/Reducers
import {
  getSpatialFeatures,
  loadMoreSpatialFeatures,
  selectRow,
  sortSpatialFeaturesTable,
} from 'src/@settings/actions';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';

// interfaces
import { ISpatialFeature } from 'src/@settings/interfaces';

// Constants
import {
  FEATURE_TYPE_FILTER,
  MAX_RENDERABLE_FEATURES,
  SPATIAL_FEATURES_MODULE,
  TABLE_CONSTANTS,
} from 'src/constants';
import {
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { formatFilterString } from 'src/utils/tableHelpers/tableFilterHelpers';

interface SpatialFeaturesListProps {
  onSelectFeature: (featureId: number, featureType: string) => void;
  features: ISpatialFeature[];
  toggleAddFeature: () => void;
}

export const SpatialFeaturesList: React.FC<SpatialFeaturesListProps> = ({
  onSelectFeature,
  features,
  toggleAddFeature,
}) => {
  const rolesSelectors = useRolesSelectors();

  const spatialFeaturePermissions = getSpatialFeaturesPermissions(rolesSelectors);

  const canAddFeatures = canInsertSomeOf(spatialFeaturePermissions);

  const settingsDispatcher = useContext<any>(SettingsDispatchContext);

  const spatialFeaturesSelector = useSpatialFeaturesSelector();
  const sortObject = spatialFeaturesSelector.getSortObject();
  const spatialFeaturesTotalCount = spatialFeaturesSelector.getTotalCount();

  const [tableRowKeys, setTableRowKeys] = useState<string[]>([]);
  const [selectedTableKeys, updateSelectedTableKeys] = useState<string[]>([]);

  const [spatialFeatureTableData, setSpatialFeatureTableData] = useState([]);
  const [selectedInTable, updateSelectedInTable] = useState<number[]>([]);

  // Display geometries on map
  const {
    userSelectedGates,
    userSelectedCorridors,
    userSelectedZones,
  } = spatialFeaturesSelector.getSelectedGeometries();
  const [displayedGates, updateDisplayedGates] = useState<IGate[]>([]);
  const [displayedCorridors, updateDisplayedCorridors] = useState<INoiseAbatementCorridor[]>([]);
  const [displayedZones, updateDisplayedZones] = useState<ISelectionZone[]>([]);

  const languageSelector = useLanguageSelectors();

  const isLoadingList = spatialFeaturesSelector.getIsLoadingList();
  const isLoadingMore = spatialFeaturesSelector.getIsLoadingMore();
  const pageInfo = spatialFeaturesSelector.getPageInfo();

  useEffect(() => {
    if (features) {
      const items = getTableData(features, onSelectFeature);
      setTableRowKeys(items.map(item => item.tableRowKey));
      setSpatialFeatureTableData(items);
    }
    // set table row keys to empty - to stop errors when filtering while having items selected
    if (isLoadingList) {
      setTableRowKeys([]);
    }
  }, [features, isLoadingList]);

  // Language Selectors
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      hints: { tryChangingFilters },
      labels: {
        table: { endTable },
        total: totalString,
      },
    },
    screens: {
      settings: {
        tabs: { spatialFeatures: spatialFeaturesString },
        infringementRules: {
          table: { lastUpdated: lastUpdatedString, type: typeString },
        },
        spatialFeatures: {
          addFeature: addFeatureString,
          table: { feature: featureString },
        },
        notFound: {
          table: { noSpatialFeaturesFound },
        },
      },
    },
  } = languageSelector.getLanguage();

  const featureTypeFilterOptions = {
    SelectionZone: 'Zone',
    Corridor: 'Corridor',
    Gate: 'Gate',
  };
  // Table header set up
  const columnData: ITableColumnData[] = [
    {
      columnName: 'feature',
      key: 'name',
      title: featureString,
      filterName: 'names',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'type',
      key: 'featureType',
      title: typeString,
      filterName: 'featureTypes',
      filterOptionType: FEATURE_TYPE_FILTER,
      filterOptions: featureTypeFilterOptions,
      filterType: 'listFilter',
    },
    { columnName: 'lastUpdateTime', key: 'lastUpdateTime', title: lastUpdatedString },
  ];
  const rowHeaders = formatTableHeaders({
    headerItems: columnData,
    resultSize: spatialFeatureTableData.length,
    dispatcher: settingsDispatcher,
    sortAction: sortSpatialFeaturesTable,
    sortObjectSelector: spatialFeaturesSelector,
    translationData: languageSelector.getLanguage(),
    translationModuleName: SPATIAL_FEATURES_MODULE,
    isLoading: isLoadingList,
  });

  const [tableFilterObject, setTableFilterObject] = useState(null);
  const { tableFilter } = useTableFilter({
    tableColumnData: columnData,
    tableData: spatialFeatureTableData,
    dispatcher: settingsDispatcher,
    onFilterAction: getSpatialFeatures,
    isTableLoading: isLoadingList,
    tableFilterObject,
    setTableFilterObject,
  });

  // When selecting features - display geometry on map
  const onSelectRow = (indexes: number[]) => {
    const ids: number[] = [];
    updateSelectedInTable(indexes);
    const geoObject: any = { gate: [], corridor: [], selectionzone: [] };
    const selectedKeys: string[] = [];

    // gets the feature types and sorts them into object with array - to easily retrieve the data

    indexes.forEach((index: number) => {
      const id = getIdFromTableRowKey(tableRowKeys[index]);
      selectedKeys.push(tableRowKeys[index]);
      const selectedFeature = spatialFeatureTableData[index];
      if (indexes.length < MAX_RENDERABLE_FEATURES) {
        if (selectedFeature && selectedFeature.featureType) {
          geoObject[selectedFeature.featureType.toLowerCase()].push(id);
        }
      }
      ids.push(id);
      const selected = selectedInTable;

      selected.push(id);
    });

    updateSelectedTableKeys(selectedKeys);
    updateSelectedInTable(ids);
    selectRow(geoObject, settingsDispatcher);
  };

  // When row is selected only display geometries for selected rows
  useEffect(() => {
    updateDisplayedGates(userSelectedGates.filter(gate => selectedInTable.includes(gate.id)));
    updateDisplayedCorridors(
      userSelectedCorridors.filter(corridor => selectedInTable.includes(corridor.id))
    );
    updateDisplayedZones(userSelectedZones.filter(zone => selectedInTable.includes(zone.id)));
  }, [selectedInTable, userSelectedGates, userSelectedCorridors, userSelectedZones]);

  return (
    <>
      <div className="settings__split-content">
        <div className="spatial-features__heading">
          <PageHeader title={spatialFeaturesString}>
            <SkeletonText loading={isLoadingList && !spatialFeaturesTotalCount} width="4rem">
              <span className="settings__heading-count page-count">
                {`${spatialFeaturesTotalCount} ${totalString}`}
              </span>
            </SkeletonText>
          </PageHeader>
          {canAddFeatures && (
            <Button
              style="primary"
              leftIcon={<Icons iconName={`ic-ui-add`} title={'add'} size={18} />}
              onClick={toggleAddFeature}>
              {addFeatureString}
            </Button>
          )}
        </div>

        <div>
          {tableFilter}
          <Table
            className={`spatial-features-table`}
            loading={isLoadingList}
            rowHeaders={rowHeaders}
            data={spatialFeatureTableData}
            wrapperClassName={'feature-wrapper'}
            columns={columnData.map(column => column.columnName)}
            selectedData={getSelectedIndexesFromKeys(selectedTableKeys, tableRowKeys)}
            gridID="spatial-feature"
            onSelectRow={onSelectRow}
            languageData={{
              noDataTitle: noSpatialFeaturesFound,
              noDataText: tryChangingFilters,
              endTable,
            }}
            hasEnded={spatialFeatureTableData.length && pageInfo && !pageInfo.hasNextPage}
            showDashIfEmpty={false}
          />
          <LoadMoreBar
            isVisible={pageInfo && pageInfo.hasNextPage}
            isLoadingMore={isLoadingMore}
            loadMore={loadMoreSpatialFeatures}
            dispatcher={settingsDispatcher}
            sortString={getSortString(sortObject)}
            filterString={!!tableFilterObject ? formatFilterString(tableFilterObject) : ''}
            resultSize={features.length}
            endCursor={pageInfo && pageInfo.endCursor}
            loadMoreText={loadMoreText}
          />
        </div>
      </div>
      <div className="settings__split-map">
        <ReviewFeaturesMap
          gates={displayedGates}
          corridors={displayedCorridors}
          selectionZones={displayedZones}
        />
      </div>
    </>
  );
};
