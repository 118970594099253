import { actionTypes } from 'src/@settings/actionTypes';
import ApolloClient from 'apollo-client';
import {
  fetchAllZones,
  fetchAllCorridors,
  fetchOperationData,
  fetchAllGates,
  fetchSpatialFeatures,
} from '../resolvers';
import { ISpatialFeature, IPageOptions } from '../interfaces';

import {
  IGetSelectedCorridorDetails,
  IGetSelectedGateDetails,
  IGetSelectedZoneDetails,
  ILoadMoreSpatialFeatures,
  ILoadMoreSpatialFeaturesSuccess,
  INewFeatureAdded,
  ISpatialFeaturesFetch,
  ISpatialFeaturesLoading,
  ISpatialFeatureUpdated,
  IGetAllGates,
  IGetAllZones,
  IGetAllCorridors,
  TFeatureUpdated,
  IGetOperations,
  IAddNewlyLoadedFeature,
  IResetOperations,
  ISortSpatialFeatures,
} from './interfaces';
import { IFeatureFlags } from 'src/app/props';
import {
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
  TSpatialFeature,
} from 'src/utils/spatialFeatureHelpers/interfaces';

import { ISortActionData } from 'src/components';

// Gets all the spatial feature data
export const getSpatialFeatures = ({
  client,
  dispatcher,
  filterString = '',
  abortControllerSignal,
}: {
  client: ApolloClient<object>;
  dispatcher: React.Dispatch<ISpatialFeaturesFetch | ISpatialFeaturesLoading>;
  filterString?: string;
  abortControllerSignal?: AbortSignal;
}) => {
  dispatcher({ type: actionTypes.SPATIAL_FEATURES_LOADING });
  fetchSpatialFeatures(client, filterString, { abortControllerSignal })
    .then((data: any) => {
      dispatcher({
        type: actionTypes.GET_SPATIAL_FEATURES,
        payload: {
          spatialFeatures: data.items,
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        },
      });
      dispatcher({
        type: actionTypes.SPATIAL_FEATURES_LOADED,
      });
    })
    .catch(error => {
      console.error(error);
      dispatcher({
        type: actionTypes.SPATIAL_FEATURES_LOADED,
      });
    });
};

// When row selected - if geometry data already not stored in state fetch geometry data
export const selectRow = (
  data: { gate: number[]; selectionzone: []; corridor: [] },
  dispatcher: React.Dispatch<
    IGetSelectedCorridorDetails | IGetSelectedGateDetails | IGetSelectedZoneDetails
  >
) => {
  // Checks to see if spatial feature geometry is already stored
  if (data.gate.length > 0) {
    dispatcher({ type: actionTypes.GET_SELECTED_GATE_DETAILS, payload: { gateIds: data.gate } });
  }
  if (data.selectionzone.length > 0) {
    dispatcher({
      type: actionTypes.GET_SELECTED_ZONE_DETAILS,
      payload: { zoneIds: data.selectionzone },
    });
  }
  if (data.corridor.length > 0) {
    dispatcher({
      type: actionTypes.GET_SELECTED_CORRIDOR_DETAILS,
      payload: { corridorIds: data.corridor },
    });
  }
};

// Loads more spatial features
export const loadMoreSpatialFeatures = (
  client: ApolloClient<object>,
  dispatcher: React.Dispatch<ILoadMoreSpatialFeatures | ILoadMoreSpatialFeaturesSuccess>,
  options: {
    resultSize: number;
    endCursor: string;
    sortString: string;
    filterString: string;
  }
) => {
  dispatcher({ type: actionTypes.LOAD_MORE_SPATIAL_FEATURES });
  const pageOptions: IPageOptions = {
    endCursor: options.endCursor,
    sortString: options.sortString,
  };
  fetchSpatialFeatures(client, options.filterString, { pageOptions })
    .then((response: any) => {
      dispatcher({
        type: actionTypes.LOAD_MORE_SPATIAL_FEATURES_SUCCESS,
        payload: {
          spatialFeatures: response.items,
          pageInfo: response.pageInfo,
          totalCount: response.totalCount,
        },
      });
    })
    .catch((error: any) => {
      throw error;
    });
};

// Sorts the spatial features table
export const newFeatureAdded = (
  newFeature: ISpatialFeature,
  dispatcher: React.Dispatch<INewFeatureAdded>
) => {
  dispatcher({ type: actionTypes.NEW_FEATURE_ADDED, payload: { newFeature } });
};

// Sorts the spatial features table
export const spatialFeatureUpdated = (
  updatedFeature: ISpatialFeature,
  updatedData: IGate | INoiseAbatementCorridor | ISelectionZone,
  dispatcher: React.Dispatch<ISpatialFeatureUpdated | TFeatureUpdated>
) => {
  dispatcher({ type: actionTypes.FEATURE_UPDATED, payload: { updatedFeature } });

  switch (updatedFeature.featureType) {
    case 'Gate':
      dispatcher({ type: actionTypes.GATE_UPDATED, payload: { gate: updatedData as IGate } });
      break;
    case 'Corridor':
      dispatcher({
        type: actionTypes.CORRIDOR_UPDATED,
        payload: { corridor: updatedData as INoiseAbatementCorridor },
      });
      break;
    case 'SelectionZone':
      dispatcher({
        type: actionTypes.ZONE_UPDATED,
        payload: { zone: updatedData as ISelectionZone },
      });
      break;
  }
};

export const getAllGates = (
  client: ApolloClient<object>,
  dispatcher: React.Dispatch<IGetAllGates>
) => {
  fetchAllGates(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_ALL_GATES, payload: { gates: data.gates } });
    })
    .catch(error => {
      console.error(error);
    });
};

export const getAllCorridors = (
  client: ApolloClient<object>,
  dispatcher: React.Dispatch<IGetAllCorridors>
) => {
  fetchAllCorridors(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_ALL_CORRIDORS, payload: { corridors: data.corridors } });
    })
    .catch(error => {
      console.error(error);
    });
};

export const getAllZones = (
  client: ApolloClient<object>,
  dispatcher: React.Dispatch<IGetAllZones>
) => {
  fetchAllZones(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_ALL_ZONES, payload: { zones: data.selectionZones } });
    })
    .catch(error => {
      console.error(error);
    });
};

export const addNewlyLoadedFeature = (
  featureData: TSpatialFeature,
  featureType: string,
  dispatcher: React.Dispatch<IAddNewlyLoadedFeature>
) => {
  dispatcher({ type: actionTypes.ADD_NEWLY_LOADED_FEATURE, payload: { featureData, featureType } });
};

export const fetchOperations = (
  client: ApolloClient<object>,
  resultSize: number,
  dispatcher: React.Dispatch<IGetOperations | IResetOperations>,
  sortString: string,
  filterString: string,
  featureFlags: IFeatureFlags,
  availableFilters: { operationTags: string[] },
  dateFrom: Date,
  dateTo: Date
) => {
  // Clear operations from redux state
  dispatcher({
    type: actionTypes.RESET_OPERATIONS,
  });
  fetchOperationData({
    client,
    count: resultSize,
    sortString,
    filterString,
    featureFlags,
    availableFilters,
    dateFrom,
    dateTo,
  })
    .then((data: any) => {
      dispatcher({
        type: actionTypes.GET_OPERATIONS,
        payload: {
          operations: data.data,
          pageInfo: data.pageInfo,
          datesSelected: { from: dateFrom, to: dateTo },
        },
      });
    })
    .catch(() => {
      dispatcher({
        type: actionTypes.GET_OPERATIONS,
        payload: {
          operations: [],
          pageInfo: '',
          datesSelected: { from: dateFrom, to: dateTo },
        },
      });
    });
};

export const resetOperations = (dispatcher: React.Dispatch<IResetOperations>) => {
  dispatcher({
    type: actionTypes.RESET_OPERATIONS,
  });
};

// Sorts the spatial features table
// SPATIAL FEATURE DATA
export const sortSpatialFeaturesTable = async (
  data: ISortActionData,
  dispatcher: React.Dispatch<ISortSpatialFeatures>
) => {
  await dispatcher({
    type: actionTypes.SORT_SPATIAL_FEATURES,
    payload: { sortName: data.sortName },
  });
};
