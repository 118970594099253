import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { AudioPlayer } from '@ems/client-design-system';
import { useLanguageSelectors } from 'src/app/reducers';

export const AudioPlayback = ({ audio, isPlaying, currentTime, playbackSpeed }: any) => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      errors: { audioLoadError: loadError, audioDecodeError: decodeError },
    },
  } = languageSelectors.getLanguage();

  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const languageData = useMemo(
    () => ({
      loadError,
      decodeError,
    }),
    [loadError, decodeError]
  );
  const audioArray = useMemo(() => {
    if (Array.isArray(audio)) {
      const allAudio = audio.filter(data => data && data.resourceUri && data.resourceUri.uri);

      let earliest: string | null = null;
      let latest: string | null = null;

      allAudio.map(audio => {
        if (earliest === null || audio.startTime < earliest) {
          earliest = audio.startTime;
        }

        if (latest === null || audio.endTime > latest) {
          latest = audio.endTime;
        }
      });

      return allAudio;
    }

    // TODO: single audio, to be deprecated
    if (audio && audio.resourceUri && audio.resourceUri.uri) {
      setStart(audio.startTime);
      setEnd(audio.endTime);
      return [audio];
    }
    return [];
  }, [audio]);

  if (audioArray.length) {
    const convertedStartTime = DateTime.fromISO(start).toSeconds();
    const convertedEndTime = DateTime.fromISO(end).toSeconds();

    return (
      <AudioPlayer
        noiseEvents={audioArray}
        startTime={convertedStartTime}
        endTime={convertedEndTime}
        isPlaying={isPlaying}
        position={currentTime}
        playbackSpeed={playbackSpeed}
        languageData={languageData}
      />
    );
  }

  return null;
};
