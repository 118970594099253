import gql from 'graphql-tag';

export const UPDATE_LOAD_FACTORS = gql`
  mutation updateAirlineLoadFactor($airlineLoadFactor: [BulkAirlineLoadFactorsChangeInput], $scenarioId: Int , $startTime: DateTimeOffset, $endTime: DateTimeOffset) {
    updateAirlineLoadFactor(airlineLoadFactor: $airlineLoadFactor) {
      id
      airline 
      aircraftType
      origin
      destination
      startTime
      endTime
      dayOfWeek
      loadFactor
      scenarioId
    }
    carbonEmissionsRequestReprocessing(scenarioId: $scenarioId, startTime: $startTime, endTime: $endTime)
  }
`;
