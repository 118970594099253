import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';
import { hexToRgba } from 'src/utils';

export const MappingProportionWrapper = styled.div`
  padding-bottom: 2.5rem;
  margin: 2.5rem;
  border-bottom: 1px solid ${sassVars.ui03};
`;

export const MappingProportionDescription = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const MappingProportion = styled.span`
  font-size: 14px;
  display: flex;
  gap: 24px;
`;

export const MappingTableCell = styled.div`
  width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MappingTableWrapper = styled.div`
  .table-row_item.invalid_row td {
    background: ${hexToRgba(sassVars.error01, 0.5)};
  }
`;
