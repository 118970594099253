import React, { useState } from 'react';
// Components
import { Table, SkeletonText } from '@ems/client-design-system';

import { ITableColumnData, LoadMoreBar, PageHeader } from 'src/components';
// Selectors
import { useLanguageSelectors } from 'src/app/reducers';
// functions
import { getSelectedIndexesFromKeys } from 'src/utils';

// constants
import { LocationManagementMap } from '../Map/LocationManagementMap';

import { ASC, DESC, LOCATION_MANAGEMENT_MODULE } from 'src/constants';
import {
  SettingsHeading,
  SplitContent,
  SplitMapContainer,
} from 'src/@settings/components/Settings.styles';
import { useFetchNMTData } from './useFetchNmtData';
import { useNmtTableRowData } from './useNmtTableRowData';
import { NMTDataQueryVariables } from '../interfaces';
import { DEFAULT_SORT_ORDER, NMT_LIST_PAGE_SIZE } from '../constants';
import { useTableColumnHeaders } from 'src/components/TableComponents/TableColumnHeader/useTableColumnHeaders';
import { ISortActionCallback } from 'src/components/TableComponents/TableColumnHeader/interfaces';
import { LocationManagementProvider } from '../Context/LocationManagementContext';

export const LocationManagementList = () => {
  return (
    <div className="settings__split locationmanagement-container">
      <LocationManagementProvider>
        <LocationManagementListContent />
      </LocationManagementProvider>
    </div>
  );
};
export const LocationManagementListContent = () => {
  // Selectors
  const languageSelector = useLanguageSelectors();
  const {
    fields: {
      locationManagement: { name, location, nmtModel, active, lastData },
    },
    components: {
      buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
        locationManagement,
      },
      hints: { tryChangingFilters },
    },
    screens: {
      settings: {
        notFound: {
          table: { noNmtsFound },
        },
      },
    },
  } = languageSelector.getLanguage();

  // Table set up
  const columnData: ITableColumnData[] = [
    {
      columnName: 'displayedName',
      key: 'deviceName',
      title: name,
    },
    {
      columnName: 'displayedLocationDescription',
      key: 'locationId',
      title: location,
    },
    {
      columnName: 'deviceType',
      key: 'deviceType',
      title: nmtModel,
    },
    {
      columnName: 'lastDataValue',
      key: 'lastData',
      title: lastData,
    },
    {
      columnName: 'isActiveCheckMark',
      key: 'isActive',
      title: active,
    },
  ];

  const locationManagementTableColumns = columnData.map(column => column.columnName);
  const [queryVariables, setQueryVariables] = useState<NMTDataQueryVariables>({
    sort: [DEFAULT_SORT_ORDER],
    count: NMT_LIST_PAGE_SIZE,
  });

  const { nmtData, pageInfo, isLoading, count, loadMore } = useFetchNMTData({ queryVariables });
  const { tableRowData, tableRowKeys } = useNmtTableRowData(nmtData);

  // Row selection
  const [selectedTableKeys, updateSelectedTableKeys] = useState<string[]>([]);
  const [selectedNmtIds, setNmtsDisplayedOnMap] = useState<number[]>([]);
  const onSelectRow = (indexes: number[]) => {
    const selectedNmts = tableRowData.flatMap((nmt, index) =>
      indexes.includes(index) ? nmt.deviceId : []
    );
    setNmtsDisplayedOnMap(selectedNmts);
    updateSelectedTableKeys(indexes.map(index => tableRowKeys[index]));
  };

  // Actions
  const sortAction = ({ sortName, sortObject: { field, direction } }: ISortActionCallback) => {
    const sortDirection = field === sortName ? (direction === 'ASC' ? DESC : ASC) : DESC;
    onSelectRow([]);
    setQueryVariables({
      ...queryVariables,
      sort: [
        {
          field: sortName,
          direction: sortDirection,
        },
      ],
    });
  };
  const getSort = () => queryVariables.sort[0];

  const rowHeaders = useTableColumnHeaders({
    columnData,
    sortAction,
    sortObjectSelector: {
      getSortObject: () => getSort(),
    },
    translationModuleName: LOCATION_MANAGEMENT_MODULE,
    isLoading,
  });

  return (
    <>
      <SplitContent>
        <SettingsHeading>
          <PageHeader title={locationManagement}>
            <SkeletonText loading={isLoading} width="4rem">
              <span className="settings__heading-count page-count">{count} Total</span>
            </SkeletonText>
          </PageHeader>
        </SettingsHeading>
        <Table
          className={`locationmanagement-table`}
          wrapperClassName={'locationmanagement-table-wrapper'}
          loading={isLoading}
          rowHeaders={rowHeaders}
          data={tableRowData}
          columns={locationManagementTableColumns}
          selectedData={getSelectedIndexesFromKeys(selectedTableKeys, tableRowKeys)}
          areAllRowsSelected={false}
          gridID="locationmanagement-table"
          onSelectRow={onSelectRow}
          hasEnded={!!tableRowData.length && pageInfo?.hasNextPage === false}
          languageData={{
            noDataTitle: noNmtsFound,
            noDataText: tryChangingFilters,
            endTable,
          }}
          showDashIfEmpty={false}
        />
        <LoadMoreBar
          isVisible={pageInfo?.hasNextPage ?? false}
          isLoadingMore={tableRowData.length && isLoading}
          loadMore={loadMore}
          loadMoreText={loadMoreText}
          resultSize={tableRowData.length}
          endCursor={pageInfo?.hasNextPage && pageInfo?.endCursor}
          dispatcher={null}
          sortString={null}
        />
      </SplitContent>
      <SplitMapContainer>
        <LocationManagementMap selectedNmtIds={selectedNmtIds} />
      </SplitMapContainer>
    </>
  );
};
