import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, Source, useMap } from 'react-map-gl';
import { InteractiveLayerId, useInteractiveLayerIds } from '../../hooks/useInteractiveLayerIds';
import { useDraggableLayer } from '../../hooks/useDraggableLayer';
import { getQueriedFeaturesById } from '../../helpers/getQueriedFeaturesById';
import { useBeforeId } from '../../hooks/useBeforeId';
import { useLineLayerDataPoints } from './useLineLayerDataPoints';
import { FeatureCollection, LineString, Point, Position } from 'geojson';
import { MapEventHandler, useMapEvent } from '../../hooks/useMapEvent';
import { featureCollection, lineString, point } from '@turf/turf';
import { useTaxiPathContext } from 'src/@settings/containers/TaxiPath/context/TaxiPathContext';
import {
  MapboxStylePathSegment,
  MapboxStylePathMidpoint,
  MapboxStylePathPoint,
} from 'src/@settings/containers/TaxiPath/TaxiPath.styles';

interface InteractiveLineLayerProps {
  lineFeatures: LineSegments;
  pointStyles;
  lineStyles;
  onLineUpdate?: (updatedLine: LineSegments) => void;
  lineConfig?: LineConfig;
  handleClick?: (e: MapLayerMouseEvent) => void;
}

interface LineConfig {
  draggable?: boolean;
  followCursor?: boolean;
  addPoints?: boolean;
}

export type LinePoints = FeatureCollection<Point>;
export type LineSegments = FeatureCollection<LineString>;
export type MovedPoint = { id: string; coords: Position };
const INTERACTIVE_LAYER_IDS = [
  InteractiveLayerId.drawableLinePoint,
  InteractiveLayerId.drawableLineSegment,
];

const DEFAULT_LINE_CONFIG = {
  draggable: false,
  followCursor: false,
  addPoints: false,
};
export const InteractiveLineLayer = ({
  lineFeatures,
  pointStyles,
  lineStyles,
  onLineUpdate,
  lineConfig = DEFAULT_LINE_CONFIG,
  handleClick,
}: InteractiveLineLayerProps) => {
  const {
    linePoints,
    lineSegments,
    lineMidPoints,
    lineCreationPoints,
    updateLineFeatures,
  } = useLineData(lineFeatures);
  const { draggable, followCursor, addPoints } = lineConfig;

  return (
    <>
      {draggable && (
        <DraggableLineControl
          linePoints={linePoints}
          lineSegments={lineSegments}
          updateLineFeatures={updateLineFeatures}
          pointStyles={pointStyles}
          onLineUpdate={onLineUpdate}
        />
      )}
      {followCursor && (
        <FollowCursorControl
          linePoints={lineCreationPoints}
          lineSegments={lineSegments}
          updateLineFeatures={updateLineFeatures}
          handleClick={handleClick}
          onLineUpdate={onLineUpdate}
        />
      )}
      {addPoints && (
        <AddPointsControl
          linePoints={linePoints}
          updateLineFeatures={updateLineFeatures}
          onLineUpdate={onLineUpdate}
        />
      )}
      <LineSourceAndLayer
        linePoints={linePoints}
        lineSegments={lineSegments}
        pointStyles={pointStyles}
        lineStyles={lineStyles}
        midPoints={lineMidPoints}
      />
    </>
  );
};

const DraggableLineControl = ({
  linePoints,
  lineSegments,
  updateLineFeatures,
  onLineUpdate,
  pointStyles,
}) => {
  useDraggablePoints(linePoints, lineSegments, pointStyles, updateLineFeatures, onLineUpdate);
  return null;
};

const FollowCursorControl = ({
  linePoints,
  lineSegments,
  handleClick,
  updateLineFeatures,
  onLineUpdate,
}) => {
  useFollowCursorLine(linePoints, lineSegments, updateLineFeatures, handleClick, onLineUpdate);
  return null;
};

const AddPointsControl = ({ linePoints, updateLineFeatures, onLineUpdate }) => {
  useAddPointOnLineClick(linePoints, updateLineFeatures, onLineUpdate);
  return null;
};

const LineSourceAndLayer = ({ linePoints, lineSegments, pointStyles, lineStyles, midPoints }) => {
  const beforeId = useBeforeId([
    InteractiveLayerId.drawableLinePoint,
    InteractiveLayerId.drawablePoint,
  ]);
  useInteractiveLayerIds(INTERACTIVE_LAYER_IDS);
  return (
    <>
      <Source
        type="geojson"
        data={{ ...linePoints }}
        id={`${InteractiveLayerId.drawableLinePoint}-source`}
        generateId>
        <Layer {...MapboxStylePathPoint} />
      </Source>

      <Source
        type="geojson"
        data={{ ...lineSegments }}
        id={`${InteractiveLayerId.drawableLineSegment}-source`}
        generateId>
        <Layer {...MapboxStylePathSegment} beforeId={beforeId as string} />
      </Source>
      <Source
        type="geojson"
        data={{ ...midPoints }}
        id={`${InteractiveLayerId.drawableLineSegmentMidpoint}-source`}
        generateId>
        <Layer {...MapboxStylePathMidpoint} />
      </Source>
    </>
  );
};

export const useLineData = (lineFeatures: LineSegments) => {
  const { segments, points, creationPoints, midPoints } = useLineLayerDataPoints(lineFeatures);
  const [linePoints, setPoints] = useState<LinePoints>(points);
  const [lineMidPoints, setLineMidPoints] = useState<LinePoints>(midPoints);
  const [lineCreationPoints, setLineCreationPoints] = useState<LinePoints>(creationPoints);
  const [lineSegments, setSegments] = useState<LineSegments>(segments);

  useEffect(() => {
    setSegments(segments);
    setPoints(points);
    setLineMidPoints(midPoints);
    setLineCreationPoints(creationPoints);
  }, [points, segments, midPoints, creationPoints]);

  const updateLineFeatures = useCallback(
    (points: LinePoints, segments: LineSegments) => {
      setSegments(segments);
      setPoints(points);
      setLineMidPoints(midPoints);
      setLineCreationPoints(creationPoints);
    },
    [setSegments, setPoints, setLineMidPoints, setLineCreationPoints]
  );

  return {
    linePoints,
    lineSegments,
    lineMidPoints,
    lineCreationPoints,
    updateLineFeatures,
  };
};

export const useDraggablePoints = (
  linePoints: LinePoints,
  lineSegments: LineSegments,
  pointStyles,
  updateLineFeatures: (points: LinePoints, segments: LineSegments) => void,
  onLineUpdate: (updatedLine: LineSegments) => void
) => {
  const { current: map } = useMap();
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null);
  const onLayerMouseDown = useCallback(
    ({ target, point }: MapLayerMouseEvent) => {
      const features = getQueriedFeaturesById(target, point, InteractiveLayerId.drawableLinePoint);
      const pointId = features.length ? features[0].properties.id : null;
      setSelectedPointId(pointId);
    },
    [setSelectedPointId]
  );

  const { setMovedPoint } = useOnPointMoved(linePoints, lineSegments, updateLineFeatures);

  const onDragMove = useCallback(
    ({ lngLat: { lng, lat } }: MapLayerMouseEvent) => {
      if (selectedPointId) {
        setMovedPoint({ id: selectedPointId, coords: [lng, lat] });
      } else {
        setMovedPoint(null);
      }
      return;
    },
    [setMovedPoint, selectedPointId]
  );

  const onLayerOver = useCallback(
    (featureId, currentMap) => {
      map.setFeatureState(
        { source: `${InteractiveLayerId.drawableLinePoint}-source`, id: featureId },
        { hover: true }
      );
    },
    [pointStyles]
  );

  const [dragEndEvent, toggleDragEndEvent] = useState<boolean>(false);
  useEffect(() => {
    if (dragEndEvent && onLineUpdate && selectedPointId === null) {
      onLineUpdate(lineSegments);
      toggleDragEndEvent(false);
    }
  }, [lineSegments, linePoints, dragEndEvent, onLineUpdate, selectedPointId]);

  const onDragEnd = useCallback(() => {
    if (selectedPointId) {
      toggleDragEndEvent(true);
      setSelectedPointId(null);
    }
  }, [toggleDragEndEvent, setSelectedPointId, selectedPointId]);

  const onLayerMouseOut = useCallback(
    currentMap => {
      const layerFeatures = map.querySourceFeatures(
        `${InteractiveLayerId.drawableLinePoint}-source`
      );

      layerFeatures.forEach(layerFeature => {
        const featureInfo = {
          source: `${InteractiveLayerId.drawableLinePoint}-source`,
          id: layerFeature.id,
        };
        const existing = map.getFeatureState(featureInfo);

        if (existing.hasOwnProperty('hover')) {
          map.removeFeatureState(featureInfo, 'hover');
        }
      });
    },
    [pointStyles]
  );

  useDraggableLayer(
    InteractiveLayerId.drawableLinePoint,
    onDragMove,
    onDragEnd,
    onLayerOver,
    onLayerMouseDown,
    onLayerMouseOut
  );
};

const useFollowCursorLine = (
  linePoints: LinePoints,
  lineSegments: LineSegments,
  updateLineFeatures: (points: LinePoints, segments: LineSegments) => void,
  handleClick: (e: MapLayerMouseEvent) => void,
  onLineUpdate: (updatedLine: LineSegments) => void
) => {
  const selectedPointId = useMemo(() => linePoints?.features?.length ?? null, [linePoints]);
  const { setMovedPoint, movedPoint } = useOnPointMoved(
    linePoints,
    lineSegments,
    updateLineFeatures
  );

  useEffect(() => {
    if (lineSegments.features.length && movedPoint !== null) {
      const lineCoords = lineSegments.features[0].geometry.coordinates;
      lineCoords[1] = movedPoint.coords;
      const updatedLineString = lineString(lineCoords);
      const updatedLine = featureCollection([updatedLineString]);
      onLineUpdate(updatedLine);
    }
  }, [lineSegments, movedPoint, onLineUpdate]);

  const onMove = useCallback(
    ({ lngLat: { lng, lat } }: MapLayerMouseEvent) => {
      if (!!selectedPointId) {
        setMovedPoint({ id: String(selectedPointId), coords: [lng, lat] });
      } else {
        setMovedPoint(null);
      }
      return;
    },
    [setMovedPoint, onLineUpdate, selectedPointId]
  );
  const onClickHandler = useCallback(
    e => {
      if (handleClick) {
        handleClick(e);
      } else {
        console.warn('no click handler configured');
      }
    },
    [handleClick]
  );

  useMapEvent('click', onClickHandler);
  useMapEvent('mousemove', onMove as MapEventHandler<'mousemove'>);
};

const useOnPointMoved = (
  linePoints: LinePoints,
  lineSegments: LineSegments,
  updateLineFeatures: (points: LinePoints, segments: LineSegments) => void
): {
  movedPoint: MovedPoint;
  setMovedPoint: React.Dispatch<React.SetStateAction<MovedPoint>>;
} => {
  const [movedPoint, setMovedPoint] = useState<null | MovedPoint>(null);
  const updateSegmentsAndPoints = useCallback(
    (movedPoint: MovedPoint) => {
      if (movedPoint) {
        const updatedPoints = linePoints.features.map(point =>
          point.properties.id === movedPoint.id
            ? { ...point, geometry: { ...point.geometry, coordinates: movedPoint.coords } }
            : point
        );
        const updatedLinePoints = featureCollection(updatedPoints);

        const updatedSegments = lineSegments.features.map(segment => {
          const { lineId, lineCoordIndex } = getIdsFromPointId(movedPoint.id);
          if (lineId === segment.properties.id) {
            return segment;
          } else {
            const coordinates = segment.geometry.coordinates.map((coordinates, index) =>
              String(index) === lineCoordIndex ? movedPoint.coords : coordinates
            );
            return { ...segment, geometry: { ...segment.geometry, coordinates } };
          }
        });
        const updatedLineSegments = featureCollection(updatedSegments);
        updateLineFeatures(updatedLinePoints, updatedLineSegments);
      }
    },
    [linePoints, lineSegments, updateLineFeatures]
  );

  useEffect(() => {
    if (movedPoint) {
      updateSegmentsAndPoints(movedPoint);
    }
  }, [movedPoint]);

  return { movedPoint, setMovedPoint };
};

const getIdsFromPointId = (pointId: string) => {
  const lineId = pointId.split('-')[0];
  const lineCoordIndex = pointId.split('-')[1];
  return { lineId, lineCoordIndex };
};

const useAddPointOnLineClick = (
  points: LinePoints,
  updateLineFeatures: (points: LinePoints, segments: LineSegments) => void,
  onLineUpdate: (lineSegment: LineSegments) => void
) => {
  const {
    state: { pathGeoJSON },
  } = useTaxiPathContext();

  const addPoint = useCallback(
    (
      properties: { PathwayId: string; beforeIndex: string; afterIndex: string },
      addedPoint: Position
    ) => {
      const { PathwayId, afterIndex } = properties;
      const updatedFeature = pathGeoJSON.features.find(
        path => path.properties.PathwayId === PathwayId
      );
      updatedFeature.geometry.coordinates.splice(Number(afterIndex) + 1, 0, addedPoint);

      const updatedLineFeature = featureCollection<GeoJSON.LineString>([updatedFeature]);
      const existingPointCoordinates =
        points.features.map(({ geometry }) => geometry.coordinates) || [];

      const updatedPoints = existingPointCoordinates.map((coord, index) => {
        const newPoint = point(coord);
        newPoint.properties.id = `${PathwayId}-${index}`;
        return newPoint;
      });
      const updatedPointFeature = featureCollection(updatedPoints);
      updateLineFeatures(updatedPointFeature, updatedLineFeature);

      // Will cause lag on large maps, to dig in more
      onLineUpdate(updatedLineFeature);
    },
    [points, updateLineFeatures, onLineUpdate]
  );
  const onPointClick = useCallback(({ target, point, lngLat: { lng, lat } }) => {
    const lineLayerFeature = getQueriedFeaturesById(
      target,
      point,
      InteractiveLayerId.drawableLineSegmentMidpoint
    );
    if (lineLayerFeature?.length) {
      addPoint(lineLayerFeature[0].properties, [lng, lat]);
    }
  }, []);
  useMapEvent('click', onPointClick);
};
