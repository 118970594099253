export const MAP_ACCESS_TOKEN =
  'pk.eyJ1IjoiamFtZXMtZW1zIiwiYSI6ImNqbDI3bDJhYzFuYnUza3F0eWNlZDAydjYifQ.RFbQDC7kOaW0NBLl9zD4FQ';
export const MAP_STYLE_URL = 'mapbox://styles/james-ems/cjsvs7gth4k3s1fpm3y7qzevk';
export const MAP_HEIGHT = '28rem';
export const MAP_WIDTH = '100%';

export const MAP_BACKGROUND_TYPES = {
  SATELLITE: 'SATELLITE',
  GREY: 'GREY',
  STREET: 'STREET',
};

export const V2_MAPBOX_SETTINGS_MAPS = {
  TAXI_PATH: 'TAXI_PATH',
};
