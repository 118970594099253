import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useLocationManagementContext } from '../../Context/LocationManagementContext';
import { LocationManagementActions } from '../../Context/LoctionManagementReducer';
import {
  IGetMonitorLocationData,
  IGetMonitorLocationVariables,
  IMonitorLocation,
} from '../../interfaces';
import { GET_MONITOR_LOCATION } from '../../queries';

export const useDeviceMonitorLocation = (locationId?: number | null) => {
  const {
    dispatch,
    state: { deviceMonitorLocation },
  } = useLocationManagementContext();

  const { loading: isLoadingDeviceMonitorLocation, data: deviceMonitorLocationData } = useQuery<
    IGetMonitorLocationData,
    IGetMonitorLocationVariables
  >(GET_MONITOR_LOCATION, {
    variables: {
      id: locationId,
    },
    skip: locationId === null || locationId === undefined || !!deviceMonitorLocation,
  });

  const updateMonitorLocation = (deviceMonitorLocation: IMonitorLocation) => {
    dispatch({
      type: LocationManagementActions.SET_DEVICE_LOCATION,
      payload: { deviceMonitorLocation },
    });
  };

  useEffect(() => {
    if (deviceMonitorLocationData) {
      updateMonitorLocation(deviceMonitorLocationData.monitorLocation);
    }
  }, [deviceMonitorLocationData]);

  return {
    isLoadingDeviceMonitorLocation,
    deviceMonitorLocation,
    updateMonitorLocation,
  };
};
