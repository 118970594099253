import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';

// Components
import { Dialog, Button } from '@ems/client-design-system';
import { NoiseScenarioDialog } from './Dialog.styles';

// Contexts
import {
  dialogsStatusContext,
  noiseScenarioRecordsContext,
  scenarioFilterDataContext,
  translationsContext,
} from 'src/@scenarioGeneration/containers/ViewScenario/contexts';

// Functions
import { modifyRecordsFormikOptions, ModifyRecordsDialogForm } from './helpers';
import { validateScenarioRecord } from 'src/@scenarioGeneration/containers/ViewScenario/helpers';
import { deepCopyObject } from 'src/utils';

// Types
import { IUpdateNoiseScenarioRecordsMutationVariables } from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';
import {
  IAddRecordFormErrors,
  IAddRecordFormFields,
} from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/interfaces';

interface BulkEditDialogProps {
  updateRecordsMutation: (options?: {
    variables: IUpdateNoiseScenarioRecordsMutationVariables;
  }) => void;
  isRecordMutationLoading: boolean;
}

export const BulkEditDialog = ({
  updateRecordsMutation,
  isRecordMutationLoading,
}: BulkEditDialogProps) => {
  // Contexts
  const { isBulkEditOpen, setIsBulkEditOpen } = useContext(dialogsStatusContext);
  const {
    noiseScenarioRecords,
    selectedInTable,
    updateSelectedInTable,
    updateSelectedTableKeys,
  } = useContext(noiseScenarioRecordsContext);
  const { scenarioFilterData } = useContext(scenarioFilterDataContext);
  const translations = useContext(translationsContext);

  if (!noiseScenarioRecords) {return null;}

  const selectedRecordList = noiseScenarioRecords.filter(({ node }) =>
    selectedInTable.includes(node.id)
  );
  const selectedRecordIdList = selectedRecordList.reduce((arr, item) => [...arr, item.node.id], []);
  const [recordsCount] = useState(selectedRecordList.length);

  let initialValues: IAddRecordFormFields = {
    dayCount: '',
    eveningCount: '',
    nightCount: '',
  };

  if (selectedRecordList.length === 1) {
    const nodeCopy = deepCopyObject(selectedRecordList[0].node);
    initialValues = {
      dayCount: `${nodeCopy.dayCount}`,
      eveningCount: `${nodeCopy.eveningCount}`,
      nightCount: `${nodeCopy.nightCount}`,
      modelingAircraftId: nodeCopy.modelingAircraftId,
      stageLength: nodeCopy.stageLength,
      modelingRouteId: `${nodeCopy.modelingRouteId}`,
    };
  }

  const bulkEditFormikOptions = modifyRecordsFormikOptions({
    initialValues,
    validation: values => {
      const scenarioErrors: IAddRecordFormErrors = {};
      const validFormKeys = Object.keys(values).filter(key => !!values[key]);
      const updatedNodes = selectedRecordList.map(({ node }) => {
        const nodeCopy = deepCopyObject(node);
        validFormKeys.map(key => {
          nodeCopy[key] = values[key];
        });
        return nodeCopy;
      });

      updatedNodes.forEach(node => {
        validateScenarioRecord({
          record: node,
          filterData: scenarioFilterData,
          translations,
        }).catch(errors => {
          Object.keys(errors).map(key => {
            scenarioErrors[key] = errors[key];
          });
        });
      });
      return scenarioErrors;
    },
    onSubmitAction: values => {
      const variableList = {};
      Object.keys(values).forEach(key => {
        if (!!values[key]) {
          if (['dayCount', 'eveningCount', 'nightCount', 'modelingRouteId'].includes(key)) {
            variableList[key] = Number(values[key]);
          } else {
            variableList[key] = values[key];
          }
        }
      });

      if (Object.keys(variableList).length) {
        const haveRecordsChanged = selectedRecordIdList.some(selectedId => {
          const { node } = noiseScenarioRecords.find(({ node }) => node.id === selectedId);
          const updatedRecordNode = {
            id: selectedId,
            ...node,
            ...variableList,
          };

          return JSON.stringify(node) !== JSON.stringify(updatedRecordNode);
        });

        if (haveRecordsChanged) {
          updateSelectedInTable([]);
          updateSelectedTableKeys([]);
          updateRecordsMutation({
            variables: {
              ids: selectedRecordIdList,
              ...variableList,
            },
          });
        } else {
          setIsBulkEditOpen(false);
        }
      } else {
        setIsBulkEditOpen(false);
      }
    },
  });

  const bulkEditFormik = useFormik({ ...bulkEditFormikOptions });
  return (
    <NoiseScenarioDialog
      isOpen={isBulkEditOpen}
      title={translations.headings.bulkEditScenarioRecordsHeading.replace(
        '{count}',
        `${recordsCount}`
      )}
      isCloseButtonShown
      onClose={() => {
        setIsBulkEditOpen(false);
      }}>
      <Dialog.Body>
        <ModifyRecordsDialogForm
          formik={bulkEditFormik}
          scenarioFilterData={scenarioFilterData}
          translations={translations}
          isMultiple
        />
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.FooterActions>
          <Button
            onClick={() => {
              setIsBulkEditOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            style="primary"
            loading={isRecordMutationLoading}
            disabled={!bulkEditFormik.isValid || isRecordMutationLoading}
            onClick={() => {
              bulkEditFormik.submitForm();
            }}>
            Update
          </Button>
        </Dialog.FooterActions>
      </Dialog.Footer>
    </NoiseScenarioDialog>
  );
};
