import { linearInterpolateValue } from 'src/utils';

export const convertXYTPointstoArray = (points: any) => {
  const converted: any = [];
  const maxTime = points[points.length - 1].t;
  let counter = 0;

  for (let time = 0; time <= maxTime; time++) {
    const currentPoint: { x: number; y: number; t: number } = points[counter];
    if (currentPoint.t === time) {
      converted.push([currentPoint.x, currentPoint.y]);
    } else if (counter < points.length && time < points[counter + 1].t) {
      const nextPoint = points[counter + 1];
      const timeDiff = nextPoint.t - currentPoint.t;
      const jumps = time - currentPoint.t;
      converted.push([
        linearInterpolateValue(currentPoint.x, nextPoint.x, timeDiff, jumps),
        linearInterpolateValue(currentPoint.y, nextPoint.y, timeDiff, jumps),
      ]);
    } else {
      const nextPoint = points[counter + 1];
      converted.push([nextPoint.x, nextPoint.y]);
      counter++;
    }
  }

  return converted;
};

// Removes any system generated fields. e.g __typename
export const removeAllTypenameProps = obj => {
  if (!obj) return {};
  const replacer = (key, value) => (key === '__typename' ? undefined : value);
  const str = JSON.stringify(obj, replacer);
  return JSON.parse(str);
};
