export const noiseEventsColumnDefinitions = [
  {
    id: 'displayLocationName',
    trackSize: 'minmax(120px, max-content)'
  },
  {
    id: 'displayTime',
    trackSize: 'minmax(100px, max-content)'
  },
  {
    id: 'maxLevel',
    trackSize: '72px'
  },
  {
    id: 'sel',
    trackSize: '72px'
  },
  {
    id: 'duration',
    trackSize: 'minmax(100px, 4fr)'
  },
  {
    id: 'displayFlag',
    trackSize: '64px',
    trackSizeSmall: 'minmax(80px, max-content)'
  },
  {
    id: 'acid',
    trackSize: 'minmax(90px, max-content)'
  },
  {
    id: 'displayCategory',
    trackSize: '95px',
  },
  {
    id: 'aircraftType',
    trackSize: 'minmax(90px, 6fr)',
  },
  {
    id: 'classification',
    trackSize: 'minmax(140px, max-content)',
  },
  {
    id: 'isGroomed',
    trackSize: '72px'
  },
];

const isHidden = (columnId, hiddenColumns) => {
  return hiddenColumns[columnId] ? true : false;
};

export const getNoiseEventsColumnsAndStyle = (
  columnDefinitions: Array<{ id: string; trackSize: string; trackSizeSmall?: string }>,
  hiddenColumns: { [key: string]: boolean }
) => {
  const noiseEventsColumns: string[] = [];
  let gridTemplateColumns = '';
  let gridTemplateColumnsNarrow = '';
  //  Mark the last visible column so we can set its track size to 'auto'
  //  so that the table always fills the available horizontal space
  let lastVisibleColumn: string = '';
  let tableHasVisibleFlexibleColumn = false;
  for (const columnDef of columnDefinitions) {
    if(!isHidden(columnDef.id, hiddenColumns)) {
      lastVisibleColumn = columnDef.id;
      if(columnDef.trackSize.match(/[0-9]fr/) || columnDef.trackSize.match(/auto/)) {
        tableHasVisibleFlexibleColumn = true;
      }
    }
  }

  for (const columnDef of columnDefinitions) {
    let trackSize = columnDef.trackSize;
    let trackSizeSmall = columnDef.trackSizeSmall ? columnDef.trackSizeSmall : columnDef.trackSize;
    if (isHidden(columnDef.id, hiddenColumns)) {
      trackSize = '0';
      trackSizeSmall = '0';
    } else if(lastVisibleColumn === columnDef.id && !tableHasVisibleFlexibleColumn) {
      trackSize = 'auto';
      trackSizeSmall = 'auto';
    }
    noiseEventsColumns.push(columnDef.id);
    gridTemplateColumns += `\n\t[${columnDef.id}] ${trackSize}`;
    gridTemplateColumnsNarrow += `\n\t[${columnDef.id}] ${trackSizeSmall}`;
  }

  const styleNoiseEventsGridTemplate = `
    .noise-events-table, .implausible-events-table { grid-template-columns: \n\t[selector] 64px ${gridTemplateColumns}; }
    .page-wrapper--landscape .noise-events-table, .table--slim .noise-events-table, .page-wrapper--landscape .implausible-events-table, .table--slim .implausible-events-table { grid-template-columns: \n\t [selector] 64px ${gridTemplateColumnsNarrow}; }
  `;
  return { noiseEventsColumns, styleNoiseEventsGridTemplate };
};
