export const operationSummariesQuery = {
  name: 'operationSummariesByTimeRange',
  schema: `
    {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items{
        id
        acid
        operationType
        operatorCategory
        aircraftCategory
        aircraftType
        aircraftCount
        airportId
        endTime
        remoteAirportId
        runwayName
        startTime
        time
        {{subQueryOperationTags}}
        pca(position: $position) @include(if: $includePca) {
          elevationAngle
          horizontalDistance
          slantDistance
          time
          trackPoint {
            alt
            lat
            lon
            t
          }
          verticalDistance
        }
        {{subQueryCorrelated}}
      }
    }
  `,
};

export const operationDetailsQuerySchema = ({
  hasInfringementsRead = false,
  hasNoiseEventsRead = false,
  hasComplaintsRead = false,
  hasWeatherRead = false,
}: {
  hasInfringementsRead: boolean;
  hasNoiseEventsRead: boolean;
  hasComplaintsRead: boolean;
  hasWeatherRead: boolean;
}) => {
  const allowedAnyCorrelated =
    hasInfringementsRead || hasNoiseEventsRead || hasComplaintsRead || hasWeatherRead;
  const noiseEventOptions = `
  noiseEvents {
    id
    maxLevel
    locationName
    time
  }`;
  const infringementOptions = `
  infringements {
    id
    infringementType
    rule {
      name
    }
    time
  }`;
  const complaintOptions = `
  complaints {
    id
    complainerName
    disturbanceTypes
    time
  }`;
  const weatherOptions = `
  weather {
    precipitation
    cloudCover
    temperature
    relativeHumidity
    atmosphericPressure
    windSpeed
    windDirection
    visibility
    ceiling
  }`;

  return `
  {
    id
    acid
    time
    startTime
    endTime
    points {
      alt
      lat
      lon
      t
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    ${
      allowedAnyCorrelated
        ? `correlated {
      ${hasNoiseEventsRead ? noiseEventOptions : ''}
      ${hasComplaintsRead ? complaintOptions : ``}
      ${hasInfringementsRead ? infringementOptions : ``}
      ${hasWeatherRead ? weatherOptions : ``}
    }`
        : ``
    }
    tags {
      name
    }
  }
`;
};

export const operationDetailsQuery = {
  name: 'operationSummary',
  schemaTag: `{
    id
    acid
    time
    startTime
    endTime
    points {
      alt
      lat
      lon
      t
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    
    tags {
      name
    }
  }`,
  schemaComplete: `
    {
      id
      acid
      time
      startTime
      endTime
      points {
        alt
        lat
        lon
        t
      }
      profile {
        alt: altitude
        dist: distance
        time: timeOffset
      }
      correlated {
        noiseEvents {
          id
          maxLevel
          locationName
          time
        }
        complaints {
          id
          complainerName
          disturbanceTypes
          time
        }
        infringements {
          id
          infringementType
          rule {
            name
          }
          time
        }
        weather {
          precipitation
          cloudCover
          temperature
          relativeHumidity
          atmosphericPressure
          windSpeed
          windDirection
          visibility
          ceiling
        }
      }
      tags {
        name
      }
    }
  `,
  schemaCompleteNoComplaints: `  {
    id
    acid
    time
    startTime
    endTime
    points {
      alt
      lat
      lon
      t
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    correlated {
      noiseEvents {
        id
        maxLevel
        locationName
        time
      }
      infringements {
        id
        infringementType
        rule {
          name
        }
        time
      }
      weather {
        precipitation
        cloudCover
        temperature
        relativeHumidity
        atmosphericPressure
        windSpeed
        windDirection
        visibility
        ceiling
      }
    }
    tags {
      name
    }
  }`,
};

export const fetchOperationQuery = {
  name: 'operation',
  schema: `
    {
      id
      acid
      airportId
      runwayName
      remoteAirportId
      aircraftCategory
      aircraftType
      aircraftCount
      beaconCode
      callSign
      flightNumber
      tailNumber
      operationType
      airline
      operatorCategory
      pathName
      aircraft {
        azbNoiseClassification
        icaoNoiseClassification
      }
    }
  `,
};

export const inAirQuery = {
  name: 'operationSummariesByTimeRange',
  schema: `
    {
      id
      startTime
      endTime
      operationType
      aircraftCategory
      acid
      points {
        alt
        lat
        lon
        t
      }
      profile {
        alt: altitude
        dist: distance
        time: timeOffset
      }
    }
  `,
};
