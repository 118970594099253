export const fetchQuery = 'noiseEventSummariesByTimeRange';
export const noiseMonitorAirportIdsQuery = 'monitorLocationsByAirportIds';
export const inAirQuery = 'operationSummariesByTimeRange';
export const profilesByIdsQuery = 'operationSummariesByIds';
export const operationIdsQuery = 'operationsByIds';
export const noiseEventsByIdQuery = 'noiseEventSummariesByIds';
export const noiseMonitorLocationIdsQuery = 'monitorLocationsByIds';
export const fetchOperationDetailsQuery = 'operationSummary';
export const operationPcaByTimeRangeQuery = 'operationPcaByTimeRange';

export const operationSchema = `
{
  id
  startTime
  endTime
  acid
  airline
  runwayName
  airportId
  operationType
  aircraftCategory
  points
  {
    t
    lat
    lon
    alt
  }
  profile {
    alt: altitude
    dist: distance
    time: timeOffset
  }
}`;

export const noiseMonitorSchema = `
{
  id
  name
  description
  airportId
  position {
    latitude
    longitude
    altitude
  }
}
`;
export const summarySchema = `
{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items{
    acid
    aircraftCategory
    aircraftType
    audio {
      id
      startTime
      endTime
      resourceUri {
        uri
      }
    }
    classification
    duration
    endTime
    epnl
    id
    locationId
    locationName
    maxLevel
    operationType
    operationId
    samples {
      samples
    }
    sel
    startTime
    threshold
    time
    tags {
      name
      isCompleted
    }
  }
}
`;

export const noiseEventsRequiringGroomingFilter =
  'includeTags:[{ name:"Manual Event Match Review" isCompleted:false }]';

export const singleSummarySchema = `
{
  acid
  aircraftCategory
  aircraftType
  audio {
    id
    startTime
    endTime
    resourceUri {
      uri
    }
  }
  classification
  duration
  endTime
  epnl
  id
  locationId
  locationName
  maxLevel
  operationType
  operationId
  samples {
    samples
  }
  sel
  leq
  startTime
  threshold
  time
}`;

export const operationDetailsSchema = `
  {
    id
    acid
    airline
    aircraftCategory
    aircraftType
    airportId
    operationType
    remoteAirportId
    runwayName
    startTime
    endTime
    points
    {
      t
      lat
      lon
      alt
    }
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
    correlated {
      weather {
        precipitation
        cloudCover
        temperature
        relativeHumidity
        atmosphericPressure
        windSpeed
        windDirection
        windDirectionCompass
        visibility
        ceiling
      }
    }
  }
`;

export const operationProfileSchema = `
  {
    id
    profile {
      alt: altitude
      dist: distance
      time: timeOffset
    }
  }
`;

export const operationPcaByTimeRangeSchema = `
  {
    slantDistance
    horizontalDistance
    verticalDistance
    elevationAngle
    time
    operation
    {
      id
      acid
      operationType
      runwayName
      aircraftCategory
      aircraftType
      airportId
    }
  }
`;
