import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { LocationManagementMap } from '../../Map/LocationManagementMap';
// Types
import { IDeviceManagementForm } from '../../interfaces';

export const MapWrapper = () => {
  const { values } = useFormikContext<IDeviceManagementForm>();
  const { description, id, name, isActive, position, locationId } = values;
  const selectedLocation = {
    deviceDescription: description,
    deviceId: id,
    deviceName: name,
    deviceType: '',
    isActive,
    lastData: '',
    locationDescription: description,
    locationId,
    locationName: description,
    position: {
      latitude: Number(position.latitude),
      longitude: Number(position.longitude),
      altitude: Number(position.altitude),
    },
  };
  return (
    <div className="settings__split-map">
      <LocationManagementMap
        selectedNmtIds={locationId ? [locationId] : []}
        staticDevicePositions={selectedLocation ? [selectedLocation] : []}
      />
    </div>
  );
};
