import { useReducer, useRef, useMemo, useEffect } from 'react';

const enchanceDispatchWithLogger = dispatch => action => {
  console.log('Action Type:', action.type);
  return dispatch(action);
};

export const useReducerWithLogger = (reducers: any, initialState: any) => {
  const prevState: any = useRef(initialState);
  const [state, dispatch] = useReducer(reducers, initialState);

  const dispatchWithLogger = useMemo(() => {
    return enchanceDispatchWithLogger(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (state !== prevState.current) {
      console.groupCollapsed('Changed State');
      console.log('Prev state: ', prevState.current);
      console.log('Next state: ', state);
      console.groupEnd();
    }
    prevState.current = state;
  }, [state]);

  return [state, dispatchWithLogger];
};

export const useSelectors = (state, mapStateToSelectors) => {
  const selectors = useMemo(() => mapStateToSelectors(state), [state]);
  return selectors;
};
