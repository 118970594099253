import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes = keyToActionTypes(
  {
    GET_DATA: null,
    SORT_TABLE: null,
    UPDATE_BREAKDOWN: null,
    UPDATE_VIEW: null,
    INITIALISE_STORE: null,
    UPDATE_SELECTED_FILTER_ITEMS: null,
    CLEAR_SELECTED_FILTER_ITEMS: null,
    DATA_LOADING: null,
    SET_METRIC: null,
    SET_IS_EXPORT: null,
    SET_DEFAULT_PHASE_FILTERS: null,
  },
  'AIRTRAK'
);
