import { ApolloClient } from 'apollo-client';
import { dispatcher } from 'src/utils/dispatcher';
import { appActionTypes } from 'src/app/newActionTypes';
import { updateAppConfig, configError } from 'src/app/actions';
import { fetchConfigData } from 'src/app/resolvers/configResolver';

export const loadAppConfig = (client: ApolloClient<object>, newDispatcher): void => {
  fetchConfigData(client)
    .then(response => {
      newDispatcher({ type: appActionTypes.UPDATE_CONFIG, payload: response });
      dispatcher.handleDispatch(updateAppConfig(response));
    })
    .catch(error => {
      newDispatcher({ type: appActionTypes.CONFIG_ERROR, payload: error });
      dispatcher.handleDispatch(configError(error));
    });
};

export const updateUserConfig = (dispatcher, updatedConfigs): void => {
  dispatcher({ type: appActionTypes.UPDATE_USER_CONFIG, payload: updatedConfigs });
};

export const updateOperationTheme = (dispatcher, updatedTheme: string): void => {
  dispatcher({ type: appActionTypes.UPDATE_OPERATION_THEME, payload: updatedTheme });
};

export const updateDistanceUnits = (dispatcher, updatedDistanceUnits: string): void => {
  dispatcher({ type: appActionTypes.UPDATE_DISTANCE_UNITS, payload: updatedDistanceUnits });
};

export const updateNoiseScenarioLicenseInfo = (dispatcher, updatedLicenseInfo: any): void => {
  dispatcher({
    type: appActionTypes.UPDATE_NOISE_SCENARIO_LICENSE_ACCEPTED,
    payload: updatedLicenseInfo,
  });
};

export const updateNoiseScenarioSelectedModel = (dispatcher, updatedNoiseModel: string): void => {
  dispatcher({
    type: appActionTypes.UPDATE_NOISE_SCENARIO_SELECTED_MODEL,
    payload: updatedNoiseModel,
  });
};

export const updateColorByAltitudeColors = (
  dispatcher,
  updatedTrackAltitudeColors: string[]
): void => {
  dispatcher({
    type: appActionTypes.UPDATE_COLOR_BY_ALTITUDE_COLORS,
    payload: updatedTrackAltitudeColors,
  });
};

export const updateColorByAltitudeBands = (
  dispatcher,
  updatedTrackAltitudeBands: number[]
): void => {
  dispatcher({
    type: appActionTypes.UPDATE_COLOR_BY_ALTITUDE_BANDS,
    payload: updatedTrackAltitudeBands,
  });
};
