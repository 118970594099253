import { ApolloClient } from 'apollo-client';
import { fetchData } from 'src/@airtrak/resolvers/getDataResolver';
// const
import { actionTypes } from 'src/@airtrak/actionTypes';
import { ResponseValidator } from 'src/utils/responseValidator';
const request = new ResponseValidator();

export const getData = (
  client: ApolloClient<object>,
  filterString: string,
  metric: string,
  unit: string,
  dispatcher: any
) => {
  const [instance, t] = request.get('fetch-airtrak-events');
  request.set(instance, t);
  dispatcher({ type: actionTypes.DATA_LOADING });
  fetchData(client, filterString, metric, unit)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        dispatcher({ type: actionTypes.GET_DATA, data });
      }
    })
    .catch(error => {
      dispatcher({ type: actionTypes.DATA_LOADING });
      console.error(error);
    });
};

export const sortTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_TABLE, data: sortName });
};

export const updateBreakdown = (data: string, dispatcher: any) => {
  dispatcher({ type: actionTypes.UPDATE_BREAKDOWN, data });
};

export const cleanUpData = (dispatcher: any) => {
  dispatcher({ type: actionTypes.GET_DATA });
};

export const updateView = (data: string, dispatcher: any) => {
  dispatcher({ type: actionTypes.UPDATE_VIEW, data });
};

export const updateStatus = (dispatcher: any) => {
  dispatcher({ type: actionTypes.DATA_LOADING });
};

export const updateMetric = (metric: string, dispatcher: any) => {
  dispatcher({ type: actionTypes.SET_METRIC, data: metric });
};

export const setIsExport = (graphType: string, isExport: boolean, dispatcher: any) => {
  dispatcher({ type: actionTypes.SET_IS_EXPORT, data: { graphType, isExport } });
};

export const setDefaultPhaseFilters = (data: any, dispatcher: any) => {
  dispatcher({ type: actionTypes.SET_DEFAULT_PHASE_FILTERS, data });
};
