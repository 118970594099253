import React from 'react';
// components
import { BlockHeader, CardSingle } from '@ems/client-design-system';

export const SingleDetailContainer = ({ title, name, description }) => {
  return (
    <div className="block">
      <BlockHeader title={title} loading={!name} />
      <CardSingle title={name} description={description} />
    </div>
  );
};
