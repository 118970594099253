import { fetchPushNotices } from 'src/app/resolvers/fetchPushNoticesResolver';
import { ApolloClient } from 'apollo-client';
import { appActionTypes } from 'src/app/newActionTypes';

export const checkPushNotices = (client: ApolloClient<object>, dispatcher): void => {
  fetchPushNotices(client)
    .then(response => {
      dispatcher({ type: appActionTypes.CHECK_PUSH_NOTICES, payload: response });
    })
    .catch(error => {
      console.warn(error);
    });
};
