import { useMemo } from 'react';
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { CHANGELOG_DISPLAY_STRINGS } from 'src/constants';
import { toCamelCase } from 'src/utils';

export const useChangelogFieldNames = () => {
  const languageSelector = useLanguageSelectors();
  const { fields: fieldStrings } = languageSelector.getLanguage();
  const filterSelectors = useFilterDataSelectors();
  const { changelogFields } = filterSelectors.getOperationsFilterData();

  return useMemo(() => {
    if (changelogFields) {
      // Find translations or just use the data value string
      const fieldNameTranslatedTemp: { [index: string]: string } = {};
      const fieldNameUntranslatedTemp: { [index: string]: string } = {};
      const browserKeys = Object.keys(CHANGELOG_DISPLAY_STRINGS).map(key => `${toCamelCase(key)}s`);

      changelogFields.forEach(field => {
        browserKeys.forEach(key => {
          if (fieldStrings[key][toCamelCase(field)] && fieldStrings[key][toCamelCase(field)]) {
            fieldNameTranslatedTemp[field] = fieldStrings[key][toCamelCase(field)];
          } else {
            fieldNameUntranslatedTemp[field] = field;
          }
        });
      });
      return {
        ...fieldNameUntranslatedTemp,
        ...fieldNameTranslatedTemp,
      };
    }
    return {};
  }, [changelogFields]);
};
