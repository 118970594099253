import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { ApolloClient } from 'apollo-client';
// resolver
import { geocodePosition } from 'src/app/resolvers/addressResolver';
// utils
import { alert } from 'src/utils';
import { getUrlDetails } from 'src/app/functions/core';
import { Map as IMapApis } from 'mapbox-gl';
import { FLY_TO_DURATION } from 'src/constants';
import { IViewState } from './interfaces';
import { IGeocodeCandidateDetail } from 'src/app/props';

let failedToAccess = false;
const displayErrorAlert = () => {
  if (!failedToAccess) {
    failedToAccess = true;
    alert('failedToGetResult');
    setTimeout(() => {
      failedToAccess = false;
    }, 10000); // display alert every 10 sec if the problem persist
  }
};

const getAddress = (endpoint: string) =>
  new Promise<string | null>((resolve, reject) => {
    fetch(endpoint)
      .then(response => {
        if (response.status !== 200) {
          reject(null);
        }
        response.json().then(({ features }) => {
          if (typeof features !== 'undefined' && features.length) {
            const { place_name, context } = features[0];
            const country =
              typeof context !== 'undefined' && context.length
                ? context[context.length - 1].text
                : null;
            resolve(place_name.replace(`, ${country}`, '')); // exclude country
          } else {
            resolve(null);
          }
        });
      })
      .catch(() => {
        reject(null);
      });
  });

const getElevation = (endpoint: string) =>
  new Promise<number | null>((resolve, reject) => {
    fetch(endpoint)
      .then(response => {
        if (response.status !== 200) {
          reject(null);
        }
        response.json().then(data => {
          // array of elevation data
          const elevations: number[] =
            data && typeof data.features !== 'undefined' && data.features
              ? data.features.map(({ properties: { ele } }) => ele)
              : [];
          // find and return the largest elevation value
          if (elevations.length) {
            // Since the elevation value which comes back from the "mapbox-terrain-v2" API is mapped to 10 meter height increments.
            // No elevation value lower than 0 is allowed as it will be incorrect/invalid.
            // An elevation value lower than 0 will be set to 0.
            const elevation = Math.max(...elevations);
            resolve(elevation >= 0 ? elevation : 0);
          } else {
            resolve(null);
          }
        });
      })
      .catch(() => {
        reject(null);
      });
  });

export const useElevation = (
  mapBoxConfig: any,
  accessToken: string,
  longitude: number,
  latitude: number
): { elevation: number | null; place: string | null } => {
  const [elevation, updateElevation] = useState<number | null>(null);
  const [place, updatePlace] = useState<string | null>(null);
  const { elevationEndpoint, reverseGeocodingEndpoint } = mapBoxConfig;
  const makeNewRequest = (position: { latitude: number; longitude: number }) => {
    const { longitude, latitude } = position;
    if (longitude && latitude) {
      updateElevation(null);
      updatePlace(null);
      getElevation(
        `${elevationEndpoint}/${longitude},${latitude}.json?layers=contour&limit=50&access_token=${accessToken}`
      )
        .then((elevation: number | null) => {
          updateElevation(elevation);
        })
        .catch(() => {
          displayErrorAlert();
          updateElevation(null);
        });
      getAddress(
        `${reverseGeocodingEndpoint}/${longitude},${latitude}.json?access_token=${accessToken}`
      )
        .then((place: string | null) => {
          updatePlace(place ? place : '');
        })
        .catch(() => {
          displayErrorAlert();
          updatePlace(null);
        });
    } else {
      updateElevation(null);
      updatePlace(null);
    }
  };

  useEffect(() => {
    makeNewRequest({ latitude, longitude });
  }, [latitude, longitude]);

  return {
    elevation,
    place,
  };
};

export const useGeocodePosition = ({
  client,
  position,
}: {
  client: ApolloClient<object>;
  position: {
    latitude: number;
    longitude: number;
  };
}): { elevation: number | null; place: string | null } => {
  const { longitude, latitude } = position;
  const [elevation, updateElevation] = useState<number>(0);
  const [place, updatePlace] = useState<string | null>(null);
  const makeNewRequest = ({ longitude, latitude }) => {
    if (longitude && latitude) {
      updatePlace(null);
      updateElevation(0);
      if (client) {
        geocodePosition({ client, position })
          .then(response => {
            if (response.length) {
              const [result] = response;
              updateElevation(result.position.altitude);
              updatePlace(result.formattedAddress);
            } else {
              updatePlace(null);
              updateElevation(0);
            }
          })
          .catch(() => {
            updatePlace(null);
            updateElevation(0);
          });
      }
    } else {
      updatePlace(null);
      updateElevation(0);
    }
  };

  useEffect(() => {
    makeNewRequest({ latitude, longitude });
  }, [latitude, longitude]);

  return {
    elevation,
    place,
  };
};

// Get stored location(s) for marker(s).
export const getStoredMarkers = () => {
  const { siteName } = getUrlDetails();
  const addressKey = `${siteName}.maps.markers`;
  const storedMarkers = localStorage.getItem(addressKey);

  try {
    if (storedMarkers) {
      return JSON.parse(storedMarkers);
    }

    return {
      main: {
        longitude: 0,
        latitude: 0,
      },
    };
  } catch (error) {
    console.log(error);
  }
};

// Save marker location(s) to local storage.
// Note: at the moment there's only one marker enabled by the pin button on maps,
// but in the future there may be more markers enabled by different means.
export const storeMarker = (id, position) => {
  const { siteName } = getUrlDetails();
  const storageKey = `${siteName}.maps.markers`;
  const updatedMarkers = {
    ...getStoredMarkers(),
    [id]: {
      ...position,
    },
  };

  localStorage.setItem(storageKey, JSON.stringify(updatedMarkers));
};

export const goToSelectedAddress = ({
  address,
  mapApis,
  viewport,
  addRemoveCircles,
  updateGeocoding,
  updateLocationAddress,
  updateLocationTagOpen,
  onViewportChange,
  updateCloseSearch,
}: {
  address: IGeocodeCandidateDetail;
  mapApis: IMapApis;
  viewport: IViewState;
  addRemoveCircles: Dispatch<SetStateAction<{ longitude: number; latitude: number } | null>>;
  updateGeocoding: Dispatch<SetStateAction<{ longitude: number; latitude: number }>>;
  updateLocationAddress: Dispatch<SetStateAction<null | string>>;
  updateLocationTagOpen: Dispatch<SetStateAction<boolean>>;
  onViewportChange: (view: IViewState) => void;
  updateCloseSearch: Dispatch<SetStateAction<boolean>>;
}) => {
  const { formattedAddress, position } = address;
  if (position && typeof position !== 'undefined') {
    if (mapApis !== null) {
      const zoom = 12;
      const { longitude, latitude } = position;
      mapApis.flyTo({
        center: [longitude, latitude],
        zoom,
        duration: FLY_TO_DURATION,
      });
      setTimeout(() => {
        updateGeocoding({ longitude, latitude });
        storeMarker('main', { longitude, latitude });
        addRemoveCircles({ longitude, latitude });
        updateLocationAddress(formattedAddress);
        updateLocationTagOpen(true);
        onViewportChange({
          ...viewport,
          zoom,
          maxPitch: 0,
          longitude,
          latitude,
        });
        updateCloseSearch(true);
        setTimeout(() => {
          updateCloseSearch(false);
        });
      }, FLY_TO_DURATION);
    }
  }
};

export const addPinToCentre = ({
  updateLocationAddress,
  geocoding,
  viewport,
  updateGeocoding,
  addRemoveCircles,
  updateLocationTagOpen,
}: {
  updateLocationAddress: Dispatch<SetStateAction<null | string>>;
  geocoding: { longitude: number; latitude: number };
  viewport: IViewState;
  updateGeocoding: Dispatch<SetStateAction<{ longitude: number; latitude: number }>>;
  addRemoveCircles: Dispatch<SetStateAction<{ longitude: number; latitude: number } | null>>;
  updateLocationTagOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  // toggle the feature
  updateLocationAddress(null);
  if (geocoding.longitude) {
    // remove the pin if it's already added to the map
    updateGeocoding({ longitude: 0, latitude: 0 });
    addRemoveCircles(null);
    storeMarker('main', { longitude: 0, latitude: 0 });
  } else {
    const { longitude, latitude } = viewport;
    updateGeocoding({ longitude, latitude });
    addRemoveCircles({ longitude, latitude });
    storeMarker('main', { longitude, latitude });
    updateLocationTagOpen(true);
  }
};

export const onGeocodingDragEnd = ({
  longitude,
  latitude,
  updateDragStatus,
  updateGeocoding,
  updateLocationAddress,
  updateLocationTagOpen,
  addRemoveCircles,
}: {
  longitude: number | undefined;
  latitude: number | undefined;
  updateDragStatus: Dispatch<SetStateAction<boolean>>;
  updateGeocoding: Dispatch<SetStateAction<{ longitude: number; latitude: number }>>;
  updateLocationAddress: Dispatch<SetStateAction<null | string>>;
  updateLocationTagOpen: Dispatch<SetStateAction<boolean>>;
  addRemoveCircles: Dispatch<SetStateAction<{ longitude: number; latitude: number } | null>>;
}) => {
  if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
    updateGeocoding({ longitude, latitude });
    storeMarker('main', { longitude, latitude });
  }
  updateLocationAddress(null);
  updateDragStatus(false);
  addRemoveCircles({ longitude, latitude });
  setTimeout(() => {
    updateLocationTagOpen(true);
  }, 1);
};
