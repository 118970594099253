// client
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { allNoiseMonitorsQuery } from './schema';

export const fetchAllNoiseMonitors = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_ALL_NOISE_MONITORS = gql`
    query {
      ${allNoiseMonitorsQuery.name} ${allNoiseMonitorsQuery.schema}
    }`;
    client
      .query({
        query: GET_ALL_NOISE_MONITORS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
