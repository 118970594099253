import React from 'react';
// components
import {
  getConvertedLocation,
  getConvertedVerticalDistance,
  MapPopupMoveable,
} from '@ems/client-design-system';
import {
  EMAIL_CONTACT,
  NOT_SET_CONTACT,
  PRIMARY_PHONE_CONTACT,
  SECONDARY_PHONE_CONTACT,
} from 'src/constants';
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
// style
import './styles.scss';
import { convertToAddress } from 'src/utils';

export const InquirerPopup = ({
  longitude,
  latitude,
  elevation,
  onClose,
  complainerName,
  complainerDetails,
  sameComplainerCount,
  languageData,
  mapApis,
  id,
}: any) => {
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      hints: { noAddressSpecified },
    },
  } = languageSelectors.getLanguage();

  if (!latitude || !longitude) {
    return null;
  }

  const { streetAddress, streetAddress2, city, state, postcode } = complainerDetails.address;
  const address = convertToAddress(complainerDetails.address);

  let complainerContact = '';
  const contactMethod = complainerDetails.preferredResponseMethod;

  if (contactMethod !== null && contactMethod !== NOT_SET_CONTACT) {
    if (contactMethod === EMAIL_CONTACT && complainerDetails.email) {
      complainerContact = complainerDetails.email;
    }

    if (contactMethod === PRIMARY_PHONE_CONTACT && complainerDetails.phoneNumbers.length > 0) {
      complainerContact = complainerDetails.phoneNumbers[0].number;
    }

    if (contactMethod === SECONDARY_PHONE_CONTACT && complainerDetails.phoneNumbers.length > 1) {
      complainerContact = complainerDetails.phoneNumbers[1].number;
    }
  } else {
    if (complainerDetails.email) {
      complainerContact = complainerDetails.email;
    } else if (complainerDetails.phoneNumbers.length > 0) {
      complainerContact = complainerDetails.phoneNumbers[0].number;
    } else if (complainerDetails.phoneNumbers.length > 0) {
      complainerContact = complainerDetails.phoneNumbers[1].number;
    }
  }

  const hasAddress = !!streetAddress || !!streetAddress2 || !!city || !!state || !!postcode;

  const { latLabel, lngLabel, amslLabel } = languageData;

  return (
    <MapPopupMoveable
      className="inquirer-popup"
      offsetLeft={8}
      offsetTop={-80}
      longitude={longitude}
      latitude={latitude}
      anchorLongitude={longitude}
      anchorLatitude={latitude}
      anchorLine={true}
      draggable={true}
      closeButton={true}
      onClose={onClose}>
      <div className="inquirer-popup">
        <div className="inquirer-popup_row">
          {complainerName ? <h2 className="inquirer-popup_title">{complainerName}</h2> : null}
          {!complainerName && !hasAddress ? (
            <h2 className="inquirer-popup_title">{noAddressSpecified}</h2>
          ) : null}
          {sameComplainerCount > 1 ? (
            <div className="inquirer-popup_count">{sameComplainerCount}</div>
          ) : null}
        </div>
        {hasAddress ? (
          <p className="inquirer-popup_value inquirer-popup_address">{address}</p>
        ) : null}
        {!hasAddress ? (
          <div className="inquirer-popup_row">
            <p className="inquirer-popup_col">
              <span className="inquirer-popup_label">{latLabel}</span>
              <span className="inquirer-popup_value">
                {getConvertedLocation(latitude, latLabel, units.location, 5)}
              </span>
            </p>
            <p className="inquirer-popup_col">
              <span className="inquirer-popup_label">{lngLabel}</span>
              <span className="inquirer-popup_value">
                {getConvertedLocation(longitude, lngLabel, units.location, 5)}
              </span>
            </p>
            <p className="inquirer-popup_col">
              <span className="inquirer-popup_label">{amslLabel}</span>
              <span className="inquirer-popup_value">
                {elevation !== null
                  ? getConvertedVerticalDistance(elevation, units.distanceVertical, 0)
                  : `—`}
              </span>
            </p>
          </div>
        ) : null}
        {complainerContact !== '' ? (
          <p className="inquirer-popup_value">{complainerContact}</p>
        ) : null}
      </div>
    </MapPopupMoveable>
  );
};
