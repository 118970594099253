import { Dispatcher } from 'flux';

// This async dispatcher added to the flux dispatcher to allow us to call dispatch while in the middle of dispatching
Dispatcher.prototype.handleDispatch = function(action: { type: string; data?: any }) {
  if (this.isDispatching()) {
    // push onto callback queue
    setTimeout(() => {
      this.handleDispatch(action);
    }, 0);
  } else if (typeof action !== 'undefined') {
    this.dispatch(action);
  } else {
    // just to indicate there was an undefined action passed in
    console.error('Dispatcher: Undefined action');
  }
};

export const dispatcher = new Dispatcher();
