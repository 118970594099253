import React, { useContext, useEffect, useState } from 'react';
import uuid from 'uuid';

// Components
import { Icons, Table, Button, SkeletonText } from '@ems/client-design-system';
import { InfringementRulesMap } from './InfringementRulesMap';
import { ITableColumnData, PageHeader, TableFilter } from 'src/components';
// Selectors
import {
  useInfringementRulesSelectors,
  useLanguageSelectors,
  useRolesSelectors,
} from 'src/app/reducers';
// functions
import { formatTableHeaders, getSelectedIndexesFromKeys } from 'src/utils';

// constants
import {
  CHARACTER_UNICODES,
  INFRINGEMENT_DISPLAY_STRINGS,
  INFRINGEMENT_RULES_MODULE,
  INFRINGEMENT_TYPE,
  MAX_RENDERABLE_FEATURES,
  RENDERED_RULES,
  TABLE_CONSTANTS,
  SETTINGS,
} from 'src/constants';
import { filterInfringementRulesSettings, sortInfringementRulesTable } from 'src/app/actions';
import { DateTimeTableField } from 'src/@settings/components/DateTimeTableField';
import { getInfringementRulePermissions } from './InfringementRulesContainerHelpers';
import { canInsertSomeOf } from 'src/@settings/functions';
import { TInfringementRule } from 'src/app/interfaces';
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';

import { Link } from 'react-router-dom';
import { getDeployedProductId } from 'src/utils';
import {
  useClearTableFilters,
  useInitializeTableFilter,
  useSetInitializedTableFilters,
} from 'src/utils/tableHelpers/tableFilterHelpers';

const addRule = setRuleId => {
  setRuleId(-1);
};

const getTableData = (infData: TInfringementRule[]) => {
  const tableRows = infData.map(item => {
    const rowItem = {
      isActiveFormatted: item.isActive ? CHARACTER_UNICODES.CHECK_MARK : '',
      infringementName: (
        <Link
          className="infringementrule-link"
          to={{
            pathname: `/${getDeployedProductId()}/${SETTINGS}/infringement-rules/${item.id}`,
          }}>
          {item.name}
        </Link>
      ),
      name: item.name,
      isActive: item.isActive,
      infringementType: INFRINGEMENT_DISPLAY_STRINGS[item.infringementType],
      lastUpdateTime: <DateTimeTableField dateTimeValue={item.lastUpdateTime} />,
      id: item.id,
      tableId: uuid.v4(),
    };
    return rowItem;
  });
  return tableRows;
};

interface InfringementRulesListProps {
  setRuleId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const InfringementRulesList: React.FC<InfringementRulesListProps> = ({ setRuleId }) => {
  // Selectors
  const languageSelector = useLanguageSelectors();

  const infRulesSelector = useInfringementRulesSelectors();
  const infringementRules = infRulesSelector.getRules();

  // Infringement Rules map
  const [infRulesToRender, setInfRulesToRender] = useState<number[]>([]);

  // TABLE SET UP

  const {
    components: {
      //    buttons: { loadMore: loadMoreText },
      labels: {
        table: { endTable },
        total: totalString,
      },
      hints: { tryChangingFilters },
    },
    screens: {
      settings: {
        tabs: { infringementRules: infringementRulesString },
        infringementRules: {
          addRule: addRuleString,
          table: {
            rule: ruleString,
            type: typeString,
            lastUpdated: lastUpdatedString,
            active: activeString,
          },
        },
        notFound: {
          table: { noInfRulesFound },
        },
      },
    },
  } = languageSelector.getLanguage();

  // Permissions
  const rolesSelectors = useRolesSelectors();

  const infRulePermissions = getInfringementRulePermissions(rolesSelectors);
  const canAddRule = canInsertSomeOf(infRulePermissions);

  const globalDispatcher = useContext(GlobalDispatchContext);

  // Table data and table data set up
  const [tableRowKeys, setTableRowKeys] = useState<string[]>([]);
  const [infRulesTableData, setInfRulesTableData] = useState<any>([]);

  const isLoadingList = infRulesSelector.getIsLoading();

  useEffect(() => {
    if (infringementRules) {
      const items = getTableData(infringementRules);
      setTableRowKeys(items.map(item => item.tableId));
      setInfRulesTableData(items);
    }
  }, [infringementRules]);

  // Sets the table to loading skeleton when updating
  useEffect(() => {
    if (isLoadingList) {
      setInfRulesTableData([]);
    }
  }, [isLoadingList]);
  // Table set up

  const columnData: ITableColumnData[] = [
    {
      columnName: 'infringementName',
      key: 'name',
      title: ruleString,
      filterName: 'ruleNames',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterType: 'listFilter',
    },
    {
      columnName: 'infringementType',
      key: 'infringementType',
      title: typeString,
      filterName: 'infringementTypes',
      filterOptionType: INFRINGEMENT_TYPE,
      filterOptions: INFRINGEMENT_DISPLAY_STRINGS,
      filterType: 'listFilter',
    },
    { columnName: 'lastUpdateTime', key: 'lastUpdateTime', title: lastUpdatedString },
    { columnName: 'isActiveFormatted', key: 'isActive', title: activeString },
  ];

  const rowHeaders = formatTableHeaders({
    headerItems: columnData,
    resultSize: infRulesTableData.length,
    dispatcher: globalDispatcher,
    sortAction: sortInfringementRulesTable,
    sortObjectSelector: infRulesSelector,
    translationData: languageSelector.getLanguage(),
    translationModuleName: INFRINGEMENT_RULES_MODULE,
    isLoading: isLoadingList,
  });

  const {
    tableFilterObject,
    isFilterInitialized,
    onClearFilters,
    setTableFilterObject,
  } = useInfringmentRulesTableFilter(isLoadingList, infRulesTableData, columnData);

  const infringementRulesColumns = [
    'infringementName',
    'infringementType',
    'lastUpdateTime',
    'isActiveFormatted',
  ];

  // Row selection
  const [selectedTableKeys, updateSelectedTableKeys] = useState<string[]>([]);
  const onSelectRow = (indexes: number[]) => {
    updateSelectedTableKeys(indexes.map(index => tableRowKeys[index]));

    // get infringement rules that render something on map - e.g corridor, gate, zone
    const rulesToRender = infRulesTableData
      .filter((_infRule, index) => indexes.includes(index))
      .filter(infRule => RENDERED_RULES.includes(infRule.infringementType))
      .map(rule => rule.id);

    // If there are more than 20 features to be rendered - only
    if (rulesToRender.length > MAX_RENDERABLE_FEATURES) {
      setInfRulesToRender([]);
    } else {
      setInfRulesToRender(rulesToRender);
    }
  };

  return (
    <>
      <div className="settings__split-content">
        <div className="settings__heading">
          <PageHeader title={infringementRulesString}>
            <SkeletonText loading={isLoadingList} width="4rem">
              <span className="settings__heading-count page-count">
                {`${infRulesTableData.length} ${totalString}`}
              </span>
            </SkeletonText>
          </PageHeader>
          {canAddRule && (
            <Button
              size="m"
              onClick={() => addRule(setRuleId)}
              style="primary"
              leftIcon={<Icons iconName={`ic-ui-add`} title={'add'} size={18} />}>
              {addRuleString}
            </Button>
          )}
        </div>
        <div>
          <TableFilter
            tableFilterObject={tableFilterObject}
            isLoading={isLoadingList}
            areFilterOptionsInitialized={isFilterInitialized}
            setTableFilterObject={setTableFilterObject}
            onClearFilters={onClearFilters}
            dispatcher={globalDispatcher}
            onFilterAction={filterInfringementRulesSettings}
          />
          <Table
            className={`infringementrules-table`}
            wrapperClassName={'infringementrules-table-wrapper'}
            loading={isLoadingList}
            rowHeaders={rowHeaders}
            data={infRulesTableData}
            columns={infringementRulesColumns}
            selectedData={getSelectedIndexesFromKeys(selectedTableKeys, tableRowKeys)}
            areAllRowsSelected={false}
            gridID="infringementrulestable"
            onSelectRow={onSelectRow}
            hasEnded={!!infRulesTableData.length}
            languageData={{
              noDataTitle: noInfRulesFound,
              noDataText: tryChangingFilters,
              endTable,
            }}
            showDashIfEmpty={false}
          />
        </div>
      </div>
      <div className="settings__split-map">
        <InfringementRulesMap infRuleIds={infRulesToRender} />
      </div>
    </>
  );
};

const useInfringmentRulesTableFilter = (isLoadingList, infRulesTableData, columnData) => {
  const { initializedTableFilter } = useInitializeTableFilter(
    isLoadingList,
    infRulesTableData,
    columnData
  );

  const {
    tableFilterObject,
    setTableFilterObject,
    isFilterInitialized,
  } = useSetInitializedTableFilters(initializedTableFilter);
  const onClearFilters = useClearTableFilters(tableFilterObject, setTableFilterObject, () => {});

  useEffect(() => {
    return () => {
      onClearFilters();
    };
  }, []);

  return {
    tableFilterObject,
    setTableFilterObject,
    isFilterInitialized,
    onClearFilters,
  };
};
