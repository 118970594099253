import React, { useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';

// Apollo
import { useLazyQuery } from '@apollo/react-hooks';
import {
  EXPORT_NOISE_SCENARIO,
  GET_EXPORTED_NOISE_SCENARIO,
} from 'src/@scenarioGeneration/containers/ViewScenario/queries';

// Components
import { Dialog, Button, Spinner, displayToast } from '@ems/client-design-system';
import { InlineEditNumber } from 'src/@scenarioGeneration/containers/ViewScenario/components';
import {
  DialogBody,
  DialogForm,
  DialogFormTable,
  DialogFormTableHeading,
  DialogFormTableTd as Td,
  DialogHeadings,
  DialogInputWrapper,
  DialogResponseIcon,
  GenerateNoiseScenarioDialog,
  DialogDownloadLink,
  DialogResponseIconError,
} from './Dialog.styles';

// Contexts
import {
  dialogsStatusContext,
  translationsContext,
  noiseScenarioRecordsContext,
} from 'src/@scenarioGeneration/containers/ViewScenario/contexts';

// Types
import {
  IExportNoiseScenarioVariables,
  IExportNoiseScenarioData,
  IGetExportedNoiseScenarioVariables,
  IGetExportedNoiseScenarioData,
  TExportStatus,
  IExportAdjustments,
  IScenarioTotals,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';
import { ICreateScenarioTranslations } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

const GenerationScenarioDialogForm = ({
  scenarioName,
  tempTotals,
  setTempTotals,
  noiseScenarioTotals,
}: {
  scenarioName: string;
  tempTotals: IExportAdjustments;
  setTempTotals: Dispatch<SetStateAction<IExportAdjustments>>;
  noiseScenarioTotals: IScenarioTotals;
}) => (
  <DialogForm>
    <DialogInputWrapper style={{ marginBottom: '40px' }}>
      <h3>{scenarioName}</h3>
    </DialogInputWrapper>
    <DialogInputWrapper>
      <DialogFormTable>
        <thead>
          <DialogFormTableHeading>
            <th />
            <th>Day</th>
            <th>Evening</th>
            <th>Night</th>
          </DialogFormTableHeading>
        </thead>
        <tbody>
          <tr>
            <Td className="title">Total</Td>
            <Td>{noiseScenarioTotals.day.toLocaleString()}</Td>
            <Td>{noiseScenarioTotals.evening.toLocaleString()}</Td>
            <Td>{noiseScenarioTotals.night.toLocaleString()}</Td>
          </tr>
          <tr>
            <Td className="title">Adjust to</Td>
            <Td>
              <InlineEditNumber
                value={tempTotals.targetDayCount}
                onChangeAction={value => {
                  const fieldValue = value || 0;
                  setTempTotals({
                    ...tempTotals,
                    targetDayCount: fieldValue,
                  });
                }}
                appearActive
              />
            </Td>
            <Td>
              <InlineEditNumber
                value={tempTotals.targetEveningCount}
                onChangeAction={value => {
                  const fieldValue = value || 0;
                  setTempTotals({
                    ...tempTotals,
                    targetEveningCount: fieldValue,
                  });
                }}
                appearActive
              />
            </Td>
            <Td>
              <InlineEditNumber
                value={tempTotals.targetNightCount}
                onChangeAction={value => {
                  const fieldValue = value || 0;
                  setTempTotals({
                    ...tempTotals,
                    targetNightCount: fieldValue,
                  });
                }}
                appearActive
              />
            </Td>
          </tr>
        </tbody>
      </DialogFormTable>
    </DialogInputWrapper>
  </DialogForm>
);

const GenerateScenarioDialogStatus = ({
  exportStatus,
  exportStatusResponse,
  translations,
}: {
  exportStatus: TExportStatus;
  exportStatusResponse: IGetExportedNoiseScenarioData;
  translations: ICreateScenarioTranslations;
}) => {
  const { buttons: buttonStrings, dialogTranslations: dialogStrings } = translations;

  if (exportStatus === 'Pending') {
    return (
      <>
        <DialogHeadings>Generating model</DialogHeadings>
        <Spinner loading size="xl" />
      </>
    );
  }
  const { downloadResource, status } = exportStatusResponse.exportResult;
  if (!downloadResource || status === 'Error') {
    return (
      <>
        <DialogHeadings>{dialogStrings.exportNoiseScenarioErrorHeading}</DialogHeadings>
        <DialogResponseIconError iconName="ic-ui-cancel" />
      </>
    );
  }
  return (
    <>
      <DialogHeadings>{dialogStrings.generateNoiseScenarioCompleted}</DialogHeadings>
      <DialogResponseIcon iconName="ic-success" />
      <Button style="primary">
        <DialogDownloadLink href={downloadResource.uri}>
          {buttonStrings.downloadButton}
        </DialogDownloadLink>
      </Button>
    </>
  );
};

// Checks if totals are edited
const areTotalsDifferent = (totals: IScenarioTotals, newTotals: IExportAdjustments) =>
  !Object.keys(newTotals).every(key => {
    const filteredString = key
      .replace('target', '')
      .replace('Count', '')
      .toLowerCase();
    return totals[filteredString] === newTotals[key];
  });

export const GenerateScenarioDialog = ({
  scenarioName,
  scenarioId,
}: {
  scenarioName: string;
  scenarioId: string;
}) => {
  // Contexts
  const { isGenerateScenarioOpen, setIsGenerateScenarioOpen } = useContext(dialogsStatusContext);
  const { noiseScenarioTotals } = useContext(noiseScenarioRecordsContext);
  const translations = useContext(translationsContext);
  const [tempTotals, setTempTotals] = useState<IExportAdjustments>({
    targetDayCount: noiseScenarioTotals.day,
    targetEveningCount: noiseScenarioTotals.evening,
    targetNightCount: noiseScenarioTotals.night,
  });
  const [exportStatus, setExportStatus] = useState<TExportStatus>('Pending');

  // Networking
  const [getExportedNoiseScenario, { stopPolling, data: exportStatusResponse }] = useLazyQuery<
    IGetExportedNoiseScenarioData,
    IGetExportedNoiseScenarioVariables
  >(GET_EXPORTED_NOISE_SCENARIO, {
    // Prevent cached values being checked
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const [generateNoiseScenario, { called: exportCalled }] = useLazyQuery<
    IExportNoiseScenarioData,
    IExportNoiseScenarioVariables
  >(EXPORT_NOISE_SCENARIO, {
    onCompleted: ({ exportAircraftNoiseModelingScenario }) => {
      setExportStatus('Pending');
      getExportedNoiseScenario({
        variables: {
          exportKey: exportAircraftNoiseModelingScenario,
        },
      });
    },
  });

  // Start polling status of export
  useEffect(() => {
    if (exportStatusResponse) {
      const { exportResult } = exportStatusResponse;
      // InProgress and Pending are just loading states
      if (exportResult.status === 'Complete') {
        stopPolling();
        setExportStatus(exportResult.status);
      } else if (exportResult.status === 'Error') {
        stopPolling();
        setExportStatus(exportResult.status);
        displayToast({
          message: translations.errors.noiseScenarioEndpointError,
          intent: 'warning',
        });
      }
    }
  }, [exportStatusResponse]);

  return (
    <GenerateNoiseScenarioDialog
      isOpen={isGenerateScenarioOpen}
      title={
        !exportCalled || exportStatus === 'Complete' || exportStatus === 'Error'
          ? translations.headings.generateNoiseScenarioHeading
          : null
      }
      isCloseButtonShown={exportCalled || exportStatus === 'Complete'}
      onClose={() => {
        setIsGenerateScenarioOpen(false);
      }}>
      <Dialog.Body>
        <DialogBody>
          {!exportCalled ? (
            <GenerationScenarioDialogForm
              scenarioName={scenarioName}
              tempTotals={tempTotals}
              setTempTotals={setTempTotals}
              noiseScenarioTotals={noiseScenarioTotals}
            />
          ) : (
            <GenerateScenarioDialogStatus
              exportStatus={exportStatus}
              exportStatusResponse={exportStatusResponse}
              translations={translations}
            />
          )}
        </DialogBody>
      </Dialog.Body>
      {!exportCalled && (
        <Dialog.Footer>
          <Dialog.FooterActions>
            <Button
              onClick={() => {
                setIsGenerateScenarioOpen(false);
              }}>
              {translations.buttons.cancelButton}
            </Button>
            <Button
              style="primary"
              onClick={() => {
                let vars: IExportNoiseScenarioVariables;
                if (areTotalsDifferent(noiseScenarioTotals, tempTotals)) {
                  vars = { scenarioId, adjustments: tempTotals };
                } else {
                  vars = { scenarioId };
                }
                generateNoiseScenario({
                  variables: vars,
                });
              }}>
              {translations.buttons.generateButton}
            </Button>
          </Dialog.FooterActions>
        </Dialog.Footer>
      )}
    </GenerateNoiseScenarioDialog>
  );
};
