import { IEditGateParams, IGate, IGateGeometry } from './interfaces';
import { Map as IMapApis } from 'mapbox-gl';
import { getCenter, offsetCoordinatesByPixels } from '../mapHelpers/mapHelpers';
import { INITIAL_GATE_OFFSET } from 'src/constants';

export const setUpExistingGate = (
  gate: IGate,
  editGeometryParams?: IEditGateParams
): IGateGeometry => {
  const gateGeometry = {
    leftPosition: gate.geometry.leftPosition,
    rightPosition: gate.geometry.rightPosition,
    anchorPosition: gate.geometry.anchorPosition,
  };
  if (editGeometryParams && editGeometryParams.updateState && editGeometryParams.updateGeometry) {
    editGeometryParams.updateState(gateGeometry);
    editGeometryParams.updateGeometry(gateGeometry, true);
  }
  return gateGeometry;
};

export const setUpNewGate = (
  viewport,
  mapApis,
  editGeometryParams?: IEditGateParams
): IGateGeometry => {
  const { longitude, latitude } = viewport;

  const newGateGeometry = getDefaultGateCoordinates(longitude, latitude, mapApis);

  if (editGeometryParams && editGeometryParams.updateState && editGeometryParams.updateGeometry) {
    editGeometryParams.updateState(newGateGeometry);
    editGeometryParams.updateGeometry(newGateGeometry, true);
  }
  return newGateGeometry;
};

export const getDefaultGateCoordinates = (
  longitude: number,
  latitude: number,
  mapApis: IMapApis
) => {
  const rightCoordinates = offsetCoordinatesByPixels(
    [longitude, latitude],
    INITIAL_GATE_OFFSET,
    0,
    mapApis
  );

  const anchorCoord = getCenter([
    { longitude, latitude },
    { longitude: rightCoordinates.lng, latitude: rightCoordinates.lat },
  ]);

  return {
    rightPosition: {
      longitude: Number(rightCoordinates.lng.toFixed(5)),
      latitude: Number(rightCoordinates.lat.toFixed(5)),
    },
    anchorPosition: {
      longitude: Number(anchorCoord[0].toFixed(5)),
      latitude: Number(anchorCoord[1].toFixed(5)),
    },
    leftPosition: {
      longitude: Number(longitude.toFixed(5)),
      latitude: Number(latitude.toFixed(5)),
    },
  };
};
