// Functions
import { hexToRgb } from 'src/utils';
// Styled
import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

const brandRgb = hexToRgb(sassVars.brand01).toString();

export const EmissionAlertWrapper = styled.div<{
  isFloating?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  bottom: 0;
  position: ${props => (props.isFloating ? 'fixed' : 'static')};
`;

export const EmissionAlertText = styled.p`
  padding: 10px;
  background: white;
  border: 2px solid rgba(${brandRgb}, 0.4);
  border-radius: 3px;
`;
