import React, { useMemo, useState } from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import { Icons } from '@ems/client-design-system';
import classNames from 'classnames';
import { NodeSearchControlContainer } from '../TaxiPathEditControls.styled';
import { useTaxiPathData } from '../../taxi-path-layer/useTaxiPathData';
import { SearchResult } from 'src/components/AutoSearch/autoSearch.types';
import { AutoSearch } from 'src/components/AutoSearch/AutoSearch';
import { debounce } from 'debounce';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';

export const NodeIdSearch = ({}) => {
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [isNotFound, updateIsNotFound] = useState<boolean>(false);
  const [results, updateResults] = useState<SearchResult[]>([]);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: { search: placeholderText, notFound: notFoundText },
    },
  } = languageSelectors.getLanguage();
  const { dispatch } = useTaxiPathContext();

  const onSelectedResult = ({ id }) => {
    dispatch({ type: TaxiPathActions.SELECT_SEARCHED_NODE, payload: id });
    setShowSearchBar(false);
    updateResults([]);
  };

  const onClearResult = () => {
    updateResults([]);
    updateIsLoading(false);
    updateIsNotFound(false);
  };

  let autoFocusOnOpen: boolean = false;
  let hideClearBtnOnSelect: boolean = false;
  const translationData = {
    placeholder: placeholderText,
    notFound: notFoundText,
  };

  const { nodes } = useTaxiPathData();
  const nodeIds = useMemo(() => nodes.features.map(({ properties }) => properties.NodeId), [nodes]);

  const onNodeSearch = debounce(searchValue => {
    const searchResults = nodeIds.flatMap(id =>
      id.indexOf(String(searchValue)) !== -1 ? { id, value: `Node Id: ${id}` } : []
    );
    updateResults(searchResults);
  }, 500);

  return (
    <NodeSearchControlContainer>
      <button
        title={'search nodes'}
        className={classNames(`mapboxgl-ctrl-icon`, `mapboxgl-ctrl-bin`, {})}
        onClick={() => {
          setShowSearchBar(!showSearchBar);
        }}>
        <Icons size={20} iconName="ic-ui-search" className={classNames(`search-icon`)} />
      </button>
      {showSearchBar && (
        <div className="mapboxgl-ctrl-search">
          <AutoSearch
            searchResults={results}
            overwriteInputValue={''}
            resultsNotFound={isNotFound}
            onSearchFocus={() => {}}
            onSearchChange={onNodeSearch}
            autoFocusOnOpen={autoFocusOnOpen}
            hideClearBtnOnSelect={hideClearBtnOnSelect}
            isLoading={isLoading}
            disabled={false}
            onSelectedResult={onSelectedResult}
            onClearResult={onClearResult}
            translationData={translationData}
          />
        </div>
      )}
    </NodeSearchControlContainer>
  );
};
