import React from 'react';

// Components
import { Spinner, Dialog } from '@ems/client-design-system';

// Styled
import {
  ScenarioModalSpinnerWrapper,
  ScenarioModalSpinnerHeading,
} from 'src/@scenarioGeneration/containers/CreateScenario/CreateScenario.styles';

export const CheckPreviousScenarioDialog = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Dialog isOpen={isLoading}>
      <Dialog.Body>
        <ScenarioModalSpinnerWrapper>
          <ScenarioModalSpinnerHeading>Checking for existing scenarios</ScenarioModalSpinnerHeading>
          <Spinner loading={true} size="xl" />
        </ScenarioModalSpinnerWrapper>
      </Dialog.Body>
    </Dialog>
  );
};
