import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { scenariosSchema, scenariosQuery, scenarioQuery } from './schema';

export const getScenarios = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_SCENARIOS = gql`
      query {
        ${scenariosQuery}
      ${scenariosSchema}
    }`;
    client
      .query({ query: GET_SCENARIOS })
      .then(response => {
        resolve(response.data[scenariosQuery]);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getScenarioReprocessStatus = (client: ApolloClient<object>, scenarioId: number) => {
  return new Promise((resolve, reject) => {
    const GET_SCENARIO = gql`
      query {
        ${scenarioQuery}(id: ${scenarioId})
        {
          outstandingMonthsToProcess
        }
    }`;
    client
      .query({ query: GET_SCENARIO })
      .then(response => {
        resolve(response.data[scenarioQuery]);
      })
      .catch(error => {
        reject(error);
      });
  });
};