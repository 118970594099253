import { useContext } from 'react';
import { SettingsStateContext } from 'src/@settings/provider/SettingsStateProvider';
import {
  ISpatialFeaturesState,
  ISpatialFeaturesSelector,
  ISpatialFeature,
  IAction,
} from 'src/@settings/interfaces';
import { useSelectors } from 'src/utils/storeHelpers';
import { actionTypes } from 'src/@settings/actionTypes';
import { FEATURE_NAME_BY_TYPE, INITIAL_PAGE_INFO_OBJECT } from 'src/constants';
import { deepCopyObject, setTableSortFieldAndDirection, sortTableData } from 'src/utils';
import {
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { ISortObject } from 'src/components';

const initSpatialFeaturesSortObject: ISortObject = {
  field: 'name',
  direction: 'ASC',
};

// STATE
export const spatialFeaturesInitialState: ISpatialFeaturesState = {
  // Spatial Feature Table
  spatialFeaturesTotalCount: 0,
  spatialFeatures: [],
  selectedIdsInTable: [],

  // Selected geometries
  userSelectedGates: [],
  userSelectedZones: [],
  userSelectedCorridors: [],
  // Geometries
  gates: [],
  selectionZones: [],
  corridors: [],

  // Loading
  isLoadingList: true,
  isLoadingMore: false,

  pageInfo: INITIAL_PAGE_INFO_OBJECT,
  spatialFeaturesSortObject: initSpatialFeaturesSortObject,

  // operations:
  operations: [],
  operationPageInfo: INITIAL_PAGE_INFO_OBJECT,
  datesSelected: null,
};

// SELECTOR
export const useSpatialFeaturesSelector: () => ISpatialFeaturesSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const spatialFeaturesState: ISpatialFeaturesState = state.spatialFeatures;
  return useSelectors(spatialFeaturesState, (state: ISpatialFeaturesState) => ({
    getSpatialFeatures: () => state.spatialFeatures,
    getActiveSpatialFeatures: () => state.spatialFeatures.filter(feature => feature.isActive),
    getSelectedIdsInTable: () => state.selectedIdsInTable,
    getSelectedGeometries: () => ({
      userSelectedGates: state.userSelectedGates,
      userSelectedCorridors: state.userSelectedCorridors,
      userSelectedZones: state.userSelectedZones,
    }),
    getTotalCount: () => state.spatialFeaturesTotalCount,
    getAllCorridors: () => state.corridors,
    getAllZones: () => state.selectionZones,
    getAllGates: () => state.gates,
    getIsLoadingList: () => state.isLoadingList,
    getIsLoadingMore: () => state.isLoadingMore,
    getSortObject: () => state.spatialFeaturesSortObject,
    getPageInfo: () => state.pageInfo,
    getFeatureData: (id: number, featureType: string) => {
      const feature = state[FEATURE_NAME_BY_TYPE[featureType]].find(item => id === item.id);
      if (feature) {
        return deepCopyObject(feature);
      } else {
        return undefined;
      }
    },
    getOperations: () => ({
      operations: state.operations,
      pageInfo: state.operationPageInfo,
      datesSelected: state.datesSelected,
    }),
  }));
};

export const spatialFeaturesReducer = (state: ISpatialFeaturesState, action: IAction) => {
  switch (action.type) {
    case actionTypes.SPATIAL_FEATURES_LOADED:
      return Object.assign({}, state, {
        isLoadingList: false,
      });
    case actionTypes.SPATIAL_FEATURES_LOADING:
      return Object.assign({}, state, {
        pageInfo: INITIAL_PAGE_INFO_OBJECT,
        isLoadingList: true,
      });
    case actionTypes.GET_SPATIAL_FEATURES:
      return Object.assign({}, state, {
        spatialFeaturesTotalCount: action.payload.totalCount,
        spatialFeatures: sortTableData(
          action.payload.spatialFeatures,
          state.spatialFeaturesSortObject
        ),
        pageInfo: action.payload.pageInfo,
      });

    case actionTypes.LOAD_MORE_SPATIAL_FEATURES_SUCCESS:
      return Object.assign({}, state, {
        spatialFeatures: state.spatialFeatures.concat(action.payload.spatialFeatures),
        pageInfo: action.payload.pageInfo,
      });

    case actionTypes.GET_ALL_GATES:
      return Object.assign({}, state, {
        gates: action.payload.gates,
      });
    case actionTypes.GET_SELECTED_GATE_DETAILS:
      return Object.assign({}, state, {
        userSelectedGates: state.gates.filter((gate: IGate) =>
          action.payload.gateIds.some((gateId: number) => gateId === gate.id)
        ),
      });
    case actionTypes.GATE_UPDATED:
      return Object.assign({}, state, {
        gates: state.gates.map((gate: IGate) =>
          gate.id === action.payload.gate.id ? action.payload.gate : gate
        ),
      });
    case actionTypes.GET_ALL_CORRIDORS:
      return Object.assign({}, state, {
        corridors: action.payload.corridors,
      });
    case actionTypes.GET_SELECTED_CORRIDOR_DETAILS:
      return Object.assign({}, state, {
        userSelectedCorridors: state.corridors.filter((corridor: INoiseAbatementCorridor) =>
          action.payload.corridorIds.some((corridorId: number) => corridorId === corridor.id)
        ),
      });
    case actionTypes.CORRIDOR_UPDATED:
      return Object.assign({}, state, {
        corridors: state.corridors.map((corridor: INoiseAbatementCorridor) =>
          corridor.id === action.payload.corridor.id ? action.payload.corridor : corridor
        ),
      });
    case actionTypes.GET_ALL_ZONES:
      return Object.assign({}, state, {
        selectionZones: action.payload.zones,
      });
    case actionTypes.GET_SELECTED_ZONE_DETAILS:
      return Object.assign({}, state, {
        userSelectedZones: state.selectionZones.filter((zone: ISelectionZone) =>
          action.payload.zoneIds.some((zoneId: number) => zoneId === zone.id)
        ),
      });
    case actionTypes.ZONE_UPDATED:
      return Object.assign({}, state, {
        selectionZone: state.selectionZones.map((zone: ISelectionZone) =>
          zone.id === action.payload.zone.id ? action.payload.zone : zone
        ),
      });
    case actionTypes.NEW_FEATURE_ADDED:
      return Object.assign({}, state, {
        spatialFeatures: [...state.spatialFeatures, action.payload.newFeature],
      });
    case actionTypes.FEATURE_UPDATED:
      return Object.assign({}, state, {
        spatialFeatures: state.spatialFeatures.map((feature: ISpatialFeature) =>
          feature.featureId === action.payload.updatedFeature.featureId
            ? action.payload.updatedFeature
            : feature
        ),
      });

    case actionTypes.GET_OPERATIONS:
      return Object.assign({}, state, {
        operations: action.payload.operations,
        operationPageInfo: action.payload.pageInfo,
        datesSelected: action.payload.datesSelected,
      });

    case actionTypes.RESET_OPERATIONS:
      return Object.assign({}, state, {
        operations: [],
        operationPageInfo: INITIAL_PAGE_INFO_OBJECT,
        datesSelected: null,
      });
    case actionTypes.ADD_NEWLY_LOADED_FEATURE:
      const featureName = FEATURE_NAME_BY_TYPE[action.payload.featureType];
      return Object.assign({}, state, {
        [featureName]: [...state[featureName], action.payload.featureData],
      });
    case actionTypes.SORT_SPATIAL_FEATURES:
      const newSortObject = setTableSortFieldAndDirection(
        action.payload.sortName,
        state.spatialFeaturesSortObject
      );
      return Object.assign({}, state, {
        spatialFeaturesSortObject: newSortObject,
        spatialFeatures: sortTableData(state.spatialFeatures, newSortObject),
      });
    default:
      return state;
  }
};
