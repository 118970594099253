import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { kml as kmlToGeoJson } from '@tmcw/togeojson';
// actions
import { updateUserConfig } from 'src/app/actions';
import { getAvailableCustomLayers, getCustomLayerById } from 'src/app/actions/geometryActions';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { GeometryDispatchContext } from 'src/app/providers/GeometryProvider';
// reducers
import { useGeometrySelectors } from 'src/app/reducers/geometryReducer';
import { useConfigSelectors } from 'src/app/reducers';
// props
import { IGisLayer } from 'src/app/props';
// constants
import {
  INFRINGEMENT_RULE_TYPES,
  OPERATIONS,
  TRACKS,
  TRACK_DENSITY,
  MONITOR_LOCATIONS,
  RUNWAYS,
  LEGEND,
} from 'src/constants';
import { useGlobalMapContext } from '../applicationState/map/Provider';

export const useMapSettings = ({
  background,
  layers,
  mapTrackStyle,
}: {
  background: string;
  layers: string[];
  mapTrackStyle?: string;
}): {
  mapStyle: string;
  storeSelectedBackground: (selectedBackground: string) => void;
  applyBackground: () => void;
  resetBackground: () => void;
  layersDisplayed: string[];
  isUsingTracks: boolean;
  storeSelectedLayers: (selectedLayers: string[]) => void;
  applyLayers: () => void;
  resetLayers: () => void;
  storeSelectedMapTrackStyle: (selectedTrackTheme: string) => void;
  storeSelectedTrackDensitySize: (selectedTrackDensitySize: string) => void;
  applyMapTrackStyle: () => void;
  updateTrackDensityInUserConfig: () => void;
  lockedMapTrackStyle: string;
  mapHasInteractiveLayer: boolean;
  mapNeedsUpdate;
  setMapNeedsUpdate;
} => {
  const dispatcher = useContext(GlobalDispatchContext);
  // For now keep the background setting in local storage
  const configSelectors = useConfigSelectors();

  const userConfigMapTrackStyle = configSelectors.getMapTrackStyle();
  const userConfigTrackDensitySize = configSelectors.getTrackDensitySize();

  const [mapNeedsUpdate, setMapNeedsUpdate] = useState<boolean>(false);

  const [layersDisplayed, updateLayersDisplayed] = useState<string[]>(layers);
  const [selectedLayers, updateSelectedLayers] = useState<string[]>(layersDisplayed);
  const [selectedMapTrackStyle, updateSelectedMapTrackStyle] = useState<string>(
    userConfigMapTrackStyle || mapTrackStyle
  );
  const [selectedTrackDensitySize, setSelectedTrackDensitySize] = useState<string>(
    userConfigTrackDensitySize || 'trackDensity150km'
  );
  const [lockedMapTrackStyle, updateLockedMapTrackStyle] = useState<string>(selectedMapTrackStyle);
  // control displaying tracks layers
  const isTracksLayerOn = (options: string[]): boolean => options.indexOf(TRACKS) !== -1;
  const [isUsingTracks, toggleIsUsingTracks] = useState<boolean>(isTracksLayerOn(layersDisplayed));

  const [mapHasInteractiveLayer, setMapHasInteractiveLayer] = useState<boolean>(true);

  const {
    selectedBackground,
    updateSelectedBackground,
    updateMapBackground,
    mapStyle,
  } = useMapBackground(background);

  useEffect(() => {
    if (layersDisplayed.some(layer => layer === 'MonitorLocations' || layer === 'Tracks')) {
      setMapHasInteractiveLayer(true);
    } else {
      setMapHasInteractiveLayer(false);
    }
  }, [layersDisplayed]);

  const storeSelectedBackground = (selectedBackground: string) => {
    updateSelectedBackground(selectedBackground);
  };

  const storeSelectedLayers = (selectedLayers: string[]) => {
    updateSelectedLayers(selectedLayers);
  };

  const requestToUpdateMapBackground = (styleSource: string) => {
    // only apply new changes if there is difference
    if (mapStyle !== styleSource) {
      updateMapBackground(styleSource);
    }
  };

  const applyBackground = () => {
    requestToUpdateMapBackground(selectedBackground);
  };

  const resetBackground = () => {
    requestToUpdateMapBackground(background);
  };

  const requestToUpdateMapLayers = (options: string[]) => {
    // only apply new changes if there is difference
    if (layersDisplayed.sort().join() !== options.sort().join()) {
      updateLayersDisplayed(options);
      const useTracksCheck: boolean = isTracksLayerOn(options);
      if (isUsingTracks !== useTracksCheck) {
        toggleIsUsingTracks(useTracksCheck);
      }
    }
  };

  const applyLayers = () => {
    requestToUpdateMapLayers(selectedLayers);
  };

  const resetLayers = () => {
    requestToUpdateMapLayers(layers);
  };

  const storeSelectedMapTrackStyle = (selectedMapTrackStyle: string) => {
    updateSelectedMapTrackStyle(selectedMapTrackStyle);
  };

  const storeSelectedTrackDensitySize = (trackDensitySize: string) => {
    setSelectedTrackDensitySize(trackDensitySize);
  };

  const applyMapTrackStyle = () => {
    updateUserConfig(dispatcher, {
      mapTrackStyle: selectedMapTrackStyle,
    });
    updateLockedMapTrackStyle(selectedMapTrackStyle);
  };

  const updateTrackDensityInUserConfig = () => {
    updateUserConfig(dispatcher, {
      trackDensitySize: selectedTrackDensitySize,
    });
  };

  return {
    mapStyle, // map style already applied
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed, // reference layers already applied
    isUsingTracks,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
    storeSelectedMapTrackStyle,
    storeSelectedTrackDensitySize,
    applyMapTrackStyle,
    updateTrackDensityInUserConfig,
    lockedMapTrackStyle,
    mapHasInteractiveLayer,
    mapNeedsUpdate,
    setMapNeedsUpdate,
  };
};

export const useMapBackground = (background: string) => {
  const dispatcher = useContext(GlobalDispatchContext);
  const configSelectors = useConfigSelectors();
  const userConfigMapBackground = configSelectors.getMapBackground();
  const { updateMapStyleURL, mapStyleURL } = useGlobalMapContext();
  if (userConfigMapBackground && userConfigMapBackground !== mapStyleURL) {
    updateMapStyleURL(userConfigMapBackground);
  } else if (!userConfigMapBackground && background !== mapStyleURL) {
    updateMapStyleURL(background);
  }

  const [mapStyle, updateMapStyle] = useState<string>(userConfigMapBackground || background);
  // temporary selection of the background in the settings modal
  const [selectedBackground, updateSelectedBackground] = useState<string>(mapStyle);

  const updateMapBackground = (backgroundUrl: string) => {
    updateSelectedBackground(backgroundUrl);
    updateMapStyleURL(backgroundUrl);
    updateMapStyle(backgroundUrl);
    updateUserConfig(dispatcher, {
      mapStyle: backgroundUrl,
    });
  };
  return {
    selectedBackground,
    updateSelectedBackground,
    updateMapStyle,
    updateMapBackground,
    mapStyle,
  };
};

export const useGis = (): {
  gisLayers: IGisLayer[];
} => {
  const client = useApolloClient();
  const geometryDispatcher = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const gisLayers = geometrySelectors.getGisLayers();

  useEffect(() => {
    if (!gisLayers.length) {
      getAvailableCustomLayers({
        client,
        dispatcher: geometryDispatcher,
      });
    }
  }, []);

  return {
    gisLayers,
  };
};

export const useCustomLayers = (
  ids: number[]
): {
  kmlData: Map<number, string>;
} => {
  const geometryDispatcher = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const kmlData = geometrySelectors.getKmlLayers();

  useEffect(() => {
    if (ids.length) {
      ids.forEach(id => {
        if (kmlData.get(id) === undefined) {
          getCustomLayerById({
            id,
            dispatcher: geometryDispatcher,
          });
        }
      });
    }
  }, [ids]);

  return {
    kmlData,
  };
};

export const useKmlLayersOnMap = ({
  mapApis,
  customLayers,
  kmlData,
  rerunHook,
}: {
  mapApis;
  customLayers: number[];
  kmlData: Map<number, string>;
  rerunHook: boolean;
}) => {
  const [displayedLayers, setDisplayedLayers] = useState<number[]>([]);
  const layersToAdd = useMemo(
    () => customLayers.filter(layerId => !displayedLayers.includes(layerId)),
    [customLayers, displayedLayers]
  );
  const layersToRemove = useMemo(
    () => displayedLayers.filter(layerId => !customLayers.includes(layerId)),
    [customLayers, displayedLayers]
  );
  const isDataReady = useMemo(() => layersToAdd.every(id => !!kmlData.get(id)), [
    layersToAdd,
    kmlData,
  ]);

  // reset displayed layers on map style change
  useEffect(() => {
    setDisplayedLayers([]);
  }, [rerunHook]);

  useEffect(() => {
    if (layersToRemove.length) {
      layersToRemove.map(id => {
        const sourceId = `sourceId${id}`;
        const layerId = `layerId${id}`;
        const mapStyle = mapApis.getStyle();
        const layersToBeRemoved = mapStyle.layers.filter(layer => layer.id.includes(layerId));
        const sourceToRemove = Object.keys(mapStyle.sources).filter(key => key.includes(sourceId));

        layersToBeRemoved.map(layer => {
          if (mapApis.getLayer(layer.id)) {
            mapApis.removeLayer(layer.id);
          }
        });
        sourceToRemove.map(source => {
          if (mapApis.getSource(source)) {
            mapApis.removeSource(source);
          }
        });
      });
      setDisplayedLayers(customLayers);
    }
  }, [layersToRemove, rerunHook]);

  useEffect(() => {
    if (layersToAdd.length && isDataReady) {
      layersToAdd.map(id => {
        const data = kmlData.get(id);
        const sourceId = `sourceId${id}`;
        const layerId = `layerId${id}`;
        const geoJsonData = kmlToGeoJson(new DOMParser().parseFromString(data, 'text/xml'), {
          styles: true,
        });

        const sorted = [];
        geoJsonData.features.map(feature => {
          const type = feature.geometry.type;
          if (!sorted[type]) {
            sorted[type] = [];
          }
          sorted[type].push(feature);
        });

        if (mapApis && data) {
          if (!mapApis.getSource(sourceId)) {
            mapApis.addSource(sourceId, {
              type: 'geojson',
              data: geoJsonData,
            });
          }

          // KML can be a Polygon or LineString, so we need to sort them in order to correctly style.
          Object.keys(sorted).map((key, index) => {
            const collection = sorted[key];
            const sortedSourceId = `${sourceId}${key}`;
            const sortedLayerId = `${layerId}${key}`;

            mapApis.addSource(sortedSourceId, {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: collection,
              },
            });

            if (key === 'LineString') {
              mapApis.addLayer({
                id: sortedLayerId,
                source: sortedSourceId,
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                type: 'line',
                paint: {
                  'line-width': 1,
                  'line-color': 'black',
                },
              });
            } else if (key === 'Polygon') {
              mapApis.addLayer({
                id: sortedLayerId,
                source: sortedSourceId,
                type: 'fill',
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                paint: {
                  'fill-color': ['get', 'fill'],
                  'fill-opacity': ['get', 'fill-opacity'],
                  'fill-outline-color': ['get', 'stroke'],
                },
              });
              mapApis.addLayer({
                id: `${sortedLayerId}Text`,
                source: sortedSourceId,
                beforeId: mapApis.isRulerEnabled ? `RULER_LAYER_LINE` : null,
                type: 'symbol',
                layout: {
                  'text-field': ['get', 'name'],
                },
                paint: {
                  'text-opacity': ['step', ['zoom'], 0, 11, 1],
                },
              });
            }
          });
        }

        // Place the track layers back on top
        const trackLayers = mapApis.getStyle().layers.filter(layer => layer.id.includes('tracks_'));
        trackLayers.map(layer => {
          mapApis.moveLayer(layer.id);
        });
      });
      setDisplayedLayers(customLayers);
    }
  }, [layersToAdd, customLayers, isDataReady, rerunHook]);
};

export const getMapSettings = (
  mapInstance: string,
  enableColorByAltitudeSelector: boolean,
  config: any,
  language: any
) => {
  const configSelectors = useConfigSelectors();
  const { distance: distanceUnit } = configSelectors.getUnits();
  console.log('distance', distanceUnit);
  // Configuration
  const {
    map: { mapStyleURL, mapStyleSatelliteURL, mapStyleStreetURL },
  } = config;
  // Translation
  const {
    fields: {
      gates: { altitude: altitudeLabel },
      noiseEvents: { operationType: operationTypeLabel },
    },
    components: {
      labels: {
        mapStyleBasicLabel,
        mapStyleStreetLabel,
        mapStyleSatelliteLabel,
        corridors: corridorsLabel,
        gates: gatesLabel,
        exclusions: exclusionsLabel,
        monitorLocations: monitorLocationsLabel,
        runways: runwaysLabel,
        tracks: tracksLabel,
        legend: legendLabel,
        trackDensity: trackDensityLabel,
      },
    },
  } = language;
  const backgrounds = [
    {
      value: mapStyleURL,
      label: mapStyleBasicLabel,
      imageName: 'mapbox_style.png',
    },
    {
      value: mapStyleStreetURL,
      label: mapStyleStreetLabel,
      imageName: 'mapstyle_street.png',
    },
    {
      value: mapStyleSatelliteURL,
      label: mapStyleSatelliteLabel,
      imageName: 'satellite.png',
    },
  ];

  const allLayers = [
    {
      label: tracksLabel,
      name: TRACKS,
      format: '',
      isAvailable: mapInstance === OPERATIONS,
      disabled: mapInstance !== OPERATIONS,
    },
    {
      label: legendLabel,
      name: LEGEND,
      format: '',
      isAvailable: enableColorByAltitudeSelector,
      disabled: !enableColorByAltitudeSelector,
    },
    {
      label: trackDensityLabel,
      name: TRACK_DENSITY,
      format: '',
      isAvailable: enableColorByAltitudeSelector,
      disabled: !enableColorByAltitudeSelector,
    },
    {
      label: corridorsLabel,
      name: INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT,
      format: '',
      isAvailable: true,
      disabled: false,
    },
    {
      label: gatesLabel,
      name: INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT,
      format: '',
      isAvailable: true,
      disabled: false,
    },
    {
      label: exclusionsLabel,
      name: INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT,
      format: '',
      isAvailable: true,
      disabled: false,
    },
    {
      label: monitorLocationsLabel,
      name: MONITOR_LOCATIONS,
      format: '',
      isAvailable: true,
      disabled: false,
    },
    {
      label: runwaysLabel,
      name: RUNWAYS,
      format: '',
      isAvailable: true,
      disabled: false,
    },
  ];

  const availableLayers = allLayers
    .filter(layer => layer.isAvailable)
    .map(layer => {
      const { label, name, format, disabled } = layer;
      return { label, name, format, disabled };
    });

  const mapTrackStyles = [
    {
      label: operationTypeLabel,
      name: 'operation',
      thumbSrc: 'trackstyle_operation.jpg',
    },
    {
      label: altitudeLabel,
      name: 'altitude',
      thumbSrc: 'trackstyle_altitude.jpg',
    },
  ];

  const trackDensityOptions = [
    {
      label: `${trackDensityLabel} ${distanceUnit === 'km' ? '150km' : '500ft'}`,
      name: 'trackDensity150km',
      thumbSrc: 'trackDensity150km.jpg',
    },
    {
      label: `${trackDensityLabel} ${distanceUnit === 'km' ? '60km' : '200ft'}`,
      name: 'trackDensity60km',
      thumbSrc: 'trackDensity60km.jpg',
    },
  ];

  return {
    backgrounds,
    availableLayers,
    mapTrackStyles,
    trackDensityOptions,
  };
};

export const useRerunFunctionOnMapBackgroundChange = ({
  mapApis,
  mapStyle,
  reloadFunction,
  timeout = 2500,
}: {
  mapApis: any;
  mapStyle: string;
  reloadFunction: () => void;
  timeout?: number;
}) => {
  useEffect(() => {
    /*
      Had to add a setTimeOut so that operations tracks render
      after map style has reloaded
      If/when we upgrade mapbox we can call this using a built in callback

      https://visgl.github.io/react-map-gl/docs/api-reference/map#onstyledata
      */
    setTimeout(() => {
      reloadFunction();
    }, timeout);
  }, [mapApis, mapStyle, reloadFunction]);
};

export const useRerunHookOnMapBackgroundChange = (
  mapApis: any,
  mapStyle: string,
  timeout = 2500
) => {
  const [rerunHook, setRerunHook] = useState<boolean>(false);
  const [currentMapStyle, setCurrentMapStyle] = useState<string>(mapStyle);

  const updateReloadHook = useCallback(
    (mapStyle: string, currentMapStyle: string) => {
      if (mapStyle !== currentMapStyle) {
        setCurrentMapStyle(mapStyle);
        setRerunHook(!rerunHook);
      }
    },
    [rerunHook]
  );

  useRerunFunctionOnMapBackgroundChange({
    mapApis,
    mapStyle,
    reloadFunction: () => updateReloadHook(mapStyle, currentMapStyle),
    timeout,
  });
  return rerunHook;
};
