import React from 'react';

interface ITrackDensityOption {
  label: string;
  name: string;
  thumbSrc: string;
}

export interface IMapSettingTrackDensity {
  onSelect: (style: string) => void;
  selectedTrackDensitySize: string;
  trackDensityOptions: ITrackDensityOption[];
}

export const MapSettingTrackDensity: React.FC<IMapSettingTrackDensity> = ({
  onSelect,
  selectedTrackDensitySize,
  trackDensityOptions,
}) => {
  const handleChange = (value: string) => {
    if (selectedTrackDensitySize !== value) {
      onSelect(value);
    }
  };

  return (
    <>
      {trackDensityOptions.map(option => (
        <div
          className={`backgrounds-panel_option ${selectedTrackDensitySize === option.name &&
            'backgrounds-panel_option--selected'}`}
          key={option.name}>
          <div className="backgrounds-panel_image" onClick={() => handleChange(option.name)}>
            <img src={`${process.env.PUBLIC_URL}/${option.thumbSrc}`} />
          </div>
          <div className="backgrounds-panel_label">
            <span>{option.label}</span>
          </div>
        </div>
      ))}
    </>
  );
};
