import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { IFeatureFlags } from 'src/app/props';
import { dateTimeInQuery } from 'src/utils';
import { operationSummariesQuery } from './schema';

interface IOperationFetch {
  client: ApolloClient<object>;
  count: number;
  sortString: string;
  filterString: string;
  featureFlags?: IFeatureFlags;
  availableFilters?: {
    operationTags: string[];
  };
  dateFrom: Date;
  dateTo: Date;
}
const FETCH_SIZE = 1000;
export const fetchOperationData = ({
  client,
  count = FETCH_SIZE,
  sortString,
  filterString,
  featureFlags,
  availableFilters,
  dateFrom,
  dateTo,
}: IOperationFetch) => {
  const operationSummariesSchemaGenerated = operationSummariesQuery.schema.replace(
    '{{subQueryOperationTags}}',
    ''
  );

  return new Promise((resolve, reject) => {
    const queryParams = `
        startTime: "${dateTimeInQuery(dateFrom, 'start')}"
        endTime: "${dateTimeInQuery(dateTo, 'end')}"
        first:${count}

      `;
    const GET_OPERATIONS = gql`
    query getOperations {
      ${operationSummariesQuery.name}(${queryParams} ${sortString} ${filterString}) ${operationSummariesSchemaGenerated}
    }
    `;
    client
      .query({
        query: GET_OPERATIONS,
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[operationSummariesQuery.name] &&
          response.data[operationSummariesQuery.name].items
        ) {
          const { items: operationsData, pageInfo, totalCount } = response.data[
            operationSummariesQuery.name
          ];
          resolve({
            data: operationsData,
            selectedDateRange: { dateFrom, dateTo },
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
