import styled from 'styled-components/macro';

export const ScenarioFormLayoutContainer = styled.div`
  max-width: 1150px;
  width: 60%;
  padding-bottom: 4rem;
`;

export const ScenarioFormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const ScenarioModalSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ScenarioModalSpinnerHeading = styled.h3`
  font-size: 28px;
  margin-bottom: 40px;
  text-align: center;
`;
