import { useContext } from 'react';
import { IRolesState, IStoreState, IRolesSelectors } from 'src/app/props';
import { appActionTypes } from 'src/app/newActionTypes';
import { GlobalStateContext } from 'src/app/providers/GlobalStateContext';
import { useSelectors } from 'src/utils/storeHelpers';

export const useRolesSelectors: () => IRolesSelectors = () => {
  const state: IStoreState = useContext(GlobalStateContext);
  const roleState: IRolesState = state.roles;

  return useSelectors(roleState, (state: IRolesState) => ({
    hasCompletedInitialLoad: () => state.initialLoadCompleted,
    showAll: () => {
      console.log(state);
    },
    canAccess: (feature: string, permissionTypes: string[]) => {
      const permObj = state.userAppPermissions.find(perm => perm.type === feature);
      if(!permObj) return false;
      const permissions = permObj.permissions;
      if(!permissions) {
        console.error('Permissions object does not have a "permissions" property');
        return false;
      }
      const hasAccess = !permissionTypes.some(type => !permissions[type]);
      return hasAccess;
    },
    hasPermission: (permission: string) => state.userPermissions.includes(permission),
  }));
};

const generateAccess = (userPermissions: any) => {
  const permissions: any = [];
  if (userPermissions && userPermissions.length) {
    userPermissions.forEach(permission => {
      const permissionArray = permission.split('.');
      const foundPermission = permissions.find(p => p.type === permissionArray[0]);
      if (foundPermission) {
        foundPermission.permissions[permissionArray[1]] = true;
      } else {
        const newPermission: any = {
          type: permissionArray[0],
          permissions: {
            [permissionArray[1]]: true,
          },
        };

        permissions.push(newPermission);
      }
    });
  }
  return permissions;
};

export const rolesReducer = (state: IRolesState, action: any) => {
  switch (action.type) {
    case appActionTypes.UPDATE_ROLES:
      const appPermissions = generateAccess(action.payload);
      return Object.assign({}, state, {
        userPermissions: action.payload,
        userAppPermissions: appPermissions,
        failedToAccessLanguage: false,
        initialLoadCompleted: true,
      });
    case appActionTypes.ROLES_ERROR:
      return Object.assign({}, state, {
        userPermissions: [],
        userAppPermissions: [],
        failedToAccessLanguage: true,
      });
    default:
      return state;
  }
};
