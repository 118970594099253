import { ApolloClient } from 'apollo-client';
import { actionTypes } from 'src/@reports/actionTypes';
import { fetchReportsData } from 'src/@reports/resolvers/dataResolver';
import { GET_COUNT_DELAY } from 'src/constants';
import { ITableFilterItem } from 'src/@reports/interfaces';
// utils
import { ResponseValidator } from 'src/utils/responseValidator';
const request = new ResponseValidator();

// fetch 1 record and check for count
export const getDataCount = (
  client: ApolloClient<object>,
  sortString: any,
  cursor: string,
  dispatcher: any
) => {
  fetchReportsData(client, 1, sortString, cursor)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_TOTAL_COUNT, data });
    })
    .catch(error => {
      console.error(error);
    });
};

// checks value of the data recieved for count
export const checkDataCount = (
  client: ApolloClient<object>,
  data: any,
  sortString: any,
  cursor: string,
  dispatcher: any
) => {
  if (
    (data &&
      data.pageInfo &&
      data.pageInfo.hasNextPage &&
      typeof data.totalCount === 'undefined') ||
    data.totalCount === -1
  ) {
    // Fetch more to get count when not available after delay
    setTimeout(() => {
      getDataCount(client, sortString, cursor, dispatcher);
    }, GET_COUNT_DELAY);
  }
};

export const resetAndFetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  dispatcher?: any,
  sortString?: string,
  filterString?: string
) => {
  dispatcher({ type: actionTypes.RESET_DATA });
  fetchData(client, resultSize, dispatcher, sortString, filterString);
};

export const fetchData = (
  client: ApolloClient<object>,
  resultSize: number,
  dispatcher: any,
  sortString: any,
  filterString: any,
  cursor?: string
) => {
  if (!cursor) {
    // Only happens on the first fetch
    dispatcher({ type: actionTypes.GET_FIRST_FETCH });
  }
  const [instance, t] = request.get('fetch-reports-data');
  request.set(instance, t);
  fetchReportsData(client, resultSize, sortString, filterString, cursor)
    .then((data: any) => {
      if (request.isValid(instance, t)) {
        checkDataCount(client, data, sortString, data.pageInfo.startCursor, dispatcher);
        dispatcher({ type: actionTypes.DATA_FETCHED, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const sortTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  await dispatcher({ type: actionTypes.SORT_TABLE, data: sortName });
};

export const selectRow = (ids: number[], dispatcher?: any) => {
  dispatcher({ type: actionTypes.SELECT_ROW, data: ids });
};

export const updateSelectedItems = (
  category: string,
  selectedItems: ITableFilterItem[],
  dispatcher: any
): void => {
  dispatcher({ type: actionTypes.UPDATE_SELECTED_ITEMS, data: { category, selectedItems } });
  // applyFiltersChanged(client, dispatcher, sortString, filterString, resultSize);
};

export const clearSelectedItems = (dispatcher: any): void => {
  dispatcher({ type: actionTypes.CLEAR_SELECTED_ITEMS });
};

export const loadMore = (
  client: ApolloClient<object>,
  newDispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    filterString: any;
  }
) => {
  const { resultSize, sortString, endCursor, filterString } = options;
  newDispatcher({ type: actionTypes.LOAD_MORE });
  fetchData(client, resultSize, newDispatcher, sortString, filterString, endCursor);
};
