import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import {
  HeaderContainer,
  MapContainer,
  TableContainer,
  DataContainer,
  FilterContainer,
} from 'src/@operations/containers';
import { useConfigSelectors } from 'src/app/reducers';
import { OperationsStateProvider } from 'src/@operations/providers/OperationsStateProvider';
import { SplitLayout } from 'src/components/Layout';
import { ScreenLoadStateProvider } from 'src/app/providers/ScreenLoadStateContext';

const OperationsScreen = ({ updateUrl }) => {
  // Configuration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const configSelectors = useConfigSelectors();

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();

  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  return (
    <SplitLayout>
      <ScreenLoadStateProvider>
        <OperationsStateProvider>
          <DataContainer>
            {!isGridFullscreen && <MapContainer />}
            <div className="container-fluid">
              <div className="header-container">
                <HeaderContainer updateUrl={updateUrl} />
              </div>
              <FilterContainer />
              <TableContainer />
            </div>
          </DataContainer>
        </OperationsStateProvider>
      </ScreenLoadStateProvider>
    </SplitLayout>
  );
};

export const Operations = withRouteProps(OperationsScreen);
