import { useEffect } from 'react';
import { IOperationData } from 'src/@noiseEvents/interfaces';
import { LAYER_PREFIX_DYNAMIC_TRACK } from 'src/constants';
import { getLayers, removeLayerFromMap, removeSourceFromMap } from 'src/utils/mapHelpers/mapApis';
import { IMap, isSourceAvailable } from 'src/utils/mapHelpers/mapHelpers';
/* Removes any flight paths passed into the hook
 used displaying only the main flight path in operations details when not in playback mode
*/
export const useRemoveNonPlaybackTracks = (
  mapApis: IMap,
  mapTrackData: IOperationData[],
  isPlaybackMode: boolean
) => {
  useEffect(() => {
    if (mapApis && mapTrackData.length && !isPlaybackMode) {
      const renderedLayers = getLayers(mapApis);
      const layersToRemove = renderedLayers.filter(({ id }) =>
        mapTrackData.find(({ id: mapTrackId }) => {
          /* 
          Sometimes the layer id has multiple suffixes including the selected operation id and another id
          so need to check that the only operation ids in the layer id are that of the selected operation
          */
          const splitIds = id.split('_').filter((idSection: string) => !isNaN(Number(idSection)));
          return (
            id.indexOf(LAYER_PREFIX_DYNAMIC_TRACK) !== -1 &&
            splitIds.some((idSuffix: string) => Number(idSuffix) !== mapTrackId)
          );
        })
      );
      if (layersToRemove.length && !isPlaybackMode) {
        layersToRemove.forEach(({ id }) => {
          removeLayerFromMap(mapApis, id);
        });
        layersToRemove.forEach(({ source }) => {
          if (isSourceAvailable(mapApis, source)) {
            removeSourceFromMap(mapApis, source);
          }
        });
      }
    }
  }, [isPlaybackMode]);
};
