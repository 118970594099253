import React from 'react';
import { withRouter } from 'react-router';
import { SplitLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@noiseEvents/containers/Summary';
// constants
import { NOISEEVENTS } from 'src/constants';

export const NoiseEventsSummaryScreen = ({ match, location }) => {
  const { id } = match.params;

  return (
    <SplitLayout>
      <ContentContainer id={id} path={NOISEEVENTS} paginationInfo={location.state} />
    </SplitLayout>
  );
};

export const NoiseEventsSummary = withRouter(NoiseEventsSummaryScreen);
