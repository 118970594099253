import React from 'react';
import { Icons } from '@ems/client-design-system';
import styled from 'styled-components/macro';

export const FileInput = styled.input.attrs({
  type: 'file',
  accept: '.kml',
})`
  display: none;
`;

export const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const ValidIcon = styled.div<{ isValid?: boolean }>`
  position: relative;
  margin-left: 10px;
  background: ${props => (props.isValid ? '#38a169' : '#e53e3e')};
  border-radius: 99px;
  width: 16px;
  height: 16px;

  svg {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - ${props => (props.isValid ? '8px' : '7px')});
    left: calc(50% - ${props => (props.isValid ? '8px' : '7px')});
  }
`;

export const ValidateIcon: React.FC<{
  isValid: boolean;
}> = ({ isValid }) => (
  <ValidIcon isValid={isValid}>
    <Icons
      iconName={isValid ? 'ic-ui-tick-01' : 'ic-ui-cancel'}
      size={isValid ? '16px' : '14px'}
      fill="#ffffff"
    />
  </ValidIcon>
);
