import React, { FC, useState } from 'react';
import cx from 'classnames';
// components
import { DropdownHeading, IDropdownHeadingItem, Badge } from '@ems/client-design-system';
// props
import { IPageHeaderDropdown } from './interfaces';

export const PageHeaderDropdown: FC<IPageHeaderDropdown> = ({
  children,
  items,
  count = 0,
  showReturnButton = false,
  returnToButton,
  headerUpdated = () => {},
}) => {
  const [selectedHeading, setSelectedHeading] = useState<IDropdownHeadingItem>(items[0]);

  return (
    <>
      {showReturnButton && <div className="page-header-button">{returnToButton}</div>}
      {items.length === 1 ? (
        <section className={cx('page-header')}>
          <h1 className="page-header-text">{selectedHeading.label}</h1>
          {children}
        </section>
      ) : (
        <section className={cx('page-header')}>
          <DropdownHeading
            badge={count ? <Badge count={count} /> : undefined}
            items={items}
            selectedItem={selectedHeading}
            updateSelection={item => {
              setSelectedHeading(item);
              headerUpdated(item);
            }}
          />
          {children}
        </section>
      )}
    </>
  );
};
