// ts
import { IFilterItem, TSource } from 'src/@noiseEvents/props';
// actions
import { actionTypes } from 'src/@noiseEvents/newActionTypes';

export const updateSelectedItems = (
  category: string,
  selectedItems: IFilterItem[],
  newDispatcher: any
): void => {
  newDispatcher({
    type: actionTypes.UPDATE_SELECTED_ITEMS,
    data: { category, selectedItems },
  });
};

export const clearSelectedItems = (newDispatcher: any): void => {
  newDispatcher({ type: actionTypes.CLEAR_SELECTED_ITEMS });
};

export const updateRangeFilter = (
  type: string,
  value: string,
  field: TSource,
  newDispatcher: any
) => {
  newDispatcher({ type: actionTypes.UPDATE_RANGE_FILTER, data: { type, value, field } });
};

export const updateGroomingFlagFilter = (newDispatcher: any, isGroomingRequired: boolean) => {
  newDispatcher({ type: actionTypes.UPDATE_GROOMING_FLAG_FILTER, data: { isGroomingRequired } });
};

export const initialiseFilterStore = (
  newDispatcher: any,
  classificationGroups: object,
  correlatedIds: number[]
) => {
  newDispatcher({
    type: actionTypes.INITIALISE_STORE,
    data: { classificationGroups, correlatedIds },
  });
};
