// Apollo
import gql from 'graphql-tag';

export const GET_OPERATION_SUMMARIES = gql`
  query operationSummariesByIds($ids: [Int!]!) {
    operationSummariesByIds(ids: $ids) {
      id
      acid
      operationType
      startTime
      points {
        alt
        lat
        lon
        t
      }
      profile {
        alt: altitude
        dist: distance
        time: timeOffset
      }
    }
  }
`;
