import React, { useCallback } from 'react';
// Styling
import classNames from 'classnames';
import { Icons } from '@ems/client-design-system';
import { TaxiPathControlsContainer } from './TaxiPathEditControls.styled';
import { CustomMapControls } from 'src/components/MapControl/controls/CustomMapControls';
import { useMapClickWithControl } from './useMapClickWithControl';
import { TaxiPathEditMode, useTaxiPathContext } from '../../context/TaxiPathContext';
import { TaxiPathActions } from '../../context/TaxiPathReducer';
import { MapKMLControl } from './MapKMLControl/MapKMLControl';
import { useSetCursor } from 'src/components/Map/AnomsMap/use-map-cursor/useSetCursor';
import { Cursor } from 'src/components/Map/map.types';
import { useMapEvent } from 'src/components/Map/hooks/useMapEvent';
import { NodeIdSearch } from './NodeIdSearch/NodeIdSearch';

export const TaxiPathEditControls = () => {
  const editModes = [TaxiPathEditMode.LINES, TaxiPathEditMode.NODES];
  const {
    dispatch,
    state: { currentEditMode },
  } = useTaxiPathContext();

  const clickHandler = useMapClickWithControl();
  // @ts-ignore
  useMapEvent('click', clickHandler);

  const setCursor = useSetCursor();
  const updateEditMode = useCallback(
    (modeString: TaxiPathEditMode) => {
      const newEditMode = modeString === currentEditMode ? TaxiPathEditMode.NULL : modeString;
      setCursor(newEditMode !== TaxiPathEditMode.NULL ? Cursor.pointer : Cursor.default);
      dispatch({
        type: TaxiPathActions.SET_EDIT_MODE,
        payload: newEditMode,
      });
    },
    [currentEditMode, setCursor]
  );

  if (!Object.keys(TaxiPathEditMode).length) {
    return null;
  }
  const allowedModes = (Object.keys(TaxiPathEditMode) as Array<
    keyof typeof TaxiPathEditMode
  >).filter(mode => editModes.includes(TaxiPathEditMode[mode]));

  return (
    <TaxiPathControlsContainer className="navigation-control">
      <MapKMLControl />
      <CustomMapControls>
        {allowedModes.map(mode => {
          const modeString = TaxiPathEditMode[mode];
          return (
            <button
              key={mode}
              title={modeString}
              className={classNames(`mapboxgl-ctrl-icon`, `mapboxgl-ctrl-${modeString}`, {
                selected: currentEditMode === modeString,
              })}
              onClick={() => updateEditMode(modeString)}>
              <Icons
                size={20}
                iconName={`ic-edit-${modeString}`}
                className={classNames(`${modeString}-icon`)}
              />
            </button>
          );
        })}
        <button
          title={'bin'}
          className={classNames(`mapboxgl-ctrl-icon`, `mapboxgl-ctrl-bin`, {
            selected: currentEditMode === TaxiPathEditMode.DELETE,
          })}
          onClick={() => {
            console.log('clicked');
            updateEditMode(TaxiPathEditMode.DELETE);
          }}>
          <Icons size={20} iconName={`ic-bin`} className={classNames(`bin-icon`)} />
        </button>
        <NodeIdSearch />
      </CustomMapControls>
    </TaxiPathControlsContainer>
  );
};
