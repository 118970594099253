import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';

// Container
import { SpatialFeaturesContainer } from 'src/@settings/containers';

const Screen = () => {
  return (
    <SettingsLayout>
      <SpatialFeaturesContainer />
    </SettingsLayout>
  );
};

export const SpatialFeaturesSettingsScreen = withRouteProps(Screen);
