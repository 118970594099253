// remove duplicates
export const uniqueIds = (array: number[]) => {
  const unique = (value: number, index: number, self: number[]) => {
    return self.indexOf(value) === index;
  };
  return array.filter(unique);
};

// map to -> arrays of keys & array of objects
export const convertMapToArray = (map: any) => {
  const keys: number[] = [];
  const items: any = [];
  if (map.size) {
    for (const [key, item] of map) {
      items.push(item);
      keys.push(key);
    }
  }
  return {
    keys,
    items,
  };
};

// convert ids to array indexes to maintain table selection
export const getSelectedIndexes = (selectedIds: number[], allAvailableIds: number[]) => {
  const indexes: number[] = [];
  if (selectedIds && selectedIds.length) {
    for (const id of selectedIds) {
      const idFound = allAvailableIds.indexOf(id);
      if (idFound !== -1) {
        indexes.push(idFound);
      }
    }
  }
  return indexes.sort();
};

export const removedFromSelection = (existingSelection: number[], newSelection: number[]) => {
  const removed: number[] = [];
  if (existingSelection.length) {
    for (const id of existingSelection) {
      if (!newSelection.includes(id)) {
        removed.push(id);
      }
    }
  }
  return removed;
};

export const selectionRemaining = (removedFromSelection: number[], selection: number[]) => {
  const newSelection: number[] = [];
  if (selection.length) {
    for (const id of selection) {
      if (!removedFromSelection.includes(id)) {
        newSelection.push(id);
      }
    }
  }
  return newSelection;
};
