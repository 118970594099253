import React from 'react';
import { Dropdown, FormErrorDisplay, FormTextField } from '@ems/client-design-system';
import { INoiseAbatementCorridor } from 'src/utils/spatialFeatureHelpers/interfaces';
import { useLanguageSelectors } from 'src/app/reducers';

export const CorridorPanel = infDetailsData => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { form: formStrings },
      },
    },
  } = languageSelectors.getLanguage();
  const { infRule, setInfRule, allCorridors, isEditing, errors } = infDetailsData;

  const handleChange = item => {
    setInfRule(
      Object.assign(
        {},
        infRule,
        { corridorId: item.key, corridorName: item.label },
        { hasChanged: true }
      )
    );
  };

  const corridorOptions = allCorridors.map((corridor: INoiseAbatementCorridor) => ({
    key: corridor.id,
    label: corridor.name,
  }));

  const selectedCorridorDetails = allCorridors.find(corridor => corridor.id === infRule.corridorId);
  const selectedCorridor = selectedCorridorDetails
    ? {
        key: selectedCorridorDetails.id,
        label: selectedCorridorDetails.name,
      }
    : null;

  return (
    <>
      {isEditing ? (
        <Dropdown
          label={formStrings.corridor}
          placeholderValue=""
          searchItems={corridorOptions}
          isNullable={false}
          updateSelection={item => handleChange(item)}
          selectedItem={selectedCorridor}
          disabled={!isEditing}
        />
      ) : (
        <FormTextField
          label={formStrings.corridor}
          id={'Corridor'}
          value={selectedCorridor ? selectedCorridor.label : ''}
          isReadOnly={true}
        />
      )}
      <FormErrorDisplay error={errors ? errors.corridor : undefined} />
    </>
  );
};
