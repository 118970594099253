import { isSourceAvailable, mapboxStyleInfringementPaint } from 'src/utils';

// Interfaces
import { IMapData } from 'src/utils/interfaces';
import { IMap } from 'src/utils/mapHelpers/mapHelpers';

/**
 * Draws track data onto a map
 *
 * @param mapApis - mapbox api object
 * @param trackData - track data to draw
 * @param sourceName - name of source on map
 */
export const drawTrackToMap = ({
  mapApis,
  trackData,
  sourceName,
}: {
  mapApis: IMap;
  trackData: IMapData;
  sourceName: string;
}) => {
  if (mapApis) {
    const {
      acid,
      aircraftType,
      airline,
      airportId,
      endTime,
      id,
      operationType,
      remoteAirportId,
      runwayName,
      startTime,
      points,
    } = trackData;

    const formattedTrackData = {
      type: 'Feature',
      id,
      properties: {
        acid,
        aircraftType,
        airline,
        airportId,
        endTime,
        id,
        operationType,
        remoteAirportId,
        runwayName,
        startTime,
      },
      geometry: {
        type: 'LineString',
        coordinates: points.map(point => [point.lon, point.lat, point.alt]),
      },
    };

    if (isSourceAvailable(mapApis, sourceName)) {
      if (mapApis.isSourceLoaded(sourceName)) {
        const existingSource = mapApis.getStyle().sources[sourceName];
        if (existingSource.data.features.some(feature => feature.id === id)) {
          return;
        }

        const newFeatures = [...existingSource.data.features, formattedTrackData];

        existingSource.data.features = newFeatures;
        mapApis.getSource(`${sourceName}`).setData(existingSource.data);
      }
    } else {
      mapApis.addSource(`${sourceName}`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [formattedTrackData],
        },
      });
      mapApis.addLayer({
        id: `${sourceName}`,
        type: 'line',
        source: `${sourceName}`,
        paint: mapboxStyleInfringementPaint(),
      });
    }
  }
};

/**
 * Set a feature state for an track on the map
 *
 * @param mapApis - mapbox api object
 * @param trackId - id to toggle feature for
 * @param sourceName - name of source on map
 * @param featureName - name of the feature to toggle
 * @param isSelected - whether the feature is selected or not
 */
export const setFeaturedOnMap = ({
  mapApis,
  trackId,
  sourceName,
  vectorSourceName,
  featureName,
  isSelected = true,
}: {
  mapApis: IMap;
  trackId: number;
  sourceName: string;
  vectorSourceName?: string;
  featureName: string;
  isSelected?: boolean;
}) => {
  if (mapApis && isSourceAvailable(mapApis, sourceName)) {
    mapApis.setFeatureState(
      {
        source: sourceName,
        sourceLayer: vectorSourceName ? vectorSourceName : null,
        id: trackId,
      },
      {
        [featureName]: isSelected,
      }
    );
  }
};
