import React, { FC, useRef } from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
// components
import { NoiseGraph, convertToSeconds } from '@ems/client-design-system';
// reducer
import { useLanguageSelectors } from 'src/app/reducers';
// props
import { IContinuousNoiseGraph } from './interfaces';
// functions
import { useGraphReftoCaptureImage } from 'src/app/functions/export';

// Include the 60 seconds either side of the Infringing Operation time and render them on the 1 second sample graph.
export const ContinuousNoiseGraph: FC<IContinuousNoiseGraph> = ({
  noiseData,
  currentTime,
  shouldDisplay,
  selectedNMT,
}: any) => {
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // capture graph image
  const { readyToCapture } = useGraphReftoCaptureImage({
    captureRef,
    // only include noise graph screenshot in the report if one selected
    isGraphAvailable: typeof noiseData !== 'undefined' && noiseData.length && shouldDisplay,
    image: 'noiseImage',
  });

  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      buttons: { resetZoom },
    },
  } = languageSelectors.getLanguage();

  if (typeof noiseData === 'undefined' || !noiseData.length) {
    // make sure we have the required data
    return null;
  }

  let selectedNoiseData = noiseData[0];
  if (selectedNMT.key) {
    selectedNoiseData = noiseData.find(e => e.locationId === selectedNMT.key);
  }

  if (!selectedNoiseData) {
    return null;
  }

  const { stitched, continuousStart, continuousEnd } = selectedNoiseData;
  const startTime = continuousStart
    ? DateTime.fromISO(continuousStart).toSeconds()
    : convertToSeconds(Date.now());
  const endTime = continuousEnd
    ? DateTime.fromISO(continuousEnd).toSeconds()
    : convertToSeconds(Date.now());
  const plotLines = selectedNoiseData.plotLines;

  return (
    <div
      ref={captureRef}
      className={cx('noise-graph_container', {
        'noise-graph_container--hide': !shouldDisplay,
      })}>
      <NoiseGraph
        data={stitched}
        count={currentTime}
        startTime={startTime}
        endTime={endTime}
        xPlotLines={plotLines} // Display 1pt vertical line either side of the Noise Event
        hideResetZoomButton={readyToCapture}
        languageData={{ resetZoom }}
      />
    </div>
  );
};
