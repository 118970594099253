import React, { useState, useContext, ReactNode } from 'react';
import { DateTime } from 'luxon';

// Components
import { displayToast } from '@ems/client-design-system';

// Global Context
import { PushNoticesStateContext } from 'src/app/providers/GlobalStateContext';
import { useConfigSelectors } from 'src/app/reducers';

// Utils
import { fetchPushNotice, getNoticeTimestamp, applyTemplateStrings } from './pushNotificationUtils';

// Constants
import { PUSH_NOTICE_SAMPLE, LOCALSTORAGE_PUSH_NOTICE } from 'src/constants';
interface ILocalStoragePushNotice {
  hasDismissed: boolean;
  timeStamp: string;
}

export const PushNotificationToast = () => {
  const { userHelpLinks } = useContext(PushNoticesStateContext);
  // Grab a local copy to get around CORS issues with the fetch in development
  if (
    process.env.NODE_ENV === 'development' &&
    userHelpLinks.some(document => document.name === 'pushNotice')
  ) {
    userHelpLinks.unshift(PUSH_NOTICE_SAMPLE);
  }
  const configSelectors = useConfigSelectors();
  const {
    dateRange: {
      rangeLimits: {
        pushNotifications: { daysToExpire },
      },
    },
  } = configSelectors.getConfig();
  const pushItem = userHelpLinks.find(document => document.name === 'pushNotice');
  // No push notice so skip everything
  if (pushItem === undefined) {
    return null;
  }

  const localStorageObject: ILocalStoragePushNotice = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_PUSH_NOTICE)
  );
  const [pushNoticeStorage, setPushNoticeStorage] = useState<ILocalStoragePushNotice>(
    localStorageObject || null
  );

  const displayPushToast = (contents: ReactNode) => {
    displayToast({
      key: 'push-notification',
      timeout: 0,
      intent: 'primary',
      message: contents,
      size: 'large',
      onDismiss: didTimeoutExpire => {
        const pushObject = {
          hasDismissed: true,
          timeStamp: pushItem.lastModified,
        };
        if (!didTimeoutExpire) {
          window.localStorage.setItem(LOCALSTORAGE_PUSH_NOTICE, JSON.stringify(pushObject));
          setPushNoticeStorage(pushObject);
        }
      },
    });
  };

  getNoticeTimestamp(userHelpLinks);

  const noticeIsExpired =
    DateTime.fromISO(pushItem.lastModified).toISO() <
    DateTime.local()
      .minus({ days: daysToExpire })
      .toISO();

  if (pushNoticeStorage === null || pushNoticeStorage.timeStamp !== pushItem.lastModified) {
    if (noticeIsExpired) {
      console.warn('Push notice is expired');
      return null;
    }
    fetchPushNotice(pushItem.downloadResource.uri)
      .then(response => {
        if (!response.trim().length) {
          console.warn('Push notice contains no content');
          return null;
        }
        displayPushToast(
          <div dangerouslySetInnerHTML={{ __html: applyTemplateStrings(response) }} />
        );
      })
      .catch(error => {
        console.error(error);
      });
  }

  return null;
};
