import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFilterObject, ITableColumnData, ITableFilterObject } from 'src/components';
import { BLANK_STRING_PLACEHOLDER, TABLE_CONSTANTS } from 'src/constants';
import { resetSelectedItems } from './tableHelpers';

export const useInitializeTableFilter = (
  isTableLoading,
  tableData,
  tableColumnData,
  filterData?
): { initializedTableFilter: ITableFilterObject } => {
  const initializedTableFilter = useMemo(() => {
    // When table data loads - set filter item options
    const tableFilter: ITableFilterObject = initializeFilter(tableColumnData);
    if (!isTableLoading && !!tableData.length) {
      return setUpFilterOptions(tableData, tableFilter, filterData);
    } else {
      return tableFilter;
    }
  }, [tableData, isTableLoading]);

  return { initializedTableFilter };
};

export const useClearTableFilters = (
  tableFilterObject,
  setTableFilterObject,
  beforeFilter?: () => void
) =>
  useCallback(() => {
    if (beforeFilter) {
      beforeFilter();
    }
    if (tableFilterObject) {
      setTableFilterObject(resetSelectedItems(tableFilterObject));
    }
  }, [setTableFilterObject, beforeFilter, tableFilterObject]);

export const useSetInitializedTableFilters = (initializedTableFilter: ITableFilterObject) => {
  const [tableFilterObject, setTableFilterObject] = useState<ITableFilterObject>(null);
  const [isFilterInitialized, setIsFilterInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initializedTableFilter.areOptionsInitialized && !isFilterInitialized) {
      setTableFilterObject(initializedTableFilter);
      setIsFilterInitialized(true);
    }
  }, [initializedTableFilter, isFilterInitialized]);
  return { tableFilterObject, setTableFilterObject, isFilterInitialized, setIsFilterInitialized };
};

const formatLabel = (filterLabel: string) =>
  filterLabel === 'SelectionZone' ? 'Zone' : filterLabel ? filterLabel : '—';

// Filter Functions
export const formatDataToFilterOptions = (filterKey, tableData, filterData?) => {
  const selectedTableData = tableData.flatMap(item =>
    item[filterKey] === BLANK_STRING_PLACEHOLDER ? [] : item[filterKey]
  );
  const hasEmptyData =
    tableData.findIndex(item => item[filterKey] === BLANK_STRING_PLACEHOLDER) !== -1;

  return {
    hasEmptyData,
    filterOptions: Array.from(
      new Set([
        ...selectedTableData,
        ...(filterData && filterData[filterKey] ? filterData[filterKey] : []),
      ])
    ),
  };
};

// Dynamically sets up filter items
export const setUpFilterOptions = (tableData, tableFilter: ITableFilterObject, filterData?) => {
  tableFilter.filters.forEach((filter: IFilterObject) => {
    // if filter items are already provided - don't map table items to filter
    if (!filter.filterItems.length) {
      const { filterOptions, hasEmptyData } = formatDataToFilterOptions(
        filter.key,
        tableData,
        filterData
      );
      // Gets unique values
      const uniqueItems = Array.from(new Set(filterOptions));
      const emptyFilterItems = hasEmptyData ? [{ label: BLANK_STRING_PLACEHOLDER, key: null }] : [];
      // Sets the unique values as filter items
      filter.filterItems = emptyFilterItems.concat(
        uniqueItems.map(filteredItem => ({
          label: formatLabel(filteredItem),
          key: filteredItem,
        }))
      );
    }
  });
  return { ...tableFilter, areOptionsInitialized: true };
};

export const hasSelectedItems = (filters: IFilterObject[]) =>
  filters.some(filter => !!filter.selectedItems.length);

export const resetFilter = (filters: IFilterObject[]) => {
  filters.forEach((filter: IFilterObject) => (filter.selectedItems = []));
  return filters;
};

// FILTER
// Returns the selected filter object key in the data type needed for gql
const returnSelectedItemAsType = (filterOptionType: string, selectedItemKey) => {
  switch (filterOptionType) {
    case TABLE_CONSTANTS.FILTER_TYPES.INT:
      return Number(selectedItemKey);
    case TABLE_CONSTANTS.FILTER_TYPES.STRING:
      return `"${selectedItemKey}"`;
    // if no type is specified - the selected filter key
    default:
      return selectedItemKey;
  }
};

export const formatFilterString = (
  tableFilterObject: ITableFilterObject,
  defaultFilterString?: string
) =>
  `filter : {${tableFilterObject.filters
    .map(({ selectedItems, filterName, filterOptionType }) =>
      !!selectedItems.length
        ? `${filterName}: [${selectedItems.map(({ key }) =>
            returnSelectedItemAsType(filterOptionType, key)
          )}]`
        : null
    )
    .concat(defaultFilterString ? defaultFilterString : '')
    .join(' ')} }`;

export const getActiveFilters = (tableFilterObject: ITableFilterObject) =>
  Object.fromEntries(
    tableFilterObject.filters
      .filter(({ selectedItems }) => selectedItems.length)
      .map(({ selectedItems, filterName }) => [filterName, selectedItems.map(({ key }) => key)])
  );

const initializeFilter = (tableColumnData: ITableColumnData[]): ITableFilterObject => ({
  areOptionsInitialized: false,
  filters: tableColumnData
    .map(tableColumn =>
      tableColumn.filterName && tableColumn.key && tableColumn.filterType
        ? {
            title: tableColumn.title,
            key: tableColumn.key,
            filterOptionType: tableColumn.filterOptionType ? tableColumn.filterOptionType : '',
            filterName: tableColumn.filterName,
            filterItems: tableColumn.filterOptions
              ? Object.keys(tableColumn.filterOptions).map(key => ({
                  key,
                  label: tableColumn.filterOptions[key],
                }))
              : [],
            selectedItems: [],
            filterType: tableColumn.filterType || 'listFilter',
          }
        : null
    )
    .filter(item => !!item) as IFilterObject[],
});
