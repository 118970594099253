import { useState, useEffect } from 'react';
import turfDestination from '@turf/destination';
import { point as turfPoint } from '@turf/helpers';

export const defineBbox = (
  viewport: any,
  configBbox: string,
  definedDistance: number | null = null
): number[] => {
  const defineDynamicBoundingBox = (): number[] => {
    const { longitude, latitude, zoom } = viewport;
    const mapZoom = typeof zoom !== 'undefined' ? Math.round(zoom) : 8;
    let distance = 300;
    if (definedDistance) {
      distance = definedDistance;
    } else {
      if (mapZoom === 9) {
        distance = 32;
      } else if (mapZoom === 10) {
        distance = 16;
      } else if (mapZoom === 11) {
        distance = 8;
      } else if (mapZoom > 12) {
        distance = 4;
      }
    }
    const point = turfPoint([longitude, latitude]);
    const {
      geometry: { coordinates: northEast },
    } = turfDestination(point, distance, 45);
    const {
      geometry: { coordinates: southWest },
    } = turfDestination(point, distance, 225);
    return [southWest[0], southWest[1], northEast[0], northEast[1]];
  };
  if (typeof configBbox === 'undefined' || !configBbox) {
    return defineDynamicBoundingBox();
  }
  let bbox: number[] = [];
  try {
    bbox = JSON.parse(configBbox);
  } catch {
    bbox = defineDynamicBoundingBox();
  }
  return bbox;
};

export const useBbox = (viewport: any, configBbox: number[]) => {
  const [bbox, updateBbox] = useState<number[]>(configBbox);
  useEffect(() => {
    if (typeof configBbox === 'undefined') {
      updateBbox(defineBbox(viewport, configBbox));
    }
  }, [viewport.longitude, Math.round(viewport.zoom)]);

  return {
    bbox,
  };
};
