import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
// Components
import { DeviceManagementCard } from './LocationSettingsCard';
import { IMonitorLocation, IDeviceManagementForm } from '../../interfaces';
import { Icons } from '@ems/client-design-system';
import {
  DeviceManagementForm,
  DeviceManagementField,
  ResetLocationButton,
} from './DeviceManagementDetails.styles';
// Variables
import { DATE_TIME_FORMAT } from 'src/constants';
// Functions
import DateTime from 'luxon/src/datetime.js';
// Reducers
import { useLanguageSelectors } from 'src/app/reducers';
import { DeviceAdvancedSettings } from './DeviceAdvancedSettings';
import { DropdownField, TextInputField } from './FormControlElements';
import { DESC_MAX_LENGTH, NAME_MAX_LENGTH } from './DeviceManagementDetailsForm';
import { resetLocationAction } from '../helpers/actions';
import { useUnitConversion } from '../../Hooks/useUnitConversion';

interface DevicePositionSettingsProps {
  isLoading: boolean;
  isEditing: boolean;
  monitorLocations: IMonitorLocation[];
  deviceId: number;
}

interface LocationState {
  lastData: string;
}

export const DevicePositionSettings = ({
  isLoading,
  isEditing,
  monitorLocations,
  deviceId,
}: DevicePositionSettingsProps) => {
  const routerLocation = useLocation<LocationState>();
  const { values, setValues, setFieldValue } = useFormikContext<IDeviceManagementForm>();
  const languageSelector = useLanguageSelectors();
  const {
    fields: {
      locationManagement: {
        location,
        locationDescription,
        locationName,
        latitude,
        longitude,
        elevation,
      },
    },
  } = languageSelector.getLanguage();
  const { lastData } = routerLocation.state || { lastData: '' };
  const formattedLastData = lastData
    ? `Last data: ${DateTime.fromISO(lastData, { setZone: true }).toFormat(
        DATE_TIME_FORMAT.DEVICE_MGT_24H
      )}`
    : null;
  const disablePositionInput = !!values.locationId;

  const locationDropdownData = useMemo(() => monitorLocations.map(({ id, name }) => ({ key: `${id}`, label: `${id} - ${name}` })), [monitorLocations]);

  // When location drop down is updated copy changes to other fields.
  useEffect(() => {
    const match = monitorLocations.find(location => Number(values.locationId) === location.id);
    if (match) {
      setFieldValue('description', match.description);
      setFieldValue('name', match.name);
      setFieldValue('position.latitude', match.position.latitude);
      setFieldValue('position.latitude', match.position.latitude);
      setFieldValue('position.longitude', match.position.longitude);
      setFieldValue('position.altitude', match.position.altitude);
      setFieldValue('templates', match.configuration.noiseEventTemplates);
    }
  }, [values.locationId]);

  const { verticalDistanceUnit } = useUnitConversion();
  return (
    <DeviceManagementCard title="Location" isLoading={isLoading} subtitle={formattedLastData}>
      <DeviceManagementForm className="location-settings">
        <DropdownField
          fieldData={{
            label: location,
            name: 'locationId',
            selectedKey: values.locationId ? String(values.locationId) : null,
            options: locationDropdownData,
          }}
          isEditing={isEditing}
        />

        <DeviceManagementField>
          {isEditing && (
            <ResetLocationButton
              onClick={() => {
                resetLocationAction(setValues, deviceId);
              }}>
              New location <Icons iconName="ic-ui-add-inverted" />
            </ResetLocationButton>
          )}
        </DeviceManagementField>

        {isEditing && (
          <>
            <TextInputField
              fieldData={{
                label: locationName,
                name: 'name',
                value: values.name,
              }}
              isEditing={isEditing}
              fieldLength={NAME_MAX_LENGTH}
            />
            <TextInputField
              fieldData={{
                label: locationDescription,
                name: 'description',
                value: values.description,
              }}
              isEditing={isEditing}
              fieldLength={DESC_MAX_LENGTH}
            />
          </>
        )}

        <TextInputField
          fieldData={{
            label: latitude,
            name: 'position.latitude',
            value: String(values.position.latitude),
          }}
          isEditing={isEditing}
          isShortField
          disabled={disablePositionInput}
          numberOnly
        />

        <TextInputField
          fieldData={{
            label: longitude,
            name: 'position.longitude',
            value: String(values.position.longitude),
          }}
          isEditing={isEditing}
          isShortField
          disabled={disablePositionInput}
          numberOnly
        />

        <TextInputField
          fieldData={{
            label: elevation,
            name: 'position.altitude',
            value: String(values.position.altitude),
          }}
          isEditing={isEditing}
          appendField={verticalDistanceUnit}
          isShortField
          disabled={disablePositionInput}
        />
      </DeviceManagementForm>
      <DeviceAdvancedSettings isEditing={isEditing} />
    </DeviceManagementCard>
  );
};
