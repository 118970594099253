import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SettingsLayout } from 'src/components/Layout';
import { LocationManagementList } from '../containers';
import { getDeployedProductId, history } from 'src/utils';

// Reducers
import { useConfigSelectors } from 'src/app/reducers';

// Flags
import { NMTMANAGEMENT as NMTMANAGEMENT_FLAG } from 'src/app/featureToggles';

const Screen = () => {
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_NMTMANAGEMENT = configSelectors.isFeatureAvailable(NMTMANAGEMENT_FLAG);

  if (!FEATURE_FLAG_NMTMANAGEMENT) {
    history.replace(`/${getDeployedProductId()}/404`);
  }

  return (
    <SettingsLayout>
      <LocationManagementList />
    </SettingsLayout>
  );
};

export const LocationManagementScreen = withRouteProps(Screen);
