import { toCamelCase } from 'src/utils';

export const getIdFromTableRowKey = (tableRowKey: string) => {
  const changelogId = tableRowKey.substring(tableRowKey.indexOf(':') + 1, tableRowKey.indexOf('/'));
  return Number(changelogId);
};

// Attempts to find a matching translation from the 'list' values in strings file
export const translateFieldValues = ({
  fieldName,
  oldValue,
  newValue,
  listStrings,
}: {
  fieldName: string;
  oldValue: string;
  newValue: string;
  listStrings: Record<string, { [key: string]: string }>;
}) => {
  const lookupList = {
    infringementFilterCategory: `infringementFilterCategories`,
    operationFilterCategory: `operationFilterCategories`,
    reportFilterCategory: `reportFilterCategories`,
    severity: `severities`,
    aircraftCategory: `aircraftCategories`,
    operatorCategory: `operatorCategories`,
    typeCategory: `typeCategories`,
    correlatedCategory: `correlatedCategories`,
    airtrakFilterCategory: `airtrakFilterCategories`,
    complaintsFilterCategory: `complaintsFilterCategories`,
  };
  const pluralFieldName = lookupList[toCamelCase(fieldName)] || `${toCamelCase(fieldName)}s`;

  let translatedOldValue = oldValue;
  let translatedNewValue = newValue;

  if (listStrings[pluralFieldName]) {
    if (listStrings[pluralFieldName][oldValue]) {
      translatedOldValue = listStrings[pluralFieldName][oldValue];
    }
    if (listStrings[pluralFieldName][toCamelCase(oldValue)]) {
      translatedOldValue = listStrings[pluralFieldName][toCamelCase(oldValue)];
    }
    if (listStrings[pluralFieldName][newValue]) {
      translatedNewValue = listStrings[pluralFieldName][newValue];
    }
    if (listStrings[pluralFieldName][toCamelCase(newValue)]) {
      translatedNewValue = listStrings[pluralFieldName][toCamelCase(newValue)];
    }
  }
  return { translatedOldValue, translatedNewValue };
};
