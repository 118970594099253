import React, { useState, useContext } from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
// providers
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
// actions
import { updateUserConfig } from 'src/app/actions';
import { useConfigSelectors } from 'src/app/reducers';

import { NoiseEventsStateProvider } from 'src/@noiseEvents/providers/NoiseEventsStateProvider';
import {
  HeaderContainer,
  DataContainer,
  MapPlaybackContainer,
  TableContainer,
} from 'src/@noiseEvents/containers';
import { FilterContainer } from 'src/@noiseEvents/containers';
import { SplitLayout } from 'src/components/Layout';
import { ViewSwitcher } from 'src/components';

const NoiseEventsScreen = ({ location, updateUrl }) => {
  const locationState = location.state;
  const fromCorrelated =
    locationState && locationState.hasOwnProperty('fromCorrelated')
      ? locationState.fromCorrelated
      : false;
  const correlatedIds = fromCorrelated ? location.state.ids : [];
  const [isPlayingBack, setPlayback] = useState<boolean>(false); // local state used to disable export while playing back noise

  // Configuration
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const dispatcher = useContext(GlobalDispatchContext);
  const configSelectors = useConfigSelectors();

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();

  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  const handleOnSwitch = (newView: string) => {
    updateUserConfig(dispatcher, {
      layout: newView,
      isPortrait: newView !== 'SPLIT_LANDSCAPE_LAYOUT',
    });
  };

  return (
    <SplitLayout>
      <NoiseEventsStateProvider>
        <DataContainer correlatedIds={correlatedIds}>
          {!isGridFullscreen && (
            <MapPlaybackContainer
              playbackModeUpdated={isPlayingBack => setPlayback(isPlayingBack)}
            />
          )}

          <div className="container-fluid">
            <div className="header-container">
              <HeaderContainer
                updateUrl={updateUrl}
                fromCorrelated={fromCorrelated}
                isPlayingBack={isPlayingBack}
              />
            </div>
            <FilterContainer />
            <TableContainer />
          </div>
        </DataContainer>
        <ViewSwitcher onSwitch={handleOnSwitch} currentView={currentLayout} />
      </NoiseEventsStateProvider>
    </SplitLayout>
  );
};

export const NoiseEvents = withRouteProps(NoiseEventsScreen);
