import { useContext } from 'react';
import { SettingsStateContext } from 'src/@settings/provider/SettingsStateProvider';
import {
  IActions,
  IAircraftFuelMappingsState,
  IAircraftFuelMappingsSelector,
} from 'src/@settings/interfaces';
import { useSelectors } from 'src/utils/storeHelpers';
import { actionTypes } from 'src/@settings/actionTypes';
import uuid from 'uuid';
import { AIRCRAFT_MODEL_SEPARATOR } from 'src/constants';

export const useAircraftFuelMappingsSelector: () => IAircraftFuelMappingsSelector = () => {
  const state: any = useContext(SettingsStateContext);
  const aircraftFuelMappingsState: IAircraftFuelMappingsState = state.fuelTypes;
  return useSelectors(aircraftFuelMappingsState, (state: IAircraftFuelMappingsState) => ({
    getFuelTypes: () => state.fuelTypes,
    getIfLoading: () => state.isLoading,
  }));
};

export const aircraftFuelMappingsInitialState: IAircraftFuelMappingsState = {
  fuelTypes: [],
  isLoading: false,
};

const addTableId = (newData: any) => {
  const data = [];
  if (newData.length > 0) {
    for (const mapping of newData) {
      data.push({
        airline: mapping.airline,
        aircraftEngine: mapping.aircraftType + AIRCRAFT_MODEL_SEPARATOR + mapping.engine,
        factorCO2: mapping.factorCO2,
        percentage: mapping.percentage,
        comment: mapping.comment,
        id: mapping.id,
        scenarioId: mapping.scenarioId,
        tableId: uuid.v4(),
      });
    }
  }
  return data;
};

export const aircraftFuelMappingsReducer = (
  state: IAircraftFuelMappingsState,
  action: IActions
) => {
  switch (action.type) {
    case actionTypes.AIRCRAFT_FUEL_MAPPINGS_LOADING:
      return Object.assign({}, state, { isLoading: true });
    case actionTypes.GET_AIRCRAFT_FUEL_MAPPINGS:
      if (action.aircraftFuelMappings) {
        const aircraftFuelMappings = action.aircraftFuelMappings;
        const itemsMap = addTableId(aircraftFuelMappings);
        return Object.assign({}, state, {
          fuelTypes: itemsMap,
          isLoading: false,
        });
      }
    default:
      return state;
  }
};
