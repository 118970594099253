// app config
import { cdnPath } from 'src/config';
// function
import { getProtocol } from 'src/utils';
import { parseQueryString } from 'src/app/functions/queryString';

export const fetchLanguage = (languageTag: string) => {
  // fetch json language from URL
  return new Promise((resolve, reject) => {
    fetch(`${getProtocol()}//${cdnPath}/languages/${languageTag}.json`)
      .then(response => {
        response.json();
      })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  });
};

export const requestNewLanguages = () => {
  const { language } = parseQueryString(window.location.search);
  return new Promise((resolve, reject) => {
    if (language) {
      fetchLanguage(language)
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      resolve({});
    }
  });
};
