import { createContext, Dispatch, SetStateAction } from 'react';

// Types
import {
  IGetNoiseScenarioRecordsVariables,
  INoiseScenario,
  IScenarioTotals,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

// Defaults
import {
  getNoiseScenarioRecordsDefaultVariables,
  noiseScenarioTotalDefaults,
} from 'src/@scenarioGeneration/containers/ViewScenario/defaults';

interface INoiseScenarioRecordsContext {
  noiseScenarioRecords: INoiseScenario[];
  setNoiseScenarioRecords: Dispatch<SetStateAction<INoiseScenario[]>>;
  noiseScenarioTotals: IScenarioTotals;
  setNoiseScenarioTotals: Dispatch<SetStateAction<IScenarioTotals>>;
  getNoiseScenarioRecordsVariables: IGetNoiseScenarioRecordsVariables;
  setGetNoiseScenarioRecordsVariables: Dispatch<SetStateAction<IGetNoiseScenarioRecordsVariables>>;
  selectedInTable: string[];
  updateSelectedInTable: Dispatch<SetStateAction<string[]>>;
  selectedTableKeys: string[];
  updateSelectedTableKeys: Dispatch<SetStateAction<string[]>>;
}

export const noiseScenarioRecordsContext = createContext<INoiseScenarioRecordsContext>({
  noiseScenarioRecords: [],
  setNoiseScenarioRecords: data => {},
  noiseScenarioTotals: noiseScenarioTotalDefaults,
  setNoiseScenarioTotals: totals => {},
  getNoiseScenarioRecordsVariables: getNoiseScenarioRecordsDefaultVariables,
  setGetNoiseScenarioRecordsVariables: variables => {},
  selectedInTable: [],
  updateSelectedInTable: selected => {},
  selectedTableKeys: [],
  updateSelectedTableKeys: selected => {},
});
