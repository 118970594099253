import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { useLocation } from 'react-router-dom';

// Layout
import { SettingsLayout } from 'src/components/Layout';
import { CarbonSettingsContainer } from 'src/@settings/containers';

const Screen = () => {
  const pathname = useLocation().pathname.split('/');
  const route = pathname[pathname.length - 1];

  return (
    <SettingsLayout>
      <CarbonSettingsContainer route={route} />
    </SettingsLayout>
  );
};

export const CarbonEmissionsSettingsScreen = withRouteProps(Screen);
