import { useContext } from 'react';
// interfaces
import { ITableSelectors, ITableState } from '../interfaces';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
// provider
import { SettingsStateContext } from '../provider/SettingsStateProvider';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
import {
  AIRCRAFT_MAPPING_MODULE,
  FLEETMIX_MODULE,
  LOADFACTORS_MODULE,
  TIME_IN_MODES_MODULE,
  FUELTYPES_MODULE,
  AIRLINE_MAPPING_MODULE,
} from 'src/constants';
import { ISortObject } from 'src/components/TableComponents';

export const useTableSelectors: () => ITableSelectors = () => {
  const state: any = useContext(SettingsStateContext);
  const tableState: ITableState = state.table;

  return useSelectors(tableState, (state: ITableState) => ({
    getIconProps: (sortName: string, moduleName: string) =>
      findSortObject(state, sortName, moduleName),
    getSortString: (moduleName: string) => {
      const sortObject = getObjectByModuleName(state, moduleName);
      const sortString = `{field:"${sortObject.field}", direction:${sortObject.direction}}`;
      return `sort: [${sortString}]`;
    },
    getSortObject: (moduleName: string) => getObjectByModuleName(state, moduleName),
  }));
};

// Constants
const initAircraftMappingsSortObject: ISortObject = {
  field: 'numberOfFlights',
  direction: 'DESC',
};
const initLoadFactorsSortObject: ISortObject = {
  field: 'airline',
  direction: 'ASC',
};
const initTimeinModesSortObject: ISortObject = {
  field: 'runway',
  direction: 'ASC',
};
const initAirlineMappingSortObject: ISortObject = {
  field: 'numberOfAircraft',
  direction: 'DESC',
};

// TODO - move sort objects to their own module/reducer
export const tableInitialState: ITableState = {
  // Sort objects
  aircraftMappingsSortObject: initAircraftMappingsSortObject,
  fleetMixSortObject: initAircraftMappingsSortObject,
  loadFactorsSortObject: initLoadFactorsSortObject,
  timeinModesSortObject: initTimeinModesSortObject,
  fuelTypesSortObject: initLoadFactorsSortObject,
  airlineMappingSortObject: initAirlineMappingSortObject,
};

const findSortObject = (state, sortName, moduleName) => {
  const sortObject = getObjectByModuleName(state, moduleName);
  return sortObject && sortObject.field === sortName ? sortObject : false;
};

const getObjectByModuleName = (state: ITableState, moduleName: string) => {
  switch (moduleName) {
    case AIRCRAFT_MAPPING_MODULE:
      return state.aircraftMappingsSortObject;
    case FLEETMIX_MODULE:
      return state.fleetMixSortObject;
    case LOADFACTORS_MODULE:
      return state.loadFactorsSortObject;
    case TIME_IN_MODES_MODULE:
      return state.timeinModesSortObject;
    case FUELTYPES_MODULE:
      return state.fuelTypesSortObject;
    case AIRLINE_MAPPING_MODULE:
      return state.airlineMappingSortObject;
    default:
      return;
  }
};

// Returns an updated column sort object for a table
export const setColumnSort = (
  columnName: string,
  moduleName: string,
  tableSortObject: ISortObject,
  state: ITableState
): ISortObject => {
  // gets a table column sort object if the selected column === sortObject column otherwise returns false
  const sortObject = findSortObject(state, columnName, moduleName);
  if (sortObject) {
    const newSortObject: ISortObject = tableSortObject;
    // Changes the direction
    newSortObject.direction = tableSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
    return newSortObject;
  } else {
    // A new column has been selected so the sortObject for that table will be set to the new column
    return { field: columnName, direction: 'ASC' };
  }
};

export const tableReducer = (state: ITableState, action: any) => {
  switch (action.type) {
    // SORTING ACTIONS
    case actionTypes.SORT_FLEET_MIX_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        FLEETMIX_MODULE,
        state.fleetMixSortObject,
        state
      );
      return Object.assign({}, state, {
        fleetMixSortObject: newColumnSort,
      });
    }

    case actionTypes.SORT_LOAD_FACTORS_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        LOADFACTORS_MODULE,
        state.loadFactorsSortObject,
        state
      );
      return Object.assign({}, state, {
        loadFactorsSortObject: newColumnSort,
      });
    }

    case actionTypes.SORT_TIME_IN_MODES_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        TIME_IN_MODES_MODULE,
        state.timeinModesSortObject,
        state
      );
      return Object.assign({}, state, {
        timeinModesSortObject: newColumnSort,
      });
    }

    case actionTypes.SORT_FUEL_TYPES_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        FUELTYPES_MODULE,
        state.fuelTypesSortObject,
        state
      );
      return Object.assign({}, state, {
        fuelTypesSortObject: newColumnSort,
      });
    }

    case actionTypes.SORT_AIRLINE_MAPPING_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        AIRLINE_MAPPING_MODULE,
        state.airlineMappingSortObject,
        state
      );
      return Object.assign({}, state, {
        airlineMappingSortObject: newColumnSort,
      });
    }

    case actionTypes.SORT_AIRCRAFT_MAPPING_TABLE: {
      const newColumnSort = setColumnSort(
        action.data,
        AIRCRAFT_MAPPING_MODULE,
        state.aircraftMappingsSortObject,
        state
      );
      return Object.assign({}, state, {
        aircraftMappingsSortObject: newColumnSort,
      });
    }

    default:
      return state;
  }
};
