import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { FieldArray } from 'formik';
import {
  FormGroup,
  Checkbox,
  Dropdown,
  Icons,
  AddressSearch,
  Card,
  IDropdownItem,
} from '@ems/client-design-system';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
import {
  getComplaintsFormikProps,
  renderComplainerMatch,
  fieldFormikProps,
  phoneNumberFormikProps,
} from 'src/@complaints/functions';
import { useLanguageSelectors } from 'src/app/reducers';
// interface
import { ICreateComplainer } from 'src/@complaints/interfaces';

export const ComplainerForm: FC<ICreateComplainer> = ({
  formikProps,
  columns,
  complainers,
  onComplainerSearchChange,
  onComplainerSelect,
  preferredContactItems,
  updatePreferredContactItems,
  preferredContact,
  setPreferredContact,
  manualAddress,
  toggleManualAddress,
  onAddressSelect,
  isAnonymous,
  setIsAnonymous,
  onInquirerAnonymous,
  checkToCreateOrUpdateComplainer = () => {},
  recordAlreadyExist = false,
  isLoadingData,
  onFocusComplainerInput,
}) => {
  const {
    firstNameProps,
    cityProps,
    streetAddressProps,
    emailProps,
    lastNameProps,
    postcodeProps,
    stateProps,
    streetAddress2Props,
    titleProps,
  } = getComplaintsFormikProps(columns);
  // configuration
  const languageSelectors = useLanguageSelectors();

  const {
    components: {
      hints: { noComplainerFound, loading },
      labels: { frequentInquirers },
    },
  } = languageSelectors.getLanguage();

  const complainerLanguageData = {
    noOptionsFound: noComplainerFound,
    loading,
    frequentInquirers,
  };

  const showFrequentComplainers = ({ values }) => {
    const { firstName, lastName, phoneNumbers, title, email, address } = values;
    const { place_name, streetAddress, streetAddress2, city, state, postcode } = address;
    const phoneHasValuesEmpty = phoneNumbers.every(phone => phone.number !== '');
    const isAddressEmpty =
      !place_name && !streetAddress && !streetAddress2 && !city && !state && !postcode;
    if (!firstName && !lastName && !phoneHasValuesEmpty && !title && !email && isAddressEmpty) {
      return true;
    }
  };

  const setPreferredContactMethod = (selectedItem: IDropdownItem) => {
    formikProps.setFieldValue('preferredContact', selectedItem.key);
    setPreferredContact(selectedItem);
  };
  const addressSearchFormValues = {
    ...fieldFormikProps('address.place_name', formikProps, isAnonymous),
  };

  // The complaints form has no input refs passed upwards to formik, so we need to set it all manually
  const inquirerNameRef = useRef<HTMLDivElement>(null);
  const scrollToNameError = ref => {
    if (ref.current) {
      ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };
  useEffect(() => {
    const inputsToCheck: string[] = ['title', 'firstName', 'lastName'];
    const formErrors = formikProps.errors;
    if (Object.keys(formErrors).length > 0 && !isAnonymous) {
      if (inputsToCheck.find(input => formErrors[input])) {
        scrollToNameError(inquirerNameRef);
      }
    }
  }, [formikProps.isSubmitting]);

  return (
    <Card className="inquirer-card">
      <div className="row" ref={inquirerNameRef}>
        <FormGroup
          {...{
            className: 'col-md-2',
            ...fieldFormikProps('title', formikProps, isAnonymous),
            ...titleProps,
            onChange: values => {
              checkToCreateOrUpdateComplainer();
              formikProps.handleChange(values);
            },
          }}
        />
        <AddressSearch
          {...{
            className: 'col-md-5',
            ...fieldFormikProps('firstName', formikProps, isAnonymous),
            ...firstNameProps,
            options: complainers,
            count: 1,
            selectedItem: formikProps.values.firstName,
            isLoading: isLoadingData,
            languageData: { ...complainerLanguageData },
            onInputFocus: onFocusComplainerInput,
            showFocusDropdown: showFrequentComplainers(formikProps),
            onInputChange: value => {
              onComplainerSearchChange(value, 'firstName', 'NAME', formikProps);
              checkToCreateOrUpdateComplainer();
            },
            getInputVisibleValue: option => option.firstName,
            onSelect: selectedItem => onComplainerSelect(selectedItem, formikProps),
            renderOption: option =>
              renderComplainerMatch(option, 'FIRST_NAME', formikProps.values.firstName),
          }}
        />
        <AddressSearch
          {...{
            className: 'col-md-5',
            ...fieldFormikProps('lastName', formikProps, isAnonymous),
            ...lastNameProps,
            options: complainers,
            count: 1,
            selectedItem: formikProps.values.lastName,
            isLoading: isLoadingData,
            languageData: { ...complainerLanguageData },
            onInputFocus: onFocusComplainerInput,
            showFocusDropdown: showFrequentComplainers(formikProps),
            onInputChange: value => {
              onComplainerSearchChange(value, 'lastName', 'NAME', formikProps);
              checkToCreateOrUpdateComplainer();
            },
            getInputVisibleValue: option => option.lastName,
            onSelect: selectedItem => onComplainerSelect(selectedItem, formikProps),
            renderOption: option =>
              renderComplainerMatch(option, 'LAST_NAME', formikProps.values.lastName),
          }}
        />
      </div>
      {!manualAddress && (
        <div className="row">
          <div className="col-md-12 form-group address">
            <label className="form-group__label">
              Address
              <span className="rule_link" onClick={() => toggleManualAddress(formikProps, true)}>
                {columns.manualAddress}
              </span>
            </label>
            <div className="form-group__form-field">
              <AddressSearchContainer
                source="complaint"
                formRelatedValues={addressSearchFormValues}
                onAddressFound={address => {
                  onAddressSelect(address, formikProps);
                  checkToCreateOrUpdateComplainer();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {manualAddress && (
        <div className="address-field">
          <p className="rule_link" onClick={() => toggleManualAddress(formikProps, false)}>
            {columns.automaticAddress}
          </p>
          <FormGroup
            {...{
              className: 'col-md-12',
              ...fieldFormikProps('address.streetAddress', formikProps, isAnonymous),
              ...streetAddressProps,
              onChange: values => {
                checkToCreateOrUpdateComplainer();
                formikProps.handleChange(values);
              },
            }}
          />
          <FormGroup
            {...{
              className: 'col-md-12',
              ...fieldFormikProps('address.streetAddress2', formikProps, isAnonymous),
              ...streetAddress2Props,
            }}
          />
          <div className="row">
            <FormGroup
              {...{
                className: 'col-md-6',
                ...fieldFormikProps('address.city', formikProps, isAnonymous),
                ...cityProps,
                onChange: values => {
                  checkToCreateOrUpdateComplainer();
                  formikProps.handleChange(values);
                },
              }}
            />
            <FormGroup
              {...{
                className: 'col-md-6',
                ...fieldFormikProps('address.state', formikProps, isAnonymous),
                ...stateProps,
                onChange: values => {
                  checkToCreateOrUpdateComplainer();
                  formikProps.handleChange(values);
                },
              }}
            />
            <FormGroup
              {...{
                className: 'col-md-3',
                ...fieldFormikProps('address.postcode', formikProps, isAnonymous),
                ...postcodeProps,
                onChange: values => {
                  checkToCreateOrUpdateComplainer();
                  formikProps.handleChange(values);
                },
              }}
            />
          </div>
        </div>
      )}
      <></>
      {/* @ts-ignore ts error TS2786 occurred when upgrading typescript to 4.6*/}
      <FieldArray
        name="phoneNumbers"
        render={() => (
          <>
            {formikProps.values.phoneNumbers.map((phoneNumber, index) => {
              return (
                <div className="row align-center" key={index}>
                  <AddressSearch
                    {...{
                      className: 'col-md-7',
                      ...fieldFormikProps(
                        'phoneNumbers[' + index + '].number',
                        formikProps,
                        isAnonymous
                      ),
                      ...phoneNumberFormikProps(index, columns),
                      options: complainers,
                      count: 1,
                      selectedItem: formikProps.values.phoneNumbers[index].number,
                      isLoading: isLoadingData,
                      languageData: { ...complainerLanguageData },
                      onInputFocus: onFocusComplainerInput,
                      showFocusDropdown: showFrequentComplainers(formikProps),
                      onInputChange: value => {
                        onComplainerSearchChange(
                          value,
                          `phoneNumbers.${index}.number`,
                          'PHONE_NUMBER',
                          formikProps
                        );
                        checkToCreateOrUpdateComplainer();
                      },
                      getInputVisibleValue: option => option.phoneNumbers[index].number,
                      onSelect: selectedItem => onComplainerSelect(selectedItem, formikProps),
                      renderOption: option =>
                        renderComplainerMatch(
                          option,
                          'PHONE_NUMBER',
                          formikProps.values.phoneNumbers[index].number
                        ),
                    }}
                  />
                  {formikProps.values.phoneNumbers.length === 1 && (
                    <div
                      className="btn-subtle-link row align-center pointer"
                      onClick={() => {
                        formikProps.setFieldValue('phoneNumbers', [
                          ...formikProps.values.phoneNumbers,
                          { number: '' },
                        ]);
                        updatePreferredContactItems();
                      }}>
                      <div className="add-icon row align-center justify-content-center">
                        <Icons iconName={`ic-ui-cancel-sm`} size="16" fill="#FFFFFF" />
                      </div>
                      <a>Add secondary phone</a>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      />
      <FormGroup
        {...{
          className: 'col-md-7',
          ...fieldFormikProps('email', formikProps, isAnonymous),
          ...emailProps,
          onChange: values => {
            checkToCreateOrUpdateComplainer();
            formikProps.handleChange(values);
          },
        }}
      />
      <div
        className={cx('dropdown', {
          'dropdown--with-margin': !recordAlreadyExist,
        })}>
        <Dropdown
          label={columns.preferredResponseMethod}
          placeholderValue={columns.preferredResponseMethod}
          searchItems={preferredContactItems}
          isNullable={false}
          selectedItem={preferredContact}
          updateSelection={item => setPreferredContactMethod(item)}
          disabled={isAnonymous ? true : false}
        />
      </div>
      {!recordAlreadyExist && (
        <Checkbox
          checked={isAnonymous}
          labelElement={<span>{columns.anonymousInquiry}</span>}
          onChange={() => {
            setIsAnonymous(!isAnonymous);
            onInquirerAnonymous(formikProps);
          }}
        />
      )}
    </Card>
  );
};
