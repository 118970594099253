import { useEffect, useState } from 'react';
import { featureCollection } from '@turf/turf';

export const LOCAL_STORAGE_KEY_NODES = 'taxipath-nodes';
export const LOCAL_STORAGE_KEY_LINESTRING = 'taxipath-linestring';

type SavedGeoJSONKeys = typeof LOCAL_STORAGE_KEY_NODES | typeof LOCAL_STORAGE_KEY_LINESTRING;

export const updateSavedGeoJSON = <T extends GeoJSON.Geometry>(
  key: SavedGeoJSONKeys,
  data: GeoJSON.FeatureCollection<T>
): void => {
  if (!data) {
    return null;
  }
  const savedData = localStorage.getItem(key);
  const newDataString = JSON.stringify(data);

  if (savedData !== newDataString) {
    console.log(`updating: ${key}. Length ${JSON.stringify(data).length}`);
    try {
      localStorage.setItem(key, newDataString);
    } catch (error) {
      console.warn(error);
    }
  }
};

export const loadSavedGeoJSON = <T extends GeoJSON.Geometry>(
  key: SavedGeoJSONKeys
): GeoJSON.FeatureCollection<T> => {
  const savedData = localStorage.getItem(key);
  if (!savedData) {
    return featureCollection([]);
  }
  console.log(`loading: ${key}.`);
  if (key === LOCAL_STORAGE_KEY_LINESTRING) {
    return JSON.parse(savedData) as GeoJSON.FeatureCollection<T>;
  }
  if (key === LOCAL_STORAGE_KEY_NODES) {
    return JSON.parse(savedData) as GeoJSON.FeatureCollection<T>;
  }
};

export const removeSavedGeoJson = (keys: SavedGeoJSONKeys[]): void => {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
};

export const useTaxiRefresher = (trigger: unknown) => {
  const [refresher, setRefresher] = useState(true);

  useEffect(() => {
    setRefresher(!refresher);
  }, [trigger]);

  useEffect(() => {
    if (!refresher) {
      setRefresher(true);
    }
  }, [refresher]);

  return { refresher };
};
