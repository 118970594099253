import React, { FC, useState } from 'react';
import { debounce } from 'debounce';
import { useApolloClient } from '@apollo/react-hooks';
import { getUrlDetails } from 'src/app/functions/core';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// resolver
import { addressSearchResults, geocodeCandidateDetail } from 'src/app/resolvers/addressResolver';
// ts
import { IAddress, IGeocodeCandidateDetail } from 'src/app/props';
import { AutoSearch } from 'src/components/AutoSearch/AutoSearch';
import { SearchResult } from 'src/components/AutoSearch/autoSearch.types';

// geocodeAddressSchema
export interface IAddressSearchContainer {
  onAddressFound?: (address: IGeocodeCandidateDetail) => void;
  source: 'map' | 'complaint';
  formRelatedValues?: {
    value: string;
    error: string;
    disabled: boolean;
  };
}

const addressToSearchResult = (addresses: IAddress[]): SearchResult[] =>
  addresses.map(address => ({ id: address.id, value: address.formattedAddress }));

export const AddressSearchContainer: FC<IAddressSearchContainer> = ({
  onAddressFound,
  source,
  formRelatedValues,
}) => {
  const client = useApolloClient();
  const [isLoading, updateIsLoading] = useState<boolean>(false);
  const [isNotFound, updateIsNotFound] = useState<boolean>(false);
  const [results, updateResults] = useState<SearchResult[]>([]);

  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    map: { centre: mapCentre },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: { search: placeholderText, notFound: notFoundText },
    },
  } = languageSelectors.getLanguage();

  // Obtain addresses saved in local storage.
  const getStoredAddresses = (): SearchResult[] => {
    const { siteName } = getUrlDetails();
    const addressKey = `${siteName}.selected.addresses`;
    const addressItem = localStorage.getItem(addressKey);

    try {
      if (addressItem) {
        const addresses = JSON.parse(addressItem);
        return addresses.map(address =>
          address.formattedAddress ? { id: address.id, value: address.formattedAddress } : address
        );
      }

      return [];
    } catch (error) {
      console.error(error);
    }
  };

  // Show addresses that were previously selected by the current user.
  const showPreviouslySelectedAddresses = () => {
    const storedAddresses = getStoredAddresses();

    if (storedAddresses.length > 0) {
      updateResults(storedAddresses);
    }
  };

  // Save an address to local storage
  const saveSelectedAddress = (id: string, value: string) => {
    const { siteName } = getUrlDetails();
    const addressKey = `${siteName}.selected.addresses`;
    const storedAddresses = getStoredAddresses();

    // Check if this address is already stored.
    const isAddressStored = storedAddresses.some(addressItem => addressItem.value === value);

    if (!isAddressStored) {
      // Add new address, and if there's 5 or more addresses, remove the oldest one.
      const newStoredAddresses = [{ id, value }, ...storedAddresses.slice(0, 4)];
      localStorage.setItem(addressKey, JSON.stringify(newStoredAddresses));
    }
  };

  const fetchAddressResult = debounce((address: string) => {
    if (address && address.length > 2) {
      updateIsLoading(true);
      updateIsNotFound(false);
      const bounds = mapCentre
        ? {
            location: {
              latitude: mapCentre.latitude,
              longitude: mapCentre.longitude,
            },
            radius: 2000,
          }
        : undefined;
      addressSearchResults({ client, address, bounds })
        .then(response => {
          updateIsLoading(false);
          if (response.length) {
            const searchResults = addressToSearchResult(response);
            updateResults(searchResults);
          } else {
            updateIsNotFound(true);
          }
        })
        .catch(() => {
          updateResults([]);
          updateIsLoading(false);
        });
    } else {
      onClearResult();
    }
  }, 500);

  const onSelectedResult = ({ id, value }) => {
    geocodeCandidateDetail({ client, id })
      .then(response => {
        updateIsLoading(false);
        if (onAddressFound) {
          onAddressFound({
            ...response,
            autoCompleteAddress: value,
          });
        }
      })
      .catch(() => {});

    saveSelectedAddress(id, value);
    updateResults([]);
  };

  const onClearResult = () => {
    updateResults([]);
    updateIsLoading(false);
    updateIsNotFound(false);
  };

  let autoFocusOnOpen = false;
  let hideClearBtnOnSelect = false;
  const translationData = {
    placeholder: placeholderText,
    notFound: notFoundText,
  };
  switch (source) {
    case 'map':
      autoFocusOnOpen = true;
      break;
    case 'complaint':
      hideClearBtnOnSelect = true;
      translationData.placeholder = '';
      break;
  }

  return (
    <>
      <AutoSearch
        searchResults={results}
        overwriteInputValue={
          formRelatedValues &&
          typeof formRelatedValues.value !== undefined &&
          formRelatedValues.value
            ? formRelatedValues.value
            : undefined
        }
        resultsNotFound={isNotFound}
        onSearchFocus={showPreviouslySelectedAddresses}
        onSearchChange={fetchAddressResult}
        autoFocusOnOpen={autoFocusOnOpen}
        hideClearBtnOnSelect={hideClearBtnOnSelect}
        isLoading={isLoading}
        disabled={
          formRelatedValues &&
          typeof formRelatedValues.disabled !== undefined &&
          formRelatedValues.disabled
            ? formRelatedValues.disabled
            : undefined
        }
        onSelectedResult={onSelectedResult}
        onClearResult={onClearResult}
        translationData={translationData}
      />
    </>
  );
};
