import { IMonitorLocation, LocationItem, LocationManagementActionPayload } from '../interfaces';
import { LocationManagementContextState } from './LocationManagementContext';

export const LocationManagementActions = {
  UPDATE_LOCATION_ITEMS: 'UPDATE_LOCATION_ITEMS',
  TOGGLE_IS_EDITING: 'TOGGLE_IS_EDITING',
  SET_LOCATIONS_LIST: 'SET_LOCATIONS_LIST',
  SET_DEVICE_LOCATION: 'SET_DEVICE_LOCATION',
};

export const locationManagementReducer = (
  state: LocationManagementContextState,
  action: LocationManagementActionPayload
) => {
  switch (action.type) {
    case LocationManagementActions.UPDATE_LOCATION_ITEMS: {
      const { returnedData } = action.payload as { returnedData: LocationItem[] };
      return { ...state, nmtListData: returnedData };
    }
    case LocationManagementActions.SET_DEVICE_LOCATION: {
      const { deviceMonitorLocation } = action.payload as {
        deviceMonitorLocation: IMonitorLocation;
      };
      return { ...state, deviceMonitorLocation };
    }
    case LocationManagementActions.SET_LOCATIONS_LIST: {
      const { locationsList } = action.payload as {
        locationsList: IMonitorLocation[];
      };
      return { ...state, locationsList };
    }
    case LocationManagementActions.TOGGLE_IS_EDITING: {
      const { isEditing } = action.payload as { isEditing: boolean };
      return { ...state, isEditing };
    }
    default:
      return { ...state };
  }
};
