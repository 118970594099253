import { useState } from 'react';
import { useRolesSelectors } from 'src/app/reducers';
// constants
import { PERMISSIONS } from 'src/constants';

interface IPermission {
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export const isDefined = (key: undefined | null | string) => typeof key !== undefined && key;

export const getPermissions = (selector, permissionKey: undefined | null | string): IPermission => {
  const definition: IPermission = {
    canRead: isDefined(permissionKey) ? selector.hasPermission(`${permissionKey}.${PERMISSIONS.READ}`) : true,
    canUpdate: false,
    canDelete: false,
  };
  if (isDefined(permissionKey) && definition.canRead) {
    definition.canUpdate = selector.hasPermission(`${permissionKey}.${PERMISSIONS.UPDATE}`);
    definition.canDelete = selector.hasPermission(`${permissionKey}.${PERMISSIONS.DELETE}`);
  }
  return definition;
};

export const usePermissions = (permissionKey: string): IPermission => {
  const rolesSelector = useRolesSelectors();
  const [permission] = useState<IPermission>(getPermissions(rolesSelector, permissionKey));
  return permission;
};
