// props
import { IRaygunOptions } from 'src/app/props';
// functions
import { parseJwt } from 'src/app/functions/core';
import { getAuth } from 'src/app/functions/storage';

declare var rg4js: (method: string, option: IRaygunOptions) => void;

// set up the library to transmit data for the currently logged-in user
export const registerUserInRaygun = (): void => {
  if (process.env.NODE_ENV === 'production') {
    const authData = getAuth();
    if (typeof authData !== 'undefined' && authData && typeof authData.profile !== 'undefined') {
      const {
        tid: identifier
      } = parseJwt(authData.profile);
      const userData = {
        identifier,
        isAnonymous: false,
      }
      rg4js('setUser', userData);
    }
  }
};
