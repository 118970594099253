import React, { createContext, useReducer } from 'react';
// reducers
import {
  dataInitialState,
  dataReducer,
  sortInitialState,
  sortReducer,
  breakdownIntialState,
  breakdownReducer,
  viewIntialState,
  viewReducer,
} from 'src/@airtrak/reducers';
import { filterInitialState, filterReducer } from 'src/@airtrak/reducers/filterReducer';
// Hooks
import { usePersistentReducer } from 'src/utils';
// interfaces
import { IStoreState } from 'src/@airtrak/interfaces';

export const AirtrakStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
  breakdown: breakdownIntialState,
  view: viewIntialState,
  filters: filterInitialState,
});

export const AirtrakDispatchContext = createContext<React.Dispatch<any> | null>(null);

export const AirtrakStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  const [breakdownState, breakdownDispatch] = useReducer(breakdownReducer, breakdownIntialState);
  const [viewState, viewDispatch] = useReducer(viewReducer, viewIntialState);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [filterState, filterDispatch] = usePersistentReducer(
    filterReducer,
    filterInitialState,
    'airtrak.filter.state',
    'session'
  );

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    breakdown: breakdownState,
    view: viewState,
    filters: filterState,
  };

  const dispatchers = [dataDispatch, sortDispatch, breakdownDispatch, viewDispatch, filterDispatch];

  const dispatch: (action: string) => void = (action: string) =>
    dispatchers.map((dispatcher: React.Dispatch<any>) => {
      dispatcher(action);
    });
  return (
    <AirtrakStateContext.Provider value={state}>
      <AirtrakDispatchContext.Provider value={dispatch}>{children}</AirtrakDispatchContext.Provider>
    </AirtrakStateContext.Provider>
  );
};
