import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

// Selectors & utils
import { useLanguageSelectors } from 'src/app/reducers';
import { getDeployedProductId, tableDateTimeWithSecondsFormatShort } from 'src/utils';

// Components
import { CorrelatedEventCollapse, CorrelatedBlockTitle } from 'src/components';
import { Link } from 'react-router-dom';

// Constants
import {
  NOISEEVENTS,
  UNIT_DECIBEL,
  OPERATION_CORRELATION_COUNT_LIMIT,
  BLANK_STRING_PLACEHOLDER,
} from 'src/constants';
import { useHasRoutePermissions } from 'src/@operations/hooks/useHasRoutePermissions';

interface INoiseEvent {
  id: number;
  maxLevel: number;
  locationName: string;
  duration: number;
  time: string;
}

interface IOperationNoiseEventsContainer {
  noiseData: INoiseEvent[];
}

export const OperationNoiseEventsContainer = ({ noiseData }: IOperationNoiseEventsContainer) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: {
        correlatedCategories: { noiseEvents: title },
      },
    },
  } = languageSelectors.getLanguage();

  const timeList: DateTime[] = noiseData.map(event =>
    DateTime.fromISO(event.time, { setZone: true })
  );
  const idList = noiseData.map(event => event.id);

  return (
    <div className="block">
      <CorrelatedBlockTitle
        idList={idList}
        timeList={timeList}
        blockTitle={title}
        screenPath={NOISEEVENTS}
      />

      {noiseData.map(({ id, maxLevel, locationName, time }, key) => {
        if (key > OPERATION_CORRELATION_COUNT_LIMIT - 1) {
          return null;
        }
        const maxLevelDesc = maxLevel ? `${maxLevel.toFixed(1)} ${UNIT_DECIBEL}` : '—';

        const cardHeader = (
          <>
            {useHasRoutePermissions(NOISEEVENTS) ? (
              <Link
                className="correlatedCollapse__header-name"
                to={{
                  pathname: `/${getDeployedProductId()}/${NOISEEVENTS}/${id}`,
                }}>
                <span>{locationName.trim() ? locationName : BLANK_STRING_PLACEHOLDER}</span>
              </Link>
            ) : (
              <span className="correlatedCollapse__header-name">
                {locationName.trim() ? locationName : BLANK_STRING_PLACEHOLDER}
              </span>
            )}

            <dl className="correlatedCollapse__header-data">
              <dt>{maxLevelDesc}</dt>
              <dd className="sr-only">Max sound level</dd>
            </dl>
            <span className="correlatedCollapse__header-data">
              {tableDateTimeWithSecondsFormatShort(time, false)}
            </span>
          </>
        );

        return <CorrelatedEventCollapse key={uuidv4()} headerChildren={cardHeader} />;
      })}
    </div>
  );
};
