import { useEffect, useMemo } from 'react';
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { TTableFilterTypes } from 'src/components';
import { BLANK_STRING_PLACEHOLDER, TABLE_CONSTANTS } from 'src/constants';
import { useOperationTheme } from 'src/utils';
import {
  useClearTableFilters,
  useInitializeTableFilter,
  useSetInitializedTableFilters,
} from 'src/utils/tableHelpers/tableFilterHelpers';
import { IModelingAircraft, ITableData } from '../interfaces';
import { useColumnData } from './useColumnData';
import { useSelectedNoiseModel } from './useSelectedNoiseModel';

export const useAircraftAssignmentTableFilters = (
  isLoading: boolean,
  tableData: ITableData[],
  noiseModelingAircraftTypes: IModelingAircraft[],
  beforeFilter: () => void,
  dateStore
) => {
  const columnData = useColumnData();
  const filtersSelectors = useFilterDataSelectors();
  const operationFilterData = filtersSelectors.getOperationsFilterData();
  const { engineModels } = filtersSelectors.getModelingAircraftAssignmentsData();
  const { initializedTableFilter } = useInitializeTableFilter(isLoading, tableData, columnData);

  const languageSelector = useLanguageSelectors();

  const {
    fields: { aircraftAssignments },
  } = languageSelector.getLanguage();
  const operationTheme = useOperationTheme();
  const selectedNoiseModel = useSelectedNoiseModel();

  // Add Operation Types as the first filter
  const operationTypes = [
    { key: 'Arrival', label: 'Arrival', icon: 'ad-arrival', className: '' },
    { key: 'Departure', label: 'Departure', icon: 'ad-departure', className: '' },
  ];
  const operationTypesFilterObject = {
    title: aircraftAssignments.operationTypes,
    key: 'operationTypes',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'operationTypes',
    filterItems: operationTypes,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
    theme: operationTheme,
  };
  const airportsFilterObject = {
    title: aircraftAssignments.airports,
    key: 'airportIds',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'airportIds',
    filterItems: operationFilterData.airportIds.map(id => ({
      key: id,
      label: id,
    })),
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const engineModelFilterOptions = [
    { key: null, label: BLANK_STRING_PLACEHOLDER },
    ...engineModels.map(model => ({
      key: model,
      label: model,
    })),
  ];
  const engineModelFilterObject = {
    title: aircraftAssignments.assignmentEngine,
    key: 'engineModels',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'engineModels',
    filterItems: engineModelFilterOptions,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  // Add noise model type as last filter
  const modelingAircraftTypesFilterItems = [
    { key: null, label: BLANK_STRING_PLACEHOLDER },
    ...noiseModelingAircraftTypes.map(item => ({
      label: item.aircraftName,
      key: item.aircraftName,
    })),
  ];

  const noiseModelFilter = {
    title: `${selectedNoiseModel} ${aircraftAssignments.assignmentModel}`,
    key: 'modelingAircraftNames',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'modelingAircraftNames',
    filterItems: modelingAircraftTypesFilterItems,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  /*
    Add filters where the value is a JSX element thus it can't be initialized in the useInitializeTableFilter hook
    */

  const tableFilters = useMemo(() => {
    const filter = { ...initializedTableFilter };
    filter.filters.splice(0, 0, operationTypesFilterObject);
    filter.filters.splice(2, 0, engineModelFilterObject);
    filter.filters.push(noiseModelFilter);
    filter.filters.push(airportsFilterObject);
    return filter;
  }, [initializedTableFilter]);

  const {
    tableFilterObject,
    setTableFilterObject,
    isFilterInitialized,
  } = useSetInitializedTableFilters(tableFilters);

  const onClearFilters = useClearTableFilters(
    tableFilterObject,
    setTableFilterObject,
    beforeFilter
  );

  useEffect(() => {
    onClearFilters();
  }, [dateStore]);

  return {
    tableFilterObject,
    setTableFilterObject,
    onClearFilters,
    isTableFilterInitialized: isFilterInitialized,
  };
};
