import { FormErrorDisplay } from '@ems/client-design-system';
import React, { ReactElement } from 'react';
import { IInfDetailsData } from 'src/@settings/interfaces';
import {
  nameInput,
  infringementTypeDropdown,
  runwayFilter,
  operationTypesFilter,
  aircraftCategoriesFilter,
  operatorCategoriesFilter,
  MTOWinput,
  MLWinput,
  getInfDetailsPanelDaysOfWeek,
  getStartEndTimeInput,
} from './InfringementRuleDetailsHelpers';

const getInfDetailsPanelItem = (itemClass: string, labelText: string, value: ReactElement) => (
  <div className={`infdetailspanel-item ${itemClass}`}>
    <div className="infdetailspanel-label">{labelText}</div>
    <div className="infdetailspanel-value">{value}</div>
  </div>
);

interface ICandidatePanelProps {
  infDetailsData: IInfDetailsData;
}
export const CandidatePanel: React.FC<ICandidatePanelProps> = ({ infDetailsData }) => {
  return (
    <div className="infdetailspanel">
      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left infdetails-name">
          {nameInput(infDetailsData)}
          <FormErrorDisplay
            displayError={infDetailsData.isEditing}
            error={infDetailsData.errors ? infDetailsData.errors.name : undefined}
          />
        </div>
        <div className="infdetailspanel-row-right infdetails-type">
          {infringementTypeDropdown(infDetailsData)}
        </div>
      </div>

      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left">{getStartEndTimeInput(infDetailsData)}</div>
        <div className="infdetailspanel-row-right">
          {getInfDetailsPanelDaysOfWeek(infDetailsData)}
        </div>
      </div>

      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left">
          {getInfDetailsPanelItem(
            'operationTypes',
            'Operation type',
            operationTypesFilter(infDetailsData)
          )}
        </div>
        <div className="infdetailspanel-row-right">
          {getInfDetailsPanelItem('runways', 'Runway', runwayFilter(infDetailsData))}
        </div>
      </div>

      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left">
          {getInfDetailsPanelItem(
            'aircraftcategories',
            'Aircraft category',
            aircraftCategoriesFilter(infDetailsData)
          )}
        </div>
        <div className="infdetailspanel-row-right">
          {getInfDetailsPanelItem(
            'operatortypes',
            'Operator type',
            operatorCategoriesFilter(infDetailsData)
          )}
        </div>
      </div>

      <div className="infdetailspanel-row">
        <div className="infdetailspanel-row-left">{MTOWinput(infDetailsData)}</div>
        <div className="infdetailspanel-row-right">{MLWinput(infDetailsData)}</div>
      </div>
    </div>
  );
};
