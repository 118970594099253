import React from 'react';
import { withRouter } from 'react-router';
// components
import { NavBar } from 'src/components/Navbar';

const RegularLayoutScreen = props => {
  return (
    <NavBar>
      <div className="page-wrapper">{props.children}</div>
    </NavBar>
  );
};

export const RegularLayout = withRouter(RegularLayoutScreen);
