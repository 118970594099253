import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { selectionZoneQuery, selectionZoneSchema } from './schema';
import { ISelectionZone } from 'src/utils/spatialFeatureHelpers/interfaces';

export const fetchAllZones = (client: ApolloClient<object>) =>
  new Promise<ISelectionZone[]>((resolve, reject) => {
    const GET_ZONE = gql`
      query {
        ${selectionZoneQuery} ${selectionZoneSchema}
      }
    `;
    client
      .query({
        query: GET_ZONE,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchSelectionZoneById = (client: ApolloClient<object>, selectionZoneId: number) =>
  new Promise<ISelectionZone>((resolve, reject) => {
    const queryParams = ` id : ${selectionZoneId}`;
    const GET_ZONE = gql`
    query {
        selectionZone(${queryParams}) ${selectionZoneSchema}
      
    }
  `;
    client
      .query({
        query: GET_ZONE,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data.selectionZone);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });

export const fetchSelectionZonesByIds = (client: ApolloClient<object>, zoneIds: number[]) =>
  new Promise<ISelectionZone[]>((resolve, reject) => {
    const queryParams = `ids :[${zoneIds}]`;
    const GET_ZONES = gql`
  query {
    selectionZonesByIds(${queryParams}) ${selectionZoneSchema}
    
  }
`;
    client
      .query({
        query: GET_ZONES,
      })
      .then(response => {
        if (response && response.data && response.data.selectionZonesByIds) {
          resolve(response.data.selectionZonesByIds);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
