import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Switch, Route } from 'react-router-dom';
// components
import { PageSkeleton, ScrollToTopOnRoute } from 'src/components';
// functions
import { getRoutes } from 'src/app/functions/getRoutes';
import { additionalRequiredRoutes } from 'src/app/functions/routing';
// selectors
import {
  useConfigSelectors,
  useFilterDataSelectors,
  useLanguageSelectors,
  useRolesSelectors,
  useInfringementRulesSelectors,
  usePushNoticeSelectors,
} from 'src/app/reducers';
import {
  GlobalDispatchContext,
  PushNoticesDispatchContext,
} from 'src/app/providers/GlobalStateProvider';
import {
  loadAppConfig,
  checkPushNotices,
  loadFilters,
  loadAppLanguage,
  loadAppRoles,
  loadInfringementRules,
  ignoreInfringementRules,
} from 'src/app/actions';

import { Router } from 'react-router-dom';
import { history } from 'src/utils';

export const PageRouter = () => {
  const client = useApolloClient();
  const dispatcher = useContext(GlobalDispatchContext);
  const configSelectors = useConfigSelectors();
  const filterDataSelectors = useFilterDataSelectors();
  const languageSelectors = useLanguageSelectors();
  const roleSelectors = useRolesSelectors();
  const infringementRulesSelectors = useInfringementRulesSelectors();
  const config = configSelectors.getConfig();
  const pushNoticesDispatcher = useContext(PushNoticesDispatchContext);
  const pushNoticesSelector = usePushNoticeSelectors();
  const { languageTag } = configSelectors.getUserConfig();

  // Config loader, only load once for now.
  useEffect(() => {
    loadAppRoles(client, dispatcher);
    loadAppConfig(client, dispatcher);
  }, []);

  useEffect(() => {
    if (roleSelectors.hasCompletedInitialLoad()) {
      if (roleSelectors.canAccess('Infringement', ['Read'])) {
        loadInfringementRules(client, dispatcher);
      } else {
        ignoreInfringementRules(client, dispatcher);
      }
    }
  }, [roleSelectors]);
  // Load in languages and check push notices
  useEffect(() => {
    if (configSelectors.hasCompletedInitialLoad() && config.globals) {
      const { availableLanguages } = config.globals;
      const defaultSiteLanguage = languageSelectors.getDefaultLanguageTag();
      loadFilters(dispatcher, config);
      loadAppLanguage(
        client,
        dispatcher,
        [...availableLanguages, defaultSiteLanguage].includes(languageTag)
          ? languageTag
          : defaultSiteLanguage
      );
      checkPushNotices(client, pushNoticesDispatcher);
    }
  }, [configSelectors]);

  // Before we should render any pages, we should first ensure our core app
  // stores are initially loaded first.
  if (
    !configSelectors.hasCompletedInitialLoad() ||
    !filterDataSelectors.hasCompletedInitialLoad() ||
    !languageSelectors.hasCompletedInitialLoad() ||
    !roleSelectors.hasCompletedInitialLoad() ||
    !infringementRulesSelectors.hasCompletedInitialLoad() ||
    !pushNoticesSelector.hasCompletedInitialLoad()
  ) {
    return <PageSkeleton />;
  }

  const {
    globals: { defaultRoute, availableRoutes: modifyAvailableRoutes },
  } = config;
  const availableRoutes: string[] = additionalRequiredRoutes(modifyAvailableRoutes);

  const routes = getRoutes(
    defaultRoute,
    availableRoutes.map(item => item.replace('{id}', ':id')), // mapping to make sure available routes match the expected format
    roleSelectors
  );

  return (
    <Router history={history}>
      <>
        <ScrollToTopOnRoute history={history} />
        <Switch>
          {routes.map((route: any, i: number) => (
            <Route key={i} {...route} />
          ))}
        </Switch>
      </>
    </Router>
  );
};
