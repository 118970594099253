import React from 'react';
import cx from 'classnames';

// Constants
import { USER_SETTINGS } from 'src/constants';

interface ILocalUserSettingsSubset {
  isPortrait?: boolean;
  layout?: string;
}

const getLocalUserSettings = (): ILocalUserSettingsSubset => {
  try {
    return JSON.parse(localStorage.getItem(USER_SETTINGS) || '{}') as ILocalUserSettingsSubset;
  } catch {
    return {};
  }
};

export const PageSkeleton = () => {
  const pathParts = window.location.pathname.split('/').filter(String);
  const settings = getLocalUserSettings();

  const isLandscape = typeof settings.isPortrait !== 'undefined' && !settings.isPortrait;
  const currentLayout =
    typeof settings.layout !== 'undefined' ? settings.layout : 'SPLIT_LANDSCAPE_LAYOUT';
  const isFullScreen = currentLayout.includes('FULL_SCREEN');

  if (pathParts.indexOf('settings') === 1) {
    return (
      <div className="skeleton-settings__wrapper">
        <div className="skeleton-settings__navigation" />
        <div className="skeleton-settings__container" />
      </div>
    );
  }

  return (
    <>
      <div className="navbar-container" />
      <div className="navbar-children">
        <div
          className={cx('page-wrapper', {
            'page-wrapper--landscape': isLandscape && !isFullScreen,
            'page-wrapper--fullscreen--map': currentLayout === 'FULL_SCREEN_MAP_LAYOUT',
            'page-wrapper--fullscreen--grid': currentLayout === 'FULL_SCREEN_GRID_LAYOUT',
          })}>
          <div className="map-skeleton" />

          <div className="content-skeleton" />
        </div>
      </div>
    </>
  );
};
