import React, { useEffect, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import {
  getAirportRunways,
  getAllGates,
  getAllCorridors,
  getAllNoiseMonitors,
  getAllZones,
  getSpatialFeatures,
} from 'src/@settings/actions';
import { useInfringementRulesSelectors } from 'src/app/reducers';
import {
  useTimeInModesSelector,
  useSpatialFeaturesSelector,
  useInfRulesSettingsSelector,
} from 'src/@settings/reducers';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import { InfringementRulesList } from './InfringementRulesList';
import { InfringementRuleDetails } from './InfringementRuleDetails/InfringementRuleDetails';
import { formatAirportAndRunwayData } from './InfringementRulesContainerHelpers';
// import { loadInfringementRules } from 'src/app/actions';
// import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';

import { useParams } from 'react-router-dom';

export const InfringementsRulesContainer = ({}) => {
  // Global state selectors
  const infringementRulesSelector = useInfringementRulesSelectors();

  // SETTINGS STATE SELECTORS
  const timeInModesSelector = useTimeInModesSelector();
  const spatialFeaturesSelector = useSpatialFeaturesSelector();
  const infRulesSettingsSelector = useInfRulesSettingsSelector();
  const pageParams: { id: string } = useParams();
  const [ruleId, setRuleId] = useState<number | null>(Number(pageParams.id) || null);

  const infRules = infringementRulesSelector.getRules();
  const airportsData = timeInModesSelector.getAirports();
  const allGates = spatialFeaturesSelector.getAllGates();
  const allCorridors = spatialFeaturesSelector.getAllCorridors();
  const allNoiseMonitors = infRulesSettingsSelector.getAllNoiseMonitors();
  const allZones = spatialFeaturesSelector.getAllZones();

  const { airportIdsByRunwayName, runwayNamesSortedByAirport } = formatAirportAndRunwayData(
    airportsData
  );

  const client = useApolloClient();
  const settingsDispatcher: any = useContext(SettingsDispatchContext);

  useEffect(() => {
    if (infRules) {
      getAirportRunways(client, settingsDispatcher);
      getAllGates(client, settingsDispatcher);
      getAllCorridors(client, settingsDispatcher);
      getAllZones(client, settingsDispatcher);
      getAllNoiseMonitors(client, settingsDispatcher);
      getSpatialFeatures({ client, dispatcher: settingsDispatcher });
    }
  }, [infRules]);

  return (
    <div className="settings__split infringementrules-container">
      {ruleId ? (
        <InfringementRuleDetails
          key={`rule-details ${ruleId}`}
          ruleId={ruleId}
          setRuleId={setRuleId}
          airportIdsByRunwayName={airportIdsByRunwayName}
          runwayNamesSortedByAirport={runwayNamesSortedByAirport}
          allGates={allGates}
          allCorridors={allCorridors}
          allZones={allZones}
          allNoiseMonitors={allNoiseMonitors}
        />
      ) : (
        <InfringementRulesList setRuleId={setRuleId} />
      )}
    </div>
  );
};
