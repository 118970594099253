// client
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { airlineMappingsSchema, airlineMappingsQuery } from './schema';
import { IScenario } from 'src/app/props';

export const fetchAirlines = (client: ApolloClient<object>, scenario: IScenario) => {
  const { id } = scenario;

  const queryParams = `scenarioIds: [${id}]`;
  const GET_AIRLINE_MAPPINGS = gql`
    query {
      ${airlineMappingsQuery} (${queryParams}) ${airlineMappingsSchema}
    }`;

  return new Promise((resolve, reject) => {
    client
      .query({
        query: GET_AIRLINE_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[airlineMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
