import React from 'react';

// Types
import { TFilterLoadRange } from 'src/components';

export const withQueryStringUpdater = ({
  Container,
  updateUrl,
  moduleName,
  scenario,
  defaultRange,
  displayShortDate,
}: {
  Container;
  updateUrl: any;
  moduleName?: string;
  scenario?: object;
  defaultRange?: TFilterLoadRange;
  displayShortDate?: boolean;
}) => () => (
  <Container
    updateUrl={updateUrl}
    moduleName={moduleName}
    scenario={scenario}
    defaultRange={defaultRange}
    displayShortDate={displayShortDate}
  />
);
