import React, { createContext, useReducer } from 'react';
// reducers
import {
  dataInitialState,
  dataReducer,
  sortInitialState,
  sortReducer,
} from 'src/@complaints/reducers';
// interfaces
import { IStoreState } from 'src/@complaints/interfaces';
import { filterInitialState, filterReducer } from 'src/@complaints/reducers/filterReducer';
// Hooks
import { usePersistentReducer } from 'src/utils';

export const ComplaintsStateContext = createContext({
  data: dataInitialState,
  sort: sortInitialState,
  filters: filterInitialState,
});

export const ComplaintsDispatchContext = createContext<React.Dispatch<any> | null>(null);

export const ComplaintsStateProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, dataInitialState);
  const [sortState, sortDispatch] = useReducer(sortReducer, sortInitialState);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [filterState, filterDispatch] = usePersistentReducer(
    filterReducer,
    filterInitialState,
    'complaints.filter.state',
    'session'
  );

  const state: IStoreState = {
    data: dataState,
    sort: sortState,
    filters: filterState,
  };

  const dispatchers = [dataDispatch, sortDispatch, filterDispatch];

  const dispatch: (action: string) => void = (action: string) =>
    dispatchers.map((dispatcher: React.Dispatch<any>) => {
      dispatcher(action);
    });

  return (
    <ComplaintsStateContext.Provider value={state}>
      <ComplaintsDispatchContext.Provider value={dispatch}>
        {children}
      </ComplaintsDispatchContext.Provider>
    </ComplaintsStateContext.Provider>
  );
};
