import React, { useContext, useState, useEffect, FC } from 'react';
// components
import { Table, Button, Spinner } from '@ems/client-design-system';
// provider
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
// selectors
import { useTableSelectors, useLoadFactorsSelector } from 'src/@settings/reducers';
import {
  useLanguageSelectors,
  useFilterDataSelectors,
  useScenariosSelectors,
  useConfigSelectors,
} from 'src/app/reducers';
// functions
import {
  updateTableIds,
  formatLoadFactorsData,
  getDayWithPosition,
  formatFilterItems,
  getFormattedMutationItem,
  getColumnTypes,
} from 'src/@settings/functions';
import { translateLabels } from 'src/@airtrak/functions';
// interfaces
import {
  ILoadFactorsContainer,
  ILoadFactorsData,
  IMutationItem,
  TMode,
} from 'src/@settings/interfaces';
// utils
import { convertMapToArray, sortTableData, timePlusMinusDuration, formatHeaders } from 'src/utils';
import { capitalizeObjectKeys } from 'src/utils/objectModifiers';
// constants
import {
  LOADFACTORS_FUTURE_YEARS,
  LOADFACTORS_MODULE,
  MANAGE_CONFIGURATIONS_MODULE,
  MAX_LOAD_FACTOR_PERCENTAGE,
  UNIT_PERCENTAGE,
} from 'src/constants';
import { Prompt } from 'react-router-dom';
import { IScenario } from 'src/app/props';
import { TextField } from '../components';
import { sortLoadFactorsTable } from '../actions';

export const LoadFactorsContainer: FC<ILoadFactorsContainer> = ({
  setChangesAvailable,
  areChangesDiscarded,
  updateLoadFactorsData,
  updateScenarios,
  discardMutationData,
}) => {
  // Config
  const configSelectors = useConfigSelectors();
  const {
    formValidations: {
      airtrak: { percentageOfFlights: percentageOfFlightsValidation },
    },
  } = configSelectors.getConfig();

  // dispatchers
  const dispatcher = useContext(SettingsDispatchContext);
  const [rowData, setRowData] = useState<ILoadFactorsData[]>([]);

  const tableSelectors = useTableSelectors();
  const sortObject = tableSelectors.getSortObject(LOADFACTORS_MODULE);
  const sortString = tableSelectors.getSortString(LOADFACTORS_MODULE);
  const languageSelectors = useLanguageSelectors();
  const translationData = languageSelectors.getLanguage();
  const loadFactorsSelector = useLoadFactorsSelector();
  const scenariosSelector = useScenariosSelectors();
  const activeScenario = scenariosSelector.getActiveScenario();
  const items = loadFactorsSelector.getLoadFactors();
  const loading: boolean = loadFactorsSelector.getIfLoading();
  const [disableDelete, setDisableDelete] = useState(true);
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [highlightRow, setHighlightRow] = useState<number>();
  const [mutationData, setMutationData] = useState<Map<number, IMutationItem>>(new Map());

  const [data, setData] = useState(items);
  const copyOfLoadFactors = items.map(item => ({ ...item }));
  const [apiData, setApiData] = useState(copyOfLoadFactors);

  const {
    fields: {
      loadFactors: {
        airline: airlineColumnHeader,
        aircraft: aircraftColumnHeader,
        origin: originColumnHeader,
        destination: destinationColumnHeader,
        fromDatetime: fromDatetimeColumnHeader,
        toDatetime: toDatetimeColumnHeader,
        dayOfWeek: dayOfWeekColumnHeader,
        loadFactor: loadFactorColumnHeader,
      },
    },
    components: {
      lists: { daysOfWeek },
      buttons: { deleteSelected, addNew },
      labels: { loadFactorHeaderText, loadFactorTableDescription, loadFactorLabel },
      hints: { noDataTitle, tryChangingFiltersOrDate, areYouSureYouWantToLeave },
    },
  } = translationData;

  // Table header set up
  const columnData = [
    {
      columnName: 'airline',
      key: 'airline',
      title: airlineColumnHeader,
    },
    { columnName: 'aircraftType', key: 'aircraftType', title: aircraftColumnHeader },
    {
      columnName: 'origin',
      key: 'origin',
      title: originColumnHeader,
    },
    {
      columnName: 'destination',
      key: 'destination',
      title: destinationColumnHeader,
    },
    {
      columnName: 'startTime',
      key: 'startTime',
      title: fromDatetimeColumnHeader,
    },
    { columnName: 'endTime', key: 'endTime', title: toDatetimeColumnHeader },
    {
      columnName: 'dayOfWeek',
      key: 'dayOfWeek',
      title: dayOfWeekColumnHeader,
    },
    {
      columnName: 'loadFactor',
      key: 'loadFactor',
      title: loadFactorColumnHeader,
    },
  ];

  const rowHeaders = formatHeaders(
    LOADFACTORS_MODULE,
    columnData,
    rowData.length,
    dispatcher,
    sortLoadFactorsTable,
    tableSelectors,
    languageSelectors.getLanguage(),
    'loadFactors',
    false,
    true
  );

  const loadFactorsColumns: string[] = columnData.map(header => header.columnName);

  const filtersSelectors = useFilterDataSelectors();
  const settingsFilterData = filtersSelectors.getSettingsFiltersData();
  const daysItems = settingsFilterData.daysOfWeek.map((item, index) => ({
    shortcut: getDayWithPosition(index),
    name: item,
  }));
  const formattedDaysItems = formatFilterItems(daysItems);
  const convertedDataList = capitalizeObjectKeys({
    ...daysOfWeek,
  });
  const translatedDayItems = translateLabels(formattedDaysItems, convertedDataList);
  const defaultLoadFactorsConfigurationData = {
    defaultLoadFactor: activeScenario.defaultLoadFactor,
  };
  const [loadFactorsConfigurationData, setLoadFactorsConfigurationData] = useState(
    defaultLoadFactorsConfigurationData
  );
  const [configfurationMutationData, setConfigfurationMutationData] = useState<IMutationItem[]>([]);
  const setdefaultLoadFactorsConfigurationData = obj => {
    const loadFactorsConfigurations = Object.assign({}, loadFactorsConfigurationData, obj);
    setLoadFactorsConfigurationData(loadFactorsConfigurations);
    const { items } = convertMapToArray(
      getFormattedMutationItem(
        'Update',
        Object.assign({}, activeScenario, obj),
        new Map<number, IMutationItem>(),
        MANAGE_CONFIGURATIONS_MODULE
      )
    );
    updateScenarios(items);
    setChangesAvailable(true);
    setConfigfurationMutationData(items);
  };

  const getMinMaxDateRange = (activeScenario: IScenario) => {
    const loadFactorFutureDate = new Date(
      timePlusMinusDuration(new Date().toISOString(), { years: LOADFACTORS_FUTURE_YEARS })
    );
    return {
      minDate: new Date(activeScenario.startTime),
      maxDate: activeScenario.isDefault ? loadFactorFutureDate : new Date(activeScenario.endTime),
    };
  };

  useEffect(() => {
    formatAndSetRowData(data);
  }, [data]);

  useEffect(() => {
    setData(apiData);
    formatAndSetRowData(apiData);
    setLoadFactorsConfigurationData(defaultLoadFactorsConfigurationData);
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [areChangesDiscarded]);

  useEffect(() => {
    mutationData.clear();
    setMutationData(mutationData);
    setConfigfurationMutationData([]);
  }, [discardMutationData]);

  const updateLoadFactor = (loadFactorId, selectedItem) => {
    setData(prev => {
      const prevData = prev.slice(0);
      prevData[loadFactorId] = { ...prevData[loadFactorId], ...selectedItem };
      handleMutation('Update', prevData[loadFactorId]);
      return prevData;
    });
    setChangesAvailable(true);
  };

  const formatAndSetRowData = data => {
    const { minDate, maxDate } = getMinMaxDateRange(activeScenario);
    const formattedData = formatLoadFactorsData(
      updateTableIds([], data),
      translationData,
      settingsFilterData,
      translatedDayItems,
      updateLoadFactor,
      minDate,
      maxDate,
      percentageOfFlightsValidation.match
    );
    setRowData(formattedData);
  };

  useEffect(() => {
    setData(sortTableData(items, sortObject));
    setApiData(items);
  }, [items]);

  useEffect(() => {
    const sortData = sortTableData(data, sortObject);
    setData(sortData);
  }, [sortString]);

  const handleMutation = (mode: TMode, updatingItem) => {
    setMutationData(getFormattedMutationItem(mode, updatingItem, mutationData, LOADFACTORS_MODULE));
    const { items } = convertMapToArray(mutationData);
    updateLoadFactorsData(items);
  };

  const onSelectRow = selectedIndexes => {
    setSelectedInTable(selectedIndexes);
    selectedIndexes.length > 0 ? setDisableDelete(false) : setDisableDelete(true);
  };

  const addNewRow = () => {
    const { minDate } = getMinMaxDateRange(activeScenario);
    const addNewDefaultRowData = {
      airline: '',
      aircraftType: '',
      origin: '',
      destination: '',
      startTime: minDate,
      endTime: new Date(),
      loadFactor: loadFactorsConfigurationData.defaultLoadFactor,
      dayOfWeek: 'SMTWTFS',
      id: new Date().getTime(),
      scenarioId: activeScenario.id,
    };
    setHighlightRow(0);
    handleMutation('Insert', addNewDefaultRowData);
    setData(updateTableIds([addNewDefaultRowData], data));
  };

  const onDeleteClick = () => {
    const deletingItems = getDeletingItems();
    deletingItems.map(item => {
      handleMutation('Delete', item);
    });
    const updatedData = data.filter((item, index) => !selectedInTable.includes(index));
    setData(updateTableIds([], updatedData));
    setSelectedInTable([]);
    mutationData.size > 0 ? setChangesAvailable(true) : setChangesAvailable(false);
  };

  const getDeletingItems = () => data.filter((item, index) => selectedInTable.includes(index));

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      {' '}
      <Spinner loading size="l" centered />{' '}
    </div>
  );

  const getLoadFactorsComponent = () => (
    <div className="loadfactor-models-container">
      <div className="load-factors-header ">{loadFactorHeaderText}</div>
      <TextField
        label={loadFactorLabel}
        value={loadFactorsConfigurationData.defaultLoadFactor}
        setData={setdefaultLoadFactorsConfigurationData}
        name={'defaultLoadFactor'}
        validationString={percentageOfFlightsValidation.match}
        maxValue={MAX_LOAD_FACTOR_PERCENTAGE}
        postfix={UNIT_PERCENTAGE}
      />
      <div className="loadfactor-models-header">
        <p className={'aircraft-models-info'}>{loadFactorTableDescription}</p>
        <div>
          <Button
            style="standard"
            className="airtrak-delete-btn"
            disabled={disableDelete}
            onClick={onDeleteClick}>
            {deleteSelected}
          </Button>
          <Button style="standard" className="airtrak-addnew-btn" onClick={addNewRow}>
            {addNew}
          </Button>
        </div>
      </div>
      <div className="multiple-emission-table">
        <Table
          className="load-factors-table"
          data={rowData}
          columns={loadFactorsColumns}
          rowHeaders={rowHeaders}
          gridID={'selectable'}
          areAllRowsSelected={false}
          columnTypes={getColumnTypes(loadFactorsColumns)}
          languageData={{
            noDataTitle: `${noDataTitle}`,
            noDataText: `${tryChangingFiltersOrDate}`,
          }}
          selectedData={selectedInTable}
          onSelectRow={onSelectRow}
          clickedRow={highlightRow}
        />
        <Prompt
          message={areYouSureYouWantToLeave}
          when={mutationData.size > 0 || configfurationMutationData.length > 0}
        />
      </div>
    </div>
  );

  return loading ? getSpinnerComponent() : getLoadFactorsComponent();
};
