// Types
import {
  IGetNoiseScenarioRecordsVariables,
  IGetNoiseScenarioRecordsData,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';
import { ISortObject } from 'src/components';

export const defaultTableName = 'View Scenario';
export const scenarioFilterStagesDefault = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  'M',
];
export const pageSize = 300;
export const loadMorePageSize = 1000;
export const defaultSortOrder: ISortObject = {
  field: 'modelingAircraftName',
  direction: 'ASC',
};

export const getNoiseScenarioRecordsDefaultVariables: IGetNoiseScenarioRecordsVariables = {
  scenarioId: '',
  first: pageSize,
  filter: {},
  sort: [defaultSortOrder],
};

export const getNoiseScenarioRecordsDefultData: IGetNoiseScenarioRecordsData = {
  aircraftNoiseModelingScenarioRecordSummaries: {
    totalCount: -1,
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
};

export const noiseScenarioTotalDefaults = {
  day: 0,
  evening: 0,
  night: 0,
};
