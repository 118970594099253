// Components
import { Spinner, Dialog } from '@ems/client-design-system';

// Styled
import styled from 'styled-components/macro';
import sassVars from 'src/styles/vars.module.scss';

export const TrackProfileDialogWrapper = styled(Dialog)`
  width: 1180px;

  .bp3-dialog-body {
    margin: 24px 20px;
  }

  .bp3-dialog-footer {
    justify-content: flex-end;
  }
`;

export const GraphTitle = styled.span`
  font-weight: normal;
  font-size: 20px;
`;

export const GraphText = styled.h3`
  margin-bottom: ${sassVars.spacingBaseline};
  padding-left: 35px;
  font-size: 14px;
  font-weight: bold;
`;

export const GraphLoading = styled(Spinner)`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

export const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;

  .highcharts-axis-title {
    fill: #5a6872;
    font-size: 16px;
  }

  .highcharts-axis-labels tspan {
    fill: #5a6872;
    font-size: 12px;
  }
`;

export const GraphExportLink = styled.a`
  display: none;
`;
