import { STATE } from 'src/constants';

export const isLoginValid = (stateReturend: string) => {
  const valueStored = localStorage.getItem(STATE) || '';
  const [siteName, pageRoute] = decodeURIComponent(valueStored).split('|');
  const valid = decodeURIComponent(stateReturend) === valueStored ? true : false;
  return {
    validSiteName: valid ? siteName : null,
    validPageRoute: valid ? pageRoute : null,
  };
};

export const isLogoutValid = (stateReturend: string) => {
  const valueStored = localStorage.getItem(STATE) || '';
  const [siteName, code] = decodeURIComponent(valueStored).split('|');
  return {
    validSiteName: decodeURIComponent(stateReturend) === valueStored ? siteName : null,
    code: code ? code : null,
  };
};

export const expiryInMilliSecond = (sec: number) => {
  const time = new Date().getTime();
  return time + (sec * 1000 - 5000); // when token expires - 5 seconds
};

export const isExpired = (time: number) => {
  const check = (time - new Date().getTime()) / 1000 / 60;
  return check <= 0 ? true : false;
};

/*
 * detect IE or Edge browser
 * returns >= 12 for Edge
 * returns < 12 for IE
 * return false for other browsers
 */
export const detectIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};
