import React from 'react';
import { Formik } from 'formik';
import {
  IDeviceManagementFormErrors,
  IDeviceManagementForm,
  // IUpdateDeviceLocationData,
  // IUpdateDeviceLocationVariables,
  // IUpdateDeviceConfigData,
  // IUpdateDeviceConfigVariables,
  // IAddNewDeviceLocationData,
  // IAddNewDeviceLocationVariables,
  // IUpdateDeviceData,
  // IUpdateDeviceVariables,
  // IUpdateDeviceLocationConfigData,
  // IUpdateDeviceLocationConfigVariables,
} from '../../interfaces';

// Hooks
import { useLanguageSelectors } from 'src/app/reducers';

export const NAME_MAX_LENGTH = 50;
export const DESC_MAX_LENGTH = 200;

export const isValidLatitude = (num: number): boolean =>
  !isNaN(Number(num)) && isFinite(num) && Math.abs(num) < 90;

export const isValidLongitude = (num: number): boolean =>
  !isNaN(Number(num)) && isFinite(num) && Math.abs(num) < 180;

export const areDuplicateTimes = (times: number[]): boolean =>
  times.some((time, index) => times.indexOf(time) !== index);

export const validate = (
  values: IDeviceManagementForm,
  errorStrings: { [key: string]: string }
) => {
  const errors: IDeviceManagementFormErrors = {};

  const {
    required: requiredErrorString,
    wholeNumber: wholeNumberErrorString,
    maxLength: maxLengthErrorString,
    noiseMonitorInvalidLong: noiseMonitorInvalidLongErrorString,
    noiseMonitorInvalidLat: noiseMonitorInvalidLatErrorString,
    duplicateTemplateTime: duplicateTemplateTimeErrorString,
  } = errorStrings;

  // Name
  if (!values.name) {
    errors.name = requiredErrorString;
  } else if (values.name.length > NAME_MAX_LENGTH) {
    errors.name = `${maxLengthErrorString.replace('${length}', String(NAME_MAX_LENGTH))}`;
  }

  // Longitude
  if (!values.position.longitude) {
    errors['position.longitude'] = requiredErrorString;
  } else if (!isValidLongitude(Number(values.position.longitude))) {
    errors['position.longitude'] = noiseMonitorInvalidLongErrorString;
  }

  // Latitude
  if (!values.position.latitude) {
    errors['position.latitude'] = requiredErrorString;
  } else if (!isValidLatitude(Number(values.position.latitude))) {
    errors['position.latitude'] = noiseMonitorInvalidLatErrorString;
  }

  // Template duplicate times
  const templateTimes = values.templates.map(({ activationTime }) => activationTime);
  if (areDuplicateTimes(templateTimes)) {
    errors.duplicateTemplates = duplicateTemplateTimeErrorString;
  }

  // Templates inputs
  values.templates.forEach((template, index) => {
    const numberFields = ['threshold', 'minEventDuration', 'maxEventDuration', 'endEventDuration'];

    numberFields.forEach(field => {
      if (typeof Number(template[field]) !== 'number') {
        errors[`templates.${index}.${field}`] = wholeNumberErrorString;
      }
    });
  });

  return errors;
};

export const DeviceManagementDetailsForm = ({
  children,
  initialFormData,
  onSubmit,
}: {
  children: React.ReactNode;
  initialFormData: IDeviceManagementForm;
  onSubmit;
}) => {
  if (initialFormData === undefined) {
    return null;
  }

  const languageSelector = useLanguageSelectors();
  const {
    components: { errors: errorStrings },
  } = languageSelector.getLanguage();

  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      initialValues={initialFormData}
      onSubmit={values => {
        onSubmit(values);
      }}
      validate={values => validate(values, errorStrings)}>
      <>{children}</>
    </Formik>
  );
};
