import { useEffect } from 'react';
import { useCurrentCursor } from './useCurrentCursor';
import { useSetCursor } from './useSetCursor';
import { useMapContext } from '../../context/useMapContext';

export const useCursorOverride = () => {
  const { cursorOverride } = useMapContext();

  const setCursor = useSetCursor();
  const currentCursor = useCurrentCursor();

  useEffect(() => {
    if (currentCursor === cursorOverride) {return;}
    if (cursorOverride) {
      setCursor(cursorOverride);
    }
  }, [currentCursor, cursorOverride, setCursor]);
};
