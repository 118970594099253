import { PayloadAction } from 'src/app/interfaces';
import { EditedNode } from '../map/taxi-path-layer/types';
import { TaxiPathContextState, TaxiPathEditMode } from './TaxiPathContext';

import * as turf from '@turf/turf';
import { featureCollection } from '@turf/helpers';

export enum TaxiPathActions {
  SET_EDIT_MODE = 'SET_EDIT_MODE',
  ADD_NODE = 'ADD_NODE',
  CLEAR_NODES = 'CLEAR_NODES',
  REMOVE_NODE = 'REMOVE_NODE',
  UPDATE_NODE = 'UPDATE_NODE',
  IMPORT_KML = 'IMPORT_KML',
  UPDATE_CONNECTOR = 'UPDATE_CONNECTOR',
  ADD_CONNECTOR = 'ADD_CONNECTOR',
  REMOVE_CONNECTOR = 'REMOVE_CONNECTOR',
  SET_PATH_GEOJSON = 'SET_PATH_GEOJSON',
  SET_NODE_GEOJSON = 'SET_NODE_GEOJSON',
  SELECT_SEARCHED_NODE = 'SELECT_SEARCHED_NODE',
  DESELECT_SEARCHED_NODE = 'DESELECT_SEARCHED_NODE',
}

export const taxiPathReducer = (
  state: TaxiPathContextState,
  action: PayloadAction<unknown, TaxiPathActions>
) => {
  switch (action.type) {
    case TaxiPathActions.SET_EDIT_MODE: {
      const { payload } = action as PayloadAction<TaxiPathEditMode>;
      return {
        ...state,
        currentEditMode: payload,
      };
    }
    case TaxiPathActions.ADD_NODE: {
      const { payload } = action as PayloadAction<GeoJSON.Feature<GeoJSON.Point>>;

      return {
        ...state,
        nodeGeoJSON: turf.featureCollection([...state.nodeGeoJSON.features, payload]),
      };
    }
    case TaxiPathActions.REMOVE_NODE: {
      const { payload } = action as PayloadAction<string>;

      return {
        ...state,
        pathGeoJSON: turf.featureCollection([
          ...state.pathGeoJSON.features.filter(
            path =>
              path.properties.DestNodeId !== payload && path.properties.SourceNodeId !== payload
          ),
        ]),
        nodeGeoJSON: turf.featureCollection([
          ...state.nodeGeoJSON.features.filter(node => node.properties.NodeId !== payload),
        ]),
      };
    }
    case TaxiPathActions.CLEAR_NODES: {
      return {
        ...state,
        nodeGeoJSON: turf.featureCollection([]) as GeoJSON.FeatureCollection<GeoJSON.Point>,
      };
    }
    case TaxiPathActions.IMPORT_KML: {
      const { payload } = action as PayloadAction<string>;
      return {
        ...state,
        importedKmlData: payload,
      };
    }
    case TaxiPathActions.UPDATE_CONNECTOR: {
      const { payload } = action as PayloadAction<{ id; coordinates }>;
      const connectors = [...state.pathGeoJSON.features];
      const indexToUpdate = connectors.findIndex(path => path.properties.PathwayId === payload.id);
      if (indexToUpdate !== -1) {
        connectors[indexToUpdate].geometry.coordinates = payload.coordinates;
        const updatedPathGeoJson = featureCollection(connectors);
        return {
          ...state,
          pathGeoJSON: updatedPathGeoJson,
        };
      }
      return state;
    }
    case TaxiPathActions.UPDATE_NODE: {
      const { payload } = action as PayloadAction<EditedNode>;
      const nodeFeaturesCopy = [...state.nodeGeoJSON.features];
      const pathFeaturesCopy = [...state.pathGeoJSON.features];

      const nodeIndexToUpdate = nodeFeaturesCopy.findIndex(
        node => node.properties.NodeId === payload.id
      );

      // Find paths that connect to the moved node
      const pathIndexesToUpdate = pathFeaturesCopy
        .map((path, index) =>
          path.properties.DestNodeId === payload.id || path.properties.SourceNodeId === payload.id
            ? index
            : ''
        )
        .filter(String);

      pathIndexesToUpdate.forEach(index => {
        const path = pathFeaturesCopy[index];
        const nodeId = payload.id;
        if (path.properties.SourceNodeId === nodeId) {
          path.geometry.coordinates[0] = payload.coordinates;
        }
        if (path.properties.DestNodeId === nodeId) {
          path.geometry.coordinates[path.geometry.coordinates.length - 1] = payload.coordinates;
        }
      });

      nodeFeaturesCopy[nodeIndexToUpdate].geometry.coordinates = payload.coordinates;

      return {
        ...state,
        nodeGeoJSON: turf.featureCollection([...nodeFeaturesCopy]),
        pathGeoJSON: turf.featureCollection([...pathFeaturesCopy]),
      };
    }
    case TaxiPathActions.ADD_CONNECTOR: {
      const { payload } = action as PayloadAction<GeoJSON.Feature>;
      const connectors = [...state.pathGeoJSON.features, payload];
      const updatedPathGeoJson = featureCollection(connectors) as GeoJSON.FeatureCollection<
        GeoJSON.LineString
      >;
      return {
        ...state,
        pathGeoJSON: updatedPathGeoJson,
      };
    }
    case TaxiPathActions.REMOVE_CONNECTOR: {
      const { payload } = action as PayloadAction<string>;
      return {
        ...state,
        pathGeoJSON: turf.featureCollection([
          ...state.pathGeoJSON.features.filter(path => path.properties.PathwayId !== payload),
        ]),
      };
    }
    case TaxiPathActions.SET_NODE_GEOJSON: {
      const { payload } = action as PayloadAction<GeoJSON.FeatureCollection<GeoJSON.Point>>;
      return {
        ...state,
        nodeGeoJSON: payload,
      };
    }
    case TaxiPathActions.SET_PATH_GEOJSON: {
      const { payload } = action as PayloadAction<GeoJSON.FeatureCollection<GeoJSON.LineString>>;
      return {
        ...state,
        pathGeoJSON: payload,
      };
    }
    case TaxiPathActions.SELECT_SEARCHED_NODE: {
      const { payload } = action as PayloadAction<number>;
      return {
        ...state,
        searchedNodeId: payload,
      };
    }
    case TaxiPathActions.DESELECT_SEARCHED_NODE: {
      return {
        ...state,
        searchedNodeId: null,
      };
    }
    default:
      return { ...state };
  }
};
