import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import uuid from 'uuid';

// Interfaces
import {
  INoiseEventTemplate,
  IDeviceTemplateTableRow,
  IDeviceManagementForm,
} from '../../interfaces';
import { TimeField, TextInputField, DeleteTemplateButton } from '../Components/FormControlElements';

// Variables
import { UNIT_DECIBEL } from 'src/constants';
import { NMTTableCellWrapper } from '../Components/DeviceManagementDetails.styles';

// Hooks
import { useLanguageSelectors } from 'src/app/reducers';

export const useTemplateRowData = (
  formData: IDeviceManagementForm,
  isEditing: boolean,
  removeTemplateAction: (indexToRemove: number) => void
): {
  tableRowData: IDeviceTemplateTableRow[];
  tableRowKeys: string[];
  rowHeaderIds: string[];
  rowHeaders: Record<string, string>;
} => {
  const { values } = useFormikContext<IDeviceManagementForm>();
  const languageSelector = useLanguageSelectors();
  const {
    fields: {
      locationManagement: {
        template: templateString,
        time: timeString,
        threshold: thresholdString,
        minDuration: minDurationString,
        maxDuration: maxDurationString,
        endDuration: endDurationString,
      },
    },
  } = languageSelector.getLanguage();
  const { locationId } = formData;

  return useMemo(() => {
    const tableRowData = formData.templates.map((nmtItem: INoiseEventTemplate, index: number) => {
      const {
        minEventDuration,
        maxEventDuration,
        endEventDuration,
        threshold,
        activationTime,
      } = nmtItem;

      const readableIndex = index + 1;
      const rowItem = {
        ...nmtItem,
        time: (
          <NMTTableCellWrapper>
            <TimeField
              fieldData={{
                label: `${templateString} ${readableIndex} ${timeString}`,
                name: `templates.${index}.activationTime`,
                value: String(activationTime),
              }}
              isEditing={isEditing}
            />
          </NMTTableCellWrapper>
        ),
        tableId: uuid.v4(),
        threshold: (
          <NMTTableCellWrapper>
            <TextInputField
              fieldData={{
                label: `${templateString} ${readableIndex} ${thresholdString}`,
                name: `templates.${index}.threshold`,
                value: String(threshold),
              }}
              isEditing={isEditing}
              appendField={UNIT_DECIBEL}
              numberOnly
              fieldLength={3}
            />
          </NMTTableCellWrapper>
        ),
        minDuration: (
          <NMTTableCellWrapper>
            <TextInputField
              fieldData={{
                label: `${templateString} ${readableIndex} ${minDurationString}`,
                name: `templates.${index}.minEventDuration`,
                value: String(minEventDuration),
              }}
              isEditing={isEditing}
              appendField={`s`}
              numberOnly
              fieldLength={4}
            />
          </NMTTableCellWrapper>
        ),
        maxDuration: (
          <NMTTableCellWrapper>
            <TextInputField
              fieldData={{
                label: `${templateString} ${readableIndex} ${maxDurationString}`,
                name: `templates.${index}.maxEventDuration`,
                value: String(maxEventDuration),
              }}
              isEditing={isEditing}
              appendField={`s`}
              numberOnly
              fieldLength={4}
            />
          </NMTTableCellWrapper>
        ),
        endDuration: (
          <NMTTableCellWrapper>
            <TextInputField
              fieldData={{
                label: `${templateString} ${readableIndex} ${endDurationString}`,
                name: `templates.${index}.endEventDuration`,
                value: String(endEventDuration),
              }}
              isEditing={isEditing}
              appendField={`s`}
              numberOnly
              fieldLength={4}
            />
          </NMTTableCellWrapper>
        ),
      };

      if (isEditing) {
        rowItem['deleteRow'] =
          formData.templates.length !== 1 ? (
            <DeleteTemplateButton index={index} removeTemplateAction={removeTemplateAction} />
          ) : (
            <div />
          );
      }

      return rowItem;
    });
    const tableRowKeys = tableRowData.map(row => row.tableId);

    const rowHeaders = {
      time: timeString,
      threshold: thresholdString,
      minDuration: minDurationString,
      maxDuration: maxDurationString,
      endDuration: endDurationString,
    };
    const rowHeaderIds = ['time', 'threshold', 'minDuration', 'maxDuration', 'endDuration'];

    if (isEditing) {
      rowHeaderIds.push('deleteRow');
      rowHeaders['deleteRow'] = '';
    }
    return { tableRowData, tableRowKeys, rowHeaders, rowHeaderIds };
  }, [isEditing, values.templates.length, locationId]);
};
