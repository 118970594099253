import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
// Components
import { InfringementRulesMap } from '../InfringementRulesMap';
import { PageHeader } from 'src/components';
import {
  useFilterDataSelectors,
  useInfringementRulesSelectors,
  useRolesSelectors,
} from 'src/app/reducers';
import {
  Card,
  Button,
  displaySuccess,
  getConvertedMass,
  getConvertedDistance,
} from '@ems/client-design-system';
import { CandidatePanel } from './CandidatePanel';
import { RulePanel } from './RulePanel';

// Actions/Reducers/Providers/Selectors
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { updateInfringementRules } from 'src/app/actions';
// Functions
import {
  getTranslations,
  formatFilterData,
  createNewInfringementRule,
  validateInfRule,
} from './InfringementRuleDetailsHelpers';
import {
  deepCopyObject,
  formatMeasurementAsNumericString,
  getImperialOrMetricBaseUnit,
  getVerticalDistance,
  removeAllTypenameProps,
  setMeasurementAsNumber,
  getDeployedProductId,
  setTabTitle,
} from 'src/utils';
import { getInfringementRulePermissions } from '../InfringementRulesContainerHelpers';

// Constants
import {
  GQL_MUTATION_TYPES,
  IMPERIAL_BASE_UNIT,
  INFRINGEMENT_RULE_TYPES,
  INPUT_RETURN_TYPES,
  PERMISSIONS,
  UNIT_FOOT,
  UNIT_KILOGRAM,
  UNIT_METER,
  UNIT_MILE,
} from 'src/constants';
// Constants

import {
  ADD_CURFEW_INFRINGEMENT_RULE,
  UPDATE_CURFEW_INFRINGEMENT_RULE,
  ADD_GATE_INFRINGEMENT_RULE,
  UPDATE_GATE_INFRINGEMENT_RULE,
  ADD_CORRIDOR_INFRINGEMENT_RULE,
  UPDATE_CORRIDOR_INFRINGEMENT_RULE,
  ADD_NOISE_INFRINGEMENT_RULE,
  UPDATE_NOISE_INFRINGEMENT_RULE,
  ADD_EXCLUSION_ZONE_INFRINGEMENT_RULE,
  UPDATE_EXCLUSION_ZONE_INFRINGEMENT_RULE,
  ADD_CCO_INFRINGEMENT_RULE,
  UPDATE_CCO_INFRINGEMENT_RULE,
  ADD_CDO_INFRINGEMENT_RULE,
  UPDATE_CDO_INFRINGEMENT_RULE,
} from 'src/@settings/mutations';

// Interfaces
import {
  IInfDetailsData,
  IInfRuleConversionParams,
  IInfRuleFormErrors,
} from 'src/@settings/interfaces';
import {
  IGate,
  INoiseAbatementCorridor,
  ISelectionZone,
} from 'src/utils/spatialFeatureHelpers/interfaces';

import {
  ICCOInfringementRule,
  ICDOInfringementRule,
  ICorridorInfringementRule,
  IExclusionInfringementRule,
  IGateInfringementRule,
  INoiseInfringementRule,
  INoiseMonitor,
  TCurfewInfringementRule,
  TInfringementRule,
} from 'src/app/interfaces';
import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import {
  getFeatureType,
  getSpatialFeatureIdsFromRule,
  getUpdatedSpatialFeatureStates,
} from 'src/@settings/functions/infringements/infringementRules';
import { mutateSpatialFeature, useSpatialFeatureMutations } from 'src/@settings/functions';
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import { spatialFeatureUpdated } from 'src/@settings/actions';
import { TMutationActions } from 'src/utils/interfaces';
import { IsActiveCheckbox } from 'src/@settings/components/ActiveCheckbox/ActiveCheckbox';

interface InfringementRuleDetailsProps {
  ruleId: number;
  setRuleId: React.Dispatch<React.SetStateAction<number | null>>;
  airportIdsByRunwayName: { [key: string]: string };
  runwayNamesSortedByAirport: string[];
  allGates: IGate[];
  allCorridors: INoiseAbatementCorridor[];
  allZones: ISelectionZone[];
  allNoiseMonitors: INoiseMonitor[];
}

export const InfringementRuleDetails: React.FC<InfringementRuleDetailsProps> = ({
  ruleId,
  setRuleId,
  airportIdsByRunwayName,
  runwayNamesSortedByAirport,
  allGates,
  allCorridors,
  allZones,
  allNoiseMonitors,
}) => {
  const routerHistory = useHistory();
  const filterSelectors = useFilterDataSelectors();
  const {
    aircraftCategories,
    operatorCategories,
    operationTypes,
  } = filterSelectors.getOperationsFilterData();

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const translations = getTranslations(useLanguageSelectors);
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { candidates: candidatesString },
    },
    screens: {
      settings: {
        title: settingsTitle,
        tabs: { infringementRules: infringementRulesString },
        infringementRules: {
          newRule: newRuleString,
          save: saveString,
          cancel: cancelString,
          edit: editString,
          messages: messageStrings,
        },
      },
    },
  } = languageSelectors.getLanguage();

  const dropdownData = {
    runwayNames: runwayNamesSortedByAirport,
  };

  // Permissions
  const rolesSelectors = useRolesSelectors();

  const infRulesSelectors = useInfringementRulesSelectors();
  const infringementRules = infRulesSelectors.getRules();

  const filterData = {
    aircraftCategories: formatFilterData(
      'aircraftCategories',
      aircraftCategories,
      translations.aircraftCategories
    ),
    operatorCategories: formatFilterData(
      'operatorCategories',
      operatorCategories,
      translations.operatorCategories
    ),
    operationTypes: formatFilterData('operationTypes', operationTypes, translations.operationTypes),
  };

  // USED FOR SUBMITTING DATA
  const conversionToNumericBackEndUnits: IInfRuleConversionParams = {
    mass: {
      unitConvertTo: UNIT_KILOGRAM as 'kg',
      unitConvertFrom: units.mass,
      decimals: 0,
    },
    verticalDistance: {
      unitConvertTo: UNIT_FOOT as 'ft',
      unitConvertFrom: units.distanceVertical,
    },
    distance: {
      unitConvertTo: UNIT_METER as 'm',
      decimals: 0,
      unitConvertFrom:
        getImperialOrMetricBaseUnit(units.distance) === IMPERIAL_BASE_UNIT ? UNIT_FOOT : UNIT_METER,
    },
    returnAs: INPUT_RETURN_TYPES.NUMBER,
  };

  // used for displaying on the front end
  const conversionToStringUserDefinedUnits: IInfRuleConversionParams = {
    mass: {
      unitConvertTo: units.mass,
      unitConvertFrom: UNIT_KILOGRAM as 'kg',
      decimals: 2,
    },
    verticalDistance: {
      unitConvertTo: units.distanceVertical,
      unitConvertFrom: UNIT_FOOT as 'ft',
    },
    distance: {
      unitConvertTo: units.distance,
      unitConvertFrom: UNIT_METER as 'm',
      decimals: 2,
    },
    returnAs: INPUT_RETURN_TYPES.STRING,
  };

  // Used to convert from kg to user config and user config to kg

  const convertAllValues = (infRule, conversionParams: IInfRuleConversionParams) => {
    const mutatedInfRule = Object.assign({}, infRule);
    if (mutatedInfRule.candidateFilter) {
      const mutatedCandidateFilter = Object.assign({}, infRule.candidateFilter);

      const candidateFilterMassFields = ['lowestMaxLandingWeight', 'lowestMaxTakeOffWeight'];
      candidateFilterMassFields.forEach((field: string) => {
        if (!!infRule.candidateFilter[field]) {
          const convertedMeasurement = formatMeasurementAsNumericString(
            getConvertedMass(
              Number(infRule.candidateFilter[field]),
              getImperialOrMetricBaseUnit(conversionParams.mass.unitConvertTo),
              conversionParams.mass.decimals,
              conversionParams.mass.unitConvertFrom
            )
          );

          mutatedCandidateFilter[field] =
            conversionParams.returnAs === INPUT_RETURN_TYPES.NUMBER
              ? Number(convertedMeasurement)
              : convertedMeasurement;
        }
      });

      mutatedInfRule.candidateFilter = mutatedCandidateFilter;
    }

    const verticalDistanceFields = ['floorAltitude', 'ceilingAltitude'];
    verticalDistanceFields.forEach((field: string) => {
      if (!!infRule[field]) {
        mutatedInfRule[field] = getVerticalDistance(infRule[field], {
          convertTo: conversionParams.verticalDistance.unitConvertTo,
          convertFrom: conversionParams.verticalDistance.unitConvertFrom,
          returnValueType: INPUT_RETURN_TYPES.NUMBER,
        });
      }
    });

    const distanceFields = ['minLevelSegmentLength', 'autoInfringementSegmentLength'];
    distanceFields.forEach((field: string) => {
      if (!!infRule[field]) {
        const convertedValue = formatMeasurementAsNumericString(
          getConvertedDistance(
            Number(infRule[field]),
            getImperialOrMetricBaseUnit(conversionParams.distance.unitConvertTo),
            0,
            conversionParams.distance.unitConvertFrom === UNIT_MILE
              ? UNIT_FOOT
              : conversionParams.distance.unitConvertFrom
          )
        );

        mutatedInfRule[field] =
          conversionParams.returnAs === INPUT_RETURN_TYPES.NUMBER
            ? setMeasurementAsNumber(convertedValue)
            : convertedValue;
      }
    });

    return mutatedInfRule;
  };
  const [currentRuleId, setCurrentRuleId] = useState<number>(ruleId);

  const createEditableRule = (): TInfringementRule => {
    if (currentRuleId !== -1) {
      const infRule = infRulesSelectors.getRule(currentRuleId);
      return convertAllValues(deepCopyObject(infRule), conversionToStringUserDefinedUnits);
    } else {
      return Object.assign(
        {},
        createNewInfringementRule(filterData, dropdownData, airportIdsByRunwayName)
      ) as TInfringementRule;
    }
  };

  const infRules = infRulesSelectors.getRules();
  const [formErrors, setFormErrors] = useState<IInfRuleFormErrors>();

  const [infRule, setInfRule] = useState<TInfringementRule>(createEditableRule());
  const [isEditing, setIsEditing] = useState(currentRuleId === -1 ? true : false);

  const [addCurfewRuleMutation] = useMutation<TCurfewInfringementRule, TCurfewInfringementRule>(
    ADD_CURFEW_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.curfewRuleCreated });
      },
    }
  );

  const [updateCurfewRuleMutation] = useMutation<TCurfewInfringementRule, TCurfewInfringementRule>(
    UPDATE_CURFEW_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.curfewRuleUpdated });
      },
    }
  );

  const [addGateRuleMutation] = useMutation<IGateInfringementRule, IGateInfringementRule>(
    ADD_GATE_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.gateRuleCreated });
      },
    }
  );

  const [updateGateRuleMutation] = useMutation<IGateInfringementRule, IGateInfringementRule>(
    UPDATE_GATE_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.gateRuleUpdated });
      },
    }
  );

  const [addCorridorRuleMutation] = useMutation<
    ICorridorInfringementRule,
    ICorridorInfringementRule
  >(ADD_CORRIDOR_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.corridorRuleCreated });
    },
  });

  const [updateCorridorRuleMutation] = useMutation<
    ICorridorInfringementRule,
    ICorridorInfringementRule
  >(UPDATE_CORRIDOR_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.corridorRuleUpdated });
    },
  });

  const [addNoiseRuleMutation] = useMutation<INoiseInfringementRule, INoiseInfringementRule>(
    ADD_NOISE_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.noiseRuleCreated });
      },
    }
  );

  const [updateNoiseRuleMutation] = useMutation<INoiseInfringementRule, INoiseInfringementRule>(
    UPDATE_NOISE_INFRINGEMENT_RULE,
    {
      update: () => {
        toggleEditMode();
        displaySuccess({ message: messageStrings.noiseRuleUpdated });
      },
    }
  );

  const [addExclusionZoneMutation] = useMutation<
    IExclusionInfringementRule,
    IExclusionInfringementRule
  >(ADD_EXCLUSION_ZONE_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.exclusionZoneRuleCreated });
    },
  });

  const [updateExclusionZoneMutation] = useMutation<
    IExclusionInfringementRule,
    IExclusionInfringementRule
  >(UPDATE_EXCLUSION_ZONE_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.exclusionZoneRuleUpdated });
    },
  });

  const [addCcoRuleMutation] = useMutation(ADD_CCO_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.ccoRuleCreated });
    },
  });

  const [updateCcoRuleMutation] = useMutation(UPDATE_CCO_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.ccoRuleUpdated });
    },
  });

  const [addCdoRuleMutation] = useMutation(ADD_CDO_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.cdoRuleCreated });
    },
  });

  const [updateCdoRuleMutation] = useMutation(UPDATE_CDO_INFRINGEMENT_RULE, {
    update: () => {
      toggleEditMode();
      displaySuccess({ message: messageStrings.cdoRuleUpdated });
    },
  });

  const globalDispatcher = useContext(GlobalDispatchContext);
  const settingsDispatcher = useContext(SettingsDispatchContext);
  const spatialFeaturesSelector = useSpatialFeaturesSelector();

  const spatialFeatureMutationObject = useSpatialFeatureMutations();

  const updateStoreAndState = newRule => {
    // Update inf rules array with new rule
    const indexToUpdate = infRules.findIndex(item => item.id === infRule.id);
    newRule.lastUpdateTime = new Date().toISOString();
    const updatedRules =
      indexToUpdate !== -1
        ? infRules.map((rule, index) => (Number(index) === Number(indexToUpdate) ? newRule : rule))
        : [...infRules, newRule];

    // Update spatial feature states - if rule has a spatial feature linked
    const updatedFeatureType = getFeatureType(newRule);
    if (updatedFeatureType) {
      // Get ids of features linked to updated rule and original rule
      const spatialFeatureIds = [getSpatialFeatureIdsFromRule(newRule)];
      if (currentRuleId !== -1) {
        const originalInfRule = deepCopyObject(infRulesSelectors.getRule(currentRuleId));
        spatialFeatureIds.push(getSpatialFeatureIdsFromRule(originalInfRule));
      }
      // get an array of spatial features - to check if they need to be set active/inactive
      const spatialFeatureIdsToCheck = spatialFeatureIds.flat();
      const allSpatialFeatures = spatialFeaturesSelector.getSpatialFeatures();
      const spatialFeaturesToCheck = allSpatialFeatures.filter(
        feature =>
          spatialFeatureIdsToCheck.includes(feature.featureId) &&
          feature.featureType === updatedFeatureType
      );

      // Check features against other rules to see what status it should be
      const featuresToBeUpdated = getUpdatedSpatialFeatureStates(
        updatedRules,
        spatialFeaturesToCheck
      );

      featuresToBeUpdated.forEach(featureToUpdate => {
        const featureGeometryData = spatialFeaturesSelector.getFeatureData(
          featureToUpdate.featureId,
          featureToUpdate.featureType
        );
        const completeFeatureData = removeAllTypenameProps({
          ...featureGeometryData,
          ...featureToUpdate,
        });

        // Pass in these callbacks to spatial feature mutation obj - so we can reference featureToUpdate
        const onUpdateFeatureSuccess = (updatedFeature, actionType: TMutationActions) => {
          // actionType will always be "update" here
          const updatedFeatureItem = {
            ...featureToUpdate,
            ...updatedFeature,
            lastUpdateTime: new Date().toISOString(),
            featureId: updatedFeature.id,
          };
          spatialFeatureUpdated(updatedFeatureItem, updatedFeature, settingsDispatcher);
        };

        const onUpdateFeatureError = error => {
          console.error(error);
        };

        spatialFeatureMutationObject.onError = onUpdateFeatureError;
        spatialFeatureMutationObject.onSuccess = onUpdateFeatureSuccess;

        mutateSpatialFeature(
          featureToUpdate.featureType,
          completeFeatureData,
          spatialFeatureMutationObject,
          GQL_MUTATION_TYPES.UPDATE
        );
      });
    }

    // update infringement reducer
    updateInfringementRules(updatedRules, globalDispatcher);

    // Updates component state
    // Want to store in component as string defined units
    const convertedRule = convertAllValues(newRule, conversionToStringUserDefinedUnits);
    setInfRule(convertedRule);
    infDetailsData.infRule = convertedRule;
    setCurrentRuleId(convertedRule.id);
    toggleEditMode();
  };

  const addCurfewInfringementRule = (infDetailsData: IInfDetailsData, addCurfewRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: TCurfewInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'Curfew rule',
      severity: 'Infringement',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addCurfewRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: TCurfewInfringementRule = {
          ...data.addCurfewInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.CURFEW_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateCurfewInfringementRule = (
    infDetailsData: IInfDetailsData,
    updateCurfewRuleMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: TCurfewInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      severity: infRule.severity,
      description: infRule.description || 'Curfew rule',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateCurfewRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        updateStoreAndState(data.updateCurfewInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addGateInfringementRule = (infDetailsData: IInfDetailsData, addGateRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: IGateInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'Gate rule',
      severity: 'Infringement',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
      gateCriteria: infRule.gateCriteria ? removeAllTypenameProps(infRule.gateCriteria) : [],
    };
    addGateRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: IGateInfringementRule = {
          ...data.addGateInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateGateInfringementRule = (infDetailsData: IInfDetailsData, updateGateRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: IGateInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      severity: infRule.severity,
      description: infRule.description || 'Gate rule',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
      gateCriteria: removeAllTypenameProps(infRule.gateCriteria),
    };
    updateGateRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        toggleEditMode();
        updateStoreAndState(data.updateGateInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addCorridorInfringementRule = (
    infDetailsData: IInfDetailsData,
    addCorridorRuleMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: ICorridorInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'Corridor rule',
      corridorId: infRule.corridorId,
      severity: 'Infringement',
      pathName: infRule.corridorName,
      allowedPassage: ['End'],
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addCorridorRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: ICorridorInfringementRule = {
          ...data.addCorridorInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateCorridorInfringementRule = (
    infDetailsData: IInfDetailsData,
    updateCorridorRuleMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: ICorridorInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      severity: infRule.severity,
      description: infRule.description || 'Corridor rule',
      corridorId: infRule.corridorId,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateCorridorRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        updateStoreAndState(data.updateCorridorInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addNoiseInfringementRule = (infDetailsData: IInfDetailsData, addNoiseRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: INoiseInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      metric: infRule.metric,
      thresholds: infRule.thresholds,
      description: infRule.description || 'Noise rule',
      severity: 'Infringement',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addNoiseRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: INoiseInfringementRule = {
          ...data.addNoiseInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateNoiseInfringementRule = (
    infDetailsData: IInfDetailsData,
    updateNoiseRuleMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: INoiseInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'Noise rule',
      metric: infRule.metric,
      severity: infRule.severity,
      thresholds: removeAllTypenameProps(infRule.thresholds),
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateNoiseRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        toggleEditMode();
        updateStoreAndState(data.updateNoiseInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addExclusionZoneInfringementRule = (
    infDetailsData: IInfDetailsData,
    addExclusionZoneMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: IExclusionInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      selectionZoneId: infRule.selectionZoneId,
      description: infRule.description || 'Exclusion rule',
      severity: 'Infringement',
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addExclusionZoneMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: IExclusionInfringementRule = {
          ...data.addExclusionInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateExclusionZoneInfringementRule = (
    infDetailsData: IInfDetailsData,
    updateExclusionZoneMutation
  ) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: IExclusionInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'Exclusion rule',
      severity: infRule.severity,
      selectionZoneId: infRule.selectionZoneId,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateExclusionZoneMutation({ variables: { rule } })
      .then(({ data }) => {
        toggleEditMode();
        updateStoreAndState(data.updateExclusionInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addCcoInfringementRule = (infDetailsData: IInfDetailsData, addCcoRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule = {
      isActive: infRule.isActive,
      name: infRule.name,
      selectionZoneId: infRule.selectionZoneId,
      description: infRule.description || 'CCO rule',
      severity: 'Infringement',
      autoInfringementSegmentLength: infRule.autoInfringementSegmentLength,
      ceilingAltitude: infRule.ceilingAltitude,
      floorAltitude: infRule.floorAltitude,
      maxLevelSegments: infRule.maxLevelSegments,
      minLevelSegmentLength: infRule.minLevelSegmentLength,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addCcoRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: ICCOInfringementRule = {
          ...data.addCcoInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.CCO_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateCcoInfringementRule = (infDetailsData: IInfDetailsData, updateCcoRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);

    const rule: ICCOInfringementRule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'CCO rule',
      severity: infRule.severity,
      selectionZoneId: infRule.selectionZoneId,
      autoInfringementSegmentLength: infRule.autoInfringementSegmentLength,
      ceilingAltitude: infRule.ceilingAltitude,
      floorAltitude: infRule.floorAltitude,
      maxLevelSegments: infRule.maxLevelSegments,
      minLevelSegmentLength: infRule.minLevelSegmentLength,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateCcoRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        toggleEditMode();
        updateStoreAndState(data.updateCcoInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const addCdoInfringementRule = (infDetailsData: IInfDetailsData, addCdoRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule: ICDOInfringementRule = {
      isActive: infRule.isActive,
      name: infRule.name,
      selectionZoneId: infRule.selectionZoneId,
      description: infRule.description || 'CDO rule',
      severity: 'Infringement',
      autoInfringementSegmentLength: infRule.autoInfringementSegmentLength,
      ceilingAltitude: infRule.ceilingAltitude,
      floorAltitude: infRule.floorAltitude,
      maxLevelSegments: infRule.maxLevelSegments,
      minLevelSegmentLength: infRule.minLevelSegmentLength,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    addCdoRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        const newRule: ICDOInfringementRule = {
          ...data.addCdoInfringementRule,
          isNew: false,
          infringementType: INFRINGEMENT_RULE_TYPES.CDO_INFRINGEMENT,
        };
        updateStoreAndState(newRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const updateCdoInfringementRule = (infDetailsData: IInfDetailsData, updateCdoRuleMutation) => {
    const infRule = convertAllValues(infDetailsData.infRule, conversionToNumericBackEndUnits);
    const rule = {
      id: infRule.id,
      isActive: infRule.isActive,
      name: infRule.name,
      description: infRule.description || 'CDO rule',
      severity: infRule.severity,
      selectionZoneId: infRule.selectionZoneId,
      autoInfringementSegmentLength: infRule.autoInfringementSegmentLength,
      ceilingAltitude: infRule.ceilingAltitude,
      floorAltitude: infRule.floorAltitude,
      maxLevelSegments: infRule.maxLevelSegments,
      minLevelSegmentLength: infRule.minLevelSegmentLength,
      candidateFilter: removeAllTypenameProps(infRule.candidateFilter),
    };
    updateCdoRuleMutation({ variables: { rule } })
      .then(({ data }) => {
        toggleEditMode();
        updateStoreAndState(data.updateCdoInfringementRule);
      })
      .catch(({ error }) => {
        throw error;
      });
  };

  const onCancelClick = (): void => {
    // If user is in edit, cancel edit mode
    if (isEditing && currentRuleId !== -1) {
      const originalRule = createEditableRule();
      infDetailsData.infRule = originalRule;
      setInfRule(originalRule);
      setIsEditing(false);
    } else {
      // Else navigate back to infringement rules page
      setRuleId(null);
      routerHistory.push(`/${getDeployedProductId()}/settings/infringement-rules`);
    }
  };

  // Reset to original state
  const toggleEditMode = (): void => {
    setIsEditing(!isEditing);
  };

  const handleSave = (): void => {
    // Validate data first -
    const { isValid, errors } = validateInfRule(infDetailsData, infringementRules, true);
    setFormErrors(errors);
    if (isValid) {
      if (!infRule.isNew) {
        switch (infRule.infringementType) {
          case 'Curfew':
            updateCurfewInfringementRule(infDetailsData, updateCurfewRuleMutation);
            break;
          case 'Gate':
            updateGateInfringementRule(infDetailsData, updateGateRuleMutation);
            break;
          case 'Corridor':
            updateCorridorInfringementRule(infDetailsData, updateCorridorRuleMutation);
            break;
          case 'Noise':
            updateNoiseInfringementRule(infDetailsData, updateNoiseRuleMutation);
            break;
          case 'Exclusion':
            updateExclusionZoneInfringementRule(infDetailsData, updateExclusionZoneMutation);
            break;
          case 'Cco':
            updateCcoInfringementRule(infDetailsData, updateCcoRuleMutation);
            break;
          case 'Cdo':
            updateCdoInfringementRule(infDetailsData, updateCdoRuleMutation);
            break;
          default:
            console.error(
              `Infringement type ${infRule.infringementType} is currently not supported`
            );
        }
      } else {
        switch (infRule.infringementType) {
          case 'Curfew':
            addCurfewInfringementRule(infDetailsData, addCurfewRuleMutation);
            break;
          case 'Corridor':
            addCorridorInfringementRule(infDetailsData, addCorridorRuleMutation);
            break;
          case 'Gate':
            addGateInfringementRule(infDetailsData, addGateRuleMutation);
            break;
          case 'Noise':
            addNoiseInfringementRule(infDetailsData, addNoiseRuleMutation);
            break;
          case 'Exclusion':
            addExclusionZoneInfringementRule(infDetailsData, addExclusionZoneMutation);
            break;
          case 'Cco':
            addCcoInfringementRule(infDetailsData, addCcoRuleMutation);
            break;
          case 'Cdo':
            addCdoInfringementRule(infDetailsData, addCdoRuleMutation);
            break;
          default:
            console.error(
              `Infringement type ${infRule.infringementType} is currently not supported`
            );
        }
      }
    }
  };

  const handleIsActiveChange = (updatedIsActive: boolean) => {
    setInfRule(Object.assign({}, infRule, { isActive: updatedIsActive, hasChanged: true }));
  };

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  const infRulePermissions = getInfringementRulePermissions(rolesSelectors);
  const getCanUpdate = (): boolean => {
    if (infRule && infRule.infringementType) {
      return infRulePermissions[infRule.infringementType][PERMISSIONS.UPDATE];
    } else {
      return true;
    }
  };

  const canUpdate = getCanUpdate();

  const infDetailsData: IInfDetailsData = {
    infRule,
    setInfRule,
    dropdownData,
    filterData,
    translations,
    units,
    airportIdsByRunwayName,
    permissions: infRulePermissions,
    isEditing,
    allGates,
    allCorridors,
    allZones,
    allNoiseMonitors,
    selectedTrackTheme: configSelectors.getTheme('operations'),
    isValid: false,
    errors: formErrors,
  };

  // FEATURES FOR MAP
  const [infRuleGateIds, setInfRuleGateIds] = useState<number[]>([]);
  const [infRuleCorridorIds, setInfRuleCorridorIds] = useState<number[]>([]);
  const [infRuleZoneIds, setInfRuleZoneIds] = useState<number[]>([]);
  const [infRuleNoiseIds, setInfRuleNoiseIds] = useState<number[]>([]);

  const [currentInfringementType, updateCurrentInfringementType] = useState<string>(
    infRule.infringementType
  );

  useEffect(() => {
    // When the infRule changes - fire form validation or update renderable feature id arrays
    switch (infDetailsData.infRule.infringementType) {
      case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
        const updatedGateRule = infDetailsData.infRule as IGateInfringementRule;
        if (updatedGateRule.gateCriteria) {
          setInfRuleGateIds([updatedGateRule.gateCriteria[0].gateId]);
        }
        break;
      case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
        const updatedCorridorRule = infDetailsData.infRule as ICorridorInfringementRule;
        if (
          updatedCorridorRule.corridorId &&
          updatedCorridorRule.corridorId !== infRuleCorridorIds[0]
        ) {
          setInfRuleCorridorIds([updatedCorridorRule.corridorId]);
        }
        break;
      case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
        const updatedExclusionRule = infDetailsData.infRule as IExclusionInfringementRule;
        if (
          updatedExclusionRule.selectionZoneId &&
          Number(updatedExclusionRule.selectionZoneId) !== Number(infRuleZoneIds[0])
        ) {
          setInfRuleZoneIds([updatedExclusionRule.selectionZoneId]);
        }
        break;
      case INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT:
        const updatedNoiseRule = infDetailsData.infRule as INoiseInfringementRule;
        if (updatedNoiseRule.thresholds) {
          if (updatedNoiseRule.thresholds.length) {
            const ids = updatedNoiseRule.thresholds.map(threshold => threshold.locationId);
            setInfRuleNoiseIds(ids);
          } else {
            setInfRuleNoiseIds([updatedNoiseRule.thresholds[0].locationId]);
          }
        }
        break;
    }

    if (currentInfringementType !== infDetailsData.infRule.infringementType) {
      updateCurrentInfringementType(infDetailsData.infRule.infringementType);
    }

    // FORM VALIDATION

    const { isValid, errors } = validateInfRule(infDetailsData, infringementRules);
    setFormErrors(errors);
    setFormIsValid(isValid);
  }, [infDetailsData.infRule]);

  // Stops null value errors while component is mounting
  if (!isEditing && currentRuleId === -1) {
    return null;
  } else {
    if (infDetailsData.infRule.isNew) {
      setTabTitle(`${settingsTitle} - ${infringementRulesString} - ${newRuleString}`);
    } else {
      setTabTitle(`${settingsTitle} - ${infringementRulesString} - ${infDetailsData.infRule.name}`);
    }

    return (
      <>
        <div className="settings__split-content">
          <div className="settings__heading">
            <PageHeader
              title={infDetailsData.infRule.isNew ? newRuleString : infDetailsData.infRule.name}
            />
            <div className="infrule_isactive">
              <IsActiveCheckbox
                isEditing={isEditing}
                isActive={infRule.isActive}
                updateIsActive={handleIsActiveChange}
              />
            </div>
          </div>
          <div className="infringementrules_content">
            <div className="infringementrules_card_title">
              <h4>{candidatesString}</h4>
            </div>
            <Card>
              <div className="infringementrules_content_pad">
                <CandidatePanel
                  key={`inf-candidate-panel${isEditing}`}
                  infDetailsData={infDetailsData}
                />
              </div>
            </Card>
            <RulePanel
              infDetailsData={infDetailsData}
              key={`inf-candidate-panel${isEditing}`}
              translationStrings={languageSelectors.getLanguage()}
            />

            <div className="infringementrules_buttons">
              <div className="infringementrules_buttons_left">
                <Button size="m" onClick={onCancelClick} style="standard">
                  {cancelString}
                </Button>
              </div>
              <div className="infringementrules_buttons_right">
                {!isEditing && canUpdate && (
                  <Button size="m" onClick={toggleEditMode} disabled={false} style="primary">
                    {editString}
                  </Button>
                )}
                {isEditing && (
                  <Button
                    size="m"
                    onClick={handleSave}
                    disabled={!infRule.hasChanged || !formIsValid}
                    style="primary">
                    {saveString}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="settings__split-map">
          <InfringementRulesMap
            infRuleGateIds={currentInfringementType === 'Gate' ? infRuleGateIds : []}
            infRuleCorridorIds={currentInfringementType === 'Corridor' ? infRuleCorridorIds : []}
            infRuleZoneIds={currentInfringementType === 'Exclusion' ? infRuleZoneIds : []}
            infRuleNoiseMonitorIds={currentInfringementType === 'Noise' ? infRuleNoiseIds : []}
          />
        </div>
      </>
    );
  }
};
