import gql from 'graphql-tag';

export const UPDATE_OPERATIONS = gql`
  mutation UpdateOperations(
    $ids: [Int!]!
    $operationType: OperationType
    $airportId: String
    $remoteAirportId: String
    $runwayName: String
    $aircraftCategory: AircraftCategory
    $operatorCategory: OperatorCategory
    $aircraftType: String
  ) {
    updateOperations(
      ids: $ids
      operationType: $operationType
      airportId: $airportId
      remoteAirportId: $remoteAirportId
      runwayName: $runwayName
      aircraftCategory: $aircraftCategory
      operatorCategory: $operatorCategory
      aircraftType: $aircraftType
    ) {
      id
      operationType
      airportId
      remoteAirportId
      runwayName
      aircraftCategory
      operatorCategory
      aircraftType
    }
  }
`;

export const UPDATE_OPERATION = gql`
  mutation UpdateOperation(
    $id: Int!
    $runwayName: String
    $aircraftCategory: AircraftCategory
    $operatorCategory: OperatorCategory
    $airportId: String
    $remoteAirportId: String
    $operationType: OperationType
    $aircraftType: String
  ) {
    updateOperation(
      operation: {
        id: $id
        operationType: $operationType
        airportId: $airportId
        remoteAirportId: $remoteAirportId
        runwayName: $runwayName
        aircraftCategory: $aircraftCategory
        operatorCategory: $operatorCategory
        aircraftType: $aircraftType
      }
    ) {
      id
      runwayName
      aircraftCategory
      operatorCategory
      airportId
      remoteAirportId
      operationType
      aircraftType
      time
    }
  }
`;

export const updateOperationBuilder = (updateType: string) => {
  let type = '';
  switch (updateType) {
    case 'operationType':
      type = 'OperationType';
      break;
    case 'aircraftCategory':
      type = 'AircraftCategory';
      break;
    case 'operatorCategory':
      type = 'OperatorCategory';
      break;
    case 'aircraftCount':
      type = 'Int';
      break;
    default:
      type = 'String';
  }
  return gql`
  mutation UpdateOperation(
    $id: Int!
    $${updateType}: ${type}
  ) {
    updateOperation(
      operation: {
        id: $id
        ${updateType}: $${updateType}
      }
    ) {
      id
      runwayName
      aircraftCategory
      operatorCategory
      airportId
      remoteAirportId
      operationType
      aircraftType
      time
    }
  }
`;
};

export const UPDATE_OPERATION_DETAILS = gql`
  mutation UpdateOperation(
    $id: Int!
    $runwayName: String
    $aircraftCategory: AircraftCategory
    $operatorCategory: OperatorCategory
    $airportId: String
    $remoteAirportId: String
    $operationType: OperationType
    $aircraftType: String
  ) {
    updateOperation(
      operation: {
        id: $id
        operationType: $operationType
        airportId: $airportId
        remoteAirportId: $remoteAirportId
        runwayName: $runwayName
        aircraftCategory: $aircraftCategory
        operatorCategory: $operatorCategory
        aircraftType: $aircraftType
      }
    ) {
      id
      runwayName
      aircraftCategory
      operatorCategory
      airportId
      remoteAirportId
      operationType
      aircraftType
      time
    }
  }
`;

export const updateOperationDetailsBuilder = (updateType: string) => {
  let type = '';
  switch (updateType) {
    case 'operationType':
      type = 'OperationType';
      break;
    case 'aircraftCategory':
      type = 'AircraftCategory';
      break;
    case 'operatorCategory':
      type = 'OperatorCategory';
      break;
    case 'aircraftCount':
      type = 'Int';
      break;
    case 'beaconCode':
      type = 'Int';
      break;
    default:
      type = 'String';
  }
  return gql`
  mutation UpdateOperation(
    $id: Int!
    $${updateType}: ${type}
  ) {
    updateOperation(
      operation: {
        id: $id
        ${updateType}: $${updateType}
      }
    ) {
      id
      acid
      runwayName
      aircraftCategory
      aircraftCount
      airportId
      remoteAirportId
      operationType
      aircraftType
      airline
      tailNumber
      beaconCode
      pathName
      operatorCategory
      time
    }
  }
`;
};
