// constants
import { NULL_VALUE } from 'src/constants';

export const createSelectItems = (
  object: any,
  type: any,
  isNullable: boolean,
  translatedList: any
) => {
  const newArray: any = [];
  object.map(item => {
    let newLabel = item;
    newLabel = translatedList[item] ? translatedList[item] : item;
    const newItem = { label: newLabel, key: item };
    if (type === 'aircraftCategory') {
      Object.assign(newItem, {
        icon: `ac-${item.toLowerCase()}`,
      });
    } else if (type === 'operationType') {
      Object.assign(newItem, {
        icon: `ad-${item.toLowerCase()}`,
      });
    }
    newArray.push(newItem);
  });

  if (isNullable) {
    const NullValue = { key: '', label: translatedList[NULL_VALUE], icon: undefined };
    return [NullValue, ...newArray];
  }

  return newArray;
};
