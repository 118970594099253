import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  operationPcaByTimeRangeQuery,
  operationPcaByTimeRangeSchema,
} from 'src/@noiseEvents/resolvers/schema';

export const fetchNearbyFlights = (
  client: ApolloClient<object>,
  pcaParams: {
    startTime: string;
    endTime: string;
    verticalRangeLimit: number;
    horizontalRangeLimit: number;
    noiseMonitorLocation: {
      latitude: number;
      longitude: number;
      altitude: number;
    };
  }
) => {
  return new Promise((resolve, reject) => {
    const GET_NEARBY_FLIGHTS = gql`
      query {
        ${operationPcaByTimeRangeQuery}(
          startTime: "${pcaParams.startTime}"
          endTime: "${pcaParams.endTime}",
          pcaFilter: {
            position: { 
              latitude: ${pcaParams.noiseMonitorLocation.latitude},
              longitude: ${pcaParams.noiseMonitorLocation.longitude},
              altitude:  ${pcaParams.noiseMonitorLocation.altitude}
            }
            verticalRange: { start: null, end: ${pcaParams.verticalRangeLimit} }
            horizontalRange: { start:null, end: ${pcaParams.horizontalRangeLimit} }
          }
        )
        ${operationPcaByTimeRangeSchema}
      }
    `;

    client
      .query({ query: GET_NEARBY_FLIGHTS })
      .then(response => {
        if (response && response.data && response.data[operationPcaByTimeRangeQuery]) {
          resolve({
            data: response.data[operationPcaByTimeRangeQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
