import React, { FC, useState, useEffect, useRef, useMemo, ReactElement } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import {
  StyledMap,
  MapControl,
  GeocoderPin,
  ComplainerGeocoderPin,
  RulerTool,
} from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// functions
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  flyTo,
  fitPointsInMap,
  useMapHover,
  useStaticFlightDisplay,
  uniqueIds,
  useClickOnMapElement,
  useHoverOnMapElement,
  useHoveredPointData,
  useGeocoderPinAlternative,
  useMapRuler,
} from 'src/utils';
import {
  useGeocodePosition,
  goToSelectedAddress,
  addPinToCentre,
  onGeocodingDragEnd,
} from 'src/utils/geocoding';
import { ComplaintsPopup, MapSelectionArea, InquirerPopup, OperationMapTags } from 'src/components';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { FLY_TO_DURATION, MAP_TYPES } from 'src/constants';
import { IMapProps, IPosition } from 'src/@complaints/interfaces';
// ts

import { ITrackInteractionPoint } from 'src/utils/interfaces';
import { ITag, addTagToList, removeTagFromList, vectorLayerToPoint } from 'src/utils/mapTagHelpers';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

// Returns true if there is a location object it's not at (0, 0) with null altitude.
const hasComplainerPosition = (location: IPosition | null) => {
  const isLocationUnset = location && location.longitude === 0 && location.longitude === 0;
  return location && !isLocationUnset;
};

export const MapContainer: FC<IMapProps> = ({ complainerData, pointData, markedTimes }) => {
  const client = useApolloClient();
  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { altitudeUnits },
    map: { mapProjectionString },
  } = configSelectors.getConfig();

  const units = configSelectors.getUnits();

  // Filter date range
  const { from: filterDateFrom, to: filterDateTo } = dateRangeStore.getDateFilters();

  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [drag, updateDragStatus] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [selectedPinCoordinates, setSelectedPinCoordinates] = useState<number[][]>([]);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [selectionBounds]: any = useState(null);
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    fields: { operations: opsFields },
    abbreviations,
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        lat: latLabel,
        lng: lngLabel,
        ruler: ruler,
        amsl: amslLabel,
      },
    },
  } = languageSelectors.getLanguage();
  const labels = Object.assign(opsFields, abbreviations);

  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 2000);

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // restrict map pan
  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const operationIds = useMemo(() => uniqueIds(complainerData.map(data => data.operationId)), [
    complainerData,
  ]);
  useStaticFlightDisplay(
    mapApis,
    pointData,
    false,
    operationIds.length > 0,
    operationIds,
    markedTimes,
    rerunHook
  );

  const [complainerDetails, setComplainerDetails] = useState<ReactElement[]>([]);
  const [complainerPopups, setComplainerPopups] = useState<ReactElement[]>([]);
  const [openedComplainers, setOpenedComplainers] = useState<number[]>([]);
  const openedComplainerRef = useRef<number[]>(openedComplainers);
  const [hoveredComplainer, setHoveredComplainer] = useState<number | null>(null);

  useEffect(() => {
    openedComplainerRef.current = openedComplainers;
  }, [openedComplainers]);

  useEffect(() => {
    // zoom and animate viewport to show all selected complaints on the map
    if (mapApis) {
      const complainerCoordinates: any = [];
      complainerData.map(complainer => {
        if (complainer !== undefined && hasComplainerPosition(complainer.complainerPosition)) {
          complainerCoordinates.push(complainer.complainerPosition);
        }
      });
      fitPointsInMap(mapApis, viewport, setViewport, complainerCoordinates);
    }
  }, [mapApis, openedComplainers]);

  const onComplainerClick = (id: number) => {
    const ids = openedComplainerRef.current;
    const idx = openedComplainerRef.current.findIndex(e => e === id);
    if (idx === -1) {
      setOpenedComplainers([...ids, id]);
    } else {
      const newComplainers: number[] = [...ids];
      newComplainers.splice(idx, 1);
      setOpenedComplainers(newComplainers);
    }
  };

  const onComplainerHoverEnter = (id: number) => {
    setHoveredComplainer(id);
  };

  const onComplainerHoverExit = () => {
    setHoveredComplainer(null);
  };

  useEffect(() => {
    const pins: ReactElement[] = [];
    const pinCoordinates: number[][] = [];
    const complainerIds: number[] = [];
    setComplainerDetails([]);
    complainerData.map(({ id, complainerId, complainerPosition }) => {
      if (!complainerPosition) {
        return false;
      }

      const idx = complainerIds.findIndex(e => e === complainerId);
      if (idx === -1 && hasComplainerPosition(complainerPosition)) {
        pins.push(
          <ComplainerGeocoderPin
            key={`pin_${id}`}
            latitude={complainerPosition.latitude}
            longitude={complainerPosition.longitude}
            draggable={false}
            onMouseEnter={() => onComplainerHoverEnter(complainerId)}
            onMouseLeave={() => onComplainerHoverExit()}
            onClick={() => {
              onComplainerClick(complainerId);
            }}
          />
        );
      }
      pinCoordinates.push([complainerPosition.longitude, complainerPosition.latitude]);
    });

    // Remove any popups that are no longer selected
    const newOpened: number[] = [];
    openedComplainerRef.current.map(id => {
      if (complainerData.findIndex(e => e.complainerId === id) !== -1) {
        newOpened.push(id);
      }
    });

    setOpenedComplainers(newOpened);
    setTimeout(() => {
      // TODO: short-term solution to improve UX - container's logic needs refactoring
      // add pins after animation is completed
      setComplainerDetails(pins);
      setSelectedPinCoordinates(pinCoordinates);
    }, FLY_TO_DURATION);
  }, [complainerData]);

  useEffect(() => {
    const popups: ReactElement[] = [];

    // Finds and removes a closed popup from map
    const closeInquirerPopup = (popups: any, key: number, id: number) => {
      popups = popups.filter(popup => popup.key !== `inq_popup_${key}`);
      setOpenedComplainers(openedComplainers.filter(item => item !== id));
    };

    openedComplainers.map((id: number) => {
      const data = complainerData.find(e => e.complainerId === id);
      if (data !== undefined && hasComplainerPosition(data.complainerPosition)) {
        popups.push(
          <InquirerPopup
            key={`inq_popup_${data.id}`}
            latitude={data.complainerPosition.latitude}
            longitude={data.complainerPosition.longitude}
            complainerName={data.complainerName}
            complainerDetails={data.complainer}
            complainerContactMethod={data.contactMethod}
            sameComplainerCount={
              complainerData.filter(item => item.complainerId === data.complainerId).length
            }
            elevation={data.complainerPosition.altitude}
            languageData={{ latLabel, lngLabel, amslLabel }}
            mapApis={mapApis}
            onClose={() => {
              closeInquirerPopup(popups, data.id, id);
            }}
            id={data.id}
          />
        );
      }
    });

    // Only open the hover one if it hasn't been clicked open
    if (
      hoveredComplainer !== null &&
      openedComplainers.findIndex(e => e === hoveredComplainer) === -1
    ) {
      const data = complainerData.find(e => e.complainerId === hoveredComplainer);
      if (data !== undefined && data.complainerPosition) {
        popups.push(
          <InquirerPopup
            key={`inq_popup_${data.id}`}
            latitude={data.complainerPosition.latitude}
            longitude={data.complainerPosition.longitude}
            complainerName={data.complainerName}
            complainerDetails={data.complainer}
            sameComplainerCount={
              complainerData.filter(item => item.complainerId === data.complainerId).length
            }
            elevation={data.complainerPosition.altitude}
            languageData={{ latLabel, lngLabel, amslLabel }}
            mapApis={mapApis}
            id={data.id}
          />
        );
      }
    }

    setComplainerPopups(popups);
  }, [openedComplainers, hoveredComplainer]);

  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude], ...selectedPinCoordinates],
  });

  const requiredMouseLayers = useMemo(
    () => ['static-points', 'static-lines', 'points', 'lines'],
    []
  );
  const mouseFilters = useMemo(() => ['any', true], []);

  const { hoveredElement, handleHover } = useHoverOnMapElement({
    viewport,
    mapApis,
    layerArray: requiredMouseLayers,
    tracksFilter: mouseFilters,
    restrictZoomLevels: false,
    layerPrefix: '',
    radius: 0.5,
    disabled: false,
    radiusGradient: 0.1,
    mapType: MAP_TYPES.COMPLAINTDETAILS,
  });

  const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

  const [tagList, setTagList] = useState<ITag[]>([]);

  const { handleClick, clickedElement } = useClickOnMapElement(
    viewport,
    mapApis,
    requiredMouseLayers,
    '',
    false,
    '',
    0.5,
    0.1,
    setTagList
  );

  useEffect(() => {
    addTagToList(clickedElement, tagList, setTagList);
  }, [clickedElement]);

  const [hoveredPointData, setHoveredPointData] = useState<ITrackInteractionPoint>({
    amsl: null,
    time: null,
    longitude: null,
    latitude: null,
    showPointData: false,
    flightId: null,
  });

  const matchedHoverOperation = useMemo(() => {
    if (hoveredElement) {
      return pointData.find(p => p.id === hoveredElement.properties.id);
    }

    return null;
  }, [hoveredElement]);

  let userHomeLocation = null;
  if (matchedHoverOperation && matchedHoverOperation.id) {
    const hoveredOperationComplainer = complainerData.find(
      cd => cd.operationId === matchedHoverOperation.id
    );
    userHomeLocation = hoveredOperationComplainer.complainerPosition;
  }

  useHoveredPointData({
    mapApis,
    operation: matchedHoverOperation,
    hoveredElement,
    profileHoverTime: null,
    setSelectedPointData: setHoveredPointData,
    isPlaybackMode: false,
    isPlaybackRunning: false,
    userHomeLocation,
    mapProjectionString,
  });

  // Ruler Tool

  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx({
        map_wrapper: !isFullScreen,
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          mapStyle={mapStyle}
          onClick={handleClick}
          onHover={debounce(handleHover, 5)}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          {isLocationTagOpen && (
            <ComplaintsPopup
              latitude={latitude}
              longitude={longitude}
              address={locationAddress || place}
              elevation={elevation}
              altitudeUnits={altitudeUnits}
              languageData={{ latLabel, lngLabel, amslLabel }}
              mapApis={mapApis}
              onClose={() => {
                updateLocationTagOpen(!isLocationTagOpen);
                setHoveredComplainer(null);
              }}
            />
          )}
          <MapSelectionArea selectionBounds={selectionBounds} />

          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              removeHovered();
              updateDragStatus(true);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]) =>
              onGeocodingDragEnd({
                longitude,
                latitude,
                updateDragStatus,
                updateGeocoding,
                updateLocationAddress,
                updateLocationTagOpen,
                addRemoveCircles,
              })
            }
            onMouseEnter={() => {
              removeHovered();
              updateDragStatus(true);
            }}
            onMouseLeave={() => {
              updateDragStatus(false);
            }}
          />
          {enableMapControls && complainerDetails.length ? complainerDetails : null}
          {complainerPopups}
          {enableMapControls && (
            <MapControl
              isPinAdded={latitude && longitude ? true : false}
              addPinToCentre={() =>
                addPinToCentre({
                  updateLocationAddress,
                  geocoding,
                  viewport,
                  updateGeocoding,
                  addRemoveCircles,
                  updateLocationTagOpen,
                })
              }
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSearch: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              rulerControl={{
                isRulerEnabled,
                toggleRuler,
              }}
              translationData={{
                home: backToCenterLabel,
                search: searchLabel,
                addPin: addPinLabel,
                removePin: removePinLabel,
                mapSettings: mapSettingsTitle,
                ruler,
              }}
              resetView={resetView}
              addressSearch={AddressSearch}
              closeSearch={closeSearch}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapRef={{ current: mapNode }}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(filterDateFrom, 'start'),
              dateFilterTo: dateTimeInQueryUTC(filterDateTo, 'end'),
            }}
          />
          {tagList.map(({ data, uuid }) => {
            const matchingOperation = pointData.find(
              operation => operation.id === data.properties.id || operation.id === data.id
            );

            const matchingComplainer = complainerData.find(
              cd => cd.operationId === matchingOperation.id
            );
            if (matchingOperation && matchingComplainer) {
              return (
                <OperationMapTags
                  element={data}
                  pointData={vectorLayerToPoint({
                    operation: matchingOperation,
                    clickedElement: data,
                    userHomeLocation: matchingComplainer.complainerPosition,
                    mapProjectionString,
                  })}
                  labels={labels}
                  onClose={() => removeTagFromList(uuid, tagList, setTagList)}
                  draggable
                  key={uuid}
                />
              );
            } else {
              setTagList([]);
            }
          })}
          {hoveredElement && (
            <OperationMapTags
              element={hoveredElement}
              labels={labels}
              pointData={hoveredPointData}
              draggable={false}
            />
          )}
          <RulerTool
            distanceUnits={units.distance}
            coordinates={rulerCoordinates}
            isRulerEnabled={isRulerEnabled}
            addressCoordinates={geocoding}
            mapProjection={mapProjectionString}
            handleDragEvent={rulerCoordinatesChanged}
            mapApis={mapApis}
          />
        </StyledMap>
      </div>
    </div>
  );
};
