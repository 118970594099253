import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// providers
import { ExportDispatchContext } from 'src/app/providers/ExportProvider';
// selectors
import { useExportSelectors } from 'src/app/reducers';
// actions
import { checkExportResult } from 'src/app/actions/exportActions';
// functions
import { downloadFileFromUrl } from 'src/utils';

let timer: ReturnType<typeof setTimeout>;

export const ExportManagerContainer = () => {
  const client = useApolloClient();
  const dispatcher = useContext(ExportDispatchContext);
  const longPollingInterval = 5000; // every 5 seconds

  // Export reports
  const exportSelectors = useExportSelectors();
  const [inProgressExport] = exportSelectors.getInProgress();
  const [completedExport] = exportSelectors.getCompleted();
  const loading = exportSelectors.isLoading();

  const checkDownloadStatus = () => {
    if (loading && inProgressExport !== undefined) {
      checkExportResult({
        client,
        dispatcher,
        exportRequest: inProgressExport,
      });
    }
  };

  const triggerDownload = () => {
    if (completedExport !== undefined) {
      downloadFileFromUrl(`${completedExport.uri}`);
    }
  };

  const [downloadInitiated, updateDownloadStatus] = useState<boolean>(false);
  useEffect(() => {
    if (loading) {
      updateDownloadStatus(true);
      timer = setInterval(checkDownloadStatus, longPollingInterval);
    } else {
      if (downloadInitiated) {
        updateDownloadStatus(false);
        clearInterval(timer);
        triggerDownload();
      }
    }
  }, [loading]);

  return null;
};
