import React from 'react';
import uuid from 'uuid';

// Reducers
import { useRolesSelectors, useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

// Provider
import { SettingsStateProvider } from 'src/@settings/provider/SettingsStateProvider';
import { ScenariosProvider } from 'src/@settings/provider/Scenarios/ScenariosProvider';

// Variables
import { getDeployedProductId, setTabTitle } from 'src/utils';
import { SETTINGS } from 'src/constants';
import { routes } from 'src/app/routes';

// Components
import { NavLink } from 'react-router-dom';
import { SideBarMenuRoute } from '@ems/client-design-system';

// Selectors

// Permissions
import { canAccessRoute } from 'src/app/components/CanAccessRoute';
import { getInfringementRulePermissions } from 'src/@settings/containers/Rules/InfringementRulesContainerHelpers';
import {
  canReadSomeOf,
  canInsertSomeOf,
  canDeleteSomeOf,
  canUpdateSomeOf,
  getSpatialFeaturesPermissions,
  getCarbonEmissionsSettingsPermissions,
} from 'src/@settings/functions';

// Flags
import {
  CHANGELOGS as CHANGELOGS_FLAG,
  NMTMANAGEMENT as NMTMANAGEMENT_FLAG,
  TAXIPATHTOOL as TAXIPATHTOOL_FLAG,
} from 'src/app/featureToggles';
import { useMonitorLocationPermissions } from 'src/@settings/containers/LocationManagement/Hooks/useMonitorLocationPermissions';

export interface IMenuList {
  label: string;
  path: string;
  children: IMenuList[];
}

export interface ISideBarMenuLink {
  id: string;
  link: React.ReactNode;
  children?: ISideBarMenuLink[];
}

const buildMenu = (list: IMenuList[], parentPath = ''): ISideBarMenuLink[] =>
  list.map(({ label, children, path }) => ({
    id: uuid.v4(),
    link: (
      <NavLink
        exact={children.length > 0}
        className="side-menu__link"
        activeClassName="active"
        to={{
          pathname: `/${getDeployedProductId()}/${SETTINGS}${
            parentPath.length ? `/${parentPath}` : ''
          }/${path}`,
        }}>
        {label}
      </NavLink>
    ),
    children: children.length > 0 ? buildMenu(children, path) : [],
  }));

export const SettingsLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const rolesSelectors = useRolesSelectors();
  const configSelectors = useConfigSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        title: settingsTitle,
        tabs: {
          general: generalSettingsString,
          airtrak: airtrakSettingsString,
          aircraftMappings: aircraftMappingsString,
          fleetMix: fleetMixString,
          timeInModes: timeInModesString,
          loadFactors: loadFactorsString,
          fuelTypes: fuelTypesString,
          modelingSettings: modelingSettingsString,
          infringementRules: infringementRulesString,
          spatialFeatures: spatialFeaturesString,
          taxipath: taxipathString,
          nmtManagement: nmtManagementString,
          contours: contoursString,
          aircraftAssignments: aircraftAssignmentsString,
          changelog: changelogString,
        },
      },
    },
  } = languageSelectors.getLanguage();

  // Flags
  const FEATURE_FLAG_CHANGELOGS = configSelectors.isFeatureAvailable(CHANGELOGS_FLAG);
  const FEATURE_FLAG_NMTMANAGEMENT = configSelectors.isFeatureAvailable(NMTMANAGEMENT_FLAG);
  const FEATURE_FLAG_TAXIPATHTOOL = configSelectors.isFeatureAvailable(TAXIPATHTOOL_FLAG);

  const hasRoutePermissions = (targetRoute: string): boolean => {
    // Find first route that end with the targetRoute
    const routeItem = routes.find(({ route }: { route: string }) =>
      new RegExp(`${targetRoute}$`).test(route)
    );
    return (
      routeItem &&
      canAccessRoute(routeItem.permissions, rolesSelectors) &&
      availableRoutes.includes(routeItem.route)
    );
  };

  const menuItems: IMenuList[] = [
    {
      label: generalSettingsString,
      path: 'general',
      children: [],
    },
  ];

  // user must have read permission to view infringement rules
  const infringementRulePermissions = getInfringementRulePermissions(rolesSelectors);
  if (canReadSomeOf(infringementRulePermissions)) {
    menuItems.push({
      label: infringementRulesString,
      path: 'infringement-rules',
      children: [],
    });
  }

  // user must have read permission to view spatial features
  const spatialFeaturePermissions = getSpatialFeaturesPermissions(rolesSelectors);
  if (canReadSomeOf(spatialFeaturePermissions)) {
    menuItems.push({
      label: spatialFeaturesString,
      path: 'spatial-features',
      children: [],
    });
  }

  // Only Air Quality Administrator can access carbon emissions settings
  const carbonEmissionRulesSettingsPermissions = getCarbonEmissionsSettingsPermissions(
    rolesSelectors
  );
  if (
    canReadSomeOf(carbonEmissionRulesSettingsPermissions) &&
    canUpdateSomeOf(carbonEmissionRulesSettingsPermissions) &&
    canInsertSomeOf(carbonEmissionRulesSettingsPermissions) &&
    canDeleteSomeOf(carbonEmissionRulesSettingsPermissions) &&
    hasRoutePermissions('carbonemissions')
  ) {
    menuItems.push({
      label: airtrakSettingsString,
      path: 'carbon',
      children: [
        {
          label: aircraftMappingsString,
          path: 'aircraft-mappings',
          children: [],
        },
        {
          label: fleetMixString,
          path: 'fleet-mix',
          children: [],
        },
        {
          label: timeInModesString,
          path: 'time-modes',
          children: [],
        },
        {
          label: loadFactorsString,
          path: 'load-factors',
          children: [],
        },
        {
          label: fuelTypesString,
          path: 'fuel-types',
          children: [],
        },
      ],
    });
  }

  // Changelog screen permission check.
  if (FEATURE_FLAG_CHANGELOGS && rolesSelectors.hasPermission(`AuditLogEntrySummary.Read`)) {
    menuItems.push({
      label: changelogString,
      path: 'changelog',
      children: [],
    });
  }

  // Modeling
  const modelingPath = (childPath: string) => `modeling/${childPath}`;
  const modelingPermissions = {
    aircraftAssignments: hasRoutePermissions(modelingPath('aircraft-assignments')) && {
      label: aircraftAssignmentsString,
      path: 'aircraft-assignments',
      children: [],
    },
    contours: rolesSelectors.hasPermission('AircraftNoiseContour.Read') && {
      label: contoursString,
      path: 'contours',
      children: [],
    },
  };

  if (Object.values(modelingPermissions).some(value => !!value)) {
    menuItems.push({
      label: modelingSettingsString,
      path: 'modeling',
      children: Object.values(modelingPermissions).filter(child => !!child && child),
    });
  }
  const monitorLocationPermissions = useMonitorLocationPermissions();
  if (
    FEATURE_FLAG_NMTMANAGEMENT &&
    Object.values(monitorLocationPermissions).every(value => !!value)
  ) {
    menuItems.push({
      label: nmtManagementString,
      path: 'nmt-management',
      children: [],
    });
  }

  if (FEATURE_FLAG_TAXIPATHTOOL) {
    menuItems.push({
      label: taxipathString,
      path: 'taxi-path',
      children: [],
    });
  }

  /**
   * Finds the selected item based on the current window location pathname.
   *
   * @param items - The array of items to search through.
   * @returns The selected item or null if no item is found.
   */
  const findItem = (items: IMenuList[]): IMenuList | null => {
    const pathname = window.location.pathname;
    for (const item of items) {
      if (pathname.includes(item.path)) {
        // Check if any child matches more specifically
        if (item.children && item.children.length > 0) {
          const childMatch = findItem(item.children);
          if (childMatch) {
            return childMatch;
          }
        }
        return item;
      }
    }
    return null;
  };

  setTabTitle(`${settingsTitle} - ${findItem(menuItems)?.label}`);

  return (
    <SettingsStateProvider>
      <ScenariosProvider>
        <div className="page-wrapper simple-sidebar-layout">
          <div className="settings-layout-content">
            <div className="settings-sidebar">
              <div className="sidebar-child">
                <SideBarMenuRoute links={buildMenu(menuItems)} />
              </div>
            </div>
            <div className="settings-content">{children}</div>
          </div>
        </div>
      </ScenariosProvider>
    </SettingsStateProvider>
  );
};
