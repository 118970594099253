import React, { FC } from 'react';
import cx from 'classnames';

import { IPageHeader } from './interfaces';

export const PageHeader: FC<IPageHeader> = ({
  children,
  title,
  showReturnButton = false,
  returnToButton,
}) => (
  <>
    {showReturnButton && <div className="page-header-button">{returnToButton}</div>}
    <section className={cx('page-header')}>
      <h1 className="page-header-text">{title}</h1>
      {children}
    </section>
  </>
);
