import React, { FC, SyntheticEvent, useState } from 'react';
// selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { useLanguageSelectors } from 'src/app/reducers/languageReducer';
// BluePrint JS
import { PopoverInteractionKind, Position } from '@blueprintjs/core';
// common components
import { Popover, Button, DateRangePicker, Icons } from '@ems/client-design-system';
// functions
import { predefinedDateRangeList } from 'src/app/functions/dateRange';
import { format, getDatePickerRange } from 'src/utils/dateTimeConverters';
import { LocaleCalendar } from 'src/utils/localeCalendar';

interface IDateRangePickerProps {
  hasShortcuts: boolean;
  isSingleDayRangeAllowed: boolean;
  hasContigousCalendarMonths: boolean;
  onDatesSelected: (firstDate: Date, secondDate: Date) => void;
  allowClearDates?: boolean;
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  currentDates?: [Date, Date];
}
export const DateRange: FC<IDateRangePickerProps> = ({
  hasShortcuts,
  isSingleDayRangeAllowed,
  hasContigousCalendarMonths,
  onDatesSelected,
  allowClearDates,
  defaultDateFrom,
  defaultDateTo,
  currentDates,
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  const {
    globals: { languageTag, firstDayOfWeek },
    operations: {
      filter: { dateFormat },
    },
  } = configSelectors.getConfig();

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { dateRangeList, monthsLong, weekdaysShort, weekdaysLong },
    },
  } = languageSelectors.getLanguage();

  // By supplying LocaleUtils to Blueprint DatePicker as a prop, you can customize how dates are rendered
  const localeCalendar = new LocaleCalendar(
    { firstDayOfWeek },
    { monthsLong, weekdaysShort, weekdaysLong }
  );

  const localeUtils = localeCalendar.localeUtils();

  const [isDateSelected, setIsDateSelected] = useState<boolean>(
    !!defaultDateFrom && !!defaultDateTo
  );
  const [dateFrom, setDateFrom] = useState<Date>(defaultDateFrom ? defaultDateFrom : new Date());
  const [dateTo, setDateTo] = useState<Date>(defaultDateTo ? defaultDateTo : new Date());
  const [maxDate] = useState<Date>(getDatePickerRange('max'));
  const [minDate] = useState<Date>(getDatePickerRange('min'));
  const shortcuts = hasShortcuts ? predefinedDateRangeList(dateRangeList) : false;

  // Add a clear dates to shortcuts
  if (shortcuts && allowClearDates && isDateSelected) {
    shortcuts.push({ dateRange: [null, null], key: 'clear', label: 'Clear selected dates' });
  }

  // dialog's open & close state
  const [isDialogOpen, toggleDialog] = useState<boolean>(false);

  // on interaction with datepicker's popover
  const popOverInteraction = (
    nextOpenState: boolean,
    event?: SyntheticEvent<HTMLElement>
  ): void => {
    if (typeof event !== 'undefined' && event.type === 'mousedown') {
      // mouse clicked outside the Popover
      toggleDialog(false);
    }
  };
  // datepicker's button's title
  const buttonTitle = (from: Date, to: Date, dateFormat: string): string => {
    const fromDateString = format(from, dateFormat);
    const toDateString = format(to, dateFormat);
    return fromDateString === toDateString
      ? `${format(from, dateFormat, languageTag)}`
      : `${format(from, dateFormat, languageTag)} - ${format(to, dateFormat, languageTag)}`;
  };
  // datepicker's button clicked
  const buttonOnClick = () => {
    toggleDialog(!isDialogOpen);
  };
  // when datepicker's date-values changed
  const dateChanged = (selectedDates: Date[]) => {
    setIsDateSelected(true);
    const [dateFrom, dateTo] = selectedDates;
    // Upon selection of the TO date, the calendar control will hide and the corresponding data in the table will be loaded
    if (dateFrom) {
      setDateFrom(dateFrom);
      if (dateTo) {
        setDateTo(dateTo);
        if (selectedDates.length === 2) {
          onDatesSelected(dateFrom, dateTo);
          toggleDialog(false);
        }
      }
    }
    if (dateFrom === null && dateTo === null) {
      onDatesSelected(dateFrom, dateTo);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
      setIsDateSelected(false);
      toggleDialog(false);
    }
  };

  const handleClose = () => {
    toggleDialog(false);
  };

  return (
    <Popover
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.CLICK}
      transitionDuration={0}
      autoFocus
      isOpen={isDialogOpen}
      onClose={handleClose}
      onInteraction={popOverInteraction}>
      <Button
        style="subtle"
        leftIcon={<Icons iconName={`ic-ui-calendar`} size="24" />}
        aria-label={`Calendar ${buttonTitle(dateFrom, dateTo, dateFormat)}`}
        onClick={buttonOnClick}>
        {isDateSelected ? buttonTitle(dateFrom, dateTo, dateFormat) : 'Please select a date range'}
      </Button>
      <DateRangePicker
        locale={languageTag}
        localeUtils={localeUtils}
        shortcuts={shortcuts}
        maxDate={maxDate}
        onChange={dateChanged}
        contiguousCalendarMonths={hasContigousCalendarMonths}
        minDate={minDate}
        allowSingleDayRange={isSingleDayRangeAllowed}
        defaultValue={currentDates ? [currentDates[0], currentDates[1]] : null}
      />
    </Popover>
  );
};
