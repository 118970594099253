import gql from 'graphql-tag';

export const UPDATE_NOISE_EVENT_CAUSE = gql`
  mutation UpdateNoiseEventCause($id: Int!, $cause: NoiseEventCause!) {
    setNoiseEventCause(id: $id, cause: $cause) {
      id
      primaryCause
    }
  }
`;

export const CORRELATE_TO_OPERATION = gql`
  mutation CorrelateToOperation($id: Int!, $cause: NoiseEventCause!, $operationId: Int!) {
    setNoiseEventCause(id: $id, cause: $cause, correlation: { operationId: $operationId }) {
      id
      primaryCause
      correlation {
        ... on NoiseEventOperationCorrelation {
          operationId
        }
      }
    }
  }
`;

export const UPDATE_NOISE_EVENTS_CAUSE = gql`
  mutation UpdateNoiseEventsCause($ids: [Int!]!, $cause: NoiseEventCause!) {
    setNoiseEventsCause(ids: $ids, cause: $cause) {
      id
      primaryCause
    }
  }
`;

export const BREAK_EXISTING_CORRELATION = gql`
  mutation UpdateNoiseEventsCause($ids: [Int!]!, $cause: NoiseEventCause!) {
    setNoiseEventsCause(ids: $ids, cause: $cause, correlatedDataEffect: BreakExistingCorrelations) {
      id
      primaryCause
    }
  }
`;

export const MODIFY_NOISE_EVENTS_TAGS = gql`
  mutation ModifyExistingTags($ids: [Int!]!, $tags: [InputTag]) {
    addNoiseEventTags(ids: $ids, tags: $tags) {
      id
      tags {
        name
        isCompleted
      }
    }
  }
`;
