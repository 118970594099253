import React, { FC } from 'react';
import { DateTime } from 'luxon';
// components
import { FieldDetails, Card } from '@ems/client-design-system';
// utils
import { DATE_TIME_FORMAT } from 'src/constants';
// functions
import { getDisturbanceTypesPlaceholder } from 'src/@complaints/functions';
// interfaces
import { IViewComplaint } from 'src/@complaints/interfaces';

export const ComplaintDetails: FC<IViewComplaint> = ({
  columns,
  data,
  twelveHourFormat,
  privacyEnabled,
}) => {
  const {
    complaint,
    incidentTime,
    responseRequired,
    contactTime,
    disturbanceTypes = [],
    privacyAgreementId,
  } = data;
  const dateFormat = twelveHourFormat ? DATE_TIME_FORMAT.FIELD_12H : DATE_TIME_FORMAT.FIELD_24H;
  const displayContactTime = contactTime
    ? DateTime.fromISO(contactTime, { setZone: true }).toFormat(dateFormat)
    : '—';
  const displayIncidentTime = incidentTime
    ? DateTime.fromISO(incidentTime, { setZone: true }).toFormat(dateFormat)
    : '—';
  return (
    <Card className="inquiry-view-card">
      <div className="row">
        <FieldDetails
          fieldType="inputField"
          className="col-md-5"
          label={columns.contactTime}
          text={displayContactTime}
        />
        <FieldDetails
          fieldType="inputField"
          className="col-md-5"
          label={columns.incidentTime}
          text={displayIncidentTime}
        />
      </div>
      <FieldDetails
        fieldType="inputField"
        label={columns.reason}
        text={getDisturbanceTypesPlaceholder(disturbanceTypes)}
      />
      <FieldDetails
        fieldType="inputField"
        className="col-md-10 field-details-complaint"
        label={columns.complaint}
        text={complaint}
      />
      <FieldDetails
        checked={responseRequired}
        fieldType="checkbox"
        className="col-md-8"
        label={columns.responseRequired}
      />
      {privacyEnabled && (
        <FieldDetails
          checked={privacyAgreementId}
          fieldType="checkbox"
          className="col-md-8"
          label={columns.privacyEnabled}
        />
      )}
    </Card>
  );
};
