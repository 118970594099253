import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import cx from 'classnames';
// components
import { NavBar } from 'src/components/Navbar';

// Type whatever you expect in 'this.props.match.params.*'
interface SimpleSidebarLayoutScreenProps extends RouteComponentProps {
  displayNav?: boolean;
  children: React.ReactNode;
  subMenuLinkSelected?: (selectedObject) => void;
}
const SimpleSidebarLayoutScreen: React.FC<SimpleSidebarLayoutScreenProps> = ({
  displayNav = true,
  children,
  subMenuLinkSelected,
}) => {
  if (!displayNav) {
    return (
      <div
        className={cx('page-wrapper', {
          'simple-sidebar-layout': true,
        })}>
        {children}
      </div>
    );
  }
  return (
    <NavBar subMenuLinkSelected={subMenuLinkSelected}>
      <div
        className={cx('page-wrapper', {
          'simple-sidebar-layout': true,
        })}>
        {children}
      </div>
    </NavBar>
  );
};

export const SimpleSidebarLayout = withRouter(SimpleSidebarLayoutScreen);
