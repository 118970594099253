import { useQuery } from '@apollo/react-hooks';
import { GetNMTDataSummary, NMTDataQueryVariables } from '../interfaces';
import { GET_MONITOR_LOCATION_SUMMARIES } from '../queries';
import { useCallback, useMemo } from 'react';
import { ILoadMoreOptions } from '../../Modeling/AircraftAssignments/interfaces';
import { useLocationManagementContext } from '../Context/LocationManagementContext';
import { LocationManagementActions } from '../Context/LoctionManagementReducer';
import { NMT_LIST_PAGE_SIZE } from '../constants';

export const useFetchNMTData = ({ queryVariables }: { queryVariables?: NMTDataQueryVariables }) => {
  const { loading, variables, data, fetchMore } = useQuery<
    GetNMTDataSummary,
    NMTDataQueryVariables | {}
  >(GET_MONITOR_LOCATION_SUMMARIES, {
    variables: queryVariables ?? {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const nmtData = useLocationItems(data);
  const count = data?.monitorLocationSummaries?.totalCount ?? 0;
  const loadMore = useLoadMore(variables, fetchMore);

  const pageInfo = data?.monitorLocationSummaries.pageInfo;

  return { nmtData, pageInfo, count, isLoading: loading, loadMore };
};

export interface IUpdateQueryOptions {
  fetchMoreResult?: GetNMTDataSummary;
  variables?: NMTDataQueryVariables | {};
}

export interface IFetchMoreArgs {
  variables: NMTDataQueryVariables | {};
  updateQuery: (
    previousQueryResult: GetNMTDataSummary,
    options: IUpdateQueryOptions
  ) => GetNMTDataSummary;
}

const useLocationItems = (data: GetNMTDataSummary) => {
  const { dispatch } = useLocationManagementContext();
  const nmtData = useMemo(() => {
    const returnedData = data?.monitorLocationSummaries.items ?? [];
    dispatch({ type: LocationManagementActions.UPDATE_LOCATION_ITEMS, payload: { returnedData } });
    return returnedData;
  }, [data, dispatch]);

  return nmtData;
};

// Callback for loading more nmt items
const useLoadMore = (variables, fetchMore) =>
  useCallback(
    (_client, _dispatcher, options: ILoadMoreOptions) => {
      const fetchMoreArguments: IFetchMoreArgs = {
        variables: {
          ...variables,
          count: NMT_LIST_PAGE_SIZE,
          cursor: options.endCursor as string,
        },
        updateQuery: (
          { monitorLocationSummaries: { items: previousItems } }: GetNMTDataSummary,
          {
            fetchMoreResult: {
              monitorLocationSummaries: {
                pageInfo: newPageInfo,
                items: newItems,
                totalCount: newTotalCount,
                __typename,
              },
            },
          }: IUpdateQueryOptions
        ) => ({
          monitorLocationSummaries: {
            pageInfo: newPageInfo,
            items: [...previousItems, ...newItems],
            totalCount: newTotalCount,
            __typename,
          },
        }),
      };
      void fetchMore(fetchMoreArguments);
    },
    [variables, fetchMore]
  );
