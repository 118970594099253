import ApolloClient from 'apollo-client';
import { actionTypes } from '../actionTypes';
import { fetchAllNoiseMonitors } from '../resolvers/infringementRulesResolver';
import { IGetAllNoiseMonitors } from './interfaces';

export const getAllNoiseMonitors = (
  client: ApolloClient<object>,
  dispatcher: React.Dispatch<IGetAllNoiseMonitors>
) => {
  fetchAllNoiseMonitors(client)
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_ALL_NOISE_MONITORS, payload: data.monitorLocations });
    })
    .catch(error => {
      console.error(error);
    });
};
