/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext } from 'react';
import { ISortSelectors, ISortState, ISortOperator } from 'src/@reports/interfaces';
import { actionTypes } from 'src/@reports/actionTypes';
import { useSelectors } from 'src/utils/storeHelpers';
import { ReportsStateContext } from 'src/@reports/providers/ReportsStateProvider';

interface IState {
  sortArray: ISortOperator[];
}

const getSortFields = (requestedField: string): string[] => {
  switch (requestedField) {
    case 'templateName':
      return ['endTime', requestedField];
    default:
      return [requestedField];
  }
};

export const useSortSelectors: () => ISortSelectors = () => {
  const state: any = useContext(ReportsStateContext);
  const dataState: ISortState = state.sort;
  return useSelectors(dataState, (state: ISortState) => ({
    getIconProps: sortName => {
      if (sortName === 'lastUpdateTime' && state.sortArray.length > 1) {
        // do not show icon for time when it is implicit
        return false;
      }
      return findSortObject(state, sortName);
    },
    getSortString: () => {
      const sortString = state.sortArray
        .map(sortObject => {
          const sortFields = getSortFields(sortObject.field);
          const directionString = `direction: ${sortObject.direction}`;
          return sortFields.map(field => `{ field: "${field}", ${directionString} }`);
        })
        .join(',');

      // Example output
      // [{field: "lastUpdateTime", direction: ASC}]
      // OR
      // [{field:"endTime", direction:ASC}, {field:"templateName", direction:ASC} ]
      return `sort: [${sortString}]`;
    },
  }));
};

const initSortArray: ISortOperator = {
  field: 'templateName',
  direction: 'DESC',
};

export const sortInitialState: ISortState = {
  sortArray: [Object.assign({}, initSortArray)],
};

const findSortObject = (state: ISortState, sortName: string) =>
  state.sortArray.find(({ field }) => field === sortName);

interface IAction {
  type: string;
  data: string;
}
export const sortReducer = (state: ISortState, { type, data: field }: IAction): IState => {
  switch (type) {
    case actionTypes.SORT_TABLE:
      const currentSortObject = findSortObject(state, field);
      const newSortObject: ISortOperator = {
        field,
        direction: 'DESC',
      };

      if (currentSortObject && currentSortObject.field === field) {
        newSortObject.direction = currentSortObject.direction === 'ASC' ? 'DESC' : 'ASC';
      }

      return {
        ...state,
        sortArray: [newSortObject],
      };

    default:
      return state;
  }
};
