import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes: any = keyToActionTypes(
  {
    // DATA
    GET_FIRST_FETCH: null,
    DATA_FETCHED: null,
    GET_TOTAL_COUNT: null,
    INLINE_EDIT: null,
    LOAD_MORE: null,
    SELECT_ROW: null,
    SELECT_TRACK: null,
    SET_SELECTED_TRACKS: null,
    SORT_TABLE: null,
    RESET_DATA: null,
    UPDATE_TABLE_IDS: null,
    // FILTER
    UPDATE_DATE_RANGE: null,
    UPDATE_SELECTED_ITEMS: null,
    CLEAR_SELECTED_ITEMS: null,
    UPDATE_RANGE_FILTER: null,
    INITIALISE_STORE: null,
    SET_TO_PCA_FILTER: null,
    CLEAR_PCA_FILTER: null,
    UPDATE_ACID_FILTER: null,
    UPDATE_TAIL_NUMBER_FILTER: null,
  },
  'OPERATIONS'
);
