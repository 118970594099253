import React, { FC, ChangeEvent, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// Hooks
import { useSessionStorage, TPersistentStorageHook } from 'src/utils';
// context
import {
  useConfigSelectors,
  useInfringementRulesSelectors,
  useLanguageSelectors,
} from 'src/app/reducers';
import { InfringementDispatchContext } from 'src/@infringements/providers/InfringementsStateProvider';
import { useSortSelectors, useDataSelectors } from 'src/@infringements/reducers';
// common components
import {
  Filter,
  FilterRow,
  RangeSelector,
  validateTimeFormat,
  isValidTime,
  onCloseTimeDialogValidation,
  isCompletedTimeFormat,
  IDFilter,
  IDropdownItem,
} from '@ems/client-design-system';
import {
  updateSelectedItems,
  clearSelectedItems,
  updateTimeFilterValue,
  updateACIDFilter,
} from 'src/@infringements/actions/filterActions';
// store
import { filterStore } from 'src/@infringements/stores/filterStore';
// function
import {
  useLatestFilter,
  getIfFiltersEmpty,
  translateLabels,
  useTimeFilter,
} from 'src/@infringements/functions';
import { capitalizeObjectKeys } from 'src/utils/objectModifiers';
import { isAtcView } from 'src/utils';
// interfaces
import { ITableFilterItem } from 'src/@infringements/interfaces';
// constants
import { NULL_VALUE } from 'src/constants';
import { DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';

export const FilterContainer: FC<any> = ({ source = '', availableCategoryList }) => {
  const client = useApolloClient();
  // Configuration
  const configSelectors = useConfigSelectors();
  const dispatcher = useContext(InfringementDispatchContext);
  const sortSelectors = useSortSelectors();
  const sortString = sortSelectors.getSortString();
  const dataSelectors = useDataSelectors();
  const showCandidates = dataSelectors.getCandidatesEnabled();
  const atcView = isAtcView(source);
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

  const {
    grid: { resultSize },
    infringements: {
      filter: { nullable, showSelectedIcons },
    },
  } = configSelectors.getConfig();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        acid,
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { loading, noMatchesFound },
      filters: { time: languageData },
      lists: {
        infringementFilterCategories,
        aircraftCategories,
        ruleTypes,
        typeCategories,
        severities,
        extraFilterValues,
        infringementStatuses,
        vectored,
      },
    },
  } = languageSelectors.getLanguage();

  const timeFilters = useTimeFilter(resultSize, showCandidates, atcView);

  const dataListUpdated = capitalizeObjectKeys({
    ...aircraftCategories,
    ...extraFilterValues,
    ...ruleTypes,
    ...typeCategories,
    ...severities,
    ...infringementStatuses,
    ...vectored,
  });

  const filterItems = filterStore.getAllFilterOptions();
  const infringementRulesSelector = useInfringementRulesSelectors();
  const infringementRulesList: IDropdownItem[] = infringementRulesSelector
    .getRules()
    .map(({ name }) => ({ key: name, label: name }));

  const selectedItems = useLatestFilter({
    extraFilterValues,
  });
  const hasCorrelatedIds = filterStore.getHasCorrelatedIds();

  // ACID Filter
  const selectedACID = filterStore.getACIDFilter();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [acidSearchValue, setACIDSearchValue]: TPersistentStorageHook = useSessionStorage(
    '',
    'infringements.ACIDSearchValue'
  );

  const onACIDClearSelect = () => {
    setACIDSearchValue('');
    updateACIDFilter('', client, dispatcher, sortString, resultSize, showCandidates, atcView);
  };

  const onACIDChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const re = /^.{1,10}$/;

    if (value === '' || re.test(value)) {
      setACIDSearchValue(value.toUpperCase());
    }
  };

  const onACIDSubmit = () => {
    if (acidSearchValue !== '') {
      updateACIDFilter(
        acidSearchValue as string,
        client,
        dispatcher,
        sortString,
        resultSize,
        showCandidates,
        atcView
      );
    }
  };

  const clearAllFilters = () => {
    setACIDSearchValue('');
    onACIDClearSelect();
    clearSelectedItems(client, resultSize, dispatcher, sortString, showCandidates, atcView);
  };

  const filterComponents: JSX.Element[] = [];
  availableCategoryList.map((category: string) => {
    if (category === 'acid' && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      filterComponents.push(
        <IDFilter
          categoryName={category}
          key="filter-id-selector"
          value={acidSearchValue as string}
          onChange={onACIDChange}
          onSubmit={onACIDSubmit}
          selectedID={selectedACID}
          onClearSelect={onACIDClearSelect}
          languageData={{
            title: acid,
            noMatchesFound,
            loading,
          }}
        />
      );
    } else if (category === 'ruleNames') {
      const NullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
      const transformedItems =
        nullable && nullable.includes(category)
          ? [NullValue, ...infringementRulesList]
          : infringementRulesList;
      filterComponents.push(
        <Filter
          key={category}
          categoryName={infringementFilterCategories[category]}
          filterItems={translateLabels(transformedItems, dataListUpdated)}
          selectedItems={selectedItems[category]}
          updateItems={(items: ITableFilterItem[]) => {
            updateSelectedItems(
              client,
              category,
              items,
              dispatcher,
              sortString,
              resultSize,
              showCandidates,
              atcView
            );
          }}
          iconCategories={showSelectedIcons.map(
            (category: string) => infringementFilterCategories[category]
          )}
          languageData={{ clearValue, filterValue, noMatchesFound }}
        />
      );
    } else {
      const NullValue = { key: NULL_VALUE, label: NULL_VALUE, icon: undefined };
      const originalItems = filterItems[category];
      if (originalItems !== undefined) {
        const transformedItems =
          nullable && nullable.includes(category) ? [NullValue, ...originalItems] : originalItems;
        filterComponents.push(
          <Filter
            key={category}
            categoryName={infringementFilterCategories[category]}
            filterItems={translateLabels(transformedItems, dataListUpdated)}
            selectedItems={selectedItems[category]}
            updateItems={(items: ITableFilterItem[]) => {
              updateSelectedItems(
                client,
                category,
                items,
                dispatcher,
                sortString,
                resultSize,
                showCandidates,
                atcView
              );
            }}
            iconCategories={showSelectedIcons.map(
              (category: string) => infringementFilterCategories[category]
            )}
            languageData={{ clearValue, filterValue, noMatchesFound }}
          />
        );
      }
    }
  });

  /*
   * arr.splice(index, 0, item); will insert
   * item into arr at the specified index
   * (deleting 0 items first, that is, it's just an insert).
   */
  const fromComplete = timeFilters.from && isCompletedTimeFormat(timeFilters.from);
  const toComplete = timeFilters.to && isCompletedTimeFormat(timeFilters.to);
  let timeLabel = '';
  if (fromComplete || toComplete) {
    timeLabel = `${timeFilters.from}-${timeFilters.to}`;
  }

  filterComponents.splice(
    1,
    0,
    <RangeSelector
      key="filter-time-selector"
      type="time"
      min={null}
      max={null}
      fromInputValue={timeFilters.from}
      toInputValue={timeFilters.to}
      setInputValue={(value: string, field: 'from' | 'to') => {
        updateTimeFilterValue(value, field);
      }}
      label={timeLabel}
      validateInputFormat={validateTimeFormat}
      isValidValue={isValidTime}
      onCloseValidation={onCloseTimeDialogValidation}
      isCompletedValueFormat={isCompletedTimeFormat}
      languageData={languageData}
    />
  );

  return (
    <div>
      <FilterRow
        filters={filterComponents}
        clearFilters={clearAllFilters}
        clearDisabled={getIfFiltersEmpty(
          selectedItems,
          timeFilters,
          selectedACID,
          hasCorrelatedIds
        )}
        languageData={{ clearFilters }}
      />
    </div>
  );
};
