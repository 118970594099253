import React from 'react';
import { useConfigSelectors, useRolesSelectors } from 'src/app/reducers';
// utils
import { isDefined, getPermissions } from 'src/app/functions/permissions';

// NOTE: this hoc is redundant and kept for backward compatibility. Please use 'withAvailabilityChecks' hoc instead
// tslint:disable:react-hooks-nesting - done because you can use hooks in HOCs
export const withPermissionsCheck = (
  Component: any,
  readPermission: string,
  updatePermission: string
) => ({ ...props }) => {
  const rolesSelector = useRolesSelectors();
  const canView = rolesSelector.hasPermission(readPermission);
  const canUpdate = rolesSelector.hasPermission(updatePermission);

  if (canView) {
    return <Component {...props} readOnly={!canUpdate} canSave={canUpdate} />;
  }

  return null;
};

export const withAvailabilityChecks = (
  Component: any,
  config: {
    feature?: string;
    permissions?: string;
    otherPermissions?: string[];
  }
) => ({ ...props }) => {
  const { feature, permissions, otherPermissions = [] } = config;

  const configSelector = useConfigSelectors();
  const rolesSelector = useRolesSelectors();
  // check if component should be displayed
  const isAvailable: boolean = isDefined(feature)
    ? configSelector.isFeatureAvailable(`${feature}`)
    : true;

  // if it can be displayed then calculate the permissions props and add then to the component
  // if there is no permission defined for it we still want to be able to view that component
  if (isAvailable) {
    const { canRead, canUpdate, canDelete } = getPermissions(rolesSelector, permissions);

    if (canRead) {
      const permissionsObject = {};
      otherPermissions.forEach(
        item => (permissionsObject[item] = getPermissions(rolesSelector, item))
      );

      return (
        <Component
          {...props}
          canRead={canRead}
          canUpdate={canUpdate}
          canDelete={canDelete}
          otherPermissions={permissionsObject}
        />
      );
    }
  }

  return null;
};
