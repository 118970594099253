import React, { useState, useContext } from 'react';

// Selectors
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { useLanguageSelectors } from 'src/app/reducers';

// Apollo
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_SETTING } from 'src/@settings/mutations';

// Components
import { SettingsSelectorList } from 'src/app/components/SettingsSelector';

// Types
import { TScenarioModelTypes } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

// Actions
import { updateNoiseScenarioSelectedModel } from 'src/app/actions';

export const ModelingSettingsContainer = () => {
  // Selectors
  const configSelectors = useConfigSelectors();
  const noiseModelingLicense = configSelectors.getNoiseModelConfig();
  const dispatcher = useContext(GlobalDispatchContext);
  const languageSelectors = useLanguageSelectors();

  // Translations
  const {
    screens: {
      settings: {
        settingsLabels: { noiseScenarioModel: noiseScenarioModelString },
      },
    },
  } = languageSelectors.getLanguage();

  // States
  const noiseScenarioModelOptions = Object.keys(noiseModelingLicense.software)
    .filter(key => noiseModelingLicense.software[key].enabled)
    .map(key => {
      return {
        label: key,
        value: key,
      };
    });
  const [selectedModels, setSelectedModels] = useState([noiseModelingLicense.selectedModel]);

  // Mutations
  const [updateSettingsForDeployedProduct] = useMutation(UPDATE_SETTING, {});

  const handleNoiseModelChange = (value: string) => {
    const settingsPath = [
      {
        parameter: `aircraftNoiseModeling/selectedModel`,
        stringValue: value,
      },
    ];

    updateSettingsForDeployedProduct({
      variables: {
        settings: settingsPath,
      },
    }).then(() => {
      setSelectedModels([value as TScenarioModelTypes]);
      updateNoiseScenarioSelectedModel(dispatcher, value);
    });
  };

  return (
    <>
      {noiseScenarioModelOptions.length ? (
        <SettingsSelectorList
          title={noiseScenarioModelString}
          className="noise-scenario"
          options={noiseScenarioModelOptions}
          selected={selectedModels}
          onChangeHandler={handleNoiseModelChange}
        />
      ) : (
        <p>No noise models installed on system</p>
      )}
    </>
  );
};
