import { IColumnDefinitions } from 'src/@operations/props';

export const operationsColumnDefinitions = {
  displayFlag: {
    trackSize: '68px',
    trackSizeSmall: '68px',
  },
  acid: {
    trackSize: 'minmax(90px, max-content)',
    trackSizeSmall: 'minmax(90px, max-content)',
  },
  displayTime: {
    trackSize: 'minmax(100px, max-content)',
    trackSizeSmall: 'minmax(70px, max-content)',
  },
  airportId: {
    trackSize: 'minmax(80px, max-content)',
    trackSizeSmall: 'minmax(80px, max-content)',
  },
  remoteAirportId: {
    trackSize: 'minmax(80px, max-content)',
  },
  displayRunwayName: {
    trackSize: '90px',
  },
  displayCategory: {
    trackSize: '90px',
  },
  operatorCategory: {
    trackSize: 'minmax(120px, max-content)',
  },
  operationTags: {
    trackSize: 'minmax(120px, max-content)',
    trackSizeSmall: 'minmax(120px, max-content)',
  },
  aircraftType: {
    trackSize: '80px',
    trackSizeSmall: '80px',
  },
  aircraftCount: {
    trackSize: '80px',
  },
  displayDiag: {
    trackSize: '80px',
  },
  displayHoriz: {
    trackSize: '80px',
  },
  displayAlt: {
    trackSize: '80px',
  },
  pcaTime: {
    trackSize: '100px',
  },
  correlated: {
    trackSize: 'minmax(120px, auto)',
  },
};

const isHidden = (columnId, hiddenColumns) => {
  switch (columnId) {
    case 'displayDiag':
    case 'displayHoriz':
    case 'displayAlt':
    case 'pcaTime':
      return hiddenColumns.pca ? true : false;
    default:
      return hiddenColumns[columnId] ? true : false;
  }
};

export const getOperationsColumnsAndStyle = (
  columnsFromDb: string[],
  columnDefinitions: IColumnDefinitions,
  hiddenColumns: { [key: string]: boolean }
) => {
  const operationsColumns: string[] = [];
  let gridTemplateColumns = '';
  let gridTemplateColumnsNarrow = '';

  const columnDefinitionsFiltered = [];
  for (const columnId of columnsFromDb) {
    const columnDef = columnDefinitions[columnId];
    if (!columnDef) {
      throw new Error(`Received column ${columnId} from DB that has no column definition.`);
    }
    columnDefinitionsFiltered.push({
      id: columnId,
      trackSize: columnDef.trackSize,
      trackSizeSmall: columnDef.trackSizeSmall,
    });
  }

  //  Mark the last visible column so we can set its track size to 'auto'
  //  so that the table always fills the available horizontal space
  let lastVisibleColumn = '';
  let tableHasVisibleFlexibleColumn = false;
  for (const columnDef of columnDefinitionsFiltered) {
    if (!isHidden(columnDef.id, hiddenColumns)) {
      lastVisibleColumn = columnDef.id;
      if (columnDef.trackSize.match(/[0-9]fr/) || columnDef.trackSize.match(/auto/)) {
        tableHasVisibleFlexibleColumn = true;
      }
    }
  }

  for (const columnDef of columnDefinitionsFiltered) {
    let trackSize = columnDef.trackSize;
    let trackSizeSmall = columnDef.trackSizeSmall ? columnDef.trackSizeSmall : columnDef.trackSize;
    if (isHidden(columnDef.id, hiddenColumns)) {
      trackSize = '0';
      trackSizeSmall = '0';
    } else if (lastVisibleColumn === columnDef.id && !tableHasVisibleFlexibleColumn) {
      trackSize = 'auto';
      trackSizeSmall = 'auto';
    }
    operationsColumns.push(columnDef.id);
    gridTemplateColumns += `\n\t[${columnDef.id}] ${trackSize}`;
    gridTemplateColumnsNarrow += `\n\t[${columnDef.id}] ${trackSizeSmall}`;
  }

  const styleOperationsGridTemplate = `
    .operations-table { grid-template-columns: \n\t[selector] 64px ${gridTemplateColumns}; \n}
    .page-wrapper--landscape .operations-table, .table--slim .operations-table { grid-template-columns: \n\t [selector] 64px ${gridTemplateColumnsNarrow}; \n}
  `;
  return { operationsColumns, styleOperationsGridTemplate };
};
