import React, { createContext } from 'react';

// Types
import { IAircraftMappingState, IAircraftMappingActions } from '../types';

export const aircraftMappingsReducer = (
  state: IAircraftMappingState,
  action: IAircraftMappingActions
): IAircraftMappingState => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true, error: null };
    case 'SUCCESS':
      const { airQualityAircraftMappingSummariesByTimeRange } = action.data;
      return {
        ...state,
        isLoading: false,
        data: { ...airQualityAircraftMappingSummariesByTimeRange },
        error: null,
        invalidRows: [],
      };
    case 'UPDATE_MAPPING_NODE':
      const {
        data: { edges },
      } = state;
      return {
        ...state,
        data: {
          ...state.data,
          edges: edges.map(edge => (edge.cursor === action.edge.cursor ? action.edge : edge)),
        },
        invalidRows: state.invalidRows.filter(row => row !== action.edge.cursor),
      };
    case 'ERROR':
      return { ...state, isLoading: false, error: action.error };
    case 'TABLE_SORT':
      const { field, direction } = action.data;
      return {
        ...state,
        queryVariables: {
          ...state.queryVariables,
          sort: [
            {
              field,
              direction,
            },
          ],
        },
      };
    case 'TABLE_FILTER':
      return {
        ...state,
        queryVariables: {
          ...state.queryVariables,
          filter: { ...action.data },
        },
      };
    case 'UPDATE_DATE_RANGE':
      const { start, end } = action.data;
      return {
        ...state,
        queryVariables: {
          ...state.queryVariables,
          startTime: start,
          endTime: end,
        },
      };
    case 'UPDATE_FILTER_MAPPINGS':
      const { ltoAircraftEngineMappings, enrouteAircraftEngineMappings } = action.data;
      return {
        ...state,
        ltoMappings: ltoAircraftEngineMappings,
        enrouteMappings: enrouteAircraftEngineMappings,
      };
    case 'UPDATE_SCENARIO_ID':
      return {
        ...state,
        queryVariables: {
          ...state.queryVariables,
          scenarioId: action.data,
        },
      };
    case 'TABLE_SET_INVALID_ROW':
      return {
        ...state,
        invalidRows: [...state.invalidRows, ...action.data],
      };
    default:
      return state;
  }
};

export const AircraftMappingsContext = createContext<
  | {
      state: IAircraftMappingState;
      dispatch: React.Dispatch<IAircraftMappingActions>;
    }
  | undefined
>(undefined);
