import React, { FC } from 'react';
import { PageHeader } from 'src/components/PageHeader';
import { IErrorPage } from './interfaces';

export const ErrorPage: FC<IErrorPage> = ({ title, children }) => (
  <div className="container">
    <PageHeader title={title} />
    {children}
  </div>
);
