import { displayToast } from '@ems/client-design-system';
import { TrackDensityLayer } from './TrackDensityLayer';
import { fetchTrackDensityImage } from 'src/app/functions/map';
import {
  TRACK_DENSITY_CREATE,
  TRACK_DENSITY_RESULT,
} from 'src/@operations/resolvers/trackDensityQueries';
import { TRACK_DENSITY } from 'src/constants';
import {
  getTrackDensityCacheItem,
  setTrackDensityCacheItem,
  getTrackDensityCacheKey,
} from './trackDensityCache';

const TRACK_DENSITY_SETTINGS = {
  trackDensity60km: {
    height: 60000,
    width: 60000,
    resolution: 100,
  },
  trackDensity150km: {
    height: 150000,
    width: 150000,
    resolution: 250,
  },
};

// This dummy data is required because even though the track density layer is self-contained,
// react-map-gl complains if a layer doesn't have an associated source.
export const trackDensitySourceData = {
  type: 'FeatureCollection',
  features: [{ type: 'Feature', id: '0', geometry: { type: 'Polygon', coordinates: [] } }],
};

export const initialTrackDensityData = {
  id: null,
  maxOperationCellCount: null,
  isCompleted: false,
  topLeft: [],
  topRight: [],
  bottomRight: [],
  bottomLeft: [],
  size: null,
  startCursor: null,
};

export const isTrackDensityDataValid = trackDensityData => {
  if (
    trackDensityData &&
    typeof trackDensityData.id === 'string' &&
    typeof trackDensityData.startCursor === 'string' &&
    typeof trackDensityData.size === 'string' &&
    typeof trackDensityData.maxOperationCellCount === 'number' &&
    trackDensityData.maxOperationCellCount > 0 &&
    trackDensityData.topLeft.length === 2 &&
    trackDensityData.topRight.length === 2 &&
    trackDensityData.bottomRight.length === 2 &&
    trackDensityData.bottomLeft.length === 2
  ) {
    return true;
  }
  return false;
};

export const mapTrackDensityResponse = (trackDensityId, td, mapApiStartCursor) => {
  const METERS_PER_KILOMETER = 1000;
  return {
    id: trackDensityId,
    maxOperationCellCount: td.maxOperationCellCount,
    topLeft: [td.grid.cornerPoints[0].longitude, td.grid.cornerPoints[0].latitude],
    topRight: [td.grid.cornerPoints[1].longitude, td.grid.cornerPoints[1].latitude],
    bottomRight: [td.grid.cornerPoints[2].longitude, td.grid.cornerPoints[2].latitude],
    bottomLeft: [td.grid.cornerPoints[3].longitude, td.grid.cornerPoints[3].latitude],
    size: `trackDensity${td.grid.height / METERS_PER_KILOMETER}km`,
    startCursor: mapApiStartCursor,
  };
};

export const generateTrackDensity = trackDensityInputs => {
  const { mapApiStartCursor, viewport, trackDensityStateFunctions } = trackDensityInputs;
  if (!viewport) {
    return;
  }
  if (typeof mapApiStartCursor !== 'string') {
    displayToast({ message: 'Cannot generate track density: no data available.' });
    return;
  }
  const {
    setIsGeneratingTrackDensity,
    setTrackDensityId,
    setTrackDensityData,
    setIsTrackDensityCompleted,
  } = trackDensityStateFunctions;
  const { apolloClient, configSelectors } = trackDensityInputs;
  const userConfigTrackDensitySize = configSelectors.getTrackDensitySize() || 'trackDensity150km';
  const cacheKey = getTrackDensityCacheKey(mapApiStartCursor, userConfigTrackDensitySize);
  const cachedTrackDensity = getTrackDensityCacheItem(cacheKey);
  if (
    cachedTrackDensity &&
    cachedTrackDensity.trackDensityData.size === userConfigTrackDensitySize
  ) {
    setTrackDensityData(cachedTrackDensity.trackDensityData);
    fetchAndDisplayTrackDensity(trackDensityInputs, cachedTrackDensity.trackDensityData);
    return;
  }
  const { latitude, longitude } = viewport;
  const { height, width, resolution } = TRACK_DENSITY_SETTINGS[userConfigTrackDensitySize];
  setIsGeneratingTrackDensity(true);
  setIsTrackDensityCompleted(false);
  apolloClient
    .query({
      query: TRACK_DENSITY_CREATE,
      variables: {
        anomsApiQueryCursor: mapApiStartCursor,
        latitude,
        longitude,
        height,
        width,
        resolution,
      },
    })
    .then(response => {
      if (response && response.data && response.data.createTrackDensity) {
        pollTrackDensityStatus(response.data.createTrackDensity, trackDensityInputs);
        setTrackDensityId(response.data.createTrackDensity);
      } else {
        console.error('Invalid response:', response);
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchAndDisplayTrackDensity = (trackDensityInputs, trackDensityData) => {
  const { mapApis, viewport, trackDensityStateFunctions } = trackDensityInputs;
  const { setIsTrackDensityVisible, setIsTrackDensityCheckRequired } = trackDensityStateFunctions;
  if (!isTrackDensityDataValid(trackDensityData)) {
    console.error('trackDensityData is invalid:', trackDensityData);
    return;
  }
  fetchTrackDensityImage(trackDensityData.id).then(response => {
    if (response.ok) {
      if (mapApis.getLayer(TRACK_DENSITY)) {
        mapApis.removeLayer(TRACK_DENSITY);
      }
      const reader = new FileReader();
      reader.onload = function() {
        const trackDensityImageBase64 = this.result.toString();
        const trackDensityLayer = TrackDensityLayer({
          mapApis,
          viewport,
          trackDensityImageBase64,
          trackDensityData,
        });
        if (!mapApis.getSource('lhrx_track_density_reference')) {
          mapApis.addSource('lhrx_track_density_reference', {
            type: 'geojson',
            data: trackDensitySourceData,
          });
        }
        if (!mapApis.getLayer(TRACK_DENSITY)) {
          mapApis.addLayer(trackDensityLayer);
        } else {
          console.warn('Track density layer already exists; remove it first');
        }
        setIsTrackDensityVisible(true);
        setIsTrackDensityCheckRequired(false);
      };
      response.blob().then(imageBlob => reader.readAsDataURL(imageBlob));
    } else {
      console.error('Fetching track density image failed', response);
    }
  });
};

export const pollTrackDensityStatus = (id: string, trackDensityInputs) => {
  const { apolloClient, mapApiStartCursor, trackDensityStateFunctions } = trackDensityInputs;
  const {
    setTrackDensityData,
    setIsTrackDensityCompleted,
    setIsGeneratingTrackDensity,
  } = trackDensityStateFunctions;
  if (!(apolloClient && mapApiStartCursor)) {
    return;
  }
  const POLLING_INTERVAL = 2000;
  apolloClient
    .query({
      query: TRACK_DENSITY_RESULT,
      variables: { id },
    })
    .then(response => {
      if (response.data && response.data.trackDensity && response.data.trackDensity.status) {
        switch (response.data.trackDensity.status) {
          case 'Completed':
            const trackDensityData = mapTrackDensityResponse(
              id,
              response.data.trackDensity,
              mapApiStartCursor
            );
            setTrackDensityData(trackDensityData);
            setIsTrackDensityCompleted(true);
            setTrackDensityCacheItem(mapApiStartCursor, trackDensityData);
            setIsGeneratingTrackDensity(false);
            fetchAndDisplayTrackDensity(trackDensityInputs, trackDensityData);
            break;
          case 'Cancelled':
            setIsGeneratingTrackDensity(false);
            break;
          case 'InProgress':
            setTimeout(() => pollTrackDensityStatus(id, trackDensityInputs), POLLING_INTERVAL);
            break;
        }
      }
    });
};

export const isTrackDensityOutOfDate = trackDensityInputs => {
  const { trackDensitySize, trackDensityData, mapApiStartCursor } = trackDensityInputs;
  return !(
    trackDensitySize &&
    trackDensityData.size === trackDensitySize &&
    trackDensityData.startCursor === mapApiStartCursor
  );
};

export const removeAndResetTrackDensity = trackDensityInputs => {
  const { mapApis, trackDensityStateFunctions } = trackDensityInputs;
  if (!mapApis) {
    return;
  }
  const { setIsTrackDensityVisible } = trackDensityStateFunctions;
  if (mapApis.getLayer(TRACK_DENSITY)) {
    mapApis.removeLayer(TRACK_DENSITY);
  }
  setIsTrackDensityVisible(false);
};

/**
 * Convert hex to WebGL (vec3) color
 * @param {string} hex - hex color string
 * @returns {string} - WebGL shader value
 */
export const hexToWgl = (hex: string) => {
  const dec_rgb = [
    parseInt('0x' + hex.substring(1, 3)),
    parseInt('0x' + hex.substring(3, 5)),
    parseInt('0x' + hex.substring(5, 7)),
  ];
  var norm_rgb_color_str =
    String(dec_rgb[0] / 255).substring(0, 5) +
    ', ' +
    String(dec_rgb[1] / 255).substring(0, 5) +
    ', ' +
    String(dec_rgb[2] / 255).substring(0, 5);

  return `vec3(${norm_rgb_color_str})`;
};
