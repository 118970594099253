import React from 'react';
import { FormikProps } from 'formik';

// Components
import { Dropdown } from '@ems/client-design-system';
import {
  DialogInputWrapper,
  DialogFormLabel,
  DialogInputError,
  DialogInput,
} from './Dialog.styles';

// Types
import { ICreateScenarioTranslations } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';
import { IAddRecordFilter, IAddRecordNumber } from './interfaces';
import { IScenarioFilterObject } from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

// Types
import { IAddRecordFormFields } from './interfaces';

export const modifyRecordDropdown = ({
  formik,
  filterOptions,
  name,
  key,
  placeholderString,
}: IAddRecordFilter) => {
  let selectedValue;
  if (formik.values[key]) {
    selectedValue = filterOptions.find(option => option.key === formik.values[key]);
  }

  return (
    <DialogInputWrapper key={key}>
      <Dropdown
        key={key}
        label={name}
        placeholderValue={placeholderString}
        searchItems={filterOptions}
        isNullable
        updateSelection={item => {
          formik.setFieldValue(`${key}`, item.key);
        }}
        selectedItem={selectedValue}
      />

      <DialogInputError>{formik.errors[key] || <>&nbsp;</>}</DialogInputError>
    </DialogInputWrapper>
  );
};

export const modifyRecordCount = ({ formik, name, placeholderString, key }: IAddRecordNumber) => (
    <DialogInputWrapper key={key}>
      <DialogFormLabel htmlFor={key}>{name}</DialogFormLabel>
      <DialogInput
        id={key}
        name={key}
        type="text"
        value={formik.values[key] ? formik.values[key] : ''}
        onChange={formik.handleChange}
        placeholder={placeholderString}
        maxLength={8}
      />
      <DialogInputError>{formik.errors[key] || <>&nbsp;</>}</DialogInputError>
    </DialogInputWrapper>
  );

export const isValidPositiveNumber = (value): boolean => {
  const n = Math.floor(Number(value));
  return n !== Infinity && String(n) === value && n >= 0;
};

const defaultInitialFormValues: IAddRecordFormFields = {
  modelingAircraftId: '',
  stageLength: '',
  modelingRouteId: '',
  dayCount: '0',
  eveningCount: '0',
  nightCount: '0',
};

export const modifyRecordsFormikOptions = ({
  onSubmitAction,
  validation,
  initialValues,
}: {
  onSubmitAction: (values: any) => void;
  validation: (values: any) => void;
  initialValues?: IAddRecordFormFields;
}) => ({
    initialValues: {
      ...defaultInitialFormValues,
      ...initialValues,
    },
    validateOnBlur: false,
    onSubmit: values => {
      onSubmitAction(values);
    },
    validate: validation,
  });

export const ModifyRecordsDialogForm = ({
  formik,
  scenarioFilterData,
  translations,
  isMultiple = false,
}: {
  formik: FormikProps<IAddRecordFormFields>;
  scenarioFilterData: IScenarioFilterObject;
  translations: ICreateScenarioTranslations;
  isMultiple?: boolean;
}) => {
  const { labels, filterLanguageData } = translations;
  const placeholderValue = isMultiple
    ? filterLanguageData.multiple
    : filterLanguageData.pleaseSelect;
  const modifyRecordDialogDropdowns = [
    {
      name: labels.aircraftType,
      key: 'modelingAircraftId',
      options: scenarioFilterData.noiseModelingAircraftTypes.map(
        ({ aircraftId, aircraftName }) => ({
          key: aircraftId,
          label: aircraftName,
        })
      ),
    },
    {
      name: labels.stage,
      key: 'stageLength',
      options: scenarioFilterData.stages.map(stage => ({
        label: stage,
        key: stage,
      })),
    },
    {
      name: labels.modelingRoute,
      key: 'modelingRouteId',
      options: scenarioFilterData.aircraftNoiseModelingRoutes.map(({ name, id }) => ({
        label: name,
        key: id.toString(),
      })),
    },
  ];

  const modifyRecordDialogCounts = [
    {
      name: labels.day,
      key: 'dayCount',
    },
    {
      name: labels.evening,
      key: 'eveningCount',
    },
    {
      name: labels.night,
      key: 'nightCount',
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      {modifyRecordDialogDropdowns.map(({ name, key, options }) =>
        modifyRecordDropdown({
          name,
          key,
          formik,
          filterOptions: options,
          placeholderString: placeholderValue,
        })
      )}
      {modifyRecordDialogCounts.map(({ name, key }) =>
        modifyRecordCount({
          name,
          key,
          placeholderString: placeholderValue,
          formik,
        })
      )}
    </form>
  );
};
