import React, { useEffect, useMemo, useRef, useState } from 'react';

// Configs
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  fetchTrackTiles,
  addSourcesStylesToMap,
} from 'src/app/functions/map';
import { useMapSettings } from 'src/app/functions/mapSettings';

// Design system
import {
  StyledMap,
  GateGeometry,
  GeocoderPin,
  RulerTool,
  MapControl,
  ZoneGeometry,
  displayError,
  CorridorGeometry,
} from '@ems/client-design-system';

import { useMapReftoCaptureImage } from 'src/app/functions/export';
import {
  addCustomTileSource,
  flyTo,
  getDateArray,
  getDateMapping,
  getInterpolatedOpacity,
  mapboxStyleBackgroundNormalPaint,
  mapboxStyleDynamicSource,
  removeAllTypenameProps,
  removeMapSourcesByPrefix,
  toggleMapSourcesVisibility,
  useGeocoderPinAlternative,
  useMapRuler,
  useSpatialFeatures,
} from 'src/utils';
import {
  CEILING_DEFAULT_FEET,
  FLOOR_DEFAULT_FEET,
  GEOMETRY_INVALID_TIMEOUT,
  invalidGeometryMessage,
  OPERATION_COUNT,
  SPATIAL_FEATURES,
} from 'src/constants';
import { IPosition, IViewState } from 'src/utils/interfaces';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { DYNAMIC_TILE_SERVER, TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { MapSettingsContainer } from 'src/containers';
import { MapReferenceLayers } from 'src/app/components';
import { LocationPopup } from 'src/components';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
import { addPinToCentre, goToSelectedAddress, useGeocodePosition } from 'src/utils/geocoding';
import { validateGeometry } from 'src/@settings/functions';

import { useDataSelectors } from 'src/@operations/reducers';
import { useOperationsDataInMap } from 'src/@operations/functions/map';
import {
  IGateCoordinateObject,
  ILongLatObject,
  TEditFeatureParams,
  TSpatialFeature,
  TSpatialFeatureGeometry,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { useSpatialFeaturesSelector } from 'src/@settings/reducers';
import { MapLegend } from 'src/components/MapLegend';
import { useApolloClient } from '@apollo/react-hooks';

interface SpatialFeaturesMapProps {
  editable: boolean;
  featureId: number;
  featureType: string | null;
  onGeometryUpdate: (geometry: TSpatialFeatureGeometry, geometryValidity: boolean) => void;
  onMapInitialized?: () => void;
  feature?: TSpatialFeature;
}

export const SpatialFeaturesMap: React.FC<SpatialFeaturesMapProps> = ({
  feature,
  featureType,
  editable,
  onGeometryUpdate,
  featureId,
  onMapInitialized,
}) => {
  const client = useApolloClient();
  // Map set up
  const [mapNode, mapRef] = useMapRef();
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  const mapBoxConfig = useMapConfig();
  const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
  const [viewport, setViewport] = useState<IViewState>(viewportFromProps);

  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });

  const [isMapInitialized, updateIsMapInitialized] = useState<boolean>();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: {
        backToCenter: backToCenterLabel,
        search: searchLabel,
        addPin: addPinLabel,
        removePin: removePinLabel,
        ruler: ruler,
        lat: latLabel,
        lng: lngLabel,
        amsl: amslLabel,
        pcaFilter: filterLabel,
      },
    },
  } = languageSelectors.getLanguage();

  const [isMapLoaded, setMapLoaded] = useState<boolean>(false);
  const handleMapLoaded = () => {
    mapLoaded();
    setMapLoaded(true);
  };

  // restrict map pan
  const onViewportChange = (viewport: IViewState) => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };

  // capture map image
  // used for taking screenshot of map
  const captureRef = useRef(null);
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

  // View/Flyto map controls

  const [zoomSet, updateZoomSet] = useState<boolean>(false);
  const resetView = (): void => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  // Geocoder pin and address search
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [closeSearch, updateCloseSearch] = useState<boolean>(false);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);

  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  // Removes the distance marker circles from the geocoding pin
  const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);

  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude: geocoding.longitude,
      latitude: geocoding.latitude,
    },
  });

  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[geocoding.longitude, geocoding.latitude]],
  });

  // Address search component

  const AddressSearch = useMemo(
    () => (
      <div className="mapboxgl-ctrl-search">
        <AddressSearchContainer
          source="map"
          onAddressFound={address =>
            goToSelectedAddress({
              address,
              mapApis,
              viewport,
              addRemoveCircles,
              updateGeocoding,
              updateLocationAddress,
              updateLocationTagOpen,
              onViewportChange,
              updateCloseSearch,
            })
          }
        />
      </div>
    ),
    [mapApis, addRemoveCircles]
  );

  // Ruler Tool

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const {
    map: { mapProjectionString },
  } = configSelectors.getConfig();
  const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
    mapApis,
    viewport,
  });

  // SPATIAL FEATURE GEOMETRY
  const validateUpdatedGeometry = (updatedGeometry: TSpatialFeatureGeometry): boolean => {
    const geometryValidity = validateGeometry(updatedGeometry, featureType);
    if (!geometryValidity) {
      displayError({
        message: invalidGeometryMessage[featureType],
        timeout: GEOMETRY_INVALID_TIMEOUT,
      });
    }
    return geometryValidity;
  };

  // Gates - state and on geometry update
  const [gateCoordinates, updateGateCoordinates] = useState<IGateCoordinateObject>();
  const gateCoordinatesChanged = (
    gatePoint: string,
    coordinates: ILongLatObject,
    anchorPoint: ILongLatObject
  ) => {
    // Updates the map state
    updateGateCoordinates({
      ...gateCoordinates,
      [gatePoint]: coordinates,
    });

    const isValid = validateUpdatedGeometry({
      ...gateCoordinates,
      [gatePoint]: coordinates,
      anchorPosition: anchorPoint,
    });
    // Callback to feature container

    onGeometryUpdate(
      {
        ...gateCoordinates,
        [gatePoint]: coordinates,
        anchorPosition: anchorPoint,
      },
      isValid
    );
  };

  // Zone - state and on geometry update
  const [zonePoints, updateZonePoints] = useState<IPosition[]>();
  const zonePointsChanged = (newZonePoints: IPosition[]) => {
    const isValid = validateUpdatedGeometry(newZonePoints);
    updateZonePoints(newZonePoints);
    onGeometryUpdate(newZonePoints, isValid);
  };
  // Corridor - state and on geometry update
  const [corridorCoordinates, updateCorridorCoordinates] = useState<IGateCoordinateObject[]>();

  const onCorridorChanged = (newCorridorCoordinates: IGateCoordinateObject[]) => {
    updateCorridorCoordinates(newCorridorCoordinates);

    const updatedFeatureData = newCorridorCoordinates.map((item: any) =>
      removeAllTypenameProps({
        ...item,
        floorAltitude: FLOOR_DEFAULT_FEET,
        ceilingAltitude: Number(CEILING_DEFAULT_FEET.toFixed(0)),
      })
    );
    const isValid = validateUpdatedGeometry(updatedFeatureData);
    onGeometryUpdate(updatedFeatureData, isValid);
  };

  useEffect(() => {
    if (zoomSet && onMapInitialized) {
      onMapInitialized();
      updateIsMapInitialized(true);
    }
  }, [zoomSet]);

  // Sets up the appropriate feature base on feature type props
  useEffect(() => {
    const featureData = { gates: [], corridors: [], zones: [] };
    const editFeatureParams: TEditFeatureParams = { updateState: null, updateGeometry: null };

    if (!!mapApis && !zoomSet) {
      switch (featureType) {
        case 'Corridor':
          featureData.corridors.push(featureId === -1 ? { id: -1 } : feature);
          editFeatureParams.updateState = updateCorridorCoordinates;
          editFeatureParams.updateGeometry = onGeometryUpdate;
          break;
        case 'SelectionZone':
          featureData.zones.push(featureId === -1 ? { id: -1 } : feature);
          editFeatureParams.updateState = updateZonePoints;
          editFeatureParams.updateGeometry = onGeometryUpdate;
          break;
        case 'Gate':
          featureData.gates.push(featureId === -1 ? { id: -1 } : feature);
          editFeatureParams.updateState = updateGateCoordinates;
          editFeatureParams.updateGeometry = onGeometryUpdate;
          break;
        default:
      }

      useSpatialFeatures({
        featureData,
        mapApis,
        viewport,
        setViewport,
        updateZoomSet,
        setFeatures: null,
        editFeatureParams,
      });
    }
  }, [isMapLoaded]);

  // VIEWING OPERATIONS

  const selectedTrackTheme = configSelectors.getTheme('operations');
  // get layers for styling

  const opsTypeFilter = [
    'all',
    [
      'any',
      ['==', ['get', 'operationType'], 'TouchAndGo'],
      ['==', ['get', 'operationType'], 'Departure'],
      ['==', ['get', 'operationType'], 'Arrival'],
    ],
  ];
  const layers = [
    {
      prefix: mapBoxConfig.backgroundLayerPrefix,
      style: Object.assign({}, mapboxStyleBackgroundNormalPaint(selectedTrackTheme), {
        'line-opacity': getInterpolatedOpacity({ filter: opsTypeFilter, totalCount: 0 }),
      }),
    },
  ];

  const [areOperationsSet, updateAreOperationsSet] = useState<boolean>(false);

  const dataSelectors = useDataSelectors();
  const requiredDataForMap = dataSelectors.getRequiredDataForMap();

  const spatialFeaturesSelectors = useSpatialFeaturesSelector();
  const { pageInfo, datesSelected } = spatialFeaturesSelectors.getOperations();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

  // get values when dates change
  const datesArray = !!datesSelected ? getDateArray(datesSelected) : [];
  const dateRangeMapping = !!datesSelected ? getDateMapping(datesSelected) : [];
  if (!!datesSelected) {
    addSourcesStylesToMap(mapApis, datesArray, mapBoxConfig, layers);
  }

  const [mapApiStartCursor, setMapApiStartCursor] = useState<string>();
  const [tileLayers, setTileLayers] = useState<string[]>([]);
  const [areTracksDisabled, setAreTracksDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (datesSelected === null) {
      setAreTracksDisabled(true);
      removeMapSourcesByPrefix(mapApis, 'track_');
    } else {
      setAreTracksDisabled(false);
    }
  }, [datesSelected]);

  useEffect(() => {
    if (pageInfo && FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
      if (pageInfo.startCursor) {
        setMapApiStartCursor(pageInfo.startCursor);
      }
    }
  }, [pageInfo]);

  useEffect(() => {
    const handleTileLoading = async () => {
      const trackTiles = await fetchTrackTiles({
        startCursor: mapApiStartCursor,
        altitudeBands: null,
      });
      setTileLayers(trackTiles);
    };
    if (mapApiStartCursor) {
      handleTileLoading();
    }
  }, [mapApiStartCursor]);

  useEffect(() => {
    if (tileLayers.length) {
      addCustomTileSource({
        mapApis,
        trackPaths: tileLayers,
        trackVisibility: !areTracksDisabled,
        paintStyle: mapboxStyleDynamicSource(selectedTrackTheme, OPERATION_COUNT),
      });
    }
  }, [tileLayers, mapApis]);

  if (FEATURE_FLAG_DYNAMIC_TILE_SERVER) {
    // Toggle visibility of tile layer for dynamic server
    useEffect(() => {
      toggleMapSourcesVisibility({ mapApis, prefix: 'trackLayer_', hide: areTracksDisabled });
    }, [areTracksDisabled]);
  }

  const [layerCount, setLayerCount] = useState<number>(0);
  if (mapApis && isMapInitialized && !areOperationsSet) {
    try {
      const layers = mapApis.getStyle().layers;
      if (layers.length !== layerCount) {
        setLayerCount(layers.length);
      }
      updateAreOperationsSet(true);
    } catch {
      console.error(typeof mapApis.getStyle);
    }
  }

  // Pass in null until map is initialized
  useOperationsDataInMap({
    mapApis: zoomSet ? null : mapApis,
    dateRangeMapping,
    datesArray,
    mapBoxConfig,
    requiredDataForMap,
    selectedOperations: [],
    opstypeFilter: opsTypeFilter,
    viewport,
    layerCount,
    disableTracks: areTracksDisabled,
    areOperationsSet,
  });

  return (
    <div className="map__wrapper">
      <StyledMap
        height={'100vh'}
        onLoad={handleMapLoaded}
        viewport={viewport}
        mapStyle={mapStyle}
        onViewportChange={viewport => {
          viewport.maxPitch = 0;
          onViewportChange(viewport);
        }}
        {...mapProps}
        ref={mapRef}
        onMouseMove={undefined}
        transformRequest={
          mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
        }>
        {featureType === 'Corridor' && corridorCoordinates && zoomSet && (
          <CorridorGeometry
            corridorName={`corridor ${featureId}`}
            isEditable={editable ? editable : false}
            coordinates={corridorCoordinates}
            handleCorridorUpdate={onCorridorChanged}
            mapApis={mapApis}
            mapZoom={viewport.zoom}
          />
        )}
        {(featureType === 'Zone' || featureType === 'SelectionZone') && zonePoints && zoomSet && (
          <ZoneGeometry
            zoneName={`zone ${featureId}`}
            isEditable={editable ? editable : false}
            points={zonePoints}
            handleZoneUpdate={zonePointsChanged}
            mapApis={mapApis}
          />
        )}
        {featureType === 'Gate' && gateCoordinates && zoomSet && (
          <GateGeometry
            mapZoom={viewport.zoom}
            gateName={`gate ${featureId}`}
            isEditable={editable ? editable : false}
            coordinates={gateCoordinates}
            handleGateUpdate={gateCoordinatesChanged}
          />
        )}
        <GeocoderPin
          latitude={enableMapControls ? geocoding.latitude : 0}
          longitude={enableMapControls ? geocoding.longitude : 0}
          draggable
          onClick={() => {
            updateLocationTagOpen(!isLocationTagOpen);
          }}
          onDragStart={() => {
            addRemoveCircles(null);
            updateLocationTagOpen(false);
          }}
          onDragEnd={([longitude, latitude]: number[]) => {
            if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
              updateGeocoding({ longitude, latitude });
            }

            updateLocationAddress(null);

            addRemoveCircles({ longitude, latitude });
            setTimeout(() => {
              updateLocationTagOpen(true);
            }, 1);
          }}
        />

        {enableMapControls && (
          <MapControl
            isPinAdded={geocoding.latitude && geocoding.longitude ? true : false}
            addPinToCentre={() =>
              addPinToCentre({
                updateLocationAddress,
                geocoding,
                viewport,
                updateGeocoding,
                addRemoveCircles,
                updateLocationTagOpen,
              })
            }
            rulerControl={{
              isRulerEnabled,
              toggleRuler,
            }}
            navigationControl={{
              showCompass: false,
              showHome: true,
              showSearch: true,
              showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
            }}
            translationData={{
              home: backToCenterLabel,
              search: searchLabel,
              addPin: addPinLabel,
              removePin: removePinLabel,
              mapSettings: mapSettingsTitle,
              ruler,
            }}
            resetView={resetView}
            addressSearch={AddressSearch}
            closeSearch={closeSearch}
            mapSettingsConfig={{
              update: () => {
                applyBackground();
                applyLayers();
              },
              reset: () => {
                resetBackground();
                resetLayers();
              },
              content: (
                <MapSettingsContainer
                  instance={SPATIAL_FEATURES}
                  config={{
                    background: mapStyle,
                    layers: layersDisplayed,
                  }}
                  onUpdate={({ selectedBackground, selectedLayers }) => {
                    if (typeof selectedBackground !== 'undefined') {
                      storeSelectedBackground(selectedBackground);
                    }
                    if (typeof selectedLayers !== 'undefined') {
                      storeSelectedLayers(selectedLayers);
                    }
                  }}
                />
              ),
            }}
          />
        )}
        {isLocationTagOpen && (
          <LocationPopup
            latitude={geocoding.latitude}
            longitude={geocoding.longitude}
            address={locationAddress || place}
            elevation={elevation}
            showFilterButton={false}
            languageData={{ latLabel, lngLabel, amslLabel, filterLabel }}
            isUsingPca={false}
            mapApis={mapApis}
            onClose={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
          />
        )}
        <MapReferenceLayers mapApis={mapApis} mapStyle={mapStyle} layers={layersDisplayed} />
        <RulerTool
          distanceUnits={units.distance}
          coordinates={rulerCoordinates}
          isRulerEnabled={isRulerEnabled}
          addressCoordinates={geocoding}
          mapProjection={mapProjectionString}
          handleDragEvent={rulerCoordinatesChanged}
          mapApis={mapApis}
        />
        <MapLegend layersDisplayed={layersDisplayed} />
      </StyledMap>
    </div>
  );
};
