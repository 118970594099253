import React, { FC, useState, useEffect } from 'react';
import { IFormWrapper, TTypedForms } from './interfaces';
import { FormField } from './FormField';
import { FormErrorDisplay } from '@ems/client-design-system';
import { areFieldsValid, validateForm } from './formFieldHelpers';

export const FormWrapper: FC<IFormWrapper> = ({
  formData,
  onUpdate,
  setFormValidity,
  formLogicValidation,
  validationParams,
}) => {
  const [form, updateFormData] = useState<TTypedForms>(formData);

  useEffect(() => {
    const updatedForm = validateForm(formData, formLogicValidation, validationParams);
    setFormValidity(areFieldsValid(Object.values(updatedForm)));
    updateFormData(updatedForm);
  }, [formData]);

  const onFormUpdate = (fieldKey: string, value: string | null) => {
    const updatedForm = validateForm(
      { ...form, [fieldKey]: { ...formData[fieldKey], value } },
      formLogicValidation,
      validationParams
    );
    // Performs two types of validation - formLogicValidation and input validation

    setFormValidity(areFieldsValid(Object.values(updatedForm)));
    updateFormData(updatedForm);
    onUpdate(updatedForm);
  };

  return (
    <>
      {Object.keys(form).map((formField: string, index: number) => (
        <div className={'input-wrapper'} key={index}>
          <FormField
            key={`${index} ${formField}`}
            {...form[formField]}
            onFormUpdate={onFormUpdate}
            formKey={formField}
          />
          <FormErrorDisplay
            key={`${index} ${form[formField].error}`}
            error={form[formField].error}
          />
        </div>
      ))}
    </>
  );
};
