import React from 'react';
import { DateTime } from 'luxon';
import { Button, Card, FieldDetails } from '@ems/client-design-system';
// reducers
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// functions
import {
  formatDisplayFeatureType,
  useFormatCardData,
} from 'src/@settings/functions/spatialFeatures';
import { setUnitAsMetersOrFeet } from 'src/utils';
// Interfaces
import { ISpatialFeatureCardField, ISpatialFeature } from 'src/@settings/interfaces';
import { TSpatialFeature } from 'src/utils/spatialFeatureHelpers/interfaces';

import { DATE_TIME_FORMAT } from 'src/constants';
interface IViewFeatureProps {
  featureDetails: TSpatialFeature;
  featureType: string;
  spatialFeatureData: ISpatialFeature;
  cancelViewFeature: () => void;
  editFeature: () => void;
  deleteFeature: () => void;
  canUpdate: boolean;
}

export const ViewSpatialFeature: React.FC<IViewFeatureProps> = ({
  featureType,
  featureDetails,
  cancelViewFeature,
  editFeature,
  canUpdate,
  spatialFeatureData,
}) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: {
          edit: editString,
          edited: editedString,
          created: createdString,
          cancel: cancelString,
        },
      },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const {
    globals: { '12HourFormat': twelveHourFormat },
  } = configSelectors.getConfig();

  const cancel = () => {
    cancelViewFeature();
  };

  const verticalDistance = setUnitAsMetersOrFeet(units.distanceVertical);
  // Formats data into card data
  const featureCardData: ISpatialFeatureCardField[] = useFormatCardData(
    featureType,
    featureDetails,
    verticalDistance
  );
  const lastUpdatedTime = spatialFeatureData.lastUpdateTime
    ? new Date(spatialFeatureData.lastUpdateTime).toISOString()
    : null;
  const createdTime = spatialFeatureData.createTime
    ? new Date(spatialFeatureData.createTime).toISOString()
    : null;

  const dateFormat = twelveHourFormat
    ? DATE_TIME_FORMAT.TABLE_12H_YEARS
    : DATE_TIME_FORMAT.TABLE_24H_YEARS;

  const displayUpdatedTime = lastUpdatedTime
    ? DateTime.fromISO(lastUpdatedTime, { setZone: true }).toFormat(dateFormat)
    : '—';
  const displayCreatedTime = createdTime
    ? DateTime.fromISO(createdTime, { setZone: true }).toFormat(dateFormat)
    : '—';

  return (
    <div className={'spatial-feature__detail'}>
      <span className={'spatial-feature__title'}>{formatDisplayFeatureType(featureType)}</span>
      <Card className="spatial-feature__card">
        {featureCardData.map((item: ISpatialFeatureCardField, index: number) => (
          <FieldDetails
            key={`${item.label} ${index}`}
            fieldType="inputField"
            className="col-md-2 feature__field"
            label={item.label}
            text={item.text}
          />
        ))}
        <div className="spatial-feature__times">
          <FieldDetails
            fieldType="inputField"
            className="col-md-2 feature__field"
            label={createdString}
            text={displayCreatedTime}
          />
          <FieldDetails
            fieldType="inputField"
            className="col-md-2 feature__field"
            label={editedString}
            text={displayUpdatedTime}
          />
        </div>
      </Card>
      <div className="spatial-feature__footer">
        <Button onClick={cancel}>{cancelString}</Button>
        <div className="footer__action-buttons">
          {canUpdate && (
            <Button size="m" className={'footer__button'} onClick={editFeature} style={'primary'}>
              {editString}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
