import React from 'react';
// Types
import { History } from 'history';
import { IDeviceManagementForm } from '../../interfaces';

// Functions
import { getDeployedProductId } from 'src/utils';

// Variables
import { SETTINGS } from 'src/constants';
import { DEFAULT_CALIBRATION_TIMES, emptyFormData } from './defaults';

// Components
import { displayToast } from '@ems/client-design-system';

export const setEditStateAction = (
  setEditingValue: (value: React.SetStateAction<boolean>) => void,
  value: boolean
): void => {
  setEditingValue(value);
};

export const redirectListViewAction = (history: History<unknown>): void => {
  history.push(`/${getDeployedProductId()}/${SETTINGS}/nmt-management`);
};

export const resetLocationAction = (
  setValues: (
    values: React.SetStateAction<IDeviceManagementForm>,
    shouldValidate?: boolean
  ) => void,
  deviceId: number
) => {
  setValues({
    ...emptyFormData,
    id: deviceId,
    calibrationTimes: DEFAULT_CALIBRATION_TIMES,
  });
};

export const onSaveSuccessAction = ({
  mutationError,
  updateSuccessString,
}: {
  mutationError: boolean;
  updateSuccessString: string;
}): void => {
  if (!mutationError) {
    displayToast({
      key: 'updateSuccessToast',
      message: updateSuccessString,
      intent: 'success',
      timeout: 5000,
    });
  }
};
