import styled, { css } from 'styled-components/macro';

// Colors
import styles from 'src/styles/vars.module.scss';

export const InlineEditErrorWrapper = styled.div<{ isError?: boolean }>`
  position: relative;

  ${props =>
    props.isError &&
    css`
      .inline-dropdown_button:focus.inline-dropdown_button--lightmode
        .inline-dropdown_button--chevron,
      .inline-dropdown_button.bp3-active.inline-dropdown_button--lightmode
        .inline-dropdown_button--chevron {
      }

      .inline-dropdown_button.inline-dropdown_button--lightmode .inline-dropdown_button--chevron,
      .inline-dropdown_button--open.inline-dropdown_button--lightmode
        .inline-dropdown_button--chevron {
        border-top: 1px solid ${styles.error01};
        border-right: 1px solid ${styles.error01};
        border-bottom: 1px solid ${styles.error01};
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        display: flex;
        position: absolute;
        right: -22px;

        svg {
          fill: ${styles.error01};
        }
      }

      .inline-dropdown_button.inline-dropdown_button--lightmode: before,
    .inline-dropdown_button--open.inline-dropdown_button--lightmode: before {
        content: '';
        width: calc(100% + 10px);
        height: 100%;
        position: absolute;
        border-color: ${styles.error01};
        top: -1px;
        left: -6px;
        box-sizing: content-box;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-left: solid 1px ${styles.error01};
        border-top: solid 1px ${styles.error01};
        border-bottom: solid 1px ${styles.error01};
      }
    `}
`;

export const InlineEditError = styled.div`
  position: absolute;
  left: -5px;
`;

export const InlineEditErrorContent = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1.7;
  color: ${styles.error01};
`;
