import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
// config
import { mapLayerApi } from 'src/config';
// global store
import { GeometryDispatchContext } from 'src/app/providers/GeometryProvider';
import { loadRequiredGeometry } from 'src/app/actions/geometryActions';
import { useGeometrySelectors } from 'src/app/reducers';
// functions
import { getDeployedProductId } from 'src/utils/generics';
import { whenMapHasLoadedSource, isSourceAvailable } from 'src/utils/mapHelpers/mapHelpers';
import {
  mapboxCorridorStyle,
  mapboxExclusionStyle,
  mapboxGateStyle,
  mapboxGateLightStyle,
  mapboxGateSymbolsStyle,
  mapboxGateSymbolsLightStyle,
  mapboxRunwayStyle,
  mapboxRunwayFillStyle,
  mapboxNmtSymbolsStyle,
  mapboxNmtSymbolsLayout,
  mapboxNmtSymbolsLightLayout,
} from 'src/utils/mapStyles';
// constants
import { INFRINGEMENT_RULE_TYPES, MONITOR_LOCATIONS, RUNWAYS } from 'src/constants';
// types
import { IGraphRestParams } from 'src/utils/interfaces';
// Actions
import { geometryActionTypes } from 'src/app/newActionTypes';
import { useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import { addLayerToMap } from './mapHelpers/mapApis';

export const getGeometryType = (infringementType: string): string => {
  let path: string;
  switch (infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      path = `corridors`;
      break;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      path = `selectionzones`;
      break;
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      path = `gates`;
      break;
    case MONITOR_LOCATIONS:
      path = `monitorLocations`;
      break;
    case RUNWAYS:
      path = `runways`;
      break;
    default:
      path = '';
  }
  return path;
};

export const getGeometryFields = (infringementType: string): string => {
  let path: string;
  switch (infringementType) {
    case MONITOR_LOCATIONS:
      path = `name,description`;
      break;
    case RUNWAYS:
      path = `airportId,name`;
      break;
    default:
      path = 'name';
  }
  return path;
};

export const getAdditionalGeometryParams = ({
  infringementType,
  dateRange,
}: {
  infringementType: string;
  dateRange: { dateFilterFrom: string; dateFilterTo: string };
}): IGraphRestParams => {
  const additionalParamList = {};

  switch (infringementType) {
    case MONITOR_LOCATIONS:
      if (dateRange) {
        // Date filters return browser timezone, we need the airport timezone.
        additionalParamList['activePeriodStartTime'] = dateRange.dateFilterFrom;
        additionalParamList['activePeriodEndTime'] = dateRange.dateFilterTo;
      }

      break;
    default:
  }
  return additionalParamList;
};

export const useGeometryData = ({
  infringementType,
  as3d,
}: {
  infringementType: string;
  as3d: boolean;
}): {
  geometryData: any;
} => {
  const geometryType = getGeometryType(infringementType);
  const geometryFields = getGeometryFields(infringementType);
  const client = useApolloClient();
  const geometryDispatcher = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const geometryData = geometrySelectors.getGeometry(geometryType, as3d);

  if (!geometryData && geometryType) {
    loadRequiredGeometry({
      client,
      dispatcher: geometryDispatcher,
      source: infringementType,
      path: geometryType,
      params: {
        fields: geometryFields,
        as3d,
      },
    });
  }

  return {
    geometryData,
  };
};

// request to fetch required geometry data and add them as new sources to map
export const useGeometryRequiredByMap = ({
  mapApis = null,
  mapBoxConfig = null,
  sourceIdExt = '',
  infringementTypes,
  dateRange,
  mapStyle = null,
}: {
  mapApis?: any;
  mapBoxConfig?: any;
  sourceIdExt?: string;
  infringementTypes: string[];
  dateRange: {
    dateFilterFrom: string;
    dateFilterTo: string;
  };
  mapStyle?: string;
}) => {
  const client = useApolloClient();
  const geometryDispatcher = useContext(GeometryDispatchContext);
  const geometrySelectors = useGeometrySelectors();
  const [requiredGeometryTypes, updateRequiredTypes] = useState<any>({
    [INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT]: false,
    [INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT]: false,
    [INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT]: false,
    [MONITOR_LOCATIONS]: false,
    [RUNWAYS]: false,
  });
  const corridorsData = geometrySelectors.getGeometry(
    getGeometryType(INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT)
  );
  const exclusionsData = geometrySelectors.getGeometry(
    getGeometryType(INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT)
  );
  const gateData = geometrySelectors.getGeometry(
    getGeometryType(INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT)
  );
  const monitorLocationsData = geometrySelectors.getGeometry(getGeometryType(MONITOR_LOCATIONS));
  const runwaysData = geometrySelectors.getGeometry(getGeometryType(RUNWAYS));
  const allowUpdateOnDateFilter = [MONITOR_LOCATIONS];

  const [queryDateRange, setQueryDateRange] = useState(dateRange);
  useEffect(() => {
    if (JSON.stringify(dateRange) !== JSON.stringify(queryDateRange)) {
      setQueryDateRange(dateRange);
    }
  }, [dateRange]);

  const rerun = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 1000);

  // Refresh when date filter changes
  useEffect(() => {
    infringementTypes.forEach((infringementType: string) => {
      if (allowUpdateOnDateFilter.indexOf(infringementType)) {
        return false;
      }
      if (
        typeof requiredGeometryTypes[infringementType] !== 'undefined' &&
        requiredGeometryTypes[infringementType]
      ) {
        loadRequiredGeometry({
          client,
          dispatcher: geometryDispatcher,
          source: infringementType,
          path: getGeometryType(infringementType),
          params: {
            fields: getGeometryFields(infringementType),
            as3d: false,
            ...getAdditionalGeometryParams({
              infringementType,
              dateRange,
            }),
          },
        });
      }
    });
  }, [queryDateRange]);

  useEffect(() => {
    if (infringementTypes.length) {
      const temp: any = Object.assign({}, requiredGeometryTypes);
      infringementTypes.forEach((infringementType: string) => {
        // Monitor locations need to always clear and fetch when toggled one
        if (infringementType === MONITOR_LOCATIONS) {
          geometryDispatcher({
            type: geometryActionTypes[MONITOR_LOCATIONS],
            data: null,
            as3d: false,
          });
          loadRequiredGeometry({
            client,
            dispatcher: geometryDispatcher,
            source: infringementType,
            path: getGeometryType(infringementType),
            params: {
              fields: getGeometryFields(infringementType),
              as3d: false,
              ...getAdditionalGeometryParams({
                infringementType,
                dateRange,
              }),
            },
          });
        }
        if (
          typeof requiredGeometryTypes[infringementType] !== 'undefined' &&
          !requiredGeometryTypes[infringementType]
        ) {
          temp[infringementType] = true;
          if (
            (infringementType === INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT &&
              !corridorsData) ||
            (infringementType === INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT &&
              !exclusionsData) ||
            (infringementType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT && !gateData) ||
            (infringementType === RUNWAYS && !runwaysData)
          ) {
            loadRequiredGeometry({
              client,
              dispatcher: geometryDispatcher,
              source: infringementType,
              path: getGeometryType(infringementType),
              params: {
                fields: getGeometryFields(infringementType),
                as3d: false,
                ...getAdditionalGeometryParams({
                  infringementType,
                  dateRange,
                }),
              },
            });
          }
        }
      });
      if (Object.values(requiredGeometryTypes).join() !== Object.values(temp).join()) {
        updateRequiredTypes(temp);
      }
    }
  }, [mapApis, infringementTypes, rerun]);

  useEffect(() => {
    if (mapApis && mapBoxConfig) {
      if (corridorsData) {
        addSourceToMap(
          mapApis,
          mapBoxConfig,
          INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT,
          corridorsData,
          sourceIdExt
        );
      }
      if (exclusionsData) {
        addSourceToMap(
          mapApis,
          mapBoxConfig,
          INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT,
          exclusionsData,
          sourceIdExt
        );
      }
      if (gateData) {
        addSourceToMap(
          mapApis,
          mapBoxConfig,
          INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT,
          gateData,
          sourceIdExt
        );
      }
      if (monitorLocationsData) {
        addSourceToMap(mapApis, mapBoxConfig, MONITOR_LOCATIONS, monitorLocationsData, sourceIdExt);
      }
      if (runwaysData) {
        addSourceToMap(mapApis, mapBoxConfig, RUNWAYS, runwaysData, sourceIdExt);
      }
    }
  }, [
    mapApis,
    corridorsData,
    exclusionsData,
    gateData,
    monitorLocationsData,
    runwaysData,
    mapStyle,
    rerun,
  ]);

  return {
    requiredGeometryTypes,
    corridorsData,
    exclusionsData,
    gateData,
    monitorLocationsData,
    runwaysData,
  };
};

/**
 * Add geometry (corridors, selectionzones, gates, etc) sources to interactive map
 * @param mapApis - Mapbox API: provides methods to access map
 * @param mapBoxConfig - map config - site specific
 */
export const addSourceToMap = (mapApis, mapBoxConfig, source, sourceData, sourceIdExt = '') => {
  const {
    isSatelliteBackground,
    corridorIdentifier,
    selectionZoneIdentifier,
    gateIdentifier,
    monitorLocationsIdentifier,
    runwaysIdentifier,
  } = mapBoxConfig;

  let type;
  let identifier;
  let paint; // map style object to paint layer
  let layout: any = null;
  let allowUpdates: boolean = false;
  switch (source) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      type = 'fill';
      identifier = `${corridorIdentifier}${sourceIdExt}`;
      paint = mapboxCorridorStyle;
      break;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      type = 'fill';
      identifier = `${selectionZoneIdentifier}${sourceIdExt}`;
      paint = mapboxExclusionStyle;
      break;
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      type = 'line';
      identifier = `${gateIdentifier}${sourceIdExt}`;
      paint = isSatelliteBackground ? mapboxGateLightStyle : mapboxGateStyle;
      break;
    case MONITOR_LOCATIONS:
      type = 'symbol';
      identifier = `${monitorLocationsIdentifier}${sourceIdExt}`;
      paint = mapboxNmtSymbolsStyle;
      layout = isSatelliteBackground ? mapboxNmtSymbolsLightLayout : mapboxNmtSymbolsLayout;
      allowUpdates = true;
      break;
    case RUNWAYS:
      type = 'line';
      identifier = `${runwaysIdentifier}${sourceIdExt}`;
      paint = mapboxRunwayStyle;
      break;
    default:
  }

  if (
    (!mapApis ||
      isSourceAvailable(mapApis, identifier) ||
      mapApis.getStyle().layers.some(({ id }) => id === identifier)) &&
    !allowUpdates // Allows an already rendered layer to be updated
  ) {
    return; // already added
  }

  if (mapApis.getStyle().layers.some(({ id }) => id === identifier)) {
    mapApis.removeLayer(identifier);
  }

  if (isSourceAvailable(mapApis, identifier)) {
    mapApis.removeSource(identifier);
  }

  try {
    mapApis.addSource(identifier, {
      type: 'geojson',
      data: sourceData,
    });
  } catch (error) {
    console.error(error);
  }

  whenMapHasLoadedSource(mapApis, identifier).then(() => {
    if (source === MONITOR_LOCATIONS) {
      addLayerToMap(mapApis, {
        id: identifier,
        type,
        source: identifier,
        paint,
        layout,
        metadata: { tagType: MONITOR_LOCATIONS },
      });
    } else {
      addLayerToMap(mapApis, {
        id: identifier,
        type,
        source: identifier,
        paint,
      });
    }

    // add runway fill
    if (source === RUNWAYS) {
      const sourceIdentifier = `${identifier}-fill`;
      mapApis.addSource(sourceIdentifier, {
        type: 'geojson',
        data: sourceData,
      });
      whenMapHasLoadedSource(mapApis, sourceIdentifier).then(() => {
        addLayerToMap(mapApis, {
          id: sourceIdentifier,
          type: 'fill',
          source: identifier,
          paint: mapboxRunwayFillStyle,
        });
      });
    }
    // add gate symbols
    else if (source === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT) {
      const { features } = sourceData;
      const sourceFeatures: any[] = [];
      const sourceIdentifier = `${identifier}-sybmols`;
      features.forEach(({ id, geometry: { coordinates } }) => {
        coordinates.forEach(([lat, lng]) => {
          sourceFeatures.push({
            id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lat, lng],
            },
          });
        });
      });
      const gatesSourceData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: sourceFeatures,
        },
      };

      mapApis.addSource(sourceIdentifier, gatesSourceData);
      whenMapHasLoadedSource(mapApis, sourceIdentifier).then(() => {
        addLayerToMap(mapApis, {
          id: sourceIdentifier,
          type: 'circle',
          source: identifier,
          paint: isSatelliteBackground ? mapboxGateSymbolsLightStyle : mapboxGateSymbolsStyle,
        });
      });
    }
  });
};

export const getGeometryEndpoint = (infringementType: string) => {
  const endpoint = `${mapLayerApi}${getDeployedProductId()}`;
  let path: null | string;
  switch (infringementType) {
    case INFRINGEMENT_RULE_TYPES.CORRIDOR_INFRINGEMENT:
      path = `corridors`;
      break;
    case INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT:
      path = `gates`;
      break;
    case INFRINGEMENT_RULE_TYPES.EXCLUSION_INFRINGEMENT:
      path = `selectionzones`;
      break;
    default:
      path = null;
  }
  return {
    geometryUrl: path ? `${endpoint}/${path}` : null,
    geometryUrl3d: path ? `${endpoint}/${path}?as3d=true` : null,
  };
};
