import React, { useCallback } from 'react';

// Types
import { IAircraftMappingEdge } from '../types';

// Functions
import { InlineDropDown } from '@ems/client-design-system';

// Hooks
import { useAircraftMappings } from '../providers';
import { useSetAircraftMapping } from '../hooks';
import { useScenarios } from 'src/@settings/provider/Scenarios/ScenariosProvider';

// Translation
import { useLanguageSelectors } from 'src/app/reducers';

export const MappingDropdown = ({
  selectedEdge,
  type,
  filterOptions,
}: {
  selectedEdge: IAircraftMappingEdge;
  type: 'mappedEnrouteAircraftEngine' | 'mappedLTOAircraftEngine';
  filterOptions: Array<{ key: string; label: string }>;
}): JSX.Element => {
  const {
    state: {
      canEdit,
      queryVariables,
      data: { edges },
    },
  } = useAircraftMappings();
  if (!canEdit) {
    return <span>{selectedEdge.node[type]}</span>;
  }

  const languageSelector = useLanguageSelectors();
  const {
    components: {
      hints: { noMatchesFound },
    },
  } = languageSelector.getLanguage();

  const {
    state: { activeScenario },
  } = useScenarios();
  const [setMapping] = useSetAircraftMapping({ queryVariables, scenarioId: activeScenario.id });

  const defaultSelectedItem = {
    label: selectedEdge.node[type],
    key: selectedEdge.node[type],
  };

  const updateSelection = useCallback(
    filter => {
      const edgeToUpdate = edges.find(node => node.cursor === selectedEdge.cursor);
      if (edgeToUpdate) {
        edgeToUpdate.node[type] = filter.key;
        setMapping({ edge: edgeToUpdate });
      }
    },
    [edges, selectedEdge.cursor, setMapping, type]
  );

  return (
    <InlineDropDown
      defaultSearchValue={selectedEdge.node.aircraftType}
      searchItems={filterOptions}
      selectedItem={defaultSelectedItem}
      hasIcon={false}
      isIconOnly={false}
      isNullable
      updateSelection={updateSelection}
      width={360}
      noMatchesFound={noMatchesFound}
    />
  );
};
