import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const actionTypes = keyToActionTypes(
  {
    GET_AIRLINES: null,
    UPDATE_CURRENT_AIRLINE_MAPPINGS: null,
    UPDATE_AIRLINE_MAPPINGS: null,
    UPDATE_CURRENT_AIRLINE: null,
    GET_AIRCRAFT_MAPPINGS: null,
    UPDATE_AIRCRAFT_MAPPINGS: null,
    GET_TIME_IN_MODES: null,
    GET_LOAD_FACTORS: null,
    GET_AIRCRAFT_FUEL_MAPPINGS: null,
    SORT_FLEET_MIX_TABLE: null,
    SORT_LOAD_FACTORS_TABLE: null,
    GET_AIRLINE_MAPPINGS_BY_ID: null,

    SORT_TIME_IN_MODES_TABLE: null,
    SORT_FUEL_TYPES_TABLE: null,
    SORT_AIRLINE_MAPPING_TABLE: null,
    AIRCRAFT_FUEL_MAPPINGS_LOADING: null,
    AIRCRAFT_MAPPINGS_LOADING: null,
    AIRLINES_LOADING: null,
    LOAD_FACTORS_LOADING: null,
    TIME_IN_MODES_LOADING: null,
    GET_LTO_MAPPINGS: null,
    GET_ENROUTE_MAPPINGS: null,
    GET_AIRCRAFT_FLIGHTS: null,
    AIRCRAFT_FLIGHTS_LOADING: null,
    SHOW_MAPPED: null,
    GET_AIRPORTS: null,

    // Spatial Features
    GET_SPATIAL_FEATURES: null,
    SORT_SPATIAL_FEATURES: null,
    SPATIAL_FEATURES_ROW_SELECTED: null,
    ADD_NEWLY_LOADED_FEATURE: null,
    SPATIAL_FEATURES_LOADED: null,
    LOAD_MORE_SPATIAL_FEATURES: null,
    LOAD_MORE_SPATIAL_FEATURES_SUCCESS: null,
    NEW_FEATURE_ADDED: null,
    FEATURE_UPDATED: null,
    GET_ALL_GATES: null,
    GET_ALL_ZONES: null,
    GET_ALL_CORRIDORS: null,
    GET_SELECTED_GATE_DETAILS: null,
    GET_SELECTED_CORRIDOR_DETAILS: null,
    GET_SELECTED_ZONE_DETAILS: null,

    CORRIDOR_UPDATED: null,
    ZONE_UPDATED: null,
    GATE_UPDATED: null,

    GET_OPERATIONS: null,

    GET_ALL_NOISE_MONITORS: null,
    RESET_OPERATIONS: null,

    GET_OPERATIONS_SUCCESS: null,
  },
  'SETTINGS'
);
