import { useCallback, useContext, useMemo } from 'react';
import { useConfigSelectors, useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { AIRCRAFT_TYPE_ICON_CODES, NULL_VALUE } from 'src/constants';
import { convertDataToFilterForm, getIfFiltersEmpty, translateLabels } from '../../functions';
import themeToken from 'src/styles/themes.json';
import { useFilterSelectors } from '../../reducers/filterReducer';
import { IFilterItem } from '@ems/client-design-system';
import { OperationDispatchContext } from '../../providers/OperationsStateProvider';
import {
  clearPcaFilter,
  clearSelectedItems,
  updateRangeFilter,
  updateSelectedItems,
} from '../../actions';
import { usePermissions } from 'src/app/functions/permissions';
import { FORMATION_COUNT, OPERATION_TAGS, OPERATOR_CATEGORY } from 'src/app/featureToggles';
import { capitalizeObjectKeys } from 'src/utils';
import {
  FilterComponentTypes,
  FilterSetupSchema,
} from 'src/@operations/containers/FilterContainer/filters.types';
import { useSearchFilter } from './useSearchFilter';
import { actionTypes } from 'src/@operations/newActionTypes';

export const useOperationsFilter = () => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      labels: {
        acid,
        tailNumber,
        filters: { clear: clearValue, filter: filterValue, clearFilters },
      },
      hints: { loading, noMatchesFound },
      lists: {
        operationFilterCategories,
        aircraftCategories,
        operatorCategories,
        operationTypes,
        correlatedCategories,
        extraFilterValues,
      },
      filters: filtersTranslation,
    },
  } = languageSelectors.getLanguage();

  const convertedDataList = capitalizeObjectKeys({
    ...aircraftCategories,
    ...operatorCategories,
    ...operationTypes,
    ...correlatedCategories,
    ...extraFilterValues,
  });
  const configSelectors = useConfigSelectors();
  const {
    operations: {
      filter: {
        availableCategoryList: filterCategories,
        nullable: emptyOptionCategories,
        showSelectedIcons: selectedIconCategories,
      },
      availableOperationTags,
    },
  } = configSelectors.getConfig();

  const selectedTrackTheme = configSelectors.getTheme('operations');
  const { canRead: infringementsRead } = usePermissions('Infringement');
  const { canRead: noiseEventsRead } = usePermissions('NoiseEvent');
  const FEATURE_FLAG_OPERATOR_CATEGORY = configSelectors.isFeatureAvailable(OPERATOR_CATEGORY);
  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const FEATURE_FLAG_FORMATION_COUNT = configSelectors.isFeatureAvailable(FORMATION_COUNT);

  // Get filterItems - data to be selected in dropdowns
  const filtersSelectors = useFilterDataSelectors();
  const operationsFilterItems = filtersSelectors.getOperationsFilterData();
  //  The operation tags are different from other filters in that they need to
  //  come from the DB instead of the filter JSON files.
  if (
    FEATURE_FLAG_OPERATOR_CATEGORY &&
    Array.isArray(availableOperationTags) &&
    availableOperationTags.length
  ) {
    operationsFilterItems.operationTags = availableOperationTags;
  }

  // TODO - add persistent filter data when context/persistence has been set up
  // https://envirosuitelimited.atlassian.net/browse/AX-2837
  const filterItems = useMemo(
    () =>
      Object.entries(operationsFilterItems).reduce((acc, [key, value]) => {
        const iconType: string = AIRCRAFT_TYPE_ICON_CODES[key] || '';
        const filterOptionItems = emptyOptionCategories.includes(key)
          ? [NULL_VALUE, ...value]
          : value;
        return {
          ...acc,
          [key]: convertDataToFilterForm(filterOptionItems, iconType),
        };
      }, {}),
    [operationsFilterItems]
  );

  // Set the selected items
  const filterSelectors = useFilterSelectors();
  const selectedFilterItems = filterSelectors.getRegularFilters();

  // Filter Actions
  const dispatcher = useContext(OperationDispatchContext);

  const { setInputValue, onUpdateFilter, onClearPcaFilter } = useFilterCallbacks(dispatcher);

  const selectedAcid = selectedFilterItems.acid;
  const selectedTailNumber = selectedFilterItems.tailNumber;

  const {
    filterValue: acidFilterValue,
    onFilterChange: onAcidFilterChange,
    onClearFilter: onClearAcidFilter,
  } = useSearchFilter({
    defaultValue: selectedAcid,
    key: 'operations.ACIDSearchValue',
    regexValidator: /^.{1,10}$/,
  });
  const {
    filterValue: tailNumberFilterValue,
    onFilterChange: onTailNumberFilterChange,
    onClearFilter: onClearTailNumberFilter,
  } = useSearchFilter({
    defaultValue: selectedTailNumber,
    key: 'operations.TailNumberSearchValue',
    regexValidator: /^.{1,12}$/,
  });

  // Time filter
  const { value: timeFilters, label: timeLabel } = filterSelectors.getTimeFilter();

  // Create filter objects to be returned to component
  const filters: FilterSetupSchema[] = filterCategories.flatMap((filterCategory: string) => {
    switch (filterCategory) {
      case 'operationTypes':
      case 'airlines':
      case 'airportIds':
      case 'remoteAirportIds':
      case 'runwayNames':
      case 'aircraftCategories':
      case 'operatorCategories':
      case 'aircraftTypes':
      case 'correlated':
      case 'operationTags':
        if (
          (filterCategory === 'correlated' && !(infringementsRead && noiseEventsRead)) ||
          (filterCategory === 'operatorCategories' && !FEATURE_FLAG_OPERATOR_CATEGORY) ||
          (filterCategory === 'operationTags' && !FEATURE_FLAG_OPERATION_TAGS) ||
          (filterCategory === 'remoteAirportIds' && FEATURE_FLAG_FORMATION_COUNT)
        ) {
          return [];
        }
        const categoryLabel = operationFilterCategories[filterCategory];
        const onFilterItemsUpdate = onUpdateFilter(filterCategory);
        return {
          filterCategory,
          filterType: FilterComponentTypes.Selection,
          filterProps: {
            categoryLabel,
            filterItems: filterItems[filterCategory]
              ? translateLabels(filterItems[filterCategory], convertedDataList)
              : [],
            theme:
              filterCategory === 'operationTypes'
                ? themeToken.operations[selectedTrackTheme]
                : null,
            iconCategories: selectedIconCategories.map(
              filterCategory => operationFilterCategories[filterCategory]
            ),
            selectedItems: selectedFilterItems[filterCategory],
            onUpdateFilter: onFilterItemsUpdate,
            languageData: { clearValue, filterValue, noMatchesFound },
          },
        };
      case 'acid':
        return {
          filterCategory,
          filterType: FilterComponentTypes.ID,
          filterProps: {
            categoryLabel: operationFilterCategories.acid,
            onFilterChange: onAcidFilterChange,
            onFilterSubmit: () => {
              dispatcher({
                type: actionTypes.UPDATE_ACID_FILTER,
                data: { acid: acidFilterValue },
              });
            },
            onFilterClear: () => {
              onClearAcidFilter();
              dispatcher({
                type: actionTypes.UPDATE_ACID_FILTER,
                data: { acid: '' },
              });
            },
            selectedItems: acidFilterValue,
            searchValue: acidFilterValue,
            languageData: { title: acid, noMatchesFound, loading },
          },
        };
      case 'tailNumber':
        return {
          filterCategory,
          filterType: FilterComponentTypes.ID,
          filterProps: {
            categoryLabel: operationFilterCategories.tailNumber,
            onFilterChange: onTailNumberFilterChange,
            onFilterSubmit: () => {
              dispatcher({
                type: actionTypes.UPDATE_TAIL_NUMBER_FILTER,
                data: { tailNumber: tailNumberFilterValue },
              });
            },
            onFilterClear: () => {
              onClearTailNumberFilter();
              dispatcher({
                type: actionTypes.UPDATE_TAIL_NUMBER_FILTER,
                data: { tailNumber: '' },
              });
            },
            selectedItems: tailNumberFilterValue,
            searchValue: tailNumberFilterValue,
            languageData: { title: tailNumber, noMatchesFound, loading },
          },
        };
      case 'time':
        return {
          filterCategory,
          filterType: FilterComponentTypes.Time,
          filterProps: {
            filterValues: timeFilters,
            setInputValue,
            label: timeLabel,
            languageData: filtersTranslation.time,
          },
        };
      default:
        console.warn(`${filterCategory} has not been configured for operations filter`);
        return [];
    }
  });

  // PCA
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaLocation = filterSelectors.getPcaLocation();
  const pcaData = {
    usingPca,
    pcaLocation,
    onClearPcaFilter,
    pcaFieldLabel: operationFilterCategories.locationNames,
  };

  // Filter Row data
  const isClearFiltersDisabled = !usingPca && getIfFiltersEmpty(selectedFilterItems, [timeFilters]);
  const filterRowLanguageData = { clearFilters };

  const clearAllFilters = () => {
    onClearAcidFilter();
    onClearTailNumberFilter();
    clearSelectedItems(dispatcher);
  };

  return { filters, clearAllFilters, filterRowLanguageData, isClearFiltersDisabled, pcaData };
};

export const useFilterCallbacks = dispatcher => {
  const setInputValue = (value: string, field: 'from' | 'to') => {
    updateRangeFilter('time', value, field, dispatcher);
  };

  const onUpdateFilter = useCallback(
    category => {
      return (items: IFilterItem[]) => {
        updateSelectedItems(category, items, dispatcher);
      };
    },
    [dispatcher]
  );

  const onClearPcaFilter = () => {
    clearPcaFilter(dispatcher);
  };

  return { onUpdateFilter, setInputValue, onClearPcaFilter };
};
