import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// stores
import { dataStore } from 'src/@infringementsCandidates/stores/dataStore';
import { IInfringementsData } from 'src/@infringementsCandidates/interfaces';
import { tableDateTimeFormat, tableDateTimeFormatShort } from 'src/utils';
import { InfringementColumnHeader } from 'src/@infringementsCandidates/components';
import { TableIcon } from 'src/components/Icons';
import { Tooltip, Lightswitch } from '@ems/client-design-system';
import { Position, Tooltip as BlueprintTooltip } from '@blueprintjs/core';
// utils
import { getDeployedProductId } from 'src/utils/generics';
// constants
import { CHANGE_EVENT, INFRINGEMENTS } from 'src/constants';

/**
 * The purpose of useLatestData Hook is to subscribe to infringements Store and recieve infringements data updates.
 */
export const useLatestData = () => {
  const [data, updateData] = useState(dataStore.getDataInformation());

  useEffect(() => {
    const handleDataUpdates = () => {
      updateData(dataStore.getDataInformation());
    };

    dataStore.on(CHANGE_EVENT, handleDataUpdates);

    return () => {
      dataStore.removeListener(CHANGE_EVENT, handleDataUpdates);
    };
  }, []);

  return { data };
};

export const useDataForNavigation = () => {
  const [ids, updateIds] = useState(dataStore.getDataIds());
  const [pagingInfo, updatePagingInfo] = useState(dataStore.getPagingInfo());

  useEffect(() => {
    const handleDataUpdates = () => {
      updateIds(dataStore.getDataIds());
      updatePagingInfo(dataStore.getPagingInfo());
    };

    dataStore.on(CHANGE_EVENT, handleDataUpdates);

    return () => {
      dataStore.removeListener(CHANGE_EVENT, handleDataUpdates);
    };
  }, []);

  return {
    itemsIds: ids,
    hasNextPage: pagingInfo ? pagingInfo.hasNextPage : false,
    endCursor: pagingInfo ? pagingInfo.endCursor : undefined,
    dateRange: dataStore.getSelectedDateRange(),
  };
};

/*
 * Aricraft icon
 */
const flightIcon = (acType: string | null, translationData) => {
  if (!acType) {
    return acType;
  }
  return (
    <Tooltip content={translationData[acType]}>
      {TableIcon({
        name: acType.toLowerCase(),
        prefix: 'ac',
        size: 26,
      })}
    </Tooltip>
  );
};

/*
 * Rule Name link
 */
const linkToInfDetails = (rule: string | null, id: any | null, ruleInfo) => (
  <Link
    className="rule_link"
    to={{
      pathname: `/${getDeployedProductId()}/${INFRINGEMENTS}/${id}`,
      search: undefined,
      state: { ...ruleInfo },
    }}>
    {rule}
  </Link>
);

/*
 * Prepare infringement for render on table
 */
export const formatInfringementData = (
  data,
  translationData: object,
  lightSwitchState,
  ruleInfo,
  twelveHourFormat
) => {
  const dataLength = data.length;
  const formattedData: IInfringementsData[] = [];
  if (dataLength) {
    for (let i = dataLength; i--; ) {
      const infringement = data[i];
      infringement.displayRuleName = lightSwitchState ? (
        infringement.isInfringement &&
        infringement.operationId &&
        infringement.operationId !== 0 ? (
          linkToInfDetails(infringement.ruleName, infringement.infringementId, ruleInfo)
        ) : (
          <span className="no-rule_link">{infringement.ruleName}</span>
        )
      ) : infringement.operationId !== 0 ? (
        linkToInfDetails(infringement.ruleName, infringement.id, ruleInfo)
      ) : (
        <span className="no-rule_link">{infringement.ruleName}</span>
      );
      infringement.displayTime = tableDateTimeFormat(infringement.time, twelveHourFormat);
      infringement['displayTime-short'] = tableDateTimeFormatShort(
        infringement.time,
        twelveHourFormat
      );
      infringement.displayCategory = flightIcon(infringement.aircraftCategory, translationData);
      infringement.displayCandidates = lightSwitchState ? (
        infringement.isInfringement ? (
          'Infringement'
        ) : (
          <span className="candidates-text">Candidate</span>
        )
      ) : (
        'Infringement'
      );
      infringement.displayInfringement = translationData[infringement.infringementType];
      infringement.displaySeverity = translationData[infringement.severity];
      formattedData.unshift(infringement);
    }
  }
  return formattedData;
};

/*
 * Prepare infringement header
 */
export const formatInfringementHeaders = (
  resultSize: number,
  isLoading,
  value,
  onClick,
  ruleFilterApplied,
  candidatesTooltipText,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config
  const {
    fields: { infringements: rowHeaders },
    abbreviations: { aircraftCategory: aircraftCategoryAbrr, runwayName: runwayNameAbbr },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const acid = InfringementColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRuleName = InfringementColumnHeader({
    sortName: 'ruleName',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayRuleName,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = InfringementColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const displayInfringement = InfringementColumnHeader({
    sortName: 'infringementType',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const displaySeverity = InfringementColumnHeader({
    sortName: 'severity',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.severity,
      abbreviation: null,
      sortBy,
    },
  });
  const remoteAirportId = InfringementColumnHeader({
    sortName: 'remoteAirportId',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.remoteAirportId,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRunwayName = InfringementColumnHeader({
    sortName: 'runwayName',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayRunwayName,
      abbreviation: runwayNameAbbr,
      sortBy,
    },
  });
  const displayCategory = InfringementColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = InfringementColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayStatus = InfringementColumnHeader({
    sortName: 'status',
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayStatus,
      abbreviation: null,
      sortBy,
    },
  });
  const displayCandidates = InfringementColumnHeader({
    sortName: 'candidates',
    sortable: false,
    resultSize,
    isLoading,
    languageData: {
      title: rowHeaders.displayCandidates,
      abbreviation: null,
      sortBy,
    },
    content: (
      <BlueprintTooltip
        content={candidatesTooltipText}
        position={Position.TOP}
        disabled={ruleFilterApplied}>
        <Lightswitch
          enabled={value}
          disabled={!ruleFilterApplied}
          label="Show candidates"
          onClick={onClick}
        />
      </BlueprintTooltip>
    ),
  });

  return Object.assign({}, rowHeaders, {
    displayRuleName,
    displayTime,
    displaySeverity,
    displayInfringement,
    remoteAirportId,
    displayRunwayName,
    displayCategory,
    acid,
    aircraftType,
    displayStatus,
    displayCandidates,
  });
};
