import React from 'react';
import { FormikProps } from 'formik';

// Components
import {
  TextInput,
  RangeSelector,
  validateTimeFormat,
  onCloseTimeDialogValidation,
  isCompletedTimeFormat,
  isValidTime,
  Card,
  TimeInput,
} from '@ems/client-design-system';
import {
  ScenarioFormLabel,
  ScenarioFormInput,
  ScenarioFormError,
  CreateScenarioFormRow,
  ScenarioFormSettingsPeriod,
  ScenarioSettingInput,
} from './CreateScenarioForm.styles';

// Functions
import { createScenarioFilter } from './helpers';
import { capitalize } from 'src/utils';

// Variables
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';

// Theme
import themeToken from 'src/styles/themes.json';

// Types
import {
  ICreateScenarioTranslations,
  IScenarioFormValues,
} from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

import { IOperationsFilters } from 'src/app/props';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

export const CreateScenarioPeriod = ({
  formik,
  period,
}: {
  formik: FormikProps<IScenarioFormValues>;
  period: 'day' | 'evening' | 'night';
}) => {
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      filters: {
        time: { validationError },
      },
      labels: labelString,
    },
  } = languageSelector.getLanguage();

  return (
    <ScenarioSettingInput>
      <ScenarioFormLabel htmlFor="period">{labelString[period]}</ScenarioFormLabel>
      <TimeInput
        defaultValue={formik.values[`${period}Period`]}
        languageData={{ validationError, placeholder: 'hh:mm' }}
        onTimeInput={(value: string) => {
          const newErrors = { ...formik.errors };
          newErrors[`${period}Period`] = '';
          formik.setErrors(newErrors);
          formik.setFieldValue(`${period}Period`, value);
        }}
        onError={() => {
          const newErrors = { ...formik.errors };
          newErrors[`${period}Period`] = validationError;
          formik.setErrors(newErrors);
        }}
        allowEmpty
      />
      <ScenarioFormError>{formik.errors[`${period}Period`] || <>&nbsp;</>}</ScenarioFormError>
    </ScenarioSettingInput>
  );
};

export const CreateScenarioName = ({
  formik,
  translations,
}: {
  formik: FormikProps<IScenarioFormValues>;
  translations: ICreateScenarioTranslations;
}) => (
  <ScenarioFormInput>
    <ScenarioFormLabel htmlFor="name">{translations.labels.name}</ScenarioFormLabel>
    <TextInput
      id="name"
      name="name"
      type="text"
      onChange={formik.handleChange}
      value={formik.values.name}
      state={formik.errors.name ? 'error' : 'default'}
    />
    <ScenarioFormError>{formik.errors.name || <>&nbsp;</>}</ScenarioFormError>
  </ScenarioFormInput>
);

const CreateScenarioTimeRange = ({
  formik,
  translations,
}: {
  formik: FormikProps<IScenarioFormValues>;
  translations: ICreateScenarioTranslations;
}) => (
  <ScenarioFormInput>
    <RangeSelector
      type="time"
      min={null}
      max={null}
      isInline
      customLabelFrom={translations.labels.timeRange.start}
      customLabelTo={translations.labels.timeRange.end}
      fromInputValue={''}
      toInputValue={''}
      setInputValue={(value: string, field: 'from' | 'to') => {
        formik.setFieldValue(`time${capitalize(field)}`, value);
      }}
      label={''}
      validateInputFormat={validateTimeFormat}
      isValidValue={isValidTime}
      languageData={translations.timeInputLanguageData}
      onCloseValidation={onCloseTimeDialogValidation}
      isCompletedValueFormat={isCompletedTimeFormat}
    />
    <ScenarioFormError>{formik.errors.timeFrom || <>&nbsp;</>}</ScenarioFormError>
  </ScenarioFormInput>
);

export const CreateScenarioForm = ({
  formik,
  translations,
  selectedTrackTheme,
  filterData,
}: {
  formik: FormikProps<IScenarioFormValues>;
  translations: ICreateScenarioTranslations;
  selectedTrackTheme: string;
  filterData: IOperationsFilters;
}) => {
  const configSelectors = useConfigSelectors();
  const noiseModelingLicense = configSelectors.getNoiseModelConfig();
  const noiseScenarioModelOptions = Object.keys(noiseModelingLicense.software)
    .filter(key => noiseModelingLicense.software[key].enabled)
    .map(key => {
      return {
        label: key,
        key: key,
      };
    });
  const allowedOperationTypes = ['Arrival', 'Departure'];
  const operationTypesListItems = filterData.operationTypes.reduce((results, operation) => {
    if (allowedOperationTypes.includes(operation)) {
      results.push({
        key: operation,
        label: translations.operationTypes[capitalize(operation)],
        icon: `ad-${operation.toLowerCase()}`,
      });
    }
    return results;
  }, []);

  return (
    <>
      <div className="scenarioGeneration_card_title">
        <h4>{translations.headings.headingCandidates}</h4>
      </div>
      <Card>
        <div className="scenarioGeneration_content_pad">
          <form onSubmit={formik.handleSubmit} className="scenarioGeneration_form">
            <CreateScenarioFormRow>
              <CreateScenarioName formik={formik} translations={translations} />
            </CreateScenarioFormRow>
            <CreateScenarioFormRow>
              <CreateScenarioTimeRange formik={formik} translations={translations} />
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.airportIds.map(airport => ({
                  key: airport,
                  label: airport === null ? BLANK_STRING_PLACEHOLDER : airport,
                })),
                key: 'airportIds',
                name: translations.labels.airports,
                isMultiple: false,
              })}
            </CreateScenarioFormRow>
            <CreateScenarioFormRow>
              {createScenarioFilter({
                formik,
                translations,
                listValues: operationTypesListItems,
                key: 'operationTypes',
                name: translations.labels.operationTypes,
                isMultiple: true,
                iconTheme: themeToken.operations[selectedTrackTheme],
                hasIcons: true,
              })}
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.runwayNames.map(runway => ({
                  key: runway,
                  label: runway === null ? BLANK_STRING_PLACEHOLDER : runway,
                })),
                key: 'runwayNames',
                name: translations.labels.runways,
                isMultiple: true,
              })}
            </CreateScenarioFormRow>
            <CreateScenarioFormRow>
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.aircraftCategories.map(aircraft => ({
                  key: aircraft,
                  label:
                    aircraft === null
                      ? BLANK_STRING_PLACEHOLDER
                      : translations.aircraftCategories[capitalize(aircraft)],
                })),
                key: 'aircraftCategories',
                name: translations.labels.aircraftCategories,
                isMultiple: true,
              })}
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.aircraftTypes.map(type => ({
                  key: type,
                  label: type === null ? BLANK_STRING_PLACEHOLDER : type,
                })),
                key: 'aircraftTypes',
                name: translations.labels.aircraftModels,
                isMultiple: true,
              })}
            </CreateScenarioFormRow>
            <CreateScenarioFormRow>
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.operatorCategories.map(operator => ({
                  key: operator,
                  label:
                    operator === null
                      ? BLANK_STRING_PLACEHOLDER
                      : translations.operatorCategories[capitalize(operator)],
                })),
                key: 'operatorCategories',
                name: translations.labels.operatorTypes,
                isMultiple: true,
              })}
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.airlines.map(airline => ({
                  key: airline,
                  label: airline === null ? BLANK_STRING_PLACEHOLDER : airline,
                })),
                key: 'airlines',
                name: translations.labels.airline,
                isMultiple: true,
              })}
            </CreateScenarioFormRow>
            <CreateScenarioFormRow>
              {createScenarioFilter({
                formik,
                translations,
                listValues: filterData.pathname.map(route => ({
                  key: route,
                  label: route === null ? BLANK_STRING_PLACEHOLDER : route,
                })),
                key: 'pathNames',
                name: translations.labels.route,
                isMultiple: true,
              })}
              {createScenarioFilter({
                formik,
                translations,
                listValues: noiseScenarioModelOptions,
                key: 'noiseModelType',
                name: translations.labels.noiseScenarioModel,
                isMultiple: false,
              })}
            </CreateScenarioFormRow>
          </form>
        </div>
      </Card>
      <div className="scenarioGeneration_card_title" style={{ marginTop: '24px' }}>
        <h4>{translations.headings.generateNoiseScenarioSettingsHeading}</h4>
      </div>
      <Card>
        <div className="scenarioGeneration_content_pad">
          <ScenarioFormSettingsPeriod>
            <h3>{translations.headings.periodStart}</h3>
            <CreateScenarioPeriod formik={formik} period={'day'} />
            <CreateScenarioPeriod formik={formik} period={'evening'} />
            <CreateScenarioPeriod formik={formik} period={'night'} />
            <h3>{translations.labels.scenarioTimesOfDay}</h3>
          </ScenarioFormSettingsPeriod>
        </div>
      </Card>
    </>
  );
};
