import React from 'react';
import { useFormikContext } from 'formik';

// Hooks
import { useTemplateRowData } from '../../Hooks';
import { useLanguageSelectors } from 'src/app/reducers';

// Components
import { DeviceManagementCard } from './LocationSettingsCard';
import { Icons, Table } from '@ems/client-design-system';
import {
  AddTemplateButton,
  FieldError,
  MaxTemplatesMessage,
} from './DeviceManagementDetails.styles';
import { IDeviceManagementForm, INoiseEventTemplate } from '../../interfaces';
import { defaultNoiseEventTemplateData } from '../helpers/defaults';

interface DeviceTemplateSettingsProps {
  isLoading: boolean;
  isEditing: boolean;
}

export const DeviceTemplateSettings = ({ isLoading, isEditing }: DeviceTemplateSettingsProps) => {
  const MAX_TEMPLATES = 24;
  const { values, setValues, errors } = useFormikContext<IDeviceManagementForm>();
  const languageSelector = useLanguageSelectors();
  const {
    fields: {
      locationManagement: { addTemplate: addTemplateString, maxTemplates: maxTemplatesString },
    },
    components: {
      labels: {
        table: { endTable },
      },
    },
    screens: {
      settings: {
        notFound: {
          table: { noNmtTemplatesFound },
        },
      },
    },
  } = languageSelector.getLanguage();

  const addTemplateAction = () => {
    const clonedTemplates = JSON.parse(JSON.stringify(values.templates)) as INoiseEventTemplate[];
    clonedTemplates.push({ ...defaultNoiseEventTemplateData });
    setValues({
      ...values,
      templates: clonedTemplates,
    });
  };

  const removeTemplateAction = (indexToRemove: number) => {
    setValues({
      ...values,
      templates: values.templates.filter((_template, index) => index !== indexToRemove),
    });
  };

  const { tableRowData, rowHeaderIds, rowHeaders } = useTemplateRowData(
    values,
    isEditing,
    removeTemplateAction
  );

  return (
    <>
      <DeviceManagementCard title="Location templates" isLoading={isLoading}>
        <Table
          loading={isLoading}
          columns={rowHeaderIds}
          rowHeaders={rowHeaders}
          data={tableRowData}
          gridID="locationTemplateTable"
          className={isEditing ? 'locationtemplate__table_edit' : `locationtemplate__table`}
          wrapperClassName={'locationtemplate__table-wrapper'}
          languageData={{
            noDataTitle: noNmtTemplatesFound,
            noDataText: '',
            endTable,
          }}
        />
      </DeviceManagementCard>
      {isEditing && (
        <>
          {values.templates.length < MAX_TEMPLATES ? (
            <>
              <FieldError className="location-settings__field-error">
                {errors.duplicateTemplates || <>&nbsp;</>}
              </FieldError>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <AddTemplateButton onClick={addTemplateAction}>
                  {addTemplateString} <Icons iconName="ic-ui-add-inverted" size={'16px'} />
                </AddTemplateButton>
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <MaxTemplatesMessage>{maxTemplatesString}</MaxTemplatesMessage>
            </div>
          )}
        </>
      )}
    </>
  );
};
