import { useCallback, useMemo } from 'react';

import { toKML } from '@placemarkio/tokml';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import * as turf from '@turf/turf';

const calculatePathDistance = (line: GeoJSON.Feature<GeoJSON.LineString>) =>
  turf.length(line, { units: 'meters' }).toFixed(13);

const calculateIsPathCurved = (line: GeoJSON.Feature<GeoJSON.LineString>) => {
  const pathDistance = Number(calculatePathDistance(line));
  const straightLineDistance = Number(
    calculatePathDistance(
      turf.lineString([
        line.geometry.coordinates[0],
        line.geometry.coordinates[line.geometry.coordinates.length - 1],
      ])
    )
  );

  return +(pathDistance > straightLineDistance);
};

const findConnectedPaths = (
  nodeId: string,
  pathGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString>
) => {
  const pathIds = pathGeoJSON.features
    .filter(
      ({ properties }) => properties.DestNodeId === nodeId || properties.SourceNodeId === nodeId
    )
    .map(({ properties }) => properties.PathwayId);

  return pathIds.join(',');
};

export const useDownloadMapFeatures = () => {
  const {
    state: { nodeGeoJSON, pathGeoJSON },
  } = useTaxiPathContext();

  const formattedPathGeoJson = useMemo(() => {
    const features = [...pathGeoJSON.features];
    return turf.featureCollection([
      ...features.map(feature => ({
        ...feature,
        properties: {
          ...feature.properties,
          PathwayLength: calculatePathDistance(feature),
          Curve: calculateIsPathCurved(feature),
        },
      })),
    ]);
  }, [pathGeoJSON]);

  const formattedNodeGeoJson = useMemo(() => {
    const features = [...nodeGeoJSON.features];
    return turf.featureCollection([
      ...features.map(feature => ({
        ...feature,
        properties: {
          ...feature.properties,
          ConnectedPathways: findConnectedPaths(feature.properties.NodeId, pathGeoJSON),
        },
      })),
    ]);
  }, [pathGeoJSON]);

  const exportToKml = useCallback((fileName: string, type: 'paths' | 'nodes') => {
    const kmlDocument: string = toKML(
      type === 'paths' ? formattedPathGeoJson : formattedNodeGeoJson
    );
    const blob = new Blob([kmlDocument], { type: 'application/vnd.google-earth.kml+xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName || `parsed`}_${type}_kml.kml`;
    a.click();
    URL.revokeObjectURL(url);
  }, []);

  const exportToGeoJson = useCallback((fileName: string, type: 'paths' | 'nodes') => {
    const blob = new Blob(
      [JSON.stringify(type === 'paths' ? formattedPathGeoJson : formattedNodeGeoJson)],
      {
        type: 'application/json',
      }
    );
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName || `parsed`}_${type}_geojson.json`;
    a.click();
    URL.revokeObjectURL(url);
  }, []);

  return { exportToKml, exportToGeoJson };
};
