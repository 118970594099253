import gql from 'graphql-tag';

export const ADD_CORRIDOR = gql`
  mutation addCorridor($corridor: InputCorridor!) {
    addCorridor(corridor: $corridor) {
      id
      name
      isActive
      groupName
      geometry {
        leftPosition {
          latitude
          longitude
        }
        rightPosition {
          latitude
          longitude
        }
        anchorPosition {
          latitude
          longitude
        }
        floorAltitude
        ceilingAltitude
      }
    }
  }
`;

export const UPDATE_CORRIDOR = gql`
  mutation UpdateCorridor($corridor: InputCorridor!) {
    updateCorridor(corridor: $corridor) {
      id
      name
      isActive
      groupName

      geometry {
        leftPosition {
          latitude
          longitude
        }
        rightPosition {
          latitude
          longitude
        }
        anchorPosition {
          latitude
          longitude
        }
        floorAltitude
        ceilingAltitude
      }
    }
  }
`;

export const DELETE_CORRIDOR = gql`
  mutation deleteCorridor($id: Int!) {
    deleteCorridor(id: $Int)
  }
`;
