import { routes } from 'src/app/routes';
import { canAccessRoute } from 'src/app/components/CanAccessRoute';
import { useRolesSelectors, useConfigSelectors } from 'src/app/reducers';

export const useHasRoutePermissions = (targetRoute: string): boolean => {
  const rolesSelectors = useRolesSelectors();
  const configSelectors = useConfigSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();

  // Find first route that end with the targetRoute
  const routeItem = routes.find(({ route }: { route: string }) =>
    new RegExp(`${targetRoute}$`).test(route)
  );
  return (
    routeItem &&
    canAccessRoute(routeItem.permissions, rolesSelectors) &&
    availableRoutes.includes(routeItem.route)
  );
};
