import { useContext } from 'react';
// interfaces
import { IFilterState, IFilterSelectors } from 'src/@airtrak/interfaces';
// actions
import { actionTypes } from 'src/@airtrak/actionTypes';
// providers
import { AirtrakStateContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// helpers
import { useSelectors } from 'src/utils/storeHelpers';
import { storeFilterReducerRow, clearFilterReducerRow } from 'src/utils';
// constants
import { AIRTRAK, PHASES, LTO, IN_BOUND, OUT_BOUND, EMMISIONS } from 'src/constants';
// functions
import { formatFilterItems, translateLabels } from 'src/@airtrak/functions';

export const useFilterSelectors: () => IFilterSelectors = () => {
  const state: any = useContext(AirtrakStateContext);
  const filterState: IFilterState = state.filters;

  return useSelectors(filterState, (state: IFilterState) => ({
    getRegularFilters: () => state.filters,
  }));
};

const initialStateObj: IFilterState = {
  isInitialised: false,
  filters: {
    emissions: [],
    aircraftTypes: [],
    airlines: [],
    regions: [],
    phases: [],
  },
};
export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);
export const filterReducer = (state: IFilterState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_FILTER_ITEMS: {
      const category = action.data.category;
      const convertedDataList = action.data.convertedDataList;
      const shortcutsFilterData = action.data.airtrakFilterData;
      let selectedItems = action.data.selectedItems;
      if (category === PHASES) {
        selectedItems = extractPhaseFilters(selectedItems, convertedDataList, shortcutsFilterData);
      }

      const newState = Object.assign({}, state, {
        filters: {
          ...state.filters,
          [category]: selectedItems,
        },
      });
      storeFilterReducerRow(AIRTRAK, newState.filters);
      return newState;
    }
    case actionTypes.CLEAR_SELECTED_FILTER_ITEMS: {
      clearFilterReducerRow(AIRTRAK);
      return Object.assign({}, initialStateObj, {
        isInitialised: true,
      });
    }
    case actionTypes.SET_DEFAULT_PHASE_FILTERS: {
      if (!state.isInitialised) {
        const defaultPhaseFilters = action.data;
        return Object.assign({}, initialStateObj, {
          isInitialised: true,
          filters: {
            ...state.filters,
            [PHASES]: defaultPhaseFilters,
            [EMMISIONS]: [
              {
                key: 'GHGt',
                label: 'GHGt',
              },
            ],
          },
        });
      }
    }

    default:
      return state;
  }
};

const extractPhaseFilters = (selectedItems, convertedDataList, shortcutsFilterData) => {
  if (selectedItems.some(item => item.key === LTO)) {
    selectedItems = formatFilterItems(shortcutsFilterData.ltoPhases);
    selectedItems = translateLabels(selectedItems, convertedDataList);
  } else if (selectedItems.some(item => item.key === OUT_BOUND)) {
    selectedItems = formatFilterItems(shortcutsFilterData.outBoundPhases);
    selectedItems = translateLabels(selectedItems, convertedDataList);
  } else if (selectedItems.some(item => item.key === IN_BOUND)) {
    selectedItems = formatFilterItems(shortcutsFilterData.inBoundPhases);
    selectedItems = translateLabels(selectedItems, convertedDataList);
  }

  return selectedItems;
};
