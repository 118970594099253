import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import {
  InteractiveLayerId,
  useInteractiveLayerIds,
} from '../../../../../../components/Map/hooks/useInteractiveLayerIds';
import { useBeforeId } from '../../../../../../components/Map/hooks/useBeforeId';
import { EditableNode } from './EditableNode';
import { getQueriedFeaturesById } from 'src/components/Map/helpers/getQueriedFeaturesById';
import { TaxiPathEditMode, useTaxiPathContext } from '../../../context/TaxiPathContext';
import { useAddOnMapClickFunction } from 'src/components/Map/hooks/useAddOnMapClickFunction';
import { Point } from 'geojson';
import { useTaxiRefresher } from '../../../utils';
import { MapboxStyleNodes } from '../../../TaxiPath.styles';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';

interface NodesProps {
  //geojson: NodesGeojson;
  geojson: GeoJSON.FeatureCollection<GeoJSON.Point>;
}

const INTERACTIVE_LAYER_IDS = [InteractiveLayerId.taxiPathNode];

export const Nodes = ({ geojson }: NodesProps) => {
  const { selectedNode, selectedNodeId } = useSelectNode(geojson);
  useInteractiveLayerIds(INTERACTIVE_LAYER_IDS);
  const beforeId = useBeforeId([
    InteractiveLayerId.drawablePoint,
    InteractiveLayerId.drawableLinePoint,
    InteractiveLayerId.drawableLineSegment,
  ]);

  const { refresher } = useTaxiRefresher(geojson);

  return (
    <>
      {refresher && (
        <Source type="geojson" data={geojson} id="node-source" generateId>
          <Layer {...MapboxStyleNodes} beforeId={beforeId as string} />
        </Source>
      )}

      {selectedNodeId && selectedNode && (
        <EditableNode geometry={selectedNode} id={selectedNodeId} />
      )}
    </>
  );
};

const useSelectNode = (geojson: GeoJSON.FeatureCollection<GeoJSON.Point>) => {
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const {
    dispatch,
    state: { currentEditMode, searchedNodeId },
  } = useTaxiPathContext();

  const selectNode = useCallback(
    ({ target, point }: MapLayerMouseEvent) => {
      if (currentEditMode === TaxiPathEditMode.NULL) {
        dispatch({ type: TaxiPathActions.DESELECT_SEARCHED_NODE, payload: {} });
        const nodeFeatures = getQueriedFeaturesById(target, point, InteractiveLayerId.taxiPathNode);
        const editableNodeFeatures = getQueriedFeaturesById(
          target,
          point,
          InteractiveLayerId.editableNode
        );
        const hasFeatures = nodeFeatures?.length || editableNodeFeatures?.length;
        if (!hasFeatures) {
          setSelectedNodeId(null);
          return;
        }
        if (nodeFeatures.length) {
          const nodeId = nodeFeatures[0].properties.NodeId;
          setSelectedNodeId(nodeId && nodeId !== selectedNodeId ? nodeId : null);
        }
      }
    },
    [selectedNodeId, setSelectedNodeId, currentEditMode, dispatch]
  );

  useEffect(() => {
    if (currentEditMode !== TaxiPathEditMode.NULL) {
      setSelectedNodeId(null);
    }
    if (!!searchedNodeId) {
      setSelectedNodeId(String(searchedNodeId));
    }
  }, [currentEditMode, searchedNodeId]);

  const onClick = useMemo(
    () => ({
      id: 'node-click',
      function: selectNode,
    }),
    [selectNode]
  );

  useAddOnMapClickFunction(onClick);

  const selectedNode = useMemo(
    () =>
      geojson.features.find(
        feature =>
          feature.properties.NodeId === selectedNodeId ||
          feature.properties.NodeId === String(searchedNodeId)
      )?.geometry,
    [selectedNodeId, geojson, searchedNodeId]
  ) as Point;

  return { selectedNodeId, selectedNode };
};
