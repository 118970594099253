// Components
import {
  getConvertedDistance,
  getConvertedVerticalDistance,
  ITrackProfileSeriesLineOptions,
  meterToMile,
  meterToKilometer,
  footToMeter,
  meterToNauticalMile,
} from '@ems/client-design-system';

// Functions
import { getOperationTheme } from 'src/utils';
import simplify from 'simplify-js';

// Types
import { IConfigurationUnits } from 'src/app/props';
import { IGetOperationSummariesData } from './interfaces';

// Variables
import { UNIT_KILOMETER, UNIT_METER, UNIT_NAUTICAL_MILE } from 'src/constants';

export const verticalDistance = (distance: number, units: IConfigurationUnits) =>
  getConvertedVerticalDistance(distance, units.distanceVertical, 0);

export const horizontalDistance = (distance: number, units: IConfigurationUnits) =>
  getConvertedDistance(distance, units.distance, -1);

const MAX_DISTANCE = 50000;

export const formatForGraph = ({
  data,
  units,
  selectedTrackTheme,
  tolerance,
}: {
  data: IGetOperationSummariesData;
  units: IConfigurationUnits;
  selectedTrackTheme: string;
  tolerance: number;
}): ITrackProfileSeriesLineOptions[] => {
  const colorList = getOperationTheme(selectedTrackTheme);
  const isDistanceMetric = units.distance === UNIT_METER || units.distance === UNIT_KILOMETER;
  const isVerticalDistanceMetric = units.distanceVertical === UNIT_METER;
  const isDistanceNautical = units.distance === UNIT_NAUTICAL_MILE;

  // Horizontal distance always returned from API as 'm'
  // Vertical distance always returned from API as 'ft'
  const list = data.operationSummariesByIds.map(({ acid, profile, operationType, startTime }) => {
    const data = profile
      .filter(profile => profile.dist < MAX_DISTANCE)
      .map(({ dist, alt, time }) => {
        // Yaxis (horizontal distace) to always be larger unit (km, mi or nm)
        const distance = isDistanceMetric
          ? meterToKilometer(dist)
          : isDistanceNautical
          ? meterToNauticalMile(dist)
          : meterToMile(dist);
        // Xaxis (vertical distance) to always be smaller unit (m or ft)
        const verticalDistance = isVerticalDistanceMetric ? footToMeter(alt) : alt;

        return {
          x: distance,
          y: verticalDistance,
          t: time,
          startTime,
        };
      });

    return {
      name: acid,
      color: colorList[operationType.toLowerCase().replace(/\s+/g, '')],
      type: 'line',
      data: simplify(data, tolerance, true),
    } as ITrackProfileSeriesLineOptions;
  });
  return list;
};
