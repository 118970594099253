// utils
import { alert } from 'src/utils';

export const fetchFilterData = (path: string, fileName: string, appFilters) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.PUBLIC_URL}/data/filters/${path}/${fileName}.json`)
      .then(response => response.json())
      .then(data => {
        appFilters[path] = data;
        resolve(data);
      })
      .catch(error => {
        console.log(
          `\t fetchFilterData ERROR: using ${process.env.PUBLIC_URL}/data/filters/${path}/default.json`
        );
        fetch(`${process.env.PUBLIC_URL}/data/filters/${path}/default.json`)
          .then(response => response.json())
          .then(data => {
            appFilters[path] = data;
            console.error(
              `Error: Failed to load "${path}/${fileName}" filters. Default filters loaded instead`
            );
            alert('failedToLoadFilters');
            resolve(data);
          })
          .catch(error => {
            console.error(`Error: Failed to load default ${path} filters.`);
            alert('failedToLoadFilters');
            reject();
          });
      });
  });
};
