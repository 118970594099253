import { Icons, SkeletonText } from '@ems/client-design-system';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ISelectFlightHeader } from 'src/@complaints/interfaces';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { PageHeaderDetails } from 'src/components';
import { DATE_TIME_FORMAT } from 'src/constants';

export const HeaderContainer: FC<ISelectFlightHeader> = ({ time, id, isEditing }) => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      complaints: {
        addFlight: { addFlightTitle, editFlightTitle, addFlightGoBack },
      },
    },
  } = languageSelectors.getLanguage();

  const configSelectors = useConfigSelectors();
  const {
    globals: { timeZone },
  } = configSelectors.getConfig();

  const subtitle = (
    <SkeletonText loading={!time} width="15rem">
      {time
        ? DateTime.fromISO(time)
            .setZone(timeZone)
            .toFormat(DATE_TIME_FORMAT.LARGE_DATE_DISPLAY)
        : ''}
    </SkeletonText>
  );

  const routerHistory = useHistory();
  const backToInquiries = () => {
    routerHistory.goBack();
  };

  const backButton = (
    <div className="return-to-button" onClick={backToInquiries}>
      <Icons size={20} iconName="ic-ui-chevron-left" />
      <span>{addFlightGoBack}</span>
    </div>
  );

  // TODO: Add date when data is available for it
  return (
    <PageHeaderDetails
      title={isEditing ? editFlightTitle : addFlightTitle}
      subtitle={subtitle}
      backButton={backButton}
    />
  );
};
