import styled from 'styled-components/macro';
import styles from 'src/styles/vars.module.scss';

export const createScenarioForm = styled.form``;

export const CreateScenarioFormRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const ScenarioFormInput = styled.div`
  display: flex;
  flex: 1;
  max-width: 250px;
  margin-right: 1rem;
  flex-direction: column;

  .bp3-button.icon.icon-right,
  .bp3-button.mosaic-button.icon.icon-right {
    padding: 0;
  }

  .range-selector-error {
    display: none;
  }

  .range-selector-container {
    padding: 0;

    .infdetailspanel-label {
      margin-bottom: 0.5rem;
      font-size: 14px;
      line-height: 1.43;
    }

    .range-selector-to {
      margin-top: 28px;
    }

    .time.text-input_container {
      margin: 0 1rem;
    }
    div:first-of-type .time.text-input_container {
      margin-left: 0;
    }
    div:nth-of-type(3) .infdetailspanel-label {
      margin-left: 1.5rem;
    }
  }

  &.scenarioGeneration__pathNames {
    .bp3-popover-wrapper.mosaic-popover {
      max-width: 200px;
      border: 1px solid #ccc;
      border-radius: 3px;
      .filter-category-button {
        padding: 0px 10px 0 14px;
      }
      .filter-category-button,
      .bp3-popover-target {
        width: 100%;
      }
    }
  }
`;

export const ScenarioFormLabel = styled.label`
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.43;
`;

export const ScenarioFormError = styled.p`
  color: ${styles.error01};
  font-size: 14px;
`;

export const ScenarioFormSettingsPeriod = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

export const ScenarioSettingInput = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px;

  label {
    width: 70px;
    color: ${styles.ui05};
    text-transform: capitalize;
  }
  .timeinput-container {
    display: flex;
    justify-content: center;
    margin-right: 6px;
    .text-input_field {
      width: 78px;
    }
    .timeinput-error {
      display: none;
    }
  }
`;
