// Theme
import themeToken from 'src/styles/themes.json';
// Constants
import { THEME_DEFAULT_OPERATIONS, THEME_DEFAULT_COLOR } from 'src/constants';
import { useConfigSelectors } from 'src/app/reducers';

export const useOperationTheme = (): Record<string, string> => {
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  if (themeToken && themeToken.operations && themeToken.operations[selectedTrackTheme]) {
    return themeToken.operations[selectedTrackTheme];
  } else {
    return THEME_DEFAULT_OPERATIONS;
  }
};

// TODO - replace getOperationTheme function with useOperationTheme hook in CF
export const getOperationTheme = (selectedTrackTheme: string): { [key: string]: string } => {
  if (themeToken && themeToken.operations && themeToken.operations[selectedTrackTheme]) {
    return themeToken.operations[selectedTrackTheme];
  } else {
    return THEME_DEFAULT_OPERATIONS;
  }
};

export const getOperationTypeColor = (
  selectedTrackTheme: string,
  operationType: string
): string => {
  if (
    themeToken &&
    themeToken.operations &&
    themeToken.operations[selectedTrackTheme] &&
    themeToken.operations[selectedTrackTheme][operationType.toLowerCase()]
  ) {
    return themeToken.operations[selectedTrackTheme][operationType.toLowerCase()];
  } else {
    return THEME_DEFAULT_COLOR;
  }
};

export const getDefaultStyleColor = (type: string): string => {
  if (themeToken && themeToken.default) {
    return themeToken.default[type];
  } else {
    return THEME_DEFAULT_COLOR;
  }
};
