import styled from 'styled-components/macro';

// Components
import { Checkbox } from '@ems/client-design-system';

export const DialogCheckboxGroup = styled.div`
  margin-top: 1.5rem;
`;

export const DialogCheckbox = styled(Checkbox)`
  margin-bottom: 0;
`;
