import React, { FC, useContext } from 'react';
import cx from 'classnames';
// actions
import { updateView } from 'src/@airtrak/actions';
// components
import { Button, Icons } from '@ems/client-design-system';
// providers
import { AirtrakDispatchContext } from 'src/@airtrak/providers/AirtrakStateProvider';
// selectors
import { useLanguageSelectors } from 'src/app/reducers';
import { useDataSelectors, useViewSelector } from 'src/@airtrak/reducers';
import { TMA_EFFICIENCY } from 'src/constants';

export const ViewContainer: FC = () => {
  const dispatcher = useContext(AirtrakDispatchContext);
  const viewSelector = useViewSelector();
  const view = viewSelector.getView();
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const dataSelectors = useDataSelectors();
  const metric = dataSelectors.getMetric();

  const {
    components: {
      labels: { views },
    },
  } = translationData;

  const getButton = (name: string, iconName: string, isDisabled: boolean, index: number) => (
    <Button
      size="s"
      style="subtle"
      leftIcon={
        <Icons iconName={iconName} size="14" fill={view === name ? '#FFFFFF' : '#2e384d'} />
      }
      className={cx({ 'view-button': true, 'active-button': view === name })}
      onClick={setViewOnClick}
      aria-label={`${name}-view`}
      name={name}
      disabled={isDisabled}
      key={index}
    />
  );
  const setViewOnClick = (e: any) => {
    updateView(e.currentTarget.name, dispatcher);
  };
  const getViewButtons = () => {
    const emissionViews = [
      { name: 'line', iconName: 'ic-ui-line-graph', disabled: false },
      { name: 'bar', iconName: 'ic-ui-bar-graph', disabled: false },
      { name: 'pie', iconName: 'ic-ui-pie-graph', disabled: false },
      { name: 'table', iconName: 'ic-ui-table', disabled: false },
    ];
    const tmaViews = [
      { name: 'line', iconName: 'ic-ui-line-graph', disabled: false },
      { name: 'bar', iconName: 'ic-ui-bar-graph', disabled: true },
      { name: 'pie', iconName: 'ic-ui-pie-graph', disabled: true },
      { name: 'table', iconName: 'ic-ui-table', disabled: false },
    ];
    const views = metric === TMA_EFFICIENCY ? tmaViews : emissionViews;
    return views.map((each, index) => getButton(each.name, each.iconName, each.disabled, index));
  };
  const viewButtons = getViewButtons();
  return (
    <div className='view-container'>
      <span className='airtrak-breakdown-title'>{views}</span>
      <div>{viewButtons}</div>
    </div>
  );
};
