import WebMercatorViewport from 'viewport-mercator-project';
import { vertexShaderSource, fragmentShaderSource } from './TrackDensityShaders';

export const TrackDensityLayer = ({
  mapApis,
  viewport,
  trackDensityImageBase64,
  trackDensityData,
}) => {
  const mercatorViewport = new WebMercatorViewport(viewport);
  const layer: any = {
    id: 'TrackDensity',
    type: 'custom',
    source: 'lhrx_track_density_reference',

    onAdd: function(map, gl) {
      const vertexSource = vertexShaderSource;
      const fragmentSource = fragmentShaderSource.replace(
        '{{trackDensityMaxCellCount}}',
        trackDensityData.maxOperationCellCount
      );

      // create a vertex shader
      const vertexShader = gl.createShader(gl.VERTEX_SHADER);
      gl.shaderSource(vertexShader, vertexSource);
      gl.compileShader(vertexShader);

      // create a fragment shader
      const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
      gl.shaderSource(fragmentShader, fragmentSource);
      gl.compileShader(fragmentShader);

      // link the two shaders into a WebGL program
      this.program = gl.createProgram();
      gl.attachShader(this.program, vertexShader);
      gl.attachShader(this.program, fragmentShader);
      gl.linkProgram(this.program);

      this.aPosition = gl.getAttribLocation(this.program, 'a_position');
      this.aTexture = gl.getAttribLocation(this.program, 'a_texture');

      this.uTexture = gl.getUniformLocation(this.program, 'u_texture');
      this.uMatrix = gl.getUniformLocation(this.program, 'u_matrix');

      const topLeft = mercatorViewport.projectFlat(trackDensityData.topLeft, 1 / 512);
      const topRight = mercatorViewport.projectFlat(trackDensityData.topRight, 1 / 512);
      const bottomRight = mercatorViewport.projectFlat(trackDensityData.bottomRight, 1 / 512);
      const bottomLeft = mercatorViewport.projectFlat(trackDensityData.bottomLeft, 1 / 512);
      // create and initialize a WebGLBuffer to store vertex data
      const bufferDataArray = [
        topLeft[0],
        topLeft[1],
        topRight[0],
        topRight[1],
        bottomRight[0],
        bottomRight[1],
        bottomRight[0],
        bottomRight[1],
        bottomLeft[0],
        bottomLeft[1],
        topLeft[0],
        topLeft[1],
      ];
      this.positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(bufferDataArray), gl.STATIC_DRAW);
      gl.bindBuffer(gl.ARRAY_BUFFER, null);

      // And create a buffer to store texture coordinates for
      // the points above.
      this.texCoordBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, this.texCoordBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array([0, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0, 1]),
        gl.STATIC_DRAW
      );
      gl.bindBuffer(gl.ARRAY_BUFFER, null);

      this.texture = gl.createTexture();

      const rgba = true;

      if (rgba) {
        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          2,
          2,
          0,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          new Uint8Array([0, 0, 255, 255, 255, 0, 0, 255, 0, 255, 0, 255, 255, 0, 255, 255])
        );

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.bindTexture(gl.TEXTURE_2D, null);

        var rgbTexture = this.texture;

        const image = new Image();
        image.onload = function() {
          gl.bindTexture(gl.TEXTURE_2D, rgbTexture);
          gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
          gl.bindTexture(gl.TEXTURE_2D, null);
        };
        image.src = trackDensityImageBase64;
      }
    },

    // method fired on each animation frame
    // https://docs.mapbox.com/mapbox-gl-js/api/#map.event:render
    render: function(gl, matrix) {
      gl.bindTexture(gl.TEXTURE_2D, this.texture);
      gl.useProgram(this.program);
      gl.uniformMatrix4fv(this.uMatrix, false, matrix);

      // Positions
      gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer);
      gl.enableVertexAttribArray(this.aPosition);
      gl.vertexAttribPointer(this.aPosition, 2, gl.FLOAT, false, 0, 0);

      // Tex coords
      gl.bindBuffer(gl.ARRAY_BUFFER, this.texCoordBuffer);
      gl.enableVertexAttribArray(this.aTexture);
      gl.vertexAttribPointer(this.aTexture, 2, gl.FLOAT, false, 0, 0);

      // Indexes
      //gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

      gl.uniform1i(this.uTexture, 0);

      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
      gl.drawArrays(gl.TRIANGLES, 0, 6);
      //gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
    },
  };
  return layer;
};
