import { TRACK_COLORS_ALTITUDE } from 'src/app/featureToggles';
import { useConfigSelectors } from 'src/app/reducers';
import { useIsDynamicTileServer } from './useIsDynamicTileServer';

export const useIsColorByAltitudeEnabled = () => {
  const configSelectors = useConfigSelectors();
  const isDynamic = useIsDynamicTileServer();
  const isColorByAltitudeEnabled =
    isDynamic && configSelectors.isFeatureAvailable(TRACK_COLORS_ALTITUDE);
  return isColorByAltitudeEnabled;
};
