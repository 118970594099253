import React, { FC } from 'react';
import { withQueryStringUpdater } from 'src/app/hocs/withQueryStringUpdater';
import { useLanguageSelectors } from 'src/app/reducers';
import { PageHeader, DateFilter } from 'src/components';
import { IHeaderContainer } from 'src/@reports/interfaces';
import { setTabTitle } from 'src/utils';

export const HeaderContainer: FC<IHeaderContainer> = ({ updateUrl }) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      reports: { title },
    },
  } = languageSelectors.getLanguage();

  const DateFilterHOC = withQueryStringUpdater({ Container: DateFilter, updateUrl });

  setTabTitle(title);

  return (
    <PageHeader title={title}>
      <DateFilterHOC />
    </PageHeader>
  );
};
