import React, { useState, Dispatch, SetStateAction, useRef, useEffect } from 'react';

// Apollo
import { useQuery } from '@apollo/react-hooks';
import { GET_OPERATION_SUMMARIES } from 'src/@operations/components/dialogs/TrackProfile/queries';

// Selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

// Components
import { formatForGraph } from './helpers';
import {
  Dialog,
  Button,
  OperationsTrackProfileGraph,
  ITrackProfileSeriesLineOptions,
} from '@ems/client-design-system';
import {
  TrackProfileDialogWrapper,
  GraphLoading,
  GraphText,
  GraphExportLink,
  GraphWrapper,
  GraphTitle,
} from './TrackProfileDialog.styles';

// Functions
import { takeScreenshotFromGraph } from 'src/utils';

// Types
import {
  IGetOperationSummariesVariables,
  IGetOperationSummariesData,
} from 'src/@operations/components/dialogs/TrackProfile/interfaces';

export const TrackProfileDialog = ({
  selectedOperationIds,
  isOpen,
  setIsOpen,
}: {
  selectedOperationIds: number[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  // Units
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  // Theme
  const selectedTrackTheme = configSelectors.getTheme('operations');
  // Translations
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      overlay: {
        graph: {
          operations: { title: graphOverlayTitle },
        },
      },
    },
  } = languageSelectors.getLanguage();

  const operationCount = selectedOperationIds.length;

  // States
  const [graphData, setGraphData] = useState<ITrackProfileSeriesLineOptions[]>();
  const [pointTolerance, setPointTolerance] = useState<number>(50);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [exportBase64String, setExportBase64String] = useState<string | null>(null);
  const graphRef = useRef<HTMLDivElement>();
  const imageLinkRef = useRef<HTMLAnchorElement>(null);

  // Actions
  const handleGraphExport = (base64Image: string) => {
    setIsExportLoading(false);
    setExportBase64String(base64Image);
  };

  useEffect(() => {
    if (imageLinkRef && imageLinkRef.current && exportBase64String) {
      imageLinkRef.current.click();
    }
    setExportBase64String(null);
  }, [exportBase64String]);

  // Queries
  const { loading, data } = useQuery<IGetOperationSummariesData, IGetOperationSummariesVariables>(
    GET_OPERATION_SUMMARIES,
    {
      variables: {
        // Only request the first 30 items
        ids: selectedOperationIds.slice(0, operationCount),
      },
    }
  );

  useEffect(() => {
    if (data && data.operationSummariesByIds) {
      setGraphData(
        formatForGraph({
          data,
          units,
          selectedTrackTheme,
          tolerance: pointTolerance,
        })
      );
    }
  }, [pointTolerance, data]);

  useEffect(() => {
    const trackCount = Math.min(operationCount, selectedOperationIds.length);

    if (trackCount < 100) {
      setPointTolerance(0);
    } else if (trackCount >= 100 && trackCount < 150) {
      setPointTolerance(5);
    } else if (trackCount >= 150 && trackCount < 200) {
      setPointTolerance(10);
    } else if (trackCount >= 200 && trackCount < 250) {
      setPointTolerance(15);
    } else {
      setPointTolerance(20);
    }
  }, [operationCount]);

  const DialogTitle = () => {
    if (data && data.operationSummariesByIds && data.operationSummariesByIds.length === 1) {
      return <GraphTitle>{`${data.operationSummariesByIds[0].acid} track profile`}</GraphTitle>;
    }
    return <GraphTitle>{graphOverlayTitle}</GraphTitle>;
  };

  return (
    <TrackProfileDialogWrapper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={<DialogTitle />}
      isCloseButtonShown>
      <Dialog.Body>
        <GraphLoading loading={loading} size="l">
          <div ref={graphRef}>
            {pointTolerance > 0 && <GraphText>Displaying tracks with reduced complexity</GraphText>}
            <GraphWrapper>
              <OperationsTrackProfileGraph data={graphData} units={units} />
            </GraphWrapper>
          </div>
        </GraphLoading>
        <GraphExportLink
          aria-hidden="true"
          download={`graph-image.jpg`}
          ref={imageLinkRef}
          href={`data:application/octet-stream;base64,${exportBase64String}`}>
          Download link
        </GraphExportLink>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.FooterActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}>
            Close
          </Button>
          <Button
            style="primary"
            disabled={isExportLoading || loading}
            loading={isExportLoading}
            onClick={() => {
              setIsExportLoading(true);
              // html2canvas causes lag so need to lock out buttons before starting
              setTimeout(function() {
                takeScreenshotFromGraph({
                  captureRef: graphRef.current,
                })
                  .then(base64Image => {
                    handleGraphExport(base64Image);
                  })
                  .catch(error => {
                    console.warn(error);
                    setIsExportLoading(false);
                  });
              }, 500);
            }}>
            Export
          </Button>
        </Dialog.FooterActions>
      </Dialog.Footer>
    </TrackProfileDialogWrapper>
  );
};
