/*
 * Parse query string params from location.search
 * We need to do this because React Router V4 removed
 * this functionality
 */

export const parseQueryString = (path: string): any => {
  const query = {};
  const pairs = (path[0] === '?' ? path.substr(1) : path).split('&');
  for (const item of pairs) {
    const pair = item.split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};
