import React, { useState, useEffect } from 'react';

// Components
import { Spinner, Dialog, Icons, Button } from '@ems/client-design-system';

// Styled
import {
  ScenarioModalSpinnerWrapper,
  ScenarioModalSpinnerHeading,
} from 'src/@scenarioGeneration/containers/CreateScenario/CreateScenario.styles';
import { NoiseScenarioDialog } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/Dialog.styles';

// Types
import { IDialogTranslations } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

export const GenerateScenarioDialog = ({
  isLoading,
  scenarioStatus,
  setScenarioStatus,
  dialogTranslations,
}: {
  isLoading: boolean;
  scenarioStatus: string;
  setScenarioStatus: React.Dispatch<React.SetStateAction<string>>;
  dialogTranslations: IDialogTranslations;
}) => {
  // States
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading || !!scenarioStatus) {
      setIsDialogOpen(true);
    }
  }, [isLoading, scenarioStatus]);

  let headingText = dialogTranslations.generateNoiseScenarioProgress;

  if (scenarioStatus === 'Completed') {
    headingText = dialogTranslations.generateNoiseScenarioCompleted;
  } else if (scenarioStatus === 'Error') {
    headingText = dialogTranslations.generateNoiseScenarioError;
  }

  let dialogIcon = <Spinner loading={true} size="xl" />;
  if (scenarioStatus === 'Completed') {
    dialogIcon = <Icons iconName="ic-ui-tick-01" size="xl" />;
  } else if (scenarioStatus === 'Error') {
    dialogIcon = <Icons iconName="ic-ui-cancel" size="xl" />;
  }

  return (
    <NoiseScenarioDialog isOpen={isDialogOpen}>
      <Dialog.Body>
        <ScenarioModalSpinnerWrapper>
          <ScenarioModalSpinnerHeading>{headingText}</ScenarioModalSpinnerHeading>
          {dialogIcon}
        </ScenarioModalSpinnerWrapper>
      </Dialog.Body>
      {scenarioStatus === 'Error' && (
        <Dialog.Footer>
          <Dialog.FooterActions>
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                setScenarioStatus(null);
              }}>
              Close
            </Button>
          </Dialog.FooterActions>
        </Dialog.Footer>
      )}
    </NoiseScenarioDialog>
  );
};
