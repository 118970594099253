import React, { FC } from 'react';
import classNames from 'classnames';
import { Button, Icons } from '@ems/client-design-system';
import { overflowTextModifier } from 'src/utils';

export interface IColumnHeader {
  titleIcon?: any;
  sortName: string;
  resultSize: number;
  isLoading: boolean;
  dispatcher: any;
  sortable?: boolean;
  sortSelectors: any;
  sortTable: (data: any, newDispatcher: any) => void;
  content?: any;
  languageData: {
    title: string;
    sortBy: string;
    abbreviation: null | string;
  };
  showSortIcon?: boolean;
  isSettingsTable?: boolean;
  moduleName?: string;
  titleElement?: JSX.Element;
}
// TODO - replace column header with table column header - works fine for non settings headers but settings sorting needs to be uplifted
export const ColumnHeader: FC<IColumnHeader> = ({
  titleIcon,
  sortName,
  resultSize,
  isLoading,
  dispatcher,
  sortTable,
  sortSelectors,
  sortable = true,
  content,
  languageData,
  showSortIcon = true,
  isSettingsTable = false,
  moduleName,
  titleElement,
}) => {
  const sortObject = isSettingsTable
    ? sortSelectors.getIconProps(sortName, moduleName)
    : sortSelectors.getIconProps(sortName);
  let iconClass = '';
  let iconName = '';
  const { title: titleText, sortBy, abbreviation } = languageData;
  const title = titleIcon !== undefined ? titleIcon : titleElement || titleText;

  const isSortable = !sortable ? 'sort-header--static' : '';
  const hasAbbreviation = abbreviation ? 'has-abbreviation' : '';
  const loading = isLoading ? 'sort-header--loading' : '';
  const className = classNames('sort-header', loading, isSortable, hasAbbreviation);

  const columnContent = abbreviation ? (
    overflowTextModifier(title, abbreviation)
  ) : (
    <span className="grid-cell--title">{title}</span>
  );

  if (content && !sortable) {
    return content;
  }

  if (showSortIcon && sortObject) {
    iconClass = 'sort-icon';
    iconName = sortObject.direction === 'DESC' ? 'ic-ui-caret-up' : 'ic-ui-caret-down';
  } else if (showSortIcon) {
    iconClass = 'sort-icon hover-only';
    iconName = 'ic-ui-caret-down';
  }
  if (!sortable) {
    return <span className={className}>{content ? content : columnContent}</span>;
  }

  return (
    <>
      <Button
        style="subtle"
        rightIcon={<Icons iconName={iconName} size="20" className={iconClass} />}
        className={className}
        disabled={isLoading}
        aria-label={`${sortBy} ${titleText}`}
        onClick={
          isLoading
            ? undefined
            : () => {
                if (!isLoading && sortable) {
                  sortTable({ sortName, resultSize }, dispatcher);
                }
              }
        }>
        {columnContent}
      </Button>
    </>
  );
};
