import React, { useState, useContext, useEffect } from 'react';
// reducers
import {
  useLanguageSelectors,
  useScenariosSelectors,
  useRolesSelectors,
  useConfigSelectors,
} from 'src/app/reducers';
// components
import { SettingsContentWrapper } from 'src/@settings/components';
import { Button, displaySuccess, Spinner, displayError } from '@ems/client-design-system';
import {
  AirtrakSettingsContainer,
  AircraftMappingsContainer,
  FleetMixContainer,
  TimeInModesContainer,
  LoadFactorsContainer,
  FuelTypesContainer,
} from 'src/@settings/containers';
// hooks
import { useMutation, useApolloClient } from '@apollo/react-hooks';
// provider
import { SettingsDispatchContext } from 'src/@settings/provider/SettingsStateProvider';
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
// mutations
import {
  UPDATE_SCENARIOS,
  UPDATE_AIRCRAFT_FUEL_MAPPINGS,
  UPDATE_AIRLINE_MAPPING,
  UPDATE_LOAD_FACTORS,
  UPDATE_AIRCRAFT_MAPPINGS,
  UPDATE_PHASE_DEFAULTS,
  CARBON_EMISSIONS_REQUEST_REPROCESSING,
} from 'src/@settings/mutations';
// actionTypes
import { actionTypes } from 'src/@settings/actionTypes';
import { appActionTypes } from 'src/app/actionTypes';
// actions
import { loadAirtrakScenarios } from 'src/app/actions';
import {
  getAircraftFlights,
  getAircraftFuelMappings,
  getAircraftMappings,
  getAirlines,
  getAirportRunways,
  getEnrouteMappings,
  getLoadFactors,
  getLTOMappings,
  getTimeInModes,
} from 'src/@settings/actions';
import { IMutationItem } from '../interfaces';
import { IScenario, IRouteItem } from 'src/app/props';

import { routes } from 'src/app/routes';
import { canAccessRoute } from 'src/app/components/CanAccessRoute';
import { useFleetMixSelector } from '../reducers';

export const CarbonSettingsContainer = ({ route }: { route: string }) => {
  // dispatcher
  const dispatcher: any = useContext(SettingsDispatchContext);
  const appDispatcher: any = useContext(GlobalDispatchContext);
  // client
  const client = useApolloClient();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const scenariosSelector = useScenariosSelectors();
  const rolesSelectors = useRolesSelectors();
  const activeScenario: IScenario = scenariosSelector.getActiveScenario();
  const hasScenariosFetched = scenariosSelector.hasScenariosFetched();
  const loading = scenariosSelector.getIfLoading();
  const configSelectors = useConfigSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();

  const {
    screens: {
      settings: { tabs },
    },
    components: {
      hints: { areYouSureYouWantToLeave },
      buttons: { saveChanges, discardChanges },
      labels: {
        fleetMixUpdateSuccessMessage,
        loadFactorUpdateSuccessMessage,
        fuelTypesUpdateSuccessMessage,
        scenarioUpdateSuccessMessage,
        aircraftMappingUpdateSuccessMessage,
        timeInModesUpdateSuccessMessage,
      },
    },
  } = languageSelectors.getLanguage();
  tabs.general = 'General';
  const [selectedSubModule, setSelectedSubModule] = useState<string | null>(tabs.general);
  const [changesAvailable, setChangesAvailable] = useState<boolean>(false);
  const [areChangesDiscarded, setAreChangesDiscarded] = useState<boolean>(false);
  const [discardMutationData, setDiscardMutationData] = useState<boolean>(false);
  const [isSubModuleNeedingChange, setIsSubModuleNeedingChange] = useState(false);
  const [previousSelectedSubModule] = useState<string | null>(null);
  //const [isSelectedItemCancelled, setIsSelectedItemCancelled] = useState(false);

  // Air Quality Administrator Role is the only one that has permission for this screen
  const carbonRoute = routes.find((route: IRouteItem) => route.route === 'carbonemissions');
  const hasCarbonRoutePermission =
    carbonRoute &&
    canAccessRoute(carbonRoute.permissions, rolesSelectors) &&
    availableRoutes.includes(carbonRoute.route);

  const settings = {
    Airtrak: {
      isWeatherApplied: false,
      tmaDistance: 0,
      activeConfig: null,
      isWeatherAppliedChanged: false,
      isTmaDistanceChanged: false,
      isActiveConfigChanged: false,
      airlineMappingMutationData: [],
      aircraftMappingsMutationData: [],
      runwaysMutationData: [],
      loadFactorsMutationData: [],
      fuelTypesMutationData: [],
      scenarioMutationData: [],
    },
  };
  const [settingsData, setSettingsData] = useState(settings);

  useEffect(() => {
    if (hasCarbonRoutePermission) {
      loadAirtrakScenarios(client, appDispatcher);
      getLTOMappings(client, dispatcher);
      getEnrouteMappings(client, dispatcher);
    }
  }, []);

  const loadCarbonEmissionsSettingsData = () => {
    if (activeScenario && activeScenario.id) {
      switch (route) {
        case 'fleet-mix':
          getAirlines(client, dispatcher, activeScenario);
          getAircraftMappings(client, dispatcher, [activeScenario.id]);
          break;
        case 'aircraft-mappings':
          getAircraftMappings(client, dispatcher, [activeScenario.id]);
          break;
        case 'time-modes':
          getTimeInModes(client, dispatcher, [activeScenario.id]);
          break;
        case 'load-factors':
          getLoadFactors(client, dispatcher, [activeScenario.id]);
          break;
        case 'fuel-types':
          getAircraftFuelMappings(client, dispatcher, [activeScenario.id]);
          break;
        default:
      }
    }
  };

  const fleetMix = useFleetMixSelector();
  const fleetMixFlights = fleetMix.getAirlines();
  useEffect(() => {
    if (fleetMixFlights.length) {
      loadCarbonEmissionsSettingsData();
    }
  }, [fleetMixFlights]);

  useEffect(() => {
    if (hasCarbonRoutePermission && activeScenario && activeScenario.id) {
      getAircraftFlights(client, dispatcher, activeScenario.startTime, activeScenario.endTime);
      getAirportRunways(client, dispatcher);
    }
  }, [activeScenario]);

  const updateAirlineMappingsMutationData = mutationData => {
    settingsData.Airtrak.airlineMappingMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const updateAicraftMappingsMutationData = mutationData => {
    settingsData.Airtrak.aircraftMappingsMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const updateLoadFactorsMutationData = mutationData => {
    settingsData.Airtrak.loadFactorsMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const updateFuelTypesMutationData = mutationData => {
    settingsData.Airtrak.fuelTypesMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const updateScenarioMutationData = mutationData => {
    settingsData.Airtrak.scenarioMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const updateRunwaysMutationData = mutationData => {
    settingsData.Airtrak.runwaysMutationData = mutationData;
    setSettingsData(settingsData);
  };

  const onDiscardClick = () => {
    setChangesAvailable(false);
    setSettingsData(settings);
    setAreChangesDiscarded(!areChangesDiscarded);
  };

  const [updateFleetMix] = useMutation(UPDATE_AIRLINE_MAPPING, {
    update() {
      displaySuccess({
        message: fleetMixUpdateSuccessMessage,
      });
    },
  });

  const [updateAirlineLoadFactor] = useMutation(UPDATE_LOAD_FACTORS, {
    update() {
      displaySuccess({
        message: loadFactorUpdateSuccessMessage,
      });
    },
  });

  const [updateAircraftMappings] = useMutation(UPDATE_AIRCRAFT_MAPPINGS, {
    update() {
      displaySuccess({
        message: aircraftMappingUpdateSuccessMessage,
      });
    },
  });

  const [updateAircraftFuelMappings] = useMutation(UPDATE_AIRCRAFT_FUEL_MAPPINGS, {
    update() {
      displaySuccess({
        message: fuelTypesUpdateSuccessMessage,
      });
    },
  });

  const [updatePhaseDefaults] = useMutation(UPDATE_PHASE_DEFAULTS, {
    update() {
      displaySuccess({
        message: timeInModesUpdateSuccessMessage,
      });
    },
  });

  const [updateScenarios] = useMutation(UPDATE_SCENARIOS, {
    update() {
      displaySuccess({
        message: scenarioUpdateSuccessMessage,
      });
    },
  });

  const [raiseCarbonEmissionReprocessRequest] = useMutation(
    CARBON_EMISSIONS_REQUEST_REPROCESSING,
    {}
  );

  const raiseRecalculationRequest = () => {
    raiseCarbonEmissionReprocessRequest({
      variables: {
        scenarioId: activeScenario.id,
        startTime: activeScenario.startTime,
        endTime: activeScenario.endTime,
      },
    }).catch(error => {
      console.error(error);
    });
  };

  const isValidMutationPayload = (mutationData: IMutationItem[]) => {
    let message = '';
    let isValid = true;
    mutationData.map((each: IMutationItem) => {
      for (const [key, value] of Object.entries(each.record)) {
        if (value === null || value === undefined || value === '' || value === '_______') {
          message = `${key} cannot be null or empty`;
          isValid = false;
          break;
        }
      }
      if (!isValid) {
        displayError({
          message,
          timeout: 3000,
        });
      }
    });
    return isValid;
  };

  const onSaveClick = () => {
    if (settingsData.Airtrak.aircraftMappingsMutationData.length > 0) {
      updateAircraftMappings({
        variables: {
          aircraftMappings: settingsData.Airtrak.aircraftMappingsMutationData,
          scenarioId: activeScenario.id,
          startTime: activeScenario.startTime,
          endTime: activeScenario.endTime,
        },
      })
        .then(({ data }) => {
          dispatcher({
            type: actionTypes.UPDATE_AIRCRAFT_MAPPINGS,
            aircraftMappingsData: data.updateAircraftMappings,
          });
          setSettingsData(settings);
          setChangesAvailable(false);
          setDiscardMutationData(!discardMutationData);
        })
        .catch(error => {
          console.error(error);
        });
    } else if (settingsData.Airtrak.airlineMappingMutationData.length > 0) {
      updateFleetMix({
        variables: {
          airlineMappings: settingsData.Airtrak.airlineMappingMutationData,
          scenarioId: activeScenario.id,
          startTime: activeScenario.startTime,
          endTime: activeScenario.endTime,
        },
      })
        .then(({ data }) => {
          dispatcher({
            type: actionTypes.UPDATE_AIRLINE_MAPPINGS,
            fleetMixData: data.updateAirlineMappings,
          });
          setSettingsData(settings);
          setChangesAvailable(false);
          setDiscardMutationData(!discardMutationData);
        })
        .catch(error => {
          console.error(error);
        });
    } else if (settingsData.Airtrak.loadFactorsMutationData.length > 0) {
      if (isValidMutationPayload(settingsData.Airtrak.loadFactorsMutationData)) {
        updateAirlineLoadFactor({
          variables: {
            airlineLoadFactor: settingsData.Airtrak.loadFactorsMutationData,
            scenarioId: activeScenario.id,
            startTime: activeScenario.startTime,
            endTime: activeScenario.endTime,
          },
        })
          .then(({ data }) => {
            dispatcher({
              type: actionTypes.GET_LOAD_FACTORS,
              loadFactorsData: data.updateAirlineLoadFactor,
            });
            setSettingsData(settings);
            setChangesAvailable(false);
            setDiscardMutationData(!discardMutationData);
          })
          .catch(error => {
            console.error(error);
          });
      }
    } else if (settingsData.Airtrak.runwaysMutationData.length > 0) {
      updatePhaseDefaults({
        variables: {
          phaseDefaults: settingsData.Airtrak.runwaysMutationData,
          scenarioId: activeScenario.id,
          startTime: activeScenario.startTime,
          endTime: activeScenario.endTime,
        },
      })
        .then(({ data }) => {
          dispatcher({
            type: actionTypes.GET_TIME_IN_MODES,
            timeInModesData: data.updatePhaseDefaults,
          });
          setSettingsData(settings);
          setChangesAvailable(false);
          setDiscardMutationData(!discardMutationData);
        })
        .catch(error => {
          console.error(error);
        });
    } else if (settingsData.Airtrak.fuelTypesMutationData.length > 0) {
      updateAircraftFuelMappings({
        variables: {
          aircraftFuelMappings: settingsData.Airtrak.fuelTypesMutationData,
          scenarioId: activeScenario.id,
          startTime: activeScenario.startTime,
          endTime: activeScenario.endTime,
        },
      })
        .then(({ data }) => {
          dispatcher({
            type: actionTypes.GET_AIRCRAFT_FUEL_MAPPINGS,
            aircraftFuelMappings: data.updateAircraftFuelMappings,
          });
          setSettingsData(settings);
          setChangesAvailable(false);
          setDiscardMutationData(!discardMutationData);
        })
        .catch(error => {
          console.error(error);
        });
    } else if (settingsData.Airtrak.scenarioMutationData.length > 0) {
      updateScenarios({
        variables: {
          scenarios: settingsData.Airtrak.scenarioMutationData,
        },
      })
        .then(({ data }) => {
          appDispatcher({
            type: appActionTypes.UPDATE_SCENARIOS,
            payload: data.updateScenarios,
          });
          setSettingsData(settings);
          setChangesAvailable(false);
          setDiscardMutationData(!discardMutationData);
          raiseRecalculationRequest();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const loadSubModuleData = selectedSubModule => {
    if (activeScenario.id) {
      switch (selectedSubModule) {
        case tabs.fleetMix:
          getAirlines(client, dispatcher, activeScenario);
          break;
        case tabs.aircraftMappings:
          getAircraftMappings(client, dispatcher, [activeScenario.id]);
          break;
        case tabs.timeInModes:
          getTimeInModes(client, dispatcher, [activeScenario.id]);
          break;
        case tabs.loadFactors:
          getLoadFactors(client, dispatcher, [activeScenario.id]);
          break;
        case tabs.fuelTypes:
          getAircraftFuelMappings(client, dispatcher, [activeScenario.id]);
          break;
        default:
      }
    }
  };

  const getSpinnerComponent = () => (
    <div className="spinner-loading">
      <Spinner loading size="l" centered />
    </div>
  );

  const SaveSettingsButtons = () => (
    <div className="settings__save">
      <Button
        style="primary"
        className={'settings__save-submit settings-submit'}
        disabled={!changesAvailable}
        onClick={onSaveClick}>
        {saveChanges}
      </Button>
      {changesAvailable && (
        <Button
          style="standard"
          className={'settings__save-discard settings-discard'}
          onClick={onDiscardClick}>
          {discardChanges}
        </Button>
      )}
    </div>
  );

  const renderModuleSettings = () => {
    if (isSubModuleNeedingChange && changesAvailable) {
      const result = confirm(areYouSureYouWantToLeave);
      setIsSubModuleNeedingChange(false);
      if (result) {
        loadSubModuleData(selectedSubModule);
        setChangesAvailable(false);
      } else {
        setSelectedSubModule(previousSelectedSubModule);
      }
    } else {
      switch (route) {
        case 'fleet-mix':
          return (
            <SettingsContentWrapper title="Fleet Mix" controls={<SaveSettingsButtons />}>
              <FleetMixContainer
                setChangesAvailable={setChangesAvailable}
                areChangesDiscarded={areChangesDiscarded}
                updateFleetMixSettings={updateAirlineMappingsMutationData}
                discardMutationData={discardMutationData}
              />
            </SettingsContentWrapper>
          );
        case 'aircraft-mappings':
          return (
            <SettingsContentWrapper title="Aircraft Mappings" controls={<SaveSettingsButtons />}>
              <div className="aircraft-mappings-root">
                <AircraftMappingsContainer
                  setChangesAvailable={setChangesAvailable}
                  areChangesDiscarded={areChangesDiscarded}
                  updateAicraftMappingsData={updateAicraftMappingsMutationData}
                  discardMutationData={discardMutationData}
                />
              </div>
            </SettingsContentWrapper>
          );
        case 'time-modes':
          return (
            <SettingsContentWrapper title="Time in Modes" controls={<SaveSettingsButtons />}>
              <TimeInModesContainer
                setChangesAvailable={setChangesAvailable}
                areChangesDiscarded={areChangesDiscarded}
                updateTimeInModeSettings={updateRunwaysMutationData}
                updateScenarios={updateScenarioMutationData}
                discardMutationData={discardMutationData}
              />
            </SettingsContentWrapper>
          );
        case 'load-factors':
          return (
            <SettingsContentWrapper title="Load Factors" controls={<SaveSettingsButtons />}>
              <LoadFactorsContainer
                setChangesAvailable={setChangesAvailable}
                areChangesDiscarded={areChangesDiscarded}
                updateLoadFactorsData={updateLoadFactorsMutationData}
                updateScenarios={updateScenarioMutationData}
                discardMutationData={discardMutationData}
              />
            </SettingsContentWrapper>
          );
        case 'fuel-types':
          return (
            <SettingsContentWrapper title="Fuel Types" controls={<SaveSettingsButtons />}>
              <FuelTypesContainer
                setChangesAvailable={setChangesAvailable}
                areChangesDiscarded={areChangesDiscarded}
                updateFuelTypesData={updateFuelTypesMutationData}
                updateScenarios={updateScenarioMutationData}
                discardMutationData={discardMutationData}
              />
            </SettingsContentWrapper>
          );
        case 'carbon':
          return (
            <SettingsContentWrapper title="Carbon Emission" controls={<SaveSettingsButtons />}>
              <AirtrakSettingsContainer
                setChangesAvailable={setChangesAvailable}
                areChangesDiscarded={areChangesDiscarded}
                updateScenarios={updateScenarioMutationData}
                discardMutationData={discardMutationData}
              />
            </SettingsContentWrapper>
          );
      }
    }
  };

  return <>{loading || !hasScenariosFetched ? getSpinnerComponent() : renderModuleSettings()}</>;
};
