import React, { FC, useState, useEffect } from 'react';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// common components
import { SettingsPanel, BackgroundPanel, LayersPanel } from '@ems/client-design-system';
// functions
import { getMapSettings, useGis } from 'src/app/functions/mapSettings';
import { OPERATIONS } from 'src/constants';

// Settings panels
import { MapSettingTrackStyle } from './MapSettingTrackStyle';
import { MapSettingTrackDensity } from './MapSettingTrackDensity';

interface IMapSettingsContainer {
  instance?: string;
  config: {
    background: string;
    layers: string[];
    mapTrackStyle?: string;
    trackDensitySize?: string;
  };
  onUpdate;
  enableColorByAltitudeSelector?: boolean;
}

export const MapSettingsContainer: FC<IMapSettingsContainer> = ({
  instance = '',
  config,
  onUpdate,
  enableColorByAltitudeSelector = false,
}) => {
  // Configuration
  const configSelectors = useConfigSelectors();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { background: backgroundTitle, layers: layersTitle },
      buttons: { hide: hideTitle, showMore: showMoreTitle },
      labels: { tracks: tracksLabel },
    },
  } = languageSelectors.getLanguage();
  // map settings items
  const { backgrounds, availableLayers, mapTrackStyles, trackDensityOptions } = getMapSettings(
    instance,
    enableColorByAltitudeSelector,
    configSelectors.getConfig(),
    languageSelectors.getLanguage()
  );
  // get gisLayers from GQL (if not available in the global store yet)
  const { gisLayers: customLayers } = useGis();
  // manage local state for selected background
  const [selectedIndex, updateSelectedIndex] = useState<number>(0);
  useEffect(() => {
    if (typeof config.background !== 'undefined' && config.background) {
      backgrounds.forEach(({ value }, index) => {
        if (value === config.background) {
          updateSelectedIndex(index);
        }
      });
    }
  }, [config.background]);
  // manage local state for selected layers
  const [selectedLayers, setSelectedLayers] = useState<string[]>(config.layers);
  const [selectedTrackTheme, setSelectedTrackTheme] = useState<string>(config.mapTrackStyle);
  const [selectedTrackDensitySize, setSelectedTrackDensitySize] = useState<string>(
    config.trackDensitySize
  );

  const onLayersPanelSelect = layers => {
    // If the tracks layer is enabled, and the user enables track density,
    // disable the tracks layer. But let the user enable tracks after that.
    if (layers.includes('Tracks') && layers.includes('TrackDensity')) {
      const indexTracks = layers.indexOf('Tracks');
      // If Track Density was enabled after Tracks was already enabled, it will be after Tracks in the array
      if (indexTracks < layers.indexOf('TrackDensity')) {
        layers.splice(indexTracks, 1);
      }
    }
    setSelectedLayers(layers);
    onUpdate({ selectedLayers: layers });
  };

  const onTrackStyleChange = theme => {
    setSelectedTrackTheme(theme);
    onUpdate({
      selectedTrackTheme: theme,
    });
  };

  const onTrackDensitySettingChange = option => {
    setSelectedTrackDensitySize(option);
    onUpdate({
      selectedTrackDensitySize: option,
    });
  };

  const onBackgroundChange = selectedIndex => {
    updateSelectedIndex(selectedIndex);
    onUpdate({
      selectedBackground: backgrounds[selectedIndex].value,
    });
  };

  return (
    <SettingsPanel className="map-settings_panel">
      <BackgroundPanel
        title={backgroundTitle}
        backgrounds={backgrounds}
        selectedIndex={selectedIndex}
        onSelect={onBackgroundChange}
      />
      <LayersPanel
        translationData={{
          title: layersTitle,
          hide: hideTitle,
          showMore: showMoreTitle,
        }}
        layers={[...availableLayers, ...customLayers]}
        selected={selectedLayers}
        onSelect={onLayersPanelSelect}
      />
      {(enableColorByAltitudeSelector || instance === OPERATIONS) && (
        <div className="backgrounds-panel">
          <h3 className="backgrounds-panel_title">{tracksLabel}</h3>
          {enableColorByAltitudeSelector && (
            <div className="track-style">
              <MapSettingTrackStyle
                onSelect={onTrackStyleChange}
                selectedTrackStyle={selectedTrackTheme}
                trackStyles={mapTrackStyles}
              />
            </div>
          )}
          {instance === OPERATIONS && (
            <div className="track-density">
              <MapSettingTrackDensity
                onSelect={onTrackDensitySettingChange}
                selectedTrackDensitySize={selectedTrackDensitySize}
                trackDensityOptions={trackDensityOptions}
              />
            </div>
          )}
        </div>
      )}
    </SettingsPanel>
  );
};
