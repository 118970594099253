import React, { FC } from 'react';

import { formatFromISO } from 'src/utils/dateTimeConverters';
import { ICcoGraphContainer } from 'src/@infringements/interfaces';
import { BlockHeader, LineGraph, Card } from '@ems/client-design-system';
// Reducers
import { useConfigSelectors } from 'src/app/reducers';
// Constants
import { THEME_DEFAULT_COLOR, THEME_DEFAULT_OPERATION_TYPE } from 'src/constants';
// Utils
import { getOperationTheme } from 'src/utils';

export const CcoGraphContainer: FC<ICcoGraphContainer> = ({
  scatterData,
  infringementData,
  title,
  time,
  operationData,
  ruleData,
  type,
  selectedTrackTheme,
}) => {
  const hasProfile: boolean =
    operationData && operationData.profile && !!Object.keys(operationData.profile).length;
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();

  const operationType: string = hasProfile
    ? operationData.operationType.toLowerCase()
    : THEME_DEFAULT_OPERATION_TYPE;
  const currentTheme = getOperationTheme(selectedTrackTheme);
  const lineColor = currentTheme[operationType.toLowerCase()] || THEME_DEFAULT_COLOR;

  return (
    <div className="block">
      <Card>
        <div className="card__padder">
          <BlockHeader
            title={`${title}`}
            cta={
              time && (
                <span className="infringements_time">{formatFromISO(time, 'HH:mm, DDD')}</span>
              )
            }
            loading={!hasProfile}
          />
          {hasProfile ? (
            <LineGraph
              scatterData={scatterData ? scatterData : operationData.profile}
              infringementData={infringementData}
              maxYValue={ruleData.ceilingAltitude}
              type={type}
              lineColor={lineColor}
              units={units}
            />
          ) : (
            <div className="graph--loading" />
          )}
        </div>
      </Card>
    </div>
  );
};
