import {
  IConfigState,
  IExportState,
  IFiltersState,
  IGeometryState,
  IInfringementRulesState,
  ILanguageState,
  IRolesState,
  IScenariosState,
  ITagDataState,
  IUserHelpState,
} from 'src/app/props';
import { getDeployedProductId } from 'src/utils/generics';

export const configInitialState: IConfigState = {
  deployedProductId: getDeployedProductId(),
  config: null,
  failedToAccessConfig: false,
  initialLoadCompleted: false,
  userConfig: {
    layout: 'SPLIT_LANDSCAPE_LAYOUT',
    isPortrait: false,
    mapStyle: '',
    languageTag: 'en',
  },
};

export const exportInitialState: IExportState = {
  captureImage: false,
  capture: [],
  requiredToCapture: [],
  trackmapImage: null,
  altitudeImage: null,
  noiseImage: null,
  inProgress: [],
  complete: [],
  error: [],
};

export const filtersInitialValues = {
  operations: null,
  noiseEvents: null,
  infringements: null,
  reports: null,
  airtrak: null,
  complaints: null,
  settings: null,
  modelingAircraftAssignments: null,
};

export const filtersInitialState: IFiltersState = {
  filters: { ...filtersInitialValues },
  failedToAccessFilters: false,
  initialLoadCompleted: false,
};

export const geometryInitialState: IGeometryState = {
  corridors: null,
  selectionzones: null,
  gates: null,
  gateSymbols: null,
  monitorLocations: null,
  runways: null,
  corridors3d: null,
  selectionzones3d: null,
  gates3d: null,
  monitorLocations3d: null,
  runways3d: null,
  gisLayers: [],
  kmlLayers: new Map(),
  failedToGetData: {
    corridors: false,
    selectionZones: false,
    gates: false,
    monitorLocations: false,
    runways: false,
    corridors3d: false,
    selectionZones3d: false,
    gates3d: false,
    monitorLocations3d: false,
    runways3d: false,
  },
};

export const infringementRulesInitialState: IInfringementRulesState = {
  rules: [],
  failedToAccessInfringementRules: false,
  initialLoadCompleted: false,
  isLoading: false,
  sortObject: {
    field: 'name',
    direction: 'ASC',
  },
};

export const languageInitialState: ILanguageState = {
  language: {},
  failedToAccessLanguage: false,
  initialLoadCompleted: false,
};

export const rolesInitialState: IRolesState = {
  userPermissions: [],
  userAppPermissions: [],
  failedToAccessRoles: false,
  initialLoadCompleted: false,
};

export const scenariosInitialState: IScenariosState = {
  isFetched: false,
  scenarios: [
    {
      id: 1,
      name: 'Default',
      isActive: true,
      isDefault: true,
      isLocked: false,
      startTime: '2000-01-01T00:00:00+00:00',
      endTime: '2020-11-30T00:00:00+00:00',
      defaultLoadFactor: 0,
      airportId: 'EGLL',
      applyWeatherAdjustment: false,
      defaultOptimumTMAApproachDistance: 0,
      defaultDurationTaxiOut: 1072,
      defaultDurationTakeOff: 32,
      defaultDurationClimb: 98,
      defaultDurationApproach: 240,
      defaultDurationTaxiIn: 240,
      emissionRateMode: 'single',
      emissionRateAvGas: 3.1331,
      emissionRateSingle: 3.156,
      emissionRateJetFuel: 3.1497,
      emissionRateUnknown: 3.1497,
      outstandingMonthsToProcess: 0,
    },
  ],
  activeScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '2000-01-01T00:00:00+00:00',
    endTime: '2020-11-30T00:00:00+00:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  currentScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '2000-01-01T00:00:00+00:00',
    endTime: '2020-11-30T00:00:00+00:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  defaultScenario: {
    id: 1,
    name: 'Default',
    isActive: true,
    isDefault: true,
    isLocked: false,
    startTime: '2000-01-01T00:00:00+00:00',
    endTime: '2020-11-30T00:00:00+00:00',
    airportId: 'EGLL',
    defaultOptimumTMAApproachDistance: 0,
    applyWeatherAdjustment: false,
    defaultLoadFactor: 0.0,
    defaultDurationTaxiOut: 1072,
    defaultDurationTakeOff: 32,
    defaultDurationClimb: 98,
    defaultDurationApproach: 240,
    defaultDurationTaxiIn: 240,
    emissionRateMode: 'multiple',
    emissionRateAvGas: 3.1331,
    emissionRateSingle: 3.156,
    emissionRateJetFuel: 3.1497,
    emissionRateUnknown: 3.1497,
    outstandingMonthsToProcess: 0,
  },
  selectedRowIndex: 0,
  outstandingMonthsToProcess: 0,
  isLoading: false,
};

export const tagDataInitialState: ITagDataState[] = [];

export const userHelpInitialState: IUserHelpState = {
  userHelpLinks: [],
  initialLoadCompleted: false,
};
