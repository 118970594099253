import { Card, ProgressBar } from "@blueprintjs/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { IScenario } from "src/app/props";
import { useLanguageSelectors, useScenariosSelectors } from "src/app/reducers";
import { DateTime } from 'luxon';
import { useApolloClient } from "@apollo/react-hooks";
import { GlobalDispatchContext } from "src/app/providers/GlobalStateProvider";
import { loadScenarioReprocessStatus } from "src/app/actions";

export const CarbonEmissionReProcessStatusContainer: FC = ({ }) => {
  // apollo client
  const client = useApolloClient();
  // dispatcher
  const appDispatcher: any = useContext(GlobalDispatchContext);
  
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const scenariosSelector = useScenariosSelectors();
  const activeScenario: IScenario = scenariosSelector.getActiveScenario();
  const activeScenarioId: number | undefined = activeScenario.id;
  const recalculationStatus: boolean = scenariosSelector.getRecalculationStatus();
  const outStandingMonthsToProcess: number = scenariosSelector.getOutStandingMonthsToProcess();

  const {
    components: {
      labels: {
        recalculating,
      },
      info: { reprocessingInfo }
    },
  } = translationData;
  const [showProgressBar, setShowProgressBar] = useState<boolean>(recalculationStatus);
  // timer
  const [scenarioProcessingStatusTimer, setScenarioProcessingStatusTimer] = useState<ReturnType<typeof setTimeout>>();

  const scenarioMonthCount = (scenario: IScenario) => {
    const end = DateTime.fromISO(scenario.endTime, { setZone: true });
    const start = DateTime.fromISO(scenario.startTime, { setZone: true });

    return parseInt(end.diff(start, 'months').months.toFixed(0), 10);
  };

  const reprocessingPercentage = () => {
    const totalMonths = scenarioMonthCount(activeScenario);
    return (totalMonths - outStandingMonthsToProcess) / totalMonths;
  };

  const reprocessingCount = () => {
    if (activeScenarioId) {
      loadScenarioReprocessStatus(client, appDispatcher, activeScenarioId);
    }
  };

  useEffect(() => {
    setShowProgressBar(recalculationStatus);
    if (recalculationStatus && !scenarioProcessingStatusTimer) {
      const timer = setInterval(reprocessingCount, 1000 * 60); // check every minute
      setScenarioProcessingStatusTimer(timer);
    }
    if (!recalculationStatus && scenarioProcessingStatusTimer) {
      clearInterval(scenarioProcessingStatusTimer);
      setScenarioProcessingStatusTimer(undefined);
    }
  }, [activeScenario, recalculationStatus]);

  return (
    <div>
      {showProgressBar &&
        <Card className="airtrak-progressbar-card">
          <div className="airtrak-progressbar">
            <p className="airtrak-progressbar-text">{reprocessingInfo}</p>
            <p className="airtrak-progressbar-text">{recalculating}</p>
            <ProgressBar
              intent={"primary"}
              value={reprocessingPercentage()}
              className={"airtrak-progressbar__bar"}
            />
            <p className="airtrak-progressbar-text">{scenarioMonthCount(activeScenario) - outStandingMonthsToProcess}/{scenarioMonthCount(activeScenario)}</p>
          </div>
        </Card>
      }
    </div>
  );
};