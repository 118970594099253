import { useCallback } from 'react';
import { useMap } from 'react-map-gl';
import { Cursor } from '../../map.types';

export const useSetCursor = () => {
  const { map } = useMap();
  return useCallback(
    (cursor: Cursor) => {
      if (!map) {return;}
      map.getCanvas().style.cursor = cursor;
    },
    [map]
  );
};
