import { createContext } from 'react';

export const dialogsStatusContext = createContext({
  isAddRecordOpen: false,
  setIsAddRecordOpen: (set: boolean) => {},
  isBulkEditOpen: false,
  setIsBulkEditOpen: (set: boolean) => {},
  isGenerateScenarioOpen: false,
  setIsGenerateScenarioOpen: (set: boolean) => {},
});
