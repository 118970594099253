import { CollapseHeader } from 'src/components/CollapseComponent/CollapseComponent.styles';
import styled from 'styled-components/macro';

// Variables
import styles from 'src/styles/vars.module.scss';

// Overrides for non styled components
export const DeviceManagementPageWrapper = styled.div`
  .timeinput.text-input_container .text-input_field {
    width: 68px;
    padding: 0 0.5rem;
  }
  .table_cell--item .field-details__input-text {
    font-weight: 400;
  }
`;

export const DeviceManagementPageHeader = styled.div`
  .page-header-text {
    margin-right: 5px;
  }
`;

export const DeviceManagementCardTitle = styled.div`
  padding: 0 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeviceManagementCardPadding = styled.div`
  padding: 1.3rem 2.8rem;
`;

export const DeviceManagementCardSubtitle = styled.div`
  font-size: 12px;
  color: ${styles.ui05};
  font-weight: 400;
`;

export const LocationSettingsCardButtons = styled.div`
  display: flex;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 96pt;
  .locationsettings__buttons_left {
    flex: 1;
  }
  .locationsettings__buttons_right {
    button {
      margin-left: 1rem;
    }
  }
`;

export const AdvancedSettingsContainer = styled.div`
  ${CollapseHeader} {
    border-top: 1px solid #c4cdd5;
    padding-top: 1.4rem;
  }
`;

export const AdvancedSettingsConfigFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .location-settings__field {
    width: 25%;

    @media (max-width: 1400px) {
      width: 50%;
    }
  }
`;

export const AddTemplateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  fill: ${styles.brand01};
  color: ${styles.brand01};

  &:hover {
    text-decoration: underline;
  }

  .mosaic-icon {
    margin-left: 5px;
  }
`;

export const MaxTemplatesMessage = styled.p`
  color: ${styles.ui05};
  font-size: 14px;
`;

export const NMTTableCellWrapper = styled.div`
  display: flex;

  .location-settings__field-label,
  .field-details__input-label,
  .timeinput-error {
    display: none;
  }
  .location-settings__field {
    margin: 0;
    padding: 0;
    min-height: initial;
    width: initial;
  }
  .text-input_field {
    width: 50px;
    padding: 0 7px;
  }
`;

export const DeviceManagementField = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-bottom: 24px;
  padding-right: 30px;
  min-height: 60px;

  .dropdown_button,
  .text-input_container,
  .dropdown {
    width: 100%;
    ${({ isShortField = false }: { isShortField?: boolean }) =>
      isShortField ? 'max-width: 105px;' : 'max-width: 280px;'};
  }
`;

export const FieldLabel = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #5a6872;
  margin-bottom: 0.125rem;

  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldError = styled.p`
  font-size: 12px;
  color: ${styles.error01};
  margin-bottom: 0.125rem;
`;

export const FieldAppendedWrapper = styled.div`
  display: flex;
  align-items: center;

  .text-input_container {
    margin-right: 5px;
  }
`;

export const DeviceManagementForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const ResetLocationButton = styled.button`
  padding-left: 0;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-align: left;

  margin-top: 17px;
  height: 36px;
  display: flex;
  align-items: center;
  color: ${styles.brand01};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .mosaic-icon {
    margin-left: 5px;
    height: 16px;
    width: 16px;
    color: ${styles.brand01};
    fill: ${styles.brand01};
  }
`;

export const BlankButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  fill: ${styles.error01};
  color: ${styles.error01};

  .mosaic-icon {
    transform: rotate(45deg);
  }
`;
