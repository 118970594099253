import React, { useState } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';

// Components
import { TextInput, Button } from '@ems/client-design-system';

import { SettingsSelectorWrapper } from 'src/app/components/SettingsSelector';

// Utils
import { getVerticalDistance } from 'src/utils';

// Reducers
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';

// Types
import { IColorByAltitudeConfig } from 'src/@settings/interfaces';

import {
  UNIT_METER,
  UNIT_KILOMETER,
  UNIT_MILE,
  UNIT_FOOT,
  IMPERIAL_BASE_UNIT,
  METRIC_BASE_UNIT,
} from 'src/constants';

export const ColorBandConfiguratorFields = ({
  colorByAltitudeConfig,
  className,
  onSubmit,
  isFormSubmitting,
}: {
  colorByAltitudeConfig: IColorByAltitudeConfig;
  className: string;
  onSubmit: (value: IColorByAltitudeConfig) => void;
  isFormSubmitting: boolean;
}) => {
  const MIN_ALTITUDE = -99999;
  const MAX_ALTITUDE = 99999;
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const inputClassName = `${classNames(`${className}__input settings-selector__input`)}`;
  const [haveReset, setHaveReset] = useState(false);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        generalSettings: { ground: groundString, maximum: maximumString },
      },
    },
  } = languageSelectors.getLanguage();

  // Despite typings, sometimes local settings can send Kilometers or Miles as the unit, so we need to account for this.
  let verticalDistanceUnit = units.distanceVertical;

  switch (units.distanceVertical as string) {
    case UNIT_KILOMETER:
      verticalDistanceUnit = UNIT_METER;
      break;
    case UNIT_MILE:
      verticalDistanceUnit = UNIT_FOOT;
      break;
  }

  const validateForm = (values: { [key: number]: number }) => {
    const errors = {};

    Object.keys(values).forEach((key, i) => {
      if (i === 0 || i === colorByAltitudeConfig.bands.length - 1) {
        return;
      }

      const nextValue = values[i + 1];
      const previousValue = values[i - 1] + 1;
      const currentValue = values[i];
      // Check bands do not overlap
      if (currentValue <= previousValue || currentValue >= nextValue) {
        errors[key] = 'Please ensure band values do not overlap';
      }
      // Check for whole (no decimal) numbers
      if (!Number.isInteger(currentValue)) {
        errors[key] = 'Please use whole numbers';
      }
    });

    return errors;
  };

  const formattedFieldValues = colorByAltitudeConfig.bands.map((value, i) => {
    // Dont need to convert our infinity values, just return them
    if (i === 0 || i === colorByAltitudeConfig.bands.length - 1) {
      return value;
    }
    return getVerticalDistance(value, {
      convertTo: verticalDistanceUnit === UNIT_FOOT ? IMPERIAL_BASE_UNIT : METRIC_BASE_UNIT,
      convertFrom: 'ft',
      returnValueType: 'number',
    }) as number;
  });

  const formik = useFormik({
    initialValues: { ...formattedFieldValues },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validate: validateForm,
    onSubmit: values => {
      const formatted: number[] = [];

      Object.keys(values).forEach((key, i) => {
        // Dont need to convert our infinity values
        if (i === 0 || i === colorByAltitudeConfig.bands.length - 1) {
          formatted.push(values[key]);
        } else {
          const newValue = getVerticalDistance(values[key], {
            convertTo: IMPERIAL_BASE_UNIT,
            convertFrom: verticalDistanceUnit,
            returnValueType: 'number',
          }) as number;

          formatted.push(newValue);
        }
      });
      onSubmit({
        colors: colorByAltitudeConfig.colors,
        bands: formatted,
      });
    },
  });

  const isFormEdited = JSON.stringify(formik.initialValues) !== JSON.stringify(formik.values);
  const handleReset = e => {
    formik.resetForm();
    formik.setErrors({});
    setHaveReset(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {Object.keys(formik.values).map((key, i) => {
        // First and last bands are our infinity values, so skip them
        if (i === 0 || i === formik.values.length - 1) {
          return null;
        }

        const bandColor = colorByAltitudeConfig.colors[i - 1];
        const getLowerValue = (i: number) => {
          let prevousBandConvertedValue = 0;
          prevousBandConvertedValue = formik.values[i - 1] + 1;

          if (i === 1) {
            // First non-infinity band
            return groundString;
          }

          return `${prevousBandConvertedValue.toLocaleString()} ${verticalDistanceUnit}`;
        };

        const getUpperValue = (i: number) => {
          // Last non-infinity band
          if (i === colorByAltitudeConfig.bands.length - 1) {
            return maximumString;
          } else {
            return (
              <>
                <TextInput
                  state={!formik.errors[key] ? 'default' : 'error'}
                  className={inputClassName}
                  defaultValue={formik.initialValues[key]}
                  onFocus={e => {
                    if (haveReset) {
                      const event = new Event('input', { bubbles: true });
                      e.target.dispatchEvent(event);
                      setHaveReset(false);
                    }
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;

                    if (parseFloat(value) > MAX_ALTITUDE || parseFloat(value) < MIN_ALTITUDE) {
                      const sliceIndex = parseFloat(value) >= 0 ? 5 : 6;
                      e.target.value = value.slice(0, sliceIndex);
                    }
                    formik.handleChange(e);
                  }}
                  id={key.toString()}
                  name={key.toString()}
                  type="number"
                  onBlur={e => {
                    formik.handleBlur(e);
                  }}
                />
                {verticalDistanceUnit}
              </>
            );
          }
        };

        return (
          <div
            key={formik.initialValues[i]}
            className={classNames(`${className}__group settings-selector__group`)}>
            <span
              style={{ backgroundColor: bandColor }}
              className={classNames(`${className}__colorpicker settings-selector__colorpicker`)}
            />
            <span
              className={classNames(
                `${className}__lowervalue settings-selector__lowervalue ${formik.errors[key] &&
                  'error'}`
              )}>
              {getLowerValue(i)}
            </span>
            —
            <span className={classNames(`${className}__uppervalue settings-selector__uppervalue`)}>
              {getUpperValue(i)}
            </span>
          </div>
        );
      })}

      <div className="form-group">
        <div className="form-group__error">
          {Object.keys(formik.errors).length ? (
            <p className="form-group__error-text">{formik.errors[Object.keys(formik.errors)[0]]}</p>
          ) : null}
        </div>
      </div>

      <div
        className={classNames(
          `${className}__controls settings-selector__controls ${isFormEdited && 'displayed'}`
        )}
        aria-hidden={isFormEdited}>
        <Button
          onClick={handleReset}
          className={classNames(`${className}__controls-button settings-selector__controls-button`)}
          style="subtle"
          type="reset">
          Cancel
        </Button>

        <Button
          disabled={!formik.isValid}
          className={classNames(`${className}__controls-button settings-selector__controls-button`)}
          style="primary"
          type="submit"
          loading={isFormSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
};

export const ColorBandConfigurator: React.FC<{
  title: string;
  className: string;
  colorByAltitudeConfig: IColorByAltitudeConfig;
  onSubmitHandler: (value: IColorByAltitudeConfig) => void;
  isFormSubmitting: boolean;
}> = ({ title, className, colorByAltitudeConfig, onSubmitHandler, isFormSubmitting }) => (
  <>
    <SettingsSelectorWrapper className={className} title={title}>
      <ColorBandConfiguratorFields
        colorByAltitudeConfig={colorByAltitudeConfig}
        className={className}
        onSubmit={onSubmitHandler}
        isFormSubmitting={isFormSubmitting}
      />
    </SettingsSelectorWrapper>
  </>
);
