import React from 'react';
// components
import { PageHeader } from 'src/components';

interface SettingsContentWrapperProps {
  title?: string;
  controls?: React.ReactNode;
  children: React.ReactNode;
}

export const SettingsContentWrapper: React.FC<SettingsContentWrapperProps> = ({
  title,
  children,
  controls,
}) => {
  return (
    <div className="settings__content">
      <div className="settings__heading">
        {title && <PageHeader title={title} />}
        {controls && controls}
      </div>
      <div className="settings__content-container">{children}</div>
    </div>
  );
};
