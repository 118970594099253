import { useMemo } from 'react';

// Types
import { TTableFilterTypes } from 'src/components';
import { IAircraftMappingTableData } from '../types';

// Constants
import { ITableColumnData } from 'src/components';
import {
  BLANK_STRING_PLACEHOLDER,
  TABLE_CONSTANTS,
  UNMAPPED_STRING_PLACEHOLDER,
} from 'src/constants';

// Hooks
import { useAircraftMappings } from '../providers/aircraftMappings';
import {
  useClearTableFilters,
  useInitializeTableFilter,
  useSetInitializedTableFilters,
} from 'src/utils/tableHelpers/tableFilterHelpers';

// Providers
import { useFilterDataSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useOperationTheme } from 'src/utils';

export const useColumnData = (): ITableColumnData[] => {
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();

  const {
    fields: { aircraftMappings },
  } = translationData;
  return [
    {
      columnName: 'aircraftType',
      key: 'aircraftType',
      title: aircraftMappings.aircraft,
    },
    {
      columnName: 'engineModel',
      key: 'engineModel',
      title: aircraftMappings.engine,
    },
    {
      columnName: 'numberOfFlights',
      key: 'numberOfFlights',
      title: aircraftMappings.numberOfFlights,
    },
    {
      columnName: 'mappedLTOAircraftEngine',
      key: 'mappedLTOAircraftEngine',
      title: aircraftMappings.ltoModel,
    },
    {
      columnName: 'mappedEnrouteAircraftEngine',
      key: 'mappedEnrouteAircraftEngine',
      title: aircraftMappings.enrouteModel,
    },
  ];
};

export const useTableFilters = ({
  isLoading,
  tableData,
  beforeFilter,
}: {
  isLoading: boolean;
  tableData: IAircraftMappingTableData[];
  beforeFilter: () => void;
}) => {
  // Providers
  const columnData = useColumnData();
  const filtersSelectors = useFilterDataSelectors();
  const { airlines = [] } = filtersSelectors.getOperationsFilterData();
  const { aircraftTypes = [], engineModels = [] } = filtersSelectors.getAirtrakFilterData();
  const { initializedTableFilter } = useInitializeTableFilter(isLoading, tableData, columnData);
  const languageSelector = useLanguageSelectors();
  const operationTheme = useOperationTheme();

  // State
  const {
    state: { ltoMappings, enrouteMappings },
  } = useAircraftMappings();

  // Language Strings
  const {
    fields: { aircraftMapping, aircraftAssignments, operations },
    components: {
      lists: { operationTypes: operationTypesList },
    },
  } = languageSelector.getLanguage();

  const operationTypes = Object.keys(operationTypesList).map(key => ({
    key,
    label: operationTypesList[key] as string,
    icon: `ad-${key.toLowerCase()}`,
  }));

  const engineModelFilterOptions = [
    { key: null, label: BLANK_STRING_PLACEHOLDER },
    ...engineModels.map(model => ({
      key: model,
      label: model,
    })),
  ];

  const ltoModelFilterOptions = [
    { key: UNMAPPED_STRING_PLACEHOLDER, label: UNMAPPED_STRING_PLACEHOLDER },
    ...ltoMappings.map(({ engine, aircraftType }) => ({
      key: `${aircraftType} / ${engine}`,
      label: `${aircraftType} / ${engine}`,
    })),
  ];

  const enrouteModelFilterOptions = [
    { key: UNMAPPED_STRING_PLACEHOLDER, label: UNMAPPED_STRING_PLACEHOLDER },
    ...enrouteMappings.map(({ engine, aircraftType }) => ({
      key: `${aircraftType} / ${engine}`,
      label: `${aircraftType} / ${engine}`,
    })),
  ];

  const airlineFilterOptions = [
    { key: null, label: BLANK_STRING_PLACEHOLDER },
    ...airlines.map(airline => ({
      key: airline,
      label: airline,
    })),
  ];

  const operationTypesFilterObject = {
    title: aircraftAssignments.operationTypes,
    key: 'operationTypes',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'operationTypes',
    filterItems: operationTypes,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
    theme: operationTheme,
  };

  const aircraftTypesFilterObject = {
    title: operations.aircraftType,
    key: 'aircraftTypes',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'aircraftTypes',
    filterItems: aircraftTypes.map(id => ({
      key: id,
      label: id,
    })),
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const engineModelFilterObject = {
    title: aircraftMapping.engineModel,
    key: 'engineModels',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'engineModels',
    filterItems: engineModelFilterOptions,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const ltoModelFilterObject = {
    title: aircraftMapping.mappedLTOAircraftEngine,
    key: 'lTOAircraftEngines',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'lTOAircraftEngines',
    filterItems: ltoModelFilterOptions,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const enrouteModelFilterObject = {
    title: aircraftMapping.mappedEnrouteAircraftEngine,
    key: 'enrouteAircraftEngines',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'enrouteAircraftEngines',
    filterItems: enrouteModelFilterOptions,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const airlineFilterObject = {
    title: aircraftMapping.airline,
    key: 'airlines',
    filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
    filterName: 'airlines',
    filterItems: airlineFilterOptions,
    selectedItems: [],
    filterType: 'listFilter' as TTableFilterTypes,
  };

  const tableFilters = useMemo(() => {
    const filter = { ...initializedTableFilter };
    filter.filters.push(operationTypesFilterObject);
    filter.filters.push(aircraftTypesFilterObject);
    filter.filters.push(airlineFilterObject);
    filter.filters.push(engineModelFilterObject);
    filter.filters.push(ltoModelFilterObject);
    filter.filters.push(enrouteModelFilterObject);
    return filter;
  }, [initializedTableFilter]);

  const {
    tableFilterObject,
    setTableFilterObject,
    isFilterInitialized,
  } = useSetInitializedTableFilters(tableFilters);

  const onClearFilters = useClearTableFilters(
    tableFilterObject,
    setTableFilterObject,
    beforeFilter
  );

  return {
    tableFilterObject,
    setTableFilterObject,
    onClearFilters,
    isTableFilterInitialized: isFilterInitialized,
  };
};
