import { OPERATIONS } from 'src/constants';
import { IMap, removeMapSourcesByPrefix } from '../mapHelpers';
import { useEffect } from 'react';
import { hasStyleLoaded, useAreSourcesLoading } from '../mapApis';

export const useTracksOnTiles = ({
  mapApis,
  trackPaths,
  trackVisibility,
  paintStyle,
  rerunHook,
}: {
  mapApis: null | IMap;
  trackPaths: any;
  trackVisibility: boolean;
  paintStyle: any;
  rerunHook: boolean;
}) => {
  const prefix = `trackLayer`;

  const paintTrackPaths = async trackPaths => {
    trackPaths.map(path => {
      const { lower, upper, url } = path;
      const currentId = `${prefix}_${lower}_${upper}`;
      mapApis.addSource(currentId, {
        type: 'vector',
        url: `${url}`,
      });
      mapApis.addLayer({
        id: currentId,
        type: 'line',
        source: currentId,
        'source-layer': 'operations',
        metadata: {
          tagType: OPERATIONS,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: trackVisibility ? 'visible' : 'none',
          'line-sort-key': ['+', ['get', 'altitudeBand']],
        },
        paint: paintStyle,
      });
    });
  };

  const paintTracks = async trackPaths => {
    if (mapApis && hasStyleLoaded(mapApis) && trackPaths.length) {
      await removeMapSourcesByPrefix(mapApis, prefix);
      await paintTrackPaths(trackPaths);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    paintTracks(trackPaths);
  }, [mapApis, trackPaths, rerunHook]);

  const areTracksLoaded = useAreSourcesLoading(mapApis);
  return areTracksLoaded;
};
