import React, { ReactNode } from 'react';
import { Map as MapboxMap, MapProvider as MapboxMapProvider } from 'react-map-gl';
import { AnomsMapWrapper } from './AnomsMap.styles';
import { useMapContext } from '../context/useMapContext';
import { MapState } from '../map.types';
import { useMapCursor } from './use-map-cursor/useMapCursor';

// Styling
import 'mapbox-gl/dist/mapbox-gl.css';

type AnomsMapProps = Partial<MapState['mapProps']>;

/**
 * Needs to be wrapped in components/Map/Provider
 */
const implementation = (MapboxMapImplementation: typeof MapboxMap) => ({
  children,
  ...rest
}: AnomsMapProps) => {
  const { mapProps } = useMapContext();
  return (
    <AnomsMapWrapper className="map">
      <MapboxMapProvider>
        <MapboxMapImplementation {...mapProps} {...rest}>
          <AnomsMapContents>{children}</AnomsMapContents>
        </MapboxMapImplementation>
      </MapboxMapProvider>
    </AnomsMapWrapper>
  );
};

export const AnomsMap = implementation(MapboxMap);

export default function TestOmnisMap(MapboxMapImplementation: typeof MapboxMap) {
  return implementation(MapboxMapImplementation);
}

interface AnomsMapContents {
  children: ReactNode;
}
const AnomsMapContents = ({ children }: AnomsMapContents) => {
  useMapCursor();
  return <>{children}</>;
};
