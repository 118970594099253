import { useContext } from 'react';
import uuid from 'uuid';
import { IDataSelectors, IDataState, IFetchedData } from 'src/@infringementsCandidates/props';
import { dataActionTypes } from 'src/@infringementsCandidates/newActionTypes';
import { appActionTypes } from 'src/app/actionTypes';
import { InfringementStateContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { setSelectionDelta, getMapDataFromSelection } from 'src/utils';
import { INFRINGEMENT_RULE_TYPES } from 'src/constants';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(InfringementStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getDataInformation: () => {
      return {
        data: state.fetchedData,
        pageInfo: state.pageInfo,
        totalCount: state.totalCount,
        selectedData: state.selectedData,
        isLoading: state.isLoading,
        areAllRowsSelected: areAllRowsSelected(state),
        isLoadingMore: state.isLoadingMore,
        isInfringement: state.isInfringement,
      };
    },
    getDataIds: () => state.fetchedData.map(item => item.id),
    getPagingInfo: () => state.pageInfo,
    getSelectedDateRange: () => state.selectedDateRange,
    getCandidatesEnabled: () => state.candidatesEnabled,
    getNoiseMonitors: () => state.noiseMonitorData,
    getNoiseEventIds: () => state.noiseEventIds,
    getNoiseEventDetails: () => state.noiseEventDetails,
    getRequiredDataForMap: () => {
      const selectedData = state.selectedData;
      const requiredData: any[] = [];
      const selectedDataLength = selectedData && selectedData.length;
      if (selectedData && selectedDataLength) {
        for (let i = selectedDataLength; i--; ) {
          const {
            id,
            operationId,
            infringementType,
            time,
            position,
            airportId,
            rule,
            isInfringement,
          } = state.fetchedData[selectedData[i]];
          requiredData.push({
            id,
            operationId,
            infringementType,
            time,
            position: typeof position !== 'undefined' && position ? position : null, // inf candidate doesn't have position
            corridorId:
              typeof rule !== 'undefined' && typeof rule.corridorId !== 'undefined'
                ? rule.corridorId
                : null, // inf candidate -> rule -> corridorId
            airportId,
            isInfringement: isInfringement,
          });
        }
      }
      return {
        requiredData,
        infringementTypes: state.infringementTypes,
        selectionLength: state.selectedData.length,
        addedToSelection: state.addedToSelection,
        removedFromSelection: state.removedFromSelection,
      };
    },
    getNavigationData: () => {
      let selection: number[] = [];
      if (!state.candidatesEnabled) {
        selection = state.fetchedData
          ? state.fetchedData
              .filter(item => {
                return item.id ? item.id : item.infringementId;
              })
              .map(item => {
                return item.id ? item.id : item.infringementId;
              })
          : [];
      } else {
        selection = state.fetchedData
          ? state.fetchedData.filter(item => item.isInfringement).map(item => item.infringementId)
          : [];
      }

      return {
        itemsIds: selection,
        hasNextPage: state.pageInfo ? state.pageInfo.hasNextPage : false,
        endCursor: state.pageInfo ? state.pageInfo.endCursor : undefined,
        dateRange: state.selectedDateRange,
      };
    },
  }));
};

export const dataInitialState: IDataState = {
  isLoading: true,
  selectedData: [],
  selectedDateRange: null,
  addedToSelection: [],
  removedFromSelection: [],
  fetchedData: [],
  infringementTypes: [],
  isLoadingMore: false,
  pageInfo: undefined,
  totalCount: undefined,
  candidatesEnabled: false,
  noiseMonitorData: [],
  noiseEventIds: [],
  noiseEventDetails: [],
  lastRequestTime: null,
  lastResetTime: null,
  isInfringement: false,
};

const setNoiseEventIds = (state, selectedData) => {
  const noiseEvents: number[] = [];
  const selectedDataLength = selectedData && selectedData.length;
  if (selectedData && selectedDataLength) {
    for (let i = selectedDataLength; i--; ) {
      const { detail, infringementType } = state.fetchedData[selectedData[i]];

      if (
        infringementType === INFRINGEMENT_RULE_TYPES.NOISE_INFRINGEMENT &&
        detail &&
        detail.noiseEventIds
      ) {
        noiseEvents.push(...detail.noiseEventIds);
      }
    }
  }

  return noiseEvents;
};

const setLocalSelectionData = (state, data) => {
  const { removedFromSelection, addedToSelection } = setSelectionDelta(data, [
    ...state.selectedData,
  ]);
  const addedToSelectionData =
    state.fetchedData.length &&
    addedToSelection.map(rowId => {
      const addedMapData = getMapDataFromSelection(state.fetchedData)(rowId);
      const addedInfringement = state.fetchedData[rowId];
      return {
        infringementId: addedInfringement.id,
        infringementType: addedInfringement.infringementType,
        operation: addedMapData,
      };
    });
  const removedFromSelectionData =
    state.fetchedData.length &&
    removedFromSelection.map(rowId => {
      const removedMapData = getMapDataFromSelection(state.fetchedData)(rowId);
      const removedInfringement = state.fetchedData[rowId];
      return {
        infringementId: removedInfringement.id,
        infringementType: removedInfringement.infringementType,
        operation: removedMapData,
      };
    });
  state.removedFromSelection = removedFromSelectionData ? removedFromSelectionData : [];
  state.addedToSelection = addedToSelectionData ? addedToSelectionData : [];
};

const areAllRowsSelected = state => {
  if (state.selectedData && state.selectedData.length && state.fetchedData) {
    if (state.fetchedData.length === state.selectedData.length) {
      return true;
    } else {
      return 'indeterminate';
    }
  }
  return false;
};

const addDisplayData = (
  currentData: IFetchedData[],
  newData: IFetchedData[],
  candidates: boolean,
  reset: boolean
) => {
  const data = new Map();
  if (!reset) {
    // add items already exist
    for (const operation of currentData) {
      data.set(operation.id, operation);
    }
  }
  for (const operation of newData) {
    const { id, ruleId, operationId } = operation;
    // candidates don't have id so id = operationId + ruleId
    const idAltered = candidates ? Number(`${operationId}${ruleId}`) : id;
    operation.tableId = uuid.v4();
    if (candidates) {
      operation.id = idAltered;
    }
    data.set(idAltered, operation);
  }
  const fetchedData: IFetchedData[] = [];
  const infringementTypes: any = {};
  data.forEach(item => {
    infringementTypes[item.infringementType] = true;
    fetchedData.push(item);
  });

  return {
    fetchedData,
    infringementTypes: Object.keys(infringementTypes),
  };
};

export const dataReducer = (state: IDataState, action: any) => {
  switch (action.type) {
    case dataActionTypes.INLINE_EDIT:
      const { id, status } = action.data;
      const dataEdited = state.fetchedData;
      const foundId = dataEdited.findIndex(item => item.id === id);
      if (foundId !== -1) {
        const currentItem = { ...dataEdited[foundId] };
        currentItem.status = status;
        currentItem.tableId = uuid.v4();

        const edited = Object.assign([...dataEdited], { [foundId]: currentItem });

        return Object.assign({}, state, {
          fetchedData: edited,
        });
      }
    case dataActionTypes.TABLE_HEADERS:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case dataActionTypes.BEGIN_NOISE_EVENT_FETCH: {
      return Object.assign({}, state, {
        lastRequestTime: new Date().getTime(),
      });
    }
    case dataActionTypes.NOISE_EVENTS_RECEIVED: {
      const { data } = action.data;
      if (
        state.lastResetTime &&
        state.lastRequestTime &&
        state.lastRequestTime > state.lastResetTime
      ) {
        return Object.assign({}, state, {
          noiseEventDetails: data,
        });
      }

      return state;
    }
    case dataActionTypes.RESET_NOISE_EVENTS: {
      return Object.assign({}, state, {
        noiseEventDetails: [],
        lastResetTime: new Date().getTime(),
      });
    }
    case dataActionTypes.NOISE_MONITORS_RECEIVED: {
      const { data } = action.data;
      return Object.assign({}, state, {
        noiseMonitorData: data,
      });
    }
    case dataActionTypes.INFRINGEMENTS_FETCHED:
      const {
        data: { data, selectedDateRange, pageInfo, totalCount },
        candidates,
        reset,
      } = action;
      const { fetchedData, infringementTypes } = addDisplayData(
        state.fetchedData,
        data,
        candidates,
        reset
      );
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingMore: false,
        selectedDateRange,
        fetchedData,
        infringementTypes,
        pageInfo,
        totalCount,
      });
    case dataActionTypes.GET_TOTAL_COUNT:
      if (state.totalCount !== action.data.totalCount) {
        return Object.assign({}, state, {
          totalCount: action.data.totalCount,
        });
      }
      // if it's not different, simply return state
      return state;
    case dataActionTypes.LOAD_MORE:
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    case dataActionTypes.SET_CANDIDATES_ENABLED:
      return Object.assign({}, state, {
        candidatesEnabled: action.data,
      });
    case dataActionTypes.SELECT_ROW:
      setLocalSelectionData(state, action.data);
      const noiseEventIds = setNoiseEventIds(state, action.data);

      return Object.assign({}, state, {
        selectedData: action.data,
        noiseEventIds,
      });
    case appActionTypes.ROUTE_CHANGE:
    case dataActionTypes.RESET_DATA:
      setLocalSelectionData(state, []);

      return Object.assign({}, state, {
        isLoading: true,
        selectedData: [],
        selectedDateRange: null,
        addedToSelection: [],
        fetchedData: [],
        infringementTypes: [],
        isLoadingMore: false,
        pageInfo: undefined,
        totalCount: undefined,
      });
    default:
      return state;
  }
};
