import { IAircraftNoiseContourFilter, IAircraftNoiseContourData } from './interfaces';
import { ISortObject } from 'src/components';

export const defaultQueryFilter: IAircraftNoiseContourFilter = {
  //noiseModelTypes: ['INM', 'AEDT'],
};

export const defaultSortOrder: ISortObject = {
  field: 'name',
  direction: 'DESC',
};

export const defaultQueryResult: IAircraftNoiseContourData = {
  aircraftNoiseContours: [],
};
