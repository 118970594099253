import React from 'react';
import cx from 'classnames';
import { Icons } from '@ems/client-design-system';

interface PillProps {
  title?: string;
  icon: string;
  type?: string;
}

// pill component
export const Pill = ({ title = '', icon, type = 'infringement' }: PillProps) => (
    <div className={cx('pill', `pill--${type}`)}>
      <Icons iconName={icon} size={14} className="pill_icon" />
      {title !== '' && <span className="pill_text">{title}</span>}
    </div>
  );
