import { Checkbox } from '@ems/client-design-system';
import React from 'react';
import {
  ActiveCheckboxLeft,
  ActiveCheckboxRight,
  ActiveCheckboxWrapper,
} from './ActiveCheckbox.styles';
import { useLanguageSelectors } from 'src/app/reducers';

export const IsActiveCheckbox = ({
  isEditing,
  isActive,
  updateIsActive,
}: {
  isEditing: boolean;
  isActive: boolean;
  updateIsActive: (isActive: boolean) => void;
}) => {
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        infringementRules: { active: activeString },
      },
    },
  } = languageSelectors.getLanguage();

  return (
    <ActiveCheckboxWrapper className="active-checkbox">
      <ActiveCheckboxLeft>{activeString}</ActiveCheckboxLeft>
      <ActiveCheckboxRight $isEditing={isEditing}>
        <Checkbox
          variant="circle"
          color="blue"
          checked={isActive}
          onChange={() => {
            if (isEditing) {
              updateIsActive(!isActive);
            }
          }}
        />
      </ActiveCheckboxRight>
    </ActiveCheckboxWrapper>
  );
};
