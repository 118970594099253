import React from 'react';
import { withRouter } from 'react-router';
import { SplitLayout } from 'src/components/Layout';
import { ContentContainer } from 'src/@infringements/containers/Summary';
import { InfringementsStateProvider } from 'src/@infringements/providers/InfringementsStateProvider';
// constants
import { INFRINGEMENTS } from 'src/constants';

const InfringementsSummaryScreen = ({ match, location }) => {
  const { id } = match.params;

  return (
    <SplitLayout>
      <InfringementsStateProvider>
        <ContentContainer id={id} path={INFRINGEMENTS} paginationInfo={location.state} />
      </InfringementsStateProvider>
    </SplitLayout>
  );
};

export const InfringementsSummary = withRouter(InfringementsSummaryScreen);
