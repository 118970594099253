import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const appActionTypes: { [key: string]: string } = keyToActionTypes(
  {
    LOAD_ROLES: null,
    ROLES_LOADED: null,
    ROLES_ERROR: null,
    UPDATE_CONFIG: null,
    CONFIG_ERROR: null,
    UPDATE_OPERATION_THEME: null,
    UPDATE_DISTANCE_UNITS: null,
    UPDATE_COLOR_BY_ALTITUDE_COLORS: null,
    UPDATE_COLOR_BY_ALTITUDE_BANDS: null,
    UPDATE_LANGUAGE: null,
    LANGUAGE_ERROR: null,
    UPDATE_DATE_RANGE: null,
    ROUTE_CHANGE: null,
    UPDATE_SCENARIOS: null,
    UPDATE_CURRENT_SCENARIO: null,
    UPDATE_SCENARIOS_MUTATION_DATA: null,
    SCENARIOS_LOADING: null,
    UPDATE_SCENARIO_REPROCESS_STATUS: null,
    CHECK_PUSH_NOTICES: null,
  },
  'APP'
);
