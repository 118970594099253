import React, { useState } from 'react';
// selectors
// components
import { FullNoiseGraph } from 'src/components/NoiseGraphs';
import { SafeLink } from 'src/components';
// utils
import { INoisePanelContainer } from 'src/@infringements/interfaces';
import { PlaybackControl } from 'src/components/PlaybackControl';
import { AudioPlayback } from 'src/components/AudioPlayback';
import { Card, BlockHeader, DescriptionList } from '@ems/client-design-system';
import { useLanguageSelectors } from 'src/app/reducers';
import { UNIT_DECIBEL, UNIT_SECOND } from 'src/constants';
import { formatFromISO } from 'src/utils';

export const NoisePanelContainer = ({
  audioData,
  noiseData,
  startTime,
  endTime,
  eventTime,
  noiseDetails,
  showFileDownload = true,
  showSeconds = false,
}: INoisePanelContainer) => {
  const languageSelectors = useLanguageSelectors();
  const {
    fields: {
      noiseEvents: { maxLevel, sel, threshold, duration, file, epnl },
    },
    components: {
      noiseEvents: {
        title,
        fields: { download, leq },
      },
    },
  } = languageSelectors.getLanguage();

  const [currentTime, setCurrentTime] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [runningStatus, setRunningStatus] = useState<boolean>(false);
  const [draggingStatus, setDraggingStatus] = useState<boolean>(false);

  const items: any = [];

  if (noiseDetails && noiseDetails.hasData) {
    const lmaxDesc = noiseDetails.lmax ? `${noiseDetails.lmax.toFixed(1)} ${UNIT_DECIBEL}` : '—';
    const selDesc = noiseDetails.sel ? `${noiseDetails.sel.toFixed(1)} ${UNIT_DECIBEL}` : '—';
    const thresholdDesc = noiseDetails.threshold
      ? `${noiseDetails.threshold} ${UNIT_DECIBEL}`
      : '—';
    const durationDesc = noiseDetails.duration ? `${noiseDetails.duration} ${UNIT_SECOND}` : '—';
    const epnlDesc = noiseDetails.epnl ? `${noiseDetails.epnl.toFixed(1)} ${UNIT_DECIBEL}` : '—';
    const leqDesc = noiseDetails.leq ? `${noiseDetails.leq.toFixed(1)} ${UNIT_DECIBEL}` : '—';
    items.push(
      {
        title: maxLevel,
        description: lmaxDesc,
      },
      {
        title: sel,
        description: selDesc,
      },
      {
        title: epnl,
        description: epnlDesc,
      },
      {
        title: threshold,
        description: thresholdDesc,
      },
      {
        title: duration,
        description: durationDesc,
      },
      {
        title: leq,
        description: leqDesc,
      }
    );
  }

  if (showFileDownload && audioData) {
    items.push({
      title: file,
      description: (
        <SafeLink
          key={`noiseDetails_download`}
          className="reports_downloadlink"
          href={audioData[0].resourceUri.uri}
          download>
          {download}
        </SafeLink>
      ),
    });
  }

  const timeFormat = showSeconds ? 'HH:mm:ss, DDD' : 'HH:mm, DDD';

  return (
    <div className="block">
      <Card>
        <div className="card__padder">
          <BlockHeader
            title={`${title}`}
            cta={
              eventTime && (
                <span className="infringements_time">{formatFromISO(eventTime, timeFormat)}</span>
              )
            }
          />
          {startTime ? (
            <>
              <FullNoiseGraph
                data={noiseData}
                currentTime={currentTime}
                startTime={startTime}
                endTime={endTime}
                className={'card--no-bot-pad'}
              />
              <AudioPlayback
                audio={audioData}
                isPlaying={runningStatus && !draggingStatus}
                currentTime={currentTime}
                playbackSpeed={playbackSpeed}
              />
              <PlaybackControl
                startTime={startTime}
                endTime={endTime}
                onPositionUpdate={setCurrentTime}
                onRunningStatusUpdate={setRunningStatus}
                onDraggingStatusUpdate={setDraggingStatus}
                onPlaybackSpeedUpdate={setPlaybackSpeed}
                alwaysShowDot
                isFullControl={false}
                startOnLoad={false}
                className={'card--no-top-pad'}
              />
            </>
          ) : (
            <div className="noise--loading">
              <div className="graph--loading" />
              <div className="playback-control--loading" />
            </div>
          )}
        </div>
        {noiseDetails && <DescriptionList items={items} loading={!noiseDetails.hasData} />}
      </Card>
    </div>
  );
};
