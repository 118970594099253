// * Infringement Actions
import { infringementsActionTypes, filterActionTypes } from 'src/@infringements/actionTypes';
import { IInfringementSummariesByTimeRange, ITableFilterItem } from 'src/@infringements/interfaces';

export const infringementsFetchedAction = (data: IInfringementSummariesByTimeRange) => ({
  type: infringementsActionTypes.INFRINGEMENTS_FETCHED,
  data,
});

export const getCountAction = data => ({
  type: infringementsActionTypes.GET_TOTAL_COUNT,
  data,
});

export const mapStyleFetchedAction = (data: any) => ({
  type: infringementsActionTypes.MAP_STYLE_FETCHED,
  data,
});

// Table Actions
export const selectRowAction = (data: number[]) => ({
  type: infringementsActionTypes.SELECT_ROW,
  data,
});

export const loadMoreAction = () => ({
  type: infringementsActionTypes.LOAD_MORE,
});

export const sortTableAction = (data: any) => ({
  type: infringementsActionTypes.SORT_TABLE,
  data,
});

export const inlineEditAction = (id: number, status: string) => ({
  type: infringementsActionTypes.INLINE_EDIT_STATUS,
  data: {
    id,
    status,
  },
});

// Filter Actions

export const resetDataAction = () => ({
  type: infringementsActionTypes.RESET_DATA,
});

export const updateACIDFilterValueAction = (data: any) => ({
  type: filterActionTypes.UPDATE_ACID_FILTER,
  data,
});

export const updateSelectedItemsAction = (category: string, selectedItems: ITableFilterItem[]) => ({
  type: filterActionTypes.UPDATE_SELECTED_ITEMS,
  category,
  selectedItems,
});

export const updateTimeFilterValueAction = (data: any) => ({
  type: filterActionTypes.UPDATE_TIME_FILTER_VALUE,
  data,
});

export const updateTimeFilterInputAction = (data: any) => ({
  type: filterActionTypes.UPDATE_TIME_FILTER_INPUT,
  data,
});

export const clearSelectedItemsAction = () => ({
  type: filterActionTypes.CLEAR_SELECTED_ITEMS,
});

export const initialiseFilterAction = (infringementFilterData, correlatedIds) => ({
  type: filterActionTypes.INITIALISE_STORE,
  infringementFilterData,
  correlatedIds,
});
