import React, { useEffect, useState } from 'react';
import ApolloClient from 'apollo-client';
import { ITableSelectors } from 'src/@settings/interfaces';
import { TableFilter } from 'src/components';
import { ColumnHeader } from 'src/components';
import {
  ITableColumnData,
  ITableFilterObject,
  ISortObject,
} from 'src/components/TableComponents/interfaces';
import { TableColumnHeader } from 'src/components/TableComponents/TableColumnHeader';
import { DESC, TABLE_FIELD_TYPES } from 'src/constants';
// libraries
import uuid from 'uuid';
import { useClearTableFilters, useInitializeTableFilter } from './tableFilterHelpers';

/*
 * Creates table headers that are sortable

// TODO - replace table headers using this hook with formatTableHeader hook
 */
export const formatHeaders = (
  moduleName: string, // Name of module - used so correct action is called
  headerItems: ITableColumnData[], // The number of items returned when sort is selected
  resultSize: number, // The number of items returned when sort is selected
  dispatcher: any, // Dispatcher to be called on sort
  sortAction: any, // The action called sort
  tableSelectors: ITableSelectors, // The selector for table redux state
  translationData: Record<string, any>, //  Translation data
  translationModuleName: string, //  Name of module for header title translation
  isLoading?: boolean, //  Boolean value if the table data is being load
  isSettingsTable?: boolean // Optional boolean value for if it is table in settings
): Record<string, JSX.Element> => {
  const {
    fields: { [translationModuleName]: rowHeaders },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const headersObject = headerItems.reduce(
    (acc, headerItem) => ({
      ...acc,
      [headerItem.columnName]: ColumnHeader({
        sortName: headerItem.key,
        sortable: headerItem.key ? true : false,
        showSortIcon: headerItem.key ? true : false,
        sortTable: sortAction,
        sortSelectors: tableSelectors,
        resultSize,
        isLoading,
        dispatcher,
        moduleName,
        isSettingsTable,
        languageData: {
          title: headerItem.title,
          abbreviation: headerItem.abbreviation ? headerItem.abbreviation : null,
          sortBy,
        },
      }),
    }),
    {}
  );

  return Object.assign({}, rowHeaders, headersObject);
};

// TODO - refactor format table headers with hook useFormatTableHeader
export const formatTableHeaders = ({
  headerItems,
  resultSize,
  dispatcher,
  sortAction,
  sortObjectSelector,
  translationData,
  translationModuleName,
  isLoading,
  client,
  filterObject,
  beforeSort,
}: {
  headerItems: ITableColumnData[]; // The number of items returned when sort is selected
  resultSize?: number; // The number of items returned when sort is selected
  dispatcher?: any; // Dispatcher to be called on sort
  sortAction: any; // The action called sort
  sortObjectSelector: object;
  translationData: Record<string, any>; //  Translation data
  translationModuleName: string; //  Name of module for header title translation
  isLoading?: boolean; //  Boolean value if the table data is being load
  client?: ApolloClient<object>;
  filterObject?: ITableFilterObject;
  beforeSort?: () => void;
}): Record<string, JSX.Element> => {
  const {
    fields: { [translationModuleName]: rowHeaders },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const headersObject = headerItems.reduce(
    (acc, headerItem) => ({
      ...acc,
      [headerItem.columnName]: TableColumnHeader({
        sortName: headerItem.key,
        sortable: headerItem.key && !headerItem.unSortable ? true : false,
        showSortIcon: headerItem.key ? true : false,
        sortAction,
        sortObjectSelector,
        resultSize,
        isLoading,
        dispatcher,
        languageData: {
          title: headerItem.title,
          abbreviation: headerItem.abbreviation ? headerItem.abbreviation : null,
          sortBy,
        },
        client,
        filterObject,
        beforeSort,
        titleIcon: headerItem.titleIcon,
      }),
    }),
    {}
  );

  return Object.assign({}, rowHeaders, headersObject);
};

export const addNewTableIds = data =>
  data.map(item => ({
    ...item,
    tableId: uuid.v4(),
  }));

/*
 * Sorts table data based on sort object - must pass in fieldName type param object
 */

export const sortTableData = (data, sortObject) => {
  const { field: fieldName, direction }: { field: string; direction: string } = sortObject;
  if (fieldName) {
    // String sorting
    let sortedData = data.sort((item1, item2) => {
      if (TABLE_FIELD_TYPES.STRING.includes(fieldName)) {
        return `${item1[fieldName]}`.localeCompare(item2[fieldName]);
        // Date Time fields
      } else if (TABLE_FIELD_TYPES.DATE_TIME.includes(fieldName)) {
        const startDate = new Date(item1[fieldName]);
        const endDate = new Date(item2[fieldName]);
        return startDate.getTime() - endDate.getTime();

        // Boolean values
      } else if (TABLE_FIELD_TYPES.BOOLEAN.includes(fieldName)) {
        // true values first
        return item1[fieldName] === item2[fieldName] ? 0 : item1[fieldName] ? 1 : -1;
      } else if (TABLE_FIELD_TYPES.NUMBER.includes(fieldName)) {
        return parseFloat(item1[fieldName]) - parseFloat(item2[fieldName]);
      } else {
        console.error(`Column sorting type for ${fieldName} is not defined`);
      }
    });
    // If the direction is DESC - reverse the array
    if (direction === DESC) {
      sortedData = sortedData.reverse();
    }
    return addNewTableIds(sortedData);
  } else {
    return data;
  }
};

// Returns an updated column sort object for a table
export const setTableSortFieldAndDirection = (
  columnName: string,
  tableSortObject: ISortObject
): ISortObject => {
  if (tableSortObject.field === columnName) {
    const newSortObject: ISortObject = tableSortObject;
    // Changes the direction
    newSortObject.direction = tableSortObject.direction === 'ASC' ? 'DESC' : 'ASC';

    return newSortObject;
  } else {
    // A new column has been selected so the sortObject for that table will be set to the new column
    return { field: columnName, direction: 'ASC' };
  }
};

export const getSortString = (sortObject: ISortObject) =>
  `sort: [{field:"${sortObject.field}", direction:${sortObject.direction}}]`;

/*
  This hook is depreciated - use the hooks from tableFilterHelpers instead
*/
export const useTableFilter = ({
  tableColumnData,
  tableData,
  dispatcher,
  onFilterAction,
  isTableLoading,
  tableFilterObject,
  setTableFilterObject,
  defaultFilterString = '',
  dateFilter,
  sortString,
  beforeFilter,
}: {
  tableColumnData: ITableColumnData[];
  tableData: any[];
  dispatcher?: any;
  onFilterAction?: any;
  isTableLoading: boolean;
  tableFilterObject;
  setTableFilterObject: any;
  defaultFilterString?: string;
  dateFilter?: {
    from: Date;
    to: Date;
  };
  sortString?: string;
  beforeFilter?: () => void;
}): {
  tableFilter: JSX.Element;
  tableFilterObject: ITableFilterObject;
} => {
  const [areFilterOptionsInitialized, setAreFilterOptionsInitialized] = useState<boolean>(false);

  // If a date filter object is passed, we want to rebuild filter values when it changes.
  useEffect(() => {
    setAreFilterOptionsInitialized(false);
  }, [dateFilter]);

  const { initializedTableFilter } = useInitializeTableFilter(
    isTableLoading,
    tableData,
    tableColumnData
  );

  useEffect(() => {
    if (!areFilterOptionsInitialized) {
      if (!isTableLoading && initializedTableFilter) {
        setTableFilterObject(initializedTableFilter);
        setAreFilterOptionsInitialized(true);
      } else {
        // Displays empty filters while table and table filters are initializing
        setTableFilterObject(initializedTableFilter);
      }
    }
  }, [initializedTableFilter, isTableLoading]);

  const onClearFilters = useClearTableFilters(
    tableFilterObject,
    setTableFilterObject,
    beforeFilter
  );

  const tableFilter = (
    <TableFilter
      onFilterAction={onFilterAction}
      dispatcher={dispatcher}
      tableFilterObject={tableFilterObject}
      defaultFilterString={defaultFilterString}
      isLoading={isTableLoading}
      areFilterOptionsInitialized={areFilterOptionsInitialized}
      setTableFilterObject={setTableFilterObject}
      onClearFilters={onClearFilters}
      sortString={sortString}
      beforeFilter={beforeFilter}
    />
  );

  return { tableFilter, tableFilterObject };
};

export const resetSelectedItems = (tableFilter: ITableFilterObject | null) => {
  if (!!tableFilter) {
    return {
      ...tableFilter,
      filters: tableFilter.filters.map(filter => ({
        ...filter,
        selectedItems: [],
      })),
    };
  } else {
    return null;
  }
};
// TABLE SELECTION

// when storing selected rows - container keeps an array of row keys (unqiue identifiers) that way correct selecton is maintained on sort and filter
// finds the indexes of the selected items and returns those index directly into table
export const getSelectedIndexesFromKeys = (selectedKeys: string[], allTableKeys: string[]) => {
  const indexes: number[] = [];
  if (selectedKeys && selectedKeys.length) {
    selectedKeys.forEach((key: string) => {
      const index: number = allTableKeys.indexOf(key);
      if (index !== -1) {
        indexes.push(index);
      }
    });
  }

  return indexes.sort();
};
