// Apollo
import gql from 'graphql-tag';

export const GET_SCENARIO_FILTER_OPTIONS = gql`
  query getScenarioFilterOptions($modelType: AircraftNoiseModelType) {
    aircraftNoiseModelingRoutes {
      airportId
      groupName
      id
      name
      operationType
    }
    noiseModelingAircraftTypes(modelType: $modelType) {
      aircraftId
      aircraftName
      noiseModelType
      id
      maxStageLength
      supportsMaxMtowStageLength
    }
  }
`;

export const GET_SCENARIO_MODEL_TYPE = gql`
  query getNoiseModelingScenarioModelType($id: ID) {
    aircraftNoiseModelingScenario(id: $id) {
      noiseModelType
      name
      createTime
    }
  }
`;

export const GET_SCENARIO_COUNTS = gql`
  query getNoiseModelingScenarioCounts($id: ID) {
    aircraftNoiseModelingScenario(id: $id) {
      dayCount
      nightCount
      eveningCount
      totalCount
    }
  }
`;

export const GET_NOISE_SCENARIO_RECORDS = gql`
  query getNoiseModelingScenarios(
    $scenarioId: ID!
    $first: Int
    $after: String
    $sort: [OrderByType]
    $filter: AircraftNoiseModelingScenarioRecordSummaryFilter
  ) {
    aircraftNoiseModelingScenarioRecordSummaries(
      scenarioId: $scenarioId
      first: $first
      after: $after
      sort: $sort
      filter: $filter
    ) {
      totalCount
      ...noiseModelingScenariosPageInfo
      ...noiseModelingScenariosPageEdges
    }
  }

  fragment noiseModelingScenariosPageInfo on AircraftNoiseModelingScenarioRecordSummaryConnection {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }

  fragment noiseModelingScenariosPageEdges on AircraftNoiseModelingScenarioRecordSummaryConnection {
    edges {
      node {
        id
        runwayName
        stageLength
        modelingAircraftName
        modelingAircraftId
        dayCount
        nightCount
        eveningCount
        modelingRouteName
        modelingRouteId
        operationType
        airportId
      }
    }
  }
`;

export const ADD_NOISE_SCENARIO_RECORD = gql`
  mutation addNoiseScenarioRecord($record: InputAircraftNoiseModelingScenarioRecord!) {
    addAircraftNoiseModelingScenarioRecord(record: $record) {
      id
      modelingAircraftId
      modelingRouteId
      stageLength
      dayCount
      nightCount
      eveningCount
    }
  }
`;

export const UPDATE_NOISE_SCENARIO_RECORDS = gql`
  mutation editScenarioRecords(
    $ids: [ID]
    $modelingAircraftId: String
    $stageLength: String
    $modelingRouteId: Int
    $dayCount: Float
    $eveningCount: Float
    $nightCount: Float
  ) {
    updateAircraftNoiseModelingScenarioRecords(
      ids: $ids
      modelingAircraftId: $modelingAircraftId
      stageLength: $stageLength
      modelingRouteId: $modelingRouteId
      dayCount: $dayCount
      eveningCount: $eveningCount
      nightCount: $nightCount
    ) {
      id
    }
  }
`;

export const EXPORT_NOISE_SCENARIO = gql`
  query exportNoiseScenarioRecord(
    $scenarioId: ID!
    $adjustments: InputAircraftNoiseModelingScenarioExportAdjustments
  ) {
    exportAircraftNoiseModelingScenario(scenarioId: $scenarioId, adjustments: $adjustments)
  }
`;

export const GET_EXPORTED_NOISE_SCENARIO = gql`
  query getExportNoiseScenarioRecordStatus($exportKey: String!) {
    exportResult(key: $exportKey) {
      key
      status
      downloadResource {
        authenticationScheme
        uri
      }
    }
  }
`;
