export const airQualitySummaryTableByTimeRangeQuery = 'airQualitySummaryTableByTimeRange';
export const airQualitySummaryTableByTimeRangeSchema = `{
  measures
  rowGrouping
  columnGrouping
  columns {
    name
    rows{
      measure
      percentage
      values
      total
    }
  }
}`;
