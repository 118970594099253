import React from 'react';
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react';
import { alert } from 'src/utils';

export const PowerBi = ({
  id,
  embedUrl,
  accessToken,
  layoutType,
  loaded = () => { },
  rendered = () => { },
}: {
  id: string;
  embedUrl: string;
  accessToken: string;
  layoutType: number;
  loaded?: () => void;
  rendered?: () => void;
}) => {
  return (
    <PowerBIEmbed
      cssClassName={'power-bi-report'}
      embedConfig={{
        type: EmbedType.Report,
        id,
        embedUrl,
        accessToken,
        tokenType: 1,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          layoutType,
          background: 1,
        },
      }}
      eventHandlers={
        new Map([
          [
            'loaded',
            () => {
              if (loaded) {
                loaded();
              }
            },
          ],
          [
            'rendered',
            () => {
              if (rendered) {
                rendered();
              }
            },
          ],
          [
            'error',
            event => {
              alert('failedToGetResult');
              console.log('error', event.detail);
            },
          ],
        ])
      }
    />
  );
};
