import { useMutation } from '@apollo/react-hooks';
import { displayToast } from '@ems/client-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import { MutationFunction } from '@apollo/react-common';
import {
  IAddNewDeviceLocationData,
  IAddNewDeviceLocationVariables,
  IDeviceManagementForm,
  IMonitorLocation,
  IUpdateDeviceConfigData,
  IUpdateDeviceConfigVariables,
  IUpdateDeviceData,
  IUpdateDeviceLocationConfigData,
  IUpdateDeviceLocationConfigVariables,
  IUpdateDeviceLocationData,
  IUpdateDeviceLocationVariables,
  IUpdateDeviceVariables,
} from '../../interfaces';
import {
  ADD_NEW_DEVICE_LOCATION,
  UPDATE_DEVICE,
  UPDATE_DEVICE_LOCATION_CONFIG,
  UPDATE_NOISE_DEVICE_CONFIG,
  UPDATE_NOISE_DEVICE_LOCATION,
} from '../../queries';
import { onSaveSuccessAction } from '../helpers/actions';
import { useUnitConversion } from '../../Hooks/useUnitConversion';
import { useDeviceMonitorLocation } from './useDeviceMonitorLocation';
import { removeAllTypenameProps } from 'src/utils';
import { useFetchLocationsList } from './useFetchLocationsList';
import { useIsEditing } from './useIsEditing';

export const useUpdateDevice = ({
  deviceId,
  initialFormData,
}: {
  deviceId: number;
  initialFormData: IDeviceManagementForm;
}) => {
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      errors: { deviceManagementUpdateError: updateErrorString },
      success: { deviceManagementUpdateSuccess: updateSuccessString },
    },
  } = languageSelector.getLanguage();
  const [mutationError, setMutationError] = useState(false);

  // Update location
  const [saveDeviceLocation, { loading: saveDeviceLocationLoading }] = useMutation<
    IUpdateDeviceLocationData,
    IUpdateDeviceLocationVariables
  >(UPDATE_NOISE_DEVICE_LOCATION, {
    onError: () => {
      setMutationError(true);
    },
    onCompleted: () => {
      onSaveSuccessAction({
        mutationError,
        updateSuccessString,
      });
    },
  });

  // Update device location and active state
  const [saveDevice, { loading: saveDeviceLoading }] = useMutation<
    IUpdateDeviceData,
    IUpdateDeviceVariables
  >(UPDATE_DEVICE, {
    onError: error => {
      if (error) {
        setMutationError(true);
      }
    },
    onCompleted: () => {
      onSaveSuccessAction({
        mutationError,
        updateSuccessString,
      });
    },
  });

  // Update device config (calibration times)
  const [saveDeviceConfig, { loading: saveDeviceConfigLoading }] = useMutation<
    IUpdateDeviceConfigData,
    IUpdateDeviceConfigVariables
  >(UPDATE_NOISE_DEVICE_CONFIG, {
    onError: () => {
      setMutationError(true);
    },
    onCompleted: () => {
      onSaveSuccessAction({
        mutationError,
        updateSuccessString,
      });
    },
  });

  const [saveDeviceLocationConfig, { loading: saveDeviceLocationConfigLoading }] = useMutation<
    IUpdateDeviceLocationConfigData,
    IUpdateDeviceLocationConfigVariables
  >(UPDATE_DEVICE_LOCATION_CONFIG, {
    onError: () => {
      setMutationError(true);
    },
    onCompleted: () => {
      onSaveSuccessAction({
        mutationError,
        updateSuccessString,
      });
    },
  });

  // Add new location
  const [addDeviceLocation, { loading: addDeviceLocationLoading }] = useMutation<
    IAddNewDeviceLocationData,
    IAddNewDeviceLocationVariables
  >(ADD_NEW_DEVICE_LOCATION, {
    onError: () => {
      setMutationError(true);
    },
    onCompleted: () => {
      onSaveSuccessAction({
        mutationError,
        updateSuccessString,
      });
    },
  });

  useEffect(() => {
    if (mutationError) {
      displayToast({
        key: 'updateErrorToast',
        message: updateErrorString,
        intent: 'danger',
        timeout: 5000,
        onDismiss: () => {
          setMutationError(false);
        },
      });
    }
  }, [mutationError]);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const updateDeviceData = useUpdateDeviceAction({
    initialFormData,
    addDeviceLocation,
    saveDevice,
    saveDeviceLocation,
    saveDeviceConfig,
    saveDeviceLocationConfig,
    deviceId,
    setIsUpdating,
  });

  const isLoadingMutation =
    saveDeviceConfigLoading ||
    saveDeviceLoading ||
    addDeviceLocationLoading ||
    saveDeviceLocationLoading ||
    saveDeviceLocationConfigLoading ||
    isUpdating;
  return {
    isLoadingMutation,
    updateDeviceData,
  };
};
/*
  issue with saveDeviceLocationConfig after adding a new location id
  even though the data is getting returned with a newly added location id 
  when trying to save the deviceLocationConfig it returns a 400 and the error is that
  there is no device attached.

  The solution is to set a small time out between calls
*/
const UPDATE_TEMPLATE_TIMEOUT = 500;

const useUpdateDeviceAction = ({
  initialFormData,
  addDeviceLocation,
  saveDevice,
  saveDeviceLocation,
  saveDeviceConfig,
  saveDeviceLocationConfig,
  deviceId,
  setIsUpdating,
}: {
  saveDeviceLocation: MutationFunction<IUpdateDeviceLocationData, IUpdateDeviceLocationVariables>;
  saveDeviceConfig: MutationFunction<IUpdateDeviceConfigData, IUpdateDeviceConfigVariables>;
  addDeviceLocation: MutationFunction<IAddNewDeviceLocationData, IAddNewDeviceLocationVariables>;
  saveDevice: MutationFunction<IUpdateDeviceData, IUpdateDeviceVariables>;
  saveDeviceLocationConfig: MutationFunction<
    IUpdateDeviceLocationConfigData,
    IUpdateDeviceLocationConfigVariables
  >;
  deviceId: number;
  initialFormData: IDeviceManagementForm;
  setIsUpdating;
}) => {
  const updateLocationTemplates = useUpdateLocationTemplateCallback(saveDeviceLocationConfig);
  const { convertAltitudeToBackEnd } = useUnitConversion();
  const { toggleIsEditing } = useIsEditing();
  const updateMonitorLocation = useUpdateMonitorLocationInState();
  return useCallback(
    async values => {
      setIsUpdating(true);
      const convertedValues = {
        ...values,
        position: {
          ...values.position,
          altitude: String(convertAltitudeToBackEnd(Number(values.position.altitude) ?? 0)),
        },
      };
      const {
        id: deviceId,
        position,
        name,
        description,
        locationId,
        isActive,
        calibrationTimes,
        templates,
        airportId,
        trigger,
      } = convertedValues;

      const updatedLocation = {
        id: locationId,
        name,
        description,
        position,
        airportId,
        configuration: { noiseEventTemplates: templates },
      };

      const addNewLocation = async () => {
        if (locationId === undefined) {
          addDeviceLocation({
            variables: {
              location: {
                id: null,
                facilityId: 'KJFK',
                name,
                description,
                position: {
                  latitude: Number(position.latitude),
                  longitude: Number(position.longitude),
                  altitude: Number(position.altitude),
                },
              },
            },
            update: async (_cache, result) => {
              // Save new location to device
              const { id: updatedLocationId } = result.data.addNoiseDeviceLocation;
              saveDevice({
                variables: {
                  device: {
                    id: deviceId,
                    locationId: updatedLocationId,
                  },
                },
              }).catch(err => {
                console.error(err);
              });
              const newLocationDevice = result.data.addNoiseDeviceLocation;
              await updateLocationTemplates(newLocationDevice, templates, trigger, true);
            },
          }).catch(err => {
            console.error(err);
          });
        }
        return;
      };
      const updateDeviceFunction = async () => {
        // If DEVICE location changed
        if (locationId !== initialFormData.locationId && !!locationId) {
          saveDevice({
            variables: {
              device: {
                id: deviceId,
                locationId,
              },
            },
          }).catch(err => {
            console.error(err);
          });

          updateMonitorLocation(updatedLocation);
        }

        // If LOCATION name or description changed
        if (
          (name !== initialFormData.name || description !== initialFormData.description) &&
          !!locationId
        ) {
          saveDeviceLocation({
            variables: {
              location: {
                id: locationId,
                name,
                description,
              },
            },
          }).catch(err => {
            console.error(err);
          });
          updateMonitorLocation(updatedLocation);
        }

        // If DEVICE template or trigger type changes
        if (
          (JSON.stringify(templates) !== JSON.stringify(initialFormData.templates) ||
            trigger !== initialFormData.trigger) &&
          !!locationId
        ) {
          updateLocationTemplates(updatedLocation, templates, trigger);
        }
        return;
      };

      // If DEVICE calibration times changes
      const updateCalibrations = async () => {
        if (JSON.stringify(calibrationTimes) !== JSON.stringify(initialFormData.calibrationTimes)) {
          // If any 'empty' times are entered, ensure they are at the end
          const formattedCalibrationTimes = calibrationTimes
            .map(time => Number(time) || null)
            .sort((a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
            });

          const CALIBRATION_TIME_ARRAY_LENGTH = 4;
          // There is an issue where if you add a new location and change the calibrations at the
          // same time it fails because calibration time array length is only the values entered not the empty ones
          const calibrationTimesToSave = formattedCalibrationTimes.concat(
            new Array(CALIBRATION_TIME_ARRAY_LENGTH - formattedCalibrationTimes.length).fill(null)
          );

          saveDeviceConfig({
            variables: {
              configuration: {
                deviceId,
                calibrationTimes: calibrationTimesToSave,
              },
            },
          }).catch(err => {
            console.error(err);
          });
        }
        return;
      };

      const setIsActive = async () => {
        if (isActive !== initialFormData.isActive) {
          saveDevice({
            variables: {
              device: {
                id: deviceId,
                isActive,
              },
            },
          }).catch(err => {
            console.error(err);
          });
        }
        return;
      };
      const addedLocation = await addNewLocation();
      const updatedDevice = await updateDeviceFunction();
      const updatedCalibration = await updateCalibrations();
      const updatedIsActive = await setIsActive();
      Promise.all([addedLocation, updatedDevice, updatedCalibration, updatedIsActive]).then(() => {
        // Not the best solution - but the fields momentarily get set blank then repopulated
        // So everything is in a loading state until all the data has been set
        setTimeout(() => {
          setIsUpdating(false);
          toggleIsEditing();
        }, UPDATE_TEMPLATE_TIMEOUT * 2);
      });
    },

    [updateLocationTemplates, convertAltitudeToBackEnd, initialFormData, deviceId]
  );
};

const useUpdateLocationTemplateCallback = (
  saveDeviceLocationConfig: MutationFunction<
    IUpdateDeviceLocationConfigData,
    IUpdateDeviceLocationConfigVariables
  >
) => {
  const updateMonitorLocation = useUpdateMonitorLocationInState();
  return useCallback(
    async (monitorLocation: IMonitorLocation, templates, trigger, isNew = false) => {
      const templateData = templates.map(
        ({ activationTime, endEventDuration, maxEventDuration, minEventDuration, threshold }) => ({
          activationTime,
          endEventDuration,
          maxEventDuration,
          minEventDuration,
          threshold,
          trigger,
        })
      );
      setTimeout(() => {
        saveDeviceLocationConfig({
          variables: {
            configuration: {
              locationId: monitorLocation.id,
              noiseEventTemplates: templateData,
            },
          },
        })
          .then(response => {
            const newDeviceLocationConfig = removeAllTypenameProps(
              response.data.setNoiseDeviceLocationConfiguration
            );
            const updatedMonitorLocation = {
              ...monitorLocation,
              configuration: { noiseEventTemplates: newDeviceLocationConfig.noiseEventTemplates },
            };
            updateMonitorLocation(updatedMonitorLocation, isNew);
          })
          .catch(err => {
            console.error(err);
          });
      }, UPDATE_TEMPLATE_TIMEOUT);
    },
    [saveDeviceLocationConfig, updateMonitorLocation]
  );
};

const useUpdateMonitorLocationInState = () => {
  const { updateMonitorLocation } = useDeviceMonitorLocation();
  const { addNewMonitorLocation, updateMonitorLocationList } = useFetchLocationsList();
  return useCallback(
    (updatedMonitorLocation, isNew = false) => {
      updateMonitorLocation(updatedMonitorLocation);
      if (isNew) {
        addNewMonitorLocation(updatedMonitorLocation);
      } else {
        updateMonitorLocationList(updatedMonitorLocation);
      }
    },
    [updateMonitorLocation, addNewMonitorLocation, updateMonitorLocation]
  );
};
