import { useContext } from 'react';
import uuid from 'uuid';
import { IDataSelectors, IDataState, IActions } from 'src/@complaints/interfaces';
import { ComplaintsStateContext } from 'src/@complaints/providers/ComplaintsStateProvider';
import { useSelectors } from 'src/utils/storeHelpers';
import { actionTypes } from 'src/@complaints/actionTypes';
// function
import { setSelectionDelta, getMapDataFromSelectedIds } from 'src/utils';

export const useDataSelectors: () => IDataSelectors = () => {
  const state: any = useContext(ComplaintsStateContext);
  const dataState: IDataState = state.data;

  return useSelectors(dataState, (state: IDataState) => ({
    getData: () => state.data,
    getSelectedRows: () => state.selectedInTable,
    getIfAllRowsAreSelected: () => areAllRowsSelected(state),
    getTotalCount: () => state.totalCount,
    getPageInfo: () => state.pageInfo,
    getIfLoading: () => state.isLoading || state.isLoadingMore,
    getIfLoadingMore: () => state.isLoadingMore,
    getSelectedAddress: () => state.selectedAddress,
    getComplaint: () => state.complaint,
    getNavigationData: () => {
      const itemsIds: number[] = [];
      // convert map selection to an array of IDs
      state.data.forEach((value, key) => {
        itemsIds.push(key);
      });
      return {
        itemsIds,
        hasNextPage: state.pageInfo ? state.pageInfo.hasNextPage : false,
        endCursor: state.pageInfo ? state.pageInfo.endCursor : undefined,
        dateRange: state.selectedDateRange,
      };
    },
  }));
};

const areAllRowsSelected = state => {
  if (state.data.size && state.selectedInTable && state.selectedInTable.length) {
    if (state.data.size === state.selectedInTable.length) {
      return true;
    } else {
      return 'indeterminate';
    }
  }
  return false;
};

const setLocalSelectionData = (state, ids) => {
  const { removedFromSelection, addedToSelection } = setSelectionDelta(ids, [
    ...state.selectedInTable,
  ]);

  state.removedFromSelection = removedFromSelection.map(
    getMapDataFromSelectedIds(state.data, 'id')
  );
  state.addedToSelection = addedToSelection.map(getMapDataFromSelectedIds(state.data, 'id'));
};

export const dataInitialState: IDataState = {
  data: new Map(),
  selectedInTable: [],
  totalCount: -1,
  pageInfo: null,
  isLoading: false,
  isLoadingMore: false,
  selectedAddress: {},
  complaint: {},
  selectedDateRange: null,
};

const addDisplayData = (currentData, newData: any) => {
  const data = new Map(currentData);
  for (const complaint of newData) {
    data.set(complaint.id, {
      ...complaint,
      tableId: uuid.v4(),
    });
  }
  return data;
};

export const dataReducer = (state: IDataState, action: IActions) => {
  switch (action.type) {
    case actionTypes.LOAD_MORE: {
      return Object.assign({}, state, {
        isLoadingMore: true,
      });
    }
    case actionTypes.GET_DATA:
      const { data, selectedDateRange, totalCount, pageInfo } = action.data;;

      const itemsMap = addDisplayData(state.data, data);
      return Object.assign({}, state, {
        data: itemsMap,
        totalCount,
        pageInfo,
        isLoading: false,
        isLoadingMore: false,
        selectedDateRange
      });
    case actionTypes.SELECT_ROW:
      const operationsForMap = action.data.map(id => state.data.get(id));
      setLocalSelectionData(state, action.data);
      return Object.assign({}, state, {
        selectedInTable: action.data,
        selectedComplaints: operationsForMap,
      });
    case actionTypes.INLINE_EDIT_STATUS: {
      const { id, status } = action.data;
      const clone = new Map(state.data);
      const foundData: any = clone.get(id);
      if (foundData) {
        clone.set(id, {
          ...foundData,
          status,
          tableId: uuid.v4(),
        });

        return Object.assign({}, state, {
          data: clone,
        });
      }
    }
    case actionTypes.GET_TOTAL_COUNT: {
      return Object.assign({}, state, {
        totalCount: action.data.totalCount,
      });
    }
    case actionTypes.RESET_DATA: {
      return Object.assign({}, dataInitialState, {
        isLoading: true,
      });
    }
    case actionTypes.SELECT_ADDRESS:
      return Object.assign({}, state, { selectedAddress: action.data });
    case actionTypes.UPDATE_COMPLAINT:
      return Object.assign({}, state, { complaint: { ...state.complaint, ...action.data } });
    default:
      return state;
  }
};
