import gql from 'graphql-tag';
import config from 'src/app/config.json';
// schema
import { configQuery, unitTestNodeInConfig } from 'src/app/resolvers/schema';
// functions
import { parseQueryString } from 'src/app/functions/queryString';
import { deepMerge } from 'src/utils/objectModifiers';

export const fetchConfigData = client => {
  return new Promise((resolve, reject) => {
    const GET_CONFIGURATION = gql`
      query {
          ${configQuery}
      }
    `;
    // fetch query
    client
      .query({ query: GET_CONFIGURATION })
      .then(response => {
        if (response && response.data && response.data[configQuery]) {
          const data = response.data[configQuery];
          // remove unittesting values inserted to the db
          delete data[unitTestNodeInConfig];
          // start of temporary language testing solution
          // TODO: remove reading "language" from query string when we don't need it anymore. All the config values should come from graphql
          // check if new language requested
          const { language } = parseQueryString(window.location.search);
          if (
            language &&
            typeof data.globals !== undefined &&
            typeof data.globals.languageTag !== undefined
          ) {
            data.globals.languageTag = language;
          }
          // end of temporary language testing solution
          // merge result with the local config (overwrite local) so we have the latest from the backend
          resolve(deepMerge(config, data, {}));
        } else {
          reject('Incorrect response');
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
