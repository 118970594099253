import React, { createContext, useReducer } from 'react';
import { IStoreState } from 'src/app/props';
import {
  configReducer,
  infringementRulesReducer,
  filterDataReducer,
  rolesReducer,
  scenariosReducer,
  languageReducer,
  pushNoticesReducer,
} from 'src/app/reducers';
import {
  configInitialState,
  infringementRulesInitialState,
  filtersInitialState,
  rolesInitialState,
  scenariosInitialState,
  languageInitialState,
  userHelpInitialState,
} from 'src/app/reducers/initialStates';

import { GlobalStateContext, PushNoticesStateContext } from './GlobalStateContext';

export const GlobalDispatchContext = createContext(null);
export const PushNoticesDispatchContext = createContext(null);

export const GlobalStateProvider = ({ children }) => {
  const [configState, configDispatch]: any = useReducer(configReducer, configInitialState);
  const [filtersState, filtersDispatch]: any = useReducer(filterDataReducer, filtersInitialState);
  const [languageState, languageDispatch]: any = useReducer(languageReducer, languageInitialState);
  const [rolesState, rolesDispatch]: any = useReducer(rolesReducer, rolesInitialState);
  const [pushNoticesState, pushNoticesDispatch]: any = useReducer(
    pushNoticesReducer,
    userHelpInitialState
  );
  const [infringementRulesState, infringementRulesDispatch]: any = useReducer(
    infringementRulesReducer,
    infringementRulesInitialState
  );
  const [scenariosState, scenariosDispatch]: any = useReducer(
    scenariosReducer,
    scenariosInitialState
  );

  const state: IStoreState = {
    config: configState,
    filters: filtersState,
    language: languageState,
    roles: rolesState,
    infringementRules: infringementRulesState,
    scenarios: scenariosState,
  };

  const dispatchers = [
    configDispatch,
    filtersDispatch,
    languageDispatch,
    rolesDispatch,
    infringementRulesDispatch,
    scenariosDispatch,
  ];

  const dispatch: any = (action: any) =>
    dispatchers.map((dispatcher: any) => {
      dispatcher(action);
    });

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <PushNoticesStateContext.Provider value={pushNoticesState}>
          <PushNoticesDispatchContext.Provider value={pushNoticesDispatch}>
            {children}
          </PushNoticesDispatchContext.Provider>
        </PushNoticesStateContext.Provider>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
