import { useEffect, useMemo, useState } from 'react';

import { useIsDynamicTileServer } from 'src/utils/mapHelpers/hooks/useIsDynamicTileServer';
import { TrackData } from 'src/utils/mapHelpers/trackHelpers/types';
import { getSources } from '../mapApis';

// TODO - pass in selection keys as parameters so it can be used on other maps
const SELECTION_KEYS = {
  SELECTED: 'selected',
};

// External means selection outside of the map component - either through props or through tables

export const useTrackSelection = ({
  mapApis,
  externallySelectedTracks,
  rerunHook = false,
  externallyDeselectedTrackIds = [],
  externallySelectTrack,
}: {
  mapApis;
  externallySelectedTracks: TrackData[];
  rerunHook?: boolean;
  externallyDeselectedTrackIds?: number[];
  externallySelectTrack?: (trackIds: number[]) => void;
}) => {
  const [activelySelectedTracks, setActivelySelectedTracks] = useState<TrackData[]>([]);
  const [selectedOperations, setSelectedTracks] = useState<TrackData[]>([]);
  const isDynamicTileServer = useIsDynamicTileServer();

  const setSelectedOperations = (operationsTrackData: TrackData[]) => {
    const triggeredOperations = operationsTrackData.map(operation => ({
      ...operation,
      // reformat so the operation id is in the same place for all maps with or without tile server
      id: isDynamicTileServer ? operation.properties.id : operation.id,
    }));
    // Deselect when clicking on an already active track
    const deselected = selectedOperations.flatMap(({ id }) =>
      triggeredOperations.map(({ id }) => id).includes(id) ? id : []
    );
    const newlySelectedOperations = triggeredOperations.filter(
      ({ id }) => !deselected.includes(id)
    );
    const currentlySelectedOperations = Array.from(
      new Set([...newlySelectedOperations, ...selectedOperations])
    );

    if (externallySelectTrack) {
      externallySelectTrack(currentlySelectedOperations.map(({ id }) => id));
    }

    setSelectedTracks(currentlySelectedOperations);
  };

  const allSelectedTracks = useMemo(
    () => Array.from(new Set([...externallySelectedTracks, ...selectedOperations])),
    [externallySelectedTracks, selectedOperations]
  );
  const currentlySelectedOperationIds = allSelectedTracks.map(({ id }) => id);

  const deselectedTracks = useMemo(
    () =>
      activelySelectedTracks.flatMap(track =>
        !currentlySelectedOperationIds.includes(track.id) ||
        externallyDeselectedTrackIds.includes(track.id)
          ? track
          : []
      ),
    [activelySelectedTracks, allSelectedTracks, externallyDeselectedTrackIds]
  );

  useUpdateTrackFeatureState(
    allSelectedTracks,
    deselectedTracks,
    mapApis,
    setActivelySelectedTracks,
    rerunHook
  );
  return { setSelectedOperations, selectedOperations };
};

const useUpdateTrackFeatureState = (
  selectedTracks: TrackData[],
  deselectedTracks: TrackData[],
  mapApis,
  setActivelySelectedTracks,
  rerunHook: boolean
) => {
  useEffect(() => {
    if (mapApis) {
      const mapSources = getSources(mapApis);

      selectedTracks.forEach(operation => {
        const selectionValue = {};
        selectionValue[SELECTION_KEYS.SELECTED] = true;

        const object = {
          id: operation.id,
          source: operation.layer.source,
          sourceLayer: operation.layer['source-layer'],
        };

        if (Object.keys(mapSources).includes(object.source)) {
          try {
            mapApis.setFeatureState(object, selectionValue);
          } catch {
            console.error('setFeatureState', object);
          }
        }
      });

      deselectedTracks.forEach(operation => {
        const object = {
          id: operation.id,
          source: operation.layer.source,
          sourceLayer: operation.layer['source-layer'],
        };
        if (Object.keys(mapSources).includes(object.source)) {
          try {
            mapApis.removeFeatureState(object, SELECTION_KEYS.SELECTED);
          } catch {
            console.error('removeFeatureState', object);
          }
        }
      });
      setActivelySelectedTracks(selectedTracks);
    }
  }, [selectedTracks, deselectedTracks, rerunHook]);
};
