import styled from 'styled-components/macro';

export const CustomControlWrapper = styled.div`
  & button {
    width: 29px;
    height: 29px;
    display: block;
    padding: 0;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
    background: #fff;
    line-height: 0;
  }
`;
