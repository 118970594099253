import styled from 'styled-components/macro';

export const ActiveCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  &__right {
    display: table-cell;
  }
`;

export const ActiveCheckboxLeft = styled.div`
  display: table-cell;
  padding: 0 1rem 0 0;
`;

export const ActiveCheckboxRight = styled.div<{ $isEditing: boolean }>`
  margin-right: -8px;
  .bp3-control .bp3-control-indicator {
    cursor: ${({ $isEditing }) => ($isEditing ? 'pointer' : 'default')};
  }
`;
