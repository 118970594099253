import React, { useEffect, useState, useContext, useRef } from 'react';

// Reducers and Context
import { useLanguageSelectors } from 'src/app/reducers';
import {
  scenarioFilterDataContext,
  noiseScenarioRecordsContext,
  translationsContext,
} from '../../contexts';

// Components
import { Table } from '@ems/client-design-system';
import { LoadMoreBar } from 'src/components';
import { RecordTotals } from '..';

// Functions
import {
  useTableFilter,
  formatTableHeaders,
  getSelectedIndexesFromKeys,
  deepCopyObject,
} from 'src/utils';
import {
  formatScenarioTableData,
  getIdFromTableRowKey,
  validateScenarioRecord,
  addRecordError,
  removeRecordError,
} from '../../helpers';
import { columnDataConfig, updateFilterItems, getQueryFilterFromTableFilters } from './helpers';

// Variables

import { loadMorePageSize } from '../../defaults';

// Types
import { ITableFilterObject } from 'src/components/TableComponents';
import {
  INoiseScenario,
  INoiseScenarioRow,
  IPageInfo,
  ILoadMoreOptions,
  IGetNoiseScenarioRecordsData,
  IUpdateQueryOptions,
  IUpdateNoiseScenarioRecordsMutationVariables,
  INoiseScenarioRecordValues,
  IRecordErrors,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

// Styled
import { ScenarioTableWrapper } from '../../ViewScenario.styles';

interface ViewScenarioTableProps {
  isLoading: boolean;
  tableData: INoiseScenario[];
  pageInfo: IPageInfo;
  fetchMore: any;
  updateRecordsMutation: (options?: {
    variables: IUpdateNoiseScenarioRecordsMutationVariables;
  }) => void;
}

export const ViewScenarioTable = ({
  tableData,
  isLoading,
  pageInfo,
  fetchMore,
  updateRecordsMutation,
}: ViewScenarioTableProps) => {
  // Reference
  const tableRef = useRef<HTMLDivElement>(null);

  // Selectors & Contexts
  const languageSelector = useLanguageSelectors();
  const translationData = languageSelector.getLanguage();
  const { scenarioFilterData } = useContext(scenarioFilterDataContext);
  const {
    getNoiseScenarioRecordsVariables,
    setGetNoiseScenarioRecordsVariables,
    updateSelectedInTable,
    selectedTableKeys,
    updateSelectedTableKeys,
    noiseScenarioRecords,
  } = useContext(noiseScenarioRecordsContext);
  const translations = useContext(translationsContext);

  // States
  const [scenarioTableData, setScenarioTableData] = useState<INoiseScenarioRow[]>([]);
  const [tableFilterObject, setTableFilterObject] = useState<ITableFilterObject>(null);
  const [tableRowKeys, setTableRowKeys] = useState<string[]>([]);
  const [mutatedRecords, setMutatedRecords] = useState<INoiseScenario[]>([]);
  const [recordErrors, setRecordErrors] = useState<IRecordErrors>({});

  const { hasNextPage, endCursor } = pageInfo;

  // Language Strings
  const {
    components: {
      buttons: { loadMore: loadMoreText },
      hints: { tryChangingFilters },
      labels: {
        table: { endTable },
      },
    },
    screens: {
      settings: {
        notFound: {
          table: { noNoiseScenariosRecordsFound },
        },
      },
    },
  } = translationData;

  // Actions
  const onSelectRow = (indexes: number[]) => {
    const ids: string[] = [];
    const selectedKeys: string[] = [];
    indexes.forEach((index: number) => {
      const id = getIdFromTableRowKey(tableRowKeys[index]);
      const matchingRow = scenarioTableData.find(scenarioRow => scenarioRow.id === id);
      // Prevent select all from selecting disabled rows
      if (matchingRow) {
        ids.push(id);
        selectedKeys.push(tableRowKeys[index]);
      }
    });
    updateSelectedInTable(ids);
    updateSelectedTableKeys(selectedKeys);
  };

  const onEditRow = ({
    id,
    values,
    noiseScenarioRecords,
  }: {
    id: string;
    values: INoiseScenarioRecordValues;
    noiseScenarioRecords: INoiseScenario[];
  }) => {
    // Check existing mutated, and update
    const mutatedRecordIndex = mutatedRecords.findIndex(({ node }) => node.id === id);
    let record: INoiseScenario;
    if (mutatedRecords.some(({ node }) => node.id === id)) {
      const copiedMutations = deepCopyObject(mutatedRecords);

      copiedMutations[mutatedRecordIndex].node = {
        ...copiedMutations[mutatedRecordIndex].node,
        ...values,
      };
      setMutatedRecords(copiedMutations);
      record = copiedMutations[mutatedRecordIndex];
    } else {
      // Create a new mutated record
      const recordIndex = noiseScenarioRecords.find(({ node }) => node.id === id);
      const copiedRecord = deepCopyObject(recordIndex);
      copiedRecord.node = { ...copiedRecord.node, ...values };

      setMutatedRecords([...mutatedRecords, copiedRecord]);
      record = copiedRecord;
    }

    validateScenarioRecord({
      record: record.node,
      filterData: scenarioFilterData,
      translations,
    })
      .then(response => {
        const {
          modelingAircraftId,
          modelingRouteId,
          stageLength,
          dayCount,
          eveningCount,
          nightCount,
        } = response;
        const recordIndex = noiseScenarioRecords.find(({ node }) => node.id === id);
        removeRecordError({ id, errorState: recordErrors, setErrorState: setRecordErrors });
        setMutatedRecords(mutatedRecords.filter(({ node }) => node.id !== id));
        // If the mutated copy is the same as the original, dont bother updating
        if (JSON.stringify(response) !== JSON.stringify(recordIndex.node)) {
          updateRecordsMutation({
            variables: {
              ids: [response.id],
              modelingAircraftId,
              modelingRouteId,
              stageLength,
              dayCount,
              eveningCount,
              nightCount,
            },
          });
        }
      })
      .catch(errors => {
        addRecordError({ id, errors, errorState: recordErrors, setErrorState: setRecordErrors });
      });
  };

  // Takes API data and formats it for the table
  useEffect(() => {
    if (tableData) {
      const items = formatScenarioTableData({
        scenarioData: tableData,
        filterData: scenarioFilterData,
        onEditRow,
        mutatedRecords,
        recordErrors,
        noiseScenarioRecords,
      });
      setTableRowKeys(items.map(item => item.tableRowKey));
      setScenarioTableData(items);
    }
  }, [noiseScenarioRecords, tableData, scenarioFilterData, mutatedRecords, recordErrors]);

  const sortAction = ({ sortName, sortObject: { field, direction } }) => {
    setMutatedRecords([]);
    setRecordErrors({});
    updateSelectedInTable([]);
    updateSelectedTableKeys([]);
    const sortDirection = field === sortName ? (direction === 'ASC' ? 'DESC' : 'ASC') : 'DESC';

    setGetNoiseScenarioRecordsVariables({
      ...getNoiseScenarioRecordsVariables,
      sort: [
        {
          field: sortName,
          direction: sortDirection,
        },
      ],
    });
  };

  const updateQueryFilter = ({ filters }: ITableFilterObject) => {
    setGetNoiseScenarioRecordsVariables({
      ...getNoiseScenarioRecordsVariables,
      filter: { ...getQueryFilterFromTableFilters(filters) },
    });
  };

  const setTableFilters = (newTableFilters: ITableFilterObject) => {
    if ((!isLoading && !tableFilterObject) || !!tableFilterObject) {
      if (scenarioFilterData.stages) {
        const { stages } = scenarioFilterData;
        updateFilterItems(newTableFilters, 'stageLengths', [
          ...stages.map(stage => ({ label: stage, key: stage })),
        ]);
      }

      if (scenarioFilterData.operations) {
        updateFilterItems(newTableFilters, 'operationTypes', [
          ...['Arrival', 'Departure'].map(operation => ({
            label: operation,
            key: operation,
          })),
        ]);
      }

      if (scenarioFilterData.runwayNames) {
        const { runwayNames } = scenarioFilterData;
        updateFilterItems(newTableFilters, 'runwayNames', [
          ...runwayNames.map(name => ({ label: name, key: name })),
        ]);
      }
      if (scenarioFilterData.aircraftNoiseModelingRoutes) {
        const { aircraftNoiseModelingRoutes } = scenarioFilterData;
        updateFilterItems(newTableFilters, 'modelingRouteNames', [
          ...aircraftNoiseModelingRoutes.map(({ name }) => ({ label: name, key: name })),
        ]);
      }

      if (scenarioFilterData.noiseModelingAircraftTypes) {
        const { noiseModelingAircraftTypes } = scenarioFilterData;
        updateFilterItems(newTableFilters, 'modelingAircraftNames', [
          ...noiseModelingAircraftTypes.map(({ aircraftName }) => ({
            label: aircraftName,
            key: aircraftName,
          })),
        ]);
      }

      setTableFilterObject(newTableFilters);
      updateQueryFilter(newTableFilters);
    }
  };

  const { tableFilter } = useTableFilter({
    tableColumnData: columnDataConfig(translations),
    tableData,
    beforeFilter: () => {
      setMutatedRecords([]);
      setRecordErrors({});
      updateSelectedInTable([]);
      updateSelectedTableKeys([]);
    },
    isTableLoading: isLoading,
    tableFilterObject,
    setTableFilterObject: setTableFilters,
  });

  const rowHeaders = formatTableHeaders({
    headerItems: columnDataConfig(translations),
    sortAction,
    sortObjectSelector: {
      getSortObject: () => getNoiseScenarioRecordsVariables.sort[0],
    },
    translationData,
    translationModuleName: 'aircraft-assignments',
    isLoading,
  });

  const handleLoadMore = (_client, _dispatcher, options: ILoadMoreOptions) => {
    const fetchMoreArguments = {
      variables: {
        ...getNoiseScenarioRecordsVariables,
        first: loadMorePageSize,
        after: options.endCursor as string,
      },
      updateQuery: (
        {
          aircraftNoiseModelingScenarioRecordSummaries: { edges: previousEdges },
        }: IGetNoiseScenarioRecordsData,
        {
          fetchMoreResult: {
            aircraftNoiseModelingScenarioRecordSummaries: {
              pageInfo: newPageInfo,
              edges: newEdges,
              totalCount: newTotalCount,
              after: cursor,
              __typename,
            },
          },
        }: IUpdateQueryOptions
      ) => ({
        aircraftNoiseModelingScenarioRecordSummaries: {
          pageInfo: newPageInfo,
          after: cursor,
          edges: [...previousEdges, ...newEdges],
          totalCount: newTotalCount,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          __typename,
        },
      }),
    };
    void fetchMore(fetchMoreArguments);
  };

  return (
    <>
      {tableFilter}
      <ScenarioTableWrapper ref={tableRef}>
        <Table
          className={`noise-scenario-table`}
          loading={isLoading}
          rowHeaders={rowHeaders}
          data={scenarioTableData}
          wrapperClassName={'feature-wrapper'}
          columns={columnDataConfig(translations).map(({ columnName }) => columnName)}
          selectedData={getSelectedIndexesFromKeys(selectedTableKeys, tableRowKeys)}
          gridID="noise-scenario-table"
          onSelectRow={onSelectRow}
          languageData={{
            noDataTitle: noNoiseScenariosRecordsFound,
            noDataText: tryChangingFilters,
            endTable,
          }}
          hasEnded={tableData.length && !hasNextPage}
          showDashIfEmpty
        />
        <LoadMoreBar
          isVisible={hasNextPage}
          isLoadingMore={tableData.length && isLoading}
          loadMore={handleLoadMore}
          loadMoreText={loadMoreText}
          resultSize={tableData.length}
          endCursor={hasNextPage && endCursor}
          dispatcher={null}
          sortString={null}
        />
        <RecordTotals tableReference={tableRef} isLoading={isLoading} />
      </ScenarioTableWrapper>
    </>
  );
};
