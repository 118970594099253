import { useContext } from 'react';
import { useDataSelectors } from 'src/@operations/reducers';
import { TrackData } from 'src/utils/mapHelpers/trackHelpers/types';
import { OperationDispatchContext } from '../providers/OperationsStateProvider';
import { setSelectedTracks } from '../actions';
import { useOperationTrackData } from 'src/utils/mapHelpers/trackHelpers/useOperationTrackData';

export const useSelectedOperationsInTable = (
  mapApis,
  datesArray: string[],
  rerunHook: boolean
): {
  selectedOperationsInTable: TrackData[];
  setSelectedInTableCallback: (trackIds) => void;
  selectedInTable: number[];
} => {
  const dispatcher: any = useContext(OperationDispatchContext);
  const dataSelectors = useDataSelectors();
  const { selectedInTable } = dataSelectors.getDataInformation();
  const selectedOperationsInTable = useOperationTrackData(
    mapApis,
    datesArray,
    selectedInTable,
    rerunHook
  );
  const setSelectedInTableCallback = (trackIds: number[]) => {
    setSelectedTracks(trackIds, dispatcher);
  };
  return { selectedOperationsInTable, setSelectedInTableCallback, selectedInTable };
};
