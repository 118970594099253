import { debounce } from 'debounce';
// functions
import { history, getDeployedProductId, dateToStringYMd } from 'src/utils';
import { constructDateRangeQueryString } from 'src/app/functions/queryString';
// constants
import { INFRINGEMENTS } from 'src/constants';

interface IPaginationInfo {
  itemsIds: number[];
  hasNextPage: boolean;
  endCursor: null | string;
  dateRange: null | { from: Date; to: Date };
}

interface IPaginationExtended extends IPaginationInfo {
  foundItem: number;
  navigateItems: boolean;
}

const paginationInfoTemplate = {
  itemsIds: [],
  hasNextPage: false,
  endCursor: null,
  dateRange: null,
};

// sort out essentials info required for navigation funcionality
export const getPaginationInformation = (
  id: number,
  paginationInfo: IPaginationInfo
): IPaginationExtended => {
  const { itemsIds, hasNextPage, endCursor, dateRange } = paginationInfo
    ? paginationInfo
    : paginationInfoTemplate;
  const foundItem = itemsIds.indexOf(id);
  const navigateItems = foundItem !== -1 && itemsIds.length > 1;
  return {
    itemsIds,
    hasNextPage,
    endCursor,
    dateRange,
    foundItem,
    navigateItems,
  };
};

// back to the parent browser page
export const goBack = (
  path: string,
  paginationInfo: IPaginationExtended | null,
  availableRoutes: string[]
) => {
  let url = '';
  let queryString = '';

  if (paginationInfo) {
    const { dateRange } = paginationInfo;
    if (dateRange) {
      const { from, to } = dateRange;
      queryString = constructDateRangeQueryString({
        from: dateToStringYMd(from),
        to: dateToStringYMd(to),
      });
    }
  }

  if (path === INFRINGEMENTS) {
    if (availableRoutes.includes(`${path}-atc`)) {
      url = `/${getDeployedProductId()}/${path}-atc${queryString}`;
    } else if (availableRoutes.includes(`${path}-candidates`)) {
      url = `/${getDeployedProductId()}/${path}-candidates${queryString}`;
    } else {
      url = `/${getDeployedProductId()}/${path}${queryString}`;
    }
  } else {
    url = `/${getDeployedProductId()}/${path}${queryString}`;
  }
  history.push(url);
};

// go to next/previous item
export const navigate = (
  type: 'forward' | 'backward',
  path: string,
  paginationInfo: IPaginationExtended,
  callAfter: () => void | undefined
) => {
  const { itemsIds, hasNextPage, endCursor, dateRange, foundItem } = paginationInfo;
  const check = type === 'forward' ? foundItem !== itemsIds.length - 1 : foundItem !== 0;
  const id = type === 'forward' ? itemsIds[foundItem + 1] : itemsIds[foundItem - 1];
  return check
    ? debounce(() => {
        history.push(`/${getDeployedProductId()}/${path}/${id}`, {
          itemsIds,
          hasNextPage,
          endCursor,
          dateRange,
        });
        if (typeof callAfter !== 'undefined') {
          callAfter();
        }
      }, 500)
    : undefined;
};
