export const fetchUserHelpQuery = 'userHelpLinks';

export const fetchUserHelpSchema = `
{
  name
  path
  fileType
  lastModified
  downloadResource {
    uri
  }
}
`;
