import { useEffect } from 'react';
import { useTaxiPathContext } from '../../context/TaxiPathContext';

import { updateSavedGeoJSON } from '../../utils';

export const useTaxiPathData = () => {
  const {
    state: { nodeGeoJSON, pathGeoJSON },
  } = useTaxiPathContext();

  useEffect(() => {
    if (pathGeoJSON) {
      updateSavedGeoJSON<GeoJSON.LineString>('taxipath-linestring', pathGeoJSON);
    }
    if (nodeGeoJSON) {
      updateSavedGeoJSON<GeoJSON.Point>('taxipath-nodes', nodeGeoJSON);
    }
  }, [nodeGeoJSON, pathGeoJSON]);

  return { nodes: nodeGeoJSON, connectors: pathGeoJSON };
};
