// Types
import { ITableColumnData } from 'src/components';

// Variables
import { TABLE_CONSTANTS } from 'src/constants';

export const columnData = (translations): ITableColumnData[] => {
  const {
    components: { labels },
  } = translations;

  return [
    {
      columnName: 'name',
      key: 'name',
      title: labels.contour,
    },
    {
      columnName: 'noiseModelType',
      key: 'noiseModelType',
      title: labels.contourType,
      filterName: 'noiseModelTypes',
      filterOptionType: TABLE_CONSTANTS.FILTER_TYPES.STRING,
      filterOptions: { INM: 'INM', AEDT: 'AEDT' },
      filterType: 'listFilter',
    },
    {
      columnName: 'style',
      key: 'style',
      title: labels.contourColor,
      unSortable: true,
    },
    {
      columnName: 'createTime',
      key: 'createTime',
      title: labels.contourFileUpload,
    },
    {
      columnName: 'delete',
      title: '',
    },
  ];
};
