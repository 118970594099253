import { actionTypes } from 'src/@complaints/actionTypes';
import { ApolloClient } from 'apollo-client';
import { debounce } from 'debounce';
import { fetchData, getComplainersByPartialMatch } from 'src/@complaints/resolvers';
import { ResponseValidator } from 'src/utils';
import { GET_COUNT_DELAY } from 'src/constants';
import { IComplainer, IFetchDataAction, ISelectAddress } from 'src/@complaints/interfaces';
const request = new ResponseValidator();

export const getDataCount = (
  client: ApolloClient<object>,
  sortString: string,
  filterString: string,
  dispatcher: any
) => {
  const [instance, t] = request.get('inquiries-count');
  request.set(instance, t);

  fetchData({ client, sortString, filterString, count: 1 })
    .then((data: any) => {
      dispatcher({ type: actionTypes.GET_TOTAL_COUNT, data });
    })
    .catch(error => {
      console.error(error);
    });
};

const checkDataCount = (
  client: ApolloClient<object>,
  sortString: string,
  filterString: string,
  dispatcher: any
) => {
  setTimeout(() => {
    getDataCount(client, sortString, filterString, dispatcher);
  }, GET_COUNT_DELAY);
};

export const getData = ({
  client,
  sortString,
  filterString,
  dispatcher,
  cursor = '',
  resultSize,
  resetData = false,
}: IFetchDataAction) => {
  if (resetData) {
    dispatcher({ type: actionTypes.RESET_DATA });
  }

  const [instance, t] = request.get('fetch-inquiries-events');
  request.set(instance, t);

  fetchData({ client, sortString, filterString, cursor, count: resultSize })
    .then((data: any) => {
      if (!cursor) {
        checkDataCount(client, sortString, filterString, dispatcher);
      }

      if (request.isValid(instance, t)) {
        dispatcher({ type: actionTypes.GET_DATA, data });
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const sortTable = async (data: any, dispatcher: any) => {
  const { sortName } = data;
  dispatcher({ type: actionTypes.SORT_TABLE, data: sortName });
};

export const selectRow = (ids: number[], dispatcher?: any) => {
  dispatcher({ type: actionTypes.SELECT_ROW, data: ids });
};

export const loadMore = (
  client: ApolloClient<object>,
  dispatcher: any,
  options: {
    resultSize: number;
    endCursor: any;
    sortString: any;
    filterString: any;
  }
) => {
  const { resultSize, sortString, filterString, endCursor } = options;
  dispatcher({ type: actionTypes.LOAD_MORE });
  getData({
    client,
    dispatcher,
    resultSize,
    sortString,
    filterString,
    cursor: endCursor,
  });
};

export const selectAddress = (address: ISelectAddress, dispatcher?: any) => {
  dispatcher({ type: actionTypes.SELECT_ADDRESS, data: address });
};

export const updateComplaint = (complaint: any, dispatcher?: any) => {
  dispatcher({ type: actionTypes.UPDATE_COMPLAINT, data: complaint });
};
export const getComplainersByPartialFullName = debounce(
  (client: ApolloClient<object>, name: string, onResultCB: (res: IComplainer[]) => void) => {
    const [instance, t] = request.get('fetch-inquiries-events');
    request.set(instance, t);

    getComplainersByPartialMatch({
      matchString: `partialFullName: "${name}"`,
      client,
    }).then((res: IComplainer[]) => {
      if (request.isValid(instance, t)) {
        onResultCB(res);
      }
    });
  },
  250
);

export const complainersByPartialMatch = debounce(
  (client: ApolloClient<object>, matchString: string, onResultCB: (res: IComplainer[]) => void) => {
    const [instance, t] = request.get('fetch-inquirer-events');
    request.set(instance, t);

    getComplainersByPartialMatch({
      matchString,
      client,
    }).then((res: IComplainer[]) => {
      if (request.isValid(instance, t)) {
        onResultCB(res);
      }
    });
  },
  500
);

export const initialiseFilterStore = ({ dispatcher, correlatedIds }: { dispatcher: any, correlatedIds: number[] }) => {
  dispatcher({
    type: actionTypes.INITIALISE_STORE,
    data: { correlatedIds },
  });
};
