import { useCallback, useMemo } from 'react';
import { useMapboxMapContext } from '../context/useMapboxMapContext';

export const useFlyTo = () => {
  const { map } = useMapboxMapContext();
  const currentMap = useMemo(() => map?.getMap(), [map]);

  return useCallback(
    (longitude, latitude) => {
      currentMap.flyTo({
        center: [longitude, latitude],
        minZoom: 12,
        speed: 0.3,
      });
    },
    [currentMap]
  );
};
