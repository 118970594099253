import gql from 'graphql-tag';

export const TRACK_DENSITY_CREATE = gql`
  query CreateTrackDensity(
    $anomsApiQueryCursor: String!
    $latitude: Float!
    $longitude: Float!
    $height: Float!
    $width: Float!
    $resolution: Float!
  ) {
    createTrackDensity(
      filter: { anomsApiQueryCursor: $anomsApiQueryCursor }
      grid: {
        centerPosition: { latitude: $latitude, longitude: $longitude }
        height: $height
        width: $width
        resolution: $resolution
      }
    )
  }
`;

export const TRACK_DENSITY_RESULT = gql`
  query TrackDensityResult($id: String!) {
    trackDensity(id: $id) {
      id
      status
      maxOperationCellCount
      grid {
        centerPosition {
          latitude
          longitude
        }
        width
        height
        numCellsWide
        numCellsHigh
        resolution
        cornerPoints {
          latitude
          longitude
        }
      }
    }
  }
`;

export const TRACK_DENSITY_CANCEL = gql`
  mutation CancelTrackDensity($id: String!) {
    cancelTrackDensity(id: $id)
  }
`;
