import React from 'react';

// Styled
import {
  DeviceManagementCardTitle,
  DeviceManagementCardPadding,
  DeviceManagementCardSubtitle,
} from './DeviceManagementDetails.styles';
// import styles from 'src/styles/vars.module.scss';

// Components
import { Card, SkeletonText } from '@ems/client-design-system';

interface DeviceManagementCardProps {
  isLoading: boolean;
  title?: string;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
}

export const DeviceManagementCard = ({
  isLoading,
  title,
  subtitle,
  children,
}: DeviceManagementCardProps) => (
  <>
    <DeviceManagementCardTitle className="device-management__card-title">
      {title && <h4>{title}</h4>}
      {subtitle && <DeviceManagementCardSubtitle>{subtitle}</DeviceManagementCardSubtitle>}
    </DeviceManagementCardTitle>
    {!isLoading ? (
      <Card>
        <DeviceManagementCardPadding className="device-management__card_padding">
          {children}
        </DeviceManagementCardPadding>
      </Card>
    ) : (
      <Card>
        <div className={'card__padder'}>
          <SkeletonText loading width="300px" />
        </div>
        <div className={'card__padder'}>
          <SkeletonText loading width="300px" />
        </div>
        <div className={'card__padder'}>
          <SkeletonText loading width="300px" />
        </div>
        <div className={'card__padder'}>
          <SkeletonText loading width="300px" />
        </div>
      </Card>
    )}
  </>
);
