import React, { SetStateAction, Dispatch } from 'react';
import uuid from 'uuid';
import cx from 'classnames';

// Reducers
import { useLanguageSelectors } from 'src/app/reducers';

// Types
import { IScenarioFilterObject, INoiseScenario, INoiseScenarioRecordValues } from './interfaces';
import { ICreateScenarioTranslations } from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';
import { IAddRecordFormErrors } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/interfaces';
import {
  IRecordError,
  IRecordErrors,
  IScenarioTotals,
} from 'src/@scenarioGeneration/containers/ViewScenario/interfaces';

// Components
import { InlineEditDropdown, InlineEditNumber } from './components';

// Functions
import { capitalizeObjectKeys, deepCopyObject } from 'src/utils';
import { isValidPositiveNumber } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/helpers';

export const formatScenarioTableData = ({
  scenarioData,
  filterData,
  onEditRow,
  mutatedRecords,
  recordErrors,
  noiseScenarioRecords,
}: {
  scenarioData: INoiseScenario[];
  filterData: IScenarioFilterObject;
  onEditRow: ({
    id,
    values,
    noiseScenarioRecords,
  }: {
    id: string;
    values: INoiseScenarioRecordValues;
    noiseScenarioRecords;
  }) => void;
  mutatedRecords: INoiseScenario[];
  recordErrors: IRecordErrors;
  noiseScenarioRecords: INoiseScenario[];
}) => {
  const tableData = scenarioData.map(({ node }: INoiseScenario) => {
    const mutatedRecord = mutatedRecords.find(mutation => node.id === mutation.node.id);
    const errorList: IRecordError = recordErrors[node.id] || {};
    const {
      id,
      runwayName,
      stageLength,
      modelingAircraftName,
      modelingAircraftId,
      dayCount,
      nightCount,
      eveningCount,
      operationType,
      modelingRouteId,
      modelingRouteName,
    } = mutatedRecord ? mutatedRecord.node : node;

    const aircraftDetails = filterData.noiseModelingAircraftTypes
      ? filterData.noiseModelingAircraftTypes.find(
          ({ aircraftId }) => aircraftId === modelingAircraftId
        )
      : { aircraftId: uuid.v4(), aircraftName: modelingAircraftName };

    const routeDetails = filterData.aircraftNoiseModelingRoutes
      ? filterData.aircraftNoiseModelingRoutes.find(({ id }) => id === modelingRouteId)
      : { id: uuid.v4(), name: modelingRouteName };

    const rowItem = {
      className: cx({ 'table-row__hasError': Object.keys(errorList).length }),
      id,
      tableId: uuid.v4(),
      tableRowKey: `id:${id}/${modelingAircraftName}`,
      modelingAircraftName: (
        <InlineEditDropdown
          selected={{ key: aircraftDetails.aircraftId, label: aircraftDetails.aircraftName }}
          list={
            filterData.noiseModelingAircraftTypes
              ? filterData.noiseModelingAircraftTypes.map(({ aircraftName, aircraftId }) => ({
                  key: aircraftId,
                  label: aircraftName,
                }))
              : []
          }
          error={errorList.aircraftId}
          onUpdate={item => {
            onEditRow({ id, values: { modelingAircraftId: item.key }, noiseScenarioRecords });
          }}
        />
      ),
      stageLength: (
        <InlineEditDropdown
          selected={{ key: stageLength, label: stageLength }}
          list={
            filterData.stages
              ? filterData.stages.map(stage => ({
                  key: stage,
                  label: stage,
                }))
              : []
          }
          error={errorList.stageLength}
          onUpdate={item => {
            onEditRow({ id, values: { stageLength: item.key }, noiseScenarioRecords });
          }}
        />
      ),
      modelingRouteName: (
        <InlineEditDropdown
          selected={{ key: routeDetails.id.toString(), label: routeDetails.name }}
          list={
            filterData.aircraftNoiseModelingRoutes
              ? filterData.aircraftNoiseModelingRoutes.map(({ name, id }) => ({
                  key: id.toString(),
                  label: name,
                }))
              : []
          }
          error={errorList.aircraftNoiseModelingRoutes}
          onUpdate={item => {
            onEditRow({ id, values: { modelingRouteId: Number(item.key) }, noiseScenarioRecords });
          }}
        />
      ),
      runwayName,
      operationType,
      dayCount: (
        <InlineEditNumber
          value={dayCount}
          onClickOutside={value => {
            onEditRow({ id, values: { dayCount: value }, noiseScenarioRecords });
          }}
        />
      ),
      eveningCount: (
        <InlineEditNumber
          value={eveningCount}
          onClickOutside={value => {
            onEditRow({ id, values: { eveningCount: value }, noiseScenarioRecords });
          }}
        />
      ),
      nightCount: (
        <InlineEditNumber
          value={nightCount}
          onClickOutside={value => {
            onEditRow({ id, values: { nightCount: value }, noiseScenarioRecords });
          }}
        />
      ),
      endSpacer: <>&nbsp;</>,
    };
    return rowItem;
  });
  return tableData;
};

export const getIdFromTableRowKey = (tableRowKey: string) => {
  const changelogId = tableRowKey.substring(tableRowKey.indexOf(':') + 1, tableRowKey.indexOf('/'));

  return changelogId;
};

export const viewScenarioTranslations = (): ICreateScenarioTranslations => {
  const languageSelectors = useLanguageSelectors();
  // Translations
  const {
    screens: {
      infringements: { title: goBackTitle },
      scenarioGeneration: { periodStart, title },
      settings: {
        settingsLabels: { noiseScenarioModel: noiseScenarioModelString },
      },
    },
    components: {
      headings: { candidates: headingCandidates, selectData: selectDataHeading },
      buttons: {
        create: createButton,
        cancel: cancelButton,
        generate: generateButton,
        download: downloadButton,
        bulkEdit: bulkEditButton,
        addRecord: addRecordButton,
      },
      labels: {
        filters: { clear: clearValue, filter: filterValue, all, empty },
        name: labelName,
        timeRange: { start: labelStart, end: labelEnd },
        airports: labelAirports,
        operationTypes: labelOperationTypes,
        runways: labelRunways,
        aircraftCategories: labelAircraftCategories,
        aircraftModels: labelAircraftModels,
        operatorTypes: labelOperatorTypes,
        airline: labelAirline,
        route: labelRoute,
        noiseModelType: labelNoiseModelType,
        day: labelDay,
        evening: labelEvening,
        night: labelNight,
        stage: labelStage,
        aircraftType: labelAircraftType,
        operation: labelOperation,
        runway: labelRunway,
        modelingRoute: labelModelingRoute,
        total: labelTotal,
        totals: labelTotals,
        new: labelNew,
        scenarioTimesOfDay: labelScenarioTimesOfDay,
      },
      dropdowns: { multiple, pleaseSelect },
      hints: { noMatchesFound },
      filters: filtersTranslation,
      lists: {
        aircraftCategories,
        operatorCategories,
        operationTypes,
        dateRangeList,
        navBarList: { settings },
      },
      errors: {
        noiseScenarioNameError,
        noiseScenarioNameLengthError,
        noiseScenarioSelectAirport,
        invalidScenarioIdError,
        noiseScenarioErrorAircraftStage,
        noiseScenarioErrorAircraftStageLength,
        noiseScenarioErrorArrivalStage,
        noiseScenarioErrorPositiveValue,
        noiseScenarioErrorEmptyValue,
        noiseScenarioEndpointError,
        required,
      },
      success: { noiseScenarioUpdateSuccess, noiseScenarioAddRecordSuccess },
      dialogs: {
        generateNoiseScenarioProgress,
        generateNoiseScenarioCompleted,
        generateNoiseScenarioError,
        addScenarioRecordHeading,
        bulkEditScenarioRecordsHeading,
        generateNoiseScenarioHeading,
        confirmNoiseScenarioLicenseHeading,
        confirmNoiseScenarioLicenseBody,
        exportNoiseScenarioCompleteHeading,
        exportNoiseScenarioErrorHeading,
        exportNoiseScenarioGeneratingHeading,
      },
    },
  } = languageSelectors.getLanguage();

  return {
    goBackTitle,
    filterLanguageData: {
      multiple,
      pleaseSelect,
      noMatchesFound,
      filterValue,
      clearValue,
      all,
      empty,
    },
    headings: {
      headingCandidates,
      selectDataHeading,
      addScenarioRecordHeading,
      bulkEditScenarioRecordsHeading,
      generateNoiseScenarioHeading,
      generateNoiseScenarioSettingsHeading: settings,
      periodStart,
      title,
    },
    buttons: {
      createButton,
      generateButton,
      cancelButton,
      downloadButton,
      bulkEditButton,
      addRecordButton,
    },
    errors: {
      noiseScenarioNameError,
      noiseScenarioNameLengthError,
      noiseScenarioSelectAirport,
      invalidScenarioIdError,
      noiseScenarioErrorAircraftStage,
      noiseScenarioErrorAircraftStageLength,
      noiseScenarioErrorArrivalStage,
      noiseScenarioErrorPositiveValue,
      noiseScenarioErrorEmptyValue,
      noiseScenarioEndpointError,
      required,
    },
    success: {
      noiseScenarioUpdateSuccess,
      noiseScenarioAddRecordSuccess,
    },
    timeInputLanguageData: filtersTranslation.time,
    aircraftCategories: capitalizeObjectKeys(aircraftCategories),
    operatorCategories: capitalizeObjectKeys(operatorCategories),
    operationTypes: capitalizeObjectKeys(operationTypes),
    dateRangeList,
    dialogTranslations: {
      generateNoiseScenarioProgress,
      generateNoiseScenarioCompleted,
      generateNoiseScenarioError,
      confirmNoiseScenarioLicenseHeading,
      confirmNoiseScenarioLicenseBody,
      exportNoiseScenarioCompleteHeading,
      exportNoiseScenarioErrorHeading,
      exportNoiseScenarioGeneratingHeading,
    },
    labels: {
      name: labelName,
      timeRange: {
        start: labelStart,
        end: labelEnd,
      },
      airports: labelAirports,
      operationTypes: labelOperationTypes,
      runways: labelRunways,
      aircraftCategories: labelAircraftCategories,
      aircraftModels: labelAircraftModels,
      operatorTypes: labelOperatorTypes,
      airline: labelAirline,
      route: labelRoute,
      noiseModelType: labelNoiseModelType,
      day: labelDay,
      evening: labelEvening,
      night: labelNight,
      stage: labelStage,
      aircraftType: labelAircraftType,
      operation: labelOperation,
      runway: labelRunway,
      modelingRoute: labelModelingRoute,
      total: labelTotal,
      totals: labelTotals,
      new: labelNew,
      scenarioTimesOfDay: labelScenarioTimesOfDay,
      noiseScenarioModel: noiseScenarioModelString,
    },
  };
};

export const validateStageLength = ({ filterData, aircraftId, stage, routeId, translations }) => {
  const { noiseModelingAircraftTypes, aircraftNoiseModelingRoutes } = filterData;
  const aircraftDetails = noiseModelingAircraftTypes.find(
    aircraft => aircraft.aircraftId === aircraftId
  );
  const { errors: errorStrings } = translations;
  let isError = false;
  let errorMessage = '';

  if (!aircraftDetails) {
    return {
      isError: true,
      errorMessage: 'Invalid aircraft details',
    };
  }

  // Check aircaft allows 'M' (Max) stage
  if (stage === 'M' && !aircraftDetails.supportsMaxMtowStageLength) {
    isError = true;
    errorMessage = errorStrings.noiseScenarioErrorAircraftStage;
  }
  // Check aircraft
  if (stage !== 'M' && !(parseInt(stage, 10) <= aircraftDetails.maxStageLength)) {
    isError = true;
    errorMessage = errorStrings.noiseScenarioErrorAircraftStageLength.replace(
      '{length}',
      `${aircraftDetails.maxStageLength}`
    );
  }

  // Arrival routes can only have a stage of 1
  const routeDetails = aircraftNoiseModelingRoutes.find(({ id }) => id === Number(routeId));
  if (!routeDetails) {
    return {
      isError: true,
      errorMessage: 'Invalid route details',
    };
  }
  if (routeDetails.operationType === 'Arrival' && parseInt(stage, 10) !== 1) {
    isError = true;
    errorMessage = errorStrings.noiseScenarioErrorArrivalStage;
  }

  return {
    isError,
    errorMessage,
  };
};

// Validates a noise scenario 'record' and returns any
// error strings via promise
export const validateScenarioRecord = async ({
  record,
  filterData,
  translations,
}: {
  record: INoiseScenarioRecordValues;
  filterData: IScenarioFilterObject;
  translations: ICreateScenarioTranslations;
}) => {
  const { errors: errorStrings } = translations;
  const numberKeys = ['dayCount', 'eveningCount', 'nightCount'];

  const errors: IAddRecordFormErrors = {};

  // Validate count values
  numberKeys.forEach(key => {
    if (!isValidPositiveNumber(record[key].toString())) {
      errors[key] = errorStrings.noiseScenarioErrorPositiveValue;
    }
  });

  // Validate stage length against route and aircraft model
  const stageValidation = validateStageLength({
    filterData,
    aircraftId: record.modelingAircraftId,
    routeId: record.modelingRouteId,
    stage: record.stageLength,
    translations,
  });
  if (stageValidation.isError) {
    errors.stageLength = stageValidation.errorMessage;
  }

  return new Promise(
    (
      resolve: (record: INoiseScenarioRecordValues) => void,
      reject: (errors: IAddRecordFormErrors) => void
    ) => {
      if (!Object.keys(errors).length) {
        resolve(record);
      } else {
        reject(errors);
      }
    }
  );
};

export const addRecordError = ({
  id,
  errors,
  errorState,
  setErrorState,
}: {
  id: string;
  errors: IRecordError;
  errorState: IRecordErrors;
  setErrorState: Dispatch<SetStateAction<IRecordErrors>>;
}) => {
  const errorCopy = deepCopyObject(errorState);
  errorCopy[id] = errors;

  setErrorState(errorCopy);
};

export const removeRecordError = ({
  id,
  errorState,
  setErrorState,
}: {
  id: string;
  errorState: IRecordErrors;
  setErrorState: Dispatch<SetStateAction<IRecordErrors>>;
}) => {
  const errorCopy = deepCopyObject(errorState);
  delete errorCopy[id];

  setErrorState(errorCopy);
};

export const getScenarioTotals = (
  scenarioData: INoiseScenario[],
  currentTotals: IScenarioTotals
) => {
  if (!scenarioData) {
    return currentTotals;
  }
  return scenarioData.reduce(
    (accumulator, { node }) => {
      Object.keys(node).forEach(key => {
        if (['dayCount', 'eveningCount', 'nightCount'].includes(key)) {
          const keyShort = key.replace('Count', '');
          accumulator[keyShort] = (accumulator[keyShort] || 0) + node[key];
        }
      });
      return accumulator;
    },
    { day: 0, evening: 0, night: 0 }
  );
};
