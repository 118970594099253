export const hideInfringementColumns = ({ showSeverity, showStatus, showCandidates }) => {
  if (!showSeverity) {
    document.documentElement.style.setProperty('--severity-width', '0px');
    document.documentElement.style.setProperty('--severity-padding', '0px');
    document.documentElement.style.setProperty('--severity-thin-padding', '0px');
  }

  if (!showStatus) {
    document.documentElement.style.setProperty('--status-width', '0px');
    document.documentElement.style.setProperty('--status-thin-width', '0px');
  }

  if (!showCandidates) {
    document.documentElement.style.setProperty('--candidates-width', '0px');
    document.documentElement.style.setProperty('--candidates-padding', '0px');
    document.documentElement.style.setProperty('--candidates-thin-padding', '0px');
    document.documentElement.style.setProperty('--candidates-overflow', 'hidden');
  }
};
