/*
 * Application shell
 */

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
// context providers
import { GlobalStateProvider } from 'src/app/providers/GlobalStateProvider';
import { GeometryStateProvider } from 'src/app/providers/GeometryProvider';
import { ExportStateProvider } from 'src/app/providers/ExportProvider';
import { ApplicationStateProvider } from 'src/app/applicationState/ApplicationStateProvider';
// container
import { ExportManagerContainer } from 'src/containers/ExportManagerContainer';
// components
import { PageRouter } from 'src/app/components';
import { Heap } from 'src/components/Heap';
// functions
import { WindowDimensionsProvider } from 'src/utils';
import { addIcons } from '@ems/client-design-system';
import { FocusStyleManager } from '@blueprintjs/core';
import { registerUserInRaygun } from 'src/app/functions/raygun';
import { getAuth } from 'src/app/functions/storage';
import { heapAppId } from 'src/config';

// appends icons to dom
addIcons();
// Register user in raygun
registerUserInRaygun();
// Enable FocusStyleManager by default
FocusStyleManager.onlyShowFocusOnTabs();

// App component consumes multiple contexts
export const AppShell = ({ client }) => {
  const authData = getAuth();

  return (
    <ApplicationStateProvider>
      <GlobalStateProvider>
        <WindowDimensionsProvider>
          <ApolloProvider client={client}>
            <GeometryStateProvider>
              <ExportStateProvider>
                <ExportManagerContainer />
                <Heap appId={heapAppId} authData={authData} />
                <PageRouter />
              </ExportStateProvider>
            </GeometryStateProvider>
          </ApolloProvider>
        </WindowDimensionsProvider>
      </GlobalStateProvider>
    </ApplicationStateProvider>
  );
};
