import gql from 'graphql-tag';

export const UPDATE_COMPLAINT_OPERATION_CORRELATION = gql`
  mutation CorrelateToOperation($id: Int!, $correlation: AircraftComplaintCorrelationDetails!) {
    setAircraftComplaintCorrelation(id: $id, correlation: $correlation) {
      id
      ... on AircraftComplaint {
        operationId
      }
    }
  }
`;

export const UPDATE_COMPLAINER_POSITION = gql`
  mutation UpdateComplainerPosition($complainer: ComplainerInput!) {
    updateComplainer(complainer: $complainer) {
      id
    }
  }
`;

export const UPDATE_COMPLAINTS = gql`
  mutation updateAircraftComplaints($ids: [Int!]!, $status: String!) {
    updateAircraftComplaints(ids: $ids, status: $status) {
      id
    }
  }
`;

export const UPDATE_COMPLAINT_STATUS = gql`
  mutation updateAircraftComplaint($complaint: AircraftComplaintInput!) {
    updateAircraftComplaint(complaint: $complaint) {
      id
      status
    }
  }
`;

export const ADD_COMPLAINER = gql`
  mutation addComplainer($complainer: ComplainerInput!) {
    addComplainer(complainer: $complainer) {
      id
    }
  }
`;

export const ADD_COMPLAINT = gql`
  mutation addAircraftComplaint($complaint: AircraftComplaintInput!) {
    addAircraftComplaint(complaint: $complaint) {
      id
    }
  }
`;

export const UPDATE_COMPLAINER = gql`
  mutation updateComplainer($complainer: ComplainerInput!) {
    updateComplainer(complainer: $complainer) {
      id
    }
  }
`;

export const UPDATE_COMPLAINT = gql`
  mutation updateAircraftComplaint($complaint: AircraftComplaintInput!) {
    updateAircraftComplaint(complaint: $complaint) {
      id
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($id: Int!, $comment: InputComment!) {
    addComplaintComment(id: $id, comment: $comment) {
      id
      createdBy
      time
      body
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: Int!, $comment: InputComment!) {
    updateComplaintComment(id: $id, comment: $comment) {
      id
      createdBy
      time
      body
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: Int!, $commentId: Int!) {
    removeComplaintComment(id: $id, commentId: $commentId)
  }
`;
