import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

// Selectors & utils
import { useLanguageSelectors } from 'src/app/reducers';
import { getDeployedProductId, tableDateTimeFormat } from 'src/utils';
import { useHasRoutePermissions } from 'src/@operations/hooks/useHasRoutePermissions';

// Components
import { CorrelatedEventCollapse, CorrelatedBlockTitle } from 'src/components';
import { Link } from 'react-router-dom';

// Constants
import {
  OPERATION_CORRELATION_COUNT_LIMIT,
  COMPLAINTS,
  BLANK_STRING_PLACEHOLDER,
} from 'src/constants';

interface IComplaint {
  id: number;
  complainerName: string;
  disturbanceTypes: string[];
  time: string;
}

interface IOperationComplaintsContainer {
  complaintData: IComplaint[];
}

export const OperationComplaintsContainer = ({ complaintData }: IOperationComplaintsContainer) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: {
        correlatedCategories: { complaints: title },
      },
    },
  } = languageSelectors.getLanguage();

  const timeList: DateTime[] = complaintData.map(complaint =>
    DateTime.fromISO(complaint.time, { setZone: true })
  );
  const idList = complaintData.map(complaint => complaint.id);

  return (
    <div className="block">
      <CorrelatedBlockTitle
        idList={idList}
        timeList={timeList}
        blockTitle={title}
        screenPath={COMPLAINTS}
      />

      {complaintData.map(({ id, complainerName, disturbanceTypes, time }, key) => {
        if (key > OPERATION_CORRELATION_COUNT_LIMIT - 1) {
          return null;
        }
        const disturbanceTypesDesc = disturbanceTypes ? `${disturbanceTypes[0]}` : '—';

        const cardHeader = (
          <>
            {useHasRoutePermissions(COMPLAINTS) ? (
              <Link
                className="correlatedCollapse__header-name"
                to={{
                  pathname: `/${getDeployedProductId()}/${COMPLAINTS}/${id}`,
                }}>
                <span>{complainerName.trim() ? complainerName : BLANK_STRING_PLACEHOLDER}</span>
              </Link>
            ) : (
              <span className="correlatedCollapse__header-name">
                {complainerName.trim() ? complainerName : BLANK_STRING_PLACEHOLDER}
              </span>
            )}

            <span className="correlatedCollapse__header-data">{disturbanceTypesDesc}</span>
            <span className="correlatedCollapse__header-data">
              {tableDateTimeFormat(time, false)}
            </span>
          </>
        );

        return <CorrelatedEventCollapse key={uuidv4()} headerChildren={cardHeader} />;
      })}
    </div>
  );
};
