import { useLocationManagementContext } from '../../Context/LocationManagementContext';
import { LocationManagementActions } from '../../Context/LoctionManagementReducer';

export const useIsEditing = () => {
  const {
    dispatch,
    state: { isEditing },
  } = useLocationManagementContext();

  const toggleIsEditing = () => {
    dispatch({
      type: LocationManagementActions.TOGGLE_IS_EDITING,
      payload: { isEditing: !isEditing },
    });
  };

  return { isEditing, toggleIsEditing };
};
