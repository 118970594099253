import { appActionTypes } from 'src/app/actionTypes';

export const loadRoles = () => ({ type: appActionTypes.LOAD_ROLES });

export const rolesLoaded = (data: any) => ({ type: appActionTypes.ROLES_LOADED, data });
export const rolesError = (data: any) => ({ type: appActionTypes.ROLES_ERROR, data });

export const updateAppConfig = (data: any) => ({ type: appActionTypes.UPDATE_CONFIG, data });
export const configError = (data: any) => ({ type: appActionTypes.CONFIG_ERROR, data });

export const updateAppLanguage = (data: any) => ({ type: appActionTypes.UPDATE_LANGUAGE, data });
export const languageError = (data: any) => ({ type: appActionTypes.LANGUAGE_ERROR, data });

export const routeChanged = () => ({ type: appActionTypes.ROUTE_CHANGE });
