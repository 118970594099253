// validate a link component
import React, { ReactNode, FC } from 'react';

interface ISafeLink {
  className: string;
  href: string;
  download?: boolean;
  children: ReactNode;
}

export const SafeLink: FC<ISafeLink> = ({ className, href, download = false, children }) => {
  const isSafe = (suspiciousUrl: string) => {
    const url = document.createElement('a');
    url.href = suspiciousUrl;
    const protocol = url.protocol === 'https:'; // only a secured source
    const hostname = !!url.hostname;
    return protocol && hostname ? true : false;
  }

  const safeURL = isSafe(href) ? href : '';

  return (
    <a
      className={className}
      href={safeURL}
      download={download}>
      {children}
    </a>
  );
};
