import { Dispatch, SetStateAction } from 'react';
import { DateTime } from 'luxon';
import { useState, useEffect, useReducer } from 'react';
import nearestPoint from '@turf/nearest-point';
import { point as turfPoint, featureCollection as turfFC } from '@turf/helpers';
import { Track, Projection, getTrackPca } from 'common-logic';
import WebMercatorViewport, { Coordinates } from 'viewport-mercator-project';
import { TableIcon } from 'src/components/Icons';
import {
  FLY_TO_DURATION,
  ZOOM_DEFAULT_LEVEL,
  ZOOM_SELECTION_TOLERANCE_HIGH,
  ZOOM_SELECTION_TOLERANCE_LOW,
  MAP_TYPES,
  LAYER_PREFIX_DYNAMIC_TRACK,
  OPERATIONS,
  MONITOR_LOCATIONS_LAYER_POSTFIX,
  MONITOR_LOCATIONS,
  EVENT_NOISE_DB_LAYER,
  DEFAULT_LAYER_PREFIX,
} from 'src/constants';

import {
  mapboxStyleForegroundPaint,
  mapboxStyleBackgroundNormalPaint,
  mapboxStyleHoverPaint,
  mapboxStyleInfringementSelectPaint,
} from 'src/utils';
import { DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
import { useConfigSelectors } from 'src/app/reducers';
import {
  IGeoPoint,
  IPoint,
  IBounds,
  IFeatureData,
  IMapData,
  IViewState,
  ZoomLevels,
} from '../interfaces';

import { Map as IMapApis, GeoJSONSource } from 'mapbox-gl';

import { IPosition } from '@ems/client-design-system/dist/components/Geometry/interfaces';
import { ILongLatObject } from '../spatialFeatureHelpers/interfaces';
import { doesLayerExist, useTrackLayers } from './mapLayerHelpers';
import { getLayers, getSources, removeLayerFromMap, removeSourceFromMap } from './mapApis';

/**
 * interfaces and types
 */

export declare type IMapType = typeof MAP_TYPES[keyof typeof MAP_TYPES];

interface IMapOperation {
  id: number;
  operationType?: string | null;
  source?: string;
  sourceLayer?: string;
}

export interface IMap {
  addSource: (arg1: any, arg2: any) => void;
  addLayer: (arg1: any) => void;
  getLayer: (arg1: any) => any;
  moveLayer: (layer: string) => void;
  removeLayer: (layer: string) => void;
  removeSource: (layer: string) => void;
  setFeatureState: (arg1: any, arg2: any) => void;
  removeFeatureState: (arg1: any, arg2: string) => void;
  setPaintProperty: (arg1: any, arg2: any, arg3: any, arg4?: any) => void;
  getStyle: () => any;
  getSource: (arg1: any) => any;
  isSourceLoaded: (arg1: string) => boolean;
  isStyleLoaded: () => boolean;
  project: (coordinates: Coordinates) => IPoint;
  unproject: (point: IPoint) => IGeoPoint;
  getFeatureState: (arg1: any) => any;
  queryRenderedFeatures: (arg1: any, arg2: any) => any;
  querySourceFeatures: (arg1: any, arg2: any) => any;
  _loaded: boolean;
  on: any;
}

export const calculateCurrentDistances = (
  userHomeLocation,
  operation,
  closestPoint,
  mapProjectionString
) => {
  Projection.current = new Projection(mapProjectionString);
  const trackSegment: Track = {
    id: operation.id,
    startTime: new Date(operation.startTime),
    points: [closestPoint],
  };
  return getTrackPca(userHomeLocation, trackSegment);
};

/**
 * Sorts track points and returns the closest match for a PCA based on time
 *
 * @param points - Points data from a track
 * @param pca  - Pca point info for a track
 * @returns - Point data closes to the PCA
 */
export const matchPcaToTrack = (points, pca) => {
  const roundedTime = Math.round(pca.trackPoint.t);
  return points.sort((a, b) => Math.abs(roundedTime - a.t) - Math.abs(roundedTime - b.t))[0];
};

/**
 * Returns the point id for PCA in a track, aligns the PCA to closes track point it can find
 *
 * @param referencePoints - Collection of reference points from which to calculate the Point of Closest Approach.
 * @param track - The track for which to calculate the Point of Closest Approach.
 * @returns - Collection of reference points plus their PCA
 */
export const getTrackPcas = (referencePoints, track) => {
  const pcaCollection: any = [];
  const trackSegment: Track = {
    id: track.id,
    startTime: new Date(track.startTime),
    points: track.points,
  };

  referencePoints.map(referencePoint => {
    const trackPca = getTrackPca(referencePoint, trackSegment);
    if (trackPca) {
      const { referencePosition, trackPoint, time, trackPointIndex, slantDistance } = trackPca;
      pcaCollection.push({ referencePosition, trackPoint, time, trackPointIndex, slantDistance });
    }
  });
  return pcaCollection;
};

/**
 * Provides additional config for map
 *
 * @param mapBoxConfig - map config from db
 */

export const getAdditionalMapBoxConfig = mapBoxConfig => {
  const { mapboxUser, sourceLayerPrefix, sitePrefix } = mapBoxConfig;
  const backgroundLayerPrefix = `${sourceLayerPrefix}background_`;
  const foregroundLayerPrefix = `${sourceLayerPrefix}foreground_`;
  const urlPrefix = `mapbox://${mapboxUser}.${sitePrefix}_`;
  const corridorIdentifier = `${sitePrefix}_corridors`;
  const selectionZoneIdentifier = `${sitePrefix}_selection_zone`;
  const gateIdentifier = `${sitePrefix}_gate`;
  const monitorLocationsIdentifier = `${sitePrefix}_${MONITOR_LOCATIONS_LAYER_POSTFIX}`;
  const runwaysIdentifier = `${sitePrefix}_runways`;
  const trackDensityIdentifier = `${sitePrefix}_track_density`;
  const limitLatitude = 2;
  const limitLongitude = 2;
  return {
    backgroundLayerPrefix,
    foregroundLayerPrefix,
    urlPrefix,
    limitLatitude,
    limitLongitude,
    corridorIdentifier,
    selectionZoneIdentifier,
    gateIdentifier,
    monitorLocationsIdentifier,
    runwaysIdentifier,
    trackDensityIdentifier,
  };
};

const dateFormat = 'yyyyMMdd';
/**
 * Extract dates from data
 *
 * @param from - selected from date
 * @param to - selected to date
 */
export const getDateArray = ({ from, to }): any => {
  const fromDate = DateTime.fromJSDate(from);
  const toDate = DateTime.fromJSDate(to);

  // array holding selected dates
  const days: string[] = [];

  // loop through the dates
  for (
    let currentDate = fromDate;
    currentDate <= toDate;
    currentDate = currentDate.plus({ days: 1 })
  ) {
    days.push(currentDate.toFormat(dateFormat));
  }
  return days;
};

/**
 * Provides date mapping for lookup
 *
 * @param from - selected from date
 * @param to - selected to date
 */
export const getDateMapping = ({ from, to }) => {
  const fromDate = DateTime.fromJSDate(from);
  const toDate = DateTime.fromJSDate(to);

  const mappedDates: any = {};

  // loop through the dates
  for (
    let currentDate = fromDate;
    currentDate <= toDate;
    currentDate = currentDate.plus({ days: 1 })
  ) {
    mappedDates[currentDate.toFormat('yyyy-MM-dd')] = currentDate.toFormat(dateFormat);
  }

  return mappedDates;
};

/**
 *
 * @param time - input time
 */

export const getMapDate = time => {
  const mapDate = DateTime.fromJSDate(time);
  return mapDate.toFormat(dateFormat);
};

/**
 * Converts shortended 3 bit hex values into their 6
 * bit version if valid
 *
 * @param hexColor
 */
export const normalizeHexCode = (hexColor: string) => {
  const hexBits = hexColor.replace(/#/g, '');
  let normalizedhexCode = '';
  if (hexBits.length === 6 || hexBits.length === 8) {
    return hexColor;
  } else if (hexBits.length === 3) {
    for (const letter of hexBits) {
      normalizedhexCode += letter + letter;
    }
  } else {
    console.warn(`${hexColor} is an invalid hex code`);
    normalizedhexCode = hexColor;
  }
  return normalizedhexCode;
};

/**
 * function that converts hex colors to array of rgb values
 * does NOT support shortended hex values (#fff)
 *
 * @param hex
 */
export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizeHexCode(hex));
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
};

/**
 * Converts a hex into rgba, support both 6 and 8 part alpha hex values
 *
 * @param hex
 * Based on https://jsfiddle.net/teddyrised/g02s07n4/
 */
export const hexToRgba = (hex: string, alpha = 1) => {
  // Fail if invalid value provided
  if (!/(^#([A-Za-z0-9]){6}$)|(^#([A-Za-z0-9]){8}$)/.test(normalizeHexCode(hex))) {
    return false;
  }
  // Strip #
  let colorString = hex.replace(/^#/, '');
  // Has alpha bits
  if (colorString.length === 8) {
    const alphaPart = colorString.slice(6, 8);
    alpha = Math.round(Math.round((parseInt(alphaPart, 16) / 255) * 1000)) / 1000;
    // Remove alpha from the color string
    colorString = colorString.slice(0, 6);
  }

  return `rgba(${hexToRgb(colorString).join(',')},${alpha})`;
};

/**
 * Get Existing sources from mapbox
 *
 * @param map - Mapbox API
 * @param sourcePrefix
 */

export const getExistingSources = (map: IMap, sourcePrefix: string) => {
  const existingSources = Object.keys(getSources(map));
  if (existingSources) {
    return existingSources
      .filter(source => source.startsWith(sourcePrefix))
      .map(id => id.split('_')[1]);
  } else {
    return [];
  }
};

export const addCustomTileSource = ({
  mapApis,
  trackPaths,
  trackVisibility,
  paintStyle,
}: {
  mapApis: null | IMap;
  trackPaths: any;
  trackVisibility: boolean;
  paintStyle: any;
}) => {
  const prefix = `trackLayer`;

  if (mapApis) {
    removeMapSourcesByPrefix(mapApis, prefix);
    trackPaths.map(path => {
      const { lower, upper, url } = path;
      const currentId = `${prefix}_${lower}_${upper}`;

      mapApis.addSource(currentId, {
        type: 'vector',
        url: `${url}`,
      });
      mapApis.addLayer({
        id: currentId,
        type: 'line',
        source: currentId,
        'source-layer': 'operations',
        metadata: {
          tagType: OPERATIONS,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
          visibility: trackVisibility ? 'visible' : 'none',
          'line-sort-key': ['+', ['get', 'altitudeBand']],
        },
        paint: paintStyle,
      });
    });
    mapApis = { ...mapApis };
  }
};

/**
 * Add tracks by date
 *
 * @param map - Mapbox API
 * @param dateString - date string to be used in map api
 * @param mapBoxConfig - map config from db
 */

export const addSourceStyleToMap = ({
  mapApis,
  mapBoxConfig,
  datesArray,
  layers,
}: {
  mapApis: null | IMap;
  mapBoxConfig: any;
  datesArray: string[];
  layers: any[];
}) => {
  if (!mapApis || !datesArray.length) {
    return;
  }
  const { urlPrefix, sourceLayerPrefix, operationPrefix } = mapBoxConfig;
  const { sourcePrefix } = mapBoxConfig;
  datesArray.forEach(dateString => {
    if (isSourceAvailable(mapApis, `${sourcePrefix}${dateString}`)) {
      return;
    }
    // adding source
    mapApis.addSource(`${sourcePrefix}${dateString}`, {
      type: 'vector',
      url: `${urlPrefix}${operationPrefix}${dateString}`,
    });
  });

  layers.forEach(layer => {
    datesArray.forEach(dateString => {
      const sourceString = `${mapBoxConfig.sourcePrefix}${dateString}`;
      whenMapHasLoadedSource(mapApis, sourceString).then(() => {
        // add background layers
        if (!mapApis.getLayer(`${layer.prefix}${dateString}`)) {
          mapApis.addLayer({
            id: `${layer.prefix}${dateString}`,
            type: 'line',
            metadata: {
              tagType: OPERATIONS,
            },
            source: `${sourcePrefix}${dateString}`,
            'source-layer': `${sourceLayerPrefix}${operationPrefix}${dateString}`,
            paint: layer.style,
          });
        }
      });
    });
  });
};

/**
 * Add tracks with provided point data
 *
 * @param map - Mapbox API
 * @param trackData - data for tracks
 * @param mapBoxConfig - map config from db
 */

export const addTrackPointsToMap = ({
  mapApis,
  trackData,
  selectedTrackTheme,
  selectedTrackId,
}: {
  mapApis: IMap;
  trackData: [IMapData];
  selectedTrackTheme: string;
  selectedTrackId?: number;
}) => {
  if (!mapApis || !trackData.length) {
    return;
  }

  const upperId = trackData[trackData.length - 1].id;
  const lowerId = trackData[0].id;
  const sourceId = `${LAYER_PREFIX_DYNAMIC_TRACK}_${lowerId}_${upperId}`;
  try {
    if (mapApis.getSource(sourceId)) {
      const existingSource = mapApis.getSource(sourceId);
      existingSource.setData({
        type: 'FeatureCollection',
        features: trackData.map(track => ({
          type: 'Feature',
          id: track.id,
          properties: {
            id: track.id,
            operationType: track.operationType,
          },
          geometry: {
            type: 'LineString',

            coordinates: track.points.map(point => [point.lon, point.lat, point.alt]),
          },
        })),
      });
    } else {
      mapApis.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: trackData.map(track => ({
            type: 'Feature',
            id: track.id,
            properties: {
              id: track.id,
              operationType: track.operationType,
            },
            geometry: {
              type: 'LineString',

              coordinates: track.points.map(point => [point.lon, point.lat, point.alt]),
            },
          })),
        },
      });
    }
  } catch (error) {
    console.error(error);
  }

  // Style for near by background flights
  const layerNameBackground = `${LAYER_PREFIX_DYNAMIC_TRACK}_background_${upperId}_${lowerId}`;
  const layerNameHover = `${LAYER_PREFIX_DYNAMIC_TRACK}_hover_${upperId}_${lowerId}`;
  const layerNameForeground = `${LAYER_PREFIX_DYNAMIC_TRACK}_foreground_${upperId}_${lowerId}`;
  const layerNameSelect = `infringement_select_`;

  if (!mapApis.getLayer(layerNameBackground)) {
    mapApis.addLayer({
      id: layerNameBackground,
      type: 'line',
      source: sourceId,
      paint: mapboxStyleBackgroundNormalPaint(selectedTrackTheme),
      metadata: {
        tagType: OPERATIONS,
      },
    });
  }

  if (!mapApis.getLayer(layerNameHover)) {
    mapApis.addLayer({
      id: layerNameHover,
      type: 'line',
      source: sourceId,
      paint: mapboxStyleHoverPaint,
      metadata: {
        tagType: OPERATIONS,
      },
    });
  }

  if (!mapApis.getLayer(layerNameSelect)) {
    mapApis.addLayer({
      id: layerNameSelect,
      type: 'line',
      source: sourceId,
      paint: mapboxStyleInfringementSelectPaint,
      metadata: {
        tagType: OPERATIONS,
      },
    });
  }

  if (selectedTrackId) {
    // Style for selected track
    if (!mapApis.getLayer(layerNameForeground)) {
      mapApis.addLayer({
        id: layerNameForeground,
        type: 'line',
        source: sourceId,
        paint: mapboxStyleForegroundPaint,
      });
    }

    // Set featurestate for selected track to trigger styling.
    mapApis.setFeatureState(
      {
        source: sourceId,
        id: selectedTrackId,
      },
      {
        selected: true,
      }
    );
  }
};

/**
 * Returns a promise that checks if a source is loaded in map
 *
 * @param map - Mapbox API
 * @param sourceString - string id of the source
 */

export const whenMapHasLoadedSource = (map: IMap, sourceString: string) =>
  new Promise<void>(resolve => {
    const checkSourceInMap = () => {
      if (checkSourceLoaded(map, sourceString)) {
        return resolve();
      }
      setTimeout(checkSourceInMap, 50);
    };
    checkSourceInMap();
  });

/**
 * Returns a promise that checks that all sources provided are loaded
 *
 * @param map - Mapbox API
 * @param datesArray - array of date strings
 * @param mapBoxConfig - map config from db
 */

export const afterMapLoadsAllSources = (map, dateArray, mapBoxConfig) => {
  const promiseArray: any[] = [];
  dateArray.forEach(dateString => {
    const sourceString = `${mapBoxConfig.sourcePrefix}${dateString}`;
    promiseArray.push(whenMapHasLoadedSource(map, sourceString));
  });
  return Promise.all(promiseArray);
};

/**
 * A function to check if provided source has loaded
 *
 * @param map - Mapbox API
 * @param dateString - date string to be used in map api
 * @param mapBoxConfig - map config from db
 */

export const checkSourceLoaded = (map: IMap, sourceString: string) => {
  if (
    !map ||
    !sourceString ||
    !map.getStyle() ||
    !map.getStyle().sources ||
    !Object.keys(map.getStyle().sources).includes(sourceString)
  ) {
    return false;
  }
  return map.isSourceLoaded(sourceString);
};

/**
 * Check to see if source is available
 *
 * @param map
 * @param sourceID
 */

export const isSourceAvailable = (map: any, sourceID: string) => {
  const mapStyle = map.getStyle();
  return mapStyle && mapStyle.sources && Object.keys(mapStyle.sources).includes(sourceID);
};

/**
 * Check to see if layer is available
 *
 * @param map
 * @param layerID
 */

export const isLayerAvailable = (map: any, layerID: string) =>
  map.getStyle().layers.findIndex(layer => layer.id === layerID);

/**
 * Remove tracks for a date
 *
 * @param map - Mapbox API
 * @param dateString - date string to be used in map api
 * @param mapBoxConfig - map config from db
 */

export const removeSourceStylesFromMap = (map: IMap, dateString: string, mapBoxConfig, layers) => {
  const { sourcePrefix } = mapBoxConfig;
  if (!dateString || !layers) {
    return false;
  }

  layers.forEach(layer => {
    if (isLayerAvailable(map, `${layer.prefix}${dateString}`) >= 0) {
      map.removeLayer(`${layer.prefix}${dateString}`);
    }
  });

  if (isSourceAvailable(map, `${sourcePrefix}${dateString}`)) {
    map.removeSource(`${sourcePrefix}${dateString}`);
  }
};

export const getDynamicTrackSource = (operationId: number, mapApis) => {
  if (mapApis) {
    let trackSource = '';
    const { sources } = mapApis.getStyle();
    Object.keys(sources)
      .filter(key => key.includes('trackLayer_'))
      .forEach(source => {
        const split = source.split('_');
        const upper = parseInt(split[split.length - 1]);
        const lower = parseInt(split[split.length - 2]);
        if (operationId <= upper && operationId >= lower) {
          trackSource = source;
        }
      });

    if (mapApis.getSource(trackSource) && mapApis.getSource(trackSource).vectorLayerIds) {
      const trackSourceLayer = mapApis.getSource(trackSource).vectorLayerIds[0];
      return { trackSource, trackSourceLayer };
    }

    return { trackSource, trackSourceLayer: '' };
  }
};

/**
 * Set operation selection feature state
 *
 * @param map - Mapbox API
 * @param mapBoxConfig - map config from db
 * @param operation - associated operation
 * @param dateString - date string to be used in map api
 * @param removeFeature - condition to set or removeFeature feature state
 */

export const setSelectionFeatureState = ({
  mapApis,
  mapBoxConfig,
  operation,
  dateString,
  removeFeature,
  dynamicTileServer = false,
}: {
  mapApis: IMap;
  mapBoxConfig: any;
  operation: IMapOperation;
  dateString: string;
  removeFeature?: boolean;
  dynamicTileServer?: boolean;
}) => {
  if (!dateString || !mapApis || !operation || !operation.id) {
    return;
  }

  const { sourcePrefix, sourceLayerPrefix, operationPrefix } = mapBoxConfig;
  let body = {};
  if (dynamicTileServer) {
    const { trackSource, trackSourceLayer } = getDynamicTrackSource(operation.id, mapApis);
    body = {
      id: operation.id,
      source: trackSource,
      sourceLayer: trackSourceLayer ? trackSourceLayer : null,
    };
  } else {
    body = {
      id: operation.id,
      source: `${sourcePrefix}${dateString}`,
      sourceLayer: `${sourceLayerPrefix}${operationPrefix}${dateString}`,
    };
  }

  if (!removeFeature) {
    mapApis.setFeatureState(body, {
      selected: true,
    });
  } else {
    const featurestate = mapApis.getFeatureState(body);
    if (featurestate && featurestate.selected) {
      mapApis.removeFeatureState(body, 'selected');
    }
  }
};

/**
 * Set featured state - filtered for tracks
 */

export const setFilteredFeatureState = ({
  mapApis,
  mapBoxConfig,
  operation,
  dateString,
  removeFeature,
}: {
  mapApis: IMap;
  mapBoxConfig: any;
  operation: IMapOperation;
  dateString: string;
  removeFeature?: boolean;
}) => {
  const { sourcePrefix, sourceLayerPrefix, operationPrefix } = mapBoxConfig;
  if (!mapApis) {
    return false;
  }
  if (!removeFeature) {
    mapApis.setFeatureState(
      {
        id: operation.id,
        source: `${sourcePrefix}${dateString}`,
        sourceLayer: `${sourceLayerPrefix}${operationPrefix}${dateString}`,
      },
      {
        filtered: true,
      }
    );
  } else {
    const { filtered } = mapApis.getFeatureState({
      id: operation.id,
      source: `${sourcePrefix}${dateString}`,
      sourceLayer: `${sourceLayerPrefix}${operationPrefix}${dateString}`,
    });
    if (filtered) {
      mapApis.removeFeatureState(
        {
          id: operation.id,
          source: `${sourcePrefix}${dateString}`,
          sourceLayer: `${sourceLayerPrefix}${operationPrefix}${dateString}`,
        },
        'filtered'
      );
    }
  }
};

/**
 * Set featured state - selected for corridors
 */
export const setFeatureStateForMap = (
  id: number | string,
  mapApis: any,
  sourceIdentifier: string, // source is required to set or remove featrue
  removeFeature: boolean,
  selectedState: 'selected' | 'visible' = 'selected'
) => {
  // do not proceed if source is not available
  if (!sourceIdentifier || !mapApis || !isSourceAvailable(mapApis, sourceIdentifier)) {
    return;
  }

  if (!removeFeature) {
    mapApis.setFeatureState(
      {
        id,
        source: sourceIdentifier,
      },
      {
        selected: selectedState === 'selected',
        visible: selectedState === 'visible',
      }
    );
  } else {
    const { selected, visible } = mapApis.getFeatureState({
      id,
      source: sourceIdentifier,
    });
    if (selected) {
      mapApis.removeFeatureState(
        {
          id,
          source: sourceIdentifier,
        },
        'selected'
      );
    }

    if (visible) {
      mapApis.removeFeatureState(
        {
          id,
          source: sourceIdentifier,
        },
        'visible'
      );
    }
  }
};

/**
 * Set BackgroundLayerStyle
 *
 * @param map - map api
 * @param mapBoxConfig - map config from db
 * @param existingSources - array of sources
 */

export const setLayerStyles = ({
  mapApis,
  mapBoxConfig,
  backgroundStyle,
}: {
  mapApis: IMap;
  mapBoxConfig: any;
  backgroundStyle: any;
}) => {
  const { backgroundLayerPrefix, sourcePrefix } = mapBoxConfig;
  const existingSources = getExistingSources(mapApis, sourcePrefix);
  existingSources.forEach(existingSource => {
    // tslint:disable-next-line: forin
    for (const key in backgroundStyle) {
      // check if layer exists before applying styles
      if (isLayerAvailable(mapApis, `${backgroundLayerPrefix}${existingSource}`) >= 0) {
        mapApis.setPaintProperty(
          `${backgroundLayerPrefix}${existingSource}`,
          key,
          backgroundStyle[key],
          {
            validate: false,
          }
        );
      }
    }
  });
};

/**
 * Get operation date from data
 * example input: 2019-06-09T23:30:03-04:00
 * output 2019-06-09
 */

export const getTrackDate: (date: null | string) => string = date => {
  if (!date) {
    return '';
  }
  return date.slice(0, 10);
};

/**
 * Provides date strings
 *
 * @param time
 */

export const getDateString = (time: string | null, dateRangeMapping) =>
  dateRangeMapping[getTrackDate(time)];

export const fitPointsInMap = (
  mapApis: IMapApis,
  viewport: IViewState,
  setViewport: Dispatch<IViewState>,
  points?: any[]
) => {
  if (mapApis && points) {
    const pointsLength = points.length;

    const oldViewport = new WebMercatorViewport(viewport);
    let { zoom } = viewport;

    if (pointsLength === 1) {
      // when single point is selected, place it on center
      if (zoom < 9.5) {
        zoom = 10;
      }
      if (zoom > 13) {
        zoom = 11;
      }

      const newViewPort = Object.assign({}, viewport, {
        latitude: points[0].latitude,
        longitude: points[0].longitude,
        zoom,
      });
      flyTo(mapApis, newViewPort).then(() => {
        setViewport(newViewPort);
      });
    }

    if (pointsLength >= 2) {
      const { longitudeMax, longitudeMin, latitudeMax, latitudeMin } = getMinMaxCoordinates(points);

      // Get viewport for new points
      const newViewport = oldViewport.fitBounds(
        [
          [longitudeMin, latitudeMin],
          [longitudeMax, latitudeMax],
        ],
        {
          padding: { top: 50, right: 200, bottom: 50, left: 200 },
        }
      );
      const { zoom: newZoom } = newViewport;
      if (newZoom < zoom) {
        zoom = newZoom;
      }

      const newViewPort = Object.assign({}, newViewport, { zoom });

      flyTo(mapApis, newViewPort).then(() => {
        setViewport(newViewPort);
      });
    }
  }
};

// temporary solution to get missing position for the infrigement candidates
export const getCoordinatesMapping = infSourceData => {
  const coordinatesMap = {};
  for (const key of Object.keys(infSourceData)) {
    coordinatesMap[key] = {};
    const { features } = infSourceData[key];
    if (typeof features !== 'undefined') {
      for (const feature of features) {
        coordinatesMap[key][feature.id] =
          key === 'Gate' ? feature.geometry.coordinates[0] : feature.geometry.coordinates[0][0];
      }
    }
  }
  return coordinatesMap;
};

/**
 * Extract coordinate of selected rows
 *
 * @param selectedInfringementsDataForMap
 */

export const getSelectedCoordinates = (
  selectedDataForMap: any,
  coordinatesMap: null | any = null
) => {
  // check for null
  const dataExists = selectedDataForMap && selectedDataForMap.length > 0;
  const selectedDataForMapAltered = selectedDataForMap;
  selectedDataForMap.forEach((item, index) => {
    const { position, corridorId } = item;
    // temporary solution to get missing position for the infrigement candidates
    if (
      !position &&
      corridorId &&
      coordinatesMap &&
      coordinatesMap[item.infringementType] !== 'undefined' &&
      coordinatesMap[item.infringementType][corridorId] !== 'undefined'
    ) {
      // position is invalid and missing
      selectedDataForMapAltered[index].position = {
        altitude: 0,
        longitude: coordinatesMap[item.infringementType][corridorId][0],
        latitude: coordinatesMap[item.infringementType][corridorId][1],
      };
    }
  });

  const validPoints =
    dataExists &&
    selectedDataForMapAltered.filter(({ position }: any) => {
      if (position) {
        const { latitude, longitude } = position;
        return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
      } else {
        return false;
      }
    });

  // return empty array when null
  return dataExists && validPoints.length
    ? validPoints.map(({ position }: any) => {
        if (position) {
          const { altitude, latitude, longitude } = position;
          return {
            altitude,
            latitude,
            longitude,
          };
        } else {
          return {};
        }
      })
    : [];
};

/**
 * Sets value for delta - added and removed
 *
 * @param data
 * @param selectedData
 */

export const setSelectionDelta = (newSelection, alreadySelected) => {
  const dataLength = newSelection.length;
  let addedToSelection: number[] = [];
  let removedFromSelection: number[] = [];

  // TODO get delta from grid in design system
  if (dataLength) {
    for (let i = dataLength; i--; ) {
      const currentID = newSelection[i];
      if (alreadySelected.length) {
        addedToSelection.push(currentID);
      } else {
        // nothing was earlier selected
        addedToSelection = newSelection;
      }
    }
    if (alreadySelected.length) {
      removedFromSelection.push(...alreadySelected);
    }
  } else {
    // nothing selected - remove all
    removedFromSelection = alreadySelected;
  }
  return {
    removedFromSelection,
    addedToSelection,
  };
};

/**
 * Extracts only relevant information for map tracks
 *
 * @param fetchedData - data fetched from server
 * @param operationId - string indetifier name for operation id
 */

export const getMapDataFromSelection = fetchedData => selection => {
  const selectedData = fetchedData[selection];
  const { operationId, time, operationType, detail, rule, ruleId } = selectedData;
  if (typeof detail !== 'undefined' && detail) {
    // infringements
    const { corridorId, selectionZoneId, gateId, gateDirection } = detail;
    return {
      id: operationId,
      time,
      operationType,
      corridorId,
      selectionZoneId,
      gateId,
      gateDirection,
      ruleId,
    };
  }
  if (typeof rule !== 'undefined' && rule) {
    // infringement candidates
    const { corridorId } = rule;
    return {
      id: operationId,
      time,
      operationType,
      corridorId,
    };
  }
  return {
    id: operationId,
    time,
    operationType,
  };
};

export const getMapDataFromSelectedIds = (fetchedData, operationId) => id => {
  const item = fetchedData.get(id);
  if (item) {
    return {
      id: item[operationId],
      time: item.time,
      operationType: item.operationType,
    };
  }
  return {};
};

/**
 * Function to animate(fly) to viewport
 *
 * @param mapApis
 * @param viewport
 */

export const flyTo = (mapApis, viewport) =>
  new Promise(resolve => {
    mapApis.flyTo({
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      bearing: viewport.bearing,
      duration: FLY_TO_DURATION,
    });
    setTimeout(() => {
      resolve(true);
    }, FLY_TO_DURATION);
  });
/**
 * Provides flight icon to be used in map
 *
 * @param acType
 */

export const mapFlightIcon = (acType: string | null) => {
  if (!acType) {
    return acType;
  }
  return TableIcon({
    name: acType.toLowerCase(),
    prefix: 'ac',
    size: 20,
  });
};

/**
 * Provides AD icon to be used in map
 *
 * @param adFlag
 */

export const mapAdIcon = (adFlag: string | null) => {
  if (!adFlag) {
    return adFlag;
  }
  return TableIcon({
    name: adFlag.toLowerCase(),
    prefix: 'ad',
    size: 32,
  });
};

/**
 * Function to query map for features on hover
 *
 * @param viewport
 * @param mapApis
 * @param datesArray
 * @param tracksFilter
 * @param layerPrefix
 */
export const useHoverOnMapElement = ({
  viewport,
  mapApis,
  layerArray,
  tracksFilter,
  restrictZoomLevels = true,
  mapType,
  layerPrefix = 'tracks_background_',
  radius = 5,
  disabled = false,
  radiusGradient = 1,
  featureStateName,
  rerunHook = false,
}: {
  viewport;
  mapApis;
  layerArray;
  tracksFilter;
  mapType: IMapType;
  restrictZoomLevels?: boolean;
  layerPrefix?: string;
  radius?: number;
  disabled?: boolean;
  radiusGradient?: number;
  featureStateName?: string;
  rerunHook?: boolean;
}) => {
  const [hoveredElement, setHoveredElement]: any = useState(null);
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  // As some maps use mapbox tiles and some dont..... we need set this value accordingly.
  const dynamicMapTypes = {
    [MAP_TYPES.OPERATIONSUMMARY]: true,
    [MAP_TYPES.OPERATIONDETAILS]: true,
    [MAP_TYPES.INFRINGEMENTDETAILS]: true,
    [MAP_TYPES.INFRINGEMENTSUMMARY]: true,
  };
  const isDynamic = FEATURE_FLAG_DYNAMIC_TILE_SERVER && dynamicMapTypes[mapType];

  const trackPrefixString =
    mapType === MAP_TYPES.COMPLAINTVIEWCOMPLAINT ? 'select-flight-tracks' : 'trackLayer_';

  // Bypass the featureState check if a certain tagtype
  const featureStateBypassTagTypeList = [MONITOR_LOCATIONS];

  const handleHover = (e: any) => {
    const fitsZoomLevel = viewport.zoom >= 11 && viewport.zoom <= 18;
    if (mapApis && !disabled && (!restrictZoomLevels || fitsZoomLevel)) {
      const combinedLayerArray = layerArray.map(layerName => `${layerPrefix}${layerName}`);
      let trackLayers = [];

      if (isDynamic) {
        mapApis.getStyle().layers.map(({ id }) => {
          if (id.includes(trackPrefixString)) {
            trackLayers.push(id);
          }
        });
      } else {
        trackLayers = combinedLayerArray.filter(l => mapApis.getLayer(l) !== undefined);
      }

      if (mapApis.getStyle()) {
        mapApis.getStyle().layers.forEach(layer => {
          if (layer.id.includes(`_${MONITOR_LOCATIONS_LAYER_POSTFIX}`)) {
            trackLayers.push(layer.id);
          }
          if (layer.id.includes(EVENT_NOISE_DB_LAYER)) {
            trackLayers.push(EVENT_NOISE_DB_LAYER);
          }
        });
      }

      // Allow some tolerance for track selection so nearby clicks still select when zoomed out.
      const zoomDistanceOffset: number =
        mapApis.getZoom() <= ZOOM_DEFAULT_LEVEL ? radius : ZOOM_SELECTION_TOLERANCE_LOW;
      for (let distance = 0; distance <= zoomDistanceOffset; distance += radiusGradient) {
        if (!mapApis) {
          return;
        }
        const features = mapApis.queryRenderedFeatures(
          [
            [e.point[0] - distance, e.point[1] - distance],
            [e.point[0] + distance, e.point[1] + distance],
          ],
          {
            layers: trackLayers,
            filter: !isDynamic ? tracksFilter : null,
          }
        );

        if (features.length) {
          if (featureStateName) {
            const withFeatureState = features.filter(feature => {
              const featureStates = mapApis.getFeatureState({
                source: feature.source,
                sourceLayer: feature.sourceLayer ? feature.sourceLayer : null,
                id: feature.id,
              });

              return featureStates[featureStateName];
            });

            features.map(feature => {
              const tagType = feature.layer.metadata.tagType;
              if (featureStateBypassTagTypeList.some(type => type === tagType)) {
                withFeatureState.push(feature);
              }
            });

            if (withFeatureState.length) {
              const feature = withFeatureState[0];
              feature.latitude = e.lngLat[1];
              feature.longitude = e.lngLat[0];
              setHoveredElement(feature);
            }
          } else {
            const feature = features[0];
            feature.latitude = e.lngLat[1];
            feature.longitude = e.lngLat[0];
            setHoveredElement(feature);
          }
        } else {
          setHoveredElement(null);
        }
      }
    } else {
      // removing hovered when zoom is not set
      if (hoveredElement) {
        setHoveredElement(null);
      }
    }
  };

  useEffect(() => {
    if (layerArray && tracksFilter) {
      setHoveredElement(null);
    }

    return () => {
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [layerArray, JSON.stringify(tracksFilter), disabled, rerunHook]);

  return { hoveredElement, handleHover, setHoveredElement };
};

export const useClickOnMapElement = (
  viewport,
  mapApis,
  layerArray,
  tracksFilter,
  restrictZoomLevels = true,
  layerPrefix = 'tracks_background_',
  radius = 5,
  radiusGradient = 1,
  setTagList?
) => {
  const [clickedElement, setClickedElement]: any = useState(null);
  const handleClick = (e: any) => {
    const fitsZoomLevel = viewport.zoom >= 11 && viewport.zoom <= 18;
    if (mapApis && (!restrictZoomLevels || fitsZoomLevel)) {
      const combinedLayerArray = layerArray.map(layerName => `${layerPrefix}${layerName}`);
      const filteredLayerArray = combinedLayerArray.filter(l => mapApis.getLayer(l) !== undefined);

      if (mapApis.getStyle()) {
        mapApis.getStyle().layers.forEach(layer => {
          if (layer.id.includes(`_${MONITOR_LOCATIONS_LAYER_POSTFIX}`)) {
            filteredLayerArray.push(layer.id);
          }
          if (layer.id.includes(EVENT_NOISE_DB_LAYER)) {
            filteredLayerArray.push(EVENT_NOISE_DB_LAYER);
          }
        });
      }

      // TODO get radius dynamically
      for (let distance = 0; distance <= radius; distance += radiusGradient) {
        const features = mapApis.queryRenderedFeatures(
          [
            [e.point[0] - distance, e.point[1] - distance],
            [e.point[0] + distance, e.point[1] + distance],
          ],
          {
            layers: filteredLayerArray,
            filter: tracksFilter,
          }
        );
        if (features.length) {
          const feature = features[0];
          feature.latitude = e.lngLat[1];
          feature.longitude = e.lngLat[0];
          setClickedElement(feature);
        } else {
          if (e.tapCount == 2) {
            setTagList([]);
          }
        }
      }
    } else {
      // removing hovered when zoom is not set
      if (clickedElement) {
        setClickedElement(null);
      }
    }
  };

  useEffect(() => {
    if (layerArray && tracksFilter) {
      setClickedElement(null);
    }
    return () => {
      mapApis = null; // invalidate mapApis on map unmount
    };
  }, [layerArray, JSON.stringify(tracksFilter)]);

  return { clickedElement, handleClick, setClickedElement };
};

/**
 * Helper function to delete anchor lines on map
 *
 * @param mapApis
 * @param prefix
 */
export const removeMapSourcesByPrefix = async (mapApis, prefix: string, exact = false) => {
  if (mapApis) {
    const layerCollection = getLayers(mapApis);
    const sourceCollection = getSources(mapApis);
    const filteredLayers = layerCollection.filter(layer => {
      if (layer.source) {
        if (exact) {
          return layer.source === prefix;
        }
        return layer.source.includes(prefix);
      }
    });
    const filteredSources = Object.keys(sourceCollection).filter(sourceName => {
      if (exact) {
        return sourceName === prefix;
      }
      return sourceName.includes(prefix);
    });

    filteredLayers.forEach(layer => {
      removeLayerFromMap(mapApis, layer.id);
    });

    Object.keys(filteredSources).forEach(key => {
      removeSourceFromMap(mapApis, filteredSources[key]);
    });
  }
};

/**
 * Toggle a source from the map by updating its visibility value
 *
 * @param mapApis - map interface
 * @param sources - collection of sources to
 */
export const toggleMapSourcesVisibility = ({
  mapApis,
  prefix,
  hide,
  exact,
}: {
  mapApis;
  prefix: string;
  hide: boolean;
  exact?: boolean;
}) => {
  if (mapApis) {
    const allLayers = mapApis.getStyle().layers;
    const matchingLayers = allLayers.filter(layer => {
      if (layer.source) {
        if (exact) {
          return layer.source === prefix;
        }
        return layer.source.includes(prefix);
      }
    });
    matchingLayers.forEach(layer => {
      if (mapApis.getLayer(layer.id)) {
        mapApis.setLayoutProperty(layer.id, 'visibility', hide ? 'none' : 'visible');
      }
    });
  }
};

/**
 * Function to query map for features on click
 *
 * @param hoveredOperation
 * @param setShowSelected
 * @param mapApis
 * @param datesArray
 * @param tracksFilter
 * @param selectedOperations
 * @param setSelectedOperations
 * @param layerPrefix
 */

export const useMapClick = ({
  hoveredOperation,
  setShowSelected,
  mapApis,
  datesArray,
  tracksFilter,
  selectedOperations,
  setSelectedOperations,
  mapType,
  clearDisplayedTags,
  layerPrefix = DEFAULT_LAYER_PREFIX,
  disabled = false,
  clearOnBlankClick = true,
}: {
  hoveredOperation;
  setShowSelected;
  mapApis;
  datesArray: string[];
  tracksFilter;
  selectedOperations;
  setSelectedOperations;
  mapType: IMapType;
  clearDisplayedTags?: () => void;
  layerPrefix?: string;
  disabled?: boolean;
  clearOnBlankClick?: boolean;
}) => {
  const [disabledState, updateDisabledState] = useState<boolean>(disabled);
  const configSelectors = useConfigSelectors();
  const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);
  const isDynamic = FEATURE_FLAG_DYNAMIC_TILE_SERVER;

  const trackLayers = useTrackLayers(mapApis, datesArray, disabled, layerPrefix);
  const handleClick = (e: any) => {
    if (hoveredOperation) {
      setShowSelected(true);
      setSelectedOperations([hoveredOperation]);
      return;
    }
    // If we are clicking on map controls we don't need to do the rest of the handle click function
    // If the class name is overlays then the user is clicking on the map
    if (e.target.className !== 'overlays') {
      return;
    }
    if (mapApis && !disabled) {
      // Allow some tolerance for track selection so nearby clicks still select when zoomed out.
      const zoomDistanceOffset: number =
        mapApis.getZoom() <= ZOOM_DEFAULT_LEVEL
          ? ZOOM_SELECTION_TOLERANCE_HIGH
          : ZOOM_SELECTION_TOLERANCE_LOW;
      for (let distance = 0; distance <= zoomDistanceOffset; distance++) {
        const features = mapApis.queryRenderedFeatures(
          [
            [e.point[0] - distance, e.point[1] - distance],
            [e.point[0] + distance, e.point[1] + distance],
          ],
          {
            layers: trackLayers,
            // Infringements details and candidates do not display tracks unless selected so we need to ensure the filter is passed
            filter:
              !isDynamic ||
              mapType === MAP_TYPES.INFRINGEMENTDETAILS ||
              mapType === MAP_TYPES.INFRINGEMENTCANDIDATES
                ? tracksFilter
                : null,
          }
        );
        if (features.length && (features[0].id || features[0].properties.id)) {
          const feature = features[0];
          feature.latitude = e.lngLat[1];
          feature.longitude = e.lngLat[0];
          setShowSelected(true);
          setSelectedOperations([feature]);
          break;
        } else {
          if (clearOnBlankClick) {
            setSelectedOperations([]);
            if (e.tapCount == 2) {
              clearDisplayedTags();
            }
          }
        }
      }
    }
  };

  let selectedOperation: any = null;
  if (selectedOperations && selectedOperations.length === 1) {
    selectedOperation = selectedOperations[0];
  }

  useEffect(() => {
    if (datesArray && tracksFilter) {
      if (disabledState !== disabled) {
        updateDisabledState(disabled);
      } else {
        setSelectedOperations([]);
      }
      setShowSelected(false);
    }
  }, [datesArray, JSON.stringify(tracksFilter)]);
  return { handleClick, selectedOperation };
};

/**
 * Function to set feature state for the clicked/selected tracks
 *
 * @param selectedOperations
 * @param mapApis
 */

export const useMapSelection = (
  selectedOperations: any[],
  mapApis: any,
  key = 'selected',
  changeSelection = false
) => {
  const selectionReducer = (state: any, action: any) => {
    if (state && state.length) {
      state.forEach((operation: any) => {
        if (mapApis) {
          const object = {
            id: operation.id,
            source: operation.layer.source,
            sourceLayer: operation.layer['source-layer'],
          };
          // doesLayerExist(mapApis, identifier)
          try {
            mapApis.removeFeatureState(object, key);
          } catch {
            console.error('removeFeatureState', object);
          }
        }
      });
    }
    switch (action.type) {
      case 'select':
        action.data.operations.forEach((selectedOperation: any) => {
          const selectionValue = {};
          selectionValue[key] = true;
          if (mapApis) {
            const object = {
              id: selectedOperation.id,
              source: selectedOperation.layer.source,
              sourceLayer: selectedOperation.layer['source-layer'],
            };
            try {
              mapApis.setFeatureState(object, selectionValue);
            } catch {
              console.error('setFeatureState', object);
            }
          }
        });

        return action.data.operations;
      default:
        return null;
    }
  };

  const [operationsSelected, dispatchMapSelection] = useReducer(selectionReducer, null);

  useEffect(() => {
    if (mapApis) {
      if (selectedOperations && selectedOperations.length) {
        const filteredOperations = selectedOperations.filter(
          selectedOperation => selectedOperation.properties
        );
        if (filteredOperations.length) {
          dispatchMapSelection({
            type: 'select',
            data: { operations: selectedOperations },
          });
        } else {
          dispatchMapSelection({ type: 'deselect' });
        }
      } else {
        dispatchMapSelection({ type: 'deselect' });
      }
    }
  }, [selectedOperations, mapApis]);

  useEffect(() => {
    if (mapApis) {
      dispatchMapSelection({ type: 'deselect' });
    }
  }, [changeSelection]);

  return operationsSelected;
};

/**
 * Function to set feature state for hovered tracks
 *
 * @param hoveredTrack
 * @param mapApis
 */

export const useMapHover = (hoveredTrack: any, mapApis: any, pinIsBeingDragged = false) => {
  const hoverReducer = (state: any, action: any) => {
    if (mapApis) {
      if (state) {
        mapApis.removeFeatureState(
          {
            id: state.id || state.properties.id,
            source: state.layer.source,
            sourceLayer: state.layer['source-layer'],
          },
          'hovered'
        );
      }
      switch (action.type) {
        case 'set-hover':
          if (!pinIsBeingDragged && action.data) {
            const feature = action.data;
            mapApis.setFeatureState(
              {
                id: feature.id || feature.properties.id,
                source: feature.layer.source,
                sourceLayer: feature.layer['source-layer'],
              },
              { hovered: true }
            );
            return feature;
          }
          return null;
        default:
          return null;
      }
    }
  };

  const removeHovered = () => {
    dispatchHover({ type: '', data: {} });
  };

  const [tracksHovered, dispatchHover] = useReducer(hoverReducer, null);
  useEffect(() => {
    // TODO dispatch hover change
    dispatchHover({ type: 'set-hover', data: hoveredTrack });
  }, [hoveredTrack]);
  return { tracksHovered, removeHovered };
};

export const getMarqueeSelectFeatures = ({
  mapApis,
  bounds,
  setSelectedOperations,
  setShowSelected,
  trackLayers,
  isDynamic = false,
  tracksFilter,
}: {
  mapApis;
  bounds: IBounds;
  setSelectedOperations: (operations) => void;
  setShowSelected: Dispatch<SetStateAction<boolean>>;
  trackLayers: string[];
  isDynamic: boolean;
  tracksFilter?: any[];
}) => {
  const { start, end } = bounds;

  if (mapApis && start && end) {
    const features = mapApis.queryRenderedFeatures(
      [
        [Math.min(start.x, end.x), Math.min(start.y, end.y)],
        [Math.max(start.x, end.x), Math.max(start.y, end.y)],
      ],
      {
        layers: trackLayers,
        filter: !isDynamic ? tracksFilter : null,
      }
    );
    if (features.length === 1) {
      const latLnt: IGeoPoint = mapApis.unproject([(start.x + end.x) / 2, (start.y + end.y) / 2]);
      const selectedFeature: IFeatureData = Object.assign(features[0], {
        latitude: latLnt.lat,
        longitude: latLnt.lng,
      });
      setSelectedOperations([selectedFeature]);
      setShowSelected(true);
    } else {
      setSelectedOperations(features);
    }
  }
};

/**
 * Function to select features using shift drag
 *
 * @param datesArray
 * @param mapNode
 * @param mapApis
 * @param setSelectedOperations
 * @param setSelectionBounds
 * @param setShowSelected
 * @param mapBoxConfig
 * @param tracksFilter
 */

export const useMapMultiSelect = (
  datesArray: string[],
  mapNode: any,
  mapApis: any,
  setSelectedOperations: any,
  setSelectionBounds: any,
  setShowSelected: any,
  mapBoxConfig: any,
  tracksFilter: any,
  disabled = false
) => {
  const [dragRotate, setDragRotate] = useState(true);
  useEffect(() => {
    let startPoint: any = null;
    let currentPoint: any = null;
    let endPoint: any = null;

    if (
      mapApis &&
      !disabled &&
      mapNode &&
      mapNode._eventCanvasRef &&
      mapNode._eventCanvasRef.current
    ) {
      const mapDiv = mapNode._eventCanvasRef.current;

      const selectFeatures = (start: { x: number; y: number }, end: { x: number; y: number }) => {
        if (mapApis && start && end) {
          const features = mapApis.queryRenderedFeatures(
            [
              [Math.min(start.x, end.x), Math.min(start.y, end.y)],
              [Math.max(start.x, end.x), Math.max(start.y, end.y)],
            ],
            {
              layers: datesArray.map(date => `${mapBoxConfig.backgroundLayerPrefix}${date}`),
              filter: tracksFilter,
            }
          );
          if (features.length === 1) {
            const latLnt = mapApis.unproject([
              (startPoint.x + endPoint.x) / 2,
              (startPoint.y + endPoint.y) / 2,
            ]);
            const selectedFeature = Object.assign(features[0], {
              latitude: latLnt.lat,
              longitude: latLnt.lng,
            });
            setSelectedOperations([selectedFeature]);
            setShowSelected(true);
          } else {
            setSelectedOperations(features);
          }
        }
      };

      const finishSelect = (startPoint: any, endPoint: any) => {
        if (startPoint && endPoint) {
          selectFeatures(startPoint, endPoint);
        }

        setDragRotate(true);

        startPoint = null;
        currentPoint = null;
        endPoint = null;
        setSelectionBounds(null);
        mapDiv.removeEventListener('mouseup', onMouseUp);
        mapDiv.removeEventListener('mousemove', onMouseMove);
      };

      const onMouseUp = (e: any) => {
        endPoint = {
          x: e.clientX - mapDiv.getBoundingClientRect().left,
          y: e.clientY - mapDiv.getBoundingClientRect().top,
        };
        finishSelect(startPoint, endPoint);
      };

      const onMouseMove = (e: any) => {
        currentPoint = {
          x: e.clientX - mapDiv.getBoundingClientRect().left,
          y: e.clientY - mapDiv.getBoundingClientRect().top,
        };
        setSelectionBounds({ startPoint, currentPoint });
      };

      const onMouseDown = (e: any) => {
        if (e.shiftKey) {
          startPoint = {
            x: e.clientX - mapDiv.getBoundingClientRect().left,
            y: e.clientY - mapDiv.getBoundingClientRect().top,
          };
          setDragRotate(false);

          mapDiv.addEventListener('mouseup', onMouseUp);
          mapDiv.addEventListener('mousemove', onMouseMove);
        }
      };
      mapDiv.addEventListener('mousedown', onMouseDown);
      return () => {
        mapDiv.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [datesArray, mapNode, mapApis, tracksFilter, disabled]);

  return { dragRotate };
};

export const getTotalCountForLayer = (mapApis, mapBoxConfig, filter) => {
  let totalCount = 0;
  const { backgroundLayerPrefix, sourcePrefix } = mapBoxConfig;
  const existingSources = getExistingSources(mapApis, sourcePrefix);
  existingSources.forEach(existingSource => {
    if (isLayerAvailable(mapApis, `${backgroundLayerPrefix}${existingSource}`) >= 0) {
      const renderedFeatures = mapApis.queryRenderedFeatures({
        layers: [`${backgroundLayerPrefix}${existingSource}`],
        filter,
      });

      const existingFeatureKeys = {};
      const uniqueFeatures = renderedFeatures.filter(el => {
        if (existingFeatureKeys[el.id]) {
          return false;
        } else {
          existingFeatureKeys[el.id] = true;
          return true;
        }
      });

      totalCount += uniqueFeatures.length;
    }
  });

  return totalCount;
};

export const useClickedPointData = ({
  operation,
  clickedElement,
  setClickedPointData,
  userHomeLocation,
  mapProjectionString,
}) => {
  useEffect(() => {
    const nullClickedPointData = {
      amsl: null,
      time: null,
      longitude: null,
      latitude: null,
      showPointData: false,
      flightId: null,
    };

    if (operation && operation.points) {
      let closest: any = null;
      if (clickedElement) {
        closest = nearestPoint(
          turfPoint([clickedElement.latitude, clickedElement.longitude]),
          turfFC(operation.points.map(pt => turfPoint([pt.lat, pt.lon])))
        );
      }

      let currentDistanceInfo: any = null;

      if (closest) {
        const point = operation.points[closest.properties.featureIndex];
        const profile = operation.profile.find(pt => pt.time === point.t);
        if (mapProjectionString && userHomeLocation) {
          currentDistanceInfo = calculateCurrentDistances(
            userHomeLocation,
            operation,
            point,
            mapProjectionString
          );
        }

        setClickedPointData({
          showPointData: true,
          amsl: point.alt,
          dist: profile.dist,
          longitude: point.lon,
          latitude: point.lat,
          type: operation.operationType,
          flightId: operation.acid,
          time: DateTime.fromISO(operation.startTime, { setZone: true })
            .plus({ seconds: point.t })
            .toISO(),
          distanceHorizontal: currentDistanceInfo ? currentDistanceInfo.horizontalDistance : null,
          distanceVertical: currentDistanceInfo ? currentDistanceInfo.verticalDistance : null,
          distanceSlant: currentDistanceInfo ? currentDistanceInfo.slantDistance : null,
        });
      } else {
        setClickedPointData(nullClickedPointData);
      }
    } else {
      setClickedPointData(nullClickedPointData);
    }
  }, [operation, clickedElement]);
};

export const useHoveredPointData = ({
  mapApis,
  operation,
  nearbyFlightsData,
  hoveredElement,
  profileHoverTime,
  setSelectedTime = time => {},
  setSelectedPointData,
  isPlaybackMode,
  isPlaybackRunning,
  userHomeLocation,
  mapProjectionString,
}: {
  mapApis: IMapApis;
  operation: any;
  nearbyFlightsData?: any[];
  hoveredElement: any;
  profileHoverTime: any;
  setSelectedTime?: any;
  setSelectedPointData: any;
  isPlaybackMode: boolean;
  isPlaybackRunning: boolean;
  userHomeLocation: any;
  mapProjectionString: string;
}) => {
  const identifier = 'selectedCircle';
  useEffect(() => {
    if (mapApis) {
      let sourceAdded = false;
      if (
        !mapApis ||
        isSourceAvailable(mapApis, identifier) ||
        mapApis.getStyle().layers.findIndex(layer => layer.id === identifier) !== -1
      ) {
        sourceAdded = true; // already added
      }

      if (!sourceAdded) {
        mapApis.addSource(identifier, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [0, 0],
                },
              },
            ],
          },
        });

        mapApis.addLayer({
          id: identifier,
          type: 'circle',
          source: identifier,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 2, 11, 4, 16, 12],
            'circle-color': 'white',
            'circle-stroke-color': '#0b6bf2',
            'circle-stroke-width': 2,
            'circle-opacity': 1,
          },
        });
      }
    }
  }, [mapApis]);

  useEffect(() => {
    let hoveredOperation = operation;
    if (nearbyFlightsData && hoveredElement && operation.id !== hoveredElement.id) {
      hoveredOperation = nearbyFlightsData.find(operation => operation.id === hoveredElement.id);
    }

    if (hoveredOperation && hoveredOperation.points && hoveredOperation.profile) {
      let currentDistanceInfo: any = null;
      if (profileHoverTime !== null) {
        const point = hoveredOperation.points.find(pt => pt.t === profileHoverTime);

        if (point !== undefined) {
          const profile = hoveredOperation.profile.find(pt => pt.time === point.t);

          if (mapApis) {
            const source = mapApis.getSource(identifier) as GeoJSONSource;
            if (source) {
              source.setData({
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: [point.lon, point.lat],
                    },
                  },
                ],
              });
            }

            if (mapApis.getLayer(identifier)) {
              mapApis.setLayoutProperty(identifier, 'visibility', 'visible');
            }
          }

          setSelectedTime(null);
          setSelectedPointData({
            showPointData: true,
            amsl: point.alt,
            dist: profile.dist,
            longitude: point.lon,
            latitude: point.lat,
            type: hoveredOperation.operationType,
            time: DateTime.fromISO(hoveredOperation.startTime, { setZone: true })
              .plus({ seconds: point.t })
              .toISO(),
            flightId: hoveredOperation.acid,
          });

          if (mapApis && doesLayerExist(mapApis, identifier)) {
            mapApis.moveLayer(identifier);
          }
        }
      } else {
        let closest: any = null;

        if (hoveredElement) {
          closest = nearestPoint(
            turfPoint([hoveredElement.latitude, hoveredElement.longitude]),
            turfFC(hoveredOperation.points.map(pt => turfPoint([pt.lat, pt.lon])))
          );
          // calculate current distances from the hovered point to the user's location
          if (mapProjectionString && userHomeLocation) {
            const closestPoint = hoveredOperation.points[closest.properties.featureIndex];
            currentDistanceInfo = calculateCurrentDistances(
              userHomeLocation,
              hoveredOperation,
              closestPoint,
              mapProjectionString
            );
          }
        }
        if (closest) {
          const point = hoveredOperation.points[closest.properties.featureIndex];

          if (point !== undefined) {
            const profile = hoveredOperation.profile.find(pt => pt.time === point.t);
            if (mapApis) {
              const source = mapApis.getSource(identifier) as GeoJSONSource;
              if (source) {
                source.setData({
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'Point',
                        coordinates: [point.lon, point.lat],
                      },
                    },
                  ],
                });
              }

              if (mapApis.getLayer(identifier)) {
                mapApis.setLayoutProperty(identifier, 'visibility', 'visible');
              }
            }

            setSelectedTime(point.t);
            setSelectedPointData({
              id: hoveredOperation.id,
              showPointData: true,
              amsl: point.alt,
              dist: profile.dist,
              longitude: point.lon,
              latitude: point.lat,
              type: hoveredOperation.operationType,
              flightId: hoveredOperation.acid,
              time: DateTime.fromISO(hoveredOperation.startTime, { setZone: true })
                .plus({ seconds: point.t })
                .toISO(),
              distanceHorizontal: currentDistanceInfo
                ? currentDistanceInfo.horizontalDistance
                : null,
              distanceVertical: currentDistanceInfo ? currentDistanceInfo.verticalDistance : null,
              distanceSlant: currentDistanceInfo ? currentDistanceInfo.slantDistance : null,
            });

            if (mapApis && doesLayerExist(mapApis, identifier)) {
              mapApis.moveLayer(identifier);
            }
          }
        } else {
          setSelectedTime(null);
          if (mapApis && mapApis.getLayer(identifier)) {
            mapApis.setLayoutProperty(identifier, 'visibility', 'none');
          }
          setSelectedPointData({
            amsl: null,
            time: null,
            longitude: null,
            latitude: null,
            showPointData: false,
            flightId: null,
          });
        }
      }
    } else {
      setSelectedTime(null);
      if (mapApis) {
        if (mapApis.getLayer(identifier)) {
          mapApis.setLayoutProperty(identifier, 'visibility', 'none');
        }
      }
      setSelectedPointData({
        amsl: null,
        time: null,
        longitude: null,
        latitude: null,
        showPointData: false,
        flightId: null,
      });
    }
  }, [operation, hoveredElement, profileHoverTime]);
};

export const useMapStylesForPlayback = ({
  mapApis,
  mapBoxConfig,
  dateString,
  isPlaybackMode,
}: {
  mapApis: IMap;
  mapBoxConfig: any;
  dateString: string;
  isPlaybackMode: boolean;
}) => {
  useEffect(() => {
    if (mapApis) {
      mapApis.getStyle().layers.forEach(layer => {
        if (layer.id.includes(`${LAYER_PREFIX_DYNAMIC_TRACK}_background`)) {
          mapApis.setPaintProperty(
            layer.id,
            'line-width',
            isPlaybackMode ? 0.01 : ['interpolate', ['linear'], ['zoom'], 8, 0.5, 11, 1, 14, 3]
          );
        }
      });

      mapApis.getStyle().layers.forEach(layer => {
        if (layer.id.includes(`${LAYER_PREFIX_DYNAMIC_TRACK}_foreground`)) {
          mapApis.setPaintProperty(layer.id, 'line-width', [
            'interpolate',
            ['linear'],
            ['zoom'],
            8,
            0.75,
            11,
            4,
            14,
            6,
          ]);
        }
      });

      mapApis.getStyle().layers.forEach(layer => {
        if (layer.id.includes(`hovered_`)) {
          mapApis.setPaintProperty(
            layer.id,
            'line-width',
            isPlaybackMode ? 0 : ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6]
          );
        }
      });

      mapApis.getStyle().layers.forEach(layer => {
        if (layer.id.includes(`infringement_select_`)) {
          mapApis.setPaintProperty(
            layer.id,
            'line-width',
            isPlaybackMode ? 0 : ['interpolate', ['linear'], ['zoom'], 8, 0.75, 11, 4, 14, 6]
          );
        }
      });
    }
  }, [isPlaybackMode]);
};

export const useGeocoderPinAlternative = ({ mapApis, enableMap, coordinates }) => {
  const sourceIdentifier = 'map-pins';
  useEffect(() => {
    if (mapApis) {
      if (!enableMap && coordinates.length > 0) {
        mapApis.addSource(sourceIdentifier, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: coordinates.map(operation => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: operation,
              },
            })),
          },
        });
        mapApis.addLayer({
          id: sourceIdentifier,
          type: 'circle',
          source: sourceIdentifier,
          paint: {
            'circle-radius': 5,
            'circle-color': '#0b6bf2',
          },
        });
      } else if (enableMap) {
        if (mapApis.getLayer(sourceIdentifier) && doesLayerExist(mapApis, sourceIdentifier)) {
          mapApis.removeLayer(sourceIdentifier);
        }
        if (isSourceAvailable(mapApis, sourceIdentifier)) {
          mapApis.removeSource(sourceIdentifier);
        }
      }
    }
  }, [mapApis, enableMap]);
  return enableMap;
};

/**
 * Draws PCA points onto a map relative to a list of reference points
 *
 * @param mapApis - mapbox api object
 * @param pcaPoints - list of pcaPoints to place
 */
export const drawMapPcaPoints = (mapApis, pcaPoints) => {
  pcaPoints.map(point => {
    const { referencePosition, trackPoint } = point;
    const layerId = `pcaPoint${Math.round(
      referencePosition.latitude + referencePosition.longitude
    )}`;

    if (mapApis) {
      mapApis.addSource(`${layerId}Source`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [trackPoint.lon, trackPoint.lat],
              },
            },
          ],
        },
      });
      mapApis.addLayer({
        id: `${layerId}Layer`,
        type: 'circle',
        source: `${layerId}Source`,
        paint: {
          'circle-radius': 5,
          'circle-color': '#0b6bf2',
        },
      });
    }
  });
};

// Gets center based on array of points
export const getCenter = (points: ILongLatObject[]): [number, number] => {
  const centroid = { longitude: 0, latitude: 0 };
  points.forEach((point: ILongLatObject) => {
    centroid.longitude += point.longitude;
    centroid.latitude += point.latitude;
  });

  if (points.length > 0) {
    centroid.longitude = centroid.longitude / points.length;
    centroid.latitude = centroid.latitude / points.length;
  }

  return [centroid.longitude, centroid.latitude];
};

export const getMinMaxCoordinates = (coordinates: ILongLatObject[] | IPosition[]) => {
  // longitude can take values from -180 to 180
  let longitudeMin = 180;
  let longitudeMax = -180;
  // latitude can take values from -90 to 90
  let latitudeMin = 90;
  let latitudeMax = -90;

  coordinates.forEach((point: ILongLatObject | IPosition) => {
    latitudeMin = Math.min(point.latitude, latitudeMin);
    latitudeMax = Math.max(point.latitude, latitudeMax);

    longitudeMin = Math.min(point.longitude, longitudeMin);
    longitudeMax = Math.max(point.longitude, longitudeMax);
  });
  return { longitudeMin, longitudeMax, latitudeMin, latitudeMax };
};
// returns new coordinates offset by screen pixels
export const offsetCoordinatesByPixels = (
  coordinates: Coordinates,
  pixelOffsetX: number,
  pixelOffsetY: number,
  mapApis: IMapApis
) => {
  const point = mapApis.project(coordinates);
  point.x += pixelOffsetX;
  point.y += pixelOffsetY;
  return mapApis.unproject(point);
};

export const calculateMapZoom = (longLatMin, longLatMax, zoomLevels?: ZoomLevels): number => {
  const maxDistance =
    Math.round(Math.max(longLatMax.lng - longLatMin.lng, longLatMax.lat - longLatMin.lat) * 4) / 4;
  switch (maxDistance) {
    case 0:
      return zoomLevels?.max ?? 12;
    case 0.25:
      return 10;
    case 0.5:
      return 9;
    default:
      return zoomLevels?.min ?? 8;
  }
};

/**
 * Checks network activity of Mapbox calls to see if current loading tiles have active connections
 *
 * @param mapApis map reference
 * @param isLoading API fetch state
 * @param totalCount number of results returned from API
 * @param layerPrefixesToCheck check layers that have names starting with these prefixes
 
 */
export const useHaveTilesLoaded = ({
  mapApis,
  isLoading,
  totalCount,
  layerPrefixesToCheck,
}: {
  mapApis: any;
  isLoading: boolean;
  totalCount: number;
  layerPrefixesToCheck: string[];
}) => {
  const [areTilesLoaded, setAreTilesLoaded] = useState<boolean>(false);

  const isTileLoadingComplete = () => {
    if (!isLoading && totalCount === 0) {
      return true;
    }
    if (mapApis.getStyle()) {
      const availableLayers = Object.keys(mapApis.getStyle().sources);

      const availableLayersToCheck = availableLayers.filter(availableLayerName =>
        layerPrefixesToCheck.some(
          layerNameToCheck => availableLayerName.indexOf(layerNameToCheck) === 0
        )
      );

      if (!availableLayersToCheck.length) {
        return false;
      }

      const areAnyLayersStillLoading = availableLayersToCheck.some(
        layer => !mapApis.isSourceLoaded(layer)
      );
      if (!areAnyLayersStillLoading) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let count = 0;
    if (mapApis) {
      const checkFunc = () => {
        // stop checking after 25 tries
        if (isTileLoadingComplete() || count >= 25) {
          setAreTilesLoaded(true);
        } else {
          count++;
          setTimeout(checkFunc, 1000);
        }
      };
      checkFunc();
    }
  }, [mapApis, isLoading]);

  return { areTilesLoaded, setAreTilesLoaded };
};
