import React, { useState } from 'react';
import { useLanguageSelectors } from 'src/app/reducers';
import { CustomMapControlButton } from '../CustomMapControls';
import { Modal } from '@ems/client-design-system';
import { MapSettingsContainer } from 'src/containers';
import { useMapContext } from 'src/components/Map/context/useMapContext';
import { useMapStyle, useSetMapBackgroundByValue } from 'src/components/Map/hooks/useMapStyles';

interface MapSettingControlProps {
  modalSize?: string;
}
export const MapSettingsControl = ({ modalSize = 'small' }: MapSettingControlProps) => {
  const languageSelectors = useLanguageSelectors();
  const language = languageSelectors.getLanguage();
  const {
    components: {
      headings: { mapSettings },
    },
  } = language;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { mapInstance } = useMapContext();
  const mapStyle = useMapStyle();
  const setBackground = useSetMapBackgroundByValue();

  return (
    <>
      <CustomMapControlButton
        title={mapSettings}
        onClick={toggleModal}
        className="mapboxgl-ctrl-settings"
        iconName="ic-ui-map-layer"
      />
      <Modal
        title={mapSettings}
        className={`mapboxgl-settings-modal modalSize-${modalSize}`}
        canOutsideClickClose={false}
        isOpen={isModalOpen}
        handleUpdate={toggleModal}
        handleReset={toggleModal}
        handleModalClose={toggleModal}>
        <MapSettingsContainer
          instance={mapInstance}
          config={{
            background: String(mapStyle),
            layers: [],
          }}
          onUpdate={({ selectedBackground }) => {
            setBackground(selectedBackground);
          }}
        />
      </Modal>
    </>
  );
};
