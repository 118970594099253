import gql from 'graphql-tag';

export const fetchRoleData = client => {
  return new Promise((resolve, reject) => {
    const GET_ROLES = gql`
      query {
        currentUserPermissions
      }
    `;
    client
      .query({ query: GET_ROLES })
      .then(response => {
        const {
          data: { currentUserPermissions: data },
        } = response;
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
