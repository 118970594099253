import React from 'react';
// selectors
import { useLanguageSelectors, useConfigSelectors } from 'src/app/reducers';
// components
import {
  BlockHeader,
  DescriptionList,
  CardHeader,
  getConvertedTemperature,
  getConvertedHumidity,
  getConvertedPressure,
  getConvertedSpeed,
  getConvertedDirection,
  getConvertedVisibility,
  getConvertedVerticalDistance,
} from '@ems/client-design-system';
import { OperationCard } from 'src/components/OperationCards';
export const OperationWeatherContainer = ({ data }) => {
  const hasData: boolean = (typeof data !== 'undefined' || !data) && !!Object.keys(data).length;
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      weatherDetails: { title },
    },
    fields: {
      periodicWeather: {
        temperature: tempTitle,
        relativeHumidity: humidityTitle,
        atmosphericPressure: pressureTitle,
        windSpeed: speedTitle,
        windDirection: directionTitle,
        visibility: visibilityTitle,
        ceiling: ceilingTitle,
      },
    },
  } = languageSelectors.getLanguage();
  // Config
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  // data
  const {
    precipitation,
    cloudCover,
    temperature,
    relativeHumidity,
    atmosphericPressure,
    windSpeed,
    windDirection,
    visibility,
    ceiling,
  } = data;

  const checkValueExists = (
    value: string | number,
    converter = (value: string | number): string => `${value}`
  ) => {
    return typeof value === 'undefined' || !value ? null : converter(value);
  };

  let weatherDescription = '—';
  if (hasData) {
    if (checkValueExists(precipitation)) {
      weatherDescription = `${precipitation}. ${cloudCover}`;
    } else if (checkValueExists(cloudCover)) {
      weatherDescription = `${cloudCover}`;
    }
  }

  const items: any = [
    {
      title: tempTitle,
      description: getConvertedTemperature(temperature, units.temperature, 0),
    },
    {
      title: humidityTitle,
      description: getConvertedHumidity(relativeHumidity, units.humidity, 0),
    },
    {
      title: pressureTitle,
      description: getConvertedPressure(atmosphericPressure, units.pressure, 0),
    },
    {
      title: speedTitle,
      description: getConvertedSpeed(windSpeed, units.speed, 0),
    },
    {
      title: directionTitle,
      description: getConvertedDirection(windDirection, units.direction, 0),
    },
    {
      title: visibilityTitle,
      description: getConvertedVisibility(visibility, units.visibility, 0),
    },
    {
      title: ceilingTitle,
      description: getConvertedVerticalDistance(ceiling, units.distanceVertical, 0),
    },
  ];

  return (
    <div className="block">
      <BlockHeader title={title} loading={!hasData} />
      <OperationCard>
        <>
          <CardHeader description={weatherDescription} loading={!hasData} />
          <DescriptionList
            className={weatherDescription ? `second-row-info` : ``}
            items={items}
            loading={!hasData}
          />
        </>
      </OperationCard>
    </div>
  );
};
