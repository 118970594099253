// utils
import { getGraphQlEndpoint } from 'src/utils/generics';

// Don't put anything in here that shouldn't be public!
const $: any = window;
if (typeof $.GRAPHQL_ENDPOINT === 'undefined' || !$.GRAPHQL_ENDPOINT) {
  console.error('FATAL ERROR: APPLICATION CONFIG IS MISSING!');
}

export const appVersion = $.VERSION;
export const appBuild = $.BUILD;
export const appTimestamp = $.TIMESTAMP;
export const appAvailableFilters = $.FILTERS;
export const cdnPath = $.CDN_PATH;
export const graphQlOrigin = getGraphQlEndpoint({
  dev: $.GRAPHQL_ENDPOINT,
  demo1: $.GRAPHQL_ENDPOINT_DEMO1,
  demo2: $.GRAPHQL_ENDPOINT_DEMO2,
});
export const anomsApi = `${graphQlOrigin}WebServices.AnomsApi/graphql/`;
export const mapLayerApi = `${graphQlOrigin}WebServices.MapLayerApi/`;
export const authConfig = {
  clientId: $.AUTH_CLIENT_ID,
  instance: $.AUTH_INSTANCE,
  tenant: $.AUTH_TENANT,
  signInPolicy: $.AUTH_SIGN_IN_POLICY,
  scopes: $.AUTH_SCOPES,
  redirectUri: window.location.origin,
  idleSessionTimeout: 1000 * 60 * 180, // 3 hours
};
export const heapAppId = $.HEAP_APP_ID;
