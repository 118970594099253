import { withRouteProps } from 'src/app/hocs/withRouteProps';
import React from 'react';
import { RegularLayout } from 'src/components/Layout';
import { AirtrakStateProvider } from 'src/@airtrak/providers/AirtrakStateProvider';
import { DataContainer, HeaderContainer, BreakDownContainer, ViewContainer, ContentContainer, FilterContainer, CarbonEmissionReProcessStatusContainer } from 'src/@airtrak/containers';
// functions
import { getRoute } from 'src/app/functions/routing';

const AirtrakScreen = ({ updateUrl, location }) => (
  <RegularLayout>
    <AirtrakStateProvider>
      <DataContainer route={getRoute(location.pathname)}>
        <div className='airtrak-single-container'>
          <CarbonEmissionReProcessStatusContainer />
          <HeaderContainer updateUrl={updateUrl} />
          <div className="airtrak-sub-header">
            <ViewContainer />
            <BreakDownContainer />
          </div>
          <FilterContainer />
          <ContentContainer />
        </div>
      </DataContainer>
    </AirtrakStateProvider>
  </RegularLayout>
);

export const Airtrak = withRouteProps(AirtrakScreen);