import React from 'react';

// Components
import { Dropdown, IDropdownItem } from '@ems/client-design-system';
import { SettingsSelectorWrapper } from '.';

export const SettingsDropdownList = ({
  title,
  className,
  options,
  selected,
  onChangeHandler,
}: {
  title: string;
  className: string;
  options: IDropdownItem[];
  selected: IDropdownItem | null;
  onChangeHandler: (value: string) => void;
}) => {
  const onChange = (value: string) => {
    onChangeHandler(value);
  };

  return (
    <SettingsSelectorWrapper className={className} title={title}>
      <Dropdown
        isNullable={false}
        placeholderValue={'Language'}
        searchItems={options}
        updateSelection={(value: IDropdownItem) => {
          onChange(value.key);
        }}
        selectedItem={selected}
      />
    </SettingsSelectorWrapper>
  );
};
