import React from 'react';
import { Position, Tooltip as BlueprintTooltip } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { InlineDropDown, Tooltip, Checkbox, Lightswitch } from '@ems/client-design-system';
// shared components
import { TableIcon, ColumnHeader } from 'src/components';
// action
import { sortTable } from 'src/@infringements/actions';
// ts
import { IInfringementsData } from 'src/@infringements/interfaces';
// utils
import { getDeployedProductId, tableDateTimeFormat, tableDateTimeFormatShort } from 'src/utils';
// constants
import { INFRINGEMENTS } from 'src/constants';

/*
 * Aircraft icon
 */
const flightIcon = (acType: string | null, translationData) => {
  if (!acType) {
    return acType;
  }
  return (
    <Tooltip content={translationData[acType]}>
      {TableIcon({
        name: acType.toLowerCase(),
        prefix: 'ac',
        size: 26,
      })}
    </Tooltip>
  );
};

/*
 * Status icon
 */
const statusIcon = (
  infringement: IInfringementsData,
  items,
  inlineClick,
  canUpdate,
  candidatesEnabled
) => {
  const updateSelection = newStatus => {
    let id: null | number = null;
    if (
      candidatesEnabled &&
      typeof infringement.infringementId !== 'undefined' &&
      infringement.isInfringement
    ) {
      id = infringement.infringementId;
    } else {
      id = infringement.id;
    }
    inlineClick(id, newStatus.key, `${infringement.infringementType}Infringement`);
  };

  let selectedItem = items.find(item => item.key === infringement.status);
  // if inf status = null
  if (selectedItem === undefined) {
    selectedItem = {
      key: '—',
      label: '—',
      icon: <span className="status_icon" style={{ backgroundColor: 'transparent', width: 0 }} />,
    };
  }

  if (!canUpdate || (candidatesEnabled && !infringement.isInfringement)) {
    return (
      <div className="u-flex-center">
        {selectedItem.icon}
        {selectedItem.label}
      </div>
    );
  }

  return (
    <div className="u-flex-center">
      <InlineDropDown
        searchItems={items}
        hasIcon={false}
        isIconOnly={false}
        isNullable={false}
        updateSelection={updateSelection}
        selectedItem={selectedItem}
      />
    </div>
  );
};

/*
 * Rule Name link
 */
const linkToInfDetails = (rule: string | null, id: any | null, ruleInfo) => (
  <Link
    className="rule_link"
    to={{
      pathname: `/${getDeployedProductId()}/${INFRINGEMENTS}/${id}`,
      search: undefined,
      state: ruleInfo,
    }}>
    {rule}
  </Link>
);

const vectoredIcon = (defaultChecked, handleChange, operationId) => {
  const handleValueHandover = event => {
    handleChange(event.target.checked, operationId);
  };

  return (
    <Checkbox variant="circle" defaultChecked={defaultChecked} onChange={handleValueHandover} />
  );
};

/*
 * Prepare infringement for render on table
 */
export const formatInfringementData = (
  data,
  translationData: object,
  items,
  inlineClick,
  ruleInfo,
  canUpdate,
  candidatesEnabled,
  twelveHourFormat
) => {
  const dataLength = data.length;
  const formattedData: IInfringementsData[] = [];
  if (dataLength) {
    for (let i = dataLength; i--; ) {
      const infringement = data[i];
      const id = candidatesEnabled ? infringement.infringementId : infringement.id;
      const ruleLink = linkToInfDetails(infringement.ruleName, id, ruleInfo);

      infringement.displayRuleName =
        ((candidatesEnabled && infringement.isInfringement) || !candidatesEnabled) &&
        // Check valid op id (not null)
        infringement.operationId &&
        infringement.operationId !== 0 ? (
          ruleLink
        ) : (
          <span className="no-rule_link">{infringement.ruleName}</span>
        );
      infringement.displayTime = tableDateTimeFormat(infringement.time, twelveHourFormat);
      infringement['displayTime-short'] = tableDateTimeFormatShort(
        infringement.time,
        twelveHourFormat
      );
      infringement.displayCategory = flightIcon(infringement.aircraftCategory, translationData);
      infringement.displayStatus = statusIcon(
        infringement,
        items,
        inlineClick,
        canUpdate,
        candidatesEnabled
      );
      infringement.displayInfringement = translationData[infringement.infringementType];
      infringement.displaySeverity = translationData[infringement.severity];
      infringement.displayCandidates = candidatesEnabled ? (
        infringement.isInfringement ? (
          'Infringement'
        ) : (
          <span className="candidates-text">Candidate</span>
        )
      ) : (
        'Infringement'
      );
      formattedData.unshift(infringement);
    }
  }
  return formattedData;
};

export const formatATCInfringementData = (
  data,
  translationData: object,
  ruleInfo,
  handleChange,
  twelveHourFormat
) => {
  const dataLength = data.length;
  const formattedData: IInfringementsData[] = [];
  if (dataLength) {
    for (let i = dataLength; i--; ) {
      const infringement = data[i];

      infringement.displayRuleName = linkToInfDetails(
        infringement.ruleName,
        infringement.id,
        ruleInfo
      );
      infringement.displayTime = tableDateTimeFormat(infringement.time, twelveHourFormat);
      infringement['displayTime-short'] = tableDateTimeFormatShort(
        infringement.time,
        twelveHourFormat
      );
      infringement.displayCategory = flightIcon(infringement.aircraftCategory, translationData);
      infringement.displayVectored = vectoredIcon(
        Array.isArray(infringement.operationTags) && infringement.operationTags.length
          ? true
          : false,
        handleChange,
        infringement.operationId
      );
      infringement.displayInfringement = translationData[infringement.infringementType];
      infringement.displaySeverity = translationData[infringement.severity];
      formattedData.unshift(infringement);
    }
  }
  return formattedData;
};

/*
 * Prepare infringement header
 */
export const formatHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  switchState: boolean,
  onClick: any,
  ruleFilterApplied: boolean,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config

  const {
    fields: { infringements: rowHeaders },
    abbreviations: { aircraftCategory: aircraftCategoryAbrr, runwayName: runwayNameAbbr },
    components: {
      labels: { sortBy },
      tooltip: { candidates: candidatesTooltipText },
    },
  } = translationData;

  const acid = ColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRuleName = ColumnHeader({
    sortName: 'ruleName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRuleName,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const displayInfringement = ColumnHeader({
    sortName: 'infringementType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const displaySeverity = ColumnHeader({
    sortName: 'severity',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.severity,
      abbreviation: null,
      sortBy,
    },
  });
  const remoteAirportId = ColumnHeader({
    sortName: 'remoteAirportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.remoteAirportId,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRunwayName = ColumnHeader({
    sortName: 'runwayName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRunwayName,
      abbreviation: runwayNameAbbr,
      sortBy,
    },
  });
  const displayCategory = ColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = ColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayStatus = ColumnHeader({
    sortName: 'status',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayStatus,
      abbreviation: null,
      sortBy,
    },
  });
  const displayCandidates = ColumnHeader({
    sortable: false,
    sortName: 'candidates',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayStatus,
      abbreviation: null,
      sortBy,
    },
    content: (
      <BlueprintTooltip
        content={candidatesTooltipText}
        position={Position.TOP}
        disabled={ruleFilterApplied}>
        <Lightswitch
          enabled={switchState}
          disabled={!ruleFilterApplied}
          label="Show candidates"
          onClick={onClick}
        />
      </BlueprintTooltip>
    ),
  });

  return Object.assign({}, rowHeaders, {
    displayRuleName,
    displayTime,
    displaySeverity,
    displayInfringement,
    remoteAirportId,
    displayRunwayName,
    displayCategory,
    acid,
    aircraftType,
    displayStatus,
    displayCandidates,
  });
};

export const formatATCHeaders = (
  resultSize: number,
  isLoading: boolean,
  dispatcher: any,
  sortSelectors: any,
  translationData: any
) => {
  // Overrides the headers provided
  // could improve and loop through data from config

  const {
    fields: { infringements: rowHeaders },
    abbreviations: { aircraftCategory: aircraftCategoryAbrr, runwayName: runwayNameAbbr },
    components: {
      labels: { sortBy },
    },
  } = translationData;

  const acid = ColumnHeader({
    sortName: 'acid',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.acid,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRuleName = ColumnHeader({
    sortName: 'ruleName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRuleName,
      abbreviation: null,
      sortBy,
    },
  });
  const displayTime = ColumnHeader({
    sortName: 'time',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayTime,
      abbreviation: null,
      sortBy,
    },
  });
  const displayInfringement = ColumnHeader({
    sortName: 'infringementType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.operationType,
      abbreviation: null,
      sortBy,
    },
  });
  const displaySeverity = ColumnHeader({
    sortName: 'severity',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.severity,
      abbreviation: null,
      sortBy,
    },
  });
  const remoteAirportId = ColumnHeader({
    sortName: 'remoteAirportId',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.remoteAirportId,
      abbreviation: null,
      sortBy,
    },
  });
  const displayRunwayName = ColumnHeader({
    sortName: 'runwayName',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayRunwayName,
      abbreviation: runwayNameAbbr,
      sortBy,
    },
  });
  const displayCategory = ColumnHeader({
    sortName: 'aircraftCategory',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.displayCategory,
      abbreviation: aircraftCategoryAbrr,
      sortBy,
    },
  });
  const aircraftType = ColumnHeader({
    sortName: 'aircraftType',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    languageData: {
      title: rowHeaders.aircraftType,
      abbreviation: null,
      sortBy,
    },
  });
  const displayVectored = ColumnHeader({
    sortName: 'vectored',
    resultSize,
    isLoading,
    dispatcher,
    sortSelectors,
    sortTable,
    sortable: false,
    languageData: {
      title: rowHeaders.displayVectored,
      abbreviation: null,
      sortBy,
    },
  });

  return Object.assign({}, rowHeaders, {
    displayRuleName,
    displayTime,
    displaySeverity,
    displayInfringement,
    remoteAirportId,
    displayRunwayName,
    displayCategory,
    acid,
    aircraftType,
    displayVectored,
  });
};
