// constants
import { getConvertedDistance } from '@ems/client-design-system';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import {
  OPERATIONS,
  INFRINGEMENTS,
  NOISEEVENTS,
  UNIT_FOOT,
  UNIT_METER,
  COMPLAINTS,
} from 'src/constants';
import {
  setMeasurementAsNumber,
  getImperialOrMetricBaseUnit,
  getVerticalDistance,
} from 'src/utils';

export const formatCSVData = (source: string, tableData: { [key: string]: any }) => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const { fields: tableHeaderStrings } = languageSelectors.getLanguage();
  const configSelectors = useConfigSelectors();
  const units = configSelectors.getUnits();
  const unitDistance = /^(km|m)$/.exec(units.distance) ? UNIT_METER : UNIT_FOOT;
  const convertDistanceTo = getImperialOrMetricBaseUnit(unitDistance);

  const operationColumns = [
    'id',
    'acid',
    'operationType',
    'startTime',
    'endTime',
    'airportId',
    'remoteAirportId',
    'runwayName',
    'operatorCategory',
    'aircraftType',
    'correlated',
    'pca',
  ];

  const infringementColumns = [
    'id',
    'acid',
    'ruleName',
    'ruleId',
    'infringementType',
    'time',
    'aircraftCategory',
    'aircraftType',
    'status',
  ];

  const noiseEventColumns = [
    'id',
    'locationId',
    'locationName',
    'time',
    'startTime',
    'endTime',
    'maxLevel',
    'sel',
    'duration',
    'operationId',
    'operationType',
    'acid',
    'aircraftCategory',
    'aircraftType',
    'cause',
  ];

  const complaintColumns = [
    'id',
    'time',
    'operationId',
    'complainerName',
    'complainerId',
    'complainer',
    'disturbanceTypes',
  ];

  let result: { [key: string]: string | number } = {};

  switch (source) {
    case OPERATIONS: {
      result = tableData.map(operation =>
        Object.keys(operation).reduce((list: { [key: string]: string | number }, key) => {
          if (operationColumns.includes(key)) {
            const translatedKey = tableHeaderStrings.operations[key] || key;
            // Check correlated key and return as string if it is an array
            if (key === 'correlated') {
              const correlatedObj: Record<string, any> = operation[key];
              const keysWithData = Object.keys(correlatedObj).filter(
                correlation =>
                  Array.isArray(correlatedObj[correlation]) && correlatedObj[correlation].length > 0
              );
              list[translatedKey] = keysWithData.join(', ');
            } else if (key === 'pca') {
              list.pcaSlantDistance = setMeasurementAsNumber(
                getConvertedDistance(operation[key].slantDistance, convertDistanceTo, 1)
              );
              list.pcaHorizontalDistance = setMeasurementAsNumber(
                getConvertedDistance(operation[key].horizontalDistance, convertDistanceTo, 1)
              );
              list.pcaVerticalDistance = getVerticalDistance(operation[key].verticalDistance, {
                convertTo: getImperialOrMetricBaseUnit(units.distanceVertical),
                convertFrom: 'ft',
                returnValueType: 'number',
              });
              list.pcaTime = operation[key].time;
            } else {
              list[translatedKey] = operation[key];
            }
          }
          return list;
        }, {})
      );

      break;
    }
    case INFRINGEMENTS: {
      result = tableData.map(infringement =>
        Object.keys(infringement).reduce((list, key) => {
          if (infringementColumns.includes(key)) {
            const translatedKey = tableHeaderStrings.infringements[key] || key;
            list[translatedKey] = infringement[key];
          }
          return list;
        }, {})
      );
      break;
    }
    case NOISEEVENTS: {
      result = tableData.map(noiseEvent =>
        Object.keys(noiseEvent).reduce((list, key) => {
          if (noiseEventColumns.includes(key)) {
            const translatedKey = tableHeaderStrings.noiseEvents[key]
              ? tableHeaderStrings.noiseEvents[key]
              : key;

            list[translatedKey] = noiseEvent[key];
          }
          return list;
        }, {})
      );
      break;
    }
    case COMPLAINTS: {
      result = tableData.map(complaints =>
        Object.keys(complaints).reduce((list, key) => {
          if (complaintColumns.includes(key)) {
            const translatedKey = tableHeaderStrings.complaints[key] || key;
            if (key === 'complainer') {
              list['postcode'] = complaints[key].address.postcode;
            } else if (key === 'reason') {
              list[translatedKey] = complaints[key].join(', ');
            } else {
              list[translatedKey] = complaints[key];
            }
          }
          return list;
        }, {})
      );
      break;
    }
    default:
      result = null;
      break;
  }

  return result;
};
