import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { FilterContainer, MapInfoContainer, DataContainer } from 'src/@infringements/containers';
import { TableContainer, HeaderContainer } from 'src/@infringements/containers/ATC';
import { InfringementsStateProvider } from 'src/@infringements/providers/InfringementsStateProvider';
import { ScreenLoadStateProvider } from 'src/app/providers/ScreenLoadStateContext';
import { useConfigSelectors } from 'src/app/reducers/configReducer';
import { getRoute } from 'src/app/functions/routing';

const InfringementsScreen = ({ location, updateUrl }) => {
  const { pathname } = location;
  const source = getRoute(pathname);
  const configSelectors = useConfigSelectors();
  const {
    infringementsATC: {
      filter: { availableCategoryList },
    },
  } = configSelectors.getConfig();

  // Configuration
  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();

  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  return (
    <SplitLayout>
      <ScreenLoadStateProvider>
        <InfringementsStateProvider>
          <DataContainer source={source}>
            {!isGridFullscreen && <MapInfoContainer />}

            <div className="container-fluid">
              <div className="header-container">
                <HeaderContainer updateUrl={updateUrl} />
              </div>
              <FilterContainer source={source} availableCategoryList={availableCategoryList} />
              <TableContainer source={source} />
            </div>
          </DataContainer>
        </InfringementsStateProvider>
      </ScreenLoadStateProvider>
    </SplitLayout>
  );
};

export const InfringementsATC = withRouteProps(InfringementsScreen);
