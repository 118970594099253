import React, { FC, useState } from 'react';
import { Dropdown, FormTextField } from '@ems/client-design-system';
import { IFormFieldProps } from './interfaces';

export const FormField: FC<IFormFieldProps> = ({
  label,
  value,
  onFormUpdate,
  readOnly,
  inputType,
  className,
  formKey,
  validations,

  ...props
}) => {
  const [inputValue, updateInputValue] = useState<string>(value);

  const onChange = (value: string) => {
    if (onFormUpdate) {
      updateInputValue(value);
      onFormUpdate(formKey, value);
    }
  };

  const updateDropdownSelection = (item: any) => {
    updateInputValue(item.label);
    onFormUpdate(formKey, item.key);
  };

  switch (inputType) {
    case 'dropdown':
      return (
        <div className={`${className ? className : ''} form-group`}>
          <Dropdown
            label={label}
            placeholderValue={inputValue}
            isNullable={false}
            updateSelection={item => updateDropdownSelection(item)}
            disabled={readOnly}
            searchItems={[]}
            width={300}
            selectedItem={null}
            {...props}
          />
        </div>
      );
    case 'text':
    default:
      return (
        <FormTextField
          className={className}
          autoFocus
          label={label}
          id={label}
          sideLabelEditMode={props.sideLabelEditMode}
          onChange={onChange}
          isNumberOnly={validations.isNumber}
          value={inputValue}
        />
      );
  }
};
