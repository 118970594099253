import React from 'react';
import { CustomControlWrapper } from '../../MapControl.styles';
import { Icons } from '@ems/client-design-system';

interface CustomMapControls {
  children?: React.ReactNode;
  className?: string;
}

export const CustomMapControls = ({ children, className }: CustomMapControls) => (
  <CustomControlWrapper className={`mapboxgl-ctrl mapboxgl-ctrl-group ${className ?? ''}`}>
    {children}
  </CustomControlWrapper>
);

interface CustomMapControlButton {
  title: string;
  onClick: () => void;
  iconName: string;
  className?: string;
}

export const CustomMapControlButton = ({ title, onClick, className, iconName }) => (
    <CustomMapControls>
      <button title={title} className={className ?? ''} onClick={onClick}>
        <Icons size={20} iconName={iconName} />
      </button>
    </CustomMapControls>
  );
