import gql from 'graphql-tag';

export const ADD_ZONE = gql`
  mutation addPolygonSelectionZone($zone: InputPolygonSelectionZone!) {
    addPolygonSelectionZone(zone: $zone) {
      id
      name
      groupName
      isActive
      ... on PolygonSelectionZone {
        floorAltitude
        ceilingAltitude
        points {
          altitude
          latitude
          longitude
        }
      }
    }
  }
`;

export const UPDATE_ZONE = gql`
  mutation UpdatePolygonSelectionZone($zone: InputPolygonSelectionZone!) {
    updatePolygonSelectionZone(zone: $zone) {
      id
      name
      groupName
      isActive
      ... on PolygonSelectionZone {
        floorAltitude
        ceilingAltitude
        points {
          altitude
          latitude
          longitude
        }
      }
    }
  }
`;

export const DELETE_ZONE = gql`
  mutation deletePolygonSelectionZone($id: Int!) {
    deleteGate(id: $Int)
  }
`;
