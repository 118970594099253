import React, { FC, useState, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
// common components
import { StyledMap, MapControl, GeocoderPin } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';
// selectors
import { useDataSelectors } from 'src/@complaints/reducers';
// functions
import { useMapSettings } from 'src/app/functions/mapSettings';
import { useMapRef, useMapWhenReady, useMapProps, useMapConfig } from 'src/app/functions/map';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { useGeocoderPinAlternative } from 'src/utils';
import { flyTo, fitPointsInMap } from 'src/utils';
import { useGeocodePosition } from 'src/utils/geocoding';
import { ComplaintsPopup, MapSelectionArea } from 'src/components';
import { IMapProps } from 'src/@infringements/interfaces';
import { ISelectMonitorCoords, ISelectAddress } from 'src/@complaints/interfaces';
import { TOGGLE_MAP_SETTINGS_CTRL } from 'src/app/featureToggles';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

export const EntryPageMapContainer: FC<IMapProps> = () => {
  const client = useApolloClient();
  // get map props from config
  const {
    viewportFromProps,
    mapboxApiAccessToken,
    mapStyle: defaultMapStyle,
    ...mapProps
  } = useMapProps('2D');
  // map settings
  const {
    mapStyle,
    storeSelectedBackground,
    applyBackground,
    resetBackground,
    layersDisplayed,
    storeSelectedLayers,
    applyLayers,
    resetLayers,
  } = useMapSettings({
    background: defaultMapStyle,
    layers: [],
  });
  // used for taking screenshot of map
  const captureRef = useRef(null);
  // map ref
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
  // viewport in state
  const [viewport, setViewport] = useState(viewportFromProps);
  // get mapbox config values required to add source and styles
  const mapBoxConfig = useMapConfig();
  // Configuration
  const configSelectors = useConfigSelectors();
  const dataSelectors = useDataSelectors();
  const {
    globals: { distanceUnits, altitudeUnits },
  } = configSelectors.getConfig();
  const [locationAddress, updateLocationAddress] = useState<null | string>(null);
  const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
  const [selectionBounds]: any = useState(null);
  const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>({
    longitude: 0,
    latitude: 0,
  });

  const selectedAddress: ISelectAddress = dataSelectors.getSelectedAddress();
  // get field labels from language selectors
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { mapSettings: mapSettingsTitle },
      labels: { backToCenter: backToCenterLabel, lat: latLabel, lng: lngLabel, amsl: amslLabel },
    },
  } = languageSelectors.getLanguage();
  const { addRemoveCircles } = useCircleRanges(mapApis, distanceUnits);

  const onViewportChange = viewport => {
    if (
      Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
      Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
    ) {
      setViewport(viewport);
    }
  };
  // resets map view
  const resetView = () => {
    if (mapApis) {
      const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
      flyTo(mapApis, resetViewport).then(() => {
        setViewport(Object.assign({}, viewport, resetViewport));
      });
    }
  };

  const { latitude, longitude } = geocoding;
  const { elevation, place } = useGeocodePosition({
    client,
    position: {
      longitude,
      latitude,
    },
  });

  // capture map image
  const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);
  useGeocoderPinAlternative({
    mapApis,
    enableMap: enableMapControls,
    coordinates: [[longitude, latitude]],
  });

  useEffect(() => {
    const { place_name, position } = selectedAddress;
    updateGeocoding({
      longitude: position ? position.longitude : 0,
      latitude: position ? position.latitude : 0,
    });
    updateLocationAddress(place_name || null);
    if (mapApis) {
      const selectedMonitorCoords: ISelectMonitorCoords[] = [];
      if (position && position.longitude && position.longitude) {
        selectedMonitorCoords.push({
          longitude: position.longitude,
          latitude: position.latitude,
        });
      }
      fitPointsInMap(mapApis, viewport, setViewport, selectedMonitorCoords);
    }
  }, [selectedAddress, mapApis]);

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
  const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

  return (
    <div
      className={cx('map_wrapper', {
        'map_wrapper--fullscreen': isMapFullscreen,
        'map_wrapper--collapsed': isGridFullscreen,
      })}>
      <div ref={captureRef} className="map">
        <StyledMap
          onLoad={() => mapLoaded()}
          viewport={viewport}
          onViewportChange={viewport => {
            viewport.maxPitch = 0;
            onViewportChange(viewport);
          }}
          mapStyle={mapStyle}
          mapboxApiAccessToken={mapboxApiAccessToken}
          {...mapProps}
          ref={mapRef}
          transformRequest={
            mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
          }
          height={mapHeight}>
          <ComplaintsPopup
            latitude={latitude}
            longitude={longitude}
            address={locationAddress || place}
            elevation={elevation}
            altitudeUnits={altitudeUnits}
            languageData={{ latLabel, lngLabel, amslLabel }}
            mapApis={mapApis}
          />
          <MapSelectionArea selectionBounds={selectionBounds} />
          <GeocoderPin
            latitude={enableMapControls ? latitude : 0}
            longitude={enableMapControls ? longitude : 0}
            draggable={false}
            onClick={() => {
              updateLocationTagOpen(!isLocationTagOpen);
            }}
            onDragStart={() => {
              addRemoveCircles(null);
              updateLocationTagOpen(false);
            }}
            onDragEnd={([longitude, latitude]) => {
              if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined') {
                updateGeocoding({ longitude, latitude });
              }
              addRemoveCircles({ longitude, latitude });
              setTimeout(() => {
                updateLocationTagOpen(true);
              }, 1);
            }}
          />
          {enableMapControls && (
            <MapControl
              navigationControl={{
                showCompass: false,
                showHome: true,
                showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
              }}
              translationData={{
                home: backToCenterLabel,
                mapSettings: mapSettingsTitle,
              }}
              resetView={resetView}
              mapSettingsConfig={{
                update: () => {
                  applyBackground();
                  applyLayers();
                },
                reset: () => {
                  resetBackground();
                  resetLayers();
                },
                content: (
                  <MapSettingsContainer
                    config={{
                      background: mapStyle,
                      layers: layersDisplayed,
                    }}
                    onUpdate={({ selectedBackground, selectedLayers }) => {
                      if (typeof selectedBackground !== 'undefined') {
                        storeSelectedBackground(selectedBackground);
                      }
                      if (typeof selectedLayers !== 'undefined') {
                        storeSelectedLayers(selectedLayers);
                      }
                    }}
                  />
                ),
              }}
            />
          )}
          <MapReferenceLayers
            mapApis={mapApis}
            mapRef={{ current: mapNode }}
            mapStyle={mapStyle}
            layers={layersDisplayed}
            dateRange={{
              dateFilterFrom: dateTimeInQueryUTC(new Date(), 'start'),
              dateFilterTo: dateTimeInQueryUTC(new Date(), 'end'),
            }}
          />
        </StyledMap>
      </div>
    </div>
  );
};
