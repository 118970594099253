import gql from 'graphql-tag';

export const GET_AIRCRAFT_MAPPINGS = gql`
  query getAircraftMappings(
    $scenarioId: ID!
    $startTime: DateTimeOffset!
    $endTime: DateTimeOffset!
    $sort: [OrderByType]
    $first: Int
    $after: String
    $filter: AirQualityAircraftMappingSummaryFilter
  ) {
    airQualityAircraftMappingSummariesByTimeRange(
      scenarioId: $scenarioId
      startTime: $startTime
      endTime: $endTime
      sort: $sort
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          aircraftType
          engineModel
          mappedEnrouteAircraftEngine
          mappedLTOAircraftEngine
          numberOfFlights
        }
      }
    }
  }
`;

export const GET_ENGINE_MAPPING_VALUES = gql`
  query getAircraftMappingFilters {
    ltoAircraftEngineMappings {
      aircraftType
      engine
    }
    enrouteAircraftEngineMappings {
      aircraftType
      engine
    }
  }
`;

export const SET_AIRCRAFT_MAPPING = gql`
  mutation setAirQualityAircraftTypeMap(
    $scenarioId: ID!
    $aircraftType: String!
    $engineModel: String!
    $ltoEngineModel: String
    $ltoAircraftType: String
    $enrouteEngineModel: String
    $enrouteAircraftType: String
    $source: AirQualityMappingSource
  ) {
    setAirQualityAircraftTypeMap(
      scenarioId: $scenarioId
      aircraftType: $aircraftType
      engineModel: $engineModel
      ltoEngineModel: $ltoEngineModel
      ltoAircraftType: $ltoAircraftType
      enrouteEngineModel: $enrouteEngineModel
      enrouteAircraftType: $enrouteAircraftType
      source: $source
    ) {
      aircraftType
      engineModel
      id
      mappedEnrouteAircraft
      mappedEnrouteEngine
      mappedLTOAircraft
      mappedLTOEngine
      scenarioId
      source
    }
  }
`;
