import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { FilterContainer, TableContainer, HeaderContainer } from 'src/@reports/containers';
import { RegularLayout } from 'src/components/Layout';
import { DataContainer } from 'src/@reports/containers';
import { ReportsStateProvider } from 'src/@reports/providers/ReportsStateProvider';

const ReportsScreen = ({ updateUrl }) => (
  <RegularLayout>
    <ReportsStateProvider>
      <DataContainer>
        <div className="container-single">
          <div className="header-container">
            <HeaderContainer updateUrl={updateUrl} />
          </div>
          <FilterContainer />
          <TableContainer />
        </div>
      </DataContainer>
    </ReportsStateProvider>
  </RegularLayout>
);

export const Reports = withRouteProps(ReportsScreen);
