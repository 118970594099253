import { useState, useEffect, useContext } from 'react';
// selectors
import { useExportSelectors } from 'src/app/reducers';
// actions
import { updateImageExportCaptured } from 'src/app/actions/exportActions';
// context
import { ExportDispatchContext } from 'src/app/providers/ExportProvider';
// functions
import { takeScreenshotFromMap, takeScreenshotFromGraph } from 'src/utils';
// props
import { TExportImage } from 'src/app/props';
// data
import { noContentImage } from 'src/app/data';

export const body = document.getElementsByTagName('body')[0];

export const freezePage = () =>
  new Promise<boolean>(resolve => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      body.style.overflow = 'hidden';
      body.style['pointer-events'] = 'none';
      resolve(true);
    }, 250);
  });

export const unFreezePage = () => {
  body.style.overflow = 'visible';
  body.style['pointer-events'] = 'auto';
};

export const useMapReftoCaptureImage = (captureRef, mapApis) => {
  const exportDispatcher: any = useContext(ExportDispatchContext);
  const exportSelectors = useExportSelectors();
  const captureImage = exportSelectors.captureImage();
  const [enableMapControls, setMapControlsAvailability] = useState<boolean>(true);

  const makeARequest = () => {
    takeScreenshotFromMap({
      captureRef: captureRef.current.firstChild,
      mapApis,
    }).then(base64Image => {
      updateImageExportCaptured({
        dispatcher: exportDispatcher,
        image: 'trackmapImage',
        base64Image,
      });
    });
  };

  useEffect(() => {
    if (captureImage) {
      // turn map control buttons off momentarily
      setMapControlsAvailability(false);
      setTimeout(() => {
        makeARequest();
      }, 2000); // give enough time to capture map-image, auto-zoom the graph (optional) and hide reset button
    } else if (!enableMapControls) {
      setMapControlsAvailability(true);
    }
  }, [captureImage]);

  return {
    enableMapControls,
  };
};

export const useGraphReftoCaptureImage = ({
  captureRef,
  isGraphAvailable, // if graph is not available, screenshot must be ignored
  image,
}: {
  captureRef: any;
  isGraphAvailable: boolean;
  enableAutoZoom?: boolean;
  image: TExportImage;
}) => {
  const exportDispatcher: any = useContext(ExportDispatchContext);
  const exportSelectors = useExportSelectors();
  const captureImage = exportSelectors.captureImage();
  const [readyToCapture, setReadyToCapture] = useState<boolean>(false);
  const requiredToCapture = exportSelectors.whatIsRequiredToCapture();

  const makeARequest = () => {
    takeScreenshotFromGraph({
      captureRef: captureRef.current,
    }).then(base64Image => {
      updateImageExportCaptured({
        dispatcher: exportDispatcher,
        image,
        base64Image,
      });
    });
  };

  useEffect(() => {
    if (captureImage && requiredToCapture.indexOf(image) !== -1) {
      if (isGraphAvailable) {
        setReadyToCapture(true);
        setTimeout(() => {
          makeARequest();
        }, 2000); // give enough time to capture map-image, auto-zoom the graph (optional) and hide reset button
      } else {
        setReadyToCapture(false);
        updateImageExportCaptured({
          dispatcher: exportDispatcher,
          image,
          base64Image: noContentImage,
        });
      }
    } else {
      setReadyToCapture(false);
    }
  }, [captureImage]);

  return {
    readyToCapture,
  };
};
