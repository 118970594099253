import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { gisQuery, gisSchema } from 'src/app/resolvers/schema';
// function
import { getAuth } from 'src/app/functions/storage';
import { getDeployedProductId } from 'src/utils';
// config
import { mapLayerApi } from 'src/config';

export const fetchCustomLayersData = (client: ApolloClient<object>) => {
  return new Promise((resolve, reject) => {
    const GET_GIS_LAYERS = gql`
      query {
        ${gisQuery} ${gisSchema}
      }
    `;
    client
      .query({ query: GET_GIS_LAYERS })
      .then(response => {
        if (response && response.data && response.data[gisQuery]) {
          resolve(response.data[gisQuery]);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

// Readable Stream
export const fetchKml = async (id: number) => {
  const response = await fetch(
    `${mapLayerApi}${getDeployedProductId()}/vectorlayer?id=${id}&filename=vectorlayer.kml`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/vnd.google-earth.kml+xml',
        Authorization: getAuth().authorization,
      },
    }
  );

  if (response.body) {
    const reader = response.body.getReader();
    let receivedLength = 0; // received that many bytes at the moment
    const chunks: any = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;
    }

    const chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (const chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    const result = new TextDecoder('utf-8').decode(chunksAll);
    return result;
  } else {
    return null;
  }
};
