import React, { ReactNode, useContext, useReducer } from 'react';
import { createContext } from 'react';
import { IMonitorLocation, LocationItem } from '../interfaces';
import { locationManagementReducer } from './LoctionManagementReducer';

export interface IContextProps {
  children: ReactNode;
}
export interface LocationManagementContextState {
  nmtListData: LocationItem[];
  isEditing: boolean;
  locationsList: IMonitorLocation[];
  deviceMonitorLocation: null | IMonitorLocation;
}

export const defaultLocationManagementState: LocationManagementContextState = {
  nmtListData: [] as LocationItem[],
  isEditing: false,
  locationsList: [] as IMonitorLocation[],
  deviceMonitorLocation: null,
};

const LocationManagementContext = createContext(null);

export const useLocationManagementContext = () => {
  const context = useContext(LocationManagementContext);
  if (!context) {
    throw new Error(
      'No location management context found. Is there a wrapping Provider component?'
    );
  }
  return context;
};

export const LocationManagementProvider = ({ children }: IContextProps) => {
  const [state, dispatch] = useReducer(locationManagementReducer, defaultLocationManagementState);
  return (
    <LocationManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </LocationManagementContext.Provider>
  );
};
