import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useLanguageSelectors } from 'src/app/reducers';
import {
  COMPLAINTS,
  CORRELATED_TYPES,
  INFRINGEMENTS,
  INFRINGEMENT_RULE_TYPES,
  NOISEEVENTS,
} from 'src/constants';
import { DateTime } from 'luxon';
import cx from 'classnames';
import { getDeployedProductId } from 'src/utils';
import { useFilterSelectors } from 'src/@operations/reducers';
import { ITimeRange } from 'src/@operations/props';
import { Pill } from 'src/components';
import { useAvailableOperationsTableWidth } from 'src/@operations/hooks/useAvailableOperationsTableWidth';
import { useHasRoutePermissions } from 'src/@operations/hooks/useHasRoutePermissions';

interface CorrelatedOperationData {
  complaints: null | CorrelatedEvent[];
  infringements: null | InfringementData[];
  noiseEvents: null | CorrelatedEvent[];
}

interface CorrelatedEvent {
  id: number;
  time: string;
}

type InfringementData = CorrelatedEvent & {
  infringementType: keyof typeof INFRINGEMENT_RULE_TYPES;
};

interface InfringementsByType {
  infringements: InfringementData[];
  timeRangeForInfringementType: ITimeRange | null;
}

// Max pill size is 120px
const PILL_WIDTH = 120;

export const CorrelatedPill = ({
  correlatedOperationData,
  tableId,
}: {
  correlatedOperationData: CorrelatedOperationData;
  tableId: string;
}): JSX.Element => {
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      lists: { correlatedCategories },
    },
  } = languageSelectors.getLanguage();
  const hasInfringementAccess = useHasRoutePermissions(INFRINGEMENTS);
  const hasnoiseEventAccess = useHasRoutePermissions(NOISEEVENTS);
  const hasComplaintsAccess = useHasRoutePermissions(COMPLAINTS);

  const {
    allComplaints,
    allInfringements,
    infringementRulesByType,
    allNoiseEvents,
    complaintsTimeRange,
    noiseEventsTimeRange,
    allInfringementsTimeRange,
  } = useFormatCorrelatedData({
    correlatedOperationData,
    hasAccess: {
      hasInfringementAccess,
      hasnoiseEventAccess,
      hasComplaintsAccess,
    },
  });

  const deployedProductId = getDeployedProductId();
  const availableWidth = useAvailableWidth();
  const totalPillCount =
    infringementRulesByType.length +
    (allNoiseEvents.length ? 1 : 0) +
    (allComplaints.length ? 1 : 0);
  const displaySmallPills = availableWidth < totalPillCount * PILL_WIDTH;
  const displayAllInfringementPills = !displaySmallPills;

  return (
    <div className="operation_pill-container">
      <>
        {infringementRulesByType.map((infringementsByType, index) => {
          if (displayAllInfringementPills || index === 0) {
            const { infringements } = infringementsByType;
            const { search, state, urlAppendix } = getInfringementLinkParams(
              displayAllInfringementPills,
              allInfringements,
              infringementsByType,
              allInfringementsTimeRange
            );
            return (
              <div
                key={`${tableId}_${CORRELATED_TYPES.INFRINGEMENT}_${index}`}
                className={`operation_pill`}>
                <Link
                  to={{
                    pathname: `/${deployedProductId}/${INFRINGEMENTS}/${urlAppendix}`,
                    search,
                    state,
                  }}
                  className="pill--link"
                  tabIndex={0}>
                  <Pill
                    title={
                      displaySmallPills
                        ? ''
                        : (correlatedCategories[
                            infringements[0].infringementType.toLowerCase()
                          ] as string)
                    }
                    icon="ic-infringements"
                    type={CORRELATED_TYPES.INFRINGEMENT}
                  />
                </Link>
                <span
                  className={cx('pill-excess', `pill-excess--${CORRELATED_TYPES.INFRINGEMENT}`)}>
                  {!displayAllInfringementPills
                    ? `+${allInfringements.length}`
                    : infringements.length > 1
                    ? `+${infringements.length - 1}`
                    : ''}
                </span>
              </div>
            );
          }
          return null;
        })}
      </>
      {allNoiseEvents.length ? (
        <div key={`${tableId}_${CORRELATED_TYPES.NOISEEVENT}`} className={`operation_pill`}>
          <Link
            to={{
              pathname: `/${deployedProductId}/${NOISEEVENTS}/${
                allNoiseEvents.length > 1 ? '' : `${allNoiseEvents[0].id}`
              }`,
              search: noiseEventsTimeRange
                ? `?from=${noiseEventsTimeRange.from}&to=${noiseEventsTimeRange.to}`
                : null,
              state:
                allNoiseEvents.length > 1
                  ? { fromCorrelated: true, ids: allNoiseEvents.map(item => item.id) }
                  : null,
            }}
            className="pill--link"
            tabIndex={0}>
            <Pill
              title={
                displaySmallPills
                  ? ''
                  : (correlatedCategories[CORRELATED_TYPES.NOISEEVENT] as string)
              }
              icon="ic-noise"
              type={CORRELATED_TYPES.NOISEEVENT.toLowerCase()}
            />
          </Link>
          <span
            className={cx(
              'pill-excess',
              `pill-excess--${CORRELATED_TYPES.NOISEEVENT.toLowerCase()}`
            )}>
            {allNoiseEvents.length > 1 ? `+${allNoiseEvents.length}` : ''}
          </span>
        </div>
      ) : null}
      <>
        {allComplaints.length ? (
          <div
            key={`${tableId}_${correlatedCategories[CORRELATED_TYPES.COMPLAINT] as string}`}
            className={`operation_pill`}>
            <Link
              to={{
                pathname: `/${deployedProductId}/${COMPLAINTS}/${
                  allComplaints.length > 1 ? '' : `${allComplaints[0].id}`
                }`,
                search: complaintsTimeRange
                  ? `?from=${complaintsTimeRange.from}&to=${complaintsTimeRange.to}`
                  : null,
                state:
                  allComplaints.length > 1
                    ? { fromCorrelated: true, ids: allComplaints.map(item => item.id) }
                    : null,
              }}
              className="pill--link"
              tabIndex={0}>
              <Pill
                title={
                  displaySmallPills
                    ? ''
                    : (correlatedCategories[CORRELATED_TYPES.COMPLAINT] as string)
                }
                icon="ic-complaints"
                type={CORRELATED_TYPES.COMPLAINT}
              />
            </Link>

            <span className={cx('pill-excess', `pill-excess--${CORRELATED_TYPES.COMPLAINT}`)}>
              {allComplaints.length > 1 ? `+${allComplaints.length}` : ''}
            </span>
          </div>
        ) : null}
      </>
    </div>
  );
};

const sortById = (correlatedEvents: CorrelatedEvent[] | null) =>
  correlatedEvents ? correlatedEvents.sort((a, b) => a.id - b.id) : [];

// We only need to calculate the time range if we have more than one correlated event
const getTimeRange = (correlatedEvents: CorrelatedEvent[]): ITimeRange | null => {
  const timeArray: string[] = correlatedEvents.map(({ time }) =>
    DateTime.fromISO(time, { setZone: true })
  );
  return timeArray.length > 1
    ? {
        from: DateTime.min(...timeArray).toFormat('yyyy-MM-dd'),
        to: DateTime.max(...timeArray).toFormat('yyyy-MM-dd'),
      }
    : null;
};

const useFormatCorrelatedData = ({
  correlatedOperationData = {
    infringements: [],
    noiseEvents: [],
    complaints: [],
  },
  hasAccess,
}: {
  correlatedOperationData: CorrelatedOperationData;
  hasAccess: {
    hasInfringementAccess: boolean;
    hasnoiseEventAccess: boolean;
    hasComplaintsAccess: boolean;
  };
}) =>
  useMemo(() => {
    const { infringements, noiseEvents, complaints } = correlatedOperationData;
    const { hasInfringementAccess, hasnoiseEventAccess, hasComplaintsAccess } = hasAccess;
    // Correlated infringment data
    /* We want to display the infringement rules grouped by type e.g Gate+2 CCO+3 so */
    const infringementRulesByType =
      infringements && hasInfringementAccess
        ? (Object.values(INFRINGEMENT_RULE_TYPES).flatMap(rule => {
            const correlatedInfringementsByRuleType = sortById(
              infringements.filter(({ infringementType }) => infringementType === rule)
            );
            const timeRange = getTimeRange(correlatedInfringementsByRuleType);
            return correlatedInfringementsByRuleType.length
              ? {
                  infringements: correlatedInfringementsByRuleType,
                  timeRangeForInfringementType: timeRange,
                }
              : [];
          }) as InfringementsByType[])
        : [];

    const allInfringements =
      infringements && hasInfringementAccess ? infringements.sort((a, b) => a.id - b.id) : [];
    const allInfringementsTimeRange = getTimeRange(allInfringements);

    // Correlated Noise event data
    const allNoiseEvents = sortById(hasnoiseEventAccess ? noiseEvents : []);
    const noiseEventsTimeRange = getTimeRange(allNoiseEvents);

    // Correlated complaint data
    const allComplaints = sortById(hasComplaintsAccess ? complaints : []);
    const complaintsTimeRange = getTimeRange(allComplaints);
    return {
      allComplaints,
      allInfringements,
      infringementRulesByType,
      allNoiseEvents,
      complaintsTimeRange,
      noiseEventsTimeRange,
      allInfringementsTimeRange,
    };
  }, [correlatedOperationData]);

// All the displayed columns combined excluding correlated
const OPERATIONS_TABLE_COLUMNS_LENGTH = 768;

export const useAvailableWidth = () => {
  const availableTableWidth = useAvailableOperationsTableWidth();
  const filterSelectors = useFilterSelectors();
  const usingPca = filterSelectors.getIfUsingPcaFilter();
  const pcaWidth = usingPca ? 170 : 0;

  return availableTableWidth - OPERATIONS_TABLE_COLUMNS_LENGTH - pcaWidth;
};

const getInfringementLinkParams = (
  displayAllInfringementPills: boolean,
  allInfringements: InfringementData[],
  infringementsByType: InfringementsByType,
  allInfringementsTimeRange: ITimeRange
): {
  search: string;
  state: { fromCorrelated: true; ids: number[] };
  urlAppendix: string;
} => {
  // if we are displaying infringement all condensed - then link search params will need to encompass all infringements
  if (!displayAllInfringementPills && allInfringementsTimeRange) {
    return {
      search: `?from=${allInfringementsTimeRange.from}&to=${allInfringementsTimeRange.to}`,
      state: { fromCorrelated: true, ids: allInfringements.map(item => item.id) },
      urlAppendix: '',
    };
  } else {
    const { infringements, timeRangeForInfringementType } = infringementsByType;
    // if we have multiple of the same rule type - link search params encompass all infringements of that type
    if (infringementsByType.infringements.length > 1) {
      return {
        search: `?from=${timeRangeForInfringementType.from}&to=${timeRangeForInfringementType.to}`,
        state: { fromCorrelated: true, ids: infringements.map(item => item.id) },
        urlAppendix: '',
      };
    }
    // if it's just one rule we only need to append the id to the url no search/state params
    return {
      search: null,
      state: null,
      urlAppendix: String(infringements[0].id),
    };
  }
};
