import React, { FC, Dispatch, SetStateAction } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Spinner } from '@ems/client-design-system';
import styled from 'styled-components/macro';
import { TRACK_DENSITY_CANCEL } from 'src/@operations/resolvers/trackDensityQueries';

const TrackDensityLoadingScreenContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(180, 180, 180, 0.5);
`;

const TrackDensityDialog = styled.div`
  text-align: center;
`;

const TrackDensitySpinner = styled.div`
  padding: 1rem 0;
`;

interface ITrackDensityLoadingScreen {
  id: string;
  setIsGeneratingTrackDensity: Dispatch<SetStateAction<boolean>>;
}

export const TrackDensityLoadingScreen: FC<ITrackDensityLoadingScreen> = ({
  id,
  setIsGeneratingTrackDensity,
}) => {
  const [cancelTrackDensityMutation] = useMutation(TRACK_DENSITY_CANCEL, {
    update() {},
  });

  const cancelTrackDensityGeneration = evt => {
    cancelTrackDensityMutation({
      variables: { id },
    })
      .then(({ data }) => {
        if (data.cancelTrackDensity) {
          setIsGeneratingTrackDensity(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <TrackDensityLoadingScreenContainer>
      <TrackDensityDialog>
        <h1>Generating track density, this might take a moment.</h1>
        <TrackDensitySpinner>
          <Spinner loading size="xl" centered />
        </TrackDensitySpinner>
        <Button size="l" style="subtle" onClick={cancelTrackDensityGeneration}>
          Cancel
        </Button>
      </TrackDensityDialog>
    </TrackDensityLoadingScreenContainer>
  );
};
