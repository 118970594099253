import React from 'react';
import cx from 'classnames';
import { MapPopup } from '@ems/client-design-system';

import './styles.scss';
import { linearInterpolateValue } from 'src/utils';
import { UNIT_DECIBEL } from 'src/constants';
import { useLanguageSelectors } from 'src/app/reducers';
import { IMonitor, INoiseData, IRuleData } from 'src/@infringements/interfaces';

export interface NoisePopupProps {
  zoomLevel: number;
  monitorData: IMonitor;
  ruleData?: IRuleData;
  noiseData?: INoiseData[];
  metricString?: string;
  isPlaybackMode?: boolean;
}

export const NoisePopup = ({
  zoomLevel,
  monitorData,
  ruleData,
  noiseData,
  metricString,
  isPlaybackMode = false,
}: NoisePopupProps) => {
  if (!monitorData || !monitorData.properties.valid) {
    return null;
  }
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      noiseEvents: {
        fields: { leq, lmax, sel, epnl },
      },
    },
  } = languageSelectors.getLanguage();

  // 8px base offset + scaled based on the radius of the noise monitor circle
  const zoomScale = linearInterpolateValue(0.5, 2.2, 10, zoomLevel - 6);
  const circleRadius = linearInterpolateValue(4, 16, 75, monitorData.properties.noiseLevel - 45);
  const offsetLeft = zoomScale * (4 + circleRadius);

  let decibalMetricString: string | null = metricString || null;
  let decibalValueString = `${monitorData.properties.dbString} ${UNIT_DECIBEL}`;

  // If metric type is passed and its value is found
  if (noiseData && ruleData && !isPlaybackMode) {
    const activeNoiseData = noiseData.find(
      monitor => Number(monitor.locationId) === monitorData.properties.id
    );

    switch (ruleData.metric) {
      case 'LMAX':
        if (activeNoiseData.maxLevel) {
          decibalMetricString = lmax;
          decibalValueString = `${activeNoiseData.maxLevel.toFixed(1)} ${UNIT_DECIBEL}`;
        }
        break;
      case 'LEQ':
        if (activeNoiseData.leq) {
          decibalMetricString = leq;
          decibalValueString = `${activeNoiseData.leq.toFixed(1)} ${UNIT_DECIBEL}`;
        }
        break;
      case 'SEL':
        if (activeNoiseData.sel) {
          decibalMetricString = sel;
          decibalValueString = `${activeNoiseData.sel.toFixed(1)} ${UNIT_DECIBEL}`;
        }
        break;
      case 'EPNL':
        if (activeNoiseData.epnl) {
          decibalMetricString = epnl;
          decibalValueString = `${activeNoiseData.sel.toFixed(1)} ${UNIT_DECIBEL}`;
        }
        break;
    }
  }

  return (
    <MapPopup
      className={cx('noise-popup', {
        'noise-popup--large': zoomLevel >= 13.5,
        'noise-popup--small': zoomLevel <= 8.5,
      })}
      latitude={monitorData.geometry.coordinates[1]}
      longitude={monitorData.geometry.coordinates[0]}
      closeButton={false}
      closeOnClick={false}
      offsetLeft={offsetLeft}
      anchor="left">
      <>
        {decibalMetricString && !isPlaybackMode && (
          <div className="noise-popup_metric">{decibalMetricString}</div>
        )}

        <div className="noise-popup_db">{decibalValueString}</div>
      </>
    </MapPopup>
  );
};
