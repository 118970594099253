import html2canvas from 'html2canvas';

// Not a recommended approach to target html elements in a react app; useRef will be used.
// An uplift card needed in the near future
export const convertHtmlElementToImage = ({
  targetId,
  imageType = 'png',
  scale = 1, // A Number between 0 and 1
}: {
  targetId: string;
  imageType?: 'jpeg' | 'png';
  scale?: number;
}) => {
  return new Promise<string>((resolve, reject) => {
    const selectedHtmlElement: HTMLElement | null = document.getElementById(targetId);
    if (selectedHtmlElement) {
      html2canvas(selectedHtmlElement, {
        scale,
      })
        .then(canvas => {
          // return a data URIs fromated string in full quality
          resolve(canvas.toDataURL(`image/${imageType}`, 1.0));
        })
        .catch(error => {
          reject(error);
        });
    } else {
      reject('Error: Failed to find html target element');
    }
  });
};

export const getBase64Image = (img: HTMLImageElement) => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx: any = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = canvas.toDataURL('image/png');
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
};

export const imageToBase64 = (base64: string) => {
  return new Promise<string>((resolve, reject) => {
    const image = new Image();
    image.src = base64;
    image.onload = () => {
      resolve(getBase64Image(image));
    };
  });
};

export const takeScreenshotFromMap = ({
  captureRef,
  mapApis,
}: {
  captureRef: any;
  mapApis: any;
}) => {
  return new Promise<string>(resolve => {
    mapApis.once('render', () => {
      convertHtmlToImage({
        captureRef,
      }).then(dataUri => {
        imageToBase64(dataUri).then(trackmapImage => {
          resolve(trackmapImage);
        });
      });
    });
    // trigger map render
    mapApis.setBearing(mapApis.getBearing());
  });
};

export const convertHtmlToImage = ({
  captureRef,
  imageType = 'png',
  scale = 1, // A Number between 0 and 1
}: {
  captureRef: any;
  imageType?: 'jpeg' | 'png';
  scale?: number;
}) => {
  return new Promise<string>((resolve, reject) => {
    html2canvas(captureRef, {
      scale,
    })
      .then(canvas => {
        // return a data URIs fromated string in full quality
        resolve(canvas.toDataURL(`image/${imageType}`, 1.0));
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const takeScreenshotFromGraph = ({ captureRef }: { captureRef: any }) => {
  return new Promise<string>(resolve => {
    convertHtmlToImage({
      captureRef,
    }).then(dataUri => {
      imageToBase64(dataUri).then(trackmapImage => {
        resolve(trackmapImage);
      });
    });
  });
};
