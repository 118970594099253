import { setFeatureStateForMap } from 'src/utils/mapHelpers/mapHelpers';
// constants
import { INFRINGEMENT_RULE_TYPES } from 'src/constants';

// manage selecting infringement rule types (Exclusion, Gate & Curfew) on map
export const manageRuleTypeSelection = (
  ruleType: string,
  operationId: number, // related operation
  selectedTypeId: number, // new one to add
  selectionIds: string[], // already selected
  sourceIdentifier: string,
  mapApis: any,
  state: any
) => {
  if (selectedTypeId) {
    const isIncluded = selectionIds.includes(String(selectedTypeId));
    if (selectionIds.length && isIncluded) {
      if (!state[selectedTypeId].includes(operationId)) {
        // all operation IDs correlated to a corridor
        state[selectedTypeId].push(operationId);
      }
    } else {
      // not added yet
      state[selectedTypeId] = [operationId];
    }
    if (!isIncluded) {
      setFeatureStateForMap(
        selectedTypeId, // id
        mapApis,
        sourceIdentifier,
        false // removeFeature
      );
      if (ruleType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT) {
        setFeatureStateForMap(
          selectedTypeId, // id
          mapApis,
          `${sourceIdentifier}-sybmols`,
          false // removeFeature
        );
      }
    }
  }
  return state;
};

export const addExtraGates = (
  extraGateIds: number[], // new one to add
  sourceIdentifier: string,
  mapApis: any,
  state: any
) => {
  const { gateIds } = state;

  if (extraGateIds.length) {
    extraGateIds.forEach(gateId => {
      if (!gateIds.includes(gateId)) {
        setFeatureStateForMap(
          gateId, // id
          mapApis,
          sourceIdentifier,
          false, // removeFeature,
          'visible'
        );
        setFeatureStateForMap(
          gateId, // id
          mapApis,
          `${sourceIdentifier}-sybmols`,
          false, // removeFeature
          'visible'
        );
      }
    });
  }

  return extraGateIds;
};

export const removeExtraGates = (
  extraGatesToRemove: number[], // new one to add
  sourceIdentifier: string,
  mapApis: any
) => {
  extraGatesToRemove.forEach(gateId => {
    setFeatureStateForMap(
      gateId, // id
      mapApis,
      sourceIdentifier,
      true, // removeFeature,
      'visible'
    );
    setFeatureStateForMap(
      gateId, // id
      mapApis,
      `${sourceIdentifier}-sybmols`,
      true, // removeFeature
      'visible'
    );
  });
};

export const manageRuleTypeRemoval = (
  ruleType: string,
  operationId: number, // related operation
  selectionIds: string[], // already selected
  sourceIdentifier: string,
  mapApis: any,
  state: any
) => {
  selectionIds.forEach(id => {
    const relatedToInfringements = state[id];
    const index = relatedToInfringements.indexOf(operationId);
    if (index >= 0) {
      relatedToInfringements.splice(index, 1);
    }
    if (relatedToInfringements.length === 0) {
      setFeatureStateForMap(
        id,
        mapApis,
        sourceIdentifier,
        true // removeFeature
      );
      if (ruleType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT) {
        setFeatureStateForMap(
          id, // id
          mapApis,
          `${sourceIdentifier}-sybmols`,
          true // removeFeature
        );
      }
      delete state[id];
    } else {
      state[id] = relatedToInfringements;
    }
  });
  return state;
};

// removing all selected infringement rule types (Exclusion, Gate & Curfew) from map
export const clearRuleTypeSelection = (
  ruleType: string,
  selectionIds: string[], // already selected
  sourceIdentifier: string,
  mapApis: any,
  state: any
) => {
  if (selectionIds.length) {
    selectionIds.forEach(id => {
      setFeatureStateForMap(
        id,
        mapApis,
        sourceIdentifier,
        true // removeFeature
      );
      if (ruleType === INFRINGEMENT_RULE_TYPES.GATE_INFRINGEMENT) {
        setFeatureStateForMap(
          id, // id
          mapApis,
          `${sourceIdentifier}-sybmols`,
          true // removeFeature
        );
      }
      delete state[id];
    });
  }
  return state;
};
