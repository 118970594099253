import {
  CEILING_MAX_FEET,
  DROPDOWN_INPUT,
  FEATURE_TYPES,
  FLOOR_MIN_FEET,
  IMPERIAL_BASE_UNIT,
  INPUT_RETURN_TYPES,
  PERMISSIONS,
  TEXT_INPUT,
  UNIT_FOOT,
} from 'src/constants';
import { IDropdownItem } from '@ems/client-design-system';

import {
  ISpatialFeatureCardField,
  ISpatialFeatureForm,
  ISpatialFeaturePermissions,
  ISpatialFeatureValidationParams,
} from '../interfaces';
import { getImperialOrMetricBaseUnit, getVerticalDistance, setUnitAsMetersOrFeet } from 'src/utils';

import { Polygon, Vector3 } from 'common-logic';
import {
  TSpatialFeature,
  IGate,
  ISelectionZone,
  TCorridorGeometry,
  IGateGeometry,
  TSelectionZoneGeometry,
  TSpatialFeatureGeometry,
  INoiseAbatementCorridor,
} from 'src/utils/spatialFeatureHelpers/interfaces';
import { IRolesSelectors } from 'src/app/props';
import { useMutation } from '@apollo/react-hooks';
import {
  ADD_CORRIDOR,
  ADD_GATE,
  ADD_ZONE,
  UPDATE_CORRIDOR,
  UPDATE_GATE,
  UPDATE_ZONE,
} from '../mutations';
import { TMutationActions } from 'src/utils/interfaces';

export const formatStringToDropdown = (stringArray: string[]): IDropdownItem[] => {
  const returnedItems = stringArray.map((item: string) => ({
    key: item === 'Zone' ? FEATURE_TYPES.SELECTION_ZONE : item,
    label: item,
  }));
  return returnedItems;
};

// Back end data and data references to zones are selection zone - however front end views must be zones
export const formatDisplayFeatureType = (featureType: string): string =>
  featureType === 'SelectionZone' ? 'Zone' : featureType;

// Creating spatial feature form for edit/add
export const formatFormData = (
  featureType: string,
  featureDetails: TSpatialFeature,
  isEdit: boolean,
  distanceUnits: 'm' | 'ft' | 'km'
): ISpatialFeatureForm => {
  // Initializes and sets default values -name and type
  // if editing these fields are set to read only
  const featureFormData: ISpatialFeatureForm = {
    name: {
      label: 'Name',
      value: featureDetails.name,
      readOnly: isEdit,
      required: true,
      inputType: TEXT_INPUT,
      validations: {
        inputMaxLength: 50,
      },
      className: 'spatial-feature__form-field',
    },
    type: {
      label: 'Type',
      value: featureType,
      selectedItem: {
        key: formatDisplayFeatureType(featureType),
        label: formatDisplayFeatureType(featureType),
      },
      readOnly: isEdit,
      inputType: DROPDOWN_INPUT,
      className: 'spatial-feature__form-field',
      searchItems: formatStringToDropdown(['Gate', 'Corridor', 'Zone']),
    },
  };

  const numericDistanceUnits = {
    convertTo: getImperialOrMetricBaseUnit(distanceUnits),
    convertFrom: UNIT_FOOT as 'ft',
    returnValueType: INPUT_RETURN_TYPES.NUMBER,
  };
  // Creates Form Fields- Specific for gate
  const createGateFields = (fieldData: IGate, featureFormData: ISpatialFeatureForm): void => {
    featureFormData.floorAltitude = {
      label: 'Floor',
      value: getVerticalDistance(fieldData.geometry.floorAltitude, numericDistanceUnits) as string,
      inputType: TEXT_INPUT,
      required: true,
      sideLabelEditMode: setUnitAsMetersOrFeet(distanceUnits),
      validations: {
        isNumber: true,
        decimalPlaces: 0,
      },
      readOnly: false,
      className: 'spatial-feature__form-field',
    };
    featureFormData.ceilingAltitude = {
      label: 'Ceiling',
      value: getVerticalDistance(
        fieldData.geometry.ceilingAltitude,
        numericDistanceUnits
      ) as string,
      inputType: TEXT_INPUT,
      readOnly: false,
      required: true,
      sideLabelEditMode: distanceUnits,
      validations: {
        isNumber: true,
        decimalPlaces: 0,
      },
      className: 'spatial-feature__form-field',
    };
  };

  //  Creates Form Fields- Specific for zones
  const createSelectionZoneFields = (
    fieldData: ISelectionZone,
    featureFormData: ISpatialFeatureForm
  ): void => {
    featureFormData.floorAltitude = {
      label: 'Floor',
      value: getVerticalDistance(fieldData.floorAltitude, numericDistanceUnits) as string,
      inputType: TEXT_INPUT,
      readOnly: false,
      required: true,
      sideLabelEditMode: distanceUnits,
      validations: {
        isNumber: true,
        decimalPlaces: 0,
      },
      className: 'spatial-feature__form-field',
    };
    featureFormData.ceilingAltitude = {
      label: 'Ceiling',
      value: getVerticalDistance(fieldData.ceilingAltitude, numericDistanceUnits) as string,
      inputType: TEXT_INPUT,
      readOnly: false,
      sideLabelEditMode: distanceUnits,
      validations: {
        isNumber: true,
        decimalPlaces: 0,
      },
      required: true,
      className: 'spatial-feature__form-field',
    };
  };

  // Formats the feature specific fields
  switch (featureType) {
    case 'Gate':
      createGateFields(featureDetails as IGate, featureFormData);
      break;
    case 'SelectionZone':
      createSelectionZoneFields(featureDetails as ISelectionZone, featureFormData);
      break;
  }
  return featureFormData;
};

// FORMATTING DATA FROM API INTO CARD - Viewing Feature
export const useFormatCardData = (
  featureType: string,
  featureDetails: TSpatialFeature,
  distanceUnits: 'm' | 'ft' | 'km'
): ISpatialFeatureCardField[] => {
  const featureCardData: ISpatialFeatureCardField[] = [
    { label: 'Name', text: featureDetails.name },
    { label: 'Type', text: formatDisplayFeatureType(featureType) },
  ];

  const stringDistanceUnits = {
    convertTo: setUnitAsMetersOrFeet(distanceUnits),
    convertFrom: UNIT_FOOT as 'ft',
    returnValueType: INPUT_RETURN_TYPES.STRING,
  };

  const formatGateData = (featureDetails: IGate, cardData: ISpatialFeatureCardField[]) => {
    cardData.push({
      label: 'Floor',
      text: getVerticalDistance(
        featureDetails.geometry.floorAltitude,
        stringDistanceUnits
      ) as string,
    });
    cardData.push({
      label: 'Ceiling',
      text: getVerticalDistance(
        featureDetails.geometry.ceilingAltitude,
        stringDistanceUnits
      ) as string,
    });
  };

  const formatSelectionZoneData = (
    featureDetails: ISelectionZone,
    cardData: ISpatialFeatureCardField[]
  ) => {
    cardData.push({
      label: 'Floor',
      text: getVerticalDistance(featureDetails.floorAltitude, stringDistanceUnits) as string,
    });
    cardData.push({
      label: 'Ceiling',
      text: getVerticalDistance(featureDetails.ceilingAltitude, stringDistanceUnits) as string,
    });
  };

  switch (featureType) {
    case 'Gate':
      formatGateData(featureDetails as IGate, featureCardData);
      break;
    case 'SelectionZone':
      formatSelectionZoneData(featureDetails as ISelectionZone, featureCardData);
      break;
    case 'Corridor':
      // formatCorridorData(featureDetails as INoiseAbatementCorridor, featureCardData);
      break;
  }

  return featureCardData;
};

export const getNewSpatialFeatureForm = (): ISpatialFeatureForm => {
  const featureFormData: ISpatialFeatureForm = {
    name: {
      label: 'Name',
      value: '',
      readOnly: false,
      required: true,
      inputType: TEXT_INPUT,
      validations: {
        inputMaxLength: 50,
      },
      className: 'spatial-feature__form-field',
    },
    type: {
      label: 'Type',
      value: FEATURE_TYPES.GATE,
      required: true,
      selectedItem: null,
      readOnly: false,
      inputType: DROPDOWN_INPUT,
      className: 'spatial-feature__form-field',
      searchItems: formatStringToDropdown(['Gate', 'Corridor', 'Zone']),
    },
  };
  return featureFormData;
};
// <------------------------ Geometry/Feature Validation --------------------->
// Validates form data based on business logic
export const validateFeature = (
  formData: ISpatialFeatureForm,
  params: ISpatialFeatureValidationParams
): ISpatialFeatureForm => {
  // reset existing validation errors - because conditions all get rechecked
  const keys: string[] = Object.keys(formData);
  keys.forEach((key: string) => {
    formData[key].error = null;
  });

  const numericFeet = {
    convertTo: IMPERIAL_BASE_UNIT as 'imperialBaseUnit',
    convertFrom: setUnitAsMetersOrFeet(params.units),
    returnValueType: INPUT_RETURN_TYPES.NUMBER as 'number',
  };

  const stringDistanceUnits = {
    convertTo: setUnitAsMetersOrFeet(params.units),
    convertFrom: setUnitAsMetersOrFeet(params.units),
    returnValueType: INPUT_RETURN_TYPES.STRING as 'string',
  };

  if (formData.name) {
    const getFeatures = (featureType: string) => {
      switch (featureType) {
        case 'Zone':
        case 'SelectionZone':
          return params.selector.getAllZones();
        case 'Corridor':
        case 'NoiseAbatementCorridor':
          return params.selector.getAllCorridors();
        case 'Gate':
        default:
          return params.selector.getAllGates();
      }
    };

    const features = getFeatures(formData.type.value);

    // Removes trailing whitespace and sets to lowercase
    const formatNameForComparison = (value: string) => value.toLowerCase().trim();
    if (
      features.some(
        feature =>
          formatNameForComparison(feature.name) === formatNameForComparison(formData.name.value) &&
          formData.name.value !== params.originalFeatureName
      )
    ) {
      formData.name.error = `${formatDisplayFeatureType(formData.type.value)} name must be unique`;
    }
  }
  if (formData.floorAltitude && formData.ceilingAltitude) {
    // the ceiling must be  greater than the floor
    if (
      getVerticalDistance(formData.ceilingAltitude.value, numericFeet) <=
      getVerticalDistance(formData.floorAltitude.value, numericFeet)
    ) {
      formData.ceilingAltitude.error = `Ceiling must be greater than floor`;
    }

    // the floor must be greater than -1,226ft AMSL
    if (getVerticalDistance(formData.floorAltitude.value, numericFeet) <= FLOOR_MIN_FEET) {
      formData.floorAltitude.error = `Floor must be greater than ${getVerticalDistance(
        FLOOR_MIN_FEET,
        stringDistanceUnits
      )}`;
    }
    // the ceiling is less than 70,000ft AMSL
    if (getVerticalDistance(formData.ceilingAltitude.value, numericFeet) >= CEILING_MAX_FEET) {
      formData.ceilingAltitude.error = `Ceiling must be less than ${getVerticalDistance(
        CEILING_MAX_FEET,
        stringDistanceUnits
      )}
      `;
    }
  }
  return formData;
};

const validateCorridorGeometry = (corridorGeometry: TCorridorGeometry): boolean =>
  !corridorGeometry.some((gate: IGateGeometry, index: number) => {
    // set up a polygon from and validate polygon

    if (index < corridorGeometry.length - 1) {
      const newPoints = [
        gate.leftPosition,
        gate.rightPosition,
        corridorGeometry[index + 1].rightPosition,
        corridorGeometry[index + 1].leftPosition,
      ];

      const polygon = new Polygon();
      polygon.points = newPoints.map(point => new Vector3(point.longitude, point.latitude, 0));
      // if polygon does self intersect then it's invalid
      return polygon.doesSelfIntersect();
    }
  });

const validateGateGeometry = (gateGeometry: IGateGeometry): boolean =>
  // Left position cant be the same as right position
  !(
    gateGeometry.leftPosition.longitude === gateGeometry.rightPosition.longitude &&
    gateGeometry.leftPosition.latitude === gateGeometry.rightPosition.latitude
  );

const validateZoneGeometry = (zoneGeometry: TSelectionZoneGeometry): boolean => {
  const polygon = new Polygon();
  polygon.points = zoneGeometry.map(
    point => new Vector3(point.longitude, point.latitude, point.altitude)
  );

  // if polygon does self intersect then it's invalid
  return !polygon.doesSelfIntersect();
};

export const setZonePointsToClockwise = (
  zoneGeometry: TSelectionZoneGeometry
): TSelectionZoneGeometry => {
  const polygon = new Polygon();
  polygon.points = zoneGeometry.map(
    point => new Vector3(point.longitude, point.latitude, point.altitude)
  );

  if (!polygon.isClockwise()) {
    const poly2 = polygon.reverseWindingDirection();
    return poly2.points.map(vectorPoint => ({
      longitude: vectorPoint.x,
      latitude: vectorPoint.y,
      altitude: vectorPoint.z,
    }));
  } else {
    return zoneGeometry;
  }
};

export const validateGeometry = (
  geometryData: TSpatialFeatureGeometry,
  featureType: string
): boolean => {
  switch (featureType) {
    case 'Gate':
      return validateGateGeometry(geometryData as IGateGeometry);
    case 'Zone':
    case 'SelectionZone':
      return validateZoneGeometry(geometryData as TSelectionZoneGeometry);
    case 'Corridor':
      return validateCorridorGeometry(geometryData as TCorridorGeometry);
  }
};

export const getSpatialFeaturesPermissions = (
  rolesSelector: IRolesSelectors
): ISpatialFeaturePermissions =>
  Object.values(FEATURE_TYPES).reduce(
    (acc, featureType) => ({
      ...acc,
      [featureType]: {
        [PERMISSIONS.UPDATE]: rolesSelector.hasPermission(`${featureType}.${PERMISSIONS.UPDATE}`),
        [PERMISSIONS.READ]: rolesSelector.hasPermission(`${featureType}.${PERMISSIONS.READ}`),
        [PERMISSIONS.INSERT]: rolesSelector.hasPermission(`${featureType}.${PERMISSIONS.INSERT}`),
        [PERMISSIONS.DELETE]: rolesSelector.hasPermission(`${featureType}.${PERMISSIONS.DELETE}`),
      },
    }),
    {}
  ) as ISpatialFeaturePermissions;

// Updating spatial features

const handleGateMutation = (
  gateDetails: IGate,
  mutationFunctions,
  mutationAction: TMutationActions
) => {
  mutationFunctions[FEATURE_TYPES.GATE]
    [mutationAction]({
      variables: {
        gate: {
          id: gateDetails.id,
          groupName: gateDetails.groupName,
          isActive: gateDetails.isActive,
          name: gateDetails.name,
          geometry: {
            ...gateDetails.geometry,
            floorAltitude: Number(gateDetails.geometry.floorAltitude.toFixed(0)),
            ceilingAltitude: Number(gateDetails.geometry.ceilingAltitude.toFixed(0)),
          },
        },
      },
    })
    .then(({ data }) => {
      const mutatedGate = data[`${mutationAction.toLowerCase()}Gate`];
      mutationFunctions.onSuccess ? mutationFunctions.onSuccess(mutatedGate, mutationAction) : null;
    })
    .catch(error => {
      mutationFunctions.onError ? mutationFunctions.onError(error) : null;
    });
};

const handleZoneMutation = (
  selectionZone: ISelectionZone,
  mutationFunctions,
  mutationAction: TMutationActions
) => {
  mutationFunctions[FEATURE_TYPES.SELECTION_ZONE]
    [mutationAction]({
      variables: {
        zone: {
          name: selectionZone.name,
          id: selectionZone.id,
          groupName: selectionZone.groupName,
          isActive: selectionZone.isActive,
          ceilingAltitude: selectionZone.ceilingAltitude,
          floorAltitude: selectionZone.floorAltitude,
          points: setZonePointsToClockwise(selectionZone.points),
        },
      },
    })
    .then(({ data }) => {
      const mutatedZone = data[`${mutationAction.toLowerCase()}PolygonSelectionZone`];
      mutationFunctions.onSuccess ? mutationFunctions.onSuccess(mutatedZone, mutationAction) : null;
    })
    .catch(error => {
      mutationFunctions.onError ? mutationFunctions.onError(error) : null;
    });
};

const handleCorridorMutation = (
  inputCorridor: INoiseAbatementCorridor,
  mutationFunctions,
  mutationAction: TMutationActions
) => {
  mutationFunctions[FEATURE_TYPES.CORRIDOR]
    [mutationAction]({
      variables: {
        corridor: {
          id: inputCorridor.id,
          name: inputCorridor.name,
          groupName: inputCorridor.groupName,
          isActive: inputCorridor.isActive,
          geometry: inputCorridor.geometry,
        },
      },
    })
    .then(({ data }) => {
      const mutatedCorridor = data[`${mutationAction.toLowerCase()}Corridor`];
      mutationFunctions.onSuccess
        ? mutationFunctions.onSuccess(mutatedCorridor, mutationAction)
        : null;
    })
    .catch(error => {
      mutationFunctions.onError ? mutationFunctions.onError(error) : null;
    });
};

export const mutateSpatialFeature = (
  featureType: string,
  updatedFeatureData: TSpatialFeature,
  mutationFunctions,
  mutationAction: TMutationActions
) => {
  switch (featureType) {
    case FEATURE_TYPES.GATE:
      handleGateMutation(updatedFeatureData as IGate, mutationFunctions, mutationAction);
      break;
    case FEATURE_TYPES.CORRIDOR:
      handleCorridorMutation(
        updatedFeatureData as INoiseAbatementCorridor,
        mutationFunctions,
        mutationAction
      );
      break;
    case FEATURE_TYPES.SELECTION_ZONE:
      handleZoneMutation(updatedFeatureData as ISelectionZone, mutationFunctions, mutationAction);
      break;
  }
};

export const useSpatialFeatureMutations = (onSuccess?, onError?) => {
  // MUTATIONS
  const [updateGate] = useMutation(UPDATE_GATE, {});

  const [updateSelectionZone] = useMutation(UPDATE_ZONE, {});
  const [updateCorridor] = useMutation(UPDATE_CORRIDOR, {});

  const [addGate] = useMutation(ADD_GATE, {});

  const [addCorridor] = useMutation(ADD_CORRIDOR, {});

  const [addZone] = useMutation(ADD_ZONE, {});
  const spatialFeatureMutationObject = {
    [FEATURE_TYPES.GATE]: { update: updateGate, add: addGate },
    [FEATURE_TYPES.CORRIDOR]: { update: updateCorridor, add: addCorridor },
    [FEATURE_TYPES.SELECTION_ZONE]: { update: updateSelectionZone, add: addZone },
    onSuccess,
    onError,
  };

  return spatialFeatureMutationObject;
};
