import { useRolesSelectors } from 'src/app/reducers';
import { NOISE_DEVICE, PERMISSIONS } from 'src/constants';

export const useMonitorLocationPermissions = () => {
  const roleSelector = useRolesSelectors();
  return {
    [PERMISSIONS.UPDATE]: roleSelector.hasPermission(`${NOISE_DEVICE}.${PERMISSIONS.UPDATE}`),
    [PERMISSIONS.READ]: roleSelector.hasPermission(`${NOISE_DEVICE}.${PERMISSIONS.READ}`),
  };
};
