import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';

// Container
import { GeneralSettings } from 'src/@settings/containers';
import { SettingsContentWrapper } from 'src/@settings/components';

// Reducers
import { useLanguageSelectors } from 'src/app/reducers';

const Screen = () => {
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    screens: {
      settings: {
        tabs: { general: generalString },
      },
    },
  } = languageSelectors.getLanguage();
  return (
    <SettingsLayout>
      <SettingsContentWrapper title={generalString}>
        <GeneralSettings />
      </SettingsContentWrapper>
    </SettingsLayout>
  );
};

export const GeneralSettingsScreen = withRouteProps(Screen);
