interface IDateSeries {
  today: string;
  yesterday: string;
  past7Days: string;
  past14Days: string;
  lastMonth: string;
}

interface IDateRangeList {
  dateRange: Date[];
  key: string;
  label: string;
}

export const getDefaultFilterDates = (
  dateRangeList: IDateSeries,
  predefinedDateRangeList: (dateRangeList: IDateSeries) => IDateRangeList[]
) => {
  const dates = predefinedDateRangeList(dateRangeList);
  const yesterdayDate = dates.find(date => date.key === 'yesterday');
  const defaultFilterDateFrom = yesterdayDate.dateRange[0];
  const defaultFilterDateTo = yesterdayDate.dateRange[1];
  return { defaultFilterDateFrom, defaultFilterDateTo };
};
