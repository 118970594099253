import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { airlineLoadFactorsQuery, airlineLoadFactorsSchema } from './schema';

export const fetchLoadFactors = (client: ApolloClient<object>, scenarioIds: number[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `scenarioIds: ${scenarioIds} `;
    const GET_AIRLINE_LOAD_FACTORS = gql`
    query {
      ${airlineLoadFactorsQuery} (${queryParams}) ${airlineLoadFactorsSchema}
    }`;
    client
      .query({
        query: GET_AIRLINE_LOAD_FACTORS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[airlineLoadFactorsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
