/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useState, useEffect, useContext } from 'react';
// context
import { ComplaintsDispatchContext } from 'src/@complaints/providers/ComplaintsStateProvider';
// actions
import { updateComplaint, selectAddress } from 'src/@complaints/actions';
// selectors
import { useDataSelectors } from 'src/@complaints/reducers';
import { useConfigSelectors } from 'src/app/reducers';
// resolvers
import {
  fetchComplaintById,
  fetchComplainerById,
  fetchSingleComplaint,
} from 'src/@complaints/resolvers';
import { fetchOperationDetails } from 'src/@infringements/resolvers/summaryResolver';
// Apollo client
import { useApolloClient } from '@apollo/react-hooks';
// functions
import { formatComplaint, formatComplainer } from 'src/@complaints/functions';
import { getPaginationInformation } from 'src/app/functions/itemsNavigation';
import { history, getDeployedProductId } from 'src/utils';
// containers
import { ComplaintsFormContainer } from 'src/@complaints/containers';
import { MapContainer } from 'src/@complaints/containers/viewComplaint/MapContainer';
import { IComplaintDetailsContainer, ISelectFlightComplaint } from 'src/@complaints/interfaces';

export const ComplaintDetailsContainer: FC<IComplaintDetailsContainer> = ({
  id,
  paginationInfo,
}) => {
  const [complaintData, setComplaintData] = useState<ISelectFlightComplaint | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [trackData, updateTrackData]: any = useState([]);

  // configuration
  const dispatcher = useContext(ComplaintsDispatchContext);
  const dataSelectors = useDataSelectors();
  const client = useApolloClient();

  const complaintDetails = dataSelectors.getComplaint();

  const paging = getPaginationInformation(Number(id), paginationInfo);
  const { operationId } = complaintDetails;

  useEffect(() => {
    fetchComplaintById(client, id)
      .then((res: any) => {
        const complaintData = formatComplaint(res.data);
        fetchComplainerById(client, res.data.complainerId)
          .then((res: any) => {
            const complainerData = formatComplainer(res.data);
            updateComplaint({ ...complaintData, ...complainerData }, dispatcher);
            selectAddress(complainerData.address, dispatcher);
          })
          .catch(error => {
            history.replace(`/${getDeployedProductId()}/404`);
          });
      })
      .catch(error => {
        history.replace(`/${getDeployedProductId()}/404`);
      });
  }, []);

  // First get the complaints data if it exists, otherwise redirect to 404
  useEffect(() => {
    if (id) {
      fetchSingleComplaint({ client, id })
        .then((data: ISelectFlightComplaint) => setComplaintData(data))
        .catch(error => {
          history.replace(`/${getDeployedProductId()}/404`);
        });
    } else {
      history.replace(`/${getDeployedProductId()}/404`);
    }
  }, [id, operationId]);

  useEffect(() => {
    if (complaintData) {
      // If we already have an operationId value, we should pre-select it
      const { operationId } = complaintData;
      if (operationId) {
        setSelectedId(operationId);
        fetchOperationDetails(client, operationId)
          .then(({ data }: any) => {
            updateTrackData([data]);
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        updateTrackData([]);
      }
    }
  }, [complaintData]);

  // Configuration
  const configSelectors = useConfigSelectors();

  const currentLayout = configSelectors.getLayout();
  const isFullScreen = configSelectors.getIsFullscreen();
  const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');

  return (
    <>
      {!isGridFullscreen && (
        <MapContainer
          trackData={trackData}
          selectedId={selectedId}
          complaintTime={complaintData ? complaintData.time : new Date().toISOString()}
        />
      )}

      <ComplaintsFormContainer isViewMode complaintDetails={complaintDetails} paging={paging} />
    </>
  );
};
