import React, { useEffect, useState } from 'react';

// Map stuff
import {
  FeatureCollection as IFeatureCollection,
  Feature as IFeature,
  Polygon as IPolygon,
  polygon as turfPolygon,
  featureCollection as turfFeatureCollection,
  intersect as turfIntersect,
  difference as turfDifference,
  bbox as turfBbox,
} from '@turf/turf';

// Providers
import { useMapProps } from 'src/app/functions/map';

// Components
import { StyledMap, useMapWhenReady, useMapRef } from '@ems/client-design-system';

// Types
import {
  IAircraftNoiseContour,
  ILayerList,
} from 'src/@settings/containers/Modeling/Contours/interfaces';

export const ContoursMap = ({
  selectedContours,
  colorProfiles,
  dataAvailable,
  setIsMapAnimating,
}: {
  selectedContours: IAircraftNoiseContour[];
  colorProfiles: { [profile: string]: { bands: number[]; colors: string[] } };
  dataAvailable: boolean;
  setIsMapAnimating: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const MAPBOX_PREFIX_STRING = 'contour';
  // map ref
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const [mapNode, mapRef] = useMapRef();
  // get map apis
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { mapApis, mapLoaded } = useMapWhenReady(mapNode);

  const mapProps = useMapProps();

  const getBands = (contours: IAircraftNoiseContour[]) =>
    contours.flatMap(({ bands }: IAircraftNoiseContour) => bands);

  const getCenterMidFromBands = (contours: IAircraftNoiseContour[]) => {
    const lats = [];
    const lons = [];
    getBands(contours)
      .flatMap(({ polygons }) => polygons || [])
      .flatMap(({ exterior }) => exterior.points)
      .forEach(({ latitude, longitude }) => {
        lats.push(latitude);
        lons.push(longitude);
      });

    if (!lats.length || !lons.length) {
      // Default midpoint
      return [];
    }

    const sum = (partial: number, a: number) => partial + a;
    return [
      +(lons.reduce(sum, 0) / lons.length).toPrecision(8),
      +(lats.reduce(sum, 0) / lats.length).toPrecision(9),
    ];
  };

  const [viewport, setViewport] = useState({
    ...mapProps.viewportFromProps,
    center: dataAvailable ? getCenterMidFromBands(selectedContours) : [],
  });

  const deleteMapContours = () => {
    // contour_
    const mapLayers = mapApis.getStyle().layers;
    const contourLayers = mapLayers.filter(({ id }) => id.includes(MAPBOX_PREFIX_STRING));
    const mapSources = mapApis.style.sourceCaches;
    const contourSources = Object.keys(mapSources).filter(key =>
      key.includes(MAPBOX_PREFIX_STRING)
    );

    if (contourLayers.length) {
      contourLayers.forEach(({ id }) => {
        mapApis.removeLayer(id);
      });
    }

    if (contourSources.length) {
      contourSources.forEach(source => {
        mapApis.removeSource(source);
      });
    }
  };

  const drawContour = ({
    featureCollection,
    name,
    color,
  }: {
    featureCollection: IFeatureCollection;
    name: string;
    color: string;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    mapApis.addSource(name, {
      type: 'geojson',
      data: featureCollection,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    mapApis.addLayer({
      id: name,
      type: 'fill',
      source: name,
      layout: {},
      paint: {
        'fill-color': color,
        'fill-opacity': 0.5,
        'fill-antialias': true,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    mapApis.addLayer({
      id: `${name}_outline`,
      type: 'line',
      source: name,
      layout: {},
      paint: {
        'line-color': color,
        'line-width': 1,
      },
    });
  };

  const cutAndRenderLayers = (layers: ILayerList[]) => {
    // Colours are top down (highest = darkest colour) so need to match to avaliable colours
    const colorList = colorProfiles['chroma'].colors;
    const topDownColourList = colorList.slice(Math.max(colorList.length - layers.length, 1));

    layers.map(({ level, polygons: currentLevelPolygons }, count) => {
      const color = topDownColourList[count];
      const name = `${MAPBOX_PREFIX_STRING}_${count}`;

      // Check for a layer above and cut any differences that intersect with the current layer
      if (layers[count + 1]) {
        const { polygons: levelAbovePolygons } = layers[count + 1];
        const collection = [];

        // Loop each polygon in the current and level above and cut if required
        currentLevelPolygons.forEach(currentLevelPolygon => {
          levelAbovePolygons.forEach(levelAbovePolygon => {
            // Returns intersected points or null if none
            const intersectedPolygon = turfIntersect(currentLevelPolygon, levelAbovePolygon);
            if (intersectedPolygon) {
              collection.push(
                // Returns difference points or null
                turfDifference(currentLevelPolygon, intersectedPolygon) || currentLevelPolygon
              );
            } else if (count === 0) {
              collection.push(currentLevelPolygon);
            }
          });
        });

        drawContour({
          featureCollection: turfFeatureCollection(collection),
          name,
          color,
        });
      } else {
        drawContour({
          featureCollection: turfFeatureCollection(currentLevelPolygons),
          name,
          color,
        });
      }
    });
  };

  const displayContours = () => {
    const layerList: ILayerList[] = selectedContours
      .flatMap(({ bands }) => bands)
      .map(bands => {
        const { polygons, level } = bands;
        return {
          level,
          polygons: polygons.map(({ exterior: { points: exteriorPoints }, holes }) => {
            const shape = exteriorPoints.map(({ latitude, longitude }) => [longitude, latitude]);

            // close the polygon if ends dont match
            if (shape[0] !== shape[shape.length - 1]) {
              shape.push(shape[0]);
            }

            let shapePolygon = turfPolygon([shape]);

            // Check and cut holes on layer
            if (holes && holes.length) {
              holes.forEach(({ points }) => {
                const holeShape = points.map(({ latitude, longitude }) => [longitude, latitude]);
                // close the polygon if ends dont match
                if (holeShape[0] !== holeShape[holeShape.length - 1]) {
                  holeShape.push(holeShape[0]);
                }
                const holePolygon = turfPolygon([holeShape]);
                const intersectedPolygon = turfIntersect(shapePolygon, holePolygon);

                if (intersectedPolygon) {
                  shapePolygon =
                    (turfDifference(shapePolygon, intersectedPolygon) as IFeature<
                      IPolygon,
                      {
                        [name: string]: any;
                      }
                    >) || shapePolygon;
                }
              });
            }

            return shapePolygon;
          }),
        };
      });

    cutAndRenderLayers(layerList);

    if (selectedContours.length) {
      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const firstSelectedContourGeoJson = mapApis.getSource(`${MAPBOX_PREFIX_STRING}_0`)._data;
      const boundingBox = turfBbox(firstSelectedContourGeoJson);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      mapApis.fitBounds([boundingBox.slice(0, 2), boundingBox.slice(2)], {
        speed: 1.2,
        curve: 1.42,
        padding: 20,
      });
      setIsMapAnimating(true);

      // Update the viewport so that we don't jump back when interacting with the map
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      mapApis.once('moveend', () => {
        setIsMapAnimating(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const { lat: latitude, lng: longitude } = mapApis.getCenter() as {
          lat: number;
          lng: number;
        };
        setViewport({
          ...viewport,
          latitude,
          longitude,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          zoom: mapApis.getZoom(),
        });
      });
    }
  };

  useEffect(() => {
    if (mapApis && dataAvailable) {
      //displayContours();
    }
  }, [mapApis]);

  useEffect(() => {
    if (mapApis && selectedContours.length && dataAvailable) {
      deleteMapContours();
      displayContours();
    }
  }, [selectedContours]);

  return (
    <section>
      <StyledMap
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        mapStyle={mapProps.mapStyle}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        mapboxApiAccessToken={mapProps.mapboxApiAccessToken}
        viewport={viewport}
        onViewportChange={(viewport: any) => setViewport(viewport)}
        dragRotate={false}
        height={'100vh'}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        onLoad={mapLoaded}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref={mapRef}
      />
    </section>
  );
};
