import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

// Apollo
import { useLazyQuery } from '@apollo/react-hooks';

// Queries
import {
  CHECK_ACTIVE_SCENARIOS,
  CREATE_NOISE_SCENARIO,
  CHECK_NOISE_SCENARIO_STATUS,
} from './queries';

// Reducers & Contexts
import { useFilterDataSelectors } from 'src/app/reducers';
import { useConfigSelectors } from 'src/app/reducers/configReducer';

// Components
import { PageHeader, DateRange } from 'src/components';
import { Button, displayToast } from '@ems/client-design-system';
import {
  GenerateScenarioDialog,
  CheckPreviousScenarioDialog,
  CreateScenarioForm,
  ConfirmLicenseDialog,
} from './components';

// Styled
import { ScenarioFormButtonWrapper } from './CreateScenario.styles';

// Types
import {
  ICreateNoiseScenarioVariables,
  ICreateNoiseScenarioQuery,
  ICheckNoiseScenarioStatusQuery,
  ICheckNoiseScenarioStatusVariables,
  IGetNoiseModelingScenariosForUserData,
  IScenarioFormValues,
  IScenarioPeriods,
} from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

import { IOperationsFilters, IAircraftNoiseModelingConfig } from 'src/app/props';

// Functions
import { predefinedDateRangeList } from 'src/app/functions/dateRange';
import { getDefaultFilterDates } from 'src/@scenarioGeneration/functions';
import { getDeployedProductId, setTabTitle } from 'src/utils';
import { hhmmToSeconds } from 'src/@settings/containers/Rules/InfringementRuleDetails/InfringementRuleDetailsHelpers';
import {
  findLatestScenario,
  buildCreateScenarioFilterOptions,
  validateCreateScenarioForm,
  formatStartAndEndTimes,
} from './helpers';
import { viewScenarioTranslations } from '../ViewScenario/helpers';

// Variables
import { NOISE_MODELING, SCENARIO_PERIOD_DEFAULTS } from 'src/constants';

interface CreateScenarioProps {
  skipCacheCheck: boolean;
  invalidScenarioId: boolean;
}

export const CreateScenarioContainer = ({
  skipCacheCheck,
  invalidScenarioId,
}: CreateScenarioProps) => {
  const routerHistory = useHistory();

  // Selectors & Context
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const appConfig = configSelectors.getConfig();
  const filterDataSelectors = useFilterDataSelectors();
  const operationFilterData = filterDataSelectors.getOperationsFilterData();

  // States
  const [scenarioId, setScenarioId] = useState<string>(null);
  const [scenarioStatus, setScenarioStatus] = useState<string>(null);
  const [blockDialogs, setBlockDialogs] = useState(true);

  // Variables
  const licenseConfig: IAircraftNoiseModelingConfig = appConfig.aircraftNoiseModeling;
  const translations = viewScenarioTranslations();
  const filtersWithNull: IOperationsFilters = buildCreateScenarioFilterOptions(operationFilterData);
  const selectedNoiseModel = licenseConfig.selectedModel;

  const { defaultFilterDateFrom, defaultFilterDateTo } = getDefaultFilterDates(
    translations.dateRangeList,
    predefinedDateRangeList
  );

  setTabTitle(translations.headings.title);

  const defaultOperationValues = ['Arrival', 'Departure'];

  const valuesMatch = (arr1, arr2) => {
    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();

    return (
      sortedArr1.length == sortedArr2.length &&
      sortedArr1.every((element, index) => {
        return element === sortedArr2[index];
      })
    );
  };

  // Set defaults if none exists currently
  useEffect(() => {
    if (!localStorage.getItem('scenario.periods')) {
      localStorage.setItem('scenario.periods', JSON.stringify(SCENARIO_PERIOD_DEFAULTS));
    }
  }, []);

  const periodValues = JSON.parse(
    localStorage.getItem('scenario.periods') || JSON.stringify(SCENARIO_PERIOD_DEFAULTS)
  ) as IScenarioPeriods;

  const formik = useFormik({
    initialValues: {
      aircraftCategories: filtersWithNull.aircraftCategories,
      aircraftTypes: filtersWithNull.aircraftTypes,
      airlines: filtersWithNull.airlines,
      airportIds: filtersWithNull.airportIds.length === 1 ? filtersWithNull.airportIds : [],
      dateFrom: defaultFilterDateFrom,
      dateTo: defaultFilterDateTo,
      name: '',
      operationTypes: defaultOperationValues,
      operatorCategories: filtersWithNull.operatorCategories,
      pathNames: filtersWithNull.pathname,
      runwayNames: filtersWithNull.runwayNames,
      timeFrom: '',
      timeTo: '',
      noiseModelType: [selectedNoiseModel],
      dayPeriod: periodValues.day,
      eveningPeriod: periodValues.evening,
      nightPeriod: periodValues.night,
    } as IScenarioFormValues,
    validate: values => validateCreateScenarioForm(values, translations),
    onSubmit: values => {
      const { startTime, endTime } = formatStartAndEndTimes(values);
      const { dayPeriod, eveningPeriod, nightPeriod } = values;

      localStorage.setItem(
        'scenario.periods',
        JSON.stringify({
          day: dayPeriod,
          evening: eveningPeriod,
          night: nightPeriod,
        })
      );

      const measurementDayPeriod = {
        dayStart: hhmmToSeconds(values.dayPeriod),
        ...(values.eveningPeriod !== null && values.eveningPeriod !== ''
          ? { eveningStart: hhmmToSeconds(values.eveningPeriod) }
          : {}),
        ...(values.nightPeriod !== null && values.nightPeriod !== ''
          ? { nightStart: hhmmToSeconds(values.nightPeriod) }
          : {}),
      };

      createNoiseScenarioQuery({
        variables: {
          startTime,
          endTime,
          name: values.name,
          noiseModelType: values.noiseModelType[0],
          measurementDayPeriod,
          filter: {
            timePeriod:
              values.timeTo && values.timeFrom
                ? {
                    end: hhmmToSeconds(values.timeTo),
                    start: hhmmToSeconds(values.timeFrom),
                  }
                : null,
            aircraftCategories: valuesMatch(
              values.aircraftCategories,
              filtersWithNull.aircraftCategories
            )
              ? null
              : values.aircraftCategories,
            aircraftTypes: valuesMatch(values.aircraftTypes, filtersWithNull.aircraftTypes)
              ? null
              : values.aircraftTypes,
            airlines: valuesMatch(values.airlines, filtersWithNull.airlines)
              ? null
              : values.airlines,
            airportIds: values.airportIds,
            operationTypes: valuesMatch(values.operationTypes, defaultOperationValues)
              ? null
              : values.operationTypes,
            operatorCategories: valuesMatch(
              values.operatorCategories,
              filtersWithNull.operatorCategories
            )
              ? null
              : values.operatorCategories,
            pathNames: valuesMatch(values.pathNames, filtersWithNull.pathname)
              ? null
              : values.pathNames,
            runwayNames: valuesMatch(values.runwayNames, filtersWithNull.runwayNames)
              ? null
              : values.runwayNames,
          },
        },
      });
    },
  });

  const handleDateSelection = (dateFrom: Date | null, dateTo: Date | null) => {
    // void .finally() to statisfy eslint
    formik.setFieldValue('dateFrom', dateFrom).finally(() => {});
    formik.setFieldValue('dateTo', dateTo).finally(() => {});
    setTimeout(function() {
      formik.validateForm();
      formik.setFieldTouched('dateFrom', true);
    }, 100);
  };

  const [checkActiveScenarios, { loading: checkActiveScenariosLoading }] = useLazyQuery<
    IGetNoiseModelingScenariosForUserData
  >(CHECK_ACTIVE_SCENARIOS, {
    onCompleted: response => {
      if (response.aircraftNoiseModelingScenariosForCurrentUser.length) {
        // Find last edited scenario and redirect to it
        const latestScenario = findLatestScenario(
          response.aircraftNoiseModelingScenariosForCurrentUser
        );
        routerHistory.push(`/${getDeployedProductId()}/${NOISE_MODELING}/${latestScenario.id}`);
      } else {
        setBlockDialogs(false);
      }
    },
  });

  useEffect(() => {
    if (invalidScenarioId) {
      displayToast({
        key: 'invalidScenarioToast',
        message: translations.errors.invalidScenarioIdError,
        intent: 'warning',
      });
    }
    if (skipCacheCheck && !invalidScenarioId) {
      setBlockDialogs(false);
    }

    if (!skipCacheCheck && !invalidScenarioId) {
      checkActiveScenarios();
    }
  }, []);

  // Create
  const [createNoiseScenarioQuery, {}] = useLazyQuery<
    ICreateNoiseScenarioQuery,
    ICreateNoiseScenarioVariables
  >(CREATE_NOISE_SCENARIO, {
    onCompleted: response => {
      const { createAircraftNoiseModelingScenario } = response;
      if (createAircraftNoiseModelingScenario) {
        setScenarioId(createAircraftNoiseModelingScenario);
        checkNoiseScenarioStatusQuery({
          variables: {
            id: createAircraftNoiseModelingScenario,
          },
        });
      }
    },
    onError: error => {
      displayToast({
        message: error.message,
        intent: 'danger',
      });
    },
  });

  const [
    checkNoiseScenarioStatusQuery,
    { loading: checkNoiseScenarioStatusLoading },
  ] = useLazyQuery<ICheckNoiseScenarioStatusQuery, ICheckNoiseScenarioStatusVariables>(
    CHECK_NOISE_SCENARIO_STATUS,
    {
      onCompleted: response => {
        const { status } = response.aircraftNoiseModelingScenario;
        setScenarioStatus(status);
        if (status === 'InProgress') {
          setTimeout(() => {
            checkNoiseScenarioStatusQuery({
              variables: {
                id: scenarioId,
              },
            });
          }, 1000);
        }
        if (status === 'Completed') {
          routerHistory.push(`/${getDeployedProductId()}/${NOISE_MODELING}/${scenarioId}`);
        }
        if (status === 'Error') {
        }
      },
    }
  );

  return (
    <>
      <CheckPreviousScenarioDialog isLoading={checkActiveScenariosLoading} />
      <GenerateScenarioDialog
        isLoading={checkNoiseScenarioStatusLoading}
        scenarioStatus={scenarioStatus}
        setScenarioStatus={setScenarioStatus}
        dialogTranslations={translations.dialogTranslations}
      />
      {!blockDialogs && <ConfirmLicenseDialog licenseConfig={licenseConfig} />}

      <div className="container-fluid scenarioGeneration-container">
        <div className="scenarioGeneration_header">
          <PageHeader title={translations.headings.selectDataHeading}>
            <div className="page-tools">
              <div
                className={cx('scenarioGeneration_dateRange', {
                  hasError: formik.errors.dateFrom,
                })}>
                <DateRange
                  hasShortcuts
                  isSingleDayRangeAllowed
                  hasContigousCalendarMonths
                  onDatesSelected={handleDateSelection}
                  allowClearDates
                  defaultDateFrom={defaultFilterDateFrom}
                  defaultDateTo={defaultFilterDateTo}
                  currentDates={[formik.values.dateFrom, formik.values.dateTo]}
                />
              </div>
            </div>
          </PageHeader>
        </div>
        <div className="scenarioGeneration_content">
          <CreateScenarioForm
            formik={formik}
            translations={translations}
            selectedTrackTheme={selectedTrackTheme}
            filterData={filtersWithNull}
          />

          <ScenarioFormButtonWrapper>
            <Button style="primary" onClick={() => formik.handleSubmit()}>
              {translations.buttons.createButton}
            </Button>
          </ScenarioFormButtonWrapper>
        </div>
      </div>
    </>
  );
};
