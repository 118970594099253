import React from 'react';

// Hooks
import { useAircraftMappings } from '../providers/aircraftMappings';
import { useTableFilters } from '../hooks';

// Components
import { TableFilter } from 'src/components';

// Types
import { IAircraftMappingTableData } from '../types';
import { onFilterUpdate } from '../functions/filterActions';

export const AMTableFilters = ({
  tableData,
}: {
  tableData: IAircraftMappingTableData[];
}): JSX.Element => {
  // Providers
  const {
    dispatch,
    state: { isLoading },
  } = useAircraftMappings();

  const {
    tableFilterObject,
    setTableFilterObject,
    onClearFilters,
    isTableFilterInitialized,
  } = useTableFilters({
    isLoading,
    tableData,
    beforeFilter: () => {},
  });

  const clearFilters = () => {
    dispatch({ type: 'TABLE_FILTER', data: {} });
    onClearFilters();
  };

  return (
    <TableFilter
      tableFilterObject={tableFilterObject}
      isLoading={isLoading}
      areFilterOptionsInitialized={isTableFilterInitialized}
      setTableFilterObject={setTableFilterObject}
      onClearFilters={clearFilters}
      onFilterUpdate={filters => onFilterUpdate(filters, dispatch)}
    />
  );
};
