import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';

// Layout
import { SettingsLayout } from 'src/components/Layout';
// Container
import { InfringementsRulesContainer } from 'src/@settings/containers';

const Screen = () => {
  return (
    <SettingsLayout>
      <InfringementsRulesContainer />
    </SettingsLayout>
  );
};

export const InfringementRulesSettingsScreen = withRouteProps(Screen);
