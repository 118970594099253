import React from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { FullNoiseGraph as FNGComponent, convertToSeconds } from '@ems/client-design-system';

export const FullNoiseGraph = ({ data, currentTime, startTime, endTime, className }: any) => {
  const tsStartTime = startTime
    ? DateTime.fromISO(startTime).toSeconds()
    : convertToSeconds(Date.now());
  const tsEndTime = endTime ? DateTime.fromISO(endTime).toSeconds() : convertToSeconds(Date.now());

  return (
    <div className={cx('noise-graph_container', 'noise-graph_container--full', className)}>
      <FNGComponent data={data} count={currentTime} startTime={tsStartTime} endTime={tsEndTime} />
    </div>
  );
};
