import React, { useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
// selectors
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// mutations
import { UPDATE_NOISE_EVENT_CAUSE, CORRELATE_TO_OPERATION } from 'src/@noiseEvents/mutations';
// components
import {
  BlockHeader,
  CardDropdown,
  Tooltip,
  Icons,
  getConvertedDistance,
  getConvertedVerticalDistance,
  getConvertedTime,
} from '@ems/client-design-system';
import { fetchNearbyFlights } from 'src/@noiseEvents/resolvers/nearbyFlightsResolver';
// functions
// utils
import { usePermissions } from 'src/app/functions/permissions';
import {
  capitalizeObjectKeys,
  timeDiffInSec,
  timePlusMinusDuration,
  getOperationTheme,
} from 'src/utils';
// constants
import {
  CAUSE_COMMUNITY,
  CAUSE_WEATHER,
  CAUSE_EQUIPMENT,
  CAUSE_UNKNOWN,
  CAUSE_NOT_SET,
  CAUSE_AIRCRAFT,
  CAUSE_SINGLE_LOCAL_AIRCRAFT,
  CAUSE_MULTIPLE_LOCAL_AIRCRAFT,
  CAUSE_NON_LOCAL_AIRCRAFT,
  CAUSE_MIXED_AIRCRAFT,
  UNIT_SECOND,
} from 'src/constants';

const communityIcon = <Icons iconName="ic-ui-community" size={36} />;
const weatherIcon = <Icons iconName="ic-ui-weather" size={36} />;
const equipmentIcon = <Icons iconName="ic-ui-malfunction" size={31} />;
const unknownIcon = <Icons iconName="ic-ac-unknown" size={24} />;

export const NoiseCauseContainer = ({ noiseEvent, operationData, noiseMonitor, causeUpdated }) => {
  const client = useApolloClient();
  // configuration
  const configSelectors = useConfigSelectors();
  const selectedTrackTheme = configSelectors.getTheme('operations');
  const currentOperationsTheme = getOperationTheme(selectedTrackTheme);

  const {
    globals: {
      nearbyFlights: { timeBufferAroundLMax, maxHeightAboveNmt, maxGroundDistance },
    },
  } = configSelectors.getConfig();
  const units = configSelectors.getUnits();
  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { cause: causeTitle },
      buttons: { causeDropdown },
      labels: { pcaRange, pcaHeight, pcaTimeDifference, pcaElevationAngle },
      lists: {
        operationTypes,
        classificationTypes: { Community, Weather, Equipment, Unknown },
      },
    },
    fields: {
      operations: { acid, aircraftCategory, aircraftType, operationType },
    },
  } = languageSelectors.getLanguage();
  const { canUpdate } = usePermissions('NoiseEvent');

  // base template for options
  const header = 'header';
  const footer = 'footer';
  const dynamic = 'operations';
  const template = {
    header: [
      {
        icon: communityIcon,
        label: Community,
        value: CAUSE_COMMUNITY,
      },
      {
        icon: weatherIcon,
        label: Weather,
        value: CAUSE_WEATHER,
      },
      {
        icon: equipmentIcon,
        label: Equipment,
        value: CAUSE_EQUIPMENT,
      },
    ],
    operations: [],
    footer: [
      {
        icon: unknownIcon,
        label: Unknown,
        value: CAUSE_AIRCRAFT,
      },
    ],
  };

  const convertedTranslationTooltips = capitalizeObjectKeys(operationTypes);
  const addDiscriptionListItems = (operation, secondaryInfo) => {
    const operationTheme = currentOperationsTheme[operation.operationType.toLowerCase()];
    const operationName: string = operation.operationType.toLowerCase();
    const discriptionListItems = [
      {
        title: operationType,
        description: (
          <div className={'operation-details_opType'}>
            <Tooltip content={convertedTranslationTooltips[operation.operationType]}>
              <Icons
                className={`ad-${operationName}`}
                iconName={`ic-ad-${operationName}`}
                size={28}
                style={{
                  fill: operationTheme,
                  color: operationTheme,
                }}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        title: acid,
        description: operation.acid,
      },
      {
        title: aircraftCategory,
        description: (
          <Tooltip content={convertedTranslationTooltips[operation.aircraftCategory]}>
            <Icons iconName={`ic-ac-${operation.aircraftCategory.toLowerCase()}`} size={20} />
          </Tooltip>
        ),
      },
      {
        title: aircraftType,
        description: operation.aircraftType,
      },
    ];
    if (secondaryInfo && Object.keys(secondaryInfo).length) {
      secondaryInfo.map(({ title, description }) => {
        discriptionListItems.push({
          title,
          description,
        });
      });
    }
    return discriptionListItems;
  };

  // decide the default selected item
  const defineDefaultOption = (classification: string, pcaData: any[]) => {
    let key = '';
    let index = 0;

    const whichOperation = () => {
      if (noiseEvent.operationId && typeof operationData.id !== 'undefined') {
        key = dynamic;
        index = pcaData.findIndex(pca => pca.operation.id === operationData.id);
      } else {
        key = footer;
        index = 0;
      }
    };

    switch (classification) {
      case CAUSE_COMMUNITY:
        key = header;
        index = 0;
        break;
      case CAUSE_WEATHER:
        key = header;
        index = 1;
        break;
      case CAUSE_EQUIPMENT:
        key = header;
        index = 2;
        break;
      case CAUSE_UNKNOWN:
        key = footer;
        index = 0;
        break;
      case CAUSE_NOT_SET:
        key = 'notFound';
        break;
      case CAUSE_AIRCRAFT:
        whichOperation();
        break;
      case CAUSE_SINGLE_LOCAL_AIRCRAFT:
        whichOperation();
        break;
      case CAUSE_MULTIPLE_LOCAL_AIRCRAFT:
        whichOperation();
        break;
      case CAUSE_NON_LOCAL_AIRCRAFT:
        whichOperation();
        break;
      case CAUSE_MIXED_AIRCRAFT:
        whichOperation();
        break;
      default:
        key = 'notFound';
        break;
    }
    return {
      key,
      index,
    };
  };

  const [options, updateOptions] = useState<any>({});
  const [selectedOption, updateSelectedOption] = useState({ key: '', index: 0 });

  const [pca, updatePca] = useState([]);
  const [loading, updateLoadingState] = useState(false);
  const [updateNoiseEventCause] = useMutation(UPDATE_NOISE_EVENT_CAUSE, {
    onCompleted() {
      causeUpdated(false);
    },
  });
  const [correlateToOperation] = useMutation(CORRELATE_TO_OPERATION, {
    onCompleted() {
      causeUpdated(true);
    },
  });

  useEffect(() => {
    updatePca([]);
    requestTofetchNearbyFlights();

    if (pca.length > 0) {
      const selectedOption = defineDefaultOption(noiseEvent.classification, pca);
      updateSelectedOption(selectedOption);
    }
  }, [noiseMonitor]);

  const onSelect = ({ key, index }) => {
    // if user clicks on the same item it won't do api call
    if (selectedOption.key !== key || selectedOption.index !== index) {
      const selectedItem = options[key][index];
      if (key === dynamic) {
        correlateToOperation({
          variables: {
            id: noiseEvent.id,
            cause: CAUSE_AIRCRAFT,
            operationId: selectedItem.operationId,
          },
        });
      } else {
        updateNoiseEventCause({
          variables: {
            id: noiseEvent.id,
            cause: selectedItem.value,
          },
        });
      }
      updateSelectedOption({ key: `${key}`, index: Number(`${index}`) });
    }
  };

  // default to 1 minute either side of LMax
  const timeBuffer = timeBufferAroundLMax !== undefined ? timeBufferAroundLMax : 60;
  const startTime = timePlusMinusDuration(noiseEvent.startTime, { seconds: -timeBuffer });
  const endTime = timePlusMinusDuration(noiseEvent.endTime, { seconds: timeBuffer });

  const requestTofetchNearbyFlights = () => {
    if (pca.length === 0) {
      updateLoadingState(true);
      const { position: noiseMonitorLocation } = noiseMonitor;
      const pcaParams = {
        startTime,
        endTime,
        verticalRangeLimit: maxHeightAboveNmt, // Height above NMT - in feet (default: 5000 feet)
        horizontalRangeLimit: maxGroundDistance, // Ground distance - in meter (default is 2500m)
        noiseMonitorLocation: {
          latitude: noiseMonitorLocation.latitude,
          longitude: noiseMonitorLocation.longitude,
          altitude: noiseMonitorLocation.altitude,
        },
      };
      fetchNearbyFlights(client, pcaParams)
        .then(({ data }: any) => {
          updatePca(data);
          updateDropdownOptions(data);
          const selectOption = defineDefaultOption(noiseEvent.classification, data);
          updateSelectedOption(selectOption);
          updateLoadingState(false);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      updateDropdownOptions(pca);
    }
  };

  const updateDropdownOptions = (pcaData: any[]) => {
    const dynamicItems = pcaData.map(
      ({ operation, slantDistance, verticalDistance, elevationAngle, time }) => ({
        operationId: operation.id,
        items: addDiscriptionListItems(operation, [
          {
            title: pcaTimeDifference, // time difference will be (noise event lmax time - pca time)
            description: getConvertedTime(
              timeDiffInSec(
                noiseEvent.time, // noise event lmax time
                time // pca time
              ),
              UNIT_SECOND,
              0
            ),
          },
          {
            title: pcaElevationAngle,
            description: `${elevationAngle}°`,
          },
          {
            title: pcaRange, // comes back in meters, convert to km
            description: getConvertedDistance(slantDistance, units.distance, 0),
          },
          {
            title: pcaHeight, // display as feet
            description: getConvertedVerticalDistance(verticalDistance, units.distanceVertical, 0),
          },
        ]),
      })
    );
    updateOptions({
      ...template,
      ...{
        [dynamic]: dynamicItems,
      },
    });
  };
  return (
    <div className="block">
      <BlockHeader title={causeTitle} />
      <CardDropdown
        options={options}
        defaultSelectedOption={selectedOption}
        onInteraction={requestTofetchNearbyFlights}
        onSelect={onSelect}
        loading={loading}
        disabled={canUpdate ? false : true}
        translationData={{
          editCauseDropdown: causeDropdown,
        }}
      />
    </div>
  );
};
