import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
// components
import { SimpleSidebarLayout } from 'src/components/Layout';
import { SettingsContentContainer } from 'src/@settings/containers';
// provider
import { SettingsStateProvider } from '../provider/SettingsStateProvider';

const SettingsScreen = () => (
  <SimpleSidebarLayout displayNav={false}>
    <SettingsStateProvider>
      <SettingsContentContainer />
    </SettingsStateProvider>
  </SimpleSidebarLayout>
);

export const Settings = withRouteProps(SettingsScreen);
