import gql from 'graphql-tag';

export const GET_AIRCRAFT_NOISE_CONTOURS = gql`
  query GetAircraftNoiseContours(
    $queryFilter: AircraftNoiseContourFilter!
    $sortOrder: [OrderByType!]!
  ) {
    aircraftNoiseContours(sort: $sortOrder, filter: $queryFilter) {
      name
      id
      noiseModelType
      bands {
        level
        polygons {
          exterior {
            ...contourPointsFragment
          }
          holes {
            ...contourPointsFragment
          }
          __typename
        }
        __typename
      }
      createdBy
      createTime
      description
      lastUpdateTime
      levelIncrement
      maximumLevel
      metric
      minimumLevel
      groupName
      __typename
    }
  }

  fragment contourPointsFragment on AircraftNoiseContourBandRing {
    points {
      altitude
      latitude
      longitude
      __typename
    }
  }
`;

export const GET_CONTOUR_BUCKET_UPLOAD = modelType => gql`
  mutation getContourBucket {
    beginAircraftNoiseContour${modelType}Upload {
      id
      files {
        contentType
        fileSubType
        fileName
        uploadUri {
          authenticationScheme
          uri
        }
      }
    }
  }
`;

export const COMPLETE_CONTOUR_BUCKET_UPLOAD = modelType => gql`
  mutation complete${modelType}Upload($jobId: ID) {
    completeAircraftNoiseContour${modelType}Upload(jobId: $jobId)
  }
`;

export const GET_CONTOUR_UPLOAD_STATUS_INM = gql`
  query INMUploadStatus($jobId: ID) {
    aircraftNoiseContourUploadResult(jobId: $jobId) {
      jobId
      status
      contourId
    }
  }
`;

export const GET_CONTOUR_UPLOAD_STATUS_AEDT = gql`
  query AEDTUploadStatus($jobId: ID) {
    aircraftNoiseContourUploadResult(jobId: $jobId) {
      jobId
      status
      contourId
    }
  }
`;

export const DELETE_NOISE_CONTOUR = gql`
  mutation deleteContour($contourId: Int!) {
    deleteAircraftNoiseContour(id: $contourId)
  }
`;
