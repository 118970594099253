export const insertStyle = (styleString: string, id: string) => {
  const doc = window.document;
  if(!doc) {
    return;
  }
  if(doc.querySelector("#" + id)) {
    doc.querySelector("#" + id).remove();
  }
  const head = doc.querySelector("head");
  const style = doc.createElement("style");
  const rules = doc.createTextNode(styleString);
  style.type = "text/css";
  style.appendChild(rules);
  style.id = id;
  head.appendChild(style);
};
