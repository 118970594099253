import React, { useMemo } from 'react';
import uuid from 'uuid';
import { InlineDropDown, Tooltip, Icons } from '@ems/client-design-system';

// Variables
import { BLANK_STRING_PLACEHOLDER } from 'src/constants';
import { formatNumber } from 'src/utils';

import {
  IAircraftToNoiseModelingTypeSummary,
  IAircraftToNoiseModelingTypeSummaryEdge,
  IInlineDropdownItem,
  IModelingAircraft,
  ISelectedModelItem,
  ISource,
  ITableData,
  TNoiseModelingTypeMapSource,
} from '../interfaces';
import { NumberCell } from '../styles';

export const getIdFromTableRowKey = (tableRowKey: string): number => {
  const id = tableRowKey.substring(tableRowKey.indexOf(':') + 1, tableRowKey.indexOf('/'));
  return Number(id);
};

const sourceIcon = ({
  source,
  systemAircraftName,
  aircraftName,
}: {
  source?: TNoiseModelingTypeMapSource;
  systemAircraftName?: string;
  aircraftName?: string;
}) => {
  // Return an empty element if either name is null
  if (aircraftName === null && systemAircraftName === null) {
    return <span>&nbsp;</span>;
  }
  const iconObject: ISource = {
    System: {
      title: 'System sourced',
      icon: 'ic-globe',
    },
    User: {
      toolTip: systemAircraftName ? `Original: ${systemAircraftName}` : undefined,
      title: 'User sourced',
      icon: 'ic-ui-home',
    },
  }[source];

  const icon = iconObject ? (
    <Icons iconName={iconObject.icon} title={iconObject.title} size={20} />
  ) : (
    BLANK_STRING_PLACEHOLDER
  );
  return (
    <span className="table-icon__source">
      {source && source === 'User' ? (
        <Tooltip
          content={(iconObject && iconObject.toolTip) || BLANK_STRING_PLACEHOLDER}
          hoverOpenDelay={800}>
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
    </span>
  );
};

const modelAircraftNameDropdown = (
  selectedNode: IAircraftToNoiseModelingTypeSummary,
  noiseModelingAircraftTypes: IModelingAircraft[],
  handleInlineSelect: (
    item: IInlineDropdownItem,
    selectedNode: ISelectedModelItem,
    index: number
  ) => void,
  index: number
): JSX.Element => {
  const itemsWithLabel = noiseModelingAircraftTypes.map(item => ({
    label: item.aircraftName,
    key: item.aircraftId,
  }));

  const selectedNoiseModelingAircraftType = noiseModelingAircraftTypes.find(
    type =>
      selectedNode.modelingAircraftName === type.aircraftName &&
      selectedNode.modelingAircraftId === type.aircraftId &&
      selectedNode.noiseModelType === type.noiseModelType
  );

  const defaultSelectedItem = {
    label:
      (selectedNoiseModelingAircraftType && selectedNoiseModelingAircraftType.aircraftName) ||
      BLANK_STRING_PLACEHOLDER,
    key: (selectedNoiseModelingAircraftType && selectedNoiseModelingAircraftType.id) || '',
  };

  const inlineDropdown = (
    <div className="u-flex-center">
      <InlineDropDown
        searchItems={itemsWithLabel}
        hasIcon={false}
        isIconOnly={false}
        isNullable
        updateSelection={(selectedItem: IInlineDropdownItem) => {
          handleInlineSelect(
            selectedItem,
            {
              ...selectedNode,
              noiseModelingAircraftType: selectedNoiseModelingAircraftType,
            },
            index
          );
        }}
        selectedItem={defaultSelectedItem}
      />
    </div>
  );

  return inlineDropdown;
};

export const formatModelingAircraftList = (
  modelList: IModelingAircraft[],
  types: string[]
): IModelingAircraft[] => modelList.filter(({ noiseModelType }) => types.includes(noiseModelType));

export const useTableData = (
  edges: IAircraftToNoiseModelingTypeSummaryEdge[],
  noiseModelingAircraftTypes: IModelingAircraft[],
  handleInlineSelect: (
    item: IInlineDropdownItem,
    selectedNode: ISelectedModelItem,
    selectedNodeIndex: number
  ) => void
): ITableData[] => {
  return useMemo(
    () =>
      edges.flatMap((edge: IAircraftToNoiseModelingTypeSummaryEdge, index) => {
        if (!edge.node) return [];
        const {
          aircraftType,
          engineModel,
          airline,
          modelingAircraftId,
          noiseModelType,
          source,
          modelingAircraftName,
          systemModelingAircraftName,
          numberOfFlights,
        } = edge.node;
        const guid = uuid.v4();
        return {
          aircraftType: aircraftType || BLANK_STRING_PLACEHOLDER,
          engineModel: engineModel || BLANK_STRING_PLACEHOLDER,
          airline: airline ? (airline === '*' ? 'All' : airline) : BLANK_STRING_PLACEHOLDER,
          modelingAircraftId: modelingAircraftId || BLANK_STRING_PLACEHOLDER,
          modelingAircraftName: noiseModelingAircraftTypes ? (
            modelAircraftNameDropdown(
              edge.node,
              noiseModelingAircraftTypes,
              handleInlineSelect,
              index
            )
          ) : (
            <></>
          ),
          noiseModelType,
          source: sourceIcon({
            source,
            systemAircraftName: systemModelingAircraftName,
            aircraftName: modelingAircraftName,
          }),
          numberOfFlights: <NumberCell>{formatNumber(numberOfFlights)}</NumberCell>,
          tableId: guid,
          tableRowKey: JSON.stringify({
            newNoiseModelType: noiseModelType,
            newAircraftType: aircraftType,
            newEngineModel: engineModel,
            newAirline: airline,
            newModelingAircraftId: modelingAircraftId,
          }),
        };
      }),
    [edges]
  );
};
