import { dispatcher } from 'src/utils/dispatcher';
import { debounce } from 'debounce';
// ts
import { ApolloClient } from 'apollo-client';
import { ITableFilterItem } from 'src/@infringementsCandidates/interfaces';
// actions
import {
  updateSelectedItemsAction,
  clearSelectedItemsAction,
  updateTimeFilterValueAction,
  updateTimeFilterInputAction,
  initialiseFilterAction,
} from 'src/@infringementsCandidates/actions/actions';
import { resetAndFetchData } from 'src/@infringementsCandidates/actions/infringementsActions';
import { dataActionTypes } from 'src/@infringementsCandidates/newActionTypes';
import {
  fetchNoiseMonitorLocations,
  fetchNoiseEvents,
} from 'src/@infringementsCandidates/resolvers/infringementsResolver';

const applyFiltersChanged = debounce(
  (
    client: ApolloClient<object>,
    dispatcher,
    sortString,
    resultSize: number,
    candidatesEnabled
  ): void => {
    resetAndFetchData(client, resultSize, dispatcher, sortString, candidatesEnabled);
  },
  500
);

export const updateSelectedItems = (
  client: ApolloClient<object>,
  category: string,
  selectedItems: ITableFilterItem[],
  newDispatcher: any,
  sortString: any,
  resultSize: number,
  setCandidatesFlag: React.Dispatch<React.SetStateAction<boolean>>,
  candidatesEnabled?: any
): void => {
  let candidatesEnabledToggle = candidatesEnabled;
  if (category === 'ruleNames' && selectedItems.length === 0) {
    newDispatcher({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: false });
    setCandidatesFlag(false);
    candidatesEnabledToggle = false;
  }
  dispatcher.handleDispatch(updateSelectedItemsAction(category, selectedItems));
  applyFiltersChanged(client, newDispatcher, sortString, resultSize, candidatesEnabledToggle);
};

export const clearSelectedItems = (
  client: ApolloClient<object>,
  resultSize: number,
  newDispatcher: any,
  sortString: any
): void => {
  dispatcher.handleDispatch(clearSelectedItemsAction());
  newDispatcher({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: false });
  resetAndFetchData(client, resultSize, newDispatcher, sortString, false);
};

export const updateTimeFilterInput = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterInputAction({
      time,
      fromOrTo,
    })
  );
};

export const updateTimeFilterValue = (time: string, fromOrTo: 'from' | 'to') => {
  dispatcher.handleDispatch(
    updateTimeFilterValueAction({
      time,
      fromOrTo,
    })
  );
};

export const initialiseFilterStore = ({ infringementFilterData }) => {
  dispatcher.handleDispatch(initialiseFilterAction(infringementFilterData));
};

export const resetCandidates = (dispatch: any) => {
  dispatch({ type: dataActionTypes.SET_CANDIDATES_ENABLED, data: false });
};

export const fetchNoiseMonitors = (
  client: ApolloClient<object>,
  dispatcher: any,
  airportIds: string[]
) => {
  fetchNoiseMonitorLocations(client, airportIds)
    .then((data: any) => {
      dispatcher({ type: dataActionTypes.NOISE_MONITORS_RECEIVED, data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchNoiseEventData = (
  client: ApolloClient<object>,
  dispatcher: any,
  noiseEventIds: number[]
) => {
  dispatcher({ type: dataActionTypes.BEGIN_NOISE_EVENT_FETCH });
  fetchNoiseEvents(client, noiseEventIds)
    .then((data: any) => {
      dispatcher({ type: dataActionTypes.NOISE_EVENTS_RECEIVED, data });
    })
    .catch(error => {
      console.error(error);
    });
};

export const resetNoiseEventData = (dispatcher: any) => {
  dispatcher({ type: dataActionTypes.RESET_NOISE_EVENTS });
};
