import React from 'react';
import cx from 'classnames';
import { InlineDropDown } from '@ems/client-design-system';

export const NMTSelector = ({
  selectedNMT,
  nmtOptions,
  nmtList,
  setSelectedNMT,
  isPlaybackRunning,
}: any) => {
  if (!nmtList.length || nmtOptions.length <= 1 || !selectedNMT.key) {
    return null;
  }

  const dropdownList = nmtOptions
    .map(nmt => {
      const found = nmtList.find(e => e.id === nmt.locationId);

      return {
        key: found.id,
        label: found.name,
        startTime: nmt.startTime,
        endTime: nmt.endTime
      };
    })
    .sort((a, b) => {
      if (b.label > a.label) {
        return -1;
      } else if (b.label < a.label) {
        return 1;
      } else {
        return 0;
      }
    });

  if (isPlaybackRunning) {
    return (
      <div className={cx('noise-graph_container--nmt')}>
        <span className={'noise-graph_container--nmt-title'}>NMT:</span>
        <span className={'noise-graph_container--nmt-label'}>{selectedNMT.label}</span>
      </div>
    );
  }

  return (
    <div className={cx('noise-graph_container--nmt')}>
      <InlineDropDown
        searchItems={dropdownList}
        selectedTitle={'NMT:'}
        selectedItem={selectedNMT}
        hasIcon={false}
        isIconOnly={false}
        isNullable={false}
        updateSelection={setSelectedNMT}
        openPosition="top-right"
      />
    </div>
  );
};
