import React from 'react';
import { withRouteProps } from 'src/app/hocs/withRouteProps';
import { SplitLayout } from 'src/components/Layout';
import { ComplaintsStateProvider } from 'src/@complaints/providers/ComplaintsStateProvider';
import { ComplaintsFormContainer, EntryPageMapContainer } from 'src/@complaints/containers';
const NewComplaintScreen = ({ location }) => (
  <SplitLayout>
    <ComplaintsStateProvider>
      <EntryPageMapContainer />
      <ComplaintsFormContainer paging={location.state} />
    </ComplaintsStateProvider>
  </SplitLayout>
);

export const NewComplaint = withRouteProps(NewComplaintScreen);
