import { ApolloClient } from 'apollo-client';
import { fetchRoleData } from 'src/app/resolvers/rolesResolver';
import { appActionTypes } from 'src/app/newActionTypes';

export const loadAppRoles = (client: ApolloClient<object>, dispatcher): void => {
  fetchRoleData(client)
    .then(response => {
      dispatcher({ type: appActionTypes.UPDATE_ROLES, payload: response });
    })
    .catch(error => {
      dispatcher({ type: appActionTypes.CONFIG_ERROR, payload: error });
    });
};
