// Types
import {
  IUserNoiseModelingScenario,
  IFormikFieldsErrors,
  ICreateScenarioTranslations,
  IScenarioFormValues,
} from './interfaces';
import { IOperationsFilters } from 'src/app/props';

// Functions
import { isValidTime } from '@ems/client-design-system';
import { dateToLocalDay, timePlusMinusDuration } from 'src/utils';
import { configStore } from 'src/app/stores/configStore';
import { hhmmToSeconds } from 'src/@settings/containers/Rules/InfringementRuleDetails/InfringementRuleDetailsHelpers';

export const findLatestScenario = (scenarioList: IUserNoiseModelingScenario[]) =>
  scenarioList.reduce((a, b) => (new Date(a.lastUpdateTime) > new Date(b.lastUpdateTime) ? a : b));

// Clone filter data then insert null values
export const buildCreateScenarioFilterOptions = (filterData: IOperationsFilters) => {
  const copiedFilterData = JSON.parse(JSON.stringify(filterData));

  Object.keys(copiedFilterData).forEach(item => {
    if (item !== 'airportIds') {
      copiedFilterData[item].unshift(null);
    }
  });
  return copiedFilterData;
};

export const validateCreateScenarioForm = (
  values: IScenarioFormValues,
  translations: ICreateScenarioTranslations
) => {
  const errors: IFormikFieldsErrors = {};
  // 5 or more characters, Alphanumerics, underscores and hyphens only
  if (values.name.length < 5 || values.name.length > 50) {
    errors.name = translations.errors.noiseScenarioNameLengthError;
  }
  if (!/^[a-zA-Z0-9-_]+$/.test(values.name)) {
    errors.name = translations.errors.noiseScenarioNameError;
  }

  // Date selection is valid
  if (!values.dateFrom || !values.dateTo) {
    errors.dateFrom = 'invalid range';
  }
  // Start/end range times are valid
  if (!isValidTime(values.timeFrom) || !isValidTime(values.timeTo)) {
    errors.timeFrom = translations.timeInputLanguageData.validationError;
  }
  if ((values.timeFrom && !values.timeTo) || (values.timeTo && !values.timeFrom)) {
    errors.timeFrom = translations.timeInputLanguageData.validationError;
  }
  // At least one airport selected
  if (!values.airportIds.length) {
    errors.airportIds = translations.errors.noiseScenarioSelectAirport;
  }

  // Ensure time periods are set
  if (!values.dayPeriod.length) {
    errors.dayPeriod = translations.errors.required;
  }

  // Ensure time periods are set
  if (!values.dayPeriod.length) {
    errors.dayPeriod = translations.errors.required;
  }

  if (hhmmToSeconds(values.dayPeriod) > hhmmToSeconds(values.eveningPeriod)) {
    errors.dayPeriod = 'Day period cannnot be after evening period';
  }

  if (hhmmToSeconds(values.dayPeriod) > hhmmToSeconds(values.nightPeriod)) {
    errors.dayPeriod = 'Day period cannnot be after night period';
  }

  if (hhmmToSeconds(values.eveningPeriod) > hhmmToSeconds(values.nightPeriod)) {
    errors.eveningPeriod = 'Evening period cannnot be after night period';
  }

  return errors;
};

export const formatStartAndEndTimes = values => {
  const { dateFrom, dateTo, dayPeriod } = values;

  const localStartDay = dateToLocalDay(dateFrom, configStore.getTimeZone());
  const startTime = timePlusMinusDuration(localStartDay.startOf('day').toISO(), {
    seconds: hhmmToSeconds(dayPeriod),
  });

  const localEndDay = dateToLocalDay(dateTo, configStore.getTimeZone());
  const endTime = timePlusMinusDuration(localEndDay.endOf('day').toISO(), {
    seconds: hhmmToSeconds(dayPeriod),
  });

  return { startTime, endTime };
};
