// PKCE helper functions

// Generate a secure random string using the browser crypto functions
export const generateKey = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
};

// Calculate the SHA256 hash of the input text and returns a promise that resolves to an ArrayBuffer
export const sha256 = (plain: string): any => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

// Return the base64-urlencoded sha256 hash for the PKCE challenge
export const pkceChallengeFromVerifier = async v => {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
};

// Base64-urlencodes the input string
export const base64urlencode = (str: any) => {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};
