import React, { useState, useContext } from 'react';

// Apollo
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_NOISE_SCENARIO_LICENSE } from 'src/@scenarioGeneration/containers/CreateScenario/queries';

// Components
import { Dialog, Button } from '@ems/client-design-system';
import { DialogCheckbox, DialogCheckboxGroup } from './Dialog.styles';
import { NoiseScenarioDialog } from 'src/@scenarioGeneration/containers/ViewScenario/components/Dialogs/Dialog.styles';

// Types
import {
  IUpdateNoiseScenarioLicenseMutation,
  IUpdateNoiseScenarioLicenseVariables,
} from 'src/@scenarioGeneration/containers/CreateScenario/interfaces';

// Strings
import { viewScenarioTranslations } from 'src/@scenarioGeneration/containers/ViewScenario/helpers';

// Actions
import { GlobalDispatchContext } from 'src/app/providers/GlobalStateProvider';
import { updateNoiseScenarioLicenseInfo } from 'src/app/actions';

import { IAircraftNoiseModelingConfig } from 'src/app/props';

export const ConfirmLicenseDialog = ({
  licenseConfig,
}: {
  licenseConfig: IAircraftNoiseModelingConfig;
}) => {
  const { software } = licenseConfig;
  const noiseModelsToConfirm = Object.keys(software).filter(
    key => software[key].enabled && !software[key].licenseConfirmed
  );

  if (!noiseModelsToConfirm.length) {
    return null;
  }

  const dispatcher = useContext(GlobalDispatchContext);
  const translations = viewScenarioTranslations();
  const {
    confirmNoiseScenarioLicenseBody,
    confirmNoiseScenarioLicenseHeading,
  } = translations.dialogTranslations;
  const [isModalOpen, setIsModalOpen] = useState(!!noiseModelsToConfirm.length);
  const [checkboxOptions] = useState(noiseModelsToConfirm);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [formHasInteraction, setFormHasInteraction] = useState(false);

  const [updateScenarioLicenses, { loading }] = useMutation<
    IUpdateNoiseScenarioLicenseMutation,
    IUpdateNoiseScenarioLicenseVariables
  >(UPDATE_NOISE_SCENARIO_LICENSE, {});

  const handleCheckSelect = (model: string) => {
    setFormHasInteraction(true);
    if (selectedOptions.includes(model)) {
      setSelectedOptions(selectedOptions.filter(type => type !== model));
    } else {
      setSelectedOptions([...selectedOptions, model]);
    }
  };

  return (
    <NoiseScenarioDialog
      isOpen={isModalOpen}
      title={confirmNoiseScenarioLicenseHeading}
      onClose={() => setIsModalOpen(false)}>
      <Dialog.Body>
        <p>{confirmNoiseScenarioLicenseBody}</p>
        <form>
          <DialogCheckboxGroup>
            {checkboxOptions.map(model => (
              <DialogCheckbox
                className="mosaic-checkbox"
                key={model}
                label={
                  model === 'INM'
                    ? `${model} (Integrated Noise Model)`
                    : `${model} (Aviation Environmental Design Tool) 
              `
                }
                name="licenses"
                onClick={() => handleCheckSelect(model)}
                checked={selectedOptions.includes(model)}
              />
            ))}
          </DialogCheckboxGroup>
        </form>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.FooterActions>
          <Button
            style="primary"
            disabled={!formHasInteraction}
            loading={loading}
            onClick={() => {
              if (selectedOptions.length) {
                const foo = selectedOptions.map(option => ({
                  parameter: `aircraftNoiseModeling/software/${option}/licenseConfirmed`,
                  boolValue: true,
                }));
                updateScenarioLicenses({
                  variables: {
                    settings: foo,
                  },
                }).then(response => {
                  const payloadData =
                    response.data.updateSettingsForDeployedProduct.aircraftNoiseModeling.software;
                  updateNoiseScenarioLicenseInfo(dispatcher, payloadData);
                  setIsModalOpen(false);
                });
              }
            }}>
            Submit
          </Button>
        </Dialog.FooterActions>
      </Dialog.Footer>
    </NoiseScenarioDialog>
  );
};
