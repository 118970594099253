import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import {
  fetchInfringementsQuery,
  infringementsSchema,
  fetchInfringementsCandidatesQuery,
  infringementsCandidatesSchema,
  fetchNoiseMonitorByOpsQuery,
  noiseMonitorSchema,
  fetchNoiseEventQuery,
  noiseEventSchema,
} from './schema';
import { dateRangeStore } from 'src/app/stores/dateRangeStore';
import { filterStore } from 'src/@infringementsCandidates/stores/filterStore';
import { dateTimeInQuery } from 'src/utils/dateTimeConverters';

const FETCH_SIZE = 1000;
export const fetchInfringementData = (
  client: ApolloClient<object>,
  count = FETCH_SIZE,
  sortString: any,
  cursor?: string | null
) => {
  // fetches grid data without tracks
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;
    const filterParams = filterStore.getFilterString(false);
    const queryParams = `
      startTime: "${dateTimeInQuery(from, 'start')}"
      endTime: "${dateTimeInQuery(to, 'end')}"
      first:${count}
      ${cursorParam}
    `;
    const GET_INFRINGEMENTS = gql`
      query {
        ${fetchInfringementsQuery}(${queryParams} ${sortString} ${filterParams}) ${infringementsSchema}
      }
    `;
    client
      .query({
        query: GET_INFRINGEMENTS,
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[fetchInfringementsQuery] &&
          response.data[fetchInfringementsQuery].items
        ) {
          const { items, pageInfo, totalCount } = response.data[fetchInfringementsQuery];
          resolve({
            data: items,
            selectedDateRange: { from, to },
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchInfringementCandidatesData = (
  client: ApolloClient<object>,
  count = FETCH_SIZE,
  sortString: any,
  cursor?: string | null
) => {
  // fetches grid data without tracks
  const { from, to } = dateRangeStore.getDateFilters();
  return new Promise((resolve, reject) => {
    const cursorParam = cursor ? `after:"${cursor}"` : ``;
    const filterParams = filterStore.getFilterString(true);
    const queryParams = `
      startTime: "${dateTimeInQuery(from, 'start')}"
      endTime: "${dateTimeInQuery(to, 'end')}"
      first:${count}
      ${cursorParam}
    `;
    const GET_INFRINGEMENTS = gql`
      query {
        ${fetchInfringementsCandidatesQuery}(${queryParams} ${sortString} ${filterParams}) ${infringementsCandidatesSchema}
      }
    `;
    client
      .query({
        query: GET_INFRINGEMENTS,
      })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data[fetchInfringementsCandidatesQuery] &&
          response.data[fetchInfringementsCandidatesQuery].items
        ) {
          const { items, pageInfo, totalCount } = response.data[fetchInfringementsCandidatesQuery];
          resolve({
            data: items,
            selectedDateRange: { from, to },
            pageInfo,
            totalCount,
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseMonitorLocations = (client: ApolloClient<object>, airportIds: string[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `
      airportIds: ${JSON.stringify(airportIds)}
    `;
    const GET_MONITORS = gql`
      query {
        ${fetchNoiseMonitorByOpsQuery}(${queryParams}) ${noiseMonitorSchema}
      }
    `;
    client
      .query({ query: GET_MONITORS })
      .then(response => {
        if (response && response.data && response.data[fetchNoiseMonitorByOpsQuery]) {
          resolve({
            data: response.data[fetchNoiseMonitorByOpsQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const fetchNoiseEvents = (client: ApolloClient<object>, id: number[]) => {
  return new Promise((resolve, reject) => {
    const GET_NOISE_EVENT = gql`
      query {
        ${fetchNoiseEventQuery}(
          ids: [${id}]
        ) ${noiseEventSchema}
      }
    `;

    client
      .query({ query: GET_NOISE_EVENT })
      .then(response => {
        if (response && response.data && response.data[fetchNoiseEventQuery].length > 0) {
          resolve({
            data: response.data[fetchNoiseEventQuery],
          });
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
