import gql from 'graphql-tag';

export const UPDATE_AIRLINE_MAPPING = gql`
  mutation UpdateAirlineMappings($airlineMappings: [BulkAirlineMappingChangeInput], $scenarioId: Int , $startTime: DateTimeOffset, $endTime: DateTimeOffset) {
    updateAirlineMappings(airlineMappings: $airlineMappings) {
      airline
      aircraftType
      numberOfAircraft
      mappedLTOEngine
      mappedLTOAircraft
      mappedEnrouteEngine
      mappedEnrouteAircraft
      scenarioId
      id
    }
    carbonEmissionsRequestReprocessing(scenarioId: $scenarioId, startTime: $startTime, endTime: $endTime)
  }
`;
