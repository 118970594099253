// validate a date in format yyyy-mm-dd or yyyy-LL-dd
// example: 0000-00-00 or 2015-1-4 not valid
// example: 2013-02-29 is not valid (not leap day)
export const isValidDate = (dateString: string): boolean => {
  // use a regex to check the format
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) {
    return false; // Invalid format
  }
  // now run it through the Date constructor and return true or false
  const date = new Date(dateString);
  const dateCheck = date.getTime();
  if (!dateCheck && dateCheck !== 0) {
    return false; // NaN value, Invalid date
  }
  return date.toISOString().slice(0, 10) === dateString;
};