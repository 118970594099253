import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { aircraftFuelMappingsQuery, aircraftFuelMappingsSchema } from './schema';

export const fetchAircraftFuelMappings = (client: ApolloClient<object>, scenarioIds: number[]) => {
  return new Promise((resolve, reject) => {
    const queryParams = `scenarioIds: ${scenarioIds} `;
    const GET_AIRCRAFT_FUEL_MAPPINGS = gql`
    query {
      ${aircraftFuelMappingsQuery} (${queryParams}) ${aircraftFuelMappingsSchema}
    }`;
    client
      .query({
        query: GET_AIRCRAFT_FUEL_MAPPINGS,
      })
      .then(response => {
        if (response && response.data) {
          resolve(response.data[aircraftFuelMappingsQuery]);
        } else {
          reject('Incorrect response');
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
