import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { OPERATION_TAGS } from 'src/app/featureToggles';
import { useConfigSelectors } from 'src/app/reducers';
import { DateRange } from 'src/components/DateRange';
import { SettingsDispatchContext } from '../provider/SettingsStateProvider';

import { fetchOperations, resetOperations } from 'src/@settings/actions';
import { OPERATION_FILTER_STRING, OPERATION_SORT_STRING } from 'src/constants';

interface IOperationsFilterProps {
  defaultFilterDateFrom?: Date;
  defaultFilterDateTo?: Date;
}

export const OperationsFilter: React.FC<IOperationsFilterProps> = ({
  defaultFilterDateFrom,
  defaultFilterDateTo,
}) => {
  const configSelectors = useConfigSelectors();

  const {} = configSelectors.getConfig();

  const client = useApolloClient();

  const FEATURE_FLAG_OPERATION_TAGS = configSelectors.isFeatureAvailable(OPERATION_TAGS);
  const {
    grid: { resultSize },
    operations: { availableOperationTags },
  } = configSelectors.getConfig();

  const featureFlags = {
    operationTags: FEATURE_FLAG_OPERATION_TAGS,
  };

  const availableFilters = {
    operationTags: availableOperationTags,
  };

  const settingsDispatcher = useContext<any>(SettingsDispatchContext);

  const datesSelected = (dateFrom: Date | null, dateTo: Date | null) => {
    if (dateFrom === null && dateTo === null) {
      resetOperations(settingsDispatcher);
    } else {
      fetchOperations(
        client,
        resultSize,
        settingsDispatcher,
        OPERATION_SORT_STRING,
        OPERATION_FILTER_STRING,
        featureFlags,
        availableFilters,
        dateFrom,
        dateTo
      );
    }
  };

  useEffect(() => {
    if (defaultFilterDateTo && defaultFilterDateFrom) {
      fetchOperations(
        client,
        resultSize,
        settingsDispatcher,
        OPERATION_SORT_STRING,
        OPERATION_FILTER_STRING,
        featureFlags,
        availableFilters,
        defaultFilterDateFrom,
        defaultFilterDateTo
      );
    }
    return () => {
      resetOperations(settingsDispatcher);
    };
  }, []);

  return (
    <div className="spatial-feature__form-field form-text-field">
      <span className="form-text-field__label">Preview Flights</span>
      <DateRange
        hasShortcuts
        isSingleDayRangeAllowed
        hasContigousCalendarMonths
        onDatesSelected={datesSelected}
        allowClearDates={true}
        defaultDateFrom={defaultFilterDateFrom}
        defaultDateTo={defaultFilterDateTo}
      />
    </div>
  );
};
