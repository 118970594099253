import { DateTime } from 'luxon';

const getPlotLines = (eventStart, eventEnd, continuousStart) => {
  const tsEventStart = DateTime.fromISO(eventStart, { setZone: true }).toSeconds();
  const tsEventEnd = DateTime.fromISO(eventEnd, { setZone: true }).toSeconds();
  const tsContStart = DateTime.fromISO(continuousStart, { setZone: true }).toSeconds();

  return [tsEventStart - tsContStart - 0.5, tsEventEnd - tsContStart + 0.5];
};

export const processNoiseData = (
  noiseData,
  continuousNoiseData,
  playbackDuration,
  startEndTime
) => {
  const data: any = continuousNoiseData.map(dataSet => {
    const { samples: continuous, locationId } = dataSet;

    return {
      locationId,
      continuous,
      continuousStart: startEndTime.startTime,
      continuousEnd: startEndTime.endTime,
      stitched: continuous,
      isContinuous: true,
      plotLines: [],
    };
  });

  noiseData.map(dataSet => {
    const {
      locationId,
      startTime: eventStart,
      endTime: eventEnd,
      maxLevel,
      samples: { samples: noiseEvent },
    } = dataSet;

    const existingDataIdx = data.findIndex(e => e.locationId === locationId);
    if (existingDataIdx !== -1) {
      const existingData = data[existingDataIdx];
      if (existingData.isContinuous) {
        // Add plot lines and check max level if basing off continuous noise data
        data[existingDataIdx] = {
          ...existingData,
          maxLevel:
            existingData.maxLevel && existingData.maxLevel > maxLevel
              ? existingData.maxLevel
              : maxLevel,
          plotLines: [
            ...existingData.plotLines,
            ...getPlotLines(eventStart, eventEnd, existingData.continuousStart),
          ],
        };
      } else {
        const tsEventStart = DateTime.fromISO(eventStart, { setZone: true }).toSeconds();
        const tsContStart = DateTime.fromISO(existingData.continuousStart, {
          setZone: true,
        }).toSeconds();

        const startIdx = tsEventStart - tsContStart;
        const newStitched = existingData.stitched;
        newStitched.splice(startIdx, noiseEvent.length, ...noiseEvent);

        data[existingDataIdx] = {
          ...existingData,
          maxLevel:
            existingData.maxLevel && existingData.maxLevel > maxLevel
              ? existingData.maxLevel
              : maxLevel,
          plotLines: [
            ...existingData.plotLines,
            ...getPlotLines(eventStart, eventEnd, existingData.continuousStart),
          ],
          stitched: newStitched,
        };
      }
    } else {
      const tsEventStart = DateTime.fromISO(eventStart, { setZone: true }).toSeconds();
      const tsContStart = DateTime.fromISO(startEndTime.startTime, { setZone: true }).toSeconds();

      const startIdx = tsEventStart - tsContStart;

      const stitched = new Array(playbackDuration).fill(null);
      stitched.splice(startIdx, noiseEvent.length, ...noiseEvent);

      data.push({
        locationId,
        maxLevel,
        continuousStart: startEndTime.startTime,
        continuousEnd: startEndTime.endTime,
        stitched,
        plotLines: getPlotLines(eventStart, eventEnd, startEndTime.startTime),
        isContinuous: false,
      });
    }
  });

  return data;
};
