import styled from 'styled-components/macro';

export const CollapseContainer = styled.div``;
export const CollapseHeader = styled.div`
  display: flex;
  padding-top: 5px;
`;
export const CollapseControl = styled.div`
  margin: 0 auto;
  display: flex;
`;
export const HeaderText = styled.span<{ color: string }>`
  height: 24px;
  margin: 0 2px 0 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
  line-height: 1.5;
  color: ${({ color }) => color};
  letter-spacing: normal;
`;
