import React from 'react';

// Components
import { AMSLPopup, NMTPopup } from 'src/components';

// Constants
import { OPERATIONS, MONITOR_LOCATIONS, EVENT_NOISE_DB } from 'src/constants';

export const OperationMapTags = ({
  element,
  pointData,
  labels,
  draggable,
  onClose,
  trackPcas,
}: {
  element: any;
  pointData: any;
  labels: any;
  draggable: boolean;
  onClose?: any;
  trackPcas?: any;
}) => {
  const { metadata } = element.layer;

  if (typeof metadata === 'undefined' || metadata.tagType === OPERATIONS) {
    return (
      <AMSLPopup
        labels={labels}
        pointData={pointData}
        draggable={draggable}
        onClose={() => onClose()}
        trackPcas={trackPcas}
      />
    );
  } else if ([MONITOR_LOCATIONS, EVENT_NOISE_DB].includes(metadata.tagType)) {
    const { properties, latitude, longitude } = element;
    return (
      <NMTPopup lat={latitude} lon={longitude} draggable={draggable}>
        <>
          <p className="amsl-popup_title">{properties.name}</p>
          <p className="amsl-popup_value">{properties.description}</p>
        </>
      </NMTPopup>
    );
  }
};
