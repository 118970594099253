import { IDataState, IFilterState, ISortState } from 'src/@operations/props';
import { IRangeFilters } from '../containers/FilterContainer/filters.types';

export const dataInitialState: IDataState = {
  isLoading: true,
  selectedInTable: [],
  selectedInMap: [],
  selectedOperations: [],
  selectedDateRange: null,
  addedToSelection: [],
  removedFromSelection: [],
  fetchedData: new Map(),
  isLoadingMore: false,
  pageInfo: undefined,
  totalCount: undefined,
  hasTotalCount: false,
};

const initialRangeFilters: IRangeFilters = {
  from: '',
  to: '',
};

export const initialStateObj: IFilterState = {
  isInitialised: false,
  tableFilters: {
    operationTypes: [],
    operatorCategories: [],
    operationTags: [],
    runwayNames: [],
    airportIds: [],
    remoteAirportIds: [],
    aircraftTypes: [],
    airlines: [],
    correlated: [],
    aircraftCategories: [],
    acid: '',
    tailNumber: '',
  },
  time: initialRangeFilters,
  filterPca: false,
  pcaPosition: { latitude: 0, longitude: 0, altitude: null },
  pcaLocation: null,
};

export const filterInitialState: IFilterState = Object.assign({}, initialStateObj);

export const initSortArray: any = {
  field: 'time',
  direction: 'ASC',
};

export const sortInitialState: ISortState = {
  sortArray: [Object.assign({}, initSortArray)],
};
