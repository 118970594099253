import { NMT_METRICS } from 'src/constants';
import { IDeviceManagementForm, INoiseEventTemplate, NMTMetric } from '../../interfaces';

export const defaultLocationData = {
  id: 1,
  name: '',
  location: '',
  trigger: '',
  deviceType: '',
  isActive: false,
  lastData: '0001-01-01T00:00:00+00:00',
  calibrationTimes: [],
  position: {
    latitude: null,
    longitude: null,
    altitude: null,
  },
  templates: [],
};

export const defaultNoiseEventTemplateData: INoiseEventTemplate = {
  activationTime: 0,
  threshold: '64',
  minEventDuration: '5',
  maxEventDuration: '80',
  endEventDuration: '5',
  trigger: NMT_METRICS.LAS as NMTMetric,
};

export const emptyFormData: IDeviceManagementForm = {
  id: 1,
  name: '',
  trigger: NMT_METRICS.LAS as NMTMetric,
  isActive: false,
  calibrationTimes: [0, 0, 0, 0],
  position: {
    latitude: '0',
    longitude: '0',
    altitude: '0',
  },
  templates: [
    {
      ...defaultNoiseEventTemplateData,
      activationTime: 14400,
    },
  ],
  description: '',
  locationId: undefined,
};

// Calibration time for a new location - 4:00 am
export const DEFAULT_CALIBRATION_TIMES = [14400];
