import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
// props
import { INavBarLink, IRouteItem } from 'src/app/props';
// reducers
import { useLanguageSelectors, useConfigSelectors, useRolesSelectors } from 'src/app/reducers';
// resolvers
import { fetchUserDashboards } from 'src/@dashboard/resolvers/dataResolver';
// common components
import { NavBar, MobileNavBar, Icons } from '@ems/client-design-system';
// functions
import { getDeployedProductId, removeAvailableFilters, clearSavedFilters } from 'src/utils';
import { signOut } from 'src/app/functions/core';
// routs
import { routes } from 'src/app/routes';
import { canAccessRoute } from 'src/app/components/CanAccessRoute';
// constants
import { DEVICE_TYPES } from 'src/constants';
import { usePermissions } from 'src/app/functions/permissions';

export const NavBarContainer = ({ isMobile = false, subMenuLinkSelected }) => {
  const client = useApolloClient();
  const configSelectors = useConfigSelectors();
  const rolesSelectors = useRolesSelectors();
  const availableRoutes = configSelectors.getAvailableRoutes();

  const [userDashboards, setUserDashboards] = useState<any[]>([]); // dashboards for mobile mode or both

  const { canRead: canReadDashboard } = usePermissions('Dashboard');

  useEffect(() => {
    if (isMobile && !userDashboards && canReadDashboard) {
      fetchUserDashboards(client).then(data => {
        const selection = data.filter(({ mode }) => mode !== DEVICE_TYPES.DESKTOP);
        setUserDashboards(selection);
      });
    }
  }, [isMobile]);

  // Translation
  const languageSelectors = useLanguageSelectors();
  const {
    components: {
      headings: { anomsx, logout },
      hints: { areYouSureToLogout },
      lists: { navBarList },
    },
  } = languageSelectors.getLanguage();

  const logoIcon = <Icons title={``} iconName="ic-a-logo" className="ic-a-logo" size="20" />;

  const LinkComponent = ({ children, ...props }: any) => (
    <NavLink activeClassName="navbar-selected" {...props}>
      {children}
    </NavLink>
  );

  const mainLinks: INavBarLink[] = [];
  routes
    .filter(
      (current: IRouteItem) =>
        current.navBarPosition === 'header' && availableRoutes.includes(current.route)
    )
    .map((current: IRouteItem) => {
      const { permissions, route, icon, isMobile } = current;
      if (canAccessRoute(permissions, rolesSelectors)) {
        mainLinks.push({
          key: route,
          name: navBarList[route],
          icon,
          isMobile,
          link: `/${getDeployedProductId()}/${route}`,
          onClick: () => {
            clearSavedFilters();
          },
        });
      }
    });

  const footerLinks: INavBarLink[] = [];
  routes
    .filter(
      (current: IRouteItem) =>
        current.navBarPosition === 'footer' && availableRoutes.includes(current.route)
    )
    .map((current: IRouteItem) => {
      const { isExternal, permissions, route, icon } = current;

      if (canAccessRoute(permissions, rolesSelectors)) {
        footerLinks.push({
          key: route,
          name: navBarList[route],
          isExternal: isExternal ? true : null,
          icon,
          link: `/${getDeployedProductId()}/${route}`,
        });
      }
    });

  footerLinks.push({
    key: 'logout',
    name: logout,
    icon: <Icons title={logout} iconName="ic-ui-logout" className="ic-ui-logout" size="20" />,
    isMobile: true,
    link: logout,
    closeOnClick: false,
    onClick: (event: any) => {
      event.preventDefault();
      if (confirm(areYouSureToLogout)) {
        removeAvailableFilters();
        signOut();
      }
    },
  });

  // externalRoutes
  //   .filter((current: IRouteItem) => current.navBarPosition === 'footer')
  //   .map((current: any) => {
  //     footerLinks.push({
  //       name: navBarList[current.route],
  //       icon: current.icon,
  //       link: current.link,
  //       isExternal: true,
  //     });
  //   });

  // example of a link to an external source
  // const externalRoutes = [
  //   {
  //     route: 'support',
  //     icon: <Icons iconName="ic-support" className="ic-support" size="20" />,
  //     navBarPosition: 'footer',
  //     link: 'https://envirosuite.com/',
  //   },
  // ];

  return (
    <>
      {isMobile ? (
        <MobileNavBar
          title={anomsx}
          // icon={logoIcon} // optional icon
          links={[...mainLinks, ...footerLinks]}
          LinkComponent={LinkComponent}
          userDashboards={userDashboards}
          subMenuLinkSelected={subMenuLinkSelected}
        />
      ) : (
        <NavBar
          productInfo={{
            name: anomsx,
            icon: logoIcon,
          }}
          displayTitle={false}
          primaryLinks={mainLinks}
          footerLinks={footerLinks}
          LinkComponent={LinkComponent}
        />
      )}
    </>
  );
};
