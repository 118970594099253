// functions
import { format, dayFormat, monthFormat } from 'src/utils/dateTimeConverters';

/**
 * @class LocaleCalendar
 * by supplying a locale: string and localeUtils: LocaleUtils prop to Blueprint components, you can customise how dates are rendered, which day of the week is the first column, etc.
 */

export interface ILocaleCalendar {
  firstDayOfWeek: number;
  months: string[];
  weekdaysShort: string[];
  weekdaysLong: string[];
}

export class LocaleCalendar implements ILocaleCalendar {
  firstDayOfWeek = 0;
  months: string[] = [];
  weekdaysShort: string[] = [];
  weekdaysLong: string[] = [];

  constructor(configData: any, languageData: any) {
    const { firstDayOfWeek } = configData;
    const { monthsLong, weekdaysShort, weekdaysLong } = languageData;
    const monthsInOrder = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    const weekdaysInOrder = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const findIndex = weekdaysInOrder.findIndex(val => val === firstDayOfWeek.toLowerCase());

    this.firstDayOfWeek = findIndex !== -1 ? findIndex : 0;
    this.months = monthsInOrder.map(val => monthsLong[val]);
    this.weekdaysShort = weekdaysInOrder.map(val => weekdaysShort[val]);
    this.weekdaysLong = weekdaysInOrder.map(val => weekdaysLong[val]);
  }

  localeUtils() {
    // important notes:
    // locale is optional and might be taken into consideration in the future iterations
    // "formatDay" & "formatMonthTitle" methods won't be used atm but this might be changed in the future if datepicker functionality is modified
    return {
      formatDay: (day: Date, locale?: string) => {
        return format(day, dayFormat, locale);
      },
      formatMonthTitle: (month: Date, locale?: string) => {
        return format(month, monthFormat, locale);
      },
      formatWeekdayLong: (weekday: number, locale?: string) => {
        return this.weekdaysLong[weekday];
      },
      formatWeekdayShort: (weekday: number, locale?: string) => {
        return this.weekdaysShort[weekday];
      },
      getFirstDayOfWeek: (locale?: string) => {
        return this.firstDayOfWeek;
      },
      getMonths: (locale?: string) => {
        return this.months;
      },
    };
  }
}
