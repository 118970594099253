import React from 'react';
// import { DateTime } from 'luxon';

// Selectors & utils
import { getDeployedProductId } from 'src/utils';
import { DateTime } from 'luxon';

// Components
import { Card, Icons, BlockHeader } from '@ems/client-design-system';
import { Link } from 'react-router-dom';

// Constants
import { OPERATION_CORRELATION_COUNT_LIMIT } from 'src/constants';

interface ICorrelatedEventCollapse {
  headerChildren: React.ReactNode;
  bodyChildren?: React.ReactNode;
  isLoading?: boolean;
}

export const CorrelatedBlockTitle = ({
  idList,
  timeList,
  blockTitle,
  screenPath,
}: {
  idList: number[];
  timeList: DateTime[];
  blockTitle: string;
  screenPath: string;
}) => {
  const count: number = idList.length;
  return (
    <BlockHeader
      title={
        count > OPERATION_CORRELATION_COUNT_LIMIT
          ? `${blockTitle} (${OPERATION_CORRELATION_COUNT_LIMIT}/${count})`
          : `${blockTitle} (${count})`
      }
      cta={
        count > 1 ? (
          <CorrelatedViewAllLink ids={idList} timeList={timeList} screenPath={screenPath} />
        ) : null
      }
    />
  );
};

export const CorrelatedViewAllLink = ({
  ids,
  timeList,
  screenPath,
}: {
  ids: number[];
  timeList: string[];
  screenPath: string;
}) => {
  const minTime: DateTime = DateTime.min(...timeList).toFormat('yyyy-MM-dd');
  const maxTime: DateTime = DateTime.max(...timeList).toFormat('yyyy-MM-dd');
  return (
    <Link
      className="correlatedCollapse__header-link"
      to={{
        pathname: `/${getDeployedProductId()}/${screenPath}`,
        search: `?from=${minTime}&to=${maxTime}`,
        state: { fromCorrelated: true, ids },
      }}>
      View all
      <Icons iconName="ic-external" />
    </Link>
  );
};

export const CorrelatedEventCollapse = ({
  headerChildren,
  bodyChildren,
  isLoading = false,
}: ICorrelatedEventCollapse) => (
  <Card className="correlatedCollapse">
    <div className="correlatedCollapse__header">{headerChildren}</div>
  </Card>
);
