// Apollo
import gql from 'graphql-tag';

export const GET_MONITOR_LOCATION = gql`
  query getMonitorLocation($id: Int!) {
    monitorLocation(id: $id) {
      id
      name
      description
      airportId
      position {
        latitude
        longitude
        altitude
      }
      configuration {
        noiseEventTemplates {
          activationTime
          threshold
          minEventDuration
          maxEventDuration
          endEventDuration
          trigger
        }
      }
    }
  }
`;

export const GET_DEVICE = gql`
  query getNoiseDevice($id: Int!) {
    noiseDevice(id: $id) {
      id
      locationId
      isActive
      name
      description
      configuration {
        calibrationTimes
      }
    }
  }
`;

export const GET_MONITOR_LOCATION_SUMMARIES = gql`
  query getMonitorLocationSummaries($sort: [OrderByType], $count: Int!, $cursor: String) {
    monitorLocationSummaries(sort: $sort, first: $count, after: $cursor) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      items {
        locationId
        deviceId
        deviceName
        deviceDescription
        locationName
        locationDescription
        position {
          latitude
          longitude
          altitude
        }
        deviceType
        isActive
        lastData
      }
    }
  }
`;

export const GET_MONITOR_LOCATIONS = gql`
  query getMonitorLocations {
    monitorLocations(filter: { hasAttachedDevice: false }) {
      id
      name
      description
      airportId
      position {
        latitude
        longitude
        altitude
      }
      configuration {
        noiseEventTemplates {
          locationId
          activationTime
          threshold
          minEventDuration
          maxEventDuration
          endEventDuration
          trigger
        }
      }
    }
  }
`;

export const UPDATE_NOISE_DEVICE_LOCATION = gql`
  mutation updateNoiseDeviceLocation($location: NoiseDeviceLocationInput!) {
    updateNoiseDeviceLocation(location: $location) {
      id
      name
      airportId
      position {
        latitude
        longitude
        altitude
      }
    }
  }
`;

export const UPDATE_NOISE_DEVICE_CONFIG = gql`
  mutation updateNoiseDeviceConfig($configuration: NoiseDeviceConfigurationInput!) {
    setNoiseDeviceConfiguration(configuration: $configuration) {
      deviceId
      calibrationTimes
    }
  }
`;

export const ADD_NEW_DEVICE_LOCATION = gql`
  mutation addDeviceLocation($location: NoiseDeviceLocationInput!) {
    addNoiseDeviceLocation(location: $location) {
      id
      name
      airportId
      position {
        latitude
        longitude
        altitude
      }
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation updateNoiseDevice($device: NoiseDeviceInput!) {
    updateNoiseDevice(device: $device) {
      id
      locationId
      isActive
      name
      description
    }
  }
`;

export const UPDATE_DEVICE_LOCATION_CONFIG = gql`
  mutation saveNoiseDeviceLocationConfig($configuration: NoiseDeviceLocationConfigurationInput!) {
    setNoiseDeviceLocationConfiguration(configuration: $configuration) {
      locationId
      noiseEventTemplates {
        activationTime
        threshold
        minEventDuration
        maxEventDuration
        endEventDuration
        trigger
      }
    }
  }
`;
