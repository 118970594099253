export const airlineMappingsQuery = 'airlineMappingsByScenarioIds';
export const airlineMappingsSchema = `{
  airline
  aircraftType
  numberOfAircraft
  mappedLTOEngine
  mappedLTOAircraft
  mappedEnrouteEngine
  mappedEnrouteAircraft
  scenarioId
  id
}`;
export const ltoMappingsQuery = 'ltoAircraftEngineMappings';
export const ltoMappingsSchema = `{
  aircraftType
  engine
}`;
export const enrouteMappingsQuery = 'enrouteAircraftEngineMappings';
export const enrouteMappingsSchema = `{
  aircraftType
  engine
}`;
export const aircraftFlightsQuery = 'aircraftFlightsByTimeRange';
export const aircraftFlightsSchema = `{
  aircraftType
  airline
  numberOfFlights
  engine
  }`;
export const aircraftMappingsQuery = 'aircraftMappingsByScenarioIds';
export const aircraftMappingsSchema = `{
  aircraftType
  id
  mappedLTOEngine
  mappedLTOAircraft
  mappedEnrouteEngine
  mappedEnrouteAircraft
  engine
  scenarioId
}`;
export const airlineLoadFactorsQuery = 'airlineLoadFactorByScenarioIds';
export const airlineLoadFactorsSchema = `{
  id
  airline
  aircraftType
  origin
  destination
  startTime
  endTime
  dayOfWeek
  loadFactor
  scenarioId
}`;
export const phaseDefaultsQuery = 'phaseDefaultsByScenarioIds';
export const phaseDefaultsSchema = `{
    id
    scenarioId
    airport
    runway
    durationTaxiOut
    durationTakeoff
    durationClimb
    durationApproach
    durationTaxiIn
}`;
export const aircraftFuelMappingsQuery = 'aircraftFuelMappingsByScenarioIds';
export const aircraftFuelMappingsSchema = `{
  airline
  aircraftType
  engine
  factorCO2
  percentage
  comment
  scenarioId
  id
}`;
export const airportsQuery = 'airports';
export const airportsSchema = `{
  id
  icaoCode
  runways {
    name
  }
}`;

export const changelogsQuery = 'auditLogEntrySummariesByTimeRange';
export const changelogsFragmentPageInfo = `
fragment auditPageInfo on AuditLogEntrySummaryConnection {
	pageInfo {
		endCursor
		hasNextPage
		hasPreviousPage
		startCursor
	}
}`;
export const changelogsFragmentItems = `
fragment auditItems on AuditLogEntrySummaryConnection {
	items {
		changeType
			dataId
			dataType
			fieldName
			id
			newValue
			oldValue
			revertable
			time
			userName
	}
}`;

export const changelogsSchema = `{
  totalCount
  ...auditPageInfo
  ...auditItems
}
`;

export const changelogsTotalCountSchema = `{
  totalCount
}
`;

export const featureQuery = 'spatialFeatureSummaries';
export const featureSchema = `{
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
  items {
    featureId
    featureType
    name
    groupName
    isActive
    createTime
    lastUpdateTime
  }
}`;

export const gateQuery = 'gates';
export const gateSchema = `
  {
    id
    name
    groupName
    isActive
    filter

    {
      operationTypes
      runways { airportId, runwayName }
    }
    geometry
    {
      leftPosition { latitude, longitude }
      rightPosition { latitude, longitude }
      anchorPosition { latitude, longitude }
      floorAltitude
      ceilingAltitude
    }
  }`;

export const corridorQuery = 'corridors';
export const corridorSchema = `
  {
    id
    name
    groupName
    isActive
    filter
    {
      operationTypes
      runways { airportId, runwayName }
    }
    geometry
    {
      leftPosition { latitude, longitude }
      rightPosition { latitude, longitude }
      anchorPosition { latitude, longitude }
      floorAltitude
      ceilingAltitude
    }

  }`;

export const selectionZoneQuery = 'selectionZones';
export const selectionZoneSchema = `
    {
      id
      name
      groupName
      isActive
      selectionZoneType
      ... on PolygonSelectionZone {
        floorAltitude
        ceilingAltitude
        points {

          altitude
          latitude
          longitude

        }
      }
    }`;

export const operationSummariesQuery = {
  name: 'operationSummariesByTimeRange',
  schema: `
        {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
          items{
            id
            acid
            operationType
            operatorCategory
            aircraftCategory
            aircraftType
            aircraftCount
            airportId
            endTime
            remoteAirportId
            runwayName
            startTime
            time
          }
        }
      `,
};

export const allNoiseMonitorsQuery = {
  name: 'monitorLocations',
  schema: `
    {
      id
      name
      description
      airportId
      position {
        latitude
        longitude
        altitude
      }
    }
  `,
};
