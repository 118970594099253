import React, { useCallback, useMemo, useState } from 'react';
import { TaxiPathActions } from '../../../context/TaxiPathReducer';
import { DEFAULT_COLOR, SELECTED_COLOR } from '../taxiPathLayer.consts';
import { useTaxiPathContext } from '../../../context/TaxiPathContext';
import { DrawablePointLayer } from 'src/components/Map/layers/InteractivePoint/DrawablePointLayer';
import { Point, Position } from 'geojson';
import { useTaxiPathData } from '../useTaxiPathData';
import { InteractiveLineLayer } from 'src/components/Map/layers/InteractiveLine/InteractiveLineLayer';
import { Feature, LineString, featureCollection } from '@turf/turf';
import { LineProperties } from '../types';

interface EditableNodeProps {
  geometry: Point;
  id: string;
}

const EDITABLE_NODE_STYLE = {
  default: {
    'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], SELECTED_COLOR, 'red'],
    'circle-radius': 10,
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 1,
  },
  hovered: {
    'circle-color': 'black',
    'circle-radius': 10,
  },
  selected: {},
};

const CONNECTOR_POINT_STYLE = {
  default: {
    'circle-color': 'yellow',
    'circle-radius': 4,
  },
  hovered: {
    'circle-color': DEFAULT_COLOR,
    'circle-radius': 4,
  },
  selected: {},
};

const CONNECTOR_LINE_STYLES = {
  default: {
    'line-color': 'yellow',
    'line-width': 2,
    'line-dasharray': [2, 1],
  },
};

export const EditableNode = ({ geometry, id }: EditableNodeProps) => {
  const [currentNodePosition, updateNodePosition] = useState<Position>(geometry.coordinates);

  const selectedConnectors = useNodeConnectors(id);
  const updatedConnectors = useUpdatedConnectors(id, currentNodePosition, selectedConnectors);
  const updateConnectorsOnPointSet = useSetUpdatedConnectors(selectedConnectors);

  const { dispatch } = useTaxiPathContext();
  const onRedraw = useCallback(
    updatedPoint => {
      const updatedPointCoordinates = updatedPoint.features[0].geometry.coordinates;
      updateNodePosition(updatedPointCoordinates);
    },
    [updateNodePosition]
  );

  const onUpdatePoint = useCallback(
    point => {
      const updatedPointCoordinates = point;
      dispatch({
        type: TaxiPathActions.UPDATE_NODE,
        payload: { id, coordinates: updatedPointCoordinates },
      });
      updateNodePosition(updatedPointCoordinates);
      updateConnectorsOnPointSet(id, point);
    },
    [id, dispatch]
  );

  const displayedConnectors = useMemo(() => {
    return featureCollection(updatedConnectors);
  }, [updatedConnectors]);

  return (
    <>
      <DrawablePointLayer
        id={id}
        geometry={geometry}
        pointStyles={EDITABLE_NODE_STYLE}
        onPointSet={onUpdatePoint}
        onRedraw={onRedraw}
      />
      <InteractiveLineLayer
        pointStyles={CONNECTOR_POINT_STYLE}
        lineStyles={CONNECTOR_LINE_STYLES}
        lineFeatures={displayedConnectors}
      />
    </>
  );
};

const useNodeConnectors = (nodeId: string): Feature<LineString, LineProperties>[] => {
  const { connectors } = useTaxiPathData();
  return useMemo(
    () =>
      connectors.features.flatMap(feature => {
        if (feature.properties.firstNode === nodeId || nodeId === feature.properties.secondNode) {
          return feature;
        }
        return [];
      }),
    [connectors, nodeId]
  );
};

const useUpdatedConnectors = (
  nodeId: string,
  updatedNodePosition: Position,
  selectedConnectors: Feature<LineString, LineProperties>[]
): Feature<LineString, LineProperties>[] =>
  useMemo(
    () =>
      selectedConnectors.map(connector =>
        updateConnectorCoords(nodeId, updatedNodePosition, connector)
      ),
    [nodeId, updatedNodePosition, selectedConnectors]
  );

const useSetUpdatedConnectors = (selectedConnectors: Feature<LineString, LineProperties>[]) => {
  const { dispatch } = useTaxiPathContext();
  return useCallback(
    (nodeId, currentNodePosition) => {
      selectedConnectors.forEach(connector => {
        const {
          properties: { PathwayId },
          geometry: { coordinates },
        } = updateConnectorCoords(nodeId, currentNodePosition, connector);
        dispatch({
          type: TaxiPathActions.UPDATE_CONNECTOR,
          payload: {
            id: PathwayId,
            coordinates,
          },
        });
      });
    },
    [selectedConnectors, dispatch]
  );
};

const updateConnectorCoords = (
  nodeId: string,
  updatedNodePosition: Position,
  connector: Feature<LineString, LineProperties>
): Feature<LineString, LineProperties> => {
  const connectorLineCoordinates = connector.geometry.coordinates;
  const updatedCoordinateIndex =
    connector.properties.firstNode === nodeId ? 0 : connectorLineCoordinates.length - 1;
  connectorLineCoordinates[updatedCoordinateIndex] = updatedNodePosition;
  return {
    ...connector,
    geometry: { ...connector.geometry, coordinates: connectorLineCoordinates },
  };
};
