import { keyToActionTypes } from 'src/utils/keyToActionTypes';

export const dataActionTypes: any = keyToActionTypes(
  {
    INFRINGEMENTS_FETCHED: null,
    GET_TOTAL_COUNT: null,
    SELECT_ROW: null,
    LOAD_MORE: null,
    SORT_TABLE: null,
    RESET_DATA: null,
    INLINE_EDIT: null,
    SET_CANDIDATES_ENABLED: null,
    NOISE_EVENTS_RECEIVED: null,
    RESET_NOISE_EVENTS: null,
    NOISE_MONITORS_RECEIVED: null,
    BEGIN_NOISE_EVENT_FETCH: null,
  },
  'INFRINGEMENTS_CANDIDATES'
);
