import { EventEmitter } from 'events';
// utils
import { dispatcher } from 'src/utils/dispatcher';
import { deepMerge } from 'src/utils/objectModifiers';
import {
  ITableFilters,
  ITableFilterItem,
  ITableFilterSelectedItem,
  ITimeFilters,
  IDefaultFilters,
} from 'src/@infringements/interfaces';
import { configStore } from 'src/app/stores/configStore';
import { filterActionTypes } from 'src/@infringements/actionTypes';
import { convertDataToFilterForm } from 'src/@infringements/functions/filters';
import {
  readFiltersRow,
  readTimeFilters,
  storeFiltersRow,
  storeTimeFilters,
  clearSavedFilters,
  letterCaseFormat,
  storeCorrelatedIds,
  readCorrelatedIds,
  storeFilterACID,
} from 'src/utils';
// constants
import { CHANGE_EVENT, INITIALISE_EVENT, INFRINGEMENT, NULL_VALUE, VECTORED } from 'src/constants';

class FilterStore extends EventEmitter {
  isStoreInitialised: boolean;
  // @ts-ignore
  initialFilters: ITableFilters;
  initialTimeFilters: ITimeFilters;
  // @ts-ignore
  tableFilters: ITableFilters;
  // @ts-ignore
  timeFilters: ITimeFilters;
  initialACIDFilter: string;
  acidFilter: string;
  filterData: any;
  correlatedIds: number[];

  constructor() {
    super();
    this.isStoreInitialised = false;

    this.initialTimeFilters = {
      from: '',
      to: '',
      fromInput: '',
      toInput: '',
    };

    this.initialACIDFilter = '';

    this.initialFilters = {
      ruleNames: {
        filterData: [],
        selectedItems: [],
      },
      statuses: {
        filterData: [],
        selectedItems: [],
      },
      severities: {
        filterData: [],
        selectedItems: [],
      },
      infringementTypes: {
        filterData: [],
        selectedItems: [],
      },
      aircraftCategories: {
        filterData: [],
        selectedItems: [],
      },
      candidates: {
        filterData: [],
        selectedItems: [],
      },
      airlines: {
        filterData: [],
        selectedItems: [],
      },
      vectored: {
        filterData: [],
        selectedItems: [],
      },
    };

    this.correlatedIds = [];

    this.setToInitialValues();
  }

  // ACID Filter
  getACIDFilter = () => {
    return this.acidFilter;
  };

  // Table Filters / Time Filters
  getAllFilterOptions = () => {
    const filterItems = {};
    Object.entries(this.tableFilters).map(([key, value]) => {
      filterItems[key] = value.filterData;
    });
    return filterItems;
  };

  // Returns all items in {key, label, icon} format for each filter category. Used for front-end display.
  getAllSelectedItems = (): ITableFilterSelectedItem => {
    const filterItems = {
      ruleNames: [],
      infringementTypes: [],
      severities: [],
      aircraftCategories: [],
      candidates: [],
      statuses: [],
      airlines: [],
      vectored: [],
    };
    Object.entries(this.tableFilters).map(([key, value]) => {
      filterItems[key] = value.selectedItems;
    });
    return filterItems;
  };

  isFilterApplied = (filter?: any) => {
    // Check if filter is applied
    let isFiltered = false;
    // Defaults to all filters unless a specific one is passed down
    let filtersToCheck: any = this.tableFilters;
    if (filter) {
      filtersToCheck = { [filter]: this.tableFilters[filter] };
    }

    Object.entries(filtersToCheck).forEach(([key, value]: any) => {
      if (value.selectedItems.length) {
        isFiltered = true;
      }
    });
    return isFiltered;
  };

  getHasCorrelatedIds = () => this.correlatedIds.length > 0;

  getFilterString = (
    addCandidateFilter: boolean,
    additionalFilter: { [key: string]: any } = {}
  ) => {
    // Manually formats the selected filters to fit what is required
    // Unlike JSON.stringify, the key side needs to be unquoted
    // For operation types and aircraft categories, each value in the array must be unquoted as well,
    // as they are enum types in the back-end.
    // Example outputs: {operationTypes: [Arrival], runwayNames: ["04L"], aircraftCategories: [BusinessJet]}
    // OR {} (initially)
    let filterString = '';
    Object.entries(this.tableFilters).map(([key, value]) => {
      const valuesToFormat = ['aircraftCategories', 'infringementTypes'];

      const selectedKeys = value.selectedItems.reduce(
        (acc: Array<string | null>, current: ITableFilterItem) => {
          const currentKey = current.key === NULL_VALUE ? null : letterCaseFormat(key, current.key);
          return acc.concat(currentKey);
        },
        []
      );
      if (selectedKeys.length > 0) {
        // Add comma if it's not the first filter to be added
        if (filterString !== '') {
          filterString += ', ';
        }

        const values = JSON.stringify(selectedKeys);
        const formattedValues = valuesToFormat.includes(key) ? values.replace(/\"/g, '') : values;

        if (key === 'candidates') {
          if (addCandidateFilter && selectedKeys.length !== 2) {
            selectedKeys.map(item => {
              if (item === 'Infringement') {
                filterString += 'isInfringement: true';
              } else if (item === 'Candidate') {
                filterString += 'isInfringement: false';
              }
            });
          }
        } else if (key === VECTORED) {
          if (selectedKeys.length !== 2) {
            selectedKeys.map(item => {
              if (item === 'Vectored') {
                filterString += `includeOperationTags:[{name:"Vector"}]`;
              } else if (item === null) {
                filterString += `excludeOperationTags:[{name:"Vector"}]`;
              }
            });
          }
        } else {
          filterString += `${key}: ${formattedValues}`;
        }
      }

      storeFiltersRow(INFRINGEMENT, this.tableFilters);
      storeTimeFilters(INFRINGEMENT, this.timeFilters);
      storeCorrelatedIds(INFRINGEMENT, this.correlatedIds);
      clearSavedFilters(INFRINGEMENT);
    });

    if (this.acidFilter && this.acidFilter !== '') {
      // Add comma if it's not the first filter to be added
      if (filterString !== '') {
        filterString += ', ';
      }

      filterString += `acids: ["${this.acidFilter}"],`;
    }

    if (this.timeFilters.from && this.timeFilters.to) {
      const fromSplit = this.timeFilters.from.split(':');
      const fromInSeconds = Number(fromSplit[0]) * 60 * 60 + Number(fromSplit[1]) * 60;
      const toSplit = this.timeFilters.to.split(':');
      const toInSeconds = Number(toSplit[0]) * 60 * 60 + Number(toSplit[1]) * 60;

      // Add comma if it's not the first filter to be added
      if (filterString !== '') {
        filterString += ', ';
      }

      filterString += `, timePeriod: {start: ${fromInSeconds}, end: ${toInSeconds}}`;
    }

    if (this.correlatedIds.length > 0) {
      // Add comma if it's not the first filter to be added
      if (filterString !== '') {
        filterString += ', ';
      }

      filterString += `limitToIds: [${this.correlatedIds.toString()}]`;
    }

    if (additionalFilter) {
      Object.keys(additionalFilter).forEach(key => {
        if (filterString !== '') {
          filterString += ', ';
        }
        filterString += `${key}: ${additionalFilter[key]}`;
      });
    }

    return `filter: {${filterString}}`;
  };

  clearAllSelectedItems = () => {
    Object.keys(this.tableFilters).map(key => {
      this.tableFilters[key].selectedItems = [];
    });

    this.timeFilters = Object.assign({}, this.initialTimeFilters);
    this.correlatedIds = [];
  };

  getTimeFilterValues = () => {
    return this.timeFilters;
  };

  setToInitialValues = () => {
    this.tableFilters = deepMerge({}, this.initialFilters, { clone: true });

    // apply saved time filters
    const savedFiltersTime = readTimeFilters(
      INFRINGEMENT, // source
      Object.keys(this.initialTimeFilters).join()
    );

    const savedCorrelatedIds = readCorrelatedIds(INFRINGEMENT);

    if (savedCorrelatedIds) {
      this.correlatedIds = JSON.parse(savedCorrelatedIds);
    }

    if (savedFiltersTime) {
      this.timeFilters = Object.assign({}, savedFiltersTime);
    } else {
      this.timeFilters = Object.assign({}, this.initialTimeFilters);
    }
  };

  getIfStoreInitialised = () => this.isStoreInitialised;

  updateInitialValues = (
    infringementFilterData = {
      ruleNames: [],
      infringementTypes: [],
      severities: [],
      aircraftCategories: [],
      candidates: [],
      statuses: [],
      airlines: [],
      vectored: [],
    },
    correlatedIds = []
  ) => {
    const defaults: IDefaultFilters = configStore.getInfringementsFilterSet();
    const defaultEntries = Object.entries(defaults);
    const {
      ruleNames,
      infringementTypes,
      severities,
      aircraftCategories,
      candidates,
      statuses,
      airlines,
      vectored,
    } = infringementFilterData;

    const initialFilters: ITableFilters = {
      ruleNames: {
        filterData: convertDataToFilterForm(ruleNames),
        selectedItems: [],
      },
      statuses: {
        filterData: convertDataToFilterForm(statuses),
        selectedItems: [],
      },
      severities: {
        filterData: convertDataToFilterForm(severities),
        selectedItems: [],
      },
      infringementTypes: {
        filterData: convertDataToFilterForm(infringementTypes),
        selectedItems: [],
      },
      aircraftCategories: {
        filterData: convertDataToFilterForm(aircraftCategories, 'ac'),
        selectedItems: [],
      },
      candidates: {
        filterData: convertDataToFilterForm(candidates),
        selectedItems: [],
      },
      airlines: {
        filterData: convertDataToFilterForm(airlines),
        selectedItems: [],
      },
      vectored: {
        filterData: convertDataToFilterForm(vectored),
        selectedItems: [],
      },
    };

    if (defaultEntries.length > 0) {
      defaultEntries.map(([key, value]) => {
        initialFilters[key].selectedItems = convertDataToFilterForm(value);
      });
    }

    // apply saved filters row
    const savedFiltersRow = readFiltersRow(
      INFRINGEMENT, // source
      Object.keys(initialFilters).join()
    );
    if (savedFiltersRow) {
      const savedFiltersRowEntries = Object.entries(savedFiltersRow);
      if (savedFiltersRowEntries.length > 0) {
        savedFiltersRowEntries.forEach(([key, value]: any) => {
          initialFilters[key].selectedItems = convertDataToFilterForm(value);
        });
      }
    }

    this.initialFilters = initialFilters;
    this.correlatedIds = correlatedIds;
  };

  handleActions(action: any) {
    if (action) {
      switch (action.type) {
        case filterActionTypes.UPDATE_ACID_FILTER: {
          const { acid } = action.data;
          this.acidFilter = acid;
          storeFilterACID(INFRINGEMENT, acid);
          this.emit(CHANGE_EVENT);
          break;
        }
        case filterActionTypes.UPDATE_TIME_FILTER_INPUT: {
          const { time, fromOrTo } = action.data;
          if (fromOrTo === 'from') {
            this.timeFilters.fromInput = time;
          } else if (fromOrTo === 'to') {
            this.timeFilters.toInput = time;
          }
          this.emit(CHANGE_EVENT);
          break;
        }
        case filterActionTypes.UPDATE_TIME_FILTER_VALUE: {
          const { time, fromOrTo } = action.data;
          if (fromOrTo === 'from') {
            this.timeFilters.from = time;
          } else if (fromOrTo === 'to') {
            this.timeFilters.to = time;
          }
          this.emit(CHANGE_EVENT);
          break;
        }
        case filterActionTypes.UPDATE_SELECTED_ITEMS:
          this.tableFilters[action.category].selectedItems = action.selectedItems;
          this.emit(CHANGE_EVENT);
          break;
        case filterActionTypes.INITIALISE_STORE:
          this.updateInitialValues(action.infringementFilterData, action.correlatedIds);
          this.setToInitialValues();
          this.isStoreInitialised = true;
          // INITIALISE_EVENT is called to allow the data containers to know they need to fetch data
          this.emit(INITIALISE_EVENT);
          // CHANGE_EVENT is called to allow components to know their data is stale and needs to be updated
          this.emit(CHANGE_EVENT);
          break;
        case filterActionTypes.CLEAR_SELECTED_ITEMS:
          this.clearAllSelectedItems();
          this.emit(CHANGE_EVENT);
          break;
        default:
      }
    }
  }
}

export const filterStore = new FilterStore();
dispatcher.register(filterStore.handleActions.bind(filterStore));
