import React, { useEffect, useContext } from 'react';
import { MapContainer } from 'src/@infringementsCandidates/containers/MapContainer';
import { InfringementDispatchContext } from 'src/@infringementsCandidates/providers/InfringementsStateProvider';
import { useDataSelectors } from 'src/@infringementsCandidates/reducers';
import { fetchNoiseEventData, resetNoiseEventData } from 'src/@infringementsCandidates/actions';
import { useApolloClient } from '@apollo/react-hooks';

export const MapInfoContainer = ({ candidateFlag }: { candidateFlag: boolean }) => {
  const client = useApolloClient();
  const dispatcher = useContext(InfringementDispatchContext);
  const dataSelectors = useDataSelectors();
  const noiseEventIds = dataSelectors.getNoiseEventIds();
  const noiseMonitors = dataSelectors.getNoiseMonitors();
  const noiseEventDetails = dataSelectors.getNoiseEventDetails();

  useEffect(() => {
    if (noiseEventIds.length > 0) {
      fetchNoiseEventData(client, dispatcher, noiseEventIds);
    } else {
      resetNoiseEventData(dispatcher);
    }
  }, [noiseEventIds]);

  return (
    <MapContainer
      noiseMonitors={noiseMonitors}
      noiseData={noiseEventDetails}
      candidateFlag={candidateFlag}
    />
  );
};
